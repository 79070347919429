import { percent, px, rem } from 'csx';
import { extend, style } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { colorGreyAccent } from '../../theme/color';
import { betweenJustified, center, centerCenter, flexRoot } from 'csstips';

export const CollapsibleStateIndicatorStyle: NestedCSSProperties = {
  position: 'relative',
  $nest: {
    '&::after': {},
    '[open] &::after': {
      transform: 'rotate(180deg)',
    },
  },
};

export const CollapsibleStateIndicatorWrapperStyle: NestedCSSProperties = {
  width: px(32),
  height: px(32),
  borderRadius: rem(1),
  lineHeight: px(35),
  textAlign: 'center',
  fontSize: px(11),
  backgroundColor: colorGreyAccent.toHexString(),
  $nest: {
    '.open > .summary & span': {
      transform: 'rotate(180deg)',
    },
  },
};

export const CollapsibleStateIndicatorButtonStyle: NestedCSSProperties = {
  ...centerCenter,
  transition: '0.25s transform ease-in-out',
  height: '100%',
};

export const CollabsibleContainerStyle: NestedCSSProperties = {
  border: '1px solid',
  borderColor: colorGreyAccent.toHexString(),
  borderRadius: px(8),
};

export const CollabsibleSummaryStyle: NestedCSSProperties = {
  padding: px(15),
  cursor: 'pointer',
};

export const CollabsibleSummaryLabelStyle: NestedCSSProperties = extend(flexRoot, betweenJustified, center, {
  width: percent(100),
  fontWeight: 600,
  textAlign: 'center',
  fontSize: px(12),
  $nest: {
    '& > p': {
      flexGrow: 1,
      margin: 0,
    },
  },
});

export const CollapsibleDetailsWrapperStyle: NestedCSSProperties = {
  overflow: 'hidden',
};

export const CollapsibleDetailsContentStyle: NestedCSSProperties = {
  fontSize: px(12),
  lineHeight: px(16),
  padding: px(16),
  paddingTop: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
};

export default {
  stateIndicator: style(CollapsibleStateIndicatorStyle),
  stateIndicatorWrapper: style(CollapsibleStateIndicatorWrapperStyle),
  stateIndicatorButton: style(CollapsibleStateIndicatorButtonStyle),
  container: style(CollabsibleContainerStyle),
  summary: style(CollabsibleSummaryStyle),
  summaryLabel: style(CollabsibleSummaryLabelStyle),
  detailsWrapper: style(CollapsibleDetailsWrapperStyle),
  details: style(CollapsibleDetailsContentStyle),
};
