import * as React from 'react';
import { classes, extend, style } from 'typestyle';
import { BaseBlock, BaseBlockProps } from './BaseBlock';
import { colorContrast100, colorGunmetal, colorWhite, rebrand } from '../../../../theme/color';
import { percent, px } from 'csx';
import { heading5, headingStyles, headingTitle } from '../../utilities/font';
import { fontstackDisplay } from '../../config';

const styles = {
  wrapper: style({
    minWidth: '325px',
    $nest: {
      '.pill + &': {
        paddingTop: 0,
      },
      '& > div': {
        backgroundColor: colorGunmetal.toHexString(),
        color: colorWhite.toHexString(),
      },
      '& h2': {
        marginLeft: px(16),
        margin: 0,
        padding: '16px 0',
        fontFamily: fontstackDisplay,
      },
      '& h3': extend(heading5, {
        marginTop: px(8),
        marginBottom: px(8),
      }),
      '& .details': {
        margin: 0,
        padding: 0,
        width: percent(100),
        borderColor: `transparent transparent ${colorContrast100.toHexString()}`,
        borderRadius: 0,
        boxShadow: 'none',
        $nest: {
          '& > *': {
            paddingLeft: px(16),
            paddingRight: px(16),
          },
        },
      },
      '& .summary': {
        paddingTop: 0,
        paddingBottom: 0,
        borderBottom: '1px solid',
        borderColor: rebrand.contrast[40].toString(),
      },
      '& .pricing': {
        margin: '16px 0',
        textAlign: 'center',
        fontSize: px(12),
        $nest: {
          '& strong': extend(headingTitle, {
            display: 'block',
            lineHeight: 'normal',
            fontWeight: 600,
            $nest: {
              '&:only-child': {
                paddingBottom: px(17),
              },
            },
          }),
        },
      },
    },
  }),
};

export type PaymentPlanBlockProps = BaseBlockProps;

export const PaymentPlanBlock: React.SFC<PaymentPlanBlockProps> = ({ children, className, ...props }) => (
  <BaseBlock className={classes(styles.wrapper, 'payment-plan', className)} {...props}>
    {children}
  </BaseBlock>
);
