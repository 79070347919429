import * as React from 'react';
import { ComponentProps, deps, inject, observer } from '../../../../lib/component';
import { BlockBanner } from '../../atoms/BlockBanner';
import { classes, extend, style } from 'typestyle';
import { Collapsible } from '../../../collapsible/collapsible';
import collapsibleStyles from '../../../collapsible/styles';
import { CollapsibleIndicator } from '../../atoms/CollapsibleIndicator';
import { CheckmarkList } from '../../atoms/CheckmarkList';
import { Button } from '../../atoms/button/Button';
import {
  ACCOUNT_PLANS_ROUTE,
  BROWSE_ROUTE,
  PRODUCTION_SEARCH_ROUTE,
  SEARCH_ROUTE,
  SIGN_UP_ROUTE,
} from '../../../../constants';
import { PaymentPlanBlock } from '../../molecules/registration/PaymentPlanBlock';
import { ButtonProminence, ButtonSize } from '../../atoms/button/buttonStyles';
import planStyles from './styles';
import { percent, px, url } from 'csx';
import ChartBanner from '../../../../static/plans/chart-plan.png';
import { isEmpty } from '../../utilities/objects';
import { HighlightText } from '../../atoms/HighlightText';
import { formatDecimalCurrency, formatNonDecimalCurrency } from '../../../../lib/currency';
import { LOAD_STATE } from '../../../../types/api';
import barsWhite from '../../../../static/bars-white.svg';
import StockChartBanner from '../../../../static/plans/creator-plan-desktop.png';
import { ArrowRight } from '../../atoms/icons/ArrowRight';
import { colorLickdPink, colorWhite, rebrand } from '../../../../theme/color';
import { AddCircle } from '../../atoms/icons/AddCircle';
import { Pill, PillStatus } from '../../atoms/Pill';
import { StarCircle } from '../../atoms/icons/StarCircle';
import { centerCenter, flexRoot } from 'csstips';
import { InfoCircle } from '../../atoms/icons/InfoCircle';
import { defaultHeadingStyles, headingTitle } from '../../utilities/font';
import { SemanticInformationType, SubscriptionStates } from '../../utilities/types';
import { ActionButton } from '../../atoms/button/ActionButton';

const { PRIMARY, SECONDARY } = ButtonProminence;
const { LARGE } = ButtonSize;
const { SUCCESS, WARNING, ERROR } = SemanticInformationType;

const styles = {
  banner: style({
    backgroundColor: colorLickdPink.toString(),
    // TBC
    // backgroundImage: [url(StockChartBanner), `${url(StockChartBanner)}`],
    $nest: {
      '& h2': {
        fontSize: px(32),
        textAlign: 'left',
      },
    },
  }),
  subline: style({
    backgroundColor: '#8019BC',
  }),
  loadingButton: style({
    height: px(16),
  }),
  upgradeBtn: style({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  pricingContentsSubscription: style({
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    whiteSpace: 'pre-wrap',
    $nest: {
      '& span': extend(defaultHeadingStyles, headingTitle),
    },
  }),
  planPill: style(flexRoot, centerCenter, {
    width: percent(100),
    position: 'relative',
    top: px(17),
  }),
  info: style({
    textAlign: 'center',
    fontSize: px(8),
    padding: '0px 16px',

    $nest: {
      '& p': {
        fontSize: '10px',
        marginTop: 0,
        marginBottom: px(8),
        $nest: {
          '&:last-child': {
            marginBottom: '24px',
          },
        },
      },
    },
  }),
};

const conditionalPStyle = style({
  color: rebrand.contrast[40].toHexString(),
});

const conditionalSpanStyle = style({
  textDecoration: 'line-through',
  color: rebrand.contrast[40].toHexString(),
});

type SubscriptionProps = ComponentProps & {
  handleShowAction?: () => void;
  handleSubscribeAction?: () => void;
  actionLoading?: boolean;
  showCaveat?: boolean;
  showBlockBanner?: boolean;
  className?: string;
};

export const Subscription = inject(deps)(
  observer(
    ({
      model: {
        user: {
          user,
          isSubscribed,
          subscriptionStatus,
          isSubscribedAndCancelled,
          subscribedRatecard,
          isSubscribedWithoutChannel,
          subscriptions,
        },
        subscription: {
          subscriptionPlansState,
          subscriptionPlan,
          subscriptionDecimalPrice,
          subscriptionUnitPrice,
          canSubscribe,
          trialLengthInDays,
        },
        basket: { ratecard },
      },
      handleShowAction,
      handleSubscribeAction,
      actionLoading = false,
      showCaveat = false,
      showBlockBanner = false,
      className,
    }: SubscriptionProps) => {
      const isLoggedIn = !isEmpty(user);
      let bannerStatus = SUCCESS;
      let bannerMessage = 'Plan active';
      if (isSubscribedAndCancelled) {
        bannerStatus = WARNING;
        bannerMessage = 'Plan ending soon';
      } else if ((isSubscribed || isSubscribedWithoutChannel) && subscriptionStatus === SubscriptionStates.OVERDUE) {
        bannerStatus = ERROR;
        bannerMessage = 'Plan in arrears';
      } else if ((isSubscribed || isSubscribedWithoutChannel) && subscriptionStatus === SubscriptionStates.DISCOUNTED) {
        bannerStatus = SUCCESS;
        bannerMessage = 'Offer applied';
      } else if (!isSubscribed && !isSubscribedWithoutChannel && trialLengthInDays > 0) {
        bannerStatus = ERROR;
        bannerMessage = `${trialLengthInDays} day free trial`;
      } else if (!isSubscribed && !isSubscribedWithoutChannel && trialLengthInDays === 0) {
        bannerStatus = ERROR;
        bannerMessage = 'Subscribe to access';
      }

      const isDiscounted = isSubscribed && subscriptionStatus === SubscriptionStates.DISCOUNTED;
      const pStyle = isDiscounted ? conditionalPStyle : '';
      const spanStyle = isDiscounted ? conditionalSpanStyle : '';
      const spanText = isDiscounted ? 'discounted' : 'subscription';

      const subscriptionType = trialLengthInDays === 0 || subscriptions.length > 0 ? 'Subscribe' : 'Start free trial';

      return (
        <div data-test-plan-card-subscription={isSubscribed}>
          <PaymentPlanBlock>
            {showBlockBanner && <BlockBanner status={bannerStatus}>{bannerMessage}</BlockBanner>}
            <div className={classes(planStyles.banner, styles.banner, className)}>
              <h2>Creator</h2>
            </div>
            <Collapsible initial={!isLoggedIn}>
              <Collapsible.Summary className={collapsibleStyles.summaryLabel}>
                <h3>What you get</h3>
                <CollapsibleIndicator colour="white" />
              </Collapsible.Summary>
              <CheckmarkList>
                <li>
                  <p>75% off all mainstream music</p>
                </li>
                <li>
                  <p>Over 1 million mainstream tracks</p>
                </li>
                <li>
                  <p>Unlimited use of 100K royalty-free tracks</p>
                </li>
                <li>
                  <p>Music for all social platforms</p>
                </li>
                <li>
                  <p>Avoid copyright claims and demonetisation</p>
                </li>
                <li>
                  <p>14-day free trial</p>
                </li>
              </CheckmarkList>
            </Collapsible>
            {subscriptionPlansState === LOAD_STATE.READY && (
              <div className={styles.pricingContentsSubscription}>
                <p className={pStyle}>
                  Monthly{'\n'}
                  <span className={spanStyle}>
                    {subscriptionDecimalPrice % 100 === 0
                      ? formatNonDecimalCurrency(subscriptionUnitPrice, subscriptionPlan.currency_code)
                      : formatDecimalCurrency(subscriptionDecimalPrice, subscriptionPlan.currency_code)}
                  </span>
                  {'\n'} {isDiscounted ? 'discounted' : 'subscription'}
                </p>
                <AddCircle size={24} color={colorWhite.toString()} style={{ margin: '0 12px' }} />
                <p>
                  From{'\n'}
                  <span>
                    {!isEmpty(subscribedRatecard) &&
                      formatNonDecimalCurrency(
                        subscribedRatecard.value || subscribedRatecard.rate,
                        subscribedRatecard.currency
                      )}
                  </span>
                  {'\n'}per Premium track
                </p>
              </div>
            )}
            {isLoggedIn && (isSubscribed || isSubscribedWithoutChannel) && (
              <Button
                data-test-plan-card-button="subscribed"
                href={BROWSE_ROUTE}
                className={planStyles.planButton}
                prominence={SECONDARY}
                size={LARGE}
              >
                Discover Music
              </Button>
            )}
            {handleShowAction && (
              <Button className={planStyles.planButton} prominence={SECONDARY} size={LARGE} onClick={handleShowAction}>
                Show Price Bands
              </Button>
            )}
            {isLoggedIn && !isSubscribed && !isSubscribedWithoutChannel && (
              <ActionButton
                className={planStyles.planButton}
                prominence={PRIMARY}
                size={LARGE}
                loading={actionLoading}
                onClick={handleSubscribeAction}
                href={typeof handleSubscribeAction !== 'function' && ACCOUNT_PLANS_ROUTE}
                disabled={subscriptionPlansState !== LOAD_STATE.READY}
                data-test-plan-card-button-subscription={subscriptionType}
              >
                {subscriptionType}
              </ActionButton>
            )}
            {!isLoggedIn && !isSubscribed && (
              <Button className={planStyles.planButton} prominence={PRIMARY} size={LARGE} href={SIGN_UP_ROUTE}>
                {subscriptionType}
              </Button>
            )}
          </PaymentPlanBlock>
          {showCaveat && (
            <div className={styles.info}>
              <InfoCircle size={24} />
              <p>
                You will still be able to purchase chart songs with this subscription. Payment will be taken immediately
                and again in 30 days.
              </p>
            </div>
          )}
        </div>
      );
    }
  )
);
