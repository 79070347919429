import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { px } from 'csx';
import { HighlightText, HighlightTextProps } from './HighlightText';

const styles = {
  wrapper: style({
    color: 'white',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(16, 16, 16, 0.6)',
    borderRadius: px(128),
    boxShadow: '0 1px 0 0 rgba(0,0,0,0.1)',
  }),
};

cssRaw(`
@supports (backdrop-filter: blur(10px) saturate(0.9)) {
  .${styles.wrapper} {
    background-color: rgba(216, 216, 216, 0.3) !important;
    backdrop-filter: blur(10px) saturate(0.9);
  }
}
`);

export type PromoBadgeProps = HighlightTextProps;

export const PromoBadge: React.SFC<PromoBadgeProps> = ({ children, className, ...props }) => (
  <div className={classes(styles.wrapper, className)}>
    <HighlightText {...props}>{children}</HighlightText>
  </div>
);
