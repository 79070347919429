import React from 'react';
import { keyframes, style } from 'typestyle';
import { borderRadius } from '../../../theme/border';
import { rebrand } from '../../../theme/color';
import { px } from 'csx';

export interface SkeletonProps {
  height: number;
  width?: number;
  children?: React.ReactNode;
}

export const Skeleton: React.FC<SkeletonProps> = ({ height, width, children }) => {
  const shimmer = keyframes({
    '0%': {
      transform: 'translateX(0)',
    },
    '100%': {
      transform: 'translateX(100%)',
    },
  });

  const styles = style({
    position: 'relative',
    overflow: 'hidden',
    borderRadius: borderRadius.SMALL,
    backgroundColor: rebrand.neutralOnDark[20].toString(),
    width: `${width ? width + 'px' : '100%'}`,
    height: px(height),
    $nest: {
      '&::after': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to right, transparent, #fff 30%, transparent)',
        animation: `${shimmer} 1.5s infinite`,
      },
    },
  });

  return <div className={styles}>{children}</div>;
};
