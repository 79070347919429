import { action } from 'mobx';
import { API, APIResponse, APIPaginatedResponse, APIError } from '../types';
import { withIncludes, withIncludesAndPage } from '../../../lib/api';
import { ArtistSchema, ReleaseSchema, TagSchema, TrackSchema } from '../../../types/schema';

export class ArtistAPIController extends API {
  @action
  getArtist = async (artistSlug: string, includes: string[] = ['images']) => {
    const url = `/catalog/artist/${artistSlug}${withIncludes(includes)}`;
    const resp = await this.request.get({ url });
    await APIError.checkResponse(resp);
    const json = (await resp.json()) as APIResponse<ArtistSchema>;

    this.cache.addArtist(json.data);

    return json;
  };

  @action
  getArtistsByLetter = async (letter: string, includes: string[] = ['images']) => {
    const url = `/catalog/artist${withIncludes(includes)}&startsWith=${letter}`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<ArtistSchema>;

    this.cache.addArtist(json.data);

    return json;
  };

  @action
  getReleases = async (artistSlug: string, includes: string[] = ['images']) => {
    const url = `/catalog/artist/${artistSlug}/releases${withIncludes(includes)}`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<Array<ReleaseSchema>>;

    return json;
  };

  @action
  getSingleRelease = async (artistSlug: string, releaseSlug: string, includes?: string[]) => {
    const url = `/catalog/artist/${artistSlug}/releases/${releaseSlug}${withIncludes(includes)}`;

    const resp = await this.request.get({ url });
    const json = await resp.json();

    return json;
  };

  @action
  getTrack = async (
    artistSlug: string,
    trackSlug: string,
    includes: string[] = [
      'images',
      'releases',
      'releases.images',
      'artists',
      'artists.images',
      'audio',
      'tags',
      'rightsholders',
    ],
    availableIn = ''
  ) => {
    const url = `/catalog/artist/${artistSlug}/track/${trackSlug}${withIncludes(includes)}${
      availableIn ? `&available_in=${availableIn}` : ''
    }`;
    const resp = await this.request.get({ url });
    await APIError.checkResponse(resp);
    const json = (await resp.json()) as APIPaginatedResponse<TrackSchema>;

    this.cache.addTrack(json.data);

    return json;
  };

  @action
  getTracks = async (
    artistSlug: string,
    includes: string[] = ['images', 'artists', 'audio', 'tags'],
    page = 1,
    availableIn = ''
  ) => {
    const url = `/catalog/artist/${artistSlug}/featured${withIncludesAndPage(includes, page)}${
      availableIn ? `&available_in=${availableIn}` : ''
    }`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIPaginatedResponse<Array<TrackSchema>>;

    this.cache.addTracks(json.data);

    return json;
  };

  @action
  getMiscTracks = async (
    artistSlug: string,
    includes: string[] = ['images', 'artists', 'audio'],
    page = 1,
    availableIn = ''
  ) => {
    const url = `/catalog/artist/${artistSlug}/tracks/miscellaneous${withIncludesAndPage(includes, page)}${
      availableIn ? `&available_in=${availableIn}` : ''
    }`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIPaginatedResponse<Array<TrackSchema>>;

    this.cache.addTracks(json.data);

    return json;
  };

  @action
  getSimilarArtists = async (identity: string, includes: string[] = ['images'], page = 1) => {
    const url = `/catalog/artist/${identity}/similar${withIncludesAndPage(includes, page)}&perPage=8`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIPaginatedResponse<Array<ArtistSchema>>;

    this.cache.addArtists(json.data);

    return json;
  };

  @action
  getTags = async (identity: string, tagType = 'hookd_genre', limit = 12) => {
    const url = `/catalog/artist/${identity}/tags/${tagType}?perPage=${limit}`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIPaginatedResponse<Array<TagSchema>>;

    return json;
  };
}
