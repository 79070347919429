import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const ChevronRight: React.SFC<SVGIconProps> = ({ size = 12, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={size * (12 / 8)} viewBox="0 0 8 12" {...props}>
    <path
      fill={color}
      transform="translate(-8 -6)"
      fillRule="evenodd"
      d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
    />
  </BaseSvg>
);
