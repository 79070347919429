import * as React from 'react';
import { Component, inject, deps, observer } from '../lib/component';
import {Page404} from '../components/page-404';

export interface TermsAndConditionsPageProps {}

@inject(deps) @observer
export class _404Page extends Component<TermsAndConditionsPageProps> {
  render() {
    return (
      <Page404 />
    )
  }
}