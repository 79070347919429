/* eslint-disable @typescript-eslint/no-unused-vars */
import { computed, observable } from 'mobx';
import { ArtistSchema, TrackSchema } from '../../../types/schema';
import findFilters from '../../../lib/findFilters';
import { FindFiltersInput } from '../../../types/graphql';
import { EntityType, SuggestionType } from '../../../components/project-happy/atoms/SearchSuggestionItem';

export interface SearchVariables {
  query: string;
  pagination: any;
  sortMode: string;
  sortOrder: string;
  filters: any;
}
export interface MooodsGenres {
  name: string;
  slug: string;
  images: {
    identity: string;
  };
}
export interface Instrument {
  label: string;
  value: boolean | undefined;
}
export interface Catalogue {
  label: string;
  value: string;
}
export type UIRange = [number, number];

export interface UILocalTempo {
  range: UIRange;
  label: string;
}

interface TrackEntity extends TrackSchema {
  name: string;
  type: SuggestionType | null;
}
export interface SelectedEntity {
  uuid?: string;
  identity?: string;
  name: string;
  type?: SuggestionType | null;
  images?: any;
  slug?: string;
  tracks?: TrackSchema[];
  title?: string;
  artistSlug?: string;
  is_available_in_territory?: boolean;
}
export type FindSortMode = 'ALPHABETICAL' | 'POPULAR_SPOTIFY';
export type FindSortOrder = 'ASC' | 'DESC';

export const DEFAULT_STATE: SearchVariables = {
  query: '',
  pagination: {
    size: 25,
    from: 0,
    page: 0,
  },
  sortMode: 'POPULAR_SPOTIFY',
  sortOrder: 'DESC',
  filters: findFilters({
    matchArtistFallback: true,
  }),
};

export class SearchMvpPageModel {
  @observable
  loading = false;

  @observable
  loadingMoreResults = false;

  @observable
  filterCountBounce = false;

  @observable
  noSearchResult = false;

  @observable
  pagination = { ...DEFAULT_STATE.pagination };

  @observable
  showSuggestionDropdown = false;

  @observable
  openFilter = false;

  @observable
  searchState: SearchVariables = { ...DEFAULT_STATE };

  @observable
  userHasInteractedWithSearch = false;

  @observable
  filters: FindFiltersInput & { catologueType?: null } = findFilters({
    matchArtistFallback: false,
    catalogueType: null,
  });

  @observable
  topSolutionFilter: FindFiltersInput = findFilters({
    artist: null,
  });

  @observable
  selectedEntity: SelectedEntity = { name: '', slug: '', type: null, uuid: '', images: null, artistSlug: '' };

  @observable
  playlistDetails: any = { name: '', type: null, uuid: '', track_images: [] };

  @observable
  histroyId = '';

  @observable
  userRegion = '';

  @observable
  selectedIsHistory = false;

  @observable
  results: TrackSchema[] = [];

  @observable
  Savedresults: TrackSchema[] = [];

  @observable
  entities: SelectedEntity[] = [];

  @observable
  saveEntitiesonSelection: SelectedEntity[] = [];

  @observable
  suggestionTrackEntity: TrackEntity[] = [];

  @observable
  searchHistory: any[] = [];

  @observable
  genres: Array<MooodsGenres> = [];

  @observable
  moods: Array<MooodsGenres> = [];

  @computed get durationRange() {
    return [0, 1040000];
  }

  @computed get bpmRange() {
    return [60, 300];
  }
  @observable tempos: Array<UILocalTempo> = [
    {
      range: [0, 80],
      label: 'V. Slow',
    },
    {
      range: [80, 110],
      label: 'Slow',
    },
    {
      range: [110, 130],
      label: 'Medium',
    },
    {
      range: [130, 150],
      label: 'Fast',
    },
    {
      range: [150, 300],
      label: 'V. Fast',
    },
  ];

  @observable instrument: Instrument[] = [
    {
      label: 'Show',
      value: undefined,
    },
    {
      label: 'Hide',
      value: false,
    },
    {
      label: 'Only',
      value: true,
    },
  ];
  @observable availableInCountry: Instrument[] = [
    {
      label: 'Show All',
      value: undefined,
    },
    {
      label: 'Hide Unavailable',
      value: true,
    },
  ];
  @observable cursor: number = null;
  @observable platformsAndLabel: { [key: string]: string } = {
    // dailymotion: 'Dailymotion',
    facebook: 'Facebook',
    snapchat: 'Snapchat',
    twitch: 'Twitch',
    twitter: 'Twitter',
    vimeo: 'Vimeo',
    youtube: 'YouTube',
    instagram: 'Instagram',
    linkedin: 'LinkedIn',
    TikTok: 'TikTok',
    podcasting: 'Podcast',
  };
  @observable catalogueData: Catalogue[] = [
    {
      label: 'All',
      value: 'ALL',
    },
    {
      label: 'Premium',
      value: 'CHART',
    },
    {
      label: 'Included',
      value: 'PRODUCTION',
    },
  ];

  @computed
  get searchVariables(): SearchVariables {
    return this.searchState;
  }

  @computed
  get filterCount(): number {
    const { genre, mood, durationMin, durationMax, tempoMin, tempoMax } = this.searchState.filters;

    const filter = Object.keys(this.searchState.filters).filter((value) => {
      if (value === 'matchArtistFallback' || value === 'availableIn') return false;
      return this.searchState.filters[value] !== this.defaultSearchState.filters[value];
    });
    const minAndMaxDurationChange =
      durationMin !== this.defaultSearchState.filters.durationMin &&
      durationMax !== this.defaultSearchState.filters.durationMax;

    const minAndMaxTempoChange =
      tempoMin !== this.defaultSearchState.filters.tempoMin && tempoMax !== this.defaultSearchState.filters.tempoMax;
    let filterCount = filter.length;
    if (minAndMaxDurationChange) {
      filterCount = filterCount - 1;
    }
    if (minAndMaxTempoChange) {
      filterCount = filterCount - 1;
    }
    if (genre) {
      const genres = genre.split(',');
      filterCount = filterCount + (genres.length - 1);
    }
    if (mood) {
      const moods = mood.split(',');
      filterCount = filterCount + (moods.length - 1);
    }
    return filterCount;
  }

  restructureSuggestionList = (data): EntityType[] => {
    const entityMap = { artists: 'artist', playlists: 'playlist', moods: 'mood', genres: 'genre', tracks: 'track' };
    if (data) {
      const obj = { ...data };
      const arr = Object.keys(obj);
      const autosuggestionEntityList = arr.reduce((arr, key) => {
        const newArr = obj[key].matches.results.map((s) => {
          s.type = entityMap[key];
          return s;
        });
        return [...arr, ...newArr];
      }, []);
      return autosuggestionEntityList;
    }
  };
  formatedImageStructure = (data: EntityType) => {
    return {
      ...data,
      images: (data.images && data.images[0]) || { identity: undefined },
      use_playlist_image: true,
    };
  };

  @computed
  get getEntities(): EntityType[] {
    return this.restructureSuggestionList(this.entities);
  }

  @computed
  get getSavedEntities(): EntityType[] {
    return this.restructureSuggestionList(this.saveEntitiesonSelection);
  }

  @computed
  get topSolutionplaylist(): EntityType[] {
    const res = this.restructureSuggestionList(this.entities)
      .filter((t) => {
        return t.type === 'playlist' && t.slug !== this.selectedEntity.slug;
      })
      .map((p) => this.formatedImageStructure(p));
    if (this.selectedEntity.type !== 'playlist') {
      return res.splice(0, 3);
    }
    return res;
  }

  @computed
  get getSelectedEntity(): SelectedEntity {
    // When history is selected from suggestion list
    if (this.selectedIsHistory) {
      return this.selectedEntity.type === 'playlist'
        ? this.formatedImageStructure(this.selectedEntity as EntityType)
        : this.selectedEntity;
    }

    // When other suggestion is selected from suggestion list
    if (this.selectedEntity.type === 'track') {
      return this.suggestionTrackEntity.find((entity) => {
        return entity.slug === this.selectedEntity.slug && entity.artists[0].slug === this.selectedEntity.artistSlug;
      });
    }

    if (this.selectedEntity && this.selectedEntity.type) {
      const data = this.getSavedEntities.find(
        (entity) => entity.type === this.selectedEntity.type && entity.slug === this.selectedEntity.slug
      );
      if (this.selectedEntity.type === 'playlist') {
        const res = this.formatedImageStructure(data);
        return res;
      }

      return data;
    }
    return null;
  }

  @computed get defaultSearchState(): SearchVariables {
    return DEFAULT_STATE;
  }
}
