import { action, autorun, IReactionDisposer, runInAction } from 'mobx';
import { UIModel } from './model';
import { defaultDescription, defaultTitle } from '../../lib/ssr';
import { AuthModel } from '../auth/model';
import { StorageModel } from '../storage/model';
import { EnvModel } from '../env/model';
import { ContentModel } from '../content/model';
import { replaceValues } from '../../lib/helpers';
import { PlayerController } from '../player/controller';
import { RouterModel } from '../router/model';
import * as React from 'react';
import { UIBreadcrumb, UIOpenGraph } from '../../types/ui';
import { ArtistSchema, TrackSchema } from '../../types/schema';
import encodeWindowFeatures from '../../lib/encodeWindowFeatures';
import { AuthController } from '../auth/controller';
import { OnboardingController } from '../onboarding/controller';
import { SEARCH_ROUTE, SESSION_LOGIN_REDIRECT } from '../../constants';
import { AnalyticsController } from '../analytics/controller';
import { clearNotifications } from '../../components/project-happy/organisms/Notifications';

export class UIController {
  constructor(
    private ui: UIModel,
    private auth: AuthModel,
    private storage: StorageModel,
    private env: EnvModel,
    private content: ContentModel,
    private player: PlayerController,
    private router: RouterModel,
    private authController: AuthController,
    private onboardingController: OnboardingController,
    private analytics: AnalyticsController
  ) {
    this._disposers = [];

    if (!env.isServer) {
      window.addEventListener('resize', this.updateWindowDimensions);
      this.updateWindowDimensions();

      this._disposers.push(
        autorun(() => {
          const shunted = this.ui.showPlayer;
          const hidden = this.ui.hideHubspotChat;
          const hubspotLauncher = document.getElementById('hubspot-messages-iframe-container');

          if (hubspotLauncher) {
            if (shunted) {
              hubspotLauncher.setAttribute('data-shunted', 'true');
            } else {
              hubspotLauncher.removeAttribute('data-shunted');
            }

            hubspotLauncher.style.display = hidden ? 'none !important' : 'initial';
          }
        })
      );

      let lastPath: string = null;
      this._disposers.push(
        autorun(() => {
          // Reset the opengraph on each transition, allow onEnter of page to overwrite
          if (router.location && router.location.pathname !== lastPath) {
            ui.opengraph = {};
            lastPath = router.location.pathname;
          }
        })
      );

      // trigger sign in and clear the auth loading overlay
      this._disposers.push(
        autorun(() => {
          if (auth.user) {
            onboardingController.closeSignUpModal();

            if (ui.authWindow) {
              runInAction(() => {
                ui.authWindow = false;
              });
            }
          }
        })
      );
    }
  }

  private _disposers: IReactionDisposer[];

  dispose = () => {
    this._disposers.forEach((dispose) => dispose());
  };

  destroy = () => {
    window.removeEventListener('resize', this.updateWindowDimensions);
  };

  @action
  updateWindowDimensions = () => {
    this.ui.window.height = window.innerHeight;
    this.ui.window.width = window.innerWidth;
  };

  @action
  scrollToTop = (x = 0, y = 0) => {
    if (!this.ui.isServer) {
      window.scrollTo(x, y);
    }
  };

  @action
  hideOverlays = () => {
    this.ui.resultsOverlay = false;
    this.ui.showAccountPopover = false;
    this.ui.showBasketPopover = false;
    this.ui.showSearchPopover = false;
    clearNotifications();
  };

  @action
  setSEO = async (path: string, values: { [key: string]: string } = {}) => {
    await this.env.ready;
    const data = this.content.seo.paths[path];

    const title =
      data && data.title
        ? `${replaceValues(data.title, values)}${data.noSuffix ? '' : ' ' + this.content.seo.titleSuffix}`
        : this.content.seo.titleFallback;

    const description =
      data && data.description ? replaceValues(data.description, values) : this.content.seo.descriptionFallback;

    this.setTitle(title);
    this.setDescription(description);
  };

  @action
  setTitle = (title: string = defaultTitle) => {
    this.ui.title = title;
  };

  @action
  setDescription = (description = '') => {
    this.ui.description = description || '';
  };

  @action
  setBreadcrumbs = (breadcrumbs: Array<UIBreadcrumb>) => {
    this.ui.breadcrumbs = breadcrumbs;
  };

  @action
  setOpenGraph = (opengraph: UIOpenGraph = {}) => {
    this.ui.opengraph = opengraph;
  };

  @action
  setSolidHeader = (value = false) => {
    this.ui.forceSolidHeader = value;
  };

  @action
  setResultsOverlay = (show = false) => {
    this.hideOverlays();
    this.ui.resultsOverlay = show;
  };

  @action
  setAccountPopover = (show = false) => {
    this.hideOverlays();
    this.ui.showAccountPopover = show;
    if (!show) this.setMenuDrawer(false);
  };

  @action
  setBasketPopover = (show = false) => {
    this.hideOverlays();
    this.ui.showBasketPopover = show;
    if (!show) this.setMenuDrawer(false);
  };

  @action
  setSearchPopover = (show = false) => {
    this.hideOverlays();
    this.ui.showSearchPopover = show;
  };

  @action
  setSearchDrawer = (show = false) => {
    this.ui.showSearchDrawer = show;
  };

  @action
  setMenuDrawer = (show = false) => {
    this.ui.showMenuDrawer = show;
  };

  @action
  setScrollBehaviour = (override = false) => {
    this.ui.scrollingOverride = override;
  };

  @action
  setChrome = (show = true) => {
    this.ui.showChrome = show;
  };

  @action
  hideSignUpCTA = () => {
    this.storage.setItem('hideSignUpCTA', '1');
  };

  @action
  dismissCookieNotification = () => {
    this.storage.setItem('hideCookieNotification', '1');
  };

  @action
  dismissMustReAuth = () => {
    this.auth.mustReAuth = false;
  };

  @action
  addDisposer = (disposer: IReactionDisposer) => {
    this._disposers.push(disposer);
  };

  @action
  preloadPlayer = (track: TrackSchema) => {
    if (track) {
      this.player.loadIfEmpty(track);
    }
  };

  setAuthReturnPayload() {
    window.sessionStorage.setItem(SESSION_LOGIN_REDIRECT, encodeURIComponent(this.router.location.pathname));
  }

  @action
  openSignInWindow = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.setAuthReturnPayload();

    const width = 320;
    const height = 480;
    const left = (this.ui.window.width - width) / 2;
    const top = (this.ui.window.height - height) / 2;

    const authWindow = window.open(
      event.currentTarget.href,
      'Signin',
      encodeWindowFeatures({
        toolbar: 0,
        scrollbars: 0,
        location: 1,
        statusbar: 1,
        menubar: 1,
        resizable: 1,
        width,
        height,
        left,
        top,
      })
    );

    runInAction(() => {
      this.ui.authWindow = true;
    });
  };

  handleClickBurgerMenuLink = (event: React.MouseEvent<any>, label: string) => {
    event.preventDefault();
    const el = event.currentTarget as HTMLAnchorElement;

    this.analytics.sendMixpanel(`Burger Menu > User clicks ${label}`);

    this.router.push(el.pathname + (el.search ? el.search : ''));
    this.ui.showMenuDrawer = false;
  };

  handleClickLink = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    const el = event.currentTarget as HTMLAnchorElement;

    if (el.pathname === SEARCH_ROUTE) {
      this.analytics.sendMixpanel('User clicks search');
    }

    if (el.pathname === '/browse') {
      this.analytics.sendMixpanel('User clicks browse');
    }

    if (el.pathname === '/browse/filter/instrumental') {
      this.analytics.sendMixpanel('User clicks instrumental');
    }

    if (el.pathname === '/browse/theme') {
      this.analytics.sendMixpanel('User clicks themes');
    }

    if (el.pathname === '/browse/collection') {
      this.analytics.sendMixpanel('User clicks playlists');
    }

    if (el.pathname === '/browse/mood') {
      this.analytics.sendMixpanel('User clicks moods');
    }

    if (el.pathname === '/browse/genre') {
      this.analytics.sendMixpanel('User clicks genres');
    }

    if (el.pathname === '/browse/artists') {
      this.analytics.sendMixpanel('User clicks artists');
    }

    if (el.pathname === '/browse/labels') {
      this.analytics.sendMixpanel('User clicks labels');
    }

    if (el.pathname === '/account/favourites') {
      this.analytics.sendMixpanel('User clicks favourites');
    }

    if (el.pathname === '/about') {
      this.analytics.sendMixpanel('User clicks about');
    }

    if (el.pathname === '/faq') {
      this.analytics.sendMixpanel('User clicks FAQ');
    }

    if (el.pathname === '/pricing') {
      this.analytics.sendMixpanel('User clicks pricing');
    }

    this.router.push(el.pathname + (el.search ? el.search : ''));
    this.ui.showMenuDrawer = false;
  };

  handleClickSignIn = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    this.onboardingController.goToSignUpPage();
  };

  @action setLastBreadcrumbLabel = (label: string) => {
    const { length } = this.ui.breadcrumbs;
    this.setBreadcrumbs(
      this.ui.breadcrumbs.map((bc, i) => ({
        path: bc.path,
        label: i === length - 1 ? label : bc.label,
      }))
    );
  };
}
