import * as React from 'react';
import { style } from 'typestyle';
import { Component, deps, inject } from '../../../lib/component';
import { observer } from 'mobx-react';
import { TrackSchema } from '../../../types/schema';
import { ArtistLinkList } from '../utilities/components';
import { PlayButtonCompat } from '../../compat/play-button-compat';
import { mixinTextEllipsis } from '../../../theme/mixin';
import { percent, px } from 'csx';
import { betweenJustified, center, flexRoot } from 'csstips';
import { isEmpty } from '../utilities/objects';
import PlayButton from './button/PlayButton';

const styles = {
  featuredTrack: style({
    position: 'relative',
    $nest: {
      '&::before': {
        content: '""',
        width: 0,
        height: 0,
        borderLeft: '7px solid transparent',
        borderRight: '7px solid transparent',
        borderBottom: '7px solid #FF056D',
        position: 'absolute',
        top: px(-7),
        left: px(8),
      },
    },
  }),
  heading: style({
    backgroundImage: 'linear-gradient(to right, #FF056D, #8019BC)',
    fontSize: px(12),
    fontWeight: 600,
    padding: px(4),
  }),
  trackInfo: style(flexRoot, center, betweenJustified, {
    backgroundColor: '#8019BC',
    padding: '8px 16px 8px 8px',
  }),
  trackCover: style({
    width: px(56),
    flexShrink: 0,
    borderRadius: px(4),
    marginRight: px(8),
  }),
  trackText: style({
    width: percent(65),
    $nest: {
      '& p': {
        margin: 0,
      },
      '& a': {
        color: 'white',
      },
    },
  }),
  trackTitle: style(mixinTextEllipsis, {
    fontWeight: 600,
  }),
};

export type FeaturedTrackOverlayProps = {
  track: TrackSchema;
};

@inject(deps)
@observer
export class FeaturedTrackOverlay extends Component<FeaturedTrackOverlayProps> {
  render() {
    const { model, track } = this.props;
    if (isEmpty(track)) return null;

    const { artists } = track;

    return (
      <div className={styles.featuredTrack}>
        <div className={styles.heading}>Featured song</div>
        <div className={styles.trackInfo}>
          <img className={styles.trackCover} src={model.image.cover(track.images.identity)} />
          <div className={styles.trackText}>
            <p className={styles.trackTitle}>{track.title}</p>
            <p>{ArtistLinkList(artists)}</p>
          </div>
          <PlayButton track={track} />
        </div>
      </div>
    );
  }
}
