import * as React from 'react';
import { style } from 'typestyle';
import { percent, px } from 'csx';
import { ContentSliceProps } from './ContentSlice';
import { BaseCarouselSlice } from './BaseCarouselSlice';
import { heading4, loadingText } from '../../utilities/font';
import { betweenJustified, center, flexRoot } from 'csstips';
import { fourTileMask, TileSvgMask } from '../../atoms/TileSvgMask';
import { loadingPulse } from '../../../../theme/mixin';

const styles = {
  heading: style(loadingText, heading4, { margin: 0 }),
  information: style(flexRoot, betweenJustified, center, {
    marginBottom: px(16),
  }),
  loadingTiles: style(loadingPulse, fourTileMask, {
    height: '23.5vw',
    maxHeight: px(234),
    width: percent(100),
    marginBottom: px(16),
  }),
};

// Exclude gradient prop as this slice has a two tone background
type CarouselSliceProps = ContentSliceProps & { heading?: string };

export const LoadingTileSlice: React.SFC<CarouselSliceProps> = ({ className, contentClassName, heading }) => {
  return (
    <BaseCarouselSlice className={className} contentClassName={contentClassName}>
      <div className={styles.information}>
        <h3 className={styles.heading}>{heading || 'Loading carousel'}</h3>
      </div>
      <div className={styles.loadingTiles}>
        <TileSvgMask />
      </div>
      <div className={styles.loadingTiles}>
        <TileSvgMask />
      </div>
    </BaseCarouselSlice>
  );
};
