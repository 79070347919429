import { CreatorTestimonialSlice } from '../organisms/slices/CreatorTestimonialSlice';
import { isEmpty } from '../utilities/objects';
import { renderPrismicRichTextArray } from '../utilities/props';
import * as React from 'react';
import {
  PrismicCallToActionProps,
  PrismicImage,
  PrismicRichText,
  PrismicYouTubeChannelProps,
} from '../utilities/types';

export type PrismicCreatorTestimonialPayload = PrismicCallToActionProps &
  PrismicYouTubeChannelProps & {
    primary: {
      creator_image: PrismicImage;
      testimonial: PrismicRichText;
    };
  };
export type PrismicCreatorTestimonialProps = {
  payload: PrismicCreatorTestimonialPayload;
};
export const PrismicCreatorTestimonial: React.SFC<PrismicCreatorTestimonialProps> = ({
  payload: {
    primary: {
      creator_image,
      channel_profile_image,
      channel_name,
      channel_subscribers,
      testimonial,
      call_to_action_url,
      call_to_action_text,
    },
  },
}) => (
  <CreatorTestimonialSlice
    creatorImage={!isEmpty(creator_image) && creator_image.url}
    channelImg={!isEmpty(channel_profile_image) && channel_profile_image.url}
    channelName={channel_name}
    channelSubscribers={channel_subscribers}
    quote={renderPrismicRichTextArray(testimonial, 'span')}
    cta={
      call_to_action_text && {
        href: !isEmpty(call_to_action_url) && call_to_action_url.url,
        text: call_to_action_text,
      }
    }
  />
);
