import * as React from 'react';
import Advert from '../molecules/referee/Advert';

export type PrismicListItemPayload = {
  primary: {
    description: string;
    hint_background_image: { url: string; alt: string };
  };
  items: {
    list_item_text: string;
  }[];
};
export type PrismicHowItWorksProps = {
  payload: PrismicListItemPayload;
};

export const PrismicLists: React.SFC<PrismicHowItWorksProps> = ({ payload: { primary, items } }) => {
  return (
    <Advert
      description={primary && primary.description}
      image={primary.hint_background_image}
      texts={items.map((item) => item.list_item_text)}
    />
  );
};
