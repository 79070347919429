import * as React from 'react';
import { observer } from 'mobx-react';
import { classes, style } from 'typestyle';
import { constantCarouselDuration, constantHeaderHeight, constantPageHeaderHeight } from '../theme/constant';
import { percent, px, url } from 'csx';
import { colorGunmetal, colorWhite } from '../theme/color';
import { centerJustified, vertical } from 'csstips';
import { transitionQuickEase } from '../theme/transition';
import { gradientHero } from '../theme/gradient';
import { mediaTablet, mediaMobileOnly } from '../theme/media';
import { LegacyButtonStyle, LegacyButtonStyleDefault } from './pill';
import { resetAnchorStyles } from '../theme/reset';
import { UIHeroArtist } from '../types/ui';
import { ArtistSchema } from '../types/schema';
import { ResponsiveImage } from './responsive-image';
import { ImagePreloader } from '../types/fn';

export interface PageHeaderCarouselModel {
  artists: Array<UIHeroArtist>;
  title: string;
  loading: boolean;
}

export interface PageHeaderCarouselProps {
  className?: string;
  model: PageHeaderCarouselModel;
  FavouriteButton?: React.SFC;
  onClickLink(event: React.MouseEvent<any>): any;
  LoadingOverlay?: any;
  preloadContent: ImagePreloader;
  hide?: boolean;
}

export interface PageHeaderCarouselState {
  active: number;
}

@observer
export class PageHeaderCarousel extends React.Component<PageHeaderCarouselProps, PageHeaderCarouselState> {
  private _timeout: any;
  private _isMounted: boolean;

  render() {
    const { onClickLink, LoadingOverlay, FavouriteButton, preloadContent, hide, className } = this.props;
    const { artists, title, loading } = this.props.model;
    const { active } = this.state;

    if (hide) return null;

    return (
      <div className={classes(container, className)}>
        <div>
          {artists.map((artist, index) => (
            <ResponsiveImage
              asBackground={true}
              identity={artist.image}
              preload={preloadContent}
              className={slide}
              key={index.toString()}
              style={{ opacity: index === active ? 1 : 0 }}
            />
          ))}
        </div>
        <div />
        <div className={overlay}>
          <h1>
            <span className={titleBackground}>{title}</span>
          </h1>
          {FavouriteButton && <FavouriteButton />}
          <div className={classes(link, titleBackground)}>
            {artists.map((artist, index) => (
              <a
                className={linkPill}
                key={index.toString()}
                onClick={onClickLink}
                href={`/music/artists/${artist.slug}`}
                style={{ opacity: index === active ? 1 : 0, zIndex: index === active ? 2 : 1 }}
              >
                {artist.name}
              </a>
            ))}
          </div>
        </div>
        {LoadingOverlay && <LoadingOverlay loading={loading} />}
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = { active: 0 };
  }

  componentDidMount() {
    this._isMounted = true;
    this.prepareTimeout();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  prepareTimeout = () => {
    this._timeout = setTimeout(() => this.next(), constantCarouselDuration);
  };

  next = () => {
    if (!this._isMounted) {
      return clearTimeout(this._timeout);
    }

    const { length } = this.props.model.artists;
    const { active } = this.state;

    this.setState({
      active: active + 1 < length ? active + 1 : 0,
    });

    this.prepareTimeout();
  };
}

const container = style({
  height: constantPageHeaderHeight,
  width: percent(100),
  padding: 0,
  background: colorGunmetal.toString(),
  overflow: 'hidden',
  position: 'relative',
  $nest: {
    '&> div': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    '&> div:nth-child(1)': {
      zIndex: 1,
    },
    '&> div:nth-child(2)': {
      zIndex: 2,
    },
    '&> div:nth-child(3)': {
      zIndex: 3,
    },
  },
});

const slide = style({
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  position: 'absolute',
  transition: `opacity ${transitionQuickEase}`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
});

const link = style({
  position: 'absolute',
  right: px(20),
  bottom: px(20),
  left: 0,
});

const linkPill = style(
  {
    ...LegacyButtonStyle,
    ...LegacyButtonStyleDefault,
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  {
    $nest: { ...resetAnchorStyles(colorWhite.toString(), true) },
  }
);

const titleBackground = style({
  backgroundColor: colorGunmetal.toString(),
});

const overlay = style(
  {
    ...vertical,
    ...centerJustified,
    $nest: {
      '& h1': {
        width: percent(100),
        color: colorWhite.toString(),
        margin: 0,
        marginBottom: px(16),
      },
    },
  },
  mediaTablet({
    padding: `0 80px 0`,
    $nest: {
      '& h1': {
        fontSize: px(60),
      },
    },
  }),
  mediaMobileOnly({
    padding: `0 20px 0`,
    $nest: {
      '& h1': {
        fontSize: px(42),
      },
    },
  })
);
