import {
  VIDSUMMIT_UTM_CAMPAIGN,
  VIDSUMMIT_UTM_MEDIUM,
  VIDSUMMIT_UTM_SOURCE,
  SIGN_UP_ACTION_QUERY
} from '../constants';

export default async function setVidsummitCampaign() {
  document.cookie = `utm_medium=${VIDSUMMIT_UTM_MEDIUM}`;
  document.cookie = `utm_source=${VIDSUMMIT_UTM_SOURCE}`;
  document.cookie = `utm_campaign=${VIDSUMMIT_UTM_CAMPAIGN}`;

  window.location.href = `browse/collection/vidsummit?${SIGN_UP_ACTION_QUERY}&utm_source=vidsummit&utm_medium=vidsummit&utm_campaign=vidsummit`;
}