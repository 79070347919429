import { TextPanel } from '../molecules/panels/TextPanel';
import { isEmpty } from '../utilities/objects';
import { renderPrismicRichTextArray, resolveBackgroundProps } from '../utilities/props';
import * as React from 'react';
import { PrismicBackgroundGradientProps, PrismicComplexBackgroundProps, PrismicRichText } from '../utilities/types';
import { createTextPanel } from '../utilities/prismic';
import { GroupHeading } from '../atoms/GroupHeading';
import { style } from 'typestyle';

export type PrismicGroupHeadingPanelPayload = PrismicBackgroundGradientProps & {
  primary: {
    heading: string;
  };
};
export type PrismicGroupHeadingPanelProps = {
  payload: PrismicGroupHeadingPanelPayload;
};
export const PrismicGroupHeading: React.SFC<PrismicGroupHeadingPanelProps> = ({
  payload: {
    primary: { heading, background_colour, background_gradient_colour_end, background_gradient_colour_direction },
  },
}) => (
  <GroupHeading
    className={style(
      resolveBackgroundProps({
        gradient: {
          from: background_colour,
          to: background_gradient_colour_end,
          direction: background_gradient_colour_direction,
        },
      })
    )}
  >
    <h2>{heading}</h2>
  </GroupHeading>
);
