import { action, observable } from 'mobx';
import { CheckoutPageModel } from './model';
import { RouterState, RedirectFunction } from 'react-router';
import { UIController } from '../../ui/controller';
import { CheckoutController } from '../../checkout/controller';
import { EnvModel } from '../../env/model';
import { CheckoutModel } from '../../checkout/model';
import { ACCOUNT_PLANS_ROUTE, CHECKOUT_STEPS } from '../../../constants';
import { stripQueryString } from '../../../lib/string';
import { AnalyticsController } from '../../analytics/controller';
import { TrackSchema } from '../../../types/schema';
import { ContentController } from '../../content/controller';
import { APIController } from '../../api/controller';
import { APIResponse } from '../../api/types';
import { getEulaType } from '../../../components/project-happy/utilities/types';
import { RouterModel } from '../../router/model';
import { UserModel } from '../../user/model';

const firedOrders = [];

export class CheckoutPageController {
  constructor(
    private model: CheckoutPageModel,
    private checkout: CheckoutController,
    private checkoutModel: CheckoutModel,
    private ui: UIController,
    private env: EnvModel,
    private analytics: AnalyticsController,
    private content: ContentController,
    private api: APIController
  ) {
    this.model.tracksWithUsage = observable.map();
  }

  @action
  init = async (step: number) => {
    const promises = [this.env.ready];

    const isDownload = step === 3;

    if (!isDownload && this.checkoutModel.inProgress === false) {
      promises.push(this.checkout.selectStep(0));
    }

    if (isDownload) {
      const analytics = this.analytics;
      const checkoutModel = this.checkoutModel;

      // This rehydrates order information after returning from Stripe/page reload
      await this.checkout.selectStep(3);

      const { orderId } = checkoutModel;
      const orderData = { orderId, tracks: [], artists: [] };
      for (let i = 0; i < checkoutModel.licences.length; i++) {
        const track = checkoutModel.licences[i].track;
        track.artists.map((artist) => {
          if (orderData.artists.indexOf(artist.name) === -1) {
            orderData.artists.push(artist.name);
          }
        });

        orderData.tracks[i] = track.title;
      }

      const hasFired = firedOrders.includes(orderId);
      if (!hasFired && checkoutModel.order != null && checkoutModel.order.status == 'complete') {
        // Free order
        analytics.sendMixpanel('Order complete', orderData);
        firedOrders.push(orderId);
      } else if (!hasFired) {
        // Paid order - Returning from Stripe - Wait for page to load
        analytics.sendMixpanel('Order complete', orderData);
        analytics.sendPaidOrderCompleteToGa(checkoutModel.order);
        firedOrders.push(orderId);
      }
    }

    this.checkoutModel.step = step;

    return Promise.all(promises);
  };

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    this.checkoutModel.claimsFreeRead = false;
    this.checkoutModel.claimsManagedRead = false;
    const step = CHECKOUT_STEPS.indexOf(stripQueryString(nextState.params['step']));
    const redirectUrls = ['/checkout/v2', '/checkout/review', '/checkout/confirm'];

    if (redirectUrls.includes(nextState.location.pathname)) {
      return replace({
        pathname: '/checkout',
      });
    } else if (nextState.location.pathname === '/checkout') {
      // Always reset the order to ensure that a previous order doesn't interfere with a new checkout journey
      this.checkoutModel.order = null;
    }

    await this.ui.setSEO(nextState.location.pathname);

    if (nextState.location.pathname === '/checkout/download') {
      this.ui.setBreadcrumbs([
        {
          path: '/checkout',
          label: 'Checkout',
        },
      ]);

      this.ui.setSolidHeader(true);
    }
    return this.init(step);
  };

  @action
  onLeave = () => {
    this.ui.setSolidHeader(false);
  };

  @action
  async loadTrackLicenseInfo(identity: TrackSchema['identity']): Promise<TrackSchema | Error> {
    const promises = [];
    promises.push(
      this.api.track.getTrack(identity, [
        'video_platforms',
        'youtube_video_categories',
        'youtube_content_owners',
        'rightsholders',
        'reporting_party',
        'images',
      ])
    );
    promises.push(this.api.track.getTrackRestrictions(identity));
    const [track, restrictions] = (await Promise.all(promises)) as [
      APIResponse<TrackSchema>,
      APIResponse<{ name: string }[]>
    ];
    if (typeof track === 'object' && track !== null && track.status !== false && track.success !== false) {
      if (
        typeof restrictions === 'object' &&
        restrictions !== null &&
        Array.isArray(restrictions.data) &&
        restrictions.data.length > 0
      ) {
        track.data['restrictions'] = restrictions.data.map((restriction) => restriction.name);
      }
      return track.data;
    }
    return Error('Unable to load track license usage information.');
  }

  @action
  async loadEulaInfo(track: TrackSchema) {
    const { identity } = track;
    const eulaReady = this.content.fetchEulas(getEulaType(track));
    if (!this.model.tracksWithUsage.has(identity) || this.model.tracksWithUsage.get(identity) instanceof Error) {
      this.model.tracksWithUsage.set(identity, await this.loadTrackLicenseInfo(identity));
    }
    await eulaReady;
  }
}
