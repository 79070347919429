import {ApolloClient, createNetworkInterface} from 'apollo-client';
import {EnvModel} from '../env/model';
import {HTTPNetworkInterface, HTTPFetchNetworkInterface} from 'apollo-client/transport/networkInterface';
import {MiddlewareInterface, MiddlewareRequest} from 'apollo-client/transport/middleware';
import {AuthModel} from '../auth/model';

export class GraphQLModel {
  public networkInferface: HTTPNetworkInterface;
  public client: ApolloClient;

  constructor(
    private env: EnvModel,
    private auth: AuthModel
  ) {
    this.networkInferface = createNetworkInterface({
      uri: env.graphqlUri
    });

    if (env.isServer) {
      this.client = new ApolloClient({
        ssrMode: true,
        networkInterface: this.networkInferface,
      });
    } else {
      const {initialApolloModel} = window as any;

      this.networkInferface.use([
        new AuthMiddleware(auth)
      ]);

      this.client = new ApolloClient({
        initialState: initialApolloModel,
        networkInterface: this.networkInferface,
      });
    }
  }
}

class AuthMiddleware implements MiddlewareInterface {
  constructor(private model: AuthModel) {}

  applyMiddleware = (request: MiddlewareRequest, next: Function) => {
    if (this.model.token && this.model.user) {
      request.options.headers = request.options.headers || {};
      request.options.headers['Authorization'] = `Bearer ${this.model.token}`;
    }
    
    next();
  }
}