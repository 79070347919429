const toString36 = (num: number) => num.toString(36).substr(2);

export const getUid = () => toString36(Math.random()) + toString36(Date.now());

export const toUpperFirst = (str: string) => str[0].toUpperCase() + str.substr(1);

/**
 * Multi-byte characters (like emoji, non-latin alphabet etc) lead with a "high surrogate" character
 * which fall in a certain character code range.
 *
 * isUnicodeHighSurrogate detects if a given character is in this range and requires additional handling.
 *
 * See https://learn.microsoft.com/en-us/globalization/encoding/surrogate-pairs for more information
 *
 * @param string The target string to perform the test on
 * @param position The character position within the string to perform the test on
 */
export const isUnicodeHighSurrogate = (string: string, position = 0) => {
  const charCode = string.charCodeAt(position);
  return charCode >= 55296 && charCode <= 56319;
};

/**
 * Like String.prototype.charCodeAt but combines unicode surrogate pair characters.
 *
 * @param string The target string to retrieve the character code from
 * @param position The character position within the string to retrieve the character code for
 */
export const getUnicodeCharCodeAt = (string: string, position: number) => {
  if (typeof string !== 'string') return NaN;
  const code = string.charCodeAt(position);
  return isUnicodeHighSurrogate(string, position) ? code + string.charCodeAt(position + 1) : code;
};

/**
 * Like String.prototype.charAt but caters for unicode surrogate pair characters.
 *
 * Note that this means this method may return a string with a length greater than one.
 *
 * @param string The target string to retrieve the character from
 * @param position The character position within the string to retrieve
 */
export const getUnicodeCharAt = (string: string, position: number) => {
  let char = string.charAt(position);
  if (isUnicodeHighSurrogate(char)) {
    char += string.charAt(position + 1);
  }
  return char;
};

/**
 * Where String.prototype.split will return UTF-16 codeunits, destroying surrogate pairs,
 * getUnicodeCharacterArray returns UTF-16 codepoints, maintaining surrogate pairs.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/split#description for more information
 *
 * @param string The target string to produce a character array from
 */
export const getUnicodeCharacterArray = (string: string): string[] => {
  // This method could be simplified to () => string.split(/(?:)/u); but Babel transpiles the unicode flag because of IE11
  const charArray = [];
  let index = 0;
  while (index < string.length) {
    const char = getUnicodeCharAt(string, index);
    charArray.push(char);
    index += char.length;
  }
  return charArray;
};
