import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { percent, px, viewWidth } from 'csx';
import { ContentSlice, ContentSliceProps, GradientBackgroundStyles } from './ContentSlice';
import { TrackTile } from '../../molecules/TrackTile';
import { TrackSchema } from '../../../../types/schema';
import { desktopBreakpoint, desktopContentWidth } from '../../config';
import { mediaDesktop, mediaUpToDesktop } from '../../utilities/mediaQueries';
import { mediaCoarsePointer, mediaFinePointer } from '../../../../theme/media';
import { heading4, headingStyles } from '../../utilities/font';
import { YouTubeVideoTrackTile, YouTubeVideoTrackTileProps } from '../../molecules/YouTubeVideoTrackTile';
import { betweenJustified, center, flexRoot } from 'csstips';
import { Component, deps, inject, observer } from '../../../../lib/component';
import { transitionQuickEase } from '../../../../theme/transition';
import { YouTubeVideoTrackCarousel } from '../../molecules/YouTubeVideoTrackCarousel';

const styles = {
  wrapper: style(
    GradientBackgroundStyles,
    {
      position: 'relative',
      background: `linear-gradient(to bottom, white 250px, transparent 0%), ${GradientBackgroundStyles.background}`,
    },
    mediaUpToDesktop({
      paddingBottom: px(32),
      $nest: {
        '.masthead + &, .with-gradient + &': {
          paddingTop: px(32),
        },
      },
    }),
    mediaDesktop({
      paddingBottom: px(56),
      $nest: {
        '.masthead + &, .with-gradient + &': {
          paddingTop: px(56),
        },
      },
    })
  ),
};

export type YouTubeVideoTrackCarouselSliceProps = { className?: string; videos: YouTubeVideoTrackTileProps[] };

export const YouTubeVideoTrackCarouselSlice: React.SFC<YouTubeVideoTrackCarouselSliceProps> = ({
  className,
  videos,
}) => (
  <YouTubeVideoTrackCarousel
    className={classes('youtube-video-tracks-slice', 'with-gradient', className, styles.wrapper)}
    videos={videos}
  />
);
