import * as React from 'react';
import { FormField, FormFields, Form as IForm } from '../types/form';
import { observer } from 'mobx-react';
import { ModalChildStyle } from './modal';
import { style } from 'typestyle';
import { px } from 'csx';
import { Form } from './form';

export interface FormDetailsFields extends FormFields {
  email: FormField<string>;
  first_name: FormField<string>;
  last_name: FormField<string>;
  country: FormField<string>;
  language: FormField<string>;
  telephone: FormField<string>;
  is_business: FormField<boolean>;
  preferred_genre: FormField<string>;
  found_us: FormField<string>;
}

export type FormDetailsModel = IForm<FormDetailsFields>;

export interface FormDetailsModalProps {
  model: FormDetailsModel;
  onChange(field: string, value: any): void;
  onSubmit(): void;
  onCancel(): void;
  i18n?: {
    title: string;
  }
}

@observer
export class FormDetails extends React.Component<FormDetailsModalProps, any> {
  render() {
    const { model, onChange, onSubmit, onCancel } = this.props;

    return (
      <Form
        model={model}
        onSubmit={onSubmit}
        onChange={onChange}
        onCancel={onCancel}
      />
    )
  }

  static styles = {
    container: style({
      ...ModalChildStyle,
      width: px(420)
    })
  };
}