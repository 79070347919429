import { observable } from 'mobx';
import { FormCreditCardFields } from '../components/form-credit-card';
import { FormOnboardingFields } from '../components/form-onboarding';
import { FormInviteFields } from '../components/form-invite';
import { FormDetailsFields } from '../components/form-details';
import { FormInputDate } from '../components/form-input-date';
import { addressToLabel } from './address';
import { FieldValues, Form, FormField, FormFields } from '../types/form';
import { AddressSchema } from '../types/schema';
import { BillingAddress } from '../types';
import { UIHeroGenre } from '../types/ui';

export const resetFormErrors = (form: Form<any>) => {
  form.error = null;

  Object.keys(form.fields).forEach((key) => {
    form.fields[key].error = null;
  });
};

export function createForm<T extends FormFields>(fields: T): Form<T> {
  return observable({
    error: null,
    disabled: false,
    pristine: true,
    dirty: false,
    valid: false,
    saving: false,
    fields: observable(fields),
  });
}

export function resetForm(form: Form<any>) {
  form.error = null;
  form.disabled = false;
  form.pristine = true;
  form.dirty = false;
  form.saving = false;
  form.valid = false;
}

export function createFormField<T>(props: FormField<T>): FormField<T> {
  return observable({
    type: props.type || 'text',
    error: null,
    required: false,
    disabled: false,
    readonly: false,
    ...props,
  });
}

export function createAddressForm(address: Partial<AddressSchema> = {}) {
  return createForm({
    address1: createFormField({
      name: 'address1',
      label: 'Street',
      value: address.address1 || '',
      autoComplete: 'billing street-address',
      required: true,
    }),
    city: createFormField({
      name: 'city',
      label: 'City',
      value: address.city || '',
      autoComplete: 'billing address-level2',
      required: true,
    }),
    state: createFormField({
      name: 'state',
      label: 'State',
      value: address['state'] || '',
      autoComplete: 'billing address-level1',
    }),
    postcode: createFormField({
      name: 'postcode',
      label: 'Postal code',
      value: address.postcode || '',
      autoComplete: 'billing postal-code',
      required: true,
    }),
    country: createFormField({
      type: 'country',
      name: 'country',
      label: 'Country',
      value: address.country || '',
      autoComplete: 'billing country',
      required: true,
    }),
  });
}

export function createCreditCardForm(addresses: Array<BillingAddress>) {
  return createForm<FormCreditCardFields>({
    name: createFormField({
      name: 'name',
      label: 'Name on card',
      value: '',
      autoComplete: 'cc-card',
      required: true,
    }),
    address: createFormField({
      name: 'address',
      label: 'Billing address',
      value: null,
      type: 'address',
      required: true,
      options: addresses.map((address) => ({ value: address, label: addressToLabel(address) })),
    }),
    number: createFormField({
      name: 'number',
      label: 'Card number',
      value: '',
      autoComplete: 'cc-number',
      required: true,
    }),
    cvc: createFormField({
      name: 'cvc',
      label: 'CVC',
      value: '',
      autoComplete: 'cc-csc',
      required: true,
    }),
    exp: createFormField({
      name: 'exp',
      label: 'Expiry date',
      value: '',
      autoComplete: 'cc-exp',
      type: 'expiry',
      required: true,
    }),
  });
}

export function createOnboardingForm(values: FieldValues<FormOnboardingFields> = {}, genres: Array<UIHeroGenre>) {
  return createForm({
    email: createFormField({
      name: 'email',
      label: 'Email address',
      value: values.email || '',
      autoComplete: 'email',
      required: true,
    }),
    first_name: createFormField({
      name: 'first_name',
      label: 'Forename',
      value: values.first_name || '',
      autoComplete: 'given-name',
      required: true,
    }),
    last_name: createFormField({
      name: 'last_name',
      label: 'Surname',
      value: values.last_name || '',
      autoComplete: 'family-name',
      required: true,
    }),
    country: createFormField({
      type: 'country',
      name: 'country',
      label: 'Country',
      value: values.country || '',
      autoComplete: 'country',
      required: true,
    }),
    age: createFormField({
      type: 'date',
      name: 'age',
      label: 'Date of birth',
      value: FormInputDate.getFormattedDate(
        (() => {
          const date = new Date();

          // We can be lazy here, because if it is a leap year this year,
          // it will also have been one 16 years ago.
          date.setFullYear(date.getFullYear() - 16);

          return date;
        })()
      ),
      autoComplete: 'age',
      required: true,
      helpText: '^ We will not store this information',
    }),
    marketing_allowed: createFormField({
      type: 'checkbox',
      name: 'marketing_allowed',
      label: 'I wish to receive updates about new content on Lickd',
      value: values.marketing_allowed === true,
      required: false,
    }),
    accepted_terms: createFormField({
      type: 'checkbox',
      name: 'accepted_terms',
      label: 'I accept the terms and conditions',
      value: values.accepted_terms === true,
      required: true,
    }),
  });
}

export function createCheckoutCardForm() {
  return createForm({
    name: createFormField({
      name: 'name',
      label: 'Name on card',
      value: '',
      autoComplete: 'cc-card',
      required: true,
    }),
    number: createFormField({
      name: 'number',
      label: 'Card number',
      value: '',
      autoComplete: 'cc-number',
      required: true,
    }),
    cvc: createFormField({
      name: 'cvc',
      label: 'CVC',
      value: '',
      autoComplete: 'cc-csc',
      required: true,
    }),
    exp: createFormField({
      name: 'exp',
      label: 'Expiry date',
      value: '',
      autoComplete: 'cc-exp',
      type: 'expiry',
      required: true,
    }),
  });
}

export function createVatNumberForm(vatNumber = '') {
  return createForm({
    vatNumber: createFormField({
      name: 'vatNumber',
      label: 'VAT number',
      type: 'text',
      value: vatNumber,
    }),
  });
}

export function createMarketingPreferencesForm(marketingAllowed = false) {
  return createForm({
    marketing_allowed: createFormField({
      type: 'checkbox',
      name: 'marketing_allowed',
      label: 'I wish to receive updates about new content on Lickd',
      value: marketingAllowed,
      required: false,
    }),
  });
}

export function createCheckoutCouponForm(couponCode = '', disabled = false) {
  return createForm({
    couponCode: createFormField({
      name: 'couponCode',
      label: 'Coupon code',
      type: 'text',
      value: couponCode,
      disabled,
    }),
  });
}

export function createInviteForm(values: FieldValues<FormInviteFields> = {}) {
  return createForm({
    name: createFormField({
      name: 'name',
      label: 'Name',
      type: 'text',
      value: values.name || '',
    }),
    email: createFormField({
      name: 'email',
      label: 'Email',
      type: 'text',
      value: values.email || '',
    }),
  });
}

export function createDetailsForm(
  values: FieldValues<FormDetailsFields> = {},
  genres: Array<UIHeroGenre>,
  isCountryDisabled = false
) {
  return createForm({
    email: createFormField({
      name: 'email',
      label: 'Email address',
      value: values.email || '',
      autoComplete: 'email',
      required: true,
    }),
    first_name: createFormField({
      name: 'first_name',
      label: 'Forename',
      value: values.first_name || '',
      autoComplete: 'given-name',
      required: true,
    }),
    last_name: createFormField({
      name: 'last_name',
      label: 'Surname',
      value: values.last_name || '',
      autoComplete: 'family-name',
      required: true,
    }),
    country: createFormField({
      type: 'country',
      name: 'country',
      label: 'Country',
      value: values.country || '',
      autoComplete: 'country',
      required: true,
      disabled: isCountryDisabled,
    }),
    language: createFormField({
      type: 'language',
      name: 'language',
      label: 'Language',
      value: values.language || '',
      autoComplete: 'language',
      required: false,
    }),
    telephone: createFormField({
      name: 'telephone',
      label: 'Telephone',
      value: values.telephone || '',
      autoComplete: 'tel',
      required: false,
    }),
    is_business: createFormField({
      type: 'select',
      name: 'is_business',
      label: 'Are you a business?',
      options: [
        { value: '0', label: 'No' },
        { value: '1', label: 'Yes' },
      ],
      value: values.is_business || '',
      autoComplete: 'tel',
      required: false,
    }),
    preferred_genre: createFormField({
      type: 'select',
      name: 'preferred_genre',
      label: 'Preferred genre',
      value: values.preferred_genre || '',
      options: genres.map(({ label, slug: value }) => ({ label, value })),
    }),
    found_us: createFormField({
      type: 'select-with-other',
      name: 'found_us',
      label: 'How did you find us?',
      options: [
        'Google search',
        'YouTube',
        'Twitter',
        'Facebook Advert',
        'Instagram Advert',
        'Google Advert',
        'Email / Newsletter',
        'News Article',
        'From a friend',
      ].map((value) => ({ value, label: value })),
      value: values.found_us,
      required: false,
    }),
  });
}
