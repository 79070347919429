import { observable } from 'mobx';

export class SwapoutsPageModel {
  @observable
  loading: boolean = false;

  @observable
  page_title: any = null;

  @observable
  page_body: any = null;

  @observable
  cover_image: any = null;
}