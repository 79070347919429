import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const InfoOutline: React.SFC<SVGIconProps> = ({ size = 18, color = '#262E3F', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 18 18" {...props} style={{ cursor: 'pointer' }}>
    <path
      d="M8.16666 4.83332H9.83332V6.49999H8.16666V4.83332ZM8.16666 8.16666H9.83332V13.1667H8.16666V8.16666ZM8.99999 0.666656C4.39999 0.666656 0.666656 4.39999 0.666656 8.99999C0.666656 13.6 4.39999 17.3333 8.99999 17.3333C13.6 17.3333 17.3333 13.6 17.3333 8.99999C17.3333 4.39999 13.6 0.666656 8.99999 0.666656ZM8.99999 15.6667C5.32499 15.6667 2.33332 12.675 2.33332 8.99999C2.33332 5.32499 5.32499 2.33332 8.99999 2.33332C12.675 2.33332 15.6667 5.32499 15.6667 8.99999C15.6667 12.675 12.675 15.6667 8.99999 15.6667Z"
      fill={color}
    />
  </BaseSvg>
);
