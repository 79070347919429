import * as React from 'react';
import { observer } from 'mobx-react';
import { style } from 'typestyle';
import { ButtonCircle } from './button-circle';
import { centerCenter, horizontal } from 'csstips';
import { colorBrand, colorGreyAccent, colorGunmetal } from '../theme/color';
import { important, px, url } from 'csx';
import { SVGIcon } from './svg-icon';
import { TrackSchema } from '../types/schema';
import { getTrackImageURL } from '../lib/image';
import { ImagePreloader } from '../types/fn';

export interface PlayerBarControlsModel {
  track: TrackSchema;
  isPlaying: boolean;
}

export interface PlayerBarControlsProps {
  model: PlayerBarControlsModel;
  preload: ImagePreloader;
  onClickPlay(event: React.MouseEvent<any>): void;
  onClickPause(event: React.MouseEvent<any>): void;
  onClickNext(event: React.MouseEvent<any>): void;
  onClickPrevious(event: React.MouseEvent<any>): void;
}

export const PlayerBarControls = observer((props: PlayerBarControlsProps) => {
  const trackImageUrl = getTrackImageURL(props.model.track, props.preload);
  const backgroundImage = trackImageUrl ? url(trackImageUrl) : void 0;

  return (
    <div className={styles.container} style={{ backgroundImage }}>
      {/*<ButtonCircle size={46} onClick={props.onClickPrevious}>*/}
      {/*<SVGIcon.Previous size={21} />*/}
      {/*</ButtonCircle>*/}
      <ButtonCircle
        size={36}
        className={styles.playPause}
        onClick={props.model.isPlaying ? props.onClickPause : props.onClickPlay}
      >
        {!props.model.isPlaying && (
          <SVGIcon.Play className={styles.play} size={15} color={colorGunmetal.toHexString()} />
        )}
        {props.model.isPlaying && <SVGIcon.Pause size={15} color={colorGunmetal.toHexString()} />}
      </ButtonCircle>
      {/*<ButtonCircle size={46} onClick={props.onClickNext}>*/}
      {/*<SVGIcon.Next size={21} />*/}
      {/*</ButtonCircle>*/}
    </div>
  );
});

const styles = {
  container: style({
    ...horizontal,
    ...centerCenter,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    width: '70px',
    height: '70px',
    backgroundSize: '70px 70px',
  }),
  playPause: style({
    background: important(colorGreyAccent.toString()),
    position: 'relative',
    marginTop: '25px',
    left: '15px',
    $nest: {
      '&:hover': {
        background: important(colorGreyAccent.darken(0.15).toString()),
      },
    },
  }),
  play: style({
    marginLeft: px(2),
  }),
};
