import * as React from 'react';
import { InputHTMLAttributes } from 'react';
import { classes, style } from 'typestyle';
import { percent, px } from 'csx';
import { centerCenter, flexRoot } from 'csstips';

const styles = {
  wrapper: style({
    width: px(24),
    height: px(24),
    borderRadius: px(24),
    borderWidth: px(2),
    borderStyle: 'solid',
    position: 'relative',
    display: 'inline-block',
  }),
  input: style({
    opacity: 0,
    position: 'absolute',
    cursor: 'pointer',
    width: percent(100),
    height: percent(100),
    zIndex: 1,
  }),
  indicator: style({
    width: px(14),
    height: px(14),
    borderRadius: px(16),
    position: 'absolute',
    top: 'calc(50% - 7px)',
    left: 'calc(50% - 7px)',
  }),
};

export type CommonRadioInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;
export type RadioInputProps = CommonRadioInputProps & { wrapperClassName: string; indicatorClassName: string };
export const RadioInput: React.SFC<RadioInputProps> = ({
  className,
  wrapperClassName,
  indicatorClassName,
  ...props
}) => (
  <span className={classes(styles.wrapper, wrapperClassName, className, 'radio-input')}>
    <input type="radio" className={styles.input} {...props} />
    <span className={classes(styles.indicator, indicatorClassName)} />
  </span>
);
