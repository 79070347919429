import * as React from 'react';
import { Component, deps, inject, observer } from '../lib/component';
import { AccountLayout } from '../components/project-happy/templates/AccountLayout';
import { ChannelSwitcherAccountSidebar } from '../components/project-happy/organisms/ChannelSwitcherAccountSidebar';
import { style } from 'typestyle';
import { spacing } from '../theme/spacing';
import { Loading } from '../components/loading/loading';
import { ConnectChannelPrompt } from '../components/project-happy/molecules/ConnectYoutubeChannelPrompt';
import { isEmpty } from '../components/project-happy/utilities/objects';
import { OrdersList } from '../components/project-happy/organisms/OrdersList';
import { ChannelBanner } from '../components/project-happy/molecules/ChannelBanner';

export interface AccountOrdersPageProps {}

const styles = {
  channelBanner: style({
    marginBottom: spacing.XLARGE,
  }),
};

@inject(deps)
@observer
export class AccountOrdersPage extends Component<AccountOrdersPageProps> {
  render() {
    const { orders = [] } = this.props.model.page.accountOrders;
    const {
      controller: {
        modal: { showChannelAddModal },
        analytics: { sendMixpanel },
      },
      model: {
        user,
        router: { location },
      },
    } = this.props;

    const loadingUser = isEmpty(user.user) || user.loading || user.loadingAccounts || user.loadingChannels;

    return (
      <AccountLayout sidebar={<ChannelSwitcherAccountSidebar />}>
        {loadingUser ? (
          <Loading />
        ) : (
          <>
            {isEmpty(user.channel) && isEmpty(user.user.default_channel) && (
              <ConnectChannelPrompt
                showChannelAddModal={showChannelAddModal}
                sendMixpanel={sendMixpanel}
                location={location.pathname}
              />
            )}

            {user.channel && user.channel.id && (
              <>
                <h1>Orders</h1>
                <ChannelBanner className={styles.channelBanner} />
                <OrdersList orders={orders} />
              </>
            )}
          </>
        )}
      </AccountLayout>
    );
  }
}
