import * as React from 'react';
import { classes, style } from 'typestyle';
import { percent, px } from 'csx';
import { desktopBreakpoint } from '../../config';
import { mediaDesktop, mediaUpToDesktop } from '../../utilities/mediaQueries';
import { betweenJustified, flexRoot } from 'csstips';
import { Component } from '../../../../lib/component';
import { transitionQuickEase } from '../../../../theme/transition';
import { HTMLAttributes } from 'react';
import { isAnyObject } from '../../utilities/objects';

const hiddenPercentage = 10;
const offset = 24;

const styles = {
  wrapper: style({ width: percent(100) }, mediaUpToDesktop({ overflow: 'auto' })),
  container: style(flexRoot, betweenJustified, {
    transition: `transform ${transitionQuickEase}`,
    transform: 'translateX(0)', // Seems to be a weird visual glitch with partial backgrounds without this
  }),
  containerPositioningDefault: style(
    {
      width: percent(100 + hiddenPercentage * 2),
    },
    mediaDesktop({
      marginLeft: percent(-1 * hiddenPercentage),
      $nest: {
        '&.first-hover': {
          transform: `translateX(calc(${percent(hiddenPercentage)} - ${px(offset)}))`,
        },
        '&.last-hover': {
          transform: `translateX(calc(${percent(-1 * hiddenPercentage)} + ${px(offset)}))`,
        },
      },
    })
  ),
};

/**
 * A peeking carousel is a desktop focussed carousel which displays its contents at 120% of the parent's width.
 * This hides a portion of the first and last items in the carousel until the user hovers over them, which then adjusts
 * the carousel so the hovered item "peeks" into full view to be interacted with.
 *
 * Mobile users are unaffected by this behaviour as the carousel acts like a normal scroller fixed at the width of the
 * desktop breakpoint to allow for smooth continuity between mobile and desktop.
 */

export type PeekingCarouselProps = { className?: string };

export class PeekingCarousel extends Component<PeekingCarouselProps> {
  state = {
    containerClassName: '',
  };

  setContainerClassName(containerClassName = '') {
    this.setState({ containerClassName });
  }

  render() {
    const { children, className } = this.props;
    const childArray = Array.isArray(children) ? children : [children];

    return (
      <div className={classes(styles.wrapper, className)}>
        <div
          className={classes(
            'peeking-carousel-container',
            styles.container,
            styles.containerPositioningDefault,
            this.state.containerClassName
          )}
        >
          {childArray.map((child, key, array) => {
            // We can't add the event listeners to non-elements, so just return the child, peeking won't work if the child is first or last
            if (!(isAnyObject(child) && 'props' in child)) return child;
            const childElement: React.ReactElement = child as React.ReactElement;
            const props: HTMLAttributes<any> = { ...childElement.props };
            if (key === 0 || key === array.length - 1) {
              props.onMouseEnter = () => this.setContainerClassName(`${key === 0 ? 'first' : 'last'}-hover`);
              props.onMouseLeave = () => this.setContainerClassName();
            }

            return React.cloneElement(childElement, props);
          })}
        </div>
      </div>
    );
  }
}
