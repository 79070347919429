import React from 'react';
import { Button, ButtonProps } from './Button';
import { classes, style } from 'typestyle';
import { ButtonProminence, ButtonSize } from './buttonStyles';
import { flexRoot, centerCenter } from 'csstips';
import { px } from 'csx';

const styles = {
  button: style(flexRoot, centerCenter, {
    fontSize: px(16),
    boxShadow: 'none',
    padding: '6px 10px',
    fontWeight: 400,
    $nest: {
      '&:hover': { boxShadow: 'none' },
    },
  }),
  circle: style({
    borderRadius: '100px',
  }),
  icon: style({
    marginRight: '4px',
  }),
  iconRight: style({
    marginLeft: '4px',
  }),
  scrollOnHover: style({
    $nest: {
      ':hover > &': {
        display: 'inline-block',
        transitionProperty: 'transform',
        transitionTimingFunction: 'linear',
      },
    },
  }),
};

export type TagButtonProps = ButtonProps & {
  text: string;
  icon?: any;
  iconRight?: any;
  active?: boolean;
  isCircular?: boolean;
  onClick: () => void;
  hideHoverState?: boolean;
};

type TagButtonState = {
  scrollClass: string | null;
};

export class TagButton extends React.Component<TagButtonProps, TagButtonState> {
  container: HTMLElement;

  state = {
    scrollClass: null,
  };

  setShouldScroll() {
    if (!this.container) return;
    const { scrollWidth, clientWidth } = this.container;
    // The button has a 10px padding, so the tag value would still be visible below clientWidth + 10
    if (scrollWidth <= clientWidth + 10) {
      this.setState({ scrollClass: null });
      return;
    }
    const difference = scrollWidth - clientWidth;
    // Normally generating styles on the fly is a no-no as it can be quite intensive, but as the value is unknown we have no choice
    const scrollClass = style({
      // 13px per second feels like a comfortable scrolling speed
      transitionDuration: Math.floor(difference / 13) + 's',
      $nest: {
        ':hover > &': {
          transform: `translateX(-${difference}px)`,
        },
      },
    });
    this.setState({ scrollClass });
  }

  componentDidMount(): void {
    // Check if a scroll update is needed, but only if it's not already in progress. setShouldScroll can infinite loop without a condition
    if (this.state.scrollClass === null) {
      this.setShouldScroll();
    }
  }
  render() {
    const { text, icon, iconRight, active = false, isCircular, onClick, className, ...props } = this.props;
    const { scrollClass } = this.state;

    return (
      <Button
        className={classes(styles.button, isCircular && styles.circle, className)}
        prominence={active ? ButtonProminence.PRIMARY : ButtonProminence.SECONDARY}
        size={ButtonSize.SMALL}
        onClick={this.props.onClick}
        {...props}
      >
        {icon && <span className={styles.icon}> {icon}</span>}
        <span ref={(ref) => (this.container = ref)} className={classes(styles.scrollOnHover, scrollClass)}>
          {text}
        </span>
        {iconRight && <span className={styles.iconRight}> {iconRight}</span>}
      </Button>
    );
  }
}
