import { SlicePageContext, PrismicSimplePlaylistProps, isTypeArray, PrismicDataDrivenTypes } from '../utilities/types';
import * as React from 'react';
import { TrackCarouselSlice } from '../organisms/slices/TrackCarouselSlice';
import { isTrackSchema, TrackSchema } from '../../../types/schema';
import { isArray } from '../utilities/objects';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { ClassicComponentClass } from 'react';
import { ObservableMap } from 'mobx';
import { StorageModel } from '../../../modules/storage/model';
import { REPLACE_CAROUSELS } from '../../devtools/feature-flags';
import { LoadingCarouselSlice } from '../organisms/slices/LoadingCarouselSlice';

export type PrismicCarouselPayload = PrismicSimplePlaylistProps;
export type PrismicCarouselProps = {
  payload: PrismicCarouselPayload;
  context: SlicePageContext;
};

export const PrismicTrackCarousel = observer(
  ({
    payload: {
      primary: { playlist_heading, playlist_slug },
    },
    context: { playlists, externalDataLoading },
  }: PrismicCarouselProps) => {
    let slug = playlist_slug;
    if (!playlists.has(slug) && StorageModel.getItem(REPLACE_CAROUSELS) === true) {
      slug = REPLACE_CAROUSELS + PrismicDataDrivenTypes.TRACKS;
    }
    const playlist = playlists.get(slug);
    const notFound = !isArray(playlist) || playlist.length === 0;
    if (externalDataLoading !== true && notFound) return null;
    if (externalDataLoading === true && notFound) return <LoadingCarouselSlice heading={playlist_heading} />;
    if (!isTypeArray<TrackSchema>(playlist, isTrackSchema)) return null;
    return <TrackCarouselSlice heading={playlist_heading} tracks={playlist} />;
  }
);
