import { EnterHook, RedirectFunction, RouterState } from 'react-router';
import { noop } from './helpers';
import { AuthModel } from '../modules/auth/model';
import { FlashMessageTypeNoUser } from '../types';
import { EnvModel } from '../modules/env/model';
import { LOG_IN_ROUTE } from '../constants';
import { RouterModel } from '../modules/router/model';
import { notify } from '../components/project-happy/organisms/Notifications';

let initial = true;

const isServer = EnvModel.isServer;

export const defaultTitle = 'Lickd music licensing for YouTube, Facebook and online video creators';
export const defaultDescription = 'Lickd music licensing for YouTube, Facebook and online video creators';

export const clearInitial = () => (initial = false);

export const maybeSkip = (onEnter: EnterHook): EnterHook => {
  return async (nextState: RouterState, replace: RedirectFunction) => {
    if (initial && !isServer) {
      initial = false;
      return;
    }

    await onEnter(nextState, replace);
    return;
  };
};

export const clientOnly = (onEnter: EnterHook): EnterHook => {
  return async (nextState: RouterState, replace: RedirectFunction) => {
    if (isServer) return;
    if (initial) initial = false;

    await onEnter(nextState, replace);
    return;
  };
};

export const passThrough = (onEnter: EnterHook): EnterHook => {
  return async (nextState: RouterState, replace: RedirectFunction) => {
    initial = false;

    await onEnter(nextState, replace);
    return;
  };
};

export const requireAuth = (
  onEnter: EnterHook,
  auth: AuthModel,
  router: RouterModel
): EnterHook => {
  return async (nextState: RouterState, replace: RedirectFunction) => {
    if (isServer) return;
    if (initial) initial = false;

    if (!auth.token) {
      notify({
        title: 'Account required',
        message: 'Register / Log in to continue',
        link: nextState.location.pathname,
      });
      // Set the previous route to enable deep linking
      router.setPreviousRoute(nextState.location);
      replace({ pathname: LOG_IN_ROUTE });
      return;
    }

    if (auth.user === null) {
      await auth.userReady;
    }
    await onEnter(nextState, replace);
    return;
  };
};

export const onEnterNoop = maybeSkip(noop);
