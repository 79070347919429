import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const MoreHoriz: React.SFC<SVGIconProps> = ({ size = 25, isSquare, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={isSquare ? size : size * (25 / 25)} viewBox="0 0 25 25" {...props}>
    <path
      fill={color}
      d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Z"
      id="a"
    />
  </BaseSvg>
);
