import * as React from 'react';
import { classes, style } from 'typestyle';
import { centerCenter } from 'csstips';
import { px } from 'csx';
import { CSSGlobalValues, CSSLength, CSSPercentage, CSSValue } from 'typestyle/lib/types';
import { transitionQuickEase } from '../theme/transition';
import { focusVisible } from './project-happy/utilities/css';
import { shadowElevation2 } from '../theme/shadow';
import { rebrand } from '../theme/color';
import { defaultButtonFocusOutline } from './project-happy/atoms/button/buttonStyles';
import { resetButton } from '../theme/reset';

export interface ButtonCircleProps {
  onClick?(event: React.MouseEvent<any>): void;
  onMouseEnter?(event: React.MouseEvent<any>): void;
  onMouseLeave?(event: React.MouseEvent<any>): void;
  className?: string;
  style?: any;
  size?: CSSValue<'auto' | CSSLength | CSSPercentage | CSSGlobalValues>;
}

export class ButtonCircle extends React.Component<ButtonCircleProps, any> {
  render() {
    const { children, className, onClick, size = px(32), style = {}, ...props } = this.props;

    const innerStyle = {
      ...style,
      width: size,
      height: size,
      borderRadius: size,
    };

    return (
      <button
        style={innerStyle}
        className={classes(className, ButtonCircle.styles.container)}
        onClick={onClick}
        {...props}
      >
        {children}
      </button>
    );
  }

  static styles = {
    container: style(
      resetButton,
      focusVisible(shadowElevation2, {
        backgroundColor: rebrand.neutralOnDark[30].toString(),
        outline: defaultButtonFocusOutline,
      }),
      {
        ...centerCenter,
        display: 'inline-flex',
        cursor: 'pointer',
        transition: `all ${transitionQuickEase}`,
      }
    ),
  };
}
