import * as React from 'react';
import { classes, style } from 'typestyle';
import { TrackSchema } from '../../../types/schema';
import { Component, deps, inject } from '../../../lib/component';
import { observer } from 'mobx-react';
import { em, px } from 'csx';
import { shadowElevation2 } from '../../../theme/shadow';
import { overlayLink } from '../../../theme/mixin';
import { transitionQuickEase } from '../../../theme/transition';
import { colorGunmetal, colorWhite } from '../../../theme/color';
import { betweenJustified, flexRoot, vertical } from 'csstips';
import { toDateString } from '../utilities/date';
import { Link } from '../atoms/Link';
import { zLabel } from '../../../theme/z';
import { AnalyticsContextProvider } from '../utilities/AnalyticsContext';

const styles = {
  wrapper: style({
    position: 'relative',
    display: 'flex',
    margin: px(8),
    borderRadius: em(0.3),
    flexDirection: 'row',
    background: colorGunmetal.toString(),
    transition: `box-shadow ${transitionQuickEase}`,
    $nest: {
      '& > div': {
        color: colorWhite.toString(),
        width: '50%',
      },
      '& > div:nth-child(2)': {
        textAlign: 'right',
      },
      '&:hover': shadowElevation2,
      '& .play-button': {
        position: 'absolute',
        bottom: px(16),
        right: px(16),
      },
    },
  }),
  img: style(shadowElevation2, {
    display: 'block',
    borderRadius: em(0.3),
    margin: px(8),
    width: 'calc(100% - 16px)',
  }),
  link: style(overlayLink, { color: 'inherit' }),
  info: style(flexRoot, vertical, betweenJustified, {
    height: '100%',
    padding: px(8),
    fontSize: px(10),
    $nest: {
      '& p': {
        margin: 0,
      },
      '& p span': {
        display: 'block',
        lineHeight: 'normal',
        fontSize: px(12),
      },
      '& .title': {
        fontWeight: 600,
      },
    },
  }),
  overlayContent: style({ $nest: { '& > *': { zIndex: zLabel } } }),
};

export type LargeTrackTileProps = { className?: string; track: TrackSchema };

@inject(deps)
@observer
export class LargeTrackTile extends Component<LargeTrackTileProps> {
  render() {
    const { children, className, track, model } = this.props;
    const artists = track.artists.map((artist) => artist.name).join(', ');
    return (
      <AnalyticsContextProvider
        context={{ trackIdentity: track.identity, trackTitle: track.title, trackArtist: artists }}
      >
        <div className={classes(styles.wrapper, className)} data-test-track-carousel={track.identity}>
          <div>
            <div className={styles.info}>
              <p>Released {toDateString(track.available_at)}</p>
              <p>
                <span>Single</span>
                <Link href={`/music/artists/${track.artists[0].slug}/track/${track.slug}`} className={styles.link}>
                  <span className="title">{track.title}</span>
                  <span>{artists}</span>
                </Link>
              </p>
              <span />
              {/*<p>Mood &#8226; Genre</p>*/}
            </div>
          </div>
          <div>
            <img className={styles.img} src={model.image.cover(track.images.identity)} />
          </div>
          <span className={styles.overlayContent}>{children}</span>
        </div>
      </AnalyticsContextProvider>
    );
  }
}
