import * as React from 'react';
import { classes, cssRaw, cssRule, media, style } from 'typestyle';
import { percent, px, url, viewHeight, viewWidth } from 'csx';
import { BaseMasthead } from './BaseMasthead';
import MobileMastheadJPG from '../../../../static/truant/truant-masthead-mobile.jpg';
import DesktopMastheadJPG from '../../../../static/truant/truant-masthead-desktop.jpg';
import MobileMastheadWEBP from '../../../../static/truant/truant-masthead-mobile.webp';
import DesktopMastheadWEBP from '../../../../static/truant/truant-masthead-desktop.webp';
import MastheadHeadingPNG from '../../../../static/truant/truant-masthead-text.png';
import MobileMastheadAVIF from '../../../../static/truant/truant-masthead-mobile.avif';
import DesktopMastheadAVIF from '../../../../static/truant/truant-masthead-desktop.avif';
import { betweenJustified, center, centerCenter, flexRoot, vertical } from 'csstips';
import { heading4, heading5, screenReaderOnly } from '../../utilities/font';
import { mediaLargeMobile, mediaUpToLargeMobile } from '../../utilities/mediaQueries';

const styles = {
  masthead: style(
    {
      height: viewWidth(110),
      maxHeight: px(512),
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    media(
      { maxWidth: px(999) },
      {
        backgroundImage: [
          `url('${MobileMastheadJPG}')`,
          `-webkit-image-set(
        url('${MobileMastheadAVIF}') type("image/avif"),
        url('${MobileMastheadWEBP}') type("image/webp"),
        url('${MobileMastheadJPG}') type("image/jpeg")
      )`,
          `image-set(
        url('${MobileMastheadAVIF}') type("image/avif"),
        url('${MobileMastheadWEBP}') type("image/webp"),
        url('${MobileMastheadJPG}') type("image/jpeg")
      )`,
        ],
      }
    ),
    media(
      { minWidth: px(1000) },
      {
        backgroundImage: [
          `url('${DesktopMastheadJPG}')`,
          `-webkit-image-set(
        url('${DesktopMastheadAVIF}') type("image/avif"),
        url('${DesktopMastheadWEBP}') type("image/webp"),
        url('${DesktopMastheadJPG}')  type("image/jpeg")
      )`,
          `image-set(
        url('${DesktopMastheadAVIF}') type("image/avif"),
        url('${DesktopMastheadWEBP}') type("image/webp"),
        url('${DesktopMastheadJPG}')  type("image/jpeg")
      )`,
        ],
      }
    )
  ),
  mastheadContent: style(flexRoot, vertical, center, betweenJustified, {
    paddingTop: px(48),
    paddingBottom: px(48),
  }),
  headingText: style(
    {
      backgroundImage: url(MastheadHeadingPNG),
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      display: 'block',
      width: percent(100),
      height: percent(100),
      flexGrow: 0,
      flexShrink: 0,
      margin: 0,
      $nest: {
        '& span': screenReaderOnly,
      },
    },
    mediaUpToLargeMobile({
      maxWidth: px(300),
      maxHeight: px(224),
    }),
    mediaLargeMobile({
      maxWidth: px(397),
      maxHeight: px(278),
    })
  ),
  subheadingText: style({
    maxWidth: px(480),
  }),
};

cssRule(
  `.${styles.masthead} h2.${styles.subheadingText}`,
  {
    fontWeight: 300,
  },
  mediaUpToLargeMobile({
    fontSize: viewWidth(5.5),
    letterSpacing: heading5.letterSpacing,
  }),
  mediaLargeMobile(heading5)
);

type MastheadProps = React.Component['props'] & {
  className?: string;
};

export const TruantMasthead: React.SFC<MastheadProps> = ({ className }) => (
  <BaseMasthead
    className={classes(className, styles.mastheadContent)}
    mastheadClassName={styles.masthead}
    dimBackground={false}
  >
    <h1 className={styles.headingText}>
      <span>Supercharge your content. Get Lickd</span>
    </h1>
    <h2 className={styles.subheadingText}>Welcome to the claims-free chart music platform for creators.</h2>
  </BaseMasthead>
);
