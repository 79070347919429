/* NOTE: Both lines are required due to current React version */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/no-string-refs */
import * as React from 'react';

import { classes, style } from 'typestyle';
import { StripeLogo } from '../../components/stripe-logo';

import { styles } from './styles';
import { formatCurrency } from '../../lib/currency';
import { BasketModel } from '../../modules/basket/model';
import { CheckoutModel } from '../../modules/checkout/model';
import { UserModel } from '../../modules/user/model';
import { CheckoutActions } from './checkout-actions';
import { CouponForm } from './coupon-form';
import { Component, deps, inject, observer } from '../../lib/component';
import { LOAD_STATE } from '../../types/api';
import { em, px } from 'csx';
import { flexRoot } from 'csstips';
import { rebrand } from '../../theme/color';
import { LabelledCheckbox } from '../project-happy/atoms/controls/LabelledCheckbox';
import { borderRadius } from '../../theme/border';
import { CheckoutCredits } from './CheckoutCredits';

const localStyles = {
  checkbox: style({
    marginTop: px(10),
    marginBottom: px(10),
  }),
  requiredText: style({
    color: rebrand.semantic.error[70].toString(),
    fontWeight: 700,
  }),
  tyntkLink: style({
    $nest: {
      [`.${styles.checkoutSummary} &`]: {
        color: 'inherit',
      },
    },
  }),
  savingsPrompt: style(flexRoot, {
    justifyContent: 'center',
    alignItems: 'center',
    gap: px(4),
    border: '1px solid',
    borderColor: rebrand.light1.toString(),
    borderRadius: borderRadius.MEDIUM,
    fontSize: px(12),
    padding: px(8),
    $nest: {
      '& strong': {
        color: rebrand.primary[100].toString(),
      },
      '+ *': {
        marginTop: px(8),
      },
    },
  }),
  savingsPromptText: style({
    margin: 0,
  }),
  savingsTooltip: style({
    maxWidth: px(134),
    margin: 0,
  }),
  savingsTooltipDiscount: style({ fontWeight: 'bold', textTransform: 'uppercase' }),
  savingsPaygIcon: style({ backgroundColor: rebrand.primary1.toString(), borderRadius: em(3) }),
};

interface CheckoutSummaryPanelProps {
  basketModel: BasketModel;
  userModel: UserModel;
  checkoutModel: CheckoutModel;
  eula: boolean;
  tyntk: boolean;
  isSubmitting: boolean;
  handleCheckout: (event: any, ref?: any) => void;
  handleCheckboxChange: (type: string, checked: boolean) => void;
  handleTYNTKRequest: () => void;
}

@inject(deps)
@observer
export class CheckoutSummaryPanel extends Component<CheckoutSummaryPanelProps> {
  summaryDiv: HTMLElement;

  constructor(props) {
    super(props);
    this.handleTYNTKRequest = this.handleTYNTKRequest.bind(this);
  }

  handleTYNTKRequest(event?: React.MouseEvent<any>) {
    if (event) {
      event.preventDefault();
    }
    this.props.controller.analytics.sendMixpanel('User clicks "Things you need to know" checkbox link');
    this.props.handleTYNTKRequest();
  }

  render() {
    const {
      basketModel,
      checkoutModel,
      eula,
      tyntk,
      handleCheckboxChange,
      handleCheckout,
      isSubmitting,
      model,
    } = this.props;

    const {
      checkout,
      user: userModel,
      ui: { projectSubscriptionEnabled },
    } = model;

    const { user, isSubscribed } = userModel;
    const { currency, total } = basketModel;

    const isOrderLoading = checkout.orderState === LOAD_STATE.LOADING;
    const invalidCoupon =
      checkout.orderState === LOAD_STATE.ERROR && checkout.globalError === "Sorry, this coupon doesn't exist.";
    const couponErrorText = invalidCoupon ? checkout.globalError : null;
    const couponTextStyle =
      checkout.discountAmount > 0 ? styles.couponSuccess : invalidCoupon ? styles.couponError : styles.couponInert;

    return (
      <div className={styles.checkoutSummary} ref={(ref) => (this.summaryDiv = ref)}>
        <div className={classes(styles.checkoutSummaryItem, style({ textAlign: 'left' }))}>
          <div className={styles.checkoutSummaryItemInner}>
            <LabelledCheckbox
              className={localStyles.checkbox}
              checked={eula}
              onChange={() => handleCheckboxChange('eula', eula)}
              data-test-checkout-licence-agreement-checkbox
              required={true}
            >
              I have read the <strong>License Agreement</strong> for each track
              <span className={localStyles.requiredText} aria-hidden="true">
                *
              </span>
            </LabelledCheckbox>
            <LabelledCheckbox
              className={localStyles.checkbox}
              checked={tyntk}
              onChange={() => handleCheckboxChange('tyntk', tyntk)}
              data-test-checkout-tyntk-checkbox
              required={true}
            >
              I have read the{' '}
              <strong>
                <a className={localStyles.tyntkLink} href="#" onClick={this.handleTYNTKRequest}>
                  Things you need to know
                </a>
              </strong>{' '}
              for each track
              <span className={localStyles.requiredText} aria-hidden="true">
                *
              </span>
            </LabelledCheckbox>
          </div>
        </div>

        {checkout.applyCoupon && (
          <div className={styles.checkoutSummaryItem}>
            <div className={styles.checkoutSummaryItemInner}>
              <h3 className={styles.sectionHeading}>Coupons</h3>
              <CouponForm />

              {!isOrderLoading && (
                <div className={classes(styles.couponMessage, couponTextStyle)}>
                  {couponErrorText && <span className={styles.smallText}>{couponErrorText}</span>}
                  <span className={classes(styles.totalText, styles.smallText, 'coupon-discount-amount')}>
                    -{formatCurrency(checkoutModel.discountAmount, checkoutModel.currency)}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}

        {/* NEW CREDITS SECTION - **PROJECT_SUB_FLAG** */}
        {projectSubscriptionEnabled && (
          <div data-test-credits-summary className={styles.checkoutSummaryItem}>
            <div className={styles.checkoutSummaryItemInner}>
              <h3 className={styles.sectionHeading}>Premium track credits</h3>

              <CheckoutCredits data-test-credits-available={checkout.creditsToUse} creditsApplied={checkout.creditsToUse} creditsAvailable={checkout.availableCredits} />
            </div>
          </div>
        )}

        <div className={classes(styles.checkoutSummaryItem, styles.checkoutSummaryActions)}>
          <div className={styles.checkoutSummaryItemInner}>
            <CheckoutActions
              termsAccepted={eula && tyntk}
              isSubmitting={isSubmitting}
              handleCheckout={handleCheckout}
            />

            {checkout.orderState === LOAD_STATE.ERROR && !invalidCoupon ? (
              <span className={styles.errorMessage}>{checkout.globalError || 'An unknown error occurred'}</span>
            ) : null}

            <div className={styles.stripeLogo}>
              <StripeLogo type="dark" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
