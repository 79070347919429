import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { contentWidth, desktopContentWidth, maxPageWidth } from '../../config';
import { percent, px } from 'csx';
import { CSSProperties } from 'typestyle/lib/types';
import { mediaDesktop } from '../../utilities/mediaQueries';

export const GradientBackgroundStyles: CSSProperties = {
  color: 'white',
  background: 'linear-gradient(to right, #410067, #FF056D)',
};

const styles = {
  wrapper: style({
    display: 'block',
  }),
  wrapperWithGradient: style(GradientBackgroundStyles),
  content: style(
    {
      marginLeft: px(16),
      marginRight: px(16),
    },
    mediaDesktop({
      width: px(desktopContentWidth),
      marginLeft: 'auto',
      marginRight: 'auto',
    })
  ),
};

export type ContentSliceProps = React.Component['props'] & {
  className?: string;
  contentClassName?: string;
  withGradient?: boolean;
};

export const ContentSlice: React.SFC<ContentSliceProps> = ({ children, className, contentClassName, withGradient }) => (
  <div
    className={classes(
      styles.wrapper,
      withGradient && 'with-gradient',
      withGradient === true && styles.wrapperWithGradient,
      className
    )}
  >
    <div className={classes(styles.content, contentClassName)}>{children}</div>
  </div>
);
