import * as React from 'react';
import { Pill } from './pill';
import { CSSProperties } from 'typestyle/lib/types';
import { centerCenter, inlineRoot } from 'csstips';
import { percent, px } from 'csx';
import { classes, style } from 'typestyle';
import { colorBrand, colorWhite } from '../theme/color';
import { mediaTablet, mediaMobileOnly } from '../theme/media';
import {ButtonPill} from './button-pill';

export interface PageHomePricingCTAProps {
  className?: string;
  onSignUp(event: React.MouseEvent<any>): void;
  i18n?: {
    label: string;
  }
}

export const PageHomePricingCTAPillStyle = style(
  {
    fontSize: px(20),
  },
  mediaMobileOnly({
    width: percent(100),
  })
);

const DEFAULT_I18N = {
  label: 'Sign up now'
};

export const PageHomePricingCTA: React.SFC<PageHomePricingCTAProps> = ({ i18n = DEFAULT_I18N, onSignUp, className }) => (
  <div className={className}>
    <ButtonPill
      className={PageHomePricingCTAPillStyle}
      primary={true}
      onClick={onSignUp}
      children={i18n.label}
    />
  </div>
);