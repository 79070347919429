import { PlaylistModel } from './model';
import { APIController } from '../api/controller';
import { action, IReactionDisposer, observable, reaction, runInAction } from 'mobx';
import { EnvModel } from '../env/model';
import { UserModel } from '../user/model';
import { BugsnagController } from '../bugsnag/controller';
import { ClientController } from '../client/controller';
import { notify } from '../../components/project-happy/organisms/Notifications';
import siteEvents, { SITE_EVENTS } from '../../components/project-happy/utilities/siteEvents';
import { PrismicDataDrivenTypes } from '../../components/project-happy/utilities/types';
import { UserController } from '../user/controller';

export class PlaylistController {
  constructor(
    private model: PlaylistModel,
    private env: EnvModel,
    private user: UserModel,
    private client: ClientController,
    private api: APIController,
    private bugsnag: BugsnagController,
    private userController: UserController
  ) {
    this._disposers = [];

    this.model.sync[this.env.playlistFavourites] = observable([]);
    this.model.tracks[this.env.playlistFavourites] = observable([]);
    this.model.removed[this.env.playlistFavourites] = observable([]);

    this._disposers.push(
      reaction(
        () => this.user.favouriteTracks,
        (favouriteTracks) => {
          runInAction(() => {
            this.model.tracks[this.env.playlistFavourites].splice(
              0,
              this.model.tracks[this.env.playlistFavourites].length,
              ...favouriteTracks.map((t) => t.identity)
            );
          });
        }
      )
    );

    this._disposers.push(
      reaction(
        () => this.user.favouritePlaylists,
        (favouritePlaylists) => {
          runInAction(() => {
            this.model.favouritePlaylists = favouritePlaylists.map((playlist) => playlist.uuid);
          });
        }
      )
    );
  }

  private _disposers: IReactionDisposer[];

  dispose = () => {
    this._disposers.forEach((dispose) => dispose());
  };

  @action
  addToPlaylist = async (playlist: string, track: string) => {
    if (!this.user.user) return;

    this.setSync(playlist, track, true);

    try {
      await this.api.playlist.addTrackToUserPlaylist(this.user.user.identity, playlist, track);

      this.setActive(playlist, track, true);
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      this.setSync(playlist, track, false);
    }
  };

  @action
  removeFromPlaylist = async (playlist: string, track: string) => {
    if (!this.user.user) return;

    this.setSync(playlist, track, true);

    try {
      await this.api.playlist.removeTrackFromUserPlaylist(this.user.user.identity, playlist, track);

      this.setActive(playlist, track, false);
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      this.setSync(playlist, track, false);
    }
  };

  @action
  favourite = async (track: string) => {
    await this.addToPlaylist(this.env.playlistFavourites, track);
    siteEvents.emit(SITE_EVENTS.FAVOURITE, { type: PrismicDataDrivenTypes.TRACKS, id: track });
    await this.userController.fetchFavouriteTracks();
  };

  @action
  async favouritePlaylist(playlist: string) {
    await this.api.playlist.favouritePlaylist(this.user.user.identity, playlist);
    siteEvents.emit(SITE_EVENTS.FAVOURITE, { type: PrismicDataDrivenTypes.PLAYLISTS, id: playlist });

    if (this.user.user) {
      notify({
        title: 'Playlist added to your Favorites',
      });
    }

    await this.userController.fetchFavouritePlaylists();
  }

  @action
  unfavourite = async (track: string) => {
    await this.removeFromPlaylist(this.env.playlistFavourites, track);
    siteEvents.emit(SITE_EVENTS.UNFAVOURITE, { type: PrismicDataDrivenTypes.TRACKS, id: track });
    await this.userController.fetchFavouriteTracks();
  };

  @action
  async unfavouritePlaylist(playlist: string) {
    await this.api.playlist.unfavouritePlaylist(this.user.user.identity, playlist);
    siteEvents.emit(SITE_EVENTS.UNFAVOURITE, { type: PrismicDataDrivenTypes.PLAYLISTS, id: playlist });
    if (this.user.user) {
      notify({
        title: 'Playlist removed from your Favorites',
      });
    }

    await this.userController.fetchFavouritePlaylists();
  }

  @action
  private setSync = (playlist: string, track: string, sync: boolean) => {
    if (!this.model.sync[playlist]) {
      this.model.sync[playlist] = observable([]);
    }

    if (sync) {
      this.model.sync[playlist].push(track);
    } else {
      const index = this.model.sync[playlist].indexOf(track);

      if (index !== -1) {
        this.model.sync[playlist].splice(index, 1);
      }
    }
  };

  @action
  private setActive = (playlist: string, track: string, active: boolean) => {
    if (!this.model.tracks[playlist]) {
      this.model.tracks[playlist] = observable([]);
    }

    if (!this.model.removed[playlist]) {
      this.model.removed[playlist] = observable([]);
    }

    if (active) {
      this.model.tracks[playlist].push(track);

      const index = this.model.removed[playlist].indexOf(track);

      if (index !== -1) {
        this.model.removed[playlist].splice(index, 1);
      }
    } else {
      this.model.removed[playlist].push(track);

      const index = this.model.tracks[playlist].indexOf(track);

      if (index !== -1) {
        this.model.tracks[playlist].splice(index, 1);
      }
    }
  };
}
