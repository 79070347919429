/* eslint-disable react/no-children-prop */
import * as React from 'react';
import { Component, deps, inject, observer } from '../lib/component';
import { PlayButtonCompat } from '../components/compat/play-button-compat';
import { Loading } from '../components/loading/loading';
import { ContainerQuery } from 'react-container-query';
import { AccountInvitesCompat } from '../components/compat/account-invites-compat';
import { PageCheckoutPanels } from '../components/page-checkout-panels';
import { PageCheckoutContentStep1 } from '../components/page-checkout-content-step-1';
import { PageCheckoutContentStep2 } from '../components/page-checkout-content-step-2';
import { PageCheckoutContentStep3 } from '../components/page-checkout-content-step-3';
import { PageCheckoutContentStep0 } from '../components/page-checkout-content-step-0';
import { PageCheckoutPanelStep0 } from '../components/page-checkout-panel-step-0';
import { PageCheckoutPanelStep2 } from '../components/page-checkout-panel-step-2';
import { noop } from '../lib/noop';
import MainWrapper from '../components/main-wrapper';
import { style } from 'typestyle';
import { centerCenter, vertical } from 'csstips';
import { rem } from 'csx';
import LabelModal from '../components/label-modal';
import { ClientController } from '../modules/client/controller';
import { ClientModel } from '../modules/client/model';

export interface CheckoutContainerProps {
  model: ClientModel;
  controller: ClientController;
}

@inject(deps)
@observer
export class CheckoutContainer extends Component<CheckoutContainerProps> {
  constructor(props) {
    super(props);

    this.state = {
      labelCopyrightModalAccepted: false,
      showModal: false,
      continueOrder: false,
    };
  }

  render() {
    const { model, controller } = this.props;

    if (model.coupon.loading)
      return (
        <div className={CheckoutContainer.styles.loading}>
          <Loading />
        </div>
      );

    // Dereference to enable reactivity
    const { step, channel } = model.checkout;

    const ratecard = model.user && model.user.channel && model.user.channel.ratecard;
    return (
      <ContainerQuery
        query={CheckoutContainer.query}
        children={(params: any = CheckoutContainer.defaultQuery) => (
          <MainWrapper>
            <PageCheckoutPanels
              model={model.checkout}
              narrow={params.narrow}
              PlayButton={PlayButtonCompat}
              onContinue={!channel ? controller.onboarding.goToSignUpPage : this.handleContinue}
              onCancel={controller.checkout.cancelOrder}
              left={this.getStep(step)}
              right={this.getPanel(step)}
            />
            {model.checkout.hasUmgContent && (
              <LabelModal
                analytics={this.props.controller.analytics}
                show={this.state.showModal}
                tracks={model.checkout.tracks}
                onRequestClose={this.handleModalClose}
                handleModalAccept={this.handleModalAccept}
                ratecard={ratecard}
              />
            )}
          </MainWrapper>
        )}
      />
    );
  }

  getStep = (step: number) => {
    const { checkout } = this.props.model;
    // This component is now only used for displaying downloads
    return <PageCheckoutContentStep3 model={checkout} />;
  };

  // This component is now only used for displaying downloads
  getPanel = (step: number) => null;

  handleLabelCopyrightClick = () => {
    this.setState({
      showModal: true,
    });
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  handleModalAccept = () => {
    this.setState({
      showModal: false,
      labelCopyrightModalAccepted: true,
    });

    if (this.state.continueOrder) {
      this.props.controller.checkout.continueOrder();
    }
  };

  handleContinue = () => {
    const { model } = this.props;

    if (model.checkout.hasUmgContent && !this.state.labelCopyrightModalAccepted) {
      this.setState({
        showModal: true,
      });

      this.props.controller.analytics.sendMixpanel('UMG checkout popup');
      return;
    }

    this.props.controller.checkout.continueOrder();
  };

  static query = {
    narrow: {
      maxWidth: 960,
    },
  };

  static defaultQuery = {
    narrow: false,
  };

  static styles = {
    loading: style({
      ...centerCenter,
      minHeight: rem(32),
    }),
    panel: style({
      ...vertical,
      flex: 1,
      $nest: {
        '& h5': {
          margin: '0 0 1rem 0',
        },
      },
    }),
  };
}
