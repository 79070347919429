import { MailchimpModel, MailchimpFields } from './model';
import { action } from 'mobx';
import { AuthModel } from '../auth/model';

export class MailchimpController {
  constructor(private model: MailchimpModel, private auth: AuthModel) {}

  @action
  reset = (fields: MailchimpFields = {}) => {
    this.model.email = this.auth.user ? this.auth.user.email : '';
    this.model.fields = fields;
  };
}