import React, { Children } from 'react';
import { classes, style } from 'typestyle';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { rebrand } from '../../../theme/color';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';

const styles = {
  rightPanel: style(mediaDesktop({ flex: 1 }), mediaUpToDesktop({ height: '200px' }), {
    backgroundColor: rebrand.contrast[70].toHexString(),
  }),
};

export type PanelProps = {
  className?: string;
};

export const RightHandOnboardContentPanel = inject(deps)(
  observer(
    ({
      children,
      model: {
        user: { channel },
      },
      className,
    }: React.PropsWithChildren<ComponentProps & PanelProps>) => {
      return <div className={classes(styles.rightPanel, className)}>{children}</div>;
    }
  )
);
