import * as React from 'react';
import { classes, keyframes, style } from 'typestyle';
import { percent, px } from 'csx';
import { betweenJustified, center, centerCenter, endJustified, flexRoot, horizontal } from 'csstips';
import { RatecardStatisticsSchema } from '../../../types/schema';
import { rebrand } from '../../../theme/color';
import { CSSProperties } from 'typestyle/lib/types';
import { abbreviateNumber } from '../utilities/numbers';

const keyframesProgressBarFill = keyframes({
  '0%': {
    transform: 'translateX(-100%)',
  },
  '100%': {
    transform: 'translateX(0)',
  },
});

const animationProgressBarFill: CSSProperties = {
  animationName: keyframesProgressBarFill,
  animationDuration: '1s',
  animationIterationCount: 1,
  animationTimingFunction: 'ease-in-out',
};

const styles = {
  wrapper: style({
    fontSize: px(10),
    lineHeight: px(14),
    color: rebrand.neutralOnLight['80'].toString(),
  }),
  progressBar: style(flexRoot, center, betweenJustified, {
    margin: '0 16px',
    color: rebrand.contrast['100'].toString(),
  }),
  outerBar: style({
    position: 'relative',
    height: px(8),
    width: 'calc(100% - 40px)',
    borderRadius: px(50),
    margin: '0 12px',
    background: rebrand.contrast['20'].toString(),
    overflow: 'hidden',
  }),
  innerBar: style(animationProgressBarFill, {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    borderRadius: px(50),
    minWidth: px(15),
    transition: 'width 0.4s ease-in',
    background: rebrand.contrast['50'].toString(),
  }),
  text: style(centerCenter),
};

export interface RatecardGaugeProps {
  ratecardStatistics: RatecardStatisticsSchema;
  className?: string;
}

type Rate = { from: number; to: number; fromLabel?: string; toLabel?: string };
const RATES: Rate[] = [
  { from: 0, to: 50000 },
  { from: 50001, to: 100000 },
  { from: 100001, to: 200000 },
  { from: 200001, to: 350000 },
  { from: 350001, to: 500000 },
  { from: 500001, to: 750000 },
  { from: 750001, to: 1000000 },
  { from: 1000001, to: 2000000 },
  { from: 2000001, to: 90000000, toLabel: '∞' },
];

export const RatecardProgressBar: React.FC<RatecardGaugeProps> = ({ className, ratecardStatistics }) => {
  if (!ratecardStatistics) return null;
  const { video_count, average_views, view_count } = ratecardStatistics;
  const rateIndex = RATES.findIndex((r) => average_views >= r.from && average_views <= r.to);
  const rate = RATES[rateIndex];
  let progress = 0;
  if (rate) {
    progress = (100 * (average_views - rate.from)) / (rate.to - rate.from);
  }
  return (
    <div className={classes(styles.wrapper, className)}>
      <div className={styles.progressBar}>
        {rate.fromLabel || abbreviateNumber(rate.from)}
        <div className={styles.outerBar}>
          <div className={styles.innerBar} style={{ width: percent(progress) }} />
        </div>
        {rate.toLabel || abbreviateNumber(rate.to)}
      </div>
      <div className={styles.text}>YouTube average video views</div>
    </div>
  );
};
