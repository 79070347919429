import { AuthModel } from '../auth/model';
import { computed, observable } from 'mobx';
import { EnvModel } from '../env/model';
import { AccountsAPI } from '../../types/api';
import { User } from '../../types/user';
import {
  ArtistSchema,
  ChannelSchema,
  CouponSchema,
  CreditSchema,
  PlaylistGroupPlaylistSchema,
  PlaylistSchema,
  RatecardSchema,
  TrackSchema,
} from '../../types/schema';
import { isEmpty } from '../../components/project-happy/utilities/objects';
import { isOngoingSubscription, SubscriptionStates } from '../../components/project-happy/utilities/types';
import { PricingModel } from '../pricing/model';
import { PaginationMeta } from '../../types';

export class UserModel {
  constructor(private auth: AuthModel, private env: EnvModel, private pricing: PricingModel) {}

  @observable loadingAccounts = true;

  @observable accounts: AccountsAPI = {
    dropbox: [],
    twitter: [],
    facebook: [],
    spotify: [],
    youtube: [],
  };

  @observable isUnlinking = {
    spotify: false,
    twitter: false,
    dropbox: false,
    facebook: false,
  };

  @observable
  loading = true;

  @observable
  loadingChannels = true;

  @observable
  loadingSubscriptions = true;

  @computed
  get user(): User {
    return this.auth.user;
  }

  @computed
  get ready(): Promise<void> {
    return this.auth.userReady;
  }

  @observable channel: ChannelSchema = null;

  @observable
  activeSubscription = null;

  @observable subscriptions = [];
  @computed
  get unsubscribedRatecard(): RatecardSchema {
    if (isEmpty(this.pricing.unsubscribedRates)) return null;
    if (isEmpty(this.channel)) {
      return this.pricing.unsubscribedRates.currency.USD[0] || null;
    }
    if (!this.channel.is_subscribed) return this.channel.ratecard;
    const { currency, viewership: userViewership } = this.channel.ratecard;
    return this.pricing.unsubscribedRates.currency[currency].find(({ viewership }) => viewership === userViewership);
  }

  @computed
  get subscribedRatecard(): RatecardSchema {
    if (isEmpty(this.pricing.subscribedRates)) return null;
    if (isEmpty(this.channel)) {
      return this.pricing.subscribedRates.currency.USD[0] || null;
    }
    if (this.channel.is_subscribed) return this.channel.ratecard;
    const { currency, viewership: userViewership } = this.channel.ratecard;
    return this.pricing.subscribedRates.currency[currency].find(({ viewership }) => viewership === userViewership);
  }

  @computed
  get subscriptionStatus(): SubscriptionStates {
    return (!isEmpty(this.channel) && this.channel.subscription_status) || SubscriptionStates.UNKNOWN;
  }

  // Whether the currently selected channel is subscribed
  @computed
  get isSubscribed(): boolean {
    return !isEmpty(this.channel) && this.channel.is_subscribed === true;
  }

  @computed
  get isSubscribedWithoutChannel(): boolean {
    return !isEmpty(this.user) && isEmpty(this.channel) && this.user.is_subscribed === true;
  }

  // Whether the currently selected channel has an active, ongoing subscription
  @computed
  get isSubscribedAndOngoing(): boolean {
    return this.isSubscribed && isOngoingSubscription(this.channel.subscription_status);
  }

  // Whether the currently selected channel has an active, but cancelled subscription
  @computed
  get isSubscribedAndCancelled(): boolean {
    return this.isSubscribed && !isOngoingSubscription(this.channel.subscription_status);
  }

  @computed
  get subscriptionType(): string | null {
    if (!this.isSubscribed && !this.isSubscribedWithoutChannel) return null;

    if (this.isSubscribedWithoutChannel || (this.channel && this.channel.subscription === null)) return 'Creator Plan';

    return `${this.channel.subscription.plan_name} Plan`;
  }

  @computed
  get isSubscribedToLegacyPlan(): boolean {
    return this.isSubscribed && this.channel.subscription === null;
  }

  @computed
  get isSubscribedToV2Plan(): boolean {
    return this.isSubscribed && this.channel.subscription !== null;
  }

  @computed
  get isSubscribedToV2PlanAndCancelled(): boolean {
    return this.isSubscribedToV2Plan && this.channel.subscription_status === SubscriptionStates.CANCELLED;
  }

  @observable channels: Array<ChannelSchema> = [];

  // Whether the user has any active subscriptions (ongoing or otherwise) across their linked channels
  @computed
  get hasSubscriptions(): boolean {
    return this.channels.some((channel) => channel.is_subscribed);
  }

  // Whether the user has any active and ongoing subscriptions across their linked channels
  @computed
  get hasOngoingSubscriptions(): boolean {
    return this.channels.some((channel) => isOngoingSubscription(channel.subscription_status));
  }

  @observable
  loadingPlaylists = false;

  @observable
  playlists: Array<PlaylistSchema> = [];

  @observable
  playlistPagination: PaginationMeta = {
    count: 0,
    current_page: 0,
    per_page: 25,
    total: 0,
    total_pages: 0,
  };

  @observable
  recommendedPlaylist: TrackSchema[] = [];

  @observable
  recommendedPlaylistSlug: string;

  @observable
  favouriteTracks: TrackSchema[] = [];

  @observable
  favouritePlaylists: PlaylistGroupPlaylistSchema[] = [];

  @observable
  favouriteArtists: ArtistSchema[] = [];

  /** All coupons assigned to the user */
  @observable
  coupons: CouponSchema[] = [];

  /** All credits assigned to the user */
  @observable
  credits: CreditSchema[] = [];

  /** Credits assigned to the current channel */
  @computed
  get currentChannelCredits(): CreditSchema[] {
    if (!this.channel) return [];

    return this.credits
      .filter((credit) => credit.youtube_channel_id === this.channel.id)
      .sort((a, b) => new Date(a.expires_at).getTime() - new Date(b.expires_at).getTime());
  }

  @computed get spotify() {
    const isLinked = this.accounts && this.accounts.spotify && this.accounts.spotify.length > 0;

    return {
      isLinked,
      name: isLinked ? this.accounts.spotify[0].name : null,
      linkURL: this.auth.spotifyLoginURL,
      isUnlinking: this.isUnlinking.spotify,
    };
  }

  @computed get twitter() {
    const isLinked = this.accounts && this.accounts.twitter && this.accounts.twitter.length > 0;

    return {
      isLinked,
      name: isLinked ? this.accounts.twitter[0].name : null,
      linkURL: this.auth.twitterLoginURL,
      isUnlinking: this.isUnlinking.twitter,
    };
  }

  @computed get dropbox() {
    const isLinked = this.accounts && this.accounts.dropbox && this.accounts.dropbox.length > 0;

    return {
      isLinked,
      name: isLinked ? this.accounts.dropbox[0].name : null,
      linkURL: this.auth.dropboxLoginURL,
      isUnlinking: this.isUnlinking.dropbox,
    };
  }

  @computed get facebook() {
    const isLinked = this.accounts && this.accounts.facebook && this.accounts.facebook.length > 0;

    return {
      isLinked,
      name: isLinked ? this.accounts.facebook[0].name : null,
      linkURL: this.auth.facebookLoginURL,
      isUnlinking: this.isUnlinking.facebook,
    };
  }

  @computed get referralURL() {
    if (!this.auth.user) return '';

    return `${this.env.baseUrl}/r/${this.auth.user.identity}`;
  }

  @computed get referralTitle() {
    const user = this.auth.user || this.channel;
    if (!user) return '';

    return `${user.name} would like to invite you to get your 1st track free on Lickd!`;
  }
}
