import { action, autorun, IReactionDisposer, runInAction } from 'mobx';
import { RequestController } from '../request/controller';
import { TOKEN, AuthModel, USER } from './model';
import { BasketModel } from '../basket/model';
import { User } from '../../types/user';
import { EnvModel } from '../env/model';
import Cookies from 'js-cookie';
import { isEmpty } from '../../components/project-happy/utilities/objects';
import { SESSION_LAST_ROUTE } from '../../constants';

export class AuthController {
  constructor(
    private model: AuthModel,
    private basket: BasketModel,
    private env: EnvModel,
    private request: RequestController
  ) {
    this._disposers = [];

    if (!this.env.isServer) {
      if (this.model.token) {
        /*
         * In April 2022, we lost UTM data for new user sign ups, in WEB-3954 we decided to send up any UTM cookies that
         * still exist in an attempt to recover some of this data.
         *
         * Notes:
         * - We purposely use Promise syntax as this operation is the only thing that needs to wait for the session call
         * - We purposely ignore the result of the update call as we don't care about it
         * - We will make the call if any of the three UTM cookies is populated
         * */
        this.getSession().then(() => {
          const source = Cookies.get('utm_source');
          const medium = Cookies.get('utm_medium');
          const campaign = Cookies.get('utm_campaign');
          if (source || medium || campaign) {
            request.post({ url: '/user/utm', body: JSON.stringify({ source, medium, campaign }) });
          }
        });
      } else {
        this.model.hasFetched = true;
      }

      this.model.hasBetaInvite = !!document.cookie
        .split(';')
        .map((part) => part.trim().split('='))
        .find((part) => part[0] === 'invite');

      this._disposers.push(
        autorun(() => {
          if (this.model.user && this.model.mustReAuth) {
            this.logout(false);
          }
        })
      );

      this._disposers.push(
        autorun(() => {
          if (!isEmpty(this.model.user)) {
            window.sessionStorage.setItem(USER, JSON.stringify(this.model.user));
          } else {
            window.sessionStorage.removeItem(USER);
          }
        })
      );
    }
  }

  private _disposers: IReactionDisposer[];

  dispose = () => {
    this._disposers.forEach((dispose) => dispose());
  };

  @action
  logout = (redirect = true, showReAuthModal = false, autoReauth = false, routeHome?: boolean): void => {
    document.cookie = `${TOKEN}=;path=/;domain=${this.env.rootDomain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    this.model.user = null;
    this.basket.identity = null;
    this.basket.tracks = [];
    window.localStorage.removeItem('trackLogin');
    window.sessionStorage.removeItem(SESSION_LAST_ROUTE);

    if (routeHome) {
      window.location.href = '/';
    }

    if (redirect && window && window.location && window.location.reload) {
      window.location.reload();
      return;
    }

    if (showReAuthModal) {
      this.model.mustReAuth = true;
    }

    if (autoReauth && window && window.location && window.location.reload) {
      window.location.href = this.model.youtubeLoginURL;
    }
  };

  @action
  getSession = async () => {
    try {
      const resp = await this.request.get({ url: '/user/session' });

      if (resp.status >= 400) {
        return this.logout(false, true);
      }

      const { data } = await resp.json();
      this.setUser(data);
      runInAction(() => (this.model.hasFetched = true));
    } catch (e) {
      this.logout(false);
      runInAction(() => (this.model.hasFetched = false));
    } finally {
      this.model.signalUserReady();
    }
  };

  @action
  setUser = (user: User) => {
    this.model.user = user;
  };
}
