import { computed, observable, ObservableMap } from 'mobx';
import {
  ReleaseSchema,
  AggregatedTagSchema,
  PlaylistSchema,
  PlaylistGroupSchema,
  TrackSchema,
  PlaylistGroupPlaylistSchema,
} from '../../../types/schema';
import { UIBrowseSection, UIHeroArtist } from '../../../types/ui';
import { PaginationInput } from '../../../types';
import slugToSentenceCase from '../../../lib/slugToSentenceCase';
import { APIError, APIPaginatedResponse } from '../../api/types';
import { CatalogueDataArray, PrismicSlice, SlicePageContext } from '../../../components/project-happy/utilities/types';

export interface BrowseState {
  options: {
    type?: string;
    slug: string;
  };
  related: {
    type: string;
    query?: string;
  };
  pagination: {
    size: number | null;
    from: number | null;
    page?: number | null;
  };
}

export const PLAYLIST_PAGE_FEATURED_PLAYLISTS_SLUG = 'featured-playlists';

export class BrowsePageModel {
  @observable prismicPageTitle: string = null;
  @observable slices: PrismicSlice[] = [];
  @observable slicePlaylists: ObservableMap<string, CatalogueDataArray> = observable.map();
  @observable sliceTracks: ObservableMap<string, TrackSchema> = observable.map();
  @computed
  get sliceContext(): SlicePageContext {
    return {
      playlists: this.slicePlaylists,
      tracks: this.sliceTracks,
      carousels: { forceIndicatorPosition: 'centre' },
      externalDataLoading: this.loading,
    };
  }
  @observable loadedDocument: string = null;

  @observable loading = true;
  @observable loadedInitial = false;

  @observable playlistTracks: ObservableMap<string, APIPaginatedResponse<TrackSchema[]>> = observable.map();
  @observable playlistMetadata: ObservableMap<string, PlaylistSchema> = observable.map();

  @observable sections: Array<UIBrowseSection> = [];
  @observable playlists: Array<PlaylistSchema> = [];
  @observable genres: Array<AggregatedTagSchema> = [];
  @observable themes: Array<AggregatedTagSchema> = [];
  @observable moods: Array<AggregatedTagSchema> = [];
  @observable releases: Array<ReleaseSchema> = [];
  @observable playlistGroups: ObservableMap<string, PlaylistGroupSchema | APIError>;

  @computed
  get playlistPageFeaturedPlaylists(): PlaylistGroupPlaylistSchema[] {
    if (this.playlistGroups.has(PLAYLIST_PAGE_FEATURED_PLAYLISTS_SLUG)) {
      const featuredPlaylistsGroup = this.playlistGroups.get(PLAYLIST_PAGE_FEATURED_PLAYLISTS_SLUG);
      if (!(featuredPlaylistsGroup instanceof APIError)) {
        return featuredPlaylistsGroup.playlists.slice(0, 6);
      }
    }
    return null;
  }

  @observable heroArtists: Array<UIHeroArtist> = [];

  @computed get heroCarousel() {
    return {
      loading: false,
      title: this.title,
      artists: this.heroArtists,
    };
  }

  @computed get isCollection() {
    return this.slug && this.type === 'collection';
  }

  @computed get isTag() {
    return this.slug && (this.type === 'genre' || this.type === 'theme' || this.type === 'mood');
  }

  @computed get isRightsholder() {
    return this.slug && this.type === 'labels';
  }

  @computed get isFilter() {
    return this.slug && this.type === 'filter';
  }

  @computed get title() {
    switch (true) {
      case !!this.slug && !!this.type:
        return this.currentLabel;
      case !!this.type:
        return this.typeLabel;
      default:
        return 'Browse';
    }
  }

  @computed get pagination(): PaginationInput {
    return {
      size: this.type === 'random' ? 24 : 72,
      from: 0,
      page: 1,
    };
  }

  @observable type: string = null;
  @observable slug: string = null;
  @observable from = 0;
  @observable size = 25;
  @observable page = 1;

  @computed get typeLabel(): string {
    if (!this.type) return '';

    const section = this.sections.find((s) => s.slug === this.type);

    if (!section) return slugToSentenceCase(this.type);

    return section.label;
  }

  @computed get browseState(): BrowseState {
    const options = {
      slug: this.slug,
    };

    const related = {
      type: 'PLAYLIST_COLLECTION',
      pagination: {
        size: 24,
        from: 0,
      },
    };

    if (this.isTag) {
      options['type'] = this.type.toUpperCase();
      related['type'] = 'TAG';
      related['query'] = `hookd_${this.type}`;
    }

    if (this.isRightsholder) {
      related['type'] = 'RELEASE_RIGHTSHOLDER';
      related['query'] = this.slug;
      delete options['type'];
    }

    const pagination = {
      size: this.size,
      from: this.from,
      page: this.page,
    };

    return { options, related, pagination };
  }

  @observable selectedStaffPick: string = null;
  @observable selectedGenre: string = null;
  @observable selectedTheme: string = null;

  @computed get staffPick(): PlaylistSchema {
    return this.playlists.find((p) => p.slug === this.selectedStaffPick);
  }

  @computed get genre(): AggregatedTagSchema {
    return this.genres.find((p) => p.slug === this.selectedGenre);
  }

  @computed get theme(): AggregatedTagSchema {
    return this.themes.find((p) => p.slug === this.selectedTheme);
  }

  path = (prefix: string, slug: string, page = 1) => `${prefix}${slug}${page > 1 ? `/${page}` : ''}`;

  collectionPath = this.path.bind(this, '/browse/collection/');
  genrePath = this.path.bind(this, '/browse/genre/');
  themePath = this.path.bind(this, '/browse/theme/');
  moodPath = this.path.bind(this, '/browse/mood/');
  rightsholderPath = this.path.bind(this, '/browse/labels/');
  filterPath = this.path.bind(this, '/browse/filter/');
  artistPath = this.path.bind(this, '/music/artists/');

  activePath = (page: number) => {
    switch (this.type) {
      case 'genre':
        return this.genrePath(this.slug, page);
      case 'theme':
        return this.themePath(this.slug, page);
      case 'collection':
        return this.collectionPath(this.slug, page);
      case 'mood':
        return this.moodPath(this.slug, page);
      case 'labels':
        return this.rightsholderPath(this.slug, page);
      case 'filter':
        return this.filterPath(this.slug, page);

      default:
        return '';
    }
  };

  makePath = (slug: string) => {
    switch (this.type) {
      case 'genre':
        return this.genrePath(slug);
      case 'theme':
        return this.themePath(slug);
      case 'collection':
        return this.collectionPath(slug);
      case 'mood':
        return this.moodPath(slug);
      case 'labels':
        return this.rightsholderPath(slug);
      case 'filter':
        return this.filterPath(slug);

      default:
        return '';
    }
  };

  makeRelatedPath = (path: string) => {
    if (this.isRightsholder) return this.artistPath(path);

    return this.makePath(path);
  };

  @computed get breadcrumbs() {
    return `${this.type ? this.type.slice(0, 1).toUpperCase() + this.type.slice(1) : ''}${
      this.currentLabel ? ` > ${this.currentLabel}` : ''
    }`;
  }

  @computed get relatedLabel() {
    if (this.isRightsholder) return `Releases`;

    return `More ${this.typeLabel.toLowerCase()}`;
  }

  @computed get currentLabel() {
    switch (this.type) {
      case 'genre': {
        const genre = this.genres.find((g) => g.slug === this.slug);
        return genre ? genre.tag : slugToSentenceCase(this.slug);
      }

      case 'theme': {
        const theme = this.themes.find((t) => t.slug === this.slug);
        return theme ? theme.tag : slugToSentenceCase(this.slug);
      }

      default:
        return slugToSentenceCase(this.slug) || '';
    }
  }

  @computed get currentBreadcrumbs() {
    return [
      {
        label: 'Browse',
        href: '/browse',
      },
      this.type && {
        label: this.typeLabel,
        href: `/browse/${this.type}`,
      },
    ].filter(Boolean);
  }
}
