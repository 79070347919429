import React from 'react';
import { V2Plan } from '../../utilities/types';
import { formatCurrency, formatNonDecimalCurrency } from '../../../../lib/currency';
import { PaymentPlanBlock } from '../../molecules/registration/PaymentPlanBlock';
import { styles } from './styles';
import { rebrand } from '../../../../theme/color';
import { StarCircle } from '../../atoms/icons/StarCircle';
import { getPlanFeatures } from './Plans';
import { IconList } from '../../atoms/IconList';
import { classes } from 'typestyle';
import { toDateString } from '../../utilities/date';
import { Tooltip } from '../../atoms/Tooltip';
import { ActionButton } from '../../atoms/button/ActionButton';
import { Checkmark } from '../../atoms/icons/Checkmark';
import { Button } from '../../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../../atoms/button/buttonStyles';
import { ComponentProps, deps, inject, observer } from '../../../../lib/component';
import { ACCOUNT_PLANS_ROUTE, REGISTRATION_ROUTE } from '../../../../constants';

interface PlanV2Props extends ComponentProps {
  plan: V2Plan;
  allPlans: V2Plan[];
  planLoadingState?: boolean;
}

export const PlanV2 = inject(deps)(
  observer(
    ({
      plan,
      allPlans,
      planLoadingState = null,
      model: {
        user: {
          channel,
          isSubscribed,
          isSubscribedToV2Plan,
          isSubscribedToV2PlanAndCancelled,
          isSubscribedToLegacyPlan,
        },
        router,
      },
      controller: {
        analytics: { sendMixpanel },
        modal: { showPreUpgradeModal, showPreDowngradeModal },
        subscription: { navigateToStripeSubscriptionV2 },
      },
    }: PlanV2Props) => {
      // Get the selected channel's current and pending subscriptions
      const currentSubscription = isSubscribedToV2Plan ? channel.subscription : null;
      const pendingSubscription =
        isSubscribedToV2Plan && channel.pending_subscription ? channel.pending_subscription : null;

      // Check if the channel is subscribed to a v2 plan to determine upgrade/downgrade options
      const currentV2Plan = isSubscribedToV2Plan
        ? allPlans.find((plan) => plan.plan_name === channel.subscription.plan_name) || null
        : null;

      // Only v2 plans users can upgrade/downgrade to other v2 plans
      const availableUpgradePlans =
        currentV2Plan && !isSubscribedToV2PlanAndCancelled
          ? allPlans.filter((plan) => plan.price > currentV2Plan.price)
          : [];
      const availableDowngradePlans =
        currentV2Plan && !isSubscribedToV2PlanAndCancelled
          ? allPlans.filter((plan) => plan.price < currentV2Plan.price)
          : [];

      // Format the plan price and split it into units and remainder for display purposes
      const [units, remainder] = formatCurrency(plan.price / 100, plan.currency_code).split('.');

      // Is this channel subscribed to this plan?
      const isActivePlan = currentV2Plan && currentV2Plan.plan_name === plan.plan_name;
      const isPendingPlan = pendingSubscription && pendingSubscription.plan_name === plan.plan_name;

      const canUpgrade = availableUpgradePlans.find((upgradePlan) => upgradePlan.plan_name === plan.plan_name)
        ? true
        : false;
      const canDowngrade = availableDowngradePlans.find((downgradePlan) => downgradePlan.plan_name === plan.plan_name)
        ? true
        : false;

      let creditsText = null;

      if (isSubscribedToV2Plan && !isSubscribedToV2PlanAndCancelled && !pendingSubscription && !isActivePlan) {
        const currentCredits = currentV2Plan.plan_credits;
        const planCredits = plan.plan_credits;

        if (planCredits > currentCredits) {
          const extraCredits = planCredits - currentCredits;
          creditsText = `You'll get ${extraCredits} more credit${extraCredits !== 1 ? 's' : ''} each month`;
        } else if (currentCredits > planCredits) {
          const fewerCredits = currentCredits - planCredits;
          creditsText = `You'll get ${fewerCredits} fewer credit${fewerCredits !== 1 ? 's' : ''} each month`;
        }
      }

      const handleUpgrade = (): void => {
        if (router.location.pathname === `${REGISTRATION_ROUTE}/plans`) {
          router.push(ACCOUNT_PLANS_ROUTE);
          return;
        }

        sendMixpanel('User clicks plan upgrade', {
          route: router.location.pathname,
          planName: plan.plan_name,
          price: formatCurrency(plan.price / 100, plan.currency_code),
        });

        showPreUpgradeModal(plan);
      };

      const handleDowngrade = (): void => {
        if (router.location.pathname === `${REGISTRATION_ROUTE}/plans`) {
          router.push(ACCOUNT_PLANS_ROUTE);
          return;
        }

        sendMixpanel('User clicks plan downgrade', {
          route: router.location.pathname,
          planName: plan.plan_name,
          price: formatCurrency(plan.price / 100, plan.currency_code),
        });

        showPreDowngradeModal(plan);
      };

      const handleSelectPlan = (): void => {
        sendMixpanel('User clicks select plan', {
          route: router.location.pathname,
          planName: plan.plan_name,
          price: formatCurrency(plan.price / 100, plan.currency_code),
        });

        navigateToStripeSubscriptionV2(plan.stripe_id);
      };

      return (
        <PaymentPlanBlock className={styles.planWrapper} key={plan.plan_name} data-test-plan-block={plan.plan_name}>
          <h2>{plan.plan_name}</h2>

          {plan.plan_credits && (
            <div className={styles.planHeadingInfoText}>
              <StarCircle color={rebrand.primary[100].toString()} size={20} />
              {plan.plan_credits} Premium track credit{plan.plan_credits !== 1 ? 's' : ''}
            </div>
          )}

          <div className={styles.pricingContentsSubscription}>
            <span className={styles.pricingContentLarge}>{units}</span>.{remainder}
            <span className={styles.pricingContentMonth}> /month</span>
          </div>

          <IconList className={styles.list}>
            {getPlanFeatures(plan.plan_name, formatNonDecimalCurrency(plan.premium_tracks_price, plan.currency_code))}
          </IconList>

          {isSubscribed && canUpgrade && (
            <Button
              className={styles.planButton}
              prominence={ButtonProminence.PRIMARY}
              size={ButtonSize.LARGE}
              onClick={handleUpgrade}
              disabled={!!pendingSubscription || planLoadingState}
            >
              Upgrade
            </Button>
          )}

          {isSubscribed && canDowngrade && (
            <Button
              className={styles.planButton}
              prominence={ButtonProminence.SECONDARY}
              size={ButtonSize.LARGE}
              onClick={handleDowngrade}
              disabled={!!pendingSubscription || planLoadingState}
            >
              Downgrade
            </Button>
          )}

          {isSubscribed && isActivePlan && (
            <Button
              className={styles.planButton}
              prominence={ButtonProminence.SECONDARY}
              size={ButtonSize.LARGE}
              disabled
            >
              Plan active
              <Checkmark size={28} color="currentColor" />
            </Button>
          )}

          {(!isSubscribed || isSubscribedToLegacyPlan || (!isActivePlan && isSubscribedToV2PlanAndCancelled)) && (
            <ActionButton
              className={styles.planButton}
              onClick={handleSelectPlan}
              data-test-plan-block-action-button={plan.plan_name}
              disabled={!!pendingSubscription || isSubscribedToV2PlanAndCancelled || planLoadingState}
            >
              Select plan
            </ActionButton>
          )}

          {creditsText && <p className={classes(styles.smallText)}>{creditsText}</p>}

          {isSubscribedToV2PlanAndCancelled ? (
            <p className={classes(styles.smallText)}>
              {!isActivePlan ? (
                'Plan unavailable while cancellation in progress'
              ) : (
                <strong>Your plan will end on {toDateString(currentSubscription.renewal_date)}</strong>
              )}
            </p>
          ) : (
            <>
              {isPendingPlan && (
                <p className={classes(styles.smallText)}>Starting on {toDateString(pendingSubscription.start_date)}</p>
              )}

              {isActivePlan && (
                <p className={classes(styles.smallText)}>
                  {pendingSubscription ? (
                    <strong>Your plan will downgrade on {toDateString(pendingSubscription.start_date)}</strong>
                  ) : (
                    <strong>Your plan will renew on {toDateString(currentSubscription.renewal_date)}</strong>
                  )}
                </p>
              )}

              {!!pendingSubscription && !isPendingPlan && !isActivePlan && (
                <p className={classes(styles.smallText)}>Plan unavailable while downgrade is in progress</p>
              )}

              {!isSubscribedToV2Plan && !pendingSubscription && (
                <p className={classes(styles.smallText, styles.guaranteeText)}>
                  <Tooltip
                    className={styles.tooltip}
                    positions={['bottom', 'top']}
                    content={
                      <span>
                        <sup>*</sup>If you don&apos;t use a premium track credit in the first month of your
                        subscription, you can apply for a full refund
                      </span>
                    }
                  >
                    Money back guarantee<sup>*</sup>
                  </Tooltip>
                </p>
              )}
            </>
          )}
        </PaymentPlanBlock>
      );
    }
  )
);
