import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const ClockShortTimeDuotone: React.SFC<SVGIconProps & { color?: never }> = ({
  size = 116,
  isSquare,
  ...props
}) => (
  <BaseSvg width={size} height={isSquare ? size : size * (115 / 116)} viewBox="0 0 116 115" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#262E3F"
        d="M58 0C26.2438281 0 .5 25.7438281.5 57.5S26.2438281 115 58 115s57.5-25.7438281 57.5-57.5S89.7561719 0 58 0"
      />
      <path
        fill="#FFF"
        d="M58 8.984375c26.751875 0 48.515625 21.76375 48.515625 48.515625S84.751875 106.015625 58 106.015625 9.484375 84.251875 9.484375 57.5 31.248125 8.984375 58 8.984375"
      />
      <path fill="#EA33BA" fillRule="nonzero" d="M62 18.116V42.57l12.234-21.189 6.928 4L61.464 59.5 54 57.5V18.116z" />
    </g>
  </BaseSvg>
);
