import * as React from 'react';
import Geosuggest from 'react-geosuggest';
import { percent, px } from 'csx';
import { shadowSubtle } from '../theme/shadow';
import { classes, style } from 'typestyle';
import { colorBrand, colorSubtle, colorWhite } from '../theme/color';
import {LocationSuggestion} from '../types';
import {deps, inject, observer, StatefulComponent} from '../lib/component';
import {Loading} from './loading/loading';

export interface FormAddressLookupProps {
  className?: string;
  onSuggestion(suggestion: LocationSuggestion): void;
  scriptId?: string;
  autoFocus?: boolean;
  i18n?: {
    placeholder: string;
    empty: string;
  }
}

export interface FormAddressLookupState {
  isFocused: boolean;
  ready: boolean;
}

const DEFAULT_I18N = {
  placeholder: 'Search for address...',
  empty: 'Start typing above to search for an address, or fill in the fields directly'
};


@inject(deps) @observer
export class FormAddressLookup extends StatefulComponent<FormAddressLookupProps, FormAddressLookupState> {
  private __mounted: boolean = false;
  private __suggest: any;

  state = {
    isFocused: false,
    ready: false,
  };

  render() {
    const { i18n = DEFAULT_I18N, className } = this.props;
    const { ready } = this.state;

    return (
      <div className={classes(FormAddressLookup.styles.container, this.state.isFocused && 'active', className)}>
        {ready && (
          <Geosuggest
            ref={(el: any) => this.__suggest = el}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onSuggestSelect={this.handleSelect}
            placeholder={i18n.placeholder}
          />
        )}
        {!ready && (
          <Loading size={24} />
        )}
        <p>{i18n.empty}</p>
      </div>
    )
  }

  handleBlur = () => this.setState({ isFocused: false });
  handleFocus = () => this.setState({ isFocused: true });
  handleSelect = (suggestion: LocationSuggestion) => {
    this.__suggest.clear();
    this.props.onSuggestion(suggestion);
  };

  componentDidMount() {
    this.__mounted = true;
    const { scriptId = FormAddressLookup.constants.scriptId } = this.props;
    const { googleApiKey } = this.props.model.env;

    const existingScript = document.getElementById(scriptId);

    const onLoad = () => this.__mounted && this.setState({ ready: true }, () => {
      if (this.__suggest && this.props.autoFocus) this.__suggest.focus()
    });

    if (!existingScript) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `//maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`;
      script.id = scriptId;
      script.onload = onLoad;
      document.body.appendChild(script);
    } else {
      onLoad();
    }
  }

  componentWillUnmount() {
    this.__mounted = false;
  }

  static constants = {
    scriptId: 'google-maps'
  };

  static styles = {
    container: style({
      position: 'relative',
      $nest: {
        '&> p': {
          color: colorSubtle.toString(),
          margin: '0 0 20px 0',
          padding: 0,
          fontSize: px(14)
        },
        '& .geosuggest__input': {
          width: percent(100),
          backgroundColor: 'transparent',
          border: 'none',
          borderRadius: 0,
          '-webkit-appearance': 'none',
          '-moz-appearance': 'none',
          borderBottom: `1px solid ${colorSubtle.toString()}`,
          padding: '8px 0',
          margin: '10px 0',
        },
        '& .geosuggest__input:hover': {
          borderBottom: `2px solid ${colorSubtle.toString()}`,
          padding: '8px 0 7px'
        },
        '&& .geosuggest__input:focus': {
          borderBottom: `2px solid ${colorBrand.toString()}`,
          outline: 'none',
          padding: '8px 0 7px'
        },
        '&&& .geosuggest__input[disabled]': {
          borderBottom: `1px dashed ${colorSubtle.toString()}`
        },
        '&&&& .geosuggest__input[disabled]:hover': {
          borderBottom: `1px dashed ${colorSubtle.toString()}`,
          padding: '8px 0',
        },
        '&.active .geosuggest__input': {
          // boxShadow: shadowSubtle
        },
        '& .geosuggest__suggests-wrapper': {
          boxShadow: shadowSubtle,
          zIndex: 2,
          position: 'absolute',
          width: percent(100),
          margin: '-10px 0 0 0',
          background: colorWhite.toString()
        },
        '& .geosuggest__suggests--hidden': {
          display: 'none'
        },
        '& .geosuggest__suggests': {
          margin: 0,
          padding: 0,
          listStyle: 'none inside none',
          minHeight: px(250),
          maxHeight: 'calc(90vh - 200px)',
          overflowY: 'scroll'
        },
        '& .geosuggest__item': {
          cursor: 'pointer',
          padding: '4px 8px',
          color: colorSubtle.toString()
        },
        '& .geosuggest__item--active': {
          color: colorBrand.toString()
        },
        '& .geosuggest__item:hover': {
          color: colorBrand.toString()
        }
      }
    })
  }
}