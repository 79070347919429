import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { px, viewWidth } from 'csx';
import { HighlightText } from '../../../atoms/HighlightText';
import { heading3, heading4, heading5 } from '../../../utilities/font';
import { mediaLargeTablet, mediaUpToLargeTablet } from '../../../utilities/mediaQueries';

const styles = {
  standard: style(
    {
      position: 'absolute',
      fontWeight: 300,
      color: 'white',
    },
    mediaUpToLargeTablet({
      fontSize: viewWidth(5),
      top: px(129),
      right: px(16),
    }),
    mediaLargeTablet(heading5, {
      top: px(216),
      right: px(22),
    })
  ),
  highlight: style(
    mediaUpToLargeTablet({
      fontSize: viewWidth(10),
    }),
    mediaLargeTablet(heading3)
  ),
};

export type PromoTextProps = React.Component['props'] & { className?: string };

export const PromoText: React.SFC<PromoTextProps> = ({ children, className }) => (
  <HighlightText className={classes(styles.standard, className)} highlightClassName={styles.highlight}>
    {children}
  </HighlightText>
);
