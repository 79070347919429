import { computed, observable } from 'mobx';
import { ArtistSchema, PlaylistGroupPlaylistSchema, PlaylistSchema, TrackSchema } from '../../../types/schema';

export class FavouritesPageModel {
  @observable
  loading = true;

  @observable
  pageTitle: string = null;

  @observable
  pageBody: string = null;

  @observable
  noTracks: string = null;

  @observable
  noArtists: string = null;

  @observable
  noFavourites: string = null;

  @observable
  coverImage: string = null;

  @observable
  tracks: Array<TrackSchema> = [];

  @observable
  filteredTracks: Array<TrackSchema> = [];

  @observable
  tracksPage = 1;

  @observable
  tracksPerPage = 25;

  @observable
  tracksPagesTotal: number;

  @computed
  get infiniteTracks(): any {
    return {
      loading: false,
      loadingMore: false,
      tracks: this.filteredTracks,
    };
  }

  @observable
  hasMoreTracks = true;

  @observable
  loadingMoreTracks = false;

  @observable
  artists: Array<ArtistSchema> = [];

  @observable
  filteredArtists: Array<ArtistSchema> = [];

  @observable
  artistsPage = 1;

  @observable
  artistsPerPage = 8;

  @observable
  artistsPagesTotal: number;

  @observable
  hasMoreArtists = true;

  @observable
  loadingMoreArtists = false;

  @observable
  playlists: Array<PlaylistGroupPlaylistSchema> = [];

  @observable
  filteredPlaylists: Array<PlaylistGroupPlaylistSchema> = [];

  @observable
  playlistsPage = 1;

  @observable
  playlistsPerPage = 8;

  @observable
  playlistsPagesTotal: number;

  @observable
  hasMorePlaylists = true;

  @observable
  loadingMorePlaylists = false;

  @computed
  get hasTracks(): boolean {
    return this.filteredTracks.length > 0;
  }

  @computed
  get hasArtists(): boolean {
    return this.filteredArtists.length > 0;
  }

  @computed
  get hasPlaylists(): boolean {
    return this.filteredPlaylists.length > 0;
  }

  @computed
  get hasFavourites(): boolean {
    return this.hasArtists || this.hasTracks || this.hasMorePlaylists;
  }
}
