import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { percent, px } from 'csx';
import { ContentSlice, ContentSliceProps } from './ContentSlice';
import { colorContrast } from '../../../../theme/color';
import { mediaDesktop, mediaLargeTablet, mediaUpToDesktop, mediaUpToLargeTablet } from '../../utilities/mediaQueries';
import { isArray } from '../../utilities/objects';
import { centerJustified } from 'csstips';
import { PrismicSliceCSSClasses } from '../../utilities/types';

const yPadding = px(56);

const styles = {
  wrapper: style(
    mediaUpToDesktop({
      paddingBottom: px(32),
      $nest: {
        '.masthead + &, .with-background + &:not(.with-background), :not(.with-background) + &.with-background, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient': {
          paddingTop: px(32),
        },
      },
    }),
    mediaDesktop({
      paddingBottom: px(56),
      $nest: {
        '.masthead + &, .with-background + &:not(.with-background), :not(.with-background) + &.with-background, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient': {
          paddingTop: px(56),
        },
      },
    })
  ),
  wrapperOnlyChild: style(centerJustified),
  content: style(
    mediaUpToLargeTablet({
      $nest: {
        '& > *': {
          width: percent(100),
        },
        '& > :first-child': {
          marginBottom: px(16),
        },
      },
    }),
    mediaLargeTablet({
      display: 'flex',
      $nest: {
        '& > *': {
          width: percent(50),
          flexGrow: 0,
        },
        '& > :first-child': {
          marginRight: px(8),
        },
        '& > :last-child': {
          marginLeft: px(8),
        },
      },
    })
  ),
};

type HeadingPanelProps = ContentSliceProps;

export const TwoColumnSlice: React.SFC<HeadingPanelProps> = ({ children, className, ...props }) => {
  const isOnlyChild = !isArray(children) || children.length === 1;
  return (
    <ContentSlice
      className={classes(PrismicSliceCSSClasses.TWO_COLUMN_SLICE, className, styles.wrapper)}
      contentClassName={classes(styles.content, isOnlyChild && styles.wrapperOnlyChild)}
      {...props}
    >
      {children}
    </ContentSlice>
  );
};
