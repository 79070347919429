import * as React from 'react';
import PrismicDOM from 'prismic-dom';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';

import { Component, deps, inject, observer } from '../lib/component';
import { classes } from 'typestyle';
import MHeaderImage from '../static/M1-Hero-Image.jpg';
import MHeaderImageGradient from '../static/M1-Hero-Gradient.png';
import DHeaderImage from '../static/D1-Hero-Image.jpg';
import DHeaderImageGradient from '../static/D1-Hero-Gradient.png';
import DownArrow from '../static/expand_more.png';
import LickdLogo from '../static/lickd_logo.svg';
import { ButtonPill } from '../components/button-pill';
import { Head } from './head';
import { LoadingContainer } from '../components/loading-container';
import { MCNPageModel } from '../modules/page/mcn/model';

import styles from '../styles/marketing-pages';

export interface MCNPageProps {
  model: MCNPageModel;
}

@inject(deps)
@observer
export class MCNPage extends Component<MCNPageProps> {
  constructor(props) {
    super(props);
  }

  componentDidMount(): void {
    const { rootDomain: domain } = this.props.model.env;
    const model = this.props.model.page.mcn;
    const searchParams = new URLSearchParams(window.location.search);
    const userIdParam = this.props.model.page.mcn.userIdParam;

    if (searchParams.has('token')) {
      Cookies.set('mcn_token', searchParams.get('token'), { domain });
    }

    if (searchParams.has(userIdParam)) {
      Cookies.set('mcn_user_id', searchParams.get(userIdParam), { domain });
    }

    Cookies.set('mcn_name', model.slug, { domain });
  }

  render(): JSX.Element | false | null {
    const model = this.props.model.page.mcn;
    const medium = model.medium || 'referral';
    const source = model.source || model.slug;
    const campaign = model.campaign || `${model.slug}-lander-download`;

    const mcnUrl = `/?show-sign-up&utm_medium=${medium}&utm_source=${source}&utm_campaign=${campaign}`;

    const customHeaderImage = model.customHeaderImage
      ? {
          backgroundImage: `url(${model.customHeaderImage})`,
        }
      : {};

    return (
      <LoadingContainer loading={model.loading}>
        <div className={classes(styles.background)}>
          <Head />
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <div className={classes(styles.container)}>
            <div
              className={classes(styles.hero, model.customHeaderImage ? 'without-gradient' : 'with-gradient')}
              style={customHeaderImage}
            >
              <div className={classes(styles.logos)}>
                <img src={LickdLogo} />
                {model.logo && <img src={model.logo} />}
              </div>
              <div className={classes(styles.heroInner)}>
                <div className={classes(styles.heroInnerText)}>{model.headerText}</div>
                <ButtonPill href={mcnUrl} primary={false}>
                  {model.ctaText}
                </ButtonPill>
              </div>
              <div className={classes(styles.readMore)}>
                <div>Read more</div>
                <img src={DownArrow} width="12" height="8" />
              </div>
            </div>
            <div className={styles.content}>
              <div className={classes(styles.contentMain)}>
                {model.sections.map((section) => (
                  <div key={section.heading}>
                    <h4>{section.heading}</h4>
                    <span dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(section.content) }} />
                  </div>
                ))}
              </div>
              <div className={classes(styles.downloadCta)}>
                <ButtonPill href={mcnUrl} primary={false}>
                  {model.ctaText}
                </ButtonPill>
              </div>
              <h4>{model.featuredTitle}</h4>

              <div className={classes(styles.hottestTracks)}>
                {model.featured.map((feature) => (
                  <div className={classes(styles.artist)} key={feature.image}>
                    <a href={mcnUrl}>
                      <div>
                        <img src={LickdLogo} />
                        <div
                          className={classes(styles.artistImage)}
                          style={{ backgroundImage: `url('${feature.image}')` }}
                        >
                          <div className={classes(styles.artistName)}>
                            <div>{feature.name}</div>
                            {feature.subtext}
                          </div>
                        </div>
                      </div>
                    </a>
                    {feature.description}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </LoadingContainer>
    );
  }
}
