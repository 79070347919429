import { SessionStorageModel, StorageModel } from '../../modules/storage/model';

export const REPLACE_CAROUSELS = 'REPLACE_CAROUSELS';

// Users are able to access the platform before the plans screen
export const FREE_USER_EARLY = 'FREE_USER_EARLY';

// Users skip the plans screen entirely; requires FREE_USER_EARLY to be active
export const SKIP_PLANS_STAGE = 'SKIP_PLANS_STAGE';

// Add skip button on the plans page on the registration flow
export const SKIP_BUTTON_ON_REGISTRATION_PLANS = 'SKIP_BUTTON_ON_PLANS';

// Show Project Subscription features pre-launch **PROJECT_SUB_FLAG**
export const PROJECT_SUBSCRIPTION_PREVIEW = 'PROJECT_SUBSCRIPTION_PREVIEW';

export type FeatureFlag =
  | typeof REPLACE_CAROUSELS
  | typeof FREE_USER_EARLY
  | typeof SKIP_PLANS_STAGE
  | typeof SKIP_BUTTON_ON_REGISTRATION_PLANS
  | typeof PROJECT_SUBSCRIPTION_PREVIEW;

const isSessionOnly = [
  FREE_USER_EARLY,
  SKIP_PLANS_STAGE,
  SKIP_BUTTON_ON_REGISTRATION_PLANS,
  PROJECT_SUBSCRIPTION_PREVIEW,
];

export const getFlag = (flag: FeatureFlag): boolean => {
  const value = isSessionOnly.includes(flag) ? SessionStorageModel.getItem(flag) : StorageModel.getItem(flag);
  return value === null ? false : value;
};

export const setFlag = (flag: FeatureFlag, state: any) =>
  isSessionOnly.includes(flag) ? SessionStorageModel.setItem(flag, state) : StorageModel.setItem(flag, state);

type FeatureFlagEventPayload = {
  flag: FeatureFlag;
  flagState: boolean;
};

export type FeatureFlagEvent = CustomEvent<FeatureFlagEventPayload>;
