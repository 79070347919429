import * as React from 'react';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { UtmLink } from '../UtmLink';
import { fontstackDefault } from '../../config';
import { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';

const styles = style({
  display: 'inline-block',
  border: 0,
  fontSize: px(16),
  fontFamily: fontstackDefault,
  color: 'white',
  position: 'relative',
});

export interface BaseButtonProps {
  className?: string;
  href?: string;
  openInNewWindow?: boolean;
  label?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<any>) => void;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  current?: boolean;
}

export const BaseButton: React.SFC<BaseButtonProps> = ({
  className,
  href,
  openInNewWindow = false,
  label,
  disabled = false,
  onClick,
  current,
  children,
  ...props
}) => {
  const compiledClasses = classes(styles, className, 'button');

  const a11yProps = {};
  const linkOnlyProps: AnchorHTMLAttributes<HTMLAnchorElement> = {};
  const hasLabel = typeof label === 'string' && label.length > 0;
  if (hasLabel) {
    a11yProps['aria-label'] = label;
  }
  if (openInNewWindow && href) {
    linkOnlyProps.target = '_blank';
    if (hasLabel) {
      a11yProps['aria-label'] += '; Opens in new window';
    }
  }
  if (current && href) {
    a11yProps['aria-current'] = 'page';
  } else if (current) {
    a11yProps['aria-current'] = 'true';
  }

  const clickHandlerWithDisabled =
    disabled === true ? (event: React.MouseEvent<any>) => event.preventDefault() : onClick;

  if (href)
    return (
      <UtmLink
        href={!disabled ? href : null}
        className={compiledClasses}
        onClick={clickHandlerWithDisabled}
        aria-disabled={disabled}
        {...a11yProps}
        {...linkOnlyProps}
        {...props}
      >
        {children}
      </UtmLink>
    );

  return (
    <button
      className={compiledClasses}
      onClick={clickHandlerWithDisabled}
      disabled={disabled}
      aria-disabled={disabled}
      {...a11yProps}
      {...props}
    >
      {children}
    </button>
  );
};
