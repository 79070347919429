import { observable } from 'mobx';

export class LightweightLandingModel {
  @observable
  customHeaderImage: any;

  @observable
  headerText: string = null;

  @observable
  ctaText: string = null;

  @observable
  bodyTitle: string = null;

  @observable
  bodyContent: Array<Array<any>> = [];

  @observable
  featuredTitle: string = null;

  @observable
  featured: Array<any> = [];
}
