import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const MarkCircle: React.SFC<SVGIconProps> = ({ size = 12, color = '#DA2699', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 10 10" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle cx="5" cy="5" r="5" fill="white" />
    <path
      d="M5 0C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 5 0ZM4 7.5L2 5.5L2.705 4.795L4 6.085L5.8975 4.1875L7.295 2.79L8 3.5L4 7.5Z"
      fill={color}
    />
  </BaseSvg>
);
