import React from 'react';
import { OrderSchema } from '../../../types/schema';
import { style } from 'typestyle';
import { mediaSmallTablet } from '../utilities/mediaQueries';
import { spacing } from '../../../theme/spacing';
import { ArtistNames } from '../../artist/names';
import { formatCurrency } from '../../../lib/currency';
import { rebrand } from '../../../theme/color';
import { borderRadius } from '../../../theme/border';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { ArrowRight } from '../atoms/icons/ArrowRight';
import { px } from 'csx';
import { screenReaderOnly } from '../utilities/font';
import { fontstackDefault } from '../config';
import { center, flexRoot } from 'csstips';
import { TrackArtwork } from '../atoms/TrackArtwork';
import { Link } from '../atoms/Link';
import { toDateString } from '../utilities/date';

interface OrdersListItemProps {
  order: OrderSchema;
  onDownload: (invoiceId: string) => Promise<void>;
  isDownloading: string | null;
  gridColumns: string;
}

const styles = {
  grid: (gridColumns) =>
    style(
      {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: spacing.DEFAULT,
        padding: spacing.DEFAULT,
        border: `1px solid ${rebrand.neutralOnDark[30].toString()}`,
        borderRadius: borderRadius.MEDIUM,
        $nest: {
          '& + *': {
            marginTop: spacing.DEFAULT,
          },
        },
      },
      mediaSmallTablet({
        gridTemplateColumns: gridColumns,
      })
    ),
  mobileHeading: style(
    {
      fontFamily: fontstackDefault,
      fontSize: px(16),
      fontWeight: 700,
      margin: `0 0 ${spacing.XSMALL}`,
    },
    mediaSmallTablet(screenReaderOnly)
  ),
  date: style({
    fontSize: px(12),
    color: rebrand.neutralOnLight[70].toString(),
  }),
  license: style(flexRoot, center, {
    fontSize: px(12),
    gap: spacing.XSMALL,
    $nest: {
      '& + *': {
        marginTop: spacing.XSMALL,
      },
    },
  }),
  trackArtwork: style({
    borderRadius: borderRadius.SMALL,
    overflow: 'hidden',
    flexShrink: 0,
  }),
  trackTitle: style({
    fontSize: px(12),
    fontWeight: 700,
    $nest: {
      '& > a:hover': {
        textDecoration: 'underline',
      },
      '& > a:focus': {
        textDecoration: 'underline',
      },
    },
  }),
  actionButtons: style(
    {
      display: 'flex',
      gap: '8px',
    },
    mediaSmallTablet({
      flexDirection: 'column',
    })
  ),
  viewOrderBtn: style({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
  }),
  getInvoiceBtn: style({
    textAlign: 'center',
  }),
};

export const OrdersListItem: React.FC<OrdersListItemProps> = ({ order, onDownload, isDownloading, gridColumns }) => {
  return (
    <li className={styles.grid(gridColumns)}>
      <div className={styles.date}>{toDateString(order.date.date)}</div>
      <div>
        <h3 className={styles.mobileHeading}>Licenses</h3>
        {order.licences.map((licence) => (
          <div key={licence.identity} className={styles.license}>
            <TrackArtwork track={licence.track} width={48} height={48} crop className={styles.trackArtwork} />
            <div>
              <div className={styles.trackTitle}>
                <Link
                  href={`/music/artists/${licence.track.artists[0].slug}/track/${licence.track.slug}`}
                  data-test-orders-track-title={licence.track.title}
                >
                  {licence.track.title}
                </Link>
              </div>
              <ArtistNames artists={licence.track.artists} />
            </div>
          </div>
        ))}
      </div>
      <div>
        <h3 className={styles.mobileHeading}>Total paid</h3>
        {formatCurrency(order.amount.total, order.locale.currency)}
      </div>
      <div>
        <h3 className={styles.mobileHeading}>Actions</h3>

        <div className={styles.actionButtons}>
          <Button
            href={`/account/order/${order.identity}`}
            prominence={ButtonProminence.PRIMARY}
            size={ButtonSize.SMALL}
            className={styles.viewOrderBtn}
          >
            View order
            <ArrowRight size={14} />
          </Button>

          <Button
            onClick={() => onDownload(order.identity)}
            prominence={ButtonProminence.SECONDARY}
            size={ButtonSize.SMALL}
            className={styles.getInvoiceBtn}
            disabled={isDownloading === order.identity}
          >
            Get invoice
          </Button>
        </div>
      </div>
    </li>
  );
};
