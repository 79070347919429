import { RequestController } from '../request/controller';
import { ArtistAPIController } from './artist/controller';
import { BrowseAPIController } from './browse/controller';
import { AnalyticsAPIController } from './analytics/controller';
import { CheckoutAPIController } from './checkout/controller';
import { EnvModel } from '../env/model';
import { PricingAPIController } from './pricing/controller';
import { UserAPIController } from './user/controller';
import { ReleaseAPIController } from './release/controller';
import { TrackAPIController } from './track/controller';
import { YouTubeAPIController } from './youtube/controller';
import { LabelAPIController } from './label/controller';
import { PlaylistAPIController } from './playlist/controller';
import { CacheController } from '../cache/controller';
import { ArtistlistAPIController } from './artistlist/controller';
import { AudioAPIController } from './audio/controller';
import { AnalyticsController } from '../analytics/controller';
import { SubscriptionAPIController } from './subscription/controller';
import { SearchAPIController } from './search/controller';

export class APIController {
  artist: ArtistAPIController;
  release: ReleaseAPIController;
  browse: BrowseAPIController;
  analytics: AnalyticsAPIController;
  checkout: CheckoutAPIController;
  pricing: PricingAPIController;
  user: UserAPIController;
  track: TrackAPIController;
  youtube: YouTubeAPIController;
  label: LabelAPIController;
  playlist: PlaylistAPIController;
  artistlist: ArtistlistAPIController;
  audio: AudioAPIController;
  subscription: SubscriptionAPIController;
  search: SearchAPIController;

  constructor(
    private request: RequestController,
    private cache: CacheController,
    private env: EnvModel,
    private nonApiAnalytics: AnalyticsController
  ) {
    this.artist = new ArtistAPIController(request, cache, env);
    this.release = new ReleaseAPIController(request, cache, env);
    this.browse = new BrowseAPIController(request, cache, env);
    this.analytics = new AnalyticsAPIController(request, cache, env);
    this.checkout = new CheckoutAPIController(request, cache, env, nonApiAnalytics);
    this.pricing = new PricingAPIController(request, cache, env);
    this.user = new UserAPIController(request, cache, env);
    this.track = new TrackAPIController(request, cache, env);
    this.youtube = new YouTubeAPIController(request, cache, env);
    this.label = new LabelAPIController(request, cache, env);
    this.playlist = new PlaylistAPIController(request, cache, env);
    this.artistlist = new ArtistlistAPIController(request, cache, env);
    this.audio = new AudioAPIController(request, cache, env);
    this.subscription = new SubscriptionAPIController(request, cache, env);
    this.search = new SearchAPIController(request, cache, env);
  }
}
