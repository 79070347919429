import * as React from 'react';
import { PrismicHeadedCarouselProps } from '../utilities/types';
import { PersonalisedCarouselSlice } from '../organisms/slices/PersonalisedCarouselSlice';

export type PrismicPersonalisedCarouselPayload = PrismicHeadedCarouselProps;
export type PrismicPersonalisedCarouselProps = {
  payload: PrismicPersonalisedCarouselPayload;
};

export const PrismicPersonalisedCarousel: React.FC<PrismicPersonalisedCarouselProps> = ({
  payload: {
    primary: { carousel_heading },
  },
}) => <PersonalisedCarouselSlice heading={carousel_heading} />;
