import * as React from 'react';
import { classes, cssRaw, cssRule, style } from 'typestyle';
import { px } from 'csx';
import { ContentSlice, ContentSliceProps } from './ContentSlice';
import { mediaSmallTablet, mediaUpToSmallTablet } from '../../utilities/mediaQueries';
import { heading3, heading4, heading5, heading6, headingStyles } from '../../utilities/font';
import { mediaMobileOnly } from '../../../../theme/media';

const styles = {
  wrapper: style(
    mediaUpToSmallTablet({
      paddingBottom: px(32),
      $nest: {
        '.masthead + &, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient, .with-background + &:not(.with-background), :not(.with-background) + &.with-background': {
          paddingTop: px(32),
        },
      },
    }),
    mediaSmallTablet({
      paddingBottom: px(56),
      $nest: {
        '.masthead + &, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient, .with-background + &:not(.with-background), :not(.with-background) + &.with-background': {
          paddingTop: px(56),
        },
      },
    }),
    {
      paddingTop: px(56),
      textAlign: 'center',
    }
  ),
  content: style(
    headingStyles,
    mediaMobileOnly({
      width: '288px',
      margin: '8px auto',
      $nest: {
        '& p': {
          width: '288px',
        },
      },
    }),
    {
      $nest: {
        '& *': {
          margin: 0,
        },
        '& p': {
          margin: '8px auto',
          width: '656px',
        },
      },
    }
  ),
};

cssRule(
  '.heading-slice .subheading',
  { fontWeight: 300, display: 'block' },
  mediaUpToSmallTablet(heading6),
  mediaSmallTablet(heading5)
);

cssRule(
  '.heading-slice h2.heading, .heading-slice .heading',
  mediaUpToSmallTablet(heading4),
  mediaSmallTablet(heading3)
);

type HeadingSliceProps = ContentSliceProps;

export const HeadingSlice: React.SFC<HeadingSliceProps> = ({ children, className, ...props }) => (
  <ContentSlice
    className={classes('heading-slice', className, styles.wrapper)}
    contentClassName={styles.content}
    {...props}
  >
    {children}
  </ContentSlice>
);
