import Cookies from 'js-cookie';

type DataItem = {
  title_tile: string;
  id: string;
};

export const shuffleDashboardHeaderData = (data: DataItem[]): DataItem[] => {
  if (data.length <= 3) return data;
  let shuffledData = [];
  // Get data from cookie
  const seenIdsCookie = Cookies.get('seenIds');
  const seenIds = seenIdsCookie ? JSON.parse(seenIdsCookie) : [];

  // removed data already seen by the user
  const notYetSeenIds = [...data].filter((item) => !seenIds.includes(item.id));

  // Shuffle the array
  if (notYetSeenIds.length === 0 && seenIds.length > 0) {
    // when user have seen all the data
    shuffledData = data.sort(() => Math.random() - 0.5);
  } else {
    shuffledData = notYetSeenIds.sort(() => Math.random() - 0.5);
  }

  // Select unique base title_tile
  const uniqueTitles: string[] = [];

  const selectedData: DataItem[] = shuffledData.filter((item) => {
    if (!uniqueTitles.includes(item.title_tile) && uniqueTitles.length < 3) {
      uniqueTitles.push(item.title_tile);
      return true;
    }
    return false;
  });

  // If the data is less than 3, add more from the shuffledData
  if (uniqueTitles.length < 3) {
    const remainingTitles = 3 - uniqueTitles.length;

    const remainingData = shuffledData
      .filter((item) => !selectedData.some((i) => i.id === item.id))
      .slice(0, remainingTitles);
    selectedData.push(...remainingData);
  }

  if (selectedData.length < 3) {
    // If the data is still less than 3, add more from the data
    const remaining = 3 - selectedData.length;
    const remainingData = data.filter((item) => !selectedData.some((i) => i.id === item.id)).slice(0, remaining);
    selectedData.push(...remainingData);
  }

  // Update the cookie with the  unique seen IDs
  const newSeenIds = [...new Set([...seenIds, ...selectedData.map((item) => item.id)])];
  Cookies.set('seenIds', JSON.stringify(newSeenIds));

  return selectedData;
};
