import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { px } from 'csx';
import { ContentSlice, ContentSliceProps } from './ContentSlice';

const styles = {
  wrapper: style({
    textAlign: 'center',
    paddingBottom: px(82),
    paddingTop: px(91),
    backgroundColor: '#F3F5F7',
    borderTop: '1px solid #E0E4E9',
    $nest: {
      '& > *': {
        marginTop: px(32),
      },
      '& > :first-child': {
        marginTop: 0,
      },
    },
  }),
  content: style({
    $nest: {
      '& *': {
        margin: 0,
      },
    },
  }),
};

type CtaSliceProps = ContentSliceProps;

export const CtaSlice: React.SFC<CtaSliceProps> = ({ children, className, ...props }) => (
  <ContentSlice
    className={classes('cta-slice', 'with-background', className, styles.wrapper)}
    contentClassName={styles.content}
    {...props}
  >
    {children}
  </ContentSlice>
);
