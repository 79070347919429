import { action } from 'mobx';
import { API, APIPaginatedResponse, APIResponse } from '../types';
import {ArtistSchema, LabelSchema, TrackSchema} from '../../../types/schema';
import {withIncludes, withIncludesAndPage} from '../../../lib/api';

export class LabelAPIController extends API {
  @action
  getLabels = async (includes: string[] = ['images']): Promise<APIResponse<Array<LabelSchema>>> => {
    const url = `/catalog/rightsholder${withIncludes(includes)}&perPage=200`;
    const resp = await this.request.get({ url });

    return await resp.json();
  };

  @action
  getLabel = async (slug: string, includes: string[] = ['images']): Promise<APIResponse<LabelSchema>> => {
    const url = `/catalog/rightsholder/${slug}${withIncludes(includes)}`;
    const resp = await this.request.get({ url });

    return await resp.json();
  };

  @action
  getLabelTracks = async (slug: string, page: number = 1, includes: string[] = ['images']): Promise<APIPaginatedResponse<Array<TrackSchema>>> => {
    const url = `/catalog/rightsholder/${slug}/tracks${withIncludesAndPage(includes, page)}`;
    const resp = await this.request.get({ url });
    const json = await resp.json();

    this.cache.addTracks(json.data);

    return json;
  };

  @action
  getLabelArtists = async (slug: string, page: number = 1, includes: string[] = ['images']): Promise<APIPaginatedResponse<Array<ArtistSchema>>> => {
    const url = `/catalog/rightsholder/${slug}/artists${withIncludesAndPage(includes, page)}`;
    const resp = await this.request.get({ url });
    const json = await resp.json();

    this.cache.addArtists(json.data);

    return json;
  };
}