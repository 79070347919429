import * as React from 'react';
import { classes, cssRule, style } from 'typestyle';
import { percent, px } from 'csx';
import { colorGunmetal, rebrand } from '../../../theme/color';
import { headerBorder, headerFullHeight } from '../config';
import { mediaDesktop, mediaLargeMobile, mediaUpToDesktop, mediaUpToLargeMobile } from '../utilities/mediaQueries';
import { zAppHeaderContainer } from '../../../theme/z';
import { LickdRebrandLogo } from '../atoms/icons/brands/LickdRebrandLogo';
import { Button, ButtonProps } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { flexRoot } from 'csstips';
import { centerCenter } from 'csstips/lib/flex';
import { Component, ComponentProps, deps, inject, observer } from '../../../lib/component';

cssRule('html body', {
  marginTop: px(headerFullHeight),
});

const styles = {
  header: style({
    position: 'fixed',
    backgroundColor: colorGunmetal.toString(),
    top: 0,
    left: 0,
    right: 0,
    width: percent(100),
    height: px(headerFullHeight),
    zIndex: zAppHeaderContainer,
    $nest: {
      '&::after': {
        content: '""',
        position: 'absolute',
        width: percent(100),
        height: px(headerBorder),
        bottom: 0,
        left: 0,
        right: 0,
      },
      '& .button': mediaDesktop({
        minHeight: px(32),
        paddingTop: px(5),
        paddingBottom: px(5),
      }),
    },
  }),
  wrapper: style(
    {
      height: percent(100),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    mediaUpToDesktop({
      marginLeft: px(16),
      marginRight: px(16),
    }),
    mediaDesktop({
      margin: '0 auto',
      width: px(1024),
    })
  ),
  exitConfirmation: style(
    {
      display: 'none',
      position: 'absolute',
      background: '#040611',
      padding: px(8),
      color: 'white',
      textAlign: 'center',
      $nest: {
        '& p': {
          margin: '0 0 8px',
        },
        '&::after': {
          content: '""',
          width: 0,
          height: 0,
          border: '8px solid transparent',
          position: 'absolute',
          borderTopWidth: 0,
          top: px(-8),
          borderBottomColor: '#040611',
        },
      },
    },
    mediaUpToLargeMobile({
      width: percent(100),
      left: 0,
      $nest: {
        '&::after': {
          left: px(54),
        },
      },
    }),
    mediaLargeMobile({
      borderRadius: px(4),
      $nest: {
        '&::after': {
          left: px(38),
        },
      },
    })
  ),
  confirmationButtons: style(flexRoot, centerCenter, {
    $nest: {
      '& button': {
        margin: '0 4px',
      },
    },
  }),
  shownConfirmation: style({ display: 'block' }),
};

@inject(deps)
@observer
export class RegistrationHeaderBanner extends Component<ComponentProps> {
  state = {
    isConfirmationShown: false,
  };

  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }

  logout() {
    const {
      controller: {
        auth: { logout },
        analytics: { sendMixpanel },
      },
    } = this.props;
    sendMixpanel('New user escaped registration');
    logout(false, false, false, true);
  }

  render() {
    const { isConfirmationShown } = this.state;
    const {
      model: {
        onboarding: { fullyOnboarded },
      },
      controller: {
        analytics: { sendMixpanel },
      },
    } = this.props;
    const optionalLogoProps: Partial<ButtonProps> = {};
    if (fullyOnboarded) {
      optionalLogoProps.href = '/';
      optionalLogoProps.onClick = () => sendMixpanel('Onboarded user escaped registration');
    } else {
      optionalLogoProps.onClick = () => this.setState({ isConfirmationShown: !isConfirmationShown });
    }
    return (
      <header className={styles.header}>
        <div className={styles.wrapper}>
          <div>
            <Button prominence={ButtonProminence.NONE} {...optionalLogoProps}>
              <LickdRebrandLogo />
            </Button>
            <div className={classes(styles.exitConfirmation, isConfirmationShown && styles.shownConfirmation)}>
              <p>Are you sure you want to exit sign up?</p>
              <div className={styles.confirmationButtons}>
                <Button
                  prominence={ButtonProminence.SECONDARY}
                  size={ButtonSize.SMALL}
                  href="/"
                  onClick={this.logout}
                  data-exit-confirmation-exit-button
                >
                  Exit sign up
                </Button>
                <Button
                  prominence={ButtonProminence.PRIMARY}
                  size={ButtonSize.SMALL}
                  onClick={() => this.setState({ isConfirmationShown: false })}
                  data-exit-confirmation-cancel-button
                >
                  Stay and sign up
                </Button>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
