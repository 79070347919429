/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { Fragment } from 'react';
import { PrismicTypedDashboardHeaderProps } from '../utilities/types';
import { Component } from '../../../lib/component';
import { PageCarousel } from '../molecules/PageCarousel';
import { classes, style } from 'typestyle';
import { rebrand } from '../../../theme/color';
import DashboardHeaderSlice from './slices/DashboardHeaderSlice';
import { important, px } from 'csx';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import ProgressBar from '../atoms/ProgressBar';
import { spacing } from '../../../theme/spacing';

export type Props = {
  items: PrismicTypedDashboardHeaderProps[];
  isSquareDashboardHeadereImage: boolean;
  sendMixpanel?: (event: string, props?: any, callback?: () => void) => void;
};

export class DashboardHeaderCarousel extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      paused: false,
    };

    this.updateActivePage = this.updateActivePage.bind(this);
  }

  updateActivePage = () => {
    const { page } = this.state;
    if (page === this.props.items.length) {
      this.setState({ page: 1 });
    } else {
      this.setState((prevState) => ({ page: prevState.page + 1 }));
    }
  };

  onMouseEnter = (e: React.MouseEvent) => {
    e.preventDefault();
    this.setState({ paused: true });
  };

  onMouseLeave = (e: React.MouseEvent) => {
    e.preventDefault();
    this.setState({ paused: false });
  };

  render() {
    const { items, isSquareDashboardHeadereImage, sendMixpanel } = this.props;
    const { page, paused } = this.state;

    return (
      <div className={styles.wrapper} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <PageCarousel
          tileClassName={styles.tileClassName}
          perPage={1}
          page={page}
          onPageChange={(page) => this.setState({ page })}
          mobileTileSize={100}
          autoplay={true}
        >
          {items.map((item: any, index: number) => (
            <Fragment key={index}>
              <DashboardHeaderSlice
                item={item}
                isSquareDashboardHeadereImage={isSquareDashboardHeadereImage}
                sendMixpanel={sendMixpanel}
              />
            </Fragment>
          ))}
        </PageCarousel>
        {items.length > 1 && (
          <div className={styles.progressBar}>
            <ProgressBar page={page} duration={7000} isPaused={paused} onComplete={this.updateActivePage} />
          </div>
        )}
        {items.length > 1 && (
          <div className={styles.paginations}>
            {items.map((_, index) => {
              const active = index + 1 === this.state.page;
              return (
                <button
                  key={index}
                  className={classes(styles.paginationBar, active && styles.activeBar)}
                  onClick={() => this.setState({ page: index + 1, progress: 0 })}
                  aria-label={`Navigate to slide ${index + 1}`}
                ></button>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  wrapper: style(
    {
      position: 'relative',
      height: '300px',
    },
    mediaDesktop({
      height: '100%',
      minHeight: '400px',
    })
  ),
  itemWrapper: style({
    height: '100%',
    position: 'relative',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: 'pink',
  }),
  paginations: style({
    position: 'absolute',
    display: 'flex',
    bottom: '32px',
    right: '32px',
    zIndex: 1,
  }),
  progressBar: style({
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
  }),
  paginationBar: style({
    width: '24px',
    height: '4px',
    appearance: 'none',
    padding: 0,
    border: 0,
    borderRadius: '2px',
    backgroundColor: rebrand.contrast[20].toString(),
    opacity: 0.4,
    display: 'block',
    cursor: 'pointer',
    zIndex: 1,
    $nest: {
      '&:not(:last-child)': {
        marginRight: spacing.XSMALL,
      },
    },
  }),
  activeBar: style({
    backgroundColor: important(rebrand.neutralOnDark[20].toString()),
    opacity: 1,
    cursor: 'default',
  }),
  tileClassName: style(mediaUpToDesktop({ width: '100%' })),
};
