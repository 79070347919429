import {computed, observable} from 'mobx';
import TileGrid, {SimpleTileItem} from '../../../components/tile-grid';
import InlinePreviewImage from '../../../components/inline-preview/image';
import {ArticleImage} from '../../../types/graphql';
import articleImage from '../../../lib/articleImage';

interface LandingImagePart {
  url: string;
  alt: string;
}

export interface LandingImage extends LandingImagePart {
  xlarge: LandingImagePart;
  large: LandingImagePart;
  medium: LandingImagePart;
  small: LandingImagePart;
  xsmall: LandingImagePart;
}

interface LandingVideo {
  html: string;
}

interface LandingLink {
  link_type: string;
  url: string;
}

export interface LandingPageData {
  background_image: LandingImage;
  cta_1_copy: string;
  cta_1_link: LandingLink;
  cta_2_copy: string;
  cta_2_link: LandingLink;
  cta_3_copy: string;
  cta_3_link: LandingLink;
  divider_image: LandingImage;
  header: string;
  header_image: LandingImage;
  hero_1_copy: string;
  hero_1_image: LandingImage;
  hero_2_copy: string;
  hero_2_image: LandingImage;
  hero_3_copy: string;
  hero_3_image: LandingImage;
  hero_title: string;
  meta_description: string;
  meta_title: string;
  slug: string;
  strapline: string;
  video: LandingVideo
  video_title: string;
  video_copy: string;
}

export class LandingPageModel implements LandingPageData {
  @observable
  slug: string = null;

  @observable
  loading: boolean = true;

  @observable background_image: LandingImage;
  @observable cta_1_copy: string;
  @observable cta_1_link: LandingLink;
  @observable cta_2_copy: string;
  @observable cta_2_link: LandingLink;
  @observable cta_3_copy: string;
  @observable cta_3_link: LandingLink;
  @observable divider_image: LandingImage;
  @observable header: string;
  @observable header_image: LandingImage;
  @observable hero_1_copy: string;
  @observable hero_1_image: LandingImage;
  @observable hero_2_copy: string;
  @observable hero_2_image: LandingImage;
  @observable hero_3_copy: string;
  @observable hero_3_image: LandingImage;
  @observable hero_title: string;
  @observable meta_description: string;
  @observable meta_title: string;
  @observable strapline: string;
  @observable video: LandingVideo;
  @observable video_title: string;
  @observable video_copy: string;

  @computed get cta_1_signup(): boolean {
    return !!this.cta_1_link || !this.cta_1_link.url;
  }
  @computed get cta_2_signup(): boolean {
    return !!this.cta_2_link || !this.cta_2_link.url;
  }
  @computed get cta_3_signup(): boolean {
    return !!this.cta_3_link || !this.cta_3_link.url;
  }
}