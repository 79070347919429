/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { center, flexRoot, vertical } from 'csstips';
import { classes, style } from 'typestyle';
import { percent, px, quote } from 'csx';
import { Component, ComponentProps, deps, inject, observer } from '../../../lib/component';
import { fontstackDefault } from '../config';
import { colorGunmetal, rebrand } from '../../../theme/color';
import { transitionQuickEase } from '../../../theme/transition';
import { PlaylistAdded as Icon } from '../atoms/icons/PlaylistAdded';
import { dropdownDialogAnimation } from '../../../theme/animation';
import { screenReaderOnly } from '../utilities/font';
import { FindSortMode, FindSortOrder } from '../../../modules/page/search-mvp/model';
import { shadowSort } from '../../../theme/shadow';

const styles = {
  list: style({
    backgroundColor: rebrand.neutralOnDark['10'].toString(),
    color: rebrand.contrast['50'].toString(),
    padding: px(8),
    borderRadius: px(8),
    margin: 0,
    listStyle: 'none',
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
    boxShadow: shadowSort,
    width: px(250),
    $nest: {
      '&::-webkit-scrollbar': {
        $unique: true,
        width: px(4),
      },
      '&::-webkit-scrollbar-thumb': {
        $unique: true,
        background: rebrand.neutralOnLight['50'].toString(),
        cursor: 'pointer',
        borderRadius: px(8),
      },
    },
  }),
  animation: style({
    animation: `${dropdownDialogAnimation} .2s ease normal`,
  }),
  altText: style(screenReaderOnly),
  title: style({
    width: percent(100),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'pre',
  }),
  tooltipClass: style({
    maxWidth: px(500),
  }),
  button: style(flexRoot, {
    fontFamily: fontstackDefault,
    color: rebrand.contrast['50'].toString(),
    background: 'none',
    border: 'none',
    borderRadius: px(4),
    padding: px(8),
    fontSize: px(16),
    lineHeight: 'normal',
    textAlign: 'left',

    width: percent(100),
    alignItems: 'center',
    marginBottom: px(2),
    transition: `background-color ${transitionQuickEase}`,
    $nest: {
      '&:hover, &:focus, &.active': {
        backgroundColor: rebrand.neutralOnDark['20'].toString(),
      },
    },
  }),
  createButton: style({
    backgroundColor: rebrand.neutralOnDark['30'].toString(),
    $nest: {
      '&:hover, &:focus': {
        backgroundColor: rebrand.neutralOnDark['40'].toString(),
      },
    },
  }),

  addedPlaylistButton: style({
    backgroundColor: rebrand.primary['100'].toString(),
    color: rebrand.contrast['10'].toString(),
    $nest: {
      '&:hover': {
        backgroundColor: `${rebrand.primary['50'].toString()}  !important`,
      },
    },
  }),
};

@inject(deps)
@observer
export class SortDropdownDrawer extends Component<ComponentProps> {
  ref: HTMLUListElement = null;

  // The commented options will be returned back, once the graphQl sorting is done
  static SortOptions: { [key in FindSortMode]: string } = {
    // POPULAR_LICKD: 'Most Licensed on Lickd',
    POPULAR_SPOTIFY: 'Most Popular',
    // DATE_ADDED: 'Recently added',
    ALPHABETICAL: 'Alphabetical Z-A',
  };

  updateSort = (mode: FindSortMode, order: FindSortOrder) => {
    this.props.controller.page.searchMvp.refineSort(mode, order);
  };

  render() {
    const { sortMode, sortOrder } = this.props.model.page.searchMvp.searchState;
    const AscIsActive = sortMode === 'ALPHABETICAL' && sortOrder === 'ASC';
    return (
      <ul
        className={classes(styles.list, styles.animation)}
        ref={(ref) => (this.ref = ref)}
        data-test-sort-track-dialog
      >
        {Object.keys(SortDropdownDrawer.SortOptions).map((key) => {
          const order = 'DESC';
          const isActive = key === sortMode && sortOrder === order;
          return (
            <li key={key}>
              <button
                className={classes(styles.button, isActive && 'active')}
                onClick={() => this.updateSort(key as FindSortMode, order)}
                data-test-sort-track-item={key === 'ALPHABETICAL' ? 'ALPHABETICAL_Z-A' : key}
              >
                <span className={styles.title}> {SortDropdownDrawer.SortOptions[key]}</span>
                {isActive && <Icon color={colorGunmetal.toString()} />}
              </button>
            </li>
          );
        })}
        <li>
          <button
            className={classes(styles.button, AscIsActive && 'active')}
            onClick={() => this.updateSort('ALPHABETICAL', 'ASC')}
            data-test-sort-track-item={'ALPHABETICAL_A-Z'}
          >
            <span className={styles.title}> Alphabetical A-Z </span>
            {AscIsActive && <Icon color={colorGunmetal.toString()} />}
          </button>
        </li>
      </ul>
    );
  }
}
