import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalChildStyle } from './modal';
import { style } from 'typestyle';
import {
  betweenJustified, centerCenter, endJustified, horizontal, horizontallySpaced, start, startJustified, vertical,
  verticallySpaced
} from 'csstips';
import { ButtonPill } from './button-pill';
import { colorBrand, colorContrast, colorGunmetal, colorSpotify, colorSubtle, colorWhite } from '../theme/color';
import { resetAnchorStyles } from '../theme/reset';
import { em, percent, px } from 'csx';
import { SVGIcon } from './svg-icon';

export interface DownloadAccountDataModalModel {
  show: boolean;
  success: boolean;
  loading: boolean;
  saving: boolean;
  error: string;
}

export interface DownloadAccountDataModalProps {
  model: DownloadAccountDataModalModel;
  onRequestClose(): void;
  onDownload(): void;
  i18n?: {
    title: string;
    strap: any;
    loading: string;
    saving: string;
    download: string;
    success: string;
    close: string;
  }
}

@observer
export class DownloadAccountDataModal extends React.Component<DownloadAccountDataModalProps, any> {
  render() {
    const { model, i18n = DownloadAccountDataModal.i18n, onRequestClose, onDownload } = this.props;

    return (
      <Modal show={model.show} onRequestClose={onRequestClose}>
        <div className={DownloadAccountDataModal.styles.container}>
          <h3>{i18n.title}</h3>
          <p className={DownloadAccountDataModal.styles.paragraph}>{model.success ? i18n.success : i18n.strap}</p>
          {model.loading && (
            <div className={DownloadAccountDataModal.styles.buttonsDownloadAccountData}>
              <ButtonPill className={DownloadAccountDataModal.styles.button} disabled={true}>
                <span>{i18n.loading}</span>
              </ButtonPill>
            </div>
          )}
          {!model.loading && !model.success && (
            <div className={DownloadAccountDataModal.styles.buttonsDownloadAccountData}>
              <ButtonPill
                onClick={onDownload}
                disabled={model.saving}
                className={DownloadAccountDataModal.styles.button}
                primary={true}
              >
                {model.saving ? i18n.saving : i18n.download}
              </ButtonPill>
            </div>
          )}
          <div className={DownloadAccountDataModal.styles.buttons}>
            <ButtonPill onClick={onRequestClose}>{i18n.close}</ButtonPill>
          </div>
        </div>
      </Modal>
    );
  }

  static i18n = {
    title: 'Download my data',
    loading: 'Loading...',
    strap: 'All the data we hold about you will be sent to your email address.',
    download: 'Send me my data',
    saving: 'Sending data...',
    success:
      "All sent! You will receive an email shortly containing a copy of all your data on Lickd. If you don't hear from us in an hour, get in touch and we'll sort it.",
    close: 'Close',
  };

  static styles = {
    container: style(ModalChildStyle, {
      ...ModalChildStyle,
      width: px(420),
      maxWidth: 'calc(100vw - 4rem)',
      overflowX: 'hidden',
      $nest: {
        '&> h3': {
          color: colorBrand.toString(),
          fontSize: px(30),
          margin: '0 0 30px 0',
        },
      },
    }),
    paragraph: style({
      color: colorGunmetal.toString(),
      margin: '5px 0 30px 0',
      lineHeight: em(1.5),
    }),
    link: style({
      color: colorBrand.toString(),
      $nest: { ...resetAnchorStyles(colorBrand.toString()) },
    }),
    buttons: style({
      ...horizontal,
      ...horizontallySpaced(10),
      ...endJustified,
      margin: '30px 0 0 0',
    }),
    buttonsDownloadAccountData: style({
      ...vertical,
      ...verticallySpaced(10),
      ...centerCenter,
      margin: 0,
    }),
    button: style({
      width: percent(100),
    }),
    loading: style({
      padding: px(30),
      textAlign: 'center',
      color: colorSubtle.toString(),
    }),
    discount: style({
      textDecoration: 'line-through',
      marginRight: px(5),
    }),
  };
}