import * as React from 'react';
import {BrowseResultsProps} from './results';
import gql from 'graphql-tag';
import {graphql, InjectedGraphQLProps} from 'react-apollo';
import {Component, deps, inject, observer} from '../../lib/component';
import {style} from 'typestyle';
import {important, viewHeight} from 'csx';
import {centerCenter} from 'csstips';
import {ReleaseTileGrid, ReleaseTileItem} from '../tile-grid';
import {gradientDark} from '../../theme/gradient';
import {ReleaseSchema} from '../../types/schema';
import {TagTile} from '../tag-tile';
import {PaginationInput} from '../../types';

@inject(deps) @observer
class BrowseReleaseGrid extends Component<Props & InjectedGraphQLProps<Data>> {
  render() {
    const {data} = this.props;
    const {handleClickLink} = this.props.controller.ui;
    const {preloadImage} = this.props.controller.image;

    const tiles = data && data.tiles && data.tiles.results
      ? data.tiles.results.map(BrowseReleaseGrid.mapRelease)
      : [];

    return (
      <div className={BrowseReleaseGrid.styles.container}>
        <ReleaseTileGrid
          tiles={tiles}
          loading={data.loading}
          loadingTiles={12}
          loadingLight={true}
          renderTile={(itemClass, tile, index) => (
            <TagTile
              className={itemClass}
              key={tile.slug + index}
              tag={tile}
              href={BrowseReleaseGrid.releasePath(tile.model.artist.slug, tile.model.slug)}
              preload={preloadImage}
              onClickLink={handleClickLink}
            />
          )}
        />
      </div>
    )
  }

  static releasePath = (artistSlug: string, releaseSlug: string) => {
    return `/music/artists/${artistSlug}/release/${releaseSlug}`;
  };
  
  static mapRelease = (tile: Item): ReleaseTileItem => ({
    name: tile.title,
    slug: tile.slug,
    images: tile.images,
    model: tile as ReleaseSchema,
  });

  static styles = {
    container: style({
      background: gradientDark,
    }),
    loading: style({
      ...centerCenter,
      width: important('100%'),
      height: viewHeight(40)
    })
  }
}

type Props = {
  rightsholder: string;
  pagination: PaginationInput;
}

type Item = {
  title: string;
  slug: string;
  images: {
    identity: string;
  }
  artist: {
    slug
  }
}

type Data = {
  tiles: {
    results: Array<Item>
  }
}

export default BrowseReleaseGrid;

export const ConnectedBrowseRightsholderReleaseGrid = graphql(gql`
  query (
    $rightsholder: String
    $pagination: PaginationInput!
  ) {
    tiles: browseRelease(options:{type: RELEASE_RIGHTSHOLDER, query: $rightsholder, pagination: $pagination}) {
      results {
        title
        slug
        images {
          identity
        }
        artist {
          slug
        }
      }
    }
  }
`, {
  options: (variables: BrowseResultsProps) => ({
    variables,
    // fetchPolicy: 'network-only'
  }),
})(BrowseReleaseGrid);