import * as React from 'react';
import { Component } from 'react';
import { important, percent, px, rem } from 'csx';
import { colorBackground, colorBrand, colorContrast, colorGunmetal, colorWhite, rebrand } from '../theme/color';
import { classes, style } from 'typestyle';
import { centerCenter } from 'csstips';
import { SVGIcon } from './svg-icon';
import { observer } from 'mobx-react';

export interface AlertProps {
  className?: string;
  type?: 'info' | 'warning' | 'simple';
  textColor?: 'default' | 'secondary';
  position?: 'top-left' | 'top-right' | 'none';
  title?: string;
  onClose?(): void;
}

@observer
export class Alert extends Component<AlertProps, any> {
  render() {
    const { className, type = 'info', position = 'top-right', title, children, onClose, textColor } = this.props;

    return (
      <div
        className={classes(
          Alert.styles.alert,
          Alert.getType(type),
          Alert.getPosition(position),
          Alert.getColor(textColor),
          className
        )}
      >
        {title && <h5>{title}</h5>}
        {children}
        {onClose && (
          <div data-action="close" role="button" className={Alert.styles.closeAlert} onClick={onClose}>
            <SVGIcon.Cross size={16} />
          </div>
        )}
      </div>
    );
  }

  static getType = (type = 'info') => {
    switch (type) {
      case 'info':
        return Alert.styles.info;
      case 'warning':
        return Alert.styles.warning;
      case 'simple':
        return Alert.styles.simple;

      default:
        return null;
    }
  };

  static getPosition = (position = 'top-right') => {
    switch (position) {
      case 'top-right':
        return Alert.styles.topRight;
      case 'top-left':
        return Alert.styles.topLeft;

      default:
        return Alert.styles.hiddenArrow;
    }
  };

  static getColor = (color = 'default') => {
    switch (color) {
      case 'secondary':
        return Alert.styles.secondary;

      default:
        return;
    }
  };

  static styles = {
    alert: style({
      margin: '20px 0 0 0',
      padding: '25px 30px',
      position: 'relative',
      borderRadius: rem(1),
      $nest: {
        '&::after': {
          bottom: percent(100),
          right: px(70),
          border: '10px solid',
          content: '" "',
          height: 0,
          width: 0,
          position: 'absolute',
          pointerEvents: 'none',
          borderColor: 'transparent',
          marginLeft: px(-10),
        },
        '& p': {
          margin: 0,
        },
        '& h5': {
          margin: '0 0 20px 0',
          fontSize: px(18),
        },
      },
    }),
    warning: style({
      color: colorWhite.toString(),
      background: rebrand.primary1.toString(),
      $nest: {
        '&::after': {
          borderBottomColor: important(rebrand.primary1.toString()),
        },
      },
    }),
    info: style({
      color: colorWhite.toString(),
      background: rebrand.primary3.toString(),
      $nest: {
        '&::after': {
          borderBottomColor: important(rebrand.primary3.toString()),
        },
      },
    }),
    simple: style({
      color: colorGunmetal.toString(),
      background: colorBackground.lighten(0.05).toString(),
      $nest: {
        '& [data-action="close"] svg g': {
          stroke: colorBrand.toString(),
        },
        '&::after': {
          borderBottomColor: important(colorBackground.lighten(0.05).toString()),
        },
      },
    }),
    hiddenArrow: style({
      $nest: {
        '&::after': {
          display: 'none',
          margin: 0,
        },
      },
    }),
    topLeft: style({
      $nest: {
        '&::after': {
          bottom: percent(100),
          left: px(70),
        },
      },
    }),
    topRight: style({
      $nest: {
        '&::after': {
          bottom: percent(100),
          right: px(70),
        },
      },
    }),
    closeAlert: style({
      ...centerCenter,
      cursor: 'pointer',
      position: 'absolute',
      width: px(30),
      height: px(30),
      top: px(5),
      right: px(5),
    }),
    default: style({
      color: '#000000',
    }),
    secondary: style({
      color: '#841199',
    }),
  };
}
