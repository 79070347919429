import * as React from 'react';
import { classes, extend, style } from 'typestyle';
import { Component, ComponentProps, deps, inject, observer } from '../../../../lib/component';
import { ButtonProminence } from '../../atoms/button/buttonStyles';
import { centerCenter, centerJustified, flexRoot, vertical, wrap } from 'csstips';
import { percent, px } from 'csx';
import { ActionButton } from '../../atoms/button/ActionButton';
import { Collapsible } from '../../../collapsible/collapsible';
import collapsibleStyles from '../../../collapsible/styles';
import { PaymentPlanBlock } from '../../molecules/registration/PaymentPlanBlock';
import { LOAD_STATE } from '../../../../types/api';
import { RegistrationSteps } from '../../../../modules/page/registration/model';
import { fontstackDefault } from '../../config';
import { CheckmarkList } from '../../atoms/CheckmarkList';
import { InfoCircle } from '../../atoms/icons/InfoCircle';
import ToolTipModal, { ToolTipModalProps } from '../../molecules/ToolTipModal';
import { defaultHeadingStyles, headingStyles, headingTitle } from '../../utilities/font';
import { formatDecimalCurrency, formatNonDecimalCurrency } from '../../../../lib/currency';
import { SignUpToUnlockBlock } from '../../molecules/SignUpToUnlockBlock';
import { colorLickdPink, rebrand } from '../../../../theme/color';
import { ONBOARDING_STATE, SUPPORT_HUB } from '../../../../constants';
import { isEmpty } from '../../utilities/objects';
import { SKIP_BUTTON_ON_REGISTRATION_PLANS } from '../../../devtools/feature-flags';
import { SessionStorageModel } from '../../../../modules/storage/model';
import { COMPLETED_REGISTRATION_STEPS } from '../../../../types/user';

const { SECONDARY } = ButtonProminence;

const styles = {
  wrapper: style(flexRoot, centerJustified, wrap, {
    maxWidth: px(700),
    margin: '0 auto',
  }),
  introBlock: style({
    width: '100%',
  }),
  text: style(headingStyles, {
    textAlign: 'center',
    margin: '0 0 32px',
    $nest: {
      '& b': {
        display: 'block',
        fontWeight: 'bold',
      },
    },
  }),
  planWrapper: style({
    width: percent(100),
    maxWidth: px(320),
    marginRight: px(8),
    marginLeft: px(8),
    marginTop: px(24),
    $nest: {
      '& .payment-plan': {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: px(8),
      },
      '& .payment-plan:first-child': {
        marginTop: px(12),
      },
    },
  }),
  banner: style({
    textTransform: 'uppercase',
    height: px(69),
    $nest: {
      '& h2': { fontSize: '32px' },
    },
  }),
  paygPlanBanner: style({
    backgroundColor: rebrand.secondary[50].toString(),
  }),
  paidPlanBanner: style({
    backgroundColor: colorLickdPink.toString(),
  }),
  planButton: style({
    display: 'flex',
    margin: '16px 26px',
    width: 'calc(100% - 52px)',
  }),
  info: style({
    textAlign: 'center',
    fontSize: px(8),
    marginBottom: px(12),
    $nest: {
      '& h4': {
        fontFamily: fontstackDefault,
        fontSize: px(12),
        fontWeight: 'normal',
        marginTop: px(4),
        marginBottom: px(8),
        textDecoration: 'underline',
      },
      '& p': {
        marginTop: px(8),
        marginBottom: px(8),
      },
    },
  }),
  actions: style(flexRoot, vertical, centerCenter, { width: percent(100) }),
  subscribeError: style({
    color: 'red',
  }),
  subscribeCta: style({
    margin: '16px 0',
  }),
  skipCta: style({
    textDecoration: 'underline',
    fontSize: px(12),
  }),
  arrowBrandColour: style({
    backgroundColor: rebrand.contrast[30].toString(),
  }),
  pricingContentsSubscription: style({
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    whiteSpace: 'pre-wrap',

    $nest: {
      '& span': extend(defaultHeadingStyles, headingTitle),
    },
  }),
  pricingContentsColumn: style({ flexDirection: 'column' }),
  pricingContentText: style({
    marginTop: '0px',
    marginBottom: '4px',
  }),
  planHeadingInfoText: style({
    fontSize: '16px',
    fontWeight: 700,
    padding: '10px',
  }),
  planHeading: style({
    color: rebrand.contrast[90].toHexString(),
  }),
  boldFont: style({
    fontWeight: 700,
  }),
  cancelText: style({
    margin: 0,
    color: rebrand.neutralOnDark[50].toHexString(),
  }),
  removeBorder: style({
    borderStyle: 'none',
  }),
  skipButton: style({ marginBottom: '12px' }),
};

const tooltipStyles = style({
  maxWidth: px(320),
});

const PriceBandsTooltip: React.SFC<ToolTipModalProps> = ({ className, ...props }) => (
  <ToolTipModal className={classes(tooltipStyles, className)} {...props}>
    <h3>How are price bands calculated?</h3>
    <p>
      Our pricing structure is based on the average views your recent video uploads have received. When you sign-in to
      Lickd with your YouTube account we fetch your latest 50 videos and take an average of the video views. This allows
      us to estimate your audience size and put you into a price band.
    </p>
  </ToolTipModal>
);

@inject(deps)
@observer
export class PaymentPlans extends Component<ComponentProps> {
  state = {
    tooltipShown: false,
    subscriptionUrlLoading: false,
    subscriptionError: null,
    submittingOnboardingState: false,
  };

  constructor(props) {
    super(props);
    this.getSubscriptionUrl = this.getSubscriptionUrl.bind(this);
    this.handlePayg = this.handlePayg.bind(this);
  }

  async getSubscriptionUrl(): Promise<void> {
    try {
      this.setState({
        subscriptionUrlLoading: true,
      });
      await this.props.controller.subscription.navigateToStripeSubscription();
    } catch (error) {
      this.setState({
        subscriptionError: error instanceof Error ? error.message : error,
      });
    } finally {
      this.setState({
        subscriptionUrlLoading: false,
      });
    }
    this.props.controller.analytics.sendMixpanel('User clicks "Subscribe" button', {
      path: this.props.model.router.location.pathname,
    });
  }

  async handlePayg(): Promise<void> {
    try {
      this.setState({ submittingOnboardingState: true });
      await this.props.controller.user.markRegistrationStepAsComplete(COMPLETED_REGISTRATION_STEPS.PLAN_SELECTED);
    } catch (error) {
      this.setState({
        subscriptionError: error instanceof Error ? error.message : error,
      });
    } finally {
      this.setState({ submittingOnboardingState: false });
      this.props.model.router.push(`/registration/${RegistrationSteps.COMPLETE}`);
    }
    this.props.controller.analytics.sendMixpanel('User selects "Chart" plan', {
      path: this.props.model.router.location.pathname,
    });
  }

  async handlePlanSkip(): Promise<void> {
    try {
      this.setState({ submittingOnboardingState: true });
      await this.props.controller.user.markRegistrationStepAsComplete(COMPLETED_REGISTRATION_STEPS.PLAN_NOT_SELECTED);
    } catch (error) {
      this.setState({
        subscriptionError: error instanceof Error ? error.message : error,
      });
    } finally {
      this.setState({ submittingOnboardingState: false });
      this.props.model.router.push(`/dashboard`);
    }
    this.props.controller.analytics.sendMixpanel('user skips plans page', {
      path: this.props.model.router.location.pathname,
    });
  }

  userSkips(): void {
    this.props.controller.analytics.sendMixpanel('User clicks "Skip" button on payment plans', {
      path: this.props.model.router.location.pathname,
    });
  }

  render() {
    const {
      model: {
        subscription: {
          subscriptionPlansState,
          subscriptionPlansError,
          subscriptionPlan,
          subscriptionUnitPrice,
          subscriptionDecimalPrice,
          canSubscribe,
          potentialTrialEndDate,
          trialLengthInDays,
        },
        user: { user, isSubscribed, channel, unsubscribedRatecard, subscribedRatecard },
        page: {
          registration: { plan },
        },
      },
    } = this.props;

    const showSkipButton = SessionStorageModel.getItem(SKIP_BUTTON_ON_REGISTRATION_PLANS);

    return (
      <div className={styles.wrapper}>
        <PriceBandsTooltip
          show={this.state.tooltipShown}
          onRequestClose={() => this.setState({ tooltipShown: false })}
        />
        <div className={styles.introBlock}>
          <p className={styles.text}>
            You&apos;re just 2 clicks away from getting access to the best music for content creators.
            <b>To remind you, here&apos;s what&apos;s included...</b>
          </p>
          <SignUpToUnlockBlock />
        </div>
        {/* New temporary block */}
        <div className={styles.planWrapper} data-test-creator-plan-block>
          <PaymentPlanBlock>
            <div className={classes(styles.banner, styles.paidPlanBanner)}>
              <h2 className={styles.planHeading}>Creator</h2>
            </div>
            <Collapsible initial={true} className={styles.removeBorder}>
              <Collapsible.Summary className={collapsibleStyles.summaryLabel} style={{ borderStyle: 'none' }}>
                <div className={styles.planHeadingInfoText}>
                  For creators who want access to the best mainstream tracks and all of our royalty-free music
                </div>
              </Collapsible.Summary>
              <CheckmarkList>
                <li>
                  {!isEmpty(unsubscribedRatecard) && (
                    <p>
                      {' '}
                      Premium licenses from{' '}
                      <span className={styles.boldFont}>
                        {formatNonDecimalCurrency(
                          unsubscribedRatecard.value || unsubscribedRatecard.rate * 0.25,
                          unsubscribedRatecard.currency
                        )}
                      </span>
                      <br /> (Non-member price from{' '}
                      {formatNonDecimalCurrency(
                        unsubscribedRatecard.value || unsubscribedRatecard.rate,
                        unsubscribedRatecard.currency
                      )}
                      )
                    </p>
                  )}
                </li>
                <li>
                  <p>
                    Free <span className={styles.boldFont}>unlimited</span> royalty-free <br />
                    tracks
                  </p>
                </li>
                <li>
                  <p>All your copyright claims resolved</p>
                </li>
              </CheckmarkList>
            </Collapsible>
            <div className={classes(styles.pricingContentsSubscription, styles.pricingContentsColumn)}>
              <span className={styles.boldFont}>
                {subscriptionDecimalPrice % 100 === 0
                  ? formatNonDecimalCurrency(subscriptionUnitPrice, subscriptionPlan.currency_code)
                  : formatDecimalCurrency(subscriptionDecimalPrice, subscriptionPlan.currency_code)}
              </span>

              <p className={styles.pricingContentText}>per month</p>
              <p className={styles.cancelText}>Cancel Anytime</p>
            </div>
            <ActionButton
              className={styles.planButton}
              onClick={this.getSubscriptionUrl}
              loading={this.state.subscriptionUrlLoading}
              disabled={subscriptionPlansState !== LOAD_STATE.READY || (user && user.is_subscribed)}
              data-test-creator-plan-block-action-button
            >
              {trialLengthInDays === 0 ? 'Subscribe' : 'Start free trial'}
            </ActionButton>
          </PaymentPlanBlock>
          <div className={styles.info}>
            <InfoCircle size={24} />
            {!isSubscribed &&
              trialLengthInDays > 0 && [
                <p key={0}>Subscription will start after the trial period has ended.</p>,
                <p key={1}>Billing will commence on {potentialTrialEndDate}.</p>,
              ]}
            <p>A subscription is required for each YouTube channel</p>

            {!isSubscribed && trialLengthInDays === 0 && <p>Payment will be taken immediately and again in 30 days.</p>}

            {isSubscribed && (
              <>
                <p>You are already subscribed to this plan</p>
                <ActionButton
                  className={classes(styles.planButton)}
                  prominence={ButtonProminence.SECONDARY}
                  onClick={() => {
                    this.handlePayg();
                    this.userSkips();
                  }}
                  loading={this.state.submittingOnboardingState}
                >
                  Skip
                </ActionButton>
              </>
            )}

            <a href="https://lickd.co/terms-and-conditions/creator-plan" target="_blank" rel="noopener noreferrer">
              Subscription Terms & Conditions
            </a>
          </div>
        </div>

        <div className={styles.planWrapper} data-test-professional-plan-block>
          <PaymentPlanBlock>
            <div className={classes(styles.banner, styles.paygPlanBanner)}>
              <h2 className={styles.planHeading}>Professional</h2>
            </div>
            <Collapsible initial={true} className={styles.removeBorder}>
              <Collapsible.Summary className={collapsibleStyles.summaryLabel} style={{ borderStyle: 'none' }}>
                <div className={styles.planHeadingInfoText}>For creators with over 2M subscribers </div>
              </Collapsible.Summary>
              <CheckmarkList>
                <li>
                  <p>License top tracks for your videos</p>
                </li>
                <li>
                  <p>Music selection support</p>
                </li>
                <li>
                  <p>Partnership solutions</p>
                </li>
                <li>
                  <p>Get your copyright claims resolved</p>
                </li>
              </CheckmarkList>
            </Collapsible>
            <div className={classes(styles.pricingContentsSubscription, styles.pricingContentsColumn)}>
              <p>pricing</p>
              <span>TALK TO US</span>
            </div>
            <ActionButton
              className={classes(styles.planButton, styles.paygPlanBanner)}
              prominence={ButtonProminence.SECONDARY}
              href={
                'https://lickd.typeform.com/to/TRIr5olf#hubspot_utk=xxxxx&hubspot_page_name=xxxxx&hubspot_page_url=xxxxx'
              }
              loading={this.state.submittingOnboardingState}
              data-test-professional-plan-block-contact-us-button
            >
              Contact us
            </ActionButton>
          </PaymentPlanBlock>
        </div>

        {/* This is a temporary change and could be reintroduced  */}

        {/* <div className={styles.planWrapper}>
          <PaymentPlanBlock>
            <div className={classes(styles.banner, styles.paygPlanBanner)}>
              <h2>Premium only</h2>
            </div>
            <Collapsible initial={true}>
              <Collapsible.Summary className={collapsibleStyles.summaryLabel}>
                <h3>What you get</h3>
                <CollapsibleIndicator colour="white" />
              </Collapsible.Summary>
              <CheckmarkList>
                <li>
                  <p>Over 1 million mainstream tracks</p>
                </li>
                <li>
                  <p>Protection from copyright claims and demonetization</p>
                </li>
                <li>
                  <p>25% off your first track</p>
                </li>
              </CheckmarkList>
            </Collapsible>
            <div className={styles.pricingContentsSubscription}>
              <p>
                From{'\n'}
                <span>
                  {!isEmpty(unsubscribedRatecard) &&
                    formatNonDecimalCurrency(
                      unsubscribedRatecard.value || unsubscribedRatecard.rate,
                      unsubscribedRatecard.currency
                    )}
                </span>
                {'\n'}per Premium track
              </p>
            </div>
            <ActionButton
              className={classes(styles.planButton, styles.arrowBrandColour)}
              prominence={ButtonProminence.SECONDARY}
              onClick={this.handlePayg}
              loading={this.state.submittingOnboardingState}
            >
              Continue
            </ActionButton>
          </PaymentPlanBlock>
        </div> */}

        {/* This is a temporary change and could be reintroduced  */}

        {/* <div className={styles.planWrapper}>
          <PaymentPlanBlock>
            <div className={classes(styles.banner, styles.paidPlanBanner)}>
              <h2>Creator</h2>
            </div>
            <Collapsible initial={true}>
              <Collapsible.Summary className={collapsibleStyles.summaryLabel}>
                <h3>What you get</h3>
                <CollapsibleIndicator colour="white" />
              </Collapsible.Summary>
              <CheckmarkList>
                <li>
                  <p>Over 1 million mainstream tracks</p>
                </li>
                <li>
                  <p>Unlimited use of 100K royalty-free tracks</p>
                </li>
                <li>
                  <p>
                    <span style={{ fontWeight: 'bold' }}>75% off</span> all mainstream music*
                  </p>
                </li>
                <li>
                  <p>Music for all social platforms</p>
                </li>
                <li>
                  <p>Protection from copyright claims and demonetization</p>
                </li>
                <li>
                  <p>14-day free trial</p>
                </li>
              </CheckmarkList>
            </Collapsible>
            <div className={styles.pricingContentsSubscription}>
              <p>
                Monthly{'\n'}
                <span>
                  {subscriptionDecimalPrice % 100 === 0
                    ? formatNonDecimalCurrency(subscriptionUnitPrice, subscriptionPlan.currency_code)
                    : formatDecimalCurrency(subscriptionDecimalPrice, subscriptionPlan.currency_code)}
                </span>
                {'\n'} subscription
              </p>

              <AddCircle size={24} color={colorWhite.toString()} style={{ margin: '0 12px' }} />
              <p>
                From{'\n'}
                <span>
                  {!isEmpty(subscribedRatecard) &&
                    formatNonDecimalCurrency(
                      subscribedRatecard.value || subscribedRatecard.rate,
                      subscribedRatecard.currency
                    )}
                </span>
                {'\n'}per Premium track
              </p>
            </div>
            <ActionButton
              className={styles.planButton}
              onClick={this.getSubscriptionUrl}
              loading={this.state.subscriptionUrlLoading}
              disabled={subscriptionPlansState !== LOAD_STATE.READY || !canSubscribe}
            >
              {trialLengthInDays === 0 ? 'Subscribe' : 'Start free trial'}
            </ActionButton>
          </PaymentPlanBlock>
          <div className={styles.info}>
            <InfoCircle size={24} />
            {!isSubscribed &&
              trialLengthInDays > 0 && [
                <p key={0}>Subscription will start after the trial period has ended.</p>,
                <p key={1}>Billing will commence on {potentialTrialEndDate}.</p>,
              ]}
            <p>A subscription is required for each YouTube channel</p>
            <p>*compared to Premium Only</p>

            {!isSubscribed && trialLengthInDays === 0 && <p>Payment will be taken immediately and again in 30 days.</p>}
            {isSubscribed && (
              <>
                <p>You are already subscribed to this plan</p>
                <ActionButton
                  className={classes(styles.planButton)}
                  prominence={ButtonProminence.SECONDARY}
                  onClick={() => {
                    this.handlePayg();
                    this.userSkips();
                  }}
                  loading={this.state.submittingOnboardingState}
                >
                  Skip
                </ActionButton>
              </>
            )}
            <a href="https://lickd.co/terms-and-conditions/creator-plan" target="_blank" rel="noopener noreferrer">
              Subscription Terms & Conditions
            </a>
          </div>
        </div> */}

        {showSkipButton && (
          <ActionButton
            className={styles.skipButton}
            prominence={SECONDARY}
            loading={this.state.submittingOnboardingState}
            onClick={() => {
              this.handlePlanSkip();
            }}
          >
            Skip Plans
          </ActionButton>
        )}
        {subscriptionPlansState === LOAD_STATE.ERROR && (
          <p className={styles.subscribeError}>
            {subscriptionPlansError} <a href={SUPPORT_HUB}>Submit a support ticket</a> if this issue persists.
          </p>
        )}
        {this.state.subscriptionError && (
          <p className={styles.subscribeError}>
            {this.state.subscriptionError} <a href={SUPPORT_HUB}>Submit a support ticket</a> if this issue persists.
          </p>
        )}
      </div>
    );
  }
}
