import { computed, observable } from 'mobx';
import { ArtistSchema, TrackSchema } from '../../../types/schema';
import { UIHeroGenre } from '../../../types/ui';

export class TrackPageModel {
  @observable
  loading = true;

  @observable
  trackSlug: string = null;

  @observable
  artistSlug: string = null;

  @observable
  track: TrackSchema = null;

  @observable
  artists: Array<ArtistSchema> = [];

  @observable
  artist: ArtistSchema = null;

  @observable
  genres: Array<UIHeroGenre> = [];

  @observable
  pageBody: string = null;

  @observable
  mixes: Array<TrackSchema> = [];
}
