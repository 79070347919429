import * as React from 'react';
import { classes, style } from 'typestyle';
import { ArtistSchema } from '../../../types/schema';
import { Component, deps, inject } from '../../../lib/component';
import { observer } from 'mobx-react';
import { em, percent, px } from 'csx';
import { shadowElevation2 } from '../../../theme/shadow';
import { mixinTextEllipsis, overlayLink } from '../../../theme/mixin';
import { transitionQuickEase } from '../../../theme/transition';
import { UtmLink } from '../atoms/UtmLink';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { colorBlack, colorBrand, rebrand } from '../../../theme/color';
import { zLabel } from '../../../theme/z';

const styles = {
  wrapper: style({
    position: 'relative',
    paddingBottom: px(32),
    margin: '0 8px',
    $nest: {
      '& .favourites-button': {
        position: 'absolute',
        top: px(8),
        right: px(8),
      },
    },
  }),
  circularwrapper: style({
    position: 'relative',
    margin: '0 8px',
    $nest: {
      '& .favourites-button': {
        position: 'absolute',
        top: px(8),
        left: percent(75),
      },
    },
  }),
  img: style({
    width: percent(100),
    borderRadius: em(0.3),
    transition: `box-shadow ${transitionQuickEase}`,
    $nest: {
      ':hover > &': shadowElevation2,
    },
  }),
  trackInfo: style(
    {
      position: 'absolute',
      bottom: px(10),
      zIndex: 50,
      fontSize: px(12),
    },
    mediaUpToDesktop({
      width: 'calc(100% - 16px)',
    }),
    mediaDesktop({
      width: percent(100),
    })
  ),
  track: style({
    fontWeight: 600,
  }),
  trackLink: style(overlayLink, {
    color: 'inherit',
  }),
  trackType: style({
    color: colorBrand.toHexString(),
  }),
  trackLine: style(mixinTextEllipsis, {
    display: 'block',
    lineHeight: 'normal',
    $nest: {
      '&:hover': {
        textDecoration: 'underline',
      },
      '&, &:hover, &:active, &:visited': {
        color: colorBlack.toString(),
      },
    },
  }),
  overlayContent: style({ $nest: { '& > *': { zIndex: zLabel } } }),

  name: style({
    fontWeight: 700,
    fontSize: px(16),
    color: rebrand.contrast[50].toString(),
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  type: style({
    fontSize: px(16),
    fontWeight: 400,
    color: rebrand.neutralOnLight[70].toString(),
  }),

  radius: style({
    boxShadow: 'none',
    borderRadius: percent(100),
  }),
};

export type ArtistTileProps = {
  className?: string;
  artist: ArtistSchema;
  isSearchPage?: boolean;
  onClick?: () => void;
};

@inject(deps)
@observer
export class ArtistTile extends Component<ArtistTileProps> {
  render() {
    const { children, className, artist, model, isSearchPage = false, onClick } = this.props;
    return (
      <div onClick={onClick} className={classes(!isSearchPage ? styles.wrapper : styles.circularwrapper, className)}>
        <UtmLink href={`/music/artists/${artist.slug}`} className={styles.trackLink}>
          <img
            className={classes(styles.img, isSearchPage && styles.radius)}
            src={model.image.cover(artist.images.identity, {
              ignoreDimensions: true,
              width: 692,
              height: 692,
              crop: true,
            })}
            alt={artist.name}
          />
        </UtmLink>

        {isSearchPage ? (
          <div>
            <UtmLink data-test-top-solution-text-link={'artist'} href={`/music/artists/${artist.slug}`}>
              <span className={styles.name}>{artist.name}</span>
            </UtmLink>
            <span className={styles.type}>Artist</span>
          </div>
        ) : (
          <>
            <div className={styles.trackInfo}>
              <UtmLink href={`/music/artists/${artist.slug}`}>
                <span className={classes(styles.track, styles.trackLine)}>{artist.name}</span>
              </UtmLink>
            </div>
          </>
        )}
        <span className={styles.overlayContent}>{children}</span>
      </div>
    );
  }
}
