import * as React from 'react';
import { observer } from 'mobx-react';
import { style } from 'typestyle';
import { ScrollableOverlay } from '../../scrollable-overlay/scrollable-overlay';
import { Component, deps, inject } from '../../../lib/component';
import { px } from 'csx';
import { LickdRebrandLogo } from '../atoms/icons/brands/LickdRebrandLogo';
import { colorNeutralLight, rebrand } from '../../../theme/color';
import { ConnectChannelPrompt } from '../molecules/ConnectYoutubeChannelPrompt';
import { ConnectChannelInfoblock } from '../atoms/ConnectChannelInfoblock';
import { spacing } from '../../../theme/spacing';

export interface AddChannelForModalProps {
  show: boolean;
  onRequestClose(): void;
}
@inject(deps)
@observer
export class AddChannelForModal extends Component<AddChannelForModalProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      show,
      onRequestClose,
      controller: {
        modal: { showChannelAddModal },
        analytics: { sendMixpanel },
      },
      model: {
        router: {
          location: { pathname },
        },
      },
    } = this.props;

    const modalHeaderText =
      this.props.model.modal.addChannelModalContext === 'checkout' ? 'TO CHECKOUT' : 'TO PERSONALIZE YOUR MUSIC';

    const isCheckoutPage = this.props.model.router.location.pathname.includes('/checkout');

    const modifiedOnRequestClose = () => {
      if (isCheckoutPage) {
        this.props.model.router.push('/');
        onRequestClose();
      } else {
        onRequestClose();
      }
    };

    return (
      <ScrollableOverlay show={show} onRequestClose={modifiedOnRequestClose} className={styles.modal}>
        <LickdRebrandLogo className={styles.logo} color={rebrand.contrast[50].toHexString()} size={50} />
        <div className={styles.wrapper}>
          <h4>{modalHeaderText}, YOU&apos;LL NEED TO CONNECT YOUR YOUTUBE CHANNEL TO LICKD.</h4>
          <p>
            Pick the channel that you&apos;ll post videos you want to license music for. You can add multiple channels
            later on.
          </p>
          <ConnectChannelPrompt
            showChannelAddModal={showChannelAddModal}
            sendMixpanel={sendMixpanel}
            location={pathname}
          />
          <ConnectChannelInfoblock
            theme={{
              backgroundColor: rebrand.neutralOnDark[10].toHexString(),
              textColor: colorNeutralLight.toHexString(),
              iconColor: rebrand.contrast[50].toHexString(),
              strongTextColor: rebrand.contrast[50].toHexString(),
            }}
          />
        </div>
      </ScrollableOverlay>
    );
  }
}

const styles = {
  modal: style({ display: 'block', maxWidth: px(440), position: 'relative' }),
  wrapper: style({
    textAlign: 'center',
    padding: '40px 0 0',
    $nest: {
      '& h4': {
        fontSize: px(24),
        margin: `0 0 ${spacing.LARGE}`,
      },
      '& p': {
        color: rebrand.contrast[40].toHexString(),
        margin: 0,
      },
    },
  }),
  logo: style({
    position: 'absolute',
    top: px(16),
    left: px(16),
  }),
};
