import { CtaSlice } from '../organisms/slices/CtaSlice';
import { cssRule, style } from 'typestyle';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { px, viewWidth } from 'csx';
import { Button } from '../atoms/button/Button';
import { isEmpty } from '../utilities/objects';
import * as React from 'react';
import { PrismicCallToActionProps } from '../utilities/types';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { heading3 } from '../utilities/font';

const { PRIMARY } = ButtonProminence;

const styles = {
  headline: style(mediaDesktop(heading3)),
  button: style({
    fontSize: px(20),
    lineHeight: 1.4,
    padding: '6px 32px',
  }),
};

cssRule(
  `.cta-slice h3.${styles.headline}`,
  {
    marginBottom: px(32),
  },
  mediaUpToDesktop({ fontSize: viewWidth(8) })
);

export type PrismicHeadlineWithCallToActionPayload = PrismicCallToActionProps & {
  primary: {
    heading: string;
  };
};
export type PrismicHeadlineWithCallToActionProps = {
  payload: PrismicHeadlineWithCallToActionPayload;
};
export const PrismicHeadlineWithCallToAction: React.SFC<PrismicHeadlineWithCallToActionProps> = ({
  payload: {
    primary: { heading, call_to_action_url, call_to_action_text },
  },
}) => (
  <CtaSlice>
    <h3 className={styles.headline}>{heading}</h3>
    <Button
      prominence={PRIMARY}
      href={!isEmpty(call_to_action_url) && call_to_action_url.url}
      className={styles.button}
    >
      {call_to_action_text}
    </Button>
  </CtaSlice>
);
