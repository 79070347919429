import * as React from 'react';
import { Component, deps, inject, observer } from '../../lib/component';
import { HeaderBanner } from './HeaderBanner';
import { cssRule } from 'typestyle';
import { isArray, isEmpty } from '../project-happy/utilities/objects';
import { renderComponentsFromPrismicSlices } from '../project-happy/prismic-slices';
import { Head } from '../../containers/head';
import { Helmet } from 'react-helmet';
import { rebrandPageClass } from '../project-happy/utilities/page';
import { colorWhite } from '../../theme/color';

cssRule('html body', {
  backgroundColor: colorWhite.toHexString(),
});

@inject(deps)
@observer
export class LanderPage extends Component<Record<string, never>> {
  get model() {
    return this.props.model.page.lander;
  }

  getChildContext() {
    if (isEmpty(this.model.metadata)) return {};
    const { utm_source, utm_medium, utm_campaign } = this.model.metadata;
    return { utm_source, utm_medium, utm_campaign };
  }

  render() {
    const { context, slices } = this.model;
    if (!isArray(slices) || slices.length === 0) return null;

    return (
      <div className={rebrandPageClass}>
        <Head />
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <HeaderBanner />
        {renderComponentsFromPrismicSlices(slices, context)}
      </div>
    );
  }
}
