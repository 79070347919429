import * as React from 'react';
import { observer } from 'mobx-react';
import { centerJustified, flex1, vertical } from 'csstips';
import { em, important, px } from 'csx';
import { colorGreyLabel, colorWhite, rebrand } from '../theme/color';
import { classes, style } from 'typestyle';
import { ArtistLinkList } from './artist-link-list';
import { resetAnchorStyles } from '../theme/reset';
import { TrackSchema } from '../types/schema';
import { AnalyticsController } from '../modules/analytics/controller';
import { mediaUpToDesktop } from './project-happy/utilities/mediaQueries';
import styles from './collapsible/styles';
import { ExplicitTag } from './project-happy/atoms/ExplicitTag';

export interface PlayerBarNowPlayingModel {
  track: TrackSchema;
}

export interface PlayerBarNowPlayingProps {
  model: PlayerBarNowPlayingModel;
  onClickLink(event: React.MouseEvent<any>): void;
  i18n?: {
    nowPlaying: string;
    waiting: string;
  };
  analytics: AnalyticsController;
}

@observer
export class PlayerBarNowPlaying extends React.Component<PlayerBarNowPlayingProps, any> {
  render() {
    const { model, onClickLink, i18n = PlayerBarNowPlaying.i18n, analytics } = this.props;
    const isStockTrack = model.track && model.track.is_stock_music;
    const isExplicit = model.track && model.track.is_explicit;
    const trackIsavailable =
      model.track && model.track.is_available_in_territory === null
        ? true
        : model.track && model.track.is_available_in_territory;

    return (
      <div className={PlayerBarNowPlaying.styles.container}>
        {isStockTrack ? (
          <span
            className={classes(
              PlayerBarNowPlaying.styles.trackType,
              !trackIsavailable && PlayerBarNowPlaying.styles.unavailableTrackColor
            )}
          >
            Included
          </span>
        ) : (
          <span
            className={classes(
              PlayerBarNowPlaying.styles.trackType,
              !trackIsavailable && PlayerBarNowPlaying.styles.unavailableTrackColor
            )}
          >
            Premium
          </span>
        )}

        {model.track && (
          <div
            className={classes(
              PlayerBarNowPlaying.styles.truncate,
              !trackIsavailable && PlayerBarNowPlaying.styles.unavailableTrackColor
            )}
          >
            <div>
              {isExplicit ? <ExplicitTag /> : null}
              <span>
                <a
                  href={`/music/artists/${model.track.artists[0].slug}/track/${model.track.slug}`}
                  onClick={this.handleTrackClick.bind(this)}
                  className={classes(
                    PlayerBarNowPlaying.styles.trackLink,
                    !trackIsavailable && PlayerBarNowPlaying.styles.unavailableTrackColor
                  )}
                  data-test-player-track-title
                >
                  {model.track.title}
                </a>
              </span>
            </div>

            <ArtistLinkList
              model={model.track}
              onClickLink={onClickLink}
              linkClassName={classes(
                PlayerBarNowPlaying.styles.artistLink,
                !trackIsavailable && PlayerBarNowPlaying.styles.unavailableTrackColor
              )}
              className={PlayerBarNowPlaying.styles.truncate}
            />
          </div>
        )}
      </div>
    );
  }

  handleTrackClick = (event: React.MouseEvent<any>) => {
    this.props.analytics.sendMixpanel('User clicks track link', {
      title: this.props.model.track.title,
      slug: this.props.model.track.slug,
    });

    this.props.onClickLink(event);
  };

  static i18n = {
    nowPlaying: 'Now playing',
    waiting: 'Waiting...',
  };

  static styles = {
    container: style(
      {
        ...vertical,
        ...centerJustified,
        lineHeight: em(1.1),
        padding: '0 16px',
        color: colorWhite.toString(),
        fontSize: px(14),
        maxWidth: px(300),
      },
      mediaUpToDesktop({
        ...flex1,
        minWidth: px(70),
        padding: '0 0 0 16px',
      })
    ),
    trackLink: style({
      fontWeight: 600,
      lineHeight: em(1.1),
      color: colorWhite.toString(),
      $nest: {
        ...resetAnchorStyles(),
      },
    }),
    truncate: style({
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    artistLink: style({
      lineHeight: em(1.4),
      color: colorWhite.toString(),
      $nest: {
        ...resetAnchorStyles(),
      },
    }),
    trackType: style({
      lineHeight: em(1.4),
      color: colorGreyLabel.toString(),
    }),
    unavailableTrackColor: style({
      color: important(rebrand.contrast[40].toString()),
    }),
  };
}
