import React from 'react';
import { style } from 'typestyle';
import { CircleButton } from './CircleButton';
import { rebrand } from '../../../../theme/color';
import { ButtonProminence } from './buttonStyles';
import { px } from 'csx';
import { PlaylistSchema, TrackSchema } from '../../../../types/schema';
import { CloseCross } from '../icons/CloseCross';
import { Component, deps, inject, observer } from '../../../../lib/component';
import { ButtonProps } from './Button';
import { Tooltip } from '../Tooltip';
import siteEvents, { SITE_EVENTS } from '../../utilities/siteEvents';

export type RemoveFromPlaylistButtonProps = Pick<ButtonProps, 'onClick' | 'className'> & {
  track: Pick<TrackSchema, 'identity' | 'title'>;
  playlist: Pick<PlaylistSchema, 'identity' | 'name'>;
};

@inject(deps)
@observer
export class RemoveFromPlaylistButton extends Component<RemoveFromPlaylistButtonProps> {
  state = {
    loading: false,
  };

  constructor(props) {
    super(props);
    this.removeFromPlaylist = this.removeFromPlaylist.bind(this);
  }

  async removeFromPlaylist(event) {
    this.setState({ loading: true });
    try {
      await this.props.controller.api.playlist.removeTrackFromUserPlaylist(
        this.props.model.user.user.identity,
        this.props.playlist.identity,
        this.props.track.identity
      );
      siteEvents.emit(SITE_EVENTS.USER_PLAYLIST_TRACK_REMOVE, {
        track: this.props.track.identity,
        playlist: this.props.playlist.identity,
      });

      await this.props.controller.analytics.sendMixpanel('User removes a track from playlist', {
        track_name: this.props.track.title,
        playlist_name: this.props.playlist.name,
      });
    } catch (error) {
      this.props.controller.bugsnag.notifyException(error);
    }
    this.setState({ loading: false });
    this.props.onClick(event);
  }

  render() {
    const { className } = this.props;
    return (
      <Tooltip content={<>Remove from playlist</>} positions={['top']}>
        <CircleButton
          prominence={ButtonProminence.SECONDARY}
          onClick={this.removeFromPlaylist}
          label="Remove from playlist"
          disabled={this.state.loading}
          data-test-remove-from-playlist-button
          className={className}
        >
          <CloseCross />
        </CircleButton>
      </Tooltip>
    );
  }
}
