import { action } from 'mobx';
import Prismic from 'prismic-javascript';
import { Logger } from 'winston';
import { AnyObject, crawlData, DataHandler } from '../../components/project-happy/utilities/objects';
import { resolveImageUrl } from '../../components/project-happy/utilities/prismic';
import { getUid } from '../../components/project-happy/utilities/string';
import { EnvModel } from '../env/model';
import { ImageModel } from '../image/model';
import { RouterModel } from '../router/model';

type LickdPrismicOptions = {
  resolveImages?: boolean;
};

type PrismicPayload = { id: string; uid: string; type: string; data: AnyObject };

type PrismicResults = { results: PrismicPayload[] };

const splitOptions = ({
  resolveImages,
  ...apiOptions
}: AnyObject): { responseOptions: LickdPrismicOptions; apiOptions: AnyObject } => ({
  responseOptions: { resolveImages },
  apiOptions,
});

export class PrismicController {
  private defaultQueryOptions: { ref?: string } = {};
  private imageResolver: DataHandler;

  constructor(private env: EnvModel, private router: RouterModel, private image: ImageModel, private logger: Logger) {
    this.imageResolver = resolveImageUrl(image);
    if (env.prismicRefOverride) {
      this.defaultQueryOptions.ref = env.prismicRefOverride;
    }
  }

  private getApi = async () => Prismic.getApi(this.env.prismicEndpoint, {
    requestHandler: {
      request: async (url, callback) => {
        const result = await fetch(url, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'User-Agent': this.env.userAgent,
          },
        });

        const json = await result.json();

        const cacheControl = result.headers.get('cache-control')
        const parsedCacheControl = cacheControl
          ? /max-age=(\d+)/.exec(cacheControl)
          : null
        const ttl = parsedCacheControl
          ? parseInt(parsedCacheControl[1], 10)
          : undefined

        callback(null, json, result, ttl)
      }
    }
  });

  private getLandingApi = async () => Prismic.getApi(this.env.prismicLandingEndpoint);

  private resolveOptions = (options?: any) => Object.assign({}, this.defaultQueryOptions, options);

  private resolveResponse = (data: any, { resolveImages }: LickdPrismicOptions) => {
    if (resolveImages === true) {
      crawlData(data, [this.imageResolver]);
    }

    return data;
  };

  @action
  getSingle = async (key: string, options: AnyObject = {}): Promise<PrismicPayload> => {
    const prismicRequest = { id: getUid(), key, options: splitOptions(options) };
    const api = await this.getApi();

    this.logger.info('Prismic request - start', { prismicRequest });

    try {
      const response = await api.getSingle(prismicRequest.key, this.resolveOptions(prismicRequest.options.apiOptions));

      return this.resolveResponse(response, prismicRequest.options.responseOptions);
    } finally {
      this.logger.info('Prismic request - finished', { prismicRequest });
    }
  };

  @action
  getByTag = async (tag: string, options: AnyObject = {}): Promise<PrismicResults> => {
    const prismicRequest = { id: getUid(), predicate: [Prismic.Predicates.at('document.type', tag)], options: splitOptions(options) };
    const api = await this.getApi();

    this.logger.info('Prismic request - start', { prismicRequest });

    try {
      const response = await api.query(prismicRequest.predicate, this.resolveOptions(prismicRequest.options.apiOptions));

      return this.resolveResponse(response, prismicRequest.options.responseOptions);
    } finally {
      this.logger.info('Prismic request - finished', { prismicRequest });
    }
  };

  @action
  query = async (predicate: any, options: AnyObject = {}): Promise<PrismicResults> => {
    const prismicRequest = { id: getUid(), predicate, options: splitOptions(options) };
    const api = await this.getApi();

    this.logger.info('Prismic request - start', { prismicRequest });

    try {
      const response = await api.query(prismicRequest.predicate, this.resolveOptions(prismicRequest.options.apiOptions));

      return this.resolveResponse(response, prismicRequest.options.responseOptions);
    } finally {
      this.logger.info('Prismic request - finished', { prismicRequest });
    }
  };

  @action
  queryLanding = async (predicate: any, options: AnyObject = {}): Promise<PrismicResults> => {
    const prismicRequest = { id: getUid(), predicate, options: splitOptions(options) };
    const api = await this.getLandingApi();

    this.logger.info('Prismic request - start', { prismicRequest });

    try {
      const response = await api.query(prismicRequest.predicate, this.resolveOptions(prismicRequest.options.apiOptions));

      return this.resolveResponse(response, prismicRequest.options.responseOptions);
    } finally {
      this.logger.info('Prismic request - finished', { prismicRequest });
    }
  };
}
