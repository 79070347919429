import * as React from 'react';
import { observer } from 'mobx-react';
import { style } from 'typestyle';
import { constantHeaderHeight } from '../theme/constant';
import { percent, px } from 'csx';
import { Alert } from './alert';
import { zModal } from '../theme/z';
import { centerCenter } from 'csstips';
import { colorWhite } from '../theme/color';

const PrismicDOM = require('prismic-dom');

export interface AppCookieNotificationModel {
  show: boolean;
  title: string;
  content: any;
}

export interface AppCookieNotificationProps {
  model: AppCookieNotificationModel;
  onClose(): void;
}

@observer
export class AppCookieNotification extends React.Component<AppCookieNotificationProps, any> {
  render() {
    const { model, onClose } = this.props;

    if (!model.show) return null;

    return (
      <div className={AppCookieNotification.styles.container} onClick={this.handleClick}>
        <Alert title={model.title} onClose={onClose} className={AppCookieNotification.styles.alert} position="none">
          <div dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(model.content) }} />
        </Alert>
      </div>
    )
  }

  handleClick = (event: React.MouseEvent<any>) => {
    const target = (event.target as HTMLAnchorElement);
    if (target.pathname) {
      event.preventDefault();
    }
  };

  static styles = {
    container: style({
      ...centerCenter,
      zIndex: zModal,
      position: 'fixed',
      bottom: px(10),
      left: px(10),
      right: px(10)
    }),
    alert: style({
      maxWidth: px(640),
      $nest: {
        '& a': {
          color: colorWhite.toString(),
          textDecoration: 'underline'
        }
      }
    })
  }
}