import { computed, observable } from 'mobx';
import { StorageModel } from '../storage/model';
import { EnvModel } from '../env/model';
import { RouterModel } from '../router/model';
import { User } from '../../types/user';
import getCookieMap from '../../lib/getCookieMap';
import Cookies from 'js-cookie';
import { isEmpty } from '../../components/project-happy/utilities/objects';
import { browserRegion } from '../../components/project-happy/utilities/browserRegion';
export class AuthModel {
  constructor(private env: EnvModel, private storage: StorageModel, private router: RouterModel) {
    this.loadInitial();

    // Set JWT token
    const { token } = getCookieMap(document.cookie) as { token?: string };
    if (typeof token === 'string' && token.length > 0) {
      this._token = token;
    }
    // In WEB-4292 we stopped using localStorage to store the JWT and relied on the token cookie itself
    const legacyToken = StorageModel.getItem(TOKEN);
    // If there isn't a token cookie, but there is a token in localStorage, set it back to a cookie for continuity
    if (this._token === null && typeof legacyToken === 'string' && legacyToken.length > 0) {
      const cookieOptions: { domain: string; expires?: number } = { domain: this.env.rootDomain };
      // Attempt to align the cookie expiry with the JWTs expiry
      try {
        const expirationTimestamp = JSON.parse(atob(legacyToken.split('.')[1])).exp * 1000;
        const expireDays = Math.floor((expirationTimestamp - Date.now()) / 86400000);
        if (Number.isFinite(expireDays)) {
          cookieOptions.expires = expireDays;
        }
      } catch {
        // If we can't parse the expiry from the JWT, don't set an expiry, the cookie will expire at the end of the session
      }
      Cookies.set(TOKEN, legacyToken, cookieOptions);
      this._token = legacyToken;
    }
    // Delete the legacy JWT token to ensure stale JWTs aren't left lying around
    StorageModel.delete(TOKEN);

    // Load in user model from sessionStorage
    if (!env.isServer && USER in window.sessionStorage) {
      try {
        this.user = JSON.parse(window.sessionStorage.getItem(USER));
      } catch {
        // The stored user model is malformed
      }
    }

    this.userReady =
      !isEmpty(this.user) || env.isServer // If the user was populated from cache or for SSR (where there is no user state)...
        ? Promise.resolve() // ...resolve the ready signal immediately...
        : // ...else provide an unresolved promise...
          new Promise((resolve) => {
            this.signalUserReady = resolve; // ...and extract the resolver function to enable the model to ready later
          });
  }

  loadInitial = () => {
    if (!this.env.isServer && document.referrer) {
      const matches = /(?:https?:\/\/)?([^/]+).*$/.exec(document.referrer);

      this.referrerUrl = matches.length >= 2 ? matches[1] : '';
    }
  };

  _token = null; // Not observable as it should not change after initialisation

  // Use a mobx computed getter to ensure the value can never be changed after initialisation
  @computed
  get token(): string | null {
    return this._token;
  }

  @observable
  mustReAuth = false;

  @observable
  hasFetched = false;

  @observable
  hasBetaInvite = false;

  @observable
  referrerUrl: string = null;

  @observable
  returnUrl: string | null = null;

  @computed
  get isPermitted(): boolean {
    return true; // Allow everyone to sign up
  }

  @observable
  user: User = null;

  @observable
  userReady: Promise<void>;

  signalUserReady: () => void = () => void 0;

  @computed
  get youtubeLoginURL(): string {
    return this.youtubeRedirect(this.returnUrl || this.router.previousRoute);
  }

  youtubeRedirect = (redirect: string) => {
    return (
      this.env.authUri +
      '/auth/youtube/redirect?skip_waiting=true&redirect_uri=' +
      `${encodeURIComponent(redirect)}` +
      (!this.referrerUrl ? '' : '&referrer_url=' + encodeURIComponent(this.referrerUrl))
    );
  };

  @computed
  get youtubeChannelLoginURL(): string {
    return this.youtubeChannelRedirect(this.router.location.pathname);
  }

  youtubeChannelRedirect = (redirect: string) => {
    return (
      this.env.authUri +
      '/auth/youtube/channel/redirect?select_account=true&redirect_uri=' +
      encodeURIComponent(redirect) +
      '&token=' +
      encodeURIComponent(this.token)
    );
  };

  @computed
  get spotifyLoginURL(): string {
    return this.spotifyRedirect(this.router.location.pathname);
  }

  spotifyRedirect = (redirect: string) => {
    return (
      this.env.authUri +
      '/auth/spotify/redirect?redirect_uri=' +
      encodeURIComponent(redirect) +
      '&token=' +
      encodeURIComponent(this.token)
    );
  };

  @computed
  get twitterLoginURL(): string {
    return this.twitterRedirect(this.router.location.pathname);
  }

  @computed
  get twitterLoginURLWithFollow(): string {
    return this.twitterRedirect(this.router.location.pathname) + '&follow_us=true';
  }

  twitterRedirect = (redirect: string) => {
    return (
      this.env.authUri +
      '/auth/twitter/redirect?redirect_uri=' +
      encodeURIComponent(redirect) +
      '&token=' +
      encodeURIComponent(this.token)
    );
  };

  @computed
  get dropboxLoginURL(): string {
    return this.dropboxRedirect(this.router.location.pathname);
  }

  dropboxRedirect = (redirect: string) => {
    return (
      this.env.authUri +
      '/auth/dropbox/redirect?redirect_uri=' +
      encodeURIComponent(redirect) +
      '&token=' +
      encodeURIComponent(this.token)
    );
  };

  @computed
  get facebookLoginURL(): string {
    return this.facebookRedirect(this.router.location.pathname);
  }

  facebookRedirect = (redirect: string) => {
    return this.env.authUri + '/auth/facebook/redirect?redirect_uri=' + encodeURIComponent(redirect);
  };
}

export const USER = 'user';
export const TOKEN = 'token';
