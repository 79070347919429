import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const CreditCard: React.SFC<SVGIconProps> = ({ size = 20, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 20 16" {...props}>
    <path
      fill={color}
      d="M18 0H2C.89 0 .01.89.01 2L0 14c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V2c0-1.11-.89-2-2-2Zm0 14H2V8h16v6Zm0-10H2V2h16v2Z"
      fillRule="evenodd"
    />
  </BaseSvg>
);
