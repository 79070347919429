import { observable } from 'mobx';
import TileGrid, { SimpleTileItem } from '../../../components/tile-grid';

export class FakeDoorPageModel {
  @observable
  slug: string = null;

  @observable
  loading = false;

  @observable
  pageTitle: string = null;

  @observable
  form: any = null;

  @observable
  mobileHeader: any = null;

  @observable
  desktopHeader: any = null;
}
