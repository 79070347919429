import * as React from 'react';
import { classes, style } from 'typestyle';
import { betweenJustified, endJustified, horizontal, start, vertical } from 'csstips';
import { colorBlack, colorWhite } from '../theme/color';
import { percent, px, rem } from 'csx';
import { ResponsiveImageTile } from './responsive-image-tile';
import { ImagePreloader } from '../types/fn';
import { shadowFirm } from '../theme/shadow';

export interface ArtistTileProps {
  artist: {
    name: string;
    slug: string;
    images: {
      identity: string;
    };
  };
  className?: string;
  onClickLink(event: React.MouseEvent<any>): void;
  preload: ImagePreloader;
  ratio?: number;
  Favourite?: any;
  withLabel?: boolean;
}

export class ArtistTile extends React.Component<ArtistTileProps, any> {
  render() {
    const { artist, onClickLink, ratio = 1, preload, className, Favourite, withLabel } = this.props;

    const props = {
      href: `/music/artists/${artist.slug}`,
      identity: artist.images.identity,
      onClick: onClickLink,
      className,
      preload,
      ratio,
    };

    return (
      <ResponsiveImageTile {...props}>
        <div data-test-artist-tile={artist.name} title={artist.name} className={ArtistTile.styles.container}>
          <div className={ArtistTile.styles.buttons}>
            {Favourite && <Favourite artist={artist} withLabel={withLabel} />}
          </div>
          <div className={classes(ArtistTile.styles.name, 'name-banner')}>{artist.name}</div>
        </div>
      </ResponsiveImageTile>
    );
  }

  static styles = {
    container: style({
      ...vertical,
      ...start,
      ...betweenJustified,
      textOverflow: 'hidden',
      overflow: 'hidden',
      cursor: 'pointer',
      width: percent(100),
    }),
    name: style({
      padding: rem(0.5),
      background: colorBlack.fade(0.9).toString(),
      fontSize: rem(1),
      lineHeight: rem(1),
      width: percent(100),
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'pre',
      color: colorWhite.toString(),
      textShadow: shadowFirm,
      $nest: {
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    }),
    buttons: style({
      ...horizontal,
      ...endJustified,
      width: percent(100),
      padding: px(10),
    }),
  };
}
