import { ChannelReferralPageModel } from './model';
import { action } from 'mobx';
import { RedirectFunction, RouterState } from 'react-router';
import { stripQueryString } from '../../../lib/string';
import { LICKD_YOUTUBE_CHANNEL_ID, REFERRER_CHANNEL_KEY, REFERRER_ID } from '../../../constants';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import { AuthModel } from '../../auth/model';
import { isEmpty } from '../../../components/project-happy/utilities/objects';
import { APIController } from '../../api/controller';
import Cookies from 'js-cookie';
import { PrismicController } from '../../prismic/controller';

export class ChannelReferralPageController {
  constructor(
    private model: ChannelReferralPageModel,
    private env: EnvModel,
    private ui: UIController,
    private authModel: AuthModel,
    private api: APIController,
    private prismic: PrismicController
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    const slug = stripQueryString(nextState.params['user']) || LICKD_YOUTUBE_CHANNEL_ID;
    const isLoggedOut = typeof this.authModel.token !== 'string' || this.authModel.token.length === 0;
    const isYoutubeChannelId = slug.startsWith('@');
    this.model.loading = true;

    if (isYoutubeChannelId) {
      // using channelId
      if (this.model.channel === null && isLoggedOut) {
        this.model.channel = await this.api.youtube.getChannel(slug);
      }
      if (!isEmpty(this.model.channel) && isLoggedOut) {
        const { id, name } = this.model.channel;
        await this.ui.setSEO('/r/:user', { user: name });
        if (!EnvModel.isServer) {
          Cookies.set(REFERRER_CHANNEL_KEY, id, {
            domain: this.env.rootDomain,
            path: '/',
          });
        }
      }
    } else {
      // using userId
      if (this.model.referrer === null && isLoggedOut) {
        const referrer = await this.api.user.getReferrer(slug);
        if (referrer.success) {
          this.model.referrer = referrer;
        }
      }
      if (!isEmpty(this.model.referrer) && isLoggedOut) {
        const { name } = this.model.referrer;
        await this.ui.setSEO('/r/:user', { user: name });
        if (!EnvModel.isServer) {
          Cookies.set(REFERRER_ID, slug, {
            domain: this.env.rootDomain,
            path: '/',
          });
        }
      }
    }

    this.model.loading = false;

    // Prismic api call
    if (this.model.slices.length > 0 && !isEmpty(this.model.singleDatas)) return;
    const documentId = 'referee_page';

    await this.env.ready;

    const content = await this.prismic.getSingle(documentId, { resolveImages: true });
    const { slices, big_creators_heading, big_creators_image, partners_heading, partners_logos } = content.data;

    const singleDatas = {
      big_creators_heading,
      big_creators_image,
      partners_heading,
      partners_logos,
    };
    this.model.slices = slices;
    this.model.singleDatas = singleDatas;
  };
}
