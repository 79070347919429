import * as React from 'react';
import { style } from 'typestyle';
import { zAppHeaderContainer, zFlash } from '../../../theme/z';
import { px } from 'csx/lib/internal';
import { percent } from 'csx';
import { betweenJustified, center, flexRoot } from 'csstips';
import { ButtonProminence } from '../atoms/button/buttonStyles';
import { CloseCross } from '../atoms/icons/CloseCross';
import { CircleButton } from '../atoms/button/CircleButton';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { observable } from 'mobx';
import { observer } from '../../../lib/component';
import { transitionQuickEase, transitionSlideFromAbove, transitionSlideFromTop } from '../../../theme/transition';
import { desktopContentWidth, headerFullHeight } from '../config';
import { mediaDesktop, mediaLargeMobile, mediaUpToDesktop, mediaUpToLargeMobile } from '../utilities/mediaQueries';
import { animationFadeIn, animationSlideUp } from '../../../theme/animation';
import siteEvents, { SITE_EVENTS } from '../utilities/siteEvents';
import { getUid } from '../utilities/string';
import { rebrand } from '../../../theme/color';
import { shadowElevation1 } from '../../../theme/shadow';

const styles = {
  container: style(
    {
      zIndex: zAppHeaderContainer - 1,
      transform: `translate3d(0, 0, ${zFlash})`,
      position: 'fixed',
      top: px(headerFullHeight + 8),
      padding: px(4),
      boxSizing: 'border-box',
      width: percent(100),
    },
    mediaUpToLargeMobile({
      right: 0,
    }),
    mediaLargeMobile(
      {
        maxWidth: px(420),
      },
      mediaUpToDesktop({
        right: px(16),
      })
    ),
    mediaDesktop({
      right: `calc((100% - ${desktopContentWidth}px) / 2)`,
    })
  ),
  toast: style(flexRoot, betweenJustified, center, {
    position: 'relative',
    minHeight: px(100),
    boxSizing: 'border-box',
    cursor: 'default',
    marginBottom: px(8),
    height: px(100),
    width: percent(100),
    zIndex: 1,
    borderRadius: px(4),
    boxShadow: '0px 6px 12px rgba(38, 46, 63, 0.17)',
    background: 'white',
    transition: `box-shadow ${transitionQuickEase}`,
    $nest: {
      '&:hover, &:focus-within': shadowElevation1,
    },
  }),
  image: style({
    height: px(100),
    flexShrink: 0,
    borderTopLeftRadius: px(4),
    borderBottomLeftRadius: px(4),
  }),
  content: style({
    marginLeft: px(24),
    marginRight: px(24),
    $nest: {
      'a&': {
        $nest: {
          '&:hover, &:focus, &:focus-visible': {
            textDecoration: 'none',
          },
        },
      },
    },
  }),
  title: style({
    color: rebrand.contrast[50].toString(),
    fontWeight: 700,
    margin: 0,
  }),
  message: style({
    color: rebrand.neutralOnLight[60].toString(),
    fontSize: px(12),
    margin: 0,
  }),
  closeButton: style({
    flexShrink: 0,
    marginRight: px(24),
    boxShadow: 'none',
  }),
};

const notifications = observable.map();

type Notification = {
  readonly uid: string;
  image?: string;
  title?: string;
  message?: string | JSX.Element;
  timeout?: number | false;
  link?: string;
};
type UserProvidedNotificationDetails = Omit<Notification, 'uid'>;

siteEvents.addListener(SITE_EVENTS.SPAWN_NOTIFICATION, (payload) => {
  if (typeof payload !== 'object') return;
  const notification = payload as UserProvidedNotificationDetails;
  const uid = getUid();
  const obj = { uid, ...notification };
  notifications.set(uid, obj);
  const timeout = typeof notification.timeout !== 'undefined' ? notification.timeout : 10000;
  if (typeof timeout === 'number') {
    setTimeout(() => notifications.delete(uid), timeout);
  }
});

export const notify = (payload: UserProvidedNotificationDetails) =>
  siteEvents.emit(SITE_EVENTS.SPAWN_NOTIFICATION, payload);

export const clearNotifications = () => notifications.clear();

@observer
export class Notifications extends React.Component {
  render() {
    return (
      <CSSTransition in={notifications.size > 0} {...transitionSlideFromTop}>
        <div data-test-notifications className={styles.container}>
          <TransitionGroup component={null}>
            {Array.from(notifications.values()).map(({ uid, image, title, message, link }) => {
              const hasLink = typeof link === 'string' && link.length > 0;
              const Content = (props) => (hasLink ? <a href={link} {...props} /> : <div {...props} />);
              return (
                <CSSTransition key={uid} {...transitionSlideFromAbove}>
                  <div data-test-notification-component className={styles.toast}>
                    {image && <img className={styles.image} src={image} />}
                    <Content className={styles.content}>
                      {title && (
                        <h5 data-test-notification-title className={styles.title}>
                          {title}
                        </h5>
                      )}
                      {message && (
                        <p data-test-notification-message className={styles.message}>
                          {message}
                        </p>
                      )}
                    </Content>
                    <CircleButton
                      prominence={ButtonProminence.SECONDARY}
                      className={styles.closeButton}
                      onClick={() => notifications.delete(uid)}
                    >
                      <CloseCross />
                    </CircleButton>
                  </div>
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        </div>
      </CSSTransition>
    );
  }
}
