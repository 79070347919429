import * as React from 'react';
import { classes, media, style } from 'typestyle';
import { flexRoot } from 'csstips';
import { BasePageCarousel } from '../atoms/carousel/BasePageCarousel';
import { PageCarousel, PageCarouselProps } from './PageCarousel';
import { ChevronLeft } from '../atoms/icons/ChevronLeft';
import { ChevronRight } from '../atoms/icons/ChevronRight';
import { CircleButton } from '../atoms/button/CircleButton';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { px } from 'csx';
import { mediaFinePointer } from '../../../theme/media';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { desktopBreakpoint, largeMobileBreakpoint } from '../config';

const { SECONDARY } = ButtonProminence;

const desktopWithButtonsBreakpoint = desktopBreakpoint + 80;
const mediaUpToDesktopWithButtons = (...styles: NestedCSSProperties[]) =>
  media({ maxWidth: desktopWithButtonsBreakpoint - 1 }, ...styles);
const mediaDesktopWithButtons = (...styles: NestedCSSProperties[]) =>
  media({ minWidth: desktopWithButtonsBreakpoint }, ...styles);

const styles = {
  wrapper: style({ position: 'relative' }),
  button: style(
    {
      position: 'absolute',
      top: 'calc(50% - 16px)',
    },
    mediaUpToDesktopWithButtons(
      {
        display: 'none',
      },
      mediaFinePointer({
        display: 'flex',
        zIndex: 2,
      })
    ),
    mediaDesktopWithButtons(flexRoot)
  ),
  previous: style(
    mediaUpToDesktopWithButtons(
      mediaFinePointer({
        left: 0,
      })
    ),
    mediaDesktopWithButtons({
      left: px(-40),
    })
  ),
  next: style(
    mediaUpToDesktopWithButtons(
      mediaFinePointer({
        right: 0,
      })
    ),
    mediaDesktopWithButtons({
      right: px(-40),
    })
  ),
};

export type PageCarouselWithControlsProps = PageCarouselProps & { carouselClassName?: string };

export class PageCarouselWithControls extends PageCarousel<PageCarouselWithControlsProps> {
  scrollPrevious() {
    if (this.workingPage >= 2) {
      this.updatePage(Math.max(1, this.workingPage - 1));
    } else {
      this.updatePage(this.state.pages);
    }
  }

  scrollNext() {
    if (this.state.pages - this.workingPage != 0) {
      this.updatePage(Math.min(this.state.pages, this.workingPage + 1));
    } else {
      this.updatePage(1);
    }
  }

  render() {
    const { children, onPageChange, className, carouselClassName, ...props } = this.props;
    return (
      <div className={classes(styles.wrapper, className)}>
        <CircleButton
          className={classes('carousel-page-control', styles.button, styles.previous)}
          prominence={SECONDARY}
          disabled={this.state.pages === 1}
          onClick={this.scrollPrevious.bind(this)}
          data-test-carousel-previous-button
        >
          <ChevronLeft width={5} color="white" />
        </CircleButton>
        <CircleButton
          className={classes('carousel-page-control', styles.button, styles.next)}
          prominence={SECONDARY}
          disabled={this.state.pages === 1}
          onClick={this.scrollNext.bind(this)}
          data-test-carousel-next-button
        >
          <ChevronRight width={5} color="white" />
        </CircleButton>
        <BasePageCarousel
          page={this.workingPage}
          onPageChange={this.updatePage.bind(this)}
          className={carouselClassName}
          {...props}
        >
          {children}
        </BasePageCarousel>
      </div>
    );
  }
}
