import * as React from 'react';
import { observer } from 'mobx-react';
import { em, important, percent, px, rem } from 'csx';
import { mediaDesktop, mediaMobileOnly, mediaTablet, mediaUpToTablet } from '../theme/media';
import { keyframes, style } from 'typestyle';
import { LoadingContainer } from './loading-container';
import { PageHomePricing } from './page-home-pricing';
import { PricingCalculator, PricingCalculatorModel } from './pricing-calculator';
import { centerCenter, horizontal, horizontallySpaced, vertical, verticallySpaced } from 'csstips';
import { transitionQuickEase } from '../theme/transition';
import { colorBackground, colorContrast, colorWhite } from '../theme/color';
import { RatecardsSchema } from '../types/schema';
import { ImagePreloader } from '../types/fn';
import { AnalyticsController } from '../modules/analytics/controller';
import PrismicDOM from 'prismic-dom';

export interface PagePricingModel {
  loading: boolean;
  page_title: any;
  page_body: any;
  cover_image: string;
  pricingImage: string;
  video_id?: string;
  ratecards: RatecardsSchema;
  ratecardTypes: Array<{ value: string; label: string }>;
  ratecardType: string;
  ratecardLoading: boolean;
  benefits: Array<{
    title: string;
    content: any;
  }>;
}

export interface PagePricingProps {
  model: PagePricingModel;
  pricingModel: PricingCalculatorModel;
  onSignUp?(event: React.MouseEvent<any>): void;
  onChangeQuery(query: string): void;
  onChangeCurrency(value: string): void;
  onSearch(): void;
  onSubmit(channelId: string): void;
  onSelectGridRateType(type: string): void;
  onSelectCalculatorRateType(type: string): void;
  preload: ImagePreloader;
  coupon?: {
    title: string;
    message: string;
  };
  analytics: AnalyticsController;
}

const animations = {
  pulse: keyframes({
    0: {
      transform: 'translate3d(0, 0, 0)',
    },
    40: {
      transform: 'translate3d(0, 0, 0)',
    },
    60: {
      transform: 'translate3d(0, -0.25em, 0)',
    },
    80: {
      transform: 'translate3d(0, 0, 0)',
    },
  }),
};

@observer
export class PagePricing extends React.Component<PagePricingProps> {
  render() {
    const { props } = this;

    return (
      <LoadingContainer loading={props.model.loading} className={PagePricing.styles.container}>
        {!props.model.loading && (
          <PageHomePricing
            model={props.model}
            onSignUp={props.onSignUp}
            onSelectRateType={props.onSelectGridRateType}
            preload={props.preload}
          />
        )}
        {!props.model.loading && (
          <div className={PagePricing.styles.split}>
            <div>
              {props.model.video_id && (
                <div className={PagePricing.styles.playerContainer}>
                  <div className={PagePricing.styles.player}>
                    <iframe
                      className={PagePricing.styles.faq}
                      src={`https://www.youtube.com/embed/${props.model.video_id}?autoplay=0&origin=https://lickd.co`}
                      frameBorder="0"
                    />
                  </div>
                </div>
              )}
              <PricingCalculator
                analytics={this.props.analytics}
                className={PagePricing.styles.calculator}
                model={props.pricingModel}
                onSearch={props.onSearch}
                onSubmit={props.onSubmit}
                onChangeQuery={props.onChangeQuery}
                onChangeCurrency={props.onChangeCurrency}
                onSignUp={props.onSignUp}
                onSelectRateType={props.onSelectCalculatorRateType}
              />
            </div>
          </div>
        )}
      </LoadingContainer>
    );
  }

  static styles = {
    split: style(
      {
        $nest: {
          '&> div:last-child': {
            padding: '0 1rem',
          },
        },
      },
      mediaUpToTablet({
        ...vertical,
        ...verticallySpaced(30),
        $nest: {
          '&> div:last-child': {
            ...vertical,
            ...verticallySpaced(30),
          },
        },
      }),
      mediaDesktop({
        ...horizontal,
        ...horizontallySpaced(30),
        padding: '0 0 0 1rem',
        $nest: {
          '&> div': {
            flex: 1,
          },
          '&> div:first-child': {
            marginLeft: rem(1),
          },
        },
      })
    ),
    container: style({}),

    playerContainer: style(
      {
        ...centerCenter,
        maxWidth: px(640),
        width: percent(100),
        padding: '0 1rem',
        margin: '0 auto',
      },
      mediaMobileOnly({
        marginTop: px(20),
      }),
      mediaTablet({
        marginTop: px(60),
      })
    ),
    player: style({
      position: 'relative',
      maxWidth: px(640),
      width: percent(100),
      paddingTop: percent((100 * 320) / 640),
    }),
    faq: style({
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: percent(100),
      height: percent(100),
    }),
    calculator: style(
      mediaUpToTablet({
        padding: important('1rem'),
      }),
      mediaDesktop({
        padding: important('30px 80px'),
      })
    ),
    firstFree: style(
      {
        ...centerCenter,
        padding: '1rem',
        $nest: {
          '& > div': {
            cursor: 'pointer',
            transition: `all ${transitionQuickEase}`,
            animation: `${animations.pulse} 2s infinite ease-in-out`,
            animationDirection: 'alternate',
            width: percent(100),
            padding: px(16),
            background: colorContrast.toString(),
            borderRadius: px(16),
            color: colorWhite.toString(),
          },
          '& > div:hover': {
            background: colorContrast.darken(0.15).toString(),
          },
          '& h5': {
            color: colorWhite.toString(),
            margin: 0,
            fontSize: px(24),
          },
          '& p': {
            margin: '20px 0 0',
            fontSize: px(20),
          },
        },
      },
      mediaTablet({
        margin: '0 auto',
        maxWidth: px(640),
        padding: '60px 0 0 0',
        $nest: {
          '&> div': {
            padding: px(30),
          },
          '& h5': {
            color: colorWhite.toString(),
            margin: 0,
            fontSize: px(30),
          },
          '& p': {
            margin: '30px 0 0',
            fontSize: px(22),
          },
        },
      })
    ),
  };
}
