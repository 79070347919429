import { action } from 'mobx';
import { API } from '../types';

export class AnalyticsAPIController extends API {
  @action
  sendPlay = async (track: string) => {
    const url = `/analytics/track/played`;
    const body = JSON.stringify({ track });
    const resp = await this.request.post({ url, body });
  };
}