import { UIController } from '../../ui/controller';
import { action } from 'mobx';
import { RedirectFunction, RouterState } from 'react-router';
import { EnvModel } from '../../env/model';
import { RouterModel } from '../../router/model';
import { BLOGOSPHERE_URL, JAACKMAATE_URL, VIDCON_URL, VIDSUMMIT_URL } from '../../../constants';

export class MasterPageController {
  constructor(private ui: UIController, private env: EnvModel, private router: RouterModel) {}

  @action
  onEnterCatchAll = (nextState: RouterState, replace: RedirectFunction) => {
    if (
      [
        '/get-lickd',
        '/getlickd',
        '/lickd-reviews',
        JAACKMAATE_URL,
        VIDSUMMIT_URL,
        BLOGOSPHERE_URL,
        VIDCON_URL,
        '/contact',
        '/about',
        '/faq',
        '/privacy-policy',
        '/cookie-policy',
        '/terms-and-conditions',
        '/music-submissions',
        '/licensing',
        '/swapouts',
        '/es/features',
        '/features',
        '/discover',
        '/learn',
        '/l/',
        '/golden-ticket',
        '/youtube',
        '/careers',
        '/outreach',
      ].some((prefix) => nextState.location.pathname.startsWith(prefix))
    ) {
      const { pathname, search } = nextState.location;
      const host = this.env.rootDomain;
      const newLocation: Partial<Location> = { pathname, search, host, hostname: host };
      if (EnvModel.isServer) {
        replace(newLocation);
      } else {
        const urlObject = new URL(window.location.href);
        urlObject.port = '';
        urlObject.host = host;
        urlObject.search = search;
        urlObject.pathname = pathname;
        window.location.href = urlObject.toString();
      }
      return true;
    }
    return false;
  };

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    if (!this.env.isServer) {
      const { query } = nextState.location;

      this.env.queryStringCookies.forEach((key) => {
        if (query[key]) {
          const d = new Date();
          d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days;
          const expires = 'expires=' + d.toUTCString();

          document.cookie = `${key}=${query[key]}; ${expires}; path=/; domain=${this.env.rootDomain}`;
        }
      });
    }

    return true;
  };

  onLeave = (currentState: RouterState) => {
    this.router.setOutgoingRoute(currentState.location);
    this.ui.hideOverlays();
    this.ui.scrollToTop();
  };
}
