import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouterModel } from '../../modules/router/model';
import { Loading } from '../loading/loading';
import { ComponentProps, observer, inject, deps } from '../../lib/component';
import { FavouriteArtistButtonCompat } from '../compat/favourite-artist-button-compat';
import {classes, style} from 'typestyle';
import { end, endJustified, horizontal, wrap } from 'csstips';
import {percent, px, rem} from 'csx';
import {ResponsiveImageTile} from '../responsive-image-tile';
import {ReleaseTile} from '../release-tile';
import {ConnectedArtistSimilarArtists} from '../artist-similar-artists';
import {ArtistSchema, ReleaseSchema, TrackSchema} from '../../types/schema';
import {ImagePreloader} from '../../types/fn';
import {transitionQuickEase} from '../../theme/transition';
import {colorBrand} from '../../theme/color';

export interface ArtistBioProps extends ComponentProps {
  artist: ArtistSchema;
  release: ReleaseSchema | null;
  similarArtists: Array<ArtistSchema>;
  loadingSimilarArtists: boolean;
  tracks: Array<TrackSchema>;
  otherReleases: Array<ReleaseSchema>;
  loading: boolean;
  router: RouterModel;
  preload: ImagePreloader;
  onShowBio(): void;
  noDialog?: boolean;
  noImage?: boolean;
  noSimilarArtist?: boolean;
  isReleasePage?: boolean;
}

export interface ArtistsBioState {
  overflow: boolean;
  expand: boolean;
  descriptionHeight: number;
}

@inject(deps) @observer
export class ArtistBio extends React.Component<ArtistBioProps, ArtistsBioState> {
  private description: HTMLParagraphElement;

  constructor(props: ArtistBioProps) {
    super(props);
    this.state = {
      overflow: false,
      expand: false,
      descriptionHeight: 0
    }
  }

  render() {
    const { preloadImage } = this.props.controller.image;
    const { release, loading, artist, similarArtists, otherReleases, loadingSimilarArtists, preload, noImage, noSimilarArtist } = this.props;
    const { overflow, expand, descriptionHeight } = this.state;
    const { handleClickLink } = this.props.controller.ui;
    
    const image = artist && artist.images && artist.images.identity;

    if (loading) return (
      <div className={ArtistBio.styles.bio} />  
    );

    const descriptionClasses = classes(
      ArtistBio.styles.description,
      overflow && !expand && ArtistBio.styles.descriptionOverflowing,
      expand && ArtistBio.styles.descriptionExpanded
    );

    const descriptionStyle = { height: expand ? descriptionHeight : void 0 };

    return (
      <div className={ArtistBio.styles.bio}>
        {!noImage && image && (
          <ResponsiveImageTile preload={preload} identity={image}>
            <div className={ArtistBio.styles.favourite}>
              <FavouriteArtistButtonCompat artist={artist} withLabel={true}/>
            </div>
          </ResponsiveImageTile>
        )}
        <div className={ArtistBio.styles.content}>
          {!noSimilarArtist && loadingSimilarArtists && (
            <Loading />
          )}
          {!release && artist.description.length > 0 && (
            <FormattedMessage tagName="h3" id="artist.bio.title" />
          )}
          {!release && (
            <p ref={el => this.description = el} className={descriptionClasses} style={descriptionStyle} dangerouslySetInnerHTML={{ __html: artist.description}}>
            </p>
          )}
          {!release && overflow && (
            <div className={ArtistBio.styles.showMore} onClick={this.toggle}>
              {expand
                ? <FormattedMessage id="artist.bio.show_less" />
                : <FormattedMessage id="artist.bio.show_more" />
              }
            </div>
          )}
          {otherReleases && otherReleases.length > 0 && (
            <div>
              <h3>{`More releases by ${artist.name}`}</h3>
              <div className={ArtistBio.styles.releases}>
                {otherReleases.map((release, index) => (
                  <ReleaseTile
                    href={`/music/artists/${release.artist.slug}/release/${release.slug}`}
                    key={index.toString()}
                    className={ArtistBio.styles.release}
                    release={release}
                    preload={preloadImage}
                    onClickLink={handleClickLink}
                  />
                ))}
              </div>
            </div>
          )}
          {!release && !noSimilarArtist && (
            <ConnectedArtistSimilarArtists
              identity={artist.identity}
              onClickLink={handleClickLink}
              preload={preload}
            />
          )}
        </div>
      </div>
    )
  }

  componentDidMount() {
    if (!this.description) return;
    const { scrollHeight } = this.description;
    const { height } = this.description.getBoundingClientRect();
    if (scrollHeight > height) {
      this.setState({
        overflow: true,
        expand: false,
        descriptionHeight: scrollHeight
      });
    }
  }

  toggle = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    if (this.props.noDialog) {
      this.setState({ expand: !this.state.expand });
    } else {
      this.props.onShowBio();
    }
  };

  static styles = {
    bio: style({
      width: percent(100),
    }),
    image: style({
      paddingTop: percent(100),
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    }),
    content: style({
      padding: px(16)
    }),
    tags: style({
      padding: px(16)
    }),
    showMore: style({
      cursor: 'pointer',
      textAlign: 'center',
      fontSize: rem(0.75),
      transition: `color ${transitionQuickEase}`,
      $nest: {
        '&:hover': {
          color: colorBrand.toString()
        }
      }
    }),
    description: style({
      position: 'relative',
      height: px(200),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      margin: 0,
      transition: 'height 0.8s ease-in-out',
    }),
    descriptionOverflowing: style({
      $nest: {
        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: px(80),
          background: `linear-gradient(to bottom, rgba(0, 0, 0, 0), white)`
        }
      }
    }),
    descriptionExpanded: style({
      $nest: {
        '&::after': {
          display: 'none'
        }
      }
    }),
    favourite: style({
      ...horizontal,
      ...end,
      ...endJustified,
      padding: px(16)
    }),
    releases: style({
      ...horizontal,
      ...wrap,
      width: percent(100)
    }),
    release: style({
      width: percent(50)
    }),
  }
}