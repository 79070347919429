import { EnvModel } from '../env/model';
import { PusherModel } from './model';
import { autorun, action, IReactionDisposer } from 'mobx';
import { AuthModel } from '../auth/model';
import { isEmpty } from '../../components/project-happy/utilities/objects';
import { notify } from '../../components/project-happy/organisms/Notifications';
import Pusher from 'pusher-js';

export class PusherController {
  private __pusher: any;

  constructor(private model: PusherModel, private env: EnvModel, private auth: AuthModel) {
    this._disposers = [];

    if (!this.env.isServer && this.env.pusherKey) {
      if (this.env.appEnv === 'local') {
        Pusher.logToConsole = true;
      }

      this.__pusher = new Pusher(this.env.pusherKey, {
        cluster: 'eu',
        encrypted: true,
      });

      let userChannel: any, userChannelName: string;
      this._disposers.push(
        autorun(() => {
          const { user } = this.auth;

          if (userChannelName) {
            userChannel.unbind_all();
            this.__pusher.unsubscribe(userChannelName);
            userChannelName = void 0;
          }

          if (user) {
            userChannelName = `user--${user.identity}`;
            userChannel = this.__pusher.subscribe(userChannelName);
            userChannel.bind_global(this.receiveData);
          }
        })
      );
    }
  }

  private _disposers: IReactionDisposer[];

  dispose = () => {
    this._disposers.forEach((dispose) => dispose());
  };

  @action
  private receiveData = (event: any, data: any) => {
    if (!isEmpty(data) && (data.title || data.message)) {
      notify(Object.assign(data, { isPusher: true }));
    }
  };
}
