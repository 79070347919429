import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const OpenInNew: React.SFC<SVGIconProps> = ({ size = 18, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 18 18" {...props}>
    <path
      fill={color}
      d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7ZM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7Z"
      transform="translate(-3 -3)"
      fillRule="evenodd"
    />
  </BaseSvg>
);
