import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { percent, px, viewWidth } from 'csx';
import { ContentSlice, ContentSliceProps, GradientBackgroundStyles } from './ContentSlice';
import { TrackTile } from '../../molecules/TrackTile';
import { TrackSchema } from '../../../../types/schema';
import { desktopContentWidth } from '../../config';
import { mediaDesktop, mediaUpToDesktop } from '../../utilities/mediaQueries';
import { mediaCoarsePointer, mediaFinePointer } from '../../../../theme/media';
import { heading4, headingStyles } from '../../utilities/font';

const styles = {
  wrapper: style(
    GradientBackgroundStyles,
    {
      color: 'inherit',
      position: 'relative',
      $nest: {
        '.masthead + &, :not(.with-gradient) + &': {
          background: `linear-gradient(to top, white 56px, transparent 0%), ${GradientBackgroundStyles.background}`,
        },
      },
    },
    mediaUpToDesktop({
      paddingBottom: px(32),
      $nest: {
        '.masthead + &, :not(.with-gradient) + &': {
          paddingTop: px(32),
        },
      },
    }),
    mediaDesktop({
      paddingBottom: px(56),
      $nest: {
        '.masthead + &, :not(.with-gradient) + &': {
          paddingTop: px(56),
        },
      },
    })
  ),
  heading: style(headingStyles, heading4, {
    margin: 0,
    marginBottom: px(16),
    color: 'white',
  }),
  trackWrapper: style(
    mediaUpToDesktop({
      width: viewWidth(100),
      overflowX: 'scroll',
      scrollSnapType: 'x mandatory',
      marginLeft: px(-16),
      paddingLeft: px(16),
    }),
    mediaCoarsePointer({
      scrollbarWidth: 'none',
      $nest: {
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    })
  ),
  trackContainer: style({
    display: 'flex',
    minWidth: px(desktopContentWidth),
    $nest: {
      '&::before': {
        content: '""',
        position: 'absolute',
        height: percent(50),
        left: 0,
        right: 0,
        bottom: 0,
        background: 'white',
      },
    },
  }),
  trackTile: style(
    mediaUpToDesktop({
      scrollSnapAlign: 'start',
      margin: 0,
      paddingLeft: px(16),
    })
  ),
};

// Exclude gradient prop as this slice has a two tone background
type TrackListSliceProps = Omit<ContentSliceProps, 'withGradient'> & { title?: string; tracks: TrackSchema[] };

export const TrackListSlice: React.SFC<TrackListSliceProps> = ({ children, className, title, tracks, ...props }) => (
  <ContentSlice className={classes('track-list-slice', className, styles.wrapper)} {...props}>
    {title && <h3 className={styles.heading}>{title}</h3>}
    <div className={styles.trackWrapper}>
      <div className={styles.trackContainer}>
        {tracks.map((track, key) => (
          <TrackTile key={key} track={track} className={styles.trackTile} />
        ))}
      </div>
    </div>
  </ContentSlice>
);
