import * as React from 'react';
import { observer } from 'mobx-react';
import { classes, style } from 'typestyle';
import { percent, px, url } from 'csx';
import {getChannelImageURL} from '../lib/image';
import {ChannelSchema} from '../types/schema';

export interface ChannelAvatarProps {
  className?: string;
  channel: ChannelSchema;
}

@observer
export class ChannelAvatar extends React.Component<ChannelAvatarProps, any> {
  render() {
    const { className, channel } = this.props;

    const image = getChannelImageURL(channel, 'default');

    return (
      <div
        style={{ backgroundImage: url(image) }}
        className={classes(ChannelAvatar.styles.container, className ? className : ChannelAvatar.styles.defaultSize)}
      />
    )
  }

  static styles = {
    container: style({
      borderRadius: percent(50),
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat'
    }),
    defaultSize: style({
      width: px(42),
      height: px(42)
    })
  }
}