import * as React from 'react';
import { classes, style } from 'typestyle';
import { ArtistSchema } from '../../../types/schema';
import { em, px } from 'csx';
import { shadowElevation2 } from '../../../theme/shadow';
import { transitionQuickEase } from '../../../theme/transition';
import { colorGunmetal, colorWhite } from '../../../theme/color';
import { centerJustified, endJustified, flexRoot, vertical } from 'csstips';
import { Link } from '../atoms/Link';
import { overlayLink } from '../../../theme/mixin';
import { zLabel } from '../../../theme/z';
import { AnalyticsContextProvider } from '../utilities/AnalyticsContext';
import { Component, deps, inject, observer } from '../../../lib/component';

const styles = {
  wrapper: style({
    position: 'relative',
    display: 'flex',
    margin: px(8),
    borderRadius: em(0.3),
    flexDirection: 'row',
    background: colorGunmetal.toString(),
    transition: `box-shadow ${transitionQuickEase}`,
    $nest: {
      '& > div': {
        color: colorWhite.toString(),
        width: '50%',
      },
      '& > div:nth-child(2)': {
        textAlign: 'right',
      },
      '&:hover': shadowElevation2,
      '& .play-button': {
        position: 'absolute',
        bottom: px(16),
        right: px(16),
      },
    },
  }),
  imgWrapper: style(flexRoot, endJustified, {
    margin: px(8),
  }),
  img: style({
    width: 'calc(100% - 16px)',
    borderRadius: em(0.3),
  }),
  link: style(overlayLink, { color: 'inherit' }),
  info: style(flexRoot, vertical, centerJustified, {
    height: '100%',
    padding: px(8),
    fontSize: px(10),
    $nest: {
      '& p': {
        margin: 0,
      },
      '& p > span': {
        display: 'block',
        lineHeight: 'normal',
        fontSize: px(12),
      },
      '& .title': {
        fontWeight: 600,
      },
    },
  }),
  overlayContent: style({ $nest: { '& > *': { zIndex: zLabel } } }),
};

export type LargeArtistTileProps = { className?: string; artist: ArtistSchema };

@inject(deps)
@observer
export class LargeArtistTile extends Component<LargeArtistTileProps> {
  render() {
    const { children, className, artist, model } = this.props;
    return (
      <AnalyticsContextProvider context={{ artistName: artist.name }}>
        <div className={classes(styles.wrapper, className)}>
          <div>
            <div className={styles.info}>
              <p>
                <span>Artist</span>
                <Link href={`/music/artists/${artist.slug}`} className={styles.link}>
                  <span className="title">{artist.name}</span>
                </Link>
              </p>
            </div>
          </div>
          <div className={styles.imgWrapper}>
            <img
              className={styles.img}
              src={model.image.cover(artist.images.identity, {
                ignoreDimensions: true,
                width: 692,
                height: 692,
                crop: true,
              })}
              alt={artist.name}
            />
          </div>
          <span className={styles.overlayContent}>{children}</span>
        </div>
      </AnalyticsContextProvider>
    );
  }
}
