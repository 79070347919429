import * as React from 'react';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { important, percent, px } from 'csx';
import UserAvatar from '../atoms/UserAvatar';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize, removeButtonShadow } from '../atoms/button/buttonStyles';
import { classes, style } from 'typestyle';
import { betweenJustified, center, flexRoot } from 'csstips';
import { colorWhite, rebrand } from '../../../theme/color';
import { mixinTextEllipsis } from '../../../theme/mixin';
import isEmpty from 'lodash/isEmpty';
import { mediaMobileOnly } from '../../../theme/media';
import { ConnectChannelPrompt } from './ConnectYoutubeChannelPrompt';
import { mediaUpToLargeMobile, mediaUpToSmallTablet } from '../utilities/mediaQueries';
import { borderRadius } from '../../../theme/border';
import { spacing } from '../../../theme/spacing';
import { RatecardSkeleton } from './skeletons/RatecardSkeleton';
import { RatecardV2 } from './RatecardV2';
import { fontstackHeading } from '../config';
import { Link } from '../atoms/Link';
import { ACCOUNT_PLANS_ROUTE } from '../../../constants';

const styles = {
  wrapper: style(mediaMobileOnly({ margin: 'auto' }), {
    border: 'none',
    position: 'relative',
    width: '100%',
    padding: spacing.DEFAULT,
  }),
  channel: style(flexRoot, betweenJustified, center, {
    backgroundColor: colorWhite.toString(),
    color: rebrand.dark1.toString(),
    borderRadius: borderRadius.MEDIUM,
    padding: `${spacing.SMALL} ${spacing.DEFAULT}`,
    marginBottom: spacing.XSMALL,
  }),
  channelName: style(mixinTextEllipsis, {
    fontSize: px(24),
    fontFamily: fontstackHeading,
    fontWeight: 400,
    margin: '0 8px',
    flexGrow: 1,
    $nest: {
      '& a': {
        font: 'inherit',
        color: 'inherit',
      },
    },
  }),
  noChannelText: style({
    color: rebrand.contrast[30].toString(),
  }),
  channelButton: style(removeButtonShadow, {
    flexShrink: 0,
    padding: '3px 12px',
    fontSize: '12px',
    backgroundColor: rebrand.neutralOnDark[20].toHexString(),
  }),
  connectChannelPrompt: style(
    mediaUpToLargeMobile({
      width: percent(100),
    }),
    {
      margin: important(0),
      fontSize: important('12px'),
      minWidth: important('auto'),
      height: important('auto'),
      padding: important('3px 10px'),
      minHeight: important('auto'),
    }
  ),
  connectChannelPromptWrapper: style(
    mediaUpToLargeMobile({
      flexShrink: 0,
    })
  ),
  hideOnMobile: style(
    mediaUpToSmallTablet({
      display: 'none',
    })
  ),
};

type RatecardDisplayProps = ComponentProps;

export const RatecardDisplay = inject(deps)(
  observer(
    ({
      model: {
        user: {
          user,
          channel,
          isSubscribed: channelIsSubscribed,
          isSubscribedWithoutChannel,
          unsubscribedRatecard,
          loading: loadingUser,
          loadingAccounts,
          loadingSubscriptions,
          loadingChannels,
        },
        router: {
          location: { pathname },
          push,
        },
      },
      controller: { modal },
      controller: {
        modal: { showChannelAddModal },
        analytics: { sendMixpanel },
      },
    }: RatecardDisplayProps) => {
      // If any initial requests are still processing, show the loading skeleton
      if (loadingUser || loadingAccounts || loadingSubscriptions || loadingChannels || isEmpty(unsubscribedRatecard)) {
        return <RatecardSkeleton />;
      }

      return (
        <div data-test-rate-card className={styles.wrapper}>
          {!isEmpty(user) && (
            <div>
              <div className={styles.channel}>
                <UserAvatar />
                {!isEmpty(channel) ? (
                  <>
                    <span className={styles.channelName}>
                      <Link href={ACCOUNT_PLANS_ROUTE}>{channel.name}</Link>
                    </span>
                    <Button
                      className={styles.channelButton}
                      prominence={ButtonProminence.SECONDARY}
                      size={ButtonSize.XSMALL}
                      onClick={(event) => {
                        event.stopPropagation();
                        modal.showChannelSelectModal();
                      }}
                      data-test-change-channel-button
                    >
                      Change<span className={styles.hideOnMobile}> channel</span>
                    </Button>
                  </>
                ) : (
                  <>
                    <span className={classes(styles.channelName, styles.noChannelText)}>No channel added</span>
                    <ConnectChannelPrompt
                      className={styles.connectChannelPrompt}
                      wrapperClassName={styles.connectChannelPromptWrapper}
                      showChannelAddModal={showChannelAddModal}
                      sendMixpanel={sendMixpanel}
                      location={pathname}
                      loading={isEmpty(user)}
                      text="Connect channel"
                      hideIcon
                    />
                  </>
                )}
              </div>
            </div>
          )}

          <RatecardV2 isDashboard={true} />
        </div>
      );
    }
  )
);
