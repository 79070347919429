import { observable } from 'mobx';
import {PageAccountCommunicationI18n} from '../../../components/page-account-communication';
import {FormMarketingPreferencesModalModel} from '../../../components/form-marketing-preferences-modal';
import {UserPreferencesSchema} from '../../../types/schema';

export class AccountCommunicationPageModel {
  @observable
  loading: boolean = false;

  @observable
  preferences: UserPreferencesSchema = null;

  @observable
  saving: UserPreferencesSchema = null;

  @observable
  i18n: PageAccountCommunicationI18n;

  @observable
  disabled: boolean = false;

  @observable
  modalMarketing: FormMarketingPreferencesModalModel = {
    show: false,
    form: null
  }
}