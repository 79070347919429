import { classes, style } from 'typestyle';
import * as React from 'react';
import { em, percent, px } from 'csx';
import { colorBlack, colorWhite } from '../../../theme/color';
import { Link, LinkProps } from './Link';
import { BackgroundProps, resolveBackgroundProps } from '../utilities/props';
import { mediaDesktop, mediaLargeTabletOnly, mediaUpToLargeTablet } from '../utilities/mediaQueries';
import { overlayLink } from '../../../theme/mixin';
import { transitionQuickEase } from '../../../theme/transition';
import { AnalyticsContextProvider } from '../utilities/AnalyticsContext';
import { SubscriptionLabel } from './SubscriptionLabel';
import FavouritePlaylistButtonCompat from '../../compat/favourite-playlist-button-compat';
import { zLabel } from '../../../theme/z';

const styles = {
  tile: style(
    {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
      margin: px(8),
    },
    mediaUpToLargeTablet({
      width: 'calc(50% - 16px)',
      paddingBottom: 'calc(50% - 16px)',
    }),
    mediaLargeTabletOnly({
      width: 'calc(33.33333333% - 16px)',
      paddingBottom: 'calc(33.33333333% - 16px)',
    }),
    mediaDesktop({
      width: 'calc(25% - 16px)',
      paddingBottom: 'calc(25% - 16px)',
    })
  ),
  playlistLink: style(overlayLink, {
    $nest: {
      '&:hover span': {
        transform: 'translate3d(0,-3px,0)',
      },
    },
  }),
  playlistName: style({
    position: 'absolute',
    top: 'calc(50% - 0.55em)',
    cursor: 'pointer',
    fontSize: px(20),
    fontWeight: 'bold',
    lineHeight: em(1.1),
    width: percent(100),
    textAlign: 'center',
    textShadow: `0px 0px 6px ${colorBlack.toRGBA()};`,
    color: colorWhite.toString(),
    transition: `transform ${transitionQuickEase}`,
    zIndex: 1,
    padding: '0 8px',
  }),
  favBtn: style({ $nest: { '& > *': { zIndex: zLabel } } }),
};

export type GridTileListItemProps = LinkProps &
  BackgroundProps & {
    heading: string;
    included?: boolean;
  };

export const GridTileListItem: React.SFC<GridTileListItemProps> = ({
  heading,
  className,
  children,
  src,
  gradient,
  included,
  ...props
}) => {
  const backgroundStyles = resolveBackgroundProps({ src, gradient });
  return (
    <AnalyticsContextProvider context={{ gridTileHeading: heading }}>
      <li className={classes(styles.tile, className)} style={backgroundStyles}>
        <SubscriptionLabel included={included} />
        <Link className={styles.playlistLink} {...props}>
          <span className={styles.playlistName}>{heading}</span>
        </Link>
        <span className={styles.favBtn}>{children}</span>
      </li>
    </AnalyticsContextProvider>
  );
};
