import * as React from 'react';
import { classes, cssRaw, extend, style } from 'typestyle';
import { percent, px, viewWidth } from 'csx';
import { ContentSlice, ContentSliceProps, GradientBackgroundStyles } from './ContentSlice';
import { LinkTile, LinkTileProps } from '../../molecules/LinkTile';
import { mediaDesktop, mediaUpToDesktop } from '../../utilities/mediaQueries';
import { DecoratedPageCarousel, DecoratedPageCarouselProps } from '../DecoratedPageCarousel';
import { colorGunmetal } from '../../../../theme/color';
import { BaseCarouselSlice } from './BaseCarouselSlice';
import { SeeAllButton } from '../../molecules/panels/atoms/SeeAllButton';

const styles = {
  carouselUI: style({
    $nest: {
      '& .carousel-page-control': {
        top: 'calc(50% - 40px)',
      },
    },
  }),
  carousel: style(
    mediaUpToDesktop({
      marginLeft: px(-16),
      marginRight: px(-16),
      width: 'calc(100% + 32px)',
    })
  ),
  trackTile: style({
    $nest: {
      ':not(.with-gradient) &': {
        color: colorGunmetal.toHexString(),
      },
    },
  }),
  trackPlay: style(
    {
      position: 'absolute',
      bottom: px(81),
      zIndex: 1,
    },
    mediaUpToDesktop({
      right: px(8),
    }),
    mediaDesktop({
      right: px(16),
    })
  ),
};

type LinkCarouselSliceProps = ContentSliceProps &
  DecoratedPageCarouselProps & { links: LinkTileProps[]; square?: boolean; actionHref?: string };

export const LinkCarouselSlice: React.SFC<LinkCarouselSliceProps> = ({
  children,
  className,
  contentClassName,
  links,
  carouselClassName,
  square = false,
  actionHref,
  ...props
}) => {
  const compact = props.perPage >= 6;
  if (!compact) {
    props.mobileTileSize = 85.3;
  }
  if (typeof actionHref === 'string' && actionHref.length > 0 && typeof props.actions === 'undefined') {
    props.actions = (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <SeeAllButton href={actionHref}>See all</SeeAllButton>
      </div>
    );
  }
  return (
    <BaseCarouselSlice className={className} contentClassName={contentClassName}>
      <DecoratedPageCarousel
        className={square ? styles.carouselUI : null}
        carouselClassName={classes(styles.carousel, carouselClassName)}
        {...props}
      >
        {links.map((link, key) => (
          <LinkTile
            key={key}
            className={styles.trackTile}
            {...link}
            compact={compact}
            square={square}
            data-test-link-carousel-slice={key}
          />
        ))}
      </DecoratedPageCarousel>
    </BaseCarouselSlice>
  );
};
