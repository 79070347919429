import * as React from 'react';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { renderComponentsFromPrismicSlices } from '../prismic-slices';
import { isArray, isEmpty } from '../utilities/objects';
import { classes, style } from 'typestyle';
import Helmet from 'react-helmet';
import { viewHeight } from 'csx';
import { Loading } from '../../loading/loading';
import { FavouritesCarouselSlice } from '../organisms/slices/FavouritesCarouselSlice';
import { SubscriptionLabelContext } from '../atoms/SubscriptionLabel';
import { AnalyticsContextProvider } from '../utilities/AnalyticsContext';
import { StockGroup } from '../molecules/StockGroup';
import { ChartGroup } from '../molecules/ChartGroup';
import { PlansSlice } from '../organisms/slices/PlansSlice';
import { PrismicPlans } from '../prismic-slices/PrismicPlans';

const styles = {
  wrapper: style({
    minHeight: viewHeight(100),
  }),
  loadingSlices: style({ height: viewHeight(100) }),
  loadingData: style({ height: viewHeight(25) }),
};

const DashboardTeaserWrapper = (props) => (
  <AnalyticsContextProvider context={{ prismicPage: 'Dashboard Teaser' }}>
    <main className={styles.wrapper} {...props} />
  </AnalyticsContextProvider>
);

export const DashboardTeaser = inject(deps)(
  observer(
    ({
      model: {
        user: { channel },
        page: { home },
      },
    }: ComponentProps) => {
      const { slices } = home;
      const slicesLength = !isArray(slices) ? 0 : slices.length;

      if (slicesLength <= 1) {
        return (
          <DashboardTeaserWrapper>
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
            {renderComponentsFromPrismicSlices(home.slices.slice(0, 1), home.sliceContext)}
            <Loading className={styles.loadingData} />
          </DashboardTeaserWrapper>
        );
      }

      return (
        <DashboardTeaserWrapper>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          {renderComponentsFromPrismicSlices(home.slices, home.sliceContext)}
          <PrismicPlans />
          {slicesLength === 0 && <Loading className={styles.loadingSlices} />}
        </DashboardTeaserWrapper>
      );
    }
  )
);
