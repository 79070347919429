import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const CloseCross: React.SFC<SVGIconProps> = ({ size = 18, color = '#2E2E3B', title, ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      fillRule="evenodd"
    />
    <title>{title}</title>
  </BaseSvg>
);
