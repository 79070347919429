import * as React from 'react';
import { classes, style } from 'typestyle';
import { Button, ButtonProps } from '../../../atoms/button/Button';
import { percent, px } from 'csx';
import { ButtonProminence, ButtonSize } from '../../../atoms/button/buttonStyles';
import { mediaLargeMobile, mediaUpToLargeMobile } from '../../../utilities/mediaQueries';
import { heading5, heading6 } from '../../../utilities/font';
import { LoadingDots } from '../../../atoms/icons/LoadingDots';

const { PRIMARY } = ButtonProminence;

const styles = style(
  {
    margin: '0 auto',
    padding: px(8),
    textAlign: 'center',
  },
  mediaUpToLargeMobile(heading6, {
    width: percent(82),
    $nest: {
      '&:first-child': {
        marginTop: px(16),
      },
      '&:last-child': {
        marginBottom: px(16),
      },
    },
  }),
  mediaLargeMobile(heading5, {
    minWidth: px(236),
    fontSize: px(20),
    $nest: {
      '&:first-child': {
        marginTop: px(32),
      },
      '&:last-child': {
        marginBottom: px(32),
      },
    },
  })
);

export type CtaButtonProps = ButtonProps;

export const CtaButton: React.SFC<CtaButtonProps> = ({ children, className, loading = false, ...props }) => (
  <Button prominence={PRIMARY} className={classes(styles, 'cta-button', className)} {...props}>
    {loading !== true && children}
    {loading === true && <LoadingDots size={21} />}
  </Button>
);
