import { computed, observable } from 'mobx';
import { isEmpty } from '../../components/project-happy/utilities/objects';

export class EnvModel {
  static get isServer(): boolean {
    return !isEmpty(process) && !isEmpty(process.versions) && typeof process.versions.node === 'string';
  }

  constructor({ envVars }: { envVars?: LickdEnvironmentVariables }) {
    this.appEnv = envVars.APP_ENV;

    this.rootDomain = envVars.ROOT_DOMAIN;
    this.baseUrl = envVars.APP_PROTOCOL + '//' + envVars.APP_URL;
    this.userAgent = [envVars.APP_NAME, envVars.APP_ENV, envVars.APP_VERSION].join('/');
    this.apiUri = envVars.API_URL;
    this.authUri = envVars.APP_PROTOCOL + '//' + envVars.AUTH_URL;
    this.assetUrl = envVars.ASSET_URL;
    this.audioUri = envVars.APP_PROTOCOL + '//' + envVars.AUDIO_URL;
    this.imagesUri = envVars.APP_PROTOCOL + '//' + envVars.IMAGES_URL;
    this.waveformsUri = envVars.APP_PROTOCOL + '//' + envVars.WAVEFORMS_URL;
    this.staticUri =
      envVars.STATIC_URL && envVars.STATIC_URL.length > 0 ? envVars.APP_PROTOCOL + '//' + envVars.STATIC_URL : '';
    this.trackReferralUri = envVars.APP_PROTOCOL + '//' + envVars.TRACK_REFERRAL_URL;
    this.graphqlUri = envVars.GRAPHQL_PROTOCOL + '//' + envVars.GRAPHQL_URL;

    this.version = envVars.VERSION;
    this.stripeKey = envVars.STRIPE_KEY;
    this.googleApiKey = envVars.GOOGLE_JS_API_KEY;
    this.ga = {
      id: envVars.GOOGLE_ANALYTICS,
      trackingVersion: 1,
    };
    this.bugsnagKey = envVars.BUGSNAG_KEY;
    this.pusherKey = envVars.PUSHER_KEY;
    this.prismicEndpoint = envVars.PRISMIC_ENDPOINT;
    this.prismicLandingEndpoint = envVars.PRISMIC_LANDING_ENDPOINT;
    if (envVars.PRISMIC_REF) {
      this.prismicRefOverride = envVars.PRISMIC_REF;
    }
    this.queryStringCookies = envVars.QUERY_STRING_COOKIES ? envVars.QUERY_STRING_COOKIES.split(',') : [];
    this.addThisKey = 'ra-5a38e640c10ce83f';
    this.playlistFavourites = envVars.PLAYLIST_FAVOURITES;
    this.artistlistFavourites = envVars.ARTISTLIST_FAVOURITES;
    this.exampleLicenseUri = `${envVars.LICENSE_URL}/${envVars.LICENSE_EXAMPLE_PATH}`;
    this.goldenTicketBackgroundIdentity = envVars.GOLDEN_TICKET_BACKGROUND_IDENTITY;
    this.channelReferralBackgroundIdentity = envVars.CHANNEL_REFERRAL_BACKGROUND_IDENTITY;
    this.keenProjectId = envVars.KEENIO_PROJECT_ID;
    this.keenWriteKey = envVars.KEENIO_WRITE_KEY;
    this.keenChannelReferralVisit = 'channel_referral_visit';
    this.keenChannelReferralClick = 'channel_referral_click';
    this.mailchimpArtist = envVars.MAILCHIMP_ARTIST;
    this.mailchimpLaunchReminderUrl = envVars.MAILCHIMP_LAUNCH_REMINDER_URL;
    this.mailchimpLaunchReminderHidden = envVars.MAILCHIMP_LAUNCH_REMINDER_HIDDEN;
    this.klaviyoPublicKey = envVars.KLAVIYO_PUBLIC_API_KEY;
    this.typeform.offboarding = envVars.TYPEFORM_OFFBOARDING;
    this.prosperStackClientId = envVars.PROSPER_STACK_CLIENT_ID;
  }

  @observable
  es6 = false;

  @observable
  ssrStatus = 200;

  @observable
  startupPromises: Array<Promise<any>> = [];

  @computed
  get ready(): Promise<any> {
    return Promise.all(this.startupPromises);
  }

  @computed
  get isServer() {
    return EnvModel.isServer;
  }

  @observable
  appEnv: string;

  @observable
  rootDomain: string;

  @observable
  baseUrl: string;

  @observable
  userAgent: string;

  @observable
  apiUri: string;

  @observable
  authUri: string;

  @observable
  assetUrl: string;

  @observable
  audioUri: string;

  @observable
  imagesUri: string;

  @observable
  waveformsUri: string;

  @observable
  staticUri: string;

  @observable
  trackReferralUri: string;

  @observable
  graphqlUri: string;

  @observable
  version: string;

  @observable
  stripeKey: string;

  @observable
  googleApiKey: string;

  @observable
  klaviyoPublicKey: string;

  @observable
  ga = {
    id: null,
    trackingVersion: null,
  };

  @observable
  bugsnagKey: string;

  @observable
  pusherKey: string;

  @observable
  prismicEndpoint: string;

  @observable
  prismicLandingEndpoint: string;

  @observable
  prismicRefOverride: string | null = null;

  @observable
  queryStringCookies: Array<string>;

  @observable
  addThisKey: string;

  @observable
  playlistFavourites: string;

  @observable
  artistlistFavourites: string;

  @observable
  exampleLicenseUri: string;

  @observable
  goldenTicketBackgroundIdentity: string;

  @observable
  channelReferralBackgroundIdentity: string;

  @observable
  keenProjectId: string;

  @observable
  keenWriteKey: string;

  @observable
  keenChannelReferralVisit: string;

  @observable
  keenChannelReferralClick: string;

  @observable
  mailchimpArtist: string;
  @observable
  mailchimpLaunchReminderUrl: string;
  @observable
  mailchimpLaunchReminderHidden: string;

  @observable
  typeform = {
    offboarding: '',
  };

  @observable
  prosperStackClientId: string;
}
