import { ModalModel } from './model';
import { action, runInAction, when } from 'mobx';
import { APIController } from '../api/controller';
import { AuthModel } from '../auth/model';
import { UIController } from '../ui/controller';
import { BugsnagController } from '../bugsnag/controller';
import { AuthController } from '../auth/controller';
import { UserController } from '../user/controller';
import { EnvModel } from '../env/model';
import { BasketController } from '../basket/controller';
import { PricingController } from '../pricing/controller';
import { ContentModel } from '../content/model';
import { RouterModel } from '../router/model';
import { ChannelSchema, TrackSchema } from '../../types/schema';
import { APIError, HttpError } from '../../types';
import wait from '../../lib/wait';
import { AnalyticsController } from '../analytics/controller';
import { isEmpty } from '../../components/project-happy/utilities/objects';
import { REGISTRATION_ROUTE } from '../../constants';
import { ContentController } from '../content/controller';
import { V2Plan } from '../../components/project-happy/utilities/types';

export class ModalController {
  constructor(
    private model: ModalModel,
    private api: APIController,
    private auth: AuthModel,
    private ui: UIController,
    private bugsnag: BugsnagController,
    private authController: AuthController,
    private user: UserController,
    private env: EnvModel,
    private basket: BasketController,
    private pricing: PricingController,
    private content: ContentModel,
    private contentController: ContentController,
    private router: RouterModel,
    private analytics: AnalyticsController
  ) {
    // When a user is logged in, not in registration and there are announcements to be shown, show the announcements modal
    when(
      () =>
        !isEmpty(this.auth.user) &&
        !isEmpty(this.router.location) &&
        !this.router.location.pathname.startsWith(REGISTRATION_ROUTE) &&
        this.content.unseenAnnouncements.length > 0 &&
        !this.model.platformAnnouncementsShow,
      () => this.showPlatformAnnouncements()
    );
  }

  @action showPlatformAnnouncements = () => {
    this.model.platformAnnouncementsShow = true;
  };

  @action hidePlatformAnnouncements = () => {
    this.model.platformAnnouncementsShow = false;
    this.contentController.setLastSeenAnnouncement();
  };

  @action showDownloadModal = async (licenceId: string) => {
    runInAction(() => {
      this.model.downloadLoading = true;
      this.model.downloadAgree = false;
      this.model.downloadShow = true;
      this.model.downloadError = false;
      this.model.downloadAttribution = null;
      this.model.downloadAttributionError = false;
      this.model.licenseIdentity = licenceId;
    });

    let url: string;
    let attempt = 30;
    let downloadLimitReached = false;

    // Fetch the licence attribution separately - you should still be able to download the audio without it
    await this.getDownloadAttribution(licenceId);

    // Attempt to get the licence audio 30 times before giving up
    try {
      while (attempt > 0) {
        const [audio] = await Promise.all([this.api.audio.getLicenceAudio(licenceId), this.env.ready]);

        url = audio.url;
        downloadLimitReached = audio.downloadLimitReached;

        if (downloadLimitReached || url) break;

        await wait(1000);
        attempt--;
      }

      if (url || downloadLimitReached) {
        runInAction(() => {
          this.model.downloadURL = url;
          this.model.downloadLoading = false;
          this.model.downloadLimitReached = downloadLimitReached;
        });
      }
    } catch (error) {
      this.model.downloadLoading = false;
      this.model.downloadError = true;

      this.bugsnag.notifyException(new Error('Failed to generate audio for license ' + licenceId));
    }
  };

  @action getDownloadAttribution = async (licenceId: string): Promise<void> => {
    const { userId } = this.model;

    try {
      const [licence] = await Promise.all([this.api.user.getLicence(userId, licenceId), this.env.ready]);

      runInAction(() => {
        this.model.downloadAttribution = licence.data.attribution;
        this.model.licenseShortId = licence.data.short_id;
        this.model.licenseTrackName = licence.data.track.title;
        this.model.licenseArtistName = licence.data.track.artists[0].name;
      });
    } catch (error) {
      this.model.downloadAttributionError = true;
      this.bugsnag.notifyException(new Error('Failed to generate attribution for license ' + licenceId));
    }
  };

  @action agreeToDownloadTerms = (agree: boolean) => {
    this.model.downloadAgree = agree;
  };

  @action hideDownloadModal = () => {
    this.model.downloadLimitReached = false;
    this.model.downloadShow = false;
  };

  @action showAttributionModal = async (licenceId: string) => {
    runInAction(() => {
      this.model.attributionLoading = true;
      this.model.attributionShow = true;
    });

    const { userId } = this.model;
    const [licence] = await Promise.all([this.api.user.getLicence(userId, licenceId), this.env.ready]);

    runInAction(() => {
      this.model.attributionAttribution = licence.data.attribution;
      this.model.licenseShortId = licence.data.short_id;
      this.model.licenseTrackName = licence.data.track.title;
      this.model.licenseArtistName = licence.data.track.artists[0].name;
      this.model.attributionLoading = false;
    });
  };

  @action hideAttributionModal = () => {
    this.model.attributionShow = false;
  };

  @action showChannelAddModal = () => {
    this.model.addChannelShow = true;
    this.analytics.sendMixpanel('User clicks add another channel');
  };

  @action hideChannelAddModal = () => {
    this.model.addChannelSelection = null;
    this.model.addChannelShow = false;
  };

  @action showAddChannelForModal = (context = '') => {
    this.model.addChannelModalContext = context;
    this.model.addChannelForShow = true;
  };

  @action hideAddChannelForModal = () => {
    this.model.addChannelForShow = false;
  };

  @action addChannelSearch = async (query: string) => {
    runInAction(() => {
      this.model.addChannelSelection = null;
      this.model.addChannelLoading = true;
    });

    try {
      const channels = await this.api.youtube.searchChannels(query);

      runInAction(() => {
        this.model.addChannelResults = channels.data;
      });
    } catch (e) {
      throw new Error(e);
    } finally {
      runInAction(() => {
        this.model.addChannelLoading = false;
      });
    }
  };

  @action addChannelSelect = (channel: ChannelSchema) => {
    this.model.addChannelSelection = channel;
  };

  @action addChannelDeselect = () => {
    this.model.addChannelSelection = null;
  };

  @action addChannelLink = async () => {
    if (!this.model.addChannelSelection) return null;

    runInAction(() => {
      this.model.addChannelError = null;
      this.model.addChannelLinking = true;
    });

    try {
      await this.api.user.addChannel(this.model.userId, this.model.addChannelSelection);
      await this.user.getYoutubeChannels();
      await this.changeChannel(this.model.addChannelSelection);

      this.hideChannelAddModal();
    } catch (e) {
      this.model.addChannelError = ((e as HttpError).body as APIError).error;
    } finally {
      runInAction(() => {
        this.model.addChannelLinking = false;
      });
    }
  };

  @action
  showChannelSelectModal = async () => {
    runInAction(() => {
      this.model.selectChannelLoading = true;
      this.model.selectChannelShowV2 = true;
    });

    try {
      const channels = await this.api.user.getYouTubeChannels(this.auth.user.identity);

      runInAction(() => {
        this.model.selectChannelChannels = channels.data;
        this.model.selectChannelLoading = false;
      });
    } catch (e) {
      this.bugsnag.notifyException(e);

      runInAction(() => {
        this.model.selectChannelChannels = [];
        this.model.selectChannelLoading = false;
      });
    }
  };

  @action
  hideChannelSelectModal = () => {
    this.model.selectChannelShow = false;
  };

  @action
  hideChannelSelectV2Modal = () => {
    this.model.selectChannelShowV2 = false;
  };

  @action
  changeChannel = async (channel: ChannelSchema) => {
    runInAction(() => {
      this.model.selectChannelActivating = channel;
      this.model.selectChannelSelecting = true;
    });

    try {
      await this.user.getYoutubeChannel(channel.id);
      await this.api.user.setDefaultChannel(channel.id);
      await this.authController.getSession();

      runInAction(() => {
        this.model.selectChannelShow = false;
      });
    } finally {
      runInAction(() => {
        this.model.selectChannelActivating = null;
        this.model.selectChannelSelecting = false;
      });
    }
  };

  @action
  showReconnectChannelModal = (channel: ChannelSchema) => {
    this.model.reconnectChannelShow = true;
    this.model.reconnectChannelObject = channel;
  };

  @action
  hideReconnectChannelModal = () => {
    this.model.reconnectChannelShow = false;
    this.model.reconnectChannelObject = null;
  };

  @action
  hideMustReAuthModal = () => {
    this.auth.mustReAuth = false;
  };

  @action
  showLicenseModal = async (track: TrackSchema) => {
    this.model.licenseShow = true;

    if (!this.auth.user && (!this.model.licenseRates || this.model.licenseRates.length === 0)) {
      this.model.licenseLoading = true;

      await this.pricing.getDefaultRates();

      this.model.licenseLoading = false;
    }

    this.model.licenseTrack = track;
  };

  @action
  hideLicenseModal = () => {
    this.model.licenseShow = false;
  };

  @action
  showSongInfoModal = async (track: TrackSchema): Promise<void> => {
    this.model.songInfoShow = true;
    const userRegion = this.auth.user ? this.auth.user.country : null;
    this.model.songInfoTrack = (
      await this.api.track.getTrack(
        track.identity,
        ['video_platforms', 'releases', 'reporting_party', 'tags', 'images'],
        userRegion
      )
    ).data;
  };

  @action
  hideSongInfoModal = (): boolean => (this.model.songInfoShow = false);

  @action
  licenseModalSelectRate = async (rateId: string) => {
    const licenseType = rateId.split('_').join(' ').toUpperCase();
    this.analytics.sendMixpanel(`${licenseType} selected`, {
      identity: this.model.licenseTrack.identity,
      title: this.model.licenseTrack.title,
      slug: this.model.licenseTrack.slug,
    });
    try {
      await this.basket.addToBasket(this.model.licenseTrack);
    } catch (e) {
      this.showBasketErrorModal();
    }
    this.model.licenseShow = false;
  };

  @action
  showBasketErrorModal = () => {
    this.model.basketErrorShow = true;
  };

  @action
  hideBasketErrorModal = async () => {
    this.model.basketErrorShow = false;
  };

  @action
  showRestrictedModal = () => {
    this.model.restrictedShow = true;
  };

  @action
  hideRestrictedModal = () => {
    this.model.restrictedShow = false;
  };

  @action
  showBasketRestrictedModal = async () => {
    this.model.basketRestrictedShow = true;
  };

  @action
  hideBasketRestrictedModal = async () => {
    this.model.basketRestrictedShow = false;
  };

  @action
  showDeleteAccountModal = async () => {
    this.model.deleteAccountShow = true;

    if (!this.model.deleteAccountPageBody.length) {
      this.model.deleteAccountLoading = true;
      await this.env.ready;
      this.model.deleteAccountPageBody = this.content.deleteModalContent;
    }

    this.model.deleteAccountLoading = false;
  };

  @action
  hideDeleteAccountModal = () => {
    this.model.deleteAccountShow = false;
  };

  @action
  submitDeleteAccount = async (reason: string, recommend: string) => {
    try {
      this.model.deleteAccountSaving = true;

      const answers = [reason.length > 0 && { reason }, recommend.length > 0 && { recommend }].filter(Boolean);

      if (answers.length) {
        const responses = answers.reduce((out, resp) => ({ ...out, ...resp }), {});
        await this.api.user.updateSurvey(this.auth.user.identity, 'delete_account', responses);
      }

      const name = this.auth.user.first_name || this.auth.user.name;
      const email = this.auth.user.email;

      await this.api.user.deleteAccount();
      await this.router.push('/');
      await this.authController.logout(false);
    } catch (e) {
      this.model.deleteAccountError = ((e as HttpError).body as APIError).error;
    } finally {
      this.model.deleteAccountSaving = false;
    }
  };

  @action
  showDownloadAccountDataModal = async () => {
    this.model.downloadAccountDataSuccess = false;
    this.model.downloadAccountDataError = null;
    this.model.downloadAccountDataLoading = false;
    this.model.downloadAccountDataSaving = false;
    this.model.downloadAccountDataShow = true;
  };

  @action
  hideDownloadAccountDataModal = () => {
    this.model.downloadAccountDataShow = false;
  };

  @action
  submitDownloadAccountData = async () => {
    this.model.downloadAccountDataError = null;

    try {
      this.model.downloadAccountDataSaving = true;

      await this.api.user.downloadAccountData();

      this.model.downloadAccountDataSuccess = true;
    } catch (e) {
      this.model.downloadAccountDataError = ((e as HttpError).body as APIError).error;
    } finally {
      this.model.downloadAccountDataSaving = false;
    }
  };

  @action
  showCreatePlaylistModal = () => {
    this.model.showCreatePlaylistModal = true;
    this.analytics.sendMixpanel('User opens Create User Playlist modal');
  };

  @action
  hideCreatePlaylistModal = () => {
    // Clean up the create playlist track ID
    if (this.model.createPlaylistModalTrackId !== null) {
      this.setCreatePlaylistModalTrackId(null);
    }

    this.model.showCreatePlaylistModal = false;
  };

  @action
  handleCreatePlaylistModalSuccess = () => {
    // Clean up the create playlist track ID
    if (this.model.createPlaylistModalTrackId !== null) {
      this.setCreatePlaylistModalTrackId(null);
    }

    // Then reload the user playlists
    this.user.loadPlaylists();
  };

  @action
  setCreatePlaylistModalTrackId = (id: string | null): void => {
    this.model.createPlaylistModalTrackId = id;
  };

  @action
  showBasketUnsubscribedModal = (): void => {
    this.model.showBasketUnsubscribedModal = true;
    this.analytics.sendMixpanel('Unsubscribed user attempts to add an included track to the basket');
  };

  @action
  hideBasketUnsubscribedModal = (): void => {
    this.model.showBasketUnsubscribedModal = false;
    this.analytics.sendMixpanel('User closes modal (unsubscribed included modal)');
  };

  @action
  setShowSavingsReminderModal = (val: boolean): void => {
    this.model.showSavingsReminderModal = val;
  };

  @action
  hideSavingsReminderModal = (): void => {
    this.setShowSavingsReminderModal(false);
    this.analytics.sendMixpanel('Savings reminder modal closed');
  };

  @action
  showPostDowngradeModal = (planName: string): void => {
    this.model.postDowngradeModalSelectedPlan = planName;
    this.model.showPostDowngradeModal = true;
  };

  @action
  hidePostDowngradeModal = (): void => {
    this.model.postDowngradeModalSelectedPlan = null;
    this.model.showPostDowngradeModal = false;
    this.analytics.sendMixpanel('User closes modal (post-downgrade modal)');
  };

  @action
  showPostUpgradeModal = (planName: string, credits: number): void => {
    this.model.postUpgradeModalPlanName = planName;
    this.model.postUpgradeModalPlanCredits = credits;
    this.model.showPostUpgradeModal = true;
  };

  @action
  hidePostUpgradeModal = (): void => {
    this.model.postUpgradeModalPlanName = null;
    this.model.postUpgradeModalPlanCredits = null;
    this.model.showPostUpgradeModal = false;
    this.analytics.sendMixpanel('User closes modal (post-upgrade modal)');
  };

  @action
  showPreDowngradeModal = (targetPlan: V2Plan): void => {
    this.model.preDowngradeModalSelectedPlan = targetPlan;
    this.model.showPreDowngradeModal = true;
  };

  @action
  hidePreDowngradeModal = (): void => {
    this.model.showPreDowngradeModal = false;
    this.analytics.sendMixpanel('User closes modal (pre-downgrade modal)');

    // Delay until modal has disappeared
    setTimeout(() => {
      this.model.preDowngradeModalSelectedPlan = null;
    }, 500);
  };

  @action
  showPreUpgradeModal = (targetPlan: V2Plan): void => {
    this.model.preUpgradeModalSelectedPlan = targetPlan;
    this.model.showPreUpgradeModal = true;
  };

  @action
  hidePreUpgradeModal = (): void => {
    this.model.showPreUpgradeModal = false;
    this.analytics.sendMixpanel('User closes modal (pre-upgrade modal)');

    setTimeout(() => {
      this.model.preUpgradeModalSelectedPlan = null;
    }, 500);
  };
}
