import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';
import CurvedArrowImg from '../../../../static/curved-arrow.png';
import CurvedArrowImg2x from '../../../../static/curved-arrow@2x.png';

export const CurvedArrow: React.FC = () => (
  <img
    src={CurvedArrowImg}
    alt="Grafitti-style image of a curved arrow"
    srcSet={`${CurvedArrowImg}, ${CurvedArrowImg2x} 2x`}
    width={100}
    height={100}
    loading="lazy"
  />
);
