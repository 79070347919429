import * as React from 'react';
import { classes, style } from 'typestyle';
import { px, url } from 'csx';
import CheckmarkIcon from '../../../static/checkmark-white.svg';

const styles = style({
  listStyle: 'none',
  padding: 0,
  $nest: {
    '& li': {
      marginLeft: px(20),
      marginBottom: px(16),
      position: 'relative',
      paddingLeft: '6px',
      $nest: {
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: px(-20),
          width: px(20),
          height: px(20),
          backgroundImage: url(CheckmarkIcon),
          backgroundSize: 'cover',
        },
        '& p': {
          margin: 0,
        },
      },
    },
  },
});

export type CheckmarkListProps = {
  className?: string;
};

export const CheckmarkList: React.FC<CheckmarkListProps> = ({ children, className }) => (
  <ul className={classes(styles, className)}>{children}</ul>
);
