import React from 'react';

type AbstractStatefulCheckboxState = { checked: boolean };
export class AbstractStatefulCheckbox<T = Record<string, any>> extends React.Component<T> {
  state = {
    checked: false,
  };

  constructor(props) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      checked: event.currentTarget.checked,
    });
  }
}
