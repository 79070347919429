import localStorage from 'mobx-localstorage';
import { observable, ObservableMap } from 'mobx';
import { EnvModel } from '../env/model';

export type StorageModel = typeof localStorage;
export const StorageModel = localStorage;

// SessionStorageModel provides a MobX compatible interface to session storage on the client side
// All calls to the interface on the server side are no-ops to avoid state leaking between requests
const observableSessionStorage: ObservableMap<string, string> = observable.map({ ...sessionStorage });
export const SessionStorageModel = {
  get length() {
    return observableSessionStorage.size;
  },
  clear(): void {
    if (EnvModel.isServer) return;
    sessionStorage.clear();
    observableSessionStorage.clear();
  },
  getItem(key: string): any {
    if (EnvModel.isServer) return null;
    const stringValue = observableSessionStorage.get(key);
    try {
      return JSON.parse(stringValue);
    } catch {
      return null;
    }
  },
  key(index: number): any {
    if (EnvModel.isServer) return null;
    const array = Array.from(observableSessionStorage.keys());
    const stringValue = observableSessionStorage.get(array[index]);
    try {
      return JSON.parse(stringValue);
    } catch {
      return null;
    }
  },
  removeItem(key: string): void {
    if (EnvModel.isServer) return;
    sessionStorage.removeItem(key);
    observableSessionStorage.delete(key);
  },
  setItem(key: string, value: any): void {
    if (EnvModel.isServer) return;
    const stringValue = JSON.stringify(value);
    sessionStorage.setItem(key, stringValue);
    observableSessionStorage.set(key, stringValue);
  },
};
