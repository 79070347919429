import { observable, computed, runInAction } from 'mobx';
import { EnvModel } from '../env/model';
import { createSimpleSchema, list, object, serializable, update } from 'serializr';
import { SerializeTrackSchema } from '../../lib/serialization';
import {PlayerBarModel} from '../../components/player-bar';
import {TrackSchema} from '../../types/schema';
import {AuthModel} from '../auth/model';

export class PlayerModel {
  constructor(
    private env: EnvModel,
    private auth: AuthModel,
  ) {
    this.loadInitial();
  }

  @observable
  listeners: Array<(play: boolean) => any> = [];

  @observable
  loading: boolean = false;

  loadInitial = () => {
    if (!this.env.isServer) {
      const { initialPlayerModel } = window as any;
      if (initialPlayerModel) runInAction(() => {
        update(this, initialPlayerModel);
      });
    };
  };

  @observable
  startAt: number | null = null;

  @computed
  get player(): PlayerBarModel {
    return {
      track: this.currentTrack,
      duration: this.duration || (this.currentTrack ? this.currentTrack.duration : 0),
      isPlaying: this.play,
      volume: this.volume,
      user: this.auth.user,
      signInHref: this.auth.youtubeLoginURL,
      startAt: this.startAt
    }
  }

  @computed
  get track(): TrackSchema {
    return this.currentTrack;
  }

  @computed
  get isPlaying(): boolean {
    return this.play;
  }

  @observable
  playlist: Array<TrackSchema> = [];

  @computed
  get playlistLength(): number {
    return this.playlist.length;
  }

  @serializable(object(SerializeTrackSchema))
  @observable
  currentTrack: TrackSchema;

  @computed
  get currentIndex(): number {
    if (!this.currentTrack) return -1;
    return this.playlist.findIndex(match => match.slug === this.currentTrack.slug);
  }

  @computed
  get previousTrack(): TrackSchema {
    return (this.playlistLength > 0 && this.currentIndex >= 1)
      ? this.playlist[this.currentIndex - 1]
      : null;
  }

  @computed
  get nextTrack(): TrackSchema {
    return (this.playlistLength > 0 && this.currentIndex < this.playlistLength - 1)
      ? this.playlist[this.currentIndex + 1]
      : null;
  }

  @observable
  play: boolean = false;

  @observable
  stop: boolean = true;

  @observable
  position: number = 0;

  @observable
  duration: number = 0;

  @observable
  volume: number = 100;

  @computed
  get status(): PlayerStatus {
    switch (true) {
      case (this.play): return ReactSoundStatus.PLAYING;
      case (this.stop): return ReactSoundStatus.STOPPED;
      default:          return ReactSoundStatus.PAUSED;
    }
  };

  @computed
  get url(): string {
    try {
      return this.currentTrack.audio.url;
    } catch (e) {
      return null;
    }
  }

  @computed
  get playerOptions(): ReactSoundOptions {
    return {
      url: this.url,
      playStatus: this.status,
      position: this.position,
      volume: this.volume
    };
  }
}

export interface ReactSoundOptions {
  url: string;
  playStatus: string;
  playFromPosition?: number;
  position?: number;
  volume: number;
  onLoading?(): any;
  onPlaying?(): any;
  onFinishedPlaying?(): any;
}

// duplicated from react-sound lib
export const ReactSoundStatus = {
  PLAYING: 'PLAYING',
  STOPPED: 'STOPPED',
  PAUSED: 'PAUSED'
};

export type PlayerStatus = string;