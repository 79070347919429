import { ArtistPageModel } from './artist/model';
import { CheckoutPageModel } from './checkout/model';
import { HomePageModel } from './home/model';
import { PricingPageModel } from './pricing/model';
import { AccountLicencesPageModel } from './account-licences/model';
import { AccountOrdersPageModel } from './account-orders/model';
import { AccountOrderPageModel } from './account-order/model';
import { clearInitial } from '../../lib/ssr';
import { SearchPageModel } from './search/model';
import { AccountChannelsPageModel } from './account-channels/model';
import { AccountChannelPageModel } from './account-channel/model';
import { AccountBillingPageModel } from './account-billing/model';
import { TermsAndConditionsPageModel } from './terms-and-conditions/model';
import { ContactPageModel } from './contact/model';
import { PrivacyPolicyPageModel } from './privacy-policy/model';
import { FAQPageModel } from './faq/model';
import { AboutPageModel } from './about/model';
import { AccountCommunicationPageModel } from './account-communication/model';
import { SwapoutsPageModel } from './swapouts/model';
import { LicensingPageModel } from './licensing/model';
import { AccountInvitesPageModel } from './account-invites/model';
import { CookiePolicyPageModel } from './cookie-policy/model';
import { ArtistsPageModel } from './artists/model';
import { AccountAttributionPageModel } from './account-attribution/model';
import { FavouritesPageModel } from './favourites/model';
import { FeaturesPageModel } from './features/model';
import { TrackPageModel } from './track/model';
import { AccountProfilePageModel } from './account-profile/model';
import { DiscoverPageModel } from './discover/model';
import { YouTubePageModel } from './youtube/model';
import { MusicSubmissionsPageModel } from './music-submissions/model';
import { LearnPageModel } from './learn/model';
import { BrowsePageModel } from './browse/model';
import { EditorPageModel } from './editor/model';
import { CareersPageModel } from './careers/model';
import { ArticlesPageModel } from './articles/model';
import { GoldenTicketPageModel } from './golden-ticket/model';
import { ChannelReferralPageModel } from './channel-referral/model';
import { AccountCouponsPageModel } from './account-coupons/model';
import { LandingPageModel } from './landing/model';
import { MCNPageModel } from './mcn/model';
import { PartnerPageModel } from './partner/model';
import { AffiliatePageModel } from './affiliate/model';
import { FakeDoorPageModel } from './fake-door/model';
import { LightweightLandingModel } from './lightweight-landing/model';
import { LanderModel } from '../../components/landers/model';
import { EnvModel } from '../env/model';
import { RouterModel } from '../router/model';
import { isEmpty } from '../../components/project-happy/utilities/objects';
import { EssentialsTermsAndConditionsPageModel } from './essentials-terms-and-conditions/model';
import { CreatorDashboardModel } from './creator-dashboard/model';
import { RegistrationPageModel } from './registration/model';
import { AccountVideosPageModel } from './account-videos/model';
import { AccountHubPageModel } from './account-hub/model';
import { AccountPlaylistsPageModel } from './account-playlists/model';
import { SearchMvpPageModel } from './search-mvp/model';
import { UserPlaylistsPageModel } from './user-playlists/model';

export class PageModel {
  [key: string]: any;
  home: HomePageModel;
  creatorDashboard: CreatorDashboardModel;
  registration: RegistrationPageModel;
  articles: ArticlesPageModel;
  artist: ArtistPageModel;
  checkout: CheckoutPageModel;
  browse: BrowsePageModel;
  pricing: PricingPageModel;
  accountHub: AccountHubPageModel;
  accountCoupons: AccountCouponsPageModel;
  accountInvites: AccountInvitesPageModel;
  accountLicences: AccountLicencesPageModel;
  accountVideos: AccountVideosPageModel;
  accountPlaylists: AccountPlaylistsPageModel;
  accountAttribution: AccountAttributionPageModel;
  accountOrders: AccountOrdersPageModel;
  accountOrder: AccountOrderPageModel;
  accountChannels: AccountChannelsPageModel;
  accountChannel: AccountChannelPageModel;
  accountBilling: AccountBillingPageModel;
  accountCommunication: AccountCommunicationPageModel;
  accountProfile: AccountProfilePageModel;
  contact: ContactPageModel;
  search: SearchPageModel;
  searchMvp: SearchMvpPageModel;
  privacyPolicy: PrivacyPolicyPageModel;
  faq: FAQPageModel;
  about: AboutPageModel;
  termsAndConditions: TermsAndConditionsPageModel;
  essentialsTermsAndConditions: EssentialsTermsAndConditionsPageModel;
  cookiePolicy: CookiePolicyPageModel;
  licensing: LicensingPageModel;
  lightweightLanding: LightweightLandingModel;
  lander: LanderModel;
  swapouts: SwapoutsPageModel;
  artists: ArtistsPageModel;
  favourites: FavouritesPageModel;
  features: FeaturesPageModel;
  track: TrackPageModel;
  discover: DiscoverPageModel;
  youtube: YouTubePageModel;
  musicSubmissions: MusicSubmissionsPageModel;
  learn: LearnPageModel;
  careers: CareersPageModel;
  editor: EditorPageModel;
  goldenTicket: GoldenTicketPageModel;
  channelReferral: ChannelReferralPageModel;
  landing: LandingPageModel;
  mcn: MCNPageModel;
  partner: PartnerPageModel;
  affiliate: AffiliatePageModel;
  fakedoor: FakeDoorPageModel;
  userPlaylists: UserPlaylistsPageModel;

  initialRoute: string;

  /*
   * _never_ pass other models into PageModels, because they will be fully serialized
   * for server side rendering and overwrite models loaded on client side
   */
  constructor(router: RouterModel) {
    this.home = new HomePageModel();
    this.creatorDashboard = new CreatorDashboardModel();
    this.registration = new RegistrationPageModel();
    this.articles = new ArticlesPageModel();
    this.artist = new ArtistPageModel();
    this.checkout = new CheckoutPageModel();
    this.browse = new BrowsePageModel();
    this.pricing = new PricingPageModel();
    this.accountHub = new AccountHubPageModel();
    this.accountCoupons = new AccountCouponsPageModel();
    this.accountInvites = new AccountInvitesPageModel();
    this.accountLicences = new AccountLicencesPageModel();
    this.accountVideos = new AccountVideosPageModel();
    this.accountPlaylists = new AccountPlaylistsPageModel();
    this.accountAttribution = new AccountAttributionPageModel();
    this.accountOrders = new AccountOrdersPageModel();
    this.accountOrder = new AccountOrderPageModel();
    this.accountChannels = new AccountChannelsPageModel();
    this.accountChannel = new AccountChannelPageModel();
    this.accountBilling = new AccountBillingPageModel();
    this.accountCommunication = new AccountCommunicationPageModel();
    this.accountProfile = new AccountProfilePageModel();
    this.contact = new ContactPageModel();
    this.search = new SearchPageModel();
    this.searchMvp = new SearchMvpPageModel();
    this.privacyPolicy = new PrivacyPolicyPageModel();
    this.faq = new FAQPageModel();
    this.about = new AboutPageModel();
    this.termsAndConditions = new TermsAndConditionsPageModel();
    this.essentialsTermsAndConditions = new EssentialsTermsAndConditionsPageModel();
    this.cookiePolicy = new CookiePolicyPageModel();
    this.licensing = new LicensingPageModel();
    this.lightweightLanding = new LightweightLandingModel();
    this.lander = new LanderModel();
    this.swapouts = new SwapoutsPageModel();
    this.artists = new ArtistsPageModel();
    this.favourites = new FavouritesPageModel();
    this.features = new FeaturesPageModel();
    this.track = new TrackPageModel();
    this.discover = new DiscoverPageModel();
    this.youtube = new YouTubePageModel();
    this.musicSubmissions = new MusicSubmissionsPageModel();
    this.learn = new LearnPageModel();
    this.careers = new CareersPageModel();
    this.editor = new EditorPageModel();
    this.goldenTicket = new GoldenTicketPageModel();
    this.channelReferral = new ChannelReferralPageModel();
    this.landing = new LandingPageModel();
    this.mcn = new MCNPageModel();
    this.partner = new PartnerPageModel();
    this.affiliate = new AffiliatePageModel();
    this.fakedoor = new FakeDoorPageModel();
    this.userPlaylists = new UserPlaylistsPageModel();

    this.initialRoute = EnvModel.isServer ? router.location.pathname : '/';

    this.loadInitial();
  }

  loadInitial = () => {
    if (EnvModel.isServer) return;

    const { initialPageModel } = window as any;
    if (!isEmpty(initialPageModel)) {
      Object.keys(initialPageModel).forEach((page) => {
        const pageModel = this[page];
        if (pageModel === null) return;
        if (typeof pageModel === 'object') {
          Object.keys(initialPageModel[page]).forEach((key) => {
            pageModel[key] = initialPageModel[page][key];
          });
          return;
        }
        this[page] = initialPageModel[page];
      });
    } else {
      clearInitial();
    }
  };
}
