import * as React from 'react';
import { ReactNodeArray } from 'react';
import { classes, style } from 'typestyle';
import { em, percent, px } from 'csx';
import { colorBrand } from '../../../../theme/color';
import { ContentSlice, ContentSliceProps } from './ContentSlice';
import { YouTubeBadge } from '../../atoms/YouTubeBadge';
import { Button } from '../../atoms/button/Button';
import { isEmpty } from '../../utilities/objects';
import { mediaDesktop, mediaUpToDesktop } from '../../utilities/mediaQueries';
import { heading4, headingStyles } from '../../utilities/font';
import { ButtonProminence, ButtonSize } from '../../atoms/button/buttonStyles';

const { PRIMARY } = ButtonProminence;
const { LARGE } = ButtonSize;

const styles = {
  wrapper: style({
    position: 'relative',
    textAlign: 'center',
    $nest: {
      '.with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient, .with-background + &:not(.with-background), :not(.with-background) + &.with-background': {
        paddingTop: px(56),
      },
      '.masthead + &': {
        marginTop: px(-80),
      },
    },
  }),
  content: style({
    paddingBottom: px(40),
  }),
  creatorPanel: style(
    {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
      borderRadius: em(0.5),
      overflow: 'hidden',
      marginBottom: px(24),
    },
    mediaUpToDesktop({
      width: px(155),
    }),
    mediaDesktop({
      width: px(195),
    })
  ),
  creatorImage: style({
    width: percent(100),
    height: 'auto',
  }),
  badge: style({
    backgroundColor: '#121212',
    color: 'white',
    padding: px(8),
  }),
  quote: style(headingStyles, heading4, {
    color: colorBrand.toHexString(),
    margin: 0,
    marginTop: px(24),
    $nest: {
      '& > span': {
        display: 'block',
      },
      '& > span:first-child::before': {
        content: 'open-quote',
      },
      '& > span:last-child::after': {
        content: 'close-quote',
      },
    },
  }),
  button: style({
    marginTop: px(32),
  }),
};

type CreatorTestimonialSliceProps = ContentSliceProps & {
  creatorImage: string;
  channelImg: string;
  channelName: string;
  channelSubscribers: number;
  quote?: ReactNodeArray;
  cta?: { href: string; text: string };
};

export const CreatorTestimonialSlice: React.SFC<CreatorTestimonialSliceProps> = ({
  children,
  className,
  creatorImage,
  channelImg,
  channelName,
  channelSubscribers,
  quote,
  cta,
  ...props
}) => (
  <ContentSlice
    className={classes('creator-testimonial-slice', className, styles.wrapper)}
    contentClassName={styles.content}
    {...props}
  >
    {children}
    <div className={styles.creatorPanel}>
      <img className={styles.creatorImage} src={creatorImage} />
      <YouTubeBadge
        className={styles.badge}
        src={channelImg}
        channelName={channelName}
        subscriberCount={channelSubscribers}
      />
    </div>
    {quote && <p className={styles.quote}>{quote}</p>}
    {!isEmpty(cta) && (
      <Button prominence={PRIMARY} href={cta.href} className={styles.button} size={LARGE}>
        {cta.text}
      </Button>
    )}
  </ContentSlice>
);
