import * as React from 'react';
import { style } from 'typestyle';
import { betweenJustified, endJustified, horizontal, start, vertical } from 'csstips';
import { colorGunmetal, colorWhite } from '../theme/color';
import { percent, px } from 'csx';
import { ResponsiveImageTile } from './responsive-image-tile';
import {ImagePreloader} from '../types/fn';

export interface ReleaseTileProps {
  release: {
    images?: {
      identity: string;
    };
    title: string;
  };
  href: string;
  className?: string;
  onClickLink(event: React.MouseEvent<any>): void;
  preload: ImagePreloader;
  ratio?: number;
  Favourite?: any;
}

export class ReleaseTile extends React.Component<ReleaseTileProps, any> {
  render() {
    const { release, onClickLink, ratio = 1, preload, className, href } = this.props;

    const image = release.images ? release.images.identity : null;

    const props = {
      identity: image,
      onClick: onClickLink,
      preload,
      ratio,
      href,
      className,
    };

    return (
      <ResponsiveImageTile {...props}>
        <div title={release.title} className={ReleaseTile.styles.container}>
          <div className={ReleaseTile.styles.buttons}></div>
          <div className={ReleaseTile.styles.name}>{release.title}</div>
        </div>
      </ResponsiveImageTile>
    );
  }

  static styles = {
    container: style({
      ...vertical,
      ...start,
      ...betweenJustified,
      textOverflow: 'hidden',
      overflow: 'hidden',
      width: percent(100),
    }),
    name: style({
      cursor: 'pointer',
      padding: px(10),
      background: colorGunmetal.fade(0.5).toString(),
      fontSize: px(16),
      lineHeight: px(16),
      width: percent(100),
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'pre',
      color: colorWhite.toString(),
      $nest: {
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    }),
    buttons: style({
      ...horizontal,
      ...endJustified,
      width: percent(100),
      padding: px(10),
    }),
  };
}