import { style } from 'typestyle';
import * as React from 'react';
import { aroundJustified, horizontal, horizontallySpaced, vertical, verticallySpaced } from 'csstips';
import { ButtonPill } from './button-pill';
import { rem } from 'csx';
import { colorBrand } from '../theme/color';
import { mediaMobileOnly, mediaTablet } from '../theme/media';

export default class ModalScreen extends React.Component<any, any> {
  buttons() {
    if (this.props.hasPrev) {
      return (
        <div className={ModalScreen.styles.buttons}>
          <ButtonPill onClick={() => this.props.handlePrev()} muted>
            Back
          </ButtonPill>
          {this.props.buttons}
          <ButtonPill onClick={() => this.props.handleClose()} muted>
            Close
          </ButtonPill>
        </div>
      );
    }

    if (this.props.hasNext) {
      return (
        <div className={ModalScreen.styles.buttons}>
          <ButtonPill onClick={() => this.props.handleClose()} muted>
            Close
          </ButtonPill>
          {this.props.buttons}
          <ButtonPill onClick={() => this.props.handleNext()} muted>
            Next
          </ButtonPill>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this.props.children}
        {this.buttons()}
      </div>
    );
  }

  static styles = {
    buttons: style(
      mediaMobileOnly({
        ...vertical,
        ...verticallySpaced(10),
      }),
      {
        marginTop: rem(3),
        $nest: {
          '& div': {
            color: colorBrand.toString(),
            paddingLeft: rem(3),
            paddingRight: rem(3),
          },
        },
      },
      mediaTablet({
        ...horizontal,
        ...horizontallySpaced(10),
        ...aroundJustified,
      })
    ),
  };
}
