import { observable } from 'mobx';
import { FormInviteModel } from '../../../components/form-invite';
import { createInviteForm } from '../../../lib/form';
import { InviteSchema } from '../../../types/schema';
import { PageAccountInvitesModel } from '../../../components/page-account-invites';

export class AccountInvitesPageModel implements PageAccountInvitesModel {
  @observable
  loading = false;

  @observable
  invites: Array<InviteSchema> = [];

  @observable
  form: FormInviteModel = createInviteForm();

  @observable
  title: string = null;

  @observable
  content: any = null;

  @observable
  cta: string = null;

  @observable
  referralURL = '';

  @observable
  referralTitle = '';
}
