import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalChildStyle } from './modal';
import { style } from 'typestyle';
import {
  betweenJustified,
  endJustified,
  horizontal,
  horizontallySpaced,
  start,
  startJustified,
  vertical,
  verticallySpaced,
} from 'csstips';
import { ButtonPill } from './button-pill';
import { colorBrand, colorContrast, colorGunmetal, colorSubtle, colorWhite } from '../theme/color';
import { resetAnchorStyles } from '../theme/reset';
import { em, percent, px } from 'csx';
import { TrackSchema } from '../types/schema';
import { NEW_USER_TYPES } from '../constants';

export interface LicenseModalModel {
  show: boolean;
  loading: boolean;
  track: TrackSchema;
  firstFree?: boolean;
  showPrices?: boolean;
  rates: Array<{
    id: string;
    label: string;
    value: string;
    available: boolean;
  }>;
}

export interface LicenseModalProps {
  model: LicenseModalModel;
  onRequestClose(): void;
  onSelectRate(rateId: string): void;
  coupon?: any;
  i18n?: {
    title: string;
    strap: any;
    loading: string;
    not_available: string;
    close: string;
    first_discount: string;
    affiliate_discount: string;
  };
}

const renderDiscountMessage = (param) => {
  switch (param) {
    case 'new_user_50':
      return '50% off your first track';
    case 'first_use':
      return 'Your first track is free';
    default:
      return '25% off your first track';
  }
};

@observer
export class LicenseModal extends React.Component<LicenseModalProps, any> {
  render() {
    const { model, coupon, i18n = LicenseModal.i18n, onRequestClose, onSelectRate } = this.props;
    const showCouponDiscount = coupon && NEW_USER_TYPES.includes(coupon.type);

    return (
      <Modal show={model.show} onRequestClose={onRequestClose}>
        <div className={LicenseModal.styles.container}>
          <h3>{i18n.title}</h3>
          {model.loading && (
            <div className={LicenseModal.styles.buttonsLicense}>
              <ButtonPill className={LicenseModal.styles.button} disabled={true}>
                <span>{i18n.loading}</span>
              </ButtonPill>
            </div>
          )}
          {!model.loading && (
            <div className={LicenseModal.styles.buttonsLicense}>
              {model.rates.map((rate, index) => (
                <ButtonPill
                  key={index.toString()}
                  className={LicenseModal.styles.button}
                  primary={true}
                  disabled={!rate.available}
                  onClick={rate.available ? onSelectRate.bind(null, rate.id) : void 0}
                >
                  <span className={LicenseModal.styles.mobileView}>{rate.label}</span>
                  {showCouponDiscount && index === 0 && (
                    <span className={LicenseModal.styles.mobileView}>
                      {model.showPrices && (
                        <span className={`${LicenseModal.styles.discount} ${LicenseModal.styles.mobileView}`}>
                          {rate.available ? rate.value : i18n.not_available}
                        </span>
                      )}
                      <span className={LicenseModal.styles.mobileView}>{renderDiscountMessage(coupon.type)}</span>
                    </span>
                  )}
                  {!(showCouponDiscount && index === 0) && (
                    <span className={LicenseModal.styles.mobileView}>
                      {rate.available ? (model.showPrices ? rate.value : '') : i18n.not_available}
                    </span>
                  )}
                </ButtonPill>
              ))}
            </div>
          )}
          <div className={LicenseModal.styles.buttons}>
            <ButtonPill onClick={onRequestClose}>{i18n.close}</ButtonPill>
          </div>
        </div>
      </Modal>
    );
  }

  static i18n = {
    title: 'License track',
    loading: 'Fetching rates...',
    not_available: 'Not available',
    close: 'Close',
    first_discount: '25% off your first track',
    affiliate_discount: '50% off your first track',
  };

  static styles = {
    container: style(ModalChildStyle, {
      ...ModalChildStyle,
      width: px(420),
      maxWidth: 'calc(100vw - 4rem)',
      minWidth: px(326),
      background: colorGunmetal.toString(),
      overflowX: 'hidden',
      $nest: {
        '&> h3': {
          color: colorContrast.toString(),
          fontSize: px(30),
          margin: '0 0 30px 0',
        },
        '& p': {
          color: colorWhite.toString(),
          margin: '5px 0 30px 0',
          lineHeight: em(1.4),
        },
      },
    }),
    link: style({
      color: colorBrand.toString(),
      $nest: { ...resetAnchorStyles(colorBrand.toString()) },
    }),
    buttons: style({
      ...horizontal,
      ...horizontallySpaced(10),
      ...endJustified,
      margin: '30px 0 0 0',
    }),
    buttonsLicense: style({
      ...vertical,
      ...verticallySpaced(10),
      ...startJustified,
      ...start,
      margin: 0,
    }),
    button: style({
      ...horizontal,
      ...betweenJustified,
      width: percent(100),
    }),
    loading: style({
      padding: px(30),
      textAlign: 'center',
      color: colorSubtle.toString(),
    }),
    discount: style({
      textDecoration: 'line-through',
      marginRight: px(5),
    }),
    mobileView: style({
      $nest: {
        '@media screen and (max-width: 448px)': {
          fontSize: px(13),
        },
      },
    }),
  };
}
