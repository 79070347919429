import * as React from 'react';
import { Component, inject, deps, observer } from '../lib/component';
import { Loading } from '../components/loading/loading';
import { style } from 'typestyle';
import { em, px } from 'csx';
import { AccountProfilePage } from './account-profile';
import {ButtonPill} from '../components/button-pill';
import {FormMarketingPreferencesModal} from '../components/form-marketing-preferences-modal';
import {PageAccountCommunication} from '../components/page-account-communication';
import {colorSubtle} from '../theme/color';
import {centerCenter} from 'csstips';
import {AccountLayout} from "../components/project-happy/templates/AccountLayout";

export interface AccountCommunicationProps {
  i18n?: {
    marketingTitle: string;
    marketingEdit: string;
  }
}

@inject(deps) @observer
export class AccountCommunicationPage extends Component<AccountCommunicationProps> {
  render() {
    const { i18n = AccountCommunicationPage.i18n } = this.props;
    const model = this.props.model.page.accountCommunication;
    const controller = this.props.controller.page.accountCommunication;
    const {user} = this.props.model.user;

    if (model.loading || !user) return (
      <AccountLayout>
        <div className={AccountCommunicationPage.styles.containerLoading}>
          <Loading />
        </div>
      </AccountLayout>
    );

    const { marketing_allowed } = this.props.model.user.user;

    return (
      <AccountLayout>
        <div className={AccountCommunicationPage.styles.block}>
          <h3>{i18n.marketingTitle}</h3>
          <dl className={AccountProfilePage.styles.definitions}>
            <dt>Allow marketing</dt>
            <dd>{marketing_allowed ? `Yes` : `No`}</dd>
          </dl>
          <ButtonPill onClick={() => controller.editMarketing()}>
            {i18n.marketingEdit}
          </ButtonPill>
          <FormMarketingPreferencesModal
            model={model.modalMarketing}
            onChange={controller.changeMarketingField}
            onSubmit={controller.submitMarketingModal}
            onRequestClose={controller.closeMarketingModal}
            onCancel={controller.closeMarketingModal}
          />
        </div>
        <PageAccountCommunication
          model={model}
          i18n={model.i18n}
          onUpdatePreferences={this.props.controller.page.accountCommunication.setPreferences}
        />
      </AccountLayout>
    )
  }

  static i18n = {
    marketingTitle: 'My marketing preferences',
    marketingEdit: 'Edit marketing preferences'
  };

  static styles = {
    block: style({
      marginBottom: px(40)
    }),
    definitions: style({
      margin: '1em 0',
      $nest: {
        '& dt': {
          margin: 0,
          padding: 0,
          fontSize: px(12),
          lineHeight: em(1.1),
          color: colorSubtle.toString()
        },
        '& dd': {
          margin: '0 0 1em 0'
        },
        '& dd:last-child': {
          margin: 0
        }
      }
    }),
    containerLoading: style({
      ...centerCenter,
      height: px(120),
    })
  }
}
