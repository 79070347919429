import React from 'react';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { desktopBreakpoint, mediumDesktopBreakpoint } from '../config';
import { mediaDesktop } from '../utilities/mediaQueries';
interface ContainerProps {
  className?: string;
  wide?: boolean;
}

export const Container: React.FC<React.PropsWithChildren<ContainerProps>> = ({
  className,
  wide = false,
  children,
  ...rest
}: React.PropsWithChildren<ContainerProps>) => {
  const maxWidth = wide ? mediumDesktopBreakpoint : desktopBreakpoint;

  const containerStyles = style(
    {
      paddingLeft: '1rem',
      paddingRight: '1rem',
      margin: '0 auto',
      maxWidth: px(maxWidth),
    },
    mediaDesktop({
      paddingLeft: '2rem',
      paddingRight: '2rem',
    })
  );

  return (
    <section className={classes(containerStyles, className)} {...rest}>
      {children}
    </section>
  );
};
