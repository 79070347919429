import {BillingAddress, LocationSuggestion} from '../types';

export const suggestionToAddress = (suggestion: LocationSuggestion): BillingAddress => {
  const subParts: Array<string> = [];
  const lineParts: Array<string> = [];
  const result = {
    address_line1: '',
    address_city: '',
    address_state: '',
    address_zip: '',
    address_country: ''
  };

  suggestion.gmaps.address_components.forEach(c => {
    switch (true) {
      case (c.types.indexOf('street_number') >= 0):
      case (c.types.indexOf('premise') >= 0):
        return (subParts.unshift(c.long_name));
      case (c.types.indexOf('route') >= 0):
        return (lineParts.push(c.long_name));
      case (c.types.indexOf('locality') >= 0):
      case (c.types.indexOf('postal_town') >= 0):
        return (result.address_city = c.long_name);
      case (c.types.indexOf('administrative_area_level_2') >= 0):
      case (c.types.indexOf('administrative_area_level_1') >= 0):
        return (result.address_state = c.long_name);
      case (c.types.indexOf('postal_code') >= 0):
        return (result.address_zip = c.long_name);
      case (c.types.indexOf('country') >= 0):
        return (result.address_country = c.short_name);
    }
  });

  if (subParts.length) {
    lineParts.unshift(subParts.join(' '));
  }

  result.address_line1 = lineParts.join(', ');

  return {
    address1: result.address_line1,
    city: result.address_city,
    state: result.address_state,
    postcode: result.address_zip,
    country: result.address_country,
  }
};

export const addressToLabel = (address: BillingAddress): string => {
  return [address.address1, address.address2, address.city, address.country, address.postcode]
    .filter(e => !!e)
    .join(', ');
};