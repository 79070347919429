import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { percent, px } from 'csx';
import { YouTubeVideoTrackTile, YouTubeVideoTrackTileProps } from './YouTubeVideoTrackTile';
import { PeekingCarousel } from '../atoms/carousel/PeekingCarousel';
import { desktopOnlyPerPageStyles } from '../utilities/carousel';
import { mediaDesktop, mediaLargeTablet, mediaUpToDesktop, mediaUpToLargeTablet } from '../utilities/mediaQueries';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { desktopBreakpoint } from '../config';

const halfTileSize = 'max(160px, 10%)';
const tileSize = 'max(320px, 20%)';

const styles = {
  tile: style(
    {
      marginLeft: px(8),
      marginRight: px(8),
      $nest: {
        // Add buffers to the hidden tiles so the outer gutters still act as hover zones; Stops weird fidgety behaviour at the edges
        '&:first-child': {
          marginLeft: 0,
          $nest: {
            '&:after': {
              left: px(-16),
            },
          },
        },
        '&:last-child': {
          marginRight: 0,
          $nest: {
            '&:after': {
              right: px(-16),
            },
          },
        },
        '&:first-child:after, &:last-child:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          width: px(16),
        },
      },
    },
    mediaUpToDesktop({
      minWidth: px(272),
      $nest: {
        '&:first-child': {
          marginLeft: px(16),
        },
        '&:last-child': {
          marginRight: px(16),
        },
      },
    }),
    mediaDesktop({
      width: [percent(20), tileSize],
    })
  ),
  containerPositioning: style({
    width: [percent(120), `calc(100% + ${tileSize})`],
  }),
};

cssRaw(`
@supports (width: ${tileSize}) {
  @media (min-width: ${desktopBreakpoint}px) {
    .youtube-video-track-carousel .peeking-carousel-container {
      margin-left: calc(-1 * ${halfTileSize});
    }

    .youtube-video-track-carousel .peeking-carousel-container.first-hover {
      transform: translateX(calc(${halfTileSize} - ${px(16)}));
    }

    .youtube-video-track-carousel .peeking-carousel-container.last-hover {
      transform: translateX(calc(-${halfTileSize} + ${px(16)}));
    }
  }
}
`);

export type YouTubeVideoTrackCarouselProps = { className?: string; videos: YouTubeVideoTrackTileProps[] };

export const YouTubeVideoTrackCarousel: React.SFC<YouTubeVideoTrackCarouselProps> = ({ className, videos }) => (
  <PeekingCarousel className={classes('youtube-video-track-carousel', className)}>
    {videos.map(({ className: videoClassName, ...video }, key) => (
      <YouTubeVideoTrackTile
        key={key}
        className={classes(styles.tile, desktopOnlyPerPageStyles[videos.length - 1], videoClassName)}
        {...video}
      />
    ))}
  </PeekingCarousel>
);
