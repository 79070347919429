import './lib/CustomEvent';
import 'mobx-localstorage';
import 'intl';
import 'whatwg-fetch';
import './lib/matchMedia-shim';
import smoothscroll from 'smoothscroll-polyfill';
import 'url-search-params-polyfill';
smoothscroll.polyfill();

import * as React from 'react';
import { render } from 'react-dom';
import { browserHistory } from 'react-router';
import { createRoutes } from './routes';

import { ClientModel } from './modules/client/model';
import { ClientController } from './modules/client/controller';
import { ClientView } from './modules/client/view';
import { syncHistoryWithStore } from 'mobx-react-router';
import { setupGlobalCSS } from './lib/styles';
import { setStylesTarget } from 'typestyle';
import {
  BLOGOSPHERE_URL,
  JAACKMAATE_URL,
  SIGNIN_URL,
  VIDSUMMIT_URL,
  VIDCON_URL,
  FULLSCREEN_URL,
  ENV_KEY,
  PRISMIC_PREVIEW_KEY,
} from './constants';
import setJaackMaateCampaign from './lib/setJaackMaateCampaign';
import setVidsummitCampaign from './lib/setVidsummitCampaign';
import setBlogosphereCampaign from './lib/setBlogosphereCampaign';
import setVidconCampaign from './lib/setVidconCampaign';
import getCookieMap from './lib/getCookieMap';
import { FeatureFlagEvent, setFlag } from './components/devtools/feature-flags';
import Cookies from 'js-cookie';

let es6 = false;

const initFeatures = () => {
  if (!localStorage.getItem('flags')) {
    localStorage.setItem('flags', JSON.stringify([{ name: 'checkoutV2', description: 'New Checkout', active: true }]));
  }
};

const envVars: LickdEnvironmentVariables = Object.assign(
  { PRISMIC_REF: Cookies.get(PRISMIC_PREVIEW_KEY) },
  (window as { lickdEnvironment?: LickdEnvironmentVariables }).lickdEnvironment
);

function run() {
  const model = new ClientModel({ envVars });
  model.env.es6 = es6;

  // Retrieve cookie we set from api-backend for Paladin users
  const cookies = getCookieMap(document.cookie);

  // Old style feature flags, left for legacy
  if (cookies['enableFeatureFlag'] && JSON.parse(cookies['enableFeatureFlag'])) {
    initFeatures();
  } else {
    localStorage.removeItem('flags');
  }

  // External feature flag (for libraries such as VWO)
  if (!model.env.isServer) {
    window.addEventListener('LickdFeatureFlag', (event: FeatureFlagEvent) => {
      const detail = event.detail;
      if (
        typeof detail !== 'object' ||
        typeof detail === null ||
        typeof detail.flag !== 'string' ||
        typeof detail.flagState !== 'boolean'
      )
        return;
      setFlag(detail.flag, detail.flagState);
    });
  }

  const controller = new ClientController(model);

  /** Bail out early of running the frontend if we're JaackMaate campaign */
  if (document.location.pathname.startsWith(JAACKMAATE_URL)) return setJaackMaateCampaign();

  /** Bail out early of running the frontend if we're Vidsummit campaign */
  if (document.location.pathname.startsWith(VIDSUMMIT_URL)) return setVidsummitCampaign();

  /** Bail out early of running the frontend if we're Blogosphere campaign */
  if (document.location.pathname.startsWith(BLOGOSPHERE_URL)) return setBlogosphereCampaign();

  /** Bail out early of running the frontend if we're Vidcon campaign */
  if (document.location.pathname.startsWith(VIDCON_URL)) return setVidconCampaign();

  const history = syncHistoryWithStore(browserHistory, model.router) as any;
  const routes = createRoutes(controller, model);
  const routerProps = { history, routes };

  // NOTE: These established MCN urls are referenced because they specifically
  // need redirection to the newer route pattern /mcn/:slug
  // and these urls have been referenced extensively in marketing materials
  // making it hard to update the existing urls to the newer ones
  const ESTABLISHED_MCN_URLS = ['/vidiq', '/fullscreen', '/tubebuddy'];

  const queryParams = document.location.search.length ? document.location.search : null;

  if (ESTABLISHED_MCN_URLS.includes(document.location.pathname)) {
    window.location.href = `/mcn${document.location.pathname}${queryParams ? `${queryParams}` : ''}`;
  }

  if (!model.env.isServer) controller.analytics.init();

  setupGlobalCSS();

  render(
    <ClientView model={model} controller={controller} routerProps={routerProps} />,
    document.getElementById('root')
  );
  setStylesTarget(document.getElementById('styles-target'));
}

const promises = [];

if (!global.Intl) {
  promises.push(System.import('./lib/intl-shim.ts'));
}

const test = 'class Foo { constructor(a = "a") { const b = a; } }';

try {
  eval(test);
  es6 = true;
} catch (e) {
  throw new Error(e);
}

Promise.all(promises).then(() => run());
