import * as React from 'react';
import {Component, deps, inject, observer} from '../lib/component';
import {style} from 'typestyle';
import {colorContrast} from '../theme/color';
import {rem} from 'csx';

const PrismicDOM = require('prismic-dom');

@inject(deps) @observer
export default class GenericError extends Component<{}> {
  render() {
    const { genericOops } = this.props.model.content;

    if (!genericOops) return (
      <span className={GenericError.styles.container}>
        {this.props.model.content.genericOops || 'Something wicked this way came :('}
      </span>
    );

    const __html = PrismicDOM.RichText.asHtml(genericOops);

    return (
      <span className={GenericError.styles.container} dangerouslySetInnerHTML={{__html}} />
    );
  }

  static styles = {
    container: style({
      color: colorContrast.toString(),
      fontWeight: 500,
      fontSize: rem(0.875),
      $nest: {
        '& a': {
          textDecoration: 'underline'
        }
      }
    })
  }
}