import * as React from 'react';
import { AnchorHTMLAttributes } from 'react';
import PropTypes from 'prop-types';
import { Link } from './Link';
import { isEmpty } from '../utilities/objects';
import { Component, deps, inject, observer } from '../../../lib/component';

const utmParameters = ['utm_source', 'utm_medium', 'utm_campaign'];

export type UtmData = { utm_source: string; utm_medium: string; utm_campaign: string };
const hasUtmParameters = (obj: any): obj is UtmData => {
  if (isEmpty(obj)) return false;
  return utmParameters.every((parameter) => parameter in obj && typeof obj[parameter] === 'string');
};

/**
 * The UtmLink component will, most of the time, act as a transparent passthrough component to a native <a> anchor tag.
 *
 * Where it differs is if it's used within a React context which includes the UTM parameters defined in the structures
 * above. In this case, the Link component will extract the href it is passed and insert the UTM parameters into the URL.
 *
 * As with a native anchor tag, the href passed may be a relative or absolute URL.
 *
 * @param children
 * @param props
 * @param context
 * @constructor
 */
export type UtmLinkProps = AnchorHTMLAttributes<HTMLAnchorElement>;
@inject(deps)
@observer
export class UtmLink extends Component<UtmLinkProps> {
  static contextTypes = {
    utm_source: PropTypes.string,
    utm_medium: PropTypes.string,
    utm_campaign: PropTypes.string,
  };

  render() {
    const { children, model, controller, ...props } = this.props;
    const context: UtmData = this.context;
    // If the UTM values aren't in the context, pass everything through to the native anchor tag
    const passthroughComponent = <Link {...props}>{children}</Link>;
    if (!hasUtmParameters(context)) return passthroughComponent;
    const { href, ...rest } = props;
    try {
      const urlObject = new URL(href);
      if (!urlObject.hostname.endsWith(model.env.rootDomain)) return passthroughComponent;
    } catch {
      // The href is not an absolute URL so will always redirect within our site
    }
    let url = href,
      queryString = '',
      hash = '';
    // Extract the base URL (anything before the question mark) and the query string
    const queryMatch = href ? href.match(/^(.*?|)\?(.*?)(#.*?|)$/) : null;
    if (queryMatch) {
      const [_, baseUrl, originalQueryString] = queryMatch;
      url = baseUrl;
      queryString = originalQueryString;
    }
    // Extract the hash and remove it from the base URL
    const hashMatch = href ? href.match(/(#.*?)$/) : null;
    if (hashMatch) {
      hash = hashMatch[1];
      url = url.replace(hash, '');
    }
    // Insert the UTM values into the pre-existing URL
    const params = new URLSearchParams(queryString);
    utmParameters.forEach((parameter) => {
      if (parameter in context && typeof context[parameter] === 'string') {
        params.append(parameter, context[parameter]);
      }
    });
    // Reconstruct the URL
    url += '?' + params.toString() + hash;

    return (
      <Link href={url} {...rest}>
        {children}
      </Link>
    );
  }
}
