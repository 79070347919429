import { style } from 'typestyle';
import { CSSProperties, NestedCSSProperties } from 'typestyle/lib/types';
import { colorGunmetal } from './color';
import { zHeroCarouselActive, zHeroCarouselInactive } from './z';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';
import { important } from 'csx';

const quickTransitionSpeed = 125;
export const transitionQuickEase = `0.${quickTransitionSpeed}s ease-in-out`;

export const transitionModal: CSSTransitionProps = {
  timeout: { enter: 125, exit: 250 },
  mountOnEnter: true,
  unmountOnExit: true,
  classNames: {
    enter: style({
      $unique: true,
      opacity: 0,
      $nest: {
        '&> div': {
          transform: 'translate3d(0,-5px,0)',
        },
      },
    }),
    enterActive: style({
      opacity: 1,
      transition: `opacity ${transitionQuickEase}`,
      $nest: {
        '&> div': {
          transform: 'translate3d(0,0,0)',
          transition: `transform ${transitionQuickEase}`,
        },
      },
    }),
    exit: style({
      $unique: true,
      opacity: 1,
      $nest: {
        '&> div': {
          transform: 'translate3d(0,0,0)',
        },
      },
    }),
    exitActive: style({
      opacity: 0,
      transition: `opacity ${transitionQuickEase}`,
      $nest: {
        '&> div': {
          transform: 'translate3d(0,-5px,0)',
          transition: `transform ${transitionQuickEase}`,
        },
      },
    }),
    exitDone: style({
      opacity: 0,
    }),
  },
};

export const transitionExpandFade: CSSTransitionProps = {
  timeout: 600,
  appear: true,
  mountOnEnter: true,
  unmountOnExit: true,
  classNames: {
    appear: style({
      maxHeight: 0,
      opacity: 0,
    }),
    appearActive: style({
      opacity: 1,
      maxHeight: '500px',
      transition: '0.5s max-height ease-in-out, 0.5s opacity ease-in-out',
    }),
    enter: style({
      maxHeight: 0,
      opacity: 0,
    }),
    enterActive: style({
      opacity: 1,
      maxHeight: '500px',
      transition: '0.5s max-height ease-in-out, 0.5s opacity ease-in-out',
    }),
    exit: style({
      opacity: 1,
      maxHeight: '500px',
    }),
    exitActive: style({
      maxHeight: 0,
      opacity: 0,
      transition: '0.5s max-height ease-in-out, 0.5s opacity ease-in-out',
    }),
  },
};

export const transitionFade: CSSTransitionProps = {
  timeout: quickTransitionSpeed,
  mountOnEnter: true,
  unmountOnExit: true,
  classNames: {
    enter: style({
      opacity: 0,
    }),
    enterActive: style({
      opacity: important(1) as CSSProperties['opacity'], // opacity doesn't have typings for strings
      transition: important(`opacity ${transitionQuickEase}`), // Other transitions need to be overridden during entrance
    }),
    exit: style({
      opacity: 1,
    }),
    exitActive: style({
      opacity: important(0) as CSSProperties['opacity'], // opacity doesn't have typings for strings
      transition: important(`opacity ${transitionQuickEase}`), // Other transitions need to be overridden during exit
    }),
    exitDone: style({
      opacity: 0,
    }),
  },
};

export const transitionSlideFromBottom: CSSTransitionProps = {
  timeout: 500,
  mountOnEnter: true,
  unmountOnExit: true,
  classNames: {
    enter: style({
      backgroundColor: colorGunmetal.fade(0.01).toString(),
      $nest: {
        '&> section': {
          transform: 'translateY(100vw)',
        },
      },
    }),
    enterActive: style({
      backgroundColor: colorGunmetal.fade(0.7).toString(),
      transition: 'background-color 0.5s ease-in-out',
      $nest: {
        '&> section': {
          transition: 'transform 0.5s ease-in-out',
          transform: 'translateY(0)',
        },
      },
    }),
    exit: style({
      backgroundColor: colorGunmetal.fade(0.7).toString(),
      $nest: {
        '&> section': {
          transform: '0',
        },
      },
    }),
    exitActive: style({
      backgroundColor: colorGunmetal.fade(0).toString(),
      transition: 'background-color 0.5s ease-in-out',
      $nest: {
        '&> section': {
          transition: 'transform 0.5s ease-in-out',
          transform: 'translateY(100vw)',
        },
      },
    }),
  },
};

export const transitionSlideFromTop: CSSTransitionProps = {
  timeout: 500,
  mountOnEnter: true,
  unmountOnExit: true,
  classNames: {
    enter: style({
      transform: 'translateY(-100vh)',
    }),
    enterActive: style({
      transition: 'transform 0.5s ease-in-out',
      transform: 'translateY(0)',
    }),
    exit: style({
      transform: '0',
    }),
    exitActive: style({
      transition: 'transform 0.5s ease-in-out',
      transform: 'translateY(-100vh)',
    }),
  },
};

export const transitionSlideFromAbove: CSSTransitionProps = {
  timeout: 500,
  mountOnEnter: true,
  unmountOnExit: true,
  classNames: {
    enter: style({
      transform: 'translateY(-100%)',
      opacity: 0,
    }),
    enterActive: style({
      transition: important('transform 0.5s ease-in-out, opacity 0.5s ease-in-out'),
      transform: 'translateY(0)',
      opacity: 1,
      zIndex: important(0) as CSSProperties['zIndex'],
    }),
    exit: style({
      transform: '0',
    }),
    exitActive: style({
      transition: important('transform 0.5s ease-in-out, opacity 0.5s ease-in-out'),
      transform: 'translateY(-100%)',
      opacity: 0,
      zIndex: important(0) as CSSProperties['zIndex'],
    }),
  },
};
