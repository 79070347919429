import { TextPanel } from '../molecules/panels/TextPanel';
import { isEmpty } from '../utilities/objects';
import { renderPrismicRichTextArray } from '../utilities/props';
import * as React from 'react';
import { PrismicComplexBackgroundProps, PrismicRichText } from '../utilities/types';
import { createTextPanel } from '../utilities/prismic';

export type PrismicStatementPanelPayload = PrismicComplexBackgroundProps & {
  primary: {
    statement: PrismicRichText;
  };
};
export type PrismicStatementPanelProps = {
  payload: PrismicStatementPanelPayload;
};
export const PrismicStatementPanel: React.SFC<PrismicStatementPanelProps> = ({
  payload: {
    primary: {
      statement,
      background_image,
      background_colour,
      background_gradient_colour_end,
      background_gradient_colour_direction,
    },
  },
}) => (
  <TextPanel
    src={!isEmpty(background_image) && background_image.url}
    gradient={
      background_colour && {
        to: background_colour,
        from: background_gradient_colour_end,
        direction: background_gradient_colour_direction,
      }
    }
  >
    {renderPrismicRichTextArray(statement, 'span')}
  </TextPanel>
);
PrismicStatementPanel.displayName = 'PrismicStatementPanel';
export const PrismicStatementPanelWithText = createTextPanel(PrismicStatementPanel);
