import * as React from 'react';
import { Component, inject, deps, observer } from '../../lib/component';
import { centerCenter } from 'csstips';
import { classes, style } from 'typestyle';

export interface AddThisShareBoxProps {
  className?: string;
  hideDesktop?: boolean;
  hideMobile?: boolean;
  scriptId?: string;
  shareUrl?: string;
  shareTitle?: string;
  shareDescription?: string;
  follow?: boolean;
}

const constants = {
  scriptId: 'add-this-script'
};

@inject(deps) @observer
export class AddThisShareBox extends Component<AddThisShareBoxProps> {
  private _mounted: boolean = false;

  constructor(props: AddThisShareBoxProps) {
    super(props);
    this.state = { ready: false };
  }

  render() {
    if (!this.state.ready) return null;

    let props: any = {};

    if (!this.props.follow) {
      props['data-url'] = this.props.shareUrl || document.location.href;
      props['data-title'] = this.props.shareTitle || this.props.model.ui.title;

      if (this.props.shareDescription) {
        props['data-description'] = this.props.shareDescription;
      }
    }

    props['className'] = this.props.follow
      ? 'addthis_inline_follow_toolbox'
      : 'addthis_inline_share_toolbox';

    return (
      <div className={classes(AddThisShareBox.styles.container, this.props.className)}>
        <div {...props} />
      </div>
    )
  }

  componentDidMount() {
    this._mounted = true;
    const { scriptId = constants.scriptId } = this.props;
    const { addThisKey } = this.props.model.env;

    const existingScript = document.getElementById(scriptId);

    const onLoad = () => this._mounted && this.setState({ ready: true }, () => this.refresh());

    if (!existingScript) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `//s7.addthis.com/js/300/addthis_widget.js#pubid=${addThisKey}`;
      script.id = scriptId;
      script.onload = onLoad;
      document.body.appendChild(script);
    } else {
      onLoad();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  refresh = () => {
    const { addthis } = window as any;

    if (addthis && addthis.layers && addthis.layers.refresh) {
      addthis.layers.refresh();
    }
  };

  static i18n = {};

  static styles = {
    container: style({
      ...centerCenter,
      $nest: {
        '& p': {
          margin: '10px 0 0 0'
        }
      }
    })
  };
}