import * as React from 'react';
import { Component, deps, inject, observer } from '../lib/component';
import { Loading } from '../components/loading/loading';
import { centerCenter, flexRoot, vertical } from 'csstips';
import { px } from 'csx';
import { style } from 'typestyle';
import UserCoupon from '../components/user/coupon';
import { rebrand } from '../theme/color';
import { AccountLayout } from '../components/project-happy/templates/AccountLayout';
import { ChannelSwitcherAccountSidebar } from '../components/project-happy/organisms/ChannelSwitcherAccountSidebar';
import { isEmpty } from '../components/project-happy/utilities/objects';
import { ConnectChannelPrompt } from '../components/project-happy/molecules/ConnectYoutubeChannelPrompt';
import { spacing } from '../theme/spacing';
import { TrackCredit } from '../components/project-happy/molecules/TrackCredit';
import { mediaDesktop } from '../components/project-happy/utilities/mediaQueries';
import { borderRadius } from '../theme/border';
import { CloseCross } from '../components/project-happy/atoms/icons/CloseCross';
import { resetButton } from '../theme/reset';
import { TrackCreditInactive } from '../components/project-happy/molecules/TrackCreditInactive';
import { Button } from '../components/project-happy/atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../components/project-happy/atoms/button/buttonStyles';
import { ChannelBanner } from '../components/project-happy/molecules/ChannelBanner';

export interface AccountCouponsPageProps {
  className?: string;
}

const styles = {
  empty: style({
    margin: 0,
  }),
  credits: style(
    {
      marginBottom: spacing.XLARGE,
      $nest: {
        '& + *': {
          marginTop: spacing.XLARGE,
          ...mediaDesktop({
            marginTop: spacing.XXXLARGE,
          }),
        },
      },
    },
    mediaDesktop({
      marginBottom: spacing.XXXLARGE,
    })
  ),
  infoBox: style({
    position: 'relative',
    border: `1px solid ${rebrand.neutralOnDark[20].toString()}`,
    borderRadius: borderRadius.MEDIUM,
    padding: spacing.DEFAULT,
    marginBottom: spacing.DEFAULT,
    fontSize: px(12),
    $nest: {
      '& p': {
        margin: 0,
        textAlign: 'center',
      },
    },
  }),
  infoBoxClose: style(resetButton, flexRoot, centerCenter, {
    position: 'absolute',
    width: px(24),
    height: px(24),
    top: px(0),
    right: px(0),
  }),
  channelBanner: style({
    marginBottom: spacing.DEFAULT,
  }),
  heading: style({
    fontSize: px(48),
    margin: `0 0 ${spacing.DEFAULT}`,
    textAlign: 'center',
  }),
  couponList: style(flexRoot, vertical, {
    gap: spacing.XSMALL,
    $nest: {
      '& + *': {
        marginTop: spacing.DEFAULT,
      },
    },
  }),
  upgradeBtnWrapper: style({
    marginTop: spacing.DEFAULT,
  }),
  upgradeBtn: style(flexRoot, centerCenter, {
    margin: '0 auto',
    width: 'fit-content',
  }),
};

@inject(deps)
@observer
export class AccountCouponsPage extends Component<AccountCouponsPageProps> {
  render() {
    if (this.props.model.ui.isServer) return null;

    const {
      controller: {
        modal: { showChannelAddModal },
        analytics: { sendMixpanel },
      },
      model: {
        user: {
          user,
          channel,
          isSubscribed,
          isSubscribedToLegacyPlan,
          currentChannelCredits,
          coupons,
          loading,
          loadingAccounts,
          loadingChannels,
        },
        router: { location },
        storage,
        ui: { projectSubscriptionEnabled },
      },
    } = this.props;

    const { hideCreditInfoBox } = this.props.controller.page.accountCoupons;

    const isLoading = isEmpty(user) || loading || loadingAccounts || loadingChannels;
    const showInfoBox =
      isSubscribed && !isSubscribedToLegacyPlan && !(storage.getItem('hideAccountCreditInfoBox') === true);

    // Show coupons with latest expiry date first
    const sortedCoupons = coupons
      .slice()
      .sort((a, b) => new Date(b.expires.timestamp).getTime() - new Date(a.expires.timestamp).getTime());

    return (
      <AccountLayout sidebar={<ChannelSwitcherAccountSidebar />}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {projectSubscriptionEnabled ? (
              <div className={styles.credits}>
                <h2 className={styles.heading}>Credits</h2>

                {showInfoBox && (
                  <div className={styles.infoBox}>
                    <p>
                      Premium track credits are valid for 90 days from the date they become active with a Lickd
                      subscription. New Premium track credits are activated immediately when your subscription rolls
                      over each month.
                    </p>
                    <button className={styles.infoBoxClose} aria-label="Hide this message" onClick={hideCreditInfoBox}>
                      <CloseCross size={16} color={rebrand.neutralOnLight[30].toString()} />
                    </button>
                  </div>
                )}

                {isEmpty(channel) && isEmpty(user.default_channel) && (
                  <ConnectChannelPrompt
                    showChannelAddModal={showChannelAddModal}
                    sendMixpanel={sendMixpanel}
                    location={location.pathname}
                  />
                )}

                {!isEmpty(channel) && (
                  <>
                    {(!isSubscribed || isSubscribedToLegacyPlan) && (
                      <div className={styles.infoBox}>
                        <p>
                          Premium track credits are only available with an active plan. Subscribe to one of our plans to
                          get access to monthly Premium track credits.
                        </p>
                      </div>
                    )}

                    <ChannelBanner className={styles.channelBanner} />

                    {!!currentChannelCredits.length && (
                      <div className={styles.couponList}>
                        {currentChannelCredits.map((credit, index) => (
                          <TrackCredit key={index} credit={credit} />
                        ))}
                      </div>
                    )}

                    {(!isSubscribed || isSubscribedToLegacyPlan) && (
                      <TrackCreditInactive>
                        {isSubscribedToLegacyPlan ? 'See new plans' : 'Subscribe to get credits'}
                      </TrackCreditInactive>
                    )}

                    {isSubscribed && !isSubscribedToLegacyPlan && !currentChannelCredits.length && (
                      <div className={styles.upgradeBtnWrapper}>
                        <Button
                          className={styles.upgradeBtn}
                          prominence={ButtonProminence.PRIMARY}
                          size={ButtonSize.SMALL}
                          href="/account/plans"
                        >
                          Upgrade for more credits
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </div>
            ) : null}

            <div>
              <h2 className={styles.heading}>Coupons</h2>
              {!coupons.length && <p className={styles.empty}>You don&apos;t have any coupons at this time.</p>}

              {!!coupons.length && (
                <div className={styles.couponList}>
                  {sortedCoupons.map((coupon) => (
                    <UserCoupon key={coupon.coupon} coupon={coupon} />
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </AccountLayout>
    );
  }
}
