import * as React from 'react';
import { classes, style } from 'typestyle';
import {
  betweenJustified, centerCenter, content, end, endJustified, horizontal, horizontallySpaced, start, vertical,
  verticallySpaced
} from 'csstips';
import {em, important, percent, px, quote, url} from 'csx';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { colorContrast, colorGunmetal, colorWhite } from '../theme/color';
import { ArtistLinkList } from './artist-link-list';
import { Pill } from './pill';
import { DataIcon } from './data-icon';
import { ResponsiveImageTile } from './responsive-image-tile';
import { resetAnchorStyles } from '../theme/reset';
import {ArtistSchema, TrackSchema} from '../types/schema';
import {ImagePreloader} from '../types/fn';
import {AnalyticsController} from "../modules/analytics/controller";

type RequiredFields = 'images' | 'created_at' | 'is_charting' | 'is_new_release' | 'title' | 'slug';
type PartialFields = {
  artists: Array<Pick<ArtistSchema, 'name' | 'slug' | 'highlight'>>
}

export interface TrackTileProps {
  className?: string;
  style?: any;
  styles?: Array<NestedCSSProperties>;
  track: PartialFields & Pick<TrackSchema, RequiredFields>;
  size?: number;
  scale?: number;
  onClickLink(event: React.MouseEvent<any>): void;
  preload: ImagePreloader;
  PlayButton: any;
  AddRemove?: any;
  Playlist?: any;
  Favourite?: any;
  noMeta?: boolean;
  noLabels?: boolean;
  ratio?: number;
  i18n?: {
    moreLikeThis: string;
  };
  analytics: AnalyticsController;
}

const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;

const DEFAULT_I18N = {
  moreLikeThis: 'More like this'
};

export class TrackTile extends React.Component<TrackTileProps, any> {
  render() {
    const { props } = this;
    let {
      className,
      track,
      styles = [],
      size = 300,
      ratio = 1,
      scale = 1,
      onClickLink,
      PlayButton,
      AddRemove,
      Favourite,
      Playlist,
      i18n = DEFAULT_I18N,
      preload,
      noMeta,
      noLabels,
      analytics,
    } = props;

    const cz = classes(
      TrackTile.styles.container,
      TrackTile.styles.ratio(ratio),
      styles.length > 0 && style(...styles),
      className
    );

    const badgeStyle = {
      width: px(64 * scale),
      height: px(64 * scale),
    };

    const containerProps = {
      className: cz,
      style: props.style,
      identity: (track.images && track.images.identity) || null,
      preload: preload,
    };

    const isNew = track.created_at && Date.now() - new Date(track.created_at).getTime() < ONE_WEEK;

    return (
      <ResponsiveImageTile {...containerProps}>
        <div className={TrackTile.styles.inner}>
          <div className={TrackTile.styles.top}>
            <div className={TrackTile.styles.badges}>
              {!noMeta && track.is_charting && <DataIcon uri={DataIcon.data.Charting} style={badgeStyle} />}
              {!noMeta && track.is_new_release && <DataIcon uri={DataIcon.data.NewRelease} style={badgeStyle} />}
            </div>
            <div>
              {AddRemove && <AddRemove size={34 * scale} track={track} withLicense={!noLabels} reverse={true} />}
              {Favourite && <Favourite size={34 * scale} track={track} />}
              {Playlist && <Playlist size={34 * scale} track={track} />}
            </div>
          </div>
          <div className={classes(TrackTile.styles.bottom, !noMeta && TrackTile.styles.bottomWithMeta)}>
            <div className={TrackTile.styles.meta}>
              {!noMeta && (
                <div>
                  {isNew && (
                    <span style={{ fontSize: px(9 * scale) }} className={TrackTile.styles.newTag}>
                      NEW
                    </span>
                  )}
                  <a
                    href={`/music/artists/${track.artists[0].slug}/track/${track.slug}`}
                    className={TrackTile.styles.link}
                    title={track.title}
                    style={{ fontSize: px(16 * scale) }}
                    onClick={this.handleTrackClick.bind(this)}
                  >
                    {track.title}
                  </a>
                </div>
              )}
              {!noMeta && (
                <ArtistLinkList
                  containerStyle={{ fontSize: px(14 * scale) }}
                  model={track}
                  linkClassName={TrackTile.styles.link}
                  onClickLink={onClickLink}
                />
              )}
            </div>
            <div className={TrackTile.styles.play}>
              <PlayButton size={34 * scale} track={track} />
            </div>
          </div>
        </div>
      </ResponsiveImageTile>
    );
  }

  handleTrackClick = (event: React.MouseEvent<any>) => {
    this.props.analytics.sendMixpanel('User clicks track link', {
      title: this.props.track.title,
      slug: this.props.track.slug,
    });

    this.props.onClickLink(event);
  };

  static styles = {
    container: style({
      ...content,
      display: 'inline-block',
      position: 'relative',
      $nest: {
        '&:before': {
          content: quote(''),
          display: 'block',
        },
      },
    }),
    background: style({
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
    }),
    backgroundBlur: style({
      filter: 'blur(5px)',
    }),
    inner: style({
      ...vertical,
      ...start,
      ...betweenJustified,
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 2,
    }),
    top: style({
      ...horizontal,
      ...betweenJustified,
      width: percent(100),
      padding: px(10),
      $nest: {
        '&> div:last-child': {
          ...vertical,
          ...verticallySpaced(10),
          ...end,
        },
      },
    }),
    badges: style({
      ...horizontal,
      ...horizontallySpaced(10),
    }),
    bottom: style({
      ...horizontal,
      minHeight: px(54),
      width: percent(100),
    }),
    bottomWithMeta: style({
      background: colorGunmetal.fade(0.5).toString(),
    }),
    meta: style({
      ...vertical,
      ...endJustified,
      width: percent(100),
      padding: px(10),
      textOverflow: 'hidden',
      overflow: 'hidden',
      lineHeight: 1.1,
      flex: 1,
      $nest: {
        '&> div': {
          color: colorWhite.toString(),
          fontWeight: 'normal',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'pre',
        },
        '&> div:first-child': {
          fontWeight: 'bold',
        },
      },
    }),
    link: style({
      cursor: 'pointer',
      color: important(colorWhite.toString()),
      $nest: {
        ...resetAnchorStyles(colorWhite.toString()),
      },
    }),
    play: style({
      ...centerCenter,
      padding: px(10),
    }),
    moreLikeThis: style({
      ...centerCenter,
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: px(-50),
      fontWeight: 'bold',
    }),
    ratio: (ratio: number) => {
      return (
        CACHED_RATIOS[ratio] ||
        (CACHED_RATIOS[ratio] = style({
          $nest: {
            '&:before': {
              paddingTop: percent(100 * (1 / ratio)),
            },
          },
        }))
      );
    },
    newTag: style({
      background: colorContrast.toString(),
      borderRadius: px(4),
      fontSize: px(9),
      lineHeight: em(16 / 9),
      padding: '0 4px',
      margin: `0 ${em(3 / 9)} 0 0`,
      display: 'inline-block',
      transform: `translateY(${em(-2 / 9)})`,
      color: colorWhite.toString(),
    }),
  };
}

let CACHED_RATIOS: {[ratio: number]: string} = {};
