import { action } from 'mobx';
import { API, APIError, APIPaginatedResponse, APIResponse, getEmptyPaginatedResponse } from '../types';
import { ArtistSchema } from '../../../types/schema';

export class ArtistlistAPIController extends API {
  @action
  addArtistToArtistlist = async (user: string, artistlist: string, artist: string) => {
    const url = `/user/${user}/artist/list/${artistlist}`;
    const body = JSON.stringify({ artist_id: artist });
    const resp = await this.request.post({ url, body });

    return await resp.json();
  };

  @action
  removeArtistFromArtistlist = async (user: string, artistlist: string, artist: string) => {
    const url = `/user/${user}/artist/list/${artistlist}`;
    const body = JSON.stringify({ artist_id: artist });
    const resp = await this.request.del({ url, body });

    return await resp.json();
  };

  @action
  getArtists = async (
    user: string,
    artistlist: string,
    page = 1,
    perPage = 25,
  ): Promise<APIPaginatedResponse<Array<ArtistSchema>>> => {
    const url = `/user/${user}/artist/list/${artistlist}?page=${page}&perPage=${perPage}`;
    try {
      const response = await this.request.get({ url });
      await APIError.checkResponse(response);
      const json = await response.json();

      this.cache.addArtists(json.data);

      return json;
    } catch {
      return getEmptyPaginatedResponse();
    }
  };
}
