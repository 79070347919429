import {EnvModel} from '../env/model';
import {GraphQLModel} from './model';
import {action} from 'mobx';
import {DocumentNode} from 'graphql';

export class GraphQLController {
  constructor(
    private model: GraphQLModel,
    private env: EnvModel,
  ) {}

  @action
  query = async <T>(query: DocumentNode, variables?: any) => {
    return this.model.client.query<T>({
      query,
      variables
    })
  }
}