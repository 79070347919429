import { ArtistPageController } from './artist/controller';
import { APIController } from '../api/controller';
import { CheckoutPageController } from './checkout/controller';
import { HomePageController } from './home/controller';
import { AuthController } from '../auth/controller';
import { PricingPageController } from './pricing/controller';
import { ClientModel } from '../client/model';
import { BasketController } from '../basket/controller';
import { AccountLicencesPageController } from './account-licences/controller';
import { AccountOrdersPageController } from './account-orders/controller';
import { AccountOrderPageController } from './account-order/controller';
import { SearchPageController } from './search/controller';
import { UIController } from '../ui/controller';
import { MasterPageController } from './master/controller';
import { AccountChannelsPageController } from './account-channels/controller';
import { AccountChannelPageController } from './account-channel/controller';
import { AccountBillingPageController } from './account-billing/controller';
import { CheckoutController } from '../checkout/controller';
import { TermsAndConditionsPageController } from './terms-and-conditions/controller';
import { EssentialsTermsAndConditionsPageController } from './essentials-terms-and-conditions/controller';
import { PrismicController } from '../prismic/controller';
import { ContactPageController } from './contact/controller';
import { PrivacyPolicyPageController } from './privacy-policy/controller';
import { FAQPageController } from './faq/controller';
import { AboutPageController } from './about/controller';
import { BugsnagController } from '../bugsnag/controller';
import { AccountCommunicationPageController } from './account-communication/controller';
import { ImageController } from '../image/controller';
import { SwapoutsPageController } from './swapouts/controller';
import { LicensingPageController } from './licensing/controller';
import { AccountInvitesPageController } from './account-invites/controller';
import { CookiePolicyPageController } from './cookie-policy/controller';
import { AnalyticsController } from '../analytics/controller';
import { ArtistsPageController } from './artists/controller';
import { AccountAttributionPageController } from './account-attribution/controller';
import { FavouritesPageController } from './favourites/controller';
import { FeaturesPageController } from './features/controller';
import { TrackPageController } from './track/controller';
import { AccountProfilePageController } from './account-profile/controller';
import { DiscoverPageController } from './discover/controller';
import { YouTubePageController } from './youtube/controller';
import { MusicSubmissionsPageController } from './music-submissions/controller';
import { LearnPageController } from './learn/controller';
import { BrowsePageController } from './browse/controller';
import { EditorPageController } from './editor/controller';
import { CareersPageController } from './careers/controller';
import { SigninPageController } from './signin/controller';
import { ArticlesPageController } from './articles/controller';
import { GoldenTicketPageController } from './golden-ticket/controller';
import { ChannelReferralPageController } from './channel-referral/controller';
import { AccountCouponsPageController } from './account-coupons/controller';
import { LandingPageController } from './landing/controller';
import { PlaylistController } from '../playlist/controller';
import { MCNPageController } from './mcn/controller';
import { PartnerPageController } from './partner/controller';
import { AffiliatePageController } from './affiliate/controller';
import { FakeDoorPageController } from './fake-door/controller';
import { LightweightLandingController } from './lightweight-landing/controller';
import { ContentController } from '../content/controller';
import { LanderController } from '../../components/landers/controller';
import { CreatorDashboardPageController } from './creator-dashboard/controller';
import { RegistrationPageController } from './registration/controller';
import { UserController } from '../user/controller';
import { AccountVideosPageController } from './account-videos/controller';
import { AccountHubPageController } from './account-hub/controller';
import { AccountPlaylistsPageController } from './account-playlists/controller';
import { SearchMvpPageController } from './search-mvp/controller';
import { UserPlaylistsPageController } from './user-playlists/controller';

export class PageController {
  master: MasterPageController;
  signin: SigninPageController;
  registration: RegistrationPageController;
  home: HomePageController;
  creatorDashboard: CreatorDashboardPageController;
  articles: ArticlesPageController;
  artist: ArtistPageController;
  checkout: CheckoutPageController;
  browse: BrowsePageController;
  pricing: PricingPageController;
  accountHub: AccountHubPageController;
  accountCoupons: AccountCouponsPageController;
  accountInvites: AccountInvitesPageController;
  accountLicences: AccountLicencesPageController;
  accountAttribution: AccountAttributionPageController;
  accountOrders: AccountOrdersPageController;
  accountOrder: AccountOrderPageController;
  accountChannels: AccountChannelsPageController;
  accountChannel: AccountChannelPageController;
  accountBilling: AccountBillingPageController;
  accountCommunication: AccountCommunicationPageController;
  accountProfile: AccountProfilePageController;
  accountVideos: AccountVideosPageController;
  accountPlaylists: AccountPlaylistsPageController;
  contact: ContactPageController;
  search: SearchPageController;
  searchMvp: SearchMvpPageController;
  faq: FAQPageController;
  about: AboutPageController;
  privacyPolicy: PrivacyPolicyPageController;
  termsAndConditions: TermsAndConditionsPageController;
  essentialsTermsAndConditions: EssentialsTermsAndConditionsPageController;
  cookiePolicy: CookiePolicyPageController;
  licensing: LicensingPageController;
  lightweightLanding: LightweightLandingController;
  lander: LanderController;
  swapouts: SwapoutsPageController;
  artists: ArtistsPageController;
  favourites: FavouritesPageController;
  features: FeaturesPageController;
  track: TrackPageController;
  discover: DiscoverPageController;
  youtube: YouTubePageController;
  musicSubmissions: MusicSubmissionsPageController;
  learn: LearnPageController;
  careers: CareersPageController;
  editor: EditorPageController;
  goldenTicket: GoldenTicketPageController;
  channelReferral: ChannelReferralPageController;
  landing: LandingPageController;
  mcn: MCNPageController;
  partner: PartnerPageController;
  affiliate: AffiliatePageController;
  fakedoor: FakeDoorPageController;
  userPlaylists: UserPlaylistsPageController;

  constructor(
    model: ClientModel,
    api: APIController,
    auth: AuthController,
    user: UserController,
    basket: BasketController,
    ui: UIController,
    content: ContentController,
    checkout: CheckoutController,
    prismic: PrismicController,
    bugsnag: BugsnagController,
    image: ImageController,
    analytics: AnalyticsController,
    playlist: PlaylistController
  ) {
    this.master = new MasterPageController(ui, model.env, model.router);
    this.signin = new SigninPageController(model.router, model.auth, model.page.registration);
    this.registration = new RegistrationPageController(
      model.page.registration,
      model.onboarding,
      api,
      analytics,
      model.user,
      user,
      model.router
    );
    this.home = new HomePageController(
      model.page,
      model.page.home,
      model.env,
      prismic,
      api,
      analytics,
      ui,
      model.user,
      model.auth
    );
    this.creatorDashboard = new CreatorDashboardPageController(
      model.page,
      model.page.creatorDashboard,
      model.env,
      prismic,
      api,
      analytics,
      ui,
      model.user,
      model.auth
    );
    this.articles = new ArticlesPageController(model.page.articles);
    this.artist = new ArtistPageController(
      model.page.artist,
      model.env,
      ui,
      api.artist,
      api.release,
      image,
      model.router,
      bugsnag,
      model.content,
      model.auth
    );
    this.checkout = new CheckoutPageController(
      model.page.checkout,
      checkout,
      model.checkout,
      ui,
      model.env,
      analytics,
      content,
      api
    );
    this.browse = new BrowsePageController(
      model.page.browse,
      api,
      ui,
      bugsnag,
      model.content,
      model.env,
      prismic,
      image,
      model.router,
      model.auth
    );
    this.pricing = new PricingPageController(model.page.pricing, prismic, bugsnag, model.env, api, ui, model.content);
    this.accountHub = new AccountHubPageController(
      model.page,
      model.page.accountHub,
      model.env,
      prismic,
      api,
      analytics,
      ui,
      model.auth
    );
    this.accountCoupons = new AccountCouponsPageController(
      model.page.accountCoupons,
      model.user,
      api,
      bugsnag,
      ui,
      model.env,
      model.storage
    );
    this.accountInvites = new AccountInvitesPageController(
      model.page.accountInvites,
      model.user,
      api,
      prismic,
      bugsnag,
      ui,
      model.env,
      model.content
    );
    this.accountLicences = new AccountLicencesPageController(
      model.user,
      model.page.accountLicences,
      api,
      model.env,
      ui
    );
    this.accountVideos = new AccountVideosPageController(
      model.user,
      model.page.accountVideos,
      api,
      model.env,
      ui,
      bugsnag
    );
    this.accountPlaylists = new AccountPlaylistsPageController(user, ui, bugsnag);
    this.userPlaylists = new UserPlaylistsPageController(
      model.page.userPlaylists,
      api,
      model.env,
      ui,
      bugsnag,
      model.auth
    );
    this.accountAttribution = new AccountAttributionPageController(
      model.page.accountAttribution,
      model.user,
      api,
      bugsnag,
      ui,
      model.env
    );
    this.accountOrders = new AccountOrdersPageController(
      model.user,
      model.page.accountOrders,
      api,
      model.env,
      ui,
      bugsnag
    );
    this.accountOrder = new AccountOrderPageController(model.user, model.page.accountOrder, api, model.env, ui);
    this.accountChannels = new AccountChannelsPageController(
      model.user,
      model.page.accountChannels,
      api,
      model.env,
      ui
    );
    this.accountChannel = new AccountChannelPageController(model.user, model.page.accountChannel, api, model.env, ui);
    this.accountBilling = new AccountBillingPageController(model.user, model.page.accountBilling, api, model.env, ui);
    this.accountCommunication = new AccountCommunicationPageController(
      model.page.accountCommunication,
      model.auth,
      api,
      model.env,
      prismic,
      ui,
      auth
    );
    this.accountProfile = new AccountProfilePageController(
      model.user,
      model.page.accountProfile,
      api,
      ui,
      model.content,
      model.env,
      auth
    );
    this.contact = new ContactPageController(model.page.contact, prismic, bugsnag, model.env, ui);
    this.search = new SearchPageController(
      model.page.search,
      ui,
      model.env,
      model.router,
      api,
      analytics,
      model.content
    );
    this.searchMvp = new SearchMvpPageController(
      model.page.searchMvp,
      ui,
      model.env,
      model.router,
      analytics,
      api,
      model.user,
      model.content
    );
    this.faq = new FAQPageController(model.page.faq, prismic, bugsnag, model.env, ui);
    this.about = new AboutPageController(model.page.about, prismic, bugsnag, model.env, ui);
    this.privacyPolicy = new PrivacyPolicyPageController(model.page.privacyPolicy, prismic, bugsnag, model.env, ui);
    this.termsAndConditions = new TermsAndConditionsPageController(
      model.page.termsAndConditions,
      prismic,
      bugsnag,
      model.env,
      ui
    );
    this.essentialsTermsAndConditions = new EssentialsTermsAndConditionsPageController(
      model.page.essentialsTermsAndConditions,
      prismic,
      bugsnag,
      model.env,
      ui
    );
    this.cookiePolicy = new CookiePolicyPageController(model.page.cookiePolicy, prismic, bugsnag, model.env, ui);
    this.licensing = new LicensingPageController(model.page.licensing, prismic, bugsnag, model.env, ui);
    this.lightweightLanding = new LightweightLandingController(
      model.page.lightweightLanding,
      prismic,
      model.env,
      ui,
      model.router,
      model.content,
      image
    );
    this.lander = new LanderController(model.page.lander, prismic, model.env, ui, api, bugsnag);
    this.swapouts = new SwapoutsPageController(model.page.swapouts, prismic, bugsnag, model.env, ui);
    this.artists = new ArtistsPageController(
      model.page.artists,
      model.env,
      api,
      ui,
      image,
      bugsnag,
      prismic,
      model.router
    );
    this.favourites = new FavouritesPageController(
      model.page.favourites,
      prismic,
      bugsnag,
      model.env,
      api,
      model.user,
      model.playlist,
      model.artistlist,
      ui,
      model.cache
    );
    this.features = new FeaturesPageController(model.page.features, prismic, bugsnag, model.env, ui);
    this.track = new TrackPageController(
      model.page.track,
      bugsnag,
      model.env,
      api,
      ui,
      model.content,
      image,
      model.router,
      analytics,
      model.auth
    );
    this.discover = new DiscoverPageController(model.page.discover, prismic, bugsnag, model.env, ui, model.router);
    this.youtube = new YouTubePageController(model.page.youtube, prismic, bugsnag, model.env, ui);
    this.musicSubmissions = new MusicSubmissionsPageController(
      model.page.musicSubmissions,
      prismic,
      bugsnag,
      model.env,
      ui
    );
    this.learn = new LearnPageController(
      model.page.learn,
      prismic,
      bugsnag,
      model.env,
      ui,
      model.router,
      model.content
    );
    this.careers = new CareersPageController(model.page.careers, prismic, bugsnag, model.env, ui, model.router);
    this.editor = new EditorPageController(model.page.editor);
    this.goldenTicket = new GoldenTicketPageController(model.page.goldenTicket, prismic, bugsnag, model.env, ui, image);
    this.channelReferral = new ChannelReferralPageController(
      model.page.channelReferral,
      model.env,
      ui,
      model.auth,
      api,
      prismic
    );
    this.landing = new LandingPageController(
      model.page.landing,
      prismic,
      bugsnag,
      model.env,
      ui,
      model.router,
      model.content
    );
    this.mcn = new MCNPageController(model.page.mcn, prismic, model.env, ui, model.router, model.content, image);
    this.partner = new PartnerPageController(
      model.page.partner,
      prismic,
      model.env,
      ui,
      model.router,
      model.content,
      image
    );
    this.affiliate = new AffiliatePageController(
      model.page.affiliate,
      prismic,
      model.env,
      ui,
      model.router,
      model.content,
      image
    );
    this.fakedoor = new FakeDoorPageController(
      model.page.fakedoor,
      prismic,
      model.env,
      ui,
      model.router,
      model.content,
      image
    );
  }
}
