import { TrackListSlice } from '../organisms/slices/TrackListSlice';
import {
  PrismicSlice,
  PrismicYouTubeChannelFields,
  PrismicYouTubeChannelProps,
  SlicePageContext,
} from '../utilities/types';
import * as React from 'react';
import {
  YouTubeVideoTrackCarouselSlice,
  YouTubeVideoTrackCarouselSliceProps,
} from '../organisms/slices/YouTubeVideoTrackCarouselSlice';
import { isEmpty } from '../utilities/objects';
import { ClassicComponentClass } from 'react';
import { observer } from '../../../lib/component';
import { StorageModel } from '../../../modules/storage/model';
import { REPLACE_CAROUSELS } from '../../devtools/feature-flags';

export type PrismicYouTubeVideoTrackCarouselPayload = PrismicSlice<
  Record<string, never>,
  PrismicYouTubeChannelFields & {
    video_id: string;
    video_timestamp?: number;
    video_title: string;
    video_views?: number;
    video_published_at: string;
    track_id: string;
  }
>;
export type PrismicYouTubeVideoTrackCarouselProps = {
  payload: PrismicYouTubeVideoTrackCarouselPayload;
  context: SlicePageContext;
};

export const PrismicYouTubeVideoTrackCarousel = observer(
  ({ payload: { items }, context: { tracks } }: PrismicYouTubeVideoTrackCarouselProps) => {
    const videos: YouTubeVideoTrackCarouselSliceProps['videos'] = items.map(
      ({
        channel_name,
        channel_subscribers,
        channel_profile_image,
        video_title,
        video_published_at,
        video_views,
        video_id,
        video_timestamp,
        track_id,
      }) => {
        let trackId = track_id;
        if (!tracks.has(trackId) && StorageModel.getItem(REPLACE_CAROUSELS) === true) {
          trackId = REPLACE_CAROUSELS;
        }
        return {
          channel: {
            src: !isEmpty(channel_profile_image) ? channel_profile_image.url : '',
            channelName: channel_name,
            subscriberCount: channel_subscribers,
          },
          video: {
            id: video_id,
            timestamp: video_timestamp,
            title: video_title,
            views: video_views,
            publishDate: new Date(video_published_at),
          },
          track: tracks.has(trackId) ? tracks.get(trackId) : null,
        };
      }
    );
    return <YouTubeVideoTrackCarouselSlice videos={videos} />;
  }
);
