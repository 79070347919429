import * as React from 'react';
import { ComponentProps, deps, inject, observer } from '../../../../lib/component';
import { BlockBanner } from '../../atoms/BlockBanner';
import { classes, extend, style } from 'typestyle';
import { Collapsible } from '../../../collapsible/collapsible';
import collapsibleStyles from '../../../collapsible/styles';
import { CollapsibleIndicator } from '../../atoms/CollapsibleIndicator';
import { CheckmarkList } from '../../atoms/CheckmarkList';
import { Button } from '../../atoms/button/Button';
import { BROWSE_CHART_PLAYLISTS_ROUTE, SIGN_UP_WITH_PAYG } from '../../../../constants';
import { PaymentPlanBlock } from '../../molecules/registration/PaymentPlanBlock';
import { ButtonProminence, ButtonSize } from '../../atoms/button/buttonStyles';
import planStyles from './styles';
import { px, url } from 'csx';
import ChartBanner from '../../../../static/plans/chart-only-desktop.png';
import { isEmpty } from '../../utilities/objects';
import { HighlightText } from '../../atoms/HighlightText';
import { formatNonDecimalCurrency } from '../../../../lib/currency';
import { EnvModel } from '../../../../modules/env/model';
import { QuestionCircle } from '../../atoms/icons/QuestionCircle';
import ToolTipModal, { ToolTipModalProps } from '../../molecules/ToolTipModal';
import { defaultHeadingStyles, headingTitle } from '../../utilities/font';
import { fontstackDefault } from '../../config';
import { SemanticInformationType } from '../../utilities/types';

const { PRIMARY, SECONDARY } = ButtonProminence;
const { LARGE } = ButtonSize;
const { SUCCESS } = SemanticInformationType;

const styles = {
  banner: style({
    backgroundColor: 'black',
    //TBC
    // backgroundImage: [url(ChartBanner), `${url(ChartBanner)}`],
    $nest: {
      '& h2': {
        fontSize: '32px',
        textAlign: 'left',
      },
    },
  }),
  pricingContentsSubscription: style({
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    fontSize: '12px',
    whiteSpace: 'pre-wrap',
    $nest: {
      '& span': extend(defaultHeadingStyles, headingTitle),
    },
  }),
  info: style({
    textAlign: 'center',
    fontSize: px(8),
    padding: '0px 16px',
    $nest: {
      '& h4': {
        fontFamily: fontstackDefault,
        fontSize: px(12),
        fontWeight: 'normal',
        marginTop: px(4),
        marginBottom: px(8),
        textDecoration: 'underline',
      },
      '& p': {
        fontSize: px(10),
        marginTop: 0,
        marginBottom: px(8),
        $nest: {
          '&:last-child': {
            marginBottom: '24px',
          },
        },
      },
    },
  }),
};

const tooltipStyles = style({
  maxWidth: px(320),
});

const PriceBandsTooltip: React.SFC<ToolTipModalProps> = ({ className, ...props }) => (
  <ToolTipModal className={classes(tooltipStyles, className)} {...props}>
    <h3>How are price bands calculated?</h3>
    <p>
      Our pricing structure is based on the average views your recent video uploads have received. When you sign-in to
      Lickd with your YouTube account we fetch your latest 50 videos and take an average of the video views. This allows
      us to estimate your audience size and put you into a price band.
    </p>
  </ToolTipModal>
);

type PaygProps = ComponentProps & { handleAction?: () => void; showCaveat?: boolean };
@inject(deps)
@observer
export class Payg extends React.Component<PaygProps> {
  state = {
    tooltipShown: false,
  };

  render() {
    const {
      model: {
        user: { user, unsubscribedRatecard },
        basket: { ratecard },
      },
      handleAction,
      showCaveat = false,
    } = this.props;

    const isLoggedIn = !isEmpty(user);
    return (
      <div data-test-plan-card="payg">
        <PriceBandsTooltip
          show={this.state.tooltipShown}
          onRequestClose={() => this.setState({ tooltipShown: false })}
        />
        <PaymentPlanBlock>
          {isLoggedIn && <BlockBanner status={SUCCESS}>Plan active</BlockBanner>}
          <div className={classes(planStyles.banner, styles.banner)}>
            <h2>
              CHART <span style={{ fontSize: '16px' }}>ONLY</span>
            </h2>
          </div>
          <Collapsible initial={EnvModel.isServer || !isLoggedIn}>
            <Collapsible.Summary className={collapsibleStyles.summaryLabel}>
              <h3>What you get</h3>
              <CollapsibleIndicator colour="white" />
            </Collapsible.Summary>
            <CheckmarkList>
              <li>
                <p>Access 1 Million+ chart tracks</p>
              </li>
              <li>
                <p>License music claims-free across your socials</p>
              </li>
              <li>
                <p>No YouTube demonetization</p>
              </li>
              <li>
                <p>Downloadable MP3</p>
              </li>
              <li>
                <p>25% off first Chart track</p>
              </li>
            </CheckmarkList>
          </Collapsible>
          <div className={styles.pricingContentsSubscription}>
            <p>
              From{'\n'}
              {!isEmpty(unsubscribedRatecard) && (
                <span>
                  {formatNonDecimalCurrency(
                    unsubscribedRatecard.value || unsubscribedRatecard.rate,
                    unsubscribedRatecard.currency
                  )}
                </span>
              )}
              {'\n'}per Chart track
            </p>
          </div>
          {handleAction && (
            <Button className={planStyles.planButton} prominence={SECONDARY} size={LARGE} onClick={handleAction}>
              Show Price Bands
            </Button>
          )}
          {isLoggedIn && (
            <Button
              href={BROWSE_CHART_PLAYLISTS_ROUTE}
              className={planStyles.planButton}
              prominence={SECONDARY}
              size={LARGE}
              data-test-plan-card-button="payg"
            >
              Discover Chart
            </Button>
          )}
          {!isLoggedIn && ratecard && (
            <Button className={planStyles.planButton} prominence={PRIMARY} size={LARGE} href={SIGN_UP_WITH_PAYG}>
              Sign up free
            </Button>
          )}
        </PaymentPlanBlock>
        {showCaveat && (
          <div className={styles.info}>
            <QuestionCircle size={24} />
            <h4 onClick={() => this.setState({ tooltipShown: true })}>How are price bands calculated?</h4>
            <p>
              Chart is free to access and will be added to your account automatically. Don&apos;t worry, you won&apos;t
              be charged until you buy chart songs.
            </p>
          </div>
        )}
      </div>
    );
  }
}
