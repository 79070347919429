import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { important, percent, px } from 'csx';
import { TrendingUp } from '../../../atoms/icons/TrendingUp';
import { fontHeading } from '../../../../../theme/font';
import {
  mediaUpToDesktop,
  mediaSmallTabletOnly,
  mediaDesktop,
  mediaMobileOnly,
  mediaUpToSmallTablet,
  mediaSmallTablet,
  mediaLargeTablet,
  mediaUpToLargeTablet,
} from '../../../utilities/mediaQueries';
import { NestedCSSProperties } from 'typestyle/lib/types';

const mobilePositioning: NestedCSSProperties = {
  $nest: {
    '& > img': {
      $nest: {
        '&:first-child': {
          width: percent(18.06),
          top: percent(37.04),
          left: percent(-7.64),
        },
        '&:nth-child(2)': {
          width: percent(26.39),
          top: percent(30.15),
          left: percent(7.64),
          zIndex: 1,
        },
        '&:nth-child(3)': {
          width: percent(37.5),
          left: percent(31.25),
          top: percent(19.15),
          zIndex: 2,
        },
        '&:nth-child(4)': {
          width: percent(26.39),
          top: percent(30.15),
          right: percent(7.64),
          zIndex: 1,
        },
        '&:nth-child(5)': {
          width: percent(18.06),
          top: percent(37.04),
          right: percent(-7.64),
        },
      },
    },
  },
};
const styles = style(
  {
    height: percent(60),
    position: 'relative',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    $nest: {
      '& > img': {
        position: 'absolute',
        borderRadius: px(4),
      },
      '&:only-child': {
        height: percent(100),
      },
    },
  },
  mediaUpToSmallTablet(mobilePositioning),
  mediaSmallTabletOnly(mobilePositioning, {
    $nest: {
      '& > img': {
        $nest: {
          '&:first-child': {
            top: percent(22.04),
          },
          '&:nth-child(2)': {
            top: percent(15.15),
          },
          '&:nth-child(3)': {
            top: percent(4.15),
          },
          '&:nth-child(4)': {
            top: percent(15.15),
          },
          '&:nth-child(5)': {
            top: percent(22.04),
          },
        },
      },
    },
  }),
  mediaLargeTablet({
    $nest: {
      '& > img': {
        $nest: {
          '&:first-child': {
            width: percent(24.591),
            top: percent(32.8),
            left: percent(-7.38),
          },
          '&:nth-child(2)': {
            width: percent(31.15),
            top: percent(27.34),
            left: percent(6.56),
            zIndex: 1,
          },
          '&:nth-child(3)': {
            width: percent(44.27),
            left: percent(27.865),
            top: percent(16.4),
            zIndex: 2,
          },
          '&:nth-child(4)': {
            width: percent(31.15),
            top: percent(27.34),
            right: percent(6.56),
            zIndex: 1,
          },
          '&:nth-child(5)': {
            width: percent(24.591),
            top: percent(32.8),
            right: percent(-7.38),
          },
        },
      },
    },
  })
);

export type CoverFlowProps = React.Component['props'] & { className?: string };

export const CoverFlow: React.SFC<CoverFlowProps> = ({ children, className }) => (
  <ul className={classes(styles, className)}>{children}</ul>
);
