import * as React from 'react';
import { Component, ComponentProps, deps, inject, observer } from '../lib/component';
import { Head } from './head';
import { Modals } from './modals';
import { AppCookieNotification } from '../components/app-cookie-notification';
import { constantHeaderHeight } from '../theme/constant';
import FeatureFlagWidget from '../components/devtools/feature-flag-widget';
import { HeaderBanner } from '../components/project-happy/organisms/HeaderBanner';
import { AppFooter } from '../components/project-happy/organisms/AppFooter';

export interface BaseContainerProps {
  className: string;
  mainClassName?: string;
  footerClassName?: string;
  header?: JSX.Element;
  footer?: JSX.Element;
}

@inject(deps)
@observer
export class BaseContainer extends Component<BaseContainerProps> {
  render() {
    const { content } = this.props.model;
    const { firstPaint, cookieNotification } = this.props.model.ui;
    const { children, className, mainClassName, footerClassName, header, footer } = this.props;
    const isHeaderProvided = React.isValidElement(header) || header === null;
    const isFooterProvided = React.isValidElement(footer) || footer === null;

    return (
      <div className={className}>
        <Head />
        {isHeaderProvided ? header : <HeaderBanner />}
        <main className={mainClassName}>
          {children}
          {isFooterProvided ? (
            footer
          ) : (
            <AppFooter
              faqs={content.footerFaqs}
              links={content.footerLinks}
              socialLinks={content.socialLinks}
              className={footerClassName}
            />
          )}
        </main>
        <Modals />
        {!firstPaint && (
          <AppCookieNotification
            model={cookieNotification}
            onClose={this.props.controller.ui.dismissCookieNotification}
          />
        )}
        <FeatureFlagWidget />
      </div>
    );
  }

  componentDidMount() {
    // Used to ensure client-side-only components can render after first paint
    this.props.model.ui.firstPaint = false;
    if (!this.props.model.env.isServer) {
      window.addEventListener('hashchange', this.offsetAnchor);
      setTimeout(this.offsetAnchor, 1);
    }
    this.props.model.basket.restrictedTracks.length > 0 && this.props.controller.modal.showBasketRestrictedModal();
  }

  componentWillUnmount() {
    if (!this.props.model.env.isServer) {
      window.removeEventListener('hashchange', this.offsetAnchor);
    }
  }

  offsetAnchor = () => {
    const { hash } = this.props.model.router.location;
    if (hash.length > 0) {
      const el = document.getElementById(hash.slice(1));
      if (el) {
        const offset = parseInt(constantHeaderHeight.toString());
        const { top } = this.getCoords(el);
        window.scrollTo(window.scrollX, Math.max(0, top - offset));
      }
    }
  };

  getCoords = (elem: Element) => {
    const box = elem.getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
  };
}
