import isNil from 'lodash/isNil';
import { BrowsePageModel, PLAYLIST_PAGE_FEATURED_PLAYLISTS_SLUG } from './model';
import { action, isObservableMap, observable, runInAction } from 'mobx';
import Router, { RedirectFunction, RouterState } from 'react-router';
import { APIController } from '../../api/controller';
import { UIController } from '../../ui/controller';
import { BugsnagController } from '../../bugsnag/controller';
import { ContentModel } from '../../content/model';
import { EnvModel } from '../../env/model';
import { PrismicController } from '../../prismic/controller';
import { ImageController } from '../../image/controller';
import { RouterModel } from '../../router/model';
import { stripQueryString } from '../../../lib/string';
import AppBreadcrumbs from '../../../components/app-breadcrumbs';
import Prismic from 'prismic-javascript';
import { isArray } from '../../../components/project-happy/utilities/objects';
import { fetchSliceData } from '../../../components/project-happy/utilities/prismic';
import {
  GradientDirection,
  PrismicDataDrivenTypes,
  PrismicSlices,
} from '../../../components/project-happy/utilities/types';
import { AuthModel } from '../../auth/model';

const PLAYLIST_NOT_FOUND = 'Playlist not found';

export class BrowsePageController {
  constructor(
    private model: BrowsePageModel,
    private api: APIController,
    private ui: UIController,
    private bugsnag: BugsnagController,
    private content: ContentModel,
    private env: EnvModel,
    private prismic: PrismicController,
    private image: ImageController,
    private router: RouterModel,
    private auth: AuthModel
  ) {
    // Potentially come from SSR which has been simplified to an object

    this.model.playlistGroups = observable.map(this.model.playlistGroups);
    const { slicePlaylists, sliceTracks, playlistMetadata, playlistTracks } = this.model;
    if (!isObservableMap(slicePlaylists)) {
      this.model.slicePlaylists = observable.map(slicePlaylists);
    }
    if (!isObservableMap(sliceTracks)) {
      this.model.sliceTracks = observable.map(sliceTracks);
    }
    if (!isObservableMap(playlistMetadata)) {
      this.model.playlistMetadata = observable.map(playlistMetadata);
    }
    if (!isObservableMap(playlistTracks)) {
      this.model.playlistTracks = observable.map(playlistTracks);
    }
  }

  @action
  onEnterPrismicRoute: Router.EnterHook = async (nextState: RouterState, replace: RedirectFunction) => {
    const { pathname } = nextState.location;

    this.model.loading = false; // Reset loading state; fixes a bug navigating between Prismic and legacy Browse pages

    if (this.model.loadedDocument === pathname) return;
    this.model.loading = true;
    this.model.slices = [];
    const documentType = 'browse_page';
    const predicates = [
      Prismic.Predicates.at('document.type', documentType),
      Prismic.Predicates.at(`my.${documentType}.slug`, pathname),
    ];

    const { results } = await this.prismic.query(predicates, { resolveImages: true });
    if (isArray(results) && results.length > 0) {
      const [content] = results;
      this.model.loadedDocument = pathname;
      this.model.prismicPageTitle = content.data.page_title;
      this.model.slices = content.data.slices;

      const { carousels: playlists, tracks } = await fetchSliceData(
        this.api,
        this.env,
        this.model.slices,
        this.auth.user ? this.auth.user.country : null
      );
      this.model.slicePlaylists = observable.map(playlists);
      this.model.sliceTracks = observable.map(tracks);
    } else if (pathname.indexOf('/stock-') > -1) {
      this.env.ssrStatus = 301;
      replace({ pathname: pathname.replace('/stock-', '/included-') });
    } else if (pathname.startsWith('/browse/playlists/')) {
      // In WEB-3984 we provided playlist group views, fall back to this behaviour if no prismic document exists
      const slug = pathname.replace('/browse/playlists/', '');
      await this.onEnterPlaylistGroup(slug);
      this.model.loadedDocument = pathname;
    } else if (pathname.startsWith('/browse/moods/')) {
      // A Prismic document hasn't been found and we know the mood hub will exist
      return replace({ pathname: '/browse/moods' });
    } else if (pathname.startsWith('/browse/video-themes/')) {
      // A Prismic document hasn't been found and we know the video-themes hub will exist
      return replace({ pathname: '/browse/video-themes' });
    } else if (pathname.startsWith('/browse/genres/')) {
      // A Prismic document hasn't been found and we know the genres hub will exist
      return replace({ pathname: '/browse/genres' });
    }
    this.model.loading = false;
  };

  @action
  onEnterPlaylistGroup = async (slug) => {
    try {
      const data = await this.api.browse.getPlaylistGroup(slug, 1);
      this.model.prismicPageTitle = data.title;
      // Piggyback the Prismic setup by hardcoding Prismic slices to render
      this.model.slicePlaylists = observable.map({ [slug]: data.playlists });
      this.model.sliceTracks = observable.map();
      this.model.slices = [
        {
          primary: {
            heading: data.title,
            background_image: {},
            background_colour: '#8019bc',
            background_gradient_colour_end: '#ff056d',
            background_gradient_colour_direction: GradientDirection.HORIZONTAL,
          },
          items: [],
          slice_type: PrismicSlices.BROWSE_MASTHEAD,
        },
        {
          primary: {
            tiles_heading: '',
            tiles_type: PrismicDataDrivenTypes.PLAYLISTS,
            tiles_slug: slug,
          },
          items: [],
          slice_type: PrismicSlices.CATALOGUE_TILES,
        },
      ];
    } catch {
      // The playlist group doesn't exist, allow the page to 404
    }
  };

  @action onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    this.model.loading = true;
    // Since WEB-4057, this has been hardcoded and all other Browse pages are served by Prismic
    this.model.type = 'collection';
    this.model.slug = stripQueryString(nextState.params['slug']) || null;

    // Need the playlist id from globals
    await this.env.ready;

    runInAction(() => {
      this.model.sections = observable([...this.content.browseSections]);
    });

    this.setBreadcrumbs();

    if (this.model.loadedInitial) {
      return this.setBrowseSEO(nextState.location.pathname);
    }

    const documentId = 'browse';

    const relatedPlaylists = await this.api.browse.getPlaylistGroup('featured-playlists', 1);
    this.model.playlistGroups.set('featured-playlists', relatedPlaylists);

    try {
      const [playlists, genres, themes, moods, releases, content] = await Promise.all([
        this.api.browse.getBrowsePlaylists(),
        this.api.browse.getSystemTags('hookd_genre'),
        this.api.browse.getSystemTags('hookd_theme'),
        this.api.browse.getSystemTags('hookd_mood'),
        this.api.browse.getRightsholderReleases(this.content.browseBottomGridRightsholder),
        this.prismic.getSingle(documentId),
        this.setBrowseSEO(nextState.location.pathname),
        this.env.ready,
      ]);

      let playlist;

      if (this.model.slug !== null) {
        playlist = await this.api.browse.getPlaylist(this.model.slug);
      }

      runInAction(() => {
        this.model.playlists = playlists.data;
        this.model.genres = genres.data;
        this.model.releases = releases.data;
        this.model.themes = themes.data;
        this.model.moods = moods.data;

        const heroSlice = content.data.body.find((slice: any) => slice.slice_type === 'hero_artist');

        this.model.heroArtists = ((heroSlice && heroSlice.items) || []).map((artist: any) => ({
          name: artist.name,
          image: artist.image.url,
          slug: artist.slug,
        }));

        let ogImageIdentity;
        const heroImage = this.model.heroArtists[0].image;

        if (!isNil(playlist) && !isNil(playlist.data)) {
          const { images } = playlist.data;
          ogImageIdentity = images ? images.identity : heroImage;
        } else {
          ogImageIdentity = heroImage;
        }

        this.model.selectedStaffPick = playlists.data.length ? playlists.data[0].slug : null;
        this.model.selectedGenre = genres.data.length ? genres.data[0].slug : null;
        this.model.selectedTheme = themes.data.length ? themes.data[0].slug : null;

        this.model.loadedInitial = true;
      });
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      runInAction(() => {
        this.model.loading = false;
      });
    }
  };

  @action
  async loadPlaylistBySlug(slug: string) {
    if (!this.model.playlistMetadata.has(slug)) {
      const { data: playlistMetadata, success } = await this.api.browse.getPlaylist(slug);
      if (success === false) throw Error(PLAYLIST_NOT_FOUND);
      this.model.playlistMetadata.set(slug, playlistMetadata);
    }

    let page = 1;
    const isLoaded = this.model.playlistTracks.has(slug);
    if (isLoaded) {
      const paginatedTracks = this.model.playlistTracks.get(slug);
      const {
        meta: { pagination },
      } = paginatedTracks;
      if (pagination.current_page === pagination.total_pages) {
        return paginatedTracks;
      }
      page += pagination.current_page;
    }
    const response = await this.api.browse.getPlaylistTracks(
      slug,
      page,
      25,
      this.auth.user ? this.auth.user.country : null
    );
    if (isLoaded) {
      const paginatedTracks = this.model.playlistTracks.get(slug);
      const { data: tracks, meta } = response;
      paginatedTracks.data.push(...tracks);
      paginatedTracks.meta.pagination.count = tracks.length;
      paginatedTracks.meta.pagination.current_page = meta.pagination.current_page;
      this.model.playlistTracks.set(slug, paginatedTracks);
      return paginatedTracks;
    }
    this.model.playlistTracks.set(slug, response);
  }

  @action onEnterTag = async (nextState: RouterState, replace: RedirectFunction) => {
    this.model.loading = true;
    const slug = stripQueryString(nextState.params['slug']) || null;
    this.model.slug = slug;

    if (slug !== null && (!this.model.playlistMetadata.has(slug) || !this.model.playlistTracks.has(slug))) {
      try {
        await this.loadPlaylistBySlug(slug);
      } catch (error) {
        if (error instanceof Error && error.message === PLAYLIST_NOT_FOUND) {
          this.env.ssrStatus = 404;
        } else {
          this.bugsnag.notifyException(error);
        }
      }
    }

    if (!this.model.playlistGroups.has(slug)) {
      try {
        const featuredPlaylists = await this.api.browse.getPlaylistGroup(PLAYLIST_PAGE_FEATURED_PLAYLISTS_SLUG, 1);
        this.model.playlistGroups.set(PLAYLIST_PAGE_FEATURED_PLAYLISTS_SLUG, featuredPlaylists);
      } catch {
        // We won't have any featured playlists to show and we'll retry fetching on the next playlist load
      }
    }
    this.model.loading = false;
  };

  @action goToPage = (page: number) => {
    const from = (page - 1) * this.model.size;

    this.model.from = from;
    this.model.page = page;
    this.router.push(this.model.activePath(page));
  };

  @action selectStaffPick = (slug: string) => (this.model.selectedStaffPick = slug);
  @action selectGenre = (slug: string) => (this.model.selectedGenre = slug);
  @action selectTheme = (slug: string) => (this.model.selectedTheme = slug);

  @action private setBreadcrumbs = (label?: string) => {
    const { type, slug } = this.model;

    const breadcrumbs = [
      {
        path: '/browse',
        label: 'Browse',
      },
    ];

    if (type)
      breadcrumbs.push({
        path: `/browse/${type}`,
        label: this.model.typeLabel,
      });

    if (slug)
      breadcrumbs.push({
        path: `/browse/${type}/${slug}`,
        label: label || AppBreadcrumbs.LOADING,
      });

    this.ui.setBreadcrumbs(breadcrumbs);
  };

  @action public setBrowseSEO = async (path: string) => {
    const { setSEO } = this.ui;
    const { slug, type, title } = this.model;

    if (!type) return setSEO(path);

    return setSEO(slug ? `/browse/${type}/slug` : `/browse/${type}`, { [type]: title });
  };
}
