import { StorageModel } from '../storage/model';
import { EnvModel } from '../env/model';
import { AuthModel } from '../auth/model';
import { RouterModel } from '../router/model';
import { UIModel } from '../ui/model';
import { I18nModel } from '../i18n/model';
import { PlayerModel } from '../player/model';
import { APIModel } from '../api/model';
import { PageModel } from '../page/model';
import { BasketModel } from '../basket/model';
import { MailchimpModel } from '../mailchimp/model';
import { UserModel } from '../user/model';
import { PusherModel } from '../pusher/model';
import { OnboardingModel } from '../onboarding/model';
import { CheckoutModel } from '../checkout/model';
import { ImageModel } from '../image/model';
import { ContentModel } from '../content/model';
import { RequestModel } from '../request/model';
import { ModalModel } from '../modal/model';
import { CouponModel } from '../coupon/model';
import { PricingModel } from '../pricing/model';
import { PlaylistModel } from '../playlist/model';
import { CacheModel } from '../cache/model';
import { ArtistlistModel } from '../artistlist/model';
import { GraphQLModel } from '../graphql/model';
import { Model } from '../../types';
import { SubscriptionModel } from '../subscription/model';
import { PrismicContentIslandsModel } from '../prismic-content-islands/model';

export class ClientModel implements Model {
  cache: CacheModel;
  env: EnvModel;
  graphql: GraphQLModel;
  api: APIModel;
  i18n: I18nModel;
  router: RouterModel;
  storage: StorageModel;
  auth: AuthModel;
  request: RequestModel;
  mailchimp: MailchimpModel;
  pusher: PusherModel;
  content: ContentModel;

  user: UserModel;
  coupon: CouponModel;
  page: PageModel;
  player: PlayerModel;
  playlist: PlaylistModel;
  artistlist: ArtistlistModel;
  basket: BasketModel;
  onboarding: OnboardingModel;
  checkout: CheckoutModel;
  modal: ModalModel;
  ui: UIModel;
  image: ImageModel;
  pricing: PricingModel;
  subscription: SubscriptionModel;
  prismicContentIslands: PrismicContentIslandsModel;

  constructor({
    location,
    envVars,
    isServer = false,
  }: {
    location?: any;
    envVars?: LickdEnvironmentVariables;
    isServer?: boolean;
  }) {
    this.router = new RouterModel(isServer);
    if (location) {
      this.router.location = location;
    }
    this.cache = new CacheModel();
    this.env = new EnvModel({ envVars });
    this.api = new APIModel();
    this.i18n = new I18nModel();
    this.storage = StorageModel;
    this.page = new PageModel(this.router);
    this.auth = new AuthModel(this.env, this.storage, this.router);
    this.graphql = new GraphQLModel(this.env, this.auth);
    this.request = new RequestModel();
    this.mailchimp = new MailchimpModel(this.env);
    this.pusher = new PusherModel();
    this.content = new ContentModel();

    this.pricing = new PricingModel();
    this.player = new PlayerModel(this.env, this.auth);
    this.playlist = new PlaylistModel();
    this.artistlist = new ArtistlistModel();
    this.user = new UserModel(this.auth, this.env, this.pricing);
    this.subscription = new SubscriptionModel(this.user);
    this.coupon = new CouponModel();
    this.basket = new BasketModel(this.auth, this.storage, this.user, this.pricing, this.coupon);
    this.onboarding = new OnboardingModel(this.auth, this.env, this.storage, this.mailchimp, this.content);
    this.checkout = new CheckoutModel(this.basket, this.auth, this.user, this.coupon, this.content);
    this.modal = new ModalModel(this.user, this.auth, this.pricing, this.basket);
    this.ui = new UIModel(
      this.player,
      this.onboarding,
      this.auth,
      this.storage,
      this.env,
      this.router,
      this.content,
      this.modal,
      this.user
    );
    this.image = new ImageModel(this.env, this.ui);
    this.prismicContentIslands = new PrismicContentIslandsModel();
  }

  dispose = () => void 0;
}
