import * as React from 'react';
import { classes, style } from 'typestyle';
import { centerCenter, horizontal, scroll, content } from 'csstips';
import { SVGIcon } from './svg-icon';
import { important, percent, px } from 'csx';
import { gradientCarouselLeft, gradientCarouselRight } from '../theme/gradient';
import { transitionQuickEase } from '../theme/transition';
import { mediaMobileOnly } from '../theme/media';
import { constantMobileBreakpoint } from '../theme/constant';
import { ButtonPill } from './button-pill';
import { deps, inject, observer, StatefulComponent } from '../lib/component';

export interface CarouselProps {
  className?: string;
  innerClassName?: string;
  spacerClassName?: string;
  scrollerClassName?: string;
  scrollToIndex?: number;
}

export interface CarouselState {
  showLeft: boolean;
  showRight: boolean;
  scrollLeft: number;
  clientX: number;
  isScrolling: boolean;
}

@inject(deps)
@observer
export class Carousel extends StatefulComponent<CarouselProps, CarouselState> {
  private content: HTMLDivElement;
  private listener: any;
  private _mounted: boolean;

  constructor(props) {
    super(props);
    this.state = {
      showLeft: false,
      showRight: false,
      scrollLeft: 0,
      clientX: 0,
      isScrolling: false,
    };
  }

  render() {
    const { spacerClassName, scrollerClassName } = this.props;
    const { showLeft, showRight } = this.state;

    return (
      <div className={classes(Carousel.styles.container, this.props.className)} onMouseDown={this.beginDragScrolling}>
        <div ref={(el) => (this.content = el)} className={classes(Carousel.styles.content, this.props.innerClassName)}>
          {this.props.children}
          {this.props.children && spacerClassName && <div className={spacerClassName} />}
        </div>
        <div
          className={classes(
            Carousel.styles.scroller,
            Carousel.styles.scrollerLeft,
            scrollerClassName,
            showLeft && Carousel.styles.scrollerActive,
          )}
        >
          <ButtonPill
            primary={true}
            className={classes(Carousel.styles.scrollerButton, Carousel.styles.scrollerButtonLeft)}
            onClick={this.scrollLeft}
          >
            <SVGIcon.ArrowLeft />
          </ButtonPill>
        </div>
        <div
          className={classes(
            Carousel.styles.scroller,
            Carousel.styles.scrollerRight,
            scrollerClassName,
            showRight && Carousel.styles.scrollerActive,
          )}
        >
          <ButtonPill
            primary={true}
            className={classes(Carousel.styles.scrollerButton, Carousel.styles.scrollerButtonRight)}
            onClick={this.scrollRight}
          >
            <SVGIcon.ArrowRight />
          </ButtonPill>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this._mounted = true;
    this.listener = () => {
      if (!this._mounted) return;

      this.setState(this.calculateVisibility());
    };

    this.content.addEventListener('scroll', this.listener);
    setTimeout(this.listener, 9);

    if (!this.content) return;

    if (
      !isNaN(this.props.scrollToIndex) &&
      this.content.children.length &&
      this.props.scrollToIndex < this.content.children.length
    ) {
      this.scrollTo(this.props.scrollToIndex);
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    this.content.removeEventListener('scroll', this.listener);
    this.toggleScrolling(false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.children !== prevProps.children) {
      this.listener();
    }
  }

  scrollRight = () => this.scrollChunk(true);
  scrollLeft = () => this.scrollChunk(false);

  private scrollChunk = (right: boolean) => {
    if (!this._mounted || !this.content || this.content.children.length === 0) return;

    const { scrollLeft, offsetWidth } = this.getContentDimensions();
    const itemWidth = this.getItemWidth();

    const numberToScroll = Math.floor(offsetWidth / itemWidth);
    const alreadyScrolled = Math.floor((scrollLeft - 60) / itemWidth); // account for paddle

    const startX = scrollLeft;
    const duration = 300;
    const targetItem = Math.max(0, alreadyScrolled + (right ? numberToScroll + 1 : 1 - numberToScroll));

    const distance = targetItem * itemWidth - startX - 60; // account for paddle

    this.createScroller({ startX, duration, distance })();
  };

  private scrollTo = (targetItem: number) => {
    if (!this._mounted || !this.content || this.content.children.length === 0) return;

    const itemWidth = this.getItemWidth();
    const startX = this.content.scrollLeft;
    const duration = 300;
    const distance = targetItem * itemWidth - startX - 60; // account for paddle

    this.createScroller({ startX, duration, distance })();
  };

  private getContentDimensions = () => {
    const contentStyle = getComputedStyle(this.content);
    return {
      scrollLeft: this.content.scrollLeft,
      offsetWidth:
        this.content.offsetWidth - parseFloat(contentStyle.paddingLeft) - parseFloat(contentStyle.paddingRight),
    };
  };

  private getItemWidth = () => {
    const itemStyle = getComputedStyle(this.content.children[0] as HTMLElement);
    return parseFloat(itemStyle.width) + parseFloat(itemStyle.marginRight);
  };

  private createScroller = (props: { startX: number; duration: number; distance: number }) => {
    const { startX, duration, distance } = props;
    const { content, _mounted } = this;

    if (!_mounted || !content) return;

    let timeoutId;
    const setScrollTimeoutId = (id) => (timeoutId = id);
    const stopScroll = () => {
      clearTimeout(timeoutId);
      setScrollTimeoutId(0);
    };

    const updatePosition = (x: number) => this._mounted && (this.content.scrollLeft = x);

    const startTime = new Date().getTime();

    return function loopScroll() {
      setScrollTimeoutId(
        setTimeout(function () {
          // Calculate percentage:
          const p = Math.min(1, (new Date().getTime() - startTime) / duration);
          // Calculate the absolute vertical position:
          const x = Math.max(0, Math.floor(startX + distance * (p < 0.5 ? 2 * p * p : p * (4 - p * 2) - 1)));

          updatePosition(x);

          if (p < 1) {
            loopScroll();
          } else {
            setTimeout(stopScroll, 99); // with cooldown time
          }
        }, 9),
      );
    };
  };

  calculateVisibility = () => {
    const { offsetWidth, scrollWidth, scrollLeft } = this.content;
    return {
      showLeft: offsetWidth < scrollWidth && scrollLeft > 0,
      showRight: offsetWidth < scrollWidth && scrollLeft + offsetWidth < scrollWidth,
    };
  };

  beginDragScrolling = (event: React.MouseEvent<any>) => {
    const { scrollLeft } = this.content;
    const { clientX } = event;

    this.setState({ isScrolling: true, scrollLeft, clientX });
  };

  componentWillUpdate(nextProps, nextState) {
    if (this.state.isScrolling !== nextState.isScrolling) {
      this.toggleScrolling(nextState.isScrolling);
    }
  }

  toggleScrolling = (enable: boolean) => {
    if (enable) {
      window.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('mouseup', this.onMouseUp);
    } else {
      window.removeEventListener('mousemove', this.onMouseMove);
      window.removeEventListener('mouseup', this.onMouseUp);
    }
  };

  onMouseMove = (event: MouseEvent) => {
    if (!this.content) return;
    const { clientX, scrollLeft } = this.state;
    this.content.scrollLeft = scrollLeft + clientX - event.clientX;
    event.preventDefault();
  };

  onMouseUp = (event: MouseEvent) => {
    if (!this.content) return;
    this.setState({ isScrolling: false, scrollLeft: 0, clientX: 0 });
  };

  isMobile = () => {
    try {
      return (
        Math.max(
          document.documentElement.clientWidth,
          document.documentElement.offsetWidth,
          document.documentElement.scrollWidth,
        ) <= constantMobileBreakpoint
      );
    } catch (e) {
      return false;
    }
  };

  static styles = {
    container: style({
      position: 'relative',
      overflow: 'hidden',
      width: percent(100),
    }),
    scroller: style(
      {
        ...centerCenter,
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: px(60),
        zIndex: 2,
        opacity: 0,
        transition: `opacity ${transitionQuickEase}, transform ${transitionQuickEase}`,
      },
      mediaMobileOnly({
        display: 'none',
      }),
    ),
    scrollerLeft: style({
      left: 0,
      background: gradientCarouselLeft,
      transform: 'translate3d(-60px,0,0)',
    }),
    scrollerRight: style({
      right: 0,
      background: gradientCarouselRight,
      transform: 'translate3d(60px,0,0)',
    }),
    scrollerActive: style({
      opacity: 1,
      transform: 'translate3d(0,0,0)',
    }),
    scrollerButton: style({
      padding: important(0),
      width: px(44),
      height: px(44),
    }),
    scrollerButtonLeft: style({
      $nest: { '&> svg': { marginRight: px(2) } },
    }),
    scrollerButtonRight: style({
      $nest: { '&> svg': { marginLeft: px(2) } },
    }),
    content: style({
      ...horizontal,
      ...content,
      overflowX: 'scroll',
      overflowY: 'hidden',
      '-webkit-overflow-scrolling': 'touch',
      zIndex: 1,
      width: percent(100),
      paddingBottom: important('22px'), // to hide scrollbars
    }),
  };
}
