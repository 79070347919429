import * as React from 'react';
import { style } from 'typestyle';
import { ComponentProps } from '../../../lib/component';
import { colorWhite, rebrand } from '../../../theme/color';
import { px } from 'csx';
import { StarCircle } from '../atoms/icons/StarCircle';
import { TagLabel } from '../atoms/icons/TagLabel';
import { centerJustified, flexRoot } from 'csstips';

const styles = {
  wrapper: style({
    textAlign: 'center',
    fontSize: px(12),
  }),
  infoText: style({
    color: rebrand.contrast[40].toString(),
  }),
  block: style(flexRoot, centerJustified, {
    background: rebrand.neutralOnDark[10].toString(),
    borderRadius: px(8),
    padding: '8px 16px',
    margin: '8px auto',
    maxWidth: px(440),
    $nest: {
      '& svg': {
        marginRight: px(8),
        flexShrink: 0,
      },
      '& p': {
        margin: 0,
      },
    },
  }),
};

export type SignUpToUnlockProps = ComponentProps;

export const SignUpToUnlockBlock: React.SFC<SignUpToUnlockProps> = () => (
  <span className={styles.wrapper}>
    <div className={styles.block}>
      <StarCircle size={18} color={rebrand.primary1.toString()} />
      <p>
        <strong>Free</strong> 14 day Creator Plan trial
      </p>
    </div>
    <div className={styles.block}>
      <TagLabel size={18} />
      <p>
        <strong>25% off</strong> your first &apos;Premium&apos; track!
      </p>
    </div>
    <p className={styles.infoText}>Discount automatically applied at checkout</p>
  </span>
);
