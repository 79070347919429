import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';
import { style } from 'typestyle';
import { mediaCoarsePointer } from '../../../../theme/media';

export const FavouritePrompt: React.SFC<SVGIconProps> = ({ size = 24, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 26 26" fill={color} {...props}>
    {/*Mobile path*/}
    <path
      d="M14.86 8.961l.046.138 1.06 3.698-.006.006.607 2.117.875-.25c.298-.086.606-.101.913-.044l4.494.834c.848.162 1.5.839 1.619 1.689l.623 4.46a1.994 1.994 0 01-1.429 2.2l-5.921 1.698c-.51.146-1.298.154-1.768-.107l-5.685-3.353.567-1.036a.99.99 0 01.807-.523l3.328-.184L12.03 9.98a1.515 1.515 0 01-.053-.285A1.495 1.495 0 0113.05 8.07c.751-.215 1.53.18 1.81.891zM14.5 0C17.58 0 20 2.42 20 5.5c0 2.238-1.192 4.23-3.233 6.455l-.9-3.132a2.498 2.498 0 00-3.092-1.714l-.157.05a2.498 2.498 0 00-1.557 3.042l1.636 5.704-1.247 1.135L10 18.35l-1.45-1.32C3.4 12.36 0 9.28 0 5.5 0 2.42 2.42 0 5.5 0 7.24 0 8.91.81 10 2.09 11.09.81 12.76 0 14.5 0z"
      className={style({ display: 'none', $unique: true }, mediaCoarsePointer({ display: 'initial' }))}
    />
    {/*Desktop path*/}
    <path
      d="M14.5 0C17.58 0 20 2.42 20 5.5c0 2.144-1.094 4.062-2.98 6.175l-2.345-2.144C14.033 8.945 13 9.4 13 10.27l.001 5.357c-.497.456-1.014.926-1.551 1.414L10 18.35l-1.45-1.32C3.4 12.36 0 9.28 0 5.5 0 2.42 2.42 0 5.5 0 7.24 0 8.91.81 10 2.09 11.09.81 12.76 0 14.5 0zM14 10.27l2.342 2.139h-.001L19 14.841h-2.188l.382.655.41.726.29.527.216.41.187.373.078.166c.064.14-.115.364-.228.486l-.084.085h-.625l-1.563-2.857L14 17.126V10.27z"
      className={style(mediaCoarsePointer({ display: 'none' }))}
    />
  </BaseSvg>
);
