export type TypeDecorated<T> = T & { __typename: string };

/**
 * Below is opied across from lambda-catalog
 * TODO extract the .grapgql + its types into the schema repo, ideally
 */

export interface Query {
  analytics: Analytics | null;
  find: FindResults | null;
  search: SearchResults | null;
  tags: Array<Tag> | null;
  track: Track | null;
  browseArticle: ArticleResults | null;
  browseCollection: CollectionResults | null;
  browseFilter: FilterResults | null;
  browseRelease: ReleaseResults | null;
  browseRightsholder: RightsholderResults | null;
  browseTag: TagResults | null;
  browseTrack: TrackResults | null;
  getArticle: Article | null;
  getCollection: Collection | null;
  getFilter: Filter | null;
  getRightsholder: Rightsholder | null;
  getTag: Tag | null;
  getTrack: Track | null;
  getYoutubeChannel: YoutubeChannel | null;
  getArtistLanding: ArtistLanding | null;
  searchExternal: SearchExternalResults | null;
}

export interface FindQueryArgs {
  query: string | null;
  filters: FindFiltersInput | null;
}

export interface SearchQueryArgs {
  size: number | null;
  from: number | null;
  query: string | null;
  type: SearchType | null;
  sort: SearchSort | null;
  durationMin: number | null;
  durationMax: number | null;
  bpmMin: number | null;
  bpmMax: number | null;
  genre: string | null;
  theme: string | null;
  mood: string | null;
  tempo: string | null;
  brandSponsored: boolean | null;
  explicit: boolean | null;
  movieMusicGenre: string | null;
  videoPlatforms: TrackVideoPlatformsInput | null;
}

export interface TagsQueryArgs {
  source: string;
  type: string;
  order: TagOrder | null;
}

export interface TrackQueryArgs {
  identity: string;
}

export interface BrowseArticleQueryArgs {
  options: BrowseQueryInput;
}

export interface BrowseCollectionQueryArgs {
  options: BrowseQueryInput;
}

export interface BrowseFilterQueryArgs {
  options: BrowseQueryInput;
}

export interface BrowseReleaseQueryArgs {
  options: BrowseQueryInput;
}

export interface BrowseRightsholderQueryArgs {
  options: BrowseQueryInput;
}

export interface BrowseTagQueryArgs {
  options: BrowseQueryInput;
}

export interface BrowseTrackQueryArgs {
  options: BrowseQueryInput;
}

export interface GetArticleQueryArgs {
  options: GetArticleInput;
}

export interface GetCollectionQueryArgs {
  options: GetCollectionInput;
}

export interface GetFilterQueryArgs {
  options: GetFilterInput;
}

export interface GetRightsholderQueryArgs {
  options: GetRightsholderInput;
}

export interface GetTagQueryArgs {
  options: GetTagInput;
}

export interface GetTrackQueryArgs {
  options: GetTrackInput;
}

export interface GetYoutubeChannelQueryArgs {
  id: string;
}

export interface GetArtistLandingQueryArgs {
  slug: string;
}

export interface SearchExternalQueryArgs {
  artist: string;
  track: string;
}

export interface Analytics {
  searchMiss: AnalyticsSearchMissResults | null;
}

export interface SearchMissAnalyticsArgs {
  filter: string | null;
  pagination: PaginationInput | null;
}

export interface PaginationInput {
  size: number | null;
  from: number | null;
  page?: number | null;
}

export interface AnalyticsSearchMissResults {
  results: Array<AnalyticsSearchMiss>;
  pagination: Pagination;
}

export interface AnalyticsSearchMiss {
  args: SearchArguments | null;
  time: number | null;
}

export interface SearchArguments {
  size: number | null;
  from: number | null;
  query: string | null;
  type: SearchType | null;
  sort: SearchSort | null;
  durationMin: number | null;
  durationMax: number | null;
  bpmMin: number | null;
  bpmMax: number | null;
  genre: string | null;
  theme: string | null;
  mood: string | null;
  tempo: string | null;
  brandSponsored: boolean | null;
  explicit: boolean | null;
  movieMusicGenre: string | null;
  videoPlatforms: TrackVideoPlatforms | null;
}

export type SearchType = 'TRACKS';

export type SearchSort =
  | 'relevance'
  | 'popularSpotify'
  | 'popularYouTube'
  | 'popularLickd'
  | 'recentlyAdded'
  | 'artistName';

export interface TrackVideoPlatforms {
  dailymotion: boolean | null;
  facebook: boolean | null;
  instagram: boolean | null;
  linkedin: boolean | null;
  snapchat: boolean | null;
  twitch: boolean | null;
  twitter: boolean | null;
  vimeo: boolean | null;
  youtube: boolean | null;
  TikTok: boolean | null;
}

export interface Pagination {
  total: number | null;
  totalPages: number | null;
  size: number | null;
  from: number | null;
  page: number | null;
  maxScore: number | null;
}

export interface FindFiltersInput {
  catalogueType: CatalogueType;
  identity?: string | undefined;
  isrc?: string | undefined;
  upc?: string | undefined;
  track?: string | undefined;
  artist?: string | undefined;
  release?: string | undefined;
  rightsholder?: string | undefined;
  playlist?: string | undefined;
  mood?: string | undefined;
  theme?: string | undefined;
  genre?: string | undefined;
  bpmMin?: number | null;
  bpmMax?: number | null;
  tempoMin?: number | null;
  tempoMax?: number | null;
  durationMin?: number | null;
  durationMax?: number | null;
  releasedAfter?: string | undefined;
  releasedBefore?: string | undefined;
  letter?: string | undefined;
  letters?: Array<string> | undefined;
  language?: string | undefined;
  brandSponsored?: boolean | undefined;
  brandedContent?: boolean | undefined;
  explicit?: boolean | undefined;
  featured?: boolean | undefined;
  instrumental?: boolean | undefined;
  dailymotion?: boolean | undefined;
  facebook?: boolean | undefined;
  instagram?: boolean | undefined;
  linkedin?: boolean | undefined;
  snapchat?: boolean | undefined;
  twitch?: boolean | undefined;
  twitter?: boolean | undefined;
  vimeo?: boolean | undefined;
  youtube?: boolean | undefined;
  TikTok?: boolean | undefined;
  podcasting?: boolean | undefined;
  availableIn?: string | undefined;
  hideNotAvailableIn?: boolean | undefined;
  matchArtistFallback?: boolean | undefined;
}

export interface FindResults {
  query: string | null;
  filters: FindFilters | null;
  tracks: TrackResults | null;
  relatedTracks: TrackResults | null;
  artists: ArtistResults | null;
  missingArtists: ArtistResults | null;
  relatedArtists: ArtistResults | null;
  fallbackArtists: FallbackArtistResults | null;
  releases: ReleaseResults | null;
  playlists: CollectionResults | null;
  rightsholders: RightsholderResults | null;
  moods: TagResults | null;
  themes: TagResults | null;
  genres: TagResults | null;
}

export interface TracksFindResultsArgs {
  pagination: PaginationInput | null;
  sortMode: FindSortMode | null;
  sortOrder: FindSortOrder | null;
}

export interface RelatedTracksFindResultsArgs {
  pagination: PaginationInput | null;
}

export interface ArtistsFindResultsArgs {
  pagination: PaginationInput | null;
}

export interface MissingArtistsFindResultsArgs {
  pagination: PaginationInput | null;
}

export interface RelatedArtistsFindResultsArgs {
  pagination: PaginationInput | null;
}

export interface FallbackArtistsFindResultsArgs {
  pagination: PaginationInput | null;
}

export interface ReleasesFindResultsArgs {
  pagination: PaginationInput | null;
}

export interface PlaylistsFindResultsArgs {
  pagination: PaginationInput | null;
}

export interface RightsholdersFindResultsArgs {
  pagination: PaginationInput | null;
}

export interface MoodsFindResultsArgs {
  pagination: PaginationInput | null;
}

export interface ThemesFindResultsArgs {
  pagination: PaginationInput | null;
}

export interface GenresFindResultsArgs {
  pagination: PaginationInput | null;
}

export interface FindFilters {
  identity: string | null;
  isrc: string | null;
  upc: string | null;
  track: string | null;
  artist: string | null;
  release: string | null;
  rightsholder: string | null;
  playlist: string | null;
  mood: string | null;
  theme: string | null;
  genre: string | null;
  bpmMin: number | null;
  bpmMax: number | null;
  durationMin: number | null;
  durationMax: number | null;
  releasedAfter: string | null;
  releasedBefore: string | null;
  letter: string | null;
  letters: Array<string> | null;
  language: string | null;
  brandSponsored: boolean | null;
  brandedContent: boolean | null;
  explicit: boolean | null;
  featured: boolean | null;
  instrumental: boolean | null;
  dailymotion: boolean | null;
  facebook: boolean | null;
  instagram: boolean | null;
  linkedin: boolean | null;
  snapchat: boolean | null;
  twitch: boolean | null;
  twitter: boolean | null;
  vimeo: boolean | null;
  youtube: boolean | null;
  TikTok: boolean | null;
  matchArtistFallback: boolean | null;
}

export type FindSortMode =
  | 'RELEVANCE'
  | 'ALPHABETICAL'
  | 'POPULAR_LICKD'
  | 'POPULAR_YOUTUBE'
  | 'POPULAR_SPOTIFY'
  | 'DATE_ADDED';

export type FindSortOrder = 'ASC' | 'DESC';

export enum CatalogueType {
  ALL = 'ALL',
  COMMERCIAL = 'CHART',
  STOCK = 'PRODUCTION',
}

export interface TrackResults {
  results: Array<Track>;
  pagination: Pagination;
}

export interface Track {
  identity: string;
  highlight: Array<Highlight> | null;
  title: string;
  slug: string;
  artists: Array<Artist>;
  duration: number;
  is_featured: boolean;
  created_at: string | null;
  is_charting: boolean;
  is_new_release: boolean;
  is_instrumental: boolean;
  mixes_count: number;
  images: Image;
  audio: Audio;
  audition_point: number | null;
  tags: Tags;
  tempo: string;
  bpm: number;
  brand_sponsored: boolean;
  branded_content: boolean;
  video_platforms: TrackVideoPlatforms;
  theme: TrackVideoTheme | null;
  themes: Array<TrackVideoTheme> | null;
  similar: Array<SimilarTrack> | null;
  rightsholders: Array<Rightsholder> | null;
  releases: Array<Release> | null;
  vouch_status: VouchStatus | null;
  youtube_content_owners: Array<YoutubeContentOwner> | null;
  youtube_video_categories: Array<YoutubeVideoCategory> | null;
  p_line: string | null;
  available_at: string | null;
  is_explicit: boolean;
}

export interface ThemeTrackArgs {
  slug: string | null;
}

export interface Highlight {
  key: string;
  value: string;
}

export interface Artist {
  active: boolean;
  identity: string;
  highlight: Array<Highlight> | null;
  name: string;
  slug: string;
  images: Image;
  tracks: TrackResults | null;
  similar: Array<SimilarArtist> | null;
}

export interface TracksArtistArgs {
  pagination: PaginationInput | null;
}

export interface Image {
  identity: string;
}

export interface SimilarArtist {
  identity: string;
  score: number;
  artist: Artist | null;
}

export interface Audio {
  identity: string;
}

export interface Tags {
  hookd_genre: Array<Tag> | null;
  ceg_category: Array<Tag> | null;
}

export interface Tag {
  slug: string;
  name: string;
  type: string | null;
  source: string | null;
  images: Image | null;
  counts: TagCounts | null;
  tracks: TrackResults | null;
}

export interface TracksTagArgs {
  pagination: PaginationInput | null;
}

export interface TagCounts {
  tracks: number | null;
}

export interface TrackVideoTheme {
  name: string;
  slug: string;
  percent: number | null;
  count: number | null;
}

export interface SimilarTrack {
  identity: string;
  sequence: number | null;
  audition_point: number | null;
  track: Track | null;
}

export interface Rightsholder {
  active: boolean;
  is_listed: boolean;
  brand_sponsored: boolean;
  branded_content: boolean;
  counts: RightsholderCounts;
  created_at: string;
  description: string | null;
  identity: string;
  images: Image;
  name: string;
  slug: string;
  video_platforms: TrackVideoPlatforms;
  tracks: TrackResults | null;
}

export interface TracksRightsholderArgs {
  pagination: PaginationInput | null;
}

export interface RightsholderCounts {
  track: number;
}

export interface Release {
  identity: string;
  title: string;
  slug: string;
  images: Image;
  artist: Artist;
  tracks: TrackResults | null;
  c_line: string;
}

export interface TracksReleaseArgs {
  pagination: PaginationInput | null;
}

export type VouchStatus = 'UNMATCHED' | 'CLEARED' | 'OVERRIDDEN';

export interface YoutubeContentOwner {
  name: string;
  active: boolean;
}

export interface YoutubeVideoCategory {
  name: string;
  slug: string;
  percent: number;
  count: number;
}

export interface ArtistResults {
  results: Array<Artist>;
  pagination: Pagination;
}

export interface FallbackArtistResults {
  results: Array<FallbackArtist>;
  pagination: Pagination;
}

export interface FallbackArtist {
  genres: Array<string>;
  image: ImageExternal;
  slug: string;
  name: string;
  tracks: TrackResults | null;
}

export interface TracksFallbackArtistArgs {
  pagination: PaginationInput | null;
}

export interface ImageExternal {
  url: string | null;
}

export interface ReleaseResults {
  results: Array<Release>;
  pagination: Pagination;
}

export interface CollectionResults {
  results: Array<Collection>;
  pagination: Pagination;
}

export interface Collection {
  identity: string;
  name: string;
  slug: string;
  description: string | null;
  images: Image;
  isListed: boolean;
  sequence: number | null;
  tracks: TrackResults | null;
}

export interface TracksCollectionArgs {
  pagination: PaginationInput | null;
}

export interface RightsholderResults {
  results: Array<Rightsholder>;
  pagination: Pagination;
}

export interface TagResults {
  results: Array<Tag>;
  pagination: Pagination;
}

export interface TrackVideoPlatformsInput {
  dailymotion: boolean | null;
  facebook: boolean | null;
  instagram: boolean | null;
  linkedin: boolean | null;
  snapchat: boolean | null;
  twitch: boolean | null;
  twitter: boolean | null;
  vimeo: boolean | null;
  youtube: boolean | null;
  TikTok: boolean | null;
}

export interface SearchResults {
  searchResultsConnection: SearchResultsConnection | null;
}

export interface SearchResultsConnection {
  hits: Array<SearchResultHit> | null;
  meta: SearchMeta | null;
}

export interface SearchResultHit {
  id: string;
  node: SearchResultNode;
}

export type SearchResultNode = Track;

export interface SearchMeta {
  total: number | null;
  totalPages: number | null;
  size: number | null;
  from: number | null;
  page: number | null;
}

export type TagOrder = 'DEFAULT' | 'TAG_ASC' | 'TAG_DESC' | 'TRACK_COUNT_ASC' | 'TRACK_COUNT_DESC';

export interface BrowseQueryInput {
  type: BrowseQueryType;
  source: string | null;
  query: string | null;
  pagination: PaginationInput | null;
}

export type BrowseQueryType =
  | 'TAG'
  | 'PLAYLIST_COLLECTION'
  | 'RELEASE_RIGHTSHOLDER'
  | 'RANDOM'
  | 'RIGHTSHOLDER'
  | 'ARTICLE'
  | 'FILTER';

export interface ArticleResults {
  category: string | null;
  slug: string | null;
  results: Array<Article>;
  pagination: Pagination | null;
}

export interface Article {
  id: string;
  slug: string;
  title: string;
  posted: string | null;
  category: ArticleCategory;
  author: ArticleAuthor;
  summary: string;
  cover: ArticleImage | null;
  sections: Array<ArticleSection>;
}

export interface ArticleCategory {
  name: string | null;
  slug: string | null;
  description: string | null;
  image: ArticleImage | null;
}

export interface ArticleImage {
  caption: string | null;
  main: string | null;
  xlarge: string | null;
  large: string | null;
  medium: string | null;
  small: string | null;
  xsmall: string | null;
}

export interface ArticleAuthor {
  name: string | null;
  image: ArticleImage | null;
}

export type ArticleSection = ArticleSectionCatalog | ArticleSectionImage | ArticleSectionProse | ArticleSectionVideo;

export interface ArticleSectionCatalog {
  identity: string;
  type: ArticleSectionCatalogType;
  display: ArticleSectionCatalogDisplay;
  item: ArticleSectionCatalogItem | null;
}

export type ArticleSectionCatalogType = 'COLLECTION' | 'RELEASE' | 'ARTIST' | 'TRACK';

export type ArticleSectionCatalogDisplay = 'CAROUSEL' | 'GRID' | 'LIST';

export type ArticleSectionCatalogItem = Collection | Release | Artist | Track;

export interface ArticleSectionImage {
  primary: ArticleImage;
  secondary: Array<ArticleImage>;
}

export interface ArticleSectionProse {
  content: string;
}

export interface ArticleSectionVideo {
  primary: ArticleVideo;
  secondary: Array<ArticleVideo>;
}

export interface ArticleVideo {
  title: string | null;
  caption: string | null;
  link: string | null;
  embed: string | null;
  thumbnail: string | null;
}

export interface FilterResults {
  results: Array<Filter>;
  pagination: Pagination;
}

export interface Filter {
  title: string;
  slug: string;
  images: Image;
  query: string | null;
  filters: FindFilters;
  tracks: TrackResults | null;
}

export interface TracksFilterArgs {
  pagination: PaginationInput | null;
}

export interface GetArticleInput {
  category: string;
  slug: string;
}

export interface GetCollectionInput {
  slug: string;
}

export interface GetFilterInput {
  slug: string;
}

export interface GetRightsholderInput {
  slug: string;
}

export interface GetTagInput {
  type: TagType;
  slug: string;
}

export type TagType = 'GENRE' | 'THEME' | 'MOOD';

export interface GetTrackInput {
  identity: string;
}

export interface YoutubeChannel {
  id: string;
  name: string;
  description: string | null;
  images: Array<YoutubeChannelImage>;
  ratecard: YoutubeChannelRatecard | null;
  ratecard_statistics: YoutubeChannelRatecardStatistics | null;
}

export interface YoutubeChannelImage {
  size: string;
  url: string;
}

export interface YoutubeChannelRatecard {
  name: string;
  value: number;
  viewership: number;
  currency: string;
  country: string | null;
  region: string | null;
  paladin_company: string | null;
  brand_sponsored: boolean;
  branded_content: boolean;
}

export interface YoutubeChannelRatecardStatistics {
  average_views: number;
  video_count: number;
  view_count: number;
}

export interface ArtistLanding {
  type: ArtistLandingType;
  slug: string;
  name: string | null;
  image: Image | null;
  tracks: Array<Track> | null;
  tags: Array<Tag> | null;
}

export type ArtistLandingType = 'ACTIVE' | 'INACTIVE' | 'FALLBACK' | 'MISSING';

export interface SearchExternalResults {
  tracks: Array<ExternalMoodAgentTrack>;
}

export interface ExternalMoodAgentTrack {
  TrackID: string;
  Title: string;
  Isrc: string;
  Album: string;
  Duration: number;
  Artists: Array<ExternalMoodAgentArtist>;
  similar: Array<SimilarTrack> | null;
}

export interface ExternalMoodAgentArtist {
  ArtistID: string;
  ArtistName: string;
}

export type TagQueryType = 'GENRE' | 'THEME';

export interface TrackQueryInput {
  type: TrackQueryType | null;
  query: string | null;
  pagination: PaginationInput | null;
}

export type TrackQueryType = 'TAG_GENRE' | 'TAG_THEME' | 'PLAYLIST_COLLECTION';
