import * as React from 'react';
import { classes, style } from 'typestyle';
import { ModalProps } from '../../modal';
import { Component, deps, inject, observer } from '../../../lib/component';
import { center, content, flexRoot, vertical } from 'csstips';
import { rebrand } from '../../../theme/color';
import { mediaDesktop, mediaMobileOnly, mediaUpToDesktop } from '../utilities/mediaQueries';
import { ScrollableOverlay } from '../../scrollable-overlay/scrollable-overlay';
import { centerCenter } from 'csstips/lib/flex';
import { em, percent, px } from 'csx';
import { CircleButton } from '../atoms/button/CircleButton';
import { ChevronLeft } from '../atoms/icons/ChevronLeft';
import { ChevronRight } from '../atoms/icons/ChevronRight';
import { ButtonProminence } from '../atoms/button/buttonStyles';
import { getEmptySlicePageContext, renderComponentsFromPrismicSlices } from '../prismic-slices';
import { CSSTransition } from 'react-transition-group';
import { transitionExpandFade } from '../../../theme/transition';
import { Button } from '../atoms/button/Button';
import { TagLabel } from '../atoms/icons/TagLabel';
import { ACCOUNT_PLAYLISTS_ROUTE } from '../../../constants';
import { Link } from '../atoms/Link';

const styles = {
  modal: style(
    center,
    {
      padding: '0 0 16px',
    },
    mediaMobileOnly({
      height: '110%',
      borderRadius: '16px 16px 0 0',
    })
  ),
  title: style(content, {
    textAlign: 'center',
    margin: '12px 0px',
  }),
  body: style(flexRoot, centerCenter, vertical, content, {
    position: 'relative',
    border: `1px solid ${rebrand.light1.toString()}`,
    borderLeft: 0,
    borderRight: 0,
    width: percent(100),
    $nest: {
      '&:last-child': {
        borderBottom: 0,
      },
    },
  }),
  announcement: style({
    position: 'absolute',
    top: 0,
    left: 0,
    width: percent(100),
    height: percent(100),
  }),
  footer: style(flexRoot, centerCenter, content, {
    width: px(120),
    paddingTop: px(8),
  }),
  text: style({
    fontSize: em(0.75),
    width: percent(75),
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  }),
  navigationButton: style({
    flexShrink: 0,
  }),
  textBody: style({
    fontWeight: 700,
  }),
  discountBlock: style({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: rebrand.neutralOnDark[10].toString(),
    width: '248px',
    height: '36px',
    borderRadius: '8px',
    justifyContent: 'center',
    marginBottom: '16px',
  }),
  button: style(
    mediaDesktop({
      width: '219px',
    }),
    {}
  ),
  buttonWrapper: style(mediaUpToDesktop({ flexDirection: 'column' }), {
    display: 'flex',
    gap: '16px',
    margin: '8px',
  }),
  termsText: style(mediaDesktop({ width: '454px' }), {
    textAlign: 'center',
    fontSize: '12px',
  }),
  termsTextWrapper: style(mediaUpToDesktop({ width: '256px' }), {
    margin: '8px',
  }),
  blackButtonText: style({ color: 'black' }),
};

const emptySliceContext = getEmptySlicePageContext();

export type PlatformAnnouncementModalProps = ModalProps;

@observer
@inject(deps)
export class PlatformAnnouncementModal extends Component<PlatformAnnouncementModalProps> {
  state = {
    shownAnnouncement: 0,
  };

  constructor(props) {
    super(props);
    this.handleAnnouncementsLinkClicks = this.handleAnnouncementsLinkClicks.bind(this);
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
    this.handleAcceptFeature = this.handleAcceptFeature.bind(this);
  }

  handleAnnouncementsLinkClicks(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    const target = event.target as typeof event.target & { tagName?: string };
    if ('tagName' in target && target.tagName === 'A') {
      this.props.onRequestClose();
    }
  }

  handleCloseButtonClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    this.props.onRequestClose();
  }

  handleAcceptFeature(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    this.props.controller.analytics.sendMixpanel('playlist feature announcement', {
      featureAnnouncement: 'user accepts feature announcement',
    });
    this.props.onRequestClose();
  }

  render() {
    const {
      show,
      onRequestClose,
      model: {
        content: { unseenAnnouncements: announcements },
      },
    } = this.props;
    const { shownAnnouncement } = this.state;
    return (
      <ScrollableOverlay className={styles.modal} show={show} onRequestClose={onRequestClose}>
        <h4 className={classes(styles.title)} data-test-platform-updates-modal-title>
          New Platform Updates
        </h4>
        <main className={styles.body}>
          {renderComponentsFromPrismicSlices(announcements, emptySliceContext).map((component, index) => (
            <CSSTransition key={index} in={index === shownAnnouncement} {...transitionExpandFade}>
              <span className={styles.textBody} onClick={this.handleAnnouncementsLinkClicks}>
                {component}
              </span>
            </CSSTransition>
          ))}
          <div className={styles.discountBlock}>
            <TagLabel size={18} style={{ marginRight: '10px' }} />
            <p>
              <span style={{ fontWeight: 700 }}>15% off</span> your next order
            </p>
          </div>

          <div className={styles.termsTextWrapper}>
            <p className={styles.termsText}>
              Promotion & discount only valid in June - so get in now to avoid missing out!{' '}
              <Link
                style={{ textDecoration: 'underline' }}
                href="https://lickd.co/terms-and-conditions/playlist-promo-jun-23"
              >
                T&Cs apply
              </Link>
            </p>
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              onClick={this.handleCloseButtonClick}
              className={styles.button}
              prominence={ButtonProminence.SECONDARY}
              data-test-announcment-close-button
            >
              Close
            </Button>
            <Button
              href={ACCOUNT_PLAYLISTS_ROUTE}
              prominence={ButtonProminence.PRIMARY}
              className={styles.blackButtonText}
              onClick={this.handleAcceptFeature}
              data-test-accept-button
            >
              Sounds good, let&apos;s go!
            </Button>
          </div>

          {announcements.length > 1 && (
            <footer className={styles.footer}>
              <CircleButton
                className={styles.navigationButton}
                prominence={ButtonProminence.SECONDARY}
                disabled={shownAnnouncement === 0}
                onClick={() => this.setState({ shownAnnouncement: shownAnnouncement - 1 })}
                label="Previous announcement"
              >
                <ChevronLeft size={6} />
              </CircleButton>
              <p className={styles.text}>
                {shownAnnouncement + 1} of {announcements.length}
              </p>
              <CircleButton
                className={styles.navigationButton}
                prominence={ButtonProminence.SECONDARY}
                disabled={shownAnnouncement === announcements.length - 1}
                onClick={() => this.setState({ shownAnnouncement: shownAnnouncement + 1 })}
                label="Next announcement"
              >
                <ChevronRight size={6} />
              </CircleButton>
            </footer>
          )}
        </main>
      </ScrollableOverlay>
    );
  }
}
