import * as React from 'react';
import { observer } from 'mobx-react';
import { PageHeader } from './page-header';
import { em, px } from 'csx';
import { mediaTablet } from '../theme/media';
import { style } from 'typestyle';
import { LoadingContainer } from './loading-container';

export interface Page404Model {}

export interface Page404Props {
  model?: Page404Model;
}

export const Page404 = observer(({ model }: Page404Props) => (
  <LoadingContainer loading={false}>
    <PageHeader>
      <h1>Page not found</h1>
    </PageHeader>
    <div data-test-page-not-found className={styles.content}></div>
  </LoadingContainer>
));

const styles = {
  content: style(
    {
      maxWidth: em(54),
      margin: '0 auto 0 0',
      padding: '60px 20px',
      $nest: {
        '&> div': {
          margin: '0 0 60px 0',
        },
      },
    },
    mediaTablet({
      padding: px(80),
    })
  ),
};
