import * as React from 'react';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { rebrand } from '../../../theme/color';
import { SemanticInformationType } from '../utilities/types';
import { Checkmark } from './icons/Checkmark';

// Each number references a colour level of the rebrand.semantic colour set
const statusGradients = {
  [SemanticInformationType.SUCCESS]: { from: 60, to: 50 },
  [SemanticInformationType.WARNING]: { from: 50, to: 40 },
  [SemanticInformationType.ERROR]: { from: 50, to: 40 },
  [SemanticInformationType.INFO]: { from: 50, to: 40 },
};

const statusStyles = {};

for (const type of Object.values(SemanticInformationType)) {
  const { from, to } = statusGradients[type];
  const fromColour = rebrand.semantic[type][from].toString();
  const toColour = rebrand.semantic[type][to].toString();
  statusStyles[type] = style({
    backgroundImage: `linear-gradient(to right, ${fromColour}, ${toColour})`,
  });
}

const styles = {
  banner: style({
    textAlign: 'center',
    fontSize: px(12),
    lineHeight: px(24),
    color: rebrand.contrast[100].toString(),
    margin: 0,
  }),
  checkmark: style({
    marginLeft: px(4),
    verticalAlign: 'sub',
  }),
};

/**
 * The block banner are used within blocks, currently mainly used for the payment plan and registration sections.
 *
 * A block banner shows a status colour with a message and sites at the top of the block.
 */
export type BlockBannerProps = {
  status: SemanticInformationType;
};

export const BlockBanner: React.SFC<BlockBannerProps> = ({ children, status }) => (
  <p className={classes(styles.banner, statusStyles[status])}>
    {children}
    {status === SemanticInformationType.SUCCESS && <Checkmark className={styles.checkmark} color="currentColor" />}
  </p>
);
