import * as React from 'react';
import { observer } from 'mobx-react';
import { style } from 'typestyle';
import {centerCenter, content, endJustified, horizontal, start, vertical} from 'csstips';
import { colorBlack, colorWhite } from '../theme/color';
import {em, percent, px} from 'csx';
import { ResponsiveImageTile } from './responsive-image-tile';
import {transitionQuickEase} from '../theme/transition';
import {ImagePreloader} from '../types/fn';
import {PlaylistSchema} from '../types/schema';
import {shadowFirm} from '../theme/shadow';
import FullWidthText from './full-width-text';
import {Component, deps, inject} from '../lib/component';

export interface PlaylistTileProps {
  playlist: {
    name: string;
    images?: {
      identity: string;
    }
  };
  className?: string;
  onClick?(event: React.MouseEvent<any>): void;
  preload: ImagePreloader;
  ratio?: number;
  href?: string;
  Favourite?: any;
}

@inject(deps) @observer
export class PlaylistTile extends Component<PlaylistTileProps> {
  render() {
    const { playlist, onClick, ratio = 1, preload, className, href } = this.props;
    const { windowSizeHash } = this.props.model.ui;

    return (
      <ResponsiveImageTile identity={playlist.images ? playlist.images.identity : void 0} preload={preload} ratio={ratio} onClick={onClick} className={className} href={href}>
        <div title={playlist.name} className={PlaylistTile.styles.container}>
          <FullWidthText refresh={windowSizeHash} className={PlaylistTile.styles.name}>
            {playlist.name}
          </FullWidthText>
        </div>
      </ResponsiveImageTile>
    )
  }

  static styles = {
    container: style({
      ...centerCenter,
      ...vertical,
      ...content,
      textOverflow: 'hidden',
      overflow: 'hidden',
      width: percent(100),
    }),
    name: style({
      cursor: 'pointer',
      padding: px(20),
      fontSize: px(20),
      fontWeight: 'bold',
      lineHeight: em(1.1),
      width: percent(100),
      textAlign: 'center',
      textShadow: shadowFirm,
      color: colorWhite.toString(),
      transition: `transform ${transitionQuickEase}`,
      $nest: {
        '&:hover': {
          transform: 'translate3d(0,-3px,0)'
        }
      }
    }),
    buttons: style({
      ...horizontal,
      ...endJustified,
      width: percent(100),
      padding: px(10)
    })
  }
}