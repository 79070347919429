import * as React from 'react';
import { Component, deps, inject, observer } from '../../../lib/component';
import { style } from 'typestyle';
import { percent, px } from 'csx';
import { ChannelCard } from '../molecules/ChannelCard';
import { Button } from '../atoms/button/Button';
import { ButtonProminence } from '../atoms/button/buttonStyles';
import { AddCircle } from '../atoms/icons/AddCircle';
import { flexRoot } from 'csstips';
import { centerCenter } from 'csstips/lib/flex';
import { ConnectChannelPrompt } from '../molecules/ConnectYoutubeChannelPrompt';
import { ConnectChannelInfoblock } from '../atoms/ConnectChannelInfoblock';
import { spacing } from '../../../theme/spacing';

const styles = {
  headerTitle: style({
    margin: `0 0 ${spacing.DEFAULT}`,
    fontSize: px(28),
  }),
  addChannel: style(flexRoot, centerCenter, {
    width: percent(100),
  }),
  addChannelIcon: style({
    marginLeft: px(8),
  }),
  connectChannelPrompt: style({
    height: px(48),
    width: px(320),
    marginTop: '16px !important',
    marginBottom: '16px !important',
  }),
};

export interface ChannelSwitcherAccountSidebarProps {
  className?: string;
}

@inject(deps)
@observer
export class ChannelSwitcherAccountSidebar extends Component<ChannelSwitcherAccountSidebarProps> {
  render() {
    const {
      model: {
        user: { channels },
        router: {
          location: { pathname },
        },
      },
      controller: {
        modal: { showChannelAddModal },
        analytics: { sendMixpanel },
      },
    } = this.props;

    const hasChannels = channels.length > 0;

    return (
      <>
        <h3 className={styles.headerTitle}>Your Channels</h3>

        {hasChannels ? (
          <>
            {channels.map((channel) => (
              <ChannelCard channel={channel} key={channel.id} />
            ))}
            <Button
              className={styles.addChannel}
              prominence={ButtonProminence.SECONDARY}
              onClick={() => showChannelAddModal()}
              data-test-channel-list-sidebar-add-new-channel-button
            >
              Add another channel <AddCircle className={styles.addChannelIcon} />
            </Button>
          </>
        ) : (
          <ConnectChannelPrompt
            className={styles.connectChannelPrompt}
            showChannelAddModal={showChannelAddModal}
            sendMixpanel={sendMixpanel}
            location={pathname}
          />
        )}
        {!hasChannels && <ConnectChannelInfoblock />}
      </>
    );
  }
}
