import { px } from 'csx';
import { maxPageWidth } from '../config';
import { style } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { applyHeadingStyles } from './font';

export const rebrandPageStyles: NestedCSSProperties = {
  margin: '0 auto',
  backgroundColor: 'white',
  boxShadow: '0px 2px 4px 1px rgb(0,0,0,5%)',
  overflow: 'hidden',
};

export const rebrandPageClass = style(rebrandPageStyles);

export const scrollToTop = () =>
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
