import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { em, percent, px, rgba } from 'csx';
import { abbreviateNumber } from '../utilities/numbers';
import { YouTubeLogo } from './icons/brands/YouTubeLogo';
import { SVGIcon } from '../../svg-icon';
import { colorDarkBlueGrey } from '../../../theme/color';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { centerCenter, flexRoot } from 'csstips';
import { mixinTextEllipsis } from '../../../theme/mixin';

const styles = {
  videoInfo: style({
    padding: px(16),
    $nest: {
      '& p': {
        margin: 0,
      },
    },
  }),
  videoTitle: style(mixinTextEllipsis, {
    display: 'block',
    fontWeight: 600,
  }),
  videoMetadata: style({
    fontSize: px(12),
  }),
};

export type YouTubeVideoDetailsProps = {
  title: string;
  views: number;
  publishDate: Date;
};

export const YouTubeVideoDetails: React.SFC<YouTubeVideoDetailsProps> = ({ title, views, publishDate }) => (
  <div className={styles.videoInfo}>
    <p className={styles.videoTitle}>{title}</p>
    <p className={styles.videoMetadata}>
      {abbreviateNumber(views)} views · {publishDate.toDateString()}
    </p>
  </div>
);
