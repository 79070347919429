/* eslint-disable react/jsx-key */
import { classes, style } from 'typestyle';
import * as React from 'react';
import { Component, deps, inject, observer } from '../../../lib/component';
import { PrismicTypedDashboardHeaderProps } from '../utilities/types';
import crown from '../../../static/crown.svg';

import { percent, px, url } from 'csx';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { colorWhite, rebrand } from '../../../theme/color';

import { flexRoot } from 'csstips';

import { RatecardDisplay } from '../molecules/RatecardDisplay';
import { DashboardHeaderCarousel } from './DashboardHeaderCarousel';
import { CommonDesktopBreakpoint, fontstackDisplay } from '../config';
import { debounce } from '../utilities/functions';

const styles = {
  container: style({
    background: rebrand.contrast[70].toHexString(),
    marginBottom: '56px',
  }),
  wrapper: style(
    mediaDesktop({
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      maxWidth: px(CommonDesktopBreakpoint),
      margin: '0 auto',
      height: '100%',
    })
  ),
  welcome: style(
    {
      maxWidth: '490px',
      padding: '16px 0',
    },
    mediaDesktop({
      padding: '48px 16px',
    })
  ),
  welcomeHeading: style(
    {
      position: 'relative',
      margin: 0,
      transform: 'rotate(-5deg)',
      fontFamily: fontstackDisplay,
      fontSize: '38px',
      textAlign: 'center',
      color: colorWhite.toString(),
      $nest: {
        '& em': {
          color: rebrand.primary1.toString(),
        },
        '& span': {
          display: 'block',
        },
      },
    },
    mediaDesktop({
      fontSize: '50px',
    }),
    mediaDesktop({
      // Crown background image - using a pseudo element to make overflow visible
      textAlign: 'left',
      overflow: 'visible',
      $nest: {
        '&::before': {
          content: "''",
          width: px(162),
          height: px(144),
          backgroundImage: url(crown),
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          position: 'absolute',
          top: px(-12),
          right: px(-58),
          zIndex: -1,
        },
      },
    })
  ),
  textWrapper: style({
    $nest: {
      '& span': {
        display: 'block',
      },
    },
  }),
  headingRatecardWrapper: style(
    {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: '16px',
    },
    mediaDesktop({
      padding: '32px',
      justifyContent: 'flex-start',
    })
  ),
  allWidth: style(flexRoot, {
    width: percent(100),
  }),
};

// Styles for NO PROMO version of the component
const noPromoStyles = {
  container: style(
    {
      position: 'relative',
    },
    // No promo container has a darker background on the left from desktop up
    mediaDesktop({
      $nest: {
        '&::before': {
          content: "''",
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '50%',
          backgroundColor: rebrand.contrast[90].toHexString(),
        },
      },
    })
  ),
  wrapper: style({
    alignItems: 'center',
  }),
  welcome: style(
    {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    mediaUpToDesktop({
      padding: '32px 16px 16px',
    })
  ),
  headingRatecardWrapper: style(
    mediaDesktop({
      justifyContent: 'center',
    })
  ),
};

const WelcomeToDashboard = ({ className }: { className?: string }) => (
  <div className={classes(styles.welcome, className)}>
    <h1 className={styles.welcomeHeading}>
      Welcome to <span>your dashboard</span>
    </h1>
  </div>
);

export type Props = { items: PrismicTypedDashboardHeaderProps[] };

@inject(deps)
@observer
export class DashboardHeader extends Component<Props> {
  resizeHandler: () => void;

  state = {
    page: 1,
    isSquareDashboardHeadereImage: false,
  };

  constructor(props) {
    super(props);
    this.resizeHandler = debounce(this.handleResize.bind(this));
  }

  handleResize() {
    const isSquareDashboardHeadereImage =
      (window.innerWidth >= 1024 && window.innerWidth <= 1259) || window.innerWidth <= 499;
    this.setState({ isSquareDashboardHeadereImage: isSquareDashboardHeadereImage });
  }

  componentDidMount(): void {
    window.addEventListener('resize', this.resizeHandler);
    this.handleResize();
  }
  componentWillUnmount(): void {
    window.removeEventListener('resize', this.resizeHandler);
  }

  render() {
    const {
      items,
      model: {
        user: { user },
      },
      controller: {
        analytics: { sendMixpanel },
      },
    } = this.props;
    const defaultChannel = user && user.default_channel;
    const promoExist = items.length > 0;

    return (
      <div className={classes(styles.container, !promoExist && noPromoStyles.container)}>
        <div className={classes(styles.wrapper, !promoExist && noPromoStyles.wrapper)}>
          {promoExist ? (
            <DashboardHeaderCarousel
              items={items}
              isSquareDashboardHeadereImage={this.state.isSquareDashboardHeadereImage}
              sendMixpanel={sendMixpanel}
            />
          ) : (
            <WelcomeToDashboard className={noPromoStyles.welcome} />
          )}
          <div className={classes(styles.headingRatecardWrapper, !promoExist && noPromoStyles.headingRatecardWrapper)}>
            {promoExist && <WelcomeToDashboard />}
            <div className={styles.allWidth}>
              <RatecardDisplay key={defaultChannel ? defaultChannel : 'no-channel'} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
