import * as React from 'react';
import {AudioMetaSchema} from '../../types/schema';
import {ImagePreloader} from '../../types/fn';
import {ResponsiveImage} from '../responsive-image';
import {classes, style} from 'typestyle';
import {percent, px} from 'csx';

type WaveformProps = {
  start: number;
  end: number;
  duration: number;
  waveform: string;
  className?: string;
  preloadWaveform: ImagePreloader;
}

class Waveform extends React.Component<WaveformProps, any> {
  render() {
    const {
      className,
      preloadWaveform,
      waveform,
      duration,
      start,
      end,
    } = this.props;

    const leftFraction = 100 * (start / duration);
    const rightFraction = 100 * (end / duration);

    const styleOver = {
      clipPath: `polygon(${leftFraction}% 0%, ${leftFraction}% 100%, ${rightFraction}% 100%, ${rightFraction}% 0%)`
    };

    const styleUnderStart = {
      clipPath: `polygon(0% 0%, 0% 100%, ${leftFraction}% 100%, ${leftFraction}% 0%)`
    };

    const styleUnderEnd = {
      clipPath: `polygon(${rightFraction}% 0%, ${rightFraction}% 100%, 100% 100%, 100% 0%)`
    };

    return (
      <div className={classes(Waveform.styles.container, className)}>
        <ResponsiveImage
          preload={preloadWaveform}
          identity={waveform}
          defaultSize={Waveform.defaultSize}
          className={classes(Waveform.styles.waveform, Waveform.styles.colorWaveform)}
          style={styleOver}
        />
        <ResponsiveImage
          preload={preloadWaveform}
          identity={waveform}
          defaultSize={Waveform.defaultSize}
          className={classes(Waveform.styles.waveform, Waveform.styles.grayscaleWaveform)}
          style={styleUnderStart}
        />
        <ResponsiveImage
          preload={preloadWaveform}
          identity={waveform}
          defaultSize={Waveform.defaultSize}
          className={classes(Waveform.styles.waveform, Waveform.styles.grayscaleWaveform)}
          style={styleUnderEnd}
        />
      </div>
    )
  }

  static defaultSize = {
    width: 128,
    height: 0,
    type: 'coloured',
    placeholder: true,
  }

  static styles = {
    container: style({
      position: 'relative',
    }),
    waveform: style({
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: percent(100),
      height: percent(100),
      borderRadius: px(50),
    }),
    colorWaveform: style({
      zIndex: 2,
    }),
    grayscaleWaveform: style({
      zIndex: 1,
      '-webkit-filter': `grayscale(100%)`,
      filter: `grayscale(100%)`,
    })
  }
}

export default Waveform;