import * as React from 'react';
import { ChangeHook, EnterHook } from 'react-router';
import { LegacyPageContainer } from './containers/legacy';
import { MusicArtistPage } from './containers/music-artist';
import { CheckoutV2Container } from './containers/checkout-v2';
import { CheckoutContainer } from './containers/checkout';
import { clientOnly, maybeSkip, onEnterNoop, passThrough, requireAuth } from './lib/ssr';
import { AccountLicencesPage } from './containers/account-licences';
import { AccountOrdersPage } from './containers/account-orders';
import { AccountOrderPage } from './containers/account-order';
import { SearchPage } from './containers/search';
import { AccountChannelPage } from './containers/account-channel';
import { AccountBillingPage } from './containers/account-billing';
import { AccountCommunicationPage } from './containers/account-communication';
import { AccountInvitesPage } from './containers/account-invites';
import { _404Page } from './containers/404';
import { AccountFavouritesPage } from './containers/account-favourites';
import { TrackPage } from './containers/track';
import { AccountProfilePage } from './containers/account-profile';
import { ClientModel } from './modules/client/model';
import { ClientController } from './modules/client/controller';
import { BrowsePage } from './containers/browse';
import { AccountCouponsPage } from './containers/account-coupons';
import {
  SIGN_UP_ROUTE,
  SEARCH_ROUTE,
  ACCOUNT_PLANS_ROUTE,
  BROWSE_ROUTE,
  LOG_IN_ROUTE,
  ACCOUNT_VIDEOS_ROUTE,
  REGISTRATION_ROUTE,
  ACCOUNT_PLAYLISTS_ROUTE,
  PUBLIC_PLAYLISTS_ROUTE,
  ONBOARD_ROUTE,
  ONBOARD_VERTICAL_CATEGORY_ROUTE,
  ONBOARD_FAVOURITE_GENRES,
  ONBOARD_VERTICALS_COMPLETE,
  ACCOUNT_ORDERS_ROUTE,
} from './constants';

import { AffiliatePage } from './containers/affiliate';
import { MCNPage } from './containers/mcn';
import { PartnerPage } from './containers/partner';
import { LanderPage } from './components/landers/LanderPage';
import { PageContainer as ModernContainer } from './components/project-happy/PageContainer';
import { SignUp } from './components/project-happy/pages/SignUp';
import { CreatorDashboard } from './components/project-happy/pages/CreatorDashboard';
import { Registration } from './components/project-happy/pages/Registration';
import { AccountPlansPage } from './containers/account-plans';
import { Pricing } from './components/project-happy/pages/Pricing';
import { PricingContainer } from './containers/pricing';
import { BrowsePage as ProjectDarwinBrowsePage } from './components/project-happy/pages/BrowsePage';
import { LogIn } from './components/project-happy/pages/LogIn';
import { DashboardTeaser } from './components/project-happy/pages/DashboardTeaser';
import { PlaylistPage } from './components/project-happy/pages/PlaylistPage';
import { RegistrationHeaderBanner } from './components/project-happy/organisms/RegistrationHeaderBanner';
import { HeaderBannerMinimal } from './components/project-happy/organisms/HeaderBannerMinimal';
import { CommonDesktopBreakpoint, desktopBreakpoint } from './components/project-happy/config';
import { px, viewWidth } from 'csx';
import { style } from 'typestyle';
import { VideoAccountPage } from './components/project-happy/pages/VideoAccountPage';
import { AccountUserPlaylistsPage } from './components/project-happy/pages/AccountUserPlaylistsPage';
import { OnboardVerticalPersonalisationPage } from './components/project-happy/pages/OnboardVerticalPersonalisationPage';
import { OnboardVerticalCategorisationPage } from './components/project-happy/pages/OnboardVerticalCategorisationPage';
import { OnboardFavouriteGenresPage } from './components/project-happy/pages/OnboardFavouriteGenres';
import { OnboardPersonalisationCompletePage } from './components/project-happy/pages/OnboardVerticalComplete';
import { Search } from './components/project-happy/pages/Search';
import { AccountMenu } from './components/project-happy/molecules/AccountMenu';
import { zFilter } from './theme/z';
import { Referral } from './components/project-happy/pages/Referral';
import { UserPlaylistPage } from './components/project-happy/pages/UserPlaylistPage';
import { PaymentPlansV2 } from './components/project-happy/organisms/registration/PaymentPlans-V2';

const minimalContentWidth = style({
  maxWidth: px(desktopBreakpoint),
});
const largeDesktopContentWidth = style({
  maxWidth: px(CommonDesktopBreakpoint),
});

const referralContentWidth = style({
  maxWidth: viewWidth(100),
});

const stickyFooter = style({
  position: 'sticky',
  zIndex: zFilter,
});

const MinimalPageModernContainer: React.FC = (props) => (
  <ModernContainer mainClassName={minimalContentWidth} header={<HeaderBannerMinimal />} footer={null} {...props} />
);

const ReferralPageModernContainer: React.FC = (props) => (
  <ModernContainer mainClassName={referralContentWidth} header={<HeaderBannerMinimal />} footer={null} {...props} />
);

const RegistrationPageModernContainer: React.FC = (props) => (
  <ModernContainer
    mainClassName={largeDesktopContentWidth}
    header={<RegistrationHeaderBanner />}
    footer={null}
    {...props}
  />
);

const CheckoutPageModernContainer: React.FC = (props) => (
  <ModernContainer mainClassName={minimalContentWidth} footer={null} {...props} />
);
const SearchPageModernContainer: React.FC = (props) => <ModernContainer {...props} footerClassName={stickyFooter} />;
const OnboardPageModernContainer: React.FC = (props) => <ModernContainer footer={null} {...props} />;

const AccountPageModernContainer: React.FC = ({ children, ...props }) => (
  <ModernContainer {...props}>
    <AccountMenu />
    {children}
  </ModernContainer>
);

export interface RouterSpec {
  path?: string;
  static?: boolean;
  serverStatus?: number;
  component: any;
  onEnter?: EnterHook;
  onLeave?: any;
  onChange?: ChangeHook;
  indexRoute?: ChildRouterSpec;
  childRoutes?: Array<ChildRouterSpec>;
}

export interface ChildRouterSpec extends RouterSpec {
  onEnter: EnterHook;
  onLeave?: any;
  onChange?: ChangeHook;
}

/*
 * NOTE: Always put more specific routes above less specific, so SSR route matching will work
 * e.g. /music/artists/:artist/release/:release goes before /music/artists/:artist
 */
export const createRoutes = (controller: ClientController, model: ClientModel): RouterSpec[] => {
  const { page } = controller;
  const { auth, router } = model;

  return [
    {
      path: '/affiliate/mike-russell',
      component: AffiliatePage,
      onEnter: (_, replace) => replace({ pathname: '/affiliate/music-radio-creative' }),
    },
    {
      path: '/mcn/musicradiocreative',
      component: MCNPage,
      onEnter: (_, replace) => replace({ pathname: '/affiliate/music-radio-creative' }),
    },
    {
      path: '/mcn/:slug',
      component: MCNPage,
      onEnter: maybeSkip(page.mcn.onEnter),
    },
    {
      path: '/partners/legis',
      component: PartnerPage,
      onEnter: maybeSkip(page.partner.onEnter),
    },
    {
      path: '/partners/:slug',
      component: LanderPage,
      onEnter: page.lander.onEnter,
      static: true,
    },
    {
      path: '/affiliate/:slug',
      component: LanderPage,
      onEnter: page.lander.onEnter,
      static: true,
    },
    {
      path: '/essentials/subscribe',
      component: () => null,
      onEnter: requireAuth((_, replace) => replace({ pathname: ACCOUNT_PLANS_ROUTE }), auth, router),
    },
    {
      path: '/account/invites',
      component: () => null,
      onEnter: requireAuth((_, replace) => replace({ pathname: '/account/community' }), auth, router),
    },
    {
      path: SIGN_UP_ROUTE,
      component: MinimalPageModernContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: SignUp,
        onEnter: passThrough(page.signin.onEnter),
      },
    },
    {
      path: LOG_IN_ROUTE,
      component: MinimalPageModernContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: LogIn,
        onEnter: passThrough(page.signin.onEnter),
      },
    },
    {
      path: `${REGISTRATION_ROUTE}(/:step)`,
      component: RegistrationPageModernContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: Registration,
        onEnter: requireAuth(page.registration.onEnter, auth, router),
        onChange: (prevState, nextState) => page.registration.onEnter(nextState),
      },
    },
    {
      path: ONBOARD_ROUTE,
      component: OnboardPageModernContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: OnboardVerticalPersonalisationPage,
        onEnter: requireAuth(() => void 0, auth, router),
      },
      childRoutes: [
        {
          path: ONBOARD_VERTICAL_CATEGORY_ROUTE,
          component: OnboardVerticalCategorisationPage,
          onEnter: requireAuth(page.favourites.onEnter, auth, router),
        },
        {
          path: ONBOARD_FAVOURITE_GENRES,
          component: OnboardFavouriteGenresPage,
          onEnter: requireAuth(() => void 0, auth, router),
        },
        {
          path: ONBOARD_VERTICALS_COMPLETE,
          component: OnboardPersonalisationCompletePage,
          onEnter: requireAuth(() => void 0, auth, router),
        },
      ],
    },
    {
      path: '/',
      component: ModernContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: DashboardTeaser,
        onEnter: passThrough(page.home.onEnter),
      },
    },
    {
      path: '/dashboard',
      component: ModernContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: CreatorDashboard,
        onEnter: passThrough(page.creatorDashboard.onEnter),
      },
    },
    // This will be removed once we're good with the new search

    // {
    //   path: SEARCH_ROUTE,
    //   component: LegacyPageContainer,
    //   onEnter: page.master.onEnter,
    //   onLeave: page.master.onLeave,
    //   onChange: page.master.onLeave,
    //   indexRoute: {
    //     component: SearchPage,
    //     onEnter: passThrough(page.search.onEnter),
    //     onLeave: page.search.onLeave,
    //   },
    // },
    {
      path: '/pricing-calculator',
      component: LegacyPageContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: PricingContainer,
        onEnter: maybeSkip(page.pricing.onEnter),
      },
    },
    {
      path: '/pricing',
      component: ModernContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: Pricing,
        onEnter: maybeSkip(page.pricing.onEnter),
      },
    },
    {
      path: '/account',
      component: AccountPageModernContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: () => null,
        onEnter: (_, replace) => replace({ pathname: ACCOUNT_PLANS_ROUTE }),
      },
      childRoutes: [
        {
          path: '/account/favourites',
          component: AccountFavouritesPage,
          onEnter: requireAuth(page.favourites.onEnter, auth, router),
        },
        {
          path: ACCOUNT_PLAYLISTS_ROUTE,
          component: AccountUserPlaylistsPage,
          onEnter: requireAuth(page.accountPlaylists.onEnter, auth, router),
        },
        //  User playlists are now public, so redirect to public page [WEB-6928]
        {
          path: ACCOUNT_PLAYLISTS_ROUTE + '/:id',
          component: () => null,
          onEnter: ({ params }, replace) => replace({ pathname: `${PUBLIC_PLAYLISTS_ROUTE}/${params.id}` }),
        },
        {
          path: ACCOUNT_PLANS_ROUTE,
          component: AccountPlansPage,
          onEnter: requireAuth(() => void 0, auth, router),
        },
        {
          path: ACCOUNT_VIDEOS_ROUTE,
          component: VideoAccountPage,
          onEnter: requireAuth(page.accountVideos.onEnter, auth, router),
        },
        {
          path: '/account/licenses(/:page)',
          component: AccountLicencesPage,
          onEnter: requireAuth(page.accountLicences.onEnter, auth, router),
        },
        {
          path: ACCOUNT_ORDERS_ROUTE,
          component: AccountOrdersPage,
          onEnter: requireAuth(page.accountOrders.onEnter, auth, router),
        },
        // Redirect legacy orders pagination (eg. `/account/orders/2`) to main orders page
        {
          path: '/account/orders/:page',
          component: AccountOrdersPage,
          onEnter: requireAuth((_, replace) => replace({ pathname: ACCOUNT_ORDERS_ROUTE }), auth, router),
        },
        {
          path: '/account/order/:identity',
          component: AccountOrderPage,
          onEnter: requireAuth(page.accountOrder.onEnter, auth, router),
        },
        {
          path: '/account/channels',
          component: () => null,
          onEnter: requireAuth((_, replace) => replace({ pathname: ACCOUNT_VIDEOS_ROUTE }), auth, router),
        },
        {
          path: '/account/channels/:identity',
          component: AccountChannelPage,
          onEnter: requireAuth(page.accountChannel.onEnter, auth, router),
        },
        {
          path: '/account/billing',
          component: AccountBillingPage,
          onEnter: requireAuth(page.accountBilling.onEnter, auth, router),
        },
        {
          path: '/account/communication',
          component: AccountCommunicationPage,
          onEnter: requireAuth(page.accountCommunication.onEnter, auth, router),
        },
        {
          path: '/account/profile',
          component: AccountProfilePage,
          onEnter: requireAuth(page.accountProfile.onEnter, auth, router),
        },
        {
          path: '/account/coupons',
          component: AccountCouponsPage,
          onEnter: requireAuth(page.accountCoupons.onEnter, auth, router),
        },
        {
          path: '/account/community',
          component: AccountInvitesPage,
          onEnter: requireAuth(page.accountInvites.onEnter, auth, router),
        },
      ],
    },
    // Public page for user playlists
    {
      path: `${PUBLIC_PLAYLISTS_ROUTE}/:id`,
      component: ModernContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: UserPlaylistPage,
        onEnter: page.userPlaylists.onEnter,
      },
    },
    {
      path: '/checkout',
      component: CheckoutPageModernContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: CheckoutV2Container,
        onEnter: clientOnly(page.checkout.onEnter),
        onLeave: page.checkout.onLeave,
      },
    },
    {
      path: '/checkout(/:step)',
      component: LegacyPageContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: CheckoutContainer,
        onEnter: clientOnly(page.checkout.onEnter),
        onLeave: page.checkout.onLeave,
      },
    },
    {
      path: '/v2/checkout',
      component: LegacyPageContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: CheckoutV2Container,
        onEnter: (_, replace) => replace({ pathname: '/checkout' }),
      },
    },
    {
      path: SEARCH_ROUTE,
      component: SearchPageModernContainer,
      onEnter: page.master.onEnter,
      indexRoute: {
        component: Search,
        onEnter: clientOnly(page.searchMvp.onEnter),
        onLeave: page.searchMvp.onLeave,
      },
    },
    {
      path: '/browse/collection',
      component: LegacyPageContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: BrowsePage,
        onEnter: (_, replace) => replace({ pathname: BROWSE_ROUTE }),
      },
      childRoutes: [
        {
          path: '/browse/collection/:slug(/:page)',
          component: PlaylistPage,
          onEnter: maybeSkip(page.browse.onEnterTag),
        },
      ],
    },
    {
      path: '/browse/mood(/**)',
      component: ModernContainer,
      onEnter: ({ location: { pathname } }, replace) =>
        replace({ pathname: pathname.replace('/browse/mood', '/browse/moods') }),
    },
    {
      path: '/browse/genre(/**)',
      component: ModernContainer,
      onEnter: ({ location: { pathname } }, replace) =>
        replace({ pathname: pathname.replace('/browse/genre', '/browse/genres') }),
    },
    {
      path: '/browse/theme(/**)',
      component: ModernContainer,
      onEnter: ({ location: { pathname } }, replace) =>
        replace({ pathname: pathname.replace('/browse/theme', '/browse/video-themes') }),
    },
    {
      path: '/browse(/**)',
      component: ModernContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: ProjectDarwinBrowsePage,
        onEnter: maybeSkip(page.browse.onEnterPrismicRoute),
        onChange: (_, nextState, replace) => page.browse.onEnterPrismicRoute(nextState, replace),
      },
    },
    {
      path: '/music/artists/:artist/release/:release',
      component: LegacyPageContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: MusicArtistPage,
        onEnter: passThrough(page.artist.onEnterRelease),
      },
    },
    {
      path: '/music/artists/:artist/track/:track',
      component: LegacyPageContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: TrackPage,
        onEnter: passThrough(page.track.onEnter),
      },
    },
    {
      path: '/music/artists/:artist',
      component: LegacyPageContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: MusicArtistPage,
        onEnter: passThrough(page.artist.onEnter),
      },
    },
    {
      path: '/r(/:user)',
      component: ReferralPageModernContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: Referral,
        onEnter: passThrough(page.channelReferral.onEnter),
      },
    },
    {
      path: '/**',
      serverStatus: 404,
      component: LegacyPageContainer,
      onEnter: page.master.onEnter,
      onLeave: page.master.onLeave,
      onChange: page.master.onLeave,
      indexRoute: {
        component: _404Page,
        onEnter: passThrough(page.master.onEnterCatchAll),
      },
    },
  ];
};
