import * as React from 'react';
import { observer } from 'mobx-react';
import { style } from 'typestyle';
import { percent, px, rem } from 'csx';
import { colorBlack, colorSubtle } from '../theme/color';
import { centerCenter, vertical, verticallySpaced } from 'csstips';
import { CopyBox } from './copy-box';

export interface PageAccountInvitesModel {
  title: string;
  content: any;
  cta: string;
}

export interface PageAccountInvitesProps {
  model: PageAccountInvitesModel;
  referralURL: string;
  i18n?: {
    invites: string;
    empty: string;
    create: string;
  };
}

@observer
export class PageAccountInvites extends React.Component<PageAccountInvitesProps, null> {
  render() {
    const { model, referralURL } = this.props;

    return (
      <div className={PageAccountInvites.styles.container}>
        <div>
          <CopyBox content={referralURL} />
        </div>
      </div>
    );
  }

  static styles = {
    container: style({
      ...vertical,
      ...verticallySpaced(30),
      width: percent(100),
      maxWidth: px(960),
    }),
    coupons: style({
      ...vertical,
      ...verticallySpaced(8),
      marginBottom: rem(2),
    }),
    value: style({
      margin: '0 0 1rem 0',
      $nest: {
        '& strong': {
          fontWeight: 500,
          color: colorBlack.toString(),
        },
      },
    }),
    empty: style({
      ...centerCenter,
      height: px(120),
      fontWeight: 200,
      fontSize: px(18),
      color: colorSubtle.toString(),
    }),
  };
}
