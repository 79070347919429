import { AboutPageModel } from './model';
import { action } from 'mobx';
import { RedirectFunction, RouterState } from 'react-router';
import { PrismicController } from '../../prismic/controller';
import { BugsnagController } from '../../bugsnag/controller';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';

export class AboutPageController {

  constructor(
    private model: AboutPageModel,
    private prismic: PrismicController,
    private bugsnag: BugsnagController,
    private env: EnvModel,
    private ui: UIController,
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    this.model.loading = true;
    const document = 'about';

    await this.ui.setSEO(nextState.location.pathname);

    this.ui.setBreadcrumbs([{
      path: '/about',
      label: 'About'
    }]);

    try {
      const [{ data }] = await Promise.all([
        this.prismic.getSingle(document),
        this.env.ready
      ]);

      // Key (plain) text
      this.model.page_title = data.page_title;
      this.model.page_subtitle = data.page_subtitle;
      this.model.definition_title = data.definition_title;
      this.model.definition_content = data.definition_content;
      this.model.section_1_title = data.section_1_title;
      this.model.section_1_content = data.section_1_content;
      this.model.section_2_title = data.section_2_title;
      this.model.section_2_content = data.section_2_content;
      this.model.section_3_title = data.section_3_title;
      this.model.section_3_content = data.section_3_content;
      this.model.quote_content = data.quote_content;
      this.model.quote_by = data.quote_by;
      this.model.cta_title = data.cta_title;
      this.model.cta_content = data.cta_content;
      this.model.cta_button = data.cta_button;

      // Image todo handle responsive images
      this.model.cover_image = data.cover_image.url;
      this.model.section_1_image = data.section_1_image.url;
      this.model.section_2_image = data.section_2_image.url;
      this.model.section_3_image = data.section_3_image.url;

      // Slice zone
      const team = data.body.find((slice: any) => slice.slice_type === 'team');
      this.model.team_title = team.primary.team_title;
      this.model.team_strap = team.primary.team_strap;
      this.model.team_members = team.items.map((item: any) => ({
        avatar: item.avatar.url,
        name: item.name,
        role: item.role
      }));

    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      this.model.loading = false;
    }

    return;
  };

  @action
  onSignUp = () => {

  }
}