import * as React from 'react';
import { Component } from '../../lib/component';
import { Link } from 'react-router';
import { style } from 'typestyle';
import { px } from 'csx';
import { ArtistSchema } from '../../types/schema';
import { resetAnchorStyles } from '../../theme/reset';
import { colorSubtle, rebrand } from '../../theme/color';

export interface ArtistNamesProps {
  className?: string;
  artists: Array<ArtistSchema>;
  suppressLinks?: boolean;
  onClickLink?(event: React.MouseEvent<any>): void;
  hit?: any;
}

export class ArtistNames extends Component<ArtistNamesProps> {
  render() {
    const { artists, className, suppressLinks = false, hit = null } = this.props;

    const last = artists.length - 1;
    const renderer = suppressLinks ? this.renderText : this.renderLink;

    const links = artists.map(renderer);

    const children = links.reduce((acc: Array<any>, link: any, index: number) => {
      if (index === 0) return [...acc, link];
      const separator = <span key={index}>{index === last ? ' & ' : ', '}</span>;
      return [...acc, separator, link];
    }, []);

    return <span className={className}>{children}</span>;
  }

  renderLink = (artist: ArtistSchema, index: number) => {
    return (
      <Link
        className={ArtistNames.styles.linkArtist}
        key={artist.slug}
        to={`/music/artists/${artist.slug}`}
        onClick={this.props.onClickLink}
      >
        {this.renderHighlight(index, artist.name)}
      </Link>
    );
  };

  renderText = (artist: ArtistSchema, index: number) => {
    return <span key={artist.slug}>{this.renderHighlight(index, artist.name)}</span>;
  };

  renderHighlight = (index: number, name: string) => {
    if (!this.props.hit) return name;
    try {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: this.props.hit._highlightResult.artists[index].name.value.replace(tagRe, 'em'),
          }}
        />
      );
    } catch (e) {
      return name;
    }
  };

  static styles = {
    linkArtist: style({
      fontSize: px(12),
      textDecoration: 'underline',
      color: rebrand.contrast[50].toString(),
      $nest: { ...resetAnchorStyles(rebrand.contrast[50].toString()) },
    }),
  };
}

const tagRe = /(ais-[^>]+)/g;
