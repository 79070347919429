import { renderPrismicRichTextArray } from './props';
import * as React from 'react';
import {
  CatalogueDataArray,
  isPrismicSlice,
  PrismicComplexPlaylistProps,
  PrismicComponentCommonProps,
  PrismicDataDrivenTypes,
  PrismicImage,
  PrismicRichText,
  PrismicSlice,
  PrismicSliceCSSClasses,
  PrismicSlices,
} from './types';
import { copyStyles } from './font';
import { px } from 'csx';
import { style } from 'typestyle';
import { ImageModel } from '../../../modules/image/model';
import { isEmpty } from './objects';
import { PrismicYouTubeVideoTrackCarouselPayload } from '../prismic-slices/PrismicYouTubeVideoTrackCarousel';
import { APIResponse } from '../../../modules/api/types';
import { ArtistSchema, PlaylistGroupPlaylistSchema, TrackSchema } from '../../../types/schema';
import { APIController } from '../../../modules/api/controller';
import { REPLACE_CAROUSELS } from '../../devtools/feature-flags';
import { StorageModel } from '../../../modules/storage/model';
import { EnvModel } from '../../../modules/env/model';
import { PrismicCatalogueCarouselPayload } from '../prismic-slices/PrismicCatalogueCarousel';
import { PrismicCatalogueTilesPayload } from '../prismic-slices/PrismicCatalogueTiles';
import { PrismicTrackPlaylistPayload } from '../prismic-slices/PrismicTrackPlaylist';
import { PrismicPlaylistCarouselPayload } from '../prismic-slices/PrismicPlaylistCarousel';

const textPanelStyles = style(copyStyles, {
  marginTop: px(32),
  $nest: {
    [`&:not(:last-child), .${PrismicSliceCSSClasses.TWO_COLUMN_SLICE} > div > :not(:last-child) &`]: {
      marginBottom: px(32),
    },
  },
});
type TextBlockPayload = { key: React.Key; payload: { primary: { text_block: PrismicRichText } } };
export function createTextPanel<T extends PrismicComponentCommonProps>(
  PrismicComponent: React.SFC<T>
): React.SFC<T & TextBlockPayload> {
  const component: React.SFC<T & TextBlockPayload> = ({
    payload: {
      slice_type,
      primary: { text_block, ...fields },
      items,
    },
    context,
  }) => (
    <div>
      <PrismicComponent payload={{ slice_type, primary: fields, items }} context={context} />
      <div className={textPanelStyles}>{renderPrismicRichTextArray(text_block)}</div>
    </div>
  );
  component.displayName = `${PrismicComponent.displayName}WithTextComponent`;
  return component;
}

export const isImageObject = (obj: any): obj is PrismicImage => {
  if (isEmpty(obj)) return false;
  return 'dimensions' in obj && 'alt' in obj && 'copyright' in obj && 'url' in obj;
};

export const resolveImageUrl = (image: ImageModel) => (value: any) => {
  if (isImageObject(value)) {
    value.url = image.content(value.url, { width: Math.min(value.dimensions.width, 2000) });
  }
};

type SliceWithDataSlug = { type: PrismicDataDrivenTypes; slug: string };
export const fetchSliceData = async (api: APIController, env: EnvModel, slices: PrismicSlice[], userRegion = '') => {
  // Iterate through every slice and pull out any information that requires further data retrieval
  const playlists: SliceWithDataSlug[] = []; // Playlist slugs in slices need to have their associated slug retrieved
  const trackIds: string[] = []; // Track identities in slices need to have track data retrieved
  for (const slice of slices) {
    // Carousels and tile grids are made up of playlists
    if (isPrismicSlice<PrismicTrackPlaylistPayload>(slice, PrismicSlices.TRACK_CAROUSEL)) {
      playlists.push({
        type: PrismicDataDrivenTypes.TRACKS,
        slug: slice.primary.playlist_slug,
      });
    } else if (isPrismicSlice<PrismicPlaylistCarouselPayload>(slice, PrismicSlices.PLAYLIST_CAROUSEL)) {
      playlists.push({
        type: PrismicDataDrivenTypes.PLAYLISTS,
        slug: slice.primary.playlist_slug,
      });
    } else if (isPrismicSlice<PrismicCatalogueCarouselPayload>(slice, PrismicSlices.CATALOGUE_CAROUSEL)) {
      playlists.push({ type: slice.primary.carousel_type, slug: slice.primary.carousel_slug });
    } else if (isPrismicSlice<PrismicCatalogueTilesPayload>(slice, PrismicSlices.CATALOGUE_TILES)) {
      playlists.push({ type: slice.primary.tiles_type, slug: slice.primary.tiles_slug });
    } else if (isPrismicSlice<PrismicYouTubeVideoTrackCarouselPayload>(slice, PrismicSlices.YOUTUBE_TRACK_CAROUSEL)) {
      // YouTube Track Carousels require track information
      for (const video of slice.items) {
        trackIds.push(video.track_id);
      }
    }
  }

  const carousels: { [key: string]: CatalogueDataArray } = {};
  const tracks: { [key: string]: TrackSchema } = {};

  if (StorageModel.getItem(REPLACE_CAROUSELS) === true || (env.isServer && env.appEnv !== 'prod')) {
    // Load the trending-with-creators playlist and We Can Have It All track which are available on test.lickd.io
    const [playlists, artists, trackPlaylist, track] = await Promise.all([
      api.browse.getPlaylistGroup('test-group', 1),
      api.browse.getArtistList('featured-artists'),
      api.browse.getPlaylistTracks('trending-with-creators', 1, undefined, userRegion),
      api.track.getTrack('32ebce4271944783abdfff8ff42ee638', undefined, userRegion),
    ]);
    if (Array.isArray(playlists.playlists)) {
      const key = REPLACE_CAROUSELS + PrismicDataDrivenTypes.PLAYLISTS;
      carousels[key] = playlists.playlists;
    }
    if (Array.isArray(artists.data)) {
      const key = REPLACE_CAROUSELS + PrismicDataDrivenTypes.ARTISTS;
      carousels[key] = artists.data;
    }
    if (Array.isArray(trackPlaylist.data)) {
      const key = REPLACE_CAROUSELS + PrismicDataDrivenTypes.TRACKS;
      carousels[key] = trackPlaylist.data;
    }
    if (!isEmpty(track.data)) {
      tracks[REPLACE_CAROUSELS] = track.data;
    }
  }

  const carouselPromises = Promise.all(
    playlists.map(async (item) => {
      const { slug, type } = item;
      return {
        key: slug,
        data: await api.browse.getTypedPlaylist(
          type,
          slug,
          1,
          36, // Homepage designs can accommodate up to 36 items in one carousel
          userRegion
        ),
      };
    })
  );
  const trackPromises = Promise.all<APIResponse<TrackSchema>>(
    trackIds.map((id) => api.track.getTrack(id, undefined, userRegion))
  );

  const [carouselData, tracksData] = await Promise.all([carouselPromises, trackPromises]);

  for (const { key, data } of carouselData) {
    let playlist;
    if ('playlists' in data && Array.isArray(data.playlists)) {
      playlist = data.playlists;
    } else if ('data' in data && Array.isArray(data.data)) {
      playlist = data.data;
    }

    if (!isEmpty(playlist)) {
      // Add playlist to model here so we can match slug to playlist
      carousels[key] = playlist;
    }
  }

  for (const track of tracksData) {
    if (isEmpty(track.data)) continue;
    tracks[track.data.identity] = track.data;
  }

  return { carousels, tracks };
};
