import React from 'react';
import { classes, style } from 'typestyle';
import { viewHeight } from 'csx';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { Loading } from '../../loading/loading';
import isEmpty from 'lodash/isEmpty';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';

const styles = {
  container: style(
    mediaUpToDesktop({
      flexDirection: 'column-reverse',
      minHeight: '654px',
      minWidth: '375px',
    }),
    mediaDesktop({ height: '93vh' }),
    {
      display: 'flex',
    }
  ),
  loading: style({
    marginTop: viewHeight(25),
    marginBottom: viewHeight(25),
  }),
};

export type LayoutProps = {
  className?: string;
};

export const OnboardVerticalsLayout = inject(deps)(
  observer(
    ({
      children,
      model: {
        user: { channel },
      },
      className,
    }: React.PropsWithChildren<ComponentProps & LayoutProps>) => {
      if (isEmpty(channel)) {
        return (
          <div className={styles.loading}>
            <Loading />
          </div>
        );
      }

      return <div className={classes(styles.container, className)}>{children}</div>;
    }
  )
);
