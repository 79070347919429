import { NestedCSSProperties } from 'typestyle/lib/types';
import { colorWhite, rebrand } from '../../../../theme/color';
import { percent, px } from 'csx';
import { transitionQuickEase } from '../../../../theme/transition';
import { extend } from 'typestyle';
import { mediaDesktop, mediaUpToDesktop } from '../../utilities/mediaQueries';
import { centerCenter, flexRoot } from 'csstips';
import { focusVisible } from '../../utilities/css';
import { linkCurrentPageClassName } from '../Link';

export const currentButton = 'active-button';

export enum ButtonSize {
  XSMALL,
  SMALL,
  MEDIUM,
  LARGE,
  VARIABLE,
}
const xsmallButton = {
  fontSize: px(10),
  minHeight: px(25),
  padding: '2px 4px',
};
const smallButton = {
  fontSize: px(12),
  minHeight: px(32),
  padding: '4px 8px',
};
const mediumButton = {
  minHeight: px(32),
  lineHeight: px(24),
  padding: '4px 8px',
};
const largeButton = {
  minHeight: px(48),
  fontSize: px(20),
  lineHeight: px(28),
  padding: '8px 16px',
};
export const buttonSize: { [key in ButtonSize]: NestedCSSProperties } = {
  [ButtonSize.XSMALL]: xsmallButton,
  [ButtonSize.SMALL]: smallButton,
  [ButtonSize.MEDIUM]: mediumButton,
  [ButtonSize.LARGE]: largeButton,
  [ButtonSize.VARIABLE]: extend(mediaUpToDesktop(smallButton), mediaDesktop(mediumButton)),
};

export const defaultButtonFocusOutline = `2px solid ${rebrand.primary[50].toString()}`;

const commonButtonInnerStyles: NestedCSSProperties = {
  cursor: 'pointer',
  fontWeight: 700,
  textAlign: 'center',
};
const standardButtonStyles: NestedCSSProperties = extend(
  commonButtonInnerStyles,
  {
    background: rebrand.dark1.toString(),
    borderRadius: px(4),
    transition: `box-shadow ${transitionQuickEase}, color ${transitionQuickEase}, background-color ${transitionQuickEase}`,
    $nest: {
      '&:hover': {
        backgroundColor: rebrand.dark4.toString(),
      },
    },
  },
  focusVisible({
    backgroundColor: rebrand.dark3.toString(),
    outline: defaultButtonFocusOutline,
  })
);
export const removeButtonShadow: NestedCSSProperties = extend(
  {
    boxShadow: 'none',
    $nest: {
      '&:hover': {
        boxShadow: 'none',
      },
    },
  },
  focusVisible({
    boxShadow: 'none',
  })
);
export enum ButtonProminence {
  NORMAL,
  PRIMARY,
  SECONDARY,
  OUTLINE_LIGHT,
  HIGHLIGHT,
  OUTLINE,
  INSET,
  NONE,
  DISABLED,
}
export const buttonProminence: { [key in ButtonProminence]: NestedCSSProperties } = {
  [ButtonProminence.NORMAL]: standardButtonStyles,
  [ButtonProminence.PRIMARY]: extend(
    standardButtonStyles,
    {
      backgroundColor: rebrand.primary[50].toString(),
      color: rebrand.contrast[90].toString(),
      $nest: {
        '&:hover': {
          backgroundColor: rebrand.primary[70].toString(),
        },
      },
    },
    focusVisible({
      backgroundColor: rebrand.primary[90].toString(),
      outline: defaultButtonFocusOutline,
      outlineOffset: px(1),
    })
  ),
  [ButtonProminence.SECONDARY]: extend(
    standardButtonStyles,
    {
      backgroundColor: rebrand.neutralOnDark[20].toString(),
      color: rebrand.contrast[50].toString(),
      $nest: {
        '&:hover': {
          backgroundColor: rebrand.neutralOnDark[50].toString(),
        },
      },
    },
    focusVisible({
      backgroundColor: rebrand.neutralOnDark[30].toString(),
      outline: defaultButtonFocusOutline,
    })
  ),
  [ButtonProminence.OUTLINE_LIGHT]: extend(
    standardButtonStyles,
    {
      backgroundColor: rebrand.contrast[10].toString(),
      border: `1px solid ${rebrand.neutralOnDark[20].toString()}`,
      color: rebrand.contrast[50].toString(),
      $nest: {
        '&:hover': {
          backgroundColor: rebrand.neutralOnDark[50].toString(),
        },
      },
    },
    focusVisible({
      backgroundColor: rebrand.neutralOnDark[30].toString(),
      outline: defaultButtonFocusOutline,
    })
  ),
  [ButtonProminence.HIGHLIGHT]: extend(
    standardButtonStyles,
    {
      backgroundColor: rebrand.highlight1.toString(),
      color: rebrand.dark1.toString(),
      $nest: {
        '&:hover': {
          backgroundColor: rebrand.highlight2.toString(),
        },
      },
    },
    focusVisible({
      backgroundColor: rebrand.highlight3.toString(),
      outline: defaultButtonFocusOutline,
    })
  ),
  [ButtonProminence.OUTLINE]: extend(
    commonButtonInnerStyles,
    {
      background: rebrand.contrast[60].toString(),
      border: '1px solid',
      borderRadius: px(4),
      borderColor: rebrand.neutralOnDark[70].toString(),
      color: rebrand.contrast[10].toString(),
      $nest: {
        '&:hover': {
          borderColor: rebrand.contrast[40].toString(),
          backgroundColor: rebrand.contrast[70].toString(),
        },
        [`&.${linkCurrentPageClassName}, &.${currentButton}`]: {
          backgroundColor: rebrand.contrast[90].toString(),
          borderColor: rebrand.contrast[90].toString(),
        },
      },
    },
    focusVisible({
      borderColor: rebrand.contrast[40].toString(),
      backgroundColor: rebrand.contrast[70].toString(),
      outline: defaultButtonFocusOutline,
    })
  ),
  [ButtonProminence.INSET]: extend(
    commonButtonInnerStyles,
    {
      background: rebrand.contrast[50].toString(),
      borderRadius: px(4),
      color: rebrand.contrast[10].toString(),
      $nest: {
        '&:hover': {
          backgroundColor: rebrand.contrast[60].toString(),
        },
        [`&.${linkCurrentPageClassName}, &.${currentButton}`]: {
          backgroundColor: rebrand.contrast[90].toString(),
        },
      },
    },
    focusVisible({
      backgroundColor: rebrand.contrast[60].toString(),
      outline: defaultButtonFocusOutline,
    })
  ),
  [ButtonProminence.NONE]: {
    background: 'transparent',
    paddingLeft: px(8),
    paddingRight: px(8),
  },
  [ButtonProminence.DISABLED]: {
    ...standardButtonStyles, // Spread instead of extend so we overwrite the nested styles
    backgroundColor: rebrand.neutralOnDark[20].toString(),
    color: rebrand.neutralOnDark[50].toString(),
    $nest: {
      '&:hover': {
        cursor: 'not-allowed',
      },
    },
  },
};

const standardHoverStates: NestedCSSProperties = extend(
  {
    $nest: {
      '&:hover': {
        color: colorWhite.toString(),
      },
      '& .badge': {
        color: colorWhite.toString(),
      },
      '& svg:not(.badge) path': {
        transition: `fill ${transitionQuickEase}`,
      },
      '&:hover svg:not(.badge) path': {
        fill: colorWhite.toString(),
      },
    },
  },
  focusVisible({
    color: colorWhite.toString(),
    $nest: {
      '& svg:not(.badge) path': {
        fill: colorWhite.toString(),
      },
    },
  })
);

export const childHoverStates: { [key in ButtonProminence]: NestedCSSProperties } = {
  [ButtonProminence.NORMAL]: standardHoverStates,
  [ButtonProminence.PRIMARY]: extend(
    standardHoverStates,
    {
      $nest: {
        '&:hover': {
          color: rebrand.contrast[90].toString(),
        },
        '& svg:not(.badge) path': {
          fill: rebrand.contrast[90].toString(),
        },
        '&:hover svg:not(.badge) path': {
          fill: rebrand.contrast[90].toString(),
        },
      },
    },
    focusVisible({
      color: rebrand.contrast[90].toString(),
      $nest: {
        '& svg:not(.badge) path': {
          fill: rebrand.contrast[90].toString(),
        },
      },
    })
  ),
  [ButtonProminence.SECONDARY]: extend(
    standardHoverStates,
    {
      $nest: {
        '&:hover': {
          color: rebrand.dark1.toString(),
        },
        '& svg:not(.badge) path': {
          fill: rebrand.dark1.toString(),
        },
        '&:hover svg:not(.badge) path': {
          fill: rebrand.dark1.toString(),
        },
      },
    },
    focusVisible({
      color: rebrand.dark1.toString(),
      $nest: {
        '& svg:not(.badge) path': {
          fill: rebrand.dark1.toString(),
        },
      },
    })
  ),
  [ButtonProminence.OUTLINE_LIGHT]: extend(
    standardHoverStates,
    {
      $nest: {
        '&:hover': {
          color: rebrand.dark1.toString(),
        },
        '& svg:not(.badge) path': {
          fill: rebrand.dark1.toString(),
        },
        '&:hover svg:not(.badge) path': {
          fill: rebrand.dark1.toString(),
        },
      },
    },
    focusVisible({
      color: rebrand.dark1.toString(),
      $nest: {
        '& svg:not(.badge) path': {
          fill: rebrand.dark1.toString(),
        },
      },
    })
  ),
  [ButtonProminence.HIGHLIGHT]: extend(
    standardHoverStates,
    {
      $nest: {
        '&:hover': {
          color: rebrand.dark1.toString(),
        },
        '& svg:not(.badge) path': {
          fill: rebrand.dark1.toString(),
        },
        '&:hover svg:not(.badge) path': {
          fill: rebrand.dark1.toString(),
        },
      },
    },
    focusVisible({
      color: rebrand.dark1.toString(),
      $nest: {
        '& svg:not(.badge) path': {
          fill: rebrand.dark1.toString(),
        },
      },
    })
  ),
  [ButtonProminence.OUTLINE]: extend(
    {
      $nest: {
        '&:hover': {
          color: rebrand.contrast[10].toString(),
        },
      },
    },
    focusVisible({
      color: rebrand.contrast[10].toString(),
    })
  ),
  [ButtonProminence.INSET]: extend(
    {
      $nest: {
        '&:hover': {
          color: rebrand.contrast[10].toString(),
        },
      },
    },
    focusVisible({
      color: rebrand.contrast[10].toString(),
    })
  ),
  [ButtonProminence.NONE]: extend(
    {
      $nest: {
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    focusVisible({
      textDecoration: 'underline',
    })
  ),
  [ButtonProminence.DISABLED]: extend(
    {
      $nest: {
        '& .badge': {
          color: 'white',
        },
        '& svg:not(.badge, .checkout-coupon-btn-icon) path': {
          fill: rebrand.light2.toString(),
        },
        '&:hover svg:not(.badge, .checkout-coupon-btn-icon) path': {
          fill: rebrand.light2.toString(),
        },
      },
    },
    focusVisible({
      $nest: {
        '& svg:not(.badge, .checkout-coupon-btn-icon) path': {
          fill: rebrand.light2.toString(),
        },
      },
    })
  ),
};

export const circleButton: NestedCSSProperties = extend(flexRoot, centerCenter, smallButton, {
  padding: px(4),
  width: px(32),
  borderRadius: percent(100),
});
