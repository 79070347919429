import React from 'react';
import { classes, style } from 'typestyle';
import { rebrand } from '../../../theme/color';
import { px } from 'csx';
import { SVGIcon } from '../../svg-icon';
import { flexRoot } from 'csstips';

type Theme = {
  backgroundColor: string;
  textColor: string;
  iconColor: string;
  strongTextColor: string;
};

const defaultTheme: Theme = {
  backgroundColor: rebrand.contrast[60].toString(),
  textColor: rebrand.contrast[40].toString(),
  iconColor: rebrand.contrast[10].toHexString(),
  strongTextColor: rebrand.contrast[10].toHexString(),
};

const dynamicStyles = (theme: Theme) => ({
  info: style(flexRoot, {
    backgroundColor: theme.backgroundColor,
    borderRadius: px(8),
    padding: px(16),
    fontSize: px(11),
  }),
  infoIcon: style({
    flexShrink: 0,
    marginRight: px(8),
  }),
  infoText: style({
    color: theme.textColor,
    textAlign: 'left',
    margin: 0,
    $nest: {
      '& strong': {
        color: theme.strongTextColor,
      },
    },
  }),
});

type ConnectChannelInfoblockProps = {
  className?: string;
  theme?: Theme;
};

export const ConnectChannelInfoblock: React.FC<ConnectChannelInfoblockProps> = ({
  className,
  theme = defaultTheme,
}) => {
  const styles = dynamicStyles(theme);

  return (
    <div className={classes(styles.info, className)}>
      <SVGIcon.InfoCircle className={styles.infoIcon} size={20} color={theme.iconColor} />
      <p className={styles.infoText}>
        <strong>Why do I need to connect my YouTube channel?</strong> Lickd connects to your YouTube channel so we can
        clear claims quickly for you and give you your unique price for Premium tracks.
      </p>
    </div>
  );
};
