import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const CheckCircle: React.SFC<SVGIconProps> = ({
  size = 24,
  color = '#2E2E3B',
  checkColor = '#e50f7e',
  ...props
}) => (
  <BaseSvg data-test-check-circle width={size} height={size} viewBox="0 2 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Z" fill={color} />
      <path
        d="M3.39 6.416.861 3.887 0 4.742l3.39 3.39L10.667.855 9.812 0z"
        fill={checkColor}
        fillRule="nonzero"
        transform="translate(6.667 7.934)"
      />
    </g>
  </BaseSvg>
);
