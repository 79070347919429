import * as React from 'react';
import { Strapline } from './atoms/Strapline';
import { ReactNode, ReactNodeArray } from 'react';
import { CtaButton } from './atoms/CtaButton';
import { BasePanel, BasePanelProps } from './BasePanel';
import { CoverFlow } from './atoms/CoverFlow';
import { Heading } from './atoms/Heading';
import { PanelPromoBadge } from './atoms/PanelPromoBadge';
import { PromoText } from './atoms/PromoText';
import { FrostedBanner } from './atoms/FrostedBanner';
import { classes, style } from 'typestyle';
import { px } from 'csx';

const styles = {
  headingBadgePadding: style({
    marginRight: px(160),
  }),
};

export type PromoPanelProps = React.Component['props'] & {
  className?: string;
  src?: string;
  gradient?: BasePanelProps['gradient'];
  heading?: ReactNode;
  badge?: ReactNodeArray;
  promo?: ReactNodeArray;
  banner?: ReactNode;
  cta?: { href: string; text: string };
};

export const PromoPanel: React.SFC<PromoPanelProps> = ({
  children,
  className,
  src,
  gradient,
  heading,
  badge,
  promo,
  cta,
  banner,
}) => (
  <BasePanel gradient={gradient} src={src}>
    {heading && <Heading className={classes(badge && styles.headingBadgePadding)}>{heading}</Heading>}
    {badge && <PanelPromoBadge>{badge}</PanelPromoBadge>}
    {promo && <PromoText>{promo}</PromoText>}
    {cta && <CtaButton href={cta.href}>{cta.text}</CtaButton>}
    {banner && <FrostedBanner>Coming soon</FrostedBanner>}
  </BasePanel>
);
