import * as React from 'react';
import { cssRule, style } from 'typestyle';
import { percent, px } from 'csx';
import { colorGunmetal } from '../../../theme/color';
import { headerBorder, headerFullHeight } from '../config';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { zAppHeaderContainer } from '../../../theme/z';
import { LickdRebrandLogo } from '../atoms/icons/brands/LickdRebrandLogo';
import { ButtonProminence } from '../atoms/button/buttonStyles';
import { Button } from '../atoms/button/Button';

cssRule('html body', {
  marginTop: px(headerFullHeight),
});

const styles = {
  header: style({
    position: 'fixed',
    backgroundColor: colorGunmetal.toString(),
    top: 0,
    left: 0,
    right: 0,
    width: percent(100),
    height: px(headerFullHeight),
    zIndex: zAppHeaderContainer,
    $nest: {
      '&::after': {
        content: '""',
        position: 'absolute',
        width: percent(100),
        height: px(headerBorder),
        bottom: 0,
        left: 0,
        right: 0,
      },
      '& .button': mediaDesktop({
        minHeight: px(32),
        paddingTop: px(5),
        paddingBottom: px(5),
      }),
    },
  }),
  wrapper: style(
    {
      height: percent(100),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    mediaUpToDesktop({
      marginLeft: px(16),
      marginRight: px(16),
    }),
    mediaDesktop({
      margin: '0 auto',
      width: px(1024),
    })
  ),
};

export const HeaderBannerMinimal: React.FC = () => (
  <header className={styles.header}>
    <div className={styles.wrapper}>
      <Button href="/" aria-label="Lickd" prominence={ButtonProminence.NONE}>
        <LickdRebrandLogo />
      </Button>
    </div>
  </header>
);
