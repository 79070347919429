import React from 'react';
import { OrderSchema } from '../../../types/schema';
import { style } from 'typestyle';
import { mediaSmallTablet, mediaUpToSmallTablet } from '../utilities/mediaQueries';
import { spacing } from '../../../theme/spacing';
import { rebrand } from '../../../theme/color';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { px } from 'csx';
import { Loading } from '../../loading/loading';
import { ArrowDownward } from '../atoms/icons/ArrowDownward';
import { screenReaderOnly } from '../utilities/font';
import { fontstackDefault } from '../config';
import { OrdersListItem } from '../molecules/OrdersListItem';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { LoadMoreArrowButton } from '../atoms/button/LoadMoreArrow';

interface OrdersListProps {
  orders: OrderSchema[];
}

const gridColumns = '110px 1fr 136px 100px';

const styles = {
  hideUntilSmallTablet: style(
    mediaUpToSmallTablet({
      display: 'none',
    })
  ),
  mobileHeading: style(
    {
      fontFamily: fontstackDefault,
      fontSize: px(16),
      fontWeight: 700,
      margin: `0 0 ${spacing.XSMALL}`,
    },
    mediaSmallTablet(screenReaderOnly)
  ),
  listHeader: style(
    {
      fontWeight: 700,
    },
    mediaSmallTablet({
      display: 'grid',
      padding: `0 ${spacing.DEFAULT}`,
      gridTemplateColumns: gridColumns,
      gap: spacing.DEFAULT,
    })
  ),
  list: style({
    padding: 0,
    margin: `${spacing.DEFAULT} 0 0`,
    listStyle: 'none',
  }),
  dateSelector: style({
    display: 'flex',
    alignItems: 'center',
    color: rebrand.contrast[50].toString(),
    fontWeight: 700,
    fontSize: px(16),
    padding: 0,
  }),
  paginationContainer: style({
    display: 'flex',
    gap: spacing.DEFAULT,
    marginTop: spacing.DEFAULT,
    padding: `${spacing.DEFAULT} 0`,
  }),
  orderCount: style({
    fontSize: '12px',
    margin: '0 0 0 auto',
    color: rebrand.neutralOnLight[70].toString(),
  }),
};

export const OrdersList = inject(deps)(
  observer(({ orders, model, controller }: OrdersListProps & ComponentProps) => {
    const { loading, loadingMore, dateOrder, isDownloadingInvoice, pagination } = model.page.accountOrders;
    const { downloadInvoice, changeDateOrder, loadMoreOrders } = controller.page.accountOrders;

    const showLoadMore = pagination && orders.length < pagination.total;

    return (
      <div>
        <div className={styles.listHeader}>
          <div>
            <Button
              className={styles.dateSelector}
              size={ButtonSize.XSMALL}
              onClick={changeDateOrder}
              prominence={ButtonProminence.NONE}
              aria-label={`Show ${dateOrder === 'ASC' ? 'newest' : 'oldest'} orders first`}
              data-test-license-date-order
            >
              Date
              <ArrowDownward
                color={rebrand.neutralOnLight[70].toString()}
                style={dateOrder === 'ASC' ? { transform: 'rotate(180deg' } : null}
              />
            </Button>
          </div>
          <div className={styles.hideUntilSmallTablet} aria-hidden="true">
            Licenses
          </div>
          <div className={styles.hideUntilSmallTablet} aria-hidden="true">
            Total paid
          </div>
          <div className={styles.hideUntilSmallTablet} aria-hidden="true">
            Actions
          </div>
        </div>

        {loading ? (
          <Loading />
        ) : orders.length > 0 ? (
          <ul className={styles.list}>
            {orders.map((order) => (
              <OrdersListItem
                key={order.identity}
                order={order}
                onDownload={downloadInvoice}
                isDownloading={isDownloadingInvoice}
                gridColumns={gridColumns}
              />
            ))}
          </ul>
        ) : (
          <p>There are no orders for this channel</p>
        )}

        {!loading && orders.length > 0 && pagination ? (
          <div className={styles.paginationContainer}>
            {showLoadMore && (
              <LoadMoreArrowButton hidden={loading} loading={loadingMore} handleLoadMore={loadMoreOrders} />
            )}

            <span className={styles.orderCount}>
              Showing {orders.length} of {pagination.total}
            </span>
          </div>
        ) : null}
      </div>
    );
  })
);
