import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const Clipboard: React.SFC<SVGIconProps> = ({ size = 18, isSquare, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={isSquare ? size : size * (18 / 16)} viewBox="0 0 16 18" {...props}>
    <path
      d="M16 2h-4.18C11.4.84 10.3 0 9 0 7.7 0 6.6.84 6.18 2H2C.9 2 0 2.9 0 4v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2ZM9 2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1Zm2 14H4v-2h7v2Zm3-4H4v-2h10v2Zm0-4H4V6h10v2Z"
      fill={color}
      transform="translate(-2 -2)"
      fillRule="evenodd"
    />
  </BaseSvg>
);
