import { observable } from 'mobx';
import { PaginationMeta } from '../../../types';
import { YouTubeVideo } from '../../../types/youtube';
import { APIError } from '../../api/types';
import { ChannelSchema } from '../../../types/schema';

export class AccountVideosPageModel {
  @observable
  loading = false;

  @observable
  refreshingVideos = false;

  @observable
  videos: Array<YouTubeVideo> = [];

  @observable
  visible = 'all';

  @observable
  pagination: PaginationMeta;

  @observable
  page = 1;

  @observable
  refreshSuccess = false;

  @observable
  rateLimitHit = false;

  @observable
  unknownError = false;

  @observable
  channelErrors: Array<{ channel: ChannelSchema; error: APIError<any> }> = [];
}
