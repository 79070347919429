import { observable, computed, ObservableMap } from 'mobx';
import { ArtistSchema, PlaylistGroupPlaylistSchema, TrackSchema } from '../../../types/schema';
import { PrismicSlice, SlicePageContext, SubscriptionPlans } from '../../../components/project-happy/utilities/types';

export enum RegistrationSteps {
  ACCOUNT_INFORMATION = 'details',
  PLANS = 'plans',
  COMPLETE = 'complete',
}

export const RegistrationStepValues = Object.values(RegistrationSteps);

export class RegistrationPageModel {
  @observable step: RegistrationSteps = null;

  @observable fullName = '';

  @observable nickname = '';

  @observable country = '';

  @observable terms = false;

  @observable plan: SubscriptionPlans | null = null;

  @computed
  get stepIndex(): number {
    // Step 0 is used for the sign up page which is not part of this flow
    return RegistrationStepValues.indexOf(this.step) + 1;
  }

  @computed
  get fullNameValid(): boolean {
    return this.fullName.length > 0;
  }

  @computed
  get nicknameValid(): boolean {
    return this.nickname.length > 0;
  }

  @computed
  get countryValid(): boolean {
    return this.country.length === 2;
  }

  @computed
  get termsValid(): boolean {
    return this.terms === true;
  }

  @computed
  get accountInformationValid(): boolean {
    return this.fullNameValid && this.nicknameValid && this.countryValid && this.termsValid;
  }
}
