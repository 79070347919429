import { rem } from 'csx';

export const spacing = {
  XXSMALL: rem(0.25), // 4px
  XSMALL: rem(0.5), // 8px
  SMALL: rem(0.75), // 12px
  DEFAULT: rem(1), // 16px
  LARGE: rem(1.5), // 24px
  XLARGE: rem(2), // 32px
  XXLARGE: rem(3), // 48px
  XXXLARGE: rem(4), // 64px
};
