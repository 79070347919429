import {ArticlesPageModel} from './model';
import { action } from 'mobx';
import { RedirectFunction, RouterState } from 'react-router';
import {stripQueryString} from '../../../lib/string';

export class ArticlesPageController {
  constructor(
    private model: ArticlesPageModel,
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    this.model.category = stripQueryString(nextState.params['category']) || null;
    this.model.slug = stripQueryString(nextState.params['slug']) || null;

    const page = parseInt(nextState.location.query['page']) || 1;
    this.model.from = this.model.size * Math.max(page - 1, 0);
  };
}