import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const DashCircle: React.SFC<SVGIconProps> = ({ size = 10, color = '#DA2699', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 10 10" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <circle cx="5" cy="5" r="5" fill="#DA2699" />
    <g clipPath="url(#clip0_1248_9607)">
      <rect x="1.6665" y="4.58325" width="6.66667" height="0.833333" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_1248_9607">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </BaseSvg>
);
