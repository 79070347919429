import { PrismicBasePanelProps, PrismicYouTubeChannelProps, PrismicImage } from '../utilities/types';
import { CreatorPanel, CreatorPanelStat } from '../molecules/panels/CreatorPanel';
import { isEmpty } from '../utilities/objects';
import * as React from 'react';
import { createTextPanel } from '../utilities/prismic';

export type PrismicCreatorStatPanelPayload = PrismicBasePanelProps &
  PrismicYouTubeChannelProps & {
    primary: {
      channel_badge_position: number;
      strap_line_1?: string;
      strap_line_2?: string;
    };
    items: CreatorPanelStat[];
  };
export type PrismicCreatorStatPanelProps = {
  payload: PrismicCreatorStatPanelPayload;
};
export const PrismicCreatorStatPanel: React.SFC<PrismicCreatorStatPanelProps> = ({
  payload: {
    primary: {
      channel_name,
      channel_subscribers,
      channel_profile_image,
      channel_badge_position,
      strap_line_1,
      strap_line_2,
      call_to_action_url,
      call_to_action_text,
      background_image,
      background_colour,
      background_gradient_colour_end,
      background_gradient_colour_direction,
    },
    items,
  },
}) => (
  <CreatorPanel
    src={!isEmpty(background_image) && background_image.url}
    gradient={
      background_colour && {
        to: background_colour,
        from: background_gradient_colour_end,
        direction: background_gradient_colour_direction,
      }
    }
    strapline={(strap_line_1 || strap_line_2) && [strap_line_1, strap_line_2]}
    channelBadge={
      channel_name && {
        channelImg: !isEmpty(channel_profile_image) && channel_profile_image.url,
        channelName: channel_name,
        channelSubscribers: channel_subscribers,
        xPosition: typeof channel_badge_position === 'number' ? channel_badge_position : 40,
      }
    }
    statistics={items}
    cta={
      call_to_action_text && {
        href: !isEmpty(call_to_action_url) && call_to_action_url.url,
        text: call_to_action_text,
      }
    }
  />
);
PrismicCreatorStatPanel.displayName = 'PrismicCreatorStatPanel';
export const PrismicCreatorStatPanelWithText = createTextPanel(PrismicCreatorStatPanel);
