import * as React from 'react';
import { transitionSlideFromBottom } from '../../theme/transition';
import overlayStyles from '../../styles/scrollable-overlay';
import { CSSTransition } from 'react-transition-group';
import { EnvModel } from '../../modules/env/model';
import * as ReactDOM from 'react-dom';
import { classes } from 'typestyle';
import { CircleButton } from '../project-happy/atoms/button/CircleButton';
import { CloseCross } from '../project-happy/atoms/icons/CloseCross';
import { ButtonProminence } from '../project-happy/atoms/button/buttonStyles';

export interface ScrollableOverlayProps {
  show: boolean;
  onRequestClose(): void;
  onVisibilityChange?: (shown: boolean) => void;
  className?: string;
}

export class ScrollableOverlay extends React.Component<ScrollableOverlayProps, never> {
  static styles = overlayStyles;

  private container: HTMLDivElement;

  constructor(props) {
    super(props);
    this.handleBackgroundClick = this.handleBackgroundClick.bind(this);
  }

  componentDidMount() {
    this.toggleBodyScroll();
  }

  componentDidUpdate(prevProps: Readonly<ScrollableOverlayProps>) {
    if (prevProps.show === this.props.show) return;
    this.toggleBodyScroll();
  }

  toggleBodyScroll() {
    if (this.props.show) {
      document.body.style.overflow = 'hidden';
      return;
    }
    document.body.style.overflow = null;
  }

  render() {
    const { show, children, onRequestClose, onVisibilityChange, className } = this.props;

    // We render modals in portals and portals can't be rendered (easily) on the server
    if (EnvModel.isServer) return null;

    return ReactDOM.createPortal(
      <CSSTransition
        in={show}
        {...transitionSlideFromBottom}
        onEnter={() => onVisibilityChange && onVisibilityChange(true)}
        onExited={() => onVisibilityChange && onVisibilityChange(false)}
      >
        <div
          className={overlayStyles.container}
          onClick={this.handleBackgroundClick}
          ref={(el) => (this.container = el)}
        >
          <section data-test-modal className={classes(overlayStyles.wrapper, className)}>
            <CircleButton
              onClick={onRequestClose}
              className={overlayStyles.closeButton}
              prominence={ButtonProminence.SECONDARY}
              aria-label="Close modal"
              data-test-close-modal
            >
              <CloseCross />
            </CircleButton>
            {children}
          </section>
        </div>
      </CSSTransition>,
      document.getElementById('react-portals-root')
    );
  }

  handleBackgroundClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!this.container || !this.props.show) return;
    const target = event.target as Node;
    if (this.container !== target) return;
    event.stopPropagation();
    this.props.onRequestClose();
  };
}
