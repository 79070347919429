import { YouTubePageModel } from './model';
import { action } from 'mobx';
import { RedirectFunction, RouterState } from 'react-router';
import { PrismicController } from '../../prismic/controller';
import { BugsnagController } from '../../bugsnag/controller';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';

export class YouTubePageController {

  constructor(
    private model: YouTubePageModel,
    private prismic: PrismicController,
    private bugsnag: BugsnagController,
    private env: EnvModel,
    private ui: UIController,
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    this.model.loading = true;
    const document = 'youtube';

    await this.ui.setSEO(nextState.location.pathname);

    this.ui.setBreadcrumbs([{
      path: '/youtube',
      label: 'YouTube'
    }]);

    try {
      const [{ data }] = await Promise.all([
        this.prismic.getSingle(document),
        this.env.ready
      ]);

      // Key (plain) text
      this.model.page_title = data.page_title;
      this.model.page_subtitle = data.page_subtitle;
      this.model.section_1_title = data.section_1_title;
      this.model.section_1_content = data.section_1_content;
      this.model.section_2_title = data.section_2_title;
      this.model.section_2_content = data.section_2_content;
      this.model.section_3_title = data.section_3_title;
      this.model.section_3_content = data.section_3_content;
      this.model.section_4_title = data.section_4_title;
      this.model.section_4_content = data.section_4_content;
      this.model.cta_title = data.cta_title;
      this.model.cta_content = data.cta_content;
      this.model.cta_button = data.cta_button;

      // Image todo handle responsive images
      this.model.cover_image = data.cover_image.url;
      this.model.section_1_image = data.section_1_image.url;
      this.model.section_2_image = data.section_2_image.url;

      // Video
      this.model.section_3_video = encodeURIComponent(data.section_3_video.html);
      this.model.section_4_video = encodeURIComponent(data.section_4_video.html);

      // Slice zone
      const testimonials = data.body.find((slice: any) => slice.slice_type === 'testimonials');
      this.model.testimonials_title = testimonials.primary.title;
      this.model.testimonials_strap = testimonials.primary.strap;
      this.model.testimonials = testimonials.items.map((item: any) => ({
        channel_name: item.channel_name,
        channel_id: item.channel_id,
        quote: item.quote,
        video: encodeURIComponent(item.video.html)
      }));

    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      this.model.loading = false;
    }

    return;
  };

  @action
  onSignUp = () => {

  }
}