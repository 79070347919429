import { BasketModel } from '../basket/model';
import {
  AddressSchema,
  CardSchema,
  ChannelSchema,
  CreditSchema,
  LicenceSchema,
  OrderSchema,
  TrackSchema,
} from '../../types/schema';
import { computed, observable, ObservableMap } from 'mobx';
import { AuthModel } from '../auth/model';
import { CouponModel } from '../coupon/model';
import { getTrackPrice, replaceValues } from '../../lib/helpers';
import { ContentModel } from '../content/model';
import { PageCheckoutAddCardModel } from '../../components/page-checkout-add-card';
import { createCheckoutCardForm, createCheckoutCouponForm, createVatNumberForm } from '../../lib/form';
import { PageCheckoutVatNumberModel } from '../../components/page-checkout-vat-number';
import { PageCheckoutCouponCodeModel } from '../../components/page-checkout-coupon-code';
import { PageCheckoutPanelsModel } from '../../components/page-checkout-panels';
import { formatCurrency, formatNonDecimalCurrency } from '../../lib/currency';
import { EssentialsSubscriptionUrlPayload } from '../../components/project-happy/utilities/types';
import { LOAD_STATE } from '../../types/api';
import { UserModel } from '../user/model';
import { isEmpty } from '../../components/project-happy/utilities/objects';

export class CheckoutModel implements PageCheckoutPanelsModel {
  constructor(
    private basket: BasketModel,
    private auth: AuthModel,
    private user: UserModel,
    private couponModel: CouponModel,
    private content: ContentModel
  ) {}

  @computed get basketTracks(): Array<TrackSchema> {
    return this.basket.tracks || [];
  }

  @observable claimsManagedRead = false;

  @observable claimsFreeRead = false;

  @observable inProgress = false;

  @observable globalError: string;

  @computed get maxStep(): number {
    return 3;
    // switch (true) {
    //   case (this.order && this.order.status === 'paid'):
    //     return 0;
    //
    //   case (!!this.order):
    //     return 2;
    //
    //   default: return 1;
    // }
  }

  @observable
  step = 3;

  @computed get currency(): string {
    if (!this.channel) return 'USD';
    return this.order ? this.order.locale.currency : this.channel.ratecard.currency;
  }

  @observable
  loading = false;

  @computed get channel(): ChannelSchema {
    return this.basket.channel;
  }

  @computed get channelId(): string {
    return this.channel ? this.channel.id : null;
  }

  @computed get userId(): string {
    return this.auth.user ? this.auth.user.identity : null;
  }

  @computed get basketId(): string {
    return this.basket.identity;
  }

  @computed get tracks(): Array<TrackSchema> {
    return this.order && this.step > 1
      ? this.order.licences.map(({ track, brand_sponsored, branded_content }) => ({
          ...track,
          basket: {
            quantity: 1,
            brand_sponsored,
            branded_content,
          },
        }))
      : this.basketTracks;
  }

  @computed
  get stockTracks(): Array<TrackSchema> {
    return this.tracks.filter((track) => track.is_stock_music);
  }

  @computed
  get commercialTracks(): Array<TrackSchema> {
    return this.tracks.filter((track) => !track.is_stock_music);
  }

  @computed
  get claimsFreeTracks(): Array<TrackSchema> {
    return this.tracks.filter((track) => track.reporting_party.slug !== 'umg');
  }

  @computed
  get claimsManagedTracks(): Array<TrackSchema> {
    return this.tracks.filter((track) => track.reporting_party.slug === 'umg');
  }

  @computed get licences(): Array<LicenceSchema> {
    return this.order ? this.order.licences : [];
  }

  // Total price of basket
  @computed get total(): string {
    return (this.order ? Number(this.order.amount.total) : Number(this.netTotal) - this.discountAmount).toFixed(2);
  }

  @computed get netTotal(): string {
    if (!this.channel) return '0.00';

    return this.basket.total.toFixed(2);
  }

  // Price per track
  @computed get price(): number {
    if (this.order && this.order.licences.length) {
      return this.order.licences[0].amount;
    }

    if (!this.channel) return 8;

    switch (this.couponType) {
      case 'first_use':
        return 0;

      case 'internal':
      case 'second_purchase':
      case 'support_coupon':
        return parseInt(this.channel.ratecard.value) * ((100 - this.discount) / 100);

      default:
        return parseInt(this.channel.ratecard.value);
    }
  }

  @computed get priceBrandSponsored(): number {
    return 80;
  }

  @computed get prices(): Array<number> {
    if (!this.channel) return [];
    if (this.order) return this.order.licences.map((l) => l.amount);

    return this.basketTracks.map((track) => getTrackPrice(track, this.basket.ratecard));
  }

  @computed
  get unsubscribedCost(): number {
    const {
      user: { unsubscribedRatecard },
      commercialTracks,
    } = this;
    if (isEmpty(unsubscribedRatecard)) return 0;
    return commercialTracks.length * unsubscribedRatecard.rate;
  }

  @computed
  get unsubscribedCostFormatted(): string {
    const {
      user: { unsubscribedRatecard },
      unsubscribedCost,
    } = this;
    if (!isEmpty(unsubscribedRatecard)) {
      const formatter = Math.abs(unsubscribedCost) === unsubscribedCost ? formatNonDecimalCurrency : formatCurrency;
      return formatter(unsubscribedCost, unsubscribedRatecard.currency);
    }
  }

  @computed
  get subscribedCost(): number {
    const {
      user: { subscribedRatecard },
      commercialTracks,
    } = this;
    if (isEmpty(subscribedRatecard)) return 0;
    return commercialTracks.length * subscribedRatecard.rate;
  }

  @computed
  get subscribedCostFormatted(): string {
    const {
      user: { subscribedRatecard },
      subscribedCost,
    } = this;
    if (!isEmpty(subscribedRatecard)) {
      const formatter = Math.abs(subscribedCost) === subscribedCost ? formatNonDecimalCurrency : formatCurrency;
      return formatter(subscribedCost, subscribedRatecard.currency);
    }
  }

  @computed
  get subscriptionSavings(): string | null {
    const {
      user: { unsubscribedRatecard, subscribedRatecard },
      subscribedCost,
      unsubscribedCost,
    } = this;
    if (isEmpty(unsubscribedRatecard) || isEmpty(subscribedRatecard)) return null;
    const difference = unsubscribedCost - subscribedCost;
    const formatter = Math.abs(difference) === difference ? formatNonDecimalCurrency : formatCurrency;
    return formatter(difference, unsubscribedRatecard.currency);
  }

  // Price band string e.g. 50k
  @computed get band(): string {
    return this.channel.ratecard.name;
  }

  @computed
  get expanded(): boolean {
    return this.step === 1;
  }

  @observable
  cards: Array<CardSchema> = [];

  @observable
  selectedCard: string = null;

  @computed get card(): CardSchema {
    return this.selectedCard ? this.cards.find((c) => c.identity === this.selectedCard) : null;
  }

  @observable
  addresses: Array<AddressSchema> = [];

  @observable
  address: AddressSchema = void 0;

  @observable
  addressError: string = null;

  @computed get addressParity(): boolean {
    if (!this.card || !this.address) return false;

    return !Object.keys(this.address).find((k) => this.address[k] !== this.card.address[k]);
  }

  @observable
  order: OrderSchema = null;

  @observable
  orderState: LOAD_STATE = LOAD_STATE.EMPTY;

  @computed get needsSubscription(): boolean {
    const { isSubscribed } = this.user;
    const needsSubscription = this.basket.tracks.some((track) => track.is_stock_music);
    return needsSubscription && !isSubscribed;
  }

  @computed get ready(): boolean {
    return isEmpty(this.user.user) || (this.orderState === LOAD_STATE.READY && !this.needsSubscription);
  }

  @computed get orderId(): string {
    return this.order ? this.order.identity : null;
  }

  @observable
  showAddCard = false;

  @observable
  addCardForm: PageCheckoutAddCardModel = createCheckoutCardForm();

  @observable
  vatNumber = '';

  get defaultVatNumber() {
    return (
      this.vatNumber ||
      (this.auth.user && this.auth.user.vat.number
        ? (this.auth.user.vat.country || '') + (this.auth.user.vat.number || '')
        : '')
    );
  }

  vatNumberForm: PageCheckoutVatNumberModel = createVatNumberForm();

  @computed
  get vatNumberChanged() {
    if (!this.auth.user) return false;
    const { number } = this.auth.user.vat;
    const { value } = this.vatNumberForm.fields.vatNumber;

    return !(!number && !value);
  }

  @computed
  get coupon(): string {
    return this.couponModel.code;
  }

  @computed
  get couponType(): string {
    return this.couponModel.type;
  }

  @computed
  get couponValue(): number {
    return this.couponModel.value;
  }

  @computed
  get discount(): number {
    return this.couponModel.discount;
  }

  @computed
  get trackDiscount(): number {
    return this.couponModel.track_discount;
  }

  @computed
  get discountAmount(): number {
    if (this.orderState !== LOAD_STATE.LOADING && !this.order) {
      return 0;
    }

    if (this.order && this.order.amount && typeof this.order.amount.discount !== 'undefined') {
      return Number(this.order.amount.discount);
    }

    if (this.couponModel.value) {
      return this.couponModel.value;
    }

    if (!this.couponModel.discount) {
      return 0;
    }

    switch (this.couponType) {
      case 'internal':
      case 'second_purchase':
      case 'support_coupon':
        return this.basket.total * (this.discount / 100);

      default:
        return 0;
    }
  }

  @computed
  get defaultCoupon() {
    return this.coupon || (this.auth.user ? this.auth.user.default_coupon : '');
  }

  @computed
  get couponText(): { title: string; message: string; discount: string } {
    if (!this.couponModel.title && !this.couponModel.message) {
      return null;
    }

    let discount = '';
    if (this.couponValue) {
      discount = formatCurrency(this.couponValue, this.currency);
    } else if (this.discount) {
      discount = parseInt(this.discount as any).toString() + '%';
    } else if (this.trackDiscount) {
      discount = parseInt(this.trackDiscount as any).toString() + '% off one track';
    }

    const values = {
      discount: discount,
    };

    return {
      title: replaceValues(this.couponModel.title, values),
      message: replaceValues(this.couponModel.message, values),
      discount,
    };
  }

  @observable
  couponForm: PageCheckoutCouponCodeModel = createCheckoutCouponForm();

  @computed
  get isBeta(): boolean {
    return this.order && Math.floor(Number(this.order.amount.total)) === 0;
  }

  @computed
  get vatRate(): string {
    return this.order ? this.order.amount.vat.rate.toFixed(2) : null;
  }

  @computed
  get showNoChargeMessage(): boolean {
    return !this.card && this.order && Math.floor(Number(this.order.amount.total)) === 0;
  }

  @computed
  get brandedContentWarning(): string {
    return this.content.brandedContentWarning;
  }

  @computed
  get hasUmgContent(): boolean {
    for (let i = 0; i < this.tracks.length; i++) {
      if (this.tracks[i].reporting_party !== undefined && this.tracks[i].reporting_party.slug === 'umg') {
        return true;
      }
    }
    return false;
  }

  @computed
  get availableCredits(): number {
    return this.user.currentChannelCredits.length;
  }

  /** How many available credits to apply to the order */
  @computed
  get creditsToUse(): number {
    // No premium tracks or no credits available
    if (!this.availableCredits || !this.commercialTracks.length) {
      return 0;
    }

    // Premium tracks are all covered by credits
    if (this.commercialTracks.length <= this.availableCredits) {
      return this.commercialTracks.length;
    }

    // At this point, there must be more premium tracks than credits, so just return all the credits
    return this.availableCredits;
  }

  @computed
  get applyCoupon(): boolean {
    // There are no premium tracks
    if (!this.commercialTracks.length) return false;

    // Premium tracks are all covered by credits
    if (this.commercialTracks.length <= this.availableCredits) return false;

    return true;
  }
}
