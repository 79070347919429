import { observable } from 'mobx';
import { ChannelSchema, ReferrerSchema } from '../../../types/schema';
import { PrismicSlice, RefereePageSingleDatas } from '../../../components/project-happy/utilities/types';

export class ChannelReferralPageModel {
  @observable channel: ChannelSchema = null;
  @observable referrer: ReferrerSchema = null;
  @observable loading = true;

  @observable document_title_match: string;

  @observable document_title_missing: string;

  @observable document_description: string;

  @observable document_image: string;

  @observable background_image: string;

  @observable header_missing: string;

  @observable referral_text: string;

  @observable sub_header: string;

  @observable cta_invite_copy: string;

  @observable cta_missing_copy: string;

  @observable cta_landing_button: string;

  @observable cta_complete_button: string;

  @observable cta_complete_url: string;

  @observable modal_hero_pre: string;

  @observable modal_hero_post: string;

  @observable modal_title: string;

  @observable modal_copy_match: any = [];

  @observable modal_copy_missing: any = [];

  @observable modal_caveat: string;

  @observable slices: PrismicSlice[] = [];

  @observable singleDatas: RefereePageSingleDatas = null;
}
