import * as React from 'react';
import { classes, cssRaw, cssRule, style } from 'typestyle';
import { px } from 'csx';
import { heading5, heading6 } from '../../../utilities/font';
import { mediaLargeMobile, mediaUpToLargeMobile } from '../../../utilities/mediaQueries';
import { rebrandPageClass } from '../../../utilities/page';

const styles = {
  wrapper: style(
    {
      textAlign: 'center',
      color: 'white',
      marginTop: 0,
      marginBottom: 0,
      $nest: {
        '& > :first-child': {
          fontWeight: 600,
          display: 'block',
        },
      },
    },
    mediaUpToLargeMobile(heading6, {
      $nest: {
        '&:first-child': {
          marginTop: px(8),
        },
        '&:last-child': {
          marginBottom: px(8),
        },
      },
    }),
    mediaLargeMobile(heading5, {
      $nest: {
        '&:first-child': {
          marginTop: px(24),
        },
        '&:last-child': {
          marginBottom: px(24),
        },
      },
    })
  ),
};

cssRule(`.${rebrandPageClass} h3.${styles.wrapper}`, { fontWeight: 300 });

export type StraplineProps = React.Component['props'] & { className?: string };

export const Strapline: React.SFC<StraplineProps> = ({ children, className }) => {
  return <h3 className={classes(styles.wrapper, className)}>{children}</h3>;
};
