import * as React from 'react';
import { Component } from '../../../../../lib/component';
import { classes } from 'typestyle';
import { rebrand } from '../../../../../theme/color';
import { Checkmark } from '../../../atoms/icons/Checkmark';
import { CloseCrossInCircle } from '../../../atoms/icons/CloseCrossInCircle';
import { ButtonProminence, ButtonSize } from '../../../atoms/button/buttonStyles';
import { Button } from '../../../atoms/button/Button';
import { User } from '../../../../../types/user';
import { CurvedArrowV2Gray } from '../../../atoms/icons/CurvedArrowV2Gray';
import cancellationModalStyles from './styles';
import { RatecardSchema } from '../../../../../types/schema';
import { formatNonDecimalCurrency } from '../../../../../lib/currency';
import { InfoFilled } from '../../../atoms/icons/InfoFilled';

export interface LegacyPlanContentProps {
  user: User;
  onRequestClose(): void;
  handleCancellation(): void;
  subscribedRateCard: RatecardSchema;
  unsubscribedRateCard: RatecardSchema;
}

export class LegacyPlanContent extends Component<LegacyPlanContentProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { user, onRequestClose, handleCancellation, subscribedRateCard, unsubscribedRateCard } = this.props;
    const unsubscribedPrice = formatNonDecimalCurrency(
      unsubscribedRateCard.value || unsubscribedRateCard.rate,
      unsubscribedRateCard.currency
    );

    const subscribedPrice = formatNonDecimalCurrency(
      subscribedRateCard.value || subscribedRateCard.rate,
      subscribedRateCard.currency
    );
    return (
      <div>
        <p className={cancellationModalStyles.message}>
          Hey {user.first_name}, here&apos;s a reminder of what you&apos;d lose if you cancel...
        </p>

        <div className={cancellationModalStyles.cardsContainer}>
          <div className={classes(cancellationModalStyles.card, cancellationModalStyles.hideOnMobile)}>
            <h3 className={cancellationModalStyles.cardTitleActive}>CREATOR</h3>

            <div className={cancellationModalStyles.cardBody}>
              <div className={cancellationModalStyles.cardBulletPoint}>
                <div className={cancellationModalStyles.icon}>
                  <Checkmark size={24} color={rebrand.secondary[60].toHexString()} />
                </div>
                <div>
                  Premium track licenses at <strong>{subscribedPrice}</strong>
                </div>
              </div>
              <div className={cancellationModalStyles.cardBulletPoint}>
                <div className={cancellationModalStyles.icon}>
                  <Checkmark size={24} color={rebrand.secondary[60].toHexString()} />
                </div>
                <div>
                  <strong>Unlimited royalty-free</strong> tracks
                </div>
              </div>
            </div>
          </div>

          <div className={cancellationModalStyles.card}>
            <h3 className={cancellationModalStyles.cardTitle}>NO SUBSCRIPTION</h3>

            <div className={cancellationModalStyles.cardBody}>
              <div className={cancellationModalStyles.cardBulletPoint}>
                <div className={cancellationModalStyles.icon}>
                  <InfoFilled size={20} color={rebrand.contrast[40].toHexString()} />
                </div>
                <div>
                  Premium track licenses will cost you <strong>{unsubscribedPrice}</strong>
                </div>
              </div>
              <div className={cancellationModalStyles.cardBulletPoint}>
                <div className={cancellationModalStyles.icon}>
                  <CloseCrossInCircle size={20} color={rebrand.contrast[40].toHexString()} />
                </div>
                <div>
                  <strong>No royalty-free</strong> tracks
                </div>
              </div>
            </div>
          </div>

          <CurvedArrowV2Gray className={cancellationModalStyles.arrow} />
        </div>

        <div className={cancellationModalStyles.buttonsContainer}>
          <Button
            className={cancellationModalStyles.button}
            prominence={ButtonProminence.PRIMARY}
            size={ButtonSize.LARGE}
            onClick={onRequestClose}
            data-test-downgrade-modal-button
          >
            Keep my subscription
          </Button>

          <Button
            className={cancellationModalStyles.button}
            prominence={ButtonProminence.SECONDARY}
            size={ButtonSize.LARGE}
            onClick={handleCancellation}
            data-test-downgrade-modal-button
          >
            I still want to cancel
          </Button>
        </div>
      </div>
    );
  }
}
