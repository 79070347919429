import * as React from 'react';
import { classes, cssRule, style } from 'typestyle';
import { percent, px } from 'csx';
import { ContentSlice, ContentSliceProps } from './ContentSlice';
import { mediaSmallTablet, mediaUpToDesktop, mediaUpToSmallTablet } from '../../utilities/mediaQueries';
import { heading3, heading4, heading5, heading6, headingStyles } from '../../utilities/font';
import { mediaMobileOnly } from '../../../../theme/media';
import { ChannelVerticalForm } from '../../molecules/ChannelVerticalForm';
import { Component, deps, inject, observer } from '../../../../lib/component';
import { CSSTransition } from 'react-transition-group';
import { transitionModal } from '../../../../theme/transition';
import { isEmpty } from '../../utilities/objects';
import { CircleButton } from '../../atoms/button/CircleButton';
import { CloseCross } from '../../atoms/icons/CloseCross';
import { desktopContentWidth } from '../../config';
import { ButtonProminence } from '../../atoms/button/buttonStyles';
import { flexRoot, vertical } from 'csstips';
import { centerCenter } from 'csstips/lib/flex';
import { animationFadeIn } from '../../../../theme/animation';

const styles = {
  wrapper: style(
    { position: 'relative' },
    mediaUpToSmallTablet({
      paddingBottom: px(32),
      $nest: {
        '.masthead + &, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient, .with-background + &:not(.with-background), :not(.with-background) + &.with-background': {
          paddingTop: px(32),
        },
      },
    }),
    mediaSmallTablet({
      paddingBottom: px(56),
      $nest: {
        '.masthead + &, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient, .with-background + &:not(.with-background), :not(.with-background) + &.with-background': {
          paddingTop: px(56),
        },
      },
    }),
    {
      paddingTop: px(56),
      textAlign: 'center',
    }
  ),
  content: style(
    headingStyles,
    mediaMobileOnly({
      width: '288px',
      margin: '8px auto',
      $nest: {
        '& p': {
          width: '288px',
        },
      },
    }),
    {
      $nest: {
        '& *': {
          margin: 0,
        },
        '& p': {
          margin: '8px auto',
          width: '656px',
        },
      },
    }
  ),
  verticalForm: style({
    $nest: {
      '.vertical-questionnaire-slice & > *': {
        margin: '8px 0',
      },
    },
  }),
  confirmationWrapper: style(
    animationFadeIn,
    flexRoot,
    vertical,
    centerCenter,
    {
      position: 'absolute',
      maxWidth: px(desktopContentWidth),
      width: percent(100),
      height: percent(100),
      top: 0,
      backgroundColor: 'white',
      zIndex: 1,
    },
    mediaUpToDesktop({ left: 0 })
  ),
  closeButton: style({
    position: 'absolute',
    top: px(16),
    right: px(16),
  }),
};

cssRule(
  '.vertical-questionnaire-slice .subheading',
  { fontWeight: 300, display: 'block' },
  mediaUpToSmallTablet(heading6),
  mediaSmallTablet(heading5)
);

cssRule(
  '.vertical-questionnaire-slice h2.heading, .vertical-questionnaire-slice .heading',
  mediaUpToSmallTablet(heading4),
  mediaSmallTablet(heading3)
);

type VerticalQuestionnaireSliceProps = ContentSliceProps & {
  confirmation: JSX.Element;
};

enum QUESTIONNAIRE_STATE {
  READY,
  ERROR,
  SUBMITTED,
  CLOSED,
}

type VerticalQuestionnaireSliceState = {
  questionnaireState: QUESTIONNAIRE_STATE;
  channelId: string | null;
};

@inject(deps)
@observer
export class VerticalQuestionnaireSlice extends Component<
  VerticalQuestionnaireSliceProps,
  VerticalQuestionnaireSliceState
> {
  state = {
    questionnaireState: QUESTIONNAIRE_STATE.READY,
    channelId: null,
  };

  constructor(props) {
    super(props);
    const {
      user: { channel },
    } = this.props.model;
    if (!isEmpty(channel) && channel.channel_category !== null) {
      this.state.questionnaireState = QUESTIONNAIRE_STATE.CLOSED;
      this.state.channelId = channel.id;
    }
  }

  componentDidUpdate() {
    const {
      user: { channel },
    } = this.props.model;
    if (!isEmpty(channel) && this.state.channelId !== channel.id) {
      // Reset state if the channel changes
      this.setState({
        questionnaireState: channel.channel_category === null ? QUESTIONNAIRE_STATE.READY : QUESTIONNAIRE_STATE.CLOSED,
        channelId: channel.id,
      });
    }
  }

  render() {
    const { children, className, confirmation, model, controller, ...props } = this.props;
    const { questionnaireState } = this.state;

    const confirmationElement = typeof confirmation === 'string' ? <p>{confirmation}</p> : confirmation;
    const errorElement = <p>An error occurred saving your selection.</p>;

    return (
      <CSSTransition in={questionnaireState !== QUESTIONNAIRE_STATE.CLOSED} {...transitionModal}>
        <ContentSlice
          className={classes('vertical-questionnaire-slice', className, styles.wrapper)}
          contentClassName={styles.content}
          {...props}
        >
          {children}
          <ChannelVerticalForm
            className={styles.verticalForm}
            onError={() => this.setState({ questionnaireState: QUESTIONNAIRE_STATE.ERROR })}
            onSuccess={() => this.setState({ questionnaireState: QUESTIONNAIRE_STATE.SUBMITTED })}
          />
          {questionnaireState !== QUESTIONNAIRE_STATE.READY && (
            <div className={styles.confirmationWrapper}>
              <CircleButton
                prominence={ButtonProminence.SECONDARY}
                className={styles.closeButton}
                onClick={() => this.setState({ questionnaireState: QUESTIONNAIRE_STATE.CLOSED })}
              >
                <CloseCross />
              </CircleButton>
              {questionnaireState === QUESTIONNAIRE_STATE.SUBMITTED && confirmationElement}
              {questionnaireState === QUESTIONNAIRE_STATE.ERROR && errorElement}
            </div>
          )}
        </ContentSlice>
      </CSSTransition>
    );
  }
}
