import { observable } from 'mobx';
import { CouponSchema } from '../../../types/schema';

export class AccountCouponsPageModel {
  @observable
  loading = false;

  @observable
  coupons: CouponSchema[] = [];
}
