import { observable } from 'mobx';
import { PaginationMeta } from '../../../types';
import { OrderSchema } from '../../../types/schema';

export class AccountOrdersPageModel {
  @observable
  loading = true;

  @observable
  loadingMore = false;

  @observable
  orders: Array<OrderSchema>;

  @observable
  dateOrder: 'DESC' | 'ASC' = 'DESC';

  @observable
  pagination: PaginationMeta;

  @observable
  isDownloadingInvoice: string | null = null;

  @observable
  channelId: string | null = null;
}
