import * as React from 'react';

export type EventOnErrorProps = {
  onError: (error: Error, errorInfo: React.ErrorInfo) => void;
};

export class EventOnError extends React.Component<EventOnErrorProps> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (typeof this.props.onError === 'function') {
      try {
        this.props.onError(error, errorInfo);
      } catch (error) {
        // Don't let event handlers cause further errors which negate the error boundary
        console.debug('The event handler passed to the EventOnError error boundary threw an error when called', error);
      }
    }
  }

  render() {
    if (this.state.hasError === true) return null; // If rendering fails then just hide the nested elements

    return this.props.children;
  }
}
