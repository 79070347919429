import { em, percent, px, rem, rotate } from 'csx';
import React, { ReactElement, ReactChild, ReactNode, ReactFragment, HTMLAttributes, DOMAttributes } from 'react';
import { classes, style } from 'typestyle';
import { colorGreyAccent } from '../../theme/color';
import { transitionExpandFade } from '../../theme/transition';
import styles from './styles';
import { SVGIcon } from '../svg-icon';
import { getUid } from '../project-happy/utilities/string';
import { ChevronDown } from '../project-happy/atoms/icons/ChevronDown';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';

export interface CollapsibleProps {
  initial?: boolean;
  overlay?: boolean;
  className?: string;
  wrapperClassName?: string;
  show?: boolean;
  isFaq?: boolean;
}

export interface SummaryProps extends Omit<HTMLAttributes<any>, keyof DOMAttributes<any>> {
  indicator?: boolean;
  className?: string;
  children: ReactNode | ReactNode[];
  onToggle?: (event: any, ref?: any) => void;
  uid?: string;
  indicatorClassName?: string;
  indicatorColour?: string;
}

interface StateIndicatorProps {
  className?: string;
  colour?: string;
}

class StateIndicator extends React.Component<StateIndicatorProps> {
  render() {
    const { className, colour = 'black' } = this.props;

    return (
      <div className={classes(styles.stateIndicatorWrapper, className)} aria-hidden={true}>
        <span className={styles.stateIndicatorButton}>
          <SVGIcon.ArrowDown color={colour} size={16} />
        </span>
      </div>
    );
  }
}

class Summary extends React.Component<SummaryProps> {
  ref: null | HTMLElement = null;

  render() {
    const { children, indicator, indicatorColour, indicatorClassName, className, uid, onToggle, ...props } = this.props;
    return (
      <div
        ref={(el) => (this.ref = el)}
        className={classes('summary', className, styles.summary)}
        tabIndex={0}
        role="button"
        id={uid}
        onClick={this.clickToggle.bind(this)}
        onKeyDown={this.keyToggle.bind(this)}
        {...props}
      >
        {children}
        {indicator && <StateIndicator colour={indicatorColour} className={indicatorClassName} />}
      </div>
    );
  }

  baseToggle(evt, ref) {
    if (typeof this.props.onToggle === 'function') {
      this.props.onToggle(evt, ref);
    }
  }

  clickToggle(evt, ref) {
    this.ref.blur();
    this.baseToggle(evt, ref);
  }

  keyToggle(evt, ref) {
    const keys = [32, 13];
    const isKey = (key) => evt.keyCode === key;
    if (keys.some(isKey)) {
      evt.preventDefault();
      this.baseToggle(evt, ref);
    }
  }
}

export class Collapsible extends React.Component<CollapsibleProps, { show: boolean }> {
  static styles = styles;

  static Summary = Summary;

  static StateIndicator = StateIndicator;

  ref: HTMLDivElement = null;

  externalEventHandler: (event: Event) => void;

  headerUid: string;

  panelUid: string;

  constructor(props: CollapsibleProps) {
    super(props);

    this.headerUid = getUid();
    this.panelUid = getUid();

    this.state = {
      show: 'initial' in props ? props.initial : false,
    };
  }

  componentDidMount() {
    if (this.ref === null) return;
    this.externalEventHandler = (event) => this.setState({ show: event.type === 'expand' });
    this.ref.addEventListener('collapse', this.externalEventHandler, false);
    this.ref.addEventListener('expand', this.externalEventHandler, false);
  }

  componentWillUnmount() {
    this.ref.removeEventListener('collapse', this.externalEventHandler);
    this.ref.removeEventListener('expand', this.externalEventHandler);
    this.externalEventHandler = null;
  }

  toggle(evt, ref) {
    this.setState({
      show: !this.state.show,
    });
  }

  render() {
    const { children, className, wrapperClassName, show: parentShow, isFaq } = this.props;
    const { show: childShow } = this.state;

    const show = typeof parentShow === 'boolean' ? parentShow : childShow;

    const toggle = this.toggle.bind(this);

    const internalSummaryProps: Partial<SummaryProps> = {
      uid: this.headerUid,
      'aria-controls': this.panelUid,
      'aria-expanded': show,
    };
    let summary: ReactElement<SummaryProps> = (
      <Summary onToggle={toggle} {...internalSummaryProps}>
        Show more
      </Summary>
    );
    let details = children;
    if (Array.isArray(children)) {
      const userSummary: ReactElement<SummaryProps> = children.find(
        (el) => typeof el === 'object' && 'type' in el && (el as React.ReactElement<any>).type === Summary // Old TS version can't infer type from conditional
      ) as ReactElement | undefined;
      if (userSummary) {
        if (typeof userSummary.props.onToggle !== 'function') {
          internalSummaryProps.onToggle = toggle;
        }
        summary = React.cloneElement(userSummary, { ...userSummary.props, ...internalSummaryProps });
        details = children.filter((el) => el !== userSummary);
      }
    }

    const detailWrapperProps: HTMLAttributes<any> = {
      id: this.panelUid,
      'aria-labelledby': this.headerUid,
    };
    if (!show) {
      detailWrapperProps['aria-hidden'] = true;
    }

    return (
      <div
        ref={(ref) => (this.ref = ref)}
        className={classes('details', show ? 'open' : 'closed', styles.container, className)}
        {...(isFaq && { 'data-test-faq-row-question': show })}
      >
        {summary}
        <CSSTransition in={show} {...detailWrapperProps} {...transitionExpandFade}>
          <div {...(isFaq && { 'data-test-faq-row-answer': '' })} className={styles.detailsWrapper}>
            {details}
          </div>
        </CSSTransition>
      </div>
    );
  }
}
