import * as React from 'react';
import { classes, style } from 'typestyle';
import { rebrand } from '../../../theme/color';
import { percent, px } from 'csx';
import { ACCOUNT_PLANS_ROUTE, ACCOUNT_VIDEOS_ROUTE } from '../../../constants';
import { mediaCoarsePointer, mediaFinePointer } from '../../../theme/media';
import { mediaSmallTablet } from '../utilities/mediaQueries';
import { betweenJustified, centerJustified, flexRoot } from 'csstips';
import { transitionFade } from '../../../theme/transition';
import { Link } from '../atoms/Link';
import { Component, ComponentProps, deps, inject, observer } from '../../../lib/component';
import { pace } from '../utilities/functions';
import { CircleButton } from '../atoms/button/CircleButton';
import { ChevronLeft } from '../atoms/icons/ChevronLeft';
import { ChevronRight } from '../atoms/icons/ChevronRight';
import { CSSTransition } from 'react-transition-group';
import { ButtonProminence, defaultButtonFocusOutline } from '../atoms/button/buttonStyles';
import { AnalyticsContextProvider } from '../utilities/AnalyticsContext';
import { clamp } from '../utilities/numbers';
import { focusVisible } from '../utilities/css';
import { borderRadius } from '../../../theme/border';

const MORE_LEFT_CLASS = 'more-left';
const MORE_RIGHT_CLASS = 'more-right';

const backgroundColourHelper = rebrand.contrast[60];
const backgroundColour = backgroundColourHelper.toString();

const styles = {
  container: style(centerJustified, {
    textAlign: 'center',
    position: 'relative',
    background: backgroundColour,
  }),
  list: style({
    margin: 0,
    padding: '0 16px',
    display: 'flex',
    listStyleType: 'none',
    scrollbarWidth: 'none',
    overflowX: 'scroll',
    $nest: {
      '&::-webkit-scrollbar': {
        $unique: true,
        display: 'none',
      },
    },
  }),
  item: style(
    {
      display: 'block',
      flexShrink: 0,
      whiteSpace: 'nowrap',
      padding: '4px',
      $nest: {
        '&:hover': {
          background: rebrand.contrast[90].toString(),
        },
      },
    },
    mediaSmallTablet({
      padding: '16px 4px',
    })
  ),
  itemActive: style({
    background: rebrand.contrast[10].toString(),
    $nest: {
      '&:hover': {
        background: rebrand.contrast[10].toString(),
      },
    },
  }),
  link: style(
    {
      fontWeight: 700,
      display: 'inline-block',
      padding: '4px 8px',
      lineHeight: '1.5',
      borderRadius: borderRadius.SMALL,
      color: rebrand.neutralOnDark[50].toString(),
      $nest: {
        'li:hover &': {
          color: rebrand.neutralOnDark[10].toString(),
        },
      },
    },
    focusVisible({
      outline: defaultButtonFocusOutline,
    }),
    mediaSmallTablet({
      padding: '8px 12px',
    })
  ),
  linkActive: style({
    color: rebrand.primary[50].toString(),
    $nest: {
      'li:hover &': {
        color: rebrand.primary[50].toString(),
      },
    },
  }),
  scrollButton: style(
    mediaFinePointer({
      position: 'absolute',
      top: percent(50),
      transform: 'translateY(-50%)',
    }),
    mediaCoarsePointer({
      display: 'none',
    })
  ),
  scrollLeftButton: style({
    left: px(16),
  }),
  scrollRightButton: style({
    right: px(16),
  }),
  buttons: style(flexRoot, betweenJustified, {
    width: percent(100),
  }),
};

interface AccountMenuLink {
  href: string;
  label: string;
  matchingRoutes?: string[];
}

// **PROJECT_SUB_FLAG** Remove feature flag logic from component and uncomment below
// const links: AccountMenuLink[] = [
//   { href: ACCOUNT_PLANS_ROUTE, label: 'Plans & Billing' },
//   { href: '/account/licenses', label: 'Licenses' },
//   { href: '/account/orders', label: 'Orders', matchingRoutes: ['/account/order'] },
//   { href: ACCOUNT_VIDEOS_ROUTE, label: 'Videos & Claims' },
//   { href: '/account/profile', label: 'My Profile' },
//   { href: '/account/playlists', label: 'Playlists' },
//   { href: '/account/favourites', label: 'Favourites' },
//   { href: '/account/coupons', label: 'Credits & Coupons' },
//   { href: '/account/communication', label: 'Communication Preferences' },
//   { href: '/account/community', label: 'Community' },
// ];

const analyticsContext = { uiLocation: 'Account Navigation' };

@inject(deps)
@observer
export class AccountMenu extends Component<ComponentProps> {
  pacedHandleListScroll: () => void;
  listRef: HTMLUListElement | null = null;
  activeLinkRef: HTMLLIElement | null = null;

  state = {
    moreLeft: false,
    moreRight: false,
  };

  constructor(props) {
    super(props);
    this.pacedHandleListScroll = pace(this.handleListScroll.bind(this), 250);
    this.handleScrollLeft = this.handleScrollLeft.bind(this);
    this.handleScrollRight = this.handleScrollRight.bind(this);
  }

  componentDidMount() {
    this.handleListScroll();
    window.addEventListener('resize', this.pacedHandleListScroll, false);
    if (this.listRef && this.activeLinkRef) {
      const { left } = this.activeLinkRef.getBoundingClientRect();
      this.listRef.scrollTo(clamp(0, left - 100, this.listRef.scrollWidth), 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.pacedHandleListScroll);
    this.pacedHandleListScroll = null;
  }

  handleListScroll() {
    if (this.listRef === null) return;
    this.setState({
      moreLeft: this.listRef.scrollLeft > 0,
      moreRight: this.listRef.scrollLeft + this.listRef.clientWidth < this.listRef.scrollWidth,
    });
  }

  handleScrollLeft() {
    if (this.listRef === null) return;
    this.listRef.scrollBy({ left: this.listRef.clientWidth * -1, behavior: 'smooth' });
  }

  handleScrollRight() {
    if (this.listRef === null) return;
    this.listRef.scrollBy({ left: this.listRef.clientWidth, behavior: 'smooth' });
  }

  setActive(pathname: string, routes: string[]): boolean {
    return routes.some((route) => pathname.includes(route));
  }

  render() {
    const { pathname } = this.props.model.router.location;
    const { projectSubscriptionEnabled } = this.props.model.ui;
    const { moreLeft, moreRight } = this.state;

    // **PROJECT_SUB_FLAG** Remove feature flag logic from below and move links back outside the component
    const links: AccountMenuLink[] = [
      { href: ACCOUNT_PLANS_ROUTE, label: 'Plans & Billing' },
      { href: '/account/licenses', label: 'Licenses' },
      { href: '/account/orders', label: 'Orders', matchingRoutes: ['/account/order'] },
      { href: ACCOUNT_VIDEOS_ROUTE, label: 'Videos & Claims' },
      { href: '/account/profile', label: 'My Profile' },
      { href: '/account/playlists', label: 'Playlists' },
      { href: '/account/favourites', label: 'Favourites' },
      {
        href: '/account/coupons',
        label: projectSubscriptionEnabled ? 'Credits & Coupons' : 'Coupons',
      },
      { href: '/account/communication', label: 'Communication Preferences' },
      { href: '/account/community', label: 'Community' },
    ];

    return (
      <AnalyticsContextProvider context={analyticsContext}>
        <nav className={styles.container}>
          <ul
            className={classes(styles.list, moreLeft && MORE_LEFT_CLASS, moreRight && MORE_RIGHT_CLASS)}
            ref={(ref) => (this.listRef = ref)}
            onScroll={this.pacedHandleListScroll}
            data-test-account-headers
          >
            {links.map((link) => {
              const routes = link.matchingRoutes ? [link.href, ...link.matchingRoutes] : [link.href];

              return (
                <li
                  key={link.href}
                  ref={(ref) => {
                    if (this.setActive(pathname, routes)) this.activeLinkRef = ref;
                  }}
                  className={classes(styles.item, this.setActive(pathname, routes) && styles.itemActive)}
                >
                  <Link
                    href={link.href}
                    className={classes(styles.link, this.setActive(pathname, routes) && styles.linkActive)}
                    data-test-account-navigation={link.label}
                  >
                    {link.label}
                  </Link>
                </li>
              );
            })}
          </ul>
          <CSSTransition in={moreLeft} {...transitionFade}>
            <CircleButton
              className={classes(styles.scrollButton, styles.scrollLeftButton)}
              prominence={ButtonProminence.SECONDARY}
              onClick={this.handleScrollLeft}
            >
              <ChevronLeft />
            </CircleButton>
          </CSSTransition>
          <CSSTransition in={moreRight} {...transitionFade}>
            <CircleButton
              className={classes(styles.scrollButton, styles.scrollRightButton)}
              prominence={ButtonProminence.SECONDARY}
              onClick={this.handleScrollRight}
            >
              <ChevronRight />
            </CircleButton>
          </CSSTransition>
        </nav>
      </AnalyticsContextProvider>
    );
  }
}
