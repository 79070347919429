import React from 'react';
import { style } from 'typestyle';
import { Tooltip } from '../Tooltip';
import { CircleButton } from './CircleButton';
import { Plus } from '../icons/Plus';
import { AddToPlaylistDialog } from '../../organisms/AddToPlaylistDialog';
import { rebrand } from '../../../../theme/color';
import { ButtonProminence } from './buttonStyles';
import { px } from 'csx';
import { TrackSchema } from '../../../../types/schema';
import { ButtonProps } from './Button';

const styles = {
  tooltip: style({
    backgroundColor: rebrand.neutralOnDark['10'].toString(),
    borderColor: rebrand.neutralOnDark['10'].toString(),
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.1)',
    padding: 0,
  }),
  dialog: style({
    borderRadius: px(8),
  }),
};

export type AddToPlaylistButtonProps = Pick<ButtonProps, 'onClick' | 'className'> & {
  tooltipClassName?: string;
  track: Pick<TrackSchema, 'identity' | 'title'>;
  onAddToPlaylist?(): void;
};

export class AddToPlaylistButton extends React.Component<AddToPlaylistButtonProps> {
  state = {
    showTooltip: false,
  };

  dialogComponent = null;

  constructor(props: AddToPlaylistButtonProps) {
    super(props);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.dialogComponent = (
      <AddToPlaylistDialog
        className={styles.dialog}
        track={this.props.track}
        onPlaylistSelection={() => {
          this.setState({ showTooltip: false });
          if (typeof props.onAddToPlaylist === 'function') {
            props.onAddToPlaylist();
          }
        }}
      />
    );
  }

  toggleTooltip() {
    const newState = !this.state.showTooltip;
    this.setState({ showTooltip: newState });
  }

  render() {
    const { showTooltip } = this.state;
    const { className, tooltipClassName } = this.props;

    return (
      <Tooltip
        className={styles.tooltip}
        portalClassName={tooltipClassName}
        show={showTooltip}
        content={this.dialogComponent}
        positions={['bottom', 'left', 'top']}
        disableOnMobile={false}
        onClickOutside={() => {
          this.setState({ showTooltip: false });
        }}
      >
        <CircleButton
          prominence={ButtonProminence.SECONDARY}
          onClick={this.toggleTooltip}
          label="Add to playlist"
          data-test-add-to-playlist-button
          className={className}
        >
          <Plus />
        </CircleButton>
      </Tooltip>
    );
  }
}
