import * as React from 'react';
import { TextareaHTMLAttributes } from 'react';
import { classes, style } from 'typestyle';
import { AdditionalInputProps, commonInputStyles } from '../../utilities/inputs';
import { screenReaderOnly } from '../../utilities/font';
import { percent, px } from 'csx';

const styles = {
  wrapper: style({
    position: 'relative',
  }),
  input: style(commonInputStyles, {
    width: percent(100),
  }),
  characterLimitDisplay: style({
    position: 'absolute',
    bottom: px(8),
    right: px(8),
    margin: 0,
    fontSize: px(12),
    $nest: {
      '&::before': {
        content: "attr(data-value-length) '/' attr(data-value-max)",
      },
    },
  }),
  srOnly: style(screenReaderOnly),
};

export type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & AdditionalInputProps;
export const TextArea: React.FC<TextAreaProps> = ({ className, isValid, value, maxLength, ...props }) => (
  <span className={styles.wrapper}>
    <textarea
      className={classes(styles.input, isValid === false && 'invalid', className)}
      value={value}
      maxLength={maxLength}
      {...props}
    />
    {Number.isFinite(maxLength) && typeof value === 'string' && (
      <p className={styles.characterLimitDisplay} data-value-length={value.length} data-value-max={maxLength}>
        <span className={styles.srOnly}>You have {maxLength - value.length} characters remaining</span>
      </p>
    )}
  </span>
);
