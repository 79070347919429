import * as React from 'react';
import { PrismicMenuLinkComponent, PrismicMenuLinkFields } from './PrismicMenuLink';
import { MenuLinkGroup, MenuLinkGroupProps } from '../atoms/MenuLinkGroup';

export type PrismicMenuLinkGroupProps = {
  primary: {
    group_name: string;
  };
  items: PrismicMenuLinkFields[];
};
export type PrismicMenuLinkGroupComponentProps = Partial<MenuLinkGroupProps> & {
  prismicFields: PrismicMenuLinkGroupProps['primary'];
  prismicItems: PrismicMenuLinkGroupProps['items'];
};

export const PrismicMenuLinkGroupComponent: React.SFC<PrismicMenuLinkGroupComponentProps> = ({
  prismicFields: { group_name },
  prismicItems: items,
  ...props
}) => (
  <MenuLinkGroup name={group_name} {...props}>
    {items.map((item, linkKey) => (
      <PrismicMenuLinkComponent key={linkKey} prismicFields={item} />
    ))}
  </MenuLinkGroup>
);

export const PrismicMenuLinkGroup = (key: number, { primary, items }: PrismicMenuLinkGroupProps) => (
  <PrismicMenuLinkGroupComponent prismicFields={primary} prismicItems={items} />
);
