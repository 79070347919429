import * as React from 'react';
import { colorWhite } from '../theme/color';
import { ColorHelper } from 'csx';

export interface LogoProps {
  logoImage?: string; // TODO remove this dependency since we don't use png any more
  width?: number;
  className?: string;
  color?: ColorHelper;
}

export const Logo: React.SFC<LogoProps> = ({ width = 92, className, color = colorWhite }) => {
  const height = width * (267.47/500);

  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 500 267.47">
      <path fill={color.toString()} d="M0 25l12.59 242.47 487.41-25L487.41 0zm485.58 204.45l-460 23.6L14.42 38l460-23.6zM50.81 71.14l26.28-1.38 6.43 122.62 41.34-2.17 1.19 22.61-67.62 3.55zm82.6-4.33l26.48-1.39 7.61 145.23L141 212zM182 175.17l-4-76.38c-1.22-23.22 15.8-37.39 38.62-38.59 21.38-1.12 38.33 10.45 39.46 32l1 19.15-23.83 1.25-.87-16.7c-.44-8.35-6-13.37-14.57-12.92-9.57.5-13.54 6.63-13.09 15.18l3.89 74.14c.51 9.78 5.88 14.81 14.84 14.34 8.35-.44 13.36-6.21 12.93-14.36l-.86-16.5 23.83-1.25.94 17.93c1.15 22-14 36.27-36 37.42-23.45 1.27-41.01-10.27-42.29-34.71zm87.77-115.51l26.48-1.38 3.44 65.58 26.1-67.13 25.86-1.36-23.31 57.8 31.54 87.4-28.11 1.43-21.61-65.45-8.82 18.84 2.52 48.07-26.48 1.39zm133.48-6.72l-40.53 2.13 7.61 145.22 40.33-2.11c21.79-1.14 38.1-13.44 36.81-38.08l-3.94-75.16c-1.21-23.02-18.69-33.13-40.28-32zm17.46 107.13c.37 7.13-2.89 15.06-13.48 15.62l-11.81.62-5.26-100.22 11.61-.6c10-.53 14.79 5.35 15.21 13.5z"/>
    </svg>
  )
};