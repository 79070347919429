import { px } from 'csx';
import React from 'react';
import { classes, style } from 'typestyle';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import UserAvatar from '../atoms/UserAvatar';
import { rebrand } from '../../../theme/color';
import { spacing } from '../../../theme/spacing';
import { borderRadius } from '../../../theme/border';
import { ChannelSchema } from '../../../types/schema';

interface ChannelNameAvatarProps extends ComponentProps {
  banner?: boolean;
  className?: string;
  channelOverride?: ChannelSchema;
}

const styles = {
  channelInfo: style({
    display: 'flex',
    alignItems: 'center',
  }),
  avatar: style({
    marginRight: px(8),
    width: '40px',
    height: '40px',
  }),
  channelName: style({ fontSize: '24px', lineHeight: 1 }),
};

const bannerStyles = {
  channelInfo: style({
    width: '100%',
    justifyContent: 'center',
    backgroundColor: rebrand.neutralOnDark[10].toString(),
    padding: spacing.SMALL,
    borderRadius: borderRadius.MEDIUM,
  }),
  channelName: style({
    margin: 0,
  }),
};

export const ChannelNameAvatar = inject(deps)(
  observer(
    ({
      model: {
        user: { channel: currentChannel },
      },
      banner = false,
      className,
      channelOverride = null,
    }: ChannelNameAvatarProps) => {
      const channel = channelOverride ? channelOverride : currentChannel;

      return (
        <div className={classes(styles.channelInfo, banner && bannerStyles.channelInfo, className && className)}>
          <UserAvatar className={styles.avatar} channelOverride={channelOverride} />
          <h3 className={classes(styles.channelName, banner ? bannerStyles.channelName : '')}>{channel.name}</h3>
        </div>
      );
    }
  )
);
