import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { px } from 'csx';
import { HighlightText } from '../../../atoms/HighlightText';
import { mediaLargeTablet, mediaUpToDesktop, mediaUpToLargeTablet } from '../../../utilities/mediaQueries';
import { PromoBadge } from '../../../atoms/PromoBadge';

const styles = {
  wrapper: style(
    {
      position: 'absolute',
    },
    mediaUpToLargeTablet({
      width: px(96),
      height: px(96),
      top: px(8),
      right: px(8),
      fontSize: px(10),
    }),
    mediaLargeTablet({
      width: px(128),
      height: px(128),
      top: px(16),
      right: px(16),
    })
  ),
};

cssRaw(`
@supports (backdrop-filter: blur(10px) saturate(0.9)) {
  .${styles.wrapper} {
    backdrop-filter: blur(10px) saturate(0.9);
  }
}
`);

export type PromoBadgeProps = React.Component['props'] & { className?: string };

export const PanelPromoBadge: React.SFC<PromoBadgeProps> = ({ children, className }) => (
  <PromoBadge className={classes(styles.wrapper, className)}>{children}</PromoBadge>
);
