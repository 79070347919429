import * as React from 'react';
import { classes, style } from 'typestyle';
import { colorGreyLabel, colorWhite, rebrand } from '../../../theme/color';
import { TrackArtwork } from '../atoms/TrackArtwork';
import { LicenceSchema } from '../../../types/schema';
import { ArtistLinkList } from '../../artist-link-list';
import { resetAnchorStyles } from '../../../theme/reset';
import { em, px } from 'csx';
import { mediaUpToLargeMobile } from '../utilities/mediaQueries';

const styles = {
  trackImage: style({
    height: '87px',
    width: '87px',
    minHeight: '87px',
    minWidth: '87px',
    borderBottomLeftRadius: '4px',
    borderTopLeftRadius: '4px',
  }),
  trackLine: style({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    backgroundColor: colorWhite.toString(),
    border: `1px solid ${rebrand.neutralOnDark[20].toString()}`,
    paddingRight: px(16),
    borderRadius: px(4),
  }),
  claimText: style(mediaUpToLargeMobile({ margin: '0px 16px' }), { margin: '8px 16px', fontSize: '14px' }),
  artistLinkList: style({
    $nest: { ...resetAnchorStyles(rebrand.contrast[50].toString()) },
  }),
  title: style({
    fontSize: '12px',
    color: rebrand.contrast[50].toString(),
    fontWeight: 'bold',
    paddingBottom: '2px',
    margin: 0,
  }),
  secondaryText: style({
    margin: 0,
    fontSize: '10px',
    color: rebrand.neutralOnLight[70].toString(),
    paddingBottom: '2px',
  }),
  LinkWrapper: style({
    margin: 0,
    fontSize: '12px',
    paddingBottom: '2px',
    marginTop: '-2px',
  }),
};

export type LicenceRowProps = {
  licence: LicenceSchema;
  className?: string;
};

export const LicenceRow: React.FC<LicenceRowProps> = ({ children, licence, className, ...props }) => (
  <div className={classes(styles.trackLine, className)} data-test-licence-row {...props}>
    <TrackArtwork className={styles.trackImage} width={100} height={100} track={licence.track} />
    <div className={styles.claimText}>
      <p className={styles.secondaryText} data-test-licence-row-track-type>
        {licence.track.is_stock_music ? 'Included' : 'Premium'}
      </p>
      <p data-test-licence-row-track-title className={styles.title}>
        {licence.track.title}
      </p>
      <ArtistLinkList
        model={licence.track}
        linkClassName={classes(styles.artistLinkList)}
        className={styles.LinkWrapper}
      />
      <p className={styles.secondaryText} data-test-licence-row-short-id>
        ({licence.short_id})
      </p>
    </div>
    {children}
  </div>
);
