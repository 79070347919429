import * as React from 'react';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { ContentSliceProps } from './ContentSlice';
import { LargeTrackTile } from '../../molecules/LargeTrackTile';
import { TrackSchema } from '../../../../types/schema';
import { mediaUpToDesktop } from '../../utilities/mediaQueries';
import { DecoratedPageCarousel, DecoratedPageCarouselProps } from '../DecoratedPageCarousel';
import { colorGunmetal } from '../../../../theme/color';
import PlayButton from '../../atoms/button/PlayButton';
import { ButtonPill } from '../../../button-pill';
import { shadowElevation2 } from '../../../../theme/shadow';
import { BaseCarouselSlice } from './BaseCarouselSlice';

const styles = {
  carousel: style(
    mediaUpToDesktop({
      marginLeft: px(-16),
      marginRight: px(-16),
      width: 'calc(100% + 32px)',
    })
  ),
  trackTile: style(
    {
      $nest: {
        ':not(.with-gradient) &': {
          color: colorGunmetal.toHexString(),
        },
      },
    },
    mediaUpToDesktop({
      margin: 0,
      paddingLeft: px(16),
    })
  ),
  trackPlay: style({
    position: 'absolute',
    bottom: px(16),
    right: px(16),
    zIndex: 1,
  }),
  channelButton: style({
    backgroundColor: '#E0E4E9',
    color: '#2E2E3B',
    height: px(32),
    fontSize: px(16),
    $nest: {
      '&:hover, &:active': {
        backgroundColor: '#E0E4E9',
        ...shadowElevation2,
      },
    },
  }),
};

// Exclude gradient prop as this slice has a two tone background
type CarouselSliceProps = ContentSliceProps & DecoratedPageCarouselProps & { tracks: TrackSchema[] };

export const TopPicksCarouselSlice: React.SFC<CarouselSliceProps> = ({
  children,
  className,
  contentClassName,
  tracks,
  carouselClassName,
  ...props
}) => {
  const actions = (
    <div style={{ textAlign: 'right' }}>
      <ButtonPill
        className={styles.channelButton}
        onClick={(e) => {
          e.preventDefault();
          window.location.href = '/browse/collection';
        }}
      >
        See all
      </ButtonPill>
    </div>
  );
  return (
    <BaseCarouselSlice className={className} contentClassName={contentClassName}>
      <DecoratedPageCarousel
        actions={actions}
        perPage={3}
        carouselClassName={classes(styles.carousel, carouselClassName)}
        {...props}
      >
        {tracks.map((track, key) => [
          <LargeTrackTile key={key} track={track} className={styles.trackTile} />,
          <PlayButton key={`${key}-play`} track={track} className={styles.trackPlay} />,
        ])}
      </DecoratedPageCarousel>
    </BaseCarouselSlice>
  );
};
