import * as React from 'react';
import { observer } from 'mobx-react';
import { resetAnchorStyles } from '../theme/reset';
import { style } from 'typestyle';
import { colorBrand } from '../theme/color';
import { Highlight } from './highlight';
import { ArtistSchema } from '../types/schema';
import { AnalyticsController } from '../modules/analytics/controller';
import createFragment from 'react-addons-create-fragment';
import { Link } from './project-happy/atoms/Link';
import { AnalyticsContextProvider } from './project-happy/utilities/AnalyticsContext';

type MinimalArtist = Pick<ArtistSchema, 'name' | 'slug' | 'highlight'>;

export interface ArtistLinkListModel {
  artists: Array<MinimalArtist>;
}

export interface ArtistLinkListProps {
  model: ArtistLinkListModel;
  className?: string;
  linkClassName?: string;
  containerStyle?: any;
  highlight?: string;
  onClickLink?(event: React.MouseEvent<any>): void;
}

export const ArtistLinkListLinkStyle = style({
  textDecoration: 'underline',
  color: colorBrand.toString(),
  $nest: { ...resetAnchorStyles(colorBrand.toString()) },
});

const DEFAULT_MODEL = {
  artists: [],
};

const handleArtistClick = (props: ArtistLinkListProps, event: React.MouseEvent<HTMLAnchorElement>) => {
  if (typeof props.onClickLink === 'function') {
    props.onClickLink(event);
  }
};

const artistContext = new WeakMap();
const getArtistContext = (artist: MinimalArtist) => {
  if (artistContext.has(artist)) return artistContext.get(artist);
  const context = { artistName: artist.name, artistSlug: artist.slug };
  artistContext.set(artist, context);
  return context;
};

export const InlineArtistLinkList = (props: ArtistLinkListProps) => (
  <>
    {props.model.artists.reduce((acc, artist, index) => {
      acc.push(
        <AnalyticsContextProvider context={getArtistContext(artist)} key={index}>
          <Link
            className={props.linkClassName || ArtistLinkListLinkStyle}
            href={`/music/artists/${artist.slug}`}
            onClick={(evt) => handleArtistClick(props, evt)}
            data-test-artist-link
          >
            {artist.name}
          </Link>
        </AnalyticsContextProvider>
      );
      if (index + 1 < props.model.artists.length) acc.push(', ');
      return acc;
    }, [])}
  </>
);

@observer
export class ArtistLinkList extends React.Component<ArtistLinkListProps> {
  render() {
    const { model = DEFAULT_MODEL, highlight, containerStyle = {}, className, linkClassName } = this.props;

    return (
      <div style={containerStyle} className={className} data-test-artist-list>
        {model.artists
          .reduce((acc, artist, index) => {
            if (index + 1 < model.artists.length) return [...acc, artist, null];
            return [...acc, artist];
          }, [])
          .map((artist, index) => {
            if (artist === null) return <span key={index}>, </span>;

            const highlightName =
              artist.highlight && artist.highlight.length && artist.highlight.find((h) => h.key === 'name');

            return (
              <AnalyticsContextProvider context={getArtistContext(artist)} key={index}>
                <Link
                  className={linkClassName || ArtistLinkListLinkStyle}
                  href={`/music/artists/${artist.slug}`}
                  onClick={(event) => handleArtistClick(this.props, event)}
                  data-test-artist-link={artist.name}
                >
                  <Highlight innerHtml={highlightName ? highlightName.value : void 0} match={highlight}>
                    {artist.name}
                  </Highlight>
                </Link>
              </AnalyticsContextProvider>
            );
          })}
      </div>
    );
  }
}
