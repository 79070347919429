/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'mobx';
import {
  DEFAULT_STATE,
  FindSortMode,
  FindSortOrder,
  SearchMvpPageModel,
  SearchVariables,
  SelectedEntity,
} from './model';
import { APIController } from '../../api/controller';
import { UserModel } from '../../user/model';
import { EnvModel } from '../../env/model';
import { ContentModel } from '../../content/model';
import { FindFiltersInput } from '../../../types/graphql';
import { RouterModel } from '../../router/model';
import { AnalyticsController } from '../../analytics/controller';
import { UIController } from '../../ui/controller';
import { RouterState } from 'react-router';
import { PaginationInput } from '../../../types';
import { FILTER_KEYS } from '../../../lib/findFilters';
import objectDifference from '../../../lib/objectDifference';
import { debounce } from '../../../components/project-happy/utilities/functions';
import { SEARCH_ROUTE } from '../../../constants';
import { EntityType } from '../../../components/project-happy/atoms/SearchSuggestionItem';
import { browserRegion } from '../../../components/project-happy/utilities/browserRegion';

type SearchField = keyof SearchVariables | keyof PaginationInput | keyof FindFiltersInput;

export class SearchMvpPageController {
  constructor(
    private model: SearchMvpPageModel,
    private ui: UIController,
    private env: EnvModel,
    private router: RouterModel,
    private analytics: AnalyticsController,
    private api: APIController,
    private user: UserModel,
    private content: ContentModel
  ) {
    this.syncState = debounce(this.syncState, 500);
  }
  @action
  onEnter = async (nextState: RouterState) => {
    const queryString = nextState.location.search.slice(1);
    this.model.userRegion = this.user.user ? this.user.user.country : null;
    return this.init(queryString);
  };
  @action
  onLeave = async () => {
    return this.ui.scrollToTop();
  };

  initialResultsBasedOnCatalogueType = async (catalogueType) => {
    const { defaultSearchState, Savedresults } = this.model;
    if (catalogueType === 'ALL') {
      this.model.results = Savedresults;
    } else {
      let defaultFetchResult = null;
      const tracks = this.model.Savedresults.filter((track) => {
        if (catalogueType === 'PRODUCTION') {
          return track.is_stock_music === true;
        } else if (catalogueType === 'CHART') {
          return track.is_stock_music === false;
        }

        return false;
      });
      if (tracks.length < 1) {
        defaultFetchResult = await this.api.search.searchTracks({
          ...defaultSearchState,
          query: '',
          filters: { ...defaultSearchState.filters, catalogueType },
        });
      }
      this.model.results = tracks.length > 0 ? tracks : defaultFetchResult.data.find.tracks.results;
    }
  };

  @action
  performSearch = async (clearFilter = false, resetLoader = true) => {
    // resetLoader will always be True except on loadMore clicks
    this.model.loading = resetLoader;
    const { searchVariables, defaultSearchState, topSolutionFilter } = this.model;
    if (clearFilter) {
      this.model.searchState.filters = defaultSearchState.filters;
    }
    this.model.searchVariables.filters.matchArtistFallback = true;
    this.model.searchVariables.filters.availableIn = this.model.userRegion;
    const searchSuggestionFilter = topSolutionFilter.artist ? { filters: { artist: topSolutionFilter.artist } } : {};
    const [results, entities] = await Promise.all([
      this.api.search.searchTracks(searchVariables),
      this.api.search.findEntity({ query: searchVariables.query, ...searchSuggestionFilter }),
    ]);

    this.model.showSuggestionDropdown = false;
    this.model.loading = false;
    this.syncState();
    this.model.pagination = results.data.find.tracks.pagination;
    if (results.data.find.tracks.results.length === 0) {
      this.initialResultsBasedOnCatalogueType(searchVariables.filters.catalogueType);
      this.model.noSearchResult = true;
      this.scrollTop();
      return;
    }

    const newTrackResult = results.data.find.tracks.results;
    if (!resetLoader) {
      this.model.results = this.model.results.concat(newTrackResult);
    } else {
      this.model.results = newTrackResult;
      if (this.model.Savedresults.length < 1) {
        this.model.Savedresults = newTrackResult;
      }

      this.scrollTop();
    }
    this.model.entities = entities.data;
    this.model.noSearchResult = false;
    this.resetCursor();
  };

  @action
  scrollTop = () => {
    if (window.innerWidth < 1024) {
      this.ui.scrollToTop();
    } else {
      this.ui.scrollToTop(0, 67);
    }
  };

  @action
  refreshPage = () => {
    const { defaultSearchState } = this.model;
    this.model.searchState = defaultSearchState;
    this.model.selectedEntity = { name: '', slug: '', type: null, uuid: '', images: null };
    this.analytics.sendMixpanel('User clicks start over');
    this.performSearch();
  };

  @action
  performAutoSuggestion = async () => {
    const { searchVariables } = this.model;

    if (searchVariables.query.length < 3) {
      this.model.showSuggestionDropdown = false;
    }

    if (searchVariables.query.length >= 3) {
      this.model.searchVariables.filters.matchArtistFallback = undefined;
      this.model.searchVariables.filters.availableIn = this.model.userRegion;

      try {
        const [results, entities] = await Promise.all([
          this.api.search.searchTracks(searchVariables),
          this.api.search.findEntity({ query: searchVariables.query }),
        ]);

        if (results.data && entities.data) {
          this.model.suggestionTrackEntity = results.data.find.tracks.results.slice(0, 3);
          this.model.entities = entities.data;
          await this.getUserSearchHistory();
        }
      } catch (error) {
        console.error(error);
      }

      if (this.model.suggestionTrackEntity.length < 1 && this.model.getEntities.length < 1) {
        this.model.showSuggestionDropdown = false;
      } else if (!this.model.showSuggestionDropdown) {
        this.model.showSuggestionDropdown = true;
      }
    }

    this.resetCursor();
  };

  @action
  resetQuery = () => {
    this.model.searchState.query = '';
    this.performSearch();
    this.resetSuggestionEntity();
  };
  @action
  resetSuggestionEntity = () => {
    this.model.selectedEntity = { name: '', type: null, uuid: '' };
    this.model.suggestionTrackEntity = [];
    this.model.playlistDetails = [];
  };

  @action
  resetFilter = () => {
    const { searchState, defaultSearchState } = this.model;
    this.model.searchState.filters = defaultSearchState.filters;
    this.model.searchState = { ...searchState };
    this.analytics.sendMixpanel('User clicks clear filters');
    this.performSearch();
    this.resetSuggestionEntity();
  };

  @action resetCursor = () => {
    this.model.cursor = null;
  };

  @action
  loadMore = async () => {
    this.model.loadingMoreResults = true;

    this.model.searchState.pagination.from = this.model.searchState.pagination.from + 25;
    this.syncState(true, true);
    await this.performSearch(false, false);

    this.model.loadingMoreResults = false;
  };

  @action
  getUserSearchHistory = async () => {
    try {
      const history = await this.api.search.fetchUserSearchHistory(this.user.user.identity);
      this.model.searchHistory = history && history.data.slice(0, 3);
    } catch (e) {
      return e;
    }
  };

  @action
  saveUserSearchTerm = async () => {
    let entity = this.model.getEntities.find((e) => e.slug === this.model.selectedEntity.slug);

    if (this.model.selectedEntity.type === 'track') {
      const track = this.model.suggestionTrackEntity.find((entity) => entity.slug === this.model.selectedEntity.slug);
      const { identity, title, artists, images, slug } = track;
      entity = {
        ...this.model.selectedEntity,
        identity,
        name: title,
        artists,
        images,
        slug,
        type: this.model.selectedEntity.type,
      };
    }

    if (entity.type === 'playlist') {
      entity = { ...this.model.playlistDetails, ...entity };
    }
    try {
      await this.api.search.addUserSearchHistory(this.user.user.identity, entity);
    } catch (e) {
      return e;
    }
  };
  @action
  deleteUserSearchTerm = async () => {
    try {
      await this.api.search.deleteUserSearchHistory(this.user.user.identity, this.model.histroyId);
      await this.getUserSearchHistory();
    } catch (e) {
      return e;
    }
  };
  @action resetPagination = () => (this.model.searchState.pagination = this.model.defaultSearchState.pagination);

  @action
  onselectSuggestion = (clearFilter = true) => {
    this.resetPagination();
    this.performSearch(clearFilter);

    if (!this.model.selectedIsHistory && this.model.selectedEntity.name) {
      this.saveUserSearchTerm();
    }
    this.model.saveEntitiesonSelection = this.model.entities;
  };

  @action updateSelectedIsHistory = (status: boolean) => {
    this.model.selectedIsHistory = status;
  };

  @action
  handleSuggestionSelection = async (item: EntityType) => {
    this.model.selectedEntity = item;
    const { type, slug, name } = item;
    if (type === 'artist') {
      this.model.topSolutionFilter.artist = item.identity || '';
    } else {
      this.model.topSolutionFilter.artist = null;
    }
    if (type === 'mood' || type === 'genre') {
      // clear all initial filters before filtering by suggestion selection
      this.model.searchState.filters = this.model.defaultSearchState.filters;
      this.model.searchVariables.query = '';
      this.refineFilter(type, slug);
      this.onselectSuggestion(false);
    } else {
      this.model.searchVariables.query = name;
      this.onselectSuggestion();
    }
  };

  // This function we be removed later, when playlist entity is returned with image
  @action
  fetchPlaylistDetails = async (slug) => {
    const details = await this.api.browse.getPlaylist(slug);
    this.model.playlistDetails = details.data;
  };

  @action
  toggleFilter = async () => {
    this.model.openFilter = !this.model.openFilter;
  };

  /**
   * This Fetch mood and genres data displayed  on the
   * search filter sidebar
   * @memberof SearchMvpPageController
   */
  fetchMoodAndGenreItems = async () => {
    const entities = await this.api.search.getMoodsGenres();
    this.model.moods = entities.data.moods.matches.results;
    this.model.genres = entities.data.genres.matches.results;
    return;
  };

  private queryStringToFilterState = (qs: string) => {
    return qs
      .split('&')
      .reduce((pairs, pair) => [...pairs, pair.split('=')], [])
      .reduce(
        (state, [key, value]: [SearchField, any]) => {
          const decoded = this.castKeyPair(key, decodeURIComponent(value));

          switch (true) {
            case ['size', 'from'].includes(key):
              return {
                ...state,
                pagination: {
                  ...state.pagination,
                  [key]: decoded,
                },
              };

            case FILTER_KEYS.includes(key as any):
              return {
                ...state,
                filters: {
                  ...state.filters,
                  [key]: decoded,
                },
              };

            default:
              return {
                ...state,
                [key]: decoded,
              };
          }
        },
        { ...DEFAULT_STATE }
      );
  };
  private castKeyPair = (key: SearchField, value: string) => {
    switch (true) {
      case SearchMvpPageController.IntegerKeys.indexOf(key) !== -1:
        return parseInt(value);

      case SearchMvpPageController.BooleanKeys.indexOf(key) !== -1:
        return value === 'true';

      case value === 'null':
        return null;

      default:
        return value;
    }
  };

  static IntegerKeys: Array<SearchField> = ['size', 'from', 'durationMin', 'durationMax', 'tempoMin', 'tempoMax'];

  static BooleanKeys: Array<SearchField> = [
    'brandSponsored',
    'brandedContent',
    'explicit',
    'featured',
    'instrumental',
    'hideNotAvailableIn',
    'dailymotion',
    'facebook',
    'instagram',
    'linkedin',
    'snapchat',
    'twitch',
    'twitter',
    'vimeo',
    'youtube',
    'TikTok',
    'podcasting',
  ];

  @action
  refineSort = (mode: FindSortMode, order: FindSortOrder | undefined = undefined) => {
    this.model.searchState.sortMode = mode;
    this.model.searchState.sortOrder = order;
    this.syncState();
    this.performSearch();
  };

  /**
   * This update graphql variables for search
   * from data coming from  url query
   * @private
   * @param {string} queryString
   * @memberof SearchMvpPageController
   */
  private updateVariableFromRouter = (queryString: string) => {
    this.model.searchState = queryString.length ? this.queryStringToFilterState(queryString) : this.model.searchState;
  };

  handleFilterCountBounce = () => {
    this.model.filterCountBounce = true;
    // Reset the bouncing state after the animation finishes (1 second in this case)
    setTimeout(() => {
      this.model.filterCountBounce = false;
    }, 1000);
  };

  @action
  refineFilter = async (key: string, value: any) => {
    this.resetPagination();
    const [MIN_DURATION, MAX_DURATION] = this.model.durationRange;
    const [MIN_BPM, MAX_BPM] = this.model.bpmRange;
    const initialCount = this.model.filterCount;
    switch (key) {
      case 'durationMin':
        this.model.searchState.filters.durationMin =
          value <= MIN_DURATION || value === null
            ? null
            : Math.min(
                Math.max(value as number, MIN_DURATION),
                this.model.searchVariables.filters.durationMax || MAX_DURATION
              );
        break;

      case 'durationMax':
        this.model.searchState.filters.durationMax =
          value >= MAX_DURATION || value === null
            ? null
            : Math.max(
                Math.min(value as number, MAX_DURATION),
                this.model.searchVariables.filters.durationMin || MIN_DURATION
              );
        break;
      case 'tempoRange':
        this.model.searchState.filters['tempoMin'] = value[0] <= MIN_BPM ? null : value[0];
        this.model.searchState.filters['tempoMax'] = value[1] >= MAX_BPM ? null : value[1];
        break;
      case 'tempoMin':
        this.model.searchState.filters.tempoMin =
          value <= MIN_BPM || value === null
            ? null
            : Math.min(Math.max(value as number, MIN_BPM), this.model.searchVariables.filters.tempoMax || MAX_BPM);
        break;

      case 'tempoMax':
        this.model.searchState.filters.tempoMax =
          value >= MAX_BPM || value === null
            ? null
            : Math.max(Math.min(value as number, MAX_BPM), this.model.searchVariables.filters.tempoMin || MIN_BPM);
        break;

      default:
        this.model.searchState.filters[key] = value;
        this.model.searchState = { ...this.model.searchState };
      // this.model.searchState.filters.mood = value;
    }
    this.resetSuggestionEntity();
    this.syncState();
    if (this.model.filterCount !== initialCount) {
      this.handleFilterCountBounce();
    }
  };

  /**
   * This method update the url with search or filter query
   * EXample: http://dev.lickd.io:8000/search?genre=ambient
   * @param {boolean} [noRouterReplace=false]
   * @param {boolean} [routerPush=false]
   * @memberof SearchMvpPageController
   */
  @action
  syncState = (noRouterReplace = false, routerPush = false) => {
    if (this.router.location.pathname !== SEARCH_ROUTE) return;

    // This part reset availableIn to undefined so that it won't show as url query,
    this.model.searchState.filters.availableIn = undefined;

    // This part reset pagination to default, so it won't show either size or from in the Url query
    const allQueryExceptPagination = {
      ...this.model.searchState,
      pagination: this.model.defaultSearchState.pagination,
    };
    const qs = this.filterStateToQueryString(allQueryExceptPagination);

    const nextPath = SEARCH_ROUTE + (qs.length ? `?${qs}` : '');
    if (routerPush) {
      this.router.push(nextPath);
    } else if (!noRouterReplace) {
      this.router.replace(nextPath);
    }

    const { query } = this.model.searchState;

    this.ui.setSEO(query && query.length ? `${SEARCH_ROUTE}/query` : SEARCH_ROUTE, { query });
  };

  private filterStateToQueryString = (state: SearchVariables) => {
    const diff = objectDifference(state, this.model.defaultSearchState);
    const queryPaths = [];

    const encode = (key, value) => `${key}=${encodeURIComponent(value as string)}`;

    Object.keys(diff).forEach((key) => {
      if (key === 'pagination')
        return Object.keys(diff.pagination).forEach((k) => {
          queryPaths.push(encode(k, diff.pagination[k]));
        });

      if (key === 'filters')
        return Object.keys(diff.filters).forEach((k) => {
          queryPaths.push(encode(k, diff.filters[k]));
        });

      queryPaths.push(encode(key, diff[key]));
    });

    return queryPaths.join('&');
  };

  init = async (queryString: string) => {
    this.updateVariableFromRouter(queryString);
    if (!this.model.results.length) {
      this.performSearch();
    }

    await this.ui.setSEO(SEARCH_ROUTE);
    this.fetchMoodAndGenreItems();

    this.syncState(this.env.isServer);
    return;
  };
}
