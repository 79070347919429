import React from 'react';
import { classes } from 'typestyle';
import styles from './styles';

interface TabProps {
  className?: string;
  heading: string;
}

class Tab extends React.Component<TabProps> {
  render() {
    const { children, className } = this.props;

    return <div className={classes(styles.tabWrapper, className)}>{children}</div>;
  }
}

interface TabsProps {
  className?: string;
  selected?: number;
  onSelect?: (index: number) => void;
}

export default class Tabs extends React.Component<TabsProps> {
  static Tab = Tab;

  state = {
    selected: 0,
  };

  get isControlled() {
    return Number.isFinite(this.props.selected);
  }

  selectTab(index: number) {
    if (this.isControlled) return this.props.onSelect(index);
    this.setState({
      selected: index,
    });
  }

  render() {
    const { children } = this.props;
    const selected = this.isControlled ? this.props.selected : this.state.selected;

    const childrenArray = Array.isArray(children) ? children : [children];
    const tabs: Tab[] = childrenArray.filter(
      (el) => typeof el === 'object' && 'type' in el && el.type === Tab
    ) as Tab[];
    const headings = tabs.map((tab) => tab.props.heading);
    const tab = tabs[selected];

    return (
      <div className={styles.wrapper}>
        <ul className={styles.list}>
          {headings.map((heading, index) => (
            <li className={styles.listItem} key={index}>
              <button
                onClick={() => this.selectTab(index)}
                className={classes(styles.tabButton, selected === index ? 'selected' : false)}
              >
                {heading}
              </button>
            </li>
          ))}
        </ul>
        {tab}
      </div>
    );
  }
}
