import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { percent, px, url } from 'csx';
import { PrismicSliceCSSClasses } from '../../utilities/types';
import { parseImageUrl } from '../../../../modules/image/model';

const styles = {
  wrapper: style({
    width: percent(100),
    textAlign: 'center',
    position: 'relative',
    color: 'white',
    height: '500px',
    backgroundColor: '#1b1b24',
    backgroundPosition: 'center top',
  }),
  wrapperWithBg: style({
    backgroundSize: 'cover',
  }),
  wrapperWithOverlay: style({
    $nest: {
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(0,0,0, 0.6)',
        zIndex: 0,
      },
    },
  }),
  content: style({ position: 'relative', height: percent(100) }),
};

cssRaw(`
@supports (backdrop-filter: saturate(0.3) brightness(0.8)) {
  .${styles.wrapperWithOverlay}::before {
    background: rgba(0,0,0, 0.4) !important;
    backdrop-filter: saturate(0.3) brightness(0.8);
  }
}
`);

export type BaseMastheadProps = React.Component['props'] & {
  src?: string;
  colour?: string;
  dimBackground?: boolean;
  className?: string;
  mastheadClassName?: string;
  model?: any;
  controller?: any;
};

export const BaseMasthead: React.SFC<BaseMastheadProps> = ({
  children,
  src,
  colour,
  dimBackground = true,
  className,
  mastheadClassName,
}) => {
  let backgroundStyle: false | string = false;
  if (src) {
    try {
      const { baseUrl, ext } = parseImageUrl(src);
      backgroundStyle = style({
        backgroundImage: [
          url(`${baseUrl}-0x500.${ext}`),
          `-webkit-image-set(${url(`${baseUrl}-0x500.${ext}`)} 1x, ${url(`${baseUrl}-0x750.${ext}`)} 1.5x, ${url(
            `${baseUrl}-0x1000.${ext}`
          )} 2x)`,
          `image-set(${url(`${baseUrl}-0x500.${ext}`)} 1x, ${url(`${baseUrl}-0x750.${ext}`)} 1.5x, ${url(
            `${baseUrl}-0x1000.${ext}`
          )} 2x)`,
        ],
      });
    } catch {
      backgroundStyle = style({
        backgroundImage: url(src),
      });
    }
  }
  return (
    <div
      className={classes(
        styles.wrapper,
        PrismicSliceCSSClasses.MASTHEAD,
        src && styles.wrapperWithBg,
        dimBackground && styles.wrapperWithOverlay,
        backgroundStyle,
        mastheadClassName
      )}
      style={{
        backgroundColor: colour || '#1b1b24',
      }}
    >
      <span className={classes(styles.content, className)}>{children}</span>
    </div>
  );
};
