export const zFlash = 120;
export const zTooltip = 102;
export const zModal = 101;

export const zAppMenuDrawer = 80;

export const zAppHeaderContainer = 76;

export const zPlayer = 75;
export const zOverMenu = 70;
export const zUnderLayer = 65;

export const zOverContent = 60;
export const zContentLoader = 41;

export const zLabel = 10;
export const zFilter = 11;

export const zHeroContent = 7;
export const zHeroCarouselGradient = 6;
export const zHeroCarouselImage = 5;
export const zHeroCarouselActive = 3;
export const zHeroCarouselInactive = 2;
export const searchFilterAndSuggestionlInactive = zAppHeaderContainer - 2;
