import React from 'react';
import { Modal } from '../modal';
import { classes, style } from 'typestyle';
import { percent, px, em } from 'csx';
import scrollableOverlay, {
  ScrollableOverlayHeaderStyle,
  ScrollableOverlayTitleStyle,
} from '../../styles/scrollable-overlay';
import { SUPPORT_HUB } from '../../constants';

interface OrderErrorModalProps {
  show: boolean;
}

const styles = {
  modal: style({ maxWidth: px(640), width: percent(50), minWidth: px(400), margin: '0 auto' }),
  header: style(ScrollableOverlayHeaderStyle, {
    padding: '1rem',
    borderBottom: '1px solid #E0E4E9',
  }),
  heading: style(ScrollableOverlayTitleStyle),
  content: style({ padding: '0 2em' }),
  button: style({ display: 'block', margin: '0 auto' }),
  helpText: style({ fontSize: em(0.75), textAlign: 'center' }),
};

export default class OrderErrorModal extends React.Component<OrderErrorModalProps> {
  render() {
    const { show } = this.props;
    return (
      <Modal show={show} onRequestClose={void 0}>
        <div className={classes(scrollableOverlay.wrapper, styles.modal)}>
          <header className={styles.header}>
            <h1 className={styles.heading}>Checkout error</h1>
          </header>
          <div className={styles.content}>
            <p>An error occurred setting up your order. Try refreshing the page.</p>
            <p>
              If the error persists, please <a href={SUPPORT_HUB}>submit a support ticket</a> or use the support chat
              for assistance.
            </p>
          </div>
        </div>
      </Modal>
    );
  }
}
