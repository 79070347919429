import * as React from 'react';
import { percent } from 'csx';
import { classes, style } from 'typestyle';
import { colorSubtle, rebrand } from '../../../theme/color';
import { mixinTextEllipsis, overlayLink } from '../../../theme/mixin';
import { PlaylistSchema } from '../../../types/schema';
import { UserPlaylistThumbnail } from '../atoms/UserPlaylistThumbnail';
import { Link } from '../atoms/Link';
import { PUBLIC_PLAYLISTS_ROUTE } from '../../../constants';
import { borderRadius, borderStyle } from '../../../theme/border';
import { shadowElevation2 } from '../../../theme/shadow';
import { transitionQuickEase } from '../../../theme/transition';

const styles = {
  card: style({
    position: 'relative',
    border: borderStyle,
    borderRadius: borderRadius.MEDIUM,
    width: percent(100),
    overflow: 'hidden',
    transition: `box-shadow ${transitionQuickEase}`,
    $nest: {
      '&:hover': shadowElevation2,
      '&:focus-within': shadowElevation2,
    },
  }),
  playlistDetails: style({
    display: 'flex',
    borderBottom: `1px solid ${rebrand.light1.toString()}`,
    $nest: {
      '&:last-child': {
        borderBottom: 0,
      },
    },
  }),
  thumbnail: style({ width: percent(50) }),
  title: style(mixinTextEllipsis, {
    fontSize: '14px',
    lineHeight: 'normal',
    margin: '4px 0',
  }),
  playlistTitle: style(overlayLink, {
    display: 'block',
    fontWeight: 600,
    color: rebrand.contrast['50'].toString(),
  }),
  published: style({
    fontSize: '12px',
    color: colorSubtle.toHexString(),
  }),
  textWrapper: style({
    margin: '32px 16px',
    width: '50%',
  }),
};

type UserPlaylistCardProps = { className?: string; playlist: PlaylistSchema };

export const UserPlaylistCard: React.FC<UserPlaylistCardProps> = ({ playlist, className, children, ...props }) => {
  return (
    <div className={classes(styles.card, className)} data-test-user-playlist-card {...props}>
      <div className={styles.playlistDetails}>
        <UserPlaylistThumbnail className={styles.thumbnail} playlist={playlist} />
        <div className={styles.textWrapper}>
          <Link
            href={`${PUBLIC_PLAYLISTS_ROUTE}/${playlist.identity}`}
            className={classes(styles.title, styles.playlistTitle)}
            data-test-playlist-card-link
          >
            {playlist.name}
          </Link>
          <div className={classes(styles.title)}>{playlist.description}</div>
        </div>
      </div>
      {children}
    </div>
  );
};
