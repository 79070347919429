import React, { Component } from 'react';
import { classes, style } from 'typestyle';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { mediaDesktop, mediaMobileOnly, mediaUpToDesktop } from '../utilities/mediaQueries';
import { OnboardVerticalsLayout } from '../templates/OnboardVerticalsLayout';
import { LeftHandOnboardContentPanel } from '../atoms/LeftHandOnboardContentPanel';
import { RightHandOnboardContentPanel } from '../atoms/RightHandOnboardContenntPanel';
import { ChannelNameAvatar } from '../molecules/ChannelNameAvatar';
import { OnboardProgressBar } from '../atoms/OnboardProgressBar';
import isEmpty from 'lodash/isEmpty';
import { ButtonProminence } from '../atoms/button/buttonStyles';
import { Button } from '../atoms/button/Button';
import { ArrowRight } from '../atoms/icons/ArrowRight';
import { rebrand } from '../../../theme/color';
import { ButtonCircle } from '../../button-circle';
import { CloseCross } from '../atoms/icons/CloseCross';
import { Link } from '../atoms/Link';
import { CircleButton } from '../atoms/button/CircleButton';
import { ChevronLeft } from '../atoms/icons/ChevronLeft';
import {
  GENRES_CUSTOMER_FACING,
  ONBOARD_VERTICALS_COMPLETE,
  ONBOARD_VERTICAL_CATEGORY_ROUTE,
} from '../../../constants';

import afro from '../../../static/channel-onboarding/channel-genres/Genre_Afro_Square-1800x0.jpg';
import ambientChill from '../../../static/channel-onboarding/channel-genres/Genre_Ambient+Chill-1800x0.jpg';
import blues from '../../../static/channel-onboarding/channel-genres/Category_Blues_02-1800x0.jpg';
import jazz from '../../../static/channel-onboarding/channel-genres/Category_Jazz_SQUARE_02-1800x0.jpg';
import punk from '../../../static/channel-onboarding/channel-genres/Category_Punk_SQUARE_02-1800x0.jpg';
import classical from '../../../static/channel-onboarding/channel-genres/Genre_Classical_Square_v2_02-1800x0.jpg';
import country from '../../../static/channel-onboarding/channel-genres/Genre_Country-1800x0.jpg';
import danceElectronic from '../../../static/channel-onboarding/channel-genres/Genre_Dance+Electronic-1800x0.jpg';
import folkAcoustic from '../../../static/channel-onboarding/channel-genres/Genre_Folk+Acoustic-1800x0.jpg';
import funk from '../../../static/channel-onboarding/channel-genres/Genre_Funk_Square_02-1800x0.jpg';
import gospelChristian from '../../../static/channel-onboarding/channel-genres/Genre_Gospel+Christian-1800x0.jpg';
import hipHop from '../../../static/channel-onboarding/channel-genres/Genre_HipHop-1800x0.jpg';
import indieAlternative from '../../../static/channel-onboarding/channel-genres/Genre_Indie+Alternative-1800x0.jpg';
import latin from '../../../static/channel-onboarding/channel-genres/Genre_Latin_Square_v2_02-1800x0.jpg';
import metal from '../../../static/channel-onboarding/channel-genres/Genre_Metal_Square_02-1800x0.jpg';
import pop from '../../../static/channel-onboarding/channel-genres/Genre_Pop_v2_02-1800x0.jpg';
import rAndB from '../../../static/channel-onboarding/channel-genres/Genre_R_B-1800x0.jpg';
import reggae from '../../../static/channel-onboarding/channel-genres/Genre_Reggae_Square_02-1800x0.jpg';
import rock from '../../../static/channel-onboarding/channel-genres/Genre_Rock-1800x0.jpg';

import { VerticalTile } from '../atoms/VerticalTile';

const styles = {
  hideUpToDesktop: style(mediaUpToDesktop({ display: 'none' })),
  hideDesktop: style(mediaDesktop({ display: 'none' })),
  hideMobile: style(mediaMobileOnly({ display: 'none' })),
  matchHeadingText: style(mediaUpToDesktop({ marginTop: '32px', top: '118px' }), {
    fontSize: '32px',
    position: 'absolute',
    top: '160px',
    marginTop: '68px',
  }),
  boldText: style({
    fontWeight: 700,
  }),
  text: style(mediaUpToDesktop({ top: '234px', width: '350px' }), {
    width: '351px',
    position: 'absolute',
    top: '300px',
  }),
  button: style(mediaUpToDesktop({ bottom: '64px', right: '0px', width: '85px' }), {
    display: 'grid',
    alignItems: 'center',
    width: '101px',
    position: 'absolute',
    bottom: '-80px',
    right: '-96px',
    height: '40px',
    margin: '32px 16px',
    boxShadow: 'none',
    $nest: {
      '&:hover': {
        boxShadow: 'none',
      },
    },
  }),
  arrow: style({
    position: 'absolute',
    right: '12px',
  }),
  textBtn: style({
    color: rebrand.contrast[90].toHexString(),
  }),
  rightPanelContainer: style(
    mediaUpToDesktop({ height: '32px', backgroundColor: rebrand.neutralOnDark['10'].toString() }),
    mediaDesktop({ display: 'flex', justifyContent: 'center' }),
    { overflow: 'hidden', height: '93vh' }
  ),
  exitBlock: style(mediaDesktop({ display: 'none' }), {
    display: 'flex',
    alignItems: 'baseline',
    position: 'absolute',
    top: '0px',
    marginLeft: '16px',
  }),
  exitText: style({ fontSize: '12px', marginLeft: '8px', color: rebrand.contrast['40'].toString() }),
  exitButton: style({
    backgroundColor: rebrand.neutralOnDark['20'].toString(),
  }),
  backButton: style(mediaUpToDesktop({ bottom: '64px', left: '0px' }), {
    position: 'absolute',
    bottom: '-80px',
    left: '-96px',
    width: '40px',
    height: '40px',
    margin: '32px 16px',
  }),
  verticalWrapper: style({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: '700px',
    minWidth: '651px',
    overflowY: 'scroll',
    height: '100%',
  }),
  verticalWrapperMobile: style({
    display: 'flex',
    flexWrap: 'wrap',
    width: '352px',
    position: 'relative',
    top: '270px',
    $nest: {
      '& p': {
        color: rebrand.contrast[50].toHexString(),
      },
    },
  }),
  container: style(mediaUpToDesktop({ height: '2700px' })),
};

const ChannelGenres = [
  { img: afro, text: GENRES_CUSTOMER_FACING[0], tag_id: 4076197 },
  { img: ambientChill, text: GENRES_CUSTOMER_FACING[1], tag_id: 4072424 },
  { img: blues, text: GENRES_CUSTOMER_FACING[2], tag_id: 4076016 },
  { img: classical, text: GENRES_CUSTOMER_FACING[3], tag_id: 4074003 },
  { img: country, text: GENRES_CUSTOMER_FACING[4], tag_id: 4076113 },
  { img: danceElectronic, text: GENRES_CUSTOMER_FACING[5], tag_id: 4061888 },
  { img: folkAcoustic, text: GENRES_CUSTOMER_FACING[6], tag_id: 4073716 },
  { img: hipHop, text: GENRES_CUSTOMER_FACING[8], tag_id: 6271905 },
  { img: indieAlternative, text: GENRES_CUSTOMER_FACING[9], tag_id: 4071847 },
  { img: jazz, text: GENRES_CUSTOMER_FACING[10], tag_id: 4075951 },
  { img: latin, text: GENRES_CUSTOMER_FACING[11], tag_id: 4071625 },
  { img: pop, text: GENRES_CUSTOMER_FACING[12], tag_id: 4074023 },
  { img: punk, text: GENRES_CUSTOMER_FACING[13], tag_id: 4076759 },
  { img: rAndB, text: GENRES_CUSTOMER_FACING[14], tag_id: 7370811 },
  { img: reggae, text: GENRES_CUSTOMER_FACING[15], tag_id: 4075861 },
  { img: rock, text: GENRES_CUSTOMER_FACING[16], tag_id: 4071216 },
  { img: metal, text: GENRES_CUSTOMER_FACING[17], tag_id: 4076353 },
  { img: funk, text: GENRES_CUSTOMER_FACING[18], tag_id: 4075617 },
];

const { PRIMARY } = ButtonProminence;

@inject(deps)
@observer
export class OnboardFavouriteGenresPage extends Component<
  ComponentProps,
  {
    requiredNumberOfSelections: number;
    selectedGenreTagIds: number[];
    selectedIndices: number[];
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndices: [],
      requiredNumberOfSelections: 3,
      selectedGenreTagIds: [],
    };
  }

  render() {
    const {
      model: {
        user: {
          user: { identity },
        },
        user: { channel },
      },
      controller: {
        analytics: { sendMixpanel },
        api: {
          user: { setChannelGenreTags },
        },
      },
    } = this.props;
    const { selectedIndices, requiredNumberOfSelections, selectedGenreTagIds } = this.state;

    const isTileSelected = (index) => selectedIndices.includes(index);

    const handleTileSelect = (index) => {
      const updatedIndices = [...selectedIndices];
      const selectedIndex = updatedIndices.indexOf(index);

      if (selectedIndex > -1) {
        updatedIndices.splice(selectedIndex, 1); // Deselect the tile
      } else {
        if (updatedIndices.length < requiredNumberOfSelections) {
          updatedIndices.push(index); // Select the tile if not reached the maximum limit
        }
      }

      const updatedVertical = updatedIndices.map((i) => ChannelGenres[i].tag_id); // Map selected indices to verticalText values

      this.setState({
        selectedIndices: updatedIndices,
        selectedGenreTagIds: updatedVertical,
      });
    };

    const handleNextStepClick = () => {
      setChannelGenreTags(identity, channel.id, selectedGenreTagIds);

      const selectedGenreNames = selectedIndices.map((index) => ChannelGenres[index].text);

      sendMixpanel('user selects genre', { genre_type: selectedGenreNames, genre_tag: selectedGenreTagIds });

      sendMixpanel('User clicks next onboard genres');
    };

    return (
      <OnboardVerticalsLayout className={styles.container}>
        <LeftHandOnboardContentPanel>
          {!isEmpty(channel) && (
            <>
              <ChannelNameAvatar />

              <OnboardProgressBar step={2} />

              <h3 className={styles.matchHeadingText}>Which genres of music do you like to feature in your videos?</h3>

              <p className={styles.text}>
                <span className={styles.boldText}>Select up to 3</span> so we can show you the most relevant tracks
              </p>
              <div className={classes(styles.hideDesktop, styles.verticalWrapperMobile)}>
                {ChannelGenres.map((vertical, i) => (
                  <VerticalTile
                    image={vertical.img}
                    text={vertical.text}
                    key={i}
                    onClick={() => handleTileSelect(i)}
                    selected={isTileSelected(i)}
                    reachedMaximumNumberOfItemsToBeSelected={selectedIndices.length === requiredNumberOfSelections}
                  />
                ))}
              </div>

              <Button
                className={classes(styles.button)}
                prominence={PRIMARY}
                href={ONBOARD_VERTICALS_COMPLETE}
                disabled={selectedGenreTagIds.length === 0}
                onClick={handleNextStepClick}
                data-test-next-button
              >
                <span className={classes(selectedIndices.length > 0 && styles.textBtn)}>Next</span>
                <ArrowRight className={styles.arrow} color={rebrand.contrast[90].toHexString()} />
              </Button>
              <Link
                href={ONBOARD_VERTICAL_CATEGORY_ROUTE}
                data-test-back-button
                onClick={() => sendMixpanel('user clicks back - onboard genre step')}
              >
                <CircleButton className={styles.backButton} prominence={ButtonProminence.SECONDARY}>
                  <ChevronLeft size={10} />
                </CircleButton>
              </Link>
            </>
          )}
        </LeftHandOnboardContentPanel>
        <RightHandOnboardContentPanel className={styles.rightPanelContainer}>
          <Link
            href="/"
            data-test-explore-button
            onClick={() => {
              sendMixpanel('User clicks let me explore - onboard genre step');
            }}
          >
            <div className={styles.exitBlock}>
              <ButtonCircle className={styles.exitButton} size={16}>
                <CloseCross size={10} />
              </ButtonCircle>
              <p className={styles.exitText}>Just let me explore</p>
            </div>
          </Link>
          <div className={classes(styles.verticalWrapper, styles.hideUpToDesktop)}>
            {ChannelGenres.map((vertical, i) => (
              <VerticalTile
                image={vertical.img}
                text={vertical.text}
                key={i}
                onClick={() => handleTileSelect(i)}
                selected={isTileSelected(i)}
                reachedMaximumNumberOfItemsToBeSelected={selectedIndices.length === requiredNumberOfSelections}
              />
            ))}
          </div>
        </RightHandOnboardContentPanel>
      </OnboardVerticalsLayout>
    );
  }
}
