import * as React from 'react';
import { style } from 'typestyle';
import { ModalProps } from '../../modal';
import { Component } from '../../../lib/component';
import { rebrand } from '../../../theme/color';
import { ScrollableOverlay } from '../../scrollable-overlay/scrollable-overlay';
import { px, rem, url } from 'csx';
import { ACCOUNT_PLANS_ROUTE } from '../../../constants';
import overlayStyles, { ScrollableOverlayBodyStyle } from '../../../styles/scrollable-overlay';
import { spacing } from '../../../theme/spacing';
import { borderRadius } from '../../../theme/border';
import MintLabel from '../../../static/tile-icon-label-green.png';
import { CheckmarkCircle } from '../atoms/icons/CheckmarkCircle';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { ArrowRight } from '../atoms/icons/ArrowRight';

const styles = {
  wrapper: style({ display: 'block', maxWidth: px(440) }),
  planButton: style({
    display: 'flex',
    margin: 'auto',
    width: '230px',
    fontSize: '16px',
  }),
  body: style(ScrollableOverlayBodyStyle, {
    padding: '1.5rem 0 0',
    borderTop: `1px solid ${rebrand.light1.toString()}`,
    textAlign: 'center',
    $nest: {
      '& > p': {
        margin: '0 0 1.5rem',
        textAlign: 'left',
      },
    },
  }),
  bannerWrapper: style({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing.DEFAULT,
    marginBottom: rem(1.5),
  }),
  banner: style({
    position: 'relative',
    height: px(64),
    width: px(64),
    backgroundColor: rebrand.contrast[20].toString(),
    borderRadius: borderRadius.SMALL,
    overflow: 'hidden',
  }),
  bannerLabel: style({
    position: 'absolute',
    top: 0,
    left: 0,
    width: px(32),
    height: px(29),
    backgroundImage: url(MintLabel),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderTopLeftRadius: 'inherit',
    zIndex: 1,
  }),
  bannerIcon: style({
    position: 'absolute',
    top: px(4),
    left: px(4),
  }),
  bannerText: style({
    fontSize: rem(0.75),
    display: 'flex',
    alignItems: 'center',
    gap: rem(0.25),
  }),
  button: style({
    display: 'inline-flex',
    alignItems: 'center',
    gap: rem(0.5),
  }),
  buttonsWrapper: style({
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
  }),
};

export type CheckoutUnsubscribedChannelStockTracksModalProps = ModalProps & {
  onRemoveTracks: () => void;
};

export class CheckoutUnsubscribedChannelStockTracksModal extends Component<CheckoutUnsubscribedChannelStockTracksModalProps> {
  render() {
    const { show, onRequestClose, onRemoveTracks } = this.props;
    return (
      <ScrollableOverlay className={styles.wrapper} show={show} onRequestClose={onRequestClose}>
        <div className={overlayStyles.header}>
          <h3 className={overlayStyles.title}>Royalty-free tracks in your cart</h3>
        </div>

        <div className={styles.body}>
          <div className={styles.bannerWrapper}>
            <div className={styles.banner}>
              <span className={styles.bannerLabel}>
                <CheckmarkCircle
                  size={16}
                  className={styles.bannerIcon}
                  color={rebrand.dark1.toString()}
                  checkColor={rebrand.highlight1.toString()}
                />
              </span>
            </div>
            <div className={styles.bannerText}>
              <span>Included</span>
              <CheckmarkCircle size={16} color={rebrand.dark1.toString()} />
            </div>
          </div>

          <p>
            One or more tracks in your cart are royalty-free tracks and only available with a subscription plan. Keep an
            eye out for the “Included” label which identifies music that is included in the subscription. Before placing
            your order, choose how you&apos;d like to proceed below
          </p>
        </div>

        <div className={styles.buttonsWrapper}>
          <Button
            prominence={ButtonProminence.SECONDARY}
            size={ButtonSize.MEDIUM}
            onClick={onRemoveTracks}
            data-test-remove-included-tracks-button
          >
            Remove tracks
          </Button>

          <Button
            prominence={ButtonProminence.PRIMARY}
            size={ButtonSize.MEDIUM}
            className={styles.button}
            href={ACCOUNT_PLANS_ROUTE}
            data-test-view-plans-checkout-unsubbed-modal-button
          >
            Subscribe to access
            <ArrowRight size={14} />
          </Button>
        </div>
      </ScrollableOverlay>
    );
  }
}
