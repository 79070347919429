import { classes, style } from 'typestyle';
import * as React from 'react';
import { percent } from 'csx';
import { flexRoot, wrap } from 'csstips';
import { HTMLAttributes } from 'react';

const styles = {
  tileGrid: style(flexRoot, wrap, {
    margin: '0 auto',
    listStyle: 'none',
    width: percent(100),
    padding: 0,
  }),
};

export type GridTileProps = HTMLAttributes<HTMLUListElement>;

export const GridTileList: React.SFC<GridTileProps> = ({ className, ...props }) => (
  <ul className={classes(styles.tileGrid, className)} {...props} />
);
