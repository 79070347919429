import { API, APIResponse } from '../types';
import { action } from 'mobx';
import {ChannelSchema, RatecardsSchema} from '../../../types/schema';

export class PricingAPIController extends API {
  @action
  getRateCards = async (withSubscription = false) => {
    const url = `/ratecards${withSubscription ? '?subscription_required=1' : ''}`;
    const resp = await this.request.get({ url });
    const json = await resp.json() as APIResponse<RatecardsSchema>;

    return json;
  };

  @action
  getPrice = async (channelId: string, currency: string) => {
    const url = `/ratecards`;
    const body = JSON.stringify({ channelId, currency });
    const resp = await this.request.post({ url, body });
    const json = await resp.json() as APIResponse<ChannelSchema>;

    return json;
  }
}