import {
  JAACKMAATE_UTM_CAMPAIGN,
  JAACKMAATE_UTM_MEDIUM,
  JAACKMAATE_UTM_SOURCE,
  SIGN_UP_ACTION_QUERY
} from '../constants';

export default async function setJaackMaateCampaign() {
  document.cookie = `utm_medium=${JAACKMAATE_UTM_MEDIUM}`;
  document.cookie = `utm_source=${JAACKMAATE_UTM_SOURCE}`;
  document.cookie = `utm_campaign=${JAACKMAATE_UTM_CAMPAIGN}`;

  window.location.href = `/?${SIGN_UP_ACTION_QUERY}&utm_source=podcast&utm_medium=referral&utm_campaign=jaackmaate-podcast`;
}