import Prismic from 'prismic-javascript';
import { action } from 'mobx';
import { RouterState } from 'react-router';
import { AffiliatePageModel } from './model';
import { PrismicController } from '../../prismic/controller';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import { ImageController } from '../../image/controller';
import { RouterModel } from '../../router/model';
import { stripQueryString } from '../../../lib/string';
import { ContentModel } from '../../content/model';

export class AffiliatePageController {
  constructor(
    private model: AffiliatePageModel,
    private prismic: PrismicController,
    private env: EnvModel,
    private ui: UIController,
    private router: RouterModel,
    private content: ContentModel,
    private image: ImageController
  ) {}

  @action
  redirectToHome = () => {
    return this.router.replace('/');
  };

  @action
  onEnter = async (nextState: RouterState) => {
    this.model.loading = true;
    // We no longer use a wildcard route with this page, Mike Russell is the only route used
    this.model.slug = 'mike-russell';

    if (this.model.slug) return this.onEnterPage();

    try {
      const predicates = [Prismic.Predicates.at('document.type', 'affiliate_page')];

      const [{ results }] = await Promise.all([this.prismic.query(predicates), this.env.ready]);

      if (results.length === 0) {
        this.redirectToHome();
      }
    } finally {
      this.model.loading = false;
    }
  };

  private onEnterPage = async () => {
    try {
      const predicates = [
        Prismic.Predicates.at('document.type', 'affiliate_page'),
        Prismic.Predicates.at('my.affiliate_page.slug', this.model.slug),
      ];

      const [{ results }] = await Promise.all([this.prismic.query(predicates), this.env.ready]);

      if (results.length === 0) {
        this.redirectToHome();
      }

      const { data } = results[0];

      this.ui.setTitle(`${data.page_title} ${this.content.seo.titleSuffix}`);
      this.ui.setDescription(data.document_meta_description);

      const sectionsSlice = data.body.find((slice: any) => slice.slice_type === 'body');
      const tracksSlice = data.body.find((slice: any) => slice.slice_type === 'featured_tracks');

      this.model.logo = this.image.preloadContent(data.affiliate_logo.url);
      this.model.ctaText = data.cta_text;
      this.model.headerText = data.header_text;
      this.model.source = data.affiliate_source;
      this.model.campaign = data.affiliate_campaign;
      this.model.medium = data.affiliate_source;

      this.model.sections = !sectionsSlice
        ? []
        : sectionsSlice.items.map((item: any) => ({
            heading: item.body_title[0].text,
            content: item.body_content,
          }));

      this.model.featuredTitle = tracksSlice.primary.tracks_title[0].text;

      this.model.featured = !tracksSlice
        ? []
        : tracksSlice.items.map((item: any) => ({
            image: this.image.preloadContent(item.artist_image.url),
            name: item.artist_name,
            subtext: item.artist_subtext,
            description: item.artist_track_description,
          }));
    } finally {
      this.model.loading = false;
    }
  };
}
