import { FavouritesPageModel } from './model';
import { action, autorun, runInAction } from 'mobx';
import { RedirectFunction, RouterState } from 'react-router';
import { PrismicController } from '../../prismic/controller';
import { BugsnagController } from '../../bugsnag/controller';
import { EnvModel } from '../../env/model';
import { APIController } from '../../api/controller';
import { UserModel } from '../../user/model';
import { PlaylistModel } from '../../playlist/model';
import { UIController } from '../../ui/controller';
import { CacheModel } from '../../cache/model';
import { ArtistlistModel } from '../../artistlist/model';
import { browserRegion } from '../../../components/project-happy/utilities/browserRegion';

export class FavouritesPageController {
  constructor(
    private model: FavouritesPageModel,
    private prismic: PrismicController,
    private bugsnag: BugsnagController,
    private env: EnvModel,
    private api: APIController,
    private user: UserModel,
    private playlist: PlaylistModel,
    private artistlist: ArtistlistModel,
    private ui: UIController,
    private cache: CacheModel
  ) {
    if (!env.isServer) {
      ui.addDisposer(
        autorun(() => {
          this.updateFilteredTracks();
        })
      );

      ui.addDisposer(
        autorun(() => {
          const favourites = artistlist.artists[env.artistlistFavourites];
          const artists = model.artists.map((t) => t.identity).filter((id) => favourites.indexOf(id) !== -1);

          const idMap = [...artists, ...favourites].reduce(
            (acc: any, id, index) => ({ ...acc, [id]: acc[id] || index }),
            {}
          );

          model.filteredArtists = Object.keys(idMap)
            .sort((a, b) => (idMap[a] < idMap[b] ? -1 : 1))
            .map((id) => cache.artist[id])
            .filter(Boolean);
        })
      );

      ui.addDisposer(
        autorun(() => {
          const { favouritePlaylists } = playlist;
          // This is quite a dumb check, but very unlikely to need any deeper check on this page
          if (favouritePlaylists.length >= model.playlists.length) return;
          const playlists = model.playlists.filter((item) => favouritePlaylists.includes(item.uuid));
          model.playlists = playlists;
          model.filteredPlaylists = playlists;
        })
      );
    }
  }

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    this.model.loading = true;
    this.model.tracksPage = 1;

    const document = 'favourites';

    this.ui.setBreadcrumbs([
      {
        path: '/account',
        label: 'Account',
      },
      {
        path: '/account/favourites',
        label: 'Favourites',
      },
    ]);

    await this.ui.setSEO(nextState.location.pathname);

    try {
      const [{ data }, tracks, artists, playlists] = await Promise.all([
        this.prismic.getSingle(document),
        this.api.playlist.getUserPlaylistTracks(
          this.user.user.identity,
          this.env.playlistFavourites,
          this.model.tracksPage,
          this.model.tracksPerPage,
          this.user.user ? this.user.user.country : null
        ),
        this.api.artistlist.getArtists(
          this.user.user.identity,
          this.env.artistlistFavourites,
          this.model.artistsPage,
          this.model.artistsPerPage
        ),
        this.api.playlist.getFavourites(this.user.user.identity),
        this.env.ready,
      ]);

      runInAction(() => {
        this.model.pageTitle = data.page_title;
        this.model.pageBody = data.page_body;
        this.model.noArtists = data.no_artists;
        this.model.noTracks = data.no_tracks;
        this.model.noFavourites = data.no_favourites;
        this.model.coverImage = data.cover_image.url;

        this.model.tracks = tracks.data;
        this.model.hasMoreTracks = tracks.data.length >= this.model.tracksPerPage;

        this.model.artists = artists.data;
        this.model.hasMoreArtists = artists.data.length >= this.model.artistsPerPage;

        this.model.playlists = playlists.data;
        this.model.filteredPlaylists = this.model.playlists; // We don't bother re-ordering the response
        this.model.hasMorePlaylists = playlists.meta.pagination.current_page === playlists.meta.pagination.total_pages;
      });
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      runInAction(() => {
        this.model.loading = false;
      });
    }

    return;
  };

  @action
  loadMoreTracks = async () => {
    if (!this.model.hasMoreTracks) return;

    runInAction(() => {
      this.model.loadingMoreTracks = true;
      this.model.tracksPage = this.model.tracksPage + 1;
    });

    try {
      const tracks = await this.api.playlist.getUserPlaylistTracks(
        this.user.user.identity,
        this.env.playlistFavourites,
        this.model.tracksPage,
        this.model.tracksPerPage,
        this.user.user ? this.user.user.country : null
      );

      this.playlist.tracks[this.env.playlistFavourites] = this.playlist.tracks[this.env.playlistFavourites].concat(
        tracks.data
          .map((t) => t.identity)
          .filter((id) => this.playlist.tracks[this.env.playlistFavourites].indexOf(id) === -1)
      );
      runInAction(() => {
        this.model.tracks = this.model.tracks.concat(tracks.data);
        this.model.hasMoreTracks = tracks.data.length >= this.model.tracksPerPage;
      });
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      this.updateFilteredTracks();
      runInAction(() => {
        this.model.loadingMoreTracks = false;
      });
    }
  };

  @action
  loadMoreArtists = async () => {
    if (!this.model.hasMoreArtists) return;

    runInAction(() => {
      this.model.loadingMoreArtists = true;
      this.model.artistsPage = this.model.artistsPage + 1;
    });

    try {
      const artists = await this.api.artistlist.getArtists(
        this.user.user.identity,
        this.env.artistlistFavourites,
        this.model.artistsPage,
        this.model.artistsPerPage
      );

      runInAction(() => {
        this.model.artists = this.model.artists.concat(artists.data);
        this.model.hasMoreArtists = artists.data.length >= this.model.artistsPage;
      });
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      runInAction(() => {
        this.model.loadingMoreArtists = false;
      });
    }
  };

  @action
  loadMorePlaylists = async () => {
    if (!this.model.hasMorePlaylists) return;

    runInAction(() => {
      this.model.loadingMorePlaylists = true;
      this.model.playlistsPage = this.model.playlistsPage + 1;
    });

    try {
      const playlists = await this.api.playlist.getFavourites(
        this.user.user.identity,
        this.model.playlistsPage,
        this.model.playlistsPerPage
      );

      runInAction(() => {
        this.model.playlists = this.model.playlists.concat(playlists.data);
        this.model.filteredPlaylists = this.model.playlists; // We don't bother re-ordering the response
        this.model.hasMoreArtists = playlists.meta.pagination.current_page === playlists.meta.pagination.total_pages;
      });
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      runInAction(() => {
        this.model.loadingMorePlaylists = false;
      });
    }
  };

  updateFilteredTracks() {
    const favourites = this.playlist.tracks[this.env.playlistFavourites];
    const tracks = this.model.tracks.map((t) => t.identity).filter((id) => favourites.indexOf(id) !== -1);

    const idMap = [...tracks, ...favourites].reduce((acc: any, id, index) => ({ ...acc, [id]: acc[id] || index }), {});

    this.model.filteredTracks = Object.keys(idMap)
      .sort((a, b) => (idMap[a] < idMap[b] ? -1 : 1))
      .map((id) => this.cache.track[id])
      .filter(Boolean);
  }
}
