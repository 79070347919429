/* NOTE: Both lines are required due to current React version */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/no-string-refs */
import * as React from 'react';
import { Component, deps, inject, observer } from '../lib/component';
import { ClientModel } from '../modules/client/model';
import { ClientController } from '../modules/client/controller';
import CheckoutCardList from '../components/checkout-v2/checkout-card-list';
import { CheckoutSummaryPanel } from '../components/checkout-v2/checkout-summary-panel';

import { styles } from '../components/checkout-v2/styles';
import { ImageModel } from '../modules/image/model';
import { EulaModal } from '../components/checkout-v2/eula-modal/eula-modal';
import { Loading } from '../components/loading/loading';
import { classes } from 'typestyle';
import CheckoutUserPanel from '../components/checkout-v2/checkout-user-panel';
import { ThingsYouNeedToKnowModal } from '../components/checkout-v2/things-you-need-to-know-modal';
import { TrackSchema } from '../types/schema';
import { ButtonPill } from '../components/button-pill';
import { unwrapMobxComponent } from '../lib/helpers';
import { BROWSE_ROUTE, SIGN_UP_ROUTE } from '../constants';
import { LOAD_STATE } from '../types/api';
import { ShoppingBasket } from '../components/project-happy/atoms/icons/ShoppingBasket';
import { isEmpty } from '../components/project-happy/utilities/objects';
import PaidIntegrityMismatchErrorModal from '../components/checkout-v2/paid-integrity-mismatch-error-modal';
import { CheckoutControllerErrors } from '../modules/checkout/controller';
import OrderErrorModal from '../components/checkout-v2/order-error-modal';
import { CheckoutUnsubscribedChannelStockTracksModal } from '../components/project-happy/organisms/CheckoutUnsubscribedChannelStockTracksModal';

export interface CheckoutContainerProps {
  model: ClientModel;
  controller: ClientController;
}

@inject(deps)
@observer
export class CheckoutV2Container extends Component<CheckoutContainerProps> {
  coverUrlProvider: ImageModel['cover'];

  checkoutSummary: HTMLElement;

  constructor(props) {
    super(props);

    this.coverUrlProvider = props.model.image.cover.bind(props.model.image.cover);

    this.state = {
      eula: false,
      tyntk: false,
      hasRightsholders: true,
      showChannelDetails: false,
      showEulaModal: false,
      showEssentialsModal: false,
      showMismatchModal: false,
      showUnknownErrorModal: false,
      showSavingsReminderModal: false,
      removingTracks: false,
      eulaTrack: null,
      showTYNTKModal: false,
      focussedTrack: null,
      isSubmitting: false,
    };

    this.showTYNTK = this.showTYNTK.bind(this);
    this.hideTYNTK = this.hideTYNTK.bind(this);
    this.showEula = this.showEula.bind(this);
    this.hideEula = this.hideEula.bind(this);
    this.showEssentialsModal = this.showEssentialsModal.bind(this);
    this.hideEssentialsModal = this.hideEssentialsModal.bind(this);
    this.removeEssentialsTracks = this.removeEssentialsTracks.bind(this);
    this.handleChannelToggle = this.handleChannelToggle.bind(this);
    this.handleRemoveTrack = this.handleRemoveTrack.bind(this);
  }

  componentDidMount() {
    // Sledgehammer way of ensuring we have the latest version of the basket
    this.props.controller.checkout.cancelOrder();
    this.checkForOrder();
  }

  componentDidUpdate(prevProps) {
    this.checkForOrder();

    const isLoggedIn = !isEmpty(this.props.model.user.user);
    const isChannelMissing =
      !isEmpty(this.props.model.user.user) && this.props.model.user.user.default_channel === null;

    if (isLoggedIn && isChannelMissing) {
      this.props.controller.modal.showAddChannelForModal();
    }
  }

  handleChannelToggle = (event) => {
    this.setState({ showChannelDetails: !this.state.showChannelDetails });
  };

  executeScroll = () =>
    this.checkoutSummary.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

  handleCheckout = async (event?: Event) => {
    event && event.preventDefault();

    const { user, checkout } = this.props.model;
    // Prompt the user to sign in before checking checkbox state
    if (!user.user) {
      this.props.model.router.push(SIGN_UP_ROUTE);
      return;
    }

    if (checkout.needsSubscription) {
      this.showEssentialsModal();
      return;
    }

    this.setState({
      isSubmitting: true,
    });

    try {
      await this.props.controller.checkout.goToDownload();
    } catch (error) {
      if (error instanceof Error && error.message === CheckoutControllerErrors.PAID_INTEGRITY_MISMATCH) {
        this.setState({ showMismatchModal: true });
        return;
      }
      this.setState({ showUnknownErrorModal: true });
    } finally {
      this.setState({
        isSubmitting: false,
      });
    }
  };

  handleCheckboxChange = (type: string, checked: boolean): void => {
    this.setState({ [type]: !checked });
  };

  async handleRemoveTrack(identity: string) {
    await this.props.controller.checkout.removeTrack(identity);
    this.props.controller.checkout.cancelOrder();
  }

  showEula(track: TrackSchema) {
    this.props.controller.page.checkout.loadEulaInfo(track);
    this.setState({
      showEulaModal: true,
      eulaTrack: track,
    });
  }

  hideEula() {
    this.setState({
      showEulaModal: false,
    });
  }

  showTYNTK(track?: TrackSchema) {
    this.setState({
      showTYNTKModal: true,
      focussedTrack: track,
    });

    let trackSummary;
    let wasGlobalTrigger = true;
    if (!isEmpty(track)) {
      const { identity, title, slug } = track;
      trackSummary = { identity, title, slug };
      wasGlobalTrigger = false;
    }
    this.props.controller.analytics.sendMixpanel('User clicks rightsholder modal', {
      track: trackSummary,
      wasGlobalTrigger,
    });
  }

  hideTYNTK() {
    this.setState({
      showTYNTKModal: false,
    });
  }

  showEssentialsModal() {
    this.setState({
      showEssentialsModal: true,
    });
  }

  hideEssentialsModal() {
    this.setState({
      showEssentialsModal: false,
    });
  }

  async removeEssentialsTracks() {
    const {
      checkout: { stockTracks },
    } = this.props.model;
    this.setState({
      removingTracks: true,
    });
    for (const track of stockTracks) {
      // We're purposely doing these removals in series to ensure state race hazards don't occur
      await this.props.controller.basket.removeFromBasket(track);
    }
    this.setState({
      removingTracks: false,
    });
    this.props.controller.checkout.deferredLoadOrder();
    this.hideEssentialsModal();
    this.props.controller.analytics.sendMixpanel('User removes Included tracks from basket');
  }

  checkForOrder() {
    const { model } = this.props;
    const { user, basket, coupon, checkout } = model;
    if (
      user.user &&
      basket.channel !== null &&
      basket.tracks.length > 0 &&
      !coupon.loading &&
      checkout.orderState === LOAD_STATE.EMPTY
    ) {
      this.props.controller.checkout.deferredLoadOrder();
    }
  }

  render() {
    const {
      eula,
      tyntk,
      showChannelDetails,
      showEulaModal,
      showEssentialsModal,
      showMismatchModal,
      showUnknownErrorModal,
      removingTracks,
      eulaTrack,
      showTYNTKModal,
      focussedTrack,
      isSubmitting,
    } = this.state;
    const { model } = this.props;
    const {
      user,
      basket,
      env,
      coupon: couponModel,
      checkout: checkoutModel,
      subscription: { trialLengthInDays },
    } = model;
    const { ready } = checkoutModel;
    const { handleClickLink } = this.props.controller.ui;
    const { analytics } = this.props.controller;

    if (env.isServer) return <Loading className={styles.loading} text="Loading basket..." />;

    if (basket.tracks.length === 0) {
      return (
        <div className={styles.empty}>
          <ShoppingBasket color="#2E2E3B" size={30} />
          <p data-test-empty-checkout-copy>Your basket is empty</p>
          <ButtonPill href={BROWSE_ROUTE} onClick={handleClickLink}>
            Browse music
          </ButtonPill>
        </div>
      );
    }

    if (user.user) {
      if (basket.channel === null) return <Loading className={styles.loading} text="Loading channel information..." />;
      if (couponModel.loading) return <Loading className={styles.loading} text="Loading coupon information..." />;
    }

    return (
      <div>
        <ThingsYouNeedToKnowModal
          show={showTYNTKModal}
          ratecard={basket.ratecard}
          track={focussedTrack}
          eulaContent={null}
          onRequestClose={this.hideTYNTK}
        />

        <EulaModal show={showEulaModal} title={'License Agreement'} track={eulaTrack} onRequestClose={this.hideEula} />

        {/* This modal is triggered when a user performs the following actions:
         1. Adds stock tracks to their basket from a subscribed channel.
         2. Switches to another unsubscribed channel associated with their account.
         3. Attempts to checkout. */}
        <CheckoutUnsubscribedChannelStockTracksModal
          show={showEssentialsModal}
          onRequestClose={this.hideEssentialsModal}
          onRemoveTracks={this.removeEssentialsTracks}
        />

        <PaidIntegrityMismatchErrorModal show={showMismatchModal} />

        <OrderErrorModal show={showUnknownErrorModal} />

        <div className={classes(styles.container, user.user && 'with-user')}>
          <CheckoutUserPanel
            className={styles.userInfo}
            handleSignIn={() => this.props.model.router.push(SIGN_UP_ROUTE)}
          />

          <CheckoutCardList
            basketModel={model.basket}
            coverUrlProvider={this.coverUrlProvider}
            onClickLink={handleClickLink}
            onRemoveTrack={this.handleRemoveTrack}
            handleEulaRequest={this.showEula}
            handleTYNTKRequest={this.showTYNTK}
            analytics={analytics}
            user={user}
          />

          <CheckoutSummaryPanel
            basketModel={model.basket}
            userModel={user}
            checkoutModel={checkoutModel}
            eula={eula}
            tyntk={tyntk}
            handleCheckboxChange={this.handleCheckboxChange}
            handleTYNTKRequest={this.showTYNTK}
            handleCheckout={this.handleCheckout}
            isSubmitting={isSubmitting}
            ref={(ref) => {
              const finalRef = unwrapMobxComponent(ref);
              const div = finalRef && finalRef.summaryDiv;
              if (div) {
                this.checkoutSummary = div;
              }
            }}
          />
        </div>
      </div>
    );
  }
}
