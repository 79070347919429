import * as React from 'react';
import { classes, style } from 'typestyle';
import { ModalProps } from '../../../modal';
import { rebrand } from '../../../../theme/color';
import { center, flexRoot } from 'csstips';
import { Component, deps, inject, observer } from '../../../../lib/component';
import { ScrollableOverlay } from '../../../scrollable-overlay/scrollable-overlay';
import overlayStyles from '../../../../styles/scrollable-overlay';
import { Button } from '../../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../../atoms/button/buttonStyles';
import { important, px } from 'csx';
import { mediaMobileOnly } from '../../utilities/mediaQueries';
import { StarCircle } from '../../atoms/icons/StarCircle';
import { spacing } from '../../../../theme/spacing';
import { CurvedArrowV2 } from '../../atoms/icons/CurvedArrowV2';
import { borderRadius } from '../../../../theme/border';
import { NestedCSSProperties } from 'typestyle/lib/types';

const sharedCreditLabelStyles: NestedCSSProperties = {
  padding: '12px 8px',
  borderRadius: borderRadius.MEDIUM,
  fontSize: px(16),
  fontWeight: 700,
  display: 'flex',
  width: '100%',
};

const sharedPlanLabelStyles: NestedCSSProperties = {
  textAlign: 'center',
  fontSize: '12px',
  marginTop: '4px',
};

const styles = {
  modal: style(center, {
    maxWidth: '550px',
  }),
  title: style({
    textAlign: 'center',
    fontSize: important('32px'),
  }),
  body: style({
    borderTop: `1px solid ${rebrand.light1.toString()}`,
    paddingTop: spacing.LARGE,
    minHeight: 'fit-content',
  }),
  creditsContainer: style(
    flexRoot,
    {
      gap: spacing.XSMALL,
      width: '100%',
      position: 'relative',
      $nest: {
        '& > div': {
          width: '100%',
        },
      },
    },
    mediaMobileOnly({
      display: 'block',
      padding: '0 12px',
      $nest: {
        '& > div:not(:last-child)': {
          marginBottom: '8px',
        },
      },
    })
  ),
  creditsNext: style(sharedCreditLabelStyles, {
    border: `1px solid ${rebrand.neutralOnDark['10'].toHexString()}`,
    backgroundColor: rebrand.neutralOnDark['10'].toHexString(),
    color: rebrand.dark1.toHexString(),
  }),
  creditsCurrent: style(sharedCreditLabelStyles, {
    border: `1px solid ${rebrand.neutralOnLight['20'].toHexString()}`,
    color: rebrand.neutralOnLight['60'].toHexString(),
  }),
  planLabelCurrent: style(sharedPlanLabelStyles, {
    color: rebrand.neutralOnLight[60].toHexString(),
  }),
  planLabelNext: style(sharedPlanLabelStyles, {
    color: rebrand.dark1.toHexString(),
    fontWeight: 700,
  }),
  arrow: style(
    {
      width: '64px',
      height: '36px',
      position: 'absolute',
      left: 'calc(50% - 32px)',
      bottom: px(-4),
      transform: 'scaleX(-1)',
      filter: 'brightness(0%)',
    },
    mediaMobileOnly({
      transform: 'rotate(270deg) scaleY(-1)',
      width: '48px',
      height: '24px',
      left: '-12px',
      bottom: '50%',
    })
  ),
  starCircle: style({
    marginRight: '8px',
    flexShrink: 0,
  }),
  infoText: style({
    marginTop: '24px',
    marginBottom: '0px',
    fontSize: '16px',
  }),
  actions: style({
    marginTop: '24px',
  }),
  actionBtn: style({
    width: '100%',
  }),
};

export type PreDowngradeModalProps = ModalProps;

@inject(deps)
@observer
export class PreDowngradeModal extends Component<PreDowngradeModalProps> {
  render() {
    const {
      show,
      onRequestClose,
      model: {
        user: { channel },
        subscription: { creditSubscriptionPlans },
        modal: { preDowngradeModalSelectedPlan: nextPlan },
      },
      controller: {
        subscription: { navigateToStripeSubscriptionChange },
      },
    } = this.props;

    const currentPlan =
      channel && channel.subscription
        ? creditSubscriptionPlans.find((plan) => plan.plan_name === channel.subscription.plan_name)
        : null;

    const handleConfirm = () => {
      if (!nextPlan) return;

      const { plan_name, stripe_id } = nextPlan;

      navigateToStripeSubscriptionChange(stripe_id, plan_name);
      onRequestClose();
    };

    return (
      <ScrollableOverlay className={styles.modal} show={show} onRequestClose={onRequestClose}>
        {nextPlan && currentPlan && (
          <>
            <div className={overlayStyles.header}>
              <h3 className={classes(overlayStyles.title, styles.title)}>DOWNGRADE TO {nextPlan.plan_name}</h3>
            </div>

            <div className={styles.body}>
              <div className={styles.creditsContainer}>
                <div>
                  <div className={styles.creditsNext}>
                    <StarCircle size={20} color={rebrand.dark1.toHexString()} className={styles.starCircle} />
                    {nextPlan.plan_credits} Premium track credits
                  </div>
                  <div className={styles.planLabelNext}>{nextPlan.plan_name} plan</div>
                </div>

                <div>
                  <CurvedArrowV2 className={styles.arrow} />

                  <div className={styles.creditsCurrent}>
                    <StarCircle
                      size={20}
                      color={rebrand.neutralOnLight['60'].toHexString()}
                      className={styles.starCircle}
                    />
                    {currentPlan.plan_credits} Premium track credits
                  </div>
                  <div className={styles.planLabelCurrent}>{currentPlan.plan_name} plan</div>
                </div>
              </div>

              <p className={styles.infoText}>
                Changes to billing and credits will take effect from your next billing date. You&apos;ll still be able
                to purchase additional Premium tracks at a slightly higher rate, or upgrade again anytime. You&apos;ll
                keep any remaining credits from your current plan.
              </p>
              <div className={styles.actions}>
                <Button
                  className={style({ width: '100%' })}
                  data-test-pre-upgrade-modal-go-to-payment
                  prominence={ButtonProminence.SECONDARY}
                  size={ButtonSize.LARGE}
                  onClick={handleConfirm}
                >
                  Confirm downgrade
                </Button>
              </div>
            </div>
          </>
        )}
      </ScrollableOverlay>
    );
  }
}
