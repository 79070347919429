import React from 'react';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { classes, style } from 'typestyle';
import { colorWhite, rebrand } from '../../../theme/color';
import { spacing } from '../../../theme/spacing';
import { px } from 'csx';
import { isEmpty } from '../utilities/objects';
import { formatNonDecimalCurrency } from '../../../lib/currency';
import { Tooltip } from '../atoms/Tooltip';
import { InfoFilled } from '../atoms/icons/InfoFilled';
import { flexRoot, start } from 'csstips';

interface RatecardDetailsProps extends ComponentProps {
  className?: string;
  isDashboard?: boolean;
}

const styles = {
  wrapper: style({
    color: rebrand.contrast[30].toString(),
    marginRight: spacing.XSMALL,
  }),
  subscriptionStatusText: style({
    fontWeight: 700,
    margin: 0,
    color: colorWhite.toString(),
  }),
  unsubscribedText: style({
    color: rebrand.semantic.warning['50'].toString(),
  }),
  subscribedActiveText: style({
    color: rebrand.secondary['50'].toString(),
  }),
  subscriptionDetails: style({
    fontSize: px(12),
    marginTop: spacing.XXSMALL,
  }),
  tooltip: style({
    backgroundColor: colorWhite.toHexString(),
    color: rebrand.contrast[40].toString(),
    fontSize: px(12),
    maxWidth: px(350),
    padding: spacing.DEFAULT,
    $nest: {
      '& strong': {
        color: rebrand.primary[100].toString(),
        fontWeight: 700,
      },
    },
  }),
  tooltipIcon: style({
    display: 'inline-block',
  }),
  tooltipInner: style(flexRoot, start, {
    gap: spacing.XSMALL,
  }),
  tooltipInfoIcon: style({
    flexShrink: 0,
  }),
};

export const RatecardDetails = inject(deps)(
  observer(
    ({
      className,
      isDashboard = false,
      model: {
        user: { channel, isSubscribed, isSubscribedToV2Plan, isSubscribedWithoutChannel, unsubscribedRatecard },
        subscription: { creditSubscriptionPlans },
        ui: { projectSubscriptionEnabled },
      },
    }: RatecardDetailsProps) => {
      const planType = isSubscribed && channel.subscription ? `${channel.subscription.plan_name} Plan` : 'Creator Plan';
      const additionalLicensePrice =
        channel && channel.ratecard
          ? formatNonDecimalCurrency(channel.ratecard.value, channel.ratecard.currency)
          : null;

      let planText = '';
      let licenceText = '';

      if (!projectSubscriptionEnabled) {
        // PRE PROJECT SUBSCRIPTION LAUNCH //

        // Subscribed channel with ratecard
        if (channel && channel.is_subscribed && additionalLicensePrice) {
          planText = `Premium tracks ${additionalLicensePrice} per license, unlimited royalty-free`;
        }

        // Unsubscribed channel with ratecard
        if (channel && !channel.is_subscribed && additionalLicensePrice) {
          planText = `Premium tracks ${additionalLicensePrice} per license, no royalty-free`;
        }

        // Subscribed user without a channel
        if (isSubscribedWithoutChannel) {
          planText = 'Premium tracks from $8 per license, unlimited royalty free';
        }

        // Unsubscribed user without a channel
        if (isEmpty(channel) && !isSubscribedWithoutChannel) {
          const unsubscribedPrice = !isEmpty(unsubscribedRatecard)
            ? formatNonDecimalCurrency(
                unsubscribedRatecard.value || unsubscribedRatecard.rate,
                unsubscribedRatecard.currency
              )
            : 'from $32';
          planText = `Premium tracks ${unsubscribedPrice} per license, no royalty free`;
        }
      } else {
        // POST PROJECT SUBSCRIPTION LAUNCH //

        // Subscribed channel with ratecard
        if (channel && channel.is_subscribed && additionalLicensePrice) {
          let credits = 0;

          if (channel.subscription && creditSubscriptionPlans.length) {
            const subscriptionPlan = creditSubscriptionPlans.find(
              (plan) => plan.plan_name === channel.subscription.plan_name
            );
            credits = subscriptionPlan ? subscriptionPlan.plan_credits : 0;
          }

          planText = `${credits} Premium track credit${credits !== 1 ? 's' : ''}, unlimited royalty-free`;
          licenceText = `Additional Premium licenses ${additionalLicensePrice} per track`;
        }

        // Unsubscribed channel with ratecard
        if (channel && !channel.is_subscribed && additionalLicensePrice) {
          planText = '0 Premium track credits, no royalty-free';
          licenceText = `Premium licenses ${additionalLicensePrice} per track`;
        }

        // Subscribed user without a channel
        if (isSubscribedWithoutChannel) {
          planText = '0 Premium track credits, unlimited royalty free';
          licenceText = 'Premium licenses from $8 per track';
        }

        // Unsubscribed user without a channel
        if (isEmpty(channel) && !isSubscribedWithoutChannel) {
          const unsubscribedPrice = !isEmpty(unsubscribedRatecard)
            ? formatNonDecimalCurrency(
                unsubscribedRatecard.value || unsubscribedRatecard.rate,
                unsubscribedRatecard.currency
              )
            : 'from $32';
          planText = '0 Premium track credits, no royalty-free';
          licenceText = `Premium licenses ${unsubscribedPrice} per track`;
        }
      }

      return (
        <div className={classes(styles.wrapper, className)}>
          <p className={classes(styles.subscriptionStatusText)}>
            {isSubscribed || isSubscribedWithoutChannel ? (
              <>
                {planType} {isSubscribedToV2Plan && <span className={styles.subscribedActiveText}>Active</span>}
                {projectSubscriptionEnabled && !isSubscribedToV2Plan && isDashboard && (
                  <Tooltip
                    className={styles.tooltip}
                    wrapperClassName={styles.tooltipIcon}
                    positions={['top', 'bottom']}
                    content={
                      <span className={styles.tooltipInner}>
                        <InfoFilled
                          size={20}
                          color={rebrand.primary[100].toString()}
                          className={styles.tooltipInfoIcon}
                        />
                        <span>
                          <strong>Check out our new subscription plans!</strong> They give you more choice and come with
                          Premium track credits as standard. Don&apos;t worry, your current plan is unaffected for now,
                          but you could be saving money on licenses, so head over to our new plans to take a look!
                        </span>
                      </span>
                    }
                  >
                    <InfoFilled size={12} color={rebrand.contrast[30].toString()} />
                  </Tooltip>
                )}
              </>
            ) : (
              <span className={styles.unsubscribedText}>Not subscribed</span>
            )}
          </p>

          <div className={styles.subscriptionDetails}>{planText}</div>
          {licenceText && !isDashboard && <div className={styles.subscriptionDetails}>{licenceText}</div>}
        </div>
      );
    }
  )
);
