import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalChildStyle } from './modal';
import { FormAddress, FormAddressModel } from './form-address';
import { TextNoOrphan } from './text-no-orphan';
import { px } from 'csx';
import { style } from 'typestyle';
import { FormAddressLookup } from './form-address-lookup';
import { mediaMobileOnly, mediaTablet } from '../theme/media';
import { horizontal, horizontallySpaced, vertical } from 'csstips';
import { LocationSuggestion } from '../types';

export interface FormAddressModalModel {
  show: boolean;
  form: FormAddressModel;
  edit: boolean;
}

export interface FormAddressModalProps {
  model: FormAddressModalModel;
  onRequestClose(): void;
  onChange(field: string, value: any): void;
  onSuggestion(suggestion: LocationSuggestion): void;
  onSubmit(): void;
  onCancel(): void;
  i18n?: {
    add: string;
    edit: string;
  };
}

const DEFAULT_I18N = {
  add: 'Add a billing address',
  edit: 'Edit a billing address',
};

export const FormAddressModal = observer(
  ({ i18n = DEFAULT_I18N, model, onRequestClose, onSuggestion, onChange, onSubmit, onCancel }: FormAddressModalProps) => (
    <Modal show={model.show} onRequestClose={onRequestClose}>
      {model.show && (
        <div className={styles.container}>
          <h3>
            <TextNoOrphan value={model.edit ? i18n.edit : i18n.add} />
          </h3>
          <div className={styles.split}>
            <FormAddressLookup autoFocus={true} onSuggestion={onSuggestion} />
            <FormAddress model={model.form} onChange={onChange} onSubmit={onSubmit} onCancel={onCancel} />
          </div>
        </div>
      )}
    </Modal>
  )
);

const styles = {
  container: style({
    ...ModalChildStyle,
  }),
  split: style(
    {
      $nest: {
        '&> *:first-child': {
          zIndex: 2,
        },
        '&> *:last-child': {
          zIndex: 1,
        },
      },
    },
    mediaMobileOnly({
      ...vertical,
    }),
    mediaTablet({
      ...horizontal,
      ...horizontallySpaced(30),
      $nest: {
        '& > *': {
          width: px(300),
        },
      },
    })
  ),
};
