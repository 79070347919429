import { observer } from 'mobx-react';
import * as React from 'react';
import { classes, style } from 'typestyle';
import { Component, deps, inject } from '../../../lib/component';
import {
  colorDarkBlueGrey,
  colorGreyAccent,
  colorGunmetal,
  colorLickdPink,
  colorWhite,
  rebrand,
} from '../../../theme/color';
import { resetAnchorStyles } from '../../../theme/reset';
import { TrackSchema } from '../../../types/schema';
import { ArtistLinkList } from '../../artist-link-list';
import { ButtonCircle } from '../../button-circle';
import { AddRemoveCompat } from '../../compat/add-remove-compat';
import { FavouriteButtonCompat } from '../../compat/favourite-button-compat';
import { Duration } from '../../duration';
import { CheckmarkCircle } from '../atoms/icons/CheckmarkCircle';
import { MoreHoriz } from '../atoms/icons/MoreHoriz';
import { StarCircle } from '../atoms/icons/StarCircle';
import { ImageWithPlayBtn } from '../atoms/ImageWithPlayBtn';
import { SubscriptionInfoModalLegacy } from '../organisms/SubscriptionInfoModalLegacy';
import {
  mediaDesktop,
  mediaDesktopTrackLine,
  mediaExtraSmallMobileTrackLine,
  mediaLargeDesktopTrackLine,
  mediaLargeMobileTrackLine,
  mediaLargeMobileUpwardsTrackLine,
  mediaMediumMobileTrackLine,
  mediaSmallMobileTrackLine,
  mediaTabletLandscapeTrackLine,
  mediaTabletPortraitTrackLine,
  mediaUpToDesktop,
  mediaUpToDesktopTrackLine,
  mediaUpToExtraSmallMobileTrackLine,
  mediaUpToLargeMobile,
  mediaUpToLargeMobileTrackLine,
  mediaUpToMediumMobileTrackLine,
  mediaUpToTabletLandscapeTrackLine,
  mediaUpToTabletPortraitTrackLine,
} from '../utilities/mediaQueries';
import { isArray, isEmpty } from '../utilities/objects';
import { Link } from '../atoms/Link';
import { important, px } from 'csx';
import { AddToPlaylistButton } from '../atoms/button/AddToPlaylistButton';
import { UserPlaylistContext } from '../pages/UserPlaylistPage';
import { RemoveFromPlaylistButton } from '../atoms/button/RemoveFromPlaylistButton';
import { pace } from '../utilities/functions';
import { TagButton } from '../atoms/button/TagButton';
import { Tooltip } from '../atoms/Tooltip';
import { Variations } from '../atoms/Variations';
import { desktopBreakpoint, responsiveTracklineBreakpoint } from '../config';
import { InfoOutline } from '../atoms/icons/InfoOutline';
import { ExplicitTag } from '../atoms/ExplicitTag';
import { SubscriptionInfoModal } from '../organisms/SubscriptionInfoModal';

const actionButtonsWidth = 160;
const actionButtonsMargin = 8;

const styles = {
  container: style(
    {
      display: 'grid',
      maxWidth: '1024px',
      minWidth: '315px',
      maxHeight: '56px',
      backgroundColor: colorWhite.toHexString(),
      gap: px(8),
      fontSize: '12px',
      alignItems: 'center',
      $nest: {
        '& a': {
          color: rebrand.dark1.toString(),
        },
        '& a:hover, & a:focus': {
          color: rebrand.dark2.toString(),
          textDecoration: 'underline',
        },
      },
    },
    mediaExtraSmallMobileTrackLine({ gridTemplateColumns: '48px auto auto auto auto !important' }),
    mediaSmallMobileTrackLine({ gridTemplateColumns: '48px auto auto auto 160px !important' }),
    mediaUpToLargeMobile({ gridTemplateColumns: '48px auto auto auto auto !important' }),
    mediaLargeMobileTrackLine({
      gridTemplateColumns: '48px 3fr 2fr 2fr 1fr auto auto !important',
    }),
    mediaTabletPortraitTrackLine({ gridTemplateColumns: '48px 4fr 3fr 2fr 2fr 1fr auto auto !important' }),
    mediaMediumMobileTrackLine({
      gridTemplateColumns: '48px 1fr 1fr auto auto !important',
    }),
    mediaTabletLandscapeTrackLine({
      gridTemplateColumns: `48px 1.5fr 0.4fr 1fr 1fr 1fr 0.5fr auto minmax(auto, 19.2%)`,
    }),
    mediaDesktopTrackLine({ gridTemplateColumns: `48px 1.5fr 0.4fr 1fr 1fr 1fr 0.5fr auto auto` }),
    mediaLargeDesktopTrackLine({ gridTemplateColumns: `56px 1.5fr 0.4fr 1fr 1fr 1fr 0.5fr auto minmax(auto, 19.2%)` })
  ),
  trackTitleContainer: style(
    mediaUpToDesktop({ height: '52px', marginLeft: '8px' }),
    mediaDesktop({
      lineHeight: 'normal',
    }),
    mediaLargeMobileUpwardsTrackLine({
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    }),
    {
      $nest: {
        '& p:first-child': {
          fontWeight: 600,
        },
        '& p': {
          margin: '0px',
        },
      },
    }
  ),
  trackTitle: style(
    mediaUpToDesktop({
      maxWidth: '379px',
    }),
    {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1.3,
    }
  ),
  paygPlanLabel: style({
    color: colorLickdPink.toHexString(),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  }),
  buttons: style(
    {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: px(8),
      paddingRight: px(actionButtonsMargin),
      minWidth: '125px',
    },
    mediaDesktop({
      justifyContent: 'flex-end',
    }),
    mediaUpToDesktop({
      justifyContent: 'flex-end',
    })
  ),
  addToPlaylistButton: style({
    backgroundColor: colorGreyAccent.toString(),
    boxShadow: 'none',
    $nest: {
      '&:hover, &:focus': {
        boxShadow: 'none !important',
      },
    },
  }),
  trackInfoBtn: style({
    backgroundColor: colorGreyAccent.toString(),
    $nest: {
      '&:hover': {
        backgroundColor: colorDarkBlueGrey.toString(),
      },
    },
  }),
  hideUpToDesktop: style(mediaUpToDesktop({ display: 'none' })),
  hideUpToDesktopTrackline: style(mediaUpToDesktopTrackLine({ display: 'none' })),
  hideUpToTabletPortraitTrackLine: style(mediaUpToTabletPortraitTrackLine({ display: 'none' })),
  hideUpToTabletLandscapeTrackLine: style(mediaUpToTabletLandscapeTrackLine({ display: 'none' })),
  hideDesktop: style(mediaDesktop({ display: 'none' })),
  hideExtraSmallScreens: style(
    mediaUpToExtraSmallMobileTrackLine({ display: 'none' }),
    mediaExtraSmallMobileTrackLine({ display: 'none' })
  ),
  hideMediumToLargeMobileScreens: style(
    mediaMediumMobileTrackLine({ display: 'none' }),
    mediaLargeMobileTrackLine({ display: 'none' })
  ),
  hideUpToMediumScreens: style(mediaUpToMediumMobileTrackLine({ display: 'none' })),
  hideUpToLargeScreens: style(mediaUpToLargeMobileTrackLine({ display: 'none' })),
  hideLargeScreensUpwards: style(mediaLargeMobileUpwardsTrackLine({ display: 'none' })),
  artistLinkList: style({ color: colorGunmetal.toString(), $nest: { ...resetAnchorStyles(colorGunmetal.toString()) } }),
  trackTypeLabel: style(mediaUpToDesktop({ position: 'relative', bottom: '4px' })),
  planLabel: style({ display: 'flex', alignItems: 'center', cursor: 'pointer' }),
  artistLinks: style(
    mediaUpToDesktop({
      maxWidth: '379px',
    }),
    mediaDesktop({ maxWidth: '252px' })
  ),
  variations: style({
    color: rebrand.neutralOnLight[80].toString(),
    $nest: {
      ...resetAnchorStyles(rebrand.neutralOnLight[80].toString(), true),
    },
  }),
  tagButton: style({
    minHeight: '24px',
    maxHeight: '24px',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    maxWidth: '84px',
    overflow: 'hidden',
    color: rebrand.neutralOnLight[80].toHexString(),
    backgroundColor: rebrand.neutralOnDark[10].toHexString(),
    cursor: 'default',
  }),
  toolTipStyles: style({
    backgroundColor: 'white',
    borderColor: 'white',
    color: rebrand.neutralOnLight[80].toHexString(),
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
  }),
  secondaryToolTipStyles: style({
    backgroundColor: rebrand.neutralOnDark[20].toHexString(),
    borderColor: rebrand.neutralOnDark[20].toHexString(),
    color: rebrand.contrast[50].toHexString(),
    borderRadius: px(4),
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
  }),
  customTooltipPosition: style({
    transform: 'translateY(-4px) translateX(-8px) !important',
    $nest: {
      '&::after': {
        left: 'calc(12% - 4px) !important',
      },
    },
  }),
  variationContainer: style({
    position: 'relative',
    left: '32%',
    bottom: '8px',
  }),
  fadeToWhite: style({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    position: 'relative',
    $nest: {
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        width: '20px',
        height: '100%',
        background: 'linear-gradient(to right, rgba(255, 255, 255, 0), white 100%)',
        pointerEvents: 'none',
      },
    },
  }),
  unavailableTrackColor: style({
    color: important(rebrand.neutralOnLight[20].toString()),
  }),
  unavailableTrackPlanColor: style({
    color: important(rebrand.primary[20].toString()),
  }),

  disableShadow: style({
    boxShadow: 'none',
    $nest: {
      '&:hover, &:focus': {
        boxShadow: 'none !important',
      },
    },
  }),
  boldFont: style({
    fontWeight: 600,
  }),
  variationToolTip: style({
    bottom: px(8),
  }),
};

export interface TrackLineProps {
  track: TrackSchema;
  onClickLink?(event: React.MouseEvent<any>): any;
  onAddTrackToPlaylist?(): void;
  onRemoveTrackFromPlaylist?(): void;
  showFilter?: boolean;
  className?: string;
  mix?: boolean;
}

type State = {
  showSubscriptionLabelInfo: boolean;
  condensedActions: boolean;
  showElements: boolean;
};

@observer
@inject(deps)
export class TrackLine extends Component<TrackLineProps> {
  state: State = {
    showSubscriptionLabelInfo: false,
    condensedActions: false,
    showElements:
      window.innerWidth >= desktopBreakpoint ||
      (this.props.showFilter && window.innerWidth >= desktopBreakpoint) ||
      (!this.props.showFilter && window.innerWidth < desktopBreakpoint),
  };

  actionButtonsRef: HTMLDivElement = null;
  resizeHandler: () => void;
  resizeObserver: ResizeObserver;

  constructor(props) {
    super(props);
    this.resizeHandler = pace(() => this.calculateCondensedActions(), 250);
    if (typeof window.ResizeObserver === 'function') {
      this.resizeObserver = new ResizeObserver(this.resizeHandler);
    }
    window.addEventListener('resize', this.resizeHandler, false);
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
    window.removeEventListener('resize', this.resizeHandler);
    this.resizeHandler = null;
  }

  componentDidMount() {
    if (this.resizeObserver && this.actionButtonsRef) {
      this.resizeObserver.observe(this.actionButtonsRef);
    }
    this.calculateCondensedActions();
  }

  componentDidUpdate(prevProps: TrackLineProps) {
    // Check if showFilter prop has changed
    if (this.props.showFilter !== prevProps.showFilter) {
      // Update showElements based on conditions
      const showElements =
        window.innerWidth >= responsiveTracklineBreakpoint ||
        (this.props.showFilter && window.innerWidth >= responsiveTracklineBreakpoint) ||
        (!this.props.showFilter && window.innerWidth < responsiveTracklineBreakpoint);

      if (showElements !== this.state.showElements) {
        this.setState({ showElements });
      }
    }

    this.calculateCondensedActions();
  }

  calculateCondensedActions() {
    const { condensedActions } = this.state;
    // CSS Container Queries will replace the need for this in the future, but they just don't have the support for now
    // Determine whether the action buttons width is large enough to house all buttons or not
    const newCondensedActions = this.actionButtonsRef && this.actionButtonsRef.clientWidth < actionButtonsWidth;
    if (condensedActions !== newCondensedActions) {
      this.setState({ condensedActions: newCondensedActions });
    }
  }

  render() {
    const {
      track,
      onClickLink,
      className,
      mix,
      onAddTrackToPlaylist,
      onRemoveTrackFromPlaylist,
      controller: {
        analytics: { sendMixpanel },
      },
      model: {
        user: { user, channel },
        ui: { projectSubscriptionEnabled },
      },
    } = this.props;

    const { showSubscriptionLabelInfo, condensedActions, showElements } = this.state;
    const trackIsAvailable = track.is_available_in_territory === null ? true : track.is_available_in_territory;

    const genreTags = track.tags ? track.tags.musiio_genre : [];
    const moodTags = track.tags ? track.tags.musiio_mood : [];

    const firstGenreTag = isArray(genreTags) && genreTags.length > 0 ? genreTags[0].name : '';
    const firstMoodTag = isArray(moodTags) && moodTags.length > 0 ? moodTags[0].name : '';

    const formatTags = (tags: Array<{ name: string }>) =>
      isArray(tags) && tags.length > 0
        ? tags
            .slice(0, 3)
            .map((tag) => tag.name)
            .join(', ')
        : '';

    const firstThreeGenreTags = formatTags(genreTags);
    const firstThreeMoodTags = formatTags(moodTags);

    // Project subscription feature flag - delete post-launch **PROJECT_SUB_FLAG**
    const useNewModal = projectSubscriptionEnabled;

    return (
      <div
        className={classes(styles.container, className)}
        data-test-track-line={track.identity}
        data-test-is_available_in_territory={trackIsAvailable}
      >
        {useNewModal ? (
          <SubscriptionInfoModal
            show={showSubscriptionLabelInfo}
            payg={!track.is_stock_music}
            channel={channel}
            onRequestClose={() => this.setState({ showSubscriptionLabelInfo: false })}
          />
        ) : (
          <SubscriptionInfoModalLegacy
            show={showSubscriptionLabelInfo}
            payg={!track.is_stock_music}
            onRequestClose={() => this.setState({ showSubscriptionLabelInfo: false })}
          />
        )}

        <ImageWithPlayBtn track={track} small={true} />
        <div className={styles.trackTitleContainer}>
          <p className={classes(styles.trackTitle, styles.fadeToWhite)}>
            {track.is_explicit ? <ExplicitTag /> : null}

            <Tooltip
              className={classes(styles.secondaryToolTipStyles, styles.boldFont, styles.customTooltipPosition)}
              positions={['top']}
              align={'start'}
              content={
                <div>
                  <span>{track.title}</span>
                </div>
              }
            >
              <Link
                href={`/music/artists/${track.artists[0].slug}/track/${track.slug}`}
                onClick={onClickLink}
                className={!trackIsAvailable ? styles.unavailableTrackColor : undefined}
                data-test-track-title
              >
                {track.title}
              </Link>
            </Tooltip>
          </p>
          <div>
            <Tooltip
              className={classes(styles.secondaryToolTipStyles, styles.customTooltipPosition)}
              positions={['top']}
              align={'start'}
              artists={track.artists}
              content={
                <div>
                  {track.artists.map((artist, index) => (
                    <span key={index} style={{ display: 'inline', margin: '2px 0' }}>
                      {artist.name}
                      {index < track.artists.length - 1 ? ', ' : ''}
                    </span>
                  ))}
                </div>
              }
            >
              <ArtistLinkList
                model={{ artists: track.artists }}
                onClickLink={onClickLink}
                linkClassName={classes(styles.artistLinkList, !trackIsAvailable && styles.unavailableTrackColor)}
                className={classes(styles.artistLinks, styles.fadeToWhite)}
              />
            </Tooltip>
          </div>
          <div className={classes(styles.trackTypeLabel, styles.hideDesktop, styles.hideLargeScreensUpwards)}>
            {track.is_stock_music ? (
              <div
                className={styles.planLabel}
                data-test-track-catalogue-type="paid"
                onClick={() => this.setState({ showSubscriptionLabelInfo: true })}
              >
                Included
                <CheckmarkCircle
                  color={rebrand.dark1.toString()}
                  checkColor={rebrand.highlight1.toString()}
                  style={{ marginLeft: '4px' }}
                  size={15}
                />
              </div>
            ) : (
              <div
                className={classes(styles.paygPlanLabel, !trackIsAvailable && styles.unavailableTrackPlanColor)}
                data-test-track-catalogue-type="payg"
                onClick={() => this.setState({ showSubscriptionLabelInfo: true })}
              >
                Premium{' '}
                <StarCircle
                  color={!trackIsAvailable ? rebrand.primary[20].toString() : colorLickdPink.toHexString()}
                  style={{ marginLeft: '4px' }}
                />
              </div>
            )}
          </div>
        </div>
        <div className={classes(styles.variations, styles.hideUpToTabletLandscapeTrackLine)}>
          {!mix && track.mixes_count > 0 && (
            <Tooltip
              className={classes(styles.secondaryToolTipStyles, styles.variationToolTip)}
              positions={['top']}
              content={<span>See all versions</span>}
            >
              <div className={styles.variationContainer} onClick={this.handleClickVariation} data-test-variations>
                <Link
                  href={`/music/artists/${track.artists[0].slug}/track/${track.slug}`}
                  onClick={onClickLink}
                  className={styles.variations}
                >
                  <Variations number={track.mixes_count} />
                </Link>
              </div>
            </Tooltip>
          )}
        </div>
        <div className={classes(styles.hideUpToTabletPortraitTrackLine)}>
          {track.releases && (
            <p className={styles.fadeToWhite}>
              {!isEmpty(track.releases[0]) && (
                <Tooltip
                  className={classes(styles.secondaryToolTipStyles, styles.customTooltipPosition)}
                  positions={['top']}
                  align={'start'}
                  content={<span>{track.releases[0].title}</span>}
                >
                  <Link
                    href={`/music/artists/${track.releases[0].artist.slug}/release/${track.releases[0].slug}`}
                    className={!trackIsAvailable ? styles.unavailableTrackColor : undefined}
                    data-test-track-release
                  >
                    {track.releases[0].title}
                  </Link>
                </Tooltip>
              )}
            </p>
          )}
        </div>
        <div>
          {firstGenreTag && (
            <Tooltip
              positions={['top']}
              content={
                <span style={{ maxWidth: '12ch' }} data-test-track-line-genre-tool-tip>
                  {firstThreeGenreTags}
                </span>
              }
              className={styles.toolTipStyles}
            >
              {showElements && (
                <TagButton
                  onClick={() => {
                    sendMixpanel('User clicks trackline genre tag', { tag: firstGenreTag });
                  }}
                  text={firstGenreTag}
                  className={classes(
                    styles.tagButton,
                    styles.hideUpToMediumScreens,
                    condensedActions && styles.hideDesktop
                  )}
                  data-test-track-line-genre-tag={firstGenreTag}
                />
              )}
            </Tooltip>
          )}
        </div>

        <div>
          {firstMoodTag && (
            <Tooltip
              positions={['top']}
              content={
                <span style={{ maxWidth: '12ch' }} data-test-track-line-genre-tool-tip>
                  {firstThreeMoodTags}
                </span>
              }
              className={styles.toolTipStyles}
            >
              {showElements && (
                <TagButton
                  onClick={() => {
                    sendMixpanel('User clicks trackline genre tag', { tag: firstMoodTag });
                  }}
                  text={firstMoodTag}
                  className={classes(
                    styles.tagButton,
                    styles.hideUpToLargeScreens,
                    condensedActions && styles.hideDesktop
                  )}
                  data-test-track-line-mood-tag={firstMoodTag}
                />
              )}
            </Tooltip>
          )}
        </div>
        <div
          className={classes(
            styles.hideUpToTabletLandscapeTrackLine,
            !trackIsAvailable && styles.unavailableTrackColor
          )}
          data-test-track-duration
        >
          <Duration value={track.duration} />
        </div>
        <div className={classes(styles.hideUpToLargeScreens)}>
          {track.is_stock_music ? (
            <div
              className={classes(styles.planLabel, condensedActions && styles.hideDesktop)}
              data-test-track-catalogue-type="paid"
              onClick={() => this.setState({ showSubscriptionLabelInfo: true })}
            >
              <p className={classes(styles.hideUpToDesktopTrackline)}>Included</p>{' '}
              <CheckmarkCircle
                color={rebrand.dark1.toString()}
                checkColor={rebrand.highlight1.toString()}
                style={{ marginLeft: '4px' }}
                size={15}
              />
            </div>
          ) : (
            <div
              className={classes(styles.paygPlanLabel, condensedActions && styles.hideDesktop)}
              data-test-track-catalogue-type="payg"
              onClick={() => this.setState({ showSubscriptionLabelInfo: true })}
            >
              <p
                className={classes(
                  styles.hideUpToDesktopTrackline,
                  !trackIsAvailable && styles.unavailableTrackPlanColor
                )}
              >
                Premium
              </p>{' '}
              <StarCircle
                color={!trackIsAvailable ? rebrand.primary[20].toString() : colorLickdPink.toHexString()}
                style={{ marginLeft: '4px' }}
              />
            </div>
          )}
        </div>

        <div className={styles.buttons} ref={(ref) => (this.actionButtonsRef = ref)}>
          {trackIsAvailable && (
            <>
              <AddRemoveCompat className={styles.disableShadow} track={track} size={32} />
              {showElements && (
                <FavouriteButtonCompat
                  className={classes(
                    styles.hideExtraSmallScreens,
                    styles.disableShadow,
                    condensedActions && styles.hideDesktop
                  )}
                  track={track}
                  size={32}
                />
              )}
            </>
          )}
          {!trackIsAvailable && (
            <>
              <Tooltip
                className={styles.secondaryToolTipStyles}
                positions={['top']}
                content={<span> Not available in your region</span>}
              >
                <InfoOutline />
              </Tooltip>
              {/* This is intensionally commented, comment will be removed when notify api is ready */}
              {/* <NotifyButton /> */}
            </>
          )}

          {/* Add/remove from playlist buttons */}
          <UserPlaylistContext.Consumer>
            {(playlist) => {
              // If there's no user, show no buttons
              if (isEmpty(user)) {
                return null;
              }
              // If there's no playlist, or the playlist belongs to another user, show the add to playlist button
              if (isEmpty(playlist) || (playlist.user_uuid && user.identity !== playlist.user_uuid)) {
                return (
                  <>
                    {trackIsAvailable && (
                      <AddToPlaylistButton
                        className={styles.addToPlaylistButton}
                        track={track}
                        onAddToPlaylist={onAddTrackToPlaylist}
                      />
                    )}
                  </>
                );
              }
              // If the current user owns the current playlist, show the remove from playlist button
              if (playlist.user_uuid && user.identity === playlist.user_uuid) {
                return (
                  <RemoveFromPlaylistButton track={track} playlist={playlist} onClick={onRemoveTrackFromPlaylist} />
                );
              }
              // If none of the conditions above are met, show nothing
              return null;
            }}
          </UserPlaylistContext.Consumer>

          <ButtonCircle
            className={styles.trackInfoBtn}
            size={32}
            onClick={this.handleClickTrackInfo}
            data-test-track-info
            aria-label="View more information about this track"
          >
            <MoreHoriz color={colorGunmetal.toString()} />
          </ButtonCircle>
        </div>
      </div>
    );
  }

  handleClickTrackInfo = (event: React.MouseEvent<any>): void => {
    this.props.controller.modal.showSongInfoModal(this.props.track);
  };
  handleClickVariation = (event: React.MouseEvent<any>): void => {
    this.props.controller.analytics.sendMixpanel('user clicks link variation');
  };
}
