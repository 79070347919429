import { EnvModel } from '../env/model';
import { action, autorun, IReactionDisposer } from 'mobx';
import { RouterModel } from '../router/model';
import { AuthModel, USER } from '../auth/model';
import { BasketModel } from '../basket/model';
import { ContentModel } from '../content/model';
import { CouponModel } from '../coupon/model';
import { LicenceSchema, OrderSchema, TrackSchema } from '../../types/schema';
import { humanTrackName } from '../../lib/string';
import { User } from '../../types/user';
import KeenTracking from 'keen-tracking';
import { YoutubeChannel } from '../../types/graphql';
import { BugsnagController } from '../bugsnag/controller';
import { StorageModel } from '../storage/model';
import { Mixpanel } from '../../mixpanel';
import { Dict } from 'mixpanel-browser';
import { UserModel } from '../user/model';
import { SearchPageModel } from '../page/search/model';
import { SEARCH_ROUTE } from '../../constants';
import { AnyObject, isEmpty } from '../../components/project-happy/utilities/objects';
import appcues from '../../appcues';

export class AnalyticsController {
  private dataLayer: any[];
  private hsq: any[];
  private common: any;
  private ecommerce: any;
  private keen: KeenTracking;

  constructor(
    private env: EnvModel,
    private router: RouterModel,
    private auth: AuthModel,
    private search: SearchPageModel,
    private basket: BasketModel,
    private content: ContentModel,
    private coupon: CouponModel,
    private bugsnag: BugsnagController,
    private mixpanel: Mixpanel,
    private user: UserModel
  ) {
    this.dataLayer = env.isServer ? [] : (window as any).dataLayer;
    this.hsq = (window as any)._hsq;
    this.common = {};
    this.ecommerce = {};
    this._disposers = [];
    this.keen = new KeenTracking({
      projectId: env.keenProjectId,
      writeKey: env.keenWriteKey,
    });
  }

  private _disposers: IReactionDisposer[];

  dispose = () => {
    this._disposers.forEach((dispose) => dispose());
  };

  @action
  init = () => {
    let first = true;
    let lastLocation: string;

    this._disposers.push(
      autorun(() => {
        const { pathname } = this.router.location;
        if (pathname && pathname !== lastLocation) {
          if (!first) {
            this.sendGA({
              event: 'VirtualPageview',
              virtualPageURL: pathname,
            });

            this.sendHS(['setPath', pathname]);
            this.sendHS(['trackPageView']);
          }

          first = false;
          lastLocation = pathname;
        }
      })
    );
    this._disposers.push(
      autorun(() => {
        const { user } = this.auth;
        const isLoggedIn = !isEmpty(this.user.user);

        if (isLoggedIn) {
          appcues.identify(user.identity, {
            onboardingState: user.onboarding_state,
            campaign: user.campaign,
            medium: user.medium,
            source: user.source,
          });
          this.mixpanel.identify(user.identity);
        }
      })
    );

    this._disposers.push(
      autorun(() => {
        const { user } = this.auth;
        this.common[variables.userId] = user ? user.identity : NULL_VALUE;
      })
    );
    this._disposers.push(
      autorun(() => {
        const { activeSubscription } = this.user;
        if (activeSubscription) {
          this.common[variables.activeSubscription] = { ...activeSubscription, subscriptionId: activeSubscription.id };
        } else {
          delete this.ecommerce[variables.activeSubscription];
        }
      })
    );

    this._disposers.push(
      autorun(() => {
        const { isSubscribed } = this.user;
        this.common[variables.hasSubscription] = isSubscribed;
      })
    );

    this._disposers.push(
      autorun(() => {
        const { currency } = this.basket;
        if (currency) {
          this.ecommerce[variables.currencyCode] = currency;
        } else {
          delete this.ecommerce[variables.currencyCode];
        }
      })
    );

    this._disposers.push(
      autorun(() => {
        const { ratecardId } = this.basket;
        this.common[variables.rateCardId] = ratecardId || NULL_VALUE;
      })
    );

    this._disposers.push(
      autorun(() => {
        const { channelName } = this.basket;
        this.common[variables.channelName] = channelName || NULL_VALUE;
      })
    );

    this._disposers.push(
      autorun(() => {
        const { channelCountry } = this.basket;
        this.common[variables.channelCountry] = channelCountry || NULL_VALUE;
      })
    );
  };

  @action
  sendGA = (event: any) => {
    const existingUser = this.user.user;

    // Don't fire any GA events for members of staff so we don't mess up tracking data
    if (existingUser && existingUser.is_staff) {
      return;
    }

    // Clear ecommerce value between calls
    this.dataLayer.push({ ecommerce: null });

    const data = {
      event: 'GAEvent',
      eventCategory: void 0, // Reset each of these for each hit, so we dont carry values over
      eventAction: void 0,
      eventLabel: void 0,
      ...this.common,
      ...event,
    };
    this.dataLayer.push(data);
  };

  @action
  sendMixpanel = (event: string, props?: Dict, callback?: () => void) => {
    const commonProps: AnyObject = { distinct_id: this.common.userId, ...this.common.activeSubscription };
    const { query } = this.search.state;
    if (this.router.location.pathname === SEARCH_ROUTE && query.length > 0) {
      commonProps.searchQuery = query;
    }
    this.mixpanel.track(event, { ...props, ...commonProps }, callback);
  };

  @action
  sendKlaviyo = (event: string, trackName: string, artistName: string, trackId: string, userEmail?: string): void => {
    const options = {
      method: 'POST',
      headers: { Accept: 'text/html', 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(({
        data: `{"token": "${this.env.klaviyoPublicKey}", "event": "${event}", "customer_properties": {"$email": "${userEmail}"}, "properties": {"track_name": "${trackName}","artist_name": "${artistName}", "track_identity": "${trackId}"}}`,
      } as any) as URLSearchParams),
    };

    fetch('https://a.klaviyo.com/api/track', options)
      .then((response) => response.json())
      .catch((err) => console.error(err));
  };

  @action
  sendHS = (data: any) => {
    this.hsq.push(data);
  };

  @action
  sendKeen = (category: string, data: any) => {
    this.keen.recordEvent(category, data);
  };

  @action
  sendChannelReferralVisit = (channel: YoutubeChannel) => {
    this.sendKeen(this.env.keenChannelReferralVisit, {
      channel_id: channel.id,
      channel_name: channel.name,
    });
  };

  @action
  sendChannelReferralClick = (channel: YoutubeChannel) => {
    this.sendKeen(this.env.keenChannelReferralClick, {
      channel_id: channel.id,
      channel_name: channel.name,
    });
  };

  @action
  sendAppSearch = (query: string) => {
    this.sendGA({
      eventCategory: 'Search',
      eventAction: 'App',
      eventLabel: query,
    });
  };

  @action
  sendAppSearchFilter = (filter: string, value: any) => {
    const isRemove = value === null || typeof value === 'undefined';

    this.sendGA({
      eventCategory: 'Search',
      eventAction: `${isRemove ? 'remove' : 'add'}Filter(${filter})`,
      eventLabel: isRemove ? void 0 : value,
    });
  };

  @action
  sendHeroArtistSearch = (query: string) => {
    this.sendGA({
      eventCategory: 'Search',
      eventAction: 'Hero artist',
      eventLabel: query,
    });
  };

  @action
  sendHeroGenreSearch = async (genre: string) => {
    await this.env.ready;

    this.sendGA({
      eventCategory: 'Search',
      eventAction: 'Hero genre',
      eventLabel: this.getGenreLabel(genre) || genre,
    });
  };

  private getGenreLabel = (slug: string) => {
    const genre = this.content.genres.find((g) => g.slug === slug);
    return genre ? genre.label : void 0;
  };

  @action
  sendHeroThemeSearch = async (theme: string) => {
    await this.env.ready;

    this.sendGA({
      eventCategory: 'Search',
      eventAction: 'Hero theme',
      eventLabel: this.getThemeLabel(theme) || theme,
    });
  };

  private getThemeLabel = (slug: string) => {
    const theme = this.content.themes.find((t) => t.slug === slug);
    return theme ? theme.label : void 0;
  };

  @action
  sendTrackPageVisit = (track: TrackSchema): void => {
    this.sendKlaviyo(
      'User visits track page',
      track.title,
      track.artists[0].name,
      track.identity,
      this.user.user ? this.user.user.email : 'usernotloggedin@notloggedin.co'
    );
  };

  @action
  sendTrackPlay = (track: TrackSchema): void => {
    this.sendKlaviyo(
      'User plays track',
      track.title,
      track.artists[0].name,
      track.identity,
      this.user.user ? this.user.user.email : 'usernotloggedin@notloggedin.co'
    );

    this.sendGA({
      event: 'productClick',
      eventCategory: 'Player',
      eventAction: 'Play',
      eventLabel: humanTrackName(track),
      ecommerce: {
        ...this.ecommerce,
        click: {
          products: this.getTracksWithDiscounts([track], false),
        },
      },
    });
    this.sendMixpanel('User plays track', {
      identity: track.identity,
      title: track.title,
      slug: track.slug,
    });
  };

  @action
  sendHubspotContact = async (user: User) => {
    await this.env.ready;

    this.sendHS([
      'identify',
      {
        email: user.email,
        primary_genre_choice: this.getGenreLabel(user.preferred_genre),
        marketing_allowed: user.marketing_allowed,
      },
    ]);

    this.sendHS(['setPath', '/login']);
    this.sendHS(['trackPageView']);
  };

  @action
  sendSignup = (user: User) => {
    this.sendGA({
      eventCategory: 'User',
      eventAction: 'Registered',
      eventLabel: user.identity,
    });

    this.sendHubspotContact(user);
  };

  @action
  sendLogin = (user: User) => {
    this.sendGA({
      eventCategory: 'User',
      eventAction: 'Logged In',
      eventLabel: user.identity,
    });

    this.sendHubspotContact(user);
  };

  @action
  sendAddToBasket = (track: TrackSchema): void => {
    this.sendGA({
      event: 'addToCart',
      eventCategory: 'Checkout',
      eventAction: 'Add to basket',
      eventLabel: humanTrackName(track),
      ecommerce: {
        ...this.ecommerce,
        add: {
          products: this.getTracksWithDiscounts([track], false),
        },
      },
    });
  };

  @action
  sendRemoveFromBasket = (track: TrackSchema) => {
    this.sendGA({
      event: 'removeFromCart',
      eventCategory: 'Checkout',
      eventAction: 'Remove from basket',
      eventLabel: humanTrackName(track),
      ecommerce: {
        ...this.ecommerce,
        remove: {
          products: this.getTracksWithDiscounts([track], false),
        },
      },
    });
  };

  @action
  sendCheckoutStep0 = async (tracks: TrackSchema[]) => {
    await this.coupon.currentPromise;

    this.sendGA({
      event: 'checkout',
      eventCategory: 'Checkout',
      eventAction: 'Review order',
      ecommerce: {
        ...this.ecommerce,
        checkout: {
          actionField: { step: 1 },
          products: this.getTracksWithDiscounts(tracks, true),
        },
      },
    });
  };

  @action
  sendCheckoutStep1 = async (tracks: TrackSchema[]) => {
    await this.coupon.currentPromise;

    this.sendGA({
      event: 'checkout',
      eventCategory: 'Checkout',
      eventAction: 'Payment details',
      ecommerce: {
        ...this.ecommerce,
        checkout: {
          actionField: { step: 2 },
          products: this.getTracksWithDiscounts(tracks, true),
        },
      },
    });
  };

  @action
  sendCheckoutStep2 = async (order: OrderSchema) => {
    await this.coupon.currentPromise;

    this.sendGA({
      event: 'checkout',
      eventCategory: 'Checkout',
      eventAction: 'Confirm order',
      ecommerce: {
        ...this.ecommerce,
        checkout: {
          actionField: { step: 3 },
          products: this.getLicences(order.licences),
        },
      },
    });
  };

  @action
  sendPaidOrderCompleteToGa = (order: OrderSchema) => {
    const id = order.identity;
    const total = Number(order.amount.total);

    const { pathname } = this.router.location;

    if (pathname !== '/checkout/download') return;

    this.sendGA({
      eventCategory: 'Checkout',
      eventAction: 'Payment Complete',
      eventLabel: '/checkout/download',
      ecommerce: {
        purchase: {
          actionField: {
            id: id,
            affiliation: 'Online Store',
            revenue: total,
            currency: order.locale.currency,
            coupon: this.coupon.type,
          },
          products: this.getLicences(order.licences),
        },
      },
    });

    return true;
  };

  private getTracksWithDiscounts = (tracks: Array<TrackSchema>, applyDiscount = false): Array<Product> => {
    const { ratecardId, ratecardValue } = this.basket;
    const { type, discount } = this.coupon;

    return tracks.reduce((tracks, track) => {
      let price = parseFloat(ratecardValue);

      if (applyDiscount)
        switch (true) {
          case type === 'first_use':
            price = 0;
            break;
          case type === 'second_purchase' && !!discount:
            price = price - price * (discount / 100);
            break;
        }

      return [
        ...tracks,
        {
          id: track.identity,
          name: humanTrackName(track),
          category: ratecardId || NULL_VALUE,
          price: price.toFixed(2),
          quantity: 1,
        },
      ];
    }, []);
  };

  private getLicences = (licences: Array<LicenceSchema>): Array<Product> => {
    const { ratecardId } = this.basket;

    return licences.map((licence) => ({
      id: licence.track.identity,
      name: humanTrackName(licence.track),
      category: ratecardId || NULL_VALUE,
      price: licence.amount.toFixed(2),
      quantity: licence.quantity,
    }));
  };
}

interface Product {
  id: string;
  name: string;
  category: string;
  price: string;
  quantity: number;
}

const NULL_VALUE = '(not set)';

const variables = {
  userId: 'userId',
  hasSubscription: 'hasSubscription',
  currencyCode: 'currencyCode',
  rateCardId: 'rateCardId',
  channelName: 'channelName',
  channelCountry: 'channelCountry',
  activeSubscription: 'activeSubscription',
};
