export default {
  'user.login': 'Register / Sign in',
  'user.logout': 'Sign out',
  'user.basket': 'Basket',
  'user.home': 'Home',
  'user.browse': 'Browse',
  'user.account': 'Account',
  'user.no_name': 'User',

  'account.popover.link': 'My account',
  'account.rate_card.title': 'Channel {number}',
  'account.rate_card.your_price': 'Your price',
  'account.rate_card.no_user': 'Please sign in to view your rate per track',
  'account.rate_card.no_channels': 'There are no YouTube channels for this account',
  'account.rate_card.viewership': '{viewership} views',
  'account.rate_card.value': '{value} per track',

  'account.link.spotify_account': 'Link Your Spotify Account',
  'account.link.facebook_account': 'Link Your Facebook Account',
  'account.link.twitter_account': 'Link Your Twitter Account',
  'account.link.dropbox_account': 'Link Your Dropbox Account',

  'search.popover.link': 'Advanced search...',
  'search.popover.hits.placeholder': 'Start typing to search Lickd',
  'search.popover.hits.empty': 'No results for "{query}"',
  'search.drawer.query.placeholder': 'Track, artist or release',
  'search.drawer.filters.artist': 'Artist',
  'search.drawer.filters.genre': 'Genre',
  'search.drawer.filters.mood': 'Mood',
  'search.drawer.filters.tempo': 'Tempo',
  'search.drawer.filters.is_featured': 'Featured',
  'search.drawer.filters.brand_sponsored': 'Brand Sponsored allowed',
  'search.drawer.filters.is_explicit': 'Explicit',
  'search.refinement.empty': 'No results',
  'search.refinement.show_more': 'Show more...',
  'search.refinement.show_less': 'Show less...',
  // 'search.results.artists': '{count, number} matching {count, plural, one {artist} other {artists}}',
  'search.results.artists': 'Matching artists',
  // 'search.results.tracks': '{count, number} matching {count, plural, one {track} other {tracks}}',
  'search.results.tracks': 'Matching tracks',

  'home.carousel.questions.1': 'Which artist do you want in your video?',
  'home.carousel.questions.2': 'Which style of music are you looking for?',
  'home.carousel.questions.3': 'What is the theme of your video?',
  'home.carousel.results': 'Results',
  'home.carousel.just_added': 'Just added',
  'home.carousel.feature_tracks': 'Feature tracks',
  'home.carousel.leaving_soon': 'Leaving soon',
  'home.strap.title': 'Get the artists you want, on your video',
  'home.strap.subtitle': 'Get the music you want from real artists and the licenses for publishing your video online',

  'basket.contents.header': 'Your basket',
  'basket.contents.empty': 'Your basket is empty',
  'basket.contents.num_tracks': '{numTracks, number} {numTracks, plural, one {track} other {tracks}}',

  'basket.actions.no_user': '{link} to view your price and checkout',
  'basket.actions.total': '{total} total',
  'basket.actions.purchase': 'Checkout',

  'tracks.label': 'Tracks',
  'tracks.empty': 'There are no tracks to show',

  'browse.title': 'Browse',
  'browse.releases.title': 'Recently added releases',
  'browse.tracks.title': 'Recently added tracks',

  'artist.releases.title': 'Releases',
  'artist.tracks.title': 'Top tracks',
  'artist.bio.title': 'Artist bio',
  'artist.bio.show_more': 'Show more',
  'artist.bio.show_less': 'Show less',
  'artist.coming_soon.title': 'Coming soon',
  'artist.coming_soon.content':
    "We don't have tracks for {name} available yet, but you can rest assured we are working on it.",
  'artist.coming_soon.cta.submit': 'Notify me when available',

  'mailchimp.subscribe.email': 'Email address',

  'search_hero.search.placeholder': 'Search artists, tracks & releases',
  'search_hero.actions.search': 'Search',
  'search_hero.actions.next': 'Next',

  'pricing.title': 'Pricing',
  'pricing.description':
    'When using hookd to find music for your video, you pay the same price for any track in our catalog. The price you pay is calculated from your youtube channel viewership over the past 3 months',
  'pricing.header.name': 'Viewership',
  'pricing.header.rate': 'Price per track',
  'pricing.value.rate': '{value}',

  'account.title': 'My account',
  'account.menu.invites': 'Refer a friend',
  'account.menu.licences': 'Licenses',
  'account.menu.orders': 'Orders',
  'account.menu.channels': 'Channels',
  'account.menu.billing': 'Billing',
  'account.menu.communication': 'Communication preferences',
  'account.menu.profile': 'My profile',
  'account.licences.title': 'Licenses',
  'account.licences.table.track.title': 'Track',
  'account.licences.table.licenced.title': 'Licensed',
  'account.licences.table.licenced.value': '{time} ago',
  'account.licences.table.actions.title': 'Actions',
  'account.licences.table.order_actions.title': ' ',
  'account.licences.table.licence.value': 'View license',
  'account.licences.table.invoice.value': 'View invoice',
  'account.licences.table.order.value': 'View order',
  'account.licences.table.amount.title': 'Price',
  'account.licences.table.amount.value': '{amount}',
  'account.licences.table.claims.title': 'Claims',
  'account.licences.table.claims.empty': 'None',
  'account.licences.table.claims.value': '{claims, number}/{quantity, number}',
  'account.licences.table.videos.title': 'Videos',
  'account.licences.table.videos.value': '{icon} View',
  'account.licences.table.view.title': 'Actions',
  'account.licences.table.view.value': 'View license',
  'account.licence.title': 'License › {identity}',
  'account.licence.details.price.title': 'Price',
  'account.licence.details.price.value': '{value}',
  'account.licence.details.used.title': 'Licenses used',
  'account.licence.details.used.value': '{claims, number}/{quantity, number}',
  'account.licence.details.order.title': 'Order',
  'account.licence.claims.title': 'Claims',
  'account.licence.claims.id.title': 'ID',
  'account.licence.claims.channel.title': 'Channel',
  'account.licence.claims.video.title': 'Video',
  'account.orders.title': 'Orders',
  'account.orders.table.title': 'Licenses',
  'account.orders.table.id.title': '#',
  'account.orders.table.amount.title': 'Amount',
  'account.orders.table.amount.value': '{amount}',
  'account.orders.table.licences.title': 'Licenses',
  'account.orders.table.actions.title': 'Actions',
  'account.orders.table.view_order.title': 'View order',
  'account.orders.table.get_invoice.title': 'Get invoice',
  'account.order.licences.title': 'Licenses',
  'account.order.details.subtotal.title': 'Subtotal',
  'account.order.details.channels.title': 'Usable on channels',
  'account.order.details.subtotal.value': '{value}',
  'account.order.details.vat.title': 'VAT',
  'account.order.details.vat.value': '{value}',
  'account.order.details.discount.title': 'Discount',
  'account.order.details.discount.value': '{value}',
  'account.order.details.card_fee.title': 'Card fee',
  'account.order.details.card_fee.value': '{value}',
  'account.order.details.total.title': 'Grand total',
  'account.order.details.total.value': '{value}',
  'account.channels.empty': "You don't have any channels",
  'account.channels.videos': 'View video details',
  'account.channels.published_at.label': 'Published at',
  'account.channels.published_at.value': '{published_at, date, medium}',
  'account.channels.description.label': 'Description',
  'account.channels.description.value': '{description}',
  'account.channel.title': 'Channel › {name}',
  'account.channel.videos.name.title': 'Name',
  'account.channel.videos.published_at.title': 'Published at',
  'account.channel.videos.published_at.value': '{published_at, date, medium}',
  'account.channel.videos.statistics.title': 'Statistics',
  'account.channel.videos.statistics.views': '{view_count, number} {view_count, plural, one {view} other {views}}',
  'account.channel.videos.statistics.comments':
    '{comment_count, number} {comment_count, plural, one {comment} other {comments}}',
  'account.channel.videos.statistics.favourites':
    '{favourite_count, number} {favourite_count, plural, one {favourite} other {favourites}}',
  'account.channel.videos.statistics.likes': '{like_count, number} {like_count, plural, one {like} other {likes}}',
  'account.channel.videos.statistics.dislikes':
    '{dislike_count, number} {dislike_count, plural, one {dislike} other {dislikes}}',
  'account.channel.videos.statistics.fetched_at': 'Statistics fetched {fetched_at, date, medium}',
  'account.channel.videos.video.title': 'Video',
  'account.billing.addresses.title': 'Billing {count, plural, one {address} other {addresses}}',
  'account.billing.addresses.empty': 'There are no billing addresses associated with your account',
  'account.billing.addresses.label': 'Address {number}',

  'footer.follow.title': 'Follow us',
  'footer.follow.facebook': 'Facebook',
  'footer.follow.twitter': 'Twitter',
  'footer.follow.youtube': 'YouTube',
  'footer.contact.title': 'Contact',
  'footer.legal.copyright': '2017 Lickd limited',
  'footer.links.pricing': 'Pricing',
  'footer.links.privacy': 'Privacy',
  'footer.links.terms': 'Terms',
  'footer.links.contact': 'Contact',

  'flash.message.error.title': 'Sorry, we are still working on that...',
  'flash.message.error.message': 'Check back soon',
  'flash.message.no_user.title': 'Account required',
  'flash.message.no_user.value': '{link} {message}',
  'flash.message.order_success.title': 'Order success',
  'flash.message.order_success.value': 'View my order',
  'flash.message.migration.title': 'Missing a channel?',
  'flash.message.migration.prompt': 'Add another channel',

  'channel.rate_card.title': 'Rate card',
  'channel.rate_card.name.label': 'Price band',
  'channel.rate_card.period.label': 'Calculation period',
  'channel.rate_card.period.value': '{start_period, date, medium} to {end_period, date, medium}',
  'channel.rate_card.viewership.label': 'Viewership',
  'channel.rate_card.viewership.value': '{viewership, number} {viewership, plural, one {view} other {views}}',
  'channel.rate_card.video_count.label': 'Number of videos',
  'channel.rate_card.video_count.value': '{video_count, number} {video_count, plural, one {video} other {videos}}',
  'channel.rate_card.price.label': 'Price per track',
  'channel.rate_card.price.value': '{value}',

  'channel.statistics.title': 'Statistics for {fetched_at, date, medium}',
  'channel.statistics.comment_count.label': 'Comments',
  'channel.statistics.comment_count.value': '{comment_count, number} ({average_comments, number} average)',
  'channel.statistics.video_count.label': 'Videos',
  'channel.statistics.video_count.value': '{video_count, number}',
  'channel.statistics.view_count.label': 'Views',
  'channel.statistics.view_count.value': '{view_count, number} ({average_views, number} average)',
};
