(function () {
  // We aren't looking to shim the entire type
  if (typeof window.matchMedia !== 'function') return false;
  var testQuery = window.matchMedia('screen');
  if (typeof testQuery.addEventListener === 'function' && typeof testQuery.removeEventListener === 'function') {
    return false;
  }

  // Because browsers without EventTarget inheritance also don't expose MediaQueryList on window, we can't patch the prototype, instead we monkey patch
  var originalMatchMedia = window.matchMedia;
  window.matchMedia = function (mediaQueryString) {
    var mediaQueryList = originalMatchMedia(mediaQueryString);
    mediaQueryList.addEventListener = function (eventName, eventHandler) {
      return this.addListener(eventHandler);
    };
    mediaQueryList.removeEventListener = function (eventName, eventHandler) {
      return this.removeListener(eventHandler);
    };
    return mediaQueryList;
  };
})();
