import {observable} from 'mobx';

export class GoldenTicketPageModel {
  @observable loading: boolean = true;

  @observable document_title: string;

  @observable document_description: string;

  @observable document_image: string;

  @observable background_image: string;

  @observable header: string;

  @observable referral_text: string;

  @observable sub_header: string;

  @observable cta_landing_copy: string;

  @observable cta_genre_1_placeholder: string;

  @observable cta_genre_2_placeholder: string;

  @observable cta_landing_button: string;

  @observable cta_complete_copy: string;

  @observable cta_complete_button: string;

  @observable cta_complete_url: string;

  @observable modal_title: string;

  @observable modal_copy: any = [];

  @observable modal_caveat: string;
}