import * as React from 'react';
import { observer } from 'mobx-react';
import { style } from 'typestyle';
import { rebrand } from '../theme/color';
import { px } from 'csx';
import overlayStyles, { ScrollableOverlayBodyStyle } from '../styles/scrollable-overlay';
import { ScrollableOverlay } from './scrollable-overlay/scrollable-overlay';
import { InfoFilled } from './project-happy/atoms/icons/InfoFilled';

const styles = {
  wrapper: style({ display: 'block', maxWidth: px(440) }),
  body: style(ScrollableOverlayBodyStyle, {
    padding: '1.5rem 0 0',
    borderTop: `1px solid ${rebrand.light1.toString()}`,
    textAlign: 'center',
    $nest: {
      '& > p': {
        marginTop: '24px',
        textAlign: 'left',
        fontSize: '12px',
      },
    },
  }),
  warningText: style({
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    background: rebrand.semantic.warning[10].toHexString(),
    borderRadius: '8px',
    padding: '0 8px',
    height: '40px',
  }),
  infoIcon: style({
    margin: '-4px 0 0',
  }),
};

export interface RestrictedModalModel {
  show: boolean;
}

export interface RestrictedModalProps {
  model: RestrictedModalModel;
  onRequestClose(): void;
}

@observer
export class RestrictedModal extends React.Component<RestrictedModalProps> {
  render() {
    const { model, onRequestClose } = this.props;

    return (
      <ScrollableOverlay
        className={styles.wrapper}
        show={model.show}
        onRequestClose={onRequestClose}
        data-test-track-ristricted-modal
      >
        <div className={overlayStyles.header}>
          <h3 className={overlayStyles.title}>Track Restricted</h3>
        </div>

        <div className={styles.body}>
          <div className={styles.warningText}>
            <InfoFilled className={styles.infoIcon} />
            <p>This track is not licensable in your country</p>
          </div>
          <p>
            Unfortunately, we aren&apos;t always able to secure licenses for every country for certain tracks. Luckily
            though, this doesn&apos;t happen often - so keep digging to find your perfect track!
          </p>
        </div>
      </ScrollableOverlay>
    );
  }
}
