/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { style } from 'typestyle';
import { PrismicTypedDashboardHeaderProps } from '../../utilities/types';
import { Button } from '../../atoms/button/Button';
import { ButtonProminence } from '../../atoms/button/buttonStyles';
import { renderPrismicRichTextArray } from '../../utilities/props';
import { flexRoot } from 'csstips';
import { colorWhite, rebrand } from '../../../../theme/color';
import { ArrowRight } from '../../atoms/icons/ArrowRight';
import { important, px } from 'csx';
import { Profile } from '../../atoms/icons/Profile';
import { Star } from '../../atoms/icons/Star';
import { AccessTime } from '../../atoms/icons/AccessTime';
import { mediaDesktop } from '../../utilities/mediaQueries';

const DashboardHeaderSlice = ({
  item,
  isSquareDashboardHeadereImage,
  children,
  sendMixpanel,
}: {
  item: PrismicTypedDashboardHeaderProps;
  children?: any;
  isSquareDashboardHeadereImage: boolean;
  sendMixpanel?: (event: string, props?: any, callback?: () => void) => void;
}) => {
  const icon = {
    Popular: <Star />,
    'Just added': <AccessTime />,
    'Picked for you': <Profile />,
  };
  return (
    <div
      className={styles.itemWrapper}
      style={{
        backgroundImage: `url(${
          isSquareDashboardHeadereImage ? item.dashboard_image.mobile.url : item.dashboard_image.url
        })`,
      }}
    >
      <div className={styles.childElement}>
        {item.title_tile && (
          <Button prominence={ButtonProminence.HIGHLIGHT} className={styles.titleTileBtn}>
            {icon[item.title_tile]}
            <span className={styles.titleTile}> {item.title_tile}</span>
          </Button>
        )}
      </div>
      <div className={styles.childElement}>
        {renderPrismicRichTextArray(item.title, 'heading3')}
        <Button
          prominence={ButtonProminence.SECONDARY}
          className={styles.btn}
          href={item.cta_link}
          onClick={() =>
            sendMixpanel('User clicks CTA in Dashboard Header Slice Carousel', {
              route: item.cta_link,
              text: item.cta_text,
            })
          }
        >
          <span> {item.cta_text}</span> <ArrowRight className={styles.arrow} />
        </Button>
      </div>

      {children}
    </div>
  );
};

const styles = {
  itemWrapper: style(
    flexRoot,
    {
      height: '100%',
      position: 'relative',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundColor: rebrand.contrast[70].toString(),
      flexDirection: 'column',
      justifyContent: 'space-between',
      color: colorWhite.toHexString(),
      padding: '32px',
      $nest: {
        '& h3': {
          fontSize: '24px',
          marginBottom: '8px',
        },
        // Linear gradient over the bottom of the image
        '&::after': {
          content: "''",
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: px(120),
          background: `linear-gradient(to bottom, transparent, ${rebrand.contrast[90].fade(0.8)})`,
        },
      },
    },
    mediaDesktop({
      $nest: {
        '&::after': {
          height: px(140),
        },
      },
    })
  ),
  childElement: style({
    position: 'relative',
    zIndex: 1,
  }),
  btn: style({
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: rebrand.neutralOnDark[20].toString(),
    padding: important('4px 12px'),
  }),
  titleTileBtn: style({
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    $nest: {
      '&:hover': {
        cursor: 'default',
        backgroundColor: rebrand.highlight1.toString(),
      },
    },
  }),
  arrow: style({
    marginLeft: '8px',
  }),
  titleTile: style({
    marginLeft: '8px',
  }),
};

export default DashboardHeaderSlice;
