import * as React from 'react';
import { classes, cssRaw, extend, style } from 'typestyle';
import { percent, px, viewWidth } from 'csx';
import { ContentSlice, ContentSliceProps, GradientBackgroundStyles } from './ContentSlice';
import { TrackTile } from '../../molecules/TrackTile';
import { TrackSchema } from '../../../../types/schema';
import { desktopContentWidth } from '../../config';
import { mediaDesktop, mediaLargeTabletOnly, mediaUpToDesktop } from '../../utilities/mediaQueries';
import { mediaCoarsePointer, mediaFinePointer } from '../../../../theme/media';
import { heading4, headingStyles } from '../../utilities/font';
import { DecoratedPageCarousel, DecoratedPageCarouselProps } from '../DecoratedPageCarousel';
import { colorGunmetal } from '../../../../theme/color';
import PlayButton from '../../atoms/button/PlayButton';
import { LargeTrackTile } from '../../molecules/LargeTrackTile';
import { BaseCarouselSlice } from './BaseCarouselSlice';
import { Button } from '../../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../../atoms/button/buttonStyles';
import { zLabel } from '../../../../theme/z';
import { FavouriteButtonCompat } from '../../../compat/favourite-button-compat';
import { SeeAllButton } from '../../molecules/panels/atoms/SeeAllButton';
import { AddRemoveCompat } from '../../../compat/add-remove-compat';

const mobileTileSizeVarName = '--mobile-tile-size';

const styles = {
  carouselControls: style({
    $nest: {
      '& > button': {
        transform: 'translateY(-34px)',
      },
    },
  }),
  carousel: style(
    mediaUpToDesktop({
      marginLeft: px(-16),
      marginRight: px(-16),
      width: 'calc(100% + 32px)',
    })
  ),
  trackTile: style(
    {
      $nest: {
        ':not(.with-gradient) &': {
          color: colorGunmetal.toHexString(),
        },
        '&:hover .basket': {
          opacity: 1,
        },
      },
    },
    mediaUpToDesktop({
      margin: 0,
      marginLeft: px(16),
    })
  ),
  basePage: style(
    mediaLargeTabletOnly({
      width: [percent(42.5), `var(${mobileTileSizeVarName}, 20.5%)`],
    }),
    {}
  ),
  playButton: style({
    zIndex: zLabel,
  }),
  basket: style({
    boxShadow: 'none',
    position: 'absolute',
    bottom: '50%',
    left: '50%',
    zIndex: zLabel,
    opacity: 0,
    transform: ' translate(-50%, -50%)',
    $nest: {
      '&:hover, &:focus': {
        boxShadow: 'none !important',
      },
    },
  }),
};

// Exclude gradient prop as this slice has a two tone background
type CarouselSliceProps = ContentSliceProps & DecoratedPageCarouselProps & { tracks?: TrackSchema[]; slug?: string };

export const TrackCarouselSlice: React.SFC<CarouselSliceProps> = ({
  children,
  className,
  contentClassName,
  tracks,
  carouselClassName,
  perPage,
  slug,
  actions,
  ...props
}) => {
  const shouldUseLargeTile = perPage <= 3;
  if (shouldUseLargeTile) {
    props.mobileTileSize = 85.3;
  }
  const defaultAction =
    typeof slug === 'string' ? (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <SeeAllButton href={`/browse/collection/${slug}`} data-test-see-all-button-track-carousel-slice>
          See all
        </SeeAllButton>
      </div>
    ) : null;

  if (tracks && tracks.length > 0) {
    return (
      <BaseCarouselSlice className={className} contentClassName={contentClassName}>
        <DecoratedPageCarousel
          controlClassName={!shouldUseLargeTile && styles.carouselControls}
          carouselClassName={classes(styles.carousel, carouselClassName)}
          perPage={perPage}
          actions={React.isValidElement(actions) || actions === null ? actions : defaultAction}
          {...props}
        >
          {tracks.map((track, key) => {
            const playButton = <PlayButton track={track} className={styles.playButton} />;
            if (shouldUseLargeTile) {
              return (
                <LargeTrackTile key={key} track={track}>
                  {playButton}
                </LargeTrackTile>
              );
            }
            return (
              <TrackTile className={styles.trackTile} key={key} track={track}>
                {playButton}
                <FavouriteButtonCompat track={track} size={32} />
                <AddRemoveCompat
                  track={track}
                  size={40}
                  className={classes(styles.basket, 'basket')}
                  firedFromTrackCarousel={true}
                />
              </TrackTile>
            );
          })}
        </DecoratedPageCarousel>
      </BaseCarouselSlice>
    );
  } else {
    return (
      <BaseCarouselSlice className={classes(className)} contentClassName={contentClassName}>
        <DecoratedPageCarousel
          controlClassName={!shouldUseLargeTile && styles.carouselControls}
          carouselClassName={classes(styles.carousel, carouselClassName)}
          perPage={perPage}
          tileClassName={styles.basePage}
          actions={React.isValidElement(actions) || actions === null ? actions : defaultAction}
          {...props}
        >
          {[1, 2, 3, 4, 5, 6].map((key) => (
            <TrackTile className={styles.trackTile} key={key}></TrackTile>
          ))}
        </DecoratedPageCarousel>
      </BaseCarouselSlice>
    );
  }
};
