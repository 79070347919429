import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const PersonCircleFilled: React.FC<SVGIconProps> = ({ size = 25, isSquare, color = '#94A4C1', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 25 25" {...props}>
    <rect x=".484" y=".5" width="24" height="24" rx="12" fill={color} />
    <mask
      id="personCircleFilledMask"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="5"
      width="20"
      height="20"
    >
      <path
        d="M12.124 15.02a4.859 4.859 0 0 0 4.86-4.86 4.859 4.859 0 0 0-4.86-4.86 4.859 4.859 0 0 0-4.86 4.86 4.859 4.859 0 0 0 4.86 4.86Zm0 2.43c-3.244 0-9.72 1.628-9.72 4.86v2.43h19.44v-2.43c0-3.232-6.476-4.86-9.72-4.86Z"
        fill="#262E3F"
      />
    </mask>
    <g mask="url(#personCircleFilledMask)">
      <rect opacity=".4" x=".484" y=".5" width="24" height="24" rx="12" fill="#262E3F" />
    </g>
  </BaseSvg>
);
