import * as React from 'react';
import { classes, style } from 'typestyle';
import { horizontal, wrap, centerCenter } from 'csstips';
import { important, percent, rem, viewWidth } from 'csx';
import { mediaDesktop, mediaMobileOnly, mediaUpToTablet, mediaTablet } from '../theme/media';
import { AggregatedTagSchema, PlaylistSchema, ReleaseSchema, RightsholderSchema, TrackSchema } from '../types/schema';
import { Loading } from './loading/loading';
import { colorBlack } from '../theme/color';
import { Article, ArticleImage, ArticleVideo, Artist } from '../types/graphql';

export type Size = 2 | 3 | 4;

export type Tile<T> = {
  name: string;
  slug: string;
  images?: {
    identity: string;
  };
  model?: T;
};

export type Props<T> = {
  tiles: Tile<T>[];
  renderTile(itemClass: string, tile: Tile<T>, index: number, tiles: Tile<T>[]): JSX.Element | null;
  perRowMobile?: Size;
  perRowTablet?: Size;
  perRowDesktop?: Size;
  className?: string;
  loading?: boolean;
  loadingTiles?: number;
  loadingSize?: number;
  loadingLight?: boolean;
  autoPadding?: boolean;
};

export default class TileGrid<T> extends React.Component<Props<T>, any> {
  render() {
    const {
      loadingTiles = 0,
      loading = false,
      tiles,
      className,
      perRowMobile = 2,
      perRowTablet = 3,
      perRowDesktop = 4,
      renderTile,
      autoPadding,
    } = this.props;

    const itemClass = classes(
      TileGrid.styles.item,
      autoPadding && TileGrid.styles.itemPadding,
      perRowDesktop === 2 && TileGrid.styles.itemDesktop2,
      perRowDesktop === 3 && TileGrid.styles.itemDesktop3,
      perRowDesktop === 4 && TileGrid.styles.itemDesktop4,
      perRowTablet === 2 && TileGrid.styles.itemTablet2,
      perRowTablet === 3 && TileGrid.styles.itemTablet3,
      perRowTablet === 4 && TileGrid.styles.itemTablet4,
      perRowMobile === 2 && TileGrid.styles.itemMobile2,
      perRowMobile === 3 && TileGrid.styles.itemMobile3,
      perRowMobile === 4 && TileGrid.styles.itemMobile4,
    );

    return (
      <div className={classes(TileGrid.styles.container, className)}>
        {loading && !!loadingTiles && new Array(loadingTiles).fill(1).map(this.renderLoadingTile.bind(this, itemClass))}
        {!loading && tiles.map(renderTile.bind(null, itemClass))}
      </div>
    );
  }

  renderLoadingTile = (className: string, _: any, index: number) => (
    <div className={classes(className, TileGrid.styles.itemPadding)} key={`loading${index}`}>
      <div>
        <Loading size={this.props.loadingSize || 48} light={this.props.loadingLight} />
      </div>
    </div>
  );

  static styles = {
    container: style({
      ...horizontal,
      ...wrap,
      width: percent(100),
      $nest: {
        img: {
          width: percent(100),
        },
      },
    }),
    loading: style({
      ...centerCenter,
      background: colorBlack.fade(0.1).toString(),
    }),
    item: style({
      flexShrink: 0,
      position: 'relative',
    }),
    itemPadding: style({
      $nest: {
        '&>*': {
          display: 'block',
          width: percent(100),
          paddingTop: percent(100),
        },
        '&>div>div': {
          ...centerCenter,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
      },
    }),
    itemMobile2: style(
      mediaMobileOnly({
        width: important(`${50}%`),
        $nest: {
          '& > div > *': {
            fontSize: viewWidth((100 * 18) / 420),
          },
        },
      }),
    ),
    itemMobile3: style(
      mediaMobileOnly({
        width: important(`${100 / 3}%`),
        $nest: {
          '& > div > *': {
            fontSize: viewWidth((100 * 12) / 420),
          },
        },
      }),
    ),
    itemMobile4: style(
      mediaMobileOnly({
        width: important(`${25}%`),
        $nest: {
          '& > div > *': {
            fontSize: viewWidth((100 * 8) / 420),
          },
        },
      }),
    ),
    itemTablet2: style(
      mediaUpToTablet({
        width: important(`${50}%`),
        $nest: {
          '& > div > *': {
            fontSize: viewWidth((100 * 18) / 420),
          },
        },
      }),
    ),
    itemTablet3: style(
      mediaUpToTablet({
        width: important(`${100 / 3}%`),
        $nest: {
          '& > div > *': {
            fontSize: viewWidth((100 * 12) / 420),
          },
        },
      }),
    ),
    itemTablet4: style(
      mediaUpToTablet({
        width: important(`${25}%`),
        $nest: {
          '& > div > *': {
            fontSize: viewWidth((100 * 8) / 420),
          },
        },
      }),
    ),
    itemDesktop2: style(
      mediaDesktop({
        width: important(`${50}%`),
        $nest: {
          '& > div > *': {
            fontSize: rem(4),
          },
        },
      }),
    ),
    itemDesktop3: style(
      mediaDesktop({
        width: important(`${100 / 3}%`),
        $nest: {
          '& > div > *': {
            fontSize: rem(2.75),
          },
        },
      }),
    ),
    itemDesktop4: style(
      mediaDesktop({
        width: important(`${25}%`),
        $nest: {
          '& > div > *': {
            fontSize: rem(1.75),
          },
        },
      }),
    ),
  };
}

export type SimpleTileItem = Tile<null>;
export class SimpleTileGrid extends TileGrid<SimpleTileItem> {}

export type ArticleImageTileItem = Tile<ArticleImage>;
export class ArticleImageTileGrid extends TileGrid<ArticleImage> {}

export type ArticleVideoTileItem = Tile<ArticleVideo>;
export class ArticleVideoTileGrid extends TileGrid<ArticleVideo> {}

export type ArtistTileItem = Tile<Artist>;
export class ArtistTileGrid extends TileGrid<Artist> {}

export type TagTileItem = Tile<AggregatedTagSchema>;
export class TagTileGrid extends TileGrid<AggregatedTagSchema> {}

export type ReleaseTileItem = Tile<ReleaseSchema>;
export class ReleaseTileGrid extends TileGrid<ReleaseSchema> {}

export type TrackTileItem = Tile<TrackSchema>;
export class TrackTileGrid extends TileGrid<TrackSchema> {}

export type PlaylistTileItem = Tile<PlaylistSchema>;
export class PlaylistTileGrid extends TileGrid<PlaylistSchema> {}

export type RightsholderTile = Tile<RightsholderSchema>;
export class RightsholderTileGrid extends TileGrid<RightsholderSchema> {}

export type ArticleTileItem = Tile<Article>;
export class ArticleTileGrid extends TileGrid<Article> {}
