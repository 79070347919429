import { observable } from 'mobx';
import {ChannelSchema, OrderSchema} from '../../../types/schema';

export class AccountOrderPageModel {
  @observable
  loading: boolean = false;

  @observable
  identity: string;

  @observable
  order: OrderSchema;

  @observable
  channels: Array<ChannelSchema>;
}