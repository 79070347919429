import * as React from 'react';
import { BaseSvg, SVGIconProps } from '../BaseSvg';

export const LinkedInLogo: React.SFC<SVGIconProps> = ({ size = 21, color = '#0277B5', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 21 21" {...props}>
    <path
      d="M20.2 20.16H16v-6.55c0-1.56-.02-3.57-2.17-3.57-2.18 0-2.51 1.7-2.51 3.46v6.66H7.15V6.7h4v1.83h.07a4.4 4.4 0 0 1 3.96-2.17c4.24 0 5.02 2.8 5.02 6.42v7.38ZM2.43 4.86a2.43 2.43 0 1 1 0-4.86 2.43 2.43 0 0 1 0 4.86ZM.33 6.69h4.19v13.47H.33V6.7Z"
      fill={color}
      fillRule="evenodd"
    />
  </BaseSvg>
);
