import * as React from 'react';
import { InputHTMLAttributes } from 'react';
import { classes, cssRaw, keyframes, style } from 'typestyle';
import { em, important, percent, px } from 'csx';
import { centerCenter, flexRoot } from 'csstips';
import {
  colorBrand,
  colorBrandDark,
  colorCrimson,
  colorLightGrey,
  colorLightGreyDark,
  colorGunmetal,
  colorSubtle,
  colorWhite,
  rebrand,
} from '../../../../theme/color';
import { transitionQuickEase } from '../../../../theme/transition';
import { NestedCSSProperties } from 'typestyle/lib/types';

const unselected = rebrand.neutralOnDark[20].toString();
const unselectedBorder = rebrand.neutralOnDark[40].toString();
const selected = rebrand.secondary[60].toString();
const selectedBorder = rebrand.secondary[90].toString();
const focus = colorBrand.toString();
const disabled = colorSubtle.toString();
const fg = colorGunmetal.toString();
const bg = colorWhite.toString();

const borderscale = keyframes({
  '50%': {
    boxShadow: `0 0 0 ${px(2)} ${selected}`,
  },
});

const errorStyles: NestedCSSProperties = {
  border: important(`${px(2)} solid ${colorCrimson.toString()}`),
};

const styles = {
  wrapper: style({
    display: 'inline-flex',
    flexShrink: 0,
  }),
  input: style({
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: px(1),
    margin: px(-1),
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: px(1),
  }),
  indicator: style(centerCenter, {
    position: 'relative',
    width: px(24),
    height: px(24),
    borderRadius: px(4),
    borderTop: `${px(1)} solid ${unselectedBorder}`,
    borderLeft: `${px(1)} solid ${unselectedBorder}`,
    textAlign: 'center',
    transition: `all ${transitionQuickEase}`,
    $nest: {
      '&:after': {
        boxSizing: 'content-box',
        content: '""',
        transformOrigin: percent(50),
        transition: `transform ${transitionQuickEase}`,
        backgroundColor: 'transparent',
        width: px(10), // The transform rotation means the final width is larger than specified
        height: px(4),
        marginTop: 0,
        borderStyle: 'solid',
        borderColor: bg,
        borderWidth: `0 0 ${px(2)} ${px(2)}`,
        transform: 'rotate(-45deg) scale(0)',
      },
      'input:focus-visible + &': {
        backgroundColor: selected,
        outlineColor: selected,
        outlineWidth: 'medium',
        outlineStyle: 'solid',
        borderRadius: px(4),
      },
      'input:active + &': {
        transitionDuration: 0,
      },
      'input:disabled:not(:checked) + &': {
        backgroundColor: rebrand.neutralOnDark[10].toString(),
        borderColor: rebrand.neutralOnDark[20].toString(),
      },
      'input:disabled:focus + &': {
        backgroundColor: 'inherit',
      },
      'input:disabled:checked + &': {
        backgroundColor: rebrand.neutralOnLight[10].toString(),
        borderColor: rebrand.neutralOnLight[20].toString(),
        borderTopWidth: px(1),
        borderLeftWidth: px(1),
        borderRightWidth: 0,
        borderBottomWidth: 0,
      },
      'input:checked:focus + &': {
        //borderColor: bg,
      },
      'input:not(:checked) + &': {
        borderTop: `${px(1)} solid ${unselectedBorder}`,
        borderLeft: `${px(1)} solid ${unselectedBorder}`,
        background: unselected,
      },

      'input:checked + &:after': {
        content: '""',
        transform: 'rotate(-45deg) scale(1)',
        transition: `transform ${transitionQuickEase}`,
      },
      'input:checked + &': {
        animation: `${borderscale} ${transitionQuickEase}`,
        background: selected,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottom: `1px solid ${selectedBorder}`,
        borderRight: `1px solid ${selectedBorder}`,
        boxShadow: 'none',
      },
      '.show-errors input:invalid + &': errorStyles,
    },
  }),
  error: style(errorStyles),
};

cssRaw(`@supports not selector(:focus-visible) {
  input:focus + .${styles.indicator} {
    outline: ${selected} solid medium;
  }
}`);

export type CheckboxInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  isValid?: boolean;
  indicatorClassName?: string;
};
export const CheckboxInput: React.SFC<CheckboxInputProps> = ({ className, isValid, indicatorClassName, ...props }) => (
  <span className={classes(className, styles.wrapper, 'checkbox-input')}>
    <input type="checkbox" className={styles.input} {...props} />
    <span className={classes(styles.indicator, indicatorClassName, isValid === false && styles.error)} />
  </span>
);
