import { CatalogueType, FindFiltersInput } from '../types/graphql';

export default function findFilters(params: Partial<FindFiltersInput> = {}): FindFiltersInput {
  return {
    catalogueType: CatalogueType.ALL,
    identity: undefined,
    isrc: undefined,
    upc: undefined,

    track: undefined,
    artist: undefined,
    release: undefined,
    rightsholder: undefined,
    playlist: undefined,

    mood: undefined,
    theme: undefined,
    genre: undefined,
    tempoMin: null,
    tempoMax: null,
    durationMin: null,
    durationMax: null,
    releasedAfter: undefined,
    releasedBefore: undefined,
    availableIn: undefined,
    hideNotAvailableIn: undefined,
    letter: undefined,
    letters: undefined,
    language: undefined,

    brandSponsored: undefined,
    brandedContent: undefined,
    explicit: undefined,
    featured: undefined,
    instrumental: undefined,

    dailymotion: undefined,
    facebook: undefined,
    instagram: undefined,
    linkedin: undefined,
    snapchat: undefined,
    twitch: undefined,
    twitter: undefined,
    vimeo: undefined,
    youtube: undefined,
    TikTok: undefined,
    podcasting: undefined,

    matchArtistFallback: undefined,

    ...params,
  };
}

export const FILTER_KEYS: Array<keyof FindFiltersInput> = Object.keys(findFilters()) as Array<keyof FindFiltersInput>;
