import * as React from 'react';
import { classes, style } from 'typestyle';
import { Button, ButtonProps } from '../../../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../../../atoms/button/buttonStyles';
import { ArrowRight } from '../../../atoms/icons/ArrowRight';

const { SECONDARY } = ButtonProminence;

const styles = style({
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  width: '91px',
  justifyContent: 'space-between',
  fontWeight: 600,
});

export type SeeAllButtonProps = ButtonProps;

export const SeeAllButton: React.SFC<SeeAllButtonProps> = ({ children, className, ...props }) => (
  <Button prominence={SECONDARY} size={ButtonSize.SMALL} className={classes(styles, className)} {...props}>
    {children} <ArrowRight size={16} />
  </Button>
);
