import * as React from 'react';
import { BasePanel, BasePanelProps } from './BasePanel';
import { Statement } from './atoms/Statement';

export type TextPanelProps = BasePanelProps;

export const TextPanel: React.SFC<TextPanelProps> = ({ children, ...props }) => (
  <BasePanel {...props}>
    <Statement>{children}</Statement>
  </BasePanel>
);
