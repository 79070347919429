import { observable } from 'mobx';

export class AffiliatePageModel {
  @observable
  slug: string = null;

  @observable
  loading = false;

  @observable
  pageTitle: string = null;

  @observable
  ctaText: string = null;

  @observable
  medium: string = null;

  @observable
  source: string = null;

  @observable
  campaign: string = null;

  @observable
  logo: any;

  @observable
  headerText: string = null;

  @observable
  featured: Array<any> = [];

  @observable
  featuredTitle: string = null;

  @observable
  sections: Array<{ heading: any; content: any }> = [];
}
