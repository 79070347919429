import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { PageCheckoutPanelProps } from './page-checkout-panels';
import { classes, style } from 'typestyle';
import { important, px, rem } from 'csx';
import { OrderLicenseList } from './project-happy/organisms/OrderLicenseList';
import { fontBody } from '../theme/font';
import InstructionCard from './project-happy/molecules/InstructionCard';
import { HashTag } from './project-happy/atoms/icons/HashTag';
import { CurvedArrow } from './project-happy/atoms/icons/CurvedArrow';
import { DevilHand } from './project-happy/atoms/icons/DevilHand';
import { betweenJustified, center, centerCenter, horizontal, vertical, wrap } from 'csstips';
import { mediaUpToLargeMobile } from './project-happy/utilities/mediaQueries';
// import { YouTubePanel } from './project-happy/molecules/panels/YouTubePanel';
import { Link } from './project-happy/atoms/Link';
import { ScrollableOverlay } from './scrollable-overlay/scrollable-overlay';
import overlayStyles, { ScrollableOverlayBodyStyle } from '../styles/scrollable-overlay';
import { Button } from './project-happy/atoms/button/Button';
import { ButtonProminence } from './project-happy/atoms/button/buttonStyles';
import { rebrand } from '../theme/color';
import { ArrowUp } from './project-happy/atoms/icons/ArrowUp';
import Cookies from 'js-cookie';
import { Container } from './project-happy/atoms/Container';
import { spacing } from '../theme/spacing';
import { YouTubePanel } from './project-happy/molecules/panels/YouTubePanel';

export type PageCheckoutContentStep3Props = PageCheckoutPanelProps;
type State = {
  showClaimModal: boolean;
  showAutomateModal: boolean;
  scrolling: boolean;
};

@observer
export class PageCheckoutContentStep3 extends React.Component<PageCheckoutContentStep3Props, State> {
  private downloadRef = React.createRef<HTMLDivElement>();

  state = {
    showClaimModal: false,
    showAutomateModal: false,
    scrolling: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  scrollToDiv = () => {
    this.downloadRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  onRequestClose = () => {
    this.setState({
      showClaimModal: false,
      showAutomateModal: false,
    });
  };

  handleScroll = () => {
    if (window.scrollY >= 250) {
      this.setState({ scrolling: true });
    } else {
      this.setState({ scrolling: false });
    }
  };

  render() {
    const { model } = this.props;
    const hasVisitedPostOrderPage = !!Cookies.get('hasVisitedPostOrderPage');
    return (
      <Container className={styles.container}>
        <h1 className={styles.header}>Thank you for your purchase!</h1>

        <div className={styles.headlineText}>
          <p className={styles.paragraph}>You&apos;re just a few steps away from elevating your content! </p>
          <p className={styles.paragraph}>
            Before you download your tracks, please check out the important information below on how to use them
          </p>
        </div>

        <h2 className={styles.subheader}>What do I do next?</h2>
        <div>
          <div className={styles.instructions}>
            <InstructionCard title={'Add the attribution to your description'} count={1} icon={<HashTag />}>
              <p className={styles.instructionCard}>
                found on the tracks below or in the{' '}
                <Link href={'/account/licenses'} target="_blank" className={styles.link}>
                  Licenses
                </Link>{' '}
                tab of your account
              </p>
            </InstructionCard>
            <InstructionCard title={'Upload your video as “Unlisted"'} count={2} icon={<CurvedArrow />}>
              <p className={styles.instructionCard}>
                for{' '}
                <Link onClick={() => this.setState({ showAutomateModal: true })} className={styles.link} href="#">
                  automated
                </Link>{' '}
                licenses simply <b>wait 30 mins* </b>for claims to be cleared
              </p>
            </InstructionCard>
            <InstructionCard title={`*If your claim isn't cleared in 30 mins`} isInfo>
              <p className={classes(styles.instructionCard, styles.infoCard)}>
                or if you have a{' '}
                <Link onClick={() => this.setState({ showClaimModal: true })} className={styles.link} href="#">
                  claims-managed
                </Link>{' '}
                license, <b>do not dispute</b> the claim. Let us know by{' '}
                <Link
                  href={'https://help.lickd.co/knowledge/what-is-lickds-self-serve-claims-tool'}
                  className={styles.link}
                  target="_blank"
                >
                  submitting a support request
                </Link>{' '}
                and we&apos;ll sort it
              </p>
            </InstructionCard>
            <InstructionCard title={'Once all claims have been cleared'} count={3} icon={<DevilHand />}>
              <p className={styles.instructionCard}>we&apos;ll let you know, and you can publish your awesome video!</p>
            </InstructionCard>
            {hasVisitedPostOrderPage && !this.state.scrolling && (
              <div className={styles.jumpbtnWrapper}>
                <Button
                  prominence={ButtonProminence.SECONDARY}
                  className={styles.jumpbtn}
                  onClick={this.scrollToDiv}
                  data-test-jump-to-your-track
                >
                  <span>Jump to your tracks</span> <ArrowUp className={styles.Arrow} />
                </Button>
              </div>
            )}
          </div>
        </div>

        {/* This is commented prior to when the new video will be available */}
        <p className={classes(styles.paragraph, styles.marginTop)}>
          Finally, this short video will help you avoid common mistakes when using your license:
        </p>

        <YouTubePanel videoId={'rUJSblDfjd4'} className={styles.video} basePanelClassName={styles.basePanelClassName} />

        <h2 className={styles.subheader} ref={this.downloadRef}>
          Download your Tracks
        </h2>

        <p className={styles.bodyText}>
          Generating licenses may take a few minutes. When they&apos;re ready, you can view them from your{' '}
          <Link href="/account/licenses" className={styles.link}>
            Licences
          </Link>{' '}
          page.
        </p>
        <OrderLicenseList licences={model.licences} currency={model.currency} />

        <p data-test-how-to-use-license className={classes(styles.bodyText, styles.moreInfo)}>
          {' '}
          Further info can be found here on{' '}
          <Link
            href={'https://help.lickd.co/knowledge/how-to-use-your-lickd-license-correctly'}
            className={styles.link}
            target="_blank"
          >
            How To Use Your License Correctly
          </Link>{' '}
        </p>

        <ScrollableOverlay
          show={this.state.showAutomateModal}
          onRequestClose={this.onRequestClose}
          className={styles.modal}
        >
          <div className={overlayStyles.header}>
            <h3 className={overlayStyles.title}>Automated claims clearance</h3>
          </div>

          <div className={styles.modalContent}>
            <p>
              Claims on these tracks are usually cleared automatically by our software, VOUCH. You can keep an eye on
              the claim statuses in YouTube Studio or via the{' '}
              <Link href={'/account/videos'} className={styles.link} target="_blank">
                Videos & Claims
              </Link>{' '}
              tab in your Lickd account. In some rare cases they may take a little longer to clear or require assistance
              from the team.
            </p>
          </div>
        </ScrollableOverlay>

        <ScrollableOverlay
          show={this.state.showClaimModal}
          onRequestClose={this.onRequestClose}
          className={styles.modal}
        >
          <div className={overlayStyles.header}>
            <h3 className={overlayStyles.title}>Claims-managed license</h3>
          </div>

          <div className={styles.modalContent}>
            <p>
              A third party (such as a record label) is involved in releasing the claim for this track. Any claims on
              claims-managed licenses should be cleared within 48 hours or less, Monday to Friday.
            </p>
          </div>
        </ScrollableOverlay>
      </Container>
    );
  }
}
const styles = {
  container: style({
    paddingTop: rem(3.5),
    paddingBottom: rem(3.5),
  }),
  marginTop: style({
    marginTop: '64px',
  }),
  header: style({
    fontSize: '48px',
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: '0 0 16px',
  }),
  subheader: style({
    fontSize: '40px',
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: '1.5rem 0 16px 0',
  }),
  headlineText: style({
    maxWidth: '650px',
    margin: '0 auto',
  }),
  paragraph: style({
    fontSize: '20px',
    fontFamily: fontBody,
    textAlign: 'center',
    fontWeight: 700,
    marginTop: 0,
  }),
  instructions: style(horizontal, centerCenter, wrap, mediaUpToLargeMobile(vertical), {
    marginBottom: rem(2.5),
    gap: spacing.DEFAULT,
    position: 'relative',
  }),
  instructionCard: style({
    textAlign: 'center',
  }),
  infoCard: style({
    paddingBottom: ' 24px',
  }),
  video: style({
    display: 'block',
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%',
    $nest: {
      '& iframe': {
        position: 'absolute',
        height: '100%',
        maxWidth: '100%',
      },
    },
  }),
  basePanelClassName: style({
    height: 'auto',
    maxWidth: '600px',
    margin: '16px auto 64px auto',
  }),
  bodyText: style({
    textAlign: 'center',
    margin: '0 0 1.5rem',
  }),
  moreInfo: style({
    marginTop: rem(2),
  }),
  link: style({
    textDecoration: 'underline',
    color: rebrand.contrast[50].toHexString(),
  }),
  modal: style({
    maxWidth: px(440),
  }),
  modalContent: style(ScrollableOverlayBodyStyle, {
    padding: '1.5rem 0 0',
    borderTop: `1px solid ${rebrand.light1.toString()}`,
    textAlign: 'center',
    $nest: {
      '& p': {
        marginBottom: 0,
      },
      '& p:first-child': {
        marginTop: 0,
      },
    },
  }),
  Arrow: style({
    transform: 'rotate(180deg)',
  }),
  jumpbtnWrapper: style(horizontal, centerCenter, {
    position: 'fixed',
    bottom: '0',
    padding: '16px 0 24px',
    width: '100%',
  }),
  jumpbtn: style(horizontal, betweenJustified, center, {
    width: '328px',
    padding: important('10px 16px'),
    borderRadius: '4px',
    backgroundColor: rebrand.neutralOnDark[20].toString(),
  }),
};
