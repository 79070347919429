import * as React from 'react';
import PricingImage from '../organisms/slices/PricingImage';
import { PrismicImage } from '../utilities/types';

export type PrismicPricingImagesPayload = {
  items: {
    pricing_image: PrismicImage;
  }[];
};
export type PrismicPricingImageProps = {
  payload: PrismicPricingImagesPayload;
};

export const PrismicPricingImage: React.SFC<PrismicPricingImageProps> = ({ payload: { items } }) => {
  return <PricingImage images={items} />;
};
