import { ClaimSchema } from '../../../types/schema';
import { claimStatusMap } from '../../../constants';

export const handleClaimsCheck = (claims: ClaimSchema[], fromModal = false) => {
  if (!claims || claims.length === 0) return claimStatusMap.NO_CLAIMS;

  if (claims.every((claim) => claim.status === 'inactive'))
    return fromModal ? claimStatusMap.CLAIM_CLEARED : claimStatusMap.CLEARED;

  if (claims.some((claim) => claim.status === 'pending')) return claimStatusMap.PENDING;
};
