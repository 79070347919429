import { PrismicRichText } from '../utilities/types';
import { HowItWorksSlice } from '../organisms/slices/HowItWorksSlice';
import { isEmpty } from '../utilities/objects';
import * as React from 'react';

export type PrismicHowItWorksPayload = {
  primary: { video_id: string };
};
export type PrismicHowItWorksProps = {
  payload: PrismicHowItWorksPayload;
};
export const PrismicHowItWorks: React.SFC<PrismicHowItWorksProps> = ({
  payload: {
    primary: { video_id },
  },
}) => <HowItWorksSlice videoId={video_id}></HowItWorksSlice>;
