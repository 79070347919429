import { Component, deps, inject, observer } from '../../lib/component';
import { classes, style } from 'typestyle';
import { SVGIcon } from '../svg-icon';
import { colorContrast, colorDarkBlueGrey, colorFavouriteHover, colorGunmetal } from '../../theme/color';
import { ButtonPill } from '../button-pill';
import React from 'react';
import { px } from 'csx';
import { transitionQuickEase } from '../../theme/transition';
import { animationPulse } from '../../theme/animation';
import { AnyObject } from '../project-happy/utilities/objects';
import { AnalyticsContext } from '../project-happy/utilities/AnalyticsContext';
import { HeartOutline } from '../project-happy/atoms/icons/HeartOutline';

const styles = {
  icon: style({
    $nest: {
      '& path': {
        stroke: colorGunmetal.toString(),
        fill: colorGunmetal.toString(),
      },
      '&.selected path': {
        stroke: colorContrast.toHexString(),
        fill: colorContrast.toHexString(),
        transition: `stroke ${transitionQuickEase}, fill ${transitionQuickEase}`,
      },
      ':hover > &.selected path': {
        stroke: colorFavouriteHover.toHexString(),
        fill: colorFavouriteHover.toHexString(),
      },
    },
  }),
  iconLoading: style({
    $nest: {
      '& path': {
        ...animationPulse,
      },
    },
  }),
};

export interface FavouritePlaylistButtonCompatProps {
  className?: string;
  identity: string;
}

@inject(deps)
@observer
export default class FavouritePlaylistButtonCompat extends Component<FavouritePlaylistButtonCompatProps> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  get isFavourite() {
    return this.props.model.playlist.favouritePlaylists.includes(this.props.identity);
  }

  async handleFavourite(analyticsData: AnyObject) {
    const { model, controller } = this.props;
    const { user } = model;
    const { playlist, onboarding } = controller;
    if (this.state.loading) return;
    if (!user.user) return onboarding.goToSignUpPage();
    const toggleFavourite = this.isFavourite ? playlist.unfavouritePlaylist : playlist.favouritePlaylist;
    const analyticsAction = this.isFavourite ? 'unfavourite' : 'favourite';
    this.setState({
      loading: true,
    });
    const response = await toggleFavourite.call(playlist, this.props.identity);
    this.setState({
      loading: false,
    });
    this.props.controller.analytics.sendMixpanel(`User clicks playlist ${analyticsAction}`, {
      identity: this.props.identity,
      ...analyticsData,
    });
    return response;
  }

  render() {
    const { className } = this.props;
    const { loading } = this.state;

    return (
      <AnalyticsContext.Consumer>
        {(analyticsData) => (
          <ButtonPill
            tertiary
            icon
            data-test-favourited-playlist-button={loading}
            className={classes('favourites-button', className, loading && styles.iconLoading)}
            disabled={loading}
            onClick={this.handleFavourite.bind(this, analyticsData)}
          >
            {this.isFavourite ? (
              <SVGIcon.Favourite
                className={classes(styles.icon, this.isFavourite && 'selected')}
                color={colorDarkBlueGrey.toHexString()}
                size={10}
              />
            ) : (
              <HeartOutline size={14} />
            )}
          </ButtonPill>
        )}
      </AnalyticsContext.Consumer>
    );
  }
}
