import * as React from 'react';
import { Component, deps, inject, observer } from '../../lib/component';
import { ButtonFavourite } from '../button-favourite';
import { TrackSchema } from '../../types/schema';
import { classes } from 'typestyle';
import { AnalyticsContext } from '../project-happy/utilities/AnalyticsContext';
import { AnyObject } from '../project-happy/utilities/objects';

export interface FavouriteButtonCompatProps {
  track: TrackSchema;
  size?: number;
  className?: string;
  withLabel?: boolean;
  i18n?: {
    favourite: string;
    unfavourite: string;
  };
}

@inject(deps)
@observer
export class FavouriteButtonCompat extends Component<FavouriteButtonCompatProps> {
  state = { bounce: false };

  render() {
    const { track, size, withLabel, i18n = FavouriteButtonCompat.i18n } = this.props;
    const { sync, tracks, removed } = this.props.model.playlist;
    const { playlistFavourites } = this.props.model.env;

    if (!track) return null;

    let isFavourite = track.user ? track.user.favourited : false;

    if (tracks[playlistFavourites].indexOf(track.identity) !== -1) {
      isFavourite = true;
    }

    if (removed[playlistFavourites].indexOf(track.identity) !== -1) {
      isFavourite = false;
    }

    const label = isFavourite ? i18n.unfavourite : i18n.favourite;

    const isSync = sync[playlistFavourites].indexOf(track.identity) !== -1;

    return (
      <AnalyticsContext.Consumer>
        {(analyticsData) => (
          <ButtonFavourite
            className={classes('favourites-button', this.props.className)}
            isFavourite={isFavourite}
            isSync={isSync}
            size={size}
            onClick={this.handleClick.bind(this, isFavourite, analyticsData)}
            label={label}
            withLabel={withLabel}
            iconBounce={this.state.bounce}
            data-test-track-favourite={isFavourite}
          />
        )}
      </AnalyticsContext.Consumer>
    );
  }

  handleClick = (isFavourite: boolean, analyticsData: AnyObject, event: React.MouseEvent<any>) => {
    event.preventDefault();
    if (!this.props.model.user.user) {
      return this.props.controller.onboarding.goToSignUpPage();
    }

    const analyticsAction = isFavourite ? 'unfavourite' : 'favourite';
    if (isFavourite) {
      this.props.controller.playlist.unfavourite(this.props.track.identity);
    } else {
      this.props.controller.playlist.favourite(this.props.track.identity);
    }
    this.handleButtonIconBounce();
    this.props.controller.analytics.sendMixpanel(`User clicks track ${analyticsAction}`, {
      identity: this.props.track.identity,
      title: this.props.track.title,
      slug: this.props.track.slug,
      ...analyticsData,
    });
  };

  handleButtonIconBounce = () => {
    this.setState({ bounce: true });

    // Reset the bouncing state after the animation finishes (1 second in this case)
    setTimeout(() => {
      this.setState({ bounce: false });
    }, 1000);
  };
  static i18n = {
    favourite: 'Favourite',
    unfavourite: 'Unfavourite',
  };
}
