import { color, ColorHelper } from 'csx';
import { SemanticInformationType } from '../components/project-happy/utilities/types';

export const colorBrand: ColorHelper = color('#4a00ff');

export const colorContrast: ColorHelper = color('#ff056d');

export const colorWhite: ColorHelper = color('#ffffff');

export const colorBlack: ColorHelper = color('#000000');

export const colorError: ColorHelper = color('#ff0550');

export const colorGoogle: ColorHelper = color('#dd4b39');

export const colorGunmetal: ColorHelper = color('#262E3F');

export const colorGradientTop: ColorHelper = color('#D62727');

export const colorSubtle: ColorHelper = color('#7b858a');

export const colorTertiary: ColorHelper = color('#ccd0d2');

export const colorBackground: ColorHelper = color('#e0e5e9');

export const colorBackgroundAlt: ColorHelper = color('#f5f7f8');

export const colorInfo: ColorHelper = color('#00b5ee');

export const colorWarning: ColorHelper = color('#eebb28');

export const colorGold: ColorHelper = color('#f5d53f');

export const colorAppHeaderBackground = color('rgba(0,0,0,0.5)');

export const colorGradientHeroCenter: ColorHelper = colorError;

export const colorGradientHeroMid: ColorHelper = color('rgba(0, 49, 255, 0.51)');

export const colorGradientHeroOuter: ColorHelper = color('rgba(0, 31, 255, 0.3)');

export const colorGradientHeroBackgroundCenter: ColorHelper = colorError;

export const colorGradientHeroBackgroundMid: ColorHelper = color('rgb(0, 49, 255)');

export const colorGradientHeroBackgroundOuter: ColorHelper = color('rgb(0, 31, 255)');

export const colorGradientPricingCenter: ColorHelper = colorError;

export const colorGradientPricingMid: ColorHelper = color('rgba(0, 49, 255, 0.85)').darken(0.3);

export const colorGradientPricingOuter: ColorHelper = color('rgba(0, 31, 255, 0.6)').darken(0.2);

export const colorDisabledImage: ColorHelper = color('rgba(211, 211, 211, 0.5)');

/* 3rd party brand colours */
export const colorYouTube: ColorHelper = color('#ff0000');

export const colorSpotify: ColorHelper = color('#1DB954');

export const colorTwitter: ColorHelper = color('#1da1f2');

export const colorDropbox: ColorHelper = color('#007ee5');

export const colorFacebook: ColorHelper = color('#3b5998');

export const colorMastercard: ColorHelper = color('#eb001b');

export const colorLinkedIn: ColorHelper = color('#0077B5');

export const colorInstagram: ColorHelper = color('#E4405F');

export const colorSignInInfo: ColorHelper = color('#841199');

/* New theme colours */
export const colorGrey: ColorHelper = color('#F4F6F7');
export const colorGreyAccent: ColorHelper = color('#E0E4E9');
export const colorLightGrey: ColorHelper = color('#E1E5EA');
export const colorCrimson: ColorHelper = color('#9E002F');
export const colorBlueGrey: ColorHelper = color('#94A4B3');
export const colorLightBlueGrey: ColorHelper = color('#F3F5F7');
export const colorDarkBlueGrey: ColorHelper = color('#B7C2CC');
export const colorDarkGreen: ColorHelper = color('#387C24');
export const colorGreen: ColorHelper = color('#0c8b11');
export const colorAmber: ColorHelper = color('#FCBA03');
export const colorLinkHover: ColorHelper = color('#3400B8');
export const colorFavouriteHover: ColorHelper = color('');
export const colorStatusWarning: ColorHelper = color('#C35600');
export const colorContrast100: ColorHelper = color('#23232D');

export const colorDarkBlueGreyDarker: ColorHelper = color('#95a6b5');

export const colorBrandDark: ColorHelper = color('#1D0066');
export const colorLightGreyDark: ColorHelper = color('(#94A4B3');

export const colorPrimary30: ColorHelper = color('#8A5CFF');
export const tertiary30: ColorHelper = color('#A638E5');

export const colorNeutral: ColorHelper = color('#565D61');
export const colorNeutralLight: ColorHelper = color('#71797D');
export const colorGreyLabel: ColorHelper = color('#8e8e94');

export const colorLickdPink: ColorHelper = color('#EA33BA');
export const colorLickdMint: ColorHelper = color('#03DAC5');
export const colorSlate: ColorHelper = color('#2E2E3B');

export const rebrand = {
  primary1: color('#EA33BA'),
  primary2: color('#DE17A9'),
  primary3: color('#B9138D'),
  highlight1: color('#03DAC5'),
  highlight2: color('#04B9A7'),
  highlight3: color('#04AA99'),
  dark1: color('#262E3F'),
  dark2: color('#212838'),
  dark3: color('#1B222E'),
  dark4: color('#181D28'),
  dark5: color('#13192D'),
  light1: color('#E0E4E9'),
  light2: color('#B7C2CC'),
  light3: color('#94A4B3'),
  primary: {
    10: color('#FFEBF8'),
    20: color('#FEC2EB'),
    30: color('#FE9ADE'),
    40: color('#FE86D8'),
    50: color('#FD57C9'),
    60: color('#FD35BE'),
    70: color('#FD21B7'),
    80: color('#F202A6'),
    90: color('#E10C9B'),
    100: color('#DA2699'),
  },
  secondary: {
    10: color('#9BFDF4'),
    20: color('#73FCEE'),
    30: color('#0EFBE4'),
    40: color('#04F1D9'),
    50: color('#03DAC5'),
    60: color('#01B7A5'),
    70: color('#02A292'),
    80: color('#018D7F'),
    90: color('#1C8679'),
    100: color('#19766B'),
  },
  contrast: {
    10: color('#FFFFFF'),
    20: color('#E1E9F4'),
    30: color('#94A4C1'),
    40: color('#5D6A84'),
    50: color('#262E3F'),
    60: color('#1B2336'),
    70: color('#13192D'),
    80: color('#0C1124'),
    90: color('#070B1E'),
    100: color('#040611'),
  },
  neutralOnLight: {
    10: color('#C9CDCF'),
    20: color('#B3B9BC'),
    30: color('#A9AFB2'),
    40: color('#9EA5A9'),
    50: color('#939B9F'),
    60: color('#7B858A'),
    70: color('#697277'),
    80: color('#6B7276'),
    90: color('#61676B'),
    100: color('#575D60'),
  },
  neutralOnDark: {
    10: color('#F3F5F7'),
    20: color('#E0E4E9'),
    30: color('#CFD6DD'),
    40: color('#B7C2CC'),
    50: color('#A0AEBB'),
    60: color('#94A4B3'),
    70: color('#7C8FA2'),
    80: color('#708599'),
    90: color('#667B8F'),
    100: color('#5D7183'),
  },
  semantic: {
    [SemanticInformationType.SUCCESS]: {
      10: color('#EDFDE2'),
      20: color('#D8FBC5'),
      30: color('#BCF5A6'),
      40: color('#9FEC8C'),
      50: color('#75E067'),
      60: color('#4FC04B'),
      70: color('#33A138'),
      80: color('#20812C'),
      90: color('#136B25'),
      100: color('#0F571E'),
    },
    [SemanticInformationType.WARNING]: {
      10: color('#FEF3DA'),
      20: color('#FEE3B5'),
      30: color('#FECF8F'),
      40: color('#FDBB74'),
      50: color('#FC9B46'),
      60: color('#D87733'),
      70: color('#B55823'),
      80: color('#923C16'),
      90: color('#78280D'),
      100: color('#6E250C'),
    },
    [SemanticInformationType.ERROR]: {
      10: color('#FFE9DC'),
      20: color('#FFCFBA'),
      30: color('#FFAE98'),
      40: color('#FF8F7E'),
      50: color('#FF665D'),
      60: color('#DB3D43'),
      70: color('#B72A3B'),
      80: color('#931A33'),
      90: color('#7A102E'),
      100: color('#6C0E29'),
    },
    [SemanticInformationType.INFO]: {
      10: color('#D7FAFE'),
      20: color('#B0F1FE'),
      30: color('#89E2FC'),
      40: color('#6BD1FA'),
      50: color('#3BB5F7'),
      60: color('#2B8ED4'),
      70: color('#1D6BB1'),
      80: color('#124C8F'),
      90: color('#0B3576'),
      100: color('#0A3370'),
    },
  },
};

export const Alternative = {
  BabyBlue: {
    10: color('#DCE8FA'),
  },
};
