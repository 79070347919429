import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const Visibility: React.SFC<SVGIconProps> = ({ size = 22, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 22 16" {...props}>
    <path
      fill={color}
      d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5ZM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5Zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3Z"
      fillRule="evenodd"
      transform="translate(-1 -4)"
    />
  </BaseSvg>
);
