import * as React from 'react';

export interface DurationProps {
  value: number;
}

export const Duration: React.SFC<DurationProps> = ({ value }) => (
  <span>{formatDuration(value)}</span>
);

export const formatDuration = (duration: number) => {
  const minutes = Math.floor(duration / 1000 / 60);
  const seconds = Math.floor(duration / 1000) % 60;

  return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
};