import * as React from 'react';
import {BrowseResultsProps} from './results';
import gql from 'graphql-tag';
import {graphql, InjectedGraphQLProps} from 'react-apollo';
import {Component, deps, inject, observer} from '../../lib/component';
import {style} from 'typestyle';
import {important, viewHeight} from 'csx';
import {centerCenter} from 'csstips';
import {TrackTileGrid, TrackTileItem} from '../tile-grid';
import {gradientDark} from '../../theme/gradient';
import {TagTile} from '../tag-tile';
import {PaginationInput} from '../../types';

@inject(deps) @observer
class BrowseTrackGrid extends Component<Props & InjectedGraphQLProps<Data>> {
  render() {
    const {data} = this.props;
    const {handleClickLink} = this.props.controller.ui;
    const {preloadImage} = this.props.controller.image;

    const tiles = data && data.tiles && data.tiles.results
      ? data.tiles.results.map(BrowseTrackGrid.mapTrack)
      : [];

    return (
      <div className={BrowseTrackGrid.styles.container}>
        <TrackTileGrid
          tiles={tiles}
          loading={data.loading}
          loadingTiles={12}
          loadingLight={true}
          renderTile={(itemClass, tile, index) => (
            <TagTile
              className={itemClass}
              key={tile.slug + index}
              tag={tile}
              preload={preloadImage}
              onClickLink={handleClickLink}
              href={BrowseTrackGrid.makeHref(tile.model.artists[0].slug, tile.slug)}
            />
          )}
        />
      </div>
    )
  }

  static mapTrack = (tile: Item): TrackTileItem => ({
    name: tile.title,
    slug: tile.slug,
    images: tile.images,
    model: tile as any, // TODO this needs deep refactor to not need to pass track all the way down
  });

  static makeHref = (artistSlug: string, trackSlug: string) =>
    `/music/artists/${artistSlug}/track/${trackSlug}`;

  static styles = {
    container: style({
      background: gradientDark,
    }),
    loading: style({
      ...centerCenter,
      width: important('100%'),
      height: viewHeight(40)
    })
  }
}

type Props = {
  seed: string;
  pagination: PaginationInput;
}

type Item = {
  identity: string;
  title: string;
  slug: string;
  images: {
    identity: string;
  }
  artist: {
    slug
  }
}

type Data = {
  tiles: {
    results: Array<Item>
  }
}

export default BrowseTrackGrid;

export const ConnectedRandomTrackGrid = graphql(gql`
  query (
    $seed: String
    $pagination: PaginationInput!
  ) {
    tiles: browseTrack(options:{type: RANDOM, query: $seed, pagination: $pagination}) {
      results {
        identity
        highlight {
          key
          value
        }
        title
        slug
        duration
        created_at
        is_charting
        is_featured
        is_new_release
        brand_sponsored
        branded_content
        audio {
          identity
        }
        images {
          identity
        }
        artists {
          highlight {
            key
            value
          }
          name
          slug
          identity
        }
      }
    }
  }
`, {
  options: (variables: BrowseResultsProps) => ({
    variables,
    // fetchPolicy: 'network-only'
  }),
})(BrowseTrackGrid);