import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { percent, px } from 'csx';
import { YouTubeBadge, YouTubeBadgeProps } from '../../../atoms/YouTubeBadge';
import { mediaLargeMobile, mediaUpToLargeMobile } from '../../../utilities/mediaQueries';

const styles = {
  wrapper: style({
    $nest: {
      '&:first-child': {
        marginTop: px(60),
      },
      '&:last-child': {
        marginBottom: px(60),
      },
    },
  }),
  badge: style(
    {
      background: 'rgba(24,24,24,0.7)',
      color: 'white',
      borderRadius: px(8),
      boxShadow: '0 1px 0 0 rgba(0,0,0,0.5)',
    },
    mediaUpToLargeMobile({
      padding: '8px',
    }),
    mediaLargeMobile({
      padding: '10px 8px',
    })
  ),
};

cssRaw(`
@supports (backdrop-filter: blur(10px) saturate(1%)) {
  .${styles.badge} {
    background: rgba(24,24,24,0.3) !important;
    backdrop-filter: blur(10px) saturate(1%);
  }
}
`);

export type FloatingYouTubeBadgeProps = YouTubeBadgeProps & { xPosition?: number };

export const FloatingYouTubeBadge: React.SFC<FloatingYouTubeBadgeProps> = ({
  children,
  className,
  xPosition,
  ...props
}) => (
  <div className={styles.wrapper}>
    <YouTubeBadge
      className={classes(styles.badge, style({ marginLeft: xPosition ? percent(xPosition) : 0 }), className)}
      {...props}
    />
  </div>
);
