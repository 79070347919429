import * as React from 'react';
import { Component, inject, deps, observer, ComponentProps } from '../lib/component';
import { DownloadModal } from '../components/download-modal';
import { ChannelAddModal } from '../components/channel-add-modal';
import { LicenseModal } from '../components/license-modal';
import { VideoAttributionModal } from '../components/video-attribution-modal';
import { OnboardingBetaModal } from '../components/onboarding-beta-modal';
import { OnboardingSignUpModal } from '../components/onboarding-sign-up-modal';
import { OnboardingTermsConditionsModal } from '../components/onboarding-terms-conditions-modal';
import { OnboardingPrivacyPolicyModal } from '../components/onboarding-privacy-policy-modal';
import { ChannelSelectModal } from '../components/channel-select-modal';
import { DeleteAccountModal } from '../components/delete-account-modal';
import { DownloadAccountDataModal } from '../components/download-account-data-modal';
import { noop } from '../lib/noop';
import { RestrictedModal } from '../components/restricted-modal';
import { BasketRestrictedModal } from '../components/basket-restricted-modal';
import { ChannelSelectModalV2 } from '../components/channel/channel-select-modal-v2';
import { SongInfoModal } from '../components/project-happy/organisms/SongInfoModal';
import { BasketErrorModal } from '../components/project-happy/organisms/BasketErrorModal';
import { EventOnError } from '../components/project-happy/atoms/EventOnError';
import { ScrollableOverlay } from '../components/scrollable-overlay/scrollable-overlay';
import { ReconnectChannelModal } from '../components/project-happy/organisms/ReconnectChannelModal';
import { SUPPORT_HUB } from '../constants';
import { PlatformAnnouncementModal } from '../components/project-happy/organisms/PlatformAnnouncementModal';
import { SpawnedPrismicModals } from '../components/project-happy/organisms/SpawnedPrismicModals';
import { CreateUserPlaylistModal } from '../components/project-happy/organisms/CreateUserPlaylistModal';
import { AddChannelForModal } from '../components/project-happy/organisms/AddChannelForModal';
import { BasketUnsubscribedModal } from '../components/project-happy/organisms/BasketUnsubscribedModal';
import { SavingsReminderModalLegacy } from '../components/project-happy/organisms/SavingsReminderModalLegacy';
import { SavingsReminderModal } from '../components/project-happy/organisms/SavingsReminderModal';
import { PostDowngradeModal } from '../components/project-happy/organisms/PostDowngradeModal';
import { PostUpgradeModal } from '../components/project-happy/organisms/PostUpgradeModal';
import { PreDowngradeModal } from '../components/project-happy/organisms/plans-v2/PreDowngradeModal';
import { PreUpgradeModal } from '../components/project-happy/organisms/plans-v2/PreUpgradeModal';

@inject(deps)
@observer
export class Modals extends Component<ComponentProps> {
  state = {
    errorModalShown: false,
  };

  constructor(props) {
    super(props);

    this.handleError = this.handleError.bind(this);
  }

  render() {
    const analytics = this.props.controller.analytics;
    const uiModel = this.props.model.ui;
    const uiController = this.props.controller.ui;
    const onboardingModel = this.props.model.onboarding;
    const onboardingController = this.props.controller.onboarding;
    const content = this.props.model.content;
    const couponModel = this.props.model.coupon;
    const modalModel = this.props.model.modal;
    const modalController = this.props.controller.modal;
    const referralURL = this.props.model.user.referralURL;

    const downloadI18n = {
      ...DownloadModal.i18n,
      title: content.downloadModalTitle,
      strap: content.downloadModalStrap,
      agree: content.downloadModalAgree,
      referralURL,
    };

    const addChannelI18n = {
      ...ChannelAddModal.i18n,
      strap: content.addChannelStrap,
    };

    const licenseModalI18n = {
      ...LicenseModal.i18n,
      title: content.licenceModalTitle,
      loading: content.licenceModalLoading,
      // strap: content.licenceModalStrap,

      not_available: content.licenceModalNotAvailable,
      close: content.licenceModalClose,
    };

    const attributionModalI18n = {
      ...VideoAttributionModal.i18n,
      why: content.attributionWhy,
      strap: content.attributionBlurb,
      referralURL,
    };

    // Project subscription feature flag - delete post-launch **PROJECT_SUB_FLAG**
    const useNewModal = uiModel.projectSubscriptionEnabled;

    return (
      <>
        <ScrollableOverlay show={this.state.errorModalShown} onRequestClose={() => window.location.reload()}>
          <p style={{ margin: '48px auto 16px', width: '75%', textAlign: 'center' }}>
            An error occurred and the Platform must reload. If you see this message repeatedly, please{' '}
            <a href={SUPPORT_HUB}>submit a support ticket</a>.
          </p>
        </ScrollableOverlay>
        <EventOnError onError={this.handleError}>
          <PlatformAnnouncementModal
            show={modalModel.platformAnnouncementsShow}
            onRequestClose={modalController.hidePlatformAnnouncements}
          />
          <OnboardingBetaModal
            model={onboardingModel.betaModal}
            onRequestClose={onboardingController.dismissBetaModal}
            onChangeEmail={onboardingController.changeBetaEmail}
          />
          <OnboardingSignUpModal
            model={onboardingModel.signUpModal}
            onClickSignUp={uiController.openSignInWindow}
            onRequestClose={onboardingController.closeSignUpModal}
            onClickTermsConditions={this.handleClickTermsConditions}
            onClickPrivacyPolicy={this.handleClickPrivacyPolicy}
            i18n={content.signUpModalI18n}
          />
          <OnboardingTermsConditionsModal
            model={onboardingModel.termsConditionsModal}
            onRequestClose={onboardingController.closeTermsConditionsModal}
          />
          <OnboardingPrivacyPolicyModal
            model={onboardingModel.privacyPolicyModal}
            onRequestClose={onboardingController.closePrivacyPolicyModal}
          />
          <DownloadModal
            model={modalModel.downloadModal}
            i18n={downloadI18n}
            onAgreeToTerms={modalController.agreeToDownloadTerms}
            onRequestClose={modalController.hideDownloadModal}
            onDownloadOriginal={this.acknowledgeTrackDownload}
            onDownloadInstrumental={noop}
            onDownloadVocal={noop}
            onSendAllToDropbox={noop}
            analytics={analytics}
          />
          <VideoAttributionModal
            i18n={attributionModalI18n}
            model={modalModel.attributionModal}
            onRequestClose={modalController.hideAttributionModal}
          />
          <ChannelAddModal
            i18n={addChannelI18n}
            model={modalModel.addChannel}
            onSearch={modalController.addChannelSearch}
            onSelect={modalController.addChannelSelect}
            onDeselect={modalController.addChannelDeselect}
            onLink={modalController.addChannelLink}
            onRequestClose={modalController.hideChannelAddModal}
          />
          <ChannelSelectModal
            model={modalModel.selectChannel}
            onRequestClose={modalController.hideChannelSelectModal}
            onSelectChannel={modalController.changeChannel}
            onAdd={modalController.showChannelAddModal}
          />
          <ChannelSelectModalV2
            model={modalModel.selectChannelV2}
            onRequestClose={modalController.hideChannelSelectV2Modal}
            onSelectChannel={modalController.changeChannel}
            onAdd={modalController.showChannelAddModal}
          />
          <ReconnectChannelModal
            show={modalModel.reconnectChannelShow}
            onRequestClose={modalController.hideReconnectChannelModal}
            channel={modalModel.reconnectChannelObject}
          />
          <SongInfoModal
            show={modalModel.songInfoShow}
            track={modalModel.songInfoTrack}
            onRequestClose={modalController.hideSongInfoModal}
          />
          <AddChannelForModal
            show={modalModel.addChannelForShow}
            onRequestClose={modalController.hideAddChannelForModal}
          />
          <LicenseModal
            model={modalModel.license}
            onRequestClose={modalController.hideLicenseModal}
            onSelectRate={modalController.licenseModalSelectRate}
            coupon={couponModel}
          />
          <RestrictedModal model={modalModel.restricted} onRequestClose={modalController.hideRestrictedModal} />
          <BasketRestrictedModal
            basket={modalModel.basketRestricted}
            onRequestClose={modalController.hideBasketRestrictedModal}
          />
          <BasketErrorModal show={modalModel.basketErrorShow} onRequestClose={modalController.hideBasketErrorModal} />
          <DeleteAccountModal
            model={modalModel.deleteAccount}
            onRequestClose={modalController.hideDeleteAccountModal}
            onDeleteAccount={modalController.submitDeleteAccount}
          />
          <DownloadAccountDataModal
            model={modalModel.downloadAccountData}
            onRequestClose={modalController.hideDownloadAccountDataModal}
            onDownload={modalController.submitDownloadAccountData}
          />
          <CreateUserPlaylistModal
            show={modalModel.showCreatePlaylistModal}
            onRequestClose={modalController.hideCreatePlaylistModal}
            onSuccess={modalController.handleCreatePlaylistModalSuccess}
          />
          <BasketUnsubscribedModal
            show={modalModel.showBasketUnsubscribedModal}
            onRequestClose={modalController.hideBasketUnsubscribedModal}
          />
          {useNewModal ? (
            <SavingsReminderModal
              show={modalModel.showSavingsReminderModal}
              onRequestClose={modalController.hideSavingsReminderModal}
            />
          ) : (
            <SavingsReminderModalLegacy
              show={modalModel.showSavingsReminderModal}
              onRequestClose={modalController.hideSavingsReminderModal}
            />
          )}
          <PostDowngradeModal
            show={modalModel.showPostDowngradeModal}
            onRequestClose={modalController.hidePostDowngradeModal}
          />
          <PostUpgradeModal
            show={modalModel.showPostUpgradeModal}
            onRequestClose={modalController.hidePostUpgradeModal}
          />
          <PreDowngradeModal
            show={modalModel.showPreDowngradeModal}
            onRequestClose={modalController.hidePreDowngradeModal}
          />
          <PreUpgradeModal show={modalModel.showPreUpgradeModal} onRequestClose={modalController.hidePreUpgradeModal} />
          <SpawnedPrismicModals />
        </EventOnError>
      </>
    );
  }

  handleError(error: Error) {
    this.setState({ errorModalShown: true });
    this.props.controller.bugsnag.notifyExceptionSafely(error);
  }

  handleClickTermsConditions = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    this.props.controller.onboarding.showTermsConditionsModal();
  };

  handleClickPrivacyPolicy = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    this.props.controller.onboarding.showPrivacyPolicyModal();
  };

  handleReAuthSignIn = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    this.props.controller.modal.hideMustReAuthModal();
    this.props.controller.onboarding.goToSignUpPage();
  };

  acknowledgeTrackDownload = (identity) => {
    return this.props.controller.api.audio.acknowledgeDownload(identity) as Promise<any>;
  };
}
