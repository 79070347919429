import React from 'react';
import { Button, ButtonProps } from './Button';
import { px } from 'csx';
import { classes, style } from 'typestyle';
import { centerCenter, flexRoot } from 'csstips';
import { colorBlueGrey, colorDarkBlueGrey, colorGreyAccent } from '../../../../theme/color';
import { transitionQuickEase } from '../../../../theme/transition';
import { CircleButton } from './CircleButton';
import { BaseButton } from './BaseButton';
import { ButtonProminence, buttonProminence, ButtonSize, buttonSize, circleButton } from './buttonStyles';

const styles = style(circleButton, buttonProminence[ButtonProminence.SECONDARY]);

export const SocialButton: React.SFC<ButtonProps> = ({ children, className, ...props }) => (
  <BaseButton className={classes(styles, className)} {...props}>
    {children}
  </BaseButton>
);
