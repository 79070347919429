import * as React from 'react';
import {BrowseResultsProps} from './results';
import gql from 'graphql-tag';
import {graphql, InjectedGraphQLProps} from 'react-apollo';
import {Component, deps, inject, observer} from '../../lib/component';
import {style} from 'typestyle';
import {important, viewHeight} from 'csx';
import {centerCenter} from 'csstips';
import {PlaylistTile} from '../playlist-tile';
import {PlaylistTileGrid, PlaylistTileItem} from '../tile-grid';
import {gradientDark} from '../../theme/gradient';
import {PaginationInput} from '../../types';

@inject(deps) @observer
class BrowsePlaylistGrid extends Component<Props & InjectedGraphQLProps<Data>> {
  render() {
    const {data} = this.props;
    const {handleClickLink} = this.props.controller.ui;
    const {preloadImage} = this.props.controller.image;

    const tiles = data && data.tiles && data.tiles.results
      ? data.tiles.results
      : [];

    return (
      <div className={BrowsePlaylistGrid.styles.container}>
        <PlaylistTileGrid
          tiles={tiles}
          loading={data.loading}
          loadingTiles={12}
          loadingLight={true}
          renderTile={(itemClass: string, tile: PlaylistTileItem, index: number) => (
            <PlaylistTile
              className={itemClass}
              href={BrowsePlaylistGrid.playlistPath(tile.slug)}
              key={tile.slug + index}
              playlist={tile}
              preload={preloadImage}
              onClick={handleClickLink}
            />
          )}
        />
      </div>
    )
  }

  static playlistPath = (slug: string) => {
    return `/browse/collection/${slug}`;
  };

  static styles = {
    container: style({
      background: gradientDark,
    }),
    loading: style({
      ...centerCenter,
      width: important('100%'),
      height: viewHeight(40)
    })
  };
}

type Props = {
  pagination: PaginationInput;
}

type Item = {
  name: string;
  slug: string;
  images: {
    identity: string;
  }
}

type Data = {
  tiles: {
    results: Array<Item>
  }
}

export default BrowsePlaylistGrid;

export const ConnectedBrowsePlaylistGrid = graphql(gql`
  query (
    $pagination: PaginationInput!
  ) {
    tiles: browseCollection(options:{type:PLAYLIST_COLLECTION, pagination: $pagination}) {
      results {
        name
        slug
        images {
          identity
        }
      }
    }
  }
`, {
  options: (variables: BrowseResultsProps) => ({
    variables,
    // fetchPolicy: 'network-only'
  }),
})(BrowsePlaylistGrid);