import * as React from 'react';
import {deps, inject, observer, StatefulComponent} from '../../lib/component';
import {classes, style} from 'typestyle';
import {percent, px, rem, viewHeight, viewWidth} from 'csx';
import {graphql, InjectedGraphQLProps} from 'react-apollo';
import gql from 'graphql-tag';
import maybe from '../../lib/maybe';
import {centerCenter, content, flex1, horizontallySpaced} from 'csstips';
import {
  Artist,
  ArtistResults,
  FallbackArtist,
  FallbackArtistResults,
  FindFiltersInput,
  Track,
  TrackResults
} from '../../types/graphql';
import {Carousel} from '../carousel';
import {AppHeroSearchResultsFallback} from '../app-hero-search-results-fallback';
import {AppHeroSearchResultsEmpty} from '../app-hero-search-results-empty';
import {Mode} from '../../types/ui';
import {TrackTile} from '../track-tile';
import {FavouriteButtonCompat} from '../compat/favourite-button-compat';
import {AddRemoveCompat} from '../compat/add-remove-compat';
import {PlayButtonCompat} from '../compat/play-button-compat';
import {mediaMobileOnly, mediaTablet} from '../../theme/media';
import {colorBlack, colorContrast, colorWhite} from '../../theme/color';
import {transitionQuickEase} from '../../theme/transition';
import {resetAnchorStyles} from '../../theme/reset';
import {Loading} from '../loading/loading';

export type HeroSearchResultsProps = {
  query: string;
  filters: FindFiltersInput;
  mode: Mode;
  onData?(data: HeroSearchInputSuggestionDataType): any;
  onMore?(query: string, filters: FindFiltersInput): any;
}

type State = {

}

export type HeroSearchInputSuggestionDataType = {
  find: null | {
    tracks: null | TrackResults
    artists: null | ArtistResults
    fallbackArtists: null | FallbackArtistResults
  }
};

type DataProps = {
  loading: boolean;
  error?: any;
}

type MaybeData = HeroSearchInputSuggestionDataType & DataProps | null;
type CombinedProps = HeroSearchResultsProps & InjectedGraphQLProps<HeroSearchInputSuggestionDataType>;

const constants = {
  mobileSize: viewWidth(75),
  mobileSizeMax: viewHeight(60),
  desktopSize: viewWidth(22),
  desktopSizeMin: px(320),
  desktopSizeMax: viewHeight(50),
  easing: { stiffness: 1000, damping: 50 }
};

@inject(deps) @observer
export default class HeroSearchResults extends StatefulComponent<CombinedProps, State> {
  render() {
    const {data, mode, filters} = this.props;
    const {preloadImage} = this.props.controller.image;
    const {handleClickLink} = this.props.controller.ui;

    const tracks = maybe<Track[]>(
      () => data!.find!.tracks!.results!,
      []
    );

    const artist = maybe<Artist>(
      () => data!.find!.artists!.results![0],
      null
    );

    const fallbackArtist = maybe<FallbackArtist>(
      () => data!.find!.fallbackArtists!.results![0],
      null
    );

    const showFallbackArtist = !data.loading && mode === 'artist' && fallbackArtist && (!artist || tracks.length === 0);
    const showEmptyFallback = !data.loading && !showFallbackArtist && tracks.length === 0;

    return (
      <div className={HeroSearchResults.styles.container}>
        <Carousel className={HeroSearchResults.styles.carousel} innerClassName={HeroSearchResults.styles.carouselInner} spacerClassName={HeroSearchResults.styles.carouselSpacer}>
          {data.loading && new Array(6).fill(1).map((_, i) => (
            <div key={i} className={classes(HeroSearchResults.styles.track, HeroSearchResults.styles.loading)}>
              <Loading light={true}/>
            </div>
          ))}
          {!data.loading && showFallbackArtist && (
            <AppHeroSearchResultsFallback artist={fallbackArtist}/>
          )}
          {!data.loading && showEmptyFallback && (
            <AppHeroSearchResultsEmpty mode={mode} />
          )}
          {!data.loading && tracks.map((item, index) => (
            <TrackTile
              analytics={this.props.controller.analytics}
              scale={1.2}
              key={index}
              track={item}
              onClickLink={handleClickLink}
              PlayButton={PlayButtonCompat}
              AddRemove={AddRemoveCompat}
              Favourite={FavouriteButtonCompat}
              preload={preloadImage}
              className={HeroSearchResults.styles.track}
              // onClickMore={this.handleMore.bind(this, item)}
            />
          ))}
          {!data.loading && filters.artist && artist && (
            <a href={`/music/artists/${artist.slug}`} onClick={handleClickLink} className={classes(HeroSearchResults.styles.track, HeroSearchResults.styles.more)}>
              Browse more
            </a>
          )}
        </Carousel>
      </div>
    )
  }

  // handleMore = (track: Track) => {
  //   if (this.props.onMore)
  //     this.props.onMore('', findFilters({
  //       track: track.name
  //     }));
  // };

  static styles = {
    container: style(
      {
        ...flex1,
        ...centerCenter,
        ...content,
        width: viewWidth(100)
      },
      mediaMobileOnly({
        margin: '0 -1rem'
      }),
      mediaTablet({
        margin: '0 -5rem'
      }),
    ),
    carousel: style(
      mediaMobileOnly({ height: constants.mobileSize, maxHeight: constants.mobileSizeMax }),
      mediaTablet({ height: constants.desktopSize, minHeight: constants.desktopSizeMin, maxHeight: constants.desktopSizeMax }),
    ),
    carouselInner: style(
      { ...horizontallySpaced(20), height: percent(100) },
      mediaMobileOnly({ padding: '0 1.25rem' }),
      mediaTablet({ padding: '0 5rem' }),
    ),
    carouselSpacer: style(
      { flexShrink: 0 },
      mediaMobileOnly({ width: px(1), marginLeft: px(-1) }),
      mediaTablet({ width: rem(4) })
    ),
    track: style(
      { height: 'auto !important' },
      mediaMobileOnly({ width: constants.mobileSize, maxWidth: constants.mobileSizeMax }),
      mediaTablet({ width: constants.desktopSize, minWidth: constants.desktopSizeMin, maxHeight: constants.desktopSizeMax }),
    ),
    more: style({
      ...centerCenter,
      flexShrink: 0,
      background: colorContrast.toString(),
      color: colorWhite.toString(),
      fontWeight: 'bold',
      fontSize: px(18),
      transition: `all ${transitionQuickEase}`,
      $nest: {
        ...resetAnchorStyles(colorWhite.toString()),
        '&:hover': {
          background: colorContrast.darken(0.15).toString()
        }
      }
    }),
    loading: style({
      ...centerCenter,
      flexShrink: 0,
      background: colorBlack.fade(0.1).toString(),
      color: colorWhite.toString(),
      fontWeight: 'bold',
      fontSize: px(18),
      transition: `all ${transitionQuickEase}`
    })
  }
}

export const ConnectedHeroSearchResults = graphql(gql`
  query ($query: String!, $filters: FindFiltersInput!) {
    find(query: $query, filters: $filters) {
      tracks {
        results {
          identity
          highlight {
            key
            value
          }
          title
          slug
          duration
          created_at
          is_charting
          is_featured
          is_new_release
          brand_sponsored
          branded_content
          audio {
            identity
          }
          images {
            identity
          }
          artists {
            highlight {
              key
              value
            }
            name
            slug
            identity
          }
        }
      }
      artists(pagination: {size: 1, from: 0}) {
        results {
          name
          slug
          images {
            identity
          }
        }
      }
      fallbackArtists(pagination: {size: 1, from: 0}) {
        results {
          name
          slug
          image {
            url
          }
          tracks {
            results {
              identity
              highlight {
                key
                value
              }
              title
              slug
              duration
              created_at
              is_charting
              is_featured
              is_new_release
              brand_sponsored
              branded_content
              audio {
                identity
              }
              images {
                identity
              }
              artists {
                highlight {
                  key
                  value
                }
                name
                slug
                identity
              }
            }
          }
        }
      }
    }
  }
`, {
  options: (variables: HeroSearchResultsProps) => ({
    variables,
  })
})(HeroSearchResults);