import React, { Component } from 'react';
import { classes, style } from 'typestyle';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { mediaDesktop, mediaMobileOnly, mediaUpToDesktop } from '../utilities/mediaQueries';
import { OnboardVerticalsLayout } from '../templates/OnboardVerticalsLayout';
import { LeftHandOnboardContentPanel } from '../atoms/LeftHandOnboardContentPanel';
import { RightHandOnboardContentPanel } from '../atoms/RightHandOnboardContenntPanel';
import { ChannelNameAvatar } from '../molecules/ChannelNameAvatar';
import { OnboardProgressBar } from '../atoms/OnboardProgressBar';
import isEmpty from 'lodash/isEmpty';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { Button } from '../atoms/button/Button';
import { ArrowRight } from '../atoms/icons/ArrowRight';
import { rebrand } from '../../../theme/color';
import { ButtonCircle } from '../../button-circle';
import { CloseCross } from '../atoms/icons/CloseCross';
import { Link } from '../atoms/Link';
import { CircleButton } from '../atoms/button/CircleButton';
import { ChevronLeft } from '../atoms/icons/ChevronLeft';
import { ONBOARD_FAVOURITE_GENRES, ONBOARD_ROUTE, VERTICALS } from '../../../constants';
import autosVehicles from '../../../static/channel-onboarding/channel-verticals/Category_Autos-&-Vehicles_SQUARE_02-1800x0.jpg';
import foodDrink from '../../../static/channel-onboarding/channel-verticals/Category_Food-and-Drink_02-1800x0.jpg';
import politicsNews from '../../../static/channel-onboarding/channel-verticals/Category_Politics-And-News_02-1800x0.jpg';
import socialEnvironmental from '../../../static/channel-onboarding/channel-verticals/Category_Social-Environmental_02.jpg';
import animals from '../../../static/channel-onboarding/channel-verticals/Video_Theme_Animals_Square_01.jpg';
import beautyFashion from '../../../static/channel-onboarding/channel-verticals/Video+Theme_Beauty-Fashion-1800x0.jpg';
import gamingTechnology from '../../../static/channel-onboarding/channel-verticals/Video+Theme_Gamin+Technology-1800x0.jpg';
import healthFitness from '../../../static/channel-onboarding/channel-verticals/Video+Theme_Health+_+Fitness-1800x0.jpg';
import humourComedy from '../../../static/channel-onboarding/channel-verticals/Video+Theme_Humour+Comedy-1800x0.jpg';
import kidsFamily from '../../../static/channel-onboarding/channel-verticals/Video+Theme_Kids+Family_Square_01-1800x0.jpg';
import sports from '../../../static/channel-onboarding/channel-verticals/Video+Theme_Sports-1800x0.jpg';
import travel from '../../../static/channel-onboarding/channel-verticals/Video+Theme_Travel-1800x0.jpg';
import { VerticalTile } from '../atoms/VerticalTile';

const styles = {
  hideUpToDesktop: style(mediaUpToDesktop({ display: 'none' })),
  hideDesktop: style(mediaDesktop({ display: 'none' })),
  hideMobile: style(mediaMobileOnly({ display: 'none' })),
  matchHeadingText: style(mediaUpToDesktop({ marginTop: '32px', top: '112px' }), {
    fontSize: '32px',
    position: 'absolute',
    top: '160px',
    marginTop: '68px',
  }),
  boldText: style({
    fontWeight: 700,
  }),
  text: style(mediaUpToDesktop({ top: '216px', width: '350px' }), {
    width: '351px',
    position: 'absolute',
    top: '300px',
  }),
  button: style(
    mediaUpToDesktop({
      bottom: '64px',
      margin: '32px 16px',
      right: '0px',
      width: '85px',
    }),
    {
      display: 'grid',
      alignItems: 'center',
      width: '101px',
      position: 'absolute',
      bottom: '-80px',
      right: '-96px',
      height: '40px',
      boxShadow: 'none',
      margin: '32px 16px',
      $nest: {
        '&:hover': {
          boxShadow: 'none',
        },
      },
    }
  ),
  arrow: style({
    position: 'absolute',
    right: '12px',
  }),
  textBtnColour: style({
    color: rebrand.contrast[90].toHexString(),
  }),
  textBtn: style({ fontSize: '16px' }),
  rightPanelContainer: style(
    mediaUpToDesktop({ height: '32px', backgroundColor: rebrand.neutralOnDark['10'].toString() }),
    mediaDesktop({ display: 'flex', justifyContent: 'center' }),
    { overflow: 'hidden', height: '93vh' }
  ),
  exitBlock: style(mediaDesktop({ display: 'none' }), {
    display: 'flex',
    alignItems: 'baseline',
    position: 'absolute',
    top: '0px',
    marginLeft: '16px',
  }),
  exitText: style({ fontSize: '12px', marginLeft: '8px', color: rebrand.contrast['40'].toString() }),
  exitButton: style({
    backgroundColor: rebrand.neutralOnDark['20'].toString(),
  }),
  backButton: style(mediaUpToDesktop({ bottom: '64px', left: '0px', margin: '32px 16px' }), {
    position: 'absolute',
    bottom: '-80px',
    left: '-96px',
    width: '40px',
    height: '40px',
    margin: '32px 16px',
  }),
  verticalWrapper: style({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: '700px',
    minWidth: '651px',
    overflowY: 'scroll',
    height: '100%',
  }),
  verticalWrapperMobile: style({
    display: 'flex',
    flexWrap: 'wrap',
    width: '352px',
    position: 'relative',
    top: '270px',
    $nest: {
      '& p': {
        color: rebrand.contrast[50].toHexString(),
      },
    },
  }),
  container: style(mediaUpToDesktop({ height: '2050px' })),
};

const ChannelVerticals = [
  { img: autosVehicles, text: VERTICALS[1] },
  { img: foodDrink, text: VERTICALS[6] },
  { img: politicsNews, text: VERTICALS[3] },
  { img: socialEnvironmental, text: VERTICALS[12] },
  { img: animals, text: VERTICALS[0] },
  { img: beautyFashion, text: VERTICALS[2] },
  { img: gamingTechnology, text: VERTICALS[7] },
  { img: healthFitness, text: VERTICALS[8] },
  { img: humourComedy, text: VERTICALS[9] },
  { img: kidsFamily, text: VERTICALS[10] },
  { img: sports, text: VERTICALS[13] },
  { img: travel, text: VERTICALS[14] },
];

const { PRIMARY } = ButtonProminence;

@inject(deps)
@observer
export class OnboardVerticalCategorisationPage extends Component<
  ComponentProps,
  {
    verticalSelected: boolean;
    selectedIndex: number;
    requiredNumberOfSelections: number;
    selectedCount: number;
    vertical: string;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      verticalSelected: false,
      selectedIndex: null,
      requiredNumberOfSelections: 1,
      selectedCount: 0,
      vertical: null,
    };
  }

  render() {
    const {
      model: {
        user: {
          user: { identity },
        },
        user: { channel },
      },
      controller: {
        analytics: { sendMixpanel },
        api: {
          user: { setChannelVertical },
        },
      },
    } = this.props;
    const { verticalSelected, selectedIndex, selectedCount, requiredNumberOfSelections, vertical } = this.state;
    return (
      <OnboardVerticalsLayout className={styles.container}>
        <LeftHandOnboardContentPanel>
          {!isEmpty(channel) && (
            <>
              <ChannelNameAvatar />

              <OnboardProgressBar step={1} />

              <h3 className={styles.matchHeadingText}>Which one of the options best describes your content?</h3>

              <p className={styles.text}>
                <span className={styles.boldText}>Select 1</span> from the options on the right so we can show you music
                that tends to work best for your type of content and subscribers
              </p>
              <div className={classes(styles.hideDesktop, styles.verticalWrapperMobile)}>
                {ChannelVerticals.map((vertical, i) => (
                  <VerticalTile
                    image={vertical.img}
                    text={vertical.text}
                    key={i}
                    onClick={() => {
                      this.handleTileSelect(i, vertical.text, sendMixpanel);
                    }}
                    selected={verticalSelected && i === selectedIndex}
                    reachedMaximumNumberOfItemsToBeSelected={selectedCount === requiredNumberOfSelections}
                  />
                ))}
              </div>

              <Button
                className={classes(styles.button)}
                prominence={PRIMARY}
                href={ONBOARD_FAVOURITE_GENRES}
                disabled={!verticalSelected}
                onClick={() => {
                  setChannelVertical(identity, channel.id, vertical);
                  sendMixpanel('user clicks next onboard categories');
                }}
                data-test-next-button
              >
                <span className={classes(verticalSelected && styles.textBtnColour, styles.textBtn)}>Next</span>
                <ArrowRight className={styles.arrow} color={rebrand.contrast[90].toHexString()} />
              </Button>
              <Link
                href={ONBOARD_ROUTE}
                data-test-back-button
                onClick={() => {
                  sendMixpanel('user clicks back - onboard categories');
                }}
              >
                <CircleButton className={styles.backButton} prominence={ButtonProminence.SECONDARY}>
                  <ChevronLeft size={10} />
                </CircleButton>
              </Link>
            </>
          )}
        </LeftHandOnboardContentPanel>
        <RightHandOnboardContentPanel className={styles.rightPanelContainer}>
          <Link href="/" data-test-explore-button>
            <div className={styles.exitBlock}>
              <ButtonCircle className={styles.exitButton} size={16}>
                <CloseCross size={10} />
              </ButtonCircle>
              <p className={styles.exitText}>Just let me explore</p>
            </div>
          </Link>
          <div className={classes(styles.verticalWrapper, styles.hideUpToDesktop)}>
            {ChannelVerticals.map((vertical, i) => (
              <VerticalTile
                image={vertical.img}
                text={vertical.text}
                key={i}
                onClick={() => this.handleTileSelect(i, vertical.text, sendMixpanel)}
                selected={verticalSelected && i === selectedIndex}
                reachedMaximumNumberOfItemsToBeSelected={selectedCount === requiredNumberOfSelections}
              />
            ))}
          </div>
        </RightHandOnboardContentPanel>
      </OnboardVerticalsLayout>
    );
  }

  handleTileSelect = (
    selectedIndex: number,
    verticalText: string,
    sendMixpanel: (event: string, properties?: Record<string, any>) => void
  ): void => {
    this.setState((prevState) => {
      const selected = !prevState.verticalSelected;
      const count = selected ? prevState.selectedCount + 1 : prevState.selectedCount - 1;
      sendMixpanel('user selects category', { category_type: verticalText });
      return {
        verticalSelected: selected,
        selectedIndex: selectedIndex,
        selectedCount: count,
        vertical: verticalText,
      };
    });
  };
}
