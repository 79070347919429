import { SlicePageContext, PrismicSimplePlaylistProps, isTypeArray } from '../utilities/types';
import * as React from 'react';
import { TopPicksCarouselSlice } from '../organisms/slices/TopPicksCarouselSlice';
import { isTrackSchema, TrackSchema } from '../../../types/schema';
import { isArray } from '../utilities/objects';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { ClassicComponentClass } from 'react';
import { ObservableMap } from 'mobx';

export type PrismicCarouselPayload = PrismicSimplePlaylistProps;
export type PrismicCarouselProps = {
  payload: PrismicCarouselPayload;
  context: SlicePageContext;
};
export const PrismicTopPicksCarousel = observer(
  ({
    payload: {
      primary: { playlist_heading, playlist_slug },
    },
    context: { playlists },
  }) => {
    const playlist = playlists.get(playlist_slug);
    if (!isTypeArray<TrackSchema>(playlist, isTrackSchema)) return null;
    return <TopPicksCarouselSlice heading={playlist_heading} tracks={playlist} />;
  }
);
