import * as React from 'react';
import { Component, deps, inject, observer } from '../lib/component';
import { Loading } from '../components/loading/loading';
import { PageAccountBillingVatNumber } from '../components/page-account-billing-vat-number';
import { FormVatNumberModal } from '../components/form-vat-number-modal';
import { PageAccountBillingAddresses } from '../components/page-account-billing-addresses';
import { FormAddressModal } from '../components/form-address-modal';
import { style } from 'typestyle';
import { px } from 'csx';
import { centerCenter } from 'csstips';
import {AccountLayout} from "../components/project-happy/templates/AccountLayout";

export interface AccountBillingPageProps {
  model?: any;
}

@inject(deps)
@observer
export class AccountBillingPage extends Component<AccountBillingPageProps> {
  render() {
    const model = this.props.model.page.accountBilling;
    const { accountBilling } = this.props.controller.page;

    if (model.loading)
      return (
        <AccountLayout>
          <div className={AccountBillingPage.styles.containerLoading}>
            <Loading />
          </div>
        </AccountLayout>
      );

    return (
      <AccountLayout>
        <PageAccountBillingVatNumber model={model} onEdit={accountBilling.editVatNumber} />
        <FormVatNumberModal
          model={model.modalVatNumber}
          onChange={accountBilling.changeVatNumberField}
          onCancel={accountBilling.closeVatNumberModal}
          onRequestClose={accountBilling.closeVatNumberModal}
          onSubmit={accountBilling.submitVatNumberModal}
        />
        <PageAccountBillingAddresses
          model={model}
          onAddAddress={accountBilling.addAddress}
          onEditAddress={accountBilling.editAddress}
        />
        <FormAddressModal
          model={model.modalAddress}
          onChange={accountBilling.changeAddressField}
          onSuggestion={accountBilling.onSuggestAddress}
          onSubmit={accountBilling.submitAddress}
          onCancel={accountBilling.closeAddressModal}
          onRequestClose={accountBilling.closeAddressModal}
        />
      </AccountLayout>
    );
  }

  static styles = {
    containerLoading: style({
      ...centerCenter,
      height: px(120),
    }),
  };
}
