import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const Headphones: React.SFC<SVGIconProps> = ({ size = 12, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 16 16" {...props}>
    <path
      d="M8 0A7.48 7.48 0 0 0 .62 7.58v5.9A2.5 2.5 0 0 0 3.08 16h2.46V9.26H2.26V7.58c0-3.26 2.57-5.9 5.74-5.9a5.82 5.82 0 0 1 5.75 5.9v1.68h-3.28V16h2.46a2.5 2.5 0 0 0 2.46-2.53v-5.9C15.39 3.4 12.09 0 8 0"
      fill={color}
      fillRule="evenodd"
    />
  </BaseSvg>
);
