import * as React from 'react';
import { Component, deps, inject, observer } from '../lib/component';
import { classes, style } from 'typestyle';
import { constantBreadcrumbsHeight, constantHeaderHeight } from '../theme/constant';
import { colorBackground, colorGunmetal, colorSubtle, colorWhite, colorBlack } from '../theme/color';
import { important, percent, px, rem } from 'csx';
import { fontHeading } from '../theme/font';
import { resetAnchorStyles } from '../theme/reset';
import { UIBreadcrumb } from '../types/ui';
import { center, horizontal } from 'csstips';
import { Loading } from './loading/loading';

export type Breadcrumb = UIBreadcrumb;

export type Props = {
  className?: string;
  light?: boolean;
  breadcrumbs?: Breadcrumb[];
};

@inject(deps)
@observer
export default class AppBreadcrumbs extends Component<Props> {
  render() {
    const { props } = this;
    const { handleClickLink } = this.props.controller.ui;
    const { breadcrumbs, showBreadcrumbs } = this.props.model.ui;

    if (!props.breadcrumbs && !showBreadcrumbs) return null;

    return (
      <div
        className={classes(
          AppBreadcrumbs.styles.container,
          props.light ? AppBreadcrumbs.styles.containerLight : AppBreadcrumbs.styles.containerDark,
          props.className
        )}
      >
        {(props.breadcrumbs || breadcrumbs).map((bc, index, { length }) => (
          <a
            className={classes(
              AppBreadcrumbs.styles.link,
              props.light ? AppBreadcrumbs.styles.linkLight : AppBreadcrumbs.styles.linkDark,
              length === index + 1 && AppBreadcrumbs.styles.linkActive,
              length === index + 1 &&
                (props.light ? AppBreadcrumbs.styles.linkActiveLight : AppBreadcrumbs.styles.linkActiveDark)
            )}
            key={bc.path}
            onClick={handleClickLink}
            href={bc.path}
          >
            <span>{bc.label === AppBreadcrumbs.LOADING ? <Loading light={!props.light} size={24} /> : bc.label}</span>
          </a>
        ))}
      </div>
    );
  }

  static LOADING = '__loading__';

  static styles = {
    container: style({
      ...horizontal,
      fontFamily: fontHeading,
      fontSize: '0.875rem',
      letterSpacing: '-0.0625em',
      padding: '0 1rem',
      overflowX: 'auto',
      overflowY: 'hidden',
      height: constantBreadcrumbsHeight,
      lineHeight: constantBreadcrumbsHeight,
    }),
    containerLight: style({
      background: colorBackground.toString(),
    }),
    containerDark: style({
      background: colorGunmetal.darken(0.05).toString(),
    }),
    logo: style({
      height: '1.125rem',
      width: 'auto',
    }),
    link: style({
      ...horizontal,
      ...center,
      flex: '0 0 auto',
      overflow: 'hidden',
      maxWidth: percent(40),
      padding: '0 1.25rem 0 0',
      position: 'relative',
      whiteSpace: 'pre',
      fontSize: rem(1),
      fontWeight: 500,
      $nest: {
        ...resetAnchorStyles(colorBackground.toString(), true),
        '&::after': {
          display: 'block',
          color: colorSubtle.toString(),
          content: '"»"',
          position: 'absolute',
          top: percent(50),
          bottom: 0,
          right: 0,
          fontSize: px(16),
          lineHeight: 0,
          width: rem(1.25),
          textAlign: 'center',
        },
        '& > span': {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          padding: '0 0.0625rem 0 0',
        },
      },
    }),
    linkLight: style({
      color: colorGunmetal.toString(),
      $nest: {
        ...resetAnchorStyles(colorGunmetal.toString(), true),
      },
    }),
    linkDark: style({
      color: colorBackground.toString(),
      $nest: {
        ...resetAnchorStyles(colorBackground.toString(), true),
      },
    }),
    linkActive: style({
      padding: 0,
      maxWidth: 'none !important',
      flex: `1 !important`,
      $nest: {
        '&::after': {
          display: important('none'),
        },
      },
    }),
    linkActiveLight: style({
      color: important(colorBlack.toString()),
      $nest: {
        ...resetAnchorStyles(colorBlack.toString(), true),
      },
    }),
    linkActiveDark: style({
      color: important(colorWhite.toString()),
      $nest: {
        ...resetAnchorStyles(colorWhite.toString(), true),
      },
    }),
  };
}
