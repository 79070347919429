import React from 'react';
import { classes, style } from 'typestyle';
import { rebrand } from '../../theme/color';

import { styles } from './styles';
import { ImageModel } from '../../modules/image/model';
import CheckoutTrackCard from './checkout-track-card';
import { AnalyticsController } from '../../modules/analytics/controller';
import { BasketModel } from '../../modules/basket/model';
import { Component, deps, inject, observer } from '../../lib/component';
import { TrackSchema } from '../../types/schema';
import { UserModel } from '../../modules/user/model';
import { Button } from '../project-happy/atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../project-happy/atoms/button/buttonStyles';
import { isEmpty } from '../project-happy/utilities/objects';
import { ErrorIcon } from '../project-happy/atoms/icons/ErrorIcon';
import { StarCircle } from '../project-happy/atoms/icons/StarCircle';
import { CheckmarkCircle } from '../project-happy/atoms/icons/CheckmarkCircle';
import { styles as tooltipStyles } from '../project-happy/atoms/Tooltip';
import { ACCOUNT_PLANS_ROUTE, SIGN_UP_ROUTE } from '../../constants';
import { InfoBox } from '../project-happy/atoms/InfoBox';

const { tooltip } = tooltipStyles;

interface CheckoutCardListProps {
  basketModel: BasketModel;
  coverUrlProvider: ImageModel['cover'];
  onClickLink(event: React.MouseEvent<any>): void;
  onRemoveTrack(identity: string): void;
  handleEulaRequest: (track: TrackSchema) => void;
  handleTYNTKRequest: (track: TrackSchema) => void;
  analytics: AnalyticsController;
  user: UserModel;
}

const subscribeCtaStyles = style({
  zIndex: 0,
  backgroundColor: '#13192D',
  $nest: { '&.left::after': { borderLeftColor: '#13192D' } },
});
const SubscribeCTA: React.FC<{ isAuthd: boolean; onClick: () => void }> = ({ children, isAuthd, onClick }) => (
  <>
    <span className={classes(tooltip, subscribeCtaStyles, 'left')}>
      {children || (
        <p data-test-premium-subscribe-copy className={styles.savingsTooltip}>
          Unlock <span className={styles.savingsTooltipDiscount}>75% off</span> Premium&nbsp;
          <StarCircle className={styles.savingsPaygIcon} color="white" />
          &nbsp;tracks
        </p>
      )}
    </span>
    <Button
      href={isAuthd ? ACCOUNT_PLANS_ROUTE : SIGN_UP_ROUTE}
      prominence={ButtonProminence.PRIMARY}
      size={ButtonSize.XSMALL}
      onClick={onClick}
      data-test-subscribe-cta
    >
      Subscribe
    </Button>
  </>
);

@inject(deps)
@observer
class CheckoutCardList extends Component<CheckoutCardListProps> {
  fireSubscribeCtaEvent(buttonLocation: string) {
    const {
      controller: {
        analytics: { sendMixpanel },
      },
    } = this.props;
    sendMixpanel('Clicks start free trial', { element: 'checkout', buttonLocation });
  }

  render() {
    const {
      basketModel,
      coverUrlProvider,
      onClickLink,
      onRemoveTrack,
      analytics,
      handleEulaRequest,
      handleTYNTKRequest,
      user,
    } = this.props;

    const childProps = {
      coverUrlProvider,
      onClickLink,
      onRemoveTrack,
      analytics,
      handleEulaRequest,
      handleTYNTKRequest,
      user,
    };

    const { currency, itemPrices, total } = basketModel;

    const commercial = basketModel.tracks.filter((track) => !track.is_stock_music);
    const stock = basketModel.tracks.filter((track) => track.is_stock_music);

    const { isSubscribed } = user;
    const isAuthd = !isEmpty(user.user);

    return (
      <div className={styles.checkoutCardListContainer}>
        <h2 className={styles.checkoutListHeading}>Your Tracks</h2>
        <div className={styles.checkoutCardList}>
          {commercial.length > 0 && (
            <div
              className={classes(styles.trackGroupBanner, styles.chartConfirmationBanner)}
              data-test-track-card-group-header="payg"
            >
              <span className={styles.trackGroupLabel}>
                Premium <StarCircle className={styles.paygIcon} color="white" />
              </span>
              <span className={styles.trackGroupSublabel}>Pay per license</span>
              {!isSubscribed && (
                <SubscribeCTA isAuthd={isAuthd} onClick={() => this.fireSubscribeCtaEvent('PAYG group header')} />
              )}
            </div>
          )}
          {commercial.map((track) => (
            <CheckoutTrackCard
              key={
                user && user.user && user.user.default_channel
                  ? `${user.user.default_channel}-${track.identity}`
                  : `-${track.identity}`
              }
              trackModel={{ track, price: itemPrices[track.identity], currency }}
              {...childProps}
            />
          ))}
          {stock.length > 0 && (
            <div
              className={classes(
                styles.trackGroupBanner,
                isSubscribed ? styles.essentialsConfirmation : styles.essentialsPrompt
              )}
              data-test-track-card-group-header="paid"
            >
              <span className={styles.trackGroupLabel}>
                Included <CheckmarkCircle size={16} color="white" checkColor={rebrand.highlight1.toString()} />
              </span>
              <span className={styles.trackGroupSublabel}>Subscription</span>
              {!isSubscribed && (
                <SubscribeCTA isAuthd={isAuthd} onClick={() => this.fireSubscribeCtaEvent('PAID group header')}>
                  <p data-test-included-subscribe-copy className={styles.savingsTooltip}>
                    <span className={styles.savingsTooltipDiscount}>Unlimited use</span> of
                    100K&nbsp;Included&nbsp;tracks
                  </p>
                </SubscribeCTA>
              )}
            </div>
          )}
          {stock.map((track) => (
            <CheckoutTrackCard
              key={track.identity}
              trackModel={{ track, price: itemPrices[track.identity], currency }}
              {...childProps}
            />
          ))}
        </div>

        <InfoBox className={styles.checkoutInfoBox}>
          <ErrorIcon size={20} className={styles.checkoutInfoBoxIcon} />
          <p className={styles.checkoutInfoBoxText}>
            Publish your YouTube videos as <strong>&quot;Unlisted&quot;</strong> so Lickd can clear your copyright claim
            before you make them live
          </p>
        </InfoBox>
      </div>
    );
  }
}

export default CheckoutCardList;
