import { API, APIError, APIPaginatedResponse, APIResponse, getEmptyPaginatedResponse } from '../types';
import { action } from 'mobx';
import {
  AggregatedTagSchema,
  ArtistSchema,
  PlaylistGroupSchema,
  PlaylistSchema,
  ReleaseSchema,
  TrackSchema,
} from '../../../types/schema';
import getMappedMoodName from '../../../lib/getMappedMoodName';
import { PrismicDataDrivenTypes, PrismicPlaylistTypes } from '../../../components/project-happy/utilities/types';
import { browserRegion } from '../../../components/project-happy/utilities/browserRegion';

export class BrowseAPIController extends API {
  @action
  getRecentlyAddedTracks = async () => {
    const url = `/catalog/playlist/hookd-just-added/tracks`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<Array<TrackSchema>>;

    this.cache.addTracks(json.data);
    return json;
  };

  @action
  getRecentlyAddedArtists = async () => {
    const url = `/catalog/browse/recently-added/artists`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<Array<ArtistSchema>>;

    this.cache.addArtists(json.data);

    return json;
  };

  @action
  getRecentlyAddedReleases = async () => {
    const url = `/catalog/browse/recently-added/releases`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<Array<ReleaseSchema>>;

    return json;
  };

  @action
  getLeavingSoonTracks = async () => {
    const url = `/catalog/browse/leaving-soon`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<Array<TrackSchema>>;

    this.cache.addTracks(json.data);

    return json;
  };

  @action
  getFeaturedTracks = async () => {
    const url = `/catalog/playlist/hookd-remixes/tracks`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<Array<TrackSchema>>;

    this.cache.addTracks(json.data);

    return json;
  };

  @action
  getFeaturedArtists = async () => {
    const url = `/catalog/browse/featured/artists`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<Array<ArtistSchema>>;

    this.cache.addArtists(json.data);

    return json;
  };

  @action
  getGenreTracks = async (slug: string, page = 1) => {
    const url = `/catalog/tag/hookd_genre/${slug}/tracks?page=${page}`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIPaginatedResponse<Array<TrackSchema>>;

    this.cache.addTracks(json.data);

    return json;
  };

  @action
  getPlaylists = async () => {
    const url = `/catalog/playlist`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<Array<PlaylistSchema>>;

    return json;
  };

  @action
  getBrowsePlaylists = async () => {
    const url = `/catalog/playlist/browse`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<Array<PlaylistSchema>>;

    return json;
  };

  @action
  getPlaylist = async (slug: string) => {
    try {
      const url = `/catalog/playlist/${slug}`;
      const resp = await this.request.get({ url });
      const json = (await resp.json()) as APIResponse<PlaylistSchema>;

      return json;
    } catch (e) {
      return {} as APIResponse<PlaylistSchema>;
    }
  };

  @action
  getTypedPlaylist(type: PrismicPlaylistTypes, slug: string, page = 1, perPage?: number, availableIn = '') {
    switch (type) {
      case PrismicDataDrivenTypes.TRACKS:
        return this.getPlaylistTracks(slug, page, perPage, availableIn);
      case PrismicDataDrivenTypes.PLAYLISTS:
        return this.getPlaylistGroup(slug, page);
      case PrismicDataDrivenTypes.ARTISTS:
        return this.getArtistList(slug);
      default:
        throw Error(`"${type}" is not a playlist type.`);
    }
  }

  @action
  getPlaylistTracks = async (slug: string, page = 1, perPage?: number, availableIn = '') => {
    try {
      let queryParameters = `page=${page}`;
      if (typeof perPage === 'number') {
        queryParameters += `&perPage=${perPage}`;
      }
      const url = `/catalog/playlist/${slug}/tracks?${queryParameters}&include=releases,images,tags${
        availableIn ? `&available_in=${availableIn}` : ''
      }`;

      const response = await this.request.get({ url });
      await APIError.checkResponse(response);
      const json = (await response.json()) as APIPaginatedResponse<Array<TrackSchema>>;

      this.cache.addTracks(json.data);

      return json;
    } catch (e) {
      return getEmptyPaginatedResponse<TrackSchema>();
    }
  };

  @action
  async getPlaylistGroup(slug: string, page = 1) {
    try {
      const url = `/catalog/playlistgroups/${slug}?page=${page}`;
      const response = await this.request.get({ url });
      await APIError.checkResponse(response);
      const json = (await response.json()) as APIResponse<PlaylistGroupSchema>;

      return json.data;
    } catch (e) {
      return {
        active: false,
        playlists: [],
        slug: '',
        title: '',
      } as PlaylistGroupSchema;
    }
  }

  @action
  getArtistList = async (slug: string) => {
    const url = `/catalog/artist/list/${slug}`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIPaginatedResponse<Array<ArtistSchema>>;

    this.cache.addArtists(json.data);

    return json;
  };

  @action
  getSystemTags = async (
    type: string,
    onlyListed = true
  ): Promise<APIPaginatedResponse<Array<AggregatedTagSchema>>> => {
    const tags = await this.getTags('hookd', type, onlyListed);

    // todo remove temporary override after backend supports emoji
    if (type === 'hookd_mood') {
      tags.data = tags.data.map((t) => ({ ...t, tag: getMappedMoodName(t.tag, t.slug) }));
    }

    return tags;
  };

  @action
  getTags = async (
    category: string,
    type: string,
    onlyListed = true
  ): Promise<APIPaginatedResponse<Array<AggregatedTagSchema>>> => {
    const url = `/catalog/tag/${category}/${type}/tags${onlyListed ? `?is_listed=1` : ''}`;
    const resp = await this.request.get({ url });

    return await resp.json();
  };

  @action
  getRightsholderReleases = async (slug: string) => {
    const url = `/catalog/rightsholder/${slug}/releases?perPage=12&includes=images`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<Array<ReleaseSchema>>;

    return json;
  };
}
