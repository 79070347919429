import * as React from 'react';
import { observer } from 'mobx-react';
import { classes, keyframes, style } from 'typestyle';
import { LegacyButtonStyle, LegacyButtonStyleDefault, LegacyButtonStyleDisabled } from './pill';
import {
  colorBackground,
  colorBrand,
  colorContrast,
  colorError,
  colorGunmetal,
  colorSubtle,
  colorWhite,
  colorDarkBlueGrey,
  colorBlueGrey,
  rebrand,
} from '../theme/color';
import { resetAnchorStyles } from '../theme/reset';
import { ColorHelper, important, px, em, percent } from 'csx';
import { animationBounce } from '../theme/animation';
import { shadowElevation1, shadowElevation2 } from '../theme/shadow';
import extend from 'lodash/extend';

export const buttonPillHelpers = {
  customColor: (backgroundColor: ColorHelper, textColor: ColorHelper = colorWhite, fixTextColor = false) => ({
    ...LegacyButtonStyle,
    ...LegacyButtonStyleDefault,
    color: fixTextColor ? important(textColor.toString()) : textColor.toString(),
    background: backgroundColor.toString(),
    $nest: {
      '&:hover': {
        backgroundColor: backgroundColor.darken(0.15).toString(),
      },
      '&&:focus': {
        backgroundColor: backgroundColor.darken(0.15).toString(),
        outline: 'none',
      },
      '&&&[disabled]': {
        ...LegacyButtonStyleDisabled,
        '&:focus': {
          backgroundColor: colorBrand.darken(0.15).toString(),
          outline: 'none',
        },
      },
    },
  }),
  customColorLink: (textColor: ColorHelper = colorWhite) => ({
    textDecoration: important('none'),
    $nest: {
      ...resetAnchorStyles(textColor.toString(), true),
    },
  }),
  bouncing: { ...animationBounce },
};

export interface ButtonPillProps {
  primary?: boolean;
  disabled?: boolean;
  onClick?(event: React.MouseEvent<any>): void;
  target?: string;
  className?: string;
  muted?: boolean;
  unstyled?: boolean;
  type?: 'submit' | 'button' | 'reset';
  href?: string;
  bouncing?: boolean;
  cursor?: string;
  darken?: boolean;
  xsmall?: boolean;
  tertiary?: boolean;
  xlarge?: boolean;
  icon?: boolean;
}

@observer
export class ButtonPill extends React.Component<ButtonPillProps> {
  render() {
    const {
      primary = false,
      disabled = false,
      onClick,
      className,
      children,
      type,
      href,
      muted,
      unstyled = false,
      target,
      bouncing,
      cursor,
      darken,
      xsmall,
      tertiary,
      xlarge,
      icon,
      ...unknownProps
    } = this.props;

    const dataAttributes = Object.entries(unknownProps)
      .filter(([key]) => key.startsWith('data-'))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    const props = {
      type: href ? void 0 : type || 'button',
      children,
      onClick,
      className: classes(
        !unstyled &&
          (primary ? ButtonPill.styles.primary : muted ? ButtonPill.styles.muted : ButtonPill.styles.standard),
        !unstyled &&
          href &&
          (primary
            ? ButtonPill.styles.primaryLink
            : muted
            ? ButtonPill.styles.mutedLink
            : ButtonPill.styles.standardLink),
        bouncing && ButtonPill.styles.bouncing,
        className,
        cursor != undefined ? style({ cursor: cursor }) : null,
        darken === true ? style({ backgroundColor: colorBackground.darken(0.15).toString() }) : null,
        disabled ? style({ ...LegacyButtonStyleDisabled }) : null,
        xsmall ? style({ fontSize: px(12), lineHeight: px(20), padding: `${px(2)} ${px(8)}` }) : null,
        tertiary
          ? style(shadowElevation1, {
              background: `${colorBackground.toString()}`,
              color: important(colorGunmetal.toString()),
              cursor: 'pointer',
              $nest: {
                '&:hover': extend(shadowElevation2, {
                  background: colorDarkBlueGrey.toString(),
                }),
                '&:focus': {
                  backgroundColor: important(colorBlueGrey.toString()),
                },
              },
            })
          : null,
        xlarge ? style({ borderRadius: em(1.6), padding: `${em(0.6)} ${em(1.4)}` }) : null,
        icon
          ? style({
              width: px(32),
              padding: px(0),
              borderRadius: percent(100),
              height: px(32),
              $nest: {
                img: {
                  height: px(16),
                },
              },
            })
          : null
      ),
      ...dataAttributes,
    };

    // If there's an href, display an anchor
    if (href) {
      return (
        <a data-test-button-pill {...props} href={href} target={target || '_self'}>
          {children}
        </a>
      );
    }

    // Submit button styling with disabled prop
    if (type === 'submit') {
      return (
        <button {...props} disabled={disabled}>
          {children}
        </button>
      );
    }

    // Default div with button role
    return (
      <div role="button" {...props} aria-disabled={disabled}>
        {children}
      </div>
    );
  }

  static styles = {
    primary: style(buttonPillHelpers.customColor(rebrand.highlight1, rebrand.dark1)),
    primaryLink: style(buttonPillHelpers.customColorLink(colorWhite)),
    standard: style(buttonPillHelpers.customColor(rebrand.primary1, colorWhite)),
    standardLink: style(buttonPillHelpers.customColorLink(colorWhite)),
    muted: style(buttonPillHelpers.customColor(colorBackground, colorGunmetal)),
    mutedLink: style(buttonPillHelpers.customColorLink(colorBrand)),
    bouncing: style(buttonPillHelpers.bouncing),
    danger: style(buttonPillHelpers.customColor(colorError, colorWhite)),
  };
}
