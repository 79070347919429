import * as React from 'react';
import {observer} from 'mobx-react';
import {Form} from './form';
import {Form as IForm, FormField, FormFields} from '../types/form';

export interface FormAddressFields extends FormFields {
  address1: FormField<string>;
  city: FormField<string>;
  state: FormField<string>;
  postcode: FormField<string>;
  country: FormField<string>;
}

export type FormAddressModel = IForm<FormAddressFields>;

export interface FormAddressProps {
  model: FormAddressModel;
  onChange(field: string, value: any): void;
  onSubmit(): void;
  onCancel(): void;
}

@observer
export class FormAddress extends React.Component<FormAddressProps, {}> {
  render() {
    const { onChange, onSubmit, onCancel, model } = this.props;

    return (
      <Form
        model={model}
        onSubmit={onSubmit}
        onChange={onChange}
        onCancel={onCancel}
      />
    )
  }
}