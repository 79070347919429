import { action, isObservableMap, observable, when } from 'mobx';
import { EnvModel } from '../../env/model';
import { PrismicController } from '../../prismic/controller';
import { AnalyticsController } from '../../analytics/controller';
import { UIController } from '../../ui/controller';
import { APIController } from '../../api/controller';
import { UserModel } from '../../user/model';
import {
  NamedPrismicSlice,
  PrismicSlices,
  PrismicTypedDashboardHeaderProps,
} from '../../../components/project-happy/utilities/types';
import { AuthModel } from '../../auth/model';
import { PageModel } from '../model';
import { fetchSliceData } from '../../../components/project-happy/utilities/prismic';
import Router from 'react-router';
import { HomePageModel } from './model';
import { shuffleDashboardHeaderData } from '../../../components/project-happy/utilities/helpers';

export class HomePageController {
  disposables: (() => void)[] = [];

  constructor(
    private page: PageModel,
    private model: HomePageModel,
    private env: EnvModel,
    private prismic: PrismicController,
    private api: APIController,
    private analytics: AnalyticsController,
    private ui: UIController,
    private user: UserModel,
    private auth: AuthModel
  ) {
    // Potentially come from SSR which has been simplified to an object
    if (!isObservableMap(this.model.carousels)) {
      this.model.carousels = observable.map(this.model.carousels);
    }
    if (!isObservableMap(this.model.tracks)) {
      this.model.tracks = observable.map(this.model.tracks);
    }
  }

  dispose() {
    this.disposables.forEach((disposer) => typeof disposer === 'function' && disposer());
  }

  @action
  onEnter: Router.EnterHook = async (nextState, replace) => {
    if (this.auth.token) {
      return replace({ pathname: '/dashboard' });
    }
    // Don't re-run on client for logged out if SSR has already processed, stops the flashing re-render
    if (this.model.slices.length > 0) return;
    const documentId = 'dashboard_teaser';
    const documentIdHeader = 'dashboard_header';

    // need the playlist id from global document
    await this.env.ready;
    const [dashboardHeaderContent, content] = await Promise.all([
      this.prismic.getSingle(documentIdHeader, { resolveImages: true }),
      this.prismic.getSingle(documentId, { resolveImages: true }),
    ]);
    const { body } = dashboardHeaderContent.data;
    const headerCarouselsData =
      body.length > 0
        ? body.map((b: { primary: PrismicTypedDashboardHeaderProps }) => ({
            ...b.primary,
            id: b.primary.title ? b.primary.title[0].text : '',
          }))
        : [];
    const dashboardHeaderslices = {
      slice_type: PrismicSlices.DASHBOARD_HEADER,
      primary: {},
      items: headerCarouselsData.length ? shuffleDashboardHeaderData(headerCarouselsData) : [],
    } as NamedPrismicSlice<PrismicSlices.DASHBOARD_HEADER>;

    const { masthead_background_image, masthead_background_colour, heading, text, cta, url } = content.data;
    const mastheadSlice = {
      slice_type: PrismicSlices.DASHBOARD_TEASER_MASTHEAD,
      primary: {
        background_image: masthead_background_image,
        background_colour: masthead_background_colour,
        heading,
        text,
        cta,
        url,
      },
      items: [],
    } as NamedPrismicSlice<PrismicSlices.DASHBOARD_TEASER_MASTHEAD>;

    const headerSlice = headerCarouselsData.length > 0 ? dashboardHeaderslices : mastheadSlice;
    this.model.slices = [dashboardHeaderslices].concat(content.data.slices);

    this.model.loadingCarouselData = true;
    const { carousels, tracks } = await fetchSliceData(this.api, this.env, this.model.slices);
    this.model.loadingCarouselData = false;
    this.model.carousels = observable.map(carousels);
    this.model.tracks = observable.map(tracks);

    await this.ui.setSEO('/');
  };

  @action
  onLeave() {
    if (!this.env.isServer) {
      this.dispose();
    }
  }
}
