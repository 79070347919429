import { SlicePageContext, PrismicSimplePlaylistProps, isTypeArray } from '../utilities/types';
import * as React from 'react';
import { PlaylistCarouselSlice } from '../organisms/slices/PlaylistCarouselSlice';
import { PlaylistGroupPlaylistSchema, isPlaylistGroupPlaylistSchema } from '../../../types/schema';
import { isArray } from '../utilities/objects';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { ClassicComponentClass } from 'react';
import { ObservableMap } from 'mobx';
import { LoadingCarouselSlice } from '../organisms/slices/LoadingCarouselSlice';

export type PrismicPlaylistCarouselPayload = PrismicSimplePlaylistProps;
export type PrismicPlaylistCarouselProps = {
  payload: PrismicPlaylistCarouselPayload;
  context: SlicePageContext;
};
export const PrismicPlaylistCarousel = observer(
  ({
    payload: {
      primary: { playlist_heading, playlist_slug },
    },
    context: { playlists, externalDataLoading },
  }) => {
    const playlist = playlists.get(playlist_slug);
    const notFound = !isArray(playlist) || playlist.length === 0;
    if (externalDataLoading !== true && notFound) return null;
    if (externalDataLoading === true && notFound) return <LoadingCarouselSlice heading={playlist_heading} />;
    if (!isTypeArray<PlaylistGroupPlaylistSchema>(playlist, isPlaylistGroupPlaylistSchema)) {
      return null;
    } else {
      return <PlaylistCarouselSlice heading={playlist_heading} tracks={playlist} />;
    }
  }
);
