import * as React from 'react';
import { Component } from '../../lib/component';
import { paginationRange } from '../../lib/helpers';
import {PaginationMeta} from '../../types';
import {style} from 'typestyle';
import {centerCenter, inlineRoot} from 'csstips';
import {px} from 'csx';
import {colorBlack, colorBrand} from '../../theme/color';
import {SVGIcon} from '../svg-icon';

export interface PaginationSelectProps {
  pagination: PaginationMeta;
  pathRoot?: string;
  pathGenerate?: (page: number) => string;
  loadPage(page: number): any;
  isMobile: boolean;
}

export class PaginationSelect extends Component<PaginationSelectProps> {
  render() {
    if (!this.props.pagination || this.props.pagination.total_pages <= 1) return null;

    return (
      <div className={PaginationSelect.styles.container}>
        {this.renderPrev()}
        {this.renderPages()}
        {this.renderNext()}
      </div>
    )
  }

  href = (page: number) => {
    switch (true) {
      case (!!this.props.pathGenerate):
        return this.props.pathGenerate(page);
      case (!!this.props.pathRoot):
        return `${this.props.pathRoot}${page === 1 ? '' : '/' + page}`;
      default:
        return '#';
    }
  };

  goTo = (page: number) => (event: React.MouseEvent<any>) => {
    if (this.props.loadPage) {
      event.preventDefault();
      this.props.loadPage(page);
    }
  };

  renderPrev = () => {
    const { current_page, total_pages } = this.props.pagination;
    if (total_pages === 1 || current_page == 1) return null;
    return (
      <a onClick={this.goTo(current_page - 1)} href={this.href(current_page - 1)}>
        <SVGIcon.ArrowLeft color={colorBrand.toString()} size={16} />
      </a>
    );
  };

  renderNext = () => {
    const { current_page, total_pages } = this.props.pagination;
    if (total_pages === 1 || current_page == total_pages) return null;
    return (
      <a onClick={this.goTo(current_page + 1)} href={this.href(current_page + 1)}>
        <SVGIcon.ArrowRight color={colorBrand.toString()} size={16} />
      </a>
    );
  };

  renderPages = () => {
    const { isMobile } = this.props;
    const { current_page, total_pages } = this.props.pagination;
    const range = paginationRange(current_page, total_pages, isMobile ? MOBILE : DESKTOP);

    // Make first item first page
    if (range[0] !== 1) {
      range.splice(0, 2, 1, null);
    }

    // Make last item last page
    if (range[range.length - 1] !== total_pages) {
      range.splice(range.length - 2, 2, null, total_pages);
    }

    return range.map((page: number, index: number) => {
      if (page === null) return (
        <span key={index}>&hellip;</span>
      );

      if (page === current_page) return (
        <span className={PaginationSelect.styles.active} key={index}>{page}</span>
      );

      return (
        <a key={index} onClick={this.goTo(page)} href={this.href(page)}>
          {page}
        </a>
      )
    });
  };

  static styles = {
    container: style({
      ...centerCenter,
      ...inlineRoot,
      $nest: {
        '& span': {
          padding: px(5),
        },
        '& a': {
          padding: px(5),
        }
      }
    }),
    active: style({
      fontWeight: 'bold',
      color: colorBlack.toString()
    })
  }
}

const DESKTOP = 9;
const MOBILE = 7;