import * as React from 'react';
import { observable, ObservableMap, ObservableSet } from 'mobx';
import { PrismicSlice, SlicePageContext } from '../../components/project-happy/utilities/types';

type PrismicDocumentId = string;
export type PrismicContentData = PrismicDocumentId | PrismicSlice;
export type PrismicContentIslandRef = HTMLElement | React.RefObject<any>;
type PrismicRenderData = {
  slices: PrismicSlice[];
  context: SlicePageContext;
};

export class PrismicContentIslandsModel {
  @observable
  documentCache: ObservableMap<PrismicDocumentId, PrismicSlice[]> = observable.map();

  @observable
  contentIslandData: ObservableMap<PrismicContentIslandRef, PrismicRenderData> = observable.map();
}
