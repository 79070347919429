import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const VolumeDown: React.SFC<SVGIconProps> = ({ size = 24, isSquare, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={isSquare ? size : size * (24 / 24)} viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M18.5 12A4.5 4.5 0 0 0 16 7.97v8.05c1.48-.73 2.5-2.25 2.5-4.02ZM5 9v6h4l5 5V4L9 9H5Z"
      id="a"
    />
  </BaseSvg>
);
