import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalChildStyle } from './modal';
import { FormMarketingPreferences, FormMarketingPreferencesModel } from './form-marketing-preferences';
import { TextNoOrphan } from './text-no-orphan';
import { style } from 'typestyle';
import { px } from 'csx';

export interface FormMarketingPreferencesModalModel {
  show: boolean;
  form: FormMarketingPreferencesModel;
}

export interface FormMarketingPreferencesModalProps {
  model: FormMarketingPreferencesModalModel;
  onRequestClose(): void;
  onChange(field: string, value: any): void;
  onSubmit(): void;
  onCancel(): void;
  i18n?: {
    title: string;
  };
}

const DEFAULT_I18N = {
  title: 'Update marketing preferences',
};

export const FormMarketingPreferencesModal = observer(
  ({ model, i18n = DEFAULT_I18N, onRequestClose, onChange, onSubmit, onCancel }: FormMarketingPreferencesModalProps) => (
    <Modal show={model.show} onRequestClose={onRequestClose}>
      {model.show && (
        <div className={styles.container}>
          <h3>
            <TextNoOrphan value={i18n.title} />
          </h3>
          <FormMarketingPreferences model={model.form} onChange={onChange} onSubmit={onSubmit} onCancel={onCancel} />
        </div>
      )}
    </Modal>
  )
);

const styles = {
  container: style({
    ...ModalChildStyle,
    width: px(420),
  }),
};
