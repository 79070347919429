import * as React from 'react';
import { classes, cssRule, style } from 'typestyle';
import { px, viewWidth } from 'csx';
import { BrandPinkText } from '../../atoms/GradientText';
import { BaseMasthead, BaseMastheadProps } from './BaseMasthead';
import { mediaUpToDesktop, mediaDesktop } from '../../utilities/mediaQueries';
import { heading1, heading2 } from '../../utilities/font';

const styles = {
  masthead: style({
    height: viewWidth(110),
    maxHeight: px(512),
  }),
  mastheadContents: style({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
  gradientHeading: style(
    mediaUpToDesktop({
      fontSize: viewWidth(15),
      letterSpacing: viewWidth(-1.2),
    }),
    mediaDesktop(heading1)
  ),
  headingWrapper: style({
    margin: 0,
  }),
  heading: style(
    heading2,
    {
      display: 'block',
      fontWeight: 500,
    },
    mediaUpToDesktop({
      fontSize: viewWidth(7),
      letterSpacing: viewWidth(-0.6),
      marginTop: viewWidth(-2),
    }),
    mediaDesktop({
      marginTop: px(-38),
    })
  ),
};

export type LandingMastheadProps = Omit<BaseMastheadProps, 'mastheadClassName'> & {
  headingPrefix: string;
  headingSuffix: string;
};

export const LandingMasthead: React.SFC<LandingMastheadProps> = ({
  children,
  className,
  headingPrefix,
  headingSuffix,
  ...childProps
}) => (
  <BaseMasthead
    className={classes(styles.mastheadContents, className)}
    mastheadClassName={styles.masthead}
    {...childProps}
  >
    <h1 className={styles.headingWrapper}>
      <BrandPinkText className={styles.gradientHeading}>{headingPrefix}</BrandPinkText>
      <span className={classes(styles.heading)}>{headingSuffix}</span>
    </h1>
    {children}
  </BaseMasthead>
);
