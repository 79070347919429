import { CardSchema } from '../types/schema';
export const formatCurrency = (rawAmount: string | number, currencyCode = 'GBP', options?: Intl.NumberFormatOptions) =>
  Number(rawAmount)
    .toLocaleString(undefined, {
      currency: currencyCode,
      style: 'currency',
      // currencyDisplay: 'narrowSymbol',
      ...options,
    })
    .replace(/[a-zA-Z]/g, ''); // currencyDisplay isn't widely supported enough, remove alphanumeric characters from response

export const formatNonDecimalCurrency = (
  rawAmount: string | number,
  currencyCode,
  options?: Intl.NumberFormatOptions
) => formatCurrency(rawAmount, currencyCode, { ...options, minimumFractionDigits: 0, maximumFractionDigits: 0 });

export const formatDecimalCurrency = (rawAmount: string | number, currencyCode: string) => {
  const parsedAmount = Number(rawAmount);
  if (!Number.isFinite(parsedAmount)) return 'Unknown';
  return formatCurrency(parsedAmount / 100, currencyCode);
};

export const formatObscuredCardNumber = (card: CardSchema) => {
  return card.brand + 'xxxxxxxxxxxx' + card.last4;
};

export const formatCardExpiry = (card: CardSchema) => {
  const { exp_month, exp_year } = card;
  return (exp_month < 10 ? '0' : '') + exp_month + '/' + exp_year;
};

export const formatVAT = (vatRate: string) => {
  return (parseFloat(vatRate) * 100).toFixed(2).replace(/0*$/, '').replace(/\.$/, '') + '%';
};
