import { mediaMobileOnly, mediaTablet } from '../../theme/media';
import { style } from 'typestyle';
import { percent, px, important, url, em } from 'csx';
import { LegacyButtonStyleDisabled } from '../../components/pill';
import {
  colorGreyAccent,
  colorLightGrey,
  colorCrimson,
  colorBlueGrey,
  colorLightBlueGrey,
  colorDarkBlueGrey,
  colorWhite,
  colorBlack,
  colorBrand,
  colorGunmetal,
  rebrand,
  colorNeutral,
  colorDisabledImage,
} from '../../theme/color';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { shadowElevation3 } from '../../theme/shadow';
import { mixinTextEllipsis } from '../../theme/mixin';
import { fontDefault } from '../../theme/font';
import { betweenJustified, center, flexRoot } from 'csstips';
import { mediaDesktop } from '../project-happy/utilities/mediaQueries';
import {
  desktopBreakpoint,
  fontstackComponentHeadings,
  fontstackDefault,
  headerFullHeight,
  minBodyHeight,
} from '../project-happy/config';
import { rebrandPageClass } from '../project-happy/utilities/page';
import MintLabel from '../../static/tile-icon-label-green.png';
import PinkLabel from '../../static/tile-icon-label-pink.png';
import { borderRadius } from '../../theme/border';
import { screenReaderOnly } from '../project-happy/utilities/font';
import { spacing } from '../../theme/spacing';

export const borderStyle = `${px(1)} solid ${colorLightGrey.toString()}`;

const messageStyle: NestedCSSProperties = {
  marginTop: px(50),
  marginBottom: px(50),
};

const errorMessageStyle: NestedCSSProperties = {
  color: colorCrimson.toString(),
  display: 'block',
  fontSize: px(12),
  marginTop: px(16),
};

export const styles = {
  container: style(
    {
      maxWidth: px(desktopBreakpoint),
      width: percent(100),
      minHeight: minBodyHeight,
      margin: 'auto',
      display: 'flex',
      background: 'white',
    },
    mediaTablet({
      display: 'grid',
      gridTemplateColumns: '55% 45%',
      gridTemplateRows: 'auto 1fr',
      gridTemplateAreas: '"items user" "items summary"',
    }),
    mediaMobileOnly({
      flexDirection: 'column',
    })
  ),
  loading: style(messageStyle),
  empty: style(messageStyle, {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  checkoutCard: style({
    background: colorWhite.toString(),
    width: percent(100),
    borderRadius: borderRadius.MEDIUM,
    margin: `0 0 ${px(16)}`,
    overflow: 'hidden',
    position: 'relative',
    $nest: {
      '> div': {
        display: 'flex',
        alignItems: 'center',
      },
      '> div:first-child ~ div': {
        borderTop: `${px(1)} solid ${colorGreyAccent.toString()}`,
        justifyContent: 'space-between',
      },
      img: {
        display: 'block',
        width: px(80),
        height: 'auto',
      },
    },
  }),
  checkoutTrackline: style({
    position: 'relative',
    $nest: {
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: px(32),
        height: px(29),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      },
      '&.payg::before': {
        backgroundImage: url(PinkLabel),
      },
      '&.paid::before': {
        backgroundImage: url(MintLabel),
      },
    },
  }),
  trackTitle: style({
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1.3,
    fontWeight: 600,
  }),
  trackTitleExplicitTag: style({
    marginRight: px(4),
    flexShrink: 0,
  }),
  trackTitleText: style({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
  checkoutTrackLabel: style({
    position: 'absolute',
    top: px(4),
    left: px(4),
  }),
  checkoutCardPrice: style({
    padding: px(16),
  }),
  checkoutCardRemove: style({
    display: 'flex',
    alignItems: 'center',
    fontSize: px(12),
    $nest: {
      span: {
        marginLeft: px(8),
      },
    },
  }),
  checkoutCardPlay: style({
    position: 'absolute',
    left: px(40),
    top: px(40),
    $nest: {
      svg: {
        marginLeft: px(4),
      },
      '&.playing svg': {
        marginLeft: 0,
      },
    },
  }),
  checkoutCardArtwork: style({
    flexShrink: 0,
  }),
  trackUnavailableimage: style({
    position: 'relative',
    display: 'flex',
    $nest: {
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: colorDisabledImage.toString(),
      },
    },
  }),
  unavailableTrackColor: style({
    color: important(rebrand.neutralOnLight[20].toString()),
    // $nest: { ...resetAnchorStyles(rebrand.neutralOnLight[20].toString()) },
  }),
  checkoutCardArtist: style({
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'relative',
    margin: `0 16px`,
    $nest: {
      p: {
        margin: 0,
        fontSize: px(12),
        ...mixinTextEllipsis,
      },
      '& a': {
        $nest: {
          '&, &:visited, &:active': {
            color: colorBlack.toHexString(),
            textDecoration: 'none',
          },
        },
      },
    },
  }),
  checkoutCardBottomRow: style({
    padding: px(16),
  }),
  checkoutCardButtonWithBadge: style({
    position: 'relative',
    $nest: {
      '& > .badge': {
        position: 'absolute',
        top: px(-4),
        right: px(-8),
      },
    },
  }),
  checkoutCardLicenseBtn: style({
    flexShrink: 1,
    whiteSpace: 'normal',
    textAlign: 'center',
  }),
  checkoutCardArtistTotal: style({
    textAlign: 'right',
  }),
  trackUnavailableTextWrapper: style({
    display: 'flex',
    alignItems: 'center',
  }),
  checkoutCardThingsToKnowWrapper: style({
    marginLeft: px(16),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  }),
  checkoutCardThingsToKnowText: style({
    marginRight: px(8),
    textAlign: 'right',
    fontSize: px(12),
    lineHeight: 1.3,
  }),
  checkoutCardThingsToKnowBtn: style({
    flexShrink: 0,
  }),
  trackUnavailableText: style({
    color: rebrand.contrast[50].toHexString(),
    marginLeft: '8px',
  }),
  checkoutInfoBox: style({
    display: 'flex',
    alignItems: 'center',
    gap: px(16),
    margin: '16px',
  }),
  checkoutInfoBoxIcon: style({
    flexShrink: 0,
  }),
  checkoutInfoBoxText: style({
    fontSize: px(12),
    margin: 0,
  }),
  checkoutVideoPrivacyDetails: style({
    fontSize: px(12),
    textAlign: 'center',
    whiteSpace: 'pre-line',
    $nest: {
      '& p': {
        marginBottom: '16px',
      },
    },
  }),
  checkoutSummaryCouponForm: style({
    display: 'flex',
    fontSize: px(12),
    lineHeight: px(16),
    alignItems: 'flex-end',
    $nest: {
      input: {
        flex: 1,
        background: rebrand.neutralOnDark[10].toString(),
        border: 0,
        borderRadius: borderRadius.SMALL,
        boxShadow: `${px(1)} ${px(1)} ${rebrand.light1.toString()} inset`,
        padding: `${px(4)} ${px(8)}`,
        height: px(32),
        fontWeight: 'normal',
        fontFamily: fontDefault,
        marginTop: px(4),
        width: percent(100),
        $nest: {
          '&[disabled]': {
            color: colorGunmetal.toHexString(),
          },
        },
      },
      button: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: px(4),
        width: px(80),
        height: px(32),
        marginLeft: px(8),
        flexShrink: 0,
        $nest: {
          '&[disabled]': {
            color: colorGunmetal.toHexString(),
          },
        },
      },
    },
  }),
  couponLabel: style(screenReaderOnly),
  trackGroupBanner: style(flexRoot, center, {
    fontSize: px(12),
    color: rebrand.light2.toString(),
    height: px(40),
    marginLeft: px(-16),
    marginRight: px(-16),
    paddingLeft: px(16),
    paddingRight: px(16),
    marginBottom: px(16),
    $nest: {
      '& .button': mediaDesktop({
        justifySelf: 'flex-end',
        paddingLeft: px(20),
        paddingRight: px(20),
      }),
    },
  }),
  trackGroupLabel: style(flexRoot, center, betweenJustified, {
    height: 'calc(100% - 8px)',
    borderRight: '1px solid #13192D',
    color: 'white',
    marginRight: px(8),
    $nest: {
      '& svg': {
        marginLeft: px(4),
        marginRight: px(8),
      },
    },
  }),
  trackGroupSublabel: style({ flexGrow: 1 }),
  savingsTooltip: style({
    maxWidth: px(126),
    margin: 0,
  }),
  savingsTooltipDiscount: style({ fontWeight: 'bold', textTransform: 'uppercase' }),
  savingsPaygIcon: style({ backgroundColor: rebrand.primary1.toString(), borderRadius: em(3) }),
  paygIcon: style({
    backgroundColor: rebrand.primary1.toString(),
    borderRadius: em(3),
  }),
  essentialsPrompt: style({
    background: colorGunmetal.toHexString(),
  }),
  essentialsConfirmation: style({
    background: colorGunmetal.toHexString(),
  }),
  essentialsTooltip: style({
    minWidth: px(288),
    $nest: {
      '& .button:last-child': {
        width: percent(100),
        fontSize: px(12),
      },
    },
  }),
  chartConfirmationBanner: style({
    background: colorGunmetal.toHexString(),
  }),
  checkoutSubtotal: style({
    margin: `${px(8)} 0`,
  }),
  checkoutCardListContainer: style(
    mediaTablet({
      gridArea: 'items',
    }),
    mediaMobileOnly({
      width: percent(100),
    })
  ),
  checkoutListHeading: style({
    fontFamily: fontstackComponentHeadings,
    fontSize: px(24),
    lineHeight: 1,
    margin: 0,
    padding: '8px 16px',
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    textAlign: 'center',
  }),
  checkoutCardList: style(
    {
      position: 'relative',
      background: rebrand.light2.toString(),
      border: borderStyle,
      padding: `0 ${px(16)}`,
    },
    mediaTablet({
      minHeight: px(300),
      maxHeight: `calc(100vh - ${headerFullHeight}px - 175px)`,
      overflowX: 'hidden',
      overflowY: 'auto',
    })
  ),
  userInfo: style(
    mediaTablet({
      gridArea: 'user',
      border: borderStyle,
      borderBottom: 0,
      borderTop: 0,
    })
  ),
  checkoutSummary: style(
    {
      borderTop: borderStyle,
      padding: px(0),
    },
    mediaTablet({
      border: borderStyle,
      gridArea: 'summary',
      $nest: {
        '.with-user &': {
          borderTop: 'none',
        },
      },
    }),
    mediaMobileOnly({
      width: percent(100),
    })
  ),
  checkoutSummaryItem: style(
    {
      padding: px(16),
      borderBottom: borderStyle,
      textAlign: 'center',
      position: 'relative',
      zIndex: 11,
      background: colorWhite.toHexString(),
      $nest: {
        '& .totalText': {
          fontSize: px(16),
        },
        '& .checkoutButton': {
          width: percent(100),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        '& input + label': {
          textAlign: 'left',
          paddingLeft: px(35),
        },
        '& input + label:before': {
          border: borderStyle,
          content: '',
          height: px(24),
          left: 0,
          background: colorLightGrey.toString(),
          marginTop: px(-15),
          position: 'absolute',
          textAlign: 'center',
          top: percent(50),
          transition: `all 0.125s ease-in-out`,
          width: px(24),
          boxShadow: `${px(1)} ${px(1)} ${colorDarkBlueGrey.toString()} inset`,
        },
      },
    },
    mediaTablet({
      zIndex: 0,
    })
  ),
  checkoutSummaryItemInner: style({
    maxWidth: px(390),
    margin: '0 auto',
  }),
  checkoutSummarySubscribePrompt: style({
    margin: `${spacing.XSMALL} 0 0`,
    color: rebrand.neutralOnLight[70].toString(),
    fontSize: px(12),
    $nest: {
      '& a': {
        textDecoration: 'underline',
        color: 'inherit',
      },
    },
  }),
  checkoutSummaryActions: style({
    background: rebrand.neutralOnDark[10].toHexString(),
  }),
  checkoutCheckbox: style({
    $nest: {
      div: {
        margin: px(0),
      },
    },
  }),
  stickyHeader: style(
    {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'fixed',
      backgroundColor: colorLightBlueGrey.toString(),
      padding: `${px(12)} ${px(16)}`,
      zIndex: 10,
      top: px(headerFullHeight),
      width: percent(100),
      borderBottom: borderStyle,
      $nest: {
        '& .largeText': {
          fontSize: px(16),
          $nest: {
            '&, & > *': {
              marginBottom: 0,
            },
          },
        },
      },
    },
    shadowElevation3,
    mediaTablet({
      display: 'none',
    })
  ),
  stickyHeaderBtn: style({
    marginLeft: 'auto',
    $nest: {
      '&:disabled': {
        ...LegacyButtonStyleDisabled,
      },
    },
  }),
  stickyHeaderLabel: style({
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 400,
    $nest: {
      'span:last-of-type': {
        fontSize: px(16),
        fontWeight: 600,
      },
    },
  }),
  loginBtn: style({
    width: percent(50),
  }),
  totalWrapper: style({
    $nest: {
      '+ *': {
        marginTop: px(16),
      },
    },
  }),
  totalText: style({
    display: 'block',
    fontWeight: 600,
  }),
  tooltip: style({
    fontWeight: 'normal',
    whiteSpace: 'pre-line',
    $nest: {
      '&.type-dark.place-left': {
        // Need these for high enough specificity, but it couples it to the libraries behaviour
        maxWidth: px(150),
        padding: '4px 8px',
        borderRadius: borderRadius.SMALL,
      },
    },
  }),
  smallText: style({
    fontSize: px(12),
  }),
  largeText: style(
    mediaTablet({
      fontSize: px(20),
    })
  ),
  stripeLogo: style({
    margin: `${px(16)} auto ${px(0)}`,
    width: percent(100),
    display: 'flex',
    justifyContent: 'center',
  }),
  sectionHeading: style({
    margin: `${px(0)} ${px(0)} ${px(16)}`,
    width: percent(100),
    fontWeight: 600,
    fontFamily: fontstackDefault,
    fontSize: px(16),
  }),
  couponMessage: style({
    marginTop: spacing.XSMALL,
  }),
  couponInert: style({
    color: rebrand.neutralOnLight[40].toString(),
  }),
  couponSuccess: style({
    $nest: {
      '& .coupon-discount-amount': {
        color: rebrand.semantic.success[70].toString(),
      },
    },
  }),
  couponError: style({
    color: rebrand.semantic.error[60].toString(),
  }),
  errorMessage: style(errorMessageStyle),
  errorMessageLeft: style({
    ...errorMessageStyle,
    textAlign: 'left',
  }),
  trackType: style({
    display: 'block',
    fontWeight: 'normal',
    fontSize: px(10),
    lineHeight: 1.4,
    color: colorNeutral.toString(),
  }),
};
