import { colorWhite, rebrand } from '../../../../theme/color';
import { style } from 'typestyle';
import { important, px } from 'csx';
import { spacing } from '../../../../theme/spacing';
import { desktopBreakpoint, fontBody, fontstackHeading } from '../../config';
import { centerCenter, centerJustified, flexRoot, vertical, wrap } from 'csstips';
import { mediaDesktop, mediaMediumDesktop, mediaUpToDesktop } from '../../utilities/mediaQueries';
import { shadowElevation5 } from '../../../../theme/shadow';
import { displayHeadingStyles } from '../../utilities/font';
import { borderRadius } from '../../../../theme/border';

/**
 * Styles for plans V2 content
 */
export const styles = {
  main: style(),
  infoBlock: style({
    textAlign: 'center',
    $nest: {
      '& > h1': {
        margin: `0 0 ${spacing.XLARGE}`,
        fontSize: px(48),
      },
      '& > p': {
        margin: 0,
      },
    },
  }),
  introText: style({
    maxWidth: px(desktopBreakpoint),
    margin: '0 auto',
    $nest: {
      '& p': {
        margin: 0,
        fontSize: px(20),
      },
      '& p + p': {
        marginTop: spacing.XLARGE,
      },
    },
  }),
  loading: style({
    height: px(400),
  }),
  plans: style(
    flexRoot,
    centerJustified,
    wrap,
    {
      margin: `${spacing.XXXLARGE} auto 0`,
      gap: spacing.DEFAULT,
    },
    mediaDesktop({
      flexWrap: 'nowrap',
      '-webkit-flex-wrap': 'nowrap',
    })
  ),
  plansNoChannel: style(
    {
      position: 'relative',
    },
    mediaDesktop({
      height: px(150),
      overflow: 'hidden',
      $nest: {
        '&::after': {
          content: "''",
          position: 'absolute',
          top: px(44),
          right: 0,
          bottom: 0,
          left: 0,
          background: 'linear-gradient(to bottom, transparent, #F3F5F7)',
          zIndex: 1,
        },
        '&.fade-to-white::after': {
          background: 'linear-gradient(to bottom, transparent, #FFF)',
        },
      },
    })
  ),
  planWrapper: style(
    {
      width: '100%',
      maxWidth: px(360),
      minWidth: px(300),
      padding: important(0),
      $nest: {
        '& > div': {
          position: 'relative',
          height: '100%',
          backgroundColor: colorWhite.toHexString(),
          color: 'inherit',
          padding: spacing.LARGE,
          ...vertical,
          ...shadowElevation5,
        },
        '& h2': {
          fontSize: '48px',
          fontFamily: fontstackHeading,
          color: rebrand.primary[100].toString(),
          margin: `0 0 ${spacing.DEFAULT}`,
          padding: 0,
          textAlign: 'center',
        },
        '&.enterprise-plan h2': {
          color: 'inherit',
        },
      },
    },
    mediaMediumDesktop({
      $nest: {
        '& > div': {
          padding: spacing.XLARGE,
        },
      },
    })
  ),
  planWrapperNoChannel: style(
    {
      color: important(rebrand.neutralOnDark[20].toString()),
      height: 'fit-content',
      $nest: {
        '& h2': {
          color: important(rebrand.neutralOnDark[20].toString()),
        },
        '& > div *': {
          color: important(rebrand.neutralOnDark[20].toString()),
        },
      },
    },
    mediaUpToDesktop({
      display: 'none',
    })
  ),
  planHeadingInfoText: style(flexRoot, centerCenter, {
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: fontBody,
    lineHeight: '28px',
    textAlign: 'center',
    color: rebrand.primary[100].toString(),
    $nest: {
      '& svg': {
        flexShrink: 0,
        marginRight: spacing.XXSMALL,
      },
      '.enterprise-plan &': {
        fontWeight: 400,
        color: rebrand.contrast[40].toString(),
      },
    },
  }),
  padlock: style(
    {
      position: 'absolute',
      top: spacing.LARGE,
      right: spacing.LARGE,
    },
    mediaMediumDesktop({
      top: spacing.XLARGE,
      right: spacing.XLARGE,
    })
  ),
  pricingContentsSubscription: style({
    ...displayHeadingStyles,
    textAlign: 'center',
    fontSize: '22px',
    whiteSpace: 'pre-wrap',
    margin: `${spacing.LARGE} 0 0`,
  }),
  pricingContentLarge: style({
    fontSize: px(64),
  }),
  pricingContentMonth: style({
    color: rebrand.contrast[40].toString(),
  }),
  pricingContentEnterprise: style({
    fontSize: px(48),
  }),
  list: style({
    flexGrow: 1,
    margin: `${spacing.LARGE} 0 0`,
  }),
  planButton: style(flexRoot, centerCenter, {
    marginTop: spacing.LARGE,
    width: '100%',
    $nest: {
      '& > span': {
        marginLeft: 0,
      },
    },
  }),
  planButtonLink: style(flexRoot, centerCenter),
  smallText: style({
    fontSize: px(12),
    color: rebrand.contrast[40].toString(),
    textAlign: 'center',
    margin: `${spacing.XSMALL} 0 0`,
  }),
  guaranteeText: style({
    cursor: 'pointer',
  }),
  tooltip: style({
    backgroundColor: rebrand.neutralOnDark[20].toHexString(),
    borderColor: rebrand.neutralOnDark[20].toHexString(),
    color: rebrand.contrast[50].toHexString(),
    textAlign: 'center',
    fontSize: px(12),
    maxWidth: px(480),
    borderRadius: borderRadius.SMALL,
    padding: `${spacing.XSMALL} ${spacing.SMALL}`,
    boxShadow: 'none',
  }),
  tooltipIcon: style({
    display: 'inline-block',
  }),
  channelInfoBox: style(flexRoot, {
    position: 'relative',
    backgroundColor: rebrand.contrast[10].toString(),
    color: rebrand.contrast[40].toString(),
    borderRadius: borderRadius.MEDIUM,
    padding: spacing.DEFAULT,
    gap: spacing.XSMALL,
    maxWidth: px(685),
    margin: `${spacing.XLARGE} auto 0`,
    $nest: {
      '&::before': {
        content: "''",
        position: 'absolute',
        backgroundColor: rebrand.contrast[10].toString(),
        width: px(14),
        height: px(14),
        top: px(-7),
        left: '50%',
        transform: 'rotate(45deg)',
        zIndex: 1,
      },
      '& svg': {
        flexShrink: 0,
      },
      '& p': {
        margin: 0,
        fontSize: px(12),
        $nest: {
          '& strong': {
            color: rebrand.contrast[50].toString(),
          },
        },
      },
    },
  }),
  info: style({
    textAlign: 'center',
    fontSize: px(12),
    maxWidth: px(desktopBreakpoint),
    margin: `${spacing.XXXLARGE} auto 0`,
    $nest: {
      '& a': {
        textDecoration: 'underline',
      },
    },
  }),
  subscribeError: style({
    textAlign: 'center',
    color: 'red',
    maxWidth: px(desktopBreakpoint),
    margin: `${spacing.XXXLARGE} auto 0`,
    $nest: {
      '& a': {
        textDecoration: 'underline',
      },
    },
  }),
  faqs: style({
    maxWidth: px(desktopBreakpoint),
    margin: `${spacing.XXXLARGE} auto 0`,
  }),
};
