import { style } from 'typestyle';
import { colorWhite } from '../theme/color';
import { center, startJustified, vertical } from 'csstips';
import { fontBody, fontHeading } from '../theme/font';
import { mediaMobileOnly, mediaTablet } from '../theme/media';
import MHeaderImageGradientJPG from '../static/M1-Hero-Gradient.png';
import MHeaderImageGradientAVIF from '../static/M1-Hero-Gradient.avif';
import { percent } from 'csx';
import MHeaderImageJPG from '../static/M1-Hero-Image.jpg';
import MHeaderImageWEBP from '../static/M1-Hero-Image.webp';
import MHeaderImageAVIF from '../static/M1-Hero-Image.avif';
import DHeaderImageJPG from '../static/D1-Hero-Image.jpg';
import DHeaderImageWEBP from '../static/D1-Hero-Image.webp';
import DHeaderImageAVIF from '../static/D1-Hero-Image.avif';

const styles = {
  background: style({
    ...vertical,
    ...center,
    ...startJustified,
    minHeight: '100%',
    padding: 0,
    backgroundColor: 'rgb(249, 249, 249)',
  }),
  container: style({
    overflow: 'hidden',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 4px',
    backgroundColor: colorWhite.toString(),
    width: 1280,
    minHeight: '100vh',
    $nest: {
      '@media screen and (max-width: 1280px)': {
        width: '100%',
      },
      '@media screen and (max-width: 640px)': {
        width: 320,
      },
    },
  }),
  hero: style({
    height: 373,
    position: 'relative',
    marginBottom: 24,
    background: 'center center no-repeat',
    backgroundImage: `url('${MHeaderImageJPG}')`,
    backgroundSize: 'cover',
    $nest: {
      '&.with-gradient::after': {
        background: 'left center no-repeat',
        backgroundImage: `url('${MHeaderImageGradientJPG}')`,
        backgroundSize: 'cover',
        opacity: 0.7,
        content: "''",
        height: percent(100),
        position: 'absolute',
        width: percent(100),
      },
      '@media screen and (max-width: 640px)': {
        height: 260,
      },
      '@media screen and (min-width: 641px)': {
        backgroundImage: `url('${DHeaderImageJPG}')`,
        backgroundPosition: '-500px center',
      },
      '@media screen and (min-width: 780px)': {
        backgroundPosition: 'right center',
      },
    },
  }),
  logos: style({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    zIndex: 1,
    top: 16,
    width: '100%',
    paddingRight: 16,
    justifyContent: 'flex-end',
    height: 'inherit',
    $nest: {
      '& img': {
        marginLeft: 16,
        height: '10%',
        $nest: {
          '@media screen and (max-width: 640px)': {
            height: 23,
          },
        },
      },
    },
  }),
  heroInner: style({
    position: 'absolute',
    left: 64,
    top: 139,
    zIndex: 999,
    $nest: {
      '@media screen and (max-width: 1280px)': {
        width: 500,
      },
      '@media screen and (max-width: 800px)': {
        width: 300,
      },
      '@media screen and (max-width: 640px)': {
        top: 62,
        left: 17,
        width: 190,
      },
    },
  }),
  heroInnerText: style({
    fontSize: 40,
    lineHeight: 1.2,
    fontFamily: fontHeading,
    fontWeight: 600,
    color: colorWhite.toString(),
    letterSpacing: -1.333,
    marginBottom: 16,
    $nest: {
      '@media screen and (max-width: 800px)': {
        fontSize: 30,
      },
      '@media screen and (max-width: 640px)': {
        fontSize: 24,
      },
    },
  }),
  readMore: style({
    width: '100%',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 400,
    fontFamily: fontBody,
    position: 'absolute',
    zIndex: 1,
    bottom: 10,
    color: colorWhite.toString(),
  }),
  content: style(
    {
      paddingLeft: 64,
      paddingRight: 64,
      color: '#343444',
      lineHeight: 1.5,
      fontSize: 16,
      fontFamily: fontBody,
      $nest: {
        h4: {
          fontFamily: fontHeading,
          fontSize: 24,
          lineHeight: 1.333,
          color: '#5900FF',
        },
        ul: {
          paddingLeft: '17px',
          $nest: {
            '> li': {
              paddingLeft: '10px',
            },
          },
        },
      },
    },
    mediaMobileOnly({
      paddingLeft: 16,
      paddingRight: 16,
    }),
  ),
  contentMain: style(
    {},
    mediaTablet({
      display: 'flex',
      justifyContent: 'space-between',
      $nest: {
        '& > div, & > p': {
          width: '47%',
        },
      },
    }),
  ),
  downloadCta: style({
    textAlign: 'center',
    $nest: {
      '> a': {
        marginTop: 19,
      },
    },
  }),
  hottestTracks: style({
    $nest: {
      '@media screen and (min-width: 640px)': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  }),
  artist: style({
    marginBottom: 32,
    maxWidth: 368,
    width: '32%',
    $nest: {
      '@media screen and (max-width: 640px)': {
        width: '100%',
      },
      '@media screen and (min-width: 640px)': {
        width: '32%',
        marginBottom: 32,
        maxWidth: 368,
      },
      '> a > div': {
        position: 'relative',
      },
      '> a > div > img': {
        width: 60,
        position: 'absolute',
        right: 16,
        top: 16,
        zIndex: 1,
      },
      ':hover .gradient': {
        opacity: 0.5,
      },
    },
  }),
  artistImage: style({
    position: 'relative',
    overflow: 'hidden',
    marginBottom: 16,
    height: 288,
    background: 'center center no-repeat',
    backgroundSize: 'cover',
    $nest: {
      '&::after': {
        content: '""',
        position: 'absolute',
        background: `center center no-repeat`,
        backgroundImage: `url('${MHeaderImageGradientJPG}')`,
        width: percent(100),
        height: percent(100),
      },
      '@media screen and (max-width: 768px)': {
        height: 205,
      },
      '@media screen and (max-width: 640px)': {
        height: 288,
      },
    },
  }),
  artistName: style({
    position: 'absolute',
    bottom: 16,
    left: 16,
    zIndex: 2,
    color: colorWhite.toString(),
    fontWeight: 400,
    $nest: {
      '> div': {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: 1.333,
      },
    },
  }),
  customHeaderImg: style({
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
  }),
};

export default styles;
