import { classes, media, style } from 'typestyle';
import * as React from 'react';
import { Modal, ModalProps } from '../../modal';
import { px, viewWidth } from 'csx';
import { CircleButton } from '../atoms/button/CircleButton';
import { CloseCross } from '../atoms/icons/CloseCross';
import { ButtonProminence } from '../atoms/button/buttonStyles';
import { YouTubeVideo } from '../atoms/YouTubeVideo';

const styles = {
  modal: style(
    {
      position: 'relative',
      overflowY: 'visible',
    },
    media(
      { maxWidth: px(319) },
      {
        width: viewWidth(100),
      }
    ),
    media(
      { minWidth: px(320) },
      {
        minWidth: px(320),
        width: viewWidth(50),
      }
    )
  ),
  close: style({
    position: 'absolute',
    top: px(-45),
    right: px(8),
  }),
};

export type VideoOverlayModalProps = ModalProps & { videoId: string };

export const VideoOverlayModal: React.SFC<VideoOverlayModalProps> = ({
  className,
  onRequestClose,
  videoId,
  ...props
}) => (
  <Modal className={classes(styles.modal, className)} onRequestClose={onRequestClose} {...props}>
    <CircleButton className={styles.close} prominence={ButtonProminence.SECONDARY} onClick={onRequestClose}>
      <CloseCross />
    </CircleButton>
    <YouTubeVideo loadImmediately={true} id={videoId} />
  </Modal>
);
