import { AccountSchema, TrackSchema } from './schema';
import { DateSchema } from './schema';

export enum LOAD_STATE {
  EMPTY,
  LOADING,
  READY,
  ERROR,
}

export interface AccountsAPI {
  dropbox: Array<AccountSchema>;
  twitter: Array<AccountSchema>;
  facebook: Array<AccountSchema>;
  spotify: Array<AccountSchema>;
  youtube: Array<AccountSchema>;
}

export interface LicenceAPI extends TrackSchema {
  artists: Array<{
    description: string;
    identity: string;
    images: {
      identity: string;
    };
    is_featured: number;
    name: string;
    slug: string;
  }>;
  audio: {
    identity: string;
    url: string;
  };
  duration: number;
  identity: string;
  images: {
    identity: string;
  };
  is_featured: number;
  licence: {
    amount: string;
    identity: string;
    order: {
      sequence?: number;
      identity: string;
      amount: {
        discount: string;
        total: string;
        vat: string;
        currency: string;
      };
    };
    sequence: number;
    ratecard: {
      name: string;
      value: string;
      viewership: number;
    };
    date?: DateSchema;
    channels: Array<{
      id: string;
      name: string;
    }>;
    eula_html_href?: string;
  };
  slug: string;
  title: string;
  share_url: string;
  branded_content_allowed: number;
}
