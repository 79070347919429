import { ClientModel } from './model';
import { AuthController } from '../auth/controller';
import { RequestController } from '../request/controller';
import { UIController } from '../ui/controller';
import { PlayerController } from '../player/controller';
import { APIController } from '../api/controller';
import { PageController } from '../page/controller';
import { BasketController } from '../basket/controller';
import { MailchimpController } from '../mailchimp/controller';
import { BugsnagController } from '../bugsnag/controller';
import { UserController } from '../user/controller';
import { AnalyticsController } from '../analytics/controller';
import { PusherController } from '../pusher/controller';
import { OnboardingController } from '../onboarding/controller';
import { CheckoutController } from '../checkout/controller';
import { PrismicController } from '../prismic/controller';
import { ImageController } from '../image/controller';
import { ContentController } from '../content/controller';
import { ModalController } from '../modal/controller';
import { CouponController } from '../coupon/controller';
import { PricingController } from '../pricing/controller';
import { PlaylistController } from '../playlist/controller';
import { CacheController } from '../cache/controller';
import { ArtistlistController } from '../artistlist/controller';
import { GraphQLController } from '../graphql/controller';
import { Controller } from '../../types';
import mixpanel from '../../mixpanel';
import { SubscriptionController } from '../subscription/controller';
import { Logger } from 'winston';
import { noop } from '../../lib/noop';
import { FavouritesController } from '../favourites/controller';
import { PrismicContentIslandsController } from '../prismic-content-islands/controller';

export class ClientController implements Controller {
  cache: CacheController;
  graphql: GraphQLController;
  ui: UIController;
  request: RequestController;
  api: APIController;
  auth: AuthController;
  mailchimp: MailchimpController;
  analytics: AnalyticsController;
  bugsnag: BugsnagController;
  prismic: PrismicController;
  content: ContentController;
  pusher: PusherController;
  image: ImageController;

  player: PlayerController;
  playlist: PlaylistController;
  artistlist: ArtistlistController;
  favourites: FavouritesController;
  page: PageController;
  basket: BasketController;
  user: UserController;
  coupon: CouponController;
  onboarding: OnboardingController;
  checkout: CheckoutController;
  modal: ModalController;
  pricing: PricingController;
  subscription: SubscriptionController;
  prismicContentIslands: PrismicContentIslandsController;

  // We provide a dummy logger default so Winston isn't necessary on the client
  constructor(private model: ClientModel, private logger: Logger = ({ info: noop, error: noop } as any) as Logger) {
    this.cache = new CacheController(model.cache);
    this.bugsnag = new BugsnagController(model.env, model.auth);
    this.graphql = new GraphQLController(model.graphql, model.env);
    this.request = new RequestController(model.env, model.auth, model.storage, model.request, this.logger);
    this.analytics = new AnalyticsController(
      model.env,
      model.router,
      model.auth,
      model.page.search,
      model.basket,
      model.content,
      model.coupon,
      this.bugsnag,
      mixpanel,
      model.user
    );
    this.api = new APIController(this.request, this.cache, model.env, this.analytics);
    this.auth = new AuthController(model.auth, model.basket, model.env, this.request);
    this.mailchimp = new MailchimpController(model.mailchimp, model.auth);
    this.player = new PlayerController(model.player, this.api.analytics, this.analytics, model.env);
    this.prismic = new PrismicController(model.env, model.router, model.image, this.logger);
    this.content = new ContentController(
      model.content,
      model.env,
      this.prismic,
      this.graphql,
      this.bugsnag,
      this.logger
    );
    this.pusher = new PusherController(model.pusher, model.env, model.auth);
    this.image = new ImageController(model.image, model.env);

    this.favourites = new FavouritesController(model.user);
    this.basket = new BasketController(
      model.basket,
      model.storage,
      this.api,
      model.env,
      model.user,
      model.onboarding,
      this.analytics,
      this.image
    );
    this.coupon = new CouponController(model.coupon, model.env, model.content, this.api, model.user);
    this.user = new UserController(model.user, this.api, this.basket, this.auth, model.env, this.coupon, this.bugsnag);
    this.playlist = new PlaylistController(
      model.playlist,
      model.env,
      model.user,
      this,
      this.api,
      this.bugsnag,
      this.user
    );
    this.artistlist = new ArtistlistController(
      model.artistlist,
      model.env,
      model.user,
      this,
      this.api,
      this.bugsnag,
      this.user
    );
    this.onboarding = new OnboardingController(
      model.onboarding,
      model.env,
      model.router,
      this.api,
      this.auth,
      model.auth,
      model.storage,
      this.bugsnag,
      this.prismic,
      this.analytics,
      model.content,
      this.coupon
    );
    this.ui = new UIController(
      model.ui,
      model.auth,
      model.storage,
      model.env,
      model.content,
      this.player,
      model.router,
      this.auth,
      this.onboarding,
      this.analytics
    );
    this.checkout = new CheckoutController(
      model.env,
      model.checkout,
      this.api,
      model.page.checkout,
      this.basket,
      this.ui,
      this.analytics,
      this.auth,
      this.bugsnag,
      model.router,
      model.content,
      this.coupon
    );
    this.page = new PageController(
      model,
      this.api,
      this.auth,
      this.user,
      this.basket,
      this.ui,
      this.content,
      this.checkout,
      this.prismic,
      this.bugsnag,
      this.image,
      this.analytics,
      this.playlist
    );
    this.pricing = new PricingController(model.pricing, this.api, this.bugsnag, model.env);
    this.modal = new ModalController(
      model.modal,
      this.api,
      model.auth,
      this.ui,
      this.bugsnag,
      this.auth,
      this.user,
      model.env,
      this.basket,
      this.pricing,
      model.content,
      this.content,
      model.router,
      this.analytics
    );
    this.subscription = new SubscriptionController(
      model.subscription,
      model.onboarding,
      model.user,
      this.api,
      this.bugsnag,
      this.user,
      this.modal
    );
    this.prismicContentIslands = new PrismicContentIslandsController(
      model.prismicContentIslands,
      model.env,
      this.prismic,
      this.api,
      model.auth
    );
  }

  disposeAll = () => {
    this.ui.dispose();
    this.auth.dispose();
    this.analytics.dispose();
    this.bugsnag.dispose();
    this.pusher.dispose();
    this.player.dispose();
    this.basket.dispose();
    this.user.dispose();
    this.coupon.dispose();
    this.onboarding.dispose();
    this.checkout.dispose();
    this.playlist.dispose();
    this.artistlist.dispose();
  };
}
