import { observable } from 'mobx';
import { EnvModel } from '../env/model';

export class MailchimpModel {
  constructor(private env: EnvModel) {
    this.action = {
      artist: this.env.mailchimpArtist,
    };
    this.launchReminder = {
      action: this.env.mailchimpLaunchReminderUrl,
      hidden: this.env.mailchimpLaunchReminderHidden,
    };
  }

  @observable
  action: { artist: string };

  @observable
  launchReminder: { action: string; hidden: string };

  @observable
  email = '';

  @observable
  fields: MailchimpFields = {};
}

export type MailchimpFields = { [key: string]: string };
