import * as React from 'react';
import { classes, extend, style } from 'typestyle';
import { CheckmarkList } from '../../atoms/CheckmarkList';
import { Button } from '../../atoms/button/Button';
import { PaymentPlanBlock } from '../../molecules/registration/PaymentPlanBlock';
import { ButtonProminence } from '../../atoms/button/buttonStyles';
import planStyles from './styles';
import { url } from 'csx';
import BrandBanner from '../../../../static/plans/brand-desktop.png';
import { colorGunmetal, colorLickdMint } from '../../../../theme/color';
import { Collapsible } from '../../../collapsible/collapsible';
import collapsibleStyles from '../../../collapsible/styles';
import { CollapsibleIndicator } from '../../atoms/CollapsibleIndicator';
import { renderPrismicRichTextArray } from '../../utilities/props';

const { PRIMARY, SECONDARY } = ButtonProminence;

const styles = {
  banner: style({
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    color: colorGunmetal.toString(),
    $nest: {
      '& h2': {
        textAlign: 'left',
        fontSize: '32px !important',
        marginLeft: '16px',
      },
    },
  }),
  brandBanner: style({
    backgroundColor: colorLickdMint.toString(),
    // TBC
    // backgroundImage: [url(BrandBanner), `${url(BrandBanner)}`],
  }),
  planBlock: style({ width: '320px' }),
  subText: style({
    textAlign: 'left',
    marginLeft: '16px',
    $nest: {
      '& p': { fontSize: '12px' },
      '& .firstLine': {
        fontSize: '16px',
      },
    },
  }),
  list: style({
    marginLeft: '16px',
  }),
};
type BrandProps = React.Component['props'] & {
  className?: string;
  handleAction?: () => void;
};

export const Brand: React.SFC<BrandProps> = ({ children, className, handleAction, ...props }) => (
  <PaymentPlanBlock className={styles.planBlock}>
    <div className={classes(styles.banner, styles.brandBanner)}>
      <h2>Brand</h2>
    </div>
    <Collapsible initial={true}>
      <Collapsible.Summary className={collapsibleStyles.summaryLabel}>
        <h3>We can help</h3>
        <CollapsibleIndicator colour="white" />
      </Collapsible.Summary>
      <CheckmarkList className={styles.list}>
        <li>
          <p>Brands that need music for their content</p>
        </li>
        <li>
          <p>Brands working on creator campaigns</p>
        </li>
        <li>
          <p>Brands who want to partner with us</p>
        </li>
        <li>
          <p>Platforms needing a micro-licensing music solution</p>
        </li>
      </CheckmarkList>
    </Collapsible>
    <Button className={planStyles.planButton} prominence={SECONDARY} href={'https://lickd.typeform.com/to/NUDjnvMu'}>
      Hit us up
    </Button>
  </PaymentPlanBlock>
);
