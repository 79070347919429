import * as React from 'react';
import { BaseSvg } from './icons/BaseSvg';
import { style } from 'typestyle';
import { px } from 'csx';
import { rebrand } from '../../../theme/color';

export interface BarsProps {
  min: number;
  max: number;
  lower: number;
  upper: number;
}

export const Bars: React.FC<BarsProps> = ({ min, max, lower, upper }) => {
  const barsLength = rectData.length;
  const barSize = (max - min) / barsLength;
  const isUnset = lower === min && upper === max;

  return (
    <BaseSvg width="100%" height="32" viewBox="0 0 325 32" className={styles.svg}>
      {rectData.map((bar, i) => {
        const currentBar = barSize * (i + 1) + min;
        const color =
          upper && currentBar >= lower && currentBar <= upper
            ? rebrand.primary[50].toString()
            : rebrand.contrast[20].toHexString();
        return (
          <rect
            key={i}
            x={bar.x}
            y={bar.y}
            width="4"
            height={bar.height}
            rx="1"
            fill={isUnset ? rebrand.contrast[20].toHexString() : color}
          />
        );
      })}
    </BaseSvg>
  );
};

const styles = {
  svg: style({
    marginBottom: px(6),
  }),
};
const rectData = [
  { x: '0', y: '29', height: '3' },
  { x: '5.73218', y: '29', height: '3' },
  { x: '11.4644', y: '29', height: '3' },
  { x: '17.1965', y: '29', height: '3' },
  { x: '22.928', y: '27', height: '5' },
  { x: '28.6606', y: '27', height: '5' },
  { x: '34.3928', y: '27', height: '5' },
  { x: '40.125', y: '26', height: '6' },
  { x: '45.8572', y: '24', height: '8' },
  { x: '51.5894', y: '23', height: '9' },
  { x: '57.3215', y: '21', height: '11' },
  { x: '63.0535', y: '21', height: '11' },
  { x: '68.7856', y: '21', height: '11' },
  { x: '74.5178', y: '21', height: '11' },
  { x: '80.25', y: '20', height: '12' },
  { x: '85.9822', y: '19', height: '13' },
  { x: '91.7144', y: '19', height: '13' },
  { x: '97.4463', y: '19', height: '13' },
  { x: '103.178', y: '19', height: '13' },
  { x: '108.911', y: '17', height: '15' },
  { x: '114.643', y: '15', height: '17' },
  { x: '120.375', y: '15', height: '17' },
  { x: '126.107', y: '14', height: '18' },
  { x: '131.839', y: '13', height: '19' },
  { x: '137.571', y: '9', height: '23' },
  { x: '143.303', y: '6', height: '26' },
  { x: '149.036', y: '6', height: '26' },
  { x: '154.768', y: '5', height: '27' },
  { x: '160.5', y: '5', height: '27' },
  { x: '166.232', y: '2', height: '30' },
  { x: '171.964', y: '0', height: '32' },
  { x: '177.697', y: '0', height: '32' },
  { x: '183.428', y: '2', height: '30' },
  { x: '189.161', y: '2', height: '30' },
  { x: '194.893', y: '5', height: '27' },
  { x: '200.625', y: '6', height: '26' },
  { x: '206.357', y: '6', height: '26' },
  { x: '212.089', y: '9', height: '23' },
  { x: '217.822', y: '13', height: '19' },
  { x: '223.554', y: '17', height: '15' },
  { x: '229.286', y: '19', height: '13' },
  { x: '235.018', y: '19', height: '13' },
  { x: '240.75', y: '21', height: '11' },
  { x: '246.482', y: '23', height: '9' },
  { x: '252.214', y: '26', height: '6' },
  { x: '257.947', y: '27', height: '5' },
  { x: '263.679', y: '28', height: '4' },
  { x: '269.411', y: '28', height: '4' },
  { x: '275.143', y: '29', height: '3' },
  { x: '280.875', y: '29', height: '3' },
  { x: '286.607', y: '29', height: '3' },
  { x: '292.339', y: '29', height: '3' },
  { x: '298.072', y: '29', height: '3' },
  { x: '303.804', y: '29', height: '3' },
  { x: '309.536', y: '29', height: '3' },
  { x: '315.268', y: '29', height: '3' },
  { x: '321', y: '29', height: '3' },
];
