import * as React from 'react';
import { observer } from 'mobx-react';

const preventOrphan = (input: string) => {
  if (!input || !input.length) return '';

  const parts = input.split(' ');
  const length = parts.length;
  switch (length) {
    case 1:
      return parts[0];
    case 2:
      return parts.join('&nbsp;');
    default:
      return parts
        .slice(0, length - 2)
        .concat(parts.slice(length - 2, length).join('&nbsp;'))
        .join(' ');
  }
};

export const TextNoOrphan = observer(({ value }) => (
  <span dangerouslySetInnerHTML={{ __html: preventOrphan(value) }} />
));
