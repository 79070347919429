import { PrismicImage, PrismicRichText } from '../utilities/types';
import * as React from 'react';
import HowItWorksReferee from '../organisms/slices/HowItWorksReferee';

export type PrismicHowItWorksRefereePayload = {
  primary: { how_it_works_heading: PrismicRichText };
  items: {
    how_it_works_image: PrismicImage;
    how_it_works_subheading: PrismicRichText;
    how_it_works_heading: PrismicRichText;
  }[];
};
export type PrismicHowItWorksRefereeProps = {
  payload: PrismicHowItWorksRefereePayload;
};
export const PrismicHowItWorksReferee: React.SFC<PrismicHowItWorksRefereeProps> = ({
  payload: {
    primary: { how_it_works_heading: heading },
    items,
  },
}) => <HowItWorksReferee heading={heading} items={items} />;
