import { EnvModel } from '../env/model';
import { computed, observable } from 'mobx';
import { UIModel } from '../ui/model';
import { ArticleImage } from '../../types/graphql';
import { ImageSizeValue } from '../../constants';

export const parseImageUrl = (src: string) => {
  const [_, baseUrl, ext] = src.match(/(.*)-[^.]+\.(\w+)/);
  return { baseUrl, ext };
};

export class ImageModel {
  constructor(private env: EnvModel, private ui: UIModel) {}

  @observable breakpoints = [
    2400, // xlarge
    1800,
    1200, // large
    960,
    760,
    692,
    636,
    610,
    582,
    564,
    500,
    450,
    432,
    390,
    376,
    336,
    320,
    128,
    64,
  ];

  @observable coverBreakpoints = [480, 640, 960, 1200, 1800, 2400];
  @observable waveformBreakpoints = [2400, 1800, 1200, 910, 884, 256];

  @computed get imagesUri() {
    return this.env.imagesUri;
  }
  @computed get waveformsUri() {
    return this.env.waveformsUri;
  }
  @computed get deviceRatio() {
    return window && window.devicePixelRatio ? window.devicePixelRatio : 1;
  }

  asArticleImage = (identity: string, caption: string | null = null): ArticleImage => {
    return {
      main: this.image(identity, { width: ImageSizeValue.medium }),
      xlarge: this.image(identity, { width: ImageSizeValue.xlarge }),
      large: this.image(identity, { width: ImageSizeValue.large }),
      medium: this.image(identity, { width: ImageSizeValue.medium }),
      small: this.image(identity, { width: ImageSizeValue.small }),
      xsmall: this.image(identity, { width: ImageSizeValue.xsmall, height: ImageSizeValue.xsmall }),
      caption,
    };
  };

  image = (identity: string, options: { type?: string; width?: number; height?: number } = {}) => {
    if (!identity) return '';

    options = { ...this.defaultOptions, ...options };
    const props = this.getProperties(identity);
    return `${this.imagesUri}/${props.type}/${props.id}-${this.getDimensions(options.width, options.height)}.${
      props.extension
    }`;
  };

  cover = (
    identity: string,
    options: { width?: number; height?: number; crop?: boolean; ignoreDimensions?: boolean } = {}
  ) => {
    if (!identity) return '';

    options = { ...this.defaultCoverOptions, ...options };
    const props = this.getProperties(identity);
    const dimensions = options.ignoreDimensions
      ? `${options.width}x${options.height}`
      : this.getDimensions(options.width, options.height);

    return `${this.imagesUri}/${props.type}/${props.id}-${dimensions}.${props.extension}${
      options.crop ? '?fit=crop' : ''
    }`;
  };

  content = (identity: string, options: { width?: number; height?: number } = {}) => {
    if (!identity) return '';

    options = { ...this.defaultCoverOptions, ...options };
    const props = this.getProperties(identity);
    return `${this.imagesUri}/${props.type}/${props.id}-${this.getDimensions(options.width, options.height)}.${
      props.extension
    }`;
  };

  waveform = (identity: string, options: { type?: string; width?: number; height?: number } = {}) => {
    options = { ...this.defaultWaveformOptions, ...options, type: 'coloured' };
    const props = this.getProperties(identity);
    return `${this.waveformsUri}/waveform/${props.id}-${options.type}-${this.getDimensions(
      options.width,
      options.height
    )}.png`;
  };

  waveformSvg = (identity: string) => {
    return `${this.waveformsUri}/waveform/${identity}.svg`;
  };

  private getDimensions = (w: number, h: number) => {
    const width = Math.round(Math.min(Math.ceil(w * this.deviceRatio), 2400));
    const height = Math.round(Math.min(Math.ceil(h * this.deviceRatio), 2400));

    const greatest = width >= height ? width : height;
    const closest = this.breakpoints.reduce(findClosestBreakpoint(greatest));

    // favour the width to reduce homepage size
    return `${closest}x0`;

    // return width >= height
    //   ? `${closest}x0`
    //   : `0x${closest}`;
  };

  private getProperties = (identity: string) => {
    const parts = identity.split('.');
    const type = identity.indexOf('_') !== -1 ? 'content' : 'image';

    const id = parts.length > 1 ? parts.slice(0, -1).join('.') : identity;

    const extension = parts.length > 1 ? parts.pop() : 'jpg';

    return { id, type, extension };
  };

  @computed get defaultOptions() {
    return {
      width: 64,
      height: 64,
    };
  }

  @computed get defaultCoverOptions() {
    return {
      width: 320,
      height: 0,
    };
  }

  @computed get defaultWaveformOptions() {
    return {
      width: 256,
      height: 0,
    };
  }
}

const findClosestBreakpoint = (width: number) => (closest: number, breakpoint: number) => {
  return width <= breakpoint ? breakpoint : closest;
};
