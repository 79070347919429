import * as React from 'react';
import { Component, deps, inject, observer } from '../../lib/component';
import { classes, style } from 'typestyle';
import {
  colorBlueGrey,
  colorBrand,
  colorDarkGreen,
  colorGunmetal,
  colorLightBlueGrey,
  colorLightGrey,
} from '../../theme/color';
import { em, percent, px } from 'csx';
import { center, centerCenter, horizontal, horizontallySpaced } from 'csstips';
import { fontTitles } from '../../theme/font';
import { ChannelAvatar } from '../channel-avatar';
import { SVGIcon } from '../svg-icon';
import { ChannelSchema } from '../../types/schema';
import { mediaTablet } from '../../theme/media';
import { contentHeadingStyles } from '../project-happy/utilities/font';

const styles = {
  channelCard: style({
    backgroundColor: colorLightBlueGrey.toHexString(),
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '15px 38px',
    paddingLeft: px(56), // 18px horizontal + 20px radio button width
    borderBottom: `1px solid ${colorLightGrey.toHexString()}`,
    cursor: 'pointer',
    $nest: {
      '&:first-child': {
        borderTop: `1px solid ${colorLightGrey.toHexString()}`,
      },
      '&::after': {
        content: "''",
        width: px(20),
        height: px(20),
        border: `2px solid ${colorGunmetal.toHexString()}`,
        position: 'absolute',
        borderRadius: px(20),
        left: px(18),
      },
      '&.active::before': {
        content: "''",
        width: px(10),
        height: px(10),
        backgroundColor: colorGunmetal.toHexString(),
        position: 'absolute',
        borderRadius: px(10),
        left: px(23),
      },
    },
  }),
  channel: style({
    ...horizontal,
    ...horizontallySpaced(10),
    ...center,
    padding: '10px 0',
    $nest: {
      '&> div:nth-child(1)': {
        width: px(60),
      },
      '&> div:nth-child(2)': {
        flex: 1,
        lineHeight: em(1.4),
      },
      '&> div:nth-child(3)': {
        width: px(110),
        textAlign: 'right',
      },
    },
  }),
  channelTextWrapper: style({
    fontSize: px(12),
    textAlign: 'center',
    width: percent(100),
    cursor: 'inherit',
    $nest: {
      '& p': {
        margin: 0,
        padding: 0,
      },
    },
  }),
  channelNameWrapper: style(centerCenter),
  channelName: style(contentHeadingStyles, {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: px(16),
    fontWeight: 'bold',
  }),
  channelNameLink: style({
    flexShrink: 0,
    marginLeft: px(8),
    display: 'flex',
  }),
  activeText: style({
    color: colorDarkGreen.toHexString(),
  }),
  connectedText: style({
    color: colorBlueGrey.toHexString(),
  }),
  avatarWrapper: style({
    flexShrink: 0,
    marginRight: px(16),
  }),
  avatar: style(
    {
      width: px(40),
      height: px(40),
    },
    mediaTablet({
      width: px(80),
      height: px(80),
    })
  ),
};

export interface ChannelListingProps {
  selectedChannel: ChannelSchema;
  channels: ChannelSchema[];
}

@inject(deps)
@observer
export default class ChannelListing extends Component<ChannelListingProps> {
  render() {
    const { selectedChannel, channels, controller } = this.props;

    return (
      <span>
        {channels.map((channel, index) => {
          const isActive = channel.id === selectedChannel.id;
          const onClick = isActive ? void 0 : controller.modal.changeChannel.bind(null, channel);

          return (
            <div
              data-test-radio-button={isActive}
              key={index.toString()}
              className={classes(styles.channelCard, isActive && 'active')}
              onClick={onClick}
            >
              <div className={styles.avatarWrapper}>
                <ChannelAvatar channel={channel} className={styles.avatar} />
              </div>
              <div className={styles.channelTextWrapper}>
                <p className={isActive ? styles.activeText : styles.connectedText}>
                  <SVGIcon.CheckmarkCircle
                    color={(isActive ? colorDarkGreen : colorBlueGrey).toHexString()}
                    size={10}
                  />{' '}
                  {isActive ? 'Active' : 'Connected'}
                </p>
                <p>YouTube Channel</p>
                <span className={styles.channelNameWrapper}>
                  <p className={styles.channelName}>{channel.name}</p>
                  <a
                    className={styles.channelNameLink}
                    rel="noreferrer"
                    target="_blank"
                    href={`https://www.youtube.com/channel/${channel.id}`}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <SVGIcon.ArrowUpRightBoxThick size={16} color="black" />
                  </a>
                </span>
              </div>
            </div>
          );
        })}
      </span>
    );
  }
}
