import * as React from 'react';
import { BaseSvg, SVGIconProps } from '../BaseSvg';

export const YouTubeLogo: React.SFC<SVGIconProps> = ({ size = 20, color = 'red', ...props }) => (
  <BaseSvg version="1.2" width={size} height={size * (256 / 366)} viewBox="0 0 366 256" {...props}>
    <path
      d="M357.49 40.6c-4.19-15.68-16.54-28.03-32.22-32.22C306.32 3.3 258.85.76 182.88.76 106.9.76 59.43 3.3 40.49 8.38 24.81 12.57 12.46 24.92 8.26 40.6 3.19 59.55.65 88.79.65 128.32c0 39.54 2.54 68.78 7.61 87.72 4.2 15.69 16.55 28.03 32.23 32.23 18.94 5.07 66.41 7.61 142.39 7.61 75.97 0 123.44-2.54 142.39-7.61 15.68-4.2 28.03-16.54 32.22-32.23 5.07-18.94 7.61-48.18 7.61-87.72 0-39.53-2.54-68.77-7.61-87.72z"
      fill={color}
    />
    <path d="M146.43 182.99l94.68-54.67-94.68-54.67v109.34z" fill="#fff" />
  </BaseSvg>
);
