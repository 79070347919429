import { createMemoryHistory } from 'history';
import { RouterStore, syncHistoryWithStore, Location } from 'mobx-react-router';
import { action, observable } from 'mobx';
import { SESSION_LAST_ROUTE } from '../../constants';
import { EnvModel } from '../env/model';

const initialPreviousRoute = EnvModel.isServer ? '/' : window.sessionStorage.getItem(SESSION_LAST_ROUTE) || '/';

export class RouterModel extends RouterStore {
  @observable
  previousRoute = initialPreviousRoute;

  constructor(isServer = false) {
    super();
    if (isServer) {
      const memoryHistory = createMemoryHistory();
      syncHistoryWithStore(memoryHistory, this);
    }
  }

  @action
  setOutgoingRoute({ pathname, search }: Pick<Location, 'pathname' | 'search'>) {
    const currentRoute = this.location.pathname + this.location.search;
    const previousRoute = pathname + search;
    if (currentRoute === previousRoute) return;
    this.setPreviousRoute(previousRoute);
  }

  @action
  setPreviousRoute(location: string | Pick<Location, 'pathname' | 'search'>) {
    let previousRoute: string;
    if (typeof location === 'string') {
      previousRoute = location;
    } else {
      const { pathname, search } = location;
      previousRoute = pathname + search;
    }
    this.previousRoute = previousRoute;
    window.sessionStorage.setItem(SESSION_LAST_ROUTE, this.previousRoute);
  }
}
