import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { em, percent, px, rgba, url } from 'csx';
import PlayIcon from '../../../static/play-icon.svg';
import { canAutoplay } from '../utilities/features';

const styles = {
  wrapper: style({
    width: percent(100),
    height: 0,
    display: 'block',
    position: 'relative',
    paddingBottom: percent(56.25),
    overflow: 'hidden',
  }),
  thumbLink: style({
    height: 0,
    paddingBottom: percent(56.25),
    $nest: {
      '&::before': {
        backgroundImage: url(PlayIcon),
        backgroundRepeat: 'no-repeat',
        display: 'block',
        content: '""',
        position: 'absolute',
        width: px(50),
        height: px(55),
        backgroundSize: 'cover',
        left: 'calc(50% - 25px)',
        top: 'calc(50% - 30px)',
      },
    },
  }),
  thumb: style({
    marginTop: percent(-9.4),
    width: percent(100),
    objectFit: 'cover',
    display: 'block',
  }),
  iframe: style({
    position: 'absolute',
    top: 0,
    left: 0,
    width: percent(100),
    height: percent(100),
    border: 0,
  }),
};

export type YouTubeVideoProps = {
  id: string;
  loadImmediately?: boolean;
  timestamp?: number;
};

export class YouTubeVideo extends React.Component<YouTubeVideoProps, { active: boolean; autoplay: boolean }> {
  state = {
    active: false,
    autoplay: false,
  };

  ref: HTMLDivElement | null;

  observer: IntersectionObserver;

  constructor(props) {
    super(props);
    if (this.props.loadImmediately === true) {
      this.state.active = true;
    }
  }

  activate(autoplay: boolean) {
    this.setState({
      active: true,
      autoplay,
    });
    this.observer.disconnect();
  }

  componentDidMount() {
    if (this.state.active) return;
    // Mobile browsers protect users from autoplaying videos, which is great! But it breaks our element
    // As a compromise, only load the YouTube player when it scrolls into view
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.intersectionRatio > 0) {
        canAutoplay().then((result) => {
          if (result === true) return;
          this.activate(result);
        });
      }
    });
    this.observer.observe(this.ref);
  }

  render() {
    const { id, timestamp } = this.props;
    const { active, autoplay } = this.state;

    const getRef = (ref) => (this.ref = ref);

    let timestampLink = '',
      timestampIframe = '';
    if (Number.isInteger(timestamp)) {
      timestampLink = `&t=${timestamp}`;
      timestampIframe = `&start=${timestamp}`;
    }

    if (!active) {
      return (
        <div className={styles.wrapper} onClick={this.activate.bind(this, true)} ref={getRef}>
          <a
            className={styles.thumbLink}
            href={`https://www.youtube.com/watch?v=${id}${timestampLink}`}
            target="_blank"
            rel="noreferrer"
            onClick={(event) => event.preventDefault()}
          >
            <img
              className={styles.thumb}
              src={`https://img.youtube.com/vi/${id}/hqdefault.jpg`}
              alt="YouTube video; Opens in a new window"
            />
          </a>
        </div>
      );
    }

    return (
      <div
        className={styles.wrapper}
        ref={getRef}
        dangerouslySetInnerHTML={{
          __html: `<iframe
      class="${styles.iframe}"
      src="https://www.youtube.com/embed/${id}?autoplay=${Number(
            autoplay
          )}&playsinline=1&modestbranding=1${timestampIframe}"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>`,
        }}
      ></div>
    );
  }
}
