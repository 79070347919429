import * as React from 'react';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { ContentSliceProps } from './ContentSlice';
import { PlaylistTile } from '../../molecules/PlaylistTile';
import { ArtistTile } from '../../molecules/ArtistTile';
import { mediaUpToDesktop } from '../../utilities/mediaQueries';
import { DecoratedPageCarousel, DecoratedPageCarouselProps } from '../DecoratedPageCarousel';
import { colorGunmetal } from '../../../../theme/color';
import PlayButton from '../../atoms/button/PlayButton';
import { EmptyTile } from '../../molecules/EmptyTile';
import { shadowElevation2 } from '../../../../theme/shadow';
import { isArray } from '../../utilities/objects';
import { Select } from '../../molecules/Select';
import { Component, deps, inject, observer } from '../../../../lib/component';
import { BaseCarouselSlice } from './BaseCarouselSlice';
import FavouritePlaylistButtonCompat from '../../../compat/favourite-playlist-button-compat';
import { FavouriteArtistButtonCompat } from '../../../compat/favourite-artist-button-compat';
import { TrackTile } from '../../molecules/TrackTile';
import { FavouriteButtonCompat } from '../../../compat/favourite-button-compat';
import { FavouritePrompt } from '../../atoms/icons/FavouritePrompt';
import { centerCenter, flexRoot } from 'csstips';
import { SeeAllButton } from '../../molecules/panels/atoms/SeeAllButton';

const styles = {
  carouselControls: style({
    $nest: {
      '& > button': {
        transform: 'translateY(-34px)',
      },
    },
  }),
  carousel: style(
    mediaUpToDesktop({
      marginLeft: px(-16),
      marginRight: px(-16),
      width: 'calc(100% + 32px)',
    })
  ),
  trackTile: style(
    {
      $nest: {
        ':not(.with-gradient) &': {
          color: colorGunmetal.toHexString(),
        },
      },
    },
    mediaUpToDesktop({
      margin: 0,
      paddingLeft: px(16),
    })
  ),
  buttonsWrapper: style({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }),
  selectClassName: style({
    color: colorGunmetal.toString(),
  }),
  channelButton: style({
    backgroundColor: '#E0E4E9',
    color: '#2E2E3B',
    height: px(32),
    fontSize: px(16),
    $nest: {
      '&:hover, &:active': {
        backgroundColor: '#E0E4E9',
        ...shadowElevation2,
      },
    },
  }),
  prompt: style(flexRoot, centerCenter, {
    transform: 'translateY(24px)',
  }),
};

// Exclude gradient prop as this slice has a two tone background
type CarouselSliceProps = ContentSliceProps &
  DecoratedPageCarouselProps & {
    tracks?: boolean;
    artists?: boolean;
    playlists?: boolean;
  };

enum FavouriteType {
  TRACKS,
  ARTISTS,
  PLAYLISTS,
}

@inject(deps)
@observer
export class FavouritesCarouselSlice extends Component<CarouselSliceProps> {
  state = { type: FavouriteType.TRACKS };

  handleChangeType(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({ type: parseInt(e.target.value) });
  }

  render() {
    const { children, className, contentClassName, carouselClassName, ...props } = this.props;
    const { tracks: showTracks = true, artists: showArtists = true, playlists: showPlaylists = true } = this.props;
    const { favouriteTracks, favouriteArtists, favouritePlaylists } = this.props.model.user;
    const { type } = this.state;
    if (!isArray(favouriteTracks) || !isArray(favouriteArtists) || !isArray(favouritePlaylists)) return null;
    let tiles = [];
    let empty = true;
    const actions = (
      <div className={styles.buttonsWrapper}>
        <Select value={type} onChange={this.handleChangeType.bind(this)} selectClassName={styles.selectClassName}>
          {showTracks && <option value={FavouriteType.TRACKS}>Songs</option>}
          {showArtists && <option value={FavouriteType.ARTISTS}>Artists</option>}
          {showPlaylists && <option value={FavouriteType.PLAYLISTS}>Playlists</option>}
        </Select>
        &nbsp;&nbsp;
        <SeeAllButton href="/account/favourites">See all</SeeAllButton>
      </div>
    );
    switch (type) {
      case FavouriteType.TRACKS:
        empty = favouriteTracks.length === 0;
        tiles = favouriteTracks.map((track, key) => (
          <TrackTile key={key} track={track}>
            <FavouriteButtonCompat track={track} />
            <PlayButton track={track} />
          </TrackTile>
        ));
        break;
      case FavouriteType.ARTISTS:
        empty = favouriteArtists.length === 0;
        tiles = favouriteArtists.map((artist, key) => (
          <ArtistTile key={key} artist={artist} className={styles.trackTile}>
            <FavouriteArtistButtonCompat artist={artist} />
          </ArtistTile>
        ));
        break;
      case FavouriteType.PLAYLISTS:
        empty = favouritePlaylists.length === 0;
        tiles = favouritePlaylists.map((playlist, key) => {
          return [
            <PlaylistTile key={key} playlist={playlist} className={styles.trackTile}>
              <FavouritePlaylistButtonCompat identity={playlist.uuid} />
            </PlaylistTile>,
          ];
        });
    }
    while (tiles.length < 6) {
      tiles.push(<EmptyTile key={tiles.length} className={styles.trackTile} />);
    }
    return (
      <BaseCarouselSlice className={className} contentClassName={contentClassName}>
        <DecoratedPageCarousel
          actions={actions}
          controlClassName={!empty && styles.carouselControls}
          carouselClassName={classes(styles.carousel, carouselClassName)}
          {...props}
        >
          {tiles}
        </DecoratedPageCarousel>
        {empty && (
          <div className={styles.prompt}>
            <FavouritePrompt />
            Start building your collection
          </div>
        )}
      </BaseCarouselSlice>
    );
  }
}
