import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { percent, px, viewWidth } from 'csx';
import { mediaUpToSmallTablet, mediaUpToDesktop } from '../../../utilities/mediaQueries';
import { fontComponentHeadings } from '../../../config';

const styles = style(
  {
    fontFamily: fontComponentHeadings,
    fontSize: px(40),
    letterSpacing: px(-2.5),
    lineHeight: 'normal',
    color: 'white',
    textAlign: 'center',
    padding: '0 48px',
  },
  mediaUpToSmallTablet({
    fontSize: viewWidth(8),
  })
);

export type StatementProps = React.Component['props'] & { className?: string };

export const Statement: React.SFC<StatementProps> = ({ children, className }) => (
  <p className={classes(styles, className)}>{children}</p>
);
