import * as React from 'react';
import { classes, style } from 'typestyle';
import { ModalProps } from '../../../modal';
import { rebrand } from '../../../../theme/color';
import { center, flexRoot } from 'csstips';
import { Component, deps, inject, observer } from '../../../../lib/component';
import { ScrollableOverlay } from '../../../scrollable-overlay/scrollable-overlay';
import overlayStyles from '../../../../styles/scrollable-overlay';
import { Button } from '../../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../../atoms/button/buttonStyles';
import { important, px } from 'csx';
import { mediaMobileOnly } from '../../utilities/mediaQueries';
import { StarCircle } from '../../atoms/icons/StarCircle';
import { spacing } from '../../../../theme/spacing';
import { CurvedArrowV2 } from '../../atoms/icons/CurvedArrowV2';
import { borderRadius } from '../../../../theme/border';
import { NestedCSSProperties } from 'typestyle/lib/types';

const sharedCreditLabelStyles: NestedCSSProperties = {
  padding: '12px 8px',
  borderRadius: borderRadius.MEDIUM,
  fontSize: px(16),
  fontWeight: 700,
  display: 'flex',
  width: '100%',
};

const sharedPlanNameStyles: NestedCSSProperties = {
  textAlign: 'center',
  fontSize: '12px',
  marginTop: '4px',
};

const styles = {
  modal: style(center, {
    maxWidth: '550px',
  }),
  title: style({
    textAlign: 'center',
    fontSize: important('32px'),
  }),
  body: style({
    borderTop: `1px solid ${rebrand.light1.toString()}`,
    paddingTop: spacing.LARGE,
    minHeight: 'fit-content',
  }),
  creditsContainer: style(
    flexRoot,
    {
      gap: spacing.XSMALL,
      width: '100%',
      position: 'relative',
      $nest: {
        '& > div': {
          width: '100%',
        },
      },
    },
    mediaMobileOnly({
      display: 'block',
      padding: '0 12px',
      $nest: {
        '& > div:not(:last-child)': {
          marginBottom: '8px',
        },
      },
    })
  ),
  creditsCurrent: style(sharedCreditLabelStyles, {
    border: `1px solid ${rebrand.neutralOnDark['20'].toHexString()}`,
    color: rebrand.neutralOnLight['60'].toHexString(),
  }),
  creditsNext: style(sharedCreditLabelStyles, {
    border: `1px solid ${rebrand.primary['10'].toHexString()}`,
    backgroundColor: rebrand.primary['10'].toHexString(),
    color: rebrand.primary[100].toHexString(),
  }),
  creditsIcon: style({
    marginRight: spacing.XSMALL,
    flexShrink: 0,
  }),
  planNameCurrent: style(sharedPlanNameStyles, {
    color: rebrand.neutralOnLight[60].toHexString(),
  }),
  planNameNext: style(sharedPlanNameStyles, {
    color: rebrand.primary[100].toHexString(),
    fontWeight: 700,
  }),
  arrow: style(
    {
      width: '64px',
      height: '36px',
      position: 'absolute',
      left: 'calc(50% - 32px)',
      bottom: px(-4),
    },
    mediaMobileOnly({
      transform: 'rotate(90deg)',
      width: '48px',
      height: '24px',
      left: '-12px',
      bottom: '50%',
    })
  ),
  infoText: style({
    marginTop: '24px',
    marginBottom: '0px',
    fontSize: '16px',
  }),
  actions: style({
    marginTop: '24px',
  }),
  actionBtn: style({
    width: '100%',
  }),
};

export type PreUpgradeModalProps = ModalProps;

@inject(deps)
@observer
export class PreUpgradeModal extends Component<PreUpgradeModalProps> {
  render() {
    const {
      show,
      onRequestClose,
      model: {
        user: { channel },
        subscription: { creditSubscriptionPlans },
        modal: { preUpgradeModalSelectedPlan: nextPlan },
      },
      controller: {
        subscription: { navigateToStripeSubscriptionChange },
      },
    } = this.props;

    const currentPlan =
      channel && channel.subscription
        ? creditSubscriptionPlans.find((plan) => plan.plan_name === channel.subscription.plan_name)
        : null;

    const handleConfirm = () => {
      if (!nextPlan) return null;
      const { plan_name, stripe_id } = nextPlan;

      navigateToStripeSubscriptionChange(stripe_id, plan_name);
      onRequestClose();
    };

    return (
      <ScrollableOverlay className={styles.modal} show={show} onRequestClose={onRequestClose}>
        {nextPlan && currentPlan && (
          <>
            <div className={overlayStyles.header}>
              <h3 className={classes(overlayStyles.title, styles.title)}>UPGRADE TO {nextPlan.plan_name}</h3>
            </div>

            <div className={styles.body}>
              <div className={styles.creditsContainer}>
                <div>
                  <div className={styles.creditsCurrent}>
                    <StarCircle
                      size={20}
                      color={rebrand.neutralOnLight[40].toHexString()}
                      className={styles.creditsIcon}
                    />
                    {currentPlan.plan_credits} Premium track credits
                  </div>
                  <div className={styles.planNameCurrent}>{currentPlan.plan_name} plan</div>
                </div>

                <div>
                  <CurvedArrowV2 className={styles.arrow} />

                  <div className={styles.creditsNext}>
                    <StarCircle size={20} color={rebrand.primary[100].toHexString()} className={styles.creditsIcon} />
                    {nextPlan.plan_credits} Premium track credits
                  </div>
                  <div className={styles.planNameNext}>{nextPlan.plan_name} plan</div>
                </div>
              </div>

              <p className={styles.infoText}>
                <strong className={style({ fontSize: '20px' })}>Start using your new credits right away!</strong>
              </p>
              <p className={styles.infoText}>
                When you confirm on the next screen, your payment will be taken and your subscription will start
                immediately so you can start using your new credits! You&apos;ll keep any remaining unused credits from
                your current plan.
              </p>
              <div className={styles.actions}>
                <Button
                  className={styles.actionBtn}
                  data-test-pre-upgrade-modal-go-to-payment
                  prominence={ButtonProminence.PRIMARY}
                  size={ButtonSize.LARGE}
                  onClick={handleConfirm}
                >
                  Go to payment
                </Button>
              </div>
            </div>
          </>
        )}
      </ScrollableOverlay>
    );
  }
}
