import * as React from 'react';
import { Component, deps, inject, observer } from '../lib/component';
import { AccountOrder } from '../components/account/order';
import { AccountLayout } from '../components/project-happy/templates/AccountLayout';
import { BackButton } from '../components/project-happy/atoms/button/BackButton';
import { Loading } from '../components/loading/loading';
import { centerCenter } from 'csstips';
import { style } from 'typestyle';
import { percent, px } from 'csx';
import { spacing } from '../theme/spacing';

export interface AccountOrderPageProps {}

const styles = {
  loading: style({
    ...centerCenter,
    width: percent(100),
    height: px(120),
  }),
  channelBanner: style({
    marginBottom: spacing.XLARGE,
  }),
  licenceList: style({
    position: 'relative',
    width: percent(100),
  }),
};

@inject(deps)
@observer
export class AccountOrderPage extends Component<AccountOrderPageProps> {
  render() {
    const { order, channels = [], loading } = this.props.model.page.accountOrder;

    return (
      <AccountLayout>
        <BackButton href="/account/orders">All orders</BackButton>

        <h1>This order</h1>

        {loading ? (
          <div className={styles.loading}>
            <Loading />
          </div>
        ) : (
          <AccountOrder order={order} channels={channels} />
        )}
      </AccountLayout>
    );
  }
}
