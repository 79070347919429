import { action } from 'mobx';
import { API, APIPaginatedResponse } from '../types';
import { withIncludesAndPage } from '../../../lib/api';
import { TrackSchema } from '../../../types/schema';

export class ReleaseAPIController extends API {
  @action
  getReleaseTracks = async (
    identity: string,
    includes: string[] = ['images', 'artists', 'audio', 'mixes'],
    page = 1,
    availableIn = ''
  ) => {
    const url = `/catalog/release/${identity}/tracks${withIncludesAndPage(includes, page)}${
      availableIn ? `&available_in=${availableIn}` : ''
    }`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIPaginatedResponse<Array<TrackSchema>>;

    this.cache.addTracks(json.data);

    return json;
  };
}
