/* eslint-disable no-async-promise-executor */
import { CouponModel } from './model';
import { APIController } from '../api/controller';
import { UserModel } from '../user/model';
import { EnvModel } from '../env/model';
import { ContentModel } from '../content/model';
import { action, autorun, IReactionDisposer, runInAction } from 'mobx';
import { formatCurrency } from '../../lib/currency';
import { CouponSchema } from '../../types/schema';

export class CouponController {
  constructor(
    private model: CouponModel,
    private env: EnvModel,
    private content: ContentModel,
    private api: APIController,
    private user: UserModel
  ) {
    this._disposers = [];

    if (!env.isServer) {
      let channelId: string;

      this._disposers.push(
        autorun(async () => {
          if (this.user.channel) {
            const { id } = this.user.channel;
            if (channelId !== id && this.user.user) {
              channelId = id;
              await this.getCoupon(channelId, false);
            }
          }
        })
      );
    }
  }

  private _disposers: IReactionDisposer[];

  dispose = () => {
    this._disposers.forEach((dispose) => dispose());
  };

  @action
  getCoupon = async (channelId: string, showLoading = true): Promise<CouponSchema> => {
    const { user } = this.user;

    this.clearCoupon();

    if (!user) {
      throw new Error('Cannot fetch coupon, user not signed in');
    }

    if (this.model.promiseMap[channelId]) {
      return this.model.promiseMap[channelId];
    }

    const promise = new Promise(async (resolve, reject) => {
      try {
        runInAction(() => {
          this.model.loading = showLoading;
          this.model.currentChannel = channelId;
        });

        await this.env.ready;
        const coupon = await this.api.user.createCoupon(user.identity, channelId);

        runInAction(() => {
          this.model.code = coupon.data.coupon;
          this.model.type = coupon.data.type;
          this.model.value = +coupon.data.value;
          this.model.discount = +coupon.data.discount;
          this.model.track_discount = +coupon.data.track_discount;

          const content = this.content.coupons[coupon.data.type];

          if (content) {
            this.model.title = content.title;
            this.model.message = content.message;
          }

          this.model.promiseMap[channelId] = null;
          this.model.loading = false;
        });

        resolve(coupon.data);
      } catch (e) {
        reject(e);
      }
    });

    runInAction(() => {
      this.model.promiseMap[channelId] = promise as Promise<CouponSchema>;
    });
  };

  @action
  clearCoupon = () => {
    this.model.type = void 0;
    this.model.discount = void 0;
    this.model.track_discount = void 0;
    this.model.code = void 0;
    this.model.value = void 0;
    this.model.title = void 0;
    this.model.message = void 0;
  };
}
