import { OnboardingModel } from './model';
import { action, autorun, IReactionDisposer } from 'mobx';
import { EnvModel } from '../env/model';
import { APIController } from '../api/controller';
import { AuthController } from '../auth/controller';
import { StorageModel } from '../storage/model';
import { PrismicController } from '../prismic/controller';
import { BugsnagController } from '../bugsnag/controller';
import { AnalyticsController } from '../analytics/controller';
import { ContentModel } from '../content/model';
import { FormOnboardingFields } from '../../components/form-onboarding';
import { FlashMessageTypeMigrationPrompt, HttpError } from '../../types';
import { ONBOARDING_STATE, SIGN_UP_ACTION_QUERY, SIGN_UP_ROUTE } from '../../constants';
import { CouponController } from '../coupon/controller';
import { rdt } from '../../reddit-track';
import { APIResponse } from '../api/types';
import { RouterModel } from '../router/model';
import { AuthModel } from '../auth/model';

const DEFAULT_FORM_ERROR = 'Oops! Something went wrong, please try again.';

export class OnboardingController {
  constructor(
    private model: OnboardingModel,
    private env: EnvModel,
    private router: RouterModel,
    private api: APIController,
    private auth: AuthController,
    private authModel: AuthModel,
    private storage: StorageModel,
    private bugsnag: BugsnagController,
    private prismic: PrismicController,
    private analytics: AnalyticsController,
    private content: ContentModel,
    private coupon: CouponController
  ) {
    this._disposers = [];
    if (!env.isServer) {
      this.init();
    }
  }

  private _disposers: IReactionDisposer[];

  dispose = () => {
    this._disposers.forEach((dispose) => dispose());
  };

  private init = async () => {
    await this.env.ready;

    this._disposers.push(
      autorun(() => {
        if (this.model.user && this.model.hasFetched) {
          if (!this.model.fullyOnboarded) {
            this.model.detailsShow = true;
            if (!this.router.location.pathname.startsWith('/registration')) {
              this.router.replace('/registration');
            }
          }

          if (this.model.detailsShow || this.model.dismissAll) {
            return;
          }

          if (!this.storage.getItem('trackLogin')) {
            this.analytics.sendLogin(this.model.user);
            this.storage.setItem('trackLogin', 1);
          }
        } else {
          this.model.detailsShow = false;
        }
      })
    );

    // show the sign up straight away i.e. from a landing page
    if (window.location.search.includes(SIGN_UP_ACTION_QUERY)) {
      this.router.push(SIGN_UP_ROUTE);
    }
  };

  @action
  changeBetaEmail = (email: string) => {
    this.model.betaEmail.value = email;
  };

  @action
  openBetaModal = () => {
    this.model.betaShow = true;
  };

  @action
  dismissBetaModal = () => {
    this.model.betaShow = false;
  };

  @action
  resetErrors(fields?: FormOnboardingFields) {
    this.model.detailsError = null;
    if (typeof fields === 'object') {
      Object.values(fields).forEach((field) => {
        field.error = undefined;
      });
    }
  }

  @action
  handleFormErrorResponse(error: APIResponse<never>['error'], fields: FormOnboardingFields) {
    if (error) {
      if (typeof error === 'string') {
        this.model.detailsError = error;
        return;
      }
      if (typeof error === 'object' && error !== null) {
        let matched = false;
        Object.keys(error).forEach((name) => {
          if (name in fields) {
            fields[name].error = error[name];
            matched = true;
          }
        });
        if (matched) {
          this.model.detailsError = 'Oops! Something went wrong, please fix the errors and try again.';
          return;
        }
      }
    }
    this.model.detailsError = DEFAULT_FORM_ERROR;
  }

  @action
  submitDetailsModal = async (fields: FormOnboardingFields) => {
    await this.env.ready;

    const values: any = Object.keys(fields).reduce((vals, key) => ({ ...vals, [key]: fields[key].value }), {});
    this.resetErrors(fields);

    if (!values.email || values.email.includes('@pages.plusgoogle.com')) {
      return (this.model.detailsError = 'Please enter your email address');
    }

    if (!values.country) {
      return (this.model.detailsError = 'Please select a country');
    }

    const SIXTEEN_YEARS_HENCE = 1000 * 60 * 60 * 24 * 365.25;
    const age = new Date(values.age);
    const differenceInYears = (Date.now() - age.getTime()) / SIXTEEN_YEARS_HENCE;

    if (differenceInYears < 16) {
      return (this.model.detailsError = this.content.ageWarning);
    }

    if (!values.accepted_terms) return (this.model.detailsError = 'Please accept the terms and conditions');

    try {
      this.model.detailsSaving = true;
      const response = await this.api.user.setContactDetails(values);
      if ('success' in response && response.success === false) {
        this.handleFormErrorResponse(response.error, fields);
        return;
      }
      await this.auth.getSession();
      await this.coupon.getCoupon(this.model.user.default_channel);
      this.model.detailsShow = false;
      this.analytics.sendSignup(this.model.user);
      this.analytics.sendMixpanel('Sign up complete');
      rdt('track', 'SignUp');
    } catch (e) {
      try {
        this.model.detailsError = (e as HttpError).body.error;
      } catch (e) {
        this.model.detailsError = DEFAULT_FORM_ERROR;
      }
    } finally {
      this.model.detailsSaving = false;
    }
  };

  @action
  goToSignUpPage = async (redirect: string = null) => {
    if (this.model.user) return;
    this.authModel.returnUrl = redirect;
    this.router.push(SIGN_UP_ROUTE);
  };

  @action
  closeSignUpModal = () => {
    this.model.signUpShow = false;
  };

  @action
  dismissSignUpCTA = () => {
    this.storage.setItem('hideSignUpCTA', '1');
  };

  @action
  setTwitterFollow = (follow: boolean) => {
    this.model.twitterFollow = follow;
  };

  @action
  showTermsConditionsModal = async () => {
    this.model.termsConditionsShow = true;
    this.model.termsConditionsLoading = true;

    const document = 'terms__conditions';

    try {
      const { data } = await this.prismic.getSingle(document);

      // RichText
      this.model.termsConditionsPageBody = data.page_body;
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      this.model.termsConditionsLoading = false;
    }

    this.analytics.sendMixpanel('User opens terms and conditions modal', {
      path: this.router.location.pathname,
    });

    return;
  };

  @action
  closeTermsConditionsModal = () => {
    this.model.termsConditionsShow = false;

    this.analytics.sendMixpanel('User closes terms and conditions modal', {
      path: this.router.location.pathname,
    });
  };

  @action
  showPrivacyPolicyModal = async () => {
    this.model.privacyPolicyShow = true;
    this.model.privacyPolicyLoading = true;

    const document = 'privacy_policy';

    try {
      const { data } = await this.prismic.getSingle(document);

      // RichText
      this.model.privacyPolicyPageBody = data.page_body;
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      this.model.privacyPolicyLoading = false;
    }

    this.analytics.sendMixpanel('User opens privacy policy modal', {
      path: this.router.location.pathname,
    });

    return;
  };

  @action
  closePrivacyPolicyModal = () => {
    this.model.privacyPolicyShow = false;

    this.analytics.sendMixpanel('User closes privacy policy modal', {
      path: this.router.location.pathname,
    });
  };
}
