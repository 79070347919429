import { YouTubeAccount } from './youtube';
import { VatSchema } from './schema';

export interface User {
  identity: string;
  address?: {
    city: string;
    country: string;
  };
  is_customer: boolean;
  onboarding_state: {
    bitmask: number;
    state: string;
  };
  /**
   * Whether the user is a staff member.
   * @deprecated Use with caution, this can be manipulated client-side by the user
   */
  is_staff: boolean;
  country: string;
  language: string;
  avatar: string;
  birthday: string;
  email: string;
  gender: string;
  name: string;
  first_name: string;
  last_name: string;
  nickname: string;
  telephone: string;
  facebook?: Array<any>;
  spotify?: Array<any>;
  twitter?: Array<any>;
  dropbox?: Array<any>;
  youtube?: Array<YouTubeAccount>;
  default_coupon: string;
  default_channel: string;
  preferred_genre: string;
  marketing_allowed: boolean;
  is_business: boolean;
  is_subscribed: boolean;
  is_golden_ticket: boolean;
  vat: VatSchema;
  found_us?: string;
  is_migrated?: boolean;
  completed_registration_steps?: Array<any>;
  utm_content?: string;
  medium?: string;
  campaign?: string;
  source?: string;
}

export type UserUpdateableFields = Partial<{
  email: string;
  first_name: string;
  last_name: string;
  name: string;
  nickname: string;
  country: string;
  accepted_terms: boolean;
  marketing_allowed: boolean;
  vat_number: string;
  default_channel: string;
  preferred_genre: string;
  found_us: string;
  is_business: boolean;
  language: string;
  telephone: string;
}>;

export interface UserTrackMeta {
  favourited?: boolean;
}

export enum COMPLETED_REGISTRATION_STEPS {
  EMAIL_CONFIRMED = 'email_confirmed',
  CHANNEL_ATTACHED = 'channel_attached',
  CHANNEL_NOT_ATTACHED = 'channel_not_attached',
  ACCOUNT_DETAILS_ADDED = 'account_details_added',
  PLAN_SELECTED = 'plan_selected',
  PLAN_NOT_SELECTED = 'plan_not_selected',
  VERTICAL_SELECTED = 'vertical_selected',
  GENRES_SELECTED = 'genres_selected',
  REGISTRATION_COMPLETED = 'registration_completed'
}
