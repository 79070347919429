import React from 'react';
import { classes } from 'typestyle';
import { CircleButton } from './CircleButton';
import { ButtonProminence } from './buttonStyles';
import withPlayer, {
  WithPlayerCompatibleComponentProps,
  WithPlayerComponentProps,
} from '../../../../modules/player/withPlayer';
import { SVGIcon } from '../../../svg-icon';
import { colorGunmetal } from '../../../../theme/color';

interface PlayButtonProps extends WithPlayerCompatibleComponentProps {
  className?: string;
}

class PlayButton extends React.Component<WithPlayerComponentProps & PlayButtonProps> {
  render() {
    const { isCurrentPlaying, className } = this.props;

    const classNames = [];

    if (typeof className === 'string') {
      classNames.push(className);
    }

    let Icon = SVGIcon.Play;
    if (isCurrentPlaying) {
      Icon = SVGIcon.Pause;
      classNames.push('playing');
    }

    return (
      <CircleButton
        prominence={ButtonProminence.SECONDARY}
        onClick={this.handleOnClick.bind(this)}
        className={classes('play-button', ...classNames)}
        aria-label={isCurrentPlaying ? 'Pause track' : 'Play track'}
        data-test-track-play
      >
        <Icon size={12} color={colorGunmetal.toString()} />
      </CircleButton>
    );
  }

  handleOnClick() {
    const { isCurrent, isCurrentPlaying, onLoadImmediate, track, onPause, onPlay } = this.props;

    if (!isCurrent) {
      onLoadImmediate(0, track);
      return;
    }
    if (isCurrentPlaying) {
      onPause();
      return;
    }
    onPlay();
  }
}

export default withPlayer(PlayButton);
