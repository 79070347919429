/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const attributionContent = (
  attribution: string,
  track: string,
  artist: string,
  shortId: string,
  referralURL: string
) => {
  /** This section will be eliminated once linkFire begins to arrive directly from the data rather than being extracted by us from attribution."  */
  const urlRegex = /(https:\/\/t\.lickd\.co\/\w+|https:\/\/lickd\.lnk\.to\/\w+)/;
  const extractedList = attribution.match(urlRegex) || [];
  const linkFireUrl = extractedList.length ? extractedList[0] : '';
  /**  */

  return `Get '${track}' by ${artist} and over 1M mainstream tracks here: https://go.lickd.co/Music \nLicense ID: ${shortId}, ${linkFireUrl}\nTry Lickd FREE for 14 days for unlimited stock music and get 50% off your first mainstream track: ${referralURL}`;
};
