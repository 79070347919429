import { AuthModel } from '../auth/model';
import { computed, observable } from 'mobx';
import { EnvModel } from '../env/model';
import { StorageModel } from '../storage/model';
import { MailchimpModel } from '../mailchimp/model';
import { ContentModel } from '../content/model';
import { createFormField } from '../../lib/form';
import { FormOnboardingFields } from '../../components/form-onboarding';
import { FieldValues } from '../../types/form';
import { User } from '../../types/user';

export class OnboardingModel {
  constructor(
    private auth: AuthModel,
    private env: EnvModel,
    private storage: StorageModel,
    private mailchimp: MailchimpModel,
    private content: ContentModel
  ) {}

  @computed
  get isPermitted() {
    return this.auth.isPermitted;
  }

  @observable
  dismissAll = false;

  @computed get showAny() {
    return this.signUpShow || this.detailsShow || this.betaModal.show;
  }

  @observable
  betaEmail = createFormField({
    name: 'EMAIL',
    label: 'Email address',
    value: this.mailchimp.email,
    autoComplete: 'email',
  });

  @observable
  betaShow = false;

  get betaModal() {
    return {
      show: !this.isPermitted && !this.auth.user && this.betaShow,
      action: this.mailchimp.launchReminder.action,
      hidden: this.mailchimp.launchReminder.hidden,
      email: this.betaEmail,
    };
  }

  /** Sign up modal, shown when clicking Sign up/in */

  @computed
  get signUpModal() {
    return {
      show: this.signUpShow,
      youtubeLoginURL: this.signUpRedirect ? this.auth.youtubeRedirect(this.signUpRedirect) : this.auth.youtubeLoginURL,
    };
  }

  @observable
  signUpShow = false;

  @observable
  signUpV2Show = false;

  @observable
  signUpRedirect: string = null;

  @computed
  get signUpCTAShow() {
    return (
      !this.env.isServer && this.auth.hasFetched && !this.auth.user && !(this.storage.getItem('hideSignUpCTA') === '1')
    );
  }

  /** Details modal, shown after first login but without valid email or country. Unskippable */

  @computed
  get detailsModal() {
    return {
      isComplete: this.fullyOnboarded,
      show: this.detailsShow,
      saving: this.detailsSaving,
      defaultValues: this.detailsDefaultValues,
      error: this.detailsError,
      genres: this.content.genres,
    };
  }

  @computed
  get fullyOnboarded(): boolean {
    return this.auth.user && this.auth.user.onboarding_state && this.auth.user.onboarding_state.bitmask > 4;
  }

  @computed
  get user(): User {
    return this.auth.user;
  }

  @computed
  get hasFetched(): boolean {
    return this.auth.hasFetched;
  }

  @computed
  get detailsDefaultValues(): FieldValues<FormOnboardingFields> {
    if (!this.user) return {};

    return {
      email:
        this.user.email && this.user.email.length && this.user.email.includes('@pages.plusgoogle.com')
          ? ''
          : this.user.email,
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      telephone: this.user.telephone,
      country: this.user.country,
    };
  }

  @observable
  detailsSaving = false;

  @observable
  detailsShow = false;

  @observable
  detailsError: string = null;

  @observable
  twitterFollow = false;

  @computed
  get termsConditionsModal(): any {
    return {
      show: this.termsConditionsShow,
      loading: this.termsConditionsLoading,
      page_body: this.termsConditionsPageBody,
    };
  }

  @observable
  termsConditionsShow = false;

  @observable
  termsConditionsLoading = false;

  @observable
  termsConditionsPageBody: any[] = [];

  @computed
  get privacyPolicyModal(): any {
    return {
      show: this.privacyPolicyShow,
      loading: this.privacyPolicyLoading,
      page_body: this.privacyPolicyPageBody,
    };
  }

  @observable
  privacyPolicyShow = false;

  @observable
  privacyPolicyLoading = false;

  @observable
  privacyPolicyPageBody: any[] = [];

  /** New user sign up button */
  @computed
  get ctaSignUpNewUserButtonShow(): boolean {
    return !this.auth.user && !!this.ctaSignUpNewUserButtonContent;
  }

  @computed
  get ctaSignUpNewUserButtonContent(): string {
    return this.content.buttonCTANewUser;
  }
}
