import { color } from 'csx';
import * as React from 'react';
import { classes, cssRaw, style, TypeStyle } from 'typestyle';
import { colorGunmetal, colorWhite, rebrand } from '../../../theme/color';
import { CheckCircle } from './icons/CheckCircle';
import { VariationBox } from './icons/VariationBox';

const styles = {
  container: style({
    position: 'absolute',
  }),
  number: style({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    bottom: '20px',
    left: '-1px',
    fontSize: '10px',
    color: colorGunmetal.toHexString(),
  }),
};

type VariationsProps = {
  number: number;
};

export const Variations: React.SFC<VariationsProps> = ({ number }) => (
  <div className={styles.container}>
    <VariationBox />
    <span className={styles.number}>{number}</span>
  </div>
);
