import * as React from 'react';
import { classes, cssRaw, cssRule, style } from 'typestyle';
import { percent, px, url } from 'csx';
import { ContentSlice, ContentSliceProps } from './ContentSlice';
import { mediaMobileOnly, mediaSmallTablet, mediaUpToSmallTablet } from '../../utilities/mediaQueries';
import { heading3, heading4, heading5, heading6 } from '../../utilities/font';
import { colorBrand, colorLickdPink, colorLightBlueGrey, colorLightGrey, colorWhite } from '../../../../theme/color';
import { PrismicRichText } from '../../utilities/types';
import { renderPrismicRichTextArray } from '../../utilities/props';
import { Pill, PillStatus } from '../../atoms/Pill';
import { StarCircle } from '../../atoms/icons/StarCircle';
import { PaymentPlanBlock } from '../../molecules/registration/PaymentPlanBlock';
import { CheckmarkList } from '../../atoms/CheckmarkList';
import { Collapsible } from '../../../collapsible/collapsible';
import collapsibleStyles from '../../../collapsible/styles';
import { CollapsibleIndicator } from '../../atoms/CollapsibleIndicator';
import { centerJustified, flexRoot, wrap } from 'csstips';
import { fontHeading } from '../../../../theme/font';
import StockChartBannerDesktop from '../../../../static/plans/creator-plan-desktop.png';
import ChartOnlyBannerDesktop from '../../../../static/plans/chart-only-desktop.png';
import { AddCircle } from '../../atoms/icons/AddCircle';
import { Button } from '../../atoms/button/Button';
import { SIGN_UP_ROUTE, SIGN_UP_WITH_PAYG } from '../../../../constants';
import { ButtonProminence } from '../../atoms/button/buttonStyles';
import { ArrowRight } from '../../atoms/icons/ArrowRight';
import { CrownStencilSpray } from '../../atoms/icons/CrownStencilSpray';
import { Brand } from '../plans/Brand';
import { fontstackComponentHeadings } from '../../config';
import { Subscription } from '../plans/Subscription';
const { PRIMARY, SECONDARY } = ButtonProminence;
const styles = {
  wrapper: style(
    mediaUpToSmallTablet({
      paddingBottom: px(32),
      $nest: {
        '.masthead + &, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient': {
          paddingTop: px(32),
        },
        '& h2': {
          fontSize: '32px',
        },
      },
    }),
    mediaSmallTablet({
      paddingBottom: px(56),
      $nest: {
        '.masthead + &, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient': {
          paddingTop: px(56),
        },
      },
    }),
    flexRoot,
    centerJustified,
    wrap,
    {
      borderBottom: `1px solid ${colorLightGrey.toString()}`,
      backgroundColor: colorLightBlueGrey.toString(),
      margin: '0 auto',
      textAlign: 'center',
      $nest: {
        '& h2': {
          fontSize: '56px',
        },
        '& li': {
          textAlign: 'left',
        },
      },
    }
  ),
  planBlock: style({
    width: '320px',
    $nest: {
      '& h2': {
        marginLeft: '0px',
      },
    },
  }),
  planWrapper: style(mediaMobileOnly({ marginLeft: 'auto', marginRight: 'auto' }), {
    width: percent(100),
    maxWidth: px(320),
    marginTop: px(-10),
    $nest: {
      '& .payment-plan': {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: px(8),
      },
      '& .payment-plan:first-child': {
        marginTop: px(32),
      },
    },
  }),
  banner: style({
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    $nest: {
      '& h2': { fontSize: '32px !important' },
      '& h2, & .h4': {
        textAlign: 'left',
        marginLeft: '16px',
      },
    },
  }),
  stockChartBanner: style({
    backgroundColor: colorLickdPink.toString(),
    // TBC
    // backgroundImage: [url(StockChartBannerDesktop), `${url(StockChartBannerDesktop)}`],
  }),
  chartOnlyBanner: style({
    backgroundColor: 'black',
    //TBC
    // backgroundImage: [url(ChartOnlyBannerDesktop), `${url(ChartOnlyBannerDesktop)}, black)`],
  }),
  pricingContentsSubscription: style({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    whiteSpace: 'pre-wrap',
    $nest: {
      '& span': {
        fontSize: '40px',
        lineHeight: '1.2',
        fontFamily: fontstackComponentHeadings,
        letterSpacing: '-2.5px',
      },
    },
  }),
  subline: style({
    fontSize: '16px',
    fontWeight: 200,
    display: 'block',
    width: '145px',
    margin: 'auto',
  }),
  chartPlanBlock: style({
    position: 'relative',
  }),
  planButton: style(mediaMobileOnly({ width: '256px' }), {
    width: '288px',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '17px',
    height: '40px',
    fontSize: '20px',
  }),
  arrowBrandColour: style({
    $nest: {
      '& svg path, &:hover svg path, &:focus svg page': {
        fill: colorBrand.toString(),
        color: colorBrand.toString(),
      },
    },
  }),
  planContentWrapper: style(
    mediaMobileOnly({ justifyContent: 'center', display: 'block', minWidth: 'unset', width: 'unset' }),
    {
      display: 'flex',
      maxWidth: '656px',
      justifyContent: 'space-between',
      minWidth: '400px',
      flexWrap: 'wrap',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: percent(100),
    }
  ),
  trialText: style({ marginBottom: '8px', marginTop: '0px', fontFamily: 'Flood' }),
  subscriptionHeading: style({ padding: '0' }),
};

cssRule(
  '.heading-slice .subheading',
  { fontWeight: 300, display: 'block' },
  mediaUpToSmallTablet(heading6),
  mediaSmallTablet(heading5)
);

cssRule(
  '.heading-slice h2.heading, .heading-slice .heading',
  mediaUpToSmallTablet(heading4),
  mediaSmallTablet(heading3)
);

type HeadingSliceProps = ContentSliceProps;

export const PlansSlice: React.SFC<HeadingSliceProps> = ({ children, className, ...props }) => (
  <ContentSlice
    className={classes('plans-slice', className, styles.wrapper)}
    contentClassName={classes(styles.planContentWrapper)}
    {...props}
  >
    <h2 style={{ flexShrink: 0, flexBasis: '100%' }}>Choose your plan</h2>
    <div className={styles.planWrapper}>
      <Subscription className={styles.subscriptionHeading} />
    </div>
    <div className={classes(styles.planWrapper, styles.chartPlanBlock)}>
      <Brand />
    </div>
  </ContentSlice>
);
