import * as React from 'react';
import {colorWhite} from '../theme/color';
import {em, percent, px} from 'csx';
import {style} from 'typestyle';
import {mediaTablet} from '../theme/media';

export interface PageHomePricingCaveatProps {
  i18n?: {
    caveat: string;
  }
}

const DEFAULT_I18N = {
  caveat: 'Prices will be in GBP so an FX rate will be applied to the end price.'
};

export const PageHomePricingCaveat: React.SFC<PageHomePricingCaveatProps> = ({ i18n = DEFAULT_I18N }) => (
  <div className={styles.container} />
);

const styles = {
  container: style(
    {
      color: colorWhite.toString(),
      width: percent(75),
      padding: '50px 20px 60px',
      fontSize: px(12),
      lineHeight: em(1.2)
    },
    mediaTablet({
      padding: '60px 100px 100px'
    })
  )
}