import { computed, observable } from 'mobx';
import {FormAddressModalModel} from '../../../components/form-address-modal';
import {createAddressForm, createCreditCardForm, createVatNumberForm} from '../../../lib/form';
import {FormCreditCardModalModel} from '../../../components/form-credit-card-modal';
import {FormVatNumberModalModel} from '../../../components/form-vat-number-modal';
import {CardSchema} from '../../../types/schema';
import {BillingAddress} from '../../../types';

export class AccountBillingPageModel {
  @observable
  loading: boolean = false;

  @observable
  addresses: Array<BillingAddress> = [];

  @observable
  modalAddress: FormAddressModalModel = {
    show: false,
    edit: false,
    form: createAddressForm()
  };

  @observable
  cards: Array<CardSchema> = [];

  @observable
  modalCreditCard: FormCreditCardModalModel = {
    show: false,
    form: createCreditCardForm([])
  };

  @observable
  vatNumber: string = '';

  @observable
  modalVatNumber: FormVatNumberModalModel = {
    show: false,
    form: createVatNumberForm()
  }
}