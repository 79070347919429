import * as React from 'react';
import { Component } from '../../lib/component';
import {classes, style} from 'typestyle';
import {percent} from 'csx';
import {centerJustified, flex} from 'csstips';

export interface LayoutContentProps {
  className?: string;
  innerClassName?: string;
  loading?: boolean
}

export class LayoutContent extends Component<LayoutContentProps> {
  render() {
    if (this.props.loading) return <div />;

    return (
      <div className={classes(LayoutContent.styles.container, this.props.className)}>
        <div className={classes(LayoutContent.styles.inner, this.props.innerClassName)}>
          {this.props.children}
        </div>
      </div>
    )
  }

  static styles = {
    container: style({
      ...flex,
      ...centerJustified,
      width: percent(100),
    }),
    inner: style({
      width: percent(100),
      maxWidth: percent(100),
    }),
  }
}