import * as React from 'react';
import { observer } from 'mobx-react';
import { classes, style } from 'typestyle';
import { transitionQuickEase } from '../theme/transition';
import { viewHeight } from 'csx';
import MainWrapper from './main-wrapper';

export interface LoadingContainerProps {
  className?: string;
  loading: boolean;
}

export const LoadingContainer = observer(({ children, className, loading }: React.PropsWithChildren<LoadingContainerProps>) => (
  <MainWrapper style={{ opacity: loading ? 0 : 1 }} className={classes(styles.container, className)}>
    {children}
  </MainWrapper>
));

const styles = {
  container: style({
    transition: `opacity ${transitionQuickEase}`,
    opacity: 1,
    minHeight: viewHeight(90),
  }),
};
