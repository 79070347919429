import { style } from 'typestyle';
import { centerJustified, flexRoot, wrap } from 'csstips';
import { em, percent, px, url } from 'csx';
import ChartBanner from '../../../../static/plans/chart-plan.png';
import EssentialsBanner from '../../../../static/plans/essentials-plan.png';
import { colorWhite } from '../../../../theme/color';
import { fontstackDefault } from '../../config';
import { mediaDesktop, mediaMobileOnly } from '../../utilities/mediaQueries';
import { mediaTablet } from '../../../../theme/media';

export default {
  wrapper: style(flexRoot, centerJustified, wrap, {
    maxWidth: px(656),
    margin: '0 auto',
    paddingLeft: px(8),
    paddingRight: px(8),
  }),
  text: style({
    textAlign: 'center',
    marginLeft: px(25),
    marginRight: px(25),
  }),
  planWrapper: style({
    width: percent(100),
    maxWidth: px(352),
    $nest: {
      '& h2': {
        marginLeft: 0,
        padding: '0 0 4px',
      },
    },
  }),
  banner: style({
    textAlign: 'center',
    padding: px(16),
    backgroundSize: 'contain',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
  }),

  subline: style({
    display: 'inline-block',
    minWidth: px(120),
    margin: '0 auto',
    padding: '4px 8px',
    textAlign: 'center',
    borderRadius: em(1),
    fontSize: px(12),
    color: colorWhite.toHexString(),
    boxShadow: 'inset 0px 1px 0 0 rgba(0,0,0,0.5)',
  }),

  planButton: style({
    display: 'block',
    margin: '16px auto',
    textAlign: 'center',
    width: 'calc(100% - 24px)',
  }),
  info: style({
    textAlign: 'center',
    fontSize: px(8),
    marginTop: px(8),
    $nest: {
      '& h4': {
        fontFamily: fontstackDefault,
        fontSize: px(12),
        fontWeight: 'normal',
        marginTop: px(4),
        marginBottom: px(8),
        textDecoration: 'underline',
      },
      '& p': {
        marginTop: px(8),
        marginBottom: px(8),
      },
      '& > a': {
        fontSize: px(12),
      },
    },
  }),

  subscribeError: style({
    color: 'red',
  }),
  hideMobile: style(mediaMobileOnly({ display: 'none' })),
  hideDesktopTablet: style(mediaDesktop({ display: 'none' }), mediaTablet({ display: 'none' })),
};
