export default function getMappedMoodName(name: string, slug: string){
  const emoji = MOOD_EMOJI_MAP[slug];

  return emoji ? `${name} ${emoji}` : name;
}

const MOOD_EMOJI_MAP: {[key: string]: string} = {
  'aggressive': '😤',
  'moody': '😒',
  'dramatic': '🎭',
  'dreamy': '✨',
  'relaxed': '💆🏽‍♀️',
  'romantic': '💞',
  'sentimental': '😔',
  'sexy': '👅',
  'tense': '🚬',
  'upbeat': '🥁',
  'light': '💡',
  'uplifting': '⚡️',
  'cold': '⛄️',
  'dark': '☠️'
};