import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalChildStyle } from './modal';
import { style } from 'typestyle';
import { colorBrand } from '../theme/color';
import { px, viewHeight, viewWidth } from 'csx';
import { TextNoOrphan } from './text-no-orphan';
import { ButtonPill } from './button-pill';

const PrismicDOM = require('prismic-dom');

export interface OnboardingTermsConditionsModalModel {
  show: boolean;
  loading: boolean;
  page_body: any;
}

export interface OnboardingTermsConditionsModalProps {
  onRequestClose(): void;
  model: OnboardingTermsConditionsModalModel;
  i18n?: {
    title: string;
    loading: string;
    close: string;
  };
}

@observer
export class OnboardingTermsConditionsModal extends React.Component<OnboardingTermsConditionsModalProps, {}> {
  render() {
    const { onRequestClose, model, i18n = OnboardingTermsConditionsModal.i18n } = this.props;

    return (
      <Modal show={model.show} onRequestClose={onRequestClose}>
        <div className={OnboardingTermsConditionsModal.styles.container}>
          <h3 className={OnboardingTermsConditionsModal.styles.heading}>
            <TextNoOrphan value={i18n.title} />
          </h3>
          {model.loading && (
            <div className={OnboardingTermsConditionsModal.styles.content}>
              <span>{i18n.loading}</span>
            </div>
          )}
          {!model.loading && (
            <div
              className={OnboardingTermsConditionsModal.styles.content}
              dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(model.page_body) }}
            />
          )}
          <div className={OnboardingTermsConditionsModal.styles.buttons}>
            <ButtonPill onClick={onRequestClose}>{i18n.close}</ButtonPill>
          </div>
        </div>
      </Modal>
    );
  }

  static i18n = {
    title: 'Terms & Conditions',
    loading: 'Loading...',
    close: 'Close',
  };

  static styles = {
    container: style({
      ...ModalChildStyle,
      width: viewWidth(90),
      height: viewHeight(90),
      position: 'relative',
    }),
    heading: style({
      color: colorBrand.toString(),
      fontSize: px(30),
      margin: '0 0 30px 0',
      flexShrink: 0,
    }),
    content: style({
      flex: 1,
      overflow: 'auto',
    }),
    buttons: style({
      margin: '30px 0 0 0',
      textAlign: 'right',
      flexShrink: 0,
    }),
  };
}
