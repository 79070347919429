import { computed, observable } from 'mobx';
import { Currencies } from '../../../components/project-happy/molecules/CurrencySelector';
import { isEmpty } from '../../../components/project-happy/utilities/objects';
import { RatecardsSchema } from '../../../types/schema';

export class PricingPageModel {
  @observable
  loading = true;

  @observable
  page_title: any = null;

  @observable
  page_body: any = null;

  @observable
  cover_image: any = null;

  @observable
  pricingImage: string;

  @observable
  ratecards: RatecardsSchema;

  @observable
  ratecardTypes: Array<{ value: string; label: string }> = [{ value: 'standard', label: 'Non-Branded' }];

  @observable
  ratecardType = 'standard';

  @observable
  ratecardLoading = false;

  @computed
  get selectedRatecardType(): { value: string; label: string } {
    return this.ratecardTypes.find((t) => t.value === this.ratecardType);
  }

  @observable
  video_id: string;

  @observable
  benefits: Array<{
    title: string;
    content: any;
  }>;
}
