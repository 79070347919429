import * as React from 'react';
import { Component } from '../../lib/component';
import { FormattedMessage } from 'react-intl';
import { Loading } from '../loading/loading';
import { PaginationSelect } from '../pagination/select';
import { PaginationMeta } from '../../types';
import { classes, style } from 'typestyle';
import { em, important, percent, px } from 'csx';
import { colorBackground, colorSubtle } from '../../theme/color';
import { centerCenter } from 'csstips';

export interface PaginatedTableProps<T> {
  className?: string;
  columns: Array<PaginatedTableColumn<T>>;
  onlyColumns?: Array<number>;
  rows: Array<T>;
  pagination: PaginationMeta;
  loadingMore: boolean;
  pathRoot: string;
  loadPage?(page: number): any;
  isMobile: boolean;
}

export interface PaginatedTableColumn<T> {
  i18nKey: string;
  render(item: T): any;
}

export class PaginatedTable<T> extends Component<PaginatedTableProps<T>> {
  render() {
    return this.props.isMobile ? this.renderMobile() : this.renderDesktop();
  }

  getColumns = () => {
    const { columns, onlyColumns } = this.props;

    return !onlyColumns || onlyColumns.length === 0
      ? columns
      : columns.filter((_, index) => onlyColumns.indexOf(index) !== -1);
  };

  renderMobile = () => {
    const { props } = this;
    const columns = this.getColumns();

    return (
      <div className={classes(PaginatedTable.styles.mobile, props.className)}>
        {!props.loadingMore &&
          props.rows.map((row, index) => (
            <div key={index}>
              {columns.map((column, index) => (
                <div className={PaginatedTable.styles.column} key={index}>
                  <FormattedMessage id={column.i18nKey} tagName="h3" />
                  <div>{column.render(row)}</div>
                </div>
              ))}
            </div>
          ))}
        {props.loadingMore && (
          <div>
            <Loading />
          </div>
        )}
        {props.pagination && props.pagination.total_pages > 1 && (
          <div className={PaginatedTable.styles.pagination}>
            <PaginationSelect
              pagination={props.pagination}
              pathRoot={props.pathRoot}
              loadPage={props.loadPage}
              isMobile={true}
            />
          </div>
        )}
      </div>
    );
  };

  renderDesktop = () => {
    const { props } = this;
    const columns = this.getColumns();

    return (
      <table className={classes(PaginatedTable.styles.container, props.className)}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <FormattedMessage key={index} tagName="th" id={column.i18nKey} />
            ))}
          </tr>
        </thead>
        <tfoot>
          {props.loadingMore && (
            <tr>
              <td colSpan={columns.length}>
                <div className={PaginatedTable.styles.loadingMore}>
                  <Loading />
                </div>
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={columns.length} className={PaginatedTable.styles.pagination}>
              <PaginationSelect
                pagination={props.pagination}
                pathRoot={props.pathRoot}
                loadPage={props.loadPage}
                isMobile={false}
              />
            </td>
          </tr>
        </tfoot>
        <tbody>
          {!props.loadingMore &&
            props.rows.map((row, index) => (
              <tr key={index}>
                {columns.map((column, index) => (
                  <td key={index}>{column.render(row)}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  static styles = {
    container: style({
      borderCollapse: 'collapse',
      width: percent(100),
      lineHeight: em(1.4),
      $nest: {
        '& thead tr': {
          borderBottom: `1px solid ${colorBackground.toString()}`,
        },
        '& tbody tr': {
          borderBottom: `1px solid ${colorBackground.toString()}`,
        },
        '& th': {
          textAlign: 'left',
          verticalAlign: 'top',
          paddingBottom: '5px',
        },
        '& tbody td': {
          textAlign: 'left',
          verticalAlign: 'top',
          padding: '16px 0',
        },
        '& th:last-child': {
          textAlign: 'right',
        },
        '& td:last-child': {
          textAlign: 'right',
        },
      },
    }),
    loadingMore: style({
      ...centerCenter,
      textAlign: 'center',
      width: percent(100),
      height: px(120),
    }),
    pagination: style({
      textAlign: 'center',
      height: px(80),
      verticalAlign: 'center',
    }),
    mobile: style({
      width: percent(100),
      $nest: {
        '& > div': {
          paddingBottom: px(16),
          borderBottom: `1px solid ${colorBackground.toString()}`,
          marginBottom: px(16),
        },
        '& > div:last-child': {
          paddingBottom: 0,
          borderBottom: 0,
        },
      },
    }),
    column: style({
      paddingBottom: px(16),
      $nest: {
        '&:last-child': {
          paddingBottom: 0,
        },
        '& > h3': {
          margin: '0 0 16px',
        },
        '& > span': {
          margin: '0 0 5px 0',
          fontSize: '12px',
          color: colorSubtle.toString(),
        },
      },
    }),
  };
}
