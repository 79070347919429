export function formatTimestamp(date: Date) {
  return date.toLocaleDateString('en-GB', { hour: '2-digit', minute: '2-digit', weekday: 'long', day: '2-digit', month: 'short', year: 'numeric' });
}

export function formatDate(date: Date) {
  return date.toLocaleDateString('en-GB', { month: 'short', day: '2-digit', year: 'numeric' });
}

export function formatYear(date: Date) {
  return date.toLocaleDateString('en-GB', { year: 'numeric' });
}