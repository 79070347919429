import {
  isTypeArray,
  PrismicDataDrivenTypes,
  PrismicDataDrivenCarouselProps,
  PrismicHeadedCarouselProps,
  PrismicSizeConfigurableCarouselProps,
  PrismicTypedCarouselProps,
  SlicePageContext,
  PrismicStockLabelProps,
} from '../utilities/types';
import * as React from 'react';
import { ClassicComponentClass } from 'react';
import { PlaylistCarouselSlice } from '../organisms/slices/PlaylistCarouselSlice';
import {
  ArtistSchema,
  isArtistSchema,
  isPlaylistGroupPlaylistSchema,
  isTrackSchema,
  PlaylistGroupPlaylistSchema,
  TrackSchema,
} from '../../../types/schema';
import { observer } from '../../../lib/component';
import { TrackCarouselSlice } from '../organisms/slices/TrackCarouselSlice';
import { DecoratedPageCarouselProps } from '../organisms/DecoratedPageCarousel';
import { isArray } from '../utilities/objects';
import { StorageModel } from '../../../modules/storage/model';
import { REPLACE_CAROUSELS } from '../../devtools/feature-flags';
import { SubscriptionLabelContext } from '../atoms/SubscriptionLabel';
import { ArtistCarouselSlice } from '../organisms/slices/ArtistCarouselSlice';
import { LoadingCarouselSlice } from '../organisms/slices/LoadingCarouselSlice';

export type PrismicCatalogueCarouselPayload = PrismicHeadedCarouselProps &
  PrismicSizeConfigurableCarouselProps &
  PrismicDataDrivenCarouselProps &
  PrismicTypedCarouselProps &
  PrismicStockLabelProps;
export type PrismicCatalogueCarouselProps = {
  payload: PrismicCatalogueCarouselPayload;
  context: SlicePageContext;
};
export const PrismicCatalogueCarousel = observer(
  ({
    payload: {
      primary: {
        carousel_slug,
        carousel_type,
        carousel_heading,
        tile_size,
        action_override,
        action_visibility = true,
        is_stock = false,
      },
    },
    context: { playlists, carousels = {}, externalDataLoading },
  }) => {
    let slug = carousel_slug;
    if (!playlists.has(slug) && StorageModel.getItem(REPLACE_CAROUSELS) === true) {
      slug = REPLACE_CAROUSELS + carousel_type;
    }
    const playlist = playlists.get(slug);
    // Escape early if the playlist doesn't exist or is empty
    const notFound = !isArray(playlist) || playlist.length === 0;
    if (externalDataLoading !== true && notFound) return null;
    if (externalDataLoading === true && notFound) return <LoadingCarouselSlice heading={carousel_heading} />;
    const perPage = tile_size ? 3 : 6;
    const { forceHideActions, forceIndicatorPosition } = carousels;
    const commonProps: DecoratedPageCarouselProps = {
      heading: carousel_heading,
      perPage,
      indicatorAlignment: forceIndicatorPosition,
    };
    if (forceHideActions || !action_visibility) {
      commonProps.actions = null;
    }
    if (
      carousel_type === PrismicDataDrivenTypes.PLAYLISTS &&
      isTypeArray<PlaylistGroupPlaylistSchema>(playlist, isPlaylistGroupPlaylistSchema)
    ) {
      const slice = (
        <PlaylistCarouselSlice
          tracks={playlist}
          actionHref={action_override || `/browse/playlists/${carousel_slug}`}
          {...commonProps}
        />
      );
      return is_stock ? (
        <SubscriptionLabelContext.Provider value={is_stock}>{slice}</SubscriptionLabelContext.Provider>
      ) : (
        slice
      );
    }
    if (carousel_type === PrismicDataDrivenTypes.TRACKS && isTypeArray<TrackSchema>(playlist, isTrackSchema)) {
      return <TrackCarouselSlice tracks={playlist} slug={carousel_slug} {...commonProps} />;
    }
    if (carousel_type === PrismicDataDrivenTypes.ARTISTS && isTypeArray<ArtistSchema>(playlist, isArtistSchema)) {
      return <ArtistCarouselSlice artists={playlist} actionHref={action_override} {...commonProps} />;
    }
    return null;
  }
);
