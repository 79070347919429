import * as React from 'react';
import { ArtistTile } from './artist-tile';
import { observer } from 'mobx-react';
import { style } from 'typestyle';
import { percent } from 'csx';
import { horizontal, wrap } from 'csstips';
import { ImagePreloader } from '../types/fn';
import { BrowseResultsProps } from './browse/results';
import { graphql, InjectedGraphQLProps } from 'react-apollo';
import gql from 'graphql-tag';
import { Artist, ArtistResults } from '../types/graphql';
import maybe from '../lib/maybe';

export interface ArtistSimilarArtistsProps {
  identity: string;
  preload: ImagePreloader;
  Favourite?: any;
  onClickLink(event: React.MouseEvent<any>): void;
  i18n?: {
    title: string;
  };
}

interface Data {
  getArtist: Artist;
}

const DEFAULT_I18N = {
  title: 'You might also like...',
};

@observer
export class ArtistSimilarArtists extends React.Component<ArtistSimilarArtistsProps & InjectedGraphQLProps<Data>, any> {
  render() {
    const { preload, onClickLink, Favourite, i18n = DEFAULT_I18N, data } = this.props;

    const similarArtists: Artist[] = maybe(() => data.getArtist.similar.map((artist) => artist.artist), []).filter(
      (artist) => artist !== null
    );

    return (
      <div>
        <h3 data-test-might-also-like-header>{i18n.title}</h3>
        <div className={ArtistSimilarArtists.styles.grid}>
          {similarArtists.map((artist, index) => (
            <ArtistTile
              key={index.toString()}
              artist={artist}
              preload={preload}
              Favourite={Favourite}
              onClickLink={onClickLink}
            />
          ))}
        </div>
      </div>
    );
  }

  static styles = {
    grid: style({
      ...horizontal,
      ...wrap,
      width: percent(100),
      $nest: {
        '&> *': {
          width: percent(50),
        },
        '&> *:only-child': {
          width: percent(100),
        },
      },
    }),
  };
}

export const ConnectedArtistSimilarArtists = graphql(
  gql`
    query($identity: ID!) {
      getArtist(options: { identity: $identity }) {
        name
        similar {
          artist {
            name
            slug
            images {
              identity
            }
          }
        }
      }
    }
  `,
  {
    options: (variables: BrowseResultsProps) => ({
      variables,
      // fetchPolicy: 'network-only'
    }),
  }
)(ArtistSimilarArtists);
