import * as React from 'react';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { ContentSliceProps } from './ContentSlice';
import { PlaylistTile } from '../../molecules/PlaylistTile';
import { PlaylistGroupPlaylistSchema, PlaylistSchema } from '../../../../types/schema';
import { mediaDesktop, mediaUpToDesktop } from '../../utilities/mediaQueries';
import { DecoratedPageCarousel, DecoratedPageCarouselProps } from '../DecoratedPageCarousel';
import { colorGunmetal } from '../../../../theme/color';
import { shadowElevation2 } from '../../../../theme/shadow';
import { BaseCarouselSlice } from './BaseCarouselSlice';
import { LargePlaylistTile } from '../../molecules/LargePlaylistTile';
import { BROWSE_ROUTE } from '../../../../constants';
import FavouritePlaylistButtonCompat from '../../../compat/favourite-playlist-button-compat';
import { SeeAllButton } from '../../molecules/panels/atoms/SeeAllButton';

const styles = {
  carouselControls: style({
    $nest: {
      '& > button': {
        transform: 'translateY(-34px)',
      },
    },
  }),
  carousel: style(
    mediaUpToDesktop({
      marginLeft: px(-16),
      marginRight: px(-16),
      width: 'calc(100% + 32px)',
    })
  ),
  trackTile: style(
    {
      $nest: {
        ':not(.with-gradient) &': {
          color: colorGunmetal.toHexString(),
        },
      },
    },
    mediaUpToDesktop({
      margin: 0,
      marginLeft: px(16),
    })
  ),
  trackPlay: style(
    {
      position: 'absolute',
      bottom: px(81),
      zIndex: 1,
    },
    mediaUpToDesktop({
      right: px(8),
    }),
    mediaDesktop({
      right: px(16),
    })
  ),
  channelButton: style({
    backgroundColor: '#E0E4E9',
    color: '#2E2E3B',
    height: px(32),
    fontSize: px(16),
    $nest: {
      '&:hover, &:active': {
        backgroundColor: '#E0E4E9',
        ...shadowElevation2,
      },
    },
  }),
};

// Exclude gradient prop as this slice has a two tone background
type CarouselSliceProps = ContentSliceProps &
  DecoratedPageCarouselProps & { tracks: Array<PlaylistGroupPlaylistSchema | PlaylistSchema>; actionHref?: string };

export const PlaylistCarouselSlice: React.FC<CarouselSliceProps> = ({
  children,
  className,
  contentClassName,
  tracks,
  actionHref,
  carouselClassName,
  actions,
  ...props
}) => {
  const shouldUseLargeTile = props.perPage <= 3;
  if (shouldUseLargeTile) {
    props.mobileTileSize = 85.3;
  }
  const defaultAction = (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <SeeAllButton href={actionHref || BROWSE_ROUTE} data-test-see-all-button-playlist-carousel-slice>
        See all
      </SeeAllButton>
    </div>
  );
  return (
    <BaseCarouselSlice className={className} contentClassName={contentClassName}>
      <DecoratedPageCarousel
        actions={React.isValidElement(actions) || actions === null ? actions : defaultAction}
        controlClassName={!shouldUseLargeTile && styles.carouselControls}
        carouselClassName={classes(styles.carousel, carouselClassName)}
        {...props}
      >
        {tracks.map((track) =>
          shouldUseLargeTile ? (
            <LargePlaylistTile key={track.identity} playlist={track} />
          ) : (
            <PlaylistTile key={track.identity} playlist={track} className={styles.trackTile}>
              <FavouritePlaylistButtonCompat identity={track.identity} />
            </PlaylistTile>
          )
        )}
      </DecoratedPageCarousel>
    </BaseCarouselSlice>
  );
};
