import * as React from 'react';
import { deps, inject, observer, StatefulComponent } from '../../lib/component';
import { classes, style } from 'typestyle';
import {
  aroundJustified,
  betweenJustified,
  center,
  centerCenter,
  centerJustified,
  flexRoot,
  horizontal,
  start,
  startJustified,
  vertical,
  wrap,
} from 'csstips';
import { important, percent, px } from 'csx';
import {
  DEFAULT_VIDEO_PLATFORM_KEYS,
  DEFAULT_VIDEO_PLATFORM_LABELS,
  SearchState,
  SearchVideoPlatforms,
} from '../../modules/page/search/model';
import { formatBpm, formatDuration } from '../../lib/helpers';
import { Loading } from '../loading/loading';
import { SVGIcon, VideoPlatformMap } from '../svg-icon';
import { PinchSlider } from '../pinch-slider';
import { ButtonPill } from '../button-pill';
import { colorBackground, colorBrand, colorContrast, colorGunmetal, colorSubtle, colorWhite } from '../../theme/color';
import { zAppHeaderContainer } from '../../theme/z';
import { constantGoldenRatio, constantHeaderHeight, constantSearchFiltersWidth } from '../../theme/constant';
import { transitionQuickEase } from '../../theme/transition';
import { mediaMobileOnly, mediaTablet } from '../../theme/media';
import { resetRangeStyles } from '../../theme/reset';
import { CatalogueType, FindFiltersInput, FindSortMode } from '../../types/graphql';
import { DarkRadioInput } from '../project-happy/atoms/controls/DarkRadioInput';
import { ClientModel } from '../../modules/client/model';

type Category =
  | 'Catalogue type'
  | 'Order by'
  | 'Genre'
  | 'Mood'
  | 'Artist'
  | 'Label'
  | 'Length'
  | 'Tempo'
  | 'Song properties'
  | 'Usage' // Note that in WEB-4164 we removed this filter from public view
  | 'Target platforms';

export type SearchFilterSection = {
  category: Category;
  label: string;
  hide?: boolean | ((model: ClientModel) => boolean);
};

export type SearchFiltersI18n = {
  sections: SearchFilterSection[];
  durationMin: string;
  durationMax: string;
  emptyFilter: string;
};

type Props = {
  i18n: SearchFiltersI18n;
};

type State = {
  activeIndex: number | null;
  localTempo: string | null;
};

@inject(deps)
@observer
class SearchFilters extends StatefulComponent<Props, State> {
  state = {
    activeIndex: null,
    localTempo: null,
  };

  render() {
    const { i18n } = this.props;
    const { loading } = this.props.model.page.search;
    const { showFilters } = this.props.model.page.search;

    const className = classes(SearchFilters.styles.container, showFilters && SearchFilters.styles.containerActive);

    if (loading)
      return (
        <div className={className}>
          <div className={SearchFilters.styles.loading}>
            <Loading />
          </div>
        </div>
      );

    return (
      <div className={className}>
        <div className={SearchFilters.styles.filters}>
          {i18n.sections
            .filter((s) => (typeof s.hide === 'function' ? !s.hide(this.props.model) : !s.hide))
            .map(this.renderSection)}
        </div>
        <div className={SearchFilters.styles.actions}>
          <ButtonPill muted={true} onClick={this.reset} className={SearchFilters.styles.action}>
            {`Clear all filters`}
            <SVGIcon.Cross size={8} color={colorGunmetal.toString()} />
          </ButtonPill>
          <ButtonPill
            muted={true}
            onClick={this.props.controller.page.search.toggleFilters.bind(null, !showFilters)}
            className={classes(SearchFilters.styles.action, SearchFilters.styles.actionHide)}
          >
            {`Hide`}
            <SVGIcon.ArrowRight size={8} color={colorGunmetal.toString()} />
          </ButtonPill>
        </div>
      </div>
    );
  }

  renderSection = (section: SearchFilterSection, index: number, sections: SearchFilterSection[]) => {
    const { i18n } = this.props;

    return (
      <div key={section.category} className={SearchFilters.styles.filter}>
        <div className={SearchFilters.styles.menuItem}>
          <div>
            <span>{sections[index].category}</span>
          </div>
          <div>{section.label || <span className={SearchFilters.styles.emptyFilter}>{i18n.emptyFilter}</span>}</div>
        </div>
        {this.renderContent(section)}
      </div>
    );
  };

  renderContent = (section: SearchFilterSection) => {
    const { state, defaultState } = this.props.model.page.search;
    switch (section.category) {
      case 'Catalogue type':
        return this.renderCatalogueType(state);
      case 'Order by':
        return this.renderOrderBy(state);

      case 'Genre':
        return this.renderGenres(state);
      case 'Mood':
        return this.renderMoods(state);

      case 'Length':
        return this.renderDuration(state);

      case 'Tempo':
        return this.renderTempo(state);

      case 'Song properties':
        return this.renderSongProperties(state);

      case 'Target platforms':
        return this.renderTargetPlatforms(state);

      default:
        return null;
    }
  };

  renderCatalogueType = ({ filters: { catalogueType } }: SearchState) => (
    <div className={classes(SearchFilters.styles.filter, SearchFilters.styles.radioSet)}>
      <label className={SearchFilters.styles.radioLabel}>
        All{' '}
        <DarkRadioInput
          name="catalogue-type"
          checked={catalogueType === CatalogueType.ALL}
          onClick={this.updateCatalogueType.bind(this, CatalogueType.ALL)}
        />
      </label>
      <label className={SearchFilters.styles.radioLabel}>
        Premium{' '}
        <DarkRadioInput
          name="catalogue-type"
          checked={catalogueType === CatalogueType.COMMERCIAL}
          onClick={this.updateCatalogueType.bind(this, CatalogueType.COMMERCIAL)}
        />
      </label>
      <label className={SearchFilters.styles.radioLabel}>
        Included{' '}
        <DarkRadioInput
          name="catalogue-type"
          checked={catalogueType === CatalogueType.STOCK}
          onClick={this.updateCatalogueType.bind(this, CatalogueType.STOCK)}
        />
      </label>
    </div>
  );

  renderOrderBy = ({ sortMode }: SearchState) => (
    <div className={SearchFilters.styles.filter}>
      <div className={SearchFilters.styles.pills}>
        {Object.keys(SearchFilters.SortOptions).map((key) => (
          <div
            key={key}
            data-role="button"
            className={classes(
              SearchFilters.styles.pill,
              SearchFilters.styles.pill2,
              key === sortMode && SearchFilters.styles.pillActive
            )}
            onClick={this.updateSort.bind(this, key)}
          >
            {SearchFilters.SortOptions[key]}
          </div>
        ))}
      </div>
    </div>
  );

  renderGenres = ({ filters }: SearchState) => (
    <div className={SearchFilters.styles.filter}>
      <div className={SearchFilters.styles.pills}>
        {this.props.model.page.search.genres.map((g) => (
          <div
            key={g.slug}
            data-role="button"
            className={classes(
              SearchFilters.styles.pill,
              SearchFilters.styles.pill3,
              g.slug === filters.genre && SearchFilters.styles.pillActive
            )}
            onClick={this.updateGenre.bind(this, g.slug)}
          >
            {g.label}
          </div>
        ))}
      </div>
    </div>
  );

  renderMoods = ({ filters }: SearchState) => (
    <div className={SearchFilters.styles.filter}>
      <div className={SearchFilters.styles.pills}>
        {this.props.model.page.search.moods.map((t) => (
          <div
            key={t.slug}
            data-role="button"
            className={classes(
              SearchFilters.styles.pill,
              SearchFilters.styles.pill3,
              t.slug === filters.mood && SearchFilters.styles.pillActive
            )}
            onClick={this.updateMood.bind(this, t.slug)}
          >
            {t.tag}
          </div>
        ))}
      </div>
    </div>
  );

  renderDuration = ({ filters }: SearchState) => {
    const { durationRange, defaultState } = this.props.model.page.search;
    const [MIN_DURATION, MAX_DURATION] = durationRange;

    return (
      <div className={SearchFilters.styles.filter}>
        <div className={SearchFilters.styles.filterBlock}>
          <PinchSlider
            lower={filters.durationMin || MIN_DURATION}
            upper={filters.durationMax || MAX_DURATION}
            min={defaultState.filters.durationMin || MIN_DURATION}
            max={defaultState.filters.durationMax || MAX_DURATION}
            step={5000}
            formatValue={formatDuration}
            onChange={this.updateDuration}
          />
        </div>
      </div>
    );
  };

  renderTempo = ({ filters }: SearchState) => {
    const { bpmRange, defaultState } = this.props.model.page.search;
    const [MIN_BPM, MAX_BPM] = bpmRange;
    const { localTempo } = this.state;
    return (
      <div className={SearchFilters.styles.filter}>
        <div className={SearchFilters.styles.filterBlock}>
          <PinchSlider
            lower={filters.bpmMin || MIN_BPM}
            upper={filters.bpmMax || MAX_BPM}
            min={defaultState.filters.bpmMin || MIN_BPM}
            max={defaultState.filters.bpmMax || MAX_BPM}
            step={1}
            formatValue={formatBpm}
            onChange={this.updateBpm}
          />
        </div>
        <div className={SearchFilters.styles.pills}>
          {this.props.model.page.search.tempos.map((tempo) => (
            <div
              key={tempo.label}
              data-role="button"
              className={classes(
                SearchFilters.styles.pill,
                SearchFilters.styles.pill5,
                tempo.label === localTempo && SearchFilters.styles.pillActive
              )}
              onClick={this.updateTempo.bind(this, tempo.label)}
            >
              {tempo.label}
            </div>
          ))}
        </div>
      </div>
    );
  };

  renderSongProperties = ({ filters }: SearchState) => (
    <div className={SearchFilters.styles.filter}>
      <div className={SearchFilters.styles.pills}>
        <div
          data-role="button"
          className={classes(
            SearchFilters.styles.pill,
            SearchFilters.styles.pill2,
            filters.instrumental === true && SearchFilters.styles.pillPositive,
            filters.instrumental === false && SearchFilters.styles.pillNegative
          )}
          onClick={this.toggleFlag.bind(this, 'instrumental', filters.instrumental)}
        >
          <span>Instrumental</span>
          {filters.instrumental === true && <SVGIcon.Checkmark size={8} />}
          {filters.instrumental === false && <SVGIcon.Cross size={8} />}
        </div>
        <div
          data-role="button"
          className={classes(
            SearchFilters.styles.pill,
            SearchFilters.styles.pill2,
            filters.explicit === true && SearchFilters.styles.pillPositive,
            filters.explicit === false && SearchFilters.styles.pillNegative
          )}
          onClick={this.toggleFlag.bind(this, 'explicit', filters.explicit)}
        >
          <span>Explicit</span>
          {filters.explicit === true && <SVGIcon.Checkmark size={8} />}
          {filters.explicit === false && <SVGIcon.Cross size={8} />}
        </div>
      </div>
    </div>
  );

  renderTargetPlatforms = ({ filters }: SearchState) => {
    return (
      <div className={SearchFilters.styles.filter}>
        <div className={SearchFilters.styles.pills}>
          {DEFAULT_VIDEO_PLATFORM_KEYS.slice(1).map((key) => {
            const Icon = VideoPlatformMap[key];
            const isActive = filters[key];

            return (
              <div
                data-role="button"
                key={key}
                onClick={this.updateVideoPlatforms.bind(this, key, filters[key])}
                className={classes(
                  SearchFilters.styles.platform,
                  SearchFilters.styles.pill,
                  SearchFilters.styles.pill3,
                  isActive && SearchFilters.styles.pillActive,
                  isActive && SearchFilters.styles.pillPositive
                )}
              >
                <Icon size={12} color={isActive ? colorWhite.toString() : colorSubtle.toString()} />
                <span>{DEFAULT_VIDEO_PLATFORM_LABELS[key]}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  updateCatalogueType(type: CatalogueType) {
    this.props.controller.page.search.refineFilter('catalogueType', type);
  }

  updateSort = (sort: FindSortMode) => {
    this.props.controller.page.search.refineSort(sort);
  };

  updateDuration = (lower: number, upper: number) => {
    this.props.controller.page.search.refineFilter('durationMin', lower);
    this.props.controller.page.search.refineFilter('durationMax', upper);
  };

  updateBpm = (lower: number, upper: number) => {
    this.props.controller.page.search.refineFilter('bpmMin', lower);
    this.props.controller.page.search.refineFilter('bpmMax', upper);
    this.setState({ localTempo: null });
  };

  updateGenre = (slug: string) => {
    const nextGenre = this.props.model.page.search.state.filters.genre === slug ? undefined : slug;

    this.props.controller.page.search.refineFilter('genre', nextGenre);
  };

  updateTheme = (slug: string) => {
    const nextTheme = this.props.model.page.search.state.filters.theme === slug ? undefined : slug;

    this.props.controller.page.search.refineFilter('theme', nextTheme);
  };

  updateMood = (slug: string) => {
    const nextMood = this.props.model.page.search.state.filters.mood === slug ? undefined : slug;

    this.props.controller.page.search.refineFilter('mood', nextMood);
  };

  updateTempo = (label: string) => {
    const nextTempoRange = this.props.model.page.search.tempos.find((t) => t.label === label);

    if (nextTempoRange && nextTempoRange.range) {
      const [min, max] = nextTempoRange.range;
      const matching = this.state.localTempo === label;

      this.props.controller.page.search.refineFilter('bpmMin', matching ? null : min);
      this.props.controller.page.search.refineFilter('bpmMax', matching ? null : max);
      this.setState({ localTempo: matching ? null : label });
    }
  };

  toggleFlag = (key: keyof FindFiltersInput, current: boolean | undefined) => {
    switch (true) {
      case current === true:
        return this.props.controller.page.search.refineFilter(key, false);

      case current === false:
        return this.props.controller.page.search.refineFilter(key, undefined);

      default:
        return this.props.controller.page.search.refineFilter(key, true);
    }
  };

  updateVideoPlatforms = (platform: keyof SearchVideoPlatforms, current: boolean | undefined) => {
    this.props.controller.page.search.refineFilter(platform, current ? undefined : true);
  };

  reset = () => {
    this.setState({ localTempo: null });
    this.props.controller.page.search.resetFilters();
  };

  static SortOptions: { [key in FindSortMode]: string } = {
    RELEVANCE: 'Most relevant',
    POPULAR_SPOTIFY: 'Popular on Spotify',
    POPULAR_YOUTUBE: 'Popular on YouTube',
    POPULAR_LICKD: 'Most licensed',
    DATE_ADDED: 'Recently added',
    ALPHABETICAL: 'Alphabetical',
  };

  static styles = {
    container: style(
      {
        ...vertical,
        ...start,
        ...startJustified,
        background: colorBackground.lighten(0.05).toString(),
        width: percent(100),
        display: 'none',
        position: 'absolute',
        top: `calc(2 * ${constantHeaderHeight})`,
        zIndex: zAppHeaderContainer - 4,
        overflow: 'scroll',
      },
      mediaMobileOnly({
        height: `calc(100vh - (2 * ${constantHeaderHeight}))`,
      }),
      mediaTablet({
        display: 'block',
        maxWidth: `calc(100vw / ${constantGoldenRatio})`,
        width: constantSearchFiltersWidth,
        top: `${constantHeaderHeight} !important`,
        right: 0,
        bottom: 0,
        background: 'transparent',
      })
    ),
    containerActive: style(
      mediaMobileOnly({
        display: 'block',
        position: 'fixed',
        left: 0,
        right: 0,
      })
    ),
    containerShunted: style({
      top: constantHeaderHeight,
    }),
    loading: style({
      ...centerCenter,
      width: percent(100),
      padding: px(64),
    }),
    hideMobile: style(
      mediaMobileOnly({
        display: 'none',
      })
    ),
    menuItem: style({
      ...vertical,
      ...centerJustified,
      ...start,
      position: 'relative',
      width: percent(100),
      flexShrink: 0,
      height: constantHeaderHeight,
      cursor: 'pointer',
      padding: '0 16px',
      background: colorBackground.lighten(0.05).toString(),
      transition: `background ${transitionQuickEase}`,
      overflow: 'hidden',
      $nest: {
        '&> div:first-child': {
          fontSize: px(15),
          fontWeight: 600,
          lineHeight: px(20),
        },
        '&> div:last-child': {
          fontSize: px(12),
          lineHeight: px(20),
          whiteSpace: 'pre',
          overflow: 'hidden',
        },
        '& svg': {
          zIndex: 2,
          transition: `transform ${transitionQuickEase}`,
          marginLeft: px(8),
        },
      },
    }),
    emptyFilter: style({
      color: important(colorSubtle.toString()),
    }),
    menuItemActive: style({
      background: colorBackground.lighten(0.075).toString(),
      $nest: {
        '&:after': {
          background: `linear-gradient(to right, ${colorBackground
            .lighten(0.075)
            .fade(0)
            .toRGBA()} 0%, ${colorBackground.lighten(0.075).fade(1).toRGBA()} 85%) !important`,
        },
        '& svg': {
          transform: `rotate(90deg)`,
        },
      },
    }),
    filters: style({
      ...vertical,
      ...start,
      ...startJustified,
      background: colorBackground.lighten(0.075).toString(),
      position: 'relative',
      flexShrink: 0,
      overflowX: 'hidden',
      overflowY: 'auto',
      width: percent(100),
      padding: '0 0 16px 0',
    }),
    filter: style({
      ...vertical,
      ...start,
      ...startJustified,
      transition: `transform ${transitionQuickEase}`,
      willChange: 'transform',
      width: percent(100),
      flexShrink: 0,
    }),
    filterBlock: style({
      padding: '8px 16px',
      width: percent(100),
    }),
    radioLabel: style(flexRoot, center, {
      $nest: {
        '& .radio-input': {
          marginLeft: px(8),
        },
      },
    }),
    radioSet: style(flexRoot, aroundJustified, horizontal, { marginTop: px(8), marginBottom: px(8) }),
    tempoTrack: style({
      padding: px(8),
    }),
    filterHeader: style({
      ...horizontal,
      ...betweenJustified,
      fontSize: px(15),
      width: percent(100),
    }),
    pills: style({
      ...horizontal,
      ...wrap,
      width: percent(100),
      maxHeight: percent(100),
      padding: '8px 8px 0 16px',
    }),
    pill: style({
      textAlign: 'center',
      position: 'relative',
      cursor: 'pointer',
      borderRadius: px(4),
      border: `1px solid ${colorSubtle.lighten(0.2).toString()}`,
      color: colorSubtle.lighten(0.1).toString(),
      background: 'transparent',
      willChange: 'background, color, border-color',
      transition: `all ${transitionQuickEase}`,
      minWidth: px(52),
      margin: '0 8px 8px 0',
      padding: '0 3px 0',
      fontSize: px(12),
      overflow: 'hidden',
      whiteSpace: 'pre',
      $nest: {
        '&:hover': {
          color: colorGunmetal.toString(),
          borderColor: colorGunmetal.toString(),
        },
        '& svg': {
          transition: `transform ${transitionQuickEase}`,
          marginLeft: px(8),
        },
      },
    }),
    pill5: style({
      width: 'calc(100% / 5 - 8px)',
    }),
    pill4: style({
      width: 'calc(100% / 4 - 8px)',
    }),
    pill3: style({
      width: 'calc(100% / 3 - 8px)',
    }),
    pill2: style({
      width: `calc(100% / 2 - 8px)`,
    }),
    pillActive: style({
      color: colorWhite.toString(),
      background: colorGunmetal.toString(),
      borderColor: colorGunmetal.toString(),
      $nest: {
        '&:hover': {
          color: important(colorWhite.toString()),
          borderColor: important(colorWhite.toString()),
        },
      },
    }),
    pillPositive: style({
      color: colorWhite.toString(),
      background: colorGunmetal.toString(),
      borderColor: colorGunmetal.toString(),
      $nest: {
        '&:hover': {
          color: important(colorWhite.toString()),
          borderColor: important(colorWhite.toString()),
        },
      },
    }),
    pillNegative: style({
      color: colorWhite.toString(),
      background: colorContrast.toString(),
      borderColor: colorContrast.toString(),
      $nest: {
        '&:hover': {
          color: important(colorWhite.toString()),
          borderColor: important(colorWhite.toString()),
        },
      },
    }),
    range: style({ width: percent(100) }, resetRangeStyles()),
    actions: style({
      ...horizontal,
      ...betweenJustified,
      ...center,
      flex: 1,
      width: percent(100),
      height: constantHeaderHeight,
      borderBottom: `1px solid ${colorBackground.toString()}`,
      background: colorBackground.lighten(0.075).toString(),
      padding: '8px 16px',
      textAlign: 'center',
      $nest: {
        '& svg': {
          transform: 'rotate(-90deg)',
          marginLeft: px(8),
        },
        '&:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          left: 0,
          right: 0,
          top: px(-16),
          height: px(16),
          zIndex: 1,
          background: `linear-gradient(to bottom, ${colorBackground
            .lighten(0.075)
            .fade(0)
            .toRGBA()} 0%, ${colorBackground.lighten(0.075).fade(1).toRGBA()} 85%)`,
        },
      },
    }),
    action: style({
      color: colorGunmetal.toString(),
      fontSize: px(12),
    }),
    actionHide: style(
      mediaTablet({
        display: 'none',
      })
    ),
    platformLabel: style({
      padding: '12px 8px 8px 8px',
      fontSize: px(12),
      lineHeight: px(12),
      color: colorSubtle.toString(),
      $nest: {
        '&:first-child': {
          marginTop: px(8),
        },
      },
    }),
    platform: style({
      $nest: {
        '&> svg': {
          margin: '0 4px -2px 0',
        },
      },
    }),
  };
}

export default SearchFilters;
