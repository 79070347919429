import * as React from 'react';
import { CSSProperties, NestedCSSProperties } from 'typestyle/lib/types';
import { quote, url } from 'csx';
import { zHeroCarouselGradient, zHeroCarouselImage, zHeroContent } from '../theme/z';
import { gradientPricing } from '../theme/gradient';
import { style } from 'typestyle';
import { PageHomePricingHeader } from './page-home-pricing-header';
import { PageHomePricingGrid } from './page-home-pricing-grid';
import { PageHomePricingCaveat } from './page-home-pricing-caveat';
import { PageHomePricingCTA } from './page-home-pricing-cta';
import { observer } from 'mobx-react';
import { mediaTablet, mediaMobileOnly } from '../theme/media';
import { RatecardsSchema } from '../types/schema';
import { ResponsiveImageTile } from './responsive-image-tile';
import { ImagePreloader } from '../types/fn';

export interface PageHomePricingModel {
  pricingImage: string;
  ratecards: RatecardsSchema;
  benefits: Array<{
    title: string;
    content: any;
  }>;
  ratecardTypes: Array<{ value: string; label: string }>;
  ratecardType: string;
  ratecardLoading: boolean;
}

export interface PageHomePricingProps {
  onClickShowCalculator?(event: React.MouseEvent<any>): void;
  onClickLink?(event: React.MouseEvent<any>): void;
  onSignUp?(event: React.MouseEvent<any>): void;
  onSelectRateType(type: string): void;
  model: PageHomePricingModel;
  preload: ImagePreloader;
}

const commonStyles: CSSProperties = {
  content: quote(''),
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

export const PageHomePricingStyle = style({
  position: 'relative',
  $nest: {
    '&::after': {
      ...commonStyles,
      zIndex: zHeroCarouselGradient,
      backgroundImage: gradientPricing,
    },
    '&> div': {
      position: 'relative',
      padding: '20px 0',
      zIndex: zHeroContent,
    },
  },
});

export const PageHomePricingSubHeaderStyle = style({
  padding: '0 80px',
});

export const PageHomePricingBenefitsStyle = style(
  mediaMobileOnly({ padding: '0 1rem 60px' }),
  mediaTablet({ padding: '0 80px 80px' })
);

export const PageHomePricingCTAStyle = style(
  mediaMobileOnly({ padding: '0 1rem 60px' }),
  mediaTablet({ padding: '0 80px 80px' })
);

export const PageHomePricing = observer(
  ({
    children,
    preload,
    onClickShowCalculator,
    onSelectRateType,
    model,
    onSignUp,
    onClickLink,
  }: React.PropsWithChildren<PageHomePricingProps>) => {
    return (
      <ResponsiveImageTile
        asBackground={true}
        className={PageHomePricingStyle}
        identity={model.pricingImage}
        preload={preload}
      >
        <div>
          <PageHomePricingHeader onClickLink={onClickLink} />
          {!children && (
            <PageHomePricingGrid
              model={model}
              onClickShowCalculator={onClickShowCalculator}
              onSelectRateType={onSelectRateType}
            />
          )}
          {children}
          {!children && <PageHomePricingCaveat />}
          {onSignUp && <PageHomePricingCTA className={PageHomePricingCTAStyle} onSignUp={onSignUp} />}
        </div>
      </ResponsiveImageTile>
    );
  }
);
