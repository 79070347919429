import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const Menu: React.SFC<SVGIconProps> = ({ size = 12, isSquare, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={isSquare ? size : size * (8 / 11)} viewBox="0 0 22 16" {...props}>
    <line x1="0.333252" y1="15" x2="21.6666" y2="15" stroke={color} strokeWidth="2" />
    <line x1="0.333252" y1="8.33325" x2="21.6666" y2="8.33325" stroke={color} strokeWidth="2" />
    <line x1="0.333252" y1="1.66663" x2="21.6666" y2="1.66663" stroke={color} strokeWidth="2" />
  </BaseSvg>
);
