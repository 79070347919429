import * as React from 'react';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { colorGreyAccent, colorGunmetal } from '../../../theme/color';

const styles = style({
  borderRadius: px(8),
  backgroundColor: '#F3F5F7',
  $nest: {
    '& .details': {
      boxShadow: 'none',
      color: colorGunmetal.toHexString(),
      border: 0,
      borderRadius: 0,
      $nest: {
        '&:not(:first-child)': {
          borderTop: '1px solid',
          borderTopColor: colorGreyAccent.toHexString(),
        },
        '& p': {
          marginLeft: px(15),
          marginRight: px(15),
        },
      },
    },
  },
});

export type FaqBlockProps = React.Component['props'] & {
  className?: string;
};

export const FaqBlock: React.SFC<FaqBlockProps> = ({ children, className }) => (
  <div data-test-faq-block className={classes(styles, className)}>
    {children}
  </div>
);
