import * as React from 'react';
import {observer} from 'mobx-react';
import {Form as IForm} from '../types/form';
import {FormInput} from './form-input';
import {ButtonPill} from './button-pill';
import {endJustified, horizontal, horizontallySpaced} from 'csstips';
import {style} from 'typestyle';
import {colorError} from '../theme/color';
import {px} from 'csx';

export interface FormProps {
  className?: string;
  autoFocus?: string;
  model: IForm<any>;
  noButtons?: boolean;
  onSubmit?(): void;
  onCancel?(): void;
  onChange(field: string, value: any);
  i18n?: {
    submit: string;
    cancel: string;
    saving: string;
  }
}

type State = {
  /** We need this because Safari does not preserve order with Object.keys */
  order: null | string[]
}

const DEFAULT_I18N = {
  submit: 'Save',
  cancel: 'Cancel',
  saving: 'Saving...'
};

@observer
export class Form extends React.Component<FormProps, State> {
  private form: HTMLFormElement;

  state = {
    order: null,
  };

  render() {
    const { className, model, noButtons, onChange, onCancel, i18n = DEFAULT_I18N } = this.props;
    const fields = this.state.order || Object.keys(model.fields);

    const hasCancel = typeof onCancel === 'function';

    return (
      <form onKeyUp={this.handleKeyUp} className={className} onSubmit={this.handleSubmit} ref={el => this.form = el}>
        {fields.map((key, index) => (
          <FormInput
            key={index}
            model={model.fields[key]}
            onChange={onChange}
            disabled={model.disabled || model.saving}
          />
        ))}
        {model.error && (
          <div className={Form.styles.error}>
            {model.error}
          </div>
        )}
        {!noButtons && (
          <div className={Form.styles.buttons}>
            {hasCancel && <ButtonPill
              children={i18n.cancel}
              onClick={this.handleCancel}
              disabled={model.saving}
            />}
            <ButtonPill
              children={model.saving ? i18n.saving : i18n.submit}
              onClick={this.handleSubmit}
              disabled={model.saving}
              primary={true}
              type="submit"
            />
          </div>
        )}
      </form>
    )
  }

  UNSAFE_componentWillMount() {
    this.captureOrder();
  };

  handleKeyUp = (event: React.KeyboardEvent<any>) => {
    switch (event.keyCode) {
      case 27:
        return this.handleCancel(event);
      case 13:
        return this.handleSubmit(event);
    }
  };

  handleSubmit = (event: React.FormEvent<any>) => {
    event.preventDefault();
    this.props.onSubmit();
  };

  handleCancel = (event: React.FormEvent<any>) => {
    event.preventDefault();
    this.props.onCancel();
  };

  componentDidMount() {
    const { autoFocus } = this.props;

    if (autoFocus) {
      const input = this.form.querySelector(`[name="${autoFocus}"]`) as HTMLFormElement;
      if (input && input.focus) input.focus();
    }
  }

  private captureOrder = () => {
    this.setState({order: Object.keys(this.props.model.fields)})
  };

  static styles = {
    buttons: style({
      ...horizontal,
      ...horizontallySpaced(10),
      ...endJustified,
      margin: '30px 0 0 0'
    }),
    error: style({
      color: colorError.toString(),
      fontSize: px(12)
    }),
  }
}