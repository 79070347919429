import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const DownloadIcon: React.FC<SVGIconProps> = ({ size = 12, color = 'currentColor', ...props }) => (
  <BaseSvg width={size} height={size * (15 / 12)} viewBox="0 0 12 15" {...props}>
    <g clipPath="url(#clip0_2473_13286)">
      <path
        d="M11.8332 5.49967H8.49984V0.499674H3.49984V5.49967H0.166504L5.99984 11.333L11.8332 5.49967Z"
        fill={color}
      />
      <path
        d="M8.49984 -11.167H11.8332L5.99984 -5.33366L0.166504 -11.167H3.49984V-16.167H8.49984V-11.167Z"
        fill={color}
      />
      <rect x="0.166504" y="13" width="11.6667" height="1.66667" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0_2473_13286">
        <rect width="11.6667" height="14.1667" fill="white" transform="translate(0.166504 0.5)" />
      </clipPath>
    </defs>
  </BaseSvg>
);
