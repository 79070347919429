import { style } from 'typestyle';
import { centerCenter, flexRoot } from 'csstips';
import { fontBody } from '../../../config';
import { colorWhite, rebrand } from '../../../../../theme/color';
import { spacing } from '../../../../../theme/spacing';
import { displayHeadingStyles } from '../../../utilities/font';
import { important, px } from 'csx';
import { mediaMobileOnly } from '../../../../../theme/media';

const styles = {
  message: style({
    fontSize: '20px',
    fontWeight: 700,
    marginBottom: spacing.LARGE,
    marginTop: '0',
  }),
  cardsContainer: style(
    {
      position: 'relative',
      display: 'grid',
      gridGap: spacing.LARGE,
      gridTemplateColumns: '1fr 1fr',
      marginBottom: spacing.LARGE,
    },
    mediaMobileOnly({
      gridTemplateColumns: '1fr',
    })
  ),
  card: style({
    padding: '32px',
    boxShadow: '0px 8px 16px 0px #00000029',
    borderRadius: '16px',
    backgroundColor: colorWhite.toHexString(),
  }),
  cardTitle: style({
    fontSize: '48px',
    fontWeight: 400,
    margin: '0',
    color: rebrand.contrast[40].toHexString(),
  }),
  cardBody: style({
    marginTop: spacing.LARGE,
  }),
  cardBulletPoint: style({
    display: 'flex',
    padding: '8px 0px',
    textAlign: 'left',
  }),
  icon: style({
    marginRight: '10px',
    flexShrink: 0,
  }),
  arrow: style(
    {
      width: '64px',
      height: '36px',
      position: 'absolute',
      left: 'calc(50% - 32px)',
      top: 'calc(50% - 18px)',
    },
    mediaMobileOnly({
      display: 'none',
    })
  ),
  cardTitleActive: style({
    fontSize: '48px',
    fontWeight: 400,
    margin: '0',
    color: rebrand.primary[100].toHexString(),
  }),
  cardTitleDisabled: style({
    fontSize: '48px',
    fontWeight: 400,
    margin: '0',
    color: rebrand.neutralOnDark[50].toHexString(),
  }),
  buttonsContainer: style(
    {
      display: 'grid',
      gridGap: spacing.LARGE,
      gridTemplateColumns: '1fr 1fr',
      marginTop: spacing.LARGE,
      $nest: {
        '& > :first-child': {
          marginLeft: 'auto',
        },
        '& > :last-child': {
          marginLeft: '0',
        },
      },
    },
    mediaMobileOnly({
      gridTemplateColumns: '1fr',
      justifyItems: 'center',
      $nest: {
        '& > button': {
          margin: important('0 auto'),
        },
      },
    })
  ),
  hideOnMobile: style(
    mediaMobileOnly({
      display: 'none',
    })
  ),
  button: style({
    width: 'fit-content',
  }),
  planHeadingInfoText: style(flexRoot, centerCenter, {
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: fontBody,
    lineHeight: '28px',
    textAlign: 'center',
    color: rebrand.primary[100].toString(),
    $nest: {
      '& svg': {
        flexShrink: 0,
        marginRight: spacing.XXSMALL,
      },
      '.enterprise-plan &': {
        fontWeight: 400,
        color: rebrand.contrast[40].toString(),
      },
    },
  }),
  pricingContentMonth: style({
    color: rebrand.contrast[40].toString(),
  }),
  pricingContentLarge: style({
    fontSize: px(64),
  }),
  pricingContentsSubscription: style({
    ...displayHeadingStyles,
    textAlign: 'center',
    fontSize: '22px',
    whiteSpace: 'pre-wrap',
    margin: `${spacing.LARGE} 0 0`,
  }),
  shadowSecondary: style({
    boxShadow: '0px 8px 16px 0px #00000029',
  }),
  shadowPrimary: style({
    boxShadow: '0px 0px 24px 0px #DA2699',
  }),
  textDisabled: style({
    color: important(rebrand.neutralOnDark[50].toString()),
  }),
};

export default styles;
