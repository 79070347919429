import React from 'react';
import { classes } from 'typestyle';
import withPlayer, {
  WithPlayerCompatibleComponentProps,
  WithPlayerComponentProps,
} from '../../modules/player/withPlayer';
import { colorGunmetal } from '../../theme/color';
import { ButtonPill } from '../button-pill';
import { SVGIcon } from '../svg-icon';

interface CheckoutPlayButtonProps extends WithPlayerCompatibleComponentProps {
  className?: string;
}

class CheckoutPlayButton extends React.Component<WithPlayerComponentProps & CheckoutPlayButtonProps> {
  render() {
    const { isCurrentPlaying, className } = this.props;

    const classNames = [];

    if (typeof className === 'string') {
      classNames.push(className);
    }

    let Icon = SVGIcon.Play;
    if (isCurrentPlaying) {
      Icon = SVGIcon.Pause;
      classNames.push('playing');
    }

    return (
      <ButtonPill
        tertiary
        icon
        onClick={this.handleOnClick.bind(this)}
        className={classes(...classNames)}
        data-test-track-play
      >
        <Icon size={12} color={colorGunmetal.toString()} />
      </ButtonPill>
    );
  }

  handleOnClick() {
    const { isCurrent, isCurrentPlaying, onLoadImmediate, track, onPause, onPlay } = this.props;

    if (!isCurrent) {
      onLoadImmediate(0, track);
      return;
    }
    if (isCurrentPlaying) {
      onPause();
      return;
    }
    onPlay();
  }
}

export default withPlayer(CheckoutPlayButton);
