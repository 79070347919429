import * as React from 'react';
import { BaseSvg, SVGIconProps } from '../BaseSvg';

export const BmgLogo: React.SFC<SVGIconProps> = ({ size = 105, color = '#2E2E3B', ...props }) => (
  <BaseSvg viewBox="0 0 105 24" width={size} height={size * (24 / 105)} {...props}>
    <path
      d="M91.815.104c8.3 0 11.635 1.65 12.33 7.45h-4.133C99.386 4.376 97.459 3.7 91.884 3.7h-4.62c-6.755 0-8.283 1.18-8.283 6.06v4.55c0 4.863 1.528 6.096 8.284 6.096h4.168c6.79 0 8.388-1.337 8.388-5.783H87.595v-3.49h16.62v3.16c-.018 7.329-3.04 9.708-12.4 9.708H86.9c-9.551 0-12.434-2.014-12.434-9.5V9.62c0-7.45 2.883-9.516 12.434-9.516h4.915zm-72.851 0c5.696 0 8.18 2.866 8.18 6.686 0 1.98-.973 3.595-2.78 4.637 2.606.99 3.908 2.952 3.908 5.383 0 3.838-2.5 6.912-8.18 6.912H0V.104h18.964zM59.584 0c5.765 0 10.35 3.595 10.35 8.909v14.813H65.54V9.048c0-3.317-2.449-5.453-5.957-5.453-3.56 0-5.991 2.153-6.008 5.453l-.053 14.674h-4.289l-.052-14.674c-.017-3.317-2.466-5.453-6.009-5.453-3.508 0-5.956 2.153-5.956 5.453v14.674h-4.394V8.91C32.822 3.612 37.407 0 43.172 0c3.473 0 6.426 1.476 8.214 3.386C53.176 1.476 56.11 0 59.583 0zM19.831 13.424l-15.386.017v6.79h15.403c2.362 0 3.89-1.406 3.89-3.42 0-2.015-1.545-3.387-3.907-3.387zm-1.077-9.83H4.445v6.374h14.31c2.362 0 3.89-1.163 3.89-3.178 0-2.014-1.528-3.195-3.89-3.195z"
      fill={color}
      fillRule="evenodd"
    />
  </BaseSvg>
);
