import React from 'react';
import { RegistrationProgress, registrationProgressFullHeight } from '../organisms/registration/RegistrationProgress';
import { percent, px } from 'csx';
import { classes, style } from 'typestyle';
import { betweenJustified, flexRoot } from 'csstips';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { SignUpForm } from '../molecules/SignUpForm';
import { Collapsible } from '../../collapsible/collapsible';
import { SVGIcon } from '../../svg-icon';
import { colorBlack, colorGunmetal, rebrand } from '../../../theme/color';
import collapsibleStyles from '../../collapsible/styles';
import { fontstackDisplay, headerFullHeight } from '../config';
import { headingTitle, screenReaderOnly } from '../utilities/font';
import { Component, ComponentProps, deps, inject, observer } from '../../../lib/component';
import { AnalyticsController } from '../../../modules/analytics/controller';
import { LOG_IN_ROUTE } from '../../../constants';
import { Balloon } from '../atoms/Balloon';
import TrustPilot from '../../../static/trustpilot-logo-4stars.png';

const headerHeight = headerFullHeight + registrationProgressFullHeight;
const styles = {
  panels: style(
    {
      $nest: {
        '& > div': {
          width: percent(100),
          padding: px(16),
        },
      },
    },
    mediaUpToDesktop({
      $nest: {
        '& > div': {
          minHeight: `calc(100vh - ${headerHeight + 136}px)`,
        },
      },
    }),
    mediaDesktop(flexRoot, {
      $nest: {
        '& > div': {
          minHeight: `calc(100vh - ${headerHeight}px)`,
        },
      },
    })
  ),
  panelHeading: style(headingTitle, { marginBottom: px(16), textAlign: 'center' }),
  signUpHeading: style({
    maxWidth: px(321),
    marginLeft: 'auto',
    marginRight: 'auto',
    color: rebrand.contrast[50].toString(),
    fontFamily: fontstackDisplay,
  }),
  signUpPanel: style({
    textAlign: 'center',
    color: rebrand.contrast[40].toString(),
    $nest: {
      '& a': {
        textDecoration: 'underline',
        $nest: {
          '&:hover, &:focus': {
            opacity: 0.8,
          },
        },
      },
    },
  }),
  logInPrompt: style({
    marginBottom: px(22),
    fontSize: px(12),
    $nest: {
      '& a': {
        fontWeight: 'bold',
        color: 'inherit',
      },
    },
  }),
  balloon: style(flexRoot, {
    fontSize: px(12),
    maxWidth: px(330),
    padding: px(16),
    margin: '0 auto',
  }),
  balloonIcon: style({
    flexShrink: 0,
    marginRight: px(8),
  }),
  balloonText: style({
    textAlign: 'left',
    margin: 0,
    $nest: {
      '& strong': {
        color: rebrand.contrast[50].toString(),
      },
    },
  }),
  collapsible: style({
    maxWidth: px(504),
    margin: '0 auto',
  }),
  authority: style(flexRoot, betweenJustified, {
    width: px(116),
    margin: '32px auto 16px',
  }),
  trustPilotLogo: style({
    height: px(24),
  }),
  altText: style(screenReaderOnly),
};

const sendClickLoginEvent = (analytics: AnalyticsController) =>
  analytics.sendMixpanel('User clicks log in prompt', { path: '/sign-up' });

@inject(deps)
@observer
export class SignUp extends Component<ComponentProps> {
  render() {
    const {
      controller: { analytics },
    } = this.props;
    return (
      <div>
        <RegistrationProgress step={0} />
        <div className={styles.panels}>
          <div className={styles.signUpPanel}>
            <h1 className={classes(styles.panelHeading, styles.signUpHeading)}>Ready for better music?</h1>
            <p>Create your Lickd account</p>
            <SignUpForm />
            <p className={styles.logInPrompt}>
              Or{' '}
              <a href={LOG_IN_ROUTE} onClick={() => sendClickLoginEvent(analytics)}>
                log in
              </a>{' '}
              here
            </p>
            <Balloon className={styles.balloon}>
              <SVGIcon.InfoCircle className={styles.balloonIcon} size={20} color={colorBlack.toHexString()} />
              <p className={styles.balloonText}>
                <strong>Why Google?</strong> Signing up with Google makes it easier to connect and link your YouTube
                channel to access the best music for your videos.
              </p>
            </Balloon>
            <div className={styles.authority}>
              <SVGIcon.YouTubeCertified color={colorGunmetal.toHexString()} />
              <img className={styles.trustPilotLogo} src={TrustPilot} alt="Lickd has 4 out of 5 stars on Trustpilot" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
