import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const StarCircle: React.FC<SVGIconProps> = ({ size = 12, color = '#2E2E3B', ...props }) => (
  <BaseSvg
    data-test-premium-star-circle
    width={size}
    height={Math.round(size * (20 / 21))}
    viewBox="0 0 21 20"
    {...props}
  >
    <path
      d="M10.49 0C4.97 0 0.5 4.48 0.5 10C0.5 15.52 4.97 20 10.49 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.49 0ZM14.73 16L10.5 13.45L6.27 16L7.39 11.19L3.66 7.96L8.58 7.54L10.5 3L12.42 7.53L17.34 7.95L13.61 11.18L14.73 16Z"
      fill={color}
    />
  </BaseSvg>
);
