import { APIController } from '../api/controller';
import { UserModel } from '../user/model';
import { BugsnagController } from '../bugsnag/controller';
import { EnvModel } from '../env/model';
import { action, IReactionDisposer, observable, reaction, runInAction } from 'mobx';
import { ArtistlistModel } from './model';
import { ClientController } from '../client/controller';
import { UserController } from '../user/controller';

export class ArtistlistController {
  constructor(
    private model: ArtistlistModel,
    private env: EnvModel,
    private user: UserModel,
    private client: ClientController,
    private api: APIController,
    private bugsnag: BugsnagController,
    private userController: UserController
  ) {
    this._disposers = [];

    this.model.sync[this.env.artistlistFavourites] = observable([]);
    this.model.artists[this.env.artistlistFavourites] = observable([]);
    this.model.removed[this.env.artistlistFavourites] = observable([]);

    this._disposers.push(
      reaction(
        () => this.user.favouriteArtists,
        (favouriteArtists) => {
          runInAction(() => {
            this.model.artists[this.env.artistlistFavourites].splice(
              0,
              this.model.artists[this.env.artistlistFavourites].length,
              ...favouriteArtists.map((t) => t.identity)
            );
          });
        }
      )
    );
  }

  private _disposers: IReactionDisposer[];

  dispose = () => {
    this._disposers.forEach((dispose) => dispose());
  };

  @action
  addToArtistlist = async (artistlist: string, artist: string) => {
    if (!this.user.user) return;

    this.setSync(artistlist, artist, true);

    try {
      await this.api.artistlist.addArtistToArtistlist(this.user.user.identity, artistlist, artist);

      this.setActive(artistlist, artist, true);
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      this.setSync(artistlist, artist, false);
    }
  };

  @action
  removeFromArtistlist = async (artistlist: string, artist: string) => {
    if (!this.user.user) return;

    this.setSync(artistlist, artist, true);

    try {
      await this.api.artistlist.removeArtistFromArtistlist(this.user.user.identity, artistlist, artist);

      this.setActive(artistlist, artist, false);
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      this.setSync(artistlist, artist, false);
    }
  };

  @action
  favourite = async (artist: string) => {
    await this.addToArtistlist(this.env.artistlistFavourites, artist);
    await this.userController.fetchFavouriteArtists();
  };

  @action
  unfavourite = async (artist: string) => {
    await this.removeFromArtistlist(this.env.artistlistFavourites, artist);
    await this.userController.fetchFavouriteArtists();
  };

  @action
  private setSync = (artistlist: string, artist: string, sync: boolean) => {
    if (!this.model.sync[artistlist]) {
      this.model.sync[artistlist] = observable([]);
    }

    if (sync) {
      this.model.sync[artistlist].push(artist);
    } else {
      const index = this.model.sync[artistlist].indexOf(artist);

      if (index !== -1) {
        this.model.sync[artistlist].splice(index, 1);
      }
    }
  };

  @action
  private setActive = (artistlist: string, artist: string, active: boolean) => {
    if (!this.model.artists[artistlist]) {
      this.model.artists[artistlist] = observable([]);
    }

    if (!this.model.removed[artistlist]) {
      this.model.removed[artistlist] = observable([]);
    }

    if (active) {
      this.model.artists[artistlist].push(artist);

      const index = this.model.removed[artistlist].indexOf(artist);

      if (index !== -1) {
        this.model.removed[artistlist].splice(index, 1);
      }
    } else {
      this.model.removed[artistlist].push(artist);

      const index = this.model.artists[artistlist].indexOf(artist);

      if (index !== -1) {
        this.model.artists[artistlist].splice(index, 1);
      }
    }
  };
}
