import { computed, observable } from 'mobx';
import { ReleaseModel } from '../../../components/release';
import { ArtistSchema, ReleaseSchema, TagSchema, TrackSchema } from '../../../types/schema';
import { PaginationMeta } from '../../../types';

export class ArtistPageModel {
  @observable
  slug: string;

  @observable
  isMissing = false;

  @observable
  loading: boolean;

  @observable
  isSingle: boolean;

  @observable
  pageBody: string;

  @observable
  requests = 0;

  @observable
  artist: ArtistSchema;

  @observable
  release: string;

  @observable
  releaseIdentity: string;

  @observable
  activeRelease: ReleaseSchema;

  @observable
  releasePage: number;

  @observable
  releases: Array<ReleaseSchema>;

  @observable
  otherReleases: Array<ReleaseSchema>;

  @observable
  tracks: Array<TrackSchema>;

  @observable
  misc: Array<TrackSchema>;

  @observable
  releaseModels: { [releaseIdentity: string]: ReleaseModel } = {};

  @observable
  pagination: PaginationMeta;

  @observable
  similarArtists: Array<ArtistSchema>;

  @observable
  loadingSimilarArtists: boolean;

  @observable
  similarArtistsPagination: PaginationMeta;

  @observable
  soundsLike: Array<TagSchema> = [];

  @observable
  loadingSoundsLike: boolean;

  @computed
  get soundsLikeArtists(): Array<string> {
    return this.soundsLike.map(({ tag }) => tag);
  }

  @observable
  showBio = false;

  @computed get bioModal() {
    return {
      show: this.showBio,
    };
  }
}
