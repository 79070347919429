import { User, UserTrackMeta } from './user';
import { isObservableArray } from 'mobx';
import { SubscriptionStates, CreditPlanTypes } from '../components/project-happy/utilities/types';
import { isEmpty } from '../components/project-happy/utilities/objects';

export interface HighlightSchema<T> {
  key: keyof T | string;
  value: string;
}

export interface ArtistSchema {
  birthday?: string;
  formed?: string;
  hometown?: string;
  is_solo_performer?: number;
  identity: string;
  name: string;
  description: string;
  is_featured: number;
  slug: string;
  link?: {
    details: string;
    similar: string;
    releases: string;
    tracks: string;
  };
  images: ImageSchema;
  releases?: Array<ReleaseSchema>;
  tracks?: Array<TrackSchema>;
  user?: UserTrackMeta;
  highlight?: Array<HighlightSchema<ArtistSchema>>;
}

export interface ChannelSubscriptionSchema {
  plan_name: CreditPlanTypes;
  stripe_price_id: string;
}

export interface ActiveSubscriptionSchema extends ChannelSubscriptionSchema {
  renewal_date: string;
}

export interface PendingSubscriptionSchema extends ChannelSubscriptionSchema {
  start_date: string;
}

export interface ChannelSchema {
  id: string;
  name: string;
  country: string;
  referrer_url: string;
  published_at: DateSchema;
  description: string;
  images?: Array<{ size: string; url: string }>;
  statistics?: Array<ChannelStatisticsSchema>;
  ratecard?: RatecardSchema;
  ratecard_statistics?: RatecardStatisticsSchema;
  is_subscribed: boolean;
  subscription_status: SubscriptionStates | null;
  channel_category: string | null;
  stripe_subscription_id: string | null;
  credits_available: number;
  subscription: ActiveSubscriptionSchema | null;
  pending_subscription: PendingSubscriptionSchema | null;
}

export interface ChannelStatisticsSchema {
  average_comments: number;
  average_views: number;
  comment_count: number;
  subscriber_count: number;
  video_count: number;
  view_count: number;
  fetched_at: DateSchema;
}

export interface RatecardStatisticsSchema {
  average_views: number;
  period: {
    ends: DateSchema;
    starts: DateSchema;
  };
  video_count: number;
  view_count: number;
}

export interface ClaimSchema {
  youtube_video_id: string;
  claim_id: string;
  video_id: string;
  channel_id: string;
  channel: ChannelSchema;
  video: VideoSchema;
  licence: LicenceSchema;
  status?: string;
}

export interface DateSchema {
  date: string;
  timestamp: number;
  text?: string;
}

export interface ImageSchema {
  identity: string;
}

export interface ImageTypeSchema {
  id: number;
  name: string;
}

export interface EntityImageSchema {
  uuid: string;
  url: string;
}

export interface TypedEntityImageSchema extends EntityImageSchema {
  image_type: ImageTypeSchema;
  is_default: boolean;
}

export interface LicenceSchema {
  amount: number;
  brand_sponsored?: boolean;
  branded_content?: boolean;
  attribution?: string;
  date: DateSchema;
  discount_amount: number;
  eula_html_href?: string;
  identity: string;
  short_id: string;
  order?: OrderSchema;
  order_id: string;
  quantity: number;
  retail_amount: number;
  rate_card: RatecardSchema;
  rate_card_name?: string;
  rate_card_rate?: number;
  track: TrackSchema;
  link?: string;
  youtube_video_claims?: Array<ClaimSchema>;
}

export interface RatecardSchema {
  brand_sponsored?: boolean;
  branded_content?: boolean;
  currency: AcceptedCurrency;
  name: string;
  value: string;
  rate: number;
  viewership: number;
  country?: {
    id: string;
    name: string;
  };
}

export interface ReleaseSchema {
  identity: string;
  title: string;
  slug: string;
  type: string;
  release_date: DateSchema;
  images: ImageSchema;
  artist: ArtistSchema;
  tracks?: Array<TrackSchema>;
  c_line?: string;
}

export type VouchStatus = 'CLEARED' | 'OVERRIDDEN' | 'UNCLEARED' | 'UNMATCHED' | 'MANUAL';

export const isTrackSchema = (item: any): item is TrackSchema =>
  typeof item === 'object' && item !== null && 'tempo' in item;
export const isTrackArray = (item: any): item is TrackSchema[] => isObservableArray(item) && isTrackSchema(item[0]);

export const isPlaylistGroupPlaylistSchema = (item: any): item is PlaylistGroupPlaylistSchema =>
  typeof item === 'object' && item !== null && 'slug' in item && 'use_playlist_image' in item;

export const isArtistSchema = (item: any): item is ArtistSchema => !isEmpty(item) && 'is_solo_performer' in item;

export interface TrackSchema {
  identity: string;
  title: string;
  description?: string;
  slug: string;
  duration: number;
  is_featured: number | boolean;
  is_charting?: boolean;
  is_new_release?: boolean;
  is_stock_music?: boolean;
  mixes_count?: number;
  is_available_in_territory?: boolean;
  link?: {
    details: string;
    similar: string;
  };
  links?: {
    [key: string]: string;
    spotify_artist_url: string;
    spotify_track_url: string;
    spotify_release_url: string;
    hookd_artist_url: string;
  };
  bpm?: number;
  images: ImageSchema;
  artists: Array<ArtistSchema>;
  release?: ReleaseSchema;
  releases?: Array<ReleaseSchema>;
  audio: {
    url: string;
    identity: string;
  };
  tags?: {
    hookd_genre: Array<{
      slug: string;
      name: string;
    }>;
    hookd_theme: Array<{
      slug: string;
      name: string;
    }>;
    hookd_mood: Array<{
      slug: string;
      name: string;
    }>;
    musiio_mood: Array<{
      slug: string;
      name: string;
    }>;
    musiio_genre: Array<{
      type: string;
      name: string;
    }>;
  };
  created_at?: string;
  expires_at?: DateSchema;
  branded_content_allowed?: number;
  share_url?: string;
  user?: UserTrackMeta;
  tempo: string;
  restrictions?: string[];
  rightsholders?: Array<LabelSchema>;
  brand_sponsored?: boolean;
  branded_content?: boolean;
  audition_point?: number | null;
  basket?: BasketTrackItemMeta;
  highlight?: Array<HighlightSchema<TrackSchema>>;
  reporting_party?: ReportingPartySchema;
  is_available?: boolean;
  vouch_status: VouchStatus;
  available_at: string;
  mixes: Array<TrackSchema>;
  is_mix?: boolean;
  is_explicit: boolean;
}

export interface BasketTrackItemMeta {
  quantity: number;
  brand_sponsored: boolean;
  branded_content: boolean;
}

export interface VideoSchema {
  id: string;
  name: string;
  description: string;
  published_at: DateSchema;
  language: string | null;
  duration: string;
  dimension: string;
  images: Array<any>;
}

export interface CardSchema {
  identity: string;
  name: string;
  brand: string;
  last4: number;
  exp_month: number;
  exp_year: number;
  country: string;
  address: AddressSchema;
}

export interface AddressSchema {
  [field: string]: string;
  city: string;
  country: string;
  address1: string;
  address2?: string;
  postcode: string;
}

export interface OrderSchema {
  amount: OrderAmountSchema;
  channels: Array<string>;
  credits_used: number;
  date: DateSchema;
  identity: string;
  licences: Array<LicenceSchema>;
  locale: LocaleSchema;
  status: string;
  stripe: {
    charge_id: string;
    order_id: string;
    session_id: string;
  };
  user: User;
  youtube_channels?: Array<ChannelSchema>;
  stripeSessionId: string;
}

export interface OrderAmountSchema {
  card_fee: number;
  discount: number;
  total: number;
  vat: {
    amount: number;
    rate: number;
  };
}

export interface LocaleSchema {
  currency: string;
  country: string;
}

export interface UserPreferencesSchema {
  [key: string]: boolean;
  daily_newmusic_email: boolean;
  daily_recommendation_email: boolean;
  monthly_newmusic_email: boolean;
  monthly_recommendation_email: boolean;
  product_announcement_email: boolean;
  thirdparty_promotions_email: boolean;
  twitter_order_announcement: boolean;
  weekly_newmusic_email: boolean;
  weekly_recommendation_email: boolean;
}

export interface TagSchema {
  tag: string;
  slug: string;
  type: string;
}
export interface ReferrerSchema {
  name: string;
  avatar: string;
  success?: boolean;
}
export interface ArtistMappingSchema {
  genres: Array<string>;
  name: string;
  objectID: string;
  image: {
    width: number;
    height: number;
    url: string;
  };
}

export interface InviteSchema {
  name: string;
  email: string;
}

export interface PricingCalculatorSchema {
  avatar: string;
  currency: string;
  name: string;
  rate: string;
  viewership: number | string;
}

export interface VatSchema {
  address: string;
  country: string;
  name: string;
  number: string;
  valid: boolean;
}

export interface CouponSchema {
  coupon: string;
  currency: string;
  discount: string;
  track_discount: string;
  expires: DateSchema;
  limit: number;
  order: OrderSchema[];
  status: 'used' | 'unused';
  value: string;
  type: string;
}

export type AcceptedCurrency = 'GBP' | 'EUR' | 'USD';
export interface RatecardsSchema {
  currency: { [code in AcceptedCurrency]: Array<RatecardSchema> };
}

export interface LabelSchema {
  identity: string;
  name: string;
  slug: string;
  is_listed: boolean;
  blurb?: string;
  images?: {
    identity: string;
    copyright: string;
  };
}

export interface AccountSchema {
  id: string;
  name: string;
  nickname: string;
  avatar: string;
}

export interface PlaylistSchema {
  uuid: string;
  identity: string;
  sequence: number;
  name: string;
  slug: string;
  description: string;
  is_public: number;
  is_generated: boolean;
  is_stock: boolean;
  use_playlist_image: boolean;
  images?: {
    identity: string;
    copyright: string;
  };
  track_images?: ImageSchema[];
  track_exists?: boolean;
  user_uuid?: string;
}

export interface PlaylistGroupSchema {
  active: boolean;
  playlists: PlaylistGroupPlaylistSchema[];
  slug: string;
  title: string;
}

export interface PlaylistGroupPlaylistSchema {
  identity: string;
  uuid: string;
  name: string;
  slug: string;
  active: boolean;
  is_stock: boolean;
  track_images: ImageSchema[];
  images: ImageSchema;
  use_playlist_image: boolean;
}

export interface AggregatedTagSchema {
  tag: string;
  slug: string;
  type: string;
  source: string;
  counts: {
    artists: number;
    tracks: number;
  };
  images: {
    identity: string;
    copyright: string;
  };
}

export interface AudioMetaSchema {
  error: null | string;
  audio: string;
  duration: number;
  end: number;
  is_blocked: boolean;
  is_restricted: boolean;
  playlist?: string;
  ratelimit_limit: number;
  ratelimit_remaining: number;
  retry_after?: number;
  start: number;
  type: string;
  waveform: string;
}

export interface RightsholderSchema {
  active: boolean;
  brand_sponsored: boolean;
  branded_content: boolean;
  counts: {
    track: number;
  };
  created_at: string;
  description: string | null;
  identity: string;
  images: {
    identity: string;
  };
  name: string;
  slug: string;
  video_platforms: TrackVideoPlatforms;
}

export interface ReportingPartySchema {
  name: string;
  slug: string;
}

export interface TrackVideoPlatforms {
  dailymotion: boolean | null;
  facebook: boolean | null;
  instagram: boolean | null;
  linkedin: boolean | null;
  snapchat: boolean | null;
  twitch: boolean | null;
  twitter: boolean | null;
  vimeo: boolean | null;
  youtube: boolean | null;
}

export interface ClaimIssueSchema {
  id: number;
  ticket_id: number;
  ticket_status: 'created' | 'in_progress' | 'resolved' | 'closed';
}

export interface SubscriptionInformationSchema {
  id: number;
  start_date: string;
  end_date: string;
  status: SubscriptionStates;
}

export type WithInclude<Type, Key extends string, Include> = Type &
  {
    [key in Key]: Include;
  };

export interface CreditSchema {
  youtube_channel_id: string;
  youtube_channel_name: string;
  created_at: string;
  expires_at: string;
}
