import React from 'react';
import { style } from 'typestyle';
import { Button } from './Button';
import { ArrowLeft } from '../icons/ArrowLeft';
import { ButtonProminence, ButtonSize } from './buttonStyles';
import { spacing } from '../../../../theme/spacing';

const styles = {
  backBtnWrapper: style({
    marginBottom: spacing.XLARGE,
  }),
  backBtn: style({
    display: 'inline-flex',
    alignItems: 'center',
    gap: spacing.XSMALL,
  }),
};

interface BackButtonProps {
  href: string;
  children: React.ReactNode;
}

export const BackButton: React.FC<BackButtonProps> = ({ href, children, ...props }) => {
  return (
    <div className={styles.backBtnWrapper}>
      <Button
        href={href}
        prominence={ButtonProminence.SECONDARY}
        size={ButtonSize.MEDIUM}
        className={styles.backBtn}
        {...props}
      >
        <ArrowLeft />
        {children}
      </Button>
    </div>
  );
};
