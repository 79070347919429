import React from 'react';
import { ComponentProps, deps, inject, observer } from '../../../../lib/component';
import { classes, style } from 'typestyle';
import { mediaDesktop } from '../../utilities/mediaQueries';
import { placeholderPlans } from '../../organisms/plans-v2/Plans';
import { ActionButton } from '../../atoms/button/ActionButton';
import { ButtonProminence } from '../../atoms/button/buttonStyles';
import { PlanPlaceholder } from './PlanPlaceholder';

interface PaymentPlansPlaceholderProps extends ComponentProps {
  className?: string;
}

const styles = {
  connectChannelBtn: style(
    {
      margin: '0 auto',
    },
    mediaDesktop({
      position: 'absolute',
      right: 'auto',
      bottom: 0,
      left: 'auto',
      zIndex: 2,
    })
  ),
};

/** Placeholder plans shown to users who haven't connected a channel yet */
export const PaymentPlansPlaceholder = inject(deps)(
  observer(
    ({
      controller: {
        modal: { showChannelAddModal },
        analytics: { sendMixpanel },
      },
      model: {
        router: {
          location: { pathname },
        },
      },
      className,
    }: PaymentPlansPlaceholderProps) => {
      const handleClickConnectChannel = () => {
        sendMixpanel('User clicks connect channel', {
          route: pathname,
        });

        showChannelAddModal();
      };

      return (
        <div className={className ? className : undefined}>
          {placeholderPlans.map((plan) => {
            return <PlanPlaceholder key={plan.name} planName={plan.name} premiumCredits={plan.premiumCredits} />;
          })}

          <ActionButton
            className={classes(styles.connectChannelBtn)}
            prominence={ButtonProminence.PRIMARY}
            onClick={handleClickConnectChannel}
            data-test-plans-page-connect-channel
          >
            Connect your channel
          </ActionButton>
        </div>
      );
    }
  )
);
