import { observable, computed, runInAction } from 'mobx';
import { PlayerModel } from '../player/model';
import { defaultDescription, defaultTitle } from '../../lib/ssr';
import { createSimpleSchema, list, object, serializable, update } from 'serializr';
import { OnboardingModel } from '../onboarding/model';
import { AuthModel } from '../auth/model';
import { SessionStorageModel, StorageModel } from '../storage/model';
import { RouterModel } from '../router/model';
import { ContentModel } from '../content/model';
import { ModalModel } from '../modal/model';
import { UIBreadcrumb, UIOpenGraph } from '../../types/ui';
import { constantTabletBreakpoint } from '../../theme/constant';
import { EnvModel } from '../env/model';
import { SEARCH_ROUTE } from '../../constants';
import { PROJECT_SUBSCRIPTION_PREVIEW } from '../../components/devtools/feature-flags';
import { UserModel } from '../user/model';

const BreadcrumbsSchema = createSimpleSchema({
  label: true,
  path: true,
});

const OpenGraphSchema = createSimpleSchema({
  title: true,
  description: true,
  type: true,
  image: true,
  url: true,
});

export class UIModel {
  constructor(
    private player: PlayerModel,
    private onboarding: OnboardingModel,
    private auth: AuthModel,
    private storage: StorageModel,
    private env: EnvModel,
    private router: RouterModel,
    private content: ContentModel,
    private modal: ModalModel,
    private user: UserModel
  ) {
    this.loadInitial();
  }

  loadInitial = () => {
    if (!this.isServer) {
      const { initialUIModel } = window as any;
      if (initialUIModel)
        runInAction(() => {
          update(this, initialUIModel);
        });
    }
  };

  @serializable
  @observable
  description: string = defaultTitle;

  @serializable
  @observable
  title: string = defaultDescription;

  @serializable(list(object(BreadcrumbsSchema)))
  @observable
  breadcrumbs: Array<UIBreadcrumb> = [];

  @computed
  get showBreadcrumbs(): boolean {
    if (!this.breadcrumbs || this.breadcrumbs.length === 0) return false;

    if (this.breadcrumbs.length > 1) return true;

    const root = this.breadcrumbs[0];

    switch (true) {
      case root.path === '/':
      case root.path === '/browse':
      case root.path === SEARCH_ROUTE:
      case root.path.startsWith('/account'):
        return false;

      default:
        return true;
    }
  }

  @observable
  authWindow = false;

  @serializable(object(OpenGraphSchema))
  @observable
  opengraph: UIOpenGraph = {};

  @computed
  get isServer(): boolean {
    return this.env.isServer;
  }

  @observable
  imageLoadIndex = 0;

  @observable
  firstPaint = true;

  @observable
  resultsOverlay = false;

  @observable
  showBasketPopover = false;

  @observable
  showAccountPopover = false;

  @observable
  showSearchPopover = false;

  @serializable
  @observable
  showSearchDrawer = false;

  @observable
  showMenuDrawer = false;

  @observable
  showSignUpModal = false;

  @observable
  forceSolidHeader = false;

  @observable
  scrollingOverride = false;

  @observable
  showChrome = true;

  @computed
  get isAdvanced(): boolean {
    if (!this.router.location) return false;

    return this.router.location.pathname.startsWith(SEARCH_ROUTE);
  }

  @computed
  get showPlayer(): boolean {
    return !!this.player.currentTrack;
  }

  @computed
  get solidHeader(): boolean {
    return true;
  }

  @observable
  window: UIModelWindow = {
    height: 0,
    width: 0,
  };

  @computed
  get windowSizeHash() {
    return this.window.width;
  }

  @computed
  get isMobile() {
    return this.window.width < constantTabletBreakpoint;
  }

  @computed
  get showAnyModals(): boolean {
    return this.modal.showAny || this.showSignUpModal;
  }

  @computed
  get preventScrolling() {
    return (
      this.showMenuDrawer ||
      this.showAnyModals ||
      (this.showSearchDrawer && this.isMobile) ||
      (this.auth.hasFetched && this.onboarding.showAny) ||
      (this.isMobile && (this.showAccountPopover || this.showBasketPopover)) ||
      this.scrollingOverride
    );
  }

  @computed
  get hideHubspotChat() {
    return (
      this.isMobile &&
      (this.showSearchDrawer || this.showMenuDrawer || this.showBasketPopover || this.showAccountPopover)
    );
  }

  @computed
  get showSignUpCTA() {
    return false;
    // return !this.isServer
    //   && this.auth.hasFetched
    //   && !this.auth.user
    //   && !(this.storage.getItem('hideSignUpCTA') === '1')
  }

  @computed
  get showJustAdded(): boolean {
    return this.showChrome && !/^\/checkout/.test(this.router.location.pathname);
  }

  @computed
  get cookieNotification() {
    return {
      show:
        this.content.cookieNotificationTitle &&
        this.content.cookieNotificationContent &&
        !(this.storage.getItem('hideCookieNotification') === '1'),
      title: this.content.cookieNotificationTitle,
      content: this.content.cookieNotificationContent,
    };
  }

  @computed
  get projectSubscriptionEnabled(): boolean {
    return this.user.isSubscribedToV2Plan || SessionStorageModel.getItem(PROJECT_SUBSCRIPTION_PREVIEW) || false;
  }
}

export interface UIModelWindow {
  height: number;
  width: number;
}
