import * as React from 'react';
import { AnyObject } from './objects';

export const AnalyticsContext = React.createContext({});

type AnalyticsContextProviderProps = {
  context: AnyObject;
};

const contextMap = new WeakMap();

export class AnalyticsContextProvider extends React.Component<AnalyticsContextProviderProps> {
  contextRef: React.RefObject<never>;

  constructor(props) {
    super(props);

    this.contextRef = React.createRef();
  }

  render() {
    const { children, context: childContext } = this.props;
    return (
      <AnalyticsContext.Consumer>
        {(parentContext) => {
          if (!contextMap.has(this.contextRef)) {
            const context = { ...parentContext, ...childContext };
            contextMap.set(this.contextRef, context);
          }
          return (
            <AnalyticsContext.Provider value={contextMap.get(this.contextRef)}>{children}</AnalyticsContext.Provider>
          );
        }}
      </AnalyticsContext.Consumer>
    );
  }
}
