import * as React from 'react';
import { observer } from 'mobx-react';
import { classes, style } from 'typestyle';
import { TrackSchema } from '../../../types/schema';
import { TrackLine, TrackLineProps } from '../molecules/TrackLine';
import isEmpty from 'lodash/isEmpty';
import { colorLightBlueGrey, colorLightGrey, colorNeutral, colorWhite, rebrand } from '../../../theme/color';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { Album } from '../atoms/icons/Album';
import { HideOnError } from '../atoms/HideOnError';
import { px } from 'csx';
import { headerFullHeight } from '../config';
import { scrollToTop } from '../utilities/page';
import { LoadMoreArrowButton } from '../atoms/button/LoadMoreArrow';
import ScrollToTopButton from '../atoms/button/ScrollToTopArrowButton';
import ClientOnly from '../atoms/ClientOnly';
import { spacing } from '../../../theme/spacing';

const styles = {
  container: style({ maxWidth: '992px' }),
  trackLineWrapper: style({
    display: 'grid',
    maxWidth: '992px',
    borderBottom: `1px solid ${colorLightGrey.toString()}`,
    backgroundColor: colorWhite.toHexString(),
  }),
  hideDesktop: style(mediaDesktop({ display: 'none' })),
  hideMobile: style(mediaUpToDesktop({ display: 'none' })),
  tableHeader: style(
    mediaDesktop({
      gridTemplateColumns: `56px 23.5% 11% 11.5% 10.5% 6.5% 4.5% auto min(160px, 19.2%)`,
      gap: '8px',
    }),
    mediaUpToDesktop({
      gridTemplateColumns: '64px auto',
      $nest: {
        '& > :nth-child(n + 3)': {
          display: 'none',
        },
      },
    }),
    {
      display: 'grid',
      alignItems: 'center',
      maxWidth: '992px',
      backgroundColor: colorLightBlueGrey.toHexString(),
      borderBottom: `1px solid ${colorLightGrey.toString()}`,
      $nest: {
        '& p': {
          fontSize: '12px',
          fontWeight: 700,
          margin: '12px 0px',
        },
        '& svg': {
          marginLeft: '21.5px',
        },
      },
    }
  ),
  btnContainer: style(
    mediaUpToDesktop({
      padding: '0 16px',
    }),
    {
      display: 'flex',
      gap: spacing.DEFAULT,
      marginTop: spacing.DEFAULT,
    }
  ),
  loadMore: style({
    display: 'flex',
    alignItems: 'center',
    color: rebrand.contrast[50].toString(),
    fontWeight: 400,
    fontSize: px(16),
  }),
  trackCount: style({
    fontSize: '12px',
    margin: '0 0 0 auto',
    color: colorNeutral.toHexString(),
  }),
};

type TrackLineEvents = Pick<TrackLineProps, 'onClickLink' | 'onAddTrackToPlaylist' | 'onRemoveTrackFromPlaylist'>;

export interface ControlledTrackListProps extends Partial<TrackLineEvents> {
  tracks: Array<TrackSchema>;
  totalTracks: number;
  onLoadMore(): void;
  className?: string;
  playerIsShowing?: boolean;
}

@observer
export class ControlledTrackList extends React.Component<ControlledTrackListProps> {
  state = {
    minimumTrackCount: 0,
    isLoadingMore: false,
  };

  constructor(props) {
    super(props);
    this.state.minimumTrackCount = this.props.tracks.length;

    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  componentDidUpdate() {
    if (this.state.minimumTrackCount > this.props.tracks.length) {
      this.setState({ minimumTrackCount: this.props.tracks.length });
    }
  }

  async handleLoadMore() {
    this.setState({ isLoadingMore: true });
    await this.props.onLoadMore();
    this.setState({ isLoadingMore: false });
  }

  render() {
    const {
      tracks,
      totalTracks,
      onClickLink,
      onAddTrackToPlaylist,
      onRemoveTrackFromPlaylist,
      className,
      playerIsShowing,
    } = this.props;
    const { minimumTrackCount, isLoadingMore } = this.state;

    return (
      <div className={classes(styles.container, className)}>
        {!isEmpty(tracks) && (
          <div>
            <div className={styles.tableHeader} data-test-track-list-header>
              <Album size={13} />
              <p data-test-track-list-header-title>Title</p>
              <p data-test-track-list-header-album>Album</p>
              <p data-test-track-list-header-genre>Genre</p>
              <p data-test-track-list-header-mood>Mood</p>
              <p data-test-track-list-header-duration>Duration</p>
              <p data-test-track-list-header-plan>Catalogue</p>
            </div>
            {tracks.map((track, index) => (
              <HideOnError key={index}>
                <div className={styles.trackLineWrapper}>
                  <TrackLine
                    track={track}
                    onClickLink={onClickLink}
                    onAddTrackToPlaylist={onAddTrackToPlaylist}
                    onRemoveTrackFromPlaylist={onRemoveTrackFromPlaylist}
                  />
                </div>
              </HideOnError>
            ))}

            <div className={classes(styles.btnContainer)}>
              {minimumTrackCount < totalTracks && (
                <LoadMoreArrowButton
                  hidden={tracks.length === totalTracks}
                  handleLoadMore={this.handleLoadMore}
                  loading={isLoadingMore}
                />
              )}
              <span className={styles.trackCount}>
                Showing {tracks.length} of {totalTracks}
              </span>
            </div>
          </div>
        )}

        <ClientOnly>
          <ScrollToTopButton scrollTop={scrollToTop} playerIsShowing={playerIsShowing} />
        </ClientOnly>
      </div>
    );
  }
}
