import { action, runInAction } from 'mobx';
import { RouterState, RedirectFunction } from 'react-router';
import { AccountInvitesPageModel } from './model';
import { APIController } from '../../api/controller';
import { UserModel } from '../../user/model';
import { PrismicController } from '../../prismic/controller';
import { BugsnagController } from '../../bugsnag/controller';
import { UIController } from '../../ui/controller';
import { EnvModel } from '../../env/model';
import { ContentModel } from '../../content/model';

export class AccountInvitesPageController {
  constructor(
    private model: AccountInvitesPageModel,
    private user: UserModel,
    private api: APIController,
    private prismic: PrismicController,
    private bugsnag: BugsnagController,
    private ui: UIController,
    private env: EnvModel,
    private content: ContentModel,
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    runInAction(() => {
      this.model.loading = true;
    });

    await this.ui.setSEO(nextState.location.pathname);

    this.ui.setBreadcrumbs([
      {
        path: '/account',
        label: 'Account'
      },
      {
        path: '/account/invites',
        label: 'Refer a friend'
      }
    ]);

    try {
      const [invites] = await Promise.all([
        this.api.user.getInvites(this.user.user.identity),
        this.env.ready
      ]);

      runInAction(() => {
        this.model.invites = invites.data;
        this.model.title = this.content.referralTitle;
        this.model.content = this.content.referralContent;
        this.model.cta = this.content.referralCTA;
      });
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      runInAction(() => {
        this.model.loading = false;
      });
    }

    return;
  };
}
