import { computed, observable, ObservableMap } from 'mobx';
import { TrackSchema } from '../../types/schema';
import { SlicePageContext, PrismicSlice } from '../project-happy/utilities/types';
import { AnyObject } from '../project-happy/utilities/objects';

const emptyMap = observable.map();

export class LanderModel {
  @observable
  playlists: ObservableMap<string, TrackSchema[]>;

  @observable
  slices: PrismicSlice[] = [];

  @observable
  metadata: AnyObject = {};

  @computed get context(): SlicePageContext {
    const { playlists } = this;
    return { playlists, tracks: emptyMap as ObservableMap<string, TrackSchema> };
  }
}
