import * as React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalChildStyle} from './modal';
import {classes, style} from 'typestyle';
import {colorSubtle, colorWhite} from '../theme/color';
import {SVGIcon} from './svg-icon';
import {TextNoOrphan} from './text-no-orphan';
import {em, px} from 'csx';
import {Logo} from './logo';
import {center, centerCenter, horizontal, horizontallySpaced, vertical, verticallySpaced} from 'csstips';
import GoogleSignIn from './user/google-sign-in';
import getCookieMap from '../lib/getCookieMap';
import {
  JAACKMAATE_UTM_CAMPAIGN,
  JAACKMAATE_UTM_MEDIUM,
  JAACKMAATE_UTM_SOURCE,
  VIDSUMMIT_UTM_CAMPAIGN,
  VIDSUMMIT_UTM_MEDIUM,
  VIDSUMMIT_UTM_SOURCE,
  BLOGOSPHERE_UTM_CAMPAIGN,
  BLOGOSPHERE_UTM_MEDIUM,
  BLOGOSPHERE_UTM_SOURCE,
  VIDCON_UTM_CAMPAIGN,
  VIDCON_UTM_MEDIUM,
  VIDCON_UTM_SOURCE
} from '../constants';

export interface OnboardingSignUpModalModel {
  show: boolean;
  youtubeLoginURL: string;
}

export interface OnboardingSignUpModalProps {
  model: OnboardingSignUpModalModel;

  onClickSignUp(event: React.MouseEvent<HTMLAnchorElement>): void;

  onRequestClose(): void;

  onClickTermsConditions(event: React.MouseEvent<any>): void;

  onClickPrivacyPolicy(event: React.MouseEvent<any>): void;

  i18n?: {
    title: string;
    jaackMaateTitle: string;
    vidsummitTitle: string;
    blogosphereTitle: string;
    vidconTitle: string;
    google: string;
    termsConditions: string;
    privacyPolicy: string;
    benefits: Array<{
      heading: string;
      content: string;
    }>;
  }
}

export interface OnboardingSignUpModalState {
  show: { [index: number]: boolean };
  isJaackMaate: boolean;
  isVidsummit: boolean;
  isBlogosphere: boolean;
  isVidcon: boolean;
}

@observer
export class OnboardingSignUpModal extends React.Component<OnboardingSignUpModalProps, OnboardingSignUpModalState> {
  state = {
    show: {},
    isJaackMaate: false,
    isVidsummit: false,
    isBlogosphere: false,
    isVidcon: false,
  };

  getTitle(i18n) {
    if (this.state.isJaackMaate) {
      return i18n.jaackMaateTitle;
    }

    if (this.state.isVidsummit) {
      return OnboardingSignUpModal.i18n.vidsummitTitle;
    }

    if (this.state.isBlogosphere) {
      return OnboardingSignUpModal.i18n.blogosphereTitle;
    }

    if (this.state.isVidcon) {
      return OnboardingSignUpModal.i18n.vidconTitle;
    }

    return i18n.title;
  }

  render() {
    const {model, onRequestClose, onClickTermsConditions, onClickPrivacyPolicy, i18n = OnboardingSignUpModal.i18n} = this.props;

    const title = this.getTitle(i18n)

    return (
      <Modal show={model.show} onRequestClose={!this.state.isVidsummit ? onRequestClose : null}>
        {model.show && (
          <div className={OnboardingSignUpModal.styles.container}>
            <h3>
              <TextNoOrphan value={title}/>
            </h3>
            <ul className={OnboardingSignUpModal.styles.list}>
              {i18n.benefits.map((benefit, index) => (
                <li key={index.toString()}
                    className={classes(OnboardingSignUpModal.styles.item, this.state.show[index] && OnboardingSignUpModal.styles.itemVisible)}>
                  <div onClick={this.toggle.bind(this, index)}>
                    <TextNoOrphan value={benefit.heading}/>
                    <SVGIcon.ArrowRight size={10} color={colorSubtle.toString()}/>
                  </div>
                  <p>
                    <TextNoOrphan value={benefit.content}/>
                  </p>
                </li>
              ))}
            </ul>
            <GoogleSignIn/>
            <div className={OnboardingSignUpModal.styles.legals}>
              <a onClick={onClickTermsConditions} href={'/terms-and-conditions'}>{i18n.termsConditions}</a>
              <a onClick={onClickPrivacyPolicy} href={'/privacy-policy'}>{i18n.privacyPolicy}</a>
            </div>
            <div className={OnboardingSignUpModal.styles.authority}>
              <Logo/>
              <SVGIcon.YouTubeCertified/>
            </div>
          </div>
        )}
      </Modal>
    )
  }

  componentDidMount() {
    const cookies = getCookieMap(document.cookie);

    if (cookies['utm_campaign'] === JAACKMAATE_UTM_CAMPAIGN
      && cookies['utm_medium'] === JAACKMAATE_UTM_MEDIUM
      && cookies['utm_source'] === JAACKMAATE_UTM_SOURCE) {
      this.setState({isJaackMaate: true});
    }

    if (cookies['utm_campaign'] === VIDSUMMIT_UTM_CAMPAIGN
        && cookies['utm_medium'] === VIDSUMMIT_UTM_MEDIUM
        && cookies['utm_source'] === VIDSUMMIT_UTM_SOURCE) {
      this.setState({isVidsummit: true});
    }

    if (cookies['utm_campaign'] === BLOGOSPHERE_UTM_CAMPAIGN
        && cookies['utm_medium'] === BLOGOSPHERE_UTM_MEDIUM
        && cookies['utm_source'] === BLOGOSPHERE_UTM_SOURCE) {
      this.setState({isBlogosphere: true});
    }

    if (cookies['utm_campaign'] === VIDCON_UTM_CAMPAIGN
        && cookies['utm_medium'] === VIDCON_UTM_MEDIUM
        && cookies['utm_source'] === VIDCON_UTM_SOURCE) {
      this.setState({isVidcon: true});
    }
  }

  toggle = (index: number) => this.setState(state => ({
    ...state,
    show: {
      ...state.show,
      [index]: !state.show[index]
    }
  }));

  static i18n: OnboardingSignUpModalProps['i18n'] = {
    title: 'Signing up to Lickd is easy',
    jaackMaateTitle: 'Welcome to Lickd! Sign up to get your two free tracks courtesy of JaackMaate.',
    vidsummitTitle: 'We hope you enjoyed VidSummit, sign in to get your 5 free tracks!',
    blogosphereTitle: 'We hope you enjoyed Blogosphere, sign in to get your 5 free tracks!',
    vidconTitle: 'We hope you enjoyed London VidCon, sign in to claim your 5 free tracks!',
    google: 'Continue with Google',
    termsConditions: 'Terms & conditions',
    benefits: [
      {
        heading: 'Discover great content curated for you',
        content: 'Collections based on your YouTube channel theme or your linked Spotify account.'
      },
      {
        heading: 'Claim & dispute free YouTube experience',
        content: 'Our VOUCH platform handles releasing claims on your video automatically, meaning you never even see content ID disputes.'
      },
      {
        heading: 'Help us change the music industry for the better',
        content: 'Not only can you legitimately use the music you love, you are part of a movement to put music in the hands of the people and help musicians thrive.'
      },
      {
        heading: 'Receive offers of free tracks and personal recommendations',
        content: 'We will contact you when we have new releases that fit your channel and personal listening habits and when we have free tracks to offer you. By signing up to the Lickd you agree to receive these occasional emails. You can unsubscribe at any time.'
      },
    ],
    privacyPolicy: 'Privacy policy'
  };

  static styles = {
    container: style({
      width: px(420),
      maxWidth: 'calc(100vw - 4rem)',
      ...ModalChildStyle,
      ...center,
    }),
    paragraph: style({
      margin: 0
    }),
    list: style({
      margin: 0,
      padding: 0,
      listStyle: 'none inside none',
    }),
    item: style({
      margin: '0 0 20px 0',
      $nest: {
        '& div': {
          position: 'relative',
          padding: '0 20px 0 0',
          cursor: 'pointer',
          margin: 0,
          fontWeight: 'bold',
          fontSize: px(16),
          lineHeight: em(1.2)
        },
        '& div svg': {
          position: 'absolute',
          top: px(4),
          right: 0,
          transform: 'rotate(0deg)'
        },
        '& p': {
          display: 'none',
          margin: '5px 0 0 0',
          lineHeight: em(1.4),
          fontSize: px(14)
        }
      }
    }),
    itemVisible: style({
      $nest: {
        '& p': {
          display: 'block'
        },
        '& div svg': {
          transform: 'rotate(90deg)'
        },
      }
    }),
    legals: style({
      ...vertical,
      ...verticallySpaced(10),
      ...centerCenter,
      margin: '20px 0 0 0',
      $nest: {
        '& a': {
          fontSize: px(14),
          lineHeight: em(1.1)
        }
      }
    }),
    authority: style({
      ...centerCenter,
      ...horizontal,
      ...horizontallySpaced(30),
      margin: '20px 0 0 0',
      $nest: {
        '& path': {
          fill: colorSubtle.fade(0.5).toString()
        },
        '& path:nth-child(4)': {
          fill: colorWhite.toString()
        }
      }
    })
  }
}