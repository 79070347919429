import { EnvModel } from './modules/env/model';

type AppCues = {
  identify: (identifier: string, options: { [key: string]: any }) => void;
  group: (identifier: string) => void;
  page: () => void;
};

const windowWithAppcues: Window & { Appcues?: AppCues } = window;

let resolveAppcuesReady: (value?: any) => void;

const appcuesReady = new Promise((resolve) => (resolveAppcuesReady = resolve));

if (!EnvModel.isServer) {
  const intervalUid = setInterval(() => {
    if (windowWithAppcues.Appcues) {
      resolveAppcuesReady();
      clearInterval(intervalUid);
    }
  }, 250);
}

const appCues: AppCues = {
  identify: (identifier, options) => appcuesReady.then(() => windowWithAppcues.Appcues.identify(identifier, options)),
  group: (identifier) => appcuesReady.then(() => windowWithAppcues.Appcues.group(identifier)),
  page: () => appcuesReady.then(() => windowWithAppcues.Appcues.page()),
};

export default appCues;
