import * as React from 'react';
import { classes, style } from 'typestyle';
import { FormInput } from './form-input';
import { horizontal, horizontallySpaced } from 'csstips';
import { FormFieldProps } from '../types/form';

export interface FormInputExpiryProps extends FormFieldProps {}

export interface FormInputExpiryState {
  month: string;
  year: string;
}

export class FormInputExpiry extends React.Component<FormInputExpiryProps, any> {
  private month: HTMLSelectElement;
  private year: HTMLSelectElement;

  constructor(props) {
    super(props);
    this.state = { month: '', year: '' };
  }

  render() {
    const { month, year } = this.state;

    const className = classes(FormInput.styles.input, FormInput.styles.select, FormInputExpiry.styles.select);

    const commonProps = {
      disabled: this.props.disabled,
      required: this.props.required,
    };

    return (
      <div className={FormInputExpiry.styles.container}>
        <select
          {...commonProps}
          id={this.props.id + '-1'}
          autoComplete="cc-exp-month"
          className={classes(className, !month && FormInput.styles.inputEmpty)}
          ref={(el) => (this.month = el)}
          value={month}
          onChange={this.handleChangeMonth}
        >
          <option value="">Expiry month</option>
          <option value="01">01 - Jan</option>
          <option value="02">02 - Feb</option>
          <option value="03">03 - Mar</option>
          <option value="04">04 - Apr</option>
          <option value="05">05 - May</option>
          <option value="06">06 - Jun</option>
          <option value="07">07 - Jul</option>
          <option value="08">08 - Aug</option>
          <option value="09">09 - Sep</option>
          <option value="10">10 - Oct</option>
          <option value="11">11 - Nov</option>
          <option value="12">12 - Dec</option>
        </select>
        <select
          {...commonProps}
          id={this.props.id + '-2'}
          autoComplete="cc-exp-year"
          className={classes(className, !year && FormInput.styles.inputEmpty)}
          ref={(el) => (this.year = el)}
          value={year}
          onChange={this.handleChangeYear}
        >
          <option value="">Expiry year</option>
          {this.renderNextYears()}
        </select>
      </div>
    );
  }

  UNSAFE_componentWillMount() {
    this.handleValue(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleValue(nextProps);
  }

  handleValue = (props: FormInputExpiryProps) => {
    if (props.value) {
      const [month = '', year = ''] = props.value.split('/');
      this.setState({ month, year });
    }
  };

  handleChangeMonth = (event: React.FormEvent<any>) => {
    this.setState({ month: (event.target as HTMLSelectElement).value }, this.emitChange);
  };

  handleChangeYear = (event: React.FormEvent<any>) => {
    this.setState({ year: (event.target as HTMLSelectElement).value }, this.emitChange);
  };

  emitChange = () => {
    const { month, year } = this.state;
    if (month && year) {
      this.props.onChange(this.props.name, `${month}/${year}`);
    } else {
      this.props.onChange(this.props.name, '');
    }
  };

  renderNextYears = () => {
    let year = new Date().getFullYear() - 2000;
    return new Array(20)
      .fill(1)
      .map((_) => year++)
      .map((y) => (
        <option key={y} value={y.toString()}>
          {y + 2000}
        </option>
      ));
  };

  static styles = {
    container: style({
      ...horizontal,
      ...horizontallySpaced(30),
    }),
    select: style({
      width: 'auto',
      flex: 1,
    }),
  };
}
