import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const ExplicitIcon: React.FC<SVGIconProps> = ({ size = 12, ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 12 12" {...props}>
    <rect width="12" height="12" rx="2" fill="#A0AEBB" />
    <path
      d="M3.54001 9.63273V2.36H8.44058V3.62776H5.07765V5.36071H8.18845V6.62847H5.07765V8.36497H8.45478V9.63273H3.54001Z"
      fill="white"
    />
  </BaseSvg>
);
