import { observable } from 'mobx';
import TileGrid, {SimpleTileItem} from '../../../components/tile-grid';

export class LearnPageModel {
  @observable
  slug: string = null;

  @observable
  loading: boolean = false;

  @observable
  tiles: SimpleTileItem[] = [];

  @observable
  document_title: string = null;

  @observable
  document_meta_description: string = null;

  @observable
  page_title: string = null;

  @observable
  page_subtitle: string = null;

  @observable
  cover_image: string = null;

  @observable
  typeform_url: string = null;

  @observable
  cta_title: string = null;

  @observable
  cta_content: string = null;

  @observable
  cta_button: string = null;

  @observable
  sections: Array<{ heading: string; image: string; content: any; video: string; }> = [];
}