import React from 'react';
import { classes, style } from 'typestyle';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { OnboardVerticalsLayout } from '../templates/OnboardVerticalsLayout';
import { LeftHandOnboardContentPanel } from '../atoms/LeftHandOnboardContentPanel';
import { RightHandOnboardContentPanel } from '../atoms/RightHandOnboardContenntPanel';
import { OnboardProgressBar } from '../atoms/OnboardProgressBar';
import desktopHeaderImagex2 from '../../../static/channel-onboarding/header-image-x2.png';
import desktopHeaderImageMobile from '../../../static/channel-onboarding/header-image-mobile-x2.png';
import { ChannelAvatar } from '../atoms/ChannelAvatar';
import { px } from 'csx';
import isEmpty from 'lodash/isEmpty';
import { ButtonProminence } from '../atoms/button/buttonStyles';
import { Button } from '../atoms/button/Button';
import { ONBOARD_VERTICAL_CATEGORY_ROUTE } from '../../../constants';
import { ArrowRight } from '../atoms/icons/ArrowRight';
import { rebrand } from '../../../theme/color';

const styles = {
  image: style({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }),
  hideUpToDesktop: style(mediaUpToDesktop({ display: 'none' })),
  hideDesktop: style(mediaDesktop({ display: 'none' })),
  avatar: style({
    marginRight: px(8),
    width: '40px',
    height: '40px',
  }),
  welcomeHeadingText: style({
    position: 'absolute',
    left: '50px',
    top: '-17px',
    width: '300px',
  }),
  matchHeadingText: style(mediaUpToDesktop({ marginTop: '32px', top: '112px' }), {
    fontSize: '32px',
    position: 'absolute',
    top: '160px',
    marginTop: '68px',
  }),
  boldText: style({
    fontWeight: 700,
  }),
  text: style(mediaUpToDesktop({ top: '216px', width: '350px' }), {
    width: '351px',
    position: 'absolute',
    top: '300px',
  }),
  button: style(
    mediaUpToDesktop({
      bottom: '32px',
      margin: '32px 16px',
      right: '0px',
      width: '85px',
    }),
    {
      display: 'grid',
      alignItems: 'center',
      width: '101px',
      position: 'absolute',
      bottom: '-80px',
      right: '-96px',
      height: '40px',
      boxShadow: 'none',
      margin: '32px 16px',
      $nest: {
        '&:hover': {
          boxShadow: 'none',
        },
      },
    }
  ),
  arrow: style({
    position: 'absolute',
    right: '12px',
  }),
  textBtn: style({
    color: rebrand.contrast[90].toHexString(),
    fontSize: '16px',
  }),
  container: style(
    mediaUpToDesktop({
      height: '98vh',
    })
  ),
};

const { PRIMARY } = ButtonProminence;

export const OnboardVerticalPersonalisationPage = inject(deps)(
  observer(
    ({
      model: {
        user: { channel },
      },
      controller: {
        analytics: { sendMixpanel },
      },
    }: ComponentProps) => {
      return (
        <OnboardVerticalsLayout className={styles.container}>
          <LeftHandOnboardContentPanel>
            {!isEmpty(channel) && (
              <>
                <ChannelAvatar channel={channel} className={styles.avatar} />

                <p className={styles.welcomeHeadingText}>
                  Congratulations, your channel {channel.name} is now active on Lickd!
                </p>

                <OnboardProgressBar step={0} />

                <h3 className={styles.matchHeadingText}>Now let us match you with the perfect music for your videos</h3>

                <p className={styles.text}>
                  This will take <span className={styles.boldText}>under 2 minutes</span> and will help us curate{' '}
                  <span className={styles.boldText}>personalized music</span> just for you
                </p>

                <Button
                  className={styles.button}
                  prominence={PRIMARY}
                  href={ONBOARD_VERTICAL_CATEGORY_ROUTE}
                  data-test-start-button
                  onClick={() => sendMixpanel('user clicks onboard start')}
                >
                  <span className={styles.textBtn}>Start</span>
                  <ArrowRight className={styles.arrow} color={rebrand.contrast[90].toHexString()} />
                </Button>
              </>
            )}
          </LeftHandOnboardContentPanel>
          <RightHandOnboardContentPanel>
            <img src={desktopHeaderImagex2} className={classes(styles.image, styles.hideUpToDesktop)} />
            <img src={desktopHeaderImageMobile} className={classes(styles.image, styles.hideDesktop)} />
          </RightHandOnboardContentPanel>
        </OnboardVerticalsLayout>
      );
    }
  )
);
