import Router from 'react-router';
import { action, isObservableMap, observable } from 'mobx';
import { APIController } from '../../api/controller';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import { PageModel } from '../model';
import { CreatorDashboardModel } from '../creator-dashboard/model';
import { PrismicController } from '../../prismic/controller';
import { AnalyticsController } from '../../analytics/controller';
import { fetchSliceData } from '../../../components/project-happy/utilities/prismic';
import { AuthModel } from '../../auth/model';

export class AccountHubPageController {
  constructor(
    private page: PageModel,
    private model: CreatorDashboardModel,
    private env: EnvModel,
    private prismic: PrismicController,
    private api: APIController,
    private analytics: AnalyticsController,
    private ui: UIController,
    private auth: AuthModel
  ) {
    // Potentially come from SSR which has been simplified to an object
    if (!isObservableMap(this.model.carousels)) {
      this.model.carousels = observable.map(this.model.carousels);
    }
    if (!isObservableMap(this.model.tracks)) {
      this.model.tracks = observable.map(this.model.tracks);
    }
  }

  @action
  onEnter: Router.EnterHook = async () => {
    // Don't re-run on client for logged out if SSR has already processed, stops the flashing re-render
    if (this.model.slices.length > 0) return;
    const documentId = 'account_hub';

    // need the playlist id from global document
    await this.env.ready;

    const {
      data: { slices },
    } = await this.prismic.getSingle(documentId, { resolveImages: true });
    this.model.slices = slices;

    this.model.loadingCarouselData = true;

    const { carousels, tracks } = await fetchSliceData(
      this.api,
      this.env,
      this.model.slices,
      this.auth.user ? this.auth.user.country : null
    );
    this.model.loadingCarouselData = false;
    this.model.carousels = observable.map(carousels);
    this.model.tracks = observable.map(tracks);

    await this.ui.setSEO('/account');
  };
}
