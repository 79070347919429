import * as React from 'react';
import { em, percent, px, important } from 'csx';
import { keyframes, style, classes } from 'typestyle';
import {
  colorBrand,
  colorContrast,
  colorGunmetal,
  colorSubtle,
  colorWhite,
  colorCrimson,
  colorLightGrey,
} from '../theme/color';
import { transitionQuickEase } from '../theme/transition';
import { FormFieldProps } from '../types/form';

export type FormInputCheckboxProps = FormFieldProps;

const selected = colorBrand.toString();
const focus = colorBrand.toString();
const disabled = colorSubtle.toString();
const fg = colorGunmetal.toString();
const bg = colorWhite.toString();

const borderscale = keyframes({
  '50%': {
    boxShadow: `0 0 0 ${px(2)} ${selected}`,
  },
});

export class FormInputCheckbox extends React.Component<FormInputCheckboxProps, any> {
  private input: HTMLInputElement;

  render() {
    const { value, name, placeholder, id, link, onClick, hasError = false } = this.props;

    return (
      <div
        className={classes(FormInputCheckbox.styles.checkbox, hasError ? FormInputCheckbox.styles.checkboxError : null)}
        onClick={this.handleClick}
        data-test-checkbox-form
      >
        <input
          id={id}
          ref={(el) => (this.input = el)}
          name={name}
          type="checkbox"
          checked={value}
          onChange={this.notifyParent}
        />
        <label htmlFor={id}>{placeholder}</label>
        {link ? (
          <div onClick={onClick} className={FormInputCheckbox.styles.checkboxAction} role="button">
            {link}
          </div>
        ) : null}
      </div>
    );
  }

  handleClick = (event) => {
    this.input.checked = !this.input.checked;
    this.notifyParent();
  };

  notifyParent = () => {
    const { checked, name } = this.input;
    this.props.onChange(name, checked);
  };

  static styles = {
    checkboxAction: style({
      display: 'inline-block',
      color: selected,
      cursor: 'pointer',
      textDecoration: 'underline',
      marginLeft: important(`${px(4)}`),
      fontSize: px(12),
    }),
    checkbox: style({
      margin: `${px(10)} 0`,
      display: 'flex',
      alignItems: 'center',
      $nest: {
        '& input': {
          border: 0,
          clip: 'rect(0 0 0 0)',
          height: px(1),
          margin: px(-1),
          overflow: 'hidden',
          padding: 0,
          position: 'absolute',
          width: px(1),
        },
        '& label': {
          display: 'block',
          position: 'relative',
          paddingLeft: px(30),
          lineHeight: em(1.1),
          verticalAlign: 'middle',
          userSelect: 'none',
          fontSize: px(12),
        },

        '& input:focus + label:before': {
          borderColor: selected,
          backgroundColor: selected,
        },
        '& input:active + label:before': {
          transitionDuration: 0,
        },
        '& input + label:before': {
          boxSizing: 'content-box',
          content: '""',
          color: fg,
          position: 'absolute',
          top: percent(50),
          left: 0,
          width: px(14),
          height: px(14),
          marginTop: px(-10),
          border: `${px(2)} solid ${fg}`,
          textAlign: 'center',
          transition: `all ${transitionQuickEase}`,
        },
        '& input + label:after': {
          boxSizing: 'content-box',
          content: '""',
          position: 'absolute',
          transformOrigin: percent(50),
          transition: `transform ${transitionQuickEase}`,
          backgroundColor: 'transparent',
          top: percent(50),
          left: px(7),
          width: px(11),
          height: px(4),
          marginTop: px(-7),
          borderStyle: 'solid',
          borderColor: bg,
          borderWidth: `0 0 ${px(2)} ${px(2)}`,
          transform: 'rotate(-45deg) scale(0)',
        },

        '& input:disabled + label:before': {
          borderColor: disabled,
        },
        '& input:disabled:focus + label:before': {
          backgroundColor: 'inherit',
        },
        '& input:disabled:checked + label:before': {
          backgroundColor: disabled,
        },
        '& input:checked:focus + label:before': {
          borderColor: bg,
        },
        '& input:not(:checked) + label:before': {
          border: `${px(1)} solid ${colorLightGrey.toString()}`,
          background: colorLightGrey.toString(),
        },

        '& input:checked + label:after': {
          content: '""',
          transform: 'rotate(-45deg) scale(1)',
          transition: `transform ${transitionQuickEase}`,
        },
        '& input:checked + label:before': {
          animation: `${borderscale} ${transitionQuickEase}`,
          background: selected,
          borderColor: selected,
          boxShadow: 'none',
        },
      },
    }),
    checkboxError: style({
      $nest: {
        '& input + label:before': {
          border: important(`${px(2)} solid ${colorCrimson.toString()}`),
        },
      },
    }),
  };
}
