import * as React from 'react';
import { classes, style } from 'typestyle';
import { PlaylistGroupPlaylistSchema, PlaylistSchema } from '../../../types/schema';
import { em, px } from 'csx';
import { shadowElevation2 } from '../../../theme/shadow';
import { transitionQuickEase } from '../../../theme/transition';
import { colorGunmetal, colorWhite } from '../../../theme/color';
import { centerJustified, endJustified, flexRoot, vertical } from 'csstips';
import { PlaylistThumbnail } from '../atoms/PlaylistThumbnail';
import { Link } from '../atoms/Link';
import { overlayLink } from '../../../theme/mixin';
import { zLabel } from '../../../theme/z';
import { AnalyticsContextProvider } from '../utilities/AnalyticsContext';

const styles = {
  wrapper: style({
    position: 'relative',
    display: 'flex',
    margin: px(8),
    borderRadius: em(0.3),
    flexDirection: 'row',
    background: colorGunmetal.toString(),
    transition: `box-shadow ${transitionQuickEase}`,
    $nest: {
      '& > div': {
        color: colorWhite.toString(),
        width: '50%',
      },
      '& > div:nth-child(2)': {
        textAlign: 'right',
      },
      '&:hover': shadowElevation2,
      '& .play-button': {
        position: 'absolute',
        bottom: px(16),
        right: px(16),
      },
    },
  }),
  imgWrapper: style(flexRoot, endJustified, {
    margin: px(8),
  }),
  img: style({
    width: 'calc(100% - 16px)',
  }),
  link: style(overlayLink, { color: 'inherit' }),
  info: style(flexRoot, vertical, centerJustified, {
    height: '100%',
    padding: px(8),
    fontSize: px(10),
    $nest: {
      '& p': {
        margin: 0,
      },
      '& p > span': {
        display: 'block',
        lineHeight: 'normal',
        fontSize: px(12),
      },
      '& .title': {
        fontWeight: 600,
      },
    },
  }),
  overlayContent: style({ $nest: { '& > *': { zIndex: zLabel } } }),
};

export type LargePlaylistTileProps = { className?: string; playlist: PlaylistGroupPlaylistSchema | PlaylistSchema };

export const LargePlaylistTile: React.SFC<LargePlaylistTileProps> = ({ children, className, playlist }) => (
  <AnalyticsContextProvider context={{ playlistName: playlist.name }}>
    <div className={classes(styles.wrapper, className)}>
      <div>
        <div className={styles.info}>
          <p>
            <span>Playlist</span>
            <Link href={`/browse/collection/${playlist.slug}`} className={styles.link}>
              <span className="title">{playlist.name}</span>
            </Link>
          </p>
        </div>
      </div>
      <div className={styles.imgWrapper}>
        <PlaylistThumbnail className={styles.img} playlist={playlist} />
      </div>
      <span className={styles.overlayContent}>{children}</span>
    </div>
  </AnalyticsContextProvider>
);
