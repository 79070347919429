import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalChildStyle } from './modal';
import { style } from 'typestyle';
import {
  betweenJustified, endJustified, horizontal, horizontallySpaced, start, startJustified, vertical,
  verticallySpaced
} from 'csstips';
import { ButtonPill } from './button-pill';
import { colorBrand, colorContrast, colorGunmetal, colorSubtle, colorWhite } from '../theme/color';
import { resetAnchorStyles } from '../theme/reset';
import { em, percent, px } from 'csx';
import {BasketModel} from "../modules/basket/model";
import {Component, deps, inject} from "../lib/component";

export interface BasketRestrictedModalModel {
  show: boolean;
  basket: BasketModel;
}

export interface BasketRestrictedModalProps {
  basket: BasketRestrictedModalModel;
  onRequestClose(): void;
}

@inject(deps)
@observer
export class BasketRestrictedModal extends Component<BasketRestrictedModalProps> {
  render() {
    const { basket, onRequestClose } = this.props;

    return (
      <Modal show={basket.show} onRequestClose={onRequestClose}>
        <div className={BasketRestrictedModal.styles.container}>
          <h3>Track restricted</h3>
          <p>
            Well this is awkward… The tracks in your basket (listed below) are not available to license in your country
            – sorry! International rules suck sometimes, but to make this musical breakup as painless as possible, once
            you click OK all trace of them will be removed from your basket.
          </p>
          <ul>
            {basket.basket.restrictedTracks.map((track) => {
              let artists = track.artists
                .map(function (artist) {
                  return artist.name;
                })
                .join(', ');
              return (
                <li key={track.title}>
                  {track.title} - {artists}
                </li>
              );
            })}
          </ul>
          <div className={BasketRestrictedModal.styles.buttons}>
            <ButtonPill onClick={this.onClose}>OK</ButtonPill>
          </div>
        </div>
      </Modal>
    );
  }

  onClose = () => {
    this.props.model.basket.restrictedTracks.forEach((t) => {
      this.props.controller.basket.removeFromBasket(t);
    });
    this.props.onRequestClose();
  };

  static styles = {
    container: style(ModalChildStyle, {
      ...ModalChildStyle,
      width: px(420),
      maxWidth: 'calc(100vw - 4rem)',
      background: colorGunmetal.toString(),
      overflowX: 'hidden',
      $nest: {
        '&> h3': {
          color: colorContrast.toString(),
          fontSize: px(30),
          margin: '0 0 30px 0',
        },
        '& p': {
          color: colorWhite.toString(),
          margin: '5px 0 30px 0',
          lineHeight: em(1.4),
        },
        '& ul': {
          color: colorWhite.toString(),
        },
      },
    }),
    link: style({
      color: colorBrand.toString(),
      $nest: { ...resetAnchorStyles(colorBrand.toString()) },
    }),
    buttons: style({
      ...horizontal,
      ...horizontallySpaced(10),
      ...endJustified,
      margin: '30px 0 0 0',
    }),
    buttonsLicense: style({
      ...vertical,
      ...verticallySpaced(10),
      ...startJustified,
      ...start,
      margin: 0,
    }),
    button: style({
      ...horizontal,
      ...betweenJustified,
      width: percent(100),
    }),
    loading: style({
      padding: px(30),
      textAlign: 'center',
      color: colorSubtle.toString(),
    }),
    discount: style({
      textDecoration: 'line-through',
      marginRight: px(5),
    }),
  };
}