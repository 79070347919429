import { observable } from 'mobx';

export class YouTubePageModel {
  @observable
  loading: boolean = false;

  @observable
  page_title: string = null;

  @observable
  page_subtitle: string = null;

  @observable
  cover_image: string = null;
  
  @observable
  section_1_title: string = null;
  
  @observable
  section_1_content: string = null;

  @observable
  section_1_image: string = null;

  @observable
  section_2_title: string = null;

  @observable
  section_2_content: string = null;

  @observable
  section_2_image: string = null;

  @observable
  section_3_title: string = null;

  @observable
  section_3_content: string = null;

  @observable
  section_3_video: string = null;

  @observable
  section_4_title: string = null;

  @observable
  section_4_content: string = null;

  @observable
  section_4_video: string = null;

  @observable
  cta_title: string = null;

  @observable
  cta_content: string = null;

  @observable
  cta_button: string = null;

  @observable
  testimonials_title: string = null;

  @observable
  testimonials_strap: string = null;

  @observable
  testimonials: Array<{
    channel_name: string;
    channel_id: string;
    quote: string;
    video: string;
  }> = [];
}