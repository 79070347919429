import * as React from 'react';
import { AllHTMLAttributes } from 'react';
import { BadgeSize, YouTubeBadge, YouTubeBadgeProps } from '../atoms/YouTubeBadge';
import { YouTubeVideo, YouTubeVideoProps } from '../atoms/YouTubeVideo';
import { FeaturedTrackOverlay } from '../atoms/FeaturedTrackOverlay';
import { TrackSchema } from '../../../types/schema';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { shadowElevation2 } from '../../../theme/shadow';
import { YouTubeVideoDetails, YouTubeVideoDetailsProps } from '../atoms/YouTubeVideoDetails';

const styles = {
  wrapper: style(shadowElevation2, {
    backgroundColor: '#181818',
    color: 'white',
    borderRadius: px(8),
    overflow: 'hidden',
  }),
  badgePadding: style({
    padding: '12px 16px',
  }),
};

export type YouTubeVideoTrackTileProps = Pick<AllHTMLAttributes<HTMLDivElement>, 'onMouseEnter' | 'onMouseLeave'> & {
  className?: string;
  channel: YouTubeBadgeProps;
  video: YouTubeVideoProps & YouTubeVideoDetailsProps;
  track: TrackSchema;
};

export const YouTubeVideoTrackTile: React.SFC<YouTubeVideoTrackTileProps> = ({
  className,
  channel: { className: badgeClassName, ...channel },
  video: { id: video_id, timestamp, ...video },
  track,
  ...eventHandlers
}) => (
  <div className={classes(styles.wrapper, className)} {...eventHandlers}>
    <YouTubeBadge className={classes(styles.badgePadding, badgeClassName)} size={BadgeSize.NORMAL} {...channel} />
    <YouTubeVideo id={video_id} timestamp={timestamp} />
    <YouTubeVideoDetails {...video} />
    <FeaturedTrackOverlay track={track} />
  </div>
);
