import { observable } from 'mobx';
import defaultLocale from '../../messages/en_US';

export class I18nModel {
  @observable
  locale: string = 'en';

  @observable
  messages: Object = defaultLocale;
}

