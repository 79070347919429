import Prismic from 'prismic-javascript';
import { action } from 'mobx';
import { RouterState } from 'react-router';
import { LightweightLandingModel } from './model';
import { PrismicController } from '../../prismic/controller';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import { ImageController } from '../../image/controller';
import { RouterModel } from '../../router/model';
import { stripQueryString } from '../../../lib/string';
import { ContentModel } from '../../content/model';

export class LightweightLandingController {
  constructor(
    private model: LightweightLandingModel,
    private prismic: PrismicController,
    private env: EnvModel,
    private ui: UIController,
    private router: RouterModel,
    private content: ContentModel,
    private image: ImageController,
  ) {}

  @action
  onEnter = async (nextState: RouterState) => {
    try {
      const predicates = [Prismic.Predicates.at('document.type', 'lightweight_lander')];

      const [{ results }] = await Promise.all([this.prismic.query(predicates), this.env.ready]);

      if (results.length === 0) {
        return this.router.replace('/');
      }

      const { data } = results[0];

      await this.ui.setSEO(nextState.location.pathname);

      const tracksContent = data.featured_tracks;

      this.model.customHeaderImage = this.image.preloadContent(data.custom_header.url, { width: 1280 });
      this.model.ctaText = data.cta_text;
      this.model.headerText = data.header_text;
      this.model.bodyTitle = data.body_title;
      this.model.bodyContent = data.body_content_columns.map(({ column_content }) => column_content);
      this.model.featuredTitle = data.featured_tracks_title;

      this.model.featured = !tracksContent
        ? []
        : tracksContent.map((item: any) => ({
            image: this.image.preloadContent(item.artist_image.url),
            name: item.artist_name,
            subtext: item.artist_subtext,
            description: item.artist_track_description,
          }));
    } catch (e) {
      throw new Error(e);
    }
  };
}
