import React from 'react';
import * as ReactDOM from 'react-dom';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { url } from 'csx';

const refsRendered = new Set();

const sixTileMaskSvgId = 'sixTileSvgMask';
const fourTileMaskSvgId = 'fourTileSvgMask';

export const sixTileMask: NestedCSSProperties = {
  clipPath: url('#' + sixTileMaskSvgId),
};

export const fourTileMask: NestedCSSProperties = {
  clipPath: url('#' + fourTileMaskSvgId),
};

export class TileSvgMask extends React.Component {
  ref: React.RefObject<never>;

  state = {
    shouldRender: false,
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    refsRendered.add(this.ref);
    if (refsRendered.size === 1) {
      this.state.shouldRender = true;
    }
  }

  componentWillUnmount() {
    refsRendered.delete(this.ref);
    this.ref = null;
  }

  render() {
    if (!this.state.shouldRender) return null;
    return ReactDOM.createPortal(
      <svg xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id={sixTileMaskSvgId} clipPathUnits="objectBoundingBox">
            <rect width="0.15" height="1" rx="0.004" ry="0.004" x="0.006" />
            <rect width="0.15" height="1" rx="0.004" ry="0.004" x="0.172" />
            <rect width="0.15" height="1" rx="0.004" ry="0.004" x="0.338" />
            <rect width="0.15" height="1" rx="0.004" ry="0.004" x="0.504" />
            <rect width="0.15" height="1" rx="0.004" ry="0.004" x="0.670" />
            <rect width="0.15" height="1" rx="0.004" ry="0.004" x="0.836" />
          </clipPath>
          <clipPath id={fourTileMaskSvgId} clipPathUnits="objectBoundingBox">
            <rect width="0.234" height="1" x="0.008" />
            <rect width="0.234" height="1" x="0.258" />
            <rect width="0.234" height="1" x="0.508" />
            <rect width="0.234" height="1" x="0.758" />
          </clipPath>
        </defs>
      </svg>,
      document.getElementById('react-portals-root')
    );
  }
}
