import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const History: React.SFC<SVGIconProps> = ({ size = 24, color = '#71797D', ...props }) => (
  <BaseSvg width={size} viewBox="0 0 25 24" {...props}>
    <path d="M13.9595 7H12.5195V13L17.5595 16.15L18.2795 14.92L13.9595 12.25V7Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 4C9.58172 4 6 7.58172 6 12H4C4 6.47715 8.47715 2 14 2C19.5228 2 24 6.47715 24 12C24 17.5228 19.5228 22 14 22C10.5319 22 7.47677 20.2337 5.68454 17.5563L7.34656 16.4437C8.78337 18.5902 11.2273 20 14 20C18.4183 20 22 16.4183 22 12C22 7.58172 18.4183 4 14 4Z"
      fill={color}
    />
    <path d="M2.16421 8.00003L6.62904 12.4649L5.21482 13.8791L0.75 9.41424L2.16421 8.00003Z" fill={color} />
    <path d="M3.79306 12.4673L8.26037 8L9.67458 9.41421L5.20727 13.8815L3.79306 12.4673Z" fill={color} />
  </BaseSvg>
);
