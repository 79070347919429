import { APIController } from '../api/controller';
import { UserModel } from './model';
import { action, autorun, IReactionDisposer, runInAction } from 'mobx';
import { BasketController } from '../basket/controller';
import { AuthController } from '../auth/controller';
import { CouponController } from '../coupon/controller';
import { User } from '../../types/user';
import { BugsnagController } from '../bugsnag/controller';
import { ONBOARDING_STATE } from '../../constants';
import siteEvents, { SITE_EVENTS } from '../../components/project-happy/utilities/siteEvents';
import { isEmpty } from '../../components/project-happy/utilities/objects';
import { EnvModel } from '../env/model';
import polyfillGoldenTicketCoupon from '../../lib/polyfillGoldenTicketCoupon';
import { CouponSchema } from '../../types/schema';

export class UserController {
  constructor(
    private model: UserModel,
    private api: APIController,
    private basket: BasketController,
    private auth: AuthController,
    private env: EnvModel,
    private coupon: CouponController,
    private bugsnag: BugsnagController
  ) {
    this._disposers = [];
    let identity = model.user ? model.user.identity : null;

    this._disposers.push(
      autorun(() => {
        if (!this.env.isServer) {
          if (model.user && model.user.identity !== identity) {
            identity = model.user.identity;

            this.getYoutubeChannel(model.user.default_channel);
            this.getYoutubeChannels();
            this.getAccounts();
            this.getSubscription(model.user.default_channel);
            this.getFavourites();
            this.fetchCredits();
            this.fetchCoupons();
          } else {
            identity = null;
            this.model.loading = false;
            this.model.loadingChannels = false;
            this.model.loadingAccounts = false;
            this.model.loadingSubscriptions = false;
          }
        }
      })
    );

    this.fetchOrCreateBasket();
  }

  private _disposers: IReactionDisposer[];

  dispose = () => {
    this._disposers.forEach((dispose) => dispose());
  };

  @action
  refreshUser = async (user?: User): Promise<void> => {
    if (!user) {
      await this.auth.getSession();
    } else {
      this.auth.setUser(user);
    }
    await this.coupon.getCoupon(this.model.user.default_channel);
  };

  @action
  async markRegistrationStepAsComplete(step: string) {
    const { user } = this.model;
    if (!user) return;

    await this.api.user.markRegistrationStepAsCompleted(user.identity, step);
    await this.refreshUser();
  }

  @action
  updateSelectedChannelVertical = async (vertical: string) => {
    const { user } = this.model;
    if (!user) return;
    await this.api.user.setChannelVertical(user.identity, user.default_channel, vertical);
    await this.getYoutubeChannel(user.default_channel);
  };

  @action
  getYoutubeChannel = async (identity: string) => {
    const { user } = this.model;
    if (!user || !identity) return;

    runInAction(() => {
      this.model.loading = true;
    });

    const channel = await this.api.user.getYouTubeChannel(user.identity, identity);
    this.model.loading = false;
    runInAction(() => {
      this.model.channel = channel.data;
      if (!isEmpty(this.model.channel) && typeof this.model.channel.ratecard.rate !== 'number') {
        // Duplicate rate property for model uniformity
        this.model.channel.ratecard.rate = parseFloat(this.model.channel.ratecard.value);
      }
      this.model.loading = false;
    });
  };

  @action
  getSubscription = async (identity: string) => {
    const { user } = this.model;
    if (!user) return;

    runInAction(() => {
      this.model.loadingSubscriptions = true;
    });

    const { data } = await this.api.user.getSubscriptionInformation(user.identity, identity);

    runInAction(() => {
      this.model.activeSubscription = data[0];
      this.model.loadingSubscriptions = false;
    });

    return data;
  };
  @action
  getSubscriptions = async () => {
    const { user } = this.model;
    if (!user) return;

    runInAction(() => {
      this.model.loadingSubscriptions = true;
    });

    const { data } = await this.api.user.getSubscriptions(user.identity);

    runInAction(() => {
      this.model.subscriptions = data;
      this.model.loadingSubscriptions = false;
    });

    return data;
  };

  @action
  getYoutubeChannels = async () => {
    const { user } = this.model;
    if (!user) return;

    runInAction(() => {
      this.model.loadingChannels = true;
    });

    const channels = await this.api.user.getYouTubeChannels(user.identity);

    runInAction(() => {
      this.model.channels = channels.data;
      this.model.loadingChannels = false;
    });
  };

  @action
  getAccounts = async () => {
    const { user } = this.model;
    if (!user) return;

    runInAction(() => {
      this.model.loadingAccounts = true;
    });

    try {
      const accounts = await this.api.user.getAccounts(user.identity);

      runInAction(() => {
        this.model.accounts = accounts.data;
        this.model.loadingAccounts = false;
      });
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => {
        this.model.loadingAccounts = false;
      });
    }
  };

  @action
  retrieveUserPlaylists = async (page: number) => {
    if (this.model.loadingPlaylists === true) return;
    this.model.loadingPlaylists = true;
    this.model.playlistPagination.current_page = page;
    const playlists = await this.api.playlist.getUserPlaylists(
      this.model.user.identity,
      page,
      this.model.playlistPagination.per_page
    );

    this.model.playlists.push(...playlists.data);
    this.model.playlistPagination = playlists.meta.pagination;
    this.model.loadingPlaylists = false;
  };

  @action
  loadPlaylists = () => {
    this.model.playlists = [];
    return this.retrieveUserPlaylists(1);
  };

  @action
  loadMorePlaylists = () => {
    if (this.model.playlistPagination.total_pages <= this.model.playlistPagination.current_page) return;
    this.retrieveUserPlaylists(this.model.playlistPagination.current_page + 1);
  };

  @action
  getRecommendedTracksPlaylist = async () => {
    const userRegion = this.model.user ? this.model.user.country : null;

    try {
      const playlist = await this.api.user.getRecommendedTrackPlaylist(this.model.user.identity, this.model.channel.id);
      const playlistTracks = await this.api.playlist.getCuratedPlaylistTracks(playlist.data.slug, 1, 36, userRegion);
      this.model.recommendedPlaylist = playlistTracks.data;
      this.model.recommendedPlaylistSlug = playlist.data.slug;
      return;
    } catch (error) {
      this.bugsnag.notifyException(error);
      throw error;
    }
  };

  @action
  getFavourites = async () => {
    await this.fetchFavouriteTracks();

    await this.fetchFavouriteArtists();

    await this.fetchFavouritePlaylists();
  };

  @action
  fetchFavouriteTracks = async () => {
    const tracks = (
      await this.api.playlist.getUserPlaylistTracks(
        this.model.user.identity,
        this.env.playlistFavourites,
        1,
        25,
        this.model.user ? this.model.user.country : null
      )
    ).data;

    runInAction(() => {
      this.model.favouriteTracks = tracks;
    });
  };

  @action
  fetchFavouriteArtists = async () => {
    const artists = (await this.api.artistlist.getArtists(this.model.user.identity, this.env.artistlistFavourites))
      .data;

    runInAction(() => {
      this.model.favouriteArtists = artists;
    });
  };

  @action
  fetchFavouritePlaylists = async () => {
    const playlists = (await this.api.playlist.getFavourites(this.model.user.identity)).data;

    runInAction(() => {
      this.model.favouritePlaylists = playlists;
    });
  };

  @action
  unlinkSpotify = async () => {
    this.model.isUnlinking.spotify = true;
    await this.api.user.unlinkSpotify(this.model.user.identity);
    await this.getAccounts();
    this.model.isUnlinking.spotify = false;
  };

  @action
  unlinkTwitter = async () => {
    this.model.isUnlinking.twitter = true;
    await this.api.user.unlinkTwitter(this.model.user.identity);
    await this.getAccounts();
    this.model.isUnlinking.twitter = false;
  };

  @action
  unlinkDropbox = async () => {
    this.model.isUnlinking.dropbox = true;
    await this.api.user.unlinkDropbox(this.model.user.identity);
    await this.getAccounts();
    this.model.isUnlinking.dropbox = false;
  };

  @action
  unlinkFacebook = async () => {
    this.model.isUnlinking.facebook = true;
    await this.api.user.unlinkFacebook(this.model.user.identity);
    await this.getAccounts();
    this.model.isUnlinking.facebook = false;
  };

  @action
  fetchOrCreateBasket = async () => {
    const url = new URL(window.location.href);
    const path = url.pathname;
    const step = url.searchParams.get('step');

    const isDownload = path === '/checkout/download' && step === '3';
    if (!this.env.isServer && !isDownload) {
      await this.basket.getBasket();
    }
  };

  @action
  fetchCredits = async (): Promise<void> => {
    if (!this.model.user) return;

    try {
      const creditsResponse = await this.api.user.getCredits(this.model.user.identity);

      this.model.credits = creditsResponse.data;
    } catch (error) {
      this.bugsnag.notifyException(error);
      throw error;
    }
  };

  @action
  fetchCoupons = async (): Promise<void> => {
    if (!this.model.user) return;

    try {
      const couponsResponse = await this.api.user.getCoupons(this.model.user.identity);
      const polyfillCoupon = polyfillGoldenTicketCoupon.bind(null, this.model.user.is_golden_ticket);
      const coupons = couponsResponse.data.map(polyfillCoupon) as CouponSchema[]; // The bound polyfill loses type information, it's a purely additive method

      this.model.coupons = coupons;
    } catch (error) {
      this.bugsnag.notifyException(error);
      throw error;
    }
  };
}
