import { action } from 'mobx';
import { API, APIResponse } from '../types';
import { withIncludes } from '../../../lib/api';
import { TrackSchema } from '../../../types/schema';

export class TrackAPIController extends API {
  @action
  getTrack = async (trackSlug: string, includes: string[] = [], availableIn = '') => {
    const url = `/catalog/track/${trackSlug}${withIncludes(includes)}${
      availableIn ? `&available_in=${availableIn}` : ''
    }`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<TrackSchema>;

    this.cache.addTrack(json.data);

    return json;
  };

  @action
  getTrackRestrictions = async (trackSlug: string) => {
    const url = `/catalog/track/${trackSlug}/restrictions`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<{ name: string }[]>;

    return json;
  };

  @action
  getSimilarTracks = async (trackId: string) => {
    const url = `/catalog/track/${trackId}/similar`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<Array<TrackSchema>>;

    json.data.forEach(this.cache.addTrack);

    return json;
  };
}
