import React, { LiHTMLAttributes } from 'react';
import { classes, style } from 'typestyle';
import { colorWhite, rebrand } from '../../../theme/color';
import ChevronRight from '../../../static/chevron-right.svg';
import { percent, px, url } from 'csx';
import { transitionQuickEase } from '../../../theme/transition';

const styles = {
  wrapper: style({
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    background: colorWhite.toString(),
    borderRadius: '4px',
    padding: 0,
    fontSize: '12px',
    color: rebrand.contrast[50].toHexString(),
    borderBottom: `4px solid ${rebrand.contrast[20].toString()}`,
  }),
  list: style({
    listStyle: 'none',
    display: 'flex',
    padding: px(8),
    margin: 0,
    $nest: {
      '& li': {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        $nest: {
          '&[aria-current] ~ li': {
            color: rebrand.contrast[50].fade(0.5).toString(),
            $nest: {
              '&:not(.highlight-chevron)::before': {
                opacity: 0.5,
              },
            },
          },
        },
      },
      '& li:not(:first-child)::before': {
        content: '""',
        display: 'block',
        width: px(16),
        height: px(16),
        marginRight: px(16),
        marginLeft: px(16),
        background: url(ChevronRight),
      },
    },
  }),
  progressBar: style({
    position: 'absolute',
    bottom: px(-4),
    height: px(4),
    background: rebrand.primary1.toString(),
    borderBottomLeftRadius: px(4),
    transition: `width ${transitionQuickEase}`,
    $nest: {
      '&.is-full': {
        borderBottomRightRadius: px(4),
      },
    },
  }),
};

/**
 * ProgressBar shows the progress in a multi-step process. The UI shows both the steps involved,
 * seperated by chevrons, and a progress bar which lines the bottom of the UI.
 *
 * @property string className An additional classname for styling purposes
 * @property number progress A number between 0 and 100 representing the percentage of the bar filled
 * @property Array<string> steps An array of strings defining the multiple steps
 * @property string colour A CSS colour value to alter the progress bar colour
 */
type ProgressBarProps = { className?: string; progress: number; steps: string[]; colour?: string };
export const ProgressBar: React.FC<ProgressBarProps> = ({ className, progress, steps, colour }) => {
  // Find which step the progress bar will stop at
  const fractionalStep = (progress / 100) * steps.length;
  // Round it to find the closest step
  const roundedStep = Math.round(fractionalStep);
  // Check to see if the percentage is close to a step boundary
  const closeToBoundary = fractionalStep >= roundedStep - 0.05 && fractionalStep < roundedStep;
  // Round down to find the "current" step shown
  const active = Math.min(Math.floor(fractionalStep), steps.length - 1);
  return (
    <div className={classes(styles.wrapper, className)} aria-label="progress">
      <ol className={styles.list}>
        {steps.map((step, index) => {
          const props: LiHTMLAttributes<HTMLLIElement> = {};
          if (index === active) {
            props['aria-current'] = 'step';
          } else if (index - 1 === active && closeToBoundary) {
            // If the percentage stops close to a chevron, highlight it
            props.className = 'highlight-chevron';
          }
          return (
            <li key={index} {...props}>
              {step}
            </li>
          );
        })}
      </ol>
      <div
        className={classes(styles.progressBar, progress === 100 && 'is-full')}
        role="progressbar"
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuenow={progress}
        style={{ width: percent(progress), backgroundColor: colour }}
      />
    </div>
  );
};
