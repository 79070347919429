import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { px } from 'csx';
import { colorContrast, colorLickdPink } from '../../../theme/color';

const styles = style({
  display: 'inline-block',
  color: colorContrast.toHexString(),
  // color: colorLickdPink.toHexString(),
});

cssRaw(`
@supports (background-clip: text) or (-webkit-background-clip: text) {
  .${styles} {
    background: ${colorLickdPink.toHexString()};
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent !important;
    padding-right: 8px; /* Fix for small character spacing glitch in headings */
  }
}
`);

type GradientTextProps = React.Component['props'] & { className?: string };

export const BrandPinkText: React.SFC<GradientTextProps> = ({ children, className }) => (
  <span className={classes(styles, className)}>{children}</span>
);
