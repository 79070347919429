import {
  isTypeArray,
  PrismicDataDrivenTypes,
  PrismicDataDrivenTileGridProps,
  PrismicHeadedTileGridProps,
  PrismicTypedTileGridProps,
  SlicePageContext,
  PrismicStockLabelProps,
} from '../utilities/types';
import * as React from 'react';
import {
  ArtistSchema,
  isArtistSchema,
  isPlaylistGroupPlaylistSchema,
  PlaylistGroupPlaylistSchema,
} from '../../../types/schema';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { isArray } from '../utilities/objects';
import { StorageModel } from '../../../modules/storage/model';
import { REPLACE_CAROUSELS } from '../../devtools/feature-flags';
import { LinkTilesSlice } from '../organisms/slices/LinkTilesSlice';
import { SubscriptionLabelContext } from '../atoms/SubscriptionLabel';
import { LoadingCarouselSlice } from '../organisms/slices/LoadingCarouselSlice';
import { LoadingTileSlice } from '../organisms/slices/LoadingTileSlice';

export type PrismicCatalogueTilesPayload = PrismicHeadedTileGridProps &
  PrismicDataDrivenTileGridProps &
  PrismicTypedTileGridProps &
  PrismicStockLabelProps;
export type PrismicCatalogueTilesProps = {
  payload: PrismicCatalogueTilesPayload;
  context: SlicePageContext;
};
export const PrismicCatalogueTiles = inject(deps)(
  observer(
    ({
      payload: {
        primary: { tiles_heading, tiles_type, tiles_slug, is_stock = false },
      },
      context: { playlists, externalDataLoading },
      model: { image },
    }: PrismicCatalogueTilesProps & ComponentProps) => {
      let slug = tiles_slug;
      if (!playlists.has(slug) && StorageModel.getItem(REPLACE_CAROUSELS) === true) {
        slug = REPLACE_CAROUSELS + tiles_type;
      }
      const playlist = playlists.get(slug);
      // Escape early if the playlist doesn't exist or is empty
      const notFound = !isArray(playlist) || playlist.length === 0;
      if (externalDataLoading !== true && notFound) return null;
      if (externalDataLoading === true && notFound) return <LoadingTileSlice heading={tiles_heading} />;
      if (
        tiles_type === PrismicDataDrivenTypes.PLAYLISTS &&
        isTypeArray<PlaylistGroupPlaylistSchema>(playlist, isPlaylistGroupPlaylistSchema)
      ) {
        const slice = (
          <LinkTilesSlice
            heading={tiles_heading}
            links={playlist.map(({ name, slug, images, track_images, is_stock }) => ({
              heading: name,
              href: `/browse/collection/${slug}`,
              src: image.cover(images.identity || track_images[0].identity),
              included: is_stock,
            }))}
          />
        );
        return is_stock ? (
          <SubscriptionLabelContext.Provider value={is_stock}>{slice}</SubscriptionLabelContext.Provider>
        ) : (
          slice
        );
      }
      if (tiles_type === PrismicDataDrivenTypes.ARTISTS && isTypeArray<ArtistSchema>(playlist, isArtistSchema)) {
        return (
          <LinkTilesSlice
            heading={tiles_heading}
            links={playlist.map(({ name, slug, images }) => ({
              heading: name,
              href: `/music/artists/${slug}`,
              src: image.cover(images.identity),
            }))}
          />
        );
      }

      return null;
    }
  )
);
