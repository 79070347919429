import * as React from 'react';
import { LightRadioInput } from '../atoms/controls/LightRadioInput';
import { betweenJustified, flexRoot, inlineRoot, vertical } from 'csstips';
import { classes, style } from 'typestyle';

const styles = {
  fieldset: style({ border: 'none', textAlign: 'center' }),
  radios: style(inlineRoot, betweenJustified),
  radio: style(flexRoot, vertical, betweenJustified, {
    margin: '0 8px',
  }),
};

export enum Currencies {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
}

const CurrencyValues: Currencies[] = [Currencies.USD, Currencies.EUR, Currencies.GBP];

const CurrencySymbols = {
  [Currencies.USD]: '\u0024',
  [Currencies.EUR]: '\u20AC',
  [Currencies.GBP]: '\u00A3',
};

export type CurrencySelectorProps = { className?: string; value: Currencies; onChange: (currency: Currencies) => void };

export const CurrencySelector: React.SFC<CurrencySelectorProps> = ({ className, value, onChange }) => (
  <fieldset className={classes(styles.fieldset, className)}>
    <legend>Select currency</legend>
    <div className={styles.radios}>
      {CurrencyValues.map((currency, key) => (
        <label className={styles.radio} key={key}>
          <LightRadioInput name="currency" checked={currency === value} onChange={() => onChange(currency)} />
          {CurrencySymbols[currency]}
        </label>
      ))}
    </div>
  </fieldset>
);
