import * as React from 'react';
import { observer } from 'mobx-react';
import { classes, style } from 'typestyle';
import { ArtistLinkList } from './artist-link-list';
import { WaveformImage } from './waveform-image';
import { Duration } from './duration';
import {
  betweenJustified,
  center,
  centerCenter,
  content,
  endJustified,
  horizontal,
  horizontallySpaced,
  startJustified,
  vertical,
  verticallySpaced,
} from 'csstips';
import { em, important, percent, px } from 'csx';
import {
  colorBackground,
  colorBrand,
  colorLickdMint,
  colorLickdPink,
  colorNeutral,
  colorSubtle,
  rebrand,
} from '../theme/color';
import { ButtonPill } from './button-pill';
import { SVGIcon } from './svg-icon';
import { TrackTile } from './track-tile';
import { resetAnchorStyles } from '../theme/reset';
import { ArtistSchema, LicenceSchema, TrackSchema } from '../types/schema';
import { formatCurrency } from '../lib/currency';
import { ImagePreloader } from '../types/fn';
import { AnalyticsController } from '../modules/analytics/controller';
import { mediaDesktop } from '../theme/media';
import isEmpty from 'lodash/isEmpty';
import { ExternalLink } from './project-happy/atoms/icons/ExternalLink';
import { mediaUpToDesktop } from './project-happy/utilities/mediaQueries';
import { UserModel } from '../modules/user/model';
import { ConnectChannelPrompt } from './project-happy/molecules/ConnectYoutubeChannelPrompt';
import { Loading } from './loading/loading';
import { AccountLayout } from './project-happy/templates/AccountLayout';

export interface PageAccountLicencesModel {
  licences: Array<LicenceSchema>;
}

export interface PageAccountLicencesProps {
  model?: PageAccountLicencesModel;
  onClickLink(event: React.MouseEvent<any>): void;
  onDownloadAudio(licenceId: string): void;
  onAttribution(licenceId: string): void;
  onViewOrder(licence: LicenceSchema): void;
  isMobile: boolean;
  preload: ImagePreloader;
  preloadWaveform: ImagePreloader;
  AddRemove: any;
  PlayButton: any;
  i18n?: {
    downloadAudio: string;
    downloadEULA: string;
    preparingEULA: string;
    attribution: string;
    viewOrder: string;
    empty: string;
  };
  analytics: AnalyticsController;
  user: UserModel;
  showChannelAddModal: any;
  sendMixpanel: any;
  location: string;
}

const constants = {
  tileSize: px(100),
};

@observer
export class PageAccountLicences extends React.Component<PageAccountLicencesProps, any> {
  render() {
    const {
      model,
      i18n = PageAccountLicences.i18n,
      onClickLink,
      isMobile,
      preload,
      preloadWaveform,
      AddRemove,
      PlayButton,
      onDownloadAudio,
      onViewOrder,
      onAttribution,
      analytics,
      user,
      user: { loading, loadingAccounts },
      showChannelAddModal,
      sendMixpanel,
      location,
    } = this.props;

    if (loadingAccounts || loading) {
      return (
        <AccountLayout mainContentClassName={PageAccountLicences.styles.mainContentClassName}>
          <Loading />;
        </AccountLayout>
      );
    }

    if (isEmpty(user.channel) && isEmpty(user.user.default_channel)) {
      return (
        <ConnectChannelPrompt
          showChannelAddModal={showChannelAddModal}
          sendMixpanel={sendMixpanel}
          location={location}
        />
      );
    }

    return (
      <div className={PageAccountLicences.styles.container}>
        {model.licences.length === 0 && <div className={PageAccountLicences.styles.empty}>{i18n.empty}</div>}
        {model.licences.map((licence, index) =>
          !isMobile ? (
            <div
              className={PageAccountLicences.styles.trackDesktop}
              key={index.toString()}
              data-test-licence-card={licence.track.identity}
            >
              <div className={PageAccountLicences.styles.trackDesktopTileContainer}>
                <TrackTile
                  analytics={analytics}
                  track={licence.track}
                  preload={preload}
                  noMeta={true}
                  noLabels={true}
                  PlayButton={PlayButton}
                  className={PageAccountLicences.styles.tile}
                  onClickLink={onClickLink}
                />
              </div>
              <div className={classes(PageAccountLicences.styles.trackDesktopMeta, PageAccountLicences.styles.meta)}>
                {licence.youtube_video_claims.length === 0 && (
                  <div className={PageAccountLicences.styles.readyToBeUsed}>Ready to use</div>
                )}

                {licence.youtube_video_claims.length > 0 && (
                  <div>
                    <div className={PageAccountLicences.styles.used}>Used</div>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://www.youtube.com/watch?v=${licence.youtube_video_claims[0].youtube_video_id}`}
                      data-test-youtube-video-link
                    >
                      <ExternalLink className={PageAccountLicences.styles.icon} size={14} />
                    </a>
                  </div>
                )}
                <div>
                  {licence.track.is_stock_music && (
                    <span className={PageAccountLicences.styles.trackType}>Included</span>
                  )}
                  {!licence.track.is_stock_music && (
                    <span className={PageAccountLicences.styles.trackType}>Premium</span>
                  )}
                </div>
                <a
                  className={PageAccountLicences.styles.link}
                  href={`/music/artists/${licence.track.artists[0].slug}/track/${licence.track.slug}`}
                  onClick={onClickLink}
                  data-test-track-link
                >
                  {licence.track.title}
                </a>
                <ArtistLinkList model={{ artists: licence.track.artists }} onClickLink={onClickLink} />
                <span data-test-channel-name className={PageAccountLicences.styles.channelAssociatedWithOrder}>
                  {licence.order.youtube_channels[0].name}
                </span>
                {licence.brand_sponsored && (
                  <div className={PageAccountLicences.styles.trackDesktopBrandSponsored}>For Brand Sponsored use</div>
                )}
              </div>

              <div className={PageAccountLicences.styles.trackDesktopDurationContainer}>
                <Duration value={licence.track.duration} />
              </div>
              <div className={PageAccountLicences.styles.trackDesktopAddRemoveContainer}>
                <AddRemove track={licence.track} />
              </div>
              <div className={PageAccountLicences.styles.actions}>
                <div className={PageAccountLicences.styles.buttons}>
                  <ButtonPill
                    muted={true}
                    onClick={onAttribution.bind(null, licence.identity)}
                    data-test-video-attribution-button
                  >
                    Video attribution
                  </ButtonPill>
                  <ButtonPill
                    muted={true}
                    onClick={onDownloadAudio.bind(null, licence.identity)}
                    data-test-download-audio-button
                  >
                    {i18n.downloadAudio}
                  </ButtonPill>
                </div>
                <div className={PageAccountLicences.styles.buttons}>
                  <ButtonPill
                    className={PageAccountLicences.styles.eulaButton}
                    disabled={!licence.eula_html_href}
                    muted={true}
                    href={licence.eula_html_href}
                    target="_blank"
                    data-test-eula-button
                  >
                    {licence.eula_html_href ? i18n.downloadEULA : i18n.preparingEULA}
                  </ButtonPill>
                  <ButtonPill muted={true} onClick={onViewOrder.bind(null, licence)} data-test-view-order-button>
                    {i18n.viewOrder}
                  </ButtonPill>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={PageAccountLicences.styles.trackMobile}
              key={index.toString()}
              data-test-licence-card={licence.track.identity}
            >
              <div className={PageAccountLicences.styles.trackMobileHeader}>
                <div className={PageAccountLicences.styles.metaMobile}>
                  <div>
                    <TrackTile
                      analytics={analytics}
                      track={licence.track}
                      preload={preload}
                      noMeta={true}
                      noLabels={true}
                      PlayButton={PlayButton}
                      AddRemove={AddRemove}
                      className={PageAccountLicences.styles.tile}
                      onClickLink={onClickLink}
                    />
                  </div>
                </div>
                <div className={classes(PageAccountLicences.styles.trackMobileMeta, PageAccountLicences.styles.meta)}>
                  {licence.youtube_video_claims[0] === undefined && (
                    <div className={PageAccountLicences.styles.readyToBeUsed}>Ready to use</div>
                  )}

                  {licence.youtube_video_claims[0] != undefined && (
                    <div>
                      <div className={PageAccountLicences.styles.used}>Used</div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://www.youtube.com/watch?v=${licence.youtube_video_claims[0].youtube_video_id}`}
                        data-test-youtube-video-link
                      >
                        <ExternalLink className={PageAccountLicences.styles.icon} size={14} />
                      </a>
                    </div>
                  )}
                  <div>
                    {licence.track.is_stock_music && (
                      <span className={PageAccountLicences.styles.trackType}>Included</span>
                    )}
                    {!licence.track.is_stock_music && (
                      <span className={PageAccountLicences.styles.trackType}>Premium</span>
                    )}
                  </div>
                  <a
                    className={PageAccountLicences.styles.link}
                    href={`/music/artists/${licence.track.artists[0].slug}/track/${licence.track.slug}`}
                    onClick={onClickLink}
                  >
                    {licence.track.title}
                  </a>
                  <ArtistLinkList model={{ artists: licence.track.artists }} onClickLink={onClickLink} />
                  <span className={PageAccountLicences.styles.channelAssociatedWithOrder}>
                    {licence.order.youtube_channels[0].name}
                  </span>
                  {licence.brand_sponsored && (
                    <div className={PageAccountLicences.styles.trackDesktopBrandSponsored}>For Brand Sponsored use</div>
                  )}
                </div>
                <div className={PageAccountLicences.styles.metaActionPrice}>
                  {formatCurrency(licence.amount, licence.order.locale.currency)}
                </div>
              </div>
              <div className={PageAccountLicences.styles.actions}>
                <ButtonPill
                  muted={true}
                  onClick={onAttribution.bind(null, licence.identity)}
                  data-test-video-attribution-button
                >
                  Video attribution
                </ButtonPill>
                <ButtonPill
                  muted={true}
                  onClick={onDownloadAudio.bind(null, licence.identity)}
                  data-test-download-audio-button
                >
                  {i18n.downloadAudio}
                </ButtonPill>
                <ButtonPill
                  className={PageAccountLicences.styles.eulaButton}
                  disabled={!licence.eula_html_href}
                  muted={true}
                  href={licence.eula_html_href}
                  target="_blank"
                  data-test-eula-button
                >
                  {licence.eula_html_href ? i18n.downloadEULA : i18n.preparingEULA}
                </ButtonPill>
                <ButtonPill muted={true} onClick={onViewOrder.bind(null, licence)} data-test-view-order-button>
                  {i18n.viewOrder}
                </ButtonPill>
              </div>
            </div>
          )
        )}
      </div>
    );
  }

  static i18n = {
    downloadAudio: 'Download audio',
    downloadEULA: 'View license',
    preparingEULA: 'Preparing license...',
    attribution: 'Video attribution',
    viewOrder: 'View order',
    empty: "Sorry you haven't got any licenses at the moment",
  };

  static styles = {
    container: style({
      position: 'relative',
      width: percent(100),
    }),
    mainContentClassName: style({ maxWidth: px(1376), padding: '0 16px' }),
    link: style({
      color: colorBrand.toString(),
      $nest: { ...resetAnchorStyles(colorBrand.toString()) },
    }),
    empty: style({
      ...centerCenter,
      height: px(120),
      fontWeight: 200,
      fontSize: px(18),
      color: colorSubtle.toString(),
    }),
    trackDesktop: style({
      ...horizontal,
      ...horizontallySpaced(20),
      ...center,
      padding: '10px 0',
    }),

    trackDesktopTileContainer: style({
      ...horizontal,
      ...startJustified,
      width: constants.tileSize,
      height: constants.tileSize,
    }),
    trackDesktopMeta: style({
      flex: 2,
      overflow: 'hidden',
    }),
    trackMobileMeta: style({
      flex: 1,
      marginLeft: '10px',
    }),
    trackDesktopBrandSponsored: style({
      margin: '10px 0 0 0',
      fontSize: em(0.7),
    }),
    trackDesktopWaveformContainer: style({
      ...centerCenter,
      position: 'relative',
      flex: 3,
      $nest: {
        '& img': {
          width: percent(100),
          height: px(30),
          borderRadius: px(50),
        },
      },
    }),
    trackDesktopDurationContainer: style({
      minWidth: px(70),
      ...horizontal,
      ...endJustified,
    }),
    trackDesktopAddRemoveContainer: style({
      ...horizontal,
      ...endJustified,
      minWidth: px(32),
    }),
    trackMobile: style({
      ...vertical,
      ...verticallySpaced(10),
      padding: '30px 0',
      borderBottom: `1px solid ${colorBackground.toString()}`,
      $nest: {
        '&:last-child': {
          borderBottom: 0,
        },
      },
    }),
    trackMobileHeader: style({
      ...horizontal,
      overflow: 'hidden',
      flex: 1,
    }),
    trackMobileFooter: style({
      ...horizontal,
      ...horizontallySpaced(20),
      ...startJustified,
    }),
    trackMobileFooterSequence: style({
      minWidth: px(32),
      fontSize: px(18),
      fontWeight: 'bold',
    }),
    trackMobileBrandSponsored: style({
      textAlign: 'right',
      margin: '10px 0 0 0',
      fontSize: em(0.7),
    }),
    trackMobileFooterMeta: style({
      flex: 1,
    }),
    tile: style({
      width: constants.tileSize,
    }),
    meta: style({
      lineHeight: em(1.1),
      fontSize: px(16),
      overflow: 'hidden',
      $nest: {
        '&> div': {
          overflow: 'hidden',
          whiteSpace: 'pre',
          textOverflow: 'ellipsis',
        },
        '&> div:nth-child(2)': {
          fontSize: px(12),
          color: colorSubtle.toString(),
        },
        '& a': {
          fontSize: px(12),
          color: important(colorSubtle.toString()),
        },
        '& a:hover': {
          color: important(colorBrand.toString()),
        },
      },
    }),
    metaMobile: style({
      ...horizontal,
      ...horizontallySpaced(20),
      ...center,
      $nest: {
        '&> *:nth-child(1)': {
          ...startJustified,
          minWidth: px(32),
          fontSize: px(18),
          fontWeight: 'bold',
        },
        '&> *:nth-child(2)': {
          ...horizontal,
          ...startJustified,
          minWidth: px(32),
        },
        '&> *:nth-child(3)': {
          flex: 1,
          ...horizontal,
          ...endJustified,
        },
        '&> *:nth-child(4)': {
          ...horizontal,
          ...endJustified,
          minWidth: px(32),
        },
      },
    }),
    metaAction: style({
      margin: '10px 0 0 0',
      $nest: {
        div: {
          fontSize: px(12),
        },
        'div svg': {
          marginLeft: px(10),
        },
        a: {
          fontSize: px(12),
        },
        'a svg': {
          marginLeft: px(10),
        },
      },
    }),
    metaActionDesktop: style({
      ...horizontal,
      ...horizontallySpaced(10),
      ...content,
      ...center,
      ...startJustified,
      textAlign: 'left',
    }),
    metaActionMobile: style({
      ...horizontal,
      ...horizontallySpaced(10),
      ...content,
      ...center,
      ...endJustified,
      textAlign: 'right',
    }),
    metaActionPrice: style(
      mediaUpToDesktop({
        alignSelf: 'flex-end',
      }),
      {
        fontSize: important('20px'),
      }
    ),
    actions: style(
      mediaDesktop({
        $nest: {
          '&> div:first-child': {
            marginBottom: '8px',
          },
        },
      }),
      mediaUpToDesktop({
        $nest: {
          '& *': {
            marginBottom: '2px',
          },
        },
      }),
      {
        ...vertical,
        ...betweenJustified,
        $nest: {
          div: {
            fontSize: px(12),
          },
          'div svg': {
            marginLeft: px(10),
          },
          a: {
            fontSize: px(12),
          },
          'a svg': {
            marginLeft: px(10),
          },
        },
      }
    ),
    eulaButton: style({
      $nest: {
        ...resetAnchorStyles(rebrand.dark1.toString(), true),
      },
    }),
    buttons: style({
      ...horizontal,
      $nest: {
        '& *': {
          justifySelf: 'flex-end',
          width: '125px',
          marginRight: '8px',
        },
      },
    }),
    readyToBeUsed: style({
      fontSize: '12px',
      border: `2px solid ${colorLickdPink.toHexString()}`,
      display: 'inline-block',
      borderRadius: '4px',
      padding: '0 4px',
    }),
    used: style({
      fontSize: '12px',
      backgroundColor: colorLickdMint.toHexString(),
      border: `2px solid ${colorLickdMint.toHexString()}`,
      display: 'inline-block',
      borderRadius: '4px',
      padding: '0 4px',
    }),
    icon: style({
      verticalAlign: 'middle',
      marginLeft: '2px',
    }),
    play: style({
      border: `1px solid ${colorBrand.toString()}`,
    }),
    channelAssociatedWithOrder: style({
      fontSize: px(14),
    }),
    trackType: style({ fontSize: px(12), color: colorNeutral.toHexString() }),
    containerConnectChannel: style({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    }),
    textConnectChannel: style({
      width: '675px',
      color: rebrand.contrast[40].toHexString(),
    }),
    connectChannelButton: style({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '37px',
    }),
  };
}
