import * as React from 'react';
import { Component, deps, inject, observer } from '../../../../../lib/component';
import { classes, style } from 'typestyle';
import { colorWhite, rebrand } from '../../../../../theme/color';
import { Checkmark } from '../../../atoms/icons/Checkmark';
import { ButtonProminence, ButtonSize } from '../../../atoms/button/buttonStyles';
import { Button } from '../../../atoms/button/Button';
import { User } from '../../../../../types/user';
import cancellationModalStyles from './styles';
import { V2Plan } from '../../../utilities/types';
import { StarCircle } from '../../../atoms/icons/StarCircle';
import { formatCurrency } from '../../../../../lib/currency';
import { CurvedArrowV2 } from '../../../atoms/icons/CurvedArrowV2';
import { scaleX } from 'csx';
import { spacing } from '../../../../../theme/spacing';
import { mediaMobileOnly } from '../../../../../theme/media';
import { PendingSubscriptionSchema } from '../../../../../types/schema';
import { borderRadius } from '../../../../../theme/border';
import { InfoFilled } from '../../../atoms/icons/InfoFilled';

const styles = {
  activePlanButton: style({
    display: 'flex',
    margin: '16px auto 0',
    width: 'fit-content',
  }),
  arrow: style({
    transform: scaleX(-1),
  }),
  cardsContainer: style({
    alignItems: 'center',
  }),
  card: style({
    height: 'fit-content',
  }),
  cardBody: style({
    marginTop: spacing.DEFAULT,
  }),
  note: style(
    {
      padding: spacing.DEFAULT,
      backgroundColor: colorWhite.toHexString(),
      borderRadius: borderRadius.MEDIUM,
      display: 'flex',
    },
    mediaMobileOnly({
      padding: 0,
      backgroundColor: 'transparent',
      borderRadius: 0,
    })
  ),
  noteHeader: style({
    fontSize: '16px',
    fontWeight: 700,
    textAlign: 'left',
  }),
  noteList: style({
    color: rebrand.contrast[40].toString(),
    fontSize: '16px',
    textAlign: 'left',
    margin: '0',
    paddingLeft: '22px',
    lineHeight: '24px',
  }),
  noteInfoIcon: style(
    {
      width: '24px',
      marginRight: spacing.XSMALL,
    },
    mediaMobileOnly({
      display: 'none',
    })
  ),
};

export interface ProAndPlusPlansContentProps {
  user: User;
  downgradePlan: V2Plan;
  currentPlan: V2Plan;
  handleCancellation(): void;
  handleDowngrade(nextPlan: V2Plan): void;
  pendingSubscription: PendingSubscriptionSchema;
}

@inject(deps)
@observer
export class ProAndPlusPlansContent extends Component<ProAndPlusPlansContentProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { user, downgradePlan, currentPlan, handleCancellation, handleDowngrade, pendingSubscription } = this.props;

    const [downgradeUnits, downgradeRemainder] = downgradePlan
      ? formatCurrency(downgradePlan.price / 100, downgradePlan.currency_code).split('.')
      : ['', ''];

    const [currentUnits, currentRemainder] = downgradePlan
      ? formatCurrency(currentPlan.price / 100, currentPlan.currency_code).split('.')
      : ['', ''];

    return (
      <div>
        <p className={cancellationModalStyles.message}>
          Hey {user.first_name}, did you know you can still downgrade your plan without losing your subscription
          benefits?
        </p>

        <div className={classes(cancellationModalStyles.cardsContainer, styles.cardsContainer)}>
          {downgradePlan && (
            <div className={classes(styles.card, cancellationModalStyles.card, cancellationModalStyles.shadowPrimary)}>
              <h3 className={cancellationModalStyles.cardTitleActive}>{downgradePlan.plan_name}</h3>

              <div className={cancellationModalStyles.cardBody}>
                <div className={cancellationModalStyles.planHeadingInfoText}>
                  <StarCircle color={rebrand.primary[100].toString()} size={20} />
                  {downgradePlan.plan_credits} Premium track credit{downgradePlan.plan_credits !== 1 ? 's' : ''}
                </div>

                <div className={cancellationModalStyles.pricingContentsSubscription}>
                  <span className={cancellationModalStyles.pricingContentLarge}>{downgradeUnits}</span>.
                  {downgradeRemainder}
                  <span className={cancellationModalStyles.pricingContentMonth}> /month</span>
                </div>
              </div>
            </div>
          )}

          <div
            className={classes(
              cancellationModalStyles.card,
              cancellationModalStyles.hideOnMobile,
              cancellationModalStyles.shadowSecondary
            )}
          >
            <h3 className={cancellationModalStyles.cardTitleDisabled}>{currentPlan.plan_name}</h3>

            <div className={classes(styles.cardBody, cancellationModalStyles.cardBody)}>
              {currentPlan.plan_credits && (
                <div
                  className={classes(cancellationModalStyles.textDisabled, cancellationModalStyles.planHeadingInfoText)}
                >
                  <StarCircle color={rebrand.neutralOnDark[50].toString()} size={20} />
                  {currentPlan.plan_credits} Premium track credit{currentPlan.plan_credits !== 1 ? 's' : ''}
                </div>
              )}

              <div
                className={classes(
                  cancellationModalStyles.pricingContentsSubscription,
                  cancellationModalStyles.textDisabled
                )}
              >
                <span
                  className={classes(cancellationModalStyles.pricingContentLarge, cancellationModalStyles.textDisabled)}
                >
                  {currentUnits}
                </span>
                .{currentRemainder}
                <span
                  className={classes(cancellationModalStyles.textDisabled, cancellationModalStyles.pricingContentMonth)}
                >
                  {' '}
                  /month
                </span>
              </div>

              <Button
                className={styles.activePlanButton}
                prominence={ButtonProminence.SECONDARY}
                size={ButtonSize.LARGE}
                onClick={void 0}
                data-test-downgrade-modal-button
                disabled
              >
                Plan active
                <Checkmark size={28} color={rebrand.neutralOnDark[50].toHexString()}></Checkmark>
              </Button>
            </div>
          </div>

          <CurvedArrowV2 className={classes(cancellationModalStyles.arrow, styles.arrow)} />
        </div>

        <div className={styles.note}>
          <div className={styles.noteInfoIcon}>
            <InfoFilled size={20} color={rebrand.contrast[40].toString()} />
          </div>
          <div>
            <div className={styles.noteHeader}>If you cancel:</div>
            <ul className={styles.noteList}>
              <li>
                You won&apos;t receive any Premium track credits, and any remaining will expire at the end of your
                billing date.
              </li>
              <li>You will pay a higher price for any Premium track licenses.</li>
              <li>You will no longer be able to use royalty-free music on Lickd.</li>
            </ul>
          </div>
        </div>

        <div className={cancellationModalStyles.buttonsContainer}>
          <Button
            className={cancellationModalStyles.button}
            prominence={ButtonProminence.PRIMARY}
            size={ButtonSize.LARGE}
            onClick={() => handleDowngrade(downgradePlan)}
            data-test-downgrade-modal-button
            disabled={!!pendingSubscription}
          >
            Downgrade my plan
          </Button>

          <Button
            className={classes(cancellationModalStyles.button)}
            prominence={ButtonProminence.SECONDARY}
            size={ButtonSize.LARGE}
            onClick={handleCancellation}
            data-test-downgrade-modal-button
          >
            I still want to cancel
          </Button>
        </div>
      </div>
    );
  }
}
