import * as React from 'react';
import { style } from 'typestyle';
import {centerCenter, content, endJustified, horizontal, start, vertical} from 'csstips';
import { colorBlack, colorWhite } from '../theme/color';
import {em, percent, px} from 'csx';
import { ResponsiveImageTile } from './responsive-image-tile';
import {ImagePreloader} from '../types/fn';
import {transitionQuickEase} from '../theme/transition';
import {PlayButtonCompat} from './compat/play-button-compat';
import {TrackSchema} from '../types/schema';
import FullWidthText from './full-width-text';
import {Component, deps, inject, observer} from '../lib/component';

export interface TagTileProps {
  tag: {
    name?: string;
    tag?: string;
    images?: {
      identity: string;
    }
  };
  className?: string;
  onClickLink(event: React.MouseEvent<any>): void;
  preload: ImagePreloader;
  ratio?: number;
  href: string;
  Favourite?: any;
}

@inject(deps) @observer
export class TagTile extends Component<TagTileProps> {
  render() {
    const { tag, ratio = 1, preload, className, href, onClickLink } = this.props;
    const label = tag.name || tag.tag || '';
    const { windowSizeHash } = this.props.model.ui;

    const props = {
      identity: tag.images ? tag.images.identity : void 0,
      onClick: onClickLink,
      preload,
      ratio,
      href,
      className,
    };

    return (
      <ResponsiveImageTile {...props}>
        <div title={label} className={TagTile.styles.container}>
          <FullWidthText className={TagTile.styles.name} refresh={windowSizeHash}>
            {label}
          </FullWidthText>
        </div>
      </ResponsiveImageTile>
    )
  }

  static styles = {
    container: style({
      ...centerCenter,
      ...vertical,
      ...content,
      textOverflow: 'hidden',
      overflow: 'hidden',
      width: percent(100),
    }),
    name: style({
      cursor: 'pointer',
      padding: px(20),
      fontSize: px(20),
      fontWeight: 'bold',
      lineHeight: em(1.1),
      width: percent(100),
      textAlign: 'center',
      textShadow: `0px 0px 6px ${colorBlack.toRGBA()};`,
      color: colorWhite.toString(),
      transition: `transform ${transitionQuickEase}`,
      $nest: {
        '&:hover': {
          transform: 'translate3d(0,-3px,0)'
        }
      }
    }),
    buttons: style({
      ...horizontal,
      ...endJustified,
      width: percent(100),
      padding: px(10)
    }),
    play: style({
      top: 'unset !important',
      left: 'unset !important',
      right: '8px !important',
      bottom: '8px !important',
    })
  }
}