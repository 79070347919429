import * as React from 'react';
import {BrowseResultsProps} from './results';
import gql from 'graphql-tag';
import {graphql, InjectedGraphQLProps} from 'react-apollo';
import {Component, deps, inject, observer} from '../../lib/component';
import {style} from 'typestyle';
import {important, viewHeight} from 'csx';
import {centerCenter} from 'csstips';
import {TagTileGrid, TagTileItem} from '../tile-grid';
import {gradientDark} from '../../theme/gradient';
import {TagTile} from '../tag-tile';
import {PaginationInput} from '../../types';

@inject(deps) @observer
class BrowseFilterGrid extends Component<Props & InjectedGraphQLProps<Data>> {
  render() {
    const {data} = this.props;
    const {handleClickLink} = this.props.controller.ui;
    const {preloadImage} = this.props.controller.image;

    const tiles = data && data.tiles && data.tiles.results
      ? data.tiles.results
      : [];

    return (
      <div className={BrowseFilterGrid.styles.container}>
        <TagTileGrid
          tiles={tiles}
          loading={data.loading}
          loadingTiles={12}
          loadingLight={true}
          renderTile={(itemClass: string, tile: TagTileItem, index: number) => (
            <TagTile
              className={itemClass}
              href={BrowseFilterGrid.tagPath(tile.slug)}
              key={tile.slug + index}
              tag={tile}
              preload={preloadImage}
              onClickLink={handleClickLink}
            />
          )}
        />
      </div>
    )
  }

  static tagPath = (slug: string) => {
    return `/browse/filter/${slug}`;
  };

  static styles = {
    container: style({
      background: gradientDark,
    }),
    loading: style({
      ...centerCenter,
      width: important('100%'),
      height: viewHeight(40)
    })
  };
}

type Props = {
  pagination: PaginationInput
}

type Item = {
  name: string;
  slug: string;
  images: {
    identity: string;
  }
}

type Data = {
  tiles: {
    results: Array<Item>
  }
}

export default BrowseFilterGrid;

export const ConnectedBrowseFilterGrid = graphql(gql`
  query (
    $pagination: PaginationInput!
  ) {
    tiles: browseFilter(options:{type:FILTER, pagination: $pagination}) {
      results {
        name: title
        slug
        images {
          identity
        }
      }
    }
  }
`, {
  options: (variables: BrowseResultsProps) => ({
    variables,
    // fetchPolicy: 'network-only'
  }),
})(BrowseFilterGrid);