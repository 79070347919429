import { NestedCSSProperties } from 'typestyle/lib/types';
import { colorBlueGrey, colorBrand, colorGreyAccent, colorGunmetal, colorWhite, rebrand } from '../../theme/color';
import { style } from 'typestyle';
import { em, percent, px } from 'csx';

const borderStyle = `${px(1)} solid ${colorGreyAccent.toHexString()}`;

export const TabsWrapper: NestedCSSProperties = {
  borderBottom: borderStyle,
};

export const TabsList: NestedCSSProperties = {
  display: 'flex',
  listStyle: 'none',
  justifyContent: 'space-between',
  margin: 0,
  padding: 0,
};

export const TabsListItem: NestedCSSProperties = {
  width: '100%',
  margin: '16px 8px 0',
  $nest: {
    '&:first-child': {
      marginLeft: px(16),
    },
    '&:last-child': {
      marginRight: px(16),
    },
  },
};

export const TabsButton: NestedCSSProperties = {
  width: percent(100),
  height: '50px',
  border: borderStyle,
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  textAlign: 'center',
  backgroundColor: rebrand.light1.toString(),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  fontWeight: 600,
  lineHeight: px(16),
  minWidth: px(16),
  $nest: {
    '&.selected': {
      borderBottom: 0,
      backgroundColor: rebrand.primary1.toString(),
      position: 'relative',
      color: 'white',
    },
    '&.selected::after': {
      content: '""',
      width: 0,
      height: 0,
      border: '8px solid transparent',
      borderBottomWidth: 0,
      borderTopColor: rebrand.primary1.toString(),
      position: 'absolute',
      bottom: px(-8),
      left: 'calc(50% - 8px)',
      zIndex: 1,
    },
  },
};

export const TabWrapper: NestedCSSProperties = {
  backgroundColor: colorWhite.toHexString(),
  padding: px(16),
};

export default {
  wrapper: style(TabsWrapper),
  list: style(TabsList),
  listItem: style(TabsListItem),
  tabButton: style(TabsButton),
  tabWrapper: style(TabWrapper),
};
