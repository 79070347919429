import React from 'react';
import { Button, ButtonProps } from './Button';
import { style } from 'typestyle';
import { ButtonProminence, ButtonSize } from './buttonStyles';
import { flexRoot, centerCenter } from 'csstips';
import { px } from 'csx';
import { mediaDesktop } from '../../utilities/mediaQueries';

const styles = {
  button: style(
    flexRoot,
    centerCenter,
    mediaDesktop({
      width: '50px',
      height: '40px',
    }),
    {
      fontSize: px(16),
      boxShadow: 'none',
      padding: '8px 12px',
      $nest: {
        '&:hover': { boxShadow: 'none' },
      },
    }
  ),
};

export const GoButton: React.SFC<ButtonProps> = (props) => (
  <Button className={styles.button} size={ButtonSize.SMALL} {...props} prominence={ButtonProminence.PRIMARY}>
    Go
  </Button>
);
