import * as React from 'react';
import { Component } from '../../lib/component';
import { PaginatedTable, PaginatedTableColumn } from '../table/paginated';
import { VideoThumbnail } from '../video/thumbnail';
import { FormattedMessage } from 'react-intl';
import { PaginationMeta } from '../../types';
import { YouTubeVideo } from '../../types/youtube';
import { style } from 'typestyle';
import { rem } from 'csx';
import { colorBrand, colorSubtle } from '../../theme/color';
import { SVGIcon } from '../svg-icon';

export interface AccountChannelVideosProps {
  isMobile: boolean;
  videos: Array<YouTubeVideo>;
  pagination: PaginationMeta;
  loadingMore: boolean;
  loadPage(page: number): any;
}

type PaginatedVideosTable = new () => PaginatedTable<YouTubeVideo>;
const PaginatedVideosTable = PaginatedTable;

export class AccountChannelVideos extends Component<AccountChannelVideosProps> {
  render() {
    return (
      <PaginatedVideosTable
        rows={this.props.videos}
        columns={AccountChannelVideos.Columns}
        isMobile={this.props.isMobile}
        loadingMore={this.props.loadingMore}
        pagination={this.props.pagination}
        pathRoot={null}
        loadPage={this.props.loadPage}
      />
    );
  }

  static Columns: Array<PaginatedTableColumn<YouTubeVideo>> = [
    {
      i18nKey: 'account.channel.videos.video.title',
      render: (video: YouTubeVideo) => <VideoThumbnail videoId={video.id} />,
    },
    {
      i18nKey: 'account.channel.videos.name.title',
      render: (video: YouTubeVideo) => (
        <div>
          <a target="_blank" href={`https://www.youtube.com/watch?v=${video.id}`}>
            <SVGIcon.NewTab color={colorSubtle.toString()} size={16} /> {video.name}
          </a>
        </div>
      ),
    },
    {
      i18nKey: 'account.channel.videos.statistics.title',
      render: ({ statistics }: YouTubeVideo) => {
        if (!statistics || !statistics.fetched_at) return <div />;
        return (
          <div>
            <FormattedMessage tagName="div" id="account.channel.videos.statistics.views" values={statistics} />
            <FormattedMessage tagName="div" id="account.channel.videos.statistics.comments" values={statistics} />
            <FormattedMessage tagName="div" id="account.channel.videos.statistics.favourites" values={statistics} />
            <FormattedMessage tagName="div" id="account.channel.videos.statistics.likes" values={statistics} />
            <FormattedMessage tagName="div" id="account.channel.videos.statistics.dislikes" values={statistics} />
            <div className={AccountChannelVideos.styles.detail}>
              <FormattedMessage
                id="account.channel.videos.statistics.fetched_at"
                values={{
                  fetched_at: new Date(statistics.fetched_at.timestamp * 1000),
                }}
              />
            </div>
          </div>
        );
      },
    },
    {
      i18nKey: 'account.channel.videos.published_at.title',
      render: (video: YouTubeVideo) => (
        <FormattedMessage
          id="account.channel.videos.published_at.value"
          values={{
            published_at: new Date(video.published_at.timestamp * 1000),
          }}
        />
      ),
    },
  ];

  static styles = {
    detail: style({
      fontSize: rem(0.75),
      color: colorSubtle.toString(),
    }),
  };
}
