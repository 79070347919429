import * as React from 'react';
import { Link } from './Link';
import { classes } from 'typestyle';
import { AnchorHTMLAttributes } from 'react';

export const menuLinkCssClassName = 'menu-link';

export type MenuLinkProps = Partial<AnchorHTMLAttributes<HTMLAnchorElement>> & {
  icon?: string;
  text: string;
  url: string;
  internal: boolean;
  className?: string;
};
export const MenuLink: React.FC<MenuLinkProps> = ({ icon, text, url, internal, className, tabIndex }) => {
  const children: React.ReactNode[] = [text];
  const props = { className: classes(menuLinkCssClassName, className), href: url, children, tabIndex };

  if (icon) {
    children.unshift(<img key="icon" src={icon} aria-hidden="true" />);
  }

  return internal === true ? <Link {...props} /> : <a {...props} />;
};
