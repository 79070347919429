import * as React from 'react';
import { Form } from '../form';
import { style } from 'typestyle';
import { px } from 'csx';
import { Component, observer, deps, inject } from '../../lib/component';
import { styles } from './styles';
import { Button } from '../project-happy/atoms/button/Button';
import { Input } from '../project-happy/atoms/controls/Input';
import { SVGIcon } from '../svg-icon';
import { rebrand } from '../../theme/color';
import { autorun } from 'mobx';
import { isEmpty } from '../project-happy/utilities/objects';
import { LOAD_STATE } from '../../types/api';
import { ButtonProminence, ButtonSize } from '../project-happy/atoms/button/buttonStyles';
import { SemanticInformationType } from '../project-happy/utilities/types';

const iconColours = {
  SUCCESS: rebrand.semantic[SemanticInformationType.SUCCESS][70].toHexString(),
  ERROR: rebrand.semantic[SemanticInformationType.ERROR][70].toHexString(),
};

@inject(deps)
@observer
export class CouponForm extends Component<Record<string, never>> {
  disposers = [];

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      interacted: false,
    };
  }

  componentDidMount() {
    this.disposers.push(
      autorun(() => {
        const { applyCoupon } = this.props.model.checkout;
        if (!applyCoupon) return;

        const apiValue = this.props.model.coupon.code;

        if (!this.state.interacted && typeof apiValue === 'string' && apiValue.length > 0) {
          this.setState(
            {
              value: apiValue,
            },
            () => this.apply()
          );
        }
      })
    );
  }

  componentWillUnmount() {
    this.disposers.forEach((dispose) => dispose());
  }

  apply(event?) {
    if (event) {
      event.preventDefault();
    }
    this.props.model.coupon.code = this.state.value;
    this.props.controller.checkout.cancelOrder();

    this.props.controller.checkout.deferredLoadOrder();
  }

  onChange(event) {
    this.setState({
      value: event.target.value,
    });

    if (!this.state.interacted) {
      this.setState({
        interacted: true,
      });
    }
  }

  render() {
    const { value } = this.state;
    const { user } = this.props.model.user;
    const { checkout, coupon } = this.props.model;

    const noUser = isEmpty(user);
    const loading = checkout.orderState === LOAD_STATE.LOADING;
    const applied = this.state.value.length > 0 && coupon.code === this.state.value && !loading;
    const invalidCoupon =
      checkout.orderState === LOAD_STATE.ERROR && checkout.globalError === "Sorry, this coupon doesn't exist.";

    return (
      <form onSubmit={this.apply.bind(this)} className={styles.checkoutSummaryCouponForm}>
        <label htmlFor="coupon-code" className={styles.couponLabel}>
          Coupon code
        </label>
        <Input
          type="text"
          id="coupon-code"
          name="coupon-code"
          value={value}
          onChange={this.onChange.bind(this)}
          disabled={noUser}
          placeholder="Enter code"
          data-test-coupon-input
        />

        <Button
          prominence={ButtonProminence.SECONDARY}
          size={ButtonSize.SMALL}
          type="submit"
          disabled={noUser || applied || loading}
          data-test-coupon-submit-button
        >
          {applied ? (
            <>
              {invalidCoupon ? (
                <>
                  Applied <SVGIcon.Cross color={iconColours.ERROR} className="checkout-coupon-btn-icon" size={10} />
                </>
              ) : (
                <>
                  Applied{' '}
                  <SVGIcon.Checkmark color={iconColours.SUCCESS} className="checkout-coupon-btn-icon" size={10} />
                </>
              )}
            </>
          ) : loading ? (
            'Loading...'
          ) : (
            'Apply'
          )}
        </Button>
      </form>
    );
  }

  static styles = {
    form: style({
      maxWidth: px(640),
    }),
  };
}
