import React from 'react';
import { Button, ButtonProps } from './Button';
import { classes, style } from 'typestyle';
import { ButtonSize, circleButton } from './buttonStyles';

const styles = style(circleButton);

export const CircleButton: React.SFC<ButtonProps> = ({ className, ...props }) => (
  <Button className={classes(styles, className)} size={ButtonSize.SMALL} {...props} />
);
