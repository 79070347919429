import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const ArrowRight: React.SFC<SVGIconProps> = ({ size = 16, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 16 16" {...props}>
    <path
      transform="translate(-4 -4)"
      fill={color}
      fillRule="evenodd"
      d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
    />
  </BaseSvg>
);
