import * as React from 'react';
import { Component } from '../../lib/component';
import barsWhite from '../../static/bars-white.svg';
import barsBrand from '../../static/bars-brand.svg';
import { classes, style } from 'typestyle';
import { centerCenter } from 'csstips';
import { isEmpty } from '../project-happy/utilities/objects';

export interface LoadingProps {
  className?: string;
  light?: boolean;
  size?: number;
  text?: string | JSX.Element;
}

export class Loading extends Component<LoadingProps> {
  render() {
    const { size = 48, className, light, text } = this.props;
    const style = { width: size };

    let wrappedText = null;
    if (typeof text === 'string') {
      wrappedText = <p>{text}</p>;
    } else if (!isEmpty(text)) {
      wrappedText = text;
    }

    return (
      <div data-test-page-loader className={classes(Loading.styles.container, className)}>
        <img src={light ? barsWhite : barsBrand} style={style} />
        {wrappedText}
      </div>
    );
  }

  static styles = {
    container: style({
      ...centerCenter,
      position: 'relative',
      flexDirection: 'column',
    }),
  };
}
