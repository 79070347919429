import { observable } from 'mobx';

export class ArtistlistModel {
  @observable
  sync: {[playlistId: string]: Array<string>} = {};

  @observable
  artists: {[playlistId: string]: Array<string>} = {};

  @observable
  removed: {[playlistId: string]: Array<string>} = {};
}