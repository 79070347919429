/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { style } from 'typestyle';
import { rebrand } from '../../../theme/color';
import { Component } from '../../../lib/component';

interface Props {
  page: number;
  duration: number;
  isPaused: boolean;
  onComplete: () => void;
}

const styles = {
  container: style({
    width: '100%',
    height: '4px',
    background: 'transparent',
    overflow: 'hidden',
  }),
  progress: style({
    height: '4px',
    background: rebrand.secondary[60].toString(),
    transformOrigin: 'left',
  }),
};

const initialState = {
  start: null,
  previousTimestamp: null,
  elapsed: 0,
  progress: 0,
  timer: null,
};

export default class ProgressBar extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isPaused !== this.props.isPaused) {
      if (this.props.isPaused) {
        this.stopTimer();
      } else {
        this.startTimer();
      }
    }

    // If the page changes, restart the timer
    if (prevProps.page !== this.props.page) {
      this.resetProgressAndTimer();
    }
  }

  componentDidMount(): void {
    this.startTimer();
  }
  componentWillUnmount(): void {
    this.stopTimer();
  }

  startTimer = () => {
    // We deduct `this.state.elapsed` from the current timestamp so that this function works when unpausing
    this.setState((prevState) => ({
      start: performance.now() - prevState.elapsed,
      timer: requestAnimationFrame(this.incrementProgress),
    }));
  };

  stopTimer = () => {
    if (this.state.timer) {
      cancelAnimationFrame(this.state.timer);
    }
  };

  incrementProgress = (timeStamp) => {
    // The start should have been set in `startTimer`, but just in case...
    if (this.state.start === null) {
      this.setState({ start: timeStamp });
    }

    // Calculate the time since the animation started
    const elapsed = timeStamp - this.state.start;
    // Calculate what fraction of the duration this equates to
    const progress = Math.min(elapsed / this.props.duration, 1);

    if (this.state.previousTimestamp !== timeStamp) {
      this.setState({
        elapsed,
        progress,
        previousTimestamp: timeStamp,
      });
    }

    // If the time elapsed is less than the duration, trigger the next update
    // Otherwise, set the animation to complete
    if (progress < 1) {
      this.setState({
        timer: requestAnimationFrame(this.incrementProgress),
      });
    } else {
      this.props.onComplete();
    }
  };

  resetProgressAndTimer = () => {
    this.stopTimer();

    this.setState({
      ...initialState,
      timer: this.props.isPaused ? null : requestAnimationFrame(this.incrementProgress),
    });
  };

  render() {
    const { progress } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.progress} style={{ transform: `scaleX(${progress})` }}></div>
      </div>
    );
  }
}
