import { PrismicRichText } from '../utilities/types';
import * as React from 'react';
import { renderPrismicRichTextArray } from '../utilities/props';
import { em, percent, px } from 'csx';
import { style } from 'typestyle';

const styles = {
  announcement: style({
    width: percent(100),
    textAlign: 'center',
    padding: px(16),
    fontSize: em(0.75),
    $nest: {
      '& img': {
        maxWidth: percent(75),
        height: 'auto',
      },
    },
  }),
  heading: style({
    fontSize: em(1.2),
    marginTop: 0,
    fontWeight: 600,
  }),
};

export type PrismicAnnouncementPayload = {
  primary: {
    date: string; // In the format YYYY-MM-DD
    heading: string;
    content: PrismicRichText;
  };
};
export type PrismicAnnouncementProps = {
  payload: PrismicAnnouncementPayload;
};
export const PrismicAnnouncement: React.SFC<PrismicAnnouncementProps> = ({
  payload: {
    primary: { heading, content },
  },
}) => (
  <div className={styles.announcement}>
    <h5 className={styles.heading}>{heading}</h5>
    {renderPrismicRichTextArray(content)}
  </div>
);
