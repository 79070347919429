import * as React from 'react';
import { classes, style } from 'typestyle';
import { FormInput } from './form-input';
import { horizontal, horizontallySpaced } from 'csstips';
import { FormFieldProps } from '../types/form';

export interface FormInputDateProps extends FormFieldProps {}

export interface FormInputDateState {
  day: string;
  month: string;
  year: string;
}

export class FormInputDate extends React.Component<FormInputDateProps, any> {
  private day: HTMLSelectElement;
  private month: HTMLSelectElement;
  private year: HTMLSelectElement;

  static getFormattedDate = (date: Date) => {
    return date.toISOString().split('T')[0];
  };

  static parseDate = (date: Date) => {
    if (!date) return { day: '', month: '', year: '' };

    return {
      day: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
    };
  };

  constructor(props) {
    super(props);
    this.state = FormInputDate.parseDate(new Date());
  }

  render() {
    const { day, month, year } = this.state;

    const className = classes(FormInput.styles.input, FormInput.styles.select, FormInputDate.styles.select);

    const commonProps = {
      disabled: this.props.disabled,
      required: this.props.required,
    };

    return (
      <div className={FormInputDate.styles.container}>
        <select
          {...commonProps}
          id={this.props.id + '-1'}
          className={classes(className, !day && FormInput.styles.inputEmpty)}
          ref={(el) => (this.day = el)}
          value={day}
          onChange={this.handleChangeDay}
        >
          <option value="">Day</option>
          {this.renderDays()}
        </select>
        <select
          {...commonProps}
          id={this.props.id + '-2'}
          className={classes(className, !month && FormInput.styles.inputEmpty)}
          ref={(el) => (this.month = el)}
          value={month}
          onChange={this.handleChangeMonth}
        >
          <option value="">Month</option>
          {this.renderMonths()}
        </select>
        <select
          {...commonProps}
          id={this.props.id + '-3'}
          className={classes(className, !year && FormInput.styles.inputEmpty)}
          ref={(el) => (this.year = el)}
          value={year}
          onChange={this.handleChangeYear}
        >
          <option value="">Year</option>
          {this.renderPreviousYears()}
        </select>
      </div>
    );
  }

  UNSAFE_componentWillMount() {
    this.handleValue(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleValue(nextProps);
  }

  handleValue = (props: FormInputDateProps) => {
    if (props.value) {
      this.setState(FormInputDate.parseDate(new Date(props.value)));
    }
  };

  handleChangeDay = (event: React.FormEvent<HTMLSelectElement>) => {
    this.setState({ day: parseInt(event.currentTarget.value) }, this.emitChange);
  };

  handleChangeMonth = (event: React.FormEvent<HTMLSelectElement>) => {
    this.setState({ month: parseInt(event.currentTarget.value) }, this.emitChange);
  };

  handleChangeYear = (event: React.FormEvent<HTMLSelectElement>) => {
    this.setState({ year: parseInt(event.currentTarget.value) }, this.emitChange);
  };

  emitChange = () => {
    const date = new Date(this.getDateString());
    const value = isNaN(date.getTime()) ? '' : FormInputDate.getFormattedDate(date);

    this.props.onChange(this.props.name, value);
  };

  renderDays = () => {
    return new Array(31).fill(1).map((_, index) => (
      <option key={index + 1} value={index + 1}>
        {index + 1}
      </option>
    ));
  };

  renderMonths = () => {
    return new Array(12).fill(1).map((_, index) => (
      <option key={index} value={index}>
        {index + 1}
      </option>
    ));
  };

  renderPreviousYears = () => {
    let year = new Date().getFullYear();
    return new Array(100)
      .fill(1)
      .map((_) => year--)
      .map((y) => (
        <option key={y} value={y.toString()}>
          {y}
        </option>
      ));
  };

  getDateString = () => {
    const { day, month, year } = this.state;

    return `${year}-${month + 1 < 10 ? 0 : ''}${month + 1}-${day < 10 ? 0 : ''}${day}`;
  };

  static styles = {
    container: style({
      ...horizontal,
      ...horizontallySpaced(10),
    }),
    select: style({
      width: 'auto',
      flex: 1,
    }),
  };
}
