import { action } from 'mobx';
import { RouterModel } from '../../router/model';
import { AuthModel } from '../../auth/model';
import { EnvModel } from '../../env/model';
import { SESSION_ONBOARDING_PLAN } from '../../../constants';
import { SubscriptionPlans } from '../../../components/project-happy/utilities/types';
import { RegistrationPageModel } from '../registration/model';

export class SigninPageController {
  constructor(private router: RouterModel, private auth: AuthModel, private registration: RegistrationPageModel) {}

  @action
  onEnter = async () => {
    if (this.auth.token) this.router.replace('/');
    const searchParams = new URLSearchParams(this.router.location.search);
    const planParam = searchParams.get('plan');
    if (Object.values(SubscriptionPlans).includes(planParam)) {
      if (!EnvModel.isServer) {
        window.sessionStorage.setItem(SESSION_ONBOARDING_PLAN, planParam);
      }
      this.registration.plan = planParam as SubscriptionPlans;
    }
  };
}
