import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { observable, ObservableSet } from 'mobx';
import { Component, ComponentProps, deps, inject, observer } from '../../../lib/component';
import { renderComponentsFromPrismicSlices } from './index';
import { PrismicSlice } from '../utilities/types';
import { EnvModel } from '../../../modules/env/model';
import { Loading } from '../../loading/loading';
import { isEmpty } from '../utilities/objects';
import { PrismicContentData } from '../../../modules/prismic-content-islands/model';

const dataQueue: Map<HTMLElement, PrismicContentData> = new Map();
const elements: ObservableSet<HTMLElement> = observable.set();

if (
  !EnvModel.isServer &&
  HTMLElement &&
  typeof window.customElements === 'object' &&
  window.customElements !== null &&
  typeof window.customElements.define === 'function'
) {
  class PrismicContentElement extends HTMLElement {
    constructor() {
      super();
    }

    connectedCallback() {
      const documentUid = this.getAttribute('document-uid');
      if (typeof documentUid !== 'string' || documentUid.length === 0) {
        throw Error('PrismicContentElement: No Prismic document ID specified');
      }
      dataQueue.set(this, documentUid);
      elements.add(this);
    }

    disconnectedCallback() {
      elements.delete(this);
      dataQueue.delete(this);
    }
  }

  window.customElements.define('prismic-content', PrismicContentElement);

  class PrismicSliceElement extends HTMLElement {
    private config;

    constructor() {
      super();
    }

    setConfiguration(config: PrismicSlice) {
      if (isEmpty(config)) throw Error('PrismicSliceElement: Slice configuration is empty');
      this.config = config;
    }

    connectedCallback() {
      if (this.hasAttribute('configuration')) {
        try {
          this.setConfiguration(JSON.parse(this.getAttribute('configuration')));
        } catch {
          throw Error('PrismicSliceElement: Slice configuration is not valid JSON');
        }
      }
      dataQueue.set(this, this.config);
      elements.add(this);
    }

    disconnectedCallback() {
      elements.delete(this);
      dataQueue.delete(this);
    }
  }

  window.customElements.define('prismic-slice', PrismicSliceElement);
}

@inject(deps)
@observer
export class ExternalPrismicContentIslands extends Component<ComponentProps> {
  componentDidUpdate() {
    const { fetchPrismicContent } = this.props.controller.prismicContentIslands;
    dataQueue.forEach((dataRequest, element) => {
      fetchPrismicContent(element, dataRequest);
      dataQueue.delete(element);
    });
  }

  render() {
    const { contentIslandData } = this.props.model.prismicContentIslands;
    return (
      <>
        {Array.from(elements.values()).map((element, key) => {
          if (!contentIslandData.has(element)) return ReactDOM.createPortal(<Loading key={key} />, element);
          const { slices, context } = contentIslandData.get(element);
          return ReactDOM.createPortal(<>{renderComponentsFromPrismicSlices(slices, context)}</>, element);
        })}
      </>
    );
  }
}
