import { CareersPageModel } from './model';
import { action } from 'mobx';
import { RedirectFunction, RouterState } from 'react-router';
import { PrismicController } from '../../prismic/controller';
import { BugsnagController } from '../../bugsnag/controller';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import Prismic from 'prismic-javascript';
import { RouterModel } from '../../router/model';
import { stripQueryString } from '../../../lib/string';
import AppBreadcrumbs from '../../../components/app-breadcrumbs';

export class CareersPageController {
  constructor(
    private model: CareersPageModel,
    private prismic: PrismicController,
    private bugsnag: BugsnagController,
    private env: EnvModel,
    private ui: UIController,
    private router: RouterModel,
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    const slug = stripQueryString(nextState.params['slug']) || null;

    this.model.loading = true;
    this.model.slug = slug;

    try {
      const predicates = [
        Prismic.Predicates.at('document.type', 'careers'),
        slug
          ? Prismic.Predicates.at('my.careers.slug', this.model.slug)
          : Prismic.Predicates.missing('my.careers.slug'),
      ];

      const breadCrumbs = [
        {
          path: '/careers',
          label: 'Careers',
        },
      ];

      if (slug)
        breadCrumbs.push({
          path: '/careers/' + slug,
          label: AppBreadcrumbs.LOADING,
        });

      breadCrumbs[breadCrumbs.length - 1].label = AppBreadcrumbs.LOADING;

      this.ui.setBreadcrumbs(breadCrumbs);

      const [{ results }] = await Promise.all([this.prismic.query(predicates), this.env.ready]);

      if (results.length === 0 && this.router.replace !== null) {
        return this.router.replace('/');
      }

      const { data } = results[0];

      await this.ui.setSEO(slug ? `/careers/role` : `/careers`, { role: data.document_title });

      this.model.page_title = data.page_title;
      this.model.page_subtitle = data.page_subtitle;
      this.model.typeform_url = data.typeform_url;
      this.model.cover_image = data.cover_image.url;
      this.model.cta_title = data.cta_title;
      this.model.cta_content = data.cta_content;
      this.model.cta_button = data.cta_button;

      const sectionsSlice = data.body.find((slice: any) => slice.slice_type === 'sections');
      this.model.sections = !sectionsSlice
        ? []
        : sectionsSlice.items.map((item: any) => ({
            heading: item.heading,
            content: item.content,
            image: item.image.url ? item.image.url : null,
            video: item.video.html ? encodeURIComponent(item.video.html) : null,
          }));

      if (!this.model.slug) {
        const currentOpenings = data.body.find((slice: any) => slice.slice_type === 'current_openings');
        this.model.currentHeading = !currentOpenings ? null : currentOpenings.primary.heading;
        this.model.currentDescription = !currentOpenings ? null : currentOpenings.primary.description;
        this.model.currentOpenings = !currentOpenings ? [] : (currentOpenings.items || []).filter((p) => !!p.job_title);

        const upcomingPositions = data.body.find((slice: any) => slice.slice_type === 'upcoming_positions');
        this.model.upcomingHeading = !upcomingPositions ? null : upcomingPositions.primary.heading;
        this.model.upcomingDescription = !upcomingPositions ? null : upcomingPositions.primary.description;
        this.model.upcomingPositions = !upcomingPositions
          ? []
          : (upcomingPositions.items || []).filter((p) => !!p.job_title);
      }

      this.ui.setLastBreadcrumbLabel(data.page_title);
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      this.model.loading = false;
    }
  };
}
