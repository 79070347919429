import * as React from 'react';
import { ArtistSchema } from '../../../types/schema';
import { ReactNodeArray } from 'react';
import { Link } from '../atoms/Link';

export const ArtistLinkList = (artists: Array<Pick<ArtistSchema, 'name' | 'slug' | 'highlight'>>): ReactNodeArray =>
  artists.reduce((acc, artist, index, array) => {
    acc.push(
      <Link title={artist.name} key={index} href={`/music/artists/${artist.slug}`}>
        {artist.name}
      </Link>
    );
    if (index + 1 < array.length) acc.push(', ');
    return acc;
  }, []);
