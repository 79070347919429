import * as React from 'react';
import { ModalProps } from '../../modal';
import { ScrollableOverlay } from '../../scrollable-overlay/scrollable-overlay';
import overlayStyles, { ScrollableOverlayBodyStyle } from '../../../styles/scrollable-overlay';
import { style } from 'typestyle';
import { px, rem, url } from 'csx';
import { Component, deps, inject, observer } from '../../../lib/component';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { rebrand } from '../../../theme/color';
import { fontBebasNeue } from '../../../theme/font';
import { mediaDesktop, mediaLargeTablet } from '../utilities/mediaQueries';
import { ArrowRight } from '../atoms/icons/ArrowRight';
import { borderRadius } from '../../../theme/border';
import { spacing } from '../../../theme/spacing';
import { CheckmarkCircle } from '../atoms/icons/CheckmarkCircle';
import MintLabel from '../../../static/tile-icon-label-green.png';
import { isEmpty } from '../utilities/objects';

const styles = {
  wrapper: style({ display: 'block', maxWidth: px(440) }),
  body: style(ScrollableOverlayBodyStyle, {
    padding: '1.5rem 0 0',
    borderTop: `1px solid ${rebrand.light1.toString()}`,
    textAlign: 'center',
    $nest: {
      '& > p': {
        margin: '0 0 1.5rem',
      },
    },
  }),
  bannerWrapper: style({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing.DEFAULT,
    marginBottom: rem(1.5),
  }),
  banner: style({
    position: 'relative',
    height: px(64),
    width: px(64),
    backgroundColor: rebrand.contrast[20].toString(),
    borderRadius: borderRadius.SMALL,
    overflow: 'hidden',
  }),
  bannerLabel: style({
    position: 'absolute',
    top: 0,
    left: 0,
    width: px(32),
    height: px(29),
    backgroundImage: url(MintLabel),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderTopLeftRadius: 'inherit',
    zIndex: 1,
  }),
  bannerIcon: style({
    position: 'absolute',
    top: px(4),
    left: px(4),
  }),
  bannerText: style({
    fontSize: rem(0.75),
    display: 'flex',
    alignItems: 'center',
    gap: rem(0.25),
  }),
  button: style({
    display: 'inline-flex',
    alignItems: 'center',
    gap: rem(0.5),
  }),
};

export type BasketUnsubscribedModalProps = ModalProps & { className?: string };

@inject(deps)
@observer
export class BasketUnsubscribedModal extends Component<BasketUnsubscribedModalProps> {
  constructor(props) {
    super(props);
  }

  handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const target = e.currentTarget as HTMLAnchorElement;

    // Fire analytics event
    this.props.controller.analytics.sendMixpanel('User clicks view plans (unsubscribed included modal)');

    // Close the modal and redirect to the link address
    this.props.onRequestClose();
    this.props.model.router.push(target.pathname);
  };

  render() {
    const {
      model: {
        user: { user },
      },
    } = this.props;

    // Logged in users without a subscription should be redirected to the plans page
    const plansUrl = isEmpty(user) ? '/sign-up' : '/account/plans';

    return (
      <ScrollableOverlay className={styles.wrapper} {...this.props}>
        <div className={overlayStyles.header}>
          <h3 className={overlayStyles.title}>Subscribe to download</h3>
        </div>

        <div className={styles.body}>
          <div className={styles.bannerWrapper}>
            <div className={styles.banner}>
              <span className={styles.bannerLabel}>
                <CheckmarkCircle
                  size={16}
                  className={styles.bannerIcon}
                  color={rebrand.dark1.toString()}
                  checkColor={rebrand.highlight1.toString()}
                />
              </span>
            </div>
            <div className={styles.bannerText}>
              <span>Included</span>
              <CheckmarkCircle size={16} color={rebrand.dark1.toString()} />
            </div>
          </div>

          <p>
            <strong>This track requires a subscription to license</strong>
          </p>
          <p>With a subscription you get unlimited access to all royalty-free music on Lickd.</p>

          <div>
            <Button
              prominence={ButtonProminence.PRIMARY}
              size={ButtonSize.MEDIUM}
              className={styles.button}
              href={plansUrl}
              onClick={this.handleClick}
              data-test-unsubscribed-basket-modal-button
            >
              View plans
              <ArrowRight size={14} />
            </Button>
          </div>
        </div>
      </ScrollableOverlay>
    );
  }
}
