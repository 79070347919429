import { RequestController } from '../request/controller';
import { CacheController } from '../cache/controller';
import { PaginationMeta } from '../../types';
import { User } from '../../types/user';
import { EnvModel } from '../env/model';
import { AnyObject, isEmpty } from '../../components/project-happy/utilities/objects';

export class API {
  constructor(protected request: RequestController, protected cache: CacheController, protected env: EnvModel) {}
}

export enum APIErrorType {
  UNKNOWN,
  SERVER,
  CLIENT,
  NETWORK,
}

export class APIError<T = never> extends Error {
  code: number;
  reason: string;
  isServerError: boolean;
  isClientError: boolean;
  isRateLimitError: boolean;
  isNetworkError: boolean;
  type: APIErrorType;

  constructor(httpError: number, message: string, public payload?: T) {
    super(`HTTP ${httpError}: ${message}`);
    this.name = 'APIError';
    this.code = httpError;
    this.reason = message;
    this.isServerError = httpError >= 500 && httpError <= 599;
    this.isClientError = httpError >= 400 && httpError <= 499;
    this.isRateLimitError = httpError === 429;
    this.isNetworkError = httpError === -1;
    this.type = APIErrorType.UNKNOWN;
    if (this.isServerError) {
      this.type = APIErrorType.SERVER;
    } else if (this.isClientError) {
      this.type = APIErrorType.CLIENT;
    } else if (this.isNetworkError) {
      this.type = APIErrorType.NETWORK;
    }
    if (typeof Error.captureStackTrace === 'function') {
      // Maintains proper stack trace for where our error was thrown (only available on V8)
      Error.captureStackTrace(this, APIError);
    }
  }

  toJSON() {
    const { name, message, stack, code, type } = this;
    return {
      name,
      message,
      stack,
      code,
      type: APIErrorType[type], // Get the enum name for human readable JSON
    };
  }

  static async checkResponse<T = never>(response: Response) {
    if (!response.ok) {
      let message = response.statusText;
      let payload;
      try {
        const json = await response.json();
        const captureError = (obj: Record<string, any>) => {
          if (isEmpty(obj)) return false;
          if ('message' in obj && typeof obj.message === 'string') {
            message = obj.message;
          }
          payload = obj;
          return true;
        };
        if (typeof json.error === 'string') {
          message = json.error;
        } else {
          [json.error, json.errors].some(captureError);
        }
      } catch {
        // Likely a JSON parsing error, fallback to HTTP status text
      }
      throw new APIError<T>(response.status, message, payload);
    }
  }
}

export type FormErrors = { [key: string]: string[] };

export interface APIResponse<T> {
  data: T;
  status?: boolean;
  success?: boolean;
  error: string | { [key: string]: string };
}

export interface APIPaginatedResponse<T> {
  data: T;
  meta: {
    pagination: PaginationMeta;
  };
}

export const getEmptyPaginatedResponse = <P>(data: P[] = []): APIPaginatedResponse<P[]> => ({
  data,
  meta: { pagination: { count: 0, current_page: 1, per_page: 25, total: 0, total_pages: 1 } },
});

export interface UserStatusResponse {
  status: boolean;
  user?: User;
}
