import * as React from 'react';
import { observer } from 'mobx-react';
import { extend, style } from 'typestyle';
import { colorGreyAccent, colorLightBlueGrey, colorWhite, rebrand } from '../../theme/color';
import { ScrollableOverlay } from '../scrollable-overlay/scrollable-overlay';
import { ScrollableOverlayMobileOffset, ScrollableOverlayTabletOffset } from '../../styles/scrollable-overlay';
import { IObservableArray } from 'mobx';
import Tabs from '../tabs/tabs';
import { px } from 'csx';
import { abbreviateAmount } from '../../lib/helpers';
import { RatecardSchema, TrackSchema } from '../../types/schema';
import { Component, ComponentProps, deps, inject } from '../../lib/component';
import { mediaTablet } from '../../theme/media';
import { center, flexRoot, startJustified, vertical } from 'csstips';
import { IconSquare } from '../project-happy/atoms/icons/IconSquare';
import { Eye } from '../project-happy/atoms/icons/Eye';
import { contentHeadingStyles } from '../project-happy/utilities/font';
import { Copyright } from '../project-happy/atoms/icons/Copyright';
import { Clock } from '../project-happy/atoms/icons/Clock';
import { Clipboard } from '../project-happy/atoms/icons/Clipboard';
import { AudioFile } from '../project-happy/atoms/icons/AudioFile';
import { ClockShortTimeDuotone } from '../project-happy/atoms/icons/ClockShortTimeDuotone';
import { ClockLongTimeDuotone } from '../project-happy/atoms/icons/ClockLongTimeDuotone';
import { centerCenter } from 'csstips/lib/flex';
import { isEmpty } from '../project-happy/utilities/objects';

const styles = {
  wrapper: style({
    backgroundColor: colorLightBlueGrey.toHexString(),
    overflow: 'hidden',
  }),
  body: style(
    {
      overflowY: 'scroll',
      maxHeight: `calc(100vh - ${ScrollableOverlayMobileOffset} - 102px)`,
      $nest: {
        '& h4': extend(contentHeadingStyles, {
          fontWeight: 600,
          marginBottom: 0,
          $nest: { '& + p': { marginTop: px(16) } },
        }),
        '& h5:first-child': {
          marginTop: 0,
        },
        '& hr': {
          margin: '0 0 0 -16px',
          width: 'calc(100% + 32px)',
          borderWidth: '0 0 1px 0',
          borderColor: rebrand.light1.toString(),
          borderStyle: 'double',
        },
      },
    },
    mediaTablet({
      maxHeight: `calc(100vh - ${ScrollableOverlayTabletOffset} - 102px)`,
    })
  ),
  header: style({
    height: px(48),
  }),
  heading: style({
    textAlign: 'center',
    fontSize: px(24),
    lineHeight: px(47),
    margin: 0,
    backgroundColor: 'white',
    borderBottom: `1px solid ${rebrand.light1.toString()}`,
  }),
  subheading: style({
    textAlign: 'center',
  }),
  tracks: style({
    padding: 0,
    backgroundColor: rebrand.dark1.toString(),
    color: 'white',
    borderRadius: px(4),
    overflow: 'hidden',
  }),
  track: style(flexRoot, startJustified, center, {
    $nest: {
      ':first-child ~ &': {
        borderTop: '1px solid black',
      },
      '& > :first-child ~ *': {
        marginLeft: px(8),
      },
      '& p': {
        margin: 0,
        fontSize: px(12),
      },
      '& .track': {
        fontWeight: 600,
      },
      '& .p-line': {
        fontSize: px(10),
        color: rebrand.light2.toString(),
      },
    },
  }),
  trackCover: style({
    height: px(65),
    flexShrink: 0,
  }),
  timeFrameInfographic: style(flexRoot, centerCenter, vertical, {
    $nest: {
      '& p': {
        fontWeight: 700,
        color: rebrand.primary1.toString(),
      },
    },
  }),
};

const VideoPrivacy = () => (
  <>
    <IconSquare>
      <Eye />
    </IconSquare>
    <h4>Video Privacy</h4>
    <p>
      <strong>Set each video&apos;s privacy to &lsquo;Unlisted&rsquo;</strong> until the claims are cleared, that way
      the copyright holder can see the video in their content management system and release the claim.
    </p>
  </>
);

const KeyLicenseTerms = ({ viralViews }) => (
  <>
    <IconSquare>
      <Clipboard />
    </IconSquare>
    <h4>Key License Terms</h4>
    <p>Make sure you’ve read the License Agreement but we’ve pulled out some key things to know.</p>
    <ul>
      <li>
        You can only use this track in a video on a YouTube channel you’ve registered with Lickd, subject to the terms
        and restrictions set out in the license agreement.
      </li>
      <li>This track can’t be used in any ad, sponsored video, branded content or endorsement.</li>
      <li>This track can’t be used for a song or lyric video.</li>
      <li>You must include the correct attribution for this track in your video’s description.</li>
      <li>If you breach the license agreement your content could be taken down.</li>
    </ul>
  </>
);

const Mp3Info = () => (
  <>
    <IconSquare>
      <AudioFile />
    </IconSquare>
    <h4>MP3</h4>
    <p>
      An MP3 is included with every license you purchase so you can get editing right away. You’ll notice the download
      audio functionality within the Licenses area of Account.
    </p>
  </>
);

export interface ThingsYouNeedToKnowModalProps {
  show: boolean;
  ratecard: RatecardSchema;
  eulaContent: Error | IObservableArray<any> | null; // Using any instead of unknown given old TS version
  onRequestClose(): void;
  coupon?: any;
  track?: TrackSchema;
}

@observer
@inject(deps)
export class ThingsYouNeedToKnowModal extends Component<ThingsYouNeedToKnowModalProps> {
  state = {
    selectedTab: 0,
  };

  get initialTab() {
    if (isEmpty(this.props.track) || this.props.model.checkout.claimsManagedTracks.length === 0) return 0;
    return this.props.track.reporting_party.slug === 'umg' ? 0 : 1;
  }

  constructor(props) {
    super(props);
    this.setTab = this.setTab.bind(this);
  }

  setTab(index: number) {
    this.setState({
      selectedTab: index,
    });
    if (index === 0) {
      this.props.model.checkout.claimsManagedRead = true;
    } else if (index === 1) {
      this.props.model.checkout.claimsFreeRead = true;
    }
  }

  componentDidUpdate(prevProps: Readonly<ThingsYouNeedToKnowModalProps & ComponentProps>) {
    if (prevProps.track !== this.props.track) {
      this.setTab(this.initialTab);
    }
  }

  render() {
    const {
      show,
      onRequestClose,
      ratecard,
      model: {
        image: { cover },
        checkout: { claimsManagedTracks, claimsFreeTracks },
      },
    } = this.props;
    const { selectedTab } = this.state;

    const viralViews = ratecard ? (ratecard.viewership * 3 > 500000 ? ratecard.viewership * 3 : 500000) : 500000;

    type TrackSchemaWithPLine = TrackSchema & { p_line?: string };
    const claimsManaged = (claimsManagedTracks as any) as TrackSchemaWithPLine[];
    const claimsFree = (claimsFreeTracks as any) as TrackSchemaWithPLine[];

    return (
      <ScrollableOverlay show={show} onRequestClose={onRequestClose}>
        <span data-test-tyntk-modal className={styles.wrapper}>
          <header className={styles.header}>
            <h3 className={styles.heading}>Things you need to know</h3>
          </header>
          <main className={styles.wrapper}>
            <Tabs selected={selectedTab} onSelect={this.setTab}>
              {claimsManaged.length > 0 && (
                <Tabs.Tab className={styles.body} heading="Claims-Managed">
                  <IconSquare>
                    <Copyright />
                  </IconSquare>
                  <h4>Additional Copyright Holder</h4>
                  <p>
                    The record label <strong>Universal Music Group</strong> hold the copyright and request to manually
                    review licenses as per an agreement with Lickd.
                  </p>
                  <h5>Tracks</h5>
                  <ul className={styles.tracks}>
                    {claimsManaged.map((track, index) => {
                      const artists = track.artists
                        .map(function (artist) {
                          return artist.name;
                        })
                        .join(', ');
                      return (
                        <li className={styles.track} key={index}>
                          <img
                            className={styles.trackCover}
                            src={cover(track.images.identity)}
                            height={65}
                            width={65}
                          />
                          <div>
                            <p className="track">{track.title}</p>
                            <p>{artists}</p>
                            <p className="p-line">{track.p_line}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <hr />
                  <VideoPrivacy />
                  <hr />
                  <IconSquare>
                    <Clock />
                  </IconSquare>
                  <h4>Time Frames</h4>
                  <p>
                    <strong>We aim to sort claims within 48 hours.</strong> Please allow enough time before publishing
                    your video, so the claim can be reviewed and cleared by the copyright holder.
                  </p>
                  <div className={styles.timeFrameInfographic}>
                    <ClockLongTimeDuotone />
                    <p>within 48 hours</p>
                  </div>
                  <p>This timeframe is just a guide and may be longer for some copyright holders.</p>
                  <hr />
                  <KeyLicenseTerms viralViews={viralViews} />
                  <hr />
                  <Mp3Info />
                </Tabs.Tab>
              )}
              {claimsFree.length > 0 && (
                <Tabs.Tab className={styles.body} heading="Automated claims clearance">
                  <h5>Tracks</h5>
                  <ul className={styles.tracks}>
                    {claimsFree.map((track, index) => {
                      const artists = track.artists
                        .map(function (artist) {
                          return artist.name;
                        })
                        .join(', ');
                      return (
                        <li className={styles.track} key={index}>
                          <img
                            className={styles.trackCover}
                            src={cover(track.images.identity)}
                            height={65}
                            width={65}
                          />
                          <div>
                            <p className="track">{track.title}</p>
                            <p>{artists}</p>
                            <p className="p-line">{track.p_line}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <hr />
                  <VideoPrivacy />
                  <hr />
                  <IconSquare>
                    <Clock />
                  </IconSquare>
                  <h4>Time Frames</h4>
                  <p>
                    <strong>We aim to sort claims within 30 minutes.</strong> Please allow enough time before publishing
                    your video, so the claim can be reviewed and cleared by the Vouch™ software.
                  </p>
                  <div className={styles.timeFrameInfographic}>
                    <ClockShortTimeDuotone />
                    <p>within 30 minutes</p>
                  </div>
                  <p>This timeframe is just a guide and may be longer for some copyright holders.</p>
                  <hr />
                  <KeyLicenseTerms viralViews={viralViews} />
                  <hr />
                  <Mp3Info />
                </Tabs.Tab>
              )}
            </Tabs>
          </main>
        </span>
      </ScrollableOverlay>
    );
  }
}
