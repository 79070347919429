import { ImgHTMLAttributes, SourceHTMLAttributes } from 'react';
import * as React from 'react';
import { classes, extend, media, style } from 'typestyle';
import { em, percent, px, url } from 'csx';
import { desktopBreakpoint, largeMobileBreakpoint } from '../config';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { mediaDesktop, mediaUpToLargeMobile } from '../utilities/mediaQueries';
import { resolveBackgroundProps } from '../utilities/props';
import { parseImageUrl } from '../../../modules/image/model';
import defaultPlaylistImage from '../../../static/searchpage-150x150.jpeg';

const styles = style({
  width: percent(100),
  height: percent(100),
  display: 'block',
});

type CarouselImageProps = Omit<SourceHTMLAttributes<HTMLSourceElement>, 'src'> & {
  alt?: ImgHTMLAttributes<HTMLImageElement>['alt'];
  identity?: string;
  isTopSolutionImage?: boolean;
};

export const getCarouselBackgroundImage = (src: string, square = false): NestedCSSProperties => {
  let baseUrl, ext;
  try {
    ({ baseUrl, ext } = parseImageUrl(src));
  } catch {
    return resolveBackgroundProps({ src });
  }
  const getDimensions = (size: number) => `${square ? size : '0'}x${size}`;
  const prefix = square ? '?fit=crop' : '';
  return extend(
    mediaUpToLargeMobile({
      backgroundImage: [
        url(`${baseUrl}-${getDimensions(200)}.${ext}${prefix}`),
        `-webkit-image-set(${url(`${baseUrl}-${getDimensions(200)}.${ext}${prefix}`)} 1x, ${url(
          `${baseUrl}-${getDimensions(300)}.${ext}${prefix}`
        )} 1.5x, ${url(`${baseUrl}-${getDimensions(400)}.${ext}${prefix}`)} 2x)`,
        `image-set(${url(`${baseUrl}-${getDimensions(200)}.${ext}${prefix}`)} 1x, ${url(
          `${baseUrl}-${getDimensions(300)}.${ext}${prefix}`
        )} 1.5x, ${url(`${baseUrl}-${getDimensions(400)}.${ext}${prefix}`)} 2x)`,
      ],
    }),
    media(
      { minWidth: largeMobileBreakpoint, maxWidth: desktopBreakpoint - 1 },
      {
        backgroundImage: [
          url(`${baseUrl}-${getDimensions(400)}.${ext}${prefix}`),
          `-webkit-image-set(${url(`${baseUrl}-${getDimensions(400)}.${ext}${prefix}`)} 1x, ${url(
            `${baseUrl}-${getDimensions(600)}.${ext}${prefix}`
          )} 1.5x, ${url(`${baseUrl}-${getDimensions(800)}.${ext}${prefix}`)} 2x)`,
          `image-set(${url(`${baseUrl}-${getDimensions(400)}.${ext}${prefix}`)} 1x, ${url(
            `${baseUrl}-${getDimensions(600)}.${ext}${prefix}`
          )} 1.5x, ${url(`${baseUrl}-${getDimensions(800)}.${ext}${prefix}`)} 2x)`,
        ],
      }
    ),
    mediaDesktop({
      backgroundImage: [
        url(`${baseUrl}-${getDimensions(150)}.${ext}${prefix}`),
        `-webkit-image-set(${url(`${baseUrl}-${getDimensions(150)}.${ext}${prefix}`)} 1x, ${url(
          `${baseUrl}-${getDimensions(225)}.${ext}${prefix}`
        )} 1.5x, ${url(`${baseUrl}-${getDimensions(300)}.${ext}${prefix}`)} 2x)`,
        `image-set(${url(`${baseUrl}-${getDimensions(150)}.${ext}${prefix}`)} 1x, ${url(
          `${baseUrl}-${getDimensions(225)}.${ext}${prefix}`
        )} 1.5x, ${url(`${baseUrl}-${getDimensions(300)}.${ext}${prefix}`)} 2x)`,
      ],
    })
  );
};

export const CarouselImage = inject(deps)(
  observer(
    ({
      identity,
      width = 150,
      height = 150,
      isTopSolutionImage = false,
      alt,
      className,
      model,
      ...props
    }: CarouselImageProps & ComponentProps) => {
      const w150 = Number(width);
      const h150 = Number(height);
      const w200 = w150 / 0.75;
      const h200 = h150 / 0.75;
      const w225 = w150 * 1.5;
      const h225 = h150 * 1.5;
      const w300 = w150 * 2;
      const h300 = h150 * 2;
      const w400 = w150 / 0.375;
      const h400 = h150 / 0.375;
      const w600 = w150 * 4;
      const h600 = h150 * 4;
      const w800 = w150 * 1.875;
      const h800 = h150 * 1.875;

      if (identity) {
        return (
          <picture className={classes(styles, className)}>
            <source
              srcSet={`${model.image.cover(identity, {
                width: w200,
                height: h200,
                crop: true,
                ignoreDimensions: true,
              })}, ${model.image.cover(identity, {
                width: w300,
                height: h300,
                crop: true,
                ignoreDimensions: true,
              })} 1.5x, ${model.image.cover(identity, {
                width: w400,
                height: h400,
                crop: true,
                ignoreDimensions: true,
              })} 2x`}
              media={`(max-width: ${px(largeMobileBreakpoint - 1)})`}
            />
            <source
              srcSet={`${model.image.cover(identity, {
                width: w400,
                height: h400,
                crop: true,
                ignoreDimensions: true,
              })}, ${model.image.cover(identity, {
                width: w600,
                height: h600,
                crop: true,
                ignoreDimensions: true,
              })} 1.5x, ${model.image.cover(identity, {
                width: w800,
                height: h800,
                crop: true,
                ignoreDimensions: true,
              })} 2x`}
              media={`(max-width: ${px(desktopBreakpoint - 1)})`}
            />
            <source
              srcSet={`${model.image.cover(identity, {
                width: w150,
                height: h150,
                crop: true,
                ignoreDimensions: true,
              })}, ${model.image.cover(identity, {
                width: w225,
                height: h225,
                crop: true,
                ignoreDimensions: true,
              })} 1.5x, ${model.image.cover(identity, {
                width: w300,
                height: h300,
                crop: true,
                ignoreDimensions: true,
              })} 2x`}
              media={`(min-width: ${px(desktopBreakpoint)})`}
            />
            <img
              className={classes(styles, className)}
              loading="lazy"
              width={width}
              height={height}
              src={model.image.cover(identity, {
                width: w150,
                height: h150,
                crop: true,
                ignoreDimensions: true,
              })}
            />
          </picture>
        );
      } else if (isTopSolutionImage) {
        return (
          <picture>
            <img
              className={classes(styles, className)}
              loading="lazy"
              width={width}
              height={height}
              src={defaultPlaylistImage}
            />
          </picture>
        );
      } else {
        const divStyles = {
          width: width,
          height: height,
          borderRadius: em(0.3),
          background:
            'linear-gradient(135deg, rgba(181, 191, 211, 0.27) 0%, #B5C0D3 32.29%, rgba(202, 208, 220, 0.22) 91.15%)',
          opacity: 0.2,
        };

        return <div style={divStyles}></div>;
      }
    }
  )
);
