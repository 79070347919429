import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalChildStyle } from '../../modal';
import { style } from 'typestyle';
import {
  betweenJustified,
  endJustified,
  horizontal,
  horizontallySpaced,
  start,
  startJustified,
  vertical,
  verticallySpaced,
} from 'csstips';
import { resetAnchorStyles } from '../../../theme/reset';
import { em, percent, px } from 'csx';
import { Component, deps, inject } from '../../../lib/component';
import { ButtonPill } from '../../button-pill';
import { colorBrand, colorContrast, colorGunmetal, colorSubtle, colorWhite } from '../../../theme/color';
import { SUPPORT_HUB } from '../../../constants';

export interface BasketRestrictedModalProps {
  show: boolean;
  onRequestClose(): void;
}

@inject(deps)
@observer
export class BasketErrorModal extends Component<BasketRestrictedModalProps> {
  render() {
    const { show, onRequestClose } = this.props;

    return (
      <Modal show={show} onRequestClose={onRequestClose}>
        <div className={BasketErrorModal.styles.container}>
          <h3>Basket Error</h3>
          <p>
            Sorry, an error occurred adding this track to your basket. Please{' '}
            <a href={SUPPORT_HUB}>submit a support ticket</a> if this issue persists.
          </p>
          <div className={BasketErrorModal.styles.buttons}>
            <ButtonPill onClick={this.onClose}>OK</ButtonPill>
          </div>
        </div>
      </Modal>
    );
  }

  onClose = () => {
    this.props.onRequestClose();
  };

  static styles = {
    container: style(ModalChildStyle, {
      ...ModalChildStyle,
      width: px(420),
      maxWidth: 'calc(100vw - 4rem)',
      background: colorGunmetal.toString(),
      overflowX: 'hidden',
      $nest: {
        '&> h3': {
          color: colorContrast.toString(),
          fontSize: px(30),
          margin: '0 0 30px 0',
        },
        '& p': {
          color: colorWhite.toString(),
          margin: '5px 0 30px 0',
          lineHeight: em(1.4),
        },
        '& ul': {
          color: colorWhite.toString(),
        },
      },
    }),
    link: style({
      color: colorBrand.toString(),
      $nest: { ...resetAnchorStyles(colorBrand.toString()) },
    }),
    buttons: style({
      ...horizontal,
      ...horizontallySpaced(10),
      ...endJustified,
      margin: '30px 0 0 0',
    }),
    buttonsLicense: style({
      ...vertical,
      ...verticallySpaced(10),
      ...startJustified,
      ...start,
      margin: 0,
    }),
    button: style({
      ...horizontal,
      ...betweenJustified,
      width: percent(100),
    }),
    loading: style({
      padding: px(30),
      textAlign: 'center',
      color: colorSubtle.toString(),
    }),
    discount: style({
      textDecoration: 'line-through',
      marginRight: px(5),
    }),
  };
}
