import * as React from 'react';
import { classes, style } from 'typestyle';
import { percent, px, viewWidth } from 'csx';
import { BaseMasthead, BaseMastheadProps } from './BaseMasthead';
import { mediaDesktop, mediaSmallTablet, mediaUpToDesktop, mediaUpToSmallTablet } from '../../utilities/mediaQueries';
import { heading3 } from '../../utilities/font';
import { rebrand } from '../../../../theme/color';
import { desktopContentWidth } from '../../config';
import { flexRoot, wrap } from 'csstips';
import { centerCenter } from 'csstips/lib/flex';
import { RatecardDisplay } from '../RatecardDisplay';

const styles = {
  masthead: style(
    flexRoot,
    {
      textAlign: 'left',
      backgroundRepeat: 'no-repeat',
      backgroundSize: px(1380),
    },
    mediaUpToDesktop({
      backgroundPosition: 'left bottom',
      height: 'auto',
      minHeight: px(420),
      paddingBottom: px(16),
    }),
    mediaDesktop({
      backgroundPosition: 'center bottom',
      height: viewWidth(110),
      maxHeight: px(420),
    })
  ),
  mastheadContents: style(
    flexRoot,
    centerCenter,
    wrap,
    {
      marginLeft: px(16),
      marginRight: px(16),
    },
    mediaDesktop({
      width: px(desktopContentWidth),
      marginLeft: 'auto',
      marginRight: 'auto',
    })
  ),
  copyContent: style(
    mediaSmallTablet({
      width: percent(60),
      minWidth: px(570),
    }),
    mediaUpToSmallTablet({
      width: percent(100),
    })
  ),
  childContent: style(
    mediaSmallTablet({
      width: percent(40),
      minWidth: px(390),
    }),
    mediaUpToSmallTablet({
      width: percent(100),
    })
  ),
  headingWrapper: style(heading3, {
    margin: 0,
    maxWidth: '18ch',
    width: '100vw',
    transform: 'rotate(-5deg)',
    $nest: {
      '& em': {
        color: rebrand.primary1.toString(),
      },
    },
  }),
  textWrapper: style({
    $nest: {
      '& span': {
        display: 'block',
      },
    },
  }),
  headingRatecardWrapper: style(mediaUpToDesktop({ justifyContent: 'center' }), {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  }),
};

export type DashboardTeaserProps = Omit<BaseMastheadProps, 'mastheadClassName'> & {
  heading: React.ReactNode;
  text: React.ReactNode;
  cta: string;
  href: string;
};

export const DashboardTeaserMasthead: React.SFC<DashboardTeaserProps> = ({
  className,
  heading,
  text,
  cta,
  href,
  children,
  ...childProps
}) => {
  let defaultChannel;

  if (
    childProps &&
    childProps.controller &&
    childProps.controller.model &&
    childProps.controller.model.user &&
    childProps.controller.model.user.user
  ) {
    defaultChannel = childProps.controller.model.user.user.default_channel;
  }

  return (
    <BaseMasthead
      className={classes(styles.mastheadContents, className)}
      mastheadClassName={styles.masthead}
      dimBackground={false}
      {...childProps}
    >
      <div className={styles.headingRatecardWrapper}>
        <div className={styles.copyContent}>
          <h1 className={styles.headingWrapper}>{heading}</h1>
          <p className={styles.textWrapper}>{text}</p>
        </div>
        <div>
          <RatecardDisplay key={defaultChannel ? defaultChannel : 'no-channel'} />
        </div>
      </div>
      <div className={styles.childContent}>{children}</div>
    </BaseMasthead>
  );
};
