import * as React from 'react';
import {FormInputRadio} from './form-input-radio';
import {centerCenter, vertical, verticallySpaced} from 'csstips';
import {classes, style} from 'typestyle';
import {important, px} from 'csx';
import {colorBackground} from '../theme/color';
import {transitionQuickEase} from '../theme/transition';

export interface PreferenceItemProps {
  label: string;
  group: string;
  selected: boolean;
  onSelect(): void;
  disabled?: boolean;
}

export class PreferenceItem extends React.Component<PreferenceItemProps, any> {
  render() {
    const { label, group, selected, disabled, onSelect } = this.props;

    return (
      <div className={classes(PreferenceItem.styles.container, disabled && PreferenceItem.styles.disabled)}>
        <FormInputRadio
          name={group}
          value={selected}
          onChange={() => !selected && onSelect()}
          disabled={disabled}
        />
        <div onClick={() => !selected && onSelect()}>{label}</div>
      </div>
    )
  }

  static styles = {
    container: style({
      ...vertical,
      ...verticallySpaced(5),
      ...centerCenter,
      cursor: 'default',
      padding: px(5),
      width: px(70),
      transition: `color ${transitionQuickEase}`
    }),
    disabled: style({
      color: important(colorBackground.toString())
    })
  }
}