import { NestedCSSProperties } from 'typestyle/lib/types';
import { media } from 'typestyle';
import {
  largeMobileBreakpoint,
  desktopBreakpoint,
  smallTabletBreakpoint,
  desktopContentWidth,
  largeTabletBreakpoint,
  mediumDesktopBreakpoint,
  CommonDesktopBreakpoint,
  wideDesktopBreakpoint,
} from '../config';
import { EnvModel } from '../../../modules/env/model';
import {
  constantLargeDesktopScreensTrackLineBreakpoint,
  constantDesktopScreensTrackLineBreakpoint,
  constantTabletLandscapeTrackLineBreakpoint,
  constantTabletPortraitTrackLineBreakpoint,
  constantLargeMobileScreensTrackLineBreakpoint,
  constantMediumMobileScreensTrackLineBreakpoint,
  constantSmallMobileScreeensTrackLineBreakpoint,
  constantExtraSmallMobileScreensTrackLineBreakpoint,
} from '../../../theme/constant';

export const mediaUpToLargeMobile = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ maxWidth: largeMobileBreakpoint - 1 }, ...styles);
export const mediaLargeMobileOnly = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ minWidth: largeMobileBreakpoint, maxWidth: smallTabletBreakpoint - 1 }, ...styles);
export const mediaLargeMobile = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ minWidth: largeMobileBreakpoint }, ...styles);

export const mediaMobileOnly = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ maxWidth: smallTabletBreakpoint - 1 }, ...styles);

export const mediaUpToSmallTablet = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ maxWidth: smallTabletBreakpoint - 1 }, ...styles);
export const mediaSmallTabletOnly = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ minWidth: smallTabletBreakpoint, maxWidth: largeTabletBreakpoint - 1 }, ...styles);
export const mediaSmallTablet = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ minWidth: smallTabletBreakpoint }, ...styles);

export const mediaUpToLargeTablet = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ maxWidth: largeTabletBreakpoint - 1 }, ...styles);
export const mediaLargeTabletOnly = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ minWidth: largeTabletBreakpoint, maxWidth: desktopBreakpoint - 1 }, ...styles);
export const mediaLargeTablet = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ minWidth: largeTabletBreakpoint }, ...styles);

export const mediaUpToDesktop = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ maxWidth: desktopBreakpoint - 1 }, ...styles);
export const mediaDesktopOnly = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ minWidth: desktopBreakpoint, maxWidth: mediumDesktopBreakpoint - 1 }, ...styles);
export const mediaDesktop = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ minWidth: desktopBreakpoint }, ...styles);

export const mediaUpToMediumDesktop = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ maxWidth: mediumDesktopBreakpoint - 1 }, ...styles);
export const mediaMediumDesktopOnly = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ minWidth: mediumDesktopBreakpoint, maxWidth: wideDesktopBreakpoint - 1 }, ...styles);
export const mediaMediumDesktop = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ minWidth: mediumDesktopBreakpoint }, ...styles);

export const mediaUpToWideDesktop = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ maxWidth: wideDesktopBreakpoint - 1 }, ...styles);
export const mediaWideDesktopOnly = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ minWidth: wideDesktopBreakpoint, maxWidth: CommonDesktopBreakpoint - 1 }, ...styles);
export const mediaWideDesktop = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ minWidth: wideDesktopBreakpoint }, ...styles);

export const mediaLargeDesktop = (...styles: NestedCSSProperties[]): NestedCSSProperties =>
  media({ minWidth: CommonDesktopBreakpoint }, ...styles);

//Trackline media queries
export const mediaLargeDesktopTrackLine = (...styles: NestedCSSProperties[]) =>
  media({ minWidth: constantLargeDesktopScreensTrackLineBreakpoint }, ...styles);

export const mediaDesktopTrackLine = (...styles: NestedCSSProperties[]) =>
  media(
    {
      minWidth: constantDesktopScreensTrackLineBreakpoint,
      maxWidth: constantLargeDesktopScreensTrackLineBreakpoint - 1,
    },
    ...styles
  );

export const mediaTabletLandscapeTrackLine = (...styles: NestedCSSProperties[]) =>
  media(
    { minWidth: constantTabletLandscapeTrackLineBreakpoint, maxWidth: constantDesktopScreensTrackLineBreakpoint - 1 },
    ...styles
  );

export const mediaTabletPortraitTrackLine = (...styles: NestedCSSProperties[]) =>
  media(
    { minWidth: constantTabletPortraitTrackLineBreakpoint, maxWidth: constantTabletLandscapeTrackLineBreakpoint - 1 },
    ...styles
  );

export const mediaLargeMobileTrackLine = (...styles: NestedCSSProperties[]) =>
  media(
    {
      minWidth: constantLargeMobileScreensTrackLineBreakpoint,
      maxWidth: constantTabletPortraitTrackLineBreakpoint - 1,
    },
    ...styles
  );

export const mediaMediumMobileTrackLine = (...styles: NestedCSSProperties[]) =>
  media(
    {
      minWidth: constantMediumMobileScreensTrackLineBreakpoint,
      maxWidth: constantLargeMobileScreensTrackLineBreakpoint - 1,
    },
    ...styles
  );

export const mediaSmallMobileTrackLine = (...styles: NestedCSSProperties[]) =>
  media(
    {
      minWidth: constantSmallMobileScreeensTrackLineBreakpoint,
      maxWidth: constantMediumMobileScreensTrackLineBreakpoint - 1,
    },
    ...styles
  );

export const mediaExtraSmallMobileTrackLine = (...styles: NestedCSSProperties[]) =>
  media(
    {
      minWidth: constantExtraSmallMobileScreensTrackLineBreakpoint,
      maxWidth: constantSmallMobileScreeensTrackLineBreakpoint - 1,
    },
    ...styles
  );
// Trackline
export const mediaUpToExtraSmallMobileTrackLine = (...styles: NestedCSSProperties[]) =>
  media({ maxWidth: constantExtraSmallMobileScreensTrackLineBreakpoint - 1 }, ...styles);

export const mediaUpToTabletPortraitTrackLine = (...styles: NestedCSSProperties[]) =>
  media({ maxWidth: constantTabletPortraitTrackLineBreakpoint - 1 }, ...styles);

export const mediaUpToMediumMobileTrackLine = (...styles: NestedCSSProperties[]) =>
  media({ maxWidth: constantMediumMobileScreensTrackLineBreakpoint - 1 }, ...styles);

export const mediaUpToLargeMobileTrackLine = (...styles: NestedCSSProperties[]) =>
  media({ maxWidth: constantLargeMobileScreensTrackLineBreakpoint - 1 }, ...styles);

export const mediaUpToDesktopTrackLine = (...styles: NestedCSSProperties[]) =>
  media({ maxWidth: constantDesktopScreensTrackLineBreakpoint - 1 }, ...styles);

export const mediaLargeMobileUpwardsTrackLine = (...styles: NestedCSSProperties[]) =>
  media(
    {
      minWidth: constantLargeMobileScreensTrackLineBreakpoint,
    },
    ...styles
  );
export const mediaUpToTabletLandscapeTrackLine = (...styles: NestedCSSProperties[]) =>
  media(
    {
      maxWidth: constantTabletLandscapeTrackLineBreakpoint - 1,
    },
    ...styles
  );

type SomeMatchMediaProperties = Pick<
  MediaQueryList & EventTarget,
  'matches' | 'addEventListener' | 'removeEventListener'
>;
const dummyMatchMediaObject = {
  matches: true,
  addEventListener: () => void 0,
  removeEventListener: () => void 0,
};
let isDesktop: SomeMatchMediaProperties = dummyMatchMediaObject;
let isFinePointer: SomeMatchMediaProperties = dummyMatchMediaObject;
let isMobileUpToDesktop: SomeMatchMediaProperties = dummyMatchMediaObject;
let isMediumDesktop: SomeMatchMediaProperties = dummyMatchMediaObject;

if (!EnvModel.isServer) {
  // The original MediaQueryList didn't inherit from EventTarget, so TypeScript complains, we shim that in matchMedia-shim.js so this is fine
  isDesktop = (window.matchMedia(`(min-width:  ${desktopBreakpoint}px)`) as any) as SomeMatchMediaProperties;
  isMediumDesktop = (window.matchMedia(
    `(min-width:  ${mediumDesktopBreakpoint}px)`
  ) as any) as SomeMatchMediaProperties;

  isFinePointer = (window.matchMedia('(pointer:fine)') as any) as SomeMatchMediaProperties;
  isMobileUpToDesktop = (window.matchMedia(
    `(max-width:  ${desktopBreakpoint - 1}px)`
  ) as any) as SomeMatchMediaProperties;
}
export { isDesktop, isFinePointer, isMobileUpToDesktop, isMediumDesktop };
