import * as React from 'react';
import { cssRule, style } from 'typestyle';
import { betweenJustified, center, centerCenter, flexRoot, vertical } from 'csstips';
import { transitionQuickEase } from '../../../theme/transition';
import { Collapsible } from '../../collapsible/collapsible';
import { CollapsibleIndicator } from './CollapsibleIndicator';
import { contentHeadingStyles } from '../utilities/font';
import { rebrandPageClass } from '../utilities/page';

const styles = {
  faqSummary: style(flexRoot, betweenJustified, center),
  faqHeading: style(contentHeadingStyles, { margin: 0, fontWeight: 600 }),
  faqIndicator: style({
    flexShrink: 0,
  }),
};

cssRule(`.${rebrandPageClass} .${styles.faqHeading}`, contentHeadingStyles);

export type FaqProps = React.Component['props'] & {
  className?: string;
  question: string;
};

export const Faq: React.SFC<FaqProps> = ({ children, className, question }) => (
  <Collapsible className={className} isFaq={true}>
    <Collapsible.Summary className={styles.faqSummary}>
      <h4 className={styles.faqHeading}>{question}</h4>
      <CollapsibleIndicator className={styles.faqIndicator} />
    </Collapsible.Summary>
    {children}
  </Collapsible>
);
