import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';
import { colorGunmetal } from '../../../../theme/color';

export const Return: React.SFC<SVGIconProps> = ({ size = 25, color = colorGunmetal.toString(), ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 25 24" {...props}>
    <path
      d="M8.87727 13.595L10.4136 12.0814L7.65586 9.36455L17.3264 9.36455L17.3264 7.23042L7.65586 7.23042L10.4136 4.51357L8.87727 3L3.5 8.29749L8.87727 13.595Z"
      fill={color}
    />
    <path
      d="M17.3273 9.36771V16.8595L8.29078 16.8595V19H17.3273C18.5223 19 19.5 18.0475 19.5 16.8595V9.36771C19.5 8.19043 18.5223 7.2272 17.3273 7.2272H9.72264V9.36771L17.3273 9.36771Z"
      fill={color}
    />
  </BaseSvg>
);
