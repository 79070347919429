/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { DashboardHeader } from '../organisms/DashboardHeader';
import { PrismicTypedDashboardHeaderProps } from '../utilities/types';

export type PrismicDashboardHeaderPayload = {
  items: PrismicTypedDashboardHeaderProps[];
};
export type PrismicDashboardHeaderProps = {
  payload: PrismicDashboardHeaderPayload;
};
export const PrismicDashboardHeader: React.SFC<PrismicDashboardHeaderProps> = ({ payload }) => (
  <DashboardHeader items={payload.items} />
);
