import { px, quote } from 'csx';
import { fontBody as originalFontBody } from '../../theme/font';

export const fontBody = originalFontBody;
export const fontDisplay = 'Flood';
export const fontSectionHeadings = 'Bebas Neue';
export const fontComponentHeadings = 'Bebas Neue';
export const fontContentHeadings = fontBody;
export const fontstackDefault = `${quote(fontBody)}, sans-serif`;
export const fontstackDisplay = `${quote(fontDisplay)}, sans-serif`;
export const fontstackHeading = `${quote(fontComponentHeadings)}, 'Arial Black', sans-serif`;
export const fontstackSectionHeadings = `${quote(fontSectionHeadings)}, sans-serif`;
export const fontstackComponentHeadings = `${quote(fontComponentHeadings)}, 'Arial Black', sans-serif`;
export const fontstackContentHeadings = fontstackDefault;
export const headerHeight = 64;
export const headerBorder = 3;
export const headerFullHeight = 67;
export const minBodyHeight = `calc(100vh - ${headerFullHeight}px)`;
export const contentWidth = 73;
export const largeMobileBreakpoint = 450;
export const smallTabletBreakpoint = 700;
export const largeTabletBreakpoint = 800;
export const desktopContentWidth = 992;
export const desktopBreakpoint = desktopContentWidth + 32;
export const mediumDesktopBreakpoint = 1280;
export const wideDesktopBreakpoint = 1440;
export const CommonDesktopBreakpoint = 1920;
export const maxPageWidth = 2000;
export const responsiveTracklineBreakpoint = 1084;
// I added 18 because the filter count component's height was causing it to render under the page header.
// Although I considered giving the div that is wrapping the count, a z-index,
// I decided against it because it would have a negative impact on the app header dropdown.
export const constantHeaderWithSearchHeight = 138 + 18;
export const constantHeaderWithSearchHeightScrollOrMobile = 72 + 18;
