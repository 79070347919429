import { PrismicBasePanelProps, PrismicImage } from '../utilities/types';
import { ArtistsPanel } from '../molecules/panels/ArtistsPanel';
import { isEmpty } from '../utilities/objects';
import * as React from 'react';
import { createTextPanel } from '../utilities/prismic';

export type PrismicArtistsFeaturePanelPayload = PrismicBasePanelProps & {
  primary: {
    strap_line_1?: string;
    strap_line_2?: string;
  };
  items: { artist_image: PrismicImage }[];
};
export type PrismicArtistsFeaturePanelProps = {
  payload: PrismicArtistsFeaturePanelPayload;
};
export const PrismicArtistsFeaturePanel: React.SFC<PrismicArtistsFeaturePanelProps> = ({
  payload: {
    primary: {
      strap_line_1,
      strap_line_2,
      call_to_action_url,
      call_to_action_text,
      background_image,
      background_colour,
      background_gradient_colour_end,
      background_gradient_colour_direction,
    },
    items,
  },
}) => (
  <ArtistsPanel
    src={!isEmpty(background_image) && background_image.url}
    gradient={
      background_colour && {
        to: background_colour,
        from: background_gradient_colour_end,
        direction: background_gradient_colour_direction,
      }
    }
    strapline={(strap_line_1 || strap_line_2) && [strap_line_1, strap_line_2]}
    artists={items.map((item) => item.artist_image.url)}
    cta={
      call_to_action_text && {
        href: !isEmpty(call_to_action_url) && call_to_action_url.url,
        text: call_to_action_text,
      }
    }
  />
);
PrismicArtistsFeaturePanel.displayName = 'PrismicArtistsFeaturePanel';
export const PrismicArtistsFeaturePanelWithText = createTextPanel(PrismicArtistsFeaturePanel);
