import * as React from 'react';
import { PrismicRichText, PrismicSliceCSSClasses } from '../utilities/types';
import { renderPrismicRichTextArray } from '../utilities/props';
import { style } from 'typestyle';
import { copyStyles } from '../utilities/font';
import { px } from 'csx';

const styles = style(copyStyles, {
  $nest: {
    [`&:not(:last-child), .${PrismicSliceCSSClasses.TWO_COLUMN_SLICE} > div > :not(:last-child) &`]: {
      marginBottom: px(32),
    },
  },
});

export type PrismicTextPanelPayload = {
  primary: { text_block: PrismicRichText; align_centre: boolean };
};
export type PrismicTextPanelProps = {
  payload: PrismicTextPanelPayload;
};
export const PrismicTextPanel: React.SFC<PrismicTextPanelProps> = ({
  payload: {
    primary: { text_block, align_centre },
  },
}) => (
  <div style={align_centre && { alignSelf: 'center' }}>
    <div className={styles}>{renderPrismicRichTextArray(text_block)}</div>
  </div>
);
