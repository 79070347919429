/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const CloseCrossInCircle: React.SFC<SVGIconProps> = ({ size = 18, color = '#2E2E3B', title, ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 20 20" {...props}>
    <path
      d="M17.0711 2.92898C13.1678 -0.974253 6.83216 -0.974252 2.92893 2.92898C-0.974297 6.83221 -0.974297 13.1679 2.92893 17.0711C6.83216 20.9743 13.1678 20.9743 17.0711 17.0711C20.9743 13.1679 20.9743 6.83221 17.0711 2.92898ZM13.8284 15.2427L10 11.4143L6.17157 15.2427L4.75736 13.8285L8.58579 10L4.75736 6.17162L6.17157 4.7574L10 8.58583L13.8284 4.7574L15.2426 6.17162L11.4142 10L15.2426 13.8285L13.8284 15.2427Z"
      fill={color}
    />
    <title>{title}</title>
  </BaseSvg>
);
