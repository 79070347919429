import React from 'react';
import { style } from 'typestyle';
import { CheckCircle } from '../../atoms/icons/CheckCircle';
import { flexRoot } from 'csstips';
import { colorBlack, colorLickdMint } from '../../../../theme/color';
import { important } from 'csx';

const styles = {
  wrapper: style(flexRoot, {
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: important('80px'),
  }),
  checkmarkList: style({
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '8px',
  }),
  text: style({
    margin: 0,
  }),
  img: style({
    maxWidth: '500px',
    width: '100%',
  }),
  iconWrapper: style({
    height: '16px',
    width: '16px',
    marginRight: '8px',
  }),
};

export type AdvertProps = {
  image: {
    url: string;
    alt: string;
  };
  description: string;
  texts: string[];
};

const Advert: React.SFC<AdvertProps> = ({ image, description, texts }) => {
  return (
    <div className={styles.wrapper}>
      <img className={styles.img} src={image.url} alt={image.alt || ''} />
      <p>{description}</p>
      <ul>
        {texts.map((text, i) => (
          <li key={i} className={styles.checkmarkList}>
            <span className={styles.iconWrapper}>
              <CheckCircle size={16} color={colorLickdMint.toString()} checkColor={colorBlack.toString()} />
            </span>
            <p className={styles.text}>{text}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default Advert;
