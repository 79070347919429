import * as React from 'react';
import { ResponsiveImage, ResponsiveImageProps } from './responsive-image';
import { observer } from 'mobx-react';
import { ImagePreloader } from '../types/fn';

export interface WaveformImageProps {
  className?: string;
  identity: string;
  preloadWaveform: ImagePreloader;
  type?: 'coloured' | 'greyscale';
}

@observer
export class WaveformImage extends React.Component<WaveformImageProps, any> {
  static defaultSize = {
    width: 128,
    height: 0,
    type: 'coloured',
  };

  render() {
    const { type, preloadWaveform, identity, ...otherProps } = this.props;

    const size = !type
      ? WaveformImage.defaultSize
      : {
          ...WaveformImage.defaultSize,
          type,
        };

    return <img src={preloadWaveform(identity)} {...otherProps} />;
  }
}
