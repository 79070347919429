import { PrismicImage } from './types';
import { IObservableArray, isObservableArray } from 'mobx';
import * as React from 'react';
import { ReactNode } from 'react';

export type AnyObject = { [key: string]: any };

export type AnyComponent<T = AnyObject> = React.ComponentClass<T> | React.SFC<T>;

export const isEmpty = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) return true;
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }
  return true;
};

export const isAnyObject = (obj: any): obj is AnyObject => typeof obj === 'object' && obj !== null;

export const isArray = <T>(obj: any): obj is Array<T> | IObservableArray<T> => {
  return Array.isArray(obj) || isObservableArray(obj);
};

export type DataHandler = (value: any, key: string, parent: AnyObject) => void;
export const crawlData = (data: any, handlers: DataHandler[]) => {
  if (isEmpty(data)) return;
  Object.entries(data).forEach(([key, value]) => {
    handlers.forEach((handler) => handler(value, key, data));
    crawlData(value, handlers);
  });
};

export const getErrorObject = (error: any): Partial<Error> & Record<string, string> => {
  const isObject = !isEmpty(error);
  const hasJsonHandler = isObject && 'toJSON' in error && typeof error.toJSON === 'function';
  if (error instanceof Error && !hasJsonHandler) {
    const { message, name, stack } = error;
    return {
      message,
      name,
      stack,
    };
  }

  if (isObject || typeof error === 'function') {
    // Best case: object has a JSON handler, otherwise best efforts from JSON.stringify; functions are ignored for JSON
    return error;
  }

  // Nasty hack to get some sort of stack trace from a poorly formed error
  try {
    throw Error(String(error));
  } catch (errorObject) {
    return getErrorObject(errorObject);
  }
};

export const serialiseError = (error: any, replacer?: (key: string, value: any) => any, space?: string | number) =>
  JSON.stringify(getErrorObject(error), replacer, space);

/**
 * objectToUrlSearchParams takes a shallow object (plus shallow arrays) and converts it to
 * PHP compatible search parameters using URLSearchParams (where arrays use the repeating key[]=value syntax).
 * undefined and null values are ignored.
 * @param object An object of values e.g. { channel_category: 'sports', tags: ['pop', 'rock'] }
 * @return URLSearchParams A URLSearchParams object with the object values contained within it
 */
export const objectToUrlSearchParams = (object: AnyObject | null | undefined) => {
  const searchParams = new URLSearchParams();
  if (typeof object === 'undefined' || object === null) return searchParams;
  Object.entries(object).forEach(([key, value]) => {
    if (typeof value === 'undefined' || value === null) return;
    if (Array.isArray(value)) {
      value.forEach((item) => {
        if (typeof item === 'undefined' || item === null) return;
        searchParams.append(`${key}[]`, item);
      });
      return;
    }
    searchParams.append(key, value);
  });
  return searchParams;
};
