import * as React from 'react';
import { AnchorHTMLAttributes } from 'react';
import { Component, deps, inject, observer } from '../../../lib/component';
import { SIGN_UP_ACTION_QUERY, SIGN_UP_ROUTE } from '../../../constants';
import { AnalyticsContext } from '../utilities/AnalyticsContext';
import { isEmpty } from '../utilities/objects';
import { classes } from 'typestyle';

export const linkCurrentPageClassName = 'link-current-page';

export type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement>;

@inject(deps)
@observer
export class Link extends Component<LinkProps> {
  render() {
    const {
      model: {
        env,
        router: {
          location: { pathname, search },
        },
      },
      controller: { analytics },
      children,
      ...parentProps
    } = this.props;
    const { href, ...props } = parentProps;
    if (href === pathname + search) {
      const { className, ...noLinkProps } = props;
      return (
        <span className={classes(className, linkCurrentPageClassName)} {...noLinkProps}>
          {children}
        </span>
      );
    }
    return (
      <AnalyticsContext.Consumer>
        {(analyticsData) => {
          if (!env.isServer) {
            const originalOnClick = props.onClick;
            props.onClick = (event) => {
              if (typeof originalOnClick === 'function') {
                let preventCalled = false;
                const preventDefaultAndRoute = () => {
                  preventCalled = true;
                  event.preventDefault();
                };
                originalOnClick({ ...event, preventDefault: preventDefaultAndRoute });
                if (preventCalled) return;
              }
              this.handleClickLink(event);
              if (!isEmpty(analyticsData)) {
                analytics.sendMixpanel('User clicks link', { ...analyticsData, href });
              }
            };
          }
          return (
            <a href={href} {...props}>
              {children}
            </a>
          );
        }}
      </AnalyticsContext.Consumer>
    );
  }

  handleClickLink(event: React.MouseEvent<HTMLAnchorElement>) {
    const { hostname, search, pathname, target } = (event.target as HTMLElement).closest('a') as HTMLAnchorElement;
    if (hostname !== window.location.hostname || target === '_blank') return;
    event.preventDefault();
    if (search.includes(SIGN_UP_ACTION_QUERY)) {
      this.props.model.router.push(SIGN_UP_ROUTE);
      return;
    }
    this.props.model.router.push(pathname + search);
  }
}
