import { VERTICALS } from '../../../../constants';
import * as React from 'react';
import { Dropdown, DropdownProps } from './Dropdown';

export type VerticalSelectorProps = DropdownProps & { placeholder?: string };

export const VerticalSelector: React.SFC<VerticalSelectorProps> = ({ className, placeholder, ...props }) => (
  <Dropdown className={className} {...props} data-test-vertical-selector>
    <option value="" disabled>
      {placeholder || 'Select category'}
    </option>
    {VERTICALS.map((vertical, index) => (
      <option key={index} value={vertical}>
        {vertical}
      </option>
    ))}
  </Dropdown>
);
