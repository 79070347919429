import * as React from 'react';
import { observer } from 'mobx-react';
import { FormInput } from './form-input';
import { style } from 'typestyle';
import { ButtonPill } from './button-pill';
import { percent, px } from 'csx';
import { vertical, verticallySpaced } from 'csstips';
import { FormField } from '../types/form';

export interface MailchimpFormModel {
  action: string;
  hidden: string;
  email: FormField<any>;
}

export interface MailchimpFormProps {
  model: MailchimpFormModel;
  onChangeEmail(email: string): void;
  onCancel?(): void;
  i18n?: {
    submit: string;
    close: string;
  };
}

const DEFAULT_I18N = {
  submit: 'Submit',
  close: 'Close',
};

export const MailchimpForm = observer(({ model, onChangeEmail, onCancel, i18n = DEFAULT_I18N }: MailchimpFormProps) => (
  <form action={model.action} method="post">
    <FormInput model={model.email} onChange={(_, email) => onChangeEmail(email)} />
    <div className={styles.hidden} aria-hidden="true">
      <input type="text" name={model.hidden} tabIndex={-1} defaultValue="" />
    </div>
    <div className={styles.buttons}>
      <ButtonPill type="submit" children={i18n.submit} />
      {onCancel && <ButtonPill muted={true} onClick={onCancel} children={i18n.close} />}
    </div>
  </form>
));

const styles = {
  hidden: style({
    position: 'absolute',
    left: px(-5000),
  }),
  buttons: style({
    ...vertical,
    ...verticallySpaced(10),
    margin: '30px 0 0 0',
    $nest: {
      '&> *': {
        width: percent(100),
      },
    },
  }),
};
