import React from 'react';
import { Button, ButtonProps } from './Button';
import { px } from 'csx';
import { classes, style } from 'typestyle';
import { centerCenter, flexRoot } from 'csstips';
import { mediaDesktop, mediaUpToDesktop } from '../../utilities/mediaQueries';
import { transitionQuickEase } from '../../../../theme/transition';

const styles = style(flexRoot, centerCenter, {
  minHeight: px(32),
  minWidth: px(32),
  padding: '6px 8px',
});

export const IconButton: React.SFC<ButtonProps> = ({ children, className, ...props }) => (
  <Button className={classes(styles, 'icon-button', className)} {...props}>
    {children}
  </Button>
);
