import { PrismicComplexBackgroundProps } from '../utilities/types';
import { isEmpty } from '../utilities/objects';
import * as React from 'react';
import { BrowseMasthead } from '../molecules/mastheads/BrowseMasthead';

export type PrismicBrowseMastheadPayload = PrismicComplexBackgroundProps & {
  primary: {
    heading?: string;
    subtitle?: string;
  };
};
export type PrismicBrowseMastheadProps = {
  payload: PrismicBrowseMastheadPayload;
};
export const PrismicBrowseMasthead: React.SFC<PrismicBrowseMastheadProps> = ({
  payload: {
    primary: {
      heading,
      subtitle,
      background_image,
      background_colour,
      background_gradient_colour_end,
      background_gradient_colour_direction,
    },
  },
}) => (
  <BrowseMasthead
    src={!isEmpty(background_image) && background_image.url}
    gradient={{
      from: background_colour,
      to: background_gradient_colour_end,
      direction: background_gradient_colour_direction,
    }}
    heading={heading}
  >
    {subtitle && <h2>{subtitle}</h2>}
  </BrowseMasthead>
);
