import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const TrackIcon: React.SFC<SVGIconProps> = ({ size = 24, color = '#262E3F', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#clip0_1569_91053)">
      <path
        d="M12 3V13.55C11.41 13.21 10.73 13 10 13C7.79 13 6 14.79 6 17C6 19.21 7.79 21 10 21C12.21 21 14 19.21 14 17V7H18V3H12Z"
        fill="#262E3F"
      />
    </g>
    <defs>
      <clipPath id="clip0_1569_91053">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </BaseSvg>
);
