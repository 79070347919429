import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { percent, px } from 'csx';
import { isArray } from '../utilities/objects';
import { heading4, headingStyles } from '../utilities/font';
import { mediaUpToLargeTablet } from '../utilities/mediaQueries';

const styles = {
  highlight: style(
    headingStyles,
    {
      display: 'block',
      fontWeight: 600,
    },
    mediaUpToLargeTablet({ lineHeight: 1 })
  ),
  highlightInline: style({
    display: 'inline',
    verticalAlign: 'middle',
    marginLeft: px(4),
    marginRight: px(4),
  }),
  highlightFallback: style(heading4),
};

export type HighlightTextProps = React.Component['props'] & {
  className?: string;
  highlightClassName?: string;
  inline?: boolean;
  prefixed?: boolean;
};

export const HighlightText: React.SFC<HighlightTextProps> = ({
  children,
  className,
  highlightClassName,
  inline = false,
  prefixed = true,
}) => {
  const childArray = isArray(children) ? children : [children];
  let prefix, highlight, suffix;
  if (prefixed) {
    [prefix, highlight, ...suffix] = childArray;
  } else {
    prefix = null;
    [highlight, ...suffix] = childArray;
  }
  return (
    <span className={className}>
      {prefix}{' '}
      <span
        className={classes(
          highlightClassName || styles.highlightFallback,
          styles.highlight,
          inline && styles.highlightInline
        )}
      >
        {highlight}
      </span>{' '}
      {suffix}
    </span>
  );
};
