/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
import * as React from 'react';
import CurvedArrowImg from '../../../../static/curved-arrow-v2-gray.png';
import CurvedArrowImg2x from '../../../../static/curved-arrow-v2-gray@2x.png';

interface CurvedArrowProps {
  className?: string;
}

export const CurvedArrowV2Gray: React.FC<CurvedArrowProps> = ({ className }) => (
  <img
    src={CurvedArrowImg}
    className={className}
    alt="Grafitti-style image of a curved arrow"
    srcSet={`${CurvedArrowImg}, ${CurvedArrowImg2x} 2x`}
    loading="lazy"
  />
);
