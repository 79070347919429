import * as React from 'react';
import { classes, style } from 'typestyle';
import { em, percent, px, viewWidth } from 'csx';
import { CSSProperties, NestedCSSProperties } from 'typestyle/lib/types';
import { mediaLargeMobileOnly, mediaSmallTablet } from '../utilities/mediaQueries';
import { betweenJustified, center, centerCenter, centerJustified, flexRoot, vertical, wrap } from 'csstips';
import {
  colorBrand,
  colorContrast100,
  colorGreyAccent,
  colorGunmetal,
  colorSubtle,
  colorWhite,
  rebrand,
} from '../../../theme/color';
import { YouTubeLogo } from '../atoms/icons/brands/YouTubeLogo';
import { SpotifyLogo } from '../atoms/icons/brands/SpotifyLogo';
import { FacebookLogo } from '../atoms/icons/brands/FacebookLogo';
import { TwitterLogo } from '../atoms/icons/brands/TwitterLogo';
import { InstagramLogo } from '../atoms/icons/brands/InstagramLogo';
import { LinkedInLogo } from '../atoms/icons/brands/LinkedInLogo';
import { SVGIcon } from '../../svg-icon';
import { StripePoweredLogo } from '../atoms/icons/brands/StripePoweredLogo';
import { ArrowUp } from '../atoms/icons/ArrowUp';
import { transitionQuickEase } from '../../../theme/transition';
import { AbbeyRoadPartner } from '../atoms/icons/partners/AbbeyRoadPartner';
import MidemLabLogo from '../../../../../public/img/midemlab-finalist-logo.png';
import { applyHeadingStyles, heading5 } from '../utilities/font';
import { PrismicFaq, PrismicFaqBlock } from '../molecules/prismic/PrismicFaqBlock';
import { PrismicMenuLinkGroup, PrismicSocialLink, PrismicSocialLinkTypes } from '../../../modules/content/model';
import { PrismicMenuLinkComponent } from '../prismic-slices/PrismicMenuLink';
import { SocialButton } from '../atoms/button/SocialButton';
import { Link } from '../atoms/Link';
import { isArray } from '../utilities/objects';
import { LickdRebrandLogo } from '../atoms/icons/brands/LickdRebrandLogo';
import { rebrandPageClass, scrollToTop } from '../utilities/page';

export const GradientBackgroundStyles: CSSProperties = {
  color: 'white',
  background: 'linear-gradient(to right, #410067, #FF056D)',
};

const contentBorder: NestedCSSProperties = {
  position: 'relative',
  paddingTop: px(32),
  paddingBottom: px(32),
  $nest: {
    '&::before': {
      content: '""',
      width: viewWidth(200),
      height: px(1),
      backgroundColor: colorContrast100.toHexString(),
      position: 'absolute',
      top: 0,
      left: viewWidth(-50),
    },
  },
};

const styles = {
  wrapper: style({
    backgroundColor: colorGunmetal.toHexString(),
    width: percent(100),
  }),
  container: style(flexRoot, vertical, {
    paddingLeft: px(16),
    paddingRight: px(16),
    maxWidth: px(656),
    width: percent(100),
    margin: '0 auto',
    color: colorWhite.toHexString(),
  }),
  moreFaqCta: style({
    textAlign: 'center',
    $nest: {
      '& a': {
        color: 'white',
        $nest: {
          '&:hover, &:focus': {
            textDecoration: 'underline',
          },
        },
      },
    },
  }),
  linkContainer: style(
    contentBorder,
    {
      columnGap: px(16),
    },
    mediaLargeMobileOnly({
      columns: '2',
    }),
    mediaSmallTablet({
      columns: '4',
    })
  ),
  linkGroup: style({
    breakInside: 'avoid',
    $nest: {
      [`#root & h6`]: {
        fontSize: px(16),
        fontWeight: 600,
        margin: 0,
      },
      '& ul': {
        listStyle: 'none',
        padding: 0,
        marginTop: px(8),
      },
      '& li': {
        marginTop: px(8),
        marginBottom: px(8),
      },
      '& a': {
        color: colorWhite.toHexString(),
        textDecoration: 'none',
        $nest: {
          '&:hover, &:focus': {
            textDecoration: 'underline',
          },
        },
      },
    },
  }),
  blurb: style(contentBorder),
  legal: style(flexRoot, center, betweenJustified, contentBorder, {
    paddingTop: px(16),
    paddingBottom: px(16),
    fontSize: px(12),
    color: colorSubtle.toHexString(),
  }),
  legalLinks: style({
    listStyle: 'none',
    margin: 0,
    padding: 0,
    $nest: {
      '& li': {
        display: 'inline-block',
        paddingRight: px(8),
        marginRight: px(8),
        borderRight: '1px solid',
        borderRightColor: colorContrast100.toHexString(),
      },
      '& li:last-child': {
        border: 0,
      },
      '& a': {
        color: colorWhite.toHexString(),
        $nest: {
          '&:hover, &:focus': {
            textDecoration: 'underline',
          },
        },
      },
    },
  }),
  socialLinks: style(contentBorder, {
    $nest: {
      '& ul': {
        ...flexRoot,
        ...centerCenter,
        ...wrap,
        listStyle: 'none',
        padding: 0,
        margin: 0,
      },
      '& li': {
        marginRight: px(4),
        marginLeft: px(4),
      },
      '& > a': {
        ...flexRoot,
        ...centerCenter,

        width: px(32),
        height: px(32),
        borderRadius: em(1),
        backgroundColor: colorGreyAccent.toHexString(),
        filter: 'contrast(0.7)',
        transition: `filter ${transitionQuickEase}`,
        $nest: {
          '&:hover, &:focus': {
            filter: 'none',
          },
        },
      },
    },
  }),
  partnerLinks: style(flexRoot, centerJustified, {
    marginBottom: px(16),
    $nest: { '& > *': { ...flexRoot, ...centerCenter, marginRight: px(8), marginLeft: px(8) } },
  }),
  midemLabLogo: style({ width: px(76), height: 'auto' }),
  toTopButton: style(flexRoot, centerJustified),
  toTopLink: style(flexRoot, vertical, centerCenter, {
    backgroundColor: rebrand.primary1.toHexString(),
    fontSize: px(12),
    borderTopLeftRadius: px(8),
    borderTopRightRadius: px(8),
    padding: px(8),
    color: colorWhite.toHexString(),
  }),
  toTopArrow: style({
    transition: `transform ${transitionQuickEase}`,
    $nest: {
      ':hover > &, :focus > &': {
        transform: 'translateY(-4px)',
      },
    },
  }),
};

export type AppFooterProps = React.Component['props'] & {
  className?: string;
  faqs: PrismicFaq[];
  links: PrismicMenuLinkGroup[];
  socialLinks: PrismicSocialLink[];
};

export const AppFooter: React.SFC<AppFooterProps> = ({ className, faqs, links, socialLinks }) => (
  <footer className={classes(styles.wrapper, className)}>
    <div className={classes(styles.container)}>
      <h3 data-test-faq-title className={style(heading5, { textAlign: 'center' })}>
        Frequently Asked Questions
      </h3>
      <div>
        {isArray(faqs) && <PrismicFaqBlock faqs={faqs} />}
        <p className={styles.moreFaqCta}>
          <a data-test-faq-view-all-button href="https://help.lickd.co/knowledge">
            View all FAQs
          </a>
        </p>
      </div>
      <div className={styles.linkContainer}>
        {isArray(links) &&
          links.map((group, groupKey) => (
            <div data-test-marketing-column key={groupKey} className={styles.linkGroup}>
              <h6 data-test-marketing-column-title={group.primary.group_name}>{group.primary.group_name}</h6>
              <ul>
                {group.items.map((link, linkKey) => (
                  <li key={linkKey} data-test-marketing-column-item>
                    <PrismicMenuLinkComponent prismicFields={link} />
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </div>
      <div data-test-footer-copy className={styles.blurb}>
        <LickdRebrandLogo />
        <p>
          At Lickd, we&apos;re passionate about giving content creators access to better music. Through collaboration
          with both the music industry and social platforms like YouTube, Lickd is the first to offer creators
          mainstream music licenses and protection from copyright claims. With music from the world&apos;s biggest
          artists and royalty-free background tracks, Lickd gives you unmatched freedom in music for content creation.
        </p>
      </div>
      <div className={styles.legal}>
        <ul className={styles.legalLinks}>
          <li>
            <Link data-test-footer-legal-link={'terms'} href="https://lickd.co/terms-and-conditions">
              Terms & Conditions
            </Link>
          </li>
          <li>
            <Link data-test-footer-legal-link={'cookies'} href="https://lickd.co/cookie-policy">
              Cookie policy
            </Link>
          </li>
          <li>
            <Link data-test-footer-legal-link={'privacy'} href="https://lickd.co/privacy-policy">
              Privacy policy
            </Link>
          </li>
        </ul>
        <p data-test-footer-copyright-year>&copy; {new Date().getFullYear()} Lickd limited</p>
      </div>
      <div className={styles.socialLinks}>
        <ul>
          {isArray(socialLinks) &&
            socialLinks.map(({ link_type, link_url: { url } }, key) => {
              let button = null;
              switch (link_type) {
                case PrismicSocialLinkTypes.YOUTUBE:
                  button = (
                    <SocialButton
                      data-test-footer-social-button={PrismicSocialLinkTypes.YOUTUBE}
                      openInNewWindow={true}
                      href={url}
                      label="Lickd on YouTube"
                    >
                      <YouTubeLogo size={16} />
                    </SocialButton>
                  );
                  break;
                case PrismicSocialLinkTypes.SPOTIFY:
                  button = (
                    <SocialButton
                      data-test-footer-social-button={PrismicSocialLinkTypes.SPOTIFY}
                      openInNewWindow={true}
                      href={url}
                      label="Lickd on Spotify"
                    >
                      <SpotifyLogo size={16} />
                    </SocialButton>
                  );
                  break;
                case PrismicSocialLinkTypes.FACEBOOK:
                  button = (
                    <SocialButton
                      data-test-footer-social-button={PrismicSocialLinkTypes.FACEBOOK}
                      openInNewWindow={true}
                      href={url}
                      label="Lickd on Facebook"
                    >
                      <FacebookLogo size={9} />
                    </SocialButton>
                  );
                  break;
                case PrismicSocialLinkTypes.TWITTER:
                  button = (
                    <SocialButton
                      data-test-footer-social-button={PrismicSocialLinkTypes.TWITTER}
                      openInNewWindow={true}
                      href={url}
                      label="Lickd on Twitter"
                    >
                      <TwitterLogo size={15} />
                    </SocialButton>
                  );
                  break;
                case PrismicSocialLinkTypes.INSTAGRAM:
                  button = (
                    <SocialButton
                      data-test-footer-social-button={PrismicSocialLinkTypes.INSTAGRAM}
                      openInNewWindow={true}
                      href={url}
                      label="Lickd on Instagram"
                    >
                      <InstagramLogo size={15} />
                    </SocialButton>
                  );
                  break;
                case PrismicSocialLinkTypes.LINKEDIN:
                  button = (
                    <SocialButton
                      data-test-footer-social-button={PrismicSocialLinkTypes.LINKEDIN}
                      openInNewWindow={true}
                      href={url}
                      label="Lickd on LinkedIn"
                    >
                      <LinkedInLogo size={14} />
                    </SocialButton>
                  );
                  break;
              }
              return <li key={key}>{button}</li>;
            })}
        </ul>
      </div>
      <div className={styles.partnerLinks}>
        <a
          href="https://youtube.com"
          target="_blank"
          rel="noreferrer"
          aria-label="Lickd is YouTube Certified; Opens YouTube in a new window"
          data-test-footer-partner-button={'youtube'}
        >
          <SVGIcon.YouTubeCertified color={colorGreyAccent.toHexString()} />
        </a>
        <a
          href="https://stripe.com"
          target="_blank"
          rel="noreferrer"
          aria-label="Lickd is powered by Stripe; Opens Stripe in a new window"
          data-test-footer-partner-button={'stripe'}
        >
          <StripePoweredLogo size={75} color={colorGreyAccent.toHexString()} />
        </a>
        <a
          href="https://www.abbeyroad.com/abbeyroadred"
          target="_blank"
          rel="noreferrer"
          aria-label="Lickd is partnered with Abbey Road Red; Opens more information in a new window"
          data-test-footer-partner-button={'abbeyRoad'}
        >
          <AbbeyRoadPartner color={colorWhite.toHexString()} size={32} />
        </a>
        <a
          href="http://www.midem.com/en/music-events/midemlab/"
          target="_blank"
          rel="noreferrer"
          aria-label="Lickd was a Midemlab 2018 finalist; Opens more information in a new window"
          data-test-footer-partner-button={'midemlab'}
        >
          <img src={MidemLabLogo} className={styles.midemLabLogo} />
        </a>
      </div>
      <div className={styles.toTopButton}>
        <a
          href="#"
          onClick={(evt) => {
            evt.preventDefault();
            scrollToTop();
          }}
          className={styles.toTopLink}
          data-test-scroll-to-top
        >
          <ArrowUp color={colorWhite.toHexString()} className={styles.toTopArrow} aria-hidden="true" />
          Back to top
        </a>
      </div>
    </div>
  </footer>
);
