import * as React from 'react';
import { classes, extend, style } from 'typestyle';
import { ModalProps } from '../../modal';
import { Component, deps, inject, observer } from '../../../lib/component';
import { center, content, flexRoot, vertical } from 'csstips';
import { rebrand } from '../../../theme/color';
import { mediaMobileOnly } from '../utilities/mediaQueries';
import { ScrollableOverlay } from '../../scrollable-overlay/scrollable-overlay';
import { centerCenter } from 'csstips/lib/flex';
import { em, percent, px } from 'csx';
import { CircleButton } from '../atoms/button/CircleButton';
import { ChevronLeft } from '../atoms/icons/ChevronLeft';
import { ChevronRight } from '../atoms/icons/ChevronRight';
import { ButtonProminence } from '../atoms/button/buttonStyles';
import { getEmptySlicePageContext, renderComponentsFromPrismicSlices } from '../prismic-slices';
import { CSSTransition } from 'react-transition-group';
import { transitionExpandFade } from '../../../theme/transition';
import { PrismicContentIsland } from '../prismic-slices/PrismicContentIsland';
import { PrismicContentData } from '../../../modules/prismic-content-islands/model';
import siteEvents, { SITE_EVENTS } from '../utilities/siteEvents';
import { isEmpty } from '../utilities/objects';
import { getUid } from '../utilities/string';
import { nextTick } from '../utilities/asynchronous';
import { PrismicSliceCSSClasses } from '../utilities/types';

const styles = {
  modal: style(
    center,
    {
      padding: '0 0 16px',
    },
    mediaMobileOnly({
      height: '110%',
      borderRadius: '16px 16px 0 0',
    })
  ),
  title: style(content, {
    textAlign: 'center',
    margin: '12px 0px',
  }),
  body: style(flexRoot, centerCenter, vertical, content, {
    position: 'relative',
    border: `1px solid ${rebrand.light1.toString()}`,
    borderLeft: 0,
    borderRight: 0,
    width: percent(100),
    paddingTop: px(24),
    $nest: {
      '&:last-child': {
        borderBottom: 0,
      },
      [`& .${PrismicSliceCSSClasses.TWO_COLUMN_SLICE} > div`]: extend(vertical, {
        width: 'calc(100% - 32px)',
        $nest: {
          '& > *': {
            width: percent(100),
          },
        },
      }),
    },
  }),
};

export type SpawnedPrismicModalsProps = Record<string, never>;

type SpawnedPrismicModalConfiguration = {
  id: string;
  heading: string;
  content: PrismicContentData;
};
type SpawnedPrismicModalsState = {
  modals: Map<SpawnedPrismicModalConfiguration, boolean>;
};

export class SpawnedPrismicModals extends React.Component<SpawnedPrismicModalsProps, SpawnedPrismicModalsState> {
  spawnHandler: (modalConfig: Pick<SpawnedPrismicModalConfiguration, 'heading' | 'content'>) => void = null;

  state: SpawnedPrismicModalsState = {
    modals: new Map(),
  };

  componentDidMount() {
    this.spawnHandler = async (modalConfig) => {
      if (isEmpty(modalConfig)) return;
      const { heading, content } = modalConfig;
      if (typeof heading !== 'string' || (typeof content !== 'string' && isEmpty(content))) return;
      const { modals } = this.state;
      const modal = { id: getUid(), heading, content };
      modals.set(modal, false);
      this.setState({ modals });
      // We need to allow React to transition from hidden to a shown state, defer until the event loop completes and set to shown state
      await nextTick();
      modals.set(modal, true);
      this.setState({ modals });
    };
    siteEvents.addListener(SITE_EVENTS.SPAWN_MODAL, this.spawnHandler);
  }

  componentWillUnmount() {
    siteEvents.removeListener(SITE_EVENTS.SPAWN_MODAL, this.spawnHandler);
    this.spawnHandler = null;
  }

  closeModal(modal) {
    const { modals } = this.state;
    modals.set(modal, false);
    this.setState({ modals });
  }

  despawnModal(modal) {
    const { modals } = this.state;
    modals.delete(modal);
    this.setState({ modals });
  }

  render() {
    const { modals } = this.state;
    return (
      <>
        {Array.from(modals.entries()).map(([modal, show]) => (
          <ScrollableOverlay
            key={modal.id}
            className={styles.modal}
            show={show}
            onRequestClose={() => this.closeModal(modal)}
            onVisibilityChange={(isShown) => !isShown && this.despawnModal(modal)}
          >
            <h4 className={classes(styles.title)} data-test-spawned-modal-title>
              {modal.heading}
            </h4>
            <main className={styles.body} data-test-spawned-modal-content>
              <PrismicContentIsland content={modal.content} />
            </main>
          </ScrollableOverlay>
        ))}
      </>
    );
  }
}
