import * as React from 'react';
import { BaseContainer, BaseContainerProps } from '../../containers/base';
import { rebrandPageClass } from './utilities/page';
import { classes } from 'typestyle';

export const PageContainer: React.FC<Partial<BaseContainerProps>> = ({
  children,
  className,
  mainClassName,
  footerClassName,
  ...props
}) => (
  <BaseContainer
    className={classes('ph-page', className)}
    mainClassName={classes(rebrandPageClass, mainClassName)}
    footerClassName={footerClassName}
    {...props}
  >
    {children}
  </BaseContainer>
);
