import { EditorPageModel } from './model';
import { action } from 'mobx';
import { RedirectFunction, RouterState } from 'react-router';

export class EditorPageController {

  constructor(
    private model: EditorPageModel,
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {

    return;
  };
}