import * as React from 'react';
import Helmet from 'react-helmet';

import { Component, deps, inject, observer } from '../lib/component';
import { classes, style } from 'typestyle';
import { LayoutContent } from '../components/layout/content';
import { percent, px, rem } from 'csx';
import {
  betweenJustified,
  centerCenter,
  horizontal,
  horizontallySpaced,
  start,
  startJustified,
  vertical,
  verticallySpaced,
  wrap,
} from 'csstips';
import { ContainerQuery } from 'react-container-query';
import { FavouriteArtistButtonCompat } from '../components/compat/favourite-artist-button-compat';
import { AddThisShareBox } from '../components/social/add-this-share-box';
import { CTASignUpNewUserButton } from '../components/onboarding/cta-sign-up-new-user-button';
import { Loading } from '../components/loading/loading';
import { ButtonPill } from '../components/button-pill';
import { ReleaseTile } from '../components/release-tile';
import { TrackSchema } from '../types/schema';
import { formatCurrency } from '../lib/currency';
import { ArtistTile } from '../components/artist-tile';
import { colorGunmetal, colorSubtle, colorWhite, rebrand } from '../theme/color';
import { resetAnchorStyles } from '../theme/reset';
import { UIHeroGenre } from '../types/ui';
import { mediaMobileOnly, mediaTablet } from '../theme/media';
import MainWrapper from '../components/main-wrapper';
import { ArtistLinkList } from '../components/artist-link-list';
import { ConnectedTrackSimilarTracks } from '../components/track/similar-tracks';
import ReleaseCLine from '../components/release/c_line';
import TrackPLine from '../components/track/p_line';
import { isEmpty } from '../components/project-happy/utilities/objects';
import { BrowseMasthead } from '../components/project-happy/molecules/mastheads/BrowseMasthead';
import { defaultHeadingStyles } from '../components/project-happy/utilities/font';
import { TrackLine } from '../components/project-happy/molecules/TrackLine';

export interface TrackPageProps {
  i18n?: {
    loading: string;
  };
}

@inject(deps)
@observer
export class TrackPage extends Component<TrackPageProps> {
  render() {
    const { i18n = TrackPage.i18n } = this.props;
    const { track, loading, pageBody } = this.props.model.page.track;
    const { preloadCover } = this.props.controller.image;
    const { handleClickLink } = this.props.controller.ui;
    const { props } = this;
    // Track title and id: Hot Sex 'a28cf3c985a142a68d6fd5fea1c39fa0', Sexy Sexy '6797e1cdfd1f439682423c5bdf65357e', Sex Game 'f9869e7e01ea47c4a696b68d4a074793'
    const tracksWithExplicitTitle = [
      'a28cf3c985a142a68d6fd5fea1c39fa0',
      '6797e1cdfd1f439682423c5bdf65357e',
      'f9869e7e01ea47c4a696b68d4a074793',
    ];

    let hasExplicitTrackTitle = false;

    if (!isEmpty(track) && tracksWithExplicitTitle.includes(track.identity)) {
      hasExplicitTrackTitle = true;
    } else {
      hasExplicitTrackTitle = false;
    }

    let image = null;
    try {
      image = track.images.identity;
      // eslint-disable-next-line no-empty
    } catch (e) {}

    if (isEmpty(track) || loading) {
      return <Loading className={TrackPage.styles.loading} />;
    }

    return (
      <MainWrapper className={TrackPage.styles.container}>
        <div>
          {hasExplicitTrackTitle && (
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
          )}
        </div>
        <BrowseMasthead heading={!isEmpty(track) && track.title} gradient={{ from: rebrand.dark1.toString() }}>
          {!isEmpty(track) && track.artists && (
            <h2>
              <ArtistLinkList
                linkClassName={TrackPage.styles.artistSubTitle}
                model={{ artists: track.artists }}
                onClickLink={handleClickLink}
              />
            </h2>
          )}
        </BrowseMasthead>
        {!loading && (
          <ContainerQuery
            query={TrackPage.query}
            /* eslint-disable-next-line react/no-children-prop */
            children={(params: any = TrackPage.defaultQuery) => <TrackPageContent narrow={params.narrow} />}
          />
        )}
      </MainWrapper>
    );
  }

  static query = {
    narrow: {
      maxWidth: 760,
    },
  };

  static defaultQuery = {
    narrow: false,
  };

  static i18n = {
    loading: 'Loading...',
  };

  static styles = {
    loading: style({
      margin: '25vh 0',
    }),
    container: style({
      minHeight: percent(100),
      flexGrow: 1,
    }),
    titleBackground: style({
      backgroundColor: colorGunmetal.toString(),
    }),
    artistSubTitle: style(defaultHeadingStyles, {
      color: rebrand.light2.toString(),
      fontSize: px(32),
    }),
    headerControls: style({
      ...horizontal,
      ...horizontallySpaced(10),
      position: 'absolute',
      bottom: px(16),
      right: px(16),
    }),
    artistHeader: style({
      color: colorWhite.toString(),
      margin: 0,
    }),
    artistLink: style({
      color: colorWhite.toString(),
      textDecoration: 'underline',
      $nest: {
        ...resetAnchorStyles(colorWhite.toString()),
      },
    }),
    body: style({
      padding: px(16),
      background: colorGunmetal.toString(),
      color: colorWhite.toString(),
    }),
  };
}

interface TrackPageContentProps {
  narrow?: boolean;
  onClickLink?: (event: React.MouseEvent<any>) => void;
  i18n?: {
    artists: string;
    track: string;
    price: string;
    nonBranded: string;
    priceBranded: string;
    brandSponsored: string;
    similar: string;
  };
}

const cline = (trackModel): string => {
  const { track } = trackModel;
  const release = track.releases[0];

  if (release && release.c_line != null && release.c_line != '') {
    return release.c_line.replace(ReleaseCLine.symbol, '');
  }

  return parseInt(track.available_at) + ' ' + track.rightsholders[0].name;
};

const pline = (track): string => {
  if (track && track.p_line != null && track.p_line != '') {
    return track.p_line.replace(TrackPLine.symbol, '');
  }

  return parseInt(track.available_at) + ' ' + track.rightsholders[0].name;
};

const combinedPCLine = (track, trackModel): string => {
  const c_line = cline(trackModel);
  const p_line = pline(track);

  if (p_line == c_line) {
    return ReleaseCLine.symbol + ' ' + TrackPLine.symbol + c_line;
  }

  return ReleaseCLine.symbol + ' ' + c_line + '<br />' + TrackPLine.symbol + ' ' + p_line;
};

@inject(deps)
@observer
class TrackPageContent extends Component<TrackPageContentProps> {
  render() {
    const { onClickLink, narrow, i18n = TrackPageContent.i18n } = this.props;
    const { track, artists, genres } = this.props.model.page.track;
    const { preloadImage, preloadWaveformSvg } = this.props.controller.image;
    const { currency, ratecard } = this.props.model.basket;
    const { isMobile } = this.props.model.ui;
    const { handleClickLink } = this.props.controller.ui;
    const { isSubscribed, unsubscribedRatecard } = this.props.model.user;

    const rate = ratecard && ratecard.rate ? formatCurrency(ratecard.rate, currency) : i18n.price;
    const unsubscribedRate =
      unsubscribedRatecard && unsubscribedRatecard.rate
        ? formatCurrency(unsubscribedRatecard.rate, currency)
        : i18n.price;

    let pricing: React.ReactNode = `Premium ${rate}`;
    if (isSubscribed && track.is_stock_music) {
      pricing = 'Included in your subscription';
    } else if (isSubscribed) {
      pricing = (
        <>
          Premium <s>{unsubscribedRate}</s> {rate}
        </>
      );
    } else if (!isSubscribed && track.is_stock_music) {
      pricing = 'Included in a Lickd Creator plan';
    }

    const listedRightsholders = track.rightsholders ? track.rightsholders.filter((label) => label.is_listed) : [];

    return (
      <LayoutContent
        innerClassName={classes(TrackPageContent.styles.split, narrow && TrackPageContent.styles.splitNarrow)}
      >
        <div className={TrackPageContent.styles.main}>
          <h3>{i18n.track}</h3>

          {!!track.description && <div dangerouslySetInnerHTML={{ __html: track.description }} />}

          <div>
            <TrackLine track={track} onClickLink={onClickLink} />
          </div>

          <div className={TrackPageContent.styles.priceContainer}>
            <div className={TrackPageContent.styles.price}>
              <h3>Price</h3>
              <div data-test-track-page-track-price>{pricing}</div>
            </div>
          </div>

          <div className={TrackPageContent.styles.details}>
            {genres && genres.length > 0 && (
              <div className={TrackPageContent.styles.genres}>
                <h3>Genres</h3>
                <div>
                  {genres.map((genre, index) => (
                    <ButtonPill
                      muted={true}
                      href={`/browse/genre/${genre.slug}`}
                      onClick={this.handleClickGenre.bind(this, genre)}
                      className={TrackPageContent.styles.pill}
                      key={index.toString()}
                    >
                      {genre.label}
                    </ButtonPill>
                  ))}
                </div>
              </div>
            )}

            {listedRightsholders.length > 0 && (
              <div className={TrackPageContent.styles.genres}>
                <h3>Labels</h3>
                <div>
                  {listedRightsholders.map((rightsholder, index) => (
                    <ButtonPill
                      muted={true}
                      href={`/search?rightsholder=${rightsholder.name}`}
                      onClick={handleClickLink}
                      className={TrackPageContent.styles.pill}
                      key={index.toString()}
                    >
                      {rightsholder.name}
                    </ButtonPill>
                  ))}
                </div>
              </div>
            )}

            {((track.tempo && track.tempo !== 'Unknown') || !!track.bpm) && (
              <div className={TrackPageContent.styles.tempo}>
                <h3>Tempo</h3>
                <div>
                  {!!track.bpm && (
                    <ButtonPill
                      muted={true}
                      href={this.getBpmHref(track)}
                      onClick={this.props.controller.ui.handleClickLink}
                      className={TrackPageContent.styles.pill}
                    >
                      {track.bpm} BPM
                    </ButtonPill>
                  )}
                  {!!track.tempo && (
                    <ButtonPill
                      muted={true}
                      href={this.getTempoHref(track)}
                      onClick={this.props.controller.ui.handleClickLink}
                      className={TrackPageContent.styles.pill}
                    >
                      {track.tempo}
                    </ButtonPill>
                  )}
                </div>
              </div>
            )}
          </div>

          {track.identity && track.mixes.length > 0 && (
            <div className={TrackPageContent.styles.tracks}>
              <h3 className={TrackPageContent.styles.heading}>Mixes</h3>
              {track.mixes.map((mix, i) => (
                <TrackLine key={i} track={mix} onClickLink={onClickLink} mix={true} />
              ))}
            </div>
          )}

          {track.identity && (
            <ConnectedTrackSimilarTracks
              identity={track.identity}
              containerStyles={TrackPageContent.styles.tracks}
              headingText={i18n.similar}
              headingStyles={TrackPageContent.styles.heading}
            />
          )}

          {track.releases && track.releases.length > 0 && (
            <div>
              <h3>Appears on</h3>
              <div className={TrackPageContent.styles.releases}>
                {track.releases.map((release, index) => (
                  <ReleaseTile
                    href={`/music/artists/${release.artist.slug}/release/${release.slug}`}
                    key={index.toString()}
                    className={TrackPageContent.styles.release}
                    release={release}
                    preload={preloadImage}
                    onClickLink={handleClickLink}
                  />
                ))}
              </div>
            </div>
          )}

          <CTASignUpNewUserButton />
        </div>

        <div className={TrackPageContent.styles.aside}>
          <h3>{i18n.artists}</h3>
          <div className={TrackPageContent.styles.grid}>
            {artists.map((artist, index) => (
              <ArtistTile
                key={index.toString()}
                artist={artist}
                preload={preloadImage}
                Favourite={FavouriteArtistButtonCompat}
                withLabel={true}
                onClickLink={handleClickLink}
              />
            ))}
          </div>

          <div
            className={TrackPageContent.styles.meta}
            dangerouslySetInnerHTML={{ __html: combinedPCLine(track, this.props.model.page.track) }}
          />
          <AddThisShareBox className={TrackPageContent.styles.shareButtons} />
        </div>
      </LayoutContent>
    );
  }

  getTempoHref = ({ bpm, tempo }: TrackSchema) => {
    const nextTempoRange = TrackPageContent.tempos.find((t) => t.label.toLowerCase() === tempo.toLowerCase());

    if (nextTempoRange && nextTempoRange.range) {
      const [min, max] = nextTempoRange.range;

      return `/search?bpmMin=${min}&bpmMax=${max}`;
    }
  };

  getBpmHref = ({ bpm }: TrackSchema) => {
    return `/search?bpmMin=${bpm}&bpmMax=${bpm}`;
  };

  handleClickGenre = (genre: UIHeroGenre, event: React.MouseEvent<any>) => {
    event.preventDefault();
    this.props.model.router.push(`/browse/genre/${genre.slug}`);
  };

  static tempos = [
    {
      range: [0, 80],
      label: 'Very Slow',
    },
    {
      range: [80, 110],
      label: 'Slow',
    },
    {
      range: [110, 130],
      label: 'Medium',
    },
    {
      range: [130, 150],
      label: 'Fast',
    },
    {
      range: [150, 300],
      label: 'Very Fast',
    },
  ];

  static i18n = {
    artists: 'Artists',
    track: 'Track',
    price: 'from $8',
    nonBranded: 'Non-Branded',
    priceBranded: 'from $80',
    similar: 'Similar tracks',
  };

  static styles = {
    split: style({ ...horizontal }, mediaMobileOnly({ ...vertical })),
    splitNarrow: style({
      ...vertical,
    }),
    main: style(
      {
        flex: 2,
        padding: px(16),
        height: percent(100),
        $nest: {
          '&> div': {
            margin: '0 0 40px 0',
          },
        },
      },
      mediaMobileOnly({
        width: percent(100),
        height: 'auto',
        flex: 'none',
      })
    ),
    childNarrow: style({
      width: percent(100),
      height: 'auto',
      flex: 'none',
    }),
    aside: style(
      {
        flex: 1,
        padding: px(16),
        height: percent(100),
      },
      mediaMobileOnly({
        width: percent(100),
        height: 'auto',
        flex: 'none',
      })
    ),
    grid: style({
      ...horizontal,
      ...wrap,
      width: percent(100),
      $nest: {
        '&> *': {
          width: percent(50),
        },
        '&> *:only-child': {
          width: percent(100),
        },
      },
    }),
    details: style(
      {
        ...horizontal,
        ...horizontallySpaced(16),
        ...startJustified,
        ...start,
      },
      mediaMobileOnly({
        ...vertical,
        ...verticallySpaced(16),
      })
    ),
    tempo: style({
      flex: 1,
      $nest: {
        '& > div': {
          ...horizontal,
          ...horizontallySpaced(5),
          ...wrap,
        },
      },
    }),
    genres: style({
      flex: 2,
      $nest: {
        '& > div': {
          ...horizontal,
          ...horizontallySpaced(5),
          ...wrap,
        },
      },
    }),
    moods: style({
      flex: 2,
    }),
    releases: style({ ...horizontal, ...wrap, width: percent(100) }),
    release: style({ width: percent(25) }, mediaMobileOnly({ width: percent(50) })),
    priceContainer: style(
      mediaTablet({
        ...horizontal,
        ...horizontallySpaced(16),
        ...betweenJustified,
        flex: 1,
        textAlign: 'left',
      }),
      mediaMobileOnly({
        ...vertical,
        ...verticallySpaced(10),
      })
    ),
    price: style({
      flex: 1,
      textAlign: 'left',
    }),
    priceAlert: style({
      flex: 2,
    }),
    ctaContainer: style({
      ...centerCenter,
      ...vertical,
      ...verticallySpaced(16),
      padding: px(16),
    }),
    pill: style({
      margin: '0 0 5px 0',
    }),
    shareButtons: style({
      padding: '40px 0',
    }),
    tracks: style({
      margin: '16px 0',
      $nest: {
        '& h3': {
          margin: '30px 0',
        },
        '& h3 a': {
          color: colorGunmetal.toString(),
        },
      },
    }),
    loadMore: style({
      ...centerCenter,
      padding: '16px',
    }),
    heading: style({
      margin: '30px 0',
    }),
    meta: style({
      color: colorSubtle.toString(),
      fontSize: rem(0.875),
    }),
  };
}
