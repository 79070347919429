import * as React from 'react';
import { Component, deps, inject, observer } from '../lib/component';
import { ArtistHeader } from '../components/artist/header';
import { ArtistBio } from '../components/artist/bio';
import { LayoutContent } from '../components/layout/content';
import { FormattedMessage } from 'react-intl';
import { AddRemoveCompat } from '../components/compat/add-remove-compat';
import { PlayButtonCompat } from '../components/compat/play-button-compat';
import { Loading } from '../components/loading/loading';
import { classes, style } from 'typestyle';
import { percent, px } from 'csx';
import { CTASignUpNewUserButton } from '../components/onboarding/cta-sign-up-new-user-button';
import { AddThisShareBox } from '../components/social/add-this-share-box';
import { ContainerQuery } from 'react-container-query';
import { horizontal, vertical } from 'csstips';
import { FavouriteButtonCompat } from '../components/compat/favourite-button-compat';
import { ModalGeneric } from '../components/modal-generic';
import { colorBackgroundAlt, colorLightGrey, rebrand } from '../theme/color';
import { ReleaseTracks } from '../components/release-tracks';
import { Release } from '../components/release';
import { mediaMobileOnly } from '../theme/media';
import InlineStrap from '../components/inline-strap';
import MainWrapper from '../components/main-wrapper';
import { ConnectedArtistLandingPage } from '../components/artist/landing-page';
import { BrowseMasthead } from '../components/project-happy/molecules/mastheads/BrowseMasthead';
import { isEmpty } from '../components/project-happy/utilities/objects';
import { TrackLine } from '../components/project-happy/molecules/TrackLine';
import styles from '../components/collapsible/styles';

interface MusicArtistPageProps {
  params: {
    artist: string;
  };
}

@inject(deps)
@observer
export class MusicArtistPage extends Component<MusicArtistPageProps> {
  render() {
    const { artist } = this.props.model.page;
    const { controller, model } = this.props;
    const { preloadCover } = this.props.controller.image;

    if (!artist.loading && artist.isMissing) return <ConnectedArtistLandingPage slug={artist.slug} />;

    if (artist.loading) {
      return <Loading className={MusicArtistPage.styles.loading} />;
    }

    return (
      <MainWrapper className={MusicArtistPage.styles.container}>
        <BrowseMasthead
          heading={!isEmpty(artist) && !isEmpty(artist.artist) && artist.artist.name}
          gradient={{ from: rebrand.dark1.toString() }}
        />
        <ContainerQuery query={MusicArtistPage.query}>
          {(params: any = MusicArtistPage.defaultQuery) => <MusicArtistPageContent narrow={params.narrow} />}
        </ContainerQuery>
        {!artist.loading && (
          <ModalGeneric model={artist.bioModal} onRequestClose={controller.page.artist.hideBio}>
            <h3>{artist.artist.name}</h3>
            <p dangerouslySetInnerHTML={{ __html: artist.artist.description }}></p>
          </ModalGeneric>
        )}
      </MainWrapper>
    );
  }

  componentWillUnmount() {
    this.props.controller.ui.setTitle();
    this.props.controller.ui.setDescription();
  }

  static styles = {
    loading: style({
      margin: '25vh 0',
    }),
    container: style({
      minHeight: percent(100),
      flexGrow: 1,
    }),
    split: style({ ...horizontal }, mediaMobileOnly({ ...vertical })),
    splitNarrow: style({
      ...vertical,
    }),
    main: style(
      {
        flex: 2,
        padding: px(16),
        minHeight: percent(100),
        background: colorBackgroundAlt.toString(),
        $nest: {
          '&> div': {
            margin: '0 0 40px 0',
          },
        },
      },
      mediaMobileOnly({
        width: percent(100),
        height: 'auto',
        flex: 'none',
      })
    ),
    childNarrow: style({
      width: percent(100),
      height: 'auto',
      flex: 'none',
    }),
    aside: style(
      {
        flex: 1,
        height: percent(100),
      },
      mediaMobileOnly({
        width: percent(100),
        height: 'auto',
        flex: 'none',
      })
    ),
    soundsLike: style({
      padding: px(16),
    }),
    trackLineWrapper: style({
      borderBottom: `1px solid ${colorLightGrey.toString()}`,
      maxWidth: '1000px',
    }),
  };

  static query = {
    narrow: {
      maxWidth: 760,
    },
  };

  static defaultQuery = {
    narrow: false,
  };
}

interface MusicArtistPageChildProps {
  narrow?: boolean;
  onClickLink?: (event: React.MouseEvent<any>) => void;
}

@inject(deps)
@observer
class MusicArtistPageContent extends Component<MusicArtistPageChildProps> {
  render() {
    const { narrow, onClickLink } = this.props;
    const { artist } = this.props.model.page;
    const { controller, model } = this.props;
    const { preloadImage, preloadWaveformSvg } = this.props.controller.image;
    const { isMobile } = this.props.model.ui;
    const { handleClickLink } = this.props.controller.ui;

    return (
      <LayoutContent
        innerClassName={classes(MusicArtistPage.styles.split, narrow && MusicArtistPage.styles.splitNarrow)}
        loading={artist.loading}
      >
        <div className={classes(MusicArtistPage.styles.main, narrow && MusicArtistPage.styles.childNarrow)}>
          {!artist.loading && !artist.release && artist.tracks.length > 0 && (
            <h3 data-test-top-tracks-header>
              <FormattedMessage id="artist.tracks.title" />
            </h3>
          )}
          {!artist.loading && !artist.release && artist.tracks.length > 0 && (
            <div>
              {artist.tracks.map((track, i) => (
                <div key={i} className={MusicArtistPage.styles.trackLineWrapper}>
                  <TrackLine track={track} onClickLink={onClickLink} />
                </div>
              ))}
            </div>
          )}
          {!artist.loading &&
            artist.releases
              .sort((a, b) => b.release_date.timestamp - a.release_date.timestamp)
              .map((release, index) => (
                <Release
                  key={index}
                  Loading={Loading}
                  AddRemove={AddRemoveCompat}
                  PlayButton={PlayButtonCompat}
                  Favourite={FavouriteButtonCompat}
                  infinite={true /* TODO handle SSR for releases (e.g. ui.isServer) */}
                  model={artist.releaseModels[release.identity]}
                  onClickLink={handleClickLink}
                  preload={preloadImage}
                  preloadWaveform={preloadWaveformSvg}
                  isMobile={isMobile}
                  analytics={this.props.controller.analytics}
                />
              ))}
          {!artist.loading && artist.misc && artist.misc.length > 0 && (
            <h3 data-test-more-tracks-header>More tracks</h3>
          )}
          {!artist.loading && artist.misc && artist.misc.length > 0 && (
            <div>
              {artist.misc.map((track, i) => (
                <div key={i} className={MusicArtistPage.styles.trackLineWrapper}>
                  <TrackLine track={track} onClickLink={onClickLink} />
                </div>
              ))}
            </div>
          )}
          <CTASignUpNewUserButton hideMobile={true} />
        </div>
        <div className={classes(MusicArtistPage.styles.aside, narrow && MusicArtistPage.styles.childNarrow)}>
          <ArtistBio
            loading={artist.loading}
            artist={artist.artist}
            release={artist.activeRelease}
            similarArtists={artist.similarArtists}
            loadingSimilarArtists={artist.loadingSimilarArtists}
            otherReleases={artist.otherReleases}
            tracks={artist.tracks}
            router={model.router}
            preload={preloadImage}
            onShowBio={controller.page.artist.showBio}
          />
          {!artist.loading && <AddThisShareBox className={MusicArtistPageContent.styles.share} />}
          <CTASignUpNewUserButton hideDesktop={true} />
        </div>
      </LayoutContent>
    );
  }

  static styles = {
    share: style({
      padding: px(16),
    }),
  };
}
