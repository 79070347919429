import { AccountOrderPageModel } from './model';
import { RouterState, RedirectFunction } from 'react-router';
import { runInAction } from 'mobx';
import { APIController } from '../../api/controller';
import { UserModel } from '../../user/model';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import { stripQueryString } from '../../../lib/string';

export class AccountOrderPageController {
  constructor(
    private user: UserModel,
    private model: AccountOrderPageModel,
    private api: APIController,
    private env: EnvModel,
    private ui: UIController
  ) {}

  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    const identity = stripQueryString(nextState.params['identity']);

    if (!identity)
      return replace({
        pathname: '/account/orders',
      });

    runInAction(() => {
      this.model.identity = identity;
      this.model.loading = true;
      this.ui.setBreadcrumbs([
        {
          path: '/account',
          label: 'Account',
        },
        {
          path: '/account/orders',
          label: 'Orders',
        },
      ]);
    });

    await this.ui.setSEO(nextState.location.pathname.replace(/\/[\d\w]+$/, ''));

    const [order, channels] = await Promise.all([
      this.api.user.getOrder(this.user.user.identity, identity),
      this.api.user.getYouTubeChannels(this.user.user.identity),
      this.env.ready,
    ]);

    runInAction(() => {
      this.model.order = order.data;
      this.model.loading = false;
      this.model.channels =
        order.data.youtube_channels &&
        order.data.youtube_channels.map((youtubeChannel) =>
          channels.data.find((channel) => channel.id === youtubeChannel.id)
        );
    });

    return;
  };
}
