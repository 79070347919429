import * as React from 'react';
import { style } from 'typestyle';
import { Component, deps, inject, observer } from '../../../lib/component';
import { colorSubtle, colorWhite, rebrand } from '../../../theme/color';
import { StripePoweredLogo } from '../atoms/icons/brands/StripePoweredLogo';
import { Cancel } from '../atoms/icons/Cancel';
import { ContactMail } from '../atoms/icons/ContactMail';
import { CreditCard } from '../atoms/icons/CreditCard';
import { InfoCircle } from '../atoms/icons/InfoCircle';
import { Visibility } from '../atoms/icons/Visibility';
import { ActionButton } from '../atoms/button/ActionButton';
import { center, flexRoot, startJustified, vertical } from 'csstips';
import { centerCenter } from 'csstips/lib/flex';
import { ButtonProminence } from '../atoms/button/buttonStyles';
import { OffboardingModal } from '../organisms/OffboardingModal';
import { percent, px } from 'csx';
import { SUPPORT_HUB } from '../../../constants';
import { prosperStack } from '../../../prosperstack';
import { Skeleton } from '../atoms/Skeleton';
import { spacing } from '../../../theme/spacing';
import { SubscriptionStates, V2Plan } from '../utilities/types';
import { PreCancellationModal } from './plans-v2/pre-cancellation-modal/PreCancellationModal';

export interface ManageSubscriptionCardProps {}

@inject(deps)
@observer
export class ManageSubscriptionCard extends Component<ManageSubscriptionCardProps> {
  state = {
    loading: null,
    error: false,
    showOffboardingModal: false,
    showPreCancellationModal: false,
  };

  constructor(props) {
    super(props);
  }

  async navigate(loading) {
    this.setState({ loading });
    try {
      await this.props.controller.subscription.navigateToStripePortal();
    } catch {
      this.setState({ error: true });
    }
    this.setState({ loading: null });
  }

  async handleCancelSubscription(): Promise<void> {
    const {
      user: { refreshUser },
      subscription: { pollChannelForChanges },
    } = this.props.controller;

    this.setState({ showPreCancellationModal: false });

    if (this.props.model.user.isSubscribedToV2Plan) {
      this.handleCancelSubscriptionV2();
    } else {
      const result = await prosperStack.flow({
        clientId: this.props.model.env.prosperStackClientId,
        subscription: {
          platformId: this.props.model.user.activeSubscription.stripe_id,
        },
      });

      if (result.status === 'canceled') {
        await refreshUser();
        pollChannelForChanges(SubscriptionStates.CANCELLED);
      }
    }
  }

  async changeSubscription(nextPlan: V2Plan): Promise<void> {
    this.setState({ showPreCancellationModal: false });
    const { navigateToStripeSubscriptionChange } = this.props.controller.subscription;

    const { plan_name, stripe_id } = nextPlan;

    navigateToStripeSubscriptionChange(stripe_id, plan_name);
  }

  async handleCancelSubscriptionV2(): Promise<void> {
    const { channel } = this.props.model.user;
    const {
      user: { refreshUser },
      subscription: { setV2PlanIsUpdating, pollChannelForChanges },
    } = this.props.controller;

    setV2PlanIsUpdating(true);

    if (channel && channel.stripe_subscription_id) {
      const result = await prosperStack.flow({
        clientId: this.props.model.env.prosperStackClientId,
        subscription: {
          platformId: channel.stripe_subscription_id,
        },
      });

      setV2PlanIsUpdating(false);

      if (result.status === 'canceled') {
        await refreshUser();
        pollChannelForChanges(SubscriptionStates.CANCELLED);
      }
    }
  }

  onCancellationRequest(): void {
    const { channel, isSubscribedToV2Plan } = this.props.model.user;

    const pendingSubscription =
      isSubscribedToV2Plan && channel.pending_subscription ? channel.pending_subscription : null;

    if (pendingSubscription) {
      this.handleCancelSubscription()
    } else {
      this.setState({ showPreCancellationModal: true });
    }
  }

  render() {
    const {
      model: {
        user: {
          isSubscribed,
          isSubscribedWithoutChannel,
          isSubscribedAndCancelled,
          isSubscribedToV2PlanAndCancelled,
          loading: userLoading,
          loadingChannels,
        },
        subscription: { v2PlanIsUpdating },
      },
    } = this.props;
    const { loading, showOffboardingModal, showPreCancellationModal, error } = this.state;

    if (userLoading || loadingChannels) {
      return (
        <div className={ManageSubscriptionCard.styles.container}>
          <Skeleton height={244} />
        </div>
      );
    }

    if (!isSubscribed && !isSubscribedWithoutChannel) return null;

    return (
      <div className={ManageSubscriptionCard.styles.container}>
        <h3>Manage my subscription</h3>
        {error && (
          <p className={ManageSubscriptionCard.styles.error}>
            An error occurred redirecting you to the subscription management portal. Please{' '}
            <a href={SUPPORT_HUB}>submit a support ticket</a> so we can help you with your subscription.
          </p>
        )}

        <ActionButton
          className={ManageSubscriptionCard.styles.cardSlice}
          prominence={ButtonProminence.NORMAL}
          onClick={() => this.navigate('view-status')}
          loading={loading === 'view-status'}
          data-test-manage-subscription-view-status
        >
          <Visibility className={ManageSubscriptionCard.styles.icon} color={colorWhite.toHexString()} />
          View Status
        </ActionButton>

        <ActionButton
          className={ManageSubscriptionCard.styles.cardSlice}
          prominence={ButtonProminence.NORMAL}
          onClick={() => this.navigate('update-payment-method')}
          loading={loading === 'update-payment-method'}
          data-test-manage-subscription-update-payment-method
        >
          <CreditCard className={ManageSubscriptionCard.styles.icon} color={colorWhite.toHexString()} />
          Update payment method
        </ActionButton>

        <ActionButton
          className={ManageSubscriptionCard.styles.cardSlice}
          prominence={ButtonProminence.NORMAL}
          onClick={() => this.navigate('update-billing-method')}
          loading={loading === 'update-billing-method'}
          data-test-manage-subscription-update-billing-information
        >
          <ContactMail className={ManageSubscriptionCard.styles.icon} color={colorWhite.toHexString()} />
          Update billing information
        </ActionButton>

        {!isSubscribedToV2PlanAndCancelled && !isSubscribedAndCancelled && (
          <ActionButton
            className={ManageSubscriptionCard.styles.cardSlice}
            prominence={ButtonProminence.NORMAL}
            onClick={() => this.onCancellationRequest()}
            loading={loading === 'cancel'}
            disabled={v2PlanIsUpdating}
            data-test-manage-subscription-cancel
          >
            <Cancel className={ManageSubscriptionCard.styles.icon} color={colorWhite.toHexString()} />
            Cancel subscription
          </ActionButton>
        )}

        <OffboardingModal
          show={showOffboardingModal}
          onRequestClose={() => this.setState({ showOffboardingModal: false })}
          onSubmit={() => this.navigate('cancel')}
        />

        <div className={ManageSubscriptionCard.styles.stripePowered}>
          <StripePoweredLogo />

          <p>
            <InfoCircle size={13} color={colorSubtle.toHexString()} /> You will be re-directed to Stripe Dashboard
          </p>
        </div>

        <PreCancellationModal
          show={showPreCancellationModal}
          onRequestClose={() => this.setState({ showPreCancellationModal: false })}
          handleCancellation={() => this.handleCancelSubscription()}
          handleDowngrade={(nextPlan) => this.changeSubscription(nextPlan)}
        />
      </div>
    );
  }

  static styles = {
    container: style(flexRoot, vertical, centerCenter, {
      width: '100%',
      marginTop: spacing.XXXLARGE,
      $nest: {
        '& h3': {
          fontSize: px(48),
          margin: `0 0 ${spacing.DEFAULT}`,
          textAlign: 'center',
        },
      },
    }),
    cardSlice: style(flexRoot, startJustified, {
      width: percent(100),
      display: 'flex',
      fontWeight: 700,
      borderRadius: 0,
      borderBottom: '1px solid #13192D',
      paddingBottom: '16px !important',
      paddingTop: '16px !important',
      textAlign: 'left',
      $nest: {
        '&:disabled': {
          background: rebrand.dark1.toString(),
          color: rebrand.neutralOnDark[10].toString(),
          $nest: {
            '& svg:not(.badge) path': {
              fill: rebrand.neutralOnDark[30].toString(),
            },
            '&:hover svg:not(.badge) path': {
              fill: rebrand.neutralOnDark[30].toString(),
            },
          },
        },
      },
    }),
    icon: style({
      margin: '0 8px 0 0',
    }),
    error: style({ color: 'red' }),
    stripePowered: style(flexRoot, vertical, center, {
      margin: '16px',
      $nest: {
        '& p': {
          fontSize: '10px',
          color: colorSubtle.toHexString(),
        },
        '& svg': {
          verticalAlign: 'middle',
        },
      },
    }),
  };
}
