import * as React from 'react';
import {graphql, InjectedGraphQLProps} from 'react-apollo';
import gql from 'graphql-tag';
import {ArtistLanding, Track} from '../../types/graphql';
import maybe from '../../lib/maybe';
import GradientCover from '../gradient-cover';
import {classes, style} from 'typestyle';
import {ResponsiveImage} from '../responsive-image';
import {deps, inject, observer, StatefulComponent} from '../../lib/component';
import {em, important, percent, rem, viewWidth} from 'csx';
import {transitionQuickEase} from '../../theme/transition';
import {center, centerCenter, content, flex1, horizontal, startJustified} from 'csstips';
import {mediaMobileOnly, mediaTablet, mediaTabletLandscape} from '../../theme/media';
import {colorBlack, colorWhite} from '../../theme/color';
import {AddRemoveCompat} from '../compat/add-remove-compat';
import {PlayButtonCompat} from '../compat/play-button-compat';
import {FavouriteButtonCompat} from '../compat/favourite-button-compat';
import HeroSearchResults from '../hero/search-results';
import {Carousel} from '../carousel';
import {Loading} from '../loading/loading';
import {TrackTile} from '../track-tile';

type Props = {
  slug: string;
}

type State = {}

type Data = {
  getArtistLanding: ArtistLanding
}

type CombinedProps = Props & InjectedGraphQLProps<Data>

@inject(deps) @observer
export default class ArtistLandingPage extends StatefulComponent<CombinedProps, State> {
  render() {
    const {data} = this.props;
    const {preloadContent, preloadRaw, preloadImage} = this.props.controller.image;
    const {handleClickLink} = this.props.controller.ui;

    const artist = maybe(
      () => data!.getArtistLanding!,
      null
    );

    const isFallback = artist && artist.type === 'FALLBACK';

    const title = artist && artist.name
      ? artist.name
      : 'Drat...';

    const imageIdentity = artist && artist.image
      ? artist.image.identity
      : void 0;

    const imageLoader = artist && artist.image
      ? isFallback
        ? preloadRaw
        : preloadContent
      : preloadContent;

    const tracks = ArtistLandingPage.getTracks(artist);
    const hasTracks = tracks.length > 0;

    return (
      <div className={ArtistLandingPage.styles.container}>
        <ResponsiveImage
          asBackground={true}
          preload={imageLoader}
          className={classes(
            ArtistLandingPage.styles.background,
            ArtistLandingPage.styles.backgroundMatch,
            ArtistLandingPage.styles.backgroundImageSlide,
          )}
          identity={imageIdentity}
        />
        <GradientCover
          reverse={true}
          className={classes(
            ArtistLandingPage.styles.background,
            ArtistLandingPage.styles.backgroundGradient,
          )}
        />
        {!data.loading && (
          <div>
            <h1 className={ArtistLandingPage.styles.title}>
              {title}
            </h1>
            <div className={ArtistLandingPage.styles.strap}>
              {ArtistLandingPage.getStrap(artist, hasTracks)}
            </div>
            <div className={ArtistLandingPage.styles.results}>
              <Carousel className={HeroSearchResults.styles.carousel} innerClassName={HeroSearchResults.styles.carouselInner} spacerClassName={HeroSearchResults.styles.carouselSpacer}>
                {data.loading && new Array(6).fill(1).map((_, i) => (
                  <div key={i} className={classes(HeroSearchResults.styles.track, HeroSearchResults.styles.loading)}>
                    <Loading light={true}/>
                  </div>
                ))}
                {!data.loading && tracks.map((item, index) => (
                  <TrackTile
                    analytics={this.props.controller.analytics}
                    scale={1.2}
                    key={index}
                    track={item}
                    onClickLink={handleClickLink}
                    PlayButton={PlayButtonCompat}
                    AddRemove={AddRemoveCompat}
                    Favourite={FavouriteButtonCompat}
                    preload={preloadImage}
                    className={HeroSearchResults.styles.track}
                    // onClickMore={this.handleMore.bind(this, item)}
                  />
                ))}
              </Carousel>
            </div>
          </div>
        )}
      </div>
    )
  }

  componentDidUpdate(lastProps: CombinedProps) {
    if (this.props.data && this.props.data.getArtistLanding) {
      const slug = this.props.data.getArtistLanding.slug || this.props.slug;
      const name = this.props.data.getArtistLanding.name;

      const breadcrumbs = [
        {
          path: '/browse',
          label: 'Browse'
        },
        {
          path: '/browse/artists',
          label: 'Artists'
        },
      ];

      if (slug) breadcrumbs.push({
        path: '/browse/artists/' + slug[0].toLowerCase(),
        label: slug[0].toUpperCase()
      });

      if (slug && name) breadcrumbs.push({
        path: '/music/artists/' + slug,
        label: this.props.data.getArtistLanding.name
      });

      this.props.controller.ui.setBreadcrumbs(breadcrumbs)
    }
  }

  static getTracks = (artist: ArtistLanding | null): Track[] => {
    if (!artist)
      return [];

    return [
      ...(artist.tracks || []),
      ...(artist.tags
        ? artist.tags.reduce((tracks, tag) => tag.tracks && tag.tracks.results
          ? [...tracks, ...tag.tracks.results]
          : tracks,
          [])
        : [])
    ];
  };

  static getStrap = (artist: ArtistLanding | null, hasTracks: boolean) => {
    const suffix = hasTracks
      ? ` Perhaps one of these tracks might work for now?`
      : '';

    switch (true) {
      case (artist && artist.type === 'ACTIVE'):
        return `Discover epic music by ${artist.name} for your project right here`;

      case (artist && artist.type === 'INACTIVE'):
        return `Coming soon, listen close! ${suffix}`;

      case (artist && artist.type === 'FALLBACK'):
        return `Sorry bud, we're not sure when we'll have ${artist.name} for you...${suffix}`;

      default:
        return `We could't find any matching artist.${suffix}`
    }
  };

  static styles = {
    container: style(
      {
        ...center,
        ...startJustified,
        ...horizontal,
        position: 'relative',
        height: 'calc(100vh - 10rem)',
        maxWidth: rem(64),
        zIndex: 0,
      },
      mediaMobileOnly({
        minHeight: rem(26),
        padding: rem(1)
      }),
      mediaTablet({
        minHeight: rem(42),
        padding: rem(5),
        maxHeight: rem(64),
      }),
      mediaTabletLandscape({
        minHeight: important('32rem')
      })
    ),
    background: style({
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      width: viewWidth(100),
      padding: 0,
      overflow: 'hidden',
    }),
    backgroundImageSlide: style({
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      position: 'absolute',
      transition: `opacity ${transitionQuickEase}`,
      backgroundColor: colorBlack.toString(),
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    }),
    backgroundMatch: style({
      zIndex: -2,
    }),
    backgroundGradient: style({
      zIndex: -1,
    }),
    title: style(
      {
        margin: 0,
        color: colorWhite.toString(),
      },
      mediaMobileOnly({
        width: percent(100),
        fontSize: viewWidth(11)
      }),
      mediaTablet({
        width: viewWidth(45),
        fontSize: rem(5)
      }),
      mediaTabletLandscape({
        fontSize: important('5vw'),
      }),
    ),
    strap: style(
      {
        margin: '1rem 0 0 0',
        color: colorWhite.toString(),
        lineHeight: em(1.375),
        fontWeight: 200,
        maxWidth: rem(50)
      },
      mediaMobileOnly({
        width: percent(100),
        fontSize: rem(1.125),
      }),
      mediaTablet({
        width: viewWidth(69),
        fontSize: rem(1.375),
      })
    ),
    results: style(
      {
        ...flex1,
        ...centerCenter,
        ...content,
        width: viewWidth(100),
        marginTop: rem(2)
      },
      mediaMobileOnly({
        margin: '1rem -1rem 0'
      }),
      mediaTablet({
        margin: '5rem -5rem 0'
      }),
    ),
  }
}

export const ConnectedArtistLandingPage = graphql(gql`
  query (
    $slug: String!
  ) {
    getArtistLanding(slug: $slug) {
      type
      name
      image {
        identity
      }
      tracks {
        identity
        title
        slug
        duration
        created_at
        is_charting
        is_featured
        is_new_release
        brand_sponsored
        branded_content
        audio {
          identity
        }
        images {
          identity
        }
        artists {
          images {
            identity
          }
          name
          slug
          identity
        }
        rightsholders {
          name
        }
      }
      tags {
        slug
        name
        tracks {
          results {
            identity
            title
            slug
            duration
            created_at
            is_charting
            is_featured
            is_new_release
            brand_sponsored
            branded_content
            audio {
              identity
            }
            images {
              identity
            }
            artists {
              images {
                identity
              }
              name
              slug
              identity
            }
          }
        }
      }
    }
  }
`, {
  options: (variables: Props) => ({
    variables,
    // fetchPolicy: 'network-only'
  }),
})(ArtistLandingPage);