import * as React from 'react';
import { BaseSvg, SVGIconProps } from '../BaseSvg';

export const InstagramLogo: React.SFC<SVGIconProps> = ({ size = 22, color = '#E03566', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 22 22" {...props}>
    <path
      d="M11 0C8.01 0 7.64.01 6.46.07a8.09 8.09 0 0 0-2.67.5c-.73.28-1.4.72-1.94 1.28A5.4 5.4 0 0 0 .58 3.8C.3 4.5.12 5.3.07 6.47A78.51 78.51 0 0 0 0 11c0 2.99.01 3.36.07 4.54.05 1.16.24 1.96.5 2.66a5.4 5.4 0 0 0 1.28 1.96c.55.55 1.21.99 1.95 1.26.7.27 1.5.46 2.67.51C7.64 22 8 22 11 22s3.36-.01 4.54-.07a8.15 8.15 0 0 0 2.66-.5 5.4 5.4 0 0 0 1.96-1.28 5.4 5.4 0 0 0 1.26-1.95c.27-.7.46-1.5.51-2.67C22 14.36 22 14 22 11s-.01-3.36-.07-4.54a8.15 8.15 0 0 0-.5-2.67 5.4 5.4 0 0 0-1.28-1.94A5.4 5.4 0 0 0 18.2.58C17.5.3 16.7.12 15.53.07A78.51 78.51 0 0 0 11 0Zm0 1.98c2.94 0 3.29.01 4.45.07 1.07.05 1.65.22 2.04.38.51.2.88.43 1.26.82.39.38.63.75.82 1.26.16.39.34.97.38 2.04.06 1.17.07 1.51.07 4.45 0 2.94-.01 3.29-.07 4.45a6.07 6.07 0 0 1-.38 2.04c-.2.51-.43.88-.82 1.26-.38.39-.75.63-1.26.82-.39.16-.97.34-2.04.38-1.16.06-1.51.07-4.45.07-2.94 0-3.29-.01-4.45-.07a6.07 6.07 0 0 1-2.04-.38c-.51-.2-.88-.43-1.26-.82a3.4 3.4 0 0 1-.83-1.26 6.12 6.12 0 0 1-.38-2.04A77.7 77.7 0 0 1 1.98 11c0-2.94.01-3.29.07-4.45.05-1.07.22-1.65.38-2.04.2-.51.43-.88.82-1.26.38-.39.75-.63 1.26-.83.39-.15.97-.32 2.04-.38A77.7 77.7 0 0 1 11 1.98Zm0 12.69a3.67 3.67 0 1 1 0-7.34 3.67 3.67 0 0 1 0 7.34Zm0-9.32a5.65 5.65 0 1 0 0 11.3 5.65 5.65 0 0 0 0-11.3Zm7.2-.22a1.32 1.32 0 1 1-2.65 0 1.32 1.32 0 0 1 2.64 0"
      fill={color}
      fillRule="evenodd"
    />
  </BaseSvg>
);
