import React, { ReactNode } from 'react';
import { classes, cssRule, style } from 'typestyle';
import { colorWhite, rebrand } from '../../../theme/color';
import { minBodyHeight } from '../config';
import ReferralBgLeft from '../../../static/referral-bg-left.jpg';
import ReferralBgRight from '../../../static/referral-bg-right.jpg';
import ReferralBgMobile from '../../../static/referral-bg-mobile.jpg';
import { url } from 'csx';
import { important, percent, px } from 'csx/lib/internal';
import { centerCenter } from 'csstips/lib/flex';
import { ChannelAvatar } from '../atoms/ChannelAvatar';
import { Button } from '../atoms/button/Button';
import { center, flexRoot, vertical } from 'csstips';
import { Component, ComponentProps, deps, inject, observer } from '../../../lib/component';
import { Loading } from '../../loading/loading';
import { contentHeadingStyles, heading5 } from '../utilities/font';
import { rebrandPageClass } from '../utilities/page';
import { TagLabel } from '../atoms/icons/TagLabel';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { mediaSmallTablet, mediaUpToLargeMobile, mediaUpToSmallTablet } from '../utilities/mediaQueries';
import { CopyBox } from '../../copy-box';
import { BROWSE_ROUTE, SIGN_UP_ROUTE } from '../../../constants';
import { Helmet } from 'react-helmet';
import { EnvModel } from '../../../modules/env/model';
import { UserReferrerAvatar } from '../atoms/UserReferrerAvatar';
import { getEmptySlicePageContext, renderComponentsFromPrismicSlices } from '../prismic-slices';
import { PrismicSlice, PrismicSlices, RefereePageSingleDatas } from '../utilities/types';
import { renderPrismicRichTextArray } from '../utilities/props';
import { fontBebasNeue } from '../../../theme/font';

const styles = {
  page: style(
    centerCenter,
    {
      background: rebrand.contrast[90].toString(),
      flexDirection: 'column',
      minHeight: minBodyHeight,
      $nest: {
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          display: 'block',
          backgroundRepeat: 'no-repeat',
          height: minBodyHeight,
          width: percent(100),
        },
        '& h1': {
          fontSize: px(40),
          color: colorWhite.toHexString(),
          fontFamily: important(fontBebasNeue),
        },
      },
    },
    mediaUpToSmallTablet({
      $nest: {
        '&::before': {
          backgroundImage: url(ReferralBgMobile),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        },
      },
    }),
    mediaSmallTablet({
      $nest: {
        '&::before, &::after': {
          backgroundSize: 'contain',
        },
        '&::before': {
          backgroundImage: url(ReferralBgLeft),
          left: 0,
        },
        '&::after': {
          backgroundImage: url(ReferralBgRight),
          right: 0,
          backgroundPosition: 'right',
        },
      },
    }),
    mediaUpToLargeMobile({
      $nest: {
        h1: {
          fontSize: px(32),
        },
      },
    })
  ),
  contentWrapper: style(
    flexRoot,
    center,
    {
      zIndex: 1,
    },
    mediaUpToSmallTablet({
      paddingLeft: px(16),
      paddingRight: px(16),
    }),
    mediaSmallTablet({
      width: percent(50),
      maxWidth: px(550),
      paddingLeft: px(32),
      paddingRight: px(32),
      background: [
        rebrand.contrast[90].toString(),
        `linear-gradient(90deg, ${rebrand.contrast[90]
          .fadeOut(100)
          .toString()} 0%, ${rebrand.contrast[90].toString()} 5%, ${rebrand.contrast[90].toString()} 95%, ${rebrand.contrast[90]
          .fadeOut(100)
          .toString()} 100%)`,
      ],
    })
  ),
  midWidthContentWrapper: style({
    width: '100%',
  }),
  fullWidthContentWrapper: style(
    mediaUpToSmallTablet({
      paddingLeft: px(16),
      paddingRight: px(16),
    }),
    mediaSmallTablet({
      paddingLeft: px(32),
      paddingRight: px(32),
    }),
    {
      width: '100%',
      textAlign: 'center',
      zIndex: 1,
    }
  ),
  content: style(vertical, center, {
    width: percent(100),
    color: rebrand.contrast[10].toString(),
    textAlign: 'center',
    marginBottom: px(80),
    $nest: {
      '& > *': {
        marginTop: 0,
        marginBottom: px(16),
      },
    },
  }),
  heading: style(heading5, {
    fontSize: px(20),
  }),
  button: style(flexRoot, center, {
    gap: px(4),
  }),
  copyBox: style({
    width: percent(100),
  }),
  imgWrapper: style({
    width: '100%',
  }),
  creatorImg: style(mediaUpToLargeMobile({ padding: '0px 16px' }), {
    maxWidth: '1312px',
    width: '100%',
    padding: '0px 64px',
  }),
  partnerImg: style({
    maxWidth: '550px',
    width: '100%',
  }),
  marginTop: style({
    marginTop: '40px',
  }),
};

cssRule(`.${rebrandPageClass} .${styles.heading}`, contentHeadingStyles, { fontWeight: 700 });
interface ReferralWrapperProps {
  children?: ReactNode;
  slices?: PrismicSlice[];
  singleDatas?: RefereePageSingleDatas;
  handleSignupTrackMixPanel?: () => void;
}
const ReferralWrapper: React.FC<ReferralWrapperProps> = ({
  children,
  slices = [],
  singleDatas = null,
  handleSignupTrackMixPanel,
}) => {
  const HowItWorkSlice = slices.filter((slice) => slice['slice_type'] === PrismicSlices.HOW_IT_WORKS_REFEREE);
  const listItemSlice = slices.filter((slice) => slice['slice_type'] === PrismicSlices.LIST_ITEM_TEXTS);
  const PricingImagesSlice = slices.filter((slice) => slice['slice_type'] === PrismicSlices.PRICING_IMAGE);

  return (
    <div className={styles.page}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={styles.contentWrapper}>
        <div className={classes(styles.content, styles.marginTop)}>
          {children}
          {listItemSlice.length > 0 && renderComponentsFromPrismicSlices(listItemSlice, getEmptySlicePageContext())}
        </div>
      </div>
      {/* Non LoggedIn user */}
      {PricingImagesSlice.length > 0 && (
        <div className={styles.fullWidthContentWrapper}>
          <div className={styles.content}>
            {renderComponentsFromPrismicSlices(PricingImagesSlice, getEmptySlicePageContext())}
          </div>
        </div>
      )}
      {singleDatas && (
        <>
          <div className={styles.fullWidthContentWrapper}>
            <div className={styles.content}>
              {renderPrismicRichTextArray(singleDatas.big_creators_heading, 'heading1')}

              <div className={styles.imgWrapper}>
                <img
                  className={styles.creatorImg}
                  src={singleDatas.big_creators_image.url}
                  alt={singleDatas.big_creators_image.alt}
                />
              </div>
            </div>
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              {renderPrismicRichTextArray(singleDatas.partners_heading, 'heading1')}

              <div className={styles.imgWrapper}>
                <img
                  className={styles.partnerImg}
                  src={singleDatas.partners_logos.url}
                  alt={singleDatas.partners_logos.alt}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {HowItWorkSlice.length > 0 && (
        <div className={styles.fullWidthContentWrapper}>
          <div className={styles.content}>
            {renderComponentsFromPrismicSlices(HowItWorkSlice, getEmptySlicePageContext())}
          </div>
        </div>
      )}
      {slices.length > 0 && !!handleSignupTrackMixPanel && (
        <div style={{ marginBottom: 64 }}>
          <Button
            href={SIGN_UP_ROUTE}
            className={styles.button}
            prominence={ButtonProminence.PRIMARY}
            size={ButtonSize.LARGE}
            disabled={EnvModel.isServer}
            onClick={handleSignupTrackMixPanel}
          >
            <TagLabel />
            Claim your 50% off
          </Button>
        </div>
      )}
    </div>
  );
};

@inject(deps)
@observer
export class Referral extends Component<ComponentProps> {
  handleSignupTrackMixPanel = () => {
    return this.props.controller.analytics.sendMixpanel('User clicks sign-up (referral page)', {
      referralURL: this.props.model.user.referralURL,
    });
  };
  render() {
    const {
      model: {
        auth: { token },
        user: { channel: userChannel, referralURL, user },
        page: {
          channelReferral: { channel, loading, referrer, slices, singleDatas },
        },
      },
    } = this.props;
    const isLoggedIn = typeof token === 'string' && token.length > 0;
    if (loading || (isLoggedIn && !userChannel)) {
      return (
        <ReferralWrapper>
          <Loading />
        </ReferralWrapper>
      );
    }

    if (isLoggedIn) {
      return (
        <ReferralWrapper>
          {user ? <UserReferrerAvatar size={100} user={user} /> : <ChannelAvatar size={100} channel={channel} />}
          <h1 className={styles.heading}>You&apos;re already part of the revolution!</h1>
          <p>
            Earn sweet discounts by sharing your unique referral code with your pals and by adding it to your YouTube
            attribution. Simply copy and paste the link, add it to your YouTube video attributions, or share on your
            socials.
          </p>
          <CopyBox className={styles.copyBox} content={referralURL} />
          <Button
            href={BROWSE_ROUTE}
            className={styles.button}
            prominence={ButtonProminence.PRIMARY}
            size={ButtonSize.LARGE}
            onClick={() => {
              this.props.controller.analytics.sendMixpanel('User clicks browse tracks (referral page)', {
                referralURL,
              });
            }}
          >
            Browse latest tracks
          </Button>
        </ReferralWrapper>
      );
    }
    return (
      <ReferralWrapper
        slices={slices}
        singleDatas={singleDatas}
        handleSignupTrackMixPanel={this.handleSignupTrackMixPanel}
      >
        {referrer ? <UserReferrerAvatar size={100} user={referrer} /> : <ChannelAvatar size={100} channel={channel} />}
        <h1 className={styles.heading}>
          Limited time offer, courtesy&nbsp;of{' '}
          <span style={{ display: 'block' }}>{referrer ? referrer.name : channel ? channel.name : 1}</span>
        </h1>
        <p>You&apos;ve got 50% off your first mainstream track waiting for you...</p>
        <noscript>You must enable javascript to apply the referral</noscript>
        <Button
          href={SIGN_UP_ROUTE}
          className={styles.button}
          prominence={ButtonProminence.PRIMARY}
          size={ButtonSize.LARGE}
          disabled={EnvModel.isServer}
          onClick={this.handleSignupTrackMixPanel}
        >
          <TagLabel />
          Claim your 50% off
        </Button>
      </ReferralWrapper>
    );
  }
}
