import React from 'react';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';

/**
 * A component to wrap client only components
 * i.e. components which use browser only functionality like hooks
 */
const ClientOnly = inject(deps)(
  observer(
    ({
      children,
      model: {
        env: { isServer },
      },
    }: React.PropsWithChildren<ComponentProps>) => {
      if (isServer) return null;

      return <>{children}</>;
    }
  )
);

export default ClientOnly;
