import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { percent, px } from 'csx';
import { ContentSlice, ContentSliceProps } from './ContentSlice';
import { colorContrast, rebrand } from '../../../../theme/color';
import { mediaDesktop, mediaLargeTablet, mediaUpToDesktop, mediaUpToLargeTablet } from '../../utilities/mediaQueries';
import { isArray } from '../../utilities/objects';
import { centerJustified } from 'csstips';
import { screenReaderOnly } from '../../utilities/font';
import { Link } from '../../atoms/Link';
import { desktopContentWidth } from '../../config';
import { SourceHTMLAttributes } from 'react';
import { ResponsiveImage } from '../../atoms/ResponsiveImage';

const yPadding = px(56);

const styles = {
  wrapper: style(
    mediaUpToDesktop({
      paddingBottom: px(32),
      $nest: {
        '.masthead + &, .with-background + &:not(.with-background), :not(.with-background) + &.with-background, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient': {
          paddingTop: px(32),
        },
      },
    }),
    mediaDesktop({
      paddingBottom: px(56),
      $nest: {
        '.masthead + &, .with-background + &:not(.with-background), :not(.with-background) + &.with-background, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient': {
          paddingTop: px(56),
        },
      },
    })
  ),
  altText: style(screenReaderOnly),
};

type PromotionalBannerSliceProps = ContentSliceProps & {
  src: string | string[];
  width?: number | number[];
  height?: number | number[];
  colour?: string;
  href: string;
  internal: boolean;
  alt: string;
};

export const PromotionalBannerSlice: React.SFC<PromotionalBannerSliceProps> = ({
  src,
  width,
  height,
  colour,
  href,
  internal = true,
  alt,
  className,
  ...props
}) => {
  const LinkComponent = internal === true ? Link : (props) => <a target="_blank" {...props} />;
  return (
    <ContentSlice className={classes('promotional-banner-slice', className, styles.wrapper)} {...props}>
      <LinkComponent href={href}>
        <ResponsiveImage
          src={src}
          width={width}
          height={height}
          alt={alt}
          style={{
            backgroundColor: colour || rebrand.dark1.toString(),
          }}
        />
        <span className={styles.altText}>{alt}</span>
      </LinkComponent>
    </ContentSlice>
  );
};
