import { centerCenter, flexRoot } from 'csstips';
import { percent, px } from 'csx';
import * as React from 'react';
import { classes, media, style } from 'typestyle';
import { Component, ComponentProps, deps, inject, observer } from '../../../lib/component';
import { Alternative, colorBrand, colorSubtle, rebrand } from '../../../theme/color';
import { Loading } from '../../loading/loading';
import { defaultHeadingStyles } from '../utilities/font';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { Button } from '../atoms/button/Button';
import { DarkRadioInput } from '../atoms/controls/DarkRadioInput';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ArrowClockwise } from '../atoms/icons/ArrowClockwise';
import { animationSpin } from '../../../theme/animation';
import { Alert } from '../molecules/Alert';
import { SemanticInformationType } from '../utilities/types';
import { ArrowUp } from '../atoms/icons/ArrowUp';
import { AddCircle } from '../atoms/icons/AddCircle';
import { TransitionGroup } from 'react-transition-group';
import { YouTubeVideo } from '../../../types/youtube';
import { VideoCard } from '../molecules/VideoCard';
import { VideoClaimStatusesModal } from '../organisms/VideoClaimsStatusesModal';
import { SubmitClaimIssueModal } from '../organisms/SubmitClaimIssueModal';
import { mediaLargeTablet, mediaUpToLargeMobile, mediaUpToLargeTablet } from '../utilities/mediaQueries';
import { AccountLayout } from '../templates/AccountLayout';
import { BackToTopButton } from '../atoms/button/BackToTopButton';
import { LoadMoreButton } from '../atoms/button/LoadMoreButton';
import { isEmpty } from '../utilities/objects';
import { ConnectChannelPrompt } from '../molecules/ConnectYoutubeChannelPrompt';
import VideoClaimsStatus from '../molecules/VideoClaimsStatus';
import { handleClaimsCheck } from '../utilities/videoClaimCheck';
import { claimStatusMap } from '../../../constants';
import { borderRadius } from '../../../theme/border';
import { spacing } from '../../../theme/spacing';

dayjs.extend(relativeTime);
const styles = {
  mainContentClassName: style({ maxWidth: px(1376), padding: '0 16px' }),
  heading: style(
    mediaUpToLargeMobile({
      marginBottom: '32px',
    }),
    defaultHeadingStyles,
    { flexGrow: 1, marginBottom: '64px' }
  ),
  containerLoading: style({
    ...centerCenter,
  }),
  empty: style({
    ...centerCenter,
    height: px(120),
    fontWeight: 200,
    fontSize: px(18),
    color: colorSubtle.toString(),
  }),
  checkboxes: style(
    {
      display: 'flex',
      width: '100%',
      padding: '8px 16px',
      backgroundColor: rebrand.neutralOnDark[10].toHexString(),
      borderRadius: borderRadius.SMALL,
      flexWrap: 'wrap',
      gap: '8px 0',
    },
    mediaLargeTablet({
      width: 'auto',
    })
  ),
  checkbox: style({ marginRight: '8px', padding: '4px 8px', borderRadius: '4px' }),
  radioButton: style({
    marginLeft: '4px',
    verticalAlign: 'middle',
  }),
  headerWrapper: style(
    mediaUpToLargeMobile({
      marginBottom: '32px',
    }),
    {
      marginBottom: '64px',
    }
  ),
  buttonWrapper: style({ display: 'flex', justifyContent: 'flex-end' }),
  header: style(flexRoot, {
    alignItems: 'baseline',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: spacing.DEFAULT,
  }),
  refreshButton: style({}),
  refreshIcon: style({ verticalAlign: 'middle' }),
  refreshIconLoading: style(animationSpin),
  reconnectLinkAction: style({
    fontSize: 'inherit',
    color: colorBrand.toHexString(),
    textDecoration: 'underline',
    paddingLeft: 0,
  }),
  cardWrapper: style({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  }),
  videoCard: style(
    {
      margin: px(8),
    },
    mediaLargeTablet({
      width: 'calc(50% - 16px)',
    })
  ),
  cardActionButton: style({
    backgroundColor: rebrand.neutralOnDark[20].toHexString(),
    boxShadow: 'none',
    padding: '4px 14px',
    minWidth: '150px',
    whiteSpace: 'nowrap',
  }),
  cardFooter: style(
    mediaUpToLargeMobile({
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    }),
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '16px',
    }
  ),
  claimStatusWrapper: style(
    mediaUpToLargeMobile({
      width: '100%',
      justifyContent: 'space-between',
    }),
    {
      display: 'flex',
      alignItems: 'center',
      marginRight: '8px',
    }
  ),
  knownClaim: style(
    mediaUpToLargeMobile({
      marginLeft: '0',
    }),
    {
      fontSize: '12px',
      color: rebrand.neutralOnLight[80].toHexString(),
      marginRight: '8px',
    }
  ),
  private: style({
    $nest: {
      '&:hover': {
        background: rebrand.semantic[SemanticInformationType.ERROR]['20'].toString(),
      },
      '& span': {
        background: rebrand.semantic[SemanticInformationType.ERROR]['20'].toString(),
      },
    },
  }),
  unlisted: style({
    $nest: {
      '&:hover': {
        background: rebrand.semantic[SemanticInformationType.WARNING]['20'].toString(),
      },
      '& span': {
        background: rebrand.semantic[SemanticInformationType.WARNING]['20'].toString(),
      },
    },
  }),
  all: style({
    $nest: {
      '&:hover': {
        background: rebrand.neutralOnDark[20].toString(),
      },
      '& span': {
        background: rebrand.neutralOnDark[20].toString(),
      },
    },
  }),
  public: style({
    $nest: {
      '&:hover': {
        background: Alternative.BabyBlue[10].toString(),
      },
      '& span': {
        background: Alternative.BabyBlue[10].toString(),
      },
    },
  }),
};

const videoTypes = [
  { key: 'all', label: 'All' },
  { key: 'public', label: 'Public' },
  { key: 'unlisted', label: 'Unlisted' },
  { key: 'private', label: 'Private & Draft' },
];

@inject(deps)
@observer
export class VideoAccountPage extends Component<ComponentProps> {
  state = {
    shownVideo: null,
    claimsStatusModalShow: false,
    newIssueShow: false,
  };

  claimStatusText = (claim: string): string => {
    if (claim === claimStatusMap.PENDING) {
      return 'Open claim(s) on video';
    }
    if (claim === claimStatusMap.CLEARED) {
      return 'All known claims cleared';
    }
  };

  render() {
    const {
      model: {
        user: { user, loading: userIsLoading, channel, loadingAccounts, loadingChannels },
        page: {
          accountVideos: {
            videos,
            visible,
            pagination,
            loading,
            refreshingVideos,
            refreshSuccess,
            channelErrors,
            rateLimitHit,
            unknownError,
          },
        },
        router: {
          location: { pathname },
        },
      },
      controller: {
        page: {
          accountVideos: { callVideos, loadMoreVideos, refreshVideos },
        },
        modal: { showReconnectChannelModal, showChannelAddModal },
        analytics: { sendMixpanel },
      },
    } = this.props;

    const loadingUserDetails = userIsLoading || loadingAccounts || loadingChannels;

    const Header = (
      <div className={styles.headerWrapper}>
        <h1 className={styles.heading}>Videos & Claims</h1>
        <div className={styles.header}>
          <div data-test-video-account-page-toggles className={styles.checkboxes}>
            {videoTypes.map((v) => (
              <label className={classes(styles.checkbox, styles[v.key])} key={v.key}>
                {v.label}
                <DarkRadioInput
                  className={styles.radioButton}
                  value={v.key}
                  name="video-privacy"
                  disabled={loading}
                  checked={visible === v.key}
                  onChange={() => callVideos(v.key)}
                  data-test-video-account-page-toggle={v.key}
                  data-test-toggle-selected={(visible === v.key) === true}
                />
              </label>
            ))}
          </div>
          <Button
            className={styles.refreshButton}
            prominence={ButtonProminence.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={refreshVideos}
            disabled={refreshingVideos || loading}
            data-test-video-account-page-refresh
          >
            Refresh{' '}
            <ArrowClockwise className={classes(styles.refreshIcon, refreshingVideos && styles.refreshIconLoading)} />
          </Button>
        </div>
      </div>
    );

    if (isEmpty(user) || loading || loadingUserDetails)
      return (
        <AccountLayout mainContentClassName={styles.mainContentClassName}>
          {Header}
          <div className={styles.containerLoading}>
            <Loading />
          </div>
        </AccountLayout>
      );

    if (isEmpty(channel) && !loading && !loadingAccounts && isEmpty(user.default_channel))
      return (
        <AccountLayout mainContentClassName={styles.mainContentClassName}>
          <ConnectChannelPrompt
            showChannelAddModal={showChannelAddModal}
            sendMixpanel={sendMixpanel}
            location={pathname}
          />
        </AccountLayout>
      );

    return (
      <AccountLayout mainContentClassName={styles.mainContentClassName}>
        <VideoClaimStatusesModal
          show={this.state.claimsStatusModalShow}
          onRequestClose={() => this.setState({ claimsStatusModalShow: false })}
          onRequestNewClaimIssue={() => this.handleClickSubmitClaimIssue()}
          video={this.state.shownVideo}
        />
        <SubmitClaimIssueModal
          show={this.state.newIssueShow}
          onRequestClose={() => this.setState({ newIssueShow: false })}
          video={this.state.shownVideo}
        />
        <div>
          {Header}
          <TransitionGroup>
            {channelErrors.map(({ channel, error }, key) =>
              error.code === 403 ? (
                <Alert key={key} type={SemanticInformationType.ERROR}>
                  We&apos;ve lost access to <strong>{channel.name}</strong>.{' '}
                  <Button
                    className={styles.reconnectLinkAction}
                    prominence={ButtonProminence.NONE}
                    onClick={() => showReconnectChannelModal(channel)}
                    data-test-video-account-page-reconnect-channel={channel.id}
                  >
                    Reconnect channel to see new videos
                  </Button>
                </Alert>
              ) : (
                <Alert key={key} type={SemanticInformationType.ERROR} title="Network error">
                  <p>A network error has occurred.</p>
                  <details>
                    <summary>More information</summary>
                    <pre>
                      <code>{error.reason}</code>
                    </pre>
                  </details>
                </Alert>
              )
            )}
          </TransitionGroup>
          <Alert show={rateLimitHit} type={SemanticInformationType.WARNING}>
            You can only refresh your videos list three times every hour.
          </Alert>
          <Alert show={unknownError} type={SemanticInformationType.ERROR}>
            An unknown error occurred while refreshing your video list.
          </Alert>
          <Alert show={refreshSuccess} type={SemanticInformationType.SUCCESS}>
            {rateLimitHit || unknownError || channelErrors.length > 0 ? 'Some' : 'All'} channels refreshed.
          </Alert>

          {videos.length === 0 && (
            <div data-test-video-account-page-no-videos-copy className={styles.empty}>
              Sorry you haven&apos;t got any {visible} videos at the moment
            </div>
          )}

          <div className={styles.cardWrapper}>
            {videos.map((video, index) => {
              const videoClaim = handleClaimsCheck(video.claims);
              return (
                <VideoCard
                  className={styles.videoCard}
                  video={video}
                  key={index}
                  data-test-video-account-page-video-card={index}
                >
                  <div className={styles.cardFooter}>
                    <Button
                      prominence={ButtonProminence.SECONDARY}
                      size={ButtonSize.SMALL}
                      onClick={() => this.handleClickClaimsStatuses(video)}
                      className={styles.cardActionButton}
                      data-test-video-account-page-view-claims-statuses-button
                    >
                      Video claims statuses
                    </Button>
                    {video.claims && video.claims.length > 0 && (
                      <div className={styles.claimStatusWrapper}>
                        <p className={styles.knownClaim}> {this.claimStatusText(videoClaim)}</p>
                        <VideoClaimsStatus claim={video.privacy_status !== 'private' && videoClaim} />
                      </div>
                    )}
                  </div>
                </VideoCard>
              );
            })}
          </div>

          <div className={styles.buttonWrapper}>
            <BackToTopButton data-test-video-account-page-back-to-top />
            {pagination && pagination.total_pages > 1 && (
              <LoadMoreButton
                onClick={loadMoreVideos}
                disabled={pagination.current_page === pagination.total_pages}
                loading={loading}
                data-test-video-account-page-load-more
              />
            )}
          </div>
        </div>
      </AccountLayout>
    );
  }

  handleClickClaimsStatuses = (video: YouTubeVideo): void => {
    this.setState({ shownVideo: video, claimsStatusModalShow: true });
    this.props.controller.analytics.sendMixpanel('User opens Claim Statuses modal', {
      videoId: video.id,
      channelId: video.channel.id,
    });
  };

  handleClickSubmitClaimIssue = (video?: YouTubeVideo): void => {
    if (video) {
      this.setState({ shownVideo: video });
    }
    this.setState({ claimsStatusModalShow: false, newIssueShow: true });
    const { shownVideo } = this.state;
    this.props.controller.analytics.sendMixpanel('User opens Submit Claim Issue modal', {
      videoId: shownVideo.id,
      channelId: shownVideo.channel.id,
    });
  };
}
