import { AccountVideosPageModel } from './model';
import { RedirectFunction, RouterState } from 'react-router';
import { action, runInAction } from 'mobx';
import { APIController } from '../../api/controller';
import { UserModel } from '../../user/model';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import { promiseAllSettled } from '../../../components/project-happy/utilities/functions';
import { APIError } from '../../api/types';
import { BugsnagController } from '../../bugsnag/controller';

export class AccountVideosPageController {
  constructor(
    private user: UserModel,
    private model: AccountVideosPageModel,
    private api: APIController,
    private env: EnvModel,
    private ui: UIController,
    private bugsnag: BugsnagController
  ) {}

  @action
  callVideos = async (visibility: string) => {
    this.model.loading = true;
    this.model.visible = visibility;
    this.model.page = 1;
    this.model.videos = [];
    const videos = await this.api.user.getYouTubeVideos(this.user.user.identity, this.model.visible, this.model.page);

    this.model.videos = videos.data;
    this.model.pagination = videos.meta.pagination;
    this.model.loading = false;
  };

  @action
  loadMoreVideos = async () => {
    this.model.loading = true;
    this.model.page += 1;
    const videos = await this.api.user.getYouTubeVideos(this.user.user.identity, this.model.visible, this.model.page);

    this.model.videos.push(...videos.data);
    this.model.pagination = videos.meta.pagination;
    this.model.loading = false;
  };

  @action
  refreshVideos = async () => {
    this.model.refreshingVideos = true;
    this.model.refreshSuccess = false;
    this.model.unknownError = false;
    this.model.rateLimitHit = false;
    this.model.channelErrors = [];
    const refreshes = await promiseAllSettled(
      this.user.channels.map((channel) => this.api.user.refreshYouTubeVideos(this.user.user.identity, channel.id))
    );
    refreshes.forEach((refresh, index) => {
      if (refresh.status === 'rejected') {
        if (refresh.reason instanceof APIError) {
          if (refresh.reason.isRateLimitError) {
            // Rate limiting hit, show rate limit warning once
            this.model.rateLimitHit = true;
            return;
          }
          if (refresh.reason.code === 403) {
            const channel = this.user.channels[index];
            // Show individual reconnection error
            this.model.channelErrors.push({ channel, error: refresh.reason });
            return;
          }
        }
        // Show unknown error once
        this.model.unknownError = true;
        return;
      }
      // If the refresh was successful, set the success flag
      this.model.refreshSuccess = true;
    });
    if (this.model.refreshSuccess) {
      await this.callVideos(this.model.visible);
    }
    this.model.refreshingVideos = false;
  };

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    try {
      this.ui.setBreadcrumbs([
        {
          path: '/account',
          label: 'Account',
        },
        {
          path: '/account/videos',
          label: 'Videos',
        },
      ]);

      await this.ui.setSEO(nextState.location.pathname);

      await this.callVideos(this.model.visible);
    } catch (error) {
      this.bugsnag.notifyException(error);
    }
  };
}
