import { observable } from 'mobx';

export class DiscoverPageModel {
  @observable
  loading: boolean = true;

  @observable
  slug: any = null;

  @observable
  document_title: any = null;

  @observable
  document_meta_description: any = null;

  @observable
  page_title: any = null;

  @observable
  page_subtitle: any = null;

  @observable
  cover_image: any = null;

  @observable
  page_body: any = null;

  @observable
  video: any = null;

  @observable
  hubspot_cta: any = null;
}