import * as React from 'react';
import { observer } from 'mobx-react';
import { center, centerJustified, horizontal, horizontallySpaced, start, vertical } from 'csstips';
import { important, px } from 'csx';
import { colorContrast100, colorDarkBlueGrey, colorGreyAccent, colorGunmetal } from '../theme/color';
import { classes, style } from 'typestyle';
import { ButtonCircle } from './button-circle';
import { VolumeUp } from './project-happy/atoms/icons/VolumeUp';
import { VolumeDown } from './project-happy/atoms/icons/VolumeDown';
import { VolumeMute } from './project-happy/atoms/icons/VolumeMute';

export interface PlayerBarVolumeProps {
  volume: number;
  onChangeVolume(volume: number): void;
}

export interface PlayerBarVolumeState {
  showSlider: boolean;
}

@observer
export class PlayerBarVolume extends React.Component<PlayerBarVolumeProps, PlayerBarVolumeState> {
  private wrapper: HTMLDivElement;

  constructor(props) {
    super(props);
    this.state = { showSlider: false };
  }

  render() {
    const { volume } = this.props;
    const { showSlider } = this.state;

    const fullVolumeRange = volume >= 75 && volume < 101;
    const midVolumeRange = volume >= 5 && volume < 75;
    const muteVolumeRange = volume < 5;

    return (
      <label
        className={classes(PlayerBarVolume.styles.container, showSlider && PlayerBarVolume.styles.containerActive)}
      >
        <div
          ref={(el) => (this.wrapper = el)}
          className={classes(
            showSlider ? PlayerBarVolume.styles.inputVisible : PlayerBarVolume.styles.hideVolumeSlider,
            PlayerBarVolume.styles.inputWrapper
          )}
        >
          <input
            className={PlayerBarVolume.styles.input}
            type="range"
            min="0"
            max="100"
            step="1"
            value={volume.toString()}
            onInput={this.handleChange}
            onChange={this.handleChange}
          />
        </div>
        <ButtonCircle
          className={PlayerBarVolume.styles.volumeBtn}
          style={showSlider ? { backgroundColor: '#b8c3cd' } : { backgroundColor: colorGreyAccent.toString() }}
          size={36}
          onClick={this.handleClick}
        >
          {fullVolumeRange && <VolumeUp size={20} color={colorGunmetal.toString()} />}
          {midVolumeRange && <VolumeDown size={20} color={colorGunmetal.toString()} />}
          {muteVolumeRange && <VolumeMute size={20} color={colorGunmetal.toString()} />}
        </ButtonCircle>
      </label>
    );
  }

  handleChange = (event: React.FormEvent<any>) => {
    this.props.onChangeVolume(parseInt((event.target as HTMLInputElement).value));
  };

  handleClick = (event: React.MouseEvent<any>) => {
    event.stopPropagation();
    this.setState({ showSlider: !this.state.showSlider });
  };

  handleWindowClick = (event: MouseEvent) => {
    const { showSlider } = this.state;
    if (!showSlider || !this.wrapper) return;

    const target = event.target as HTMLElement;
    if (!this.wrapper.contains(target) && this.wrapper !== target) {
      this.setState({ showSlider: false });
    }
  };

  componentDidMount() {
    window.addEventListener('click', this.handleWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleWindowClick);
  }

  static styles = {
    container: style({
      ...horizontal,
      ...horizontallySpaced(10),
      ...center,
      position: 'relative',
      padding: '0 12px 0 8px',
    }),
    input: style({
      width: px(80),
      borderRadius: px(4),
      '-webkit-appearance': 'none',
      appearance: 'none',
      margin: '8px 0',
      fontSize: '0',
      $nest: {
        '&[type="range"]:focus': {
          outline: 'none',
        },
        // volume bar line
        '&[type="range"]::-webkit-slider-runnable-track': {
          width: '100%',
          height: '3px',
          cursor: 'pointer',
          boxShadow: '0px 0px 0px #000000, 0px 0px 0px #0d0d0d',
          background: '#b8c3cd',
          borderRadius: '10px',
          border: '0px solid #562425',
        },
        '&[type="range"]::-webkit-slider-thumb': {
          boxShadow: '0px 0px 0px #050000, 0px 0px 0px #1f0000',
          border: '2px solid #b8c3cd',
          height: '20px',
          width: '20px',
          borderRadius: '20px',
          background: colorGunmetal.toString(),
          cursor: 'pointer',
          '-webkit-appearance': 'none',
          marginTop: '-9px',
        },
        '&[type="range"]:focus::-webkit-slider-runnable-track': {
          background: '#ffffff',
        },
        '&[type="range"]::-moz-range-track': {
          width: '100%',
          height: '4px',
          cursor: 'pointer',
          boxShadow: '0px 0px 0px #000000, 0px 0px 0px #0d0d0d',
          background: '#b8c3cd',
          borderRadius: '4px',
          border: '0px solid #562425',
        },
        '&[type="range"]::-moz-range-thumb': {
          boxShadow: '0px 0px 0px #050000, 0px 0px 0px #1f0000',
          border: '2px solid #b8c3cd',
          height: '15px',
          width: '15px',
          borderRadius: '20px',
          background: '#4a00ff',
          cursor: 'pointer',
        },
        '&[type="range"]::-ms-track': {
          width: '100%',
          height: '4px',
          cursor: 'pointer',
          background: 'transparent',
          borderColor: 'transparent',
          color: 'transparent',
        },
        '&[type="range"]::-ms-fill-lower': {
          background: '#4a00ff',
          border: '0px solid #562425',
          borderRadius: '8px',
          boxShadow: '0px 0px 0px #000000, 0px 0px 0px #0d0d0d',
        },
        '&[type="range"]::-ms-fill-upper': {
          background: '#ffffff',
          border: '0px solid #562425',
          borderRadius: '8px',
          boxShadow: '0px 0px 0px #000000, 0px 0px 0px #0d0d0d',
        },
        '&[type="range"]::-ms-thumb': {
          boxShadow: '0px 0px 0px #050000, 0px 0px 0px #1f0000',
          border: '2px solid #ffffff',
          height: '20px',
          width: '20px',
          borderRadius: '20px',
          background: '#4a00ff',
          cursor: 'pointer',
        },
        '&[type="range"]:focus::-ms-fill-lower': {
          background: '#ffffff',
        },
        '&[type="range"]:focus::-ms-fill-upper': {
          background: '#ffffff',
        },
      },
    }),
    // Container around audio button
    containerActive: style({
      zIndex: 7,
    }),
    inputWrapper: style({
      ...vertical,
      ...centerJustified,
      ...start,
      display: 'flex',
      padding: '0 0 0 4px',
    }),
    // Veritcal slider
    inputVisible: style({
      display: 'flex',
      position: 'absolute',
      bottom: px(75),
      right: '-8px',
      background: colorGunmetal.toString(),
      height: px(40),
      width: px(95),
      transform: 'rotate(270deg)',
      transformOrigin: 'top',
      borderRadius: '0 25px 25px 0',
    }),
    hideVolumeSlider: style({
      $unique: true,
      display: 'none',
    }),
    volumeBtn: style({
      $nest: {
        '&:hover': {
          backgroundColor: important(colorDarkBlueGrey.toString()),
        },
      },
    }),
  };
}
