import * as React from 'react';
import { colorLightGrey, colorWhite, rebrand } from '../../../theme/color';
import { em, percent, px } from 'csx';
import { classes, style } from 'typestyle';
import { flexRoot } from 'csstips';
import { SemanticInformationType } from '../utilities/types';
import { ErrorOutlineCircle } from '../atoms/icons/ErrorCircleOutline';
import { CheckmarkCircle } from '../atoms/icons/CheckmarkCircle';
import { InfoCircle } from '../atoms/icons/InfoCircle';
import { centerCenter } from 'csstips/lib/flex';
import { CSSTransition } from 'react-transition-group';
import { transitionExpandFade } from '../../../theme/transition';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';

export const styles = {
  container: style(flexRoot, {
    background: colorWhite.toString(),
    border: `${px(1)} solid ${colorLightGrey.toString()}`,
    width: percent(100),
    minHeight: px(50),
    borderRadius: px(10),
    boxShadow: `0 ${px(2)} ${px(4)} ${px(1)} rgb(0, 0, 0, 0.3)`,
    margin: `0 0 ${px(16)}`,
    padding: 0,
    overflow: 'hidden',
    position: 'relative',
  }),
  typeIndicator: style(flexRoot, {
    borderLeft: '8px solid',
    flexShrink: 0,
    $nest: {
      [`.${SemanticInformationType.SUCCESS} > &`]: {
        borderColor: rebrand.semantic[SemanticInformationType.SUCCESS][60].toString(),
        color: rebrand.semantic[SemanticInformationType.SUCCESS][60].toString(),
      },
      [`.${SemanticInformationType.WARNING} > &`]: {
        borderColor: rebrand.semantic[SemanticInformationType.WARNING][60].toString(),
        color: rebrand.semantic[SemanticInformationType.WARNING][60].toString(),
      },
      [`.${SemanticInformationType.ERROR} > &`]: {
        borderColor: rebrand.semantic[SemanticInformationType.ERROR][60].toString(),
        color: rebrand.semantic[SemanticInformationType.ERROR][60].toString(),
      },
      [`.${SemanticInformationType.INFO} > &`]: {
        borderColor: rebrand.semantic[SemanticInformationType.INFO][60].toString(),
        color: rebrand.semantic[SemanticInformationType.INFO][60].toString(),
      },
    },
  }),
  typeIcon: style({
    margin: 'auto 20px',
  }),
  alertContent: style({
    margin: 'auto 8px',
    padding: '8px 0',
    flexGrow: 1,
  }),
  alertTitle: style({
    fontSize: em(1.1),
  }),
  alertDescription: style({
    fontSize: em(0.75),
    display: 'block',
  }),
  alertDismiss: style(flexRoot, centerCenter, {
    border: 0,
    borderLeft: `${px(1)} solid ${colorLightGrey.toString()}`,
    minWidth: px(60),
    padding: '0 8px',
    fontSize: em(0.75),
    backgroundColor: 'transparent',
    color: rebrand.light3.toString(),
    $nest: {
      '&:hover, &:focus': {
        backgroundColor: colorLightGrey.toString(),
        color: rebrand.dark1.toString(),
      },
    },
  }),
};

export type AlertProps = {
  type: SemanticInformationType;
  title?: string;
  show?: boolean;
  in?: CSSTransitionProps['in']; // Allows compatibility with <TransitionGroup>
  onRequestClose?: () => void;
  onExited?: CSSTransitionProps['onExited']; // Allows compatibility with <TransitionGroup>
};

export class Alert extends React.Component<AlertProps, { shown: boolean }> {
  constructor(props) {
    super(props);

    this.handleOnExited = this.handleOnExited.bind(this);

    this.state = {
      shown: typeof props.show === 'boolean' ? props.show : true,
    };
  }

  get typeIcon() {
    switch (this.props.type) {
      case SemanticInformationType.SUCCESS:
        return CheckmarkCircle;
      case SemanticInformationType.WARNING:
        return InfoCircle;
      case SemanticInformationType.ERROR:
        return ErrorOutlineCircle;
      case SemanticInformationType.INFO:
        return InfoCircle;
    }
  }

  get typeTitle() {
    switch (this.props.type) {
      case SemanticInformationType.SUCCESS:
        return 'Success';
      case SemanticInformationType.WARNING:
        return 'Warning';
      case SemanticInformationType.ERROR:
        return 'Error';
      case SemanticInformationType.INFO:
        return 'Info';
    }
  }

  componentDidUpdate(prevProps: Readonly<AlertProps>) {
    let prevShow = prevProps.show;
    let nowShow = this.props.show;
    // If the <Alert> is used as part of a TransitionGroup, we need to honour CSSTransitionProps
    if ('in' in prevProps && 'in' in this.props) {
      prevShow = prevProps.in;
      nowShow = this.props.in;
    }
    if (typeof prevShow === 'boolean' && typeof nowShow === 'boolean' && prevShow !== nowShow) {
      this.setState({ shown: nowShow });
    }
  }

  handleOnExited(node: HTMLElement) {
    const { onRequestClose, onExited } = this.props;
    if (typeof onRequestClose === 'function') {
      onRequestClose();
    }
    // If the <Alert> is used as part of a TransitionGroup, we need to bubble the call
    if (typeof onExited === 'function') {
      onExited(node);
    }
  }

  render() {
    const { shown } = this.state;
    const { type, title, children } = this.props;
    const TypeIcon = this.typeIcon;
    const alertTitle = title || this.typeTitle;
    return (
      <CSSTransition in={shown} onExited={this.handleOnExited} {...transitionExpandFade}>
        <dialog className={classes('alert', type, styles.container)} role="alert" data-test-alert={type}>
          <div className={styles.typeIndicator}>
            <TypeIcon className={styles.typeIcon} width={20} height={20} color="currentColor" />
          </div>
          <div className={styles.alertContent}>
            <strong className={styles.alertTitle}>{alertTitle}</strong>
            <span className={styles.alertDescription}>{children}</span>
          </div>
          <button
            onClick={() => this.setState({ shown: false })}
            className={styles.alertDismiss}
            data-test-alert-dismiss
          >
            Close
          </button>
        </dialog>
      </CSSTransition>
    );
  }
}
