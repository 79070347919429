import React from 'react';
import { style } from 'typestyle';
import { colorWhite, rebrand } from '../../../../theme/color';
import SearchSuggestionItem, { EntityType } from '../../atoms/SearchSuggestionItem';
const styles = {
  wrapper: style({
    backgroundColor: colorWhite.toHexString(),
    border: `solid 1px ${rebrand.neutralOnDark[20]}`,
    borderTop: 0,
    maxHeight: '70vh',
    overflow: 'scroll',
  }),
};

type Props = {
  entities: EntityType[];
};

const SearchSuggestion: React.FC<Props> = ({ entities }) => {
  return (
    <div className={styles.wrapper} data-test-view-search-suggestion>
      {entities.map((entity: EntityType, i) => (
        <SearchSuggestionItem
          key={i + entity.type}
          name={entity.name}
          slug={entity.slug}
          type={entity.type}
          position={i}
          uuid={entity.uuid}
          identity={entity.identity}
          artist={entity.artist}
          artistSlug={entity.artistSlug}
          context={entity.context}
          images={entity.images}
          // This is added for playlist use and will be remove in phase 2
          tracks={entity && entity.tracks}
          index={i}
        />
      ))}
    </div>
  );
};

export default SearchSuggestion;
