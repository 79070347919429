import * as React from 'react';
import { observer } from 'mobx-react';
import { classes, media, style } from 'typestyle';
import { important, percent, px } from 'csx';
import { LegacyButtonStyle, LegacyButtonStyleDefault } from './pill';
import { StatelessComponent } from 'react';
import { shadowSubtle } from '../theme/shadow';
import { colorBackground, colorBrand, colorGunmetal, colorWhite } from '../theme/color';
import { SVGIcon } from './svg-icon';
import { centerCenter, inlineRoot } from 'csstips';
import { ButtonPill } from './button-pill';

export interface ButtonDropdownProps {
  onSelect(value: string): void;
  left?: boolean;
  right?: boolean;
  primary?: boolean;
  muted?: boolean;
  placeholder?: string;
  selected?: null | {
    value: string;
    label: string;
  };
  choices: Array<{
    value: string;
    label: string;
  }>;
}

export interface ButtonDropdownState {
  open: boolean;
}

@observer
export class ButtonDropdown extends React.Component<ButtonDropdownProps, ButtonDropdownState> {
  private _isMounted: boolean;

  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  render() {
    const { choices, selected, placeholder, left, right, primary, muted } = this.props;
    const { open } = this.state;

    const className = classes(
      ButtonDropdown.styles.button,
      primary ? ButtonPill.styles.primary : muted ? ButtonPill.styles.muted : ButtonPill.styles.standard,
      left && ButtonDropdown.styles.buttonLeft,
      right && ButtonDropdown.styles.buttonRight
    );

    const arrowColor = muted ? colorBrand.toString() : colorWhite.toString();

    return (
      <div className={ButtonDropdown.styles.buttonContainer} onClick={this.handleToggleDropdown}>
        <span role="button" className={className}>
          {selected ? selected.label : placeholder ? placeholder : ''}{' '}
          <SVGIcon.ArrowRight color={arrowColor} className={ButtonDropdown.styles.dropdownToggle} size={12} />
        </span>
        <div className={classes(ButtonDropdown.styles.dropdownMenu, open && ButtonDropdown.styles.dropdownMenuOpen)}>
          {choices.map((choice) => (
            <div
              className={classes(
                ButtonDropdown.styles.dropdownMenuItem,
                selected && choice.value === selected.value && ButtonDropdown.styles.dropdownMenuItemActive
              )}
              key={choice.value}
              onClick={this.handleSelectItem.bind(this, choice.value)}
            >
              {choice.label}
            </div>
          ))}
        </div>
      </div>
    );
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeDropdown);
    this._isMounted = false;
  }

  handleToggleDropdown = (event: React.MouseEvent<any>) => {
    const open = !this.state.open;

    this.setState({ open }, () => {
      if (open) {
        setTimeout(() => window.addEventListener('click', this.closeDropdown), 0);
      }
    });
  };

  handleSelectItem = (value: string, event: React.MouseEvent<any>) => {
    this.props.onSelect(value);
  };

  closeDropdown = () =>
    this._isMounted &&
    this.setState({ open: false }, () => {
      window.removeEventListener('click', this.closeDropdown);
    });

  static i18n = {};

  static styles = {
    container: style({
      borderRadius: percent(50),
      ...centerCenter,
    }),
    button: style({
      overflow: 'visible',
      padding: '0.6em 1.4em',
    }),
    buttonContainer: style({
      ...inlineRoot,
      position: 'relative',
    }),
    buttonLeft: style({
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      boxShadow: '-1px 0px 0px rgba(0,0,0,0.1) inset',
      paddingRight: '1.2em',
    }),
    buttonRight: style({
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      boxShadow: '1px 0px 0px rgba(0,0,0,0.1) inset',
      paddingLeft: '1.2em',
    }),
    dropdownToggle: style({
      transform: 'rotate(90deg)',
      margin: '0 0 0 10px',
    }),
    dropdownMenu: style({
      position: 'absolute',
      top: 'calc(100% + 10px)',
      right: 0,
      left: 0,
      display: 'none',
      boxShadow: shadowSubtle,
      background: colorWhite.toString(),
    }),
    dropdownMenuOpen: style({
      display: important('block'),
    }),
    dropdownMenuItem: style({
      padding: '0.3em 0.6em',
      textAlign: 'center',
      cursor: 'pointer',
      userSelect: 'none',
      color: colorGunmetal.toString(),
      $nest: {
        '&:hover': {
          backgroundColor: colorBackground.toString(),
        },
      },
    }),
    dropdownMenuItemActive: style({
      fontWeight: 'bold',
    }),
  };
}
