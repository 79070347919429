import { PrismicCallToActionProps } from '../utilities/types';
import * as React from 'react';
import { CtaButtonSlice } from '../organisms/slices/CtaButtonSlice';
import { isEmpty } from '../utilities/objects';

export type PrismicCtaButtonPayload = PrismicCallToActionProps;
export type PrismicCtaButtonProps = {
  payload: PrismicCtaButtonPayload;
};
export const PrismicCtaButton: React.SFC<PrismicCtaButtonProps> = ({
  payload: {
    primary: { call_to_action_url, call_to_action_text },
  },
}) => (
  <CtaButtonSlice
    cta={
      call_to_action_text && {
        href: !isEmpty(call_to_action_url) && call_to_action_url.url,
        text: call_to_action_text,
      }
    }
  />
);
