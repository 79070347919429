/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { FC } from 'react';
import isEmpty from 'lodash/isEmpty';
import { classes, cssRaw, style } from 'typestyle';
import { center, flexRoot } from 'csstips';
import { important, px } from 'csx/lib/internal';
import { colorGreyAccent, colorGunmetal, colorNeutralLight, colorWhite, rebrand } from '../../../theme/color';
import { History as HistoryIcon } from './icons/History';
import { Profile } from './icons/Profile';
import { PlaylistHamburger } from './icons/PlaylistHamburger';
import { TrackIcon } from './icons/TrackIcon';
import { GenreIcon } from './icons/GenreIcon';
import { MoodIcon } from './icons/MoodIcon';
import { CloseCross } from './icons/CloseCross';
import { Component, ComponentProps, deps, inject, observer } from '../../../lib/component';
import { runInAction } from 'mobx';
import { ArtistSchema, ImageSchema, TrackSchema } from '../../../types/schema';

const styles = {
  wrapper: style(flexRoot, center, {
    backgroundColor: colorWhite.toHexString(),
    padding: px(12),
    borderTop: `solid 1px ${rebrand.neutralOnDark[20]}`,
    maxHeight: px(48),
    cursor: 'pointer',
    $nest: {
      '> .cancel': {
        display: 'none',
        opacity: 0,
      },
    },
  }),
  active: style({
    backgroundColor: colorGreyAccent.toString(),
    $nest: {
      '> .cancel': {
        display: 'block',
        opacity: 1,
      },
    },
  }),
  entityWrapper: style({
    paddingLeft: px(8),
  }),
  title: style({
    fontSize: px(16),
    color: colorGunmetal.toString(),
    lineHeight: px(24),
    fontWeight: 400,
    margin: 0,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  type: style({
    fontSize: px(12),
    lineHeight: px(11),
    fontWeight: 400,
    color: colorNeutralLight.toString(),
    margin: 0,
    textTransform: 'capitalize',
  }),
  subtitle: style({
    fontSize: px(16),
    color: colorNeutralLight.toString(),
    margin: 0,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  historytitle: style({
    paddingLeft: px(8),
    fontSize: px(16),
    color: colorNeutralLight.toString(),
    lineHeight: px(24),
    fontWeight: 400,
    flex: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  svg: style({
    minWidth: px(24),
    minHeight: px(24),
  }),
};

export type SuggestionType = 'artist' | 'playlist' | 'mood' | 'genre' | 'track' | 'history';

const entityIcons = {
  artist: Profile,
  playlist: PlaylistHamburger,
  mood: MoodIcon,
  genre: GenreIcon,
  track: TrackIcon,
};

export type EntityType = {
  type: SuggestionType;
  name: string;
  title?: string;
  artist?: string;
  artistSlug?: string;
  uuid?: string;
  position?: number;
  slug?: string;
  context?: string;
  tracks?: TrackSchema[];
  images?: ImageSchema;
  artists?: Array<ArtistSchema>;
  index?: number;
  identity?: string;
  is_available_in_territory?: boolean;
};

@inject(deps)
@observer
class SearchSuggestionItem extends Component<EntityType> {
  render() {
    const {
      type,
      name,
      artist,
      artistSlug,
      uuid,
      position,
      slug,
      tracks,
      context,
      images,
      identity,
      index,
      controller: {
        page: {
          searchMvp: { deleteUserSearchTerm, handleSuggestionSelection, updateSelectedIsHistory, performSearch },
        },
        analytics: { sendMixpanel },
      },
      model: {
        page: { searchMvp },
      },
    } = this.props;
    const Icon = entityIcons[type];
    const handleMouseEnter = (e) => {
      this.props.model.page.searchMvp.cursor = index;
    };
    const handleMouseLeave = (e) => {
      this.props.model.page.searchMvp.cursor = null;
    };
    const activeItem = index === searchMvp.cursor;
    return (
      <>
        {Object.keys(entityIcons).includes(type) && (
          <div
            className={classes(styles.wrapper, activeItem && styles.active)}
            data-test-view-search-suggestion={type}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => {
              updateSelectedIsHistory(false);
              handleSuggestionSelection({ type, name, uuid, slug, tracks, images, identity, artistSlug });
              sendMixpanel('User clicks on a search suggestion', {
                type: type,
                artist: artist,
                name: name,
                position: position + 1,
              });
            }}
          >
            <Icon className={styles.svg} />
            <div className={styles.entityWrapper}>
              <p className={styles.title}>{name}</p>
              {type === 'track' ? <p className={styles.type}>{artist}</p> : <p className={styles.type}>{type}</p>}{' '}
            </div>
          </div>
        )}
        {type === 'history' && (
          <div
            className={classes(styles.wrapper, activeItem && styles.active)}
            data-test-view-search-suggestion={type}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => {
              const saveSuggestion = JSON.parse(context);
              // This will be removed later when we have most user search data updated;
              if (isEmpty(saveSuggestion)) {
                this.props.model.page.searchMvp.searchState.query = name;
                performSearch();
              } else {
                if (saveSuggestion.type === 'track') {
                  saveSuggestion.title = saveSuggestion.name;
                }
                handleSuggestionSelection(saveSuggestion);
              }
              updateSelectedIsHistory(true);
            }}
          >
            <HistoryIcon className={styles.svg} />
            <p className={styles.historytitle}>{name}</p>
            {uuid && (
              <CloseCross
                className={'cancel'}
                title="Remove history"
                size={20}
                color={colorNeutralLight.toString()}
                onClick={(e) => {
                  runInAction(() => {
                    e.stopPropagation();
                    searchMvp.histroyId = uuid;
                    deleteUserSearchTerm();
                  });
                }}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

export default SearchSuggestionItem;
