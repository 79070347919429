import { classes, extend, style } from 'typestyle';
import * as React from 'react';
import { Modal, ModalChildStyle, ModalProps } from '../../modal';
import { px } from 'csx';
import { colorBrand, colorWhite } from '../../../theme/color';
import { shadowElevation3 } from '../../../theme/shadow';
import { SVGIcon } from '../../svg-icon';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { CloseCross } from '../atoms/icons/CloseCross';
import { heading6 } from '../utilities/font';
import { fontstackDefault } from '../config';

const styles = {
  modal: style(ModalChildStyle, shadowElevation3, {
    padding: px(16),
    maxWidth: px(256),
    backgroundColor: colorBrand.toHexString(),
    color: 'white',
    position: 'relative',
    borderRadius: px(5),
    $nest: {
      '& h1, & h2, & h3, & h4, & h5, & h6': extend(heading6, {
        fontFamily: fontstackDefault,
        margin: 0,
        textAlign: 'center',
        marginTop: px(-8),
      }),
      '& p': {
        marginTop: px(8),
        marginBottom: px(8),
      },
      '& p:last-child': {
        marginBottom: 0,
      },
    },
  }),
  close: style({
    width: px(14),
    position: 'absolute',
    top: px(8),
    right: px(8),
    padding: 0,
    $nest: {
      '&:hover, &:focus': {
        opacity: 0.8,
      },
    },
  }),
};

export type ToolTipModalProps = Pick<ModalProps, 'show' | 'onRequestClose' | 'className'>;

const ToolTipModal: React.SFC<ToolTipModalProps> = ({ children, className, show, onRequestClose }) => (
  <Modal className={classes(styles.modal, className)} show={show} onRequestClose={onRequestClose}>
    <div>
      <Button
        className={styles.close}
        prominence={ButtonProminence.NONE}
        size={ButtonSize.SMALL}
        onClick={onRequestClose}
        label="Close modal"
        data-test-close-modal
      >
        <CloseCross color="white" />
      </Button>
      {children}
    </div>
  </Modal>
);
export default ToolTipModal;
