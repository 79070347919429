import { observable, computed } from 'mobx';
import { AuthModel } from '../auth/model';
import { StorageModel } from '../storage/model';
import { UserModel } from '../user/model';
import { getTrackPrice, reduceTracksPrice } from '../../lib/helpers';
import { PricingModel } from '../pricing/model';
import { CouponModel } from '../coupon/model';
import { ChannelSchema, RatecardSchema, TrackSchema } from '../../types/schema';
import { EnvModel } from '../env/model';

export class BasketModel {
  constructor(
    private auth: AuthModel,
    private storage: StorageModel,
    private user: UserModel,
    private pricing: PricingModel,
    private coupon: CouponModel
  ) {
    // Load in user model from sessionStorage
    if (!EnvModel.isServer) {
      if (BASKET_IDENTITY in window.sessionStorage) {
        this.identity = window.sessionStorage.getItem(BASKET_IDENTITY);
      }
      if (BASKET_TRACKS in window.sessionStorage) {
        try {
          this.tracks = JSON.parse(window.sessionStorage.getItem(BASKET_TRACKS));
        } catch {
          // The stored basket is malformed
        }
      }
    }
  }

  @observable
  createPromise: Promise<any> = null;

  @observable
  sync: boolean;

  @observable
  syncItems: Array<TrackSchema> = [];

  @observable
  identity: string = null;

  @observable
  tracks: TrackSchema[] = [];

  @computed
  get total(): number {
    return this.ratecard ? reduceTracksPrice(this.tracks, this.ratecard) : 0;
  }

  @computed
  get itemPrices(): { [trackIdentity: string]: number } {
    return this.ratecard
      ? this.tracks.reduce(
          (prices, track) => ({
            ...prices,
            [track.identity]: getTrackPrice(track, this.ratecard),
          }),
          {}
        )
      : {};
  }

  @computed
  get currency(): string {
    return this.channel && this.channel.ratecard ? this.channel.ratecard.currency : 'USD';
  }

  @computed
  get ratecardId(): string {
    return this.channel && this.channel.ratecard
      ? `${this.channel.ratecard.name}/${this.channel.ratecard.currency}`
      : null;
  }

  @computed
  get ratecardValue(): string {
    return this.channel && this.channel.ratecard ? this.channel.ratecard.value : null;
  }

  @computed
  get channel(): ChannelSchema {
    return this.user.channel;
  }

  @computed
  get channelName(): string {
    return this.channel ? this.channel.name : null;
  }

  @computed
  get channelCountry(): string {
    return this.channel ? this.channel.country : null;
  }

  @computed
  get ratecard(): RatecardSchema {
    return this.user && this.user.channel && this.user.channel.ratecard
      ? this.user.channel.ratecard
      : this.pricing.unsubscribedRates
      ? this.pricing.unsubscribedRates.currency.USD[0]
      : null;
  }

  @computed
  get firstTrackIsFree(): boolean {
    return this.coupon.type === 'first_use';
  }

  @computed
  get basketIncludesStandardTrack(): boolean {
    return this.tracks.filter((t) => !t.basket.brand_sponsored && !t.basket.branded_content).length > 0;
  }

  @computed
  get restrictedTracks(): Array<TrackSchema> {
    return this.tracks.filter((t) => t.is_available === false);
  }
  isInBasket = (track: TrackSchema) => {
    return this.tracks.findIndex((t) => t.identity === track.identity) !== -1;
  };

  isSyncing = (track: TrackSchema) => {
    return this.syncItems.findIndex((t) => t.identity === track.identity) !== -1;
  };
}

export const BASKET_IDENTITY = 'basketIdentity';
export const BASKET_TRACKS = 'basketTracks';
