import * as React from 'react';
import { classes, cssRaw, extend, style } from 'typestyle';
import { percent, px, viewWidth } from 'csx';
import { ContentSlice, ContentSliceProps, GradientBackgroundStyles } from './ContentSlice';
import { LinkTile, LinkTileProps } from '../../molecules/LinkTile';
import { mediaDesktop, mediaUpToDesktop } from '../../utilities/mediaQueries';
import { DecoratedPageCarousel, DecoratedPageCarouselProps } from '../DecoratedPageCarousel';
import { colorGunmetal } from '../../../../theme/color';
import { BaseCarouselSlice } from './BaseCarouselSlice';
import { BaseTilesSlice } from './BaseTilesSlice';
import { GridTileList } from '../../atoms/GridTileList';
import { GridTileListItem, GridTileListItemProps } from '../../atoms/GridTileListItem';
import { heading4 } from '../../utilities/font';

const styles = {
  heading: style(heading4, { margin: 0 }),
};

type LinkTilesSliceProps = ContentSliceProps & { heading?: string; links: GridTileListItemProps[] };

export const LinkTilesSlice: React.SFC<LinkTilesSliceProps> = ({ heading, links, ...props }) => (
  <BaseTilesSlice {...props}>
    {heading && <h3 className={styles.heading}>{heading}</h3>}
    <GridTileList>
      {links.map((link, key) => (
        <GridTileListItem key={key} {...link} />
      ))}
    </GridTileList>
  </BaseTilesSlice>
);
