import React from 'react';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { classes, style } from 'typestyle';
import { rebrand } from '../../../theme/color';
import { borderRadius } from '../../../theme/border';
import { spacing } from '../../../theme/spacing';
import { RemainingCredits } from '../atoms/RemainingCredits';
import { center, centerCenter, flexRoot } from 'csstips';
import { RatecardDetails } from './RatecardDetails';
import { LOAD_STATE } from '../../../types/api';
import { Skeleton } from '../atoms/Skeleton';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { ACCOUNT_PLANS_ROUTE } from '../../../constants';
import { px } from 'csx';
import { Link } from '../atoms/Link';

interface RatecardV2Props extends ComponentProps {
  className?: string;
  isDashboard?: boolean;
}

const styles = {
  ratecard: style(flexRoot, center, {
    backgroundColor: rebrand.contrast[50].toString(),
    borderRadius: borderRadius.MEDIUM,
    padding: `${spacing.SMALL} ${spacing.DEFAULT}`,
    color: rebrand.contrast[30].toString(),
  }),
  info: style({
    flexShrink: 0,
    marginLeft: 'auto',
    textAlign: 'right',
  }),
  remainingCredits: style({
    $nest: {
      '& + *': {
        marginTop: spacing.XXSMALL,
      },
    },
  }),
  infoText: style({
    fontSize: px(12),
    lineHeight: 1.5,
    marginBottom: 0,
    $nest: {
      '& a': {
        color: 'inherit',
        $nest: {
          '&:hover, &:focus': {
            textDecoration: 'underline',
          },
        },
      },
      '& + *': {
        marginTop: spacing.XXSMALL,
      },
    },
  }),
  subscribeBtn: style(flexRoot, centerCenter),
};

export const RatecardV2 = inject(deps)(
  observer(
    ({
      className,
      isDashboard = false,
      model: {
        user: {
          channel,
          isSubscribed,
          isSubscribedWithoutChannel,
          isSubscribedToV2Plan,
          isSubscribedToLegacyPlan,
          loading,
          loadingChannels,
        },
        router,
        subscription: { creditSubscriptionPlansState },
        ui: { projectSubscriptionEnabled },
      },
      controller: {
        analytics: { sendMixpanel },
      },
    }: RatecardV2Props) => {
      const isLoading = loading || loadingChannels || creditSubscriptionPlansState === LOAD_STATE.LOADING;

      const handleMixpanelLink = (target: HTMLAnchorElement, eventName: string): void => {
        sendMixpanel(eventName, {
          route: router.location.pathname,
        });

        router.push(target.pathname);
      };

      const handleClickSeeNewPlans = (e: React.MouseEvent): void => {
        e.preventDefault();
        handleMixpanelLink(e.currentTarget as HTMLAnchorElement, 'User clicks see new plans');
      };

      const handleClickSubscribe = (e: React.MouseEvent): void => {
        e.preventDefault();
        handleMixpanelLink(e.currentTarget as HTMLAnchorElement, 'User clicks subscribe');
      };

      if (isLoading) {
        return <Skeleton height={67} />;
      }

      return (
        <div className={classes(styles.ratecard, className)}>
          <RatecardDetails isDashboard={isDashboard} />

          <div className={styles.info}>
            {projectSubscriptionEnabled && channel && (
              <>
                {isSubscribedToV2Plan && (
                  <RemainingCredits
                    creditsAvailable={channel.credits_available}
                    className={styles.remainingCredits}
                    darkTheme
                  />
                )}

                {isDashboard && (isSubscribed || isSubscribedWithoutChannel) && (
                  <>
                    {isSubscribedToV2Plan && !channel.credits_available && (
                      <p className={styles.infoText}>
                        Pay per license or <Link href={ACCOUNT_PLANS_ROUTE}>upgrade</Link>
                      </p>
                    )}

                    {isSubscribedToLegacyPlan && (
                      <Button
                        href={ACCOUNT_PLANS_ROUTE}
                        className={styles.subscribeBtn}
                        prominence={ButtonProminence.PRIMARY}
                        size={ButtonSize.SMALL}
                        onClick={handleClickSeeNewPlans}
                      >
                        See new plans
                      </Button>
                    )}
                  </>
                )}
              </>
            )}

            {isDashboard && !isSubscribed && !isSubscribedWithoutChannel && (
              <Button
                href={ACCOUNT_PLANS_ROUTE}
                className={styles.subscribeBtn}
                prominence={ButtonProminence.PRIMARY}
                size={ButtonSize.SMALL}
                onClick={handleClickSubscribe}
              >
                Subscribe
              </Button>
            )}
          </div>
        </div>
      );
    }
  )
);
