import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { classes, style } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { transitionModal, transitionQuickEase } from '../theme/transition';
import { percent, rem, viewHeight, viewWidth } from 'csx';
import { colorBrand, colorGunmetal, colorWhite } from '../theme/color';
import { centerCenter, content, scrollY, vertical } from 'csstips';
import { zModal } from '../theme/z';
import { shadowNotification } from '../theme/shadow';
import { CSSTransition } from 'react-transition-group';
import { EnvModel } from '../modules/env/model';
import { ScrollableOverlayProps } from './scrollable-overlay/scrollable-overlay';

export interface ModalProps {
  show: boolean;
  className?: string;
  onRequestClose(): void;
  preventClose?: boolean;
}

export class Modal extends React.Component<ModalProps> {
  private content: HTMLDivElement;

  componentDidMount() {
    this.toggleBodyScroll();
  }

  componentDidUpdate(prevProps: Readonly<ScrollableOverlayProps>) {
    if (prevProps.show === this.props.show) return;
    this.toggleBodyScroll();
  }

  toggleBodyScroll() {
    if (this.props.show) {
      document.body.style.overflow = 'hidden';
      return;
    }
    document.body.style.overflow = null;
  }

  render() {
    const { show, className, children } = this.props;

    // We render modals in portals and portals can't be rendered (easily) on the server
    if (EnvModel.isServer) return null;

    return ReactDOM.createPortal(
      <CSSTransition in={show} {...transitionModal}>
        <div className={Modal.styles.container} onClick={this.handleClick}>
          <div className={classes(Modal.styles.content, className)} ref={(el) => (this.content = el)}>
            {children}
          </div>
        </div>
      </CSSTransition>,
      document.getElementById('react-portals-root')
    );
  }

  handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!this.content || !this.props.show) return;
    const target = event.target as Node;
    if (this.content !== target && !this.content.contains(target)) {
      event.stopPropagation();
      this.props.onRequestClose();
    }
  };

  static styles = {
    container: style({
      ...centerCenter,
      zIndex: zModal,
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: colorGunmetal.fade(0.7).toString(),
      transition: `opacity ${transitionQuickEase}`,
    }),
    content: style({
      maxWidth: `calc(100vw - 4rem)`,
      maxHeight: `calc(100vh - 4rem)`,
      ...scrollY,
    }),
  };
}

export const ModalChildStyle: NestedCSSProperties = {
  ...vertical,
  ...content,
  ...scrollY,
  maxWidth: percent(100),
  maxHeight: percent(100),
  background: colorWhite.toString(),
  boxShadow: shadowNotification,
  borderRadius: rem(1),
  margin: 0,
  padding: rem(2),
  $nest: {
    '&> h3': {
      color: colorBrand.toString(),
      fontSize: rem(1.75),
      margin: '0 0 1.75rem 0',
      width: percent(100),
    },
  },
};
