import { action, when } from 'mobx';
import { OnboardingModel } from '../../onboarding/model';
import { RouterState } from 'react-router';
import { RegistrationPageModel, RegistrationSteps } from './model';
import { rdt } from '../../../reddit-track';
import { UserController } from '../../user/controller';
import { UserModel } from '../../user/model';
import { APIController } from '../../api/controller';
import { AnalyticsController } from '../../analytics/controller';
import { SESSION_ONBOARDING_PLAN, SESSION_ONBOARDING_VALUES } from '../../../constants';
import { COMPLETED_REGISTRATION_STEPS, User, UserUpdateableFields } from '../../../types/user';
import { SubscriptionPlans } from '../../../components/project-happy/utilities/types';
import { RouterModel } from '../../router/model';
import siteEvents, { SITE_EVENTS } from '../../../components/project-happy/utilities/siteEvents';

export class RegistrationPageController {
  constructor(
    private model: RegistrationPageModel,
    private onboarding: OnboardingModel,
    private api: APIController,
    private analytics: AnalyticsController,
    private user: UserModel,
    private userController: UserController,
    private router: RouterModel
  ) {
    // Rehydrate checkbox values from sign up page
    try {
      const { termsCheckbox } = JSON.parse(window.sessionStorage.getItem(SESSION_ONBOARDING_VALUES));
      this.model.terms = !!termsCheckbox;
    } catch {
      // State is corrupt, use default
    }
    // Rehydrate plan selection
    try {
      this.model.plan = window.sessionStorage.getItem(SESSION_ONBOARDING_PLAN) as SubscriptionPlans;
    } catch {
      // State is corrupt, leave unset
    }
    // We should be able to handle all this logic in the model, but we can't as it's a page model
    // Instead, sync the user country to the model value if the user is already onboarded
    when(
      () => onboarding.fullyOnboarded && user.user.country.length === 2,
      () => {
        model.country = user.user.country;
      }
    );
    // We should be able to handle all this logic in the model, but we can't as it's a page model
    // Instead, sync the accepted terms to the model value if the user is already onboarded
    when(
      () => onboarding.fullyOnboarded,
      () => {
        model.terms = true;
      }
    );
  }

  @action
  onEnter = async (nextState: RouterState) => {
    const urlStep = nextState.params['step'];
    await this.user.ready;

    const currentPath = nextState.location.pathname;

    const missingStep = this.getMissingStep(this.user.user);

    const registrationPaths = {
      [COMPLETED_REGISTRATION_STEPS.PLAN_SELECTED]: `/registration/${RegistrationSteps.PLANS}`,
      [COMPLETED_REGISTRATION_STEPS.ACCOUNT_DETAILS_ADDED]: `/registration/${RegistrationSteps.ACCOUNT_INFORMATION}`,
      null: `/registration/${RegistrationSteps.COMPLETE}`,
    };

    const targetPath = registrationPaths[missingStep];
    if (currentPath !== targetPath) {
      return this.router.replace({ pathname: targetPath });
    }

    this.model.step = urlStep as RegistrationSteps;
    this.analytics.sendMixpanel(`User navigates to "${urlStep}" stage of registration`);
  };

  @action
  submitAccountInformation = async () => {
    const { fullyOnboarded } = this.onboarding;
    const { fullName: name, nickname, country, terms } = this.model;
    const payload: UserUpdateableFields = {
      name,
      nickname,
      accepted_terms: terms,
      marketing_allowed: true, // Since WEB-4779, we removed the option to opt out of marketing emails at sign up
    };
    if (!fullyOnboarded) {
      payload.country = country;
    }
    const fromOnboardingState = this.user.user.onboarding_state;
    const { data: user } = await this.api.user.setContactDetails(payload); // Throws an error if unsuccessful
    await this.userController.refreshUser(user);
    await Promise.all([
      this.userController.getYoutubeChannel(user.default_channel),
      this.userController.getYoutubeChannels(),
    ]);
    siteEvents.emit(SITE_EVENTS.REGISTRATION_TRANSITION, {
      from: fromOnboardingState,
      to: this.user.user.onboarding_state,
    });
    this.analytics.sendSignup(this.user.user);
    this.analytics.sendMixpanel('Sign up complete');
    rdt('track', 'SignUp');
  };

  getMissingStep = (user: User): COMPLETED_REGISTRATION_STEPS => {
    if (!this.accountDetailsStepCompleted(user)) {
      return COMPLETED_REGISTRATION_STEPS.ACCOUNT_DETAILS_ADDED;
    }
    if (!this.planStepCompleted(user)) {
      return COMPLETED_REGISTRATION_STEPS.PLAN_SELECTED;
    }

    return null;
  };

  accountDetailsStepCompleted = (user: User): boolean => {
    return this.isStepCompleted(COMPLETED_REGISTRATION_STEPS.ACCOUNT_DETAILS_ADDED, user);
  };

  planStepCompleted = (user: User): boolean => {
    const steps = [COMPLETED_REGISTRATION_STEPS.PLAN_SELECTED, COMPLETED_REGISTRATION_STEPS.PLAN_NOT_SELECTED];

    return steps.some((step) => this.isStepCompleted(step, user));
  };

  isStepCompleted = (step: string, user: User): boolean => {
    return user.completed_registration_steps.includes(step);
  };
}
