import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { PlaylistGroupPlaylistSchema, PlaylistSchema } from '../../../types/schema';
import { em, percent, px } from 'csx';
import { mixinTextEllipsis } from '../../../theme/mixin';
import { UtmLink } from '../atoms/UtmLink';
import { mediaDesktop, mediaUpToDesktop, mediaUpToLargeTablet } from '../utilities/mediaQueries';
import { colorBlack, colorNeutral, colorWhite, rebrand } from '../../../theme/color';
import { PlaylistThumbnail } from '../atoms/PlaylistThumbnail';
import { zLabel } from '../../../theme/z';
import { SubscriptionLabel } from '../atoms/SubscriptionLabel';
import { AnalyticsContextProvider } from '../utilities/AnalyticsContext';
import { Dot } from '../atoms/icons/Dot';
import { center, flex } from 'csstips';
import { supportsLineClamp } from '../utilities/functions';
import { CSSValue } from 'typestyle/lib/types';

const styles = {
  wrapper: style({
    position: 'relative',
    paddingBottom: px(66),
    margin: '0 8px',
    borderRadius: em(0.3),
    $nest: {
      '& .favourites-button': {
        position: 'absolute',
        top: px(8),
        right: px(8),
      },
    },
  }),
  trackInfo: style(
    mixinTextEllipsis,
    {
      position: 'absolute',
      bottom: px(10),

      fontSize: px(12),
    },
    mediaUpToDesktop({
      width: 'calc(100% - 16px)',
    }),
    mediaDesktop({
      width: percent(100),
    })
  ),
  zeroBottomMobile: style({
    bottom: px(0),
  }),
  track: style({
    fontWeight: 600,
  }),
  trackType: style({
    color: colorNeutral.toHexString(),
    $nest: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
  }),
  trackLine: style(mixinTextEllipsis, {
    display: 'block',
    lineHeight: 'normal',
    position: 'relative',
    zIndex: 20,
    fontWeight: 'bold',
    cursor: 'pointer',
    $nest: {
      '&:hover': {
        textDecoration: 'underline',
      },
      '&, &:hover, &:active, &:visited': {
        color: colorBlack.toString(),
      },
    },
  }),
  overlayContent: style({ $nest: { '& > *': { zIndex: zLabel } } }),

  // Below contains the css for Search page playlist TrackTile
  dot: style(mixinTextEllipsis, { margin: '0px 8px' }),
  playlistName: style(mixinTextEllipsis, {
    fontWeight: 700,
    fontSize: px(16),
    color: rebrand.contrast[50].toString(),
    display: 'block',
  }),
  LineclampSupportplaylistName: style(
    mediaUpToLargeTablet({
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    {
      fontWeight: 700,
      fontSize: px(16),
      color: rebrand.contrast[50].toString(),
      display: '-webkit-box' as CSSValue<
        'initial' | 'inherit' | 'unset' | 'revert' | 'none' | 'block' | 'inline' | 'run-in'
      >,
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      whiteSpace: 'normal',
      overflow: 'hidden',
    }
  ),
  type: style({
    fontSize: px(16),
    fontWeight: 400,
    color: rebrand.neutralOnLight[70].toString(),
    whiteSpace: 'nowrap',
  }),

  imageSearchPage: style(
    mediaUpToDesktop({
      height: px(150),
    }),
    {
      boxShadow: 'none',
      height: px(224),
    }
  ),
  infoWrapper: style({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  }),
  searchTrackInfo: style({
    position: 'static',
    marginTop: 12,
  }),
};

export type PlaylistTileProps = {
  className?: string;
  playlist: PlaylistGroupPlaylistSchema | PlaylistSchema | any;
  searchPage?: boolean;
  onClick?: () => void;
};
export const PlaylistTile: React.SFC<PlaylistTileProps> = ({
  children,
  className,
  playlist,
  searchPage = false,
  onClick,
}) => (
  <AnalyticsContextProvider context={{ playlistName: playlist.name }}>
    <div data-test-playlist-tile={playlist.name} className={classes(styles.wrapper, className)}>
      <SubscriptionLabel included={playlist.is_stock} />
      <span>{children}</span>

      {searchPage ? (
        <>
          <div onClick={onClick}>
            <UtmLink data-test-top-solution-image-link={'playlist'} href={`/browse/collection/${playlist.slug}`}>
              <PlaylistThumbnail playlist={playlist} isTopSolutionImage={searchPage} />
            </UtmLink>
          </div>
          <div
            data-test-top-solution-text-link={'playlist'}
            className={classes(styles.trackInfo, styles.zeroBottomMobile, searchPage && styles.searchTrackInfo)}
          >
            <span
              title={playlist.name}
              className={supportsLineClamp() ? styles.LineclampSupportplaylistName : styles.playlistName}
            >
              {playlist.name}
            </span>
            <div className={styles.infoWrapper}>
              <span className={classes(styles.type)}>Playlist</span>
              <Dot className={styles.dot} />
              <span className={classes(styles.type)}> {playlist.tracks && playlist.tracks.length} tracks</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <UtmLink href={`/browse/collection/${playlist.slug}`}>
            <PlaylistThumbnail playlist={playlist} isTopSolutionImage={searchPage} />
          </UtmLink>
          <div className={styles.trackInfo}>
            <span className={classes(styles.trackType)}>{playlist.is_stock ? 'Included' : 'Premium'} Playlist</span>
            <UtmLink href={`/browse/collection/${playlist.slug}`}>
              <span className={classes(styles.track, styles.trackLine)}>{playlist.name}</span>
            </UtmLink>
          </div>
        </>
      )}
      <span className={styles.overlayContent}>{children}</span>
    </div>
  </AnalyticsContextProvider>
);
