import {computed, observable} from 'mobx';

export class ArticlesPageModel {
  @observable category: string;
  @observable slug: string;
  @observable from: number;

  @computed get size(): number {
    if (!!this.category)
      return 12;

    return 7;
  };
}