import * as React from 'react';
import { classes, style } from 'typestyle';
import { rebrand } from '../../../theme/color';
import { Component, deps, inject, observer } from '../../../lib/component';
import { mediaMediumDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { px } from 'csx';

const styles = {
  sidebar: style(
    {
      background: rebrand.dark1.toString(),
      position: 'relative',
    },
    mediaUpToDesktop({
      display: 'none',
    })
  ),
  wrapper: style(
    {
      height: 'inherit',
      maxWidth: px(320),
      color: 'white',
      overflowY: 'auto',
      margin: px(32),
      width: 'calc(100% - 64px)',
      paddingBottom: px(32),
    },
    mediaMediumDesktop({
      margin: '32px 32px 32px 48px',
      width: 'calc(100% - 80px)',
    })
  ),
};

export interface AccountSidebarProps {
  className?: string;
}

export class AccountSidebar extends Component<AccountSidebarProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { className, children } = this.props;

    return (
      <aside className={classes(styles.sidebar, className)}>
        <section className={styles.wrapper}>{children}</section>
      </aside>
    );
  }
}
