import * as React from 'react';
import { observer } from 'mobx-react';
import { PreferenceSelector } from './preference-selector';
import { PreferenceItem } from './preference-item';
import { style } from 'typestyle';
import { vertical, verticallySpaced } from 'csstips';
import { mediaMobileOnly } from '../theme/media';
import { UserPreferencesSchema } from '../types/schema';

export interface PageAccountCommunicationModel {
  preferences: UserPreferencesSchema;
  saving: UserPreferencesSchema;
  disabled: boolean;
}

export interface PageAccountCommunicationProps {
  model: PageAccountCommunicationModel;
  onUpdatePreferences(preferences: Partial<UserPreferencesSchema>): void;
  i18n?: PageAccountCommunicationI18n;
}

export interface PageAccountCommunicationI18n {
  newMusic: {
    title: string;
    subtitle: string;
  };
  recommendedMusic: {
    title: string;
    subtitle: string;
  };
  productAnnouncements: {
    title: string;
    subtitle: string;
  };
  twitterAnnouncement: {
    title: string;
    subtitle: string;
  };
  thirdParty: {
    title: string;
    subtitle: string;
  };
}

const DEFAULT_I18N = {
  newMusic: {
    title: 'New content',
    subtitle: 'Get notified by email when we have new content released on Lickd',
  },
  recommendedMusic: {
    title: 'Recommended content',
    subtitle: 'Receive personalised emails with tracks curated just for you',
  },
  productAnnouncements: {
    title: 'Product announcements',
    subtitle: 'Keep up to date with emails about new or updated features on Lickd',
  },
  twitterAnnouncement: {
    title: 'Twitter order announcement',
    subtitle: 'Tweet your followers when you hook a track',
  },
  thirdParty: {
    title: 'Third party promotions',
    subtitle: 'Receive promotional offers from our partners',
  },
};

@observer
export class PageAccountCommunication extends React.Component<PageAccountCommunicationProps, any> {
  render() {
    const { i18n = DEFAULT_I18N } = this.props;
    const { disabled } = this.props.model;

    return (
      <div className={PageAccountCommunication.styles.container}>
        <PreferenceSelector {...i18n.newMusic} saving={true} disabled={disabled}>
          <PreferenceItem
            label="Never"
            group="new-music"
            selected={this.isSelected(newMusicKeys, null)}
            onSelect={this.handleSelectNewMusic.bind(this, null)}
            disabled={disabled}
          />
          <PreferenceItem
            label="Monthly"
            group="new-music"
            selected={this.isSelected(newMusicKeys, newMusicKeys[0])}
            onSelect={this.handleSelectNewMusic.bind(this, newMusicKeys[0])}
            disabled={disabled}
          />
          <PreferenceItem
            label="Weekly"
            group="new-music"
            selected={this.isSelected(newMusicKeys, newMusicKeys[1])}
            onSelect={this.handleSelectNewMusic.bind(this, newMusicKeys[1])}
            disabled={disabled}
          />
          <PreferenceItem
            label="Daily"
            group="new-music"
            selected={this.isSelected(newMusicKeys, newMusicKeys[2])}
            onSelect={this.handleSelectNewMusic.bind(this, newMusicKeys[2])}
            disabled={disabled}
          />
        </PreferenceSelector>
        <PreferenceSelector {...i18n.recommendedMusic} saving={this.isSaving(recommendedMusicKeys)} disabled={disabled}>
          <PreferenceItem
            label="Never"
            group="recommended-music"
            selected={this.isSelected(recommendedMusicKeys, null)}
            onSelect={this.handleSelectRecommendedMusic.bind(this, null)}
            disabled={disabled}
          />
          <PreferenceItem
            label="Monthly"
            group="recommended-music"
            selected={this.isSelected(recommendedMusicKeys, recommendedMusicKeys[0])}
            onSelect={this.handleSelectRecommendedMusic.bind(this, recommendedMusicKeys[0])}
            disabled={disabled}
          />
          <PreferenceItem
            label="Weekly"
            group="recommended-music"
            selected={this.isSelected(recommendedMusicKeys, recommendedMusicKeys[1])}
            onSelect={this.handleSelectRecommendedMusic.bind(this, recommendedMusicKeys[1])}
            disabled={disabled}
          />
          <PreferenceItem
            label="Daily"
            group="recommended-music"
            selected={this.isSelected(recommendedMusicKeys, recommendedMusicKeys[2])}
            onSelect={this.handleSelectRecommendedMusic.bind(this, recommendedMusicKeys[2])}
            disabled={disabled}
          />
        </PreferenceSelector>
        <PreferenceSelector
          {...i18n.productAnnouncements}
          saving={this.isSaving(productAnnouncementsKeys)}
          disabled={disabled}
        >
          <PreferenceItem
            label="No"
            group="product-announcement"
            selected={this.isSelected(productAnnouncementsKeys, null)}
            onSelect={this.handleSelectProductAnnouncements.bind(this, false)}
            disabled={disabled}
          />
          <PreferenceItem
            label="Yes"
            group="product-announcement"
            selected={this.isSelected(productAnnouncementsKeys, productAnnouncementsKeys[0])}
            onSelect={this.handleSelectProductAnnouncements.bind(this, true)}
            disabled={disabled}
          />
        </PreferenceSelector>
        <PreferenceSelector
          {...i18n.twitterAnnouncement}
          saving={this.isSaving(twitterAnnouncementKeys)}
          disabled={disabled}
        >
          <PreferenceItem
            label="No"
            group="product-announcement"
            selected={this.isSelected(twitterAnnouncementKeys, null)}
            onSelect={this.handleSelectTwitterAnnouncement.bind(this, false)}
            disabled={disabled}
          />
          <PreferenceItem
            label="Yes"
            group="product-announcement"
            selected={this.isSelected(twitterAnnouncementKeys, twitterAnnouncementKeys[0])}
            onSelect={this.handleSelectTwitterAnnouncement.bind(this, true)}
            disabled={disabled}
          />
        </PreferenceSelector>
        <PreferenceSelector {...i18n.thirdParty} saving={this.isSaving(thirdPartyKeys)} disabled={disabled}>
          <PreferenceItem
            label="No"
            group="product-announcement"
            selected={this.isSelected(thirdPartyKeys, null)}
            onSelect={this.handleSelectThirdParty.bind(this, false)}
            disabled={disabled}
          />
          <PreferenceItem
            label="Yes"
            group="product-announcement"
            selected={this.isSelected(thirdPartyKeys, thirdPartyKeys[0])}
            onSelect={this.handleSelectThirdParty.bind(this, true)}
            disabled={disabled}
          />
        </PreferenceSelector>
      </div>
    );
  }

  isSaving = (keys: string[]) => {
    const { saving } = this.props.model;
    if (saving) {
      return keys.some((key) => !!saving[key]);
    }
  };

  isSelected = (keys: string[], key: string) => {
    const { preferences } = this.props.model;
    let activeKey = null;

    // The latter keys in input array take precedence over previous i.e. daily trumps weekly
    keys.forEach((k) => {
      if (preferences && preferences[k]) {
        activeKey = k;
        return;
      }
    });

    return key === activeKey;
  };

  handleSelectNewMusic = (active?: keyof UserPreferencesSchema) => {
    const preferences: Partial<UserPreferencesSchema> = {
      daily_newmusic_email: false,
      weekly_newmusic_email: false,
      monthly_newmusic_email: false,
    };

    if (active) preferences[active] = true;

    this.props.onUpdatePreferences(preferences);
  };

  handleSelectRecommendedMusic = (active?: keyof UserPreferencesSchema) => {
    const preferences: Partial<UserPreferencesSchema> = {
      daily_recommendation_email: false,
      weekly_recommendation_email: false,
      monthly_recommendation_email: false,
    };

    if (active) preferences[active] = true;

    this.props.onUpdatePreferences(preferences);
  };

  handleSelectProductAnnouncements = (active?: boolean) => {
    this.props.onUpdatePreferences({
      product_announcement_email: active,
    });
  };

  handleSelectTwitterAnnouncement = (active?: boolean) => {
    this.props.onUpdatePreferences({
      twitter_order_announcement: active,
    });
  };

  handleSelectThirdParty = (active?: boolean) => {
    this.props.onUpdatePreferences({
      thirdparty_promotions_email: active,
    });
  };

  static styles = {
    container: style(
      {
        ...vertical,
      },
      mediaMobileOnly({
        ...verticallySpaced(30),
      })
    ),
  };
}

const newMusicKeys = ['monthly_newmusic_email', 'weekly_newmusic_email', 'daily_newmusic_email'];

const recommendedMusicKeys = [
  'monthly_recommendation_email',
  'weekly_recommendation_email',
  'daily_recommendation_email',
];

const productAnnouncementsKeys = ['product_announcement_email'];

const twitterAnnouncementKeys = ['twitter_order_announcement'];

const thirdPartyKeys = ['thirdparty_promotions_email'];
