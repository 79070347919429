import { UIHeroTheme, UIHeroGenre } from './types/ui';
import { rem } from 'csx';
import { SubscriptionPlans } from './components/project-happy/utilities/types';

export const LICKD_YOUTUBE_CHANNEL_ID = 'UCcyDVcv2mByysfO0i1GjTqA';

export const SIGNIN_URL = '/signin';

export const GOLDEN_TICKET_KEY = 'is_golden_ticket';
export const REFERRER_CHANNEL_KEY = 'referrerChannelId';
export const REFERRER_ID = 'referrerId';

export const PREFERRED_GENRE = 'preferred_genre';
export const PRISMIC_PREVIEW_KEY = 'prismicPreview';
export const ENV_KEY = 'lickd_env';

export const SIGN_UP_ACTION_QUERY = 'show-sign-up';
export const SIGN_UP_ROUTE = '/sign-up';
export const SIGN_UP_WITH_PAYG = SIGN_UP_ROUTE + '?plan=' + SubscriptionPlans.PAYG;
export const LOG_IN_ROUTE = '/log-in';

export const REGISTRATION_ROUTE = '/registration';
export const ONBOARD_ROUTE = '/onboard';
export const ONBOARD_VERTICAL_CATEGORY_ROUTE = '/onboard/vertical-category';
export const ONBOARD_FAVOURITE_GENRES = '/onboard/favorite-genres';
export const ONBOARD_VERTICALS_COMPLETE = '/onboard/complete';
export const BROWSE_ROUTE = '/browse';
export const SEARCH_ROUTE = '/search';
export const CHART_SEARCH_ROUTE = `${SEARCH_ROUTE}?catalogueType=CHART`;
export const PRODUCTION_SEARCH_ROUTE = `${SEARCH_ROUTE}?catalogueType=PRODUCTION`;
export const ACCOUNT_PLANS_ROUTE = '/account/plans';
export const ACCOUNT_VIDEOS_ROUTE = '/account/videos';
export const ACCOUNT_PLAYLISTS_ROUTE = '/account/playlists';
export const ACCOUNT_ORDERS_ROUTE = '/account/orders';
export const BROWSE_CHART_PLAYLISTS_ROUTE = '/browse/playlists/chart-playlists';
export const PUBLIC_PLAYLISTS_ROUTE = '/playlists';
export const PLANS_V2_ROUTE = `${REGISTRATION_ROUTE}/plans/v2`;

export const JAACKMAATE_URL = '/jaackmaate';
export const JAACKMAATE_UTM_MEDIUM = 'referral';
export const JAACKMAATE_UTM_SOURCE = 'podcast';
export const JAACKMAATE_UTM_CAMPAIGN = 'jaackmaate-podcast';

export const VIDSUMMIT_URL = '/vidsummit';
export const VIDSUMMIT_UTM_SOURCE = 'vidsummit';
export const VIDSUMMIT_UTM_MEDIUM = 'vidsummit';
export const VIDSUMMIT_UTM_CAMPAIGN = 'vidsummit';

export const BLOGOSPHERE_URL = '/blogosphere';
export const BLOGOSPHERE_UTM_SOURCE = 'blogosphere';
export const BLOGOSPHERE_UTM_MEDIUM = 'blogosphere';
export const BLOGOSPHERE_UTM_CAMPAIGN = 'blogosphere';

export const VIDCON_URL = '/vidconlondon2020';
export const VIDCON_UTM_SOURCE = 'vidconlondon2020';
export const VIDCON_UTM_MEDIUM = 'vidconlondon2020';
export const VIDCON_UTM_CAMPAIGN = 'vidconlondon2020';

export const FULLSCREEN_URL = '/fullscreen';
export const TUBEBUDDY_URL = '/tubebuddy';
export const VIDIQ_URL = '/vidiq';
export const MEDIACUBE_URL = '/mediacube';
export const FULLSCREEN_UTM_SOURCE = 'fullscreen';
export const FULLSCREEN_UTM_MEDIUM = 'referral';
export const FULLSCREEN_UTM_CAMPAIGN = 'fullscreen-dashboard';

export const USER_LAST_SEEN_ANNOUNCEMENT = 'LICKD_USER_LAST_SEEN_ANNOUNCEMENT';

export const SESSION_ONBOARDING_VALUES = 'lickd_upfront_onboarding';
export const SESSION_ONBOARDING_PLAN = 'LICKD_ONBOARDING_PLAN';
export const SESSION_LOGIN_REDIRECT = 'lickd_login';
export const SESSION_LAST_ROUTE = 'LICKD_LAST_ROUTE';
export const SESSION_VWO_PLAYLIST_INITIAL_TRACKS = 'LICKD_VWO_PLAYLIST_INITIAL_TRACKS';

export const SUPPORT_HUB = 'https://help.lickd.co/knowledge/kb-tickets/new';

export const YOUTUBE_THEMES: Array<UIHeroTheme> = [
  { icon: {}, label: 'Auto & Vehicles', slug: 'auto-and-vehicles' },
  { icon: {}, label: 'Beauty & Fashion', slug: 'beauty-and-fashion' },
  { icon: {}, label: 'Comedy', slug: 'comedy' },
  { icon: {}, label: 'Education', slug: 'education' },
  { icon: {}, label: 'Entertainment', slug: 'entertainment' },
  { icon: {}, label: 'Film & Animation', slug: 'film-and-animation' },
  { icon: {}, label: 'Food', slug: 'food' },
  { icon: {}, label: 'Gaming', slug: 'gaming' },
  { icon: {}, label: 'How-to & Style', slug: 'how-to-and-style' },
  { icon: {}, label: 'Music', slug: 'music' },
  { icon: {}, label: 'Pets & Animals', slug: 'pets-and-animals' },
  { icon: {}, label: 'Science & Technology', slug: 'science-and-technology' },
  { icon: {}, label: 'Sport', slug: 'sport' },
  { icon: {}, label: 'Travel & Events', slug: 'travel-and-events' },
];

export const GENRES: Array<UIHeroGenre> = [
  { label: 'Breakbeat', slug: 'breakbeat' },
  { label: 'Drum & Bass', slug: 'drum-and-bass' },
  { label: 'Dubstep', slug: 'dubstep' },
  { label: 'Folk', slug: 'folk' },
  { label: 'House', slug: 'house' },
  { label: 'Indie', slug: 'indie' },
  { label: 'Pop', slug: 'pop' },
  { label: 'Punk', slug: 'punk' },
  { label: 'Remixes', slug: 'remixes' },
  { label: 'Rock', slug: 'rock' },
];

export enum ONBOARDING_STATE {
  EMAIL_UNCONFIRMED = 'email_unconfirmed',
  ONBOARDING_COMPLETE = 'onboarding_complete',
  PLAN_SELECTED = 'plan_selected',
  CHANNEL_ATTACHED = 'channel_attached',
  PLAN_NOT_SELECTED = 'plan_not_selected',
}

export const CHECKOUT_STEPS = ['review', 'payment', 'confirm', 'download'];

export const ENGLISH_COUNTRIES: Array<string> = ['US', 'CA', 'GB', 'AU'];

export const BASE_PX = 16;
export const BASE_RHYTHM = rem(1.25);
export const MAX_PAGE_WIDTH = 64 * 16;

export const VERTICALS = [
  'Animals',
  'Auto & Vehicles',
  'Beauty & Fashion',
  'Business, News & Politics',
  'Diy & Crafts',
  'Education',
  'Food & Drink',
  'Gaming & Technology',
  'Health & Fitness',
  'Humour & Comedy',
  'Kids & Family',
  'Music, Art, Film & Literature',
  'Social & Environmental Topics',
  'Sports',
  'Travel',
];

export const GENRES_CUSTOMER_FACING = [
  'Afro',
  'Ambient / Chill',
  'Blues',
  'Classical',
  'Country',
  'Dance / Electronic',
  'Folk / Acoustic',
  'Christian / Gospel',
  'Hip-Hop',
  'Indie / Alternative',
  'Jazz',
  'Latin',
  'Pop',
  'Punk',
  'R&B / Soul',
  'Reggae',
  'Rock',
  'Metal',
  'Funk / Disco',
];

export const COUNTRIES: { [code: string]: string } = {
  AD: 'Andorra',
  AE: 'United Arab Emirates',
  AF: 'Afghanistan',
  AG: 'Antigua and Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AO: 'Angola',
  AQ: 'Antarctica',
  AR: 'Argentina',
  AS: 'American Samoa',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AX: 'Åland Islands',
  AZ: 'Azerbaijan',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BL: 'Saint Barthélemy',
  BM: 'Bermuda',
  BN: 'Brunei Darussalam',
  BO: 'Bolivia, Plurinational State of',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BR: 'Brazil',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CC: 'Cocos (Keeling) Islands',
  CD: 'Congo, Democratic Republic of the',
  CF: 'Central African Republic',
  CG: 'Congo',
  CH: 'Switzerland',
  CI: "Côte d'Ivoire",
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CN: 'China',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CV: 'Cabo Verde',
  CW: 'Curaçao',
  CX: 'Christmas Island',
  CY: 'Cyprus',
  CZ: 'Czechia',
  DE: 'Germany',
  DJ: 'Djibouti',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Egypt',
  EH: 'Western Sahara',
  ER: 'Eritrea',
  ES: 'Spain',
  ET: 'Ethiopia',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands (Malvinas)',
  FM: 'Micronesia, Federated States of',
  FO: 'Faroe Islands',
  FR: 'France',
  GA: 'Gabon',
  GB: 'United Kingdom of Great Britain and Northern Ireland',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Equatorial Guinea',
  GR: 'Greece',
  GS: 'South Georgia and the South Sandwich Islands',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HM: 'Heard Island and McDonald Islands',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IM: 'Isle of Man',
  IN: 'India',
  IO: 'British Indian Ocean Territory',
  IQ: 'Iraq',
  IS: 'Iceland',
  IT: 'Italy',
  JE: 'Jersey',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'Saint Kitts and Nevis',
  KR: 'Korea, Republic of',
  KW: 'Kuwait',
  KY: 'Cayman Islands',
  KZ: 'Kazakhstan',
  LA: "Lao People's Democratic Republic",
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Morocco',
  MC: 'Monaco',
  MD: 'Moldova, Republic of',
  ME: 'Montenegro',
  MF: 'Saint Martin, (French part)',
  MG: 'Madagascar',
  MH: 'Marshall Islands',
  MK: 'North Macedonia',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MO: 'Macao',
  MP: 'Northern Mariana Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Poland',
  PM: 'Saint Pierre and Miquelon',
  PN: 'Pitcairn',
  PR: 'Puerto Rico',
  PS: 'Palestine, State of',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  RE: 'Réunion',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SE: 'Sweden',
  SG: 'Singapore',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  SI: 'Slovenia',
  SJ: 'Svalbard and Jan Mayen',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  ST: 'Sao Tome and Principe',
  SV: 'El Salvador',
  SX: 'Sint Maarten, (Dutch part)',
  SZ: 'Eswatini',
  TC: 'Turks and Caicos Islands',
  TD: 'Chad',
  TF: 'French Southern Territories',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TK: 'Tokelau',
  TL: 'Timor-Leste',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Turkey',
  TT: 'Trinidad and Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan, Province of China',
  TZ: 'Tanzania, United Republic of',
  UA: 'Ukraine',
  UG: 'Uganda',
  UM: 'United States Minor Outlying Islands',
  US: 'United States of America',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Holy See',
  VC: 'Saint Vincent and the Grenadines',
  VE: 'Venezuela, Bolivarian Republic of',
  VG: 'Virgin Islands, British',
  VI: 'Virgin Islands, U.S.',
  VN: 'Viet Nam',
  VU: 'Vanuatu',
  WF: 'Wallis and Futuna',
  WS: 'Samoa',
  YE: 'Yemen',
  YT: 'Mayotte',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};

export const LANGUAGES: { [code: string]: string } = {
  ab: 'Abkhazian',
  aa: 'Afar',
  af: 'Afrikaans',
  ak: 'Akan',
  sq: 'Albanian',
  am: 'Amharic',
  ar: 'Arabic',
  an: 'Aragonese',
  hy: 'Armenian',
  as: 'Assamese',
  av: 'Avaric',
  ae: 'Avestan',
  ay: 'Aymara',
  az: 'Azerbaijani',
  bm: 'Bambara',
  ba: 'Bashkir',
  eu: 'Basque',
  be: 'Belarusian',
  bn: 'Bengali',
  bh: 'Bihari languages',
  bi: 'Bislama',
  bs: 'Bosnian',
  br: 'Breton',
  bg: 'Bulgarian',
  my: 'Burmese',
  ca: 'Catalan, Valencian',
  km: 'Central Khmer',
  ch: 'Chamorro',
  ce: 'Chechen',
  ny: 'Chichewa, Chewa, Nyanja',
  zh: 'Chinese',
  cu: 'Church Slavic, Church Slavonic, Old Church Slavonic, Old Slavonic, Old Bulgarian',
  cv: 'Chuvash',
  kw: 'Cornish',
  co: 'Corsican',
  cr: 'Cree',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  dv: 'Divehi, Dhivehi, Maldivian',
  nl: 'Dutch, Flemish',
  dz: 'Dzongkha',
  en: 'English',
  eo: 'Esperanto',
  et: 'Estonian',
  ee: 'Ewe',
  fo: 'Faroese',
  fj: 'Fijian',
  fl: 'Filipino',
  fi: 'Finnish',
  fr: 'French',
  ff: 'Fulah',
  gd: 'Gaelic, Scottish Gaelic',
  gl: 'Galician',
  lg: 'Ganda',
  ka: 'Georgian',
  de: 'German',
  el: 'Greek (modern)',
  gn: 'Guaraní',
  gu: 'Gujarati',
  ht: 'Haitian, Haitian Creole',
  ha: 'Hausa',
  he: 'Hebrew (modern)',
  hz: 'Herero',
  hi: 'Hindi',
  ho: 'Hiri Motu',
  hu: 'Hungarian',
  is: 'Icelandic',
  io: 'Ido',
  ig: 'Igbo',
  id: 'Indonesian',
  ia: 'Interlingua',
  ie: 'Interlingue',
  iu: 'Inuktitut',
  ik: 'Inupiaq',
  ga: 'Irish',
  it: 'Italian',
  ja: 'Japanese',
  jv: 'Javanese',
  kl: 'Kalaallisut, Greenlandic',
  kn: 'Kannada',
  kr: 'Kanuri',
  ks: 'Kashmiri',
  kk: 'Kazakh',
  ki: 'Kikuyu, Gikuyu',
  rw: 'Kinyarwanda',
  ky: 'Kirghiz, Kyrgyz',
  kv: 'Komi',
  kg: 'Kongo',
  ko: 'Korean',
  kj: 'Kuanyama, Kwanyama',
  ku: 'Kurdish',
  lo: 'Lao',
  la: 'Latin',
  lv: 'Latvian',
  li: 'Limburgan, Limburger, Limburgish',
  ln: 'Lingala',
  lt: 'Lithuanian',
  lu: 'Luba-Katanga',
  lb: 'Luxembourgish, Letzeburgesch',
  mk: 'Macedonian',
  mg: 'Malagasy',
  ms: 'Malay',
  ml: 'Malayalam',
  mt: 'Maltese',
  gv: 'Manx',
  mi: 'Maori',
  mr: 'Marathi',
  mh: 'Marshallese',
  mn: 'Mongolian',
  na: 'Nauru',
  nv: 'Navajo, Navaho',
  ng: 'Ndonga',
  ne: 'Nepali',
  nd: 'North Ndebele',
  se: 'Northern Sami',
  no: 'Norwegian',
  nb: 'Norwegian Bokmål',
  nn: 'Norwegian Nynorsk',
  oc: 'Occitan',
  oj: 'Ojibwa',
  or: 'Oriya',
  om: 'Oromo',
  os: 'Ossetian, Ossetic',
  pi: 'Pali',
  pa: 'Panjabi, Punjabi',
  ps: 'Pashto, Pushto',
  fa: 'Persian',
  pl: 'Polish',
  pt: 'Portuguese',
  qu: 'Quechua',
  ro: 'Romanian, Moldavian, Moldovan',
  rm: 'Romansh',
  rn: 'Rundi',
  ru: 'Russian',
  sm: 'Samoan',
  sg: 'Sango',
  sa: 'Sanskrit',
  sc: 'Sardinian',
  sr: 'Serbian',
  sn: 'Shona',
  ii: 'Sichuan Yi, Nuosu',
  sd: 'Sindhi',
  si: 'Sinhala, Sinhalese',
  sk: 'Slovak',
  sl: 'Slovenian',
  so: 'Somali',
  nr: 'South Ndebele',
  st: 'Southern Sotho',
  es: 'Spanish, Castilian',
  su: 'Sundanese',
  sw: 'Swahili',
  ss: 'Swati',
  sv: 'Swedish',
  tl: 'Tagalog',
  ty: 'Tahitian',
  tg: 'Tajik',
  ta: 'Tamil',
  tt: 'Tatar',
  te: 'Telugu',
  th: 'Thai',
  bo: 'Tibetan',
  ti: 'Tigrinya',
  to: 'Tonga (Tonga Islands)',
  ts: 'Tsonga',
  tn: 'Tswana',
  tr: 'Turkish',
  tk: 'Turkmen',
  tw: 'Twi',
  ug: 'Uighur, Uyghur',
  uk: 'Ukrainian',
  ur: 'Urdu',
  uz: 'Uzbek',
  ve: 'Venda',
  vi: 'Vietnamese',
  vo: 'Volapük',
  wa: 'Walloon',
  cy: 'Welsh',
  fy: 'Western Frisian',
  wo: 'Wolof',
  xh: 'Xhosa',
  yi: 'Yiddish',
  yo: 'Yoruba',
  za: 'Zhuang, Chuang',
  zu: 'Zulu',
};

export enum ImageSize {
  XLARGE = 'xlarge',
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
  XSMALL = 'xsmall',
}

export enum ImageAspect {
  WIDESCREEN = 16 / 10,
  TV = 4 / 3,
  BOX = 1,
}

export const ImageSizeValue: { [key in ImageSize]: number } = {
  xlarge: 2400,
  large: 1200,
  medium: 760,
  small: 390,
  xsmall: 64,
};

export const KNOWN_COOKIES = [
  'basketIdentity',
  'utm_campaign',
  'utm_medium',
  'utm_source',
  'hsCtaTracking',
  REFERRER_CHANNEL_KEY,
  'is_lsylive',
  GOLDEN_TICKET_KEY,
  PREFERRED_GENRE,
  'mcn_user_id',
  'mcn_token',
  'mcn_name',
];

export const NEW_USER_TYPES = ['new_user', 'new_user_50', 'first_use'];
export const claimStatusMap = {
  NO_CLAIMS: 'No claims found',
  PENDING: 'Pending',
  CLEARED: 'Cleared',
  IN_INPROGRESS: 'In progress',
  CLOSED: 'Request closed',
  CLAIM_CLEARED: 'Claim cleared',
};
