import React from 'react';
import { Button } from './Button';
import { style } from 'typestyle';
import { px } from 'csx';
import { ButtonProminence, ButtonSize } from './buttonStyles';
import { rebrand } from '../../../../theme/color';
import { ArrowDownward } from '../icons/ArrowDownward';
import { LoadingDots } from '../icons/LoadingDots';
import { SrOnlyText } from '../SrOnlyText';

const styles = {
  loadMore: style({
    display: 'flex',
    alignItems: 'center',
    color: rebrand.contrast[50].toString(),
    fontWeight: 400,
    fontSize: px(16),
    padding: 0,
  }),
};

export type LoadMoreArrowProps = { hidden: boolean; handleLoadMore: () => void; loading?: boolean };

export const LoadMoreArrowButton: React.FC<LoadMoreArrowProps> = ({ hidden, handleLoadMore, loading = false }) => {
  if (hidden) {
    return null;
  }

  return (
    <Button
      className={styles.loadMore}
      size={ButtonSize.XSMALL}
      onClick={handleLoadMore}
      prominence={ButtonProminence.NONE}
      disabled={loading}
      data-test-load-more
    >
      {loading ? (
        <>
          <LoadingDots />
          <SrOnlyText>Loading</SrOnlyText>
        </>
      ) : (
        <>
          <span>Load More</span>
          <ArrowDownward />
        </>
      )}
    </Button>
  );
};
