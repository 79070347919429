import * as React from 'react';
import { Component, deps, inject, observer } from '../../../lib/component';
import { EnvModel } from '../../../modules/env/model';
import { Link } from '../atoms/Link';
import { LOG_IN_ROUTE, SIGN_UP_ROUTE, SUPPORT_HUB } from '../../../constants';
import { isEmpty } from '../utilities/objects';
import { Loading } from '../../loading/loading';
import { AnalyticsContextProvider } from '../utilities/AnalyticsContext';
import { desktopBreakpoint } from '../config';
import { classes, style } from 'typestyle';
import { percent, px, viewHeight } from 'csx';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { transitionQuickEase } from '../../../theme/transition';
import { rebrand } from '../../../theme/color';
import { flexRoot } from 'csstips';
import { EventOnError } from '../atoms/EventOnError';
import { SemanticInformationType } from '../utilities/types';
import { AccountSidebar } from './AccountSidebar';
import { centerCenter } from 'csstips/lib/flex';
import { Container } from '../atoms/Container';
import { spacing } from '../../../theme/spacing';

const WITH_SIDEBAR_CLASS = 'with-sidebar';

const styles = {
  wrapper: style(flexRoot),
  unauthenticatedWrapper: style({
    margin: '0 auto',
    maxWidth: px(desktopBreakpoint),
    padding: '32px 16px',
    minHeight: viewHeight(100),
  }),
  loading: style(flexRoot, centerCenter),
  mainContent: style(mediaUpToDesktop({ margin: '32px 0' }), {
    maxWidth: px(desktopBreakpoint + 64 + 88),
    width: percent(100),
    margin: '64px auto',
    transition: `margin ${transitionQuickEase}`,
    $nest: {
      '& h1': {
        fontSize: px(48),
        margin: `0 0 ${spacing.DEFAULT}`,
        textAlign: 'center',
      },
      [`&.${WITH_SIDEBAR_CLASS}`]: {
        padding: '0 16px',
        ...mediaDesktop({
          padding: '0 32px',
          width: percent(70),
        }),
      },
    },
  }),
  sidebar: style({
    width: percent(30),
  }),
  errorText: style({
    color: rebrand.semantic[SemanticInformationType.ERROR]['70'].toString(),
  }),
};

const accountCentrePageContext = { prismicPage: 'Account' };

interface AccountLayoutWrapperProps {
  className?: string;
  hasSidebar?: boolean;
}

const AccountLayoutWrapper: React.FC<React.PropsWithChildren<AccountLayoutWrapperProps>> = ({
  className,
  hasSidebar = false,
  children,
  ...props
}) => {
  const containerClasses = hasSidebar ? styles.wrapper : '';

  return (
    <AnalyticsContextProvider context={accountCentrePageContext}>
      {hasSidebar ? (
        <section className={classes(styles.wrapper, className)} {...props}>
          {children}
        </section>
      ) : (
        <div className={className} {...props}>
          <Container className={containerClasses}>{children}</Container>
        </div>
      )}
    </AnalyticsContextProvider>
  );
};

type AccountLayoutProps = { sidebar?: JSX.Element; mainContentClassName?: string };

@inject(deps)
@observer
export class AccountLayout extends Component<AccountLayoutProps> {
  state = {
    pageHasErrored: false,
  };

  constructor(props) {
    super(props);

    this.handlePageError = this.handlePageError.bind(this);
  }

  handlePageError(error: Error) {
    this.setState({ pageHasErrored: true });
    this.props.controller.bugsnag.notifyExceptionSafely(error);
  }

  render() {
    if (EnvModel.isServer) {
      return (
        <AccountLayoutWrapper className={styles.unauthenticatedWrapper}>
          <p>
            <Link href={SIGN_UP_ROUTE}>Sign up</Link> or <Link href={LOG_IN_ROUTE}>Log in</Link> to view your account
          </p>
        </AccountLayoutWrapper>
      );
    }

    const {
      children,
      sidebar,
      mainContentClassName,
      model: {
        user: { user },
      },
    } = this.props;

    const { pageHasErrored } = this.state;

    if (isEmpty(user)) {
      return (
        <AccountLayoutWrapper className={classes(styles.unauthenticatedWrapper, styles.loading)}>
          <Loading />
        </AccountLayoutWrapper>
      );
    }

    if (pageHasErrored) {
      return (
        <AccountLayoutWrapper>
          <article className={classes(styles.mainContent, mainContentClassName)}>
            <p className={styles.errorText}>
              An error has occurred while displaying this page. Try again or{' '}
              <a href={SUPPORT_HUB}>submit a support ticket</a>.
            </p>
          </article>
        </AccountLayoutWrapper>
      );
    }

    return (
      <AccountLayoutWrapper hasSidebar={!!sidebar}>
        <article className={classes(styles.mainContent, mainContentClassName, sidebar && WITH_SIDEBAR_CLASS)}>
          <EventOnError onError={this.handlePageError}>{children}</EventOnError>
        </article>
        {sidebar && (
          <EventOnError onError={this.handlePageError}>
            <AccountSidebar className={styles.sidebar}>{sidebar}</AccountSidebar>
          </EventOnError>
        )}
      </AccountLayoutWrapper>
    );
  }
}
