export async function get(url: string) {
  const resp = await fetch(url);
  return resp.json();
}

export function withIncludes(includes: Array<string> = []) {
  if (includes.length === 0) return '';

  return `?include=${includes.join(',')}`;
}

export function withPage(page: number = 1) {
  if (page === 1) return '';

  return `?page=${page}`;
}

export function withIncludesAndPage(includes: Array<string> = [], page: number = 1) {
  const includesParts = withIncludes(includes);
  const pageParts = withPage(page);

  switch (true) {
    case (!includesParts.length && !pageParts.length):
      return '';

    case (!!includesParts.length && !pageParts.length):
      return includesParts;

    case (!includesParts.length && !!pageParts.length):
      return pageParts;

    default:
      return includesParts + pageParts.replace(/^\?/, '&');
  }
}