import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const CheckmarkCircle: React.SFC<SVGIconProps> = ({
  size = 24,
  color = '#158918',
  checkColor = '#FFFFFF',
  ...props
}) => (
  <BaseSvg data-test-included-checkmark-circle width={size} height={size} viewBox="0 0 24 24" {...props}>
    <defs>
      <polygon
        id="checkmark-circle__checkmark"
        points="3.38980481 6.41576653 0.861095319 3.88705704 0 4.74208831 3.38980481 8.13189312 10.6666667 0.855031268 9.8116354 -8.8817842e-16"
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="01-Atoms-/-Icons-/-Action-/-check_circle">
        <path
          d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z"
          id="check_circle"
          fill={color}
        />
        <g id="01-Atoms-/-Icons-/-Navigation-/-check" transform="translate(6.666667, 7.934053)">
          <mask id="mask-2" fill={checkColor}>
            <use xlinkHref="#checkmark-circle__checkmark" />
          </mask>
          <use id="check" fill={checkColor} fillRule="nonzero" xlinkHref="#checkmark-circle__checkmark" />
        </g>
      </g>
    </g>
  </BaseSvg>
);
