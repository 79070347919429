import { cssRaw, cssRule, fontFace } from 'typestyle';
import { fontBody, fontHeading, fontIcons } from './font';
import { em, important, percent, px, quote, url, viewHeight } from 'csx';
import { colorGunmetal } from './color';
import { transitionQuickEase } from './transition';
import { constantPlayerHeight } from './constant';
import { BASE_PX } from '../constants';
import { headerFullHeight, minBodyHeight } from '../components/project-happy/config';
import { applyHeadingStyles } from '../components/project-happy/utilities/font';

export const injectGlobal = () => {
  // TODO fix styles
  // fontFace({
  //   fontFamily: quote(fontHeading),
  //   fontStyle: 'normal',
  //   fontWeight: 'bold',
  //   src: `${url(fontHeadingURL)}`,
  // });

  cssRule('html, body', {
    margin: 0,
    padding: 0,
    minHeight: minBodyHeight,
    height: 'auto',
    width: percent(100),
    fontFamily: `${quote(fontBody)}, 'Segoe UI', sans-serif`,
    fontSize: px(BASE_PX),
    lineHeight: 1.375,
    color: colorGunmetal.toString(),
    backgroundColor: colorGunmetal.toString(),
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
  });

  cssRule('*', {
    boxSizing: 'border-box',
    minHeight: 0,
    minWidth: 0,
  });

  cssRule('body', applyHeadingStyles);

  cssRule('body > main', {
    minHeight: minBodyHeight,
    position: 'relative',
  });

  cssRule('a', {
    transition: `color 0.1s linear`,
    textDecoration: 'none',
    color: colorGunmetal.toString(),
  });

  cssRule('p', {
    margin: '1em 0 1.25em 0',
    lineHeight: em(1.375),
  });

  cssRule('button', {
    cursor: 'pointer',
  });

  cssRule('#hubspot-messages-iframe-container', {
    transition: `bottom ${transitionQuickEase}`,
  });

  cssRule('#hubspot-messages-iframe-container[data-shunted]:not(:has(.hs-shadow-container.active))', {
    bottom: important(constantPlayerHeight.toString()),
  });
};
