import * as React from 'react';
import {observer} from 'mobx-react';
import {classes, style} from 'typestyle';
import {percent, quote} from 'csx';

export type RatioTileProps = {
  className?: string;
  onClick?(event: React.MouseEvent<any>): void;
  ratio?: number;
  href?: string;
};

const _memoizedStyles = {};

@observer
export class RatioTile extends React.Component<RatioTileProps, any> {
  render() {
    const { children, className, href, onClick, ratio = 1 } = this.props;

    const props = {
      className: classes(
        RatioTile.styles.container,
        RatioTile.getRatioStyle(ratio),
        onClick && RatioTile.styles.clickable,
        className
      ),
      onClick,
      children,
    };

    if (href)
      props['href'] = href;

    return href
      ? <a {...props} />
      : <div {...props} />
  }

  static styles = {
    container: style({
      position: 'relative',
      $nest: {
        '&> *': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
        },
        '&:before': {
          content: quote(''),
          display: 'block',
        }
      }
    }),
    clickable: style({
      cursor: 'pointer'
    })
  };

  static getRatioStyle = (ratio: number) => {
    return _memoizedStyles[ratio] || (_memoizedStyles[ratio] = style({
      $nest: {
        '&:before': {
          paddingTop: percent(100 * (1/ratio))
        }
      }
    }))
  };
}