import * as React from 'react';
import { Component, ComponentProps, deps, inject, observer } from '../lib/component';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { fontstackDefault } from '../components/project-happy/config';
import { LOAD_STATE } from '../types/api';
import planStyles from '../components/project-happy/organisms/plans/styles';
import { Payg } from '../components/project-happy/organisms/plans/Payg';
import { Subscription } from '../components/project-happy/organisms/plans/Subscription';
import { InfoCircle } from '../components/project-happy/atoms/icons/InfoCircle';
import { QuestionCircle } from '../components/project-happy/atoms/icons/QuestionCircle';
import ToolTipModal, { ToolTipModalProps } from '../components/project-happy/molecules/ToolTipModal';
import { ManageSubscriptionCard } from '../components/project-happy/molecules/ManageSubscriptionCard';
import { SUPPORT_HUB } from '../constants';
import { APIError } from '../modules/api/types';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { SubscriptionStates } from '../components/project-happy/utilities/types';
import { AccountLayout } from '../components/project-happy/templates/AccountLayout';
import { ChannelSwitcherAccountSidebar } from '../components/project-happy/organisms/ChannelSwitcherAccountSidebar';
import { mediaUpToDesktop, mediaUpToSmallTablet } from '../components/project-happy/utilities/mediaQueries';
import { spacing } from '../theme/spacing';
import { ChannelBanner } from '../components/project-happy/molecules/ChannelBanner';
import { RatecardV2 } from '../components/project-happy/molecules/RatecardV2';
import { centerJustified, flexRoot, wrap } from 'csstips';
import { AccountPlansV2 } from '../components/project-happy/organisms/plans-v2/AccountPlansV2';

const styles = {
  text: style({
    textAlign: 'center',
    marginLeft: px(25),
    marginRight: px(25),
  }),
  info: style({
    textAlign: 'center',
    fontSize: px(10),
    marginTop: px(8),
    $nest: {
      '& h4': {
        fontFamily: fontstackDefault,
        fontSize: px(12),
        fontWeight: 'normal',
        marginTop: px(4),
        marginBottom: px(8),
        textDecoration: 'underline',
      },
      '& p': {
        marginTop: px(8),
        marginBottom: px(8),
      },
      '& > a': {
        fontSize: px(12),
      },
    },
  }),
  subscribeError: style({
    color: 'red',
  }),
  channelBanner: style({
    marginBottom: spacing.DEFAULT,
  }),
  channelBannerInfo: style(
    {
      fontSize: px(12),
      margin: 0,
      maxWidth: '75%',
    },
    mediaUpToSmallTablet({
      textAlign: 'center',
    })
  ),
  channelBannerInfoWithChannel: style(
    mediaUpToSmallTablet({
      display: 'none',
    })
  ),
  hideUntilDesktop: style(mediaUpToDesktop({ display: 'none' })),
  plans: style(flexRoot, centerJustified, wrap, {
    marginTop: spacing.XXXLARGE,
  }),
};

const tooltipStyles = style({
  maxWidth: px(320),
  $nest: {
    '& h3': {
      marginTop: '16px',
    },
    '& p': {
      margin: '16px',
    },
  },
});

const PriceBandsTooltip: React.FC<ToolTipModalProps> = ({ className, ...props }) => (
  <ToolTipModal className={classes(tooltipStyles, className)} {...props}>
    <h3>How are price bands calculated?</h3>
    <p>
      Our pricing structure is based on the average views your recent video uploads have received. When you sign-in to
      Lickd with your YouTube account we fetch your latest 50 videos and take an average of the video views. This allows
      us to estimate your audience size and put you into a price band.
    </p>
  </ToolTipModal>
);
@inject(deps)
@observer
export class AccountPlansPage extends Component<ComponentProps> {
  state = {
    tooltipShown: false,
    subscriptionUrlLoading: false,
    subscriptionError: null,
    shownChannelId: null,
    subscriptionInfoError: false,
    subscriptionInfo: null,
    subscriptionInfoLoading: false,
  };

  constructor(props) {
    super(props);
    this.getSubscriptionUrl = this.getSubscriptionUrl.bind(this);
  }

  componentDidMount(): void {
    const {
      model: {
        user: { channel },
      },
    } = this.props;
    if (!isEmpty(channel) && this.state.shownChannelId !== channel.id) {
      this.setState({
        shownChannelId: channel.id,
        subscriptionInfo: null,
        subscriptionInfoError: false,
        subscriptionInfoLoading: false,
      });
    }
    this.getSubscriptionInfo();
    this.getSubscriptions();
  }

  componentDidUpdate() {
    const {
      model: {
        user: { channel },
      },
    } = this.props;
    if (!isEmpty(channel) && this.state.shownChannelId !== channel.id) {
      this.setState({
        shownChannelId: channel.id,
        subscriptionInfo: null,
        subscriptionInfoError: false,
        subscriptionInfoLoading: false,
      });
    }
    this.getSubscriptionInfo();
  }

  async getSubscriptionUrl(): Promise<void> {
    try {
      this.setState({
        subscriptionUrlLoading: true,
      });
      await this.props.controller.subscription.navigateToStripeSubscription();
    } catch (error) {
      this.setState({
        subscriptionError: error instanceof Error ? error.message : error,
      });
    } finally {
      this.setState({
        subscriptionUrlLoading: false,
      });
    }
    this.props.controller.analytics.sendMixpanel('User clicks "Subscribe" button', {
      path: this.props.model.router.location.pathname,
    });
  }

  async getSubscriptionInfo() {
    const readyForInfo =
      this.state.subscriptionInfo === null &&
      this.state.subscriptionInfoLoading !== true &&
      this.state.subscriptionInfoError === false &&
      this.state.shownChannelId !== null &&
      !isEmpty(this.props.model.user.user);

    if (!readyForInfo) return;

    try {
      this.setState({
        subscriptionInfoLoading: true,
      });
      const data = await this.props.controller.user.getSubscription(this.props.model.user.channel.id);
      this.setState({ subscriptionInfo: data });
      return data;
    } catch (error) {
      if (error instanceof APIError) {
        this.setState({
          subscriptionInfoError: error.reason,
        });
      } else {
        this.setState({
          subscriptionInfoError: 'An unknown error occurred loading subscription information.',
        });
      }
    } finally {
      this.setState({
        subscriptionInfoLoading: false,
      });
    }
  }

  async getSubscriptions() {
    const data = await this.props.controller.user.getSubscriptions();
  }

  render() {
    const {
      model: {
        user: {
          isSubscribed,
          isSubscribedAndOngoing,
          isSubscribedAndCancelled,
          isSubscribedWithoutChannel,
          channel,
          loading,
          loadingChannels,
          loadingAccounts,
        },
        subscription: {
          subscriptionPlansState,
          subscriptionPlansError,
          subscriptionPlan,
          canSubscribe,
          potentialTrialEndDate,
          trialLengthInDays,
        },
        ui: { projectSubscriptionEnabled },
      },
    } = this.props;
    const { subscriptionInfo } = this.state;

    const isLoading = loading || loadingChannels || loadingAccounts;

    let channelSubscriptionInfo = null;

    if (!isEmpty(subscriptionInfo) && isSubscribed) {
      channelSubscriptionInfo = subscriptionInfo[0];
    }

    return (
      <AccountLayout sidebar={<ChannelSwitcherAccountSidebar />}>
        <h1>Plans &amp; billing</h1>

        <PriceBandsTooltip
          show={this.state.tooltipShown}
          onRequestClose={() => this.setState({ tooltipShown: false })}
        />

        <ChannelBanner className={styles.channelBanner}>
          {isEmpty(channel) ? (
            <p className={classes(styles.channelBannerInfo)}>Please add your YouTube channel to subscribe to Lickd</p>
          ) : (
            <p className={classes(styles.channelBannerInfo, styles.channelBannerInfoWithChannel)}>
              Subscriptions are on a channel basis{' '}
              <span className={styles.hideUntilDesktop}>
                - you can see your status for each YouTube channel in the &quot;Your Channels&quot; section
              </span>
            </p>
          )}
        </ChannelBanner>

        {!isLoading && <RatecardV2 />}

        {projectSubscriptionEnabled ? (
          <AccountPlansV2 />
        ) : (
          !isLoading && (
            <>
              {subscriptionPlansState === LOAD_STATE.ERROR && (
                <p className={styles.subscribeError}>
                  {subscriptionPlansError} <a href={SUPPORT_HUB}>Submit a support ticket</a> if this issue persists.
                </p>
              )}

              <div className={styles.plans}>
                <div className={planStyles.planWrapper}>
                  <Subscription
                    handleSubscribeAction={this.getSubscriptionUrl}
                    actionLoading={this.state.subscriptionUrlLoading}
                    showBlockBanner={true}
                  />
                  <div className={styles.info}>
                    <InfoCircle size={24} />
                    {isSubscribedAndOngoing && !isEmpty(channelSubscriptionInfo) && (
                      <div>
                        <p>
                          Subscription will renew on {''}
                          {dayjs(channelSubscriptionInfo.end_date).format('DD/MM/YYYY')}
                        </p>
                      </div>
                    )}
                    {isSubscribedAndCancelled && !isEmpty(channelSubscriptionInfo) && (
                      <div>
                        <p>
                          Subscription will end on {''}
                          {dayjs(channelSubscriptionInfo.end_date).format('DD/MM/YYYY')}
                        </p>
                      </div>
                    )}
                    {!isSubscribed &&
                      trialLengthInDays > 0 && [
                        <p key={0}>Subscription will start after the trial period has ended.</p>,
                        <p key={1}>Billing will commence on {potentialTrialEndDate}.</p>,
                      ]}
                    {!isEmpty(channelSubscriptionInfo) &&
                      isSubscribed &&
                      this.state.subscriptionInfo != null &&
                      channelSubscriptionInfo.status === SubscriptionStates.TRIAL && (
                        <div>
                          {!isEmpty(channelSubscriptionInfo) && (
                            <p>
                              Subscription trial period will end on {''}
                              {dayjs(channelSubscriptionInfo.end_date).format('DD/MM/YYYY')}
                            </p>
                          )}
                        </div>
                      )}
                    {!isSubscribed && trialLengthInDays === 0 && (
                      <p>Payment will be taken immediately and again in 30 days.</p>
                    )}
                    <p>A subscription is required for each YouTube channel</p>
                  </div>
                </div>

                {!isSubscribed && !isSubscribedWithoutChannel && (
                  <div className={planStyles.planWrapper}>
                    <Payg />
                    <div className={styles.info}>
                      <QuestionCircle size={24} />
                      <h4 onClick={() => this.setState({ tooltipShown: true })}>How are price bands calculated?</h4>
                      <p style={{ width: '95%' }}>
                        Chart is free to access and will be added to your account automatically. Don&apos;t worry, you
                        won&apos;t be charged until you buy chart songs.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </>
          )
        )}

        <ManageSubscriptionCard />
      </AccountLayout>
    );
  }
}
