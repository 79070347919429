import React from 'react';
import { style } from 'typestyle';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { colorWhite, rebrand } from '../../../theme/color';
import { mediaUpToDesktop } from '../utilities/mediaQueries';
import { ProgressBar } from '../molecules/ProgressBar';

const styles = {
  container: style(mediaUpToDesktop({ marginTop: '32px', top: '40px' }), {
    position: 'absolute',
    top: '115px',
  }),
  progressBar: style(mediaUpToDesktop({ width: '350px' })),
};

const progressPercentages = [0, 33, 73, 100];

export const OnboardProgressBar = inject(deps)(
  observer(
    ({
      model: {
        user: { channel },
      },
      step,
    }: ComponentProps & { step: number }) => {
      return (
        <div className={styles.container}>
          <ProgressBar
            steps={['Your content', 'Favorite genres', 'Complete!']}
            progress={progressPercentages[step]}
            colour={rebrand.secondary[60].toHexString()}
            className={styles.progressBar}
          />
        </div>
      );
    }
  )
);
