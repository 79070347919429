import {
  VIDCON_UTM_CAMPAIGN,
  VIDCON_UTM_MEDIUM,
  VIDCON_UTM_SOURCE,
  SIGN_UP_ACTION_QUERY
} from '../constants';

export default async function setVidconCampaign() {
  document.cookie = `utm_medium=${VIDCON_UTM_MEDIUM}`;
  document.cookie = `utm_source=${VIDCON_UTM_SOURCE}`;
  document.cookie = `utm_campaign=${VIDCON_UTM_CAMPAIGN}`;

  window.location.href = `/?${SIGN_UP_ACTION_QUERY}&utm_source=vidconlondon2020&utm_medium=vidconlondon2020&utm_campaign=vidconlondon2020`;
}