import * as React from 'react';
import { Component, inject, deps, observer } from '../lib/component';
import { Helmet } from 'react-helmet';
import { UIBreadcrumb } from '../types/ui';

export interface HeadProps {
  description?: string;
  title?: string;
}

@inject(deps)
@observer
export class Head extends Component<HeadProps> {
  render() {
    const { title, description, breadcrumbs = [], opengraph } = this.props.model.ui;
    const { baseUrl, staticUri } = this.props.model.env;
    const { location } = this.props.model.router;

    return (
      <Helmet>
        <meta name="description" content={description || void 0} />
        <title>{title}</title>
        <link rel="canonical" href={this.props.model.env.baseUrl + location.pathname} />
        <link rel="alternate" href={this.props.model.env.baseUrl + location.pathname} hrefLang="en" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link
          href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@300;400;600;700&display=swap"
          rel="stylesheet"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Lickd" />
        <meta property="og:title" content={opengraph.title || title} />
        <meta property="og:url" content={opengraph.url || `${baseUrl}${location.pathname}`} />
        <meta property="og:image" content={`${baseUrl}/img/lickd-social-thumbnail.png`} />
        <meta property="og:description" content={opengraph.description || description} />
        {this.renderBreadcrumbs(breadcrumbs)}
      </Helmet>
    );
  }

  renderBreadcrumbs = (breadcrumbs: Array<UIBreadcrumb>) => {
    if (breadcrumbs.length === 0) return null;
    const { baseUrl } = this.props.model.env;

    const content = `{
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [${breadcrumbs.map(
          (breadcrumb, index) => `{
          "@type": "ListItem",
          "position": ${(index + 1).toString()},
          "item": {
            "@id": "${baseUrl + breadcrumb.path}",
            "name": "${breadcrumb.label}"
          }
        }`
        )}]
    }`;

    return <script type="application/ld+json">{content}</script>;
  };
}
