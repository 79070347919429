import { PricingModel } from './model';
import { action, runInAction } from 'mobx';
import { APIController } from '../api/controller';
import { BugsnagController } from '../bugsnag/controller';
import { EnvModel } from '../env/model';
import {RatecardStatisticsSchema} from '../../types/schema';
import {formatCurrency} from '../../lib/currency';

export class PricingController {
  constructor(
    private model: PricingModel,
    private api: APIController,
    private bugsnag: BugsnagController,
    private env: EnvModel
  ) {
    if (!env.isServer) {
      this.getDefaultRates();
    }
  }

  @action
  reset = () => {
    this.model.channelId = null;
    this.model.result = null;
    this.model.query = '';
    this.model.channels = [];
    this.model.error = null;
    this.model.searching = false;
    this.model.loading = false;
  };

  @action
  changeChannelId = (channelId: string) => {
    this.model.channelId = channelId;
  };

  @action
  changeQuery = (query: string) => {
    this.model.query = query;
  }

  @action
  changeCurrency = (value: string) => {
    this.model.currency = this.model.currencies.find(c => c.value === value);

    if (this.model.channelId) {
      this.submit(this.model.channelId);
    }
  };

  @action
  search = async () => {
    if (!this.model.query) return;

    runInAction(() => {
      this.model.searching = true;
      this.model.channelId = null;
      this.model.result = null;
    });

    try {
      const channels = await this.api.youtube.searchChannels(this.model.query);

      runInAction(() => {
        if (channels.data.length) {
          this.model.error = '';
          this.model.channels = channels.data;
        } else {
          this.model.error = `No channels found for "${this.model.query}"`
        }
      });
    } catch (e) {

    } finally {
      runInAction(() => {
        this.model.searching = false;
      })
    }
  };

  @action
  submit = async (channelId: string) => {
    if (!channelId) return;

    runInAction(() => {
      this.model.loading = true;
      this.model.channelId = channelId;
      this.model.error = null;
      this.model.result = null;
    });

    try {
      const { data } = await this.api.pricing.getPrice(this.model.channelId, this.model.currency.value);

      runInAction(() => {
        const image = data.images.find(i => i.size === 'high') || data.images[data.images.length - 1];
        const stats = (data.ratecard_statistics as any) as RatecardStatisticsSchema;

        this.model.result = {
          avatar: image.url,
          currency: data.ratecard.currency,
          name: data.name,
          rate: (data.ratecard.value + '').replace(/\..+$/, ''),
          viewership: stats.average_views
        };
      })
    } catch (e) {
      runInAction(() => {
        this.bugsnag.notifyException(e);
        try {
          this.model.error = e.body.error;
        } catch (e) {
          this.model.error = 'We\'re not sure what happened here, please try again later'
        }
      })
    } finally {
      runInAction(() => {
        this.model.loading = false;
      })
    }
  };

  @action
  selectRateType = async (type: string) => {
    const rateType = this.model.ratecardTypes.find(t => t.value === type);
    if (!rateType) return;

    this.model.ratecardLoading = true;
    this.model.ratecardType = type;

    if (this.model.channelId) {
      this.submit(this.model.channelId);
    }

    try {
      const ratecards = await this.api.pricing.getRateCards();

      runInAction(() => {
        this.model.ratecards = ratecards.data;
      });
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      runInAction(() => {
        this.model.ratecardLoading = false;
      });
    }
  };

  @action
  getDefaultRates = async () => {
    try {
      this.model.ratesLoading = true;

      const [unsubscribed, subscribed] = await Promise.all([
        this.api.pricing.getRateCards(false),
        this.api.pricing.getRateCards(true),
      ]);

      this.model.rates = [
        {
          id: 'standard',
          value: `25% off your first track`,
          label: 'Standard',
          available: true
        },
      ];

      this.model.unsubscribedRates = unsubscribed.data;
      this.model.subscribedRates = subscribed.data;

    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      runInAction(() => {
        this.model.ratesLoading = false;
      });
    }
  }
}