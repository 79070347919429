import React from 'react';
import { Button, ButtonProps } from './Button';
import { classes, style } from 'typestyle';
import { center, flexRoot } from 'csstips';
import { px } from 'csx';
import { ButtonProminence, ButtonSize } from './buttonStyles';
import { ArrowUp } from '../icons/ArrowUp';
import { scrollToTop } from '../../utilities/page';

const styles = {
  button: style(flexRoot, center, {
    margin: '12px 0px 0px 8px',
  }),
  arrow: style({
    marginRight: px(4),
  }),
};

export type BackToTopProps = ButtonProps;

export const BackToTopButton: React.FC<BackToTopProps> = ({ children, className, prominence, size, ...props }) => (
  <Button
    {...props}
    className={classes(styles.button, className)}
    size={size || ButtonSize.XSMALL}
    prominence={prominence || ButtonProminence.SECONDARY}
    onClick={scrollToTop}
    data-test-back-to-top
  >
    <ArrowUp className={styles.arrow} size={12} />
    Back to top
  </Button>
);
