import * as React from 'react';
import { FormEvent } from 'react';
import { ModalProps } from '../../modal';
import { ScrollableOverlay } from '../../scrollable-overlay/scrollable-overlay';
import { classes, style } from 'typestyle';
import { em, percent, px } from 'csx';
import { heading6 } from '../utilities/font';
import { Component, deps, inject, observer } from '../../../lib/component';
import { Loading } from '../../loading/loading';
import { Button } from '../atoms/button/Button';
import { ButtonProminence } from '../atoms/button/buttonStyles';
import { flexRoot, vertical } from 'csstips';
import { rebrand } from '../../../theme/color';
import { APIError } from '../../../modules/api/types';
import { SemanticInformationType } from '../utilities/types';
import { Input } from '../atoms/controls/Input';
import { animationFadeIn } from '../../../theme/animation';
import { commonInputStyles } from '../utilities/inputs';
import { TextArea } from '../atoms/controls/TextArea';
import siteEvents, { SITE_EVENTS } from '../utilities/siteEvents';

const styles = {
  wrapper: style({ width: percent(100), display: 'block' }),
  heading: style(heading6, { textAlign: 'center' }),
  body: style({ position: 'relative', padding: px(16), borderTop: `1px solid ${rebrand.light1.toString()}` }),
  text: style({ fontSize: em(0.75), textAlign: 'center' }),
  form: style(flexRoot, vertical, {
    $nest: {
      '& label': {
        fontSize: em(0.75),
        fontWeight: 600,
        margin: '12px 0 2px',
      },
    },
  }),
  description: style({
    fontSize: em(0.9),
  }),
  error: style({
    color: rebrand.semantic[SemanticInformationType.ERROR][70].toString(),
  }),
  submitButton: style({
    alignSelf: 'center',
    marginTop: px(16),
  }),
  loader: style(animationFadeIn, {
    position: 'absolute',
    top: px(54),
    width: percent(100),
    height: 'calc(100% - 54px)',
    left: 0,
    background: 'white',
  }),
};

export type CreateUserPlaylistModalProps = ModalProps & { className?: string; onSuccess?(): void };
type CreateUserPlaylistModalState = {
  name: string;
  description: string;
  clicked: boolean;
  loading: boolean;
  error: string | null;
};

@inject(deps)
@observer
export class CreateUserPlaylistModal extends Component<CreateUserPlaylistModalProps, CreateUserPlaylistModalState> {
  state: CreateUserPlaylistModalState = {
    name: '',
    description: '',
    clicked: false,
    loading: false,
    error: null,
  };

  constructor(props) {
    super(props);
    this.createUserPlaylist = this.createUserPlaylist.bind(this);
  }

  get isValid(): boolean {
    const { name, description } = this.state;
    return name.length > 0 && description.length > 0;
  }

  async createUserPlaylist(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const {
      model: {
        user: {
          user: { identity },
        },
        modal: { createPlaylistModalTrackId },
      },
    } = this.props;
    const { name, description } = this.state;
    if (!this.isValid) return;
    this.setState({ loading: true });
    try {
      const response = await this.props.controller.api.playlist.createUserPlaylist(identity, name, description);
      await this.props.controller.analytics.sendMixpanel('User Creates a Playlist', {
        playlist_name: name,
        playlist_description: description,
      });
      if (response.success !== false) {
        siteEvents.emit(SITE_EVENTS.USER_PLAYLIST_CREATE, { name, description });

        // If the playlist is created via the add track to playlist button, there should be a track ID in the store
        // which we can use to add that track once the playlist has been created
        if (createPlaylistModalTrackId) {
          // Get the newly created playlist ID and add the track
          const { identity: playlistId } = response.data;

          await this.props.controller.api.playlist.addTrackToUserPlaylist(
            identity,
            playlistId,
            createPlaylistModalTrackId
          );
        }

        this.props.onRequestClose();
        this.props.onSuccess();
      } else {
        this.setState({
          error: 'An unknown error occurred.',
        });
      }
    } catch (error) {
      if (error instanceof APIError) {
        this.setState({
          error: error.reason,
        });
      } else {
        this.setState({
          error: 'An unknown error occurred.',
        });
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<CreateUserPlaylistModalProps>) {
    if (prevProps.show === true && this.props.show === false) {
      this.setState({
        name: '',
        description: '',
        clicked: false,
        error: null,
      });
    }
  }

  render() {
    const {
      props: { model, controller, ...props },
    } = this;
    const { name, description, clicked, loading, error } = this.state;

    return (
      <ScrollableOverlay className={styles.wrapper} {...props}>
        <h3 className={styles.heading}>Create a new playlist</h3>
        <main className={styles.body}>
          <p className={styles.text}>
            Playlists allow you to organise tracks into groups. Give your playlist a name and a description to get
            started.
          </p>
          <form className={classes(styles.form, clicked && 'show-errors')} onSubmit={this.createUserPlaylist}>
            <label htmlFor="playlist-name">Name</label>
            <Input
              id="playlist-name"
              type="text"
              placeholder="Enter playlist name"
              value={name}
              onChange={(event) => this.setState({ name: event.target.value })}
              isValid={!clicked || name.length > 0}
              required={true}
              maxLength={255}
              data-test-playlist-modal-name-field
            />
            <label htmlFor="playlist-description">Description</label>
            <TextArea
              id="playlist-description"
              className={styles.description}
              value={description}
              onChange={(event) => this.setState({ description: event.target.value })}
              placeholder="Playlist description..."
              maxLength={1000}
              data-test-playlist-modal-description-field
            />
            {error && <p className={classes(styles.text, styles.error)}>{error}</p>}
            <Button
              className={styles.submitButton}
              disabled={loading}
              prominence={this.isValid ? ButtonProminence.PRIMARY : ButtonProminence.DISABLED}
              type="submit"
              onClick={() => this.setState({ clicked: true })}
              data-test-create-user-playlist-button
            >
              Submit
            </Button>
          </form>
          {loading && <Loading className={styles.loader} />}
        </main>
      </ScrollableOverlay>
    );
  }
}
