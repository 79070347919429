import { parseISO, format } from 'date-fns';

type UntypedDate = string | number | Date;

const getDate = (date: UntypedDate): Date => {
  if (typeof date === 'string') return parseISO(date);
  if (typeof date === 'number' && Number.isFinite(date)) new Date(date);
  if (date instanceof Date) return date;
  return new Date();
};

export const toDateString = (date: UntypedDate): string => format(getDate(date), 'MMM dd yyyy');
