import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalChildStyle } from './modal';
import { style } from 'typestyle';
import { CopyBox } from './copy-box';
import { content, endJustified, horizontal, horizontallySpaced } from 'csstips';
import { ButtonPill } from './button-pill';
import { colorBrand, colorSubtle } from '../theme/color';
import { resetAnchorStyles } from '../theme/reset';
import { px } from 'csx';
import { attributionContent } from './project-happy/utilities/attributionText';
import { spacing } from '../theme/spacing';

export interface VideoAttributionModalModel {
  show: boolean;
  loading: boolean;
  attribution: string;
  artist: string;
  shortId: string;
  track: string;
}

export interface VideoAttributionModalProps {
  model: VideoAttributionModalModel;
  onRequestClose(): void;
  i18n?: {
    title: string;
    strap: string;
    why: string;
    empty: string;
    close: string;
    referralURL: string;
  };
}

@observer
export class VideoAttributionModal extends React.Component<VideoAttributionModalProps, any> {
  render() {
    const { model, i18n = VideoAttributionModal.i18n, onRequestClose } = this.props;
    return (
      <Modal show={model.show} onRequestClose={onRequestClose}>
        <div className={VideoAttributionModal.styles.container}>
          <h3>Video description attribution</h3>
          <p>Adding this attribution to your video description is a must, because:</p>
          <ol>
            <li>The attribution allows us to prevent and clear claims made against your video</li>
            <li>
              The promotion of the artist allows us to provide you with this license{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://help.lickd.co/knowledge/why-do-i-need-to-credit-the-artist-and-include-a-short-url-in-my-video-description"
                className={VideoAttributionModal.styles.link}
              >
                (why?)
              </a>
            </li>
          </ol>
          <p>
            The additional referral link allows you to earn discounts like 50% off your next mainstream track!{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://help.lickd.co/knowledge/how-does-the-referral-url-link-work "
              className={VideoAttributionModal.styles.link}
            >
              (here&apos;s how)
            </a>
          </p>
          {model.loading && <div className={VideoAttributionModal.styles.loading}>Loading...</div>}
          {!model.loading && model.attribution && (
            <CopyBox
              content={attributionContent(
                model.attribution,
                model.track,
                model.artist,
                model.shortId,
                i18n.referralURL
              )}
              className={VideoAttributionModal.styles.copy}
              singleLine={false}
            />
          )}
          {!model.loading && !model.attribution && <div>{i18n.empty}</div>}
          <div className={VideoAttributionModal.styles.buttons}>
            <ButtonPill onClick={onRequestClose}>{i18n.close}</ButtonPill>
          </div>
        </div>
      </Modal>
    );
  }

  static i18n = {
    title: 'Video description attribution',
    strap: 'Please copy the following into your YouTube video description.',
    why: 'Why do I have to do this? 🤔',
    empty: 'There are no attributions to copy',
    close: 'Close',
    referralURL: '',
  };

  static styles = {
    container: style({
      ...ModalChildStyle,
      $nest: {
        '&> p': {
          margin: '0',
        },
      },
    }),
    link: style({
      color: colorBrand.toString(),
      $nest: { ...resetAnchorStyles(colorBrand.toString()) },
    }),
    buttons: style({
      ...horizontal,
      ...horizontallySpaced(10),
      ...endJustified,
      margin: '24px 0 0 0',
    }),
    loading: style({
      padding: px(30),
      textAlign: 'center',
      color: colorSubtle.toString(),
    }),
    spaceBottom: style({
      marginBottom: '8px',
    }),
    copy: style({
      overflowWrap: 'break-word',
      marginTop: spacing.DEFAULT,
    }),
  };
}
