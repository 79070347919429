import * as React from 'react';
import { Component, ComponentProps, deps, inject, observer } from '../../lib/component';
import { style } from 'typestyle';
import { px } from 'csx';
import {
  FeatureFlag,
  FREE_USER_EARLY,
  getFlag,
  REPLACE_CAROUSELS,
  setFlag,
  SKIP_PLANS_STAGE,
  SKIP_BUTTON_ON_REGISTRATION_PLANS,
  PROJECT_SUBSCRIPTION_PREVIEW,
} from './feature-flags';
import Cookies from 'js-cookie';
import { PRISMIC_PREVIEW_KEY } from '../../constants';
import { mixinTextEllipsis } from '../../theme/mixin';
import { runInAction } from 'mobx';

const styles = {
  container: style({
    position: 'fixed',
    bottom: 0,
    left: 0,
    background: 'white',
    zIndex: 9999,
    padding: px(20),
    border: '1px solid black',
  }),
  list: style({
    listStyle: 'none',
    margin: 0,
    padding: 0,
    marginBottom: px(20),
    $nest: {
      '& li': {
        margin: '4px 0',
      },
    },
  }),
  prismicRef: style(mixinTextEllipsis, { maxWidth: px(160), display: 'inline-block', verticalAlign: 'text-top' }),
};

const StorageInput = (itemKey: FeatureFlag) => (
  <input type="checkbox" checked={getFlag(itemKey) || false} onChange={(evt) => setFlag(itemKey, evt.target.checked)} />
);

@inject(deps)
@observer
export default class FeatureFlagWidget extends Component<ComponentProps> {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
    this.resetPrismicRef = this.resetPrismicRef.bind(this);
  }

  resetPrismicRef() {
    Cookies.remove(PRISMIC_PREVIEW_KEY);
    runInAction(() => {
      this.props.model.env.prismicRefOverride = null;
    });
  }

  render() {
    const isProduction = this.props.model.env.appEnv === 'prod';
    const { show } = this.state;

    if (!show || isProduction) return null;

    return (
      <div data-test-feature-toggle-modal className={styles.container}>
        <ul className={styles.list}>
          <li>
            <label>Users can access the Platform without a plan {StorageInput(FREE_USER_EARLY)}</label>
          </li>
          <li>
            <label>Users do not see the plans page at registration {StorageInput(SKIP_PLANS_STAGE)}</label>
          </li>
          <li>
            <label>Display carousels available on test {StorageInput(REPLACE_CAROUSELS)}</label>
          </li>
          <li>
            <label>
              Show the skip button on the registration plans page {StorageInput(SKIP_BUTTON_ON_REGISTRATION_PLANS)}
            </label>
          </li>
          {/* **PROJECT_SUB_FLAG** delete post-subscription launch */}
          <li>
            <label>Enable Project Subscription features {StorageInput(PROJECT_SUBSCRIPTION_PREVIEW)}</label>
          </li>
          <li>
            <label>
              Prismic content reference:{' '}
              {this.props.model.env.prismicRefOverride ? (
                <>
                  <span className={styles.prismicRef} title={this.props.model.env.prismicRefOverride}>
                    {this.props.model.env.prismicRefOverride}
                  </span>{' '}
                  <button onClick={this.resetPrismicRef}>Reset</button>
                </>
              ) : (
                'Default'
              )}
            </label>
          </li>
        </ul>
        <button onClick={() => this.setState({ show: false })}>Hide</button>
      </div>
    );
  }
}
