import * as React from 'react';
import { observer } from 'mobx-react';
import { style } from 'typestyle';
import { centerCenter, flexRoot } from 'csstips';
import { percent, px } from 'csx';
import { rebrand } from '../theme/color';
import { ChannelSchema } from '../types/schema';
import GoogleButton from './user/google-button';
import { ScrollableOverlay } from './scrollable-overlay/scrollable-overlay';
import overlayStyles from '../styles/scrollable-overlay';
import GoogleAuthIllustration from '../static/google-auth-checkbox-illustration.png';
import { ExternalLink } from './project-happy/atoms/icons/ExternalLink';

export interface ChannelAddModalModel {
  show: boolean;
  loading: boolean;
  linking: boolean;
  results: Array<ChannelSchema>;
  selection: any;
  youtubeChannelLoginURL: string;
  error: string;
}

export interface ChannelAddModalProps {
  model: ChannelAddModalModel;
  onRequestClose(): void;
  onSearch(query: string): void;
  onSelect(channel: ChannelSchema): void;
  onDeselect(): void;
  onLink(): void;
  i18n?: {
    title: string;
    titleSignIn: string;
    titleSearch: string;
    placeholder: string;
    submit: string;
    strap: string;
    select: string;
    reset: string;
    close: string;
    loading: string;
    linking: string;
    noResults: (query: string) => string;
    isLinkedTitle: string;
    isLinkedAction: string;
    notLinkedTitle: string;
    notLinkedAction: string;
  };
}

export interface ChannelAddModalState {
  query: string;
}

@observer
export class ChannelAddModal extends React.Component<ChannelAddModalProps, ChannelAddModalState> {
  constructor(props) {
    super(props);
    this.state = { query: '' };
  }

  render() {
    const { styles } = ChannelAddModal;
    const { model, i18n = ChannelAddModal.i18n, onRequestClose, onSelect, onDeselect, onLink } = this.props;
    const { query } = this.state;

    return (
      <ScrollableOverlay className={styles.wrapper} show={model.show} onRequestClose={onRequestClose}>
        <div className={overlayStyles.header} style={{ padding: '0.3rem 0 1rem' }}>
          <h3 className={overlayStyles.title}>Connect channel</h3>
        </div>

        {!model.selection && (
          <>
            <div className={styles.imgContainer}>
              <img src={GoogleAuthIllustration} alt="Google Authentication Illustration" />
            </div>
            <p>
              Please tick all boxes on the permissions page when given the choice. Without these permissions, Lickd
              cannot find and squash claims on your behalf on YouTube. Don&apos;t worry, we cannot change or update
              anything in your YouTube account.{' '}
              <a
                className={styles.link}
                target="_blank"
                rel="noreferrer"
                href={'https://help.lickd.co/knowledge/why-do-you-need-to-see-my-youtube-channels-analytics-and-data'}
              >
                Learn more
                <ExternalLink className={styles.icon} size={14} color={rebrand.contrast[40].toHexString()} />
              </a>
            </p>
          </>
        )}
        {!model.selection && (
          <div className={styles.googleButton}>
            <GoogleButton href={model.youtubeChannelLoginURL} text="Sign up with Google" />
          </div>
        )}
      </ScrollableOverlay>
    );
  }

  static i18n = {
    title: 'Add channel',
    titleSignIn: 'Sign in to add channel',
    titleSearch: 'Search for channel',
    placeholder: 'Search by YouTube channel name or ID',
    submit: 'Search for channels',
    select: 'Select',
    close: 'Close',
    reset: 'Reset selection',
    loading: 'Searching for channels...',
    strap:
      "Looking today to license for a different YouTube Channel? Sign-in below with the channel you'd like to license with.",
    noResults: (query: string) => `No channels found for "${query}"`,
    isLinkedTitle: 'This channel is on Lickd and can be added to your account.',
    isLinkedAction: 'Add to account',
    linking: 'Adding to account...',
    notLinkedTitle: 'This channel needs to be connected with Lickd in order to be added to your account.',
    notLinkedAction: 'Connect to channel',
  };

  static styles = {
    wrapper: style({ padding: px(16), width: percent(100), display: 'block', maxWidth: px(440) }),
    googleButton: style(flexRoot, centerCenter),
    imgContainer: style({
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    }),
    icon: style({
      verticalAlign: 'middle',
      marginLeft: '2px',
    }),
    link: style({
      color: rebrand.contrast[40].toHexString(),
      textDecoration: 'underline',
    }),
  };
}
