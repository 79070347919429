import { scrollY, someChildWillScroll } from 'csstips';
import { px, rem } from 'csx';
import { extend, style } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { ModalContainerStyle, ModalChildStyle, ModalPadding } from './modal';
import { zModal } from '../theme/z';
import { borderRadius } from '../theme/border';
import { fontBebasNeue } from '../theme/font';
import { mediaSmallTablet } from '../components/project-happy/utilities/mediaQueries';

export const ScrollableOverlayContainerStyle: NestedCSSProperties = extend({
  ...ModalContainerStyle,
  textAlign: 'left',
  zIndex: zModal,
});

export const ScrollableOverlayMobileOffset = rem(4);
export const ScrollableOverlayTabletOffset = rem(5);
export const ScrollableOverlayMobileMaxHeight = `(100% - ${ScrollableOverlayMobileOffset})`;
export const ScrollableOverlayTabletMaxHeight = `(100% - ${ScrollableOverlayTabletOffset})`;
export const ScrollableOverlayWrapperStyle: NestedCSSProperties = {
  ...ModalChildStyle,
  ...someChildWillScroll,
  padding: '24px',
  borderRadius: borderRadius.LARGE,
  width: 'calc(100% - 2rem)',
  maxWidth: px(600),
  maxHeight: `calc${ScrollableOverlayMobileMaxHeight}`,
  position: 'relative',
  ...mediaSmallTablet({
    maxHeight: `calc${ScrollableOverlayTabletMaxHeight}`,
    marginBottom: rem(2.5),
    padding: '32px',
  }),
};

export const ScrollableOverlayCloseButtonStyle: NestedCSSProperties = {
  position: 'absolute',
  width: px(32),
  height: px(32),
  right: rem(1),
  top: rem(1),
  zIndex: 1,
};

export const ScrollableOverlayHeaderStyle: NestedCSSProperties = {
  fontFamily: fontBebasNeue,
  padding: '0 40px 16px',
  flexShrink: 0,
  textAlign: 'center',
  ...mediaSmallTablet({
    paddingBottom: '24px',
  }),
};

export const ScrollableOverlayTitleStyle: NestedCSSProperties = {
  margin: '0',
  fontFamily: fontBebasNeue,
  fontSize: '24px',
  lineHeight: 1.1,
};

export const ScrollableOverlayBodyStyle: NestedCSSProperties = {
  ...scrollY,
  ...ModalPadding,
  flexShrink: 4,
};

export const ScrollableOverlayFooterStyle: NestedCSSProperties = {
  ...ModalPadding,
};

export default {
  container: style(ScrollableOverlayContainerStyle),
  wrapper: style(ScrollableOverlayWrapperStyle),
  closeButton: style(ScrollableOverlayCloseButtonStyle),
  header: style(ScrollableOverlayHeaderStyle),
  title: style(ScrollableOverlayTitleStyle),
  body: style(ScrollableOverlayBodyStyle),
  footer: style(ScrollableOverlayFooterStyle),
};
