import * as React from 'react';
import {classes, style} from 'typestyle';
import {FormFieldOption, FormFieldProps} from '../types/form';
import {BillingAddress} from '../types';
import {FormInput} from './form-input';

export interface FormInputAddressProps extends FormFieldProps {

}

export class FormInputAddress extends React.Component<FormInputAddressProps, any> {
  render() {
    const { options = [], value, disabled } = this.props;
    const current = this.findCurrent(options, value);
    const className = classes(FormInput.styles.input, FormInput.styles.select, current === -1 && FormInput.styles.inputEmpty);

    return (
      <select id={this.props.id} value={current} onChange={this.handleChange} className={className} disabled={disabled}>
        <option value="-1">{this.props.placeholder}</option>
        {this.renderOptions(options)}
      </select>
    )
  }

  renderOptions = (options: Array<FormFieldOption>) => {
    return options.map((option, index) => (
      <option key={index} value={index}>{option.label}</option>
    ))
  };

  handleChange = (event: React.FormEvent<any>) => {
    const index = parseInt((event.target as HTMLSelectElement).value);
    const option = this.props.options[index];

    this.props.onChange(this.props.name, option && option.value || null);
  };

  findCurrent = (options: Array<FormFieldOption>, value: BillingAddress) => {
    if (!value || !value) return -1;

    return options.findIndex(o => this.isEqual(o.value, value));
  };

  isEqual = (a: BillingAddress, b: BillingAddress) => {
    return Object.keys(a).every(key => a[key] === b[key]);
  }
}