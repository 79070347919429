import * as React from 'react';
import { Component, deps, inject, observer } from '../../lib/component';
import { HookIt } from '../hook-it';
import { TrackSchema } from '../../types/schema';
import { getCountryCode } from '../../lib/helpers';
import { isEmpty } from '../project-happy/utilities/objects';
import { StorageModel } from '../../modules/storage/model';
import { isDate, differenceInHours } from 'date-fns';

export interface AddRemoveCompatProps {
  size?: number;
  className?: string;
  track: TrackSchema;
  removeOnly?: boolean;
  invert?: boolean;
  withLabel?: boolean;
  withLicense?: boolean;
  reverse?: boolean;
  firedFromTrackCarousel?: boolean;
  i18n?: {
    added?: string;
    hookd: string;
    unhookd: string;
    license: string;
  };
}

@inject(deps)
@observer
export class AddRemoveCompat extends Component<AddRemoveCompatProps> {
  render() {
    const {
      className,
      track,
      invert,
      removeOnly,
      size,
      reverse,
      withLicense,
      i18n = AddRemoveCompat.i18n,
    } = this.props;
    if (!track) return null;

    const { basket, ui } = this.props.model;

    const isHookd = !ui.firstPaint && basket.isInBasket(track);

    const label = withLicense ? (isHookd ? i18n.added : i18n.license) : isHookd ? i18n.hookd : i18n.unhookd;
    return (
      <HookIt
        removeOnly={removeOnly}
        className={className}
        onClick={this.handleClick.bind(this, track, isHookd)}
        isHookd={isHookd}
        isSync={basket.isSyncing(track)}
        size={size}
        label={label}
        showLabel={withLicense}
        reverse={reverse}
        invert={invert}
      />
    );
  }

  /* If the user is unsubscribed and is trying to add a premium track,
   * show the savings reminder modal once every 24 hours
   */
  maybeShowSavingsReminderModal(): void {
    if (this.userIsSubscribed) return;

    const { setShowSavingsReminderModal } = this.props.controller.modal;
    const storeKey = 'LICKD_SAVINGS_REMINDER_SHOWN';
    const currentStoreKeyValue = StorageModel.getItem(storeKey);
    const now = Date.now();

    if (currentStoreKeyValue) {
      const valueAsDate = new Date(currentStoreKeyValue);

      // NOTE: In previous implementations, the store key value was a boolean,
      // so we need to check that it's a valid date/time before comparing it to the current timestamp
      if (isDate(valueAsDate)) {
        const difference = differenceInHours(now, valueAsDate);

        // If the modal has been shown in the past 24 hours, don't show it again
        if (difference < 24) return;
      }
    }

    // Add a short delay so the user has a chance to see the add to basket notification
    setTimeout(() => {
      setShowSavingsReminderModal(true);
      // Save the current timestamp to local storage
      StorageModel.setItem(storeKey, now);

      const { unsubscribedCostFormatted, subscribedCostFormatted } = this.props.model.checkout;
      this.props.controller.analytics.sendMixpanel('Savings reminder modal shown', {
        unsubscribedCost: unsubscribedCostFormatted,
        subscribedCost: subscribedCostFormatted,
      });
    }, 1500);
  }

  handleClick = async (track: TrackSchema, isHookd: boolean, event: React.MouseEvent<any>) => {
    if (isHookd) {
      return this.props.controller.basket.removeFromBasket(track);
    }

    const isStock = track.is_stock_music === true;

    if (isStock && !this.userIsSubscribed) {
      this.props.controller.modal.showBasketUnsubscribedModal();
      return;
    }

    // Check whether the track is unavailable in the user's country
    const country = await getCountryCode(this.props.model);
    const isRestricted = await this.isTrackRestricted(track, country);

    if (isRestricted) {
      this.props.controller.modal.showRestrictedModal();
      this.props.controller.analytics.sendMixpanel('Restricted Track', {
        identity: track.identity,
        title: track.title,
        slug: track.slug,
        country: country,
      });
      return;
    }
    // From here, we know the user is able to license a track
    // There's only one license type that can be selected, choose it immediately
    this.props.model.modal.licenseTrack = track;

    await this.props.controller.modal.licenseModalSelectRate('standard');

    // MixPanel events
    const { pathname } = this.props.model.router.location;

    if (this.props.firedFromTrackCarousel) {
      this.props.controller.analytics.sendMixpanel('User Adds Track to Basket From Carousel', {
        identity: track.identity,
        title: track.title,
        artist: track.artists[0].name,
        slug: track.slug,
        fromPage: pathname,
      });
    }

    this.props.controller.analytics.sendMixpanel(`Clicked ${isStock ? 'Included' : 'Premium'} license`, {
      identity: track.identity,
      title: track.title,
      slug: track.slug,
      fromPage: pathname,
    });

    if (!isStock) {
      this.maybeShowSavingsReminderModal();
    }
  };

  isTrackRestricted = async (track: TrackSchema, country: string) => {
    const { env } = this.props.model;

    const response = await fetch(env.apiUri + '/catalog/track/' + track.identity + '/available_in', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'User-Agent': env.userAgent,
      },
      body: JSON.stringify({
        country: country,
      }),
      mode: 'cors',
    });

    const { data } = await response.json();
    return data.restricted;
  };

  get userIsSubscribed(): boolean {
    const { user } = this.props.model;
    return !isEmpty(user.user) && (user.isSubscribed || user.isSubscribedWithoutChannel) ? true : false;
  }

  static i18n = {
    hookd: 'Remove from basket',
    unhookd: 'Add to basket',
    license: 'License',
    added: 'Added',
  };
}
