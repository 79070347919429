import * as React from 'react';
import { Voucher } from './Voucher';
import { CreditSchema } from '../../../types/schema';
import { toDateString } from '../utilities/date';

interface TrackCreditProps {
  credit: CreditSchema;
}

export const TrackCredit: React.FC<TrackCreditProps> = ({ credit }) => {
  const createdAt = toDateString(credit.created_at);
  const expiry = toDateString(credit.expires_at);

  return (
    <Voucher
      type="credit"
      title="Premium track"
      description={`Subscription - ${createdAt}`}
      value="Track credit"
      expiry={expiry}
      usages={1}
    />
  );
};
