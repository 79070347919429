import * as React from 'react';
import { FavouritesCarouselSlice } from '../organisms/slices/FavouritesCarouselSlice';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { ClassicComponentClass } from 'react';
import {
  PrismicBackgroundGradientProps,
  PrismicComponentCommonProps,
  PrismicHeadedCarouselProps,
} from '../utilities/types';

export type PrismicFavouritesCarouselPayload = PrismicHeadedCarouselProps & {
  primary: {
    tracks: boolean;
    artists: boolean;
    playlists: boolean;
  };
};
export type PrismicFavouritesCarouselProps = {
  payload: PrismicFavouritesCarouselPayload;
};

export const PrismicFavouritesCarousel: React.SFC<PrismicFavouritesCarouselProps> = ({
  payload: {
    primary: { carousel_heading, tracks, artists, playlists },
  },
}) => <FavouritesCarouselSlice heading={carousel_heading} tracks={tracks} artists={artists} playlists={playlists} />;
