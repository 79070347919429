import { action, runInAction } from 'mobx';
import { RouterState, RedirectFunction } from 'react-router';
import { PricingPageModel } from './model';
import { APIController } from '../../api/controller';
import { EnvModel } from '../../env/model';
import { PrismicController } from '../../prismic/controller';
import { BugsnagController } from '../../bugsnag/controller';
import { UIController } from '../../ui/controller';
import { ContentModel } from '../../content/model';

export class PricingPageController {
  constructor(
    private model: PricingPageModel,
    private prismic: PrismicController,
    private bugsnag: BugsnagController,
    private env: EnvModel,
    private api: APIController,
    private ui: UIController,
    private content: ContentModel
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    runInAction(() => {
      this.model.loading = true;
      this.ui.setBreadcrumbs([
        {
          path: '/pricing',
          label: 'Pricing',
        },
      ]);
    });

    await this.ui.setSEO(nextState.location.pathname);

    const document = 'pricing';

    try {
      const [{ data }, ratecards] = await Promise.all([
        this.prismic.getSingle(document),
        this.api.pricing.getRateCards(),
        this.env.ready,
      ]);

      runInAction(() => {
        this.model.ratecards = ratecards.data;
        this.model.video_id = data.video_id;

        // RichText
        this.model.page_title = data.page_title;
        this.model.page_body = data.page_body;

        // Image
        this.model.cover_image = data.cover_image.url;

        this.model.benefits = this.content.benefits;
        this.model.pricingImage = this.content.pricingBackground;
      });
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      runInAction(() => {
        this.model.loading = false;
      });
    }

    return;
  };

  @action
  selectRateType = async (type: string) => {
    const rateType = this.model.ratecardTypes.find((t) => t.value === type);
    if (!rateType) return;

    this.model.ratecardLoading = true;

    try {
      const ratecards = await this.api.pricing.getRateCards();

      runInAction(() => {
        this.model.ratecards = ratecards.data;
        this.model.ratecardType = type;
      });
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      runInAction(() => {
        this.model.ratecardLoading = false;
      });
    }
  };
}
