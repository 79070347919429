import * as React from 'react';
import { ModalProps } from '../../modal';
import { style } from 'typestyle';
import { flexRoot } from 'csstips';
import { px, rem } from 'csx';
import { rebrand } from '../../../theme/color';
import { ScrollableOverlay } from '../../scrollable-overlay/scrollable-overlay';
import overlayStyles, { ScrollableOverlayBodyStyle } from '../../../styles/scrollable-overlay';
import { mediaMobileOnly } from '../utilities/mediaQueries';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { Component, deps, inject, observer } from '../../../lib/component';
import { ACCOUNT_PLANS_ROUTE } from '../../../constants';
import { ActionButton } from '../atoms/button/ActionButton';

const styles = {
  wrapper: style({
    maxWidth: px(440),
  }),
  body: style(ScrollableOverlayBodyStyle, {
    padding: '24px 0 0',
    borderTop: `1px solid ${rebrand.light1.toString()}`,
    textAlign: 'center',
    $nest: {
      '& > p': {
        margin: '0 0 24px',
      },
    },
  }),
  textSM: style({ fontSize: px(12) }),
  actions: style(
    flexRoot,
    {
      justifyContent: 'center',
      flexDirection: 'row-reverse',
      gap: rem(1),
    },
    mediaMobileOnly({
      flexDirection: 'column',
      gap: rem(0.5),
      width: '100%',
      paddingBottom: rem(0.5),
    })
  ),
};

type SavingsReminderModalProps = ModalProps;

@inject(deps)
@observer
export class SavingsReminderModal extends Component<SavingsReminderModalProps> {
  constructor(props) {
    super(props);

    this.onReject = this.onReject.bind(this);
    this.onAccept = this.onAccept.bind(this);
  }

  onReject = (): void => {
    this.props.onRequestClose();

    const { unsubscribedCostFormatted, subscribedCostFormatted } = this.props.model.checkout;

    this.props.controller.analytics.sendMixpanel('Savings reminder modal offer rejected', {
      unsubscribedCost: unsubscribedCostFormatted,
      subscribedCost: subscribedCostFormatted,
    });
  };

  onAccept = (): void => {
    this.props.onRequestClose();
    this.props.model.router.push(ACCOUNT_PLANS_ROUTE);

    const { unsubscribedCostFormatted, subscribedCostFormatted } = this.props.model.checkout;

    this.props.controller.analytics.sendMixpanel('Savings reminder modal offer accepted', {
      unsubscribedCost: unsubscribedCostFormatted,
      subscribedCost: subscribedCostFormatted,
    });
  };

  render() {
    const { onRequestClose, model, ...props } = this.props;

    const {
      subscription: { trialLengthInDays },
    } = model;

    return (
      <ScrollableOverlay className={styles.wrapper} onRequestClose={onRequestClose} {...props}>
        <div className={overlayStyles.header}>
          <h3 className={overlayStyles.title}>Save on Premium tracks</h3>
        </div>

        <div className={styles.body}>
          <p>
            <strong>
              Get premium track credits included with a Lickd subscription. Plus, enjoy unlimited royalty-free music,
              all for less than the cost of a single track!
            </strong>
          </p>
          <p>Credits last for 90 days and you can choose a plan that suits your needs.</p>
          <div className={styles.actions}>
            <ActionButton
              data-test-wait-sign-up-button
              prominence={ButtonProminence.PRIMARY}
              size={ButtonSize.MEDIUM}
              onClick={this.onAccept}
            >
              View plans
            </ActionButton>
            <Button
              data-test-wait-continue-button
              prominence={ButtonProminence.SECONDARY}
              size={ButtonSize.MEDIUM}
              onClick={this.onReject}
            >
              No, thanks
            </Button>
          </div>
        </div>
      </ScrollableOverlay>
    );
  }
}
