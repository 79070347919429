import { NestedCSSProperties } from 'typestyle/lib/types';
import { colorCrimson, colorDarkBlueGrey, colorLightBlueGrey, colorGunmetal, rebrand } from '../../../theme/color';
import { px } from 'csx';
import { extend } from 'typestyle';
import { SESSION_ONBOARDING_VALUES } from '../../../constants';
import { isEmpty } from './objects';

const border = `1px solid ${rebrand.neutralOnDark[20].toHexString()}`;
export const baseInputStyles: NestedCSSProperties = {
  border: '1px solid transparent',
  borderRadius: px(4),
  background: rebrand.neutralOnDark[10].toHexString(),
  color: colorGunmetal.toHexString(),
  padding: px(8),
  $nest: {
    '&.invalid': {
      borderColor: colorCrimson.toHexString(),
    },
  },
};

export const commonInputStyles: NestedCSSProperties = extend(baseInputStyles, {
  borderLeft: border,
  borderTop: border,
  fontFamily: 'inherit',
  $nest: {
    '&:focus': {
      outline: `1px solid ${rebrand.contrast[100].toString()}`,
    },
    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
});

export const dropdownInputStyles: NestedCSSProperties = extend(commonInputStyles, {
  '-webkit-appearance': 'none',
  '-moz-appearance': 'none',
  appearance: 'none',
});

export type AdditionalInputProps = {
  isValid?: boolean;
};

type OnboardingSessionValues = { termsCheckbox?: boolean };
let onboardingSessionValues: OnboardingSessionValues = {};
export const getOnboardingSessionValues = (): OnboardingSessionValues => {
  if (isEmpty(onboardingSessionValues)) {
    try {
      Object.assign(onboardingSessionValues, JSON.parse(window.sessionStorage.getItem(SESSION_ONBOARDING_VALUES)));
    } catch {
      // State is corrupt or doesn't exist
    }
  }
  return onboardingSessionValues;
};

export const setOnboardingSessionValues = (newValues: OnboardingSessionValues) => {
  onboardingSessionValues = Object.assign(onboardingSessionValues, newValues);
  window.sessionStorage.setItem(SESSION_ONBOARDING_VALUES, JSON.stringify(onboardingSessionValues));
};
