import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalChildStyle } from './modal';
import { classes, style } from 'typestyle';
import { colorError } from '../theme/color';
import { em, important, percent, px } from 'csx';
import { TextNoOrphan } from './text-no-orphan';
import { ButtonPill } from './button-pill';
import { FormInput } from './form-input';
import {FormField, FormFieldType} from '../types/form';

const PrismicDOM = require('prismic-dom');

export interface DeleteAccountModalModel {
  show: boolean;
  loading: boolean;
  saving: boolean;
  error: string;
  page_body: any;
}

export interface DeleteAccountModalProps {
  onRequestClose(): void;
  onDeleteAccount(reason: string, recommend: string): void;
  model: DeleteAccountModalModel;
  i18n?: {
    title: string;
    loading: string;
    submit: string;
    saving: string;
    close: string;
  }
}

export interface DeleteAccountModalState {
  understood: FormField<boolean>;
  reason: FormField<string>;
  recommend: FormField<string>;
}

@observer
export class DeleteAccountModal extends React.Component<DeleteAccountModalProps, DeleteAccountModalState> {
  state = {
    understood: {
      label: 'I understand that this is irreversible and wish to delete my account',
      name: 'understood',
      value: false,
      type: 'checkbox' as FormFieldType
    },
    reason: {
      label: 'Why are you leaving us?',
      name: 'reason',
      value: '',
      type: 'text' as FormFieldType
    },
    recommend: {
      label: 'How likely are you to recommend us?',
      name: 'recommend',
      value: '',
      type: 'text' as FormFieldType
    }
  };

  render() {
    const {
      onRequestClose,
      model,
      i18n = DeleteAccountModal.i18n,
    } = this.props;

    const {
      understood,
      reason,
      recommend
    } = this.state;

    return (
      <Modal show={model.show} onRequestClose={onRequestClose}>
        <div className={DeleteAccountModal.styles.container}>
          <h3 className={DeleteAccountModal.styles.heading}>
            <TextNoOrphan value={i18n.title} />
          </h3>
          {model.loading && (
            <div className={DeleteAccountModal.styles.content}>
              <span>{i18n.loading}</span>
            </div>
          )}
          {!model.loading && (
            <div
              className={DeleteAccountModal.styles.content}
              dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(model.page_body) }}
            />
          )}
          {!model.loading && (
            <div className={DeleteAccountModal.styles.actions}>
              <FormInput model={understood} onChange={this.handleChange}/>
              {understood.value && (<FormInput model={reason} onChange={this.handleChange}/>)}
              {understood.value && (<FormInput model={recommend} onChange={this.handleChange}/>)}
              <ButtonPill onClick={this.handleSubmit} disabled={model.saving || !this.state.understood.value} className={classes(DeleteAccountModal.styles.submit, ButtonPill.styles.danger)}>
                {model.saving ? i18n.saving : i18n.submit}
              </ButtonPill>
            </div>
          )}
          {model.error && (
            <div className={DeleteAccountModal.styles.error}>
              {model.error}
            </div>
          )}
          <div className={DeleteAccountModal.styles.buttons}>
            <ButtonPill onClick={onRequestClose}>
              {i18n.close}
            </ButtonPill>
          </div>
        </div>
      </Modal>
    )
  }

  handleChange = (field, value) => {
    this.setState(state => ({ ...state, [field]: { ...state[field], value } }));
  };

  handleSubmit = (event) => {
    if (this.props.model.saving || !this.state.understood.value) return;

    this.props.onDeleteAccount(this.state.reason.value, this.state.recommend.value);
  };

  static i18n = {
    title: 'Delete my account',
    loading: 'Loading...',
    submit: 'Delete my account',
    saving: 'Deleting...',
    close: 'Close'
  };

  static styles = {
    container: style({
      ...ModalChildStyle,
      width: px(640),
      position: 'relative',
    }),
    heading: style({
      color: important(colorError.toString()),
      fontSize: px(30),
      margin: '0 0 30px 0',
      flexShrink: 0,
    }),
    content: style({
      flex: 1,
      overflow: 'auto',
      lineHeight: em(1.5),
    }),
    actions: style({
      margin: '30px 0 0 0',
      flexShrink: 0,
    }),
    submit: style({
      marginTop: px(20),
      width: percent(100)
    }),
    error: style({
      lineHeight: em(1.5),
      fontSize: px(14),
      color: colorError.toString(),
      margin: '30px 0 0 0',
      flexShrink: 0,
    }),
    buttons: style({
      margin: '30px 0 0 0',
      textAlign: 'right',
      flexShrink: 0,
    })
  }
}