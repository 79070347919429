import * as React from 'react';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { classes, style } from 'typestyle';
import { px, viewHeight } from 'csx';
import { Payg } from '../organisms/plans/Payg';
import { Subscription } from '../organisms/plans/Subscription';
import planStyles from '../organisms/plans/styles';
import { centerCenter, centerJustified, flexRoot, vertical, wrap } from 'csstips';
import { heading3 } from '../utilities/font';
import { fontstackDefault, fontstackDisplay } from '../config';
import { PricingModal } from '../organisms/PricingModal';
import { SignUpToUnlockBlock } from '../molecules/SignUpToUnlockBlock';
import { colorGunmetal } from '../../../theme/color';

const styles = {
  wrapper: style({
    minHeight: viewHeight(100),
  }),
  masthead: style(flexRoot, vertical, centerCenter, {
    color: 'white',
    backgroundColor: colorGunmetal.toString(),
    textAlign: 'center',
    minHeight: px(278),
    $nest: {
      '& h1': { ...heading3, fontFamily: fontstackDisplay },
      '& h2': {
        fontFamily: fontstackDefault,
        fontWeight: 400,
        fontSize: px(16),
        letterSpacing: 'normal',
      },
    },
  }),
  loadingSlices: style({ height: viewHeight(100) }),
  loadingData: style({ height: viewHeight(25) }),
  plansWrapper: style(flexRoot, centerJustified, wrap),
  chartPlan: style({}),
  signUpWrapper: style({
    display: 'flex',
    justifyContent: 'center',
    margin: '24px 0px',
  }),
};

export class Pricing extends React.Component {
  state = {
    showBands: false,
  };

  constructor(props) {
    super(props);
    this.handleToggleBands = this.handleToggleBands.bind(this);
  }

  handleToggleBands() {
    this.setState({
      showBands: !this.state.showBands,
    });
  }

  render() {
    return (
      <main className={styles.wrapper}>
        <div className={styles.masthead}>
          <h1>Our Plans</h1>
          {/*<h2>A body style sub heading to go here</h2>*/}
        </div>
        <div className={styles.signUpWrapper}>
          <SignUpToUnlockBlock />
        </div>
        <div className={styles.plansWrapper}>
          <div className={planStyles.planWrapper}>
            <Subscription handleShowAction={this.handleToggleBands} showCaveat={true} />
          </div>
          <div className={classes(planStyles.planWrapper, styles.chartPlan)}>
            <Payg handleAction={this.handleToggleBands} showCaveat={true} />
          </div>
        </div>
        <PricingModal show={this.state.showBands} onRequestClose={this.handleToggleBands} />
      </main>
    );
  }
}
