import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalChildStyle } from './modal';
import { style } from 'typestyle';
import { em, px } from 'csx';
import { TextNoOrphan } from './text-no-orphan';
import { ButtonPill } from './button-pill';
import { center, endJustified, horizontal, horizontallySpaced } from 'csstips';
import { ChannelAvatar } from './channel-avatar';
import {ChannelSchema} from '../types/schema';
import GenericError from './generic-error';

export interface ChannelSelectModalModel {
  channels: Array<ChannelSchema>;
  channel: ChannelSchema;
  activating: ChannelSchema;
  show: boolean;
  loading: boolean;
  selecting: boolean;
}

export interface ChannelSelectModalProps {
  model: ChannelSelectModalModel;
  onRequestClose(): void;
  onSelectChannel(channel: ChannelSchema): void;
  onAdd(): void;
  i18n?: {
    title: string;
    active: string;
    loading: string;
    activating: string;
    choose: string;
    close: string;
    add: string;
  }
}

@observer
export class ChannelSelectModal extends React.Component<ChannelSelectModalProps, any> {
  render() {
    const { onRequestClose, onAdd, model, i18n = ChannelSelectModal.i18n, onSelectChannel } = this.props;
    const { loading, selecting, activating } = model;

    return (
      <Modal show={model.show} onRequestClose={selecting ? void 0 : onRequestClose}>
        <div className={ChannelSelectModal.styles.container}>
          <h3>
            <TextNoOrphan value={i18n.title} />
          </h3>
          <div>
            {loading && (
              <span>{i18n.loading}</span>
            )}
            {!loading && model.channels.map((channel, index) => {
              const isActive = channel.id === model.channel.id;
              const onClick = isActive ? void 0 : onSelectChannel.bind(null, channel);

              return (
                <div key={index.toString()} className={ChannelSelectModal.styles.channel}>
                  <div>
                    <ChannelAvatar
                      channel={channel}
                      className={ChannelSelectModal.styles.avatar}
                    />
                  </div>
                  <div>{channel.name}</div>
                  <div>
                    <ButtonPill muted={isActive} primary={!isActive} disabled={isActive || selecting} onClick={onClick}>
                      {isActive ? i18n.active : channel === activating ? i18n.activating : i18n.choose}
                    </ButtonPill>
                  </div>
                </div>
              )
            })}
            {!loading && !model.channels.length && (
              <GenericError />
            )}
          </div>
          <div className={ChannelSelectModal.styles.buttons}>
            <ButtonPill primary={true} onClick={selecting ? void 0 : this.handleAdd} disabled={selecting}>
              {i18n.add}
            </ButtonPill>
            <ButtonPill onClick={selecting ? void 0 : onRequestClose} disabled={selecting}>
              {i18n.close}
            </ButtonPill>
          </div>
        </div>
      </Modal>
    )
  }

  handleAdd = (event: React.MouseEvent<any>) => {
    this.props.onRequestClose();
    this.props.onAdd();
  };

  static i18n = {
    title: 'Switch channel',
    active: 'Current',
    choose: 'Choose',
    loading: 'Loading channels...',
    activating: 'Activating...',
    close: 'Cancel',
    add: 'Add a channel'
  };

  static styles = {
    container: style({
      ...ModalChildStyle,
      maxWidth: px(640)
    }),
    channel: style({
      ...horizontal,
      ...horizontallySpaced(10),
      ...center,
      padding: '10px 0',
      $nest: {
        '&> div:nth-child(1)': {
          width: px(60)
        },
        '&> div:nth-child(2)': {
          flex: 1,
          lineHeight: em(1.4),
        },
        '&> div:nth-child(3)': {
          width: px(110),
          textAlign: 'right'
        }
      }
    }),
    avatar: style({
      width: px(60),
      height: px(60),
    }),
    buttons: style({
      ...horizontal,
      ...horizontallySpaced(10),
      ...endJustified,
      margin: '30px 0 0 0'
    })
  }
}