/* eslint-disable @typescript-eslint/no-var-requires */
import { action, autorun, IReactionDisposer } from 'mobx';
import { AuthModel } from '../auth/model';
import { EnvModel } from '../env/model';
import { serialiseError } from '../../components/project-happy/utilities/objects';
const Bugsnag = require('bugsnag-js');

export class BugsnagController {
  private bugsnag: any;

  constructor(private env: EnvModel, private auth: AuthModel) {
    this._disposers = [];

    if (env.appEnv === 'local') {
      Bugsnag.disableAutoBreadcrumbsConsole();
    }

    if (!env.isServer && env.bugsnagKey) {
      Bugsnag.apiKey = env.bugsnagKey;
      Bugsnag.releaseStage = env.appEnv;
      Bugsnag.appVersion = env.version;

      this._disposers.push(
        autorun((): void => {
          if (!auth.user) {
            return (Bugsnag.user = void 0);
          }

          Bugsnag.user = {
            id: auth.user.identity,
            name: auth.user.name,
            email: auth.user.email,
          };
        })
      );

      this.bugsnag = Bugsnag;
    }
  }

  private _disposers: IReactionDisposer[];

  dispose = () => {
    this._disposers.forEach((dispose) => dispose());
  };

  @action
  refresh = () => {
    if (this.bugsnag) this.bugsnag.refresh();
  };

  @action
  notifyException = (e: any) => {
    if (this.bugsnag) {
      Bugsnag.notifyException(e, `CLIENT ERROR - ${serialiseError(e)}`);
    } else {
      console.error('BUGSNAG', serialiseError(e));
      throw e;
    }
  };

  /**
   * notifyExceptionSafely will NOT rethrow errors when Bugsnag is unavailable, unlike notifyException.
   * This is useful when reacting to errors which will otherwise cause an app crash
   * @param e The error to notify Bugsnag with
   */
  @action
  notifyExceptionSafely = (e: any) => {
    try {
      this.notifyException(e);
    } catch (error) {
      console.debug('A request to notify Bugsnag was made while Bugsnag was unavailable.', error);
      // Purposely swallow the error
    }
  };
}
