import * as React from 'react';
import { observer } from 'mobx-react';
import { style } from 'typestyle';
import { colorContrast, colorGunmetal, colorWhite } from '../theme/color';
import { mediaTablet, mediaMobileOnly } from '../theme/media';
import { em, percent, px, url, viewHeight, viewWidth } from 'csx';
import { centerCenter, horizontal, horizontallySpaced, vertical, verticallySpaced } from 'csstips';
import { resetAnchorStyles } from '../theme/reset';
import {Mode} from '../types/ui';

export interface AppHeroSearchResultsEmptyProps {
  mode: Mode
}

@observer
export class AppHeroSearchResultsEmpty extends React.Component<AppHeroSearchResultsEmptyProps, any> {
  render() {
    const {mode} = this.props;

    return (
      <div className={AppHeroSearchResultsEmpty.styles.container}>
        <h3>hmmm!</h3>
        <h4>Either you just fell asleep on your keyboard or that’s a {mode} name we don’t recognise.</h4>
        {mode === 'artist' && (
          <p>If it’s the latter, send us a link about them on <a className={AppHeroSearchResultsEmpty.styles.link} href="mailto:hello@lickd.co">hello@lickd.co</a> and we’ll try and get them for you</p>
        )}
      </div>
    )
  }

  static styles = {
    link: style({
      color: colorWhite.toString(),
      $nest: {
        ...resetAnchorStyles(colorWhite.toString())
      }
    }),
    container: style(
      {
        ...vertical,
        ...verticallySpaced(20),
        borderRight: `2px solid ${colorWhite.fade(0.2).toString()}`,
        flexShrink: 0,
        padding: px(10),
        position: 'relative',
        $nest: {
          '&> div': {
            flexShrink: 0,
            borderRadius: percent(50),
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          },
          '&> h3': {
            margin: 0,
            fontSize: px(44),
            color: colorWhite.toString(),
          },
          '&> h4': {
            margin: 0,
            fontSize: px(24),
            color: colorWhite.toString(),
          },
          '&> p': {
            margin: 0,
            color: colorWhite.toString(),
            flex: 1,
            lineHeight: em(1.4)
          }
        }
      },
      mediaMobileOnly({
        width: viewWidth(75),
        maxWidth: viewHeight(100),
        height: viewWidth(75),
        maxHeight: viewHeight(60),
        $nest: {
          '&> div': {
            width: viewWidth(20),
            maxWidth: viewHeight(20),
            height: viewWidth(20),
            maxHeight: viewHeight(20)
          },
        }
      }),
      mediaTablet({
        width: viewWidth(20),
        minWidth: px(360),
        height: viewWidth(20),
        minHeight: px(240),
        $nest: {
          '&> div': {
            width: viewWidth(5),
            minWidth: px(60),
            height: viewWidth(5),
            minHeight: px(60),
          },
        }
      })
    )
  }
}