import React from 'react';
import EmptyTile from './carousel/empty-tile';
import { FavouriteCTA } from './carousel/favourite-cta';
import { ButtonPill } from './button-pill';
import { style } from 'typestyle';
import { percent, px, viewWidth } from 'csx';
import { mediaDesktop, mediaMobileOnly, mediaTablet } from '../theme/media';
import { overlayLink } from '../theme/mixin';

const styles = {
  heading: style({
    margin: '30px 0',
  }),
  empty: style({
    position: 'relative',
  }),
  emptyTile: style(
    {
      width: percent(100),
      minHeight: px(225),
    },
    mediaMobileOnly({
      height: 'calc(100vw - 40px)',
    }),
    mediaDesktop({
      height: viewWidth(20),
      minHeight: px(258),
    })
  ),
  emptyCta: style(
    {
      height: percent(60),
      marginBottom: percent(10),
    },
    mediaTablet({
      padding: percent(10),
    }),
    mediaDesktop({
      height: percent(50),
      marginTop: percent(20),
    })
  ),
  emptyButton: style(overlayLink, {
    height: px(32),
    lineHeight: px(32),
  }),
};

export interface PageAccountFavouritesListingProps {
  className?: string;
  title: string;
  isEmpty: boolean;
  emptyText: string;
  emptyHref: string;
  emptyCta: string;
  onClickLink(event: React.MouseEvent<any>): void;
}

export default class PageAccountFavouritesListing extends React.Component<PageAccountFavouritesListingProps> {
  render() {
    const { className, title, isEmpty, emptyText, emptyHref, emptyCta, onClickLink, children } = this.props;

    return (
      <div className={className}>
        <h2 className={styles.heading}>{title}</h2>
        {isEmpty && (
          <div data-test-no-favourites={className} className={styles.empty}>
            <EmptyTile className={styles.emptyTile} />
            <FavouriteCTA className={styles.emptyCta} ctaText={emptyText}>
              <ButtonPill className={styles.emptyButton} href={emptyHref} onClick={onClickLink}>
                {emptyCta}
              </ButtonPill>
            </FavouriteCTA>
          </div>
        )}
        {!isEmpty && children}
      </div>
    );
  }
}
