import * as React from 'react';
import { Component, inject, deps, observer } from '../../lib/component';
import { AccountOrderDetails } from './order-details';
import { ChannelSchema, OrderSchema } from '../../types/schema';
import { style } from 'typestyle';
import { percent } from 'csx';
import { spacing } from '../../theme/spacing';
import { OrderLicence } from '../project-happy/molecules/OrderLicence';
import { ChannelBanner } from '../project-happy/molecules/ChannelBanner';

export interface AccountOrderProps {
  order: OrderSchema;
  channels: Array<ChannelSchema>;
}

const styles = {
  channelBanner: style({
    marginBottom: spacing.XLARGE,
  }),
  licenceList: style({
    position: 'relative',
    width: percent(100),
  }),
};

@inject(deps)
@observer
export class AccountOrder extends Component<AccountOrderProps> {
  render() {
    const { order, channels } = this.props;
    const { projectSubscriptionEnabled } = this.props.model.ui;

    // Get the channel attached to this order (it may not be the same as the currently selected channel)
    const channel = channels.length ? channels[0] : null;

    if (!order) return null;

    return (
      <>
        <ChannelBanner className={styles.channelBanner} channelOverride={channel} hideChangeBtn />

        <AccountOrderDetails order={order} showCredits={projectSubscriptionEnabled} />

        {order.licences.length && (
          <div className={styles.licenceList}>
            {order.licences.map((licence) => (
              <OrderLicence licence={licence} key={licence.identity} />
            ))}
          </div>
        )}
      </>
    );
  }
}
