import { PlaylistGroupPlaylistSchema, TrackSchema, ArtistSchema } from '../../../types/schema';
import { AnyObject, isArray, isEmpty } from './objects';
import { ObservableMap } from 'mobx';

export enum SemanticInformationType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export enum GradientDirection {
  HORIZONTAL = 'Left to right',
  VERTICAL = 'Top to bottom',
  DIAGONAL_UP = 'Top left to bottom right',
  DIAGONAL_DOWN = 'Bottom left to top right',
}

export type CatalogueDataArray = TrackSchema[] | PlaylistGroupPlaylistSchema[] | ArtistSchema[];

export type SlicePageContext = {
  playlists: ObservableMap<string, CatalogueDataArray>;
  tracks: ObservableMap<string, TrackSchema>;
  carousels?: { forceIndicatorPosition?: 'centre'; forceHideActions?: true };
  externalDataLoading?: boolean;
};

export type PrismicImage = {
  dimensions: { width: number; height: number };
  alt: string | null;
  copyright: string | null;
  url: string;
};
export type PrismicURL = {
  link_type: 'Web';
  url: string;
};

type PrismicRichTextContentComponentName =
  | 'paragraph'
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heading4'
  | 'heading5'
  | 'heading6'
  | 'strong'
  | 'em'
  | 'span'
  | 'div'
  | 'list-item'
  | 'o-list-item'
  | 'hyperlink';
export type PrismicRichTextComponentName =
  | PrismicRichTextContentComponentName
  | 'fragment' // Not used by Prismic, but used for React Fragment wrapping
  | 'image'; // Not flow content so has different characteristics to content components
export type PrismicRichTextSpan = {
  type: PrismicRichTextComponentName;
  start: number;
  end: number;
  data?: { [key: string]: string };
};
export type PrismicRichTextImageElement = { type: 'image' } & PrismicImage;
export type PrismicRichTextContentElement = {
  type: PrismicRichTextContentComponentName;
  text: string;
  spans: PrismicRichTextSpan[];
};
export type PrismicRichTextElement = PrismicRichTextImageElement | PrismicRichTextContentElement;
export type PrismicRichText = PrismicRichTextElement[];

export enum PrismicSlices {
  MASTHEAD = 'masthead',
  TRUANT_MASTHEAD = 'truant_masthead',
  HOMEPAGE_MASTHEAD = 'logged_out_masthead',
  CREATOR_DASHBOARD_MASTHEAD = 'logged_in_masthead',
  DASHBOARD_TEASER_MASTHEAD = 'dashboard_teaser_masthead',
  BROWSE_MASTHEAD = 'browse_masthead',
  HEADLINE = 'headline_statement',
  CREATOR_PANEL = 'creator_stat_panel',
  CREATOR_PANEL_RICHTEXT = 'creator_stat_panel___text',
  ARTIST_PANEL = 'artist_feature_panel',
  ARTIST_PANEL_RICHTEXT = 'artist_feature_panel___text',
  TRACK_PLAYLIST = 'track_playlist',
  PROMO_PANEL = 'promotional_panel',
  PROMO_PANEL_RICHTEXT = 'promotional_panel___text',
  YOUTUBE_PANEL = 'youtube_panel',
  YOUTUBE_PANEL_RICHTEXT = 'youtube_panel___text',
  PARTNERS = 'partner_labels',
  HEADLINE_CTA = 'headline_with_call_to_action',
  CREATOR_TESTIMONIAL = 'creator_testimonial',
  PARTNER_BADGE = 'partner_badge',
  STATEMENT_PANEL = 'statement_panel',
  STATEMENT_PANEL_RICHTEXT = 'statement_panel___text',
  CTA_BUTTON = 'call_to_action_button',
  TRACK_CAROUSEL = 'track_carousel',
  YOUTUBE_TRACK_CAROUSEL = 'youtube_video_track_carousel',
  PLAYLIST_CAROUSEL = 'playlist_carousel',
  LINK_CAROUSEL = 'link_carousel',
  TOP_PICKS_CAROUSEL = 'top_picks_carousel',
  FAVOURITES_CAROUSEL = 'favourites_carousel',
  SLICE_GROUP_HEADING = 'slice_group_heading',
  CATALOGUE_CAROUSEL = 'catalogue_carousel',
  HOW_IT_WORKS = 'how_it_works',
  TEXT_PANEL = 'text_panel',
  PLANS = 'plans',
  CATALOGUE_TILES = 'catalogue_tiles',
  LINK_TILES = 'link_tiles',
  PROMO_BANNER = 'promotional_banner',
  VERTICAL_QUESTIONNAIRE = 'vertical_questionnaire',
  ANNOUNCEMENT = 'announcement',
  PERSONALISED_CAROUSEL = 'personalised_carousel',
  LIST_ITEM_TEXTS = 'list_item_text',
  HOW_IT_WORKS_REFEREE = 'how_it_works_referee',
  PRICING_IMAGE = 'pricing_images',
  DASHBOARD_HEADER = 'dashboard_header',
}

export enum PrismicSliceCSSClasses {
  MASTHEAD = 'masthead',
  CAROUSEL_SLICE = 'carousel-slice',
  TILE_SLICE = 'tiles-slice',
  TWO_COLUMN_SLICE = 'two-column-slice',
}

export type CommonPrismicSlice<T = AnyObject, P = AnyObject> = {
  slice_type: string;
  primary: T;
  items: P[];
};
export type NamedPrismicSlice<T, P = CommonPrismicSlice> = P & {
  slice_type: T;
};
export type PrismicSlice<T = AnyObject, P = AnyObject> = CommonPrismicSlice<T, P> & {
  slice_type: PrismicSlices;
};
export const isPrismicSlice = <T extends PrismicSlice>(obj: PrismicSlice, sliceType: PrismicSlices): obj is T =>
  obj.slice_type === sliceType;
export type PrismicBackgroundColourProps = PrismicSlice<{
  background_colour?: string;
}>;
export type PrismicSimpleBackgroundProps = PrismicBackgroundColourProps &
  PrismicSlice<{
    background_image?: PrismicImage;
  }>;
type GradientBackgroundProps = PrismicSlice<{
  background_gradient_colour_end?: string;
  background_gradient_colour_direction: GradientDirection;
}>;
export type PrismicBackgroundGradientProps = PrismicBackgroundColourProps & GradientBackgroundProps;
export type PrismicComplexBackgroundProps = PrismicSimpleBackgroundProps & GradientBackgroundProps;
export type PrismicCallToActionProps = PrismicSlice<{
  call_to_action_url?: PrismicURL;
  call_to_action_text: string | null;
}>;
export type PrismicYouTubeChannelFields = {
  channel_name?: string;
  channel_subscribers: number;
  channel_profile_image?: PrismicImage;
};
export type PrismicYouTubeChannelProps = PrismicSlice<PrismicYouTubeChannelFields>;
export type PrismicSimplePlaylistProps = PrismicSlice<{ playlist_heading: string; playlist_slug: string }>;
export type PrismicManualLinkListItemProps = PrismicComplexBackgroundProps & {
  tile_heading: string;
  link_pathname: string;
};
export type PrismicManualLinkListProps = {
  primary: {
    action_href?: string;
  };
  items: PrismicManualLinkListItemProps[];
};
export enum PrismicDataDrivenTypes {
  TRACKS = 'Tracks',
  PLAYLISTS = 'Playlists',
  ARTISTS = 'Artists',
}
export type PrismicHeadedCarouselProps = PrismicSlice<{ carousel_heading: string }>;
export enum PrismicTileShape {
  SMALL = 'Small square',
  LARGE_RECTANGLE = 'Large rectangle',
  LARGE_SQUARE = 'Large square',
}
export type PrismicSizeConfigurableCarouselProps = PrismicSlice<{ tile_shape: PrismicTileShape }>;
export type PrismicDataDrivenCarouselProps = PrismicSlice<{
  carousel_slug: string;
  action_override: string;
  action_visibility: boolean;
}>;
export type PrismicTypedCarouselProps = PrismicSlice<{ carousel_type: PrismicDataDrivenTypes }>;
export type PrismicStockLabelProps = PrismicSlice<{ is_stock?: boolean }>;

export type PrismicHeadedTileGridProps = PrismicSlice<{ tiles_heading: string }>;
export type PrismicDataDrivenTileGridProps = PrismicSlice<{ tiles_slug: string }>;
export type PrismicTypedTileGridProps = PrismicSlice<{ tiles_type: PrismicDataDrivenTypes }>;
export type PrismicTypedDashboardHeaderProps = {
  cta_link: string;
  cta_text: string;
  dashboard_image: PrismicImage & { mobile: PrismicImage };
  title_tile: string | null;
  title: PrismicRichTextContentElement[];
};

export type PrismicPlaylistTypes = PrismicDataDrivenTypes;
export type PrismicComplexPlaylistProps = PrismicSimplePlaylistProps &
  PrismicSlice<{ playlist_type: PrismicPlaylistTypes }>;
export type PrismicBasePanelProps = PrismicComplexBackgroundProps & PrismicCallToActionProps;
export type PrismicComponentCommonProps = { payload?: Partial<CommonPrismicSlice>; context?: AnyObject };

// Naive type guard for Track objects
export const isTrackObject = (obj: any): obj is TrackSchema =>
  !isEmpty(obj) && isArray(obj.artists) && !isEmpty(obj.audio);
export const isTypeArray = <T>(obj: any, typeGuard: (any) => boolean): obj is T[] =>
  isArray(obj) && (obj.length === 0 || typeGuard(obj[0])); // If the array is empty, pass the check

export type EssentialsSubscriptionUrlPayload = {
  checkout_url: string | null;
};

export type V2SubscriptionUrlPayload = {
  checkout_url: string | null;
};

export enum SubscriptionChangeTypes {
  UPGRADE = 'upgrade',
  DOWNGRADE = 'downgrade',
}

export type V2SubscriptionChangePayload = {
  change_type: SubscriptionChangeTypes;
  checkout_url: string | null;
};

export type EssentialsManagementUrlPayload = {
  portal_url: string;
};

export type EssentialsPlan = {
  price: string;
  currency_symbol: string;
  currency_code: string;
  trial_length_in_days: string;
};

export enum CreditPlanTypes {
  STARTER = 'Starter',
  PLUS = 'Plus',
  PRO = 'Pro',
}

export type V2Plan = {
  currency_code: string;
  currency_symbol: string;
  plan_credits: number;
  plan_name: CreditPlanTypes;
  premium_tracks_price: string;
  price: number;
  stripe_id: string;
  trial_length_in_days: number;
};

export enum SubscriptionStates {
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  TRIAL = 'TRIAL',
  CANCELLED_TRIAL = 'CANCELLED_TRIAL',
  EXPIRED = 'EXPIRED',
  OVERDUE = 'OVERDUE',
  UNKNOWN = 'UNKNOWN',
  DISCOUNTED = 'DISCOUNTED',
}

export const isOngoingSubscription = (status: SubscriptionStates): boolean =>
  status === SubscriptionStates.PAID ||
  status === SubscriptionStates.TRIAL ||
  status === SubscriptionStates.OVERDUE ||
  status === SubscriptionStates.DISCOUNTED;

export const isCancelledSubscription = (status: SubscriptionStates): boolean =>
  status === SubscriptionStates.CANCELLED || status === SubscriptionStates.CANCELLED_TRIAL;

export const getEulaType = ({ reporting_party, is_stock_music }: TrackSchema) => {
  if (reporting_party.slug === 'umg') {
    return 'umg';
  }
  if (is_stock_music === true) {
    return 'essentials';
  }
  return 'lickd';
};

export enum SubscriptionPlans {
  PAID = 'creator',
  PAYG = 'chart',
}

export interface RefereePageSingleDatas {
  big_creators_heading: PrismicRichText;
  big_creators_image: PrismicImage;
  partners_heading: PrismicRichText;
  partners_logos: PrismicImage;
}
