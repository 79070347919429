import * as React from 'react';
import { classes, style } from 'typestyle';
import { borderRadius } from '../../../theme/border';
import { spacing } from '../../../theme/spacing';
import { centerCenter, flexRoot } from 'csstips';
import { rebrand } from '../../../theme/color';
import { InfoFilled } from './icons/InfoFilled';
import { CheckmarkCircle } from './icons/CheckmarkCircle';
import { Warning } from './icons/Warning';
import { SemanticInformationType } from '../utilities/types';

interface BannerProps {
  type: SemanticInformationType;
  className?: string;
}

const styles = {
  wrapper: style(flexRoot, centerCenter, {
    gap: spacing.XSMALL,
    borderRadius: borderRadius.MEDIUM,
    padding: spacing.XSMALL,
    fontWeight: 700,
    width: '100%',
  }),
  success: style({
    backgroundColor: rebrand.semantic.success[20].toString(),
    color: rebrand.semantic.success[100].toString(),
  }),
  warning: style({
    backgroundColor: rebrand.semantic.warning[10].toString(),
    color: rebrand.semantic.warning[90].toString(),
  }),
  error: style({
    backgroundColor: rebrand.semantic.error[10].toString(),
    color: rebrand.semantic.error[90].toString(),
  }),
  info: style({
    backgroundColor: rebrand.neutralOnDark[20].toString(),
  }),
  icon: style({
    flexShrink: 0,
  }),
};

const getBannerIcon = (type: SemanticInformationType) => {
  switch (type) {
    case 'success':
      return (
        <CheckmarkCircle
          size={20}
          color={rebrand.semantic.success[70].toString()}
          checkColor={rebrand.semantic.success[20].toString()}
          className={styles.icon}
        />
      );
    case 'warning':
      return <InfoFilled size={20} color={rebrand.semantic.warning[60].toString()} className={styles.icon} />;
    case 'error':
      return <Warning size={20} className={styles.icon} />;
    default:
      return <InfoFilled size={20} color="currentColor" className={styles.icon} />;
  }
};

/**
 * The banner component is used to flag something important
 * It can also include an icon denoting its type
 */
export const Banner: React.FC<React.PropsWithChildren<BannerProps>> = ({ type, className, children, ...props }) => {
  const wrapperClasses = classes(styles.wrapper, styles[type], className ? className : '');
  const icon = getBannerIcon(type);

  return (
    <div className={wrapperClasses} {...props}>
      {icon}
      <div>{children}</div>
    </div>
  );
};
