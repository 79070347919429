import * as React from 'react';
import { classes, style } from 'typestyle';

import { px } from 'csx';
import { mediaDesktop } from '../utilities/mediaQueries';
import { rebrand } from '../../../theme/color';

const styles = {
  container: style({
    position: 'relative',
    flex: 1,
  }),

  title: style(
    {
      fontSize: px(28),
      fontWeight: 400,
      color: rebrand.dark1.toString(),
      textTransform: 'capitalize',
      margin: 0,
    },
    mediaDesktop({
      fontSize: px(32),
    })
  ),
};

type Props = {
  text: string;
  className?: string;
};

const TitleText: React.FC<Props> = ({ text, className = '' }) => {
  return <h3 className={classes(styles.title, className)}>{text}</h3>;
};

export default TitleText;
