import { observable } from 'mobx';
import {FormMarketingPreferencesModalModel} from '../../../components/form-marketing-preferences-modal';

export class AccountProfilePageModel {
  @observable
  loading: boolean = false;

  @observable
  modalDetails: any = {
    show: false,
    form: null
  };

  @observable
  modalMarketing: FormMarketingPreferencesModalModel = {
    show: false,
    form: null
  }
}