import { important, percent, px } from 'csx';
import React, { ButtonHTMLAttributes } from 'react';
import { classes, style } from 'typestyle';
import { SVGIcon } from '../svg-icon';
import { centerCenter } from 'csstips/lib/flex';
import { center } from 'csstips';

const styles = {
  wrapper: style(center, {
    display: 'inline-flex',
    padding: 0,
    backgroundColor: '#4285F4',
    border: '1px solid transparent',
    borderRadius: px(2),
    boxShadow: 'rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px',
    textDecoration: important('none'), // Google buttons should NEVER have underlining
    $nest: {
      '&:hover, &:focus': {
        filter: 'brightness(1.1)',
      },
      '&:active': {
        filter: 'brightness(0.9)',
      },
    },
  }),
  logoWrapper: style(centerCenter, {
    height: percent(100),
    aspectRatio: '1/1',
    padding: px(9),
    borderRadius: px(2),
    flexShrink: 0,
    backgroundColor: 'white',
    display: 'inline-flex',
    $nest: {
      '& svg': {
        height: percent(100),
        width: percent(100),
      },
    },
  }),
  text: style({
    color: 'white',
    padding: px(11),
    marginLeft: 'auto',
    marginRight: 'auto',
    fontWeight: 500,
    display: 'inline-block',
    verticalAlign: 'top',
    fontFamily:
      'roboto,-apple-system,BlinkMacSystemFont,segoe ui,oxygen,ubuntu,cantarell,fira sans,droid sans,helvetica neue,sans-serif',
  }),
};

const GIcon = (
  <div className={styles.logoWrapper}>
    <SVGIcon.GoogleMultiColour size={26} />
  </div>
);

interface GoogleButtonProps {
  className?: string;
  href?: string;
  text?: string;
  onClick?: (event: React.MouseEvent<any>) => void;
}

export default class GoogleButton extends React.Component<GoogleButtonProps> {
  render() {
    const { className, href, text = 'Sign up with Google', onClick } = this.props;

    const wording = <span className={styles.text}>{text}</span>;

    const wrapperClasses = classes(styles.wrapper, className);

    if (href)
      return (
        <a data-test-login-button-google className={wrapperClasses} href={href} onClick={onClick}>
          {GIcon}
          {wording}
        </a>
      );

    return (
      <button className={wrapperClasses} onClick={onClick}>
        {GIcon}
        {wording}
      </button>
    );
  }
}
