import * as React from 'react';
import { Component } from '../../lib/component';
import { getAmountValues } from '../../lib/helpers';
import { flexRoot } from 'csstips';
import { classes, style } from 'typestyle';
import { OrderSchema } from '../../types/schema';
import { formatCurrency } from '../../lib/currency';
import { px } from 'csx';
import { rebrand } from '../../theme/color';
import { spacing } from '../../theme/spacing';
import { mediaDesktop, mediaLargeTablet, mediaUpToLargeTablet } from '../project-happy/utilities/mediaQueries';
import { toDateString } from '../project-happy/utilities/date';
import { StarCircle } from '../project-happy/atoms/icons/StarCircle';

export interface AccountOrderDetailsProps {
  order: OrderSchema;
  showCredits: boolean;
}

const styles = {
  itemsList: style(
    flexRoot,
    {
      margin: `0 0 ${spacing.XLARGE}`,
      padding: 0,
      listStyle: 'none',
      gap: spacing.DEFAULT,
      flexWrap: 'wrap',
    },
    mediaLargeTablet({
      gap: spacing.LARGE,
    }),
    mediaDesktop({
      gap: spacing.XLARGE,
    })
  ),
  item: style({
    margin: 0,
  }),
  date: style(
    mediaUpToLargeTablet({
      width: `calc(100% - ${spacing.DEFAULT})`,
    })
  ),
  itemHeading: style({
    display: 'block',
    fontSize: px(12),
    color: rebrand.neutralOnLight[70].toString(),
    marginBottom: spacing.XXSMALL,
  }),
};

export class AccountOrderDetails extends Component<AccountOrderDetailsProps> {
  render() {
    const { order, showCredits } = this.props;
    const amount = getAmountValues(order.amount);
    const { currency } = order.locale;

    return (
      <ul className={styles.itemsList}>
        <li className={classes(styles.item, styles.date)}>
          <span className={styles.itemHeading}>Date</span>
          <span>{toDateString(order.date.date)}</span>
        </li>

        {amount.discount > 0 && (
          <li className={styles.item}>
            <span className={styles.itemHeading}>Discount</span>
            <span>{formatCurrency(amount.discount, currency)}</span>
          </li>
        )}
        {amount.card_fee > 0 && (
          <li className={styles.item}>
            <span className={styles.itemHeading}>Card fee</span>
            <span>{formatCurrency(amount.card_fee, currency)}</span>
          </li>
        )}
        {/* PREMIUM CREDITS - activate with project subscription **PROJECT_SUB_FLAG** */}
        {showCredits && order.credits_used > 0 && (
          <li data-test-credits-summary className={styles.item}>
            <span className={styles.itemHeading}>Premium track credits</span>
            <span>
              <StarCircle color="currentcolor" /> {order.credits_used} credit{order.credits_used !== 1 ? 's' : ''} used
            </span>
          </li>
        )}
        <li className={styles.item}>
          <span className={styles.itemHeading}>Total paid</span>
          <span>{formatCurrency(amount.total, currency)}</span>
        </li>
      </ul>
    );
  }
}
