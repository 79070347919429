import { SearchState, SearchPageModel, DEFAULT_VIDEO_PLATFORM_KEYS, DEFAULT_STATE } from './model';
import { action, autorun, observable } from 'mobx';
import { UIController } from '../../ui/controller';
import { RedirectFunction, RouterState } from 'react-router';
import { EnvModel } from '../../env/model';
import { RouterModel } from '../../router/model';
import { APIController } from '../../api/controller';
import { AnalyticsController } from '../../analytics/controller';
import { ContentModel } from '../../content/model';
import SearchFilters, { SearchFilterSection } from '../../../components/search/filters';
import { CatalogueType, FindFiltersInput, FindSortMode, FindSortOrder } from '../../../types/graphql';
import objectDifference from '../../../lib/objectDifference';
import { PaginationInput } from '../../../types';
import { FILTER_KEYS } from '../../../lib/findFilters';
import { debounce } from '../../../lib/helpers';
import { SEARCH_ROUTE } from '../../../constants';

type SearchField = keyof SearchState | keyof PaginationInput | keyof FindFiltersInput;

export class SearchPageController {
  constructor(
    private model: SearchPageModel,
    private ui: UIController,
    private env: EnvModel,
    private router: RouterModel,
    private api: APIController,
    private analytics: AnalyticsController,
    private content: ContentModel
  ) {
    this.syncState = debounce(this.syncState, 500);

    if (!env.isServer) {
      let firstRun = true;
      let search;
      autorun(() => {
        if (!router.location) return (firstRun = false);

        const isSearch = router.location.pathname === SEARCH_ROUTE;
        const isNewQuery = router.location.search !== search;

        if (firstRun || (isSearch && isNewQuery)) {
          search = router.location.search;
          this.updateRouterState(search ? search.slice(1) : '');
        }

        firstRun = false;
      });
    }
  }

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    const queryString = nextState.location.search.slice(1);

    return this.init(queryString);
  };

  private updateRouterState = (queryString: string) => {
    this.model.userState = queryString.length ? this.queryStringToFilterState(queryString) : this.model.userState;
  };

  init = async (queryString: string) => {
    this.model.loading = true;

    this.updateRouterState(queryString);

    this.ui.setBreadcrumbs([
      {
        path: SEARCH_ROUTE,
        label: 'Search',
      },
    ]);

    await this.ui.setSEO(SEARCH_ROUTE);

    const [themes, moods] = await Promise.all([
      this.api.browse.getTags('youtube', 'youtube_video_categories'),
      this.api.browse.getTags('musiio', 'musiio_mood'),
      this.env.ready,
    ]);

    this.model.themes = themes.data;
    this.model.moods = moods.data;

    this.model.genres = this.content.genres
      .filter((g) => g.slug !== 'remixes')
      .sort((a, b) => {
        const x = a.label.toLowerCase();
        const y = b.label.toLowerCase();

        return x < y ? -1 : x > y ? 1 : 0;
      });

    this.model.loading = false;

    this.sendAnalytics();
    this.syncState(this.env.isServer);

    this.ui.scrollToTop();

    return;
  };

  @action
  onLeave = async () => {
    return;
  };

  @action
  refineQuery = (query?: string) => {
    if (query === this.model.userState.query) return;

    if (this.model.userHasInteractedWithSearch == false) {
      this.model.userState.sortMode = 'RELEVANCE';
      this.model.userHasInteractedWithSearch = true;
    }

    this.model.userState.pagination.from = 0;
    this.model.userState.query = query || '';
    this.syncState();
  };

  @action
  refineSort = (mode: FindSortMode, order: FindSortOrder | null = null) => {
    this.model.userState.sortMode = mode;
    this.model.userState.sortOrder = order;
    this.syncState();
  };

  @action
  refineFilter = (key: keyof FindFiltersInput, value: any) => {
    this.model.userState.pagination.from = 0;
    const [MIN_DURATION, MAX_DURATION] = this.model.durationRange;
    const [MIN_BPM, MAX_BPM] = this.model.bpmRange;

    switch (key) {
      case 'catalogueType':
        this.model.userState.filters.catalogueType = value;
        break;
      case 'durationMin':
        this.model.userState.filters.durationMin =
          value <= MIN_DURATION || value === null
            ? null
            : Math.min(Math.max(value as number, MIN_DURATION), this.model.state.filters.durationMax || MAX_DURATION);
        break;

      case 'durationMax':
        this.model.userState.filters.durationMax =
          value >= MAX_DURATION || value === null
            ? null
            : Math.max(Math.min(value as number, MAX_DURATION), this.model.state.filters.durationMin || MIN_DURATION);
        break;

      case 'bpmMin':
        this.model.userState.filters.bpmMin =
          value <= MIN_BPM || value === null
            ? null
            : Math.min(Math.max(value as number, MIN_BPM), this.model.state.filters.bpmMax || MAX_BPM);
        break;

      case 'bpmMax':
        this.model.userState.filters.bpmMax =
          value >= MAX_BPM || value === null
            ? null
            : Math.max(Math.min(value as number, MAX_BPM), this.model.state.filters.bpmMin || MIN_BPM);
        break;

      default:
        this.model.userState.filters[key] = value;
    }

    this.sendFilterAnalytics(key, value);
    this.syncState();
  };

  @action
  goToPage = (page: number) => {
    this.model.userState.pagination.from = page * this.model.state.pagination.size;
    this.syncState(true, true);
    this.ui.scrollToTop();
  };

  @action
  toggleFilters = (show?: boolean) => {
    const next = typeof show === 'undefined' ? !this.model.showFilters : show;

    this.model.showFilters = next;
  };

  @action
  resetSection = (section: SearchFilterSection) => {
    switch (section.category) {
      case 'Catalogue type':
        this.refineFilter('catalogueType', this.model.defaultState.filters.catalogueType);
        break;
      case 'Order by':
        this.refineSort(this.model.defaultState.sortMode, this.model.defaultState.sortOrder);
        break;

      case 'Artist':
        this.refineFilter('artist', this.model.defaultState.filters.artist);
        break;

      case 'Label':
        this.refineFilter('rightsholder', this.model.defaultState.filters.rightsholder);
        break;

      case 'Genre':
        this.refineFilter('genre', this.model.defaultState.filters.genre);
        break;

      case 'Mood':
        this.refineFilter('mood', this.model.defaultState.filters.mood);
        break;

      case 'Length':
        this.refineFilter('durationMin', this.model.defaultState.filters.durationMin);
        this.refineFilter('durationMax', this.model.defaultState.filters.durationMax);
        break;

      case 'Tempo':
        this.refineFilter('bpmMin', this.model.defaultState.filters.bpmMin);
        this.refineFilter('bpmMax', this.model.defaultState.filters.bpmMax);
        break;

      case 'Song properties':
        this.refineFilter('explicit', this.model.defaultState.filters.explicit);
        this.refineFilter('instrumental', this.model.defaultState.filters.instrumental);
        break;

      case 'Usage':
        this.refineFilter('brandSponsored', this.model.defaultState.filters.brandSponsored);
        break;

      case 'Target platforms':
        this.model.defaultVideoPlatformKeys.forEach((k) => {
          this.refineFilter(k, this.model.defaultVideoPlatforms[k]);
        });
        break;

      default:
        return null;
    }
  };

  @action
  resetFilters = () => {
    Object.keys(this.model.defaultState)
      .filter((key) => key !== 'query') // don't reset the search
      .forEach((key) => (this.model.userState[key] = this.model.defaultState[key]));

    this.syncState();
  };

  @action
  syncState = (noRouterReplace = false, routerPush = false) => {
    if (this.router.location.pathname !== SEARCH_ROUTE) return;
    Object.keys(this.model.state).forEach((key) => {
      if (key === 'pagination') return (this.model.apiState.pagination = { ...this.model.state.pagination });

      if (key === 'filters') return (this.model.apiState.filters = { ...this.model.state.filters });

      this.model.apiState[key] = this.model.state[key];
    });

    const qs = this.filterStateToQueryString(this.model.userState);
    const nextPath = SEARCH_ROUTE + (qs.length ? `?${qs}` : '');

    if (routerPush) {
      this.router.push(nextPath);
    } else if (!noRouterReplace) {
      this.router.replace(nextPath);
    }

    if (!this.env.isServer) {
      this.sendSearchAnalytics();
    }

    const { query } = this.model.userState;

    this.ui.setSEO(query && query.length ? `/search/query` : `/search`, { query });
  };

  private sendAnalytics = () => {
    const { stateDifference } = this.model;

    if (stateDifference.sortMode) this.sendFilterAnalytics('sortMode', stateDifference.sortMode);

    if (stateDifference.sortOrder) this.sendFilterAnalytics('sortOrder', stateDifference.sortOrder);

    if (stateDifference.pagination && stateDifference.pagination.size)
      this.sendFilterAnalytics('size', stateDifference.pagination.size);

    if (stateDifference.pagination && stateDifference.pagination.from)
      this.sendFilterAnalytics('from', stateDifference.pagination.from);

    if (stateDifference.filters)
      Object.keys(stateDifference.filters).forEach((key) => {
        this.sendFilterAnalytics(key, stateDifference.filters[key]);
      });
  };

  private sendSearchAnalytics = () => {
    this.analytics.sendAppSearch(this.model.state.query || '');
  };

  private sendFilterAnalytics = (key: string, value: any) => {
    this.analytics.sendAppSearchFilter(key, value);
  };

  private filterStateToQueryString = (state: SearchState) => {
    const diff = objectDifference(state, this.model.defaultState);
    const parts = [];

    const encode = (key, value) => `${key}=${encodeURIComponent(value as string)}`;

    Object.keys(diff).forEach((key) => {
      if (key === 'pagination')
        return Object.keys(diff.pagination).forEach((k) => {
          parts.push(encode(k, diff.pagination[k]));
        });

      if (key === 'filters')
        return Object.keys(diff.filters).forEach((k) => {
          parts.push(encode(k, diff.filters[k]));
        });

      parts.push(encode(key, diff[key]));
    });

    return parts.join('&');
  };

  private queryStringToFilterState = (qs: string) => {
    return qs
      .split('&')
      .reduce((pairs, pair) => [...pairs, pair.split('=')], [])
      .reduce(
        (state, [key, value]: [SearchField, any]) => {
          const decoded = this.castKeyPair(key, decodeURIComponent(value));

          switch (true) {
            case ['size', 'from'].includes(key):
              return {
                ...state,
                pagination: {
                  ...state.pagination,
                  [key]: decoded,
                },
              };

            case FILTER_KEYS.includes(key as any):
              return {
                ...state,
                filters: {
                  ...state.filters,
                  [key]: decoded,
                },
              };

            default:
              return {
                ...state,
                [key]: decoded,
              };
          }
        },
        { ...DEFAULT_STATE }
      );
  };

  private castKeyPair = (key: SearchField, value: string) => {
    switch (true) {
      case SearchPageController.IntegerKeys.indexOf(key) !== -1:
        return parseInt(value);

      case SearchPageController.BooleanKeys.indexOf(key) !== -1:
        return value === 'true';

      case value === 'null':
        return null;

      default:
        return value;
    }
  };

  static IntegerKeys: Array<SearchField> = ['size', 'from', 'durationMin', 'durationMax', 'bpmMin', 'bpmMax'];

  static BooleanKeys: Array<SearchField> = [
    'brandSponsored',
    'brandedContent',
    'explicit',
    'featured',
    'instrumental',

    'dailymotion',
    'facebook',
    'instagram',
    'linkedin',
    'snapchat',
    'twitch',
    'twitter',
    'vimeo',
    'youtube',
    'TikTok',
    'podcasting',
  ];
}
