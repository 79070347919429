import * as React from 'react';
import { EnvModel } from '../../../../modules/env/model';
import { classes, style } from 'typestyle';
import { percent } from 'csx';
import { isDesktop, isFinePointer, mediaDesktop, mediaUpToDesktop } from '../../utilities/mediaQueries';
import { flexRoot } from 'csstips';
import { desktopOnlyPerPageStyles } from '../../utilities/carousel';
import { desktopBreakpoint } from '../../config';
import { AnalyticsContextProvider } from '../../utilities/AnalyticsContext';
import debounce from 'lodash/debounce';

const mobileTileSizeVarName = '--mobile-tile-size';
const styles = {
  wrapper: style({
    width: percent(100),
    height: percent(100),
    overflowX: 'scroll',
    overflowY: 'visible',
    position: 'relative',
    scrollSnapType: 'x mandatory',
    scrollbarWidth: 'none',
    $nest: {
      '&::-webkit-scrollbar': {
        $unique: true,
        display: 'none',
      },
    },
  }),
  innerWrapper: style(flexRoot, {
    height: percent(100),
  }),
  tileLike: style({
    width: [percent(42.5), `var(${mobileTileSizeVarName}, 42.5%)`],
    flexShrink: 0,
    flexGrow: 0,
  }),
  tile: style(
    {
      position: 'relative',
    },
    mediaUpToDesktop({
      scrollSnapAlign: 'start',
    }),
    mediaDesktop({ scrollSnapAlign: 'none' })
  ),
  anchor: style(
    mediaDesktop({
      scrollSnapAlign: 'start',
    })
  ),
};

export const BasePageCarouselDefaultProps = {
  perPage: 6,
  anchorPerPage: 1,
  mobileTileSize: 42.5,
};

export type BasePageCarouselProps = {
  className?: string;
  tileClassName?: string;
  page: number;
  perPage?: number;
  mobileTileSize?: number;
  onPageChange?: (page: number) => void;
  autoplay?: boolean;
};
export class BasePageCarousel extends React.Component<BasePageCarouselProps> {
  ref: HTMLDivElement;

  isInteracting = false;

  touchHandler: (event: TouchEvent) => void;

  scrollHandler: () => void;

  resizeHandler: () => void;

  getPageDivider() {
    const { mobileTileSize = BasePageCarouselDefaultProps.mobileTileSize } = this.props;
    return isDesktop.matches ? this.ref.clientWidth : this.ref.clientWidth * (mobileTileSize / 100);
  }

  updatePage() {
    if (this.ref) {
      this.props.onPageChange(Math.round(this.ref.scrollLeft / this.getPageDivider()) + 1);
    }
  }

  updateScrollPosition() {
    // If the user is interacting with the carousel via touch, don't interfere by programmatically scrolling
    if (this.isInteracting) return;
    if (this.ref) {
      this.ref.scrollTo({ left: this.getPageDivider() * (this.props.page - 1), behavior: 'smooth' });
    }
  }

  componentDidMount() {
    this.updatePage();
    this.scrollHandler = debounce(this.updatePage.bind(this), 100);
    // Track touches and report page updates as necessary
    this.touchHandler = (event: TouchEvent) => {
      this.isInteracting = event.touches.length > 0;
      this.scrollHandler();
    };
    this.ref.addEventListener('touchstart', this.touchHandler, false);
    this.ref.addEventListener('touchcancel', this.touchHandler, false);
    this.ref.addEventListener('touchend', this.touchHandler, false);
    // Scroll intertia can be interfered with even after the touch event has ended; debounce on scroll events too
    this.ref.addEventListener('scroll', this.scrollHandler, false);
    // Fix for Gecko based browsers where the carousel becomes misaligned during resize
    this.resizeHandler = debounce(this.updateScrollPosition.bind(this), 250);
    // We only set it where the main pointing device is fine (a good indication that we're probably on desktop, not dealing with touch events)
    if (isFinePointer.matches) {
      window.addEventListener('resize', this.resizeHandler, false);
    }
  }

  componentDidUpdate(prevProps: Readonly<BasePageCarouselProps>) {
    if (this.ref) {
      if (Number.isFinite(this.props.mobileTileSize)) {
        const mobileTileSizePercentage = `${this.props.mobileTileSize}%`;
        if (mobileTileSizePercentage !== this.ref.style.getPropertyValue(mobileTileSizeVarName)) {
          this.ref.style.setProperty(mobileTileSizeVarName, mobileTileSizePercentage);
        }
      }
      if ((isFinePointer.matches || this.props.autoplay) && prevProps.page !== this.props.page) {
        this.updateScrollPosition();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('touchstart', this.touchHandler);
    window.removeEventListener('touchcancel', this.touchHandler);
    window.removeEventListener('touchend', this.touchHandler);
    window.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('resize', this.resizeHandler);
  }

  render() {
    const {
      children,
      className,
      tileClassName,
      perPage = BasePageCarouselDefaultProps.perPage,
      mobileTileSize = BasePageCarouselDefaultProps.mobileTileSize,
    } = this.props;
    const childArray = Array.isArray(children) ? children : [children];
    const tileSize = 100 / perPage;
    const fullWidth = 100 * Math.ceil(childArray.length / perPage);
    const remainder = fullWidth - childArray.length * tileSize;

    const perPageClass = desktopOnlyPerPageStyles[perPage - 1];

    return (
      <div className={classes(styles.wrapper, className)} ref={(ref) => (this.ref = ref)}>
        <div className={styles.innerWrapper}>
          {childArray.map((child, index) => (
            <AnalyticsContextProvider context={{ carouselItemPosition: index + 1 }} key={index}>
              <span
                className={classes(
                  styles.tileLike,
                  tileClassName,
                  styles.tile,
                  index % perPage === 0 && styles.anchor,
                  perPageClass
                )}
              >
                {child}
              </span>
            </AnalyticsContextProvider>
          ))}
          <span className={styles.tileLike} style={{ width: percent(remainder) }} />
        </div>
      </div>
    );
  }
}
