import * as React from 'react';
import { ComponentProps, inject, deps, observer } from '../../../lib/component';
import { style } from 'typestyle';
import { ArtistLinkList } from '../../artist-link-list';
import { WaveformImage } from '../../waveform-image';
import { center, centerCenter, flexRoot, horizontal, vertical } from 'csstips';
import { percent, px } from 'csx';
import { rebrand } from '../../../theme/color';
import { resetAnchorStyles } from '../../../theme/reset';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { mediaDesktop, mediaLargeTablet, mediaSmallTablet, mediaUpToLargeTablet } from '../utilities/mediaQueries';
import { spacing } from '../../../theme/spacing';
import { borderRadius } from '../../../theme/border';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { AddRemoveCompat } from '../../compat/add-remove-compat';
import { ButtonCircle } from '../../button-circle';
import { MoreHoriz } from '../atoms/icons/MoreHoriz';
import { LicenceSchema } from '../../../types/schema';
import { ImageWithPlayBtn } from '../atoms/ImageWithPlayBtn';
import { Link } from '../atoms/Link';
import { formatCurrency } from '../../../lib/currency';

export interface OrderLicenceProps extends ComponentProps {
  licence: LicenceSchema;
  currency?: string;
  isDownload?: boolean;
}

const tileSize = px(100);

const linkStyles: NestedCSSProperties = {
  color: rebrand.contrast[50].toString(),
  $nest: { ...resetAnchorStyles(rebrand.contrast[50].toString()) },
};

const styles = {
  licence: style(
    flexRoot,
    {
      ...vertical,
      width: '100%',
      gap: spacing.DEFAULT,
      padding: spacing.DEFAULT,
      borderRadius: borderRadius.MEDIUM,
      border: `1px solid ${rebrand.neutralOnDark[30].toString()}`,
      $nest: {
        '& + &': {
          marginTop: spacing.DEFAULT,
        },
      },
    },
    mediaSmallTablet(horizontal, center)
  ),
  licenceInfo: style(
    flexRoot,
    center,
    {
      gap: spacing.DEFAULT,
    },
    mediaSmallTablet({
      flex: '2 1 30%',
    }),
    mediaDesktop({
      flex: '2 1 25%',
    })
  ),
  trackImage: style({
    height: tileSize,
    width: tileSize,
    flexShrink: 0,
  }),
  playButton: style({
    top: 'auto',
    left: 'auto',
    bottom: '8px',
    right: '8px',
  }),
  link: style({
    ...linkStyles,
    fontWeight: 700,
  }),
  artistLink: style({
    ...linkStyles,
  }),
  waveform: style(mediaUpToLargeTablet({ display: 'none' }), {
    flexGrow: 3,
    maxWidth: px(410),
    $nest: {
      img: {
        display: 'block',
        width: percent(100),
        height: px(30),
        borderRadius: px(50),
      },
    },
  }),
  linksWrapper: style({
    fontSize: px(12),
    overflow: 'hidden',
  }),
  trackInfoBtn: style({
    backgroundColor: rebrand.neutralOnDark[20].toString(),
    $nest: {
      '&:hover': {
        backgroundColor: rebrand.neutralOnDark[50].toString(),
      },
    },
  }),
  currency: style({
    fontSize: '12px',
    fontWeight: 700,
  }),
  iconButtons: style(flexRoot, {
    gap: spacing.XSMALL,
    flexShrink: 0,
  }),
  buttonWrapper: style(
    vertical,
    {
      gap: spacing.XSMALL,
    },
    mediaLargeTablet({
      flex: '0 0 148px',
    })
  ),
  btn: style(flexRoot, centerCenter),
};

export const OrderLicence = inject(deps)(
  observer(({ licence, isDownload = false, currency = 'GBP', controller }: OrderLicenceProps) => {
    const { preloadWaveformSvg } = controller.image;
    const { showDownloadModal, showAttributionModal, showSongInfoModal } = controller.modal;
    const { handleClickLink } = controller.ui;

    return (
      <div className={styles.licence} data-test-trackline-license>
        <div className={styles.licenceInfo}>
          <ImageWithPlayBtn
            track={licence.track}
            trackImageClassname={styles.trackImage}
            playButtonClassName={styles.playButton}
            hasImgRadius
          />

          <div className={styles.linksWrapper}>
            <Link
              data-test-track-title={licence.track.title}
              className={styles.link}
              href={`/music/artists/${licence.track.artists[0].slug}/track/${licence.track.slug}`}
              onClick={handleClickLink}
            >
              {licence.track.title}
            </Link>
            <ArtistLinkList
              model={{ artists: licence.track.artists }}
              onClickLink={handleClickLink}
              linkClassName={styles.artistLink}
            />
          </div>
        </div>

        {licence.track.audio && licence.track.audio.identity && (
          <div className={styles.waveform}>
            <WaveformImage identity={licence.track.audio.identity} preloadWaveform={preloadWaveformSvg} />
          </div>
        )}

        {isDownload ? (
          <div data-test-checkout-download-price className={styles.currency}>
            {formatCurrency(licence.amount, currency)}
          </div>
        ) : (
          <div className={styles.iconButtons}>
            <AddRemoveCompat track={licence.track} size={32} />
            <ButtonCircle
              className={styles.trackInfoBtn}
              size={32}
              onClick={() => showSongInfoModal(licence.track)}
              data-test-track-info
              aria-label="View more information about this track"
            >
              <MoreHoriz color={rebrand.contrast[50].toString()} />
            </ButtonCircle>
          </div>
        )}

        <div className={styles.buttonWrapper}>
          {!isDownload && (
            <Button
              size={ButtonSize.SMALL}
              prominence={ButtonProminence.SECONDARY}
              className={styles.btn}
              onClick={() => showAttributionModal(licence.identity)}
              data-test-trackline-license-view-attribution
            >
              View video attribution
            </Button>
          )}

          <Button
            size={ButtonSize.SMALL}
            prominence={isDownload ? ButtonProminence.PRIMARY : ButtonProminence.SECONDARY}
            className={styles.btn}
            onClick={() => showDownloadModal(licence.identity)}
            data-test-trackline-license-download-audio
          >
            Download audio
          </Button>

          <Button
            size={ButtonSize.SMALL}
            prominence={ButtonProminence.SECONDARY}
            disabled={!isDownload && !licence.eula_html_href}
            href={isDownload ? '/account/licenses' : licence.eula_html_href}
            className={styles.btn}
            openInNewWindow={!isDownload}
            data-test-trackline-license-view-license
          >
            {isDownload || licence.eula_html_href ? 'View license' : 'Preparing license...'}
          </Button>
        </div>
      </div>
    );
  })
);
