import * as React from 'react';
import Helmet from 'react-helmet';

import { observer } from 'mobx-react';
import { percent, px, rem, viewWidth } from 'csx';
import { center, content, flex1, flexRoot, horizontal, horizontallySpaced, vertical, verticallySpaced } from 'csstips';
import { mediaMobileOnly, mediaTablet } from '../theme/media';
import { style } from 'typestyle';
import { colorGunmetal, colorSubtle } from '../theme/color';
import { ResponsiveImageTile } from './responsive-image-tile';
import { ArtistSchema, ReleaseSchema, TrackSchema } from '../types/schema';
import { formatYear } from '../lib/date';
import { ImagePreloader } from '../types/fn';
import ReleaseCLine from './release/c_line';
import TrackPLine from './track/p_line';
import { isEmpty } from './project-happy/utilities/objects';

export interface ReleaseHeaderModel {
  artist: ArtistSchema;
  release: ReleaseSchema;
  tracks: TrackSchema[];
}

export interface ReleaseHeaderProps {
  model: ReleaseHeaderModel;
  preload: ImagePreloader;
  onClickLink(event: React.MouseEvent<any>): void;
}

export const ReleaseHeader = observer(({ model, onClickLink, preload }) => {
  if (isEmpty(model.release)) return null;

  // Release title and id: Sex Game - '659f5648af0243d9965fbd871ddc0c35'
  const releaseWithExplicitReleaseTitle = ['659f5648af0243d9965fbd871ddc0c35'];

  let hasExplicitReleaseTitle = false;

  if (releaseWithExplicitReleaseTitle.includes(model.release.identity)) {
    hasExplicitReleaseTitle = true;
  } else {
    hasExplicitReleaseTitle = false;
  }

  return (
    <div className={styles.container}>
      <div>
        {hasExplicitReleaseTitle && (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        )}
      </div>
      <ResponsiveImageTile identity={model.release.images && model.release.images.identity} preload={preload} />
      <div>
        <h3>
          <a
            onClick={onClickLink}
            className={styles.link}
            href={`/music/artists/${model.artist.slug}/release/${model.release.slug}`}
            data-test-release-header={model.release.title}
          >
            {model.release.title}
          </a>
        </h3>
        <div className={styles.meta}>
          <div>Released {formatYear(new Date(model.release.release_date.timestamp * 1000))}</div>
          {model.release &&
            model.release.c_line != null &&
            model.release.c_line != '' &&
            (model.release.c_line.indexOf(ReleaseCLine.symbol) !== -1
              ? model.release.c_line
              : ReleaseCLine.symbol + ' ' + model.release.c_line)}
          {model.release &&
            (model.release.c_line == null || model.release.c_line == '') &&
            model.tracks[0] != undefined &&
            ReleaseCLine.symbol +
              ' ' +
              parseInt(model.tracks[0].available_at) +
              ' ' +
              model.tracks[0].rightsholders[0].name}
        </div>
      </div>
    </div>
  );
});

const styles = {
  container: style(
    {
      ...flexRoot,
      $nest: {
        '& h3': {
          fontSize: px(42),
          margin: 0,
        },
      },
    },
    mediaMobileOnly({
      ...vertical,
      ...verticallySpaced(20),
      $nest: {
        '&> div:first-child': {
          width: percent(100),
        },
        '&> div:last-child': {},
      },
    }),
    mediaTablet({
      ...horizontal,
      ...horizontallySpaced(20),
      ...center,
      ...content,
      $nest: {
        '&> div:first-child': {
          width: viewWidth(20),
          maxWidth: px(160),
        },
        '&> div:last-child': {
          ...flex1,
        },
      },
    })
  ),
  link: style({
    color: colorGunmetal.toString(),
    $nest: {
      '&:link': { color: colorGunmetal.toString(), textDecoration: 'none' },
      '&&:visited': { color: colorGunmetal.toString(), textDecoration: 'none' },
      '&&&:hover': { color: colorGunmetal.darken(0.15).toString(), textDecoration: 'underline' },
      '&&&&:active': { color: colorGunmetal.toString(), textDecoration: 'none' },
    },
  }),
  meta: style({
    color: colorSubtle.toString(),
    fontSize: rem(0.875),
  }),
};
