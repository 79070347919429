import * as React from 'react';
import { classes, cssRule, extend, style } from 'typestyle';
import { px } from 'csx';
import { ContentSlice, ContentSliceProps, GradientBackgroundStyles } from './ContentSlice';
import { mediaDesktop, mediaUpToDesktop } from '../../utilities/mediaQueries';
import { colorLightBlueGrey } from '../../../../theme/color';
import { PrismicSliceCSSClasses } from '../../utilities/types';

const carouselSliceClass = PrismicSliceCSSClasses.CAROUSEL_SLICE;
cssRule(
  `.${carouselSliceClass} + .${carouselSliceClass}, .${PrismicSliceCSSClasses.TILE_SLICE} + .${carouselSliceClass}`,
  {
    borderTopWidth: px(1),
    borderTopStyle: 'solid',
    borderTopColor: colorLightBlueGrey.toHexString(),
    paddingTop: px(49),
  }
);

const styles = style(
  {
    color: 'inherit',
    position: 'relative',
    $nest: {
      // If this slice follows a bottom two-tone slice, mirror it (unless this slice has withGradient set)
      '.youtube-video-tracks-slice.with-gradient + &:not(.with-gradient)': extend(GradientBackgroundStyles, {
        paddingTop: 0,
      }),
    },
  },
  mediaUpToDesktop({
    paddingBottom: px(32),
    $nest: {
      '.masthead + &, .slice-group + &, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient, .with-background + &:not(.with-background), :not(.with-background) + &.with-background': {
        paddingTop: px(32),
      },
      '.youtube-video-tracks-slice.with-gradient + &:not(.with-gradient)': {
        background: `linear-gradient(to top, white 49.3%, transparent 0%), ${GradientBackgroundStyles.background}`,
      },
    },
  }),
  mediaDesktop({
    paddingBottom: px(56),
    $nest: {
      '.masthead + &, .slice-group + &, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient, .with-background + &:not(.with-background), :not(.with-background) + &.with-background': {
        paddingTop: px(56),
      },
      '.youtube-video-tracks-slice.with-gradient + &:not(.with-gradient)': {
        background: `linear-gradient(to top, white 200px, transparent 0%), ${GradientBackgroundStyles.background}`,
      },
    },
  })
);

type BaseCarouselSliceProps = ContentSliceProps;

export const BaseCarouselSlice: React.SFC<BaseCarouselSliceProps> = ({ className, ...props }) => (
  <ContentSlice className={classes(carouselSliceClass, styles, className)} {...props} />
);
