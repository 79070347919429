import React from 'react';
import { px, url } from 'csx';
import { classes, style } from 'typestyle';
import { ReferrerSchema } from '../../../types/schema';

const getAvatarDimensions = (size: number) => ({
  width: px(size),
  height: px(size),
  borderRadius: px(size),
});

const styles = style(getAvatarDimensions(24), {
  backgroundSize: 'cover',
  flexShrink: 0,
});

export interface UserReferrerAvatarProps {
  user: ReferrerSchema;
  size?: number;
  className?: string;
}

export const UserReferrerAvatar: React.FC<UserReferrerAvatarProps> = ({ user, size, className }) => {
  const avatar = user.avatar || null;
  const inlineStyles = { backgroundImage: url(avatar) };
  if (typeof size === 'number') {
    Object.assign(inlineStyles, getAvatarDimensions(size));
  }
  return <div className={classes(styles, className)} style={inlineStyles} />;
};
