/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { CheckmarkCircle } from '../atoms/icons/CheckmarkCircle';
import { rebrand } from '../../../theme/color';
import { SemanticInformationType } from '../utilities/types';
import { classes, style } from 'typestyle';
import { InfoFilled } from '../atoms/icons/InfoFilled';
import { claimStatusMap } from '../../../constants';

const VideoClaimsStatus = ({ claim = null, className, ...props }: { claim: string; className?: string }) => {
  switch (claim) {
    case claimStatusMap.NO_CLAIMS:
      return (
        <div className={classes(styles.claimStatus, styles.no_claims, className)} {...props}>
          <CheckmarkCircle
            color={rebrand.contrast[50].toString()}
            checkColor={rebrand.neutralOnDark[20].toHexString()}
            className={styles.icon}
            size={16}
          />
          {claim}
        </div>
      );

    case claimStatusMap.PENDING:
    case claimStatusMap.IN_INPROGRESS:
      return (
        <div className={classes(styles.claimStatus, styles.pending, className)} {...props}>
          <InfoFilled className={styles.icon} />
          {claim}
        </div>
      );
    case claimStatusMap.CLEARED:
    case claimStatusMap.CLAIM_CLEARED:
    case claimStatusMap.CLOSED:
      return (
        <div className={classes(styles.claimStatus, styles.cleared, className)} {...props}>
          <CheckmarkCircle
            color={rebrand.semantic[SemanticInformationType.SUCCESS]['70'].toString()}
            checkColor={rebrand.semantic[SemanticInformationType.SUCCESS]['20'].toString()}
            size={16}
            className={styles.icon}
          />
          {claim}
        </div>
      );

    default:
      return null;
  }
};

const styles = {
  claimStatus: style({
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    fontSize: '12px',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
  }),
  no_claims: style({
    backgroundColor: rebrand.neutralOnDark[20].toHexString(),
    minWidth: '92px',
  }),
  pending: style({
    backgroundColor: rebrand.semantic[SemanticInformationType.WARNING]['10'].toString(),
    minWidth: '83px',
  }),
  cleared: style({
    backgroundColor: rebrand.semantic[SemanticInformationType.SUCCESS]['20'].toString(),
    minWidth: '81px',
  }),
  icon: style({
    marginRight: '4px',
    minHeight: '14px',
    minWidth: '14px',
  }),
};

export default VideoClaimsStatus;
