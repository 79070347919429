import { color } from 'csx';
import * as React from 'react';
import { classes, cssRaw, style, TypeStyle } from 'typestyle';
import { colorWhite, rebrand } from '../../../theme/color';
import { CheckCircle } from './icons/CheckCircle';

const styles = {
  image: style({
    height: '160px',
    width: '160px',
    borderRadius: '8px',
  }),
  imageWrapper: style({ position: 'relative' }),
  tile: style({ width: '160px', margin: '8px' }),
  text: style({
    color: rebrand.contrast[10].toHexString(),
    fontWeight: 700,
    textAlign: 'center',
  }),
  selectedWrapper: style({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    backgroundColor: rebrand.primary1.toHexString(),
    opacity: 0.7,
  }),
  blurImage: style({
    opacity: 0.4,
  }),
};

type VerticalTileProps = {
  image: string;
  text: string;
  onClick?(event: React.MouseEvent<any>): any;
  selected: boolean;
  reachedMaximumNumberOfItemsToBeSelected: boolean;
};

export const VerticalTile: React.SFC<VerticalTileProps> = ({
  image,
  text,
  onClick,
  selected,
  reachedMaximumNumberOfItemsToBeSelected,
}) => (
  <div className={styles.tile} onClick={onClick} data-test-vertical-tile={text}>
    <div className={styles.imageWrapper}>
      {selected && (
        <div className={classes(styles.selectedWrapper, styles.image)}>
          <CheckCircle size={40} color={colorWhite.toHexString()} />
        </div>
      )}
      <img
        className={classes(styles.image, reachedMaximumNumberOfItemsToBeSelected && !selected ? styles.blurImage : '')}
        src={image}
      />
    </div>
    <p className={styles.text}>{text}</p>
  </div>
);
