import * as React from 'react';
import { AnyObject } from './objects';

export const ContentContext: React.Context<AnyObject> = React.createContext({});

type ContentContextProviderProps = {
  context: AnyObject;
};

const contextMap = new WeakMap();

export class ContentContextProvider extends React.Component<ContentContextProviderProps> {
  contextRef: React.RefObject<never>;

  constructor(props) {
    super(props);

    this.contextRef = React.createRef();
  }

  render() {
    const { children, context } = this.props;
    if (!contextMap.has(this.contextRef)) {
      contextMap.set(this.contextRef, context);
    }
    return <ContentContext.Provider value={contextMap.get(this.contextRef)}>{children}</ContentContext.Provider>;
  }
}
