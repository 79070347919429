import { LearnPageModel } from './model';
import { action } from 'mobx';
import { RedirectFunction, RouterState } from 'react-router';
import { PrismicController } from '../../prismic/controller';
import { BugsnagController } from '../../bugsnag/controller';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import Prismic from 'prismic-javascript';
import { RouterModel } from '../../router/model';
import { stripQueryString } from '../../../lib/string';
import AppBreadcrumbs from '../../../components/app-breadcrumbs';
import { ContentModel } from '../../content/model';

export class LearnPageController {
  constructor(
    private model: LearnPageModel,
    private prismic: PrismicController,
    private bugsnag: BugsnagController,
    private env: EnvModel,
    private ui: UIController,
    private router: RouterModel,
    private content: ContentModel,
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    this.model.loading = true;
    this.model.slug = stripQueryString(nextState.params['slug']);

    if (this.model.slug) return this.onEnterPage();

    try {
      this.ui.setBreadcrumbs([
        {
          path: '/learn',
          label: 'Learn',
        },
      ]);

      const predicates = [Prismic.Predicates.at('document.type', 'for_x')];

      const options = {
        fetch: ['for_x.page_title', 'for_x.slug', 'for_x.cover_image'],
      };

      const [{ results }] = await Promise.all([this.prismic.query(predicates, options), this.env.ready]);

      await this.ui.setSEO(`/learn`);

      if (results.length === 0) {
        return this.router.replace('/');
      }

      this.model.tiles = results
        .filter((r) => !!r.data.page_title && !!r.data.slug)
        .map((r) => ({
          name: r.data.page_title,
          slug: r.data.slug,
          images: {
            identity: r.data.cover_image.url,
          },
        }));
    } catch (e) {
      this.bugsnag.notifyException(e);
      // this.router.replace('/404');
    } finally {
      this.model.loading = false;
    }
  };

  private onEnterPage = async () => {
    try {
      const predicates = [
        Prismic.Predicates.at('document.type', 'for_x'),
        Prismic.Predicates.at('my.for_x.slug', this.model.slug),
      ];

      this.ui.setBreadcrumbs([
        {
          path: '/learn',
          label: 'Learn',
        },
        {
          path: '/learn/' + this.model.slug,
          label: AppBreadcrumbs.LOADING,
        },
      ]);

      const [{ results }] = await Promise.all([this.prismic.query(predicates), this.env.ready]);

      if (results.length === 0) {
        return this.router.replace('/');
      }

      const { data } = results[0];

      this.ui.setTitle(`${data.document_title} ${this.content.seo.titleSuffix}`);
      this.ui.setDescription(data.document_meta_description);

      this.model.page_title = data.page_title;
      this.model.page_subtitle = data.page_subtitle;
      this.model.typeform_url = data.typeform_url;
      this.model.cover_image = data.cover_image.url;
      this.model.cta_title = data.cta_title;
      this.model.cta_content = data.cta_content;
      this.model.cta_button = data.cta_button;

      const sectionsSlice = data.body.find((slice: any) => slice.slice_type === 'sections');
      this.model.sections = !sectionsSlice
        ? []
        : sectionsSlice.items.map((item: any) => ({
            heading: item.heading,
            content: item.content,
            image: item.image.url ? item.image.url : null,
            video: item.video.html ? encodeURIComponent(item.video.html) : null,
          }));

      this.ui.setLastBreadcrumbLabel(data.page_title);
    } catch (e) {
      this.bugsnag.notifyException(e);
      // this.router.replace('/404');
    } finally {
      this.model.loading = false;
    }
  };
}
