import * as React from 'react';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { renderComponentsFromPrismicSlices } from '../prismic-slices';
import { isArray, isEmpty } from '../utilities/objects';
import { classes, style } from 'typestyle';
import Helmet from 'react-helmet';
import { color, px, viewHeight } from 'csx';
import { Loading } from '../../loading/loading';
import { SliceGroup } from '../atoms/SliceGroup';
import { GroupHeading } from '../atoms/GroupHeading';
import { GroupHeadingBadge } from '../atoms/GroupHeadingBadge';
import { CheckmarkCircle } from '../atoms/icons/CheckmarkCircle';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { FavouritesCarouselSlice } from '../organisms/slices/FavouritesCarouselSlice';
import { ACCOUNT_PLANS_ROUTE } from '../../../constants';
import { mediaLargeTablet, mediaUpToLargeTablet } from '../utilities/mediaQueries';
import { SubscriptionLabelContext } from '../atoms/SubscriptionLabel';
import { colorGunmetal } from '../../../theme/color';
import { AnalyticsContextProvider } from '../utilities/AnalyticsContext';
import { Page404 } from '../../page-404';

const styles = {
  wrapper: style({
    minHeight: viewHeight(100),
  }),
  loadingSlices: style({ height: viewHeight(100) }),
  loadingData: style({ height: viewHeight(25) }),
  stockHeading: style({
    backgroundColor: '#FF056D',
    backgroundImage: 'linear-gradient(to right, #8019BC, #FF056D)',
    $nest: {
      '&::after': {
        borderTopColor: color('#8019BC').mix('#FF056D').toString(),
      },
    },
  }),
  stockHeadingUnsubscribed: style(
    {
      backgroundColor: colorGunmetal.toString(),
      backgroundImage: 'none',
      $nest: {
        '&::after': {
          borderTopColor: colorGunmetal.toString(),
        },
      },
    },
    mediaUpToLargeTablet({
      paddingBottom: px(16),
    }),
    mediaLargeTablet({
      paddingBottom: px(16),
    })
  ),
  chartHeading: style({
    backgroundColor: '#4A00FF',
    backgroundImage: 'linear-gradient(to top right, #4A00FF, #8100FF)',
    $nest: {
      '&::after': {
        borderTopColor: color('#4A00FF').mix('#8100FF', 0.75).toString(),
      },
    },
  }),
  subscribeButton: style({
    display: 'block',
    margin: '16px auto 0',
  }),
};

export const BrowsePage = inject(deps)(
  observer(
    ({
      model: {
        page: { browse },
      },
    }: ComponentProps) => {
      const { prismicPageTitle, slices, sliceContext, loading } = browse;
      const slicesLength = !isArray(slices) ? 0 : slices.length;

      if (slicesLength === 0 && !loading) return <Page404 />;

      if (loading && slicesLength <= 1) {
        return (
          <div className={styles.wrapper}>
            {renderComponentsFromPrismicSlices(slices.slice(0, 1), sliceContext)}
            <Loading className={slicesLength > 0 ? styles.loadingData : styles.loadingSlices} />
          </div>
        );
      }

      return (
        <AnalyticsContextProvider context={{ prismicPage: prismicPageTitle }}>
          <div className={styles.wrapper}>{renderComponentsFromPrismicSlices(slices, sliceContext)}</div>
        </AnalyticsContextProvider>
      );
    }
  )
);
