import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalChildStyle } from './modal';
import { FormVatNumber, FormVatNumberModel } from './form-vat-number';
import { TextNoOrphan } from './text-no-orphan';
import { style } from 'typestyle';
import { px } from 'csx';

export interface FormVatNumberModalModel {
  show: boolean;
  form: FormVatNumberModel;
}

export interface FormVatNumberModalProps {
  model: FormVatNumberModalModel;
  onRequestClose(): void;
  onChange(field: string, value: any): void;
  onSubmit(): void;
  onCancel(): void;
  i18n?: {
    title: string;
  };
}

const DEFAULT_I18N = {
  title: 'Update VAT number',
};

export const FormVatNumberModal = observer(
  ({ model, i18n = DEFAULT_I18N, onRequestClose, onChange, onSubmit, onCancel }: FormVatNumberModalProps) => (
    <Modal show={model.show} onRequestClose={onRequestClose}>
      {model.show && (
        <div className={styles.container}>
          <h3>
            <TextNoOrphan value={i18n.title} />
          </h3>
          <FormVatNumber model={model.form} onChange={onChange} onSubmit={onSubmit} onCancel={onCancel} />
        </div>
      )}
    </Modal>
  )
);

const styles = {
  container: style({
    ...ModalChildStyle,
    width: px(420),
  }),
};
