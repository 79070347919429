import * as React from 'react';
import { Component, deps, inject, observer } from '../../../lib/component';
import { TrackSchema } from '../../../types/schema';

export type TrackArtworkProps = {
  track: TrackSchema;
  className?: string;
  width?: number;
  height?: number;
  crop?: boolean;
  ignoreDimensions?: boolean;
};

@inject(deps)
@observer
export class TrackArtwork extends Component<TrackArtworkProps> {
  render() {
    const {
      model: {
        image: { cover },
      },
      controller,
      children,
      track,
      className,
      ...coverOptions
    } = this.props;
    return (
      <img
        className={className}
        height={coverOptions.height}
        width={coverOptions.width}
        src={cover(track.images.identity, coverOptions)}
        alt={`Track artwork for "${track.title}" by ${track.artists.map((artist) => artist.name).join(', ')}`}
        loading="lazy"
        decoding="async"
      />
    );
  }
}
