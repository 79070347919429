import React, { LiHTMLAttributes } from 'react';
import { classes, style } from 'typestyle';
import { PopoverPosition } from 'react-tiny-popover';
import { rebrand } from '../../../theme/color';
import { px } from 'csx';

const styles = {
  balloon: style({
    backgroundColor: rebrand.neutralOnDark[10].toString(),
    borderColor: rebrand.neutralOnDark[10].toString(),
    borderRadius: px(8),
    padding: px(16),
    $nest: {
      '&::after': {
        content: '""',
        width: 0,
        height: 0,
        border: '14px solid transparent',
        position: 'absolute',
      },
      '&.top': {
        transform: 'translateY(-14px)',
        $nest: {
          '&::after': {
            borderBottomWidth: 0,
            bottom: px(-14),
            left: 'calc(50% - 14px)',
            borderTopColor: 'inherit',
          },
        },
      },
      '&.left': {
        transform: 'translateX(-14px)',
        $nest: {
          '&::after': {
            borderRightWidth: 0,
            right: px(-14),
            top: 'calc(50% - 14px)',
            borderLeftColor: 'inherit',
          },
        },
      },
      '&.bottom': {
        transform: 'translateY(14px)',
        $nest: {
          '&::after': {
            borderTopWidth: 0,
            top: px(-14),
            left: 'calc(50% - 14px)',
            borderBottomColor: 'inherit',
          },
        },
      },
      '&.right': {
        transform: 'translateX(14px)',
        $nest: {
          '&::after': {
            borderLeftWidth: 0,
            left: px(-14),
            top: 'calc(50% - 14px)',
            borderRightColor: 'inherit',
          },
        },
      },
    },
  }),
};

type BalloonProps = { className?: string; position?: PopoverPosition };
export const Balloon: React.FC<BalloonProps> = ({ className, position = 'bottom', children }) => (
  <div className={classes(styles.balloon, position, className)}>{children}</div>
);
