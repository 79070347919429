/* eslint-disable react/react-in-jsx-scope */
import { rebrand } from '../../../../theme/color';
import { ButtonProminence, ButtonSize } from './buttonStyles';
import { px } from 'csx';
import { Button, ButtonProps } from './Button';
import { ArrowDown } from '../icons/ArrowDown';
import { Tooltip } from '../Tooltip';
import { classes, style } from 'typestyle';
import React from 'react';
import { SortDropdownDrawer } from '../../organisms/SortDropdownDrawer';
import { shadowSort } from '../../../../theme/shadow';

const styles = {
  tooltip: style({
    backgroundColor: rebrand.neutralOnDark['10'].toString(),
    borderColor: rebrand.neutralOnDark['10'].toString(),
    boxShadow: shadowSort,
    padding: 0,
  }),

  btn: style({
    fontWeight: 'normal',
    padding: '4px 8px 4px 12px',
    boxShadow: 'none',
    backgroundColor: rebrand.neutralOnDark[20].toString(),
    $nest: {
      '&:hover': {
        boxShadow: 'none',
      },
    },
  }),
  icon: style({
    marginLeft: px(8),
    marginBottom: px(2),
  }),
  arrowUp: style({ transform: 'rotate(180deg)' }),
};

export type SortDropdownButtonProps = Pick<ButtonProps, 'onClick' | 'className'> & {
  tooltipClassName?: string;
};

export class SortDropdownButton extends React.Component<SortDropdownButtonProps> {
  state = {
    showTooltip: false,
  };

  constructor(props: SortDropdownButtonProps) {
    super(props);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip() {
    const newState = !this.state.showTooltip;
    this.setState({ showTooltip: newState });
  }

  render() {
    const { showTooltip } = this.state;
    const { tooltipClassName } = this.props;

    return (
      <Tooltip
        className={styles.tooltip}
        portalClassName={tooltipClassName}
        show={showTooltip}
        content={<SortDropdownDrawer />}
        positions={['bottom', 'left']}
        align="end"
        disableOnMobile={false}
        onClickOutside={() => {
          this.setState({ showTooltip: false });
        }}
      >
        <Button
          data-test-sortby-dropdown-button
          prominence={ButtonProminence.SECONDARY}
          size={ButtonSize.MEDIUM}
          className={styles.btn}
          onClick={this.toggleTooltip}
        >
          Sort by
          <ArrowDown className={classes(styles.icon, showTooltip && styles.arrowUp)} />
        </Button>
      </Tooltip>
    );
  }
}
