import React, { Children } from 'react';
import { classes, style } from 'typestyle';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { rebrand } from '../../../theme/color';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { Link } from '../atoms/Link';
import { ButtonCircle } from '../../button-circle';
import { CloseCross } from './icons/CloseCross';
import { ChannelNameAvatar } from '../molecules/ChannelNameAvatar';
import { OnboardProgressBar } from './OnboardProgressBar';

const styles = {
  leftPanel: style(
    mediaDesktop({
      minHeight: '620px',
    }),
    {
      flex: 1,
      backgroundColor: rebrand.neutralOnDark['10'].toString(),
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      $nest: {
        '& a': {
          color: rebrand.contrast[40].toString(),
        },
      },
    }
  ),
  exitButton: style({
    backgroundColor: rebrand.neutralOnDark['20'].toString(),
  }),
  exitBlock: style(mediaDesktop({ marginLeft: '32px' }), mediaUpToDesktop({ top: '-200px' }), {
    display: 'flex',
    alignItems: 'baseline',
    position: 'absolute',
    top: '0px',
    left: '8px',
  }),
  exitText: style({ fontSize: '12px', marginLeft: '8px' }),
  leftContent: style(mediaUpToDesktop({ height: 'calc(100% - 32px)' }), {
    position: 'relative',
    width: '350px',
    height: '65%',
  }),
};

export const LeftHandOnboardContentPanel = inject(deps)(
  observer(
    ({
      children,
      model: {
        user: { channel },
      },
      controller: { analytics },
      className,
    }: React.PropsWithChildren<ComponentProps & { className?: string }>) => {
      return (
        <div className={classes(styles.leftPanel, className)}>
          <Link
            href="/"
            data-test-explore-button
            onClick={() => {
              analytics.sendMixpanel('User clicks let me explore ');
            }}
          >
            <div className={styles.exitBlock}>
              <ButtonCircle className={styles.exitButton} size={16}>
                <CloseCross size={10} />
              </ButtonCircle>
              <p className={styles.exitText}>Just let me explore</p>
            </div>
          </Link>
          <div data-test-onboard-body className={styles.leftContent}>
            {children}
          </div>
        </div>
      );
    }
  )
);
