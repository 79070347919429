import { GoldenTicketPageModel } from './model';
import { action } from 'mobx';
import { RedirectFunction, RouterState } from 'react-router';
import {BugsnagController} from '../../bugsnag/controller';
import {PrismicController} from '../../prismic/controller';
import {EnvModel} from '../../env/model';
import {LicensingPageModel} from '../licensing/model';
import {UIController} from '../../ui/controller';
import {ImageController} from '../../image/controller';
import Prismic from "prismic-javascript";

export class GoldenTicketPageController {

  constructor(
    private model: GoldenTicketPageModel,
    private prismic: PrismicController,
    private bugsnag: BugsnagController,
    private env: EnvModel,
    private ui: UIController,
    private image: ImageController,
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    this.model.loading = true;
    const document = 'golden_ticket';

    this.ui.setBreadcrumbs([{
      path: '/golden-ticket',
      label: 'Golden Ticket'
    }]);

    await this.ui.setSEO(nextState.location.pathname);

    try {
      const [{ data }] = await Promise.all([
        this.prismic.getSingle(document),
        this.env.ready
      ]);

      const image = this.image.preloadContent(data.document_image.url, {width: 1200});

      this.ui.setTitle(data.document_title);
      this.ui.setDescription(data.document_description);
      this.ui.setOpenGraph({image});

      // Key text (simple string)
      this.model.document_title = data.document_title;
      this.model.document_description = data.document_description;
      this.model.header = data.header;
      this.model.referral_text = data.referral_text;
      this.model.sub_header = data.sub_header;
      this.model.cta_landing_copy = data.cta_landing_copy;
      this.model.cta_genre_1_placeholder = data.cta_genre_1_placeholder;
      this.model.cta_genre_2_placeholder = data.cta_genre_2_placeholder;
      this.model.cta_landing_button = data.cta_landing_button;
      this.model.cta_complete_copy = data.cta_complete_copy;
      this.model.cta_complete_button = data.cta_complete_button;
      this.model.cta_complete_url = data.cta_complete_url;
      this.model.modal_title = data.modal_title;
      this.model.modal_caveat = data.modal_caveat;
      
      // RichText (html)
      this.model.modal_copy = data.modal_copy;

      // Image
      this.model.document_image = data.document_image.url;
      this.model.background_image = data.background_image.url;

    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      this.model.loading = false;
    }

    return;
  };
}