import { FeaturesPageModel } from './model';
import { action, runInAction } from 'mobx';
import { RedirectFunction, RouterState } from 'react-router';
import { PrismicController } from '../../prismic/controller';
import { BugsnagController } from '../../bugsnag/controller';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import Prismic from 'prismic-javascript';

export class FeaturesPageController {

  constructor(
    private model: FeaturesPageModel,
    private prismic: PrismicController,
    private bugsnag: BugsnagController,
    private env: EnvModel,
    private ui: UIController
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    this.model.loading = true;
    const document = 'f';

    let lang = 'en-gb';
    let { pathname } = nextState.location;

    if (pathname.startsWith('/es')) {
      lang = 'es-es';
    }

    this.ui.setBreadcrumbs([{
      path: '/features',
      label: 'Features'
    }]);

    await this.ui.setSEO(pathname);

    try {
      const [{ results }] = await Promise.all([
        this.prismic.query(
          Prismic.Predicates.at('document.type', document),
          { lang }
        ),
        this.env.ready
      ]);

      const { data } = results[0];

      runInAction(() => {
        this.model.page_title = data.page_title;
        this.model.cover_image = data.cover_image.url;
        this.model.video = encodeURIComponent(data.video.html);
        this.model.strap_title = data.strap_title;
        this.model.strap_content = data.strap_content;
        this.model.features_1 = data.features_1;
        this.model.features_2 = data.features_2;
        this.model.features_3 = data.features_3;
        this.model.features_4 = data.features_4;
        this.model.subtitle = data.subtitle;
        this.model.cta = data.cta;
        this.model.icon_rosette = data.icon_rosette.url;
        this.model.icon_disc = data.icon_disc.url;
        this.model.icon_lightning = data.icon_lightning.url;
        this.model.icon_bulb = data.icon_bulb.url;
        this.model.icon_friends = data.icon_friends.url;
      });
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      runInAction(() => {
        this.model.loading = false;
      });
    }

    return;
  };
}