import {
  PrismicCallToActionProps,
  PrismicRichText,
  PrismicSimpleBackgroundProps,
  PrismicSlice,
  PrismicURL,
} from '../utilities/types';
import { isEmpty } from '../utilities/objects';
import * as React from 'react';
import { CreatorDashboardMasthead } from '../molecules/mastheads/CreatorDashboardMasthead';
import { renderPrismicRichTextArray } from '../utilities/props';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { DashboardTeaserMasthead } from '../molecules/mastheads/DashboardTeaserMasthead';
import { ContentContext, ContentContextProvider } from '../utilities/ContentContext';

export type PrismicCreatorDashboardMastheadPayload = PrismicSimpleBackgroundProps &
  PrismicSlice<{
    heading: PrismicRichText;
    text: string;
    cta: string;
    url: PrismicURL;
    standard_tooltip: PrismicRichText;
    essentials_tooltip: PrismicRichText;
  }>;

export type PrismicCreatorDashboardMastheadProps = {
  payload: PrismicCreatorDashboardMastheadPayload;
};
export const PrismicCreatorDashboardMasthead = inject(deps)(
  observer(
    ({
      model: {
        user: { user },
      },
      payload: {
        primary: { background_image, background_colour, heading, text, cta, url, standard_tooltip, essentials_tooltip },
      },
    }: React.Component<PrismicCreatorDashboardMastheadProps & ComponentProps>['props']) => {
      const src = !isEmpty(background_image) && background_image.url;
      const props = {
        src,
        colour: background_colour,
        heading: renderPrismicRichTextArray(heading, 'fragment'),
        text: (
          <>
            {text}
            {!isEmpty(user) && ', ' + (user.nickname || user.first_name)}
          </>
        ),
        cta,
        href: !isEmpty(url) && url.url,
      };
      return (
        <ContentContextProvider
          context={{
            payg_description: renderPrismicRichTextArray(standard_tooltip),
            paid_description: renderPrismicRichTextArray(essentials_tooltip),
          }}
        >
          <CreatorDashboardMasthead {...props} />
        </ContentContextProvider>
      );
    }
  )
);
