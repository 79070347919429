import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const Person: React.SFC<SVGIconProps> = ({ size = 16, isSquare, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={isSquare ? size : size * (16 / 16)} viewBox="0 0 16 16" {...props}>
    <path
      fill={color}
      d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4Zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4Z"
      transform="translate(-4 -4)"
      id="a"
    />
  </BaseSvg>
);
