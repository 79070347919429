import { computed, observable } from 'mobx';
import { UserModel } from '../user/model';
import { AuthModel } from '../auth/model';
import { PricingModel } from '../pricing/model';
import { BasketModel } from '../basket/model';
import { ChannelSchema, TrackSchema } from '../../types/schema';
import { formatCurrency } from '../../lib/currency';
import { TrackSchemaWithUsage } from '../page/checkout/model';
import { isEmpty } from '../../components/project-happy/utilities/objects';
import { CreditPlanTypes, V2Plan } from '../../components/project-happy/utilities/types';

export class ModalModel {
  constructor(
    private user: UserModel,
    private auth: AuthModel,
    private pricing: PricingModel,
    private basket: BasketModel
  ) {}

  @computed get userId(): string {
    return this.user.user ? this.user.user.identity : null;
  }

  @computed get showAny(): boolean {
    return this.downloadShow || this.attributionShow || this.addChannelShow || this.selectChannelShow;
  }

  @observable platformAnnouncementsShow = false;

  @observable downloadShow = false;
  @observable downloadAgree = false;
  @observable downloadURL: string = null;
  @observable downloadError = false;
  @observable downloadLoading = false;
  @observable downloadAttribution: string;
  @observable downloadAttributionError = false;
  @observable downloadLimitReached = false;
  @observable licenseIdentity = '';

  @computed get downloadModal() {
    return {
      show: this.downloadShow,
      agree: this.downloadAgree,
      loading: this.downloadLoading,
      downloadError: this.downloadError,
      downloadURL: this.downloadURL,
      attribution: this.downloadAttribution,
      attributionError: this.downloadAttributionError,
      downloadLimitReached: this.downloadLimitReached,
      licenseIdentity: this.licenseIdentity,
      artist: this.licenseArtistName,
      track: this.licenseTrackName,
      shortId: this.licenseShortId,
    };
  }

  @observable attributionShow = false;
  @observable attributionLoading = false;
  @observable attributionAttribution: string = null;
  @observable licenseShortId = '';
  @observable licenseTrackName = '';
  @observable licenseArtistName = '';

  @computed get attributionModal() {
    return {
      show: this.attributionShow,
      loading: this.attributionLoading,
      attribution: this.attributionAttribution,
      artist: this.licenseArtistName,
      track: this.licenseTrackName,
      shortId: this.licenseShortId,
    };
  }

  @observable addChannelShow = false;
  @observable addChannelResults: Array<ChannelSchema> = null;
  @observable addChannelLoading = false;
  @observable addChannelLinking = false;
  @observable addChannelSelection: ChannelSchema = null;
  @observable addChannelError: string = null;

  @computed get addChannel() {
    return {
      show: this.addChannelShow,
      results: this.addChannelResults,
      loading: this.addChannelLoading,
      linking: this.addChannelLinking,
      selection: this.addChannelSelection,
      error: this.addChannelError,
      youtubeChannelLoginURL: this.auth.youtubeChannelLoginURL,
    };
  }

  @observable selectChannelShow = false;
  @observable selectChannelShowV2 = false;
  @observable selectChannelLoading = false;
  @observable selectChannelChannels: Array<ChannelSchema> = [];
  @observable selectChannelSelecting = false;
  @observable selectChannelActivating: ChannelSchema = null;

  @computed
  get selectChannel() {
    return {
      show: this.selectChannelShow,
      loading: this.selectChannelLoading,
      selecting: this.selectChannelSelecting,
      channels: this.selectChannelChannels,
      channel: this.user.channel,
      activating: this.selectChannelActivating,
    };
  }

  @computed
  get selectChannelV2() {
    return {
      show: this.selectChannelShowV2,
      loading: this.selectChannelLoading,
      selecting: this.selectChannelSelecting,
      channels: this.selectChannelChannels,
      channel: this.user.channel,
      activating: this.selectChannelActivating,
      addUrl: this.auth.youtubeChannelLoginURL,
    };
  }

  @observable reconnectChannelShow = false;
  @observable reconnectChannelObject: ChannelSchema = null;

  @computed get mustReAuthShow(): boolean {
    return this.auth.mustReAuth;
  }

  @computed
  get mustReAuth() {
    return {
      show: this.mustReAuthShow,
    };
  }

  @observable restrictedShow: boolean;

  @computed get restricted() {
    return {
      show: this.restrictedShow,
    };
  }

  @observable basketErrorShow: boolean;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  @computed get basketError() {
    return {
      show: this.basketErrorShow,
    };
  }

  @observable basketRestrictedShow: boolean;

  @computed get basketRestricted() {
    return {
      show: this.basketRestrictedShow,
      basket: this.basket,
    };
  }

  @observable songInfoShow = false;
  @observable songInfoTrack: TrackSchemaWithUsage;

  @observable addChannelForShow = false;
  @observable addChannelModalContext = '';

  @observable licenseLoading = false;
  @observable licenseShow: boolean;
  @observable licenseTrack: TrackSchema;
  @computed get licenseRates(): Array<{
    id: string;
    label: string;
    value: string;
    available: boolean;
  }> {
    return this.pricing.rates;
  }

  @computed get license() {
    let rates = this.licenseRates;
    if (!isEmpty(this.user.channel)) {
      rates = [];
      for (const rate of this.licenseRates) {
        const personalisedRate = { ...rate };
        personalisedRate.value = formatCurrency(this.user.channel.ratecard.value, this.user.channel.ratecard.currency);
        rates.push(personalisedRate);
      }
    }
    return {
      show: this.licenseShow,
      track: this.licenseTrack,
      loading: this.licenseLoading,
      firstFree: (this.basket.firstTrackIsFree && !this.basket.basketIncludesStandardTrack) || !this.user.channel,
      showPrices: !!this.user.channel,
      rates,
    };
  }

  @observable deleteAccountLoading = false;
  @observable deleteAccountShow = false;
  @observable deleteAccountPageBody: any = [];
  @observable deleteAccountError: string;
  @observable deleteAccountSaving = false;

  @computed get deleteAccount() {
    return {
      show: this.user.user && this.deleteAccountShow,
      loading: this.deleteAccountLoading,
      page_body: this.deleteAccountPageBody,
      error: this.deleteAccountError,
      saving: this.deleteAccountSaving,
    };
  }

  @observable downloadAccountDataLoading = false;
  @observable downloadAccountDataSaving = false;
  @observable downloadAccountDataShow = false;
  @observable downloadAccountDataSuccess = false;
  @observable downloadAccountDataError: string;

  @computed get downloadAccountData() {
    return {
      show: this.user.user && this.downloadAccountDataShow,
      loading: this.downloadAccountDataLoading,
      saving: this.downloadAccountDataSaving,
      success: this.downloadAccountDataSuccess,
      error: this.downloadAccountDataError,
    };
  }

  @observable showCreatePlaylistModal = false;
  // Used to add a track when creating a playlist from the trackline
  @observable createPlaylistModalTrackId: string | null = null;

  // Basket unsubscribed modal
  @observable showBasketUnsubscribedModal = false;

  // Savings reminder modal
  @observable
  showSavingsReminderModal = false;

  // Post-downgrade modal
  @observable
  showPostDowngradeModal = false;

  @observable
  postDowngradeModalSelectedPlan: string | null = null;

  // Post-upgrade modal
  @observable
  showPostUpgradeModal = false;

  @observable
  postUpgradeModalPlanName: string | null = null;

  @observable
  postUpgradeModalPlanCredits: number | null = null;

  // Pre-downgrade modal
  @observable
  showPreDowngradeModal = false;

  @observable
  preDowngradeModalSelectedPlan: V2Plan | null = null;

  // Pre-upgrade modal
  @observable
  showPreUpgradeModal = false;

  @observable
  preUpgradeModalSelectedPlan: V2Plan | null = null;
}
