import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Component, inject, deps, observer } from '../../lib/component';
import {style} from 'typestyle';
import {centerCenter} from 'csstips';
import {zModal} from '../../theme/z';
import {rgba} from 'csx';
import {colorWhite} from '../../theme/color';
import {shadowSubtle} from '../../theme/shadow';
import {mediaMobileOnly} from '../../theme/media';

export interface LayoutModalProps {
  show: boolean;
  onRequestClose(): void;
}

@inject(deps) @observer
export class LayoutModal extends Component<LayoutModalProps> {
  private content: any;

  render() {
    if (!this.props.show) return null;

    return (
      <div className={LayoutModal.styles.container} onClick={this.handleClick}>
        <div ref={(el) => this.content = el} className={LayoutModal.styles.content}>
          {this.props.children}
        </div>
      </div>
    )
  }

  handleClick = (event: React.MouseEvent<any>) => {
    if (!this.props.show) return;
    const root = ReactDOM.findDOMNode(this.content);
    const target = event.target as Node;
    if (root !== target && !root.contains(target)) {
      event.stopPropagation();
      this.props.onRequestClose();
    }
  };


  componentDidMount() { this.changePageScrolling(this.props.show); }
  componentDidUpdate() { this.changePageScrolling(this.props.show); }

  changePageScrolling = (prevent: boolean) => {
    this.props.controller.ui.setScrollBehaviour(prevent);
  };

  static styles = {
    container: style({
      ...centerCenter,
      zIndex: zModal,
      position: 'fixed',
      display: 'flex',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: rgba(0, 0, 0, 0.42)
    }),
    content: style(
      {
        boxShadow: shadowSubtle,
        maxWidth: 'calc(100vw - 4rem)',
        maxHeight: 'calc(100vh - 40px)',
        background: colorWhite.toString()
      },
      mediaMobileOnly({
        $nest: {
          '& iframe': {
            width: `calc(100vw - 4rem) !important`,
            height: `calc(56.25vw - 40px) !important`
          }
        }
      })
    )
  }
}
