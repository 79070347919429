import React, { useState, useEffect, useCallback } from 'react';
import throttle from 'lodash/throttle';
import { ArrowUp } from '../icons/ArrowUp';
import { headerFullHeight } from '../../config';
import { style } from 'typestyle';
import { rebrand } from '../../../../theme/color';
import { resetButton } from '../../../../theme/reset';

const styles = {
  arrowUp: (playerIsShowing) =>
    style(resetButton, {
      position: 'fixed',
      right: '30px',
      bottom: playerIsShowing ? '162px' : '90px',
      width: '32px',
      height: '32px',
      background: rebrand.contrast[50].toString(),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '100%',
      cursor: 'pointer',
    }),
};

interface ScrollToTopButtonProps {
  scrollTop: () => void;
  playerIsShowing?: boolean;
}

const ScrollToTopButton: React.FC<ScrollToTopButtonProps> = ({ scrollTop, playerIsShowing }) => {
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = useCallback(
    throttle(() => {
      if (window.scrollY >= headerFullHeight) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    }, 100),
    []
  );

  const isScrollAtBottom = () => {
    const scrollableContent = document.documentElement.scrollHeight - window.innerHeight;
    return Math.ceil(window.scrollY + window.innerHeight) < scrollableContent;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  if (!scrolling || !isScrollAtBottom()) {
    return null;
  }

  return (
    <button
      className={styles.arrowUp(playerIsShowing)}
      onClick={scrollTop}
      data-test-scroll-to-top
      aria-label="Scroll to the top of this window"
    >
      <ArrowUp color="#FFF" />
    </button>
  );
};

export default ScrollToTopButton;
