import * as React from 'react';
import { style } from 'typestyle';
import { percent, px } from 'csx';
import { Logo } from '../logo';
import { Button } from '../project-happy/atoms/button/Button';
import { colorGunmetal } from '../../theme/color';
import { headerBorder, headerFullHeight, desktopContentWidth } from '../project-happy/config';
import { mediaDesktop, mediaUpToDesktop } from '../project-happy/utilities/mediaQueries';
import { ButtonProminence, ButtonSize } from '../project-happy/atoms/button/buttonStyles';

const { PRIMARY } = ButtonProminence;

const styles = {
  header: style({
    position: 'fixed',
    backgroundColor: colorGunmetal.darken(0.075).toString(),
    top: 0,
    left: 0,
    right: 0,
    width: percent(100),
    height: px(headerFullHeight),
    zIndex: 10,
    $nest: {
      '&::after': {
        content: '""',
        position: 'absolute',
        background: 'linear-gradient(to left, #FF056D, #8019BC)',
        width: percent(100),
        height: px(headerBorder),
        bottom: 0,
        left: 0,
        right: 0,
      },
      '& .button': mediaDesktop({
        minHeight: px(32),
        paddingTop: px(5),
        paddingBottom: px(5),
      }),
    },
  }),
  wrapper: style(
    {
      height: percent(100),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    mediaUpToDesktop({
      marginLeft: px(16),
      marginRight: px(16),
    }),
    mediaDesktop({
      margin: '0 auto',
      width: px(desktopContentWidth),
    })
  ),
};

export interface HeaderBannerProps {
  className?: string;
  href?: string;
  onClick?: (event: React.MouseEvent<any>) => void;
}

export class HeaderBanner extends React.Component<HeaderBannerProps> {
  render() {
    return (
      <header className={styles.header}>
        <div className={styles.wrapper}>
          <Logo width={60} />
          <Button prominence={PRIMARY} href="/?show-sign-up">
            Sign up
          </Button>
        </div>
      </header>
    );
  }
}
