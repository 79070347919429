import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';
import { rebrand } from '../../../../theme/color';
import { SemanticInformationType } from '../../utilities/types';

export const Warning: React.SFC<SVGIconProps> = ({
  size = 16,
  color = rebrand.semantic[SemanticInformationType.ERROR]['60'].toString(),
  ...props
}) => (
  <BaseSvg width={size} height={size * (13 / 16)} viewBox="0 0 16 13" {...props} style={{ cursor: 'pointer' }}>
    <path
      d="M0.666626 13H15.3333L7.99996 0.333344L0.666626 13ZM8.66663 11H7.33329V9.66668H8.66663V11ZM8.66663 8.33334H7.33329V5.66668H8.66663V8.33334Z"
      fill={color}
    />
  </BaseSvg>
);
