import * as React from 'react';
import {observer} from 'mobx-react';
import {Form as IForm, FormField, FormFields} from '../types/form';
import {Form} from './form';

export interface FormVatNumberFields extends FormFields {
  vatNumber: FormField<string>;
}

export type FormVatNumberModel = IForm<FormVatNumberFields>;

export interface FormVatNumberProps {
  model: FormVatNumberModel;
  onChange(field: string, value: any): void;
  onSubmit(): void;
  onCancel(): void;
}

@observer
export class FormVatNumber extends React.Component<FormVatNumberProps, {}> {
  render() {
    const { onChange, onSubmit, onCancel, model } = this.props;

    return (
      <Form
        model={model}
        onSubmit={onSubmit}
        onChange={onChange}
        onCancel={onCancel}
      />
    )
  }
}