import * as React from 'react';
import { BrowseResultsProps } from './results';
import gql from 'graphql-tag';
import { graphql, InjectedGraphQLProps } from 'react-apollo';
import { Component, deps, inject, observer } from '../../lib/component';
import { style } from 'typestyle';
import { important, viewHeight } from 'csx';
import { centerCenter } from 'csstips';
import { RightsholderTileGrid } from '../tile-grid';
import { gradientDark } from '../../theme/gradient';
import { TagTile } from '../tag-tile';
import { PaginationInput } from '../../types';

@inject(deps)
@observer
class BrowseRightsholderGrid extends Component<Props & InjectedGraphQLProps<Data>> {
  render() {
    const { data } = this.props;
    const { handleClickLink } = this.props.controller.ui;
    const { preloadImage } = this.props.controller.image;

    const tiles = data && data.tiles && data.tiles.results ? data.tiles.results : [];

    return (
      <div className={BrowseRightsholderGrid.styles.container}>
        <RightsholderTileGrid
          tiles={tiles}
          loading={data.loading}
          loadingTiles={12}
          loadingLight={true}
          renderTile={(itemClass, tile, index) => (
            <TagTile
              className={itemClass}
              key={tile.slug + index}
              tag={tile}
              href={BrowseRightsholderGrid.rightsholderPath(tile.slug)}
              preload={preloadImage}
              onClickLink={handleClickLink}
            />
          )}
        />
      </div>
    );
  }

  static rightsholderPath = (slug: string) => {
    return `/browse/labels/${slug}`;
  };

  static styles = {
    container: style({
      background: gradientDark,
    }),
    loading: style({
      ...centerCenter,
      width: important('100%'),
      height: viewHeight(40),
    }),
  };
}

type Props = {
  pagination: PaginationInput;
};

type Item = {
  name: string;
  slug: string;
  images: {
    identity: string;
  };
};

type Data = {
  tiles: {
    results: Array<Item>;
  };
};

export default BrowseRightsholderGrid;

export const ConnectedBrowseRightsholderGrid = graphql(
  gql`
    query($pagination: PaginationInput!) {
      tiles: browseRightsholder(options: { type: RIGHTSHOLDER, pagination: $pagination }) {
        results {
          name
          slug
          images {
            identity
          }
        }
      }
    }
  `,
  {
    options: (variables: BrowseResultsProps) => ({
      variables,
      // fetchPolicy: 'network-only'
    }),
  },
)(BrowseRightsholderGrid);
