import * as React from 'react';
import { classes, style } from 'typestyle';
import { LandingMasthead, LandingMastheadProps } from './LandingMasthead';
import { center, centerCenter } from 'csstips';
import { Button } from '../../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../../atoms/button/buttonStyles';
import { percent, px, viewWidth } from 'csx';
import { mediaDesktop, mediaMobileOnly, mediaUpToDesktop } from '../../utilities/mediaQueries';
import { heading5 } from '../../utilities/font';
import { SIGN_UP_ROUTE } from '../../../../constants';

const { PRIMARY } = ButtonProminence;

const styles = {
  wrapper: style(
    centerCenter,
    mediaUpToDesktop({
      $nest: {
        '& h1 span:last-child': {
          fontSize: '9vw',
        },
      },
    }),
    mediaDesktop({
      $nest: {
        '& h1 span:last-child': {
          paddingLeft: px(48),
        },
      },
    })
  ),
  text: style(
    heading5,
    {
      fontFamily: 'Campton',
      fontWeight: 300,
      margin: 0,
    },
    mediaUpToDesktop({
      paddingTop: px(18),
      paddingBottom: px(32),
    }),
    mediaDesktop({
      paddingTop: px(34),
      paddingBottom: px(48),
    }),
    mediaMobileOnly({
      fontSize: '16px',
      margin: '0 35px',
    })
  ),
  button: style({
    minWidth: px(236),
    fontSize: px(20),
  }),
};

export type HomepageMastheadProps = Omit<LandingMastheadProps, 'headingPrefix' | 'headingSuffix'>;

export const HomepageMasthead: React.SFC<HomepageMastheadProps> = ({ className, ...props }) => (
  <LandingMasthead
    headingPrefix="All the music"
    headingSuffix="a creator needs"
    className={classes(styles.wrapper, className)}
    {...props}
  >
    <p className={styles.text}>From chart-topping tracks to the highest quality stock music</p>
    <Button className={styles.button} prominence={PRIMARY} href={SIGN_UP_ROUTE}>
      Sign Up
    </Button>
  </LandingMasthead>
);
