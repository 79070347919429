import * as React from 'react';
import { observer } from 'mobx-react';
import { important, percent, px, rem, viewWidth } from 'csx';
import { classes, style } from 'typestyle';
import { mediaTablet, mediaMobileOnly } from '../theme/media';
import { betweenJustified, end, horizontal, vertical, verticallySpaced } from 'csstips';
import { colorBackground, colorError } from '../theme/color';
import { ButtonPill } from './button-pill';
import { PageCheckoutAddCardModel } from './page-checkout-add-card';
import { PageCheckoutCouponCodeModel } from './page-checkout-coupon-code';
import { PageCheckoutVatNumberModel } from './page-checkout-vat-number';
import { AddressSchema, CardSchema, ChannelSchema, LicenceSchema, OrderSchema, TrackSchema } from '../types/schema';
import { Alert } from './alert';

export interface PageCheckoutPanelsModel {
  channel: ChannelSchema;
  step: number;
  coupon: string;
  couponText: {
    title: string;
    message: string;
  };
  currency: string;
  total: string | number;
  netTotal: string | number;
  price: string | number;
  priceBrandSponsored: string | number;
  prices: Array<string | number>;
  cards: Array<CardSchema>;
  selectedCard: string;
  address: AddressSchema;
  addresses: Array<AddressSchema>;
  addressError: string;
  band: string;
  expanded: boolean;
  tracks: Array<TrackSchema>;
  licences: Array<LicenceSchema>;
  loading: boolean;
  addCardForm: PageCheckoutAddCardModel;
  couponForm: PageCheckoutCouponCodeModel;
  vatNumberForm: PageCheckoutVatNumberModel;
  showAddCard: boolean;
  globalError: string;
  order: OrderSchema;
  isBeta: boolean;
  vatNumber?: string;
  vatRate: string;
  discountAmount: number;
  showNoChargeMessage?: boolean;
  brandedContentWarning: string;
}

export interface PageCheckoutPanelsProps {
  model: PageCheckoutPanelsModel;
  onContinue(): void;
  onCancel(): void;
  PlayButton: any;
  narrow?: boolean;
  left: any;
  right?: any;
  i18n?: {
    tracks: string;
    signUp: string;
    nextPayment: string;
    waitPayment: string;
    nextDiscount: string;
    waitDiscount: string;
    nextConfirm: string;
    waitConfirm: string;
    nextComplete: string;
    waitComplete: string;
    cancel: string;
  };
}

export interface PageCheckoutPanelProps {
  model: PageCheckoutPanelsModel;
}

@observer
export class PageCheckoutPanels extends React.Component<PageCheckoutPanelsProps, any> {
  render() {
    const { model, narrow, left, right, onContinue, onCancel, i18n = PageCheckoutPanels.i18n } = this.props;

    const button = left && right && model.tracks.length !== 0 && (
      <div className={PageCheckoutPanels.styles.buttons}>
        <ButtonPill
          className={PageCheckoutPanels.styles.button}
          onClick={!model.loading && onContinue}
          disabled={model.loading}
          primary={!!model.channel}
        >
          {model.loading ? this.getWait() : [<span key="label">{this.getText()}</span>]}
        </ButtonPill>
        {model.step !== 0 && (
          <ButtonPill
            className={PageCheckoutPanels.styles.button}
            onClick={!model.loading && onCancel}
            disabled={model.loading}
            muted={true}
          >
            {i18n.cancel}
          </ButtonPill>
        )}
      </div>
    );

    return (
      <div
        className={classes(PageCheckoutPanels.styles.container, narrow && PageCheckoutPanels.styles.containerNarrow)}
      >
        {left && !right && <div className={PageCheckoutPanels.styles.leftOnly}>{left}</div>}
        {left && right && <div className={!narrow && PageCheckoutPanels.styles.left}>{left}</div>}
        {left && right && (
          <div className={!narrow && PageCheckoutPanels.styles.right}>
            {right}
            {model.step === 0 &&
              model.tracks.filter((track) => {
                return track.reporting_party && track.reporting_party.slug === 'umg';
              }).length > 0 && (
                <Alert type="simple" position="none" textColor="secondary">
                  {
                    'Just so you know, claim removal will take a little longer for some of the tracks in your basket – click the © icon on a track for more info, and to find out what action you need to take.'
                  }
                </Alert>
              )}
            {model.step === 0 && model.tracks.length !== 0 && (
              <Alert type="simple" position="none" textColor="secondary">
                {
                  'By continuing, you’re indicating that you’ve read and agree to the license (EULA) for each track that you’re purchasing.'
                }
              </Alert>
            )}

            {button}
            {model.globalError && <div className={PageCheckoutPanels.styles.globalError}>{model.globalError}</div>}
          </div>
        )}
      </div>
    );
  }

  getText = () => {
    const { i18n = PageCheckoutPanels.i18n, model } = this.props;

    if (!model.channel) return i18n.signUp;

    switch (model.step) {
      case 0:
        return i18n.nextDiscount;
      case 1:
        return i18n.nextConfirm;
      case 2:
        return i18n.nextComplete;
    }
  };

  getWait = () => {
    const { i18n = PageCheckoutPanels.i18n, model } = this.props;

    switch (model.step) {
      case 0:
        return i18n.waitDiscount;
      case 1:
        return i18n.waitConfirm;
      case 2:
        return i18n.waitComplete;
    }
  };

  static i18n = {
    tracks: 'Your tracks',
    signUp: 'Register / sign in',
    nextDiscount: 'Continue',
    waitDiscount: 'Loading...',
    nextConfirm: 'Checkout & pay',
    waitConfirm: 'Loading...',
    nextComplete: 'Complete',
    waitComplete: 'Completing...',
    cancel: 'Back',
  };

  static styles = {
    container: style(
      {
        maxWidth: viewWidth(100),
        $nest: {
          '&> div:nth-child(2)': {
            background: colorBackground.fade(0.3).toString(),
          },
          '& h5': {
            fontSize: rem(1.125),
            margin: '0 0 1rem 0',
          },
        },
      },
      mediaMobileOnly({
        ...vertical,
        $nest: {
          '&> div:last-child': {
            borderTop: `1px solid ${colorBackground.fade(0.7).toString()}`,
          },
        },
      }),
      mediaTablet({
        ...horizontal,
      })
    ),
    containerNarrow: style({
      flexDirection: 'column !important',
      $nest: {
        '&> div:last-child': {
          borderTop: `1px solid ${colorBackground.fade(0.7).toString()}`,
        },
      },
    }),
    leftOnly: style(
      mediaTablet({
        width: percent(100),
      })
    ),
    left: style(
      mediaTablet({
        width: 'calc(200vw / 3)',
        maxWidth: 'calc(200vw / 3)',
        flexGrow: 2,
        flexShrink: 1,
        flexBasis: 2 / 3,
      })
    ),
    right: style(
      mediaTablet({
        ...vertical,
        ...betweenJustified,
        width: 'calc(100vw / 3)',
        maxWidth: 'calc(100vw / 3)',
        flexGrow: 1,
        flexShrink: 2,
        flexBasis: 1 / 3,
      })
    ),
    buttons: style({
      ...vertical,
      ...verticallySpaced(15),
      ...end,
      margin: '1.5rem 0 0 0',
    }),
    button: style(
      {
        width: percent(100),
        lineHeight: important('1.5rem'),
        fontSize: important('1.125rem'),
        fontWeight: 'bold',
      },
      mediaMobileOnly({
        maxWidth: 'none',
      })
    ),
    globalError: style({
      margin: '0.5rem 0 0 0',
      color: colorError.toString(),
    }),
  };
}
