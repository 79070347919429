import { action, observable } from 'mobx';
import { API } from '../types';
import { Client, cacheExchange, fetchExchange } from 'urql';
import { EntityType } from '../../../components/project-happy/atoms/SearchSuggestionItem';

const TRACK_QUERY = `query(
      $query: String
      $sortMode: FindSortMode
      $sortOrder: FindSortOrder
      $pagination: PaginationInput
      $filters: FindFiltersInput
    ) {
      find(query: $query, filters: $filters) {
        tracks(pagination: $pagination, sortOrder: $sortOrder, sortMode: $sortMode) {
          pagination {
            total
            totalPages
            from
            size
            page
          }
          results {
            identity
            highlight {
              key
              value
            }
            title
            releases {
              title
              slug
              artist {
                slug
              }
            }
            slug
            duration
            created_at
            is_charting
            is_explicit
            is_featured
            is_new_release
            is_stock_music
            mixes_count
            brand_sponsored
            branded_content
            is_available_in_territory
            tags {
              hookd_genre {
                name
                type
                source
              }
              musiio_genre {
                name
                type
                source
              }
              musiio_mood {
                name
                type
                source
              }
              hookd_mood {
                name
                type
                source
              }
            }
            audio {
              identity
            }
            images {
              identity
            }
            artists {
              highlight {
                key
                value
              }
              name
              slug
              identity
            }
          }
        }
      }
    }`;

const ENTITY_QUERY = `
query($query: String, $filters: FindFiltersInput) {
      artists: find(query: $query,  filters: $filters) {
        matches: artists(pagination: { size: 3, from: 0 }) {
          results {
            identity
            name
            slug
            images {
              identity
            }
          }
        }
      }

      genres: find(query: $query) {
        matches: genres(pagination: { size: 3, from: 0 }) {
          results {
            name
            slug
            images {
              identity
            }
          }
        }
      }

      moods: find(query: $query) {
        matches: moods(pagination: { size: 3, from: 0 }) {
          results {
            name
            slug
            images {
              identity
            }
          }
        }
      }
      playlists: find(query: $query, filters: $filters) {
        matches: playlists(pagination: { size: 4, from: 0 }) {
          results {
            identity
            uuid
            name
            slug
            is_stock
            images {
              identity
            }
            tracks {
              uuid
              title
            }
          }
        }
      }
    }`;

const SIDEBAR_MOOD_GENRY_QUERY = `
  query MOODS_GENRES_FILTERS {
    genres: find {
      matches: genres(pagination: { size: 100, from: 0 }) {
        results {
          name
          slug
          images {
            identity
          }
        }
      }
    }
    moods: find {
      matches: moods (pagination: { size: 100, from: 0 }){
        results {
          name
          slug
          images {
            identity
          }
        }
      }
    }
  }`;

export class SearchAPIController extends API {
  private graphqlClient: Client = null;

  get client(): Client {
    if (this.graphqlClient === null) {
      this.graphqlClient = new Client({
        url: this.env.graphqlUri,
        exchanges: [cacheExchange, fetchExchange],
      });
    }
    return this.graphqlClient;
  }

  @observable searchTracksAbortController: AbortController | null = null;
  @observable findEntityAbortController: AbortController | null = null;

  @action
  async searchTracks(variables) {
    if (!variables.filters.availableIn) {
      delete variables.filters.availableIn;
    }

    // Abort any in progress requests
    if (this.searchTracksAbortController) {
      this.searchTracksAbortController.abort();
    }

    // Assign a new AbortController instance to each request and save it in state
    this.searchTracksAbortController = new AbortController();

    const data = await this.client
      .query(TRACK_QUERY, variables, {
        fetchOptions: {
          signal: this.searchTracksAbortController.signal,
        },
      })
      .toPromise();

    // Once the request has finished, reset the abort controller
    this.searchTracksAbortController = null;

    return data;
  }

  @action
  async findEntity(variables) {
    // Abort any in progress requests
    if (this.findEntityAbortController) {
      this.findEntityAbortController.abort();
    }

    // Assign a new AbortController instance to each request and save it in state
    this.findEntityAbortController = new AbortController();

    const data = await this.client
      .query(ENTITY_QUERY, variables, {
        fetchOptions: {
          signal: this.searchTracksAbortController.signal,
        },
      })
      .toPromise();

    // Once the request has finished, reset the abort controller
    this.findEntityAbortController = null;

    return data;
  }
  @action
  async getMoodsGenres() {
    const data = await this.client.query(SIDEBAR_MOOD_GENRY_QUERY, {}).toPromise();
    return data;
  }
  @action
  fetchUserSearchHistory = async (userIdentity: string) => {
    const url = `/user/${userIdentity}/search/history?perPage=3`;
    const resp = await this.request.get({ url });
    const json = await resp.json();

    return json;
  };

  @action
  addUserSearchHistory = async (userIdentity: string, suggestion: EntityType) => {
    const url = `/user/${userIdentity}/search/history`;
    const body = JSON.stringify({ term: suggestion.name, context: JSON.stringify(suggestion) });
    const json = await this.request.post({ url, body });
    return json;
  };
  @action
  deleteUserSearchHistory = async (userIdentity: string, uuid: string) => {
    const url = `/user/${userIdentity}/search/history`;
    const body = JSON.stringify({ uuid });
    const json = await this.request.del({ url, body });
    return json;
  };
}
