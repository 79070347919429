import { UserPlaylistsPageModel } from './model';
import { RedirectFunction, RouterState } from 'react-router';
import { action, isObservableMap, observable } from 'mobx';
import { APIController } from '../../api/controller';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import { BugsnagController } from '../../bugsnag/controller';
import { stripQueryString } from '../../../lib/string';
import { AuthModel } from '../../auth/model';
import { APIPaginatedResponse } from '../../api/types';
import { TrackSchema } from '../../../types/schema';

const PLAYLIST_NOT_FOUND = 'Playlist not found';

export class UserPlaylistsPageController {
  constructor(
    private model: UserPlaylistsPageModel,
    private api: APIController,
    private env: EnvModel,
    private ui: UIController,
    private bugsnag: BugsnagController,
    private auth: AuthModel
  ) {
    const { playlistMetadata, playlistTracks } = this.model;

    if (!isObservableMap(playlistMetadata)) {
      this.model.playlistMetadata = observable.map(playlistMetadata);
    }

    if (!isObservableMap(playlistTracks)) {
      this.model.playlistTracks = observable.map(playlistTracks);
    }
  }

  @action
  async loadPlaylistByIdentity(identity: string, refresh = false): Promise<APIPaginatedResponse<TrackSchema[]> | void> {
    const country = this.auth.user ? this.auth.user.country : null;

    // Fetch the playlist metadata (if we don't have it already)
    if (!this.model.playlistMetadata.has(identity)) {
      const { data: playlistMetadata, success } = await this.api.playlist.getUserPlaylistMetadataPublic(identity);

      if (success === false) throw Error(PLAYLIST_NOT_FOUND);

      this.model.playlistMetadata.set(identity, playlistMetadata);
    }

    let page = 1;
    const isLoaded = this.model.playlistTracks.has(identity) && !refresh;

    if (isLoaded) {
      // Get the current page from the saved metadata
      const paginatedTracks = this.model.playlistTracks.get(identity);
      const {
        meta: { pagination },
      } = paginatedTracks;

      // If we're on the last page already, do nothing
      if (pagination.current_page === pagination.total_pages) {
        return paginatedTracks;
      }

      // Otherwise, set the page to the current page
      page += pagination.current_page;
    }

    // Fetch the tracks for the current page
    const response = await this.api.playlist.getUserPlaylistTracksPublic(identity, page, 25, country || '');

    if (isLoaded) {
      const paginatedTracks = this.model.playlistTracks.get(identity);
      const { data: tracks, meta } = response;

      paginatedTracks.data.push(...tracks);
      paginatedTracks.meta.pagination.count = tracks.length;
      paginatedTracks.meta.pagination.current_page = meta.pagination.current_page;
      this.model.playlistTracks.set(identity, paginatedTracks);
      return paginatedTracks;
    }

    this.model.playlistTracks.set(identity, response);
  }

  @action onEnter = async (nextState: RouterState, replace: RedirectFunction): Promise<void> => {
    this.model.loading = true;
    // Get the playlist ID from the URL
    const identity = stripQueryString(nextState.params['id']) || null;
    this.model.playlistIdentity = identity;

    // The redirect route depends on whether the user is signed in
    const redirectRoute = this.auth.user ? '/account/playlists' : '/browse';

    if (identity === null) {
      return replace(redirectRoute);
    }

    // Load the playlist each time from scratch in case any changes have been made since the last load
    try {
      await this.loadPlaylistByIdentity(identity, true);
    } catch (error) {
      if (error instanceof Error && error.message === PLAYLIST_NOT_FOUND) {
        this.env.ssrStatus = 404;
      } else {
        this.bugsnag.notifyException(error);
      }
    }

    this.model.loading = false;
  };
}
