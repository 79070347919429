import { DiscoverPageModel } from './model';
import { action } from 'mobx';
import { RedirectFunction, RouterState } from 'react-router';
import { PrismicController } from '../../prismic/controller';
import { BugsnagController } from '../../bugsnag/controller';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import Prismic from 'prismic-javascript';
import { RouterModel } from '../../router/model';
import {stripQueryString} from '../../../lib/string';

export class DiscoverPageController {

  constructor(
    private model: DiscoverPageModel,
    private prismic: PrismicController,
    private bugsnag: BugsnagController,
    private env: EnvModel,
    private ui: UIController,
    private router: RouterModel
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    this.model.loading = true;
    this.model.slug = stripQueryString(nextState.params['slug']);

    try {
      const predicates = [
        Prismic.Predicates.at('document.type', 'discover'),
        Prismic.Predicates.at('my.discover.slug', this.model.slug)
      ];

      const [{ results }] = await Promise.all([
        this.prismic.queryLanding(predicates),
        this.env.ready
      ]);

      if (results.length === 0) {
        return this.router.replace('/');
      }

      const { data } = results[0];

      this.ui.setBreadcrumbs([{
        path: '/discover/' + this.model.slug,
        label: data.page_title
      }]);

      this.ui.setTitle(data.page_title);
      this.ui.setDescription(data.document_meta_description);

      // RichText
      this.model.page_title = data.page_title;
      this.model.page_subtitle = data.page_subtitle;
      this.model.page_body = data.page_body;

      // Image
      this.model.cover_image = data.cover_image.url;

      // Video
      this.model.video = encodeURIComponent(data.video.html);

      // CTA
      this.model.hubspot_cta = encodeURIComponent(data.hubspot_cta);

    } catch (e) {
      this.bugsnag.notifyException(e);
      // this.router.replace('/404');
    } finally {
      this.model.loading = false;
    }

    return;
  };
}