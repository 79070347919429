import { action, runInAction } from 'mobx';
import { RouterState, RedirectFunction } from 'react-router';
import { AccountCouponsPageModel } from './model';
import { APIController } from '../../api/controller';
import { UserModel } from '../../user/model';
import { BugsnagController } from '../../bugsnag/controller';
import { UIController } from '../../ui/controller';
import { EnvModel } from '../../env/model';
import polyfillGoldenTicketCoupon from '../../../lib/polyfillGoldenTicketCoupon';
import { CouponSchema } from '../../../types/schema';
import { StorageModel } from '../../storage/model';

export class AccountCouponsPageController {
  constructor(
    private model: AccountCouponsPageModel,
    private user: UserModel,
    private api: APIController,
    private bugsnag: BugsnagController,
    private ui: UIController,
    private env: EnvModel,
    private storage: StorageModel
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    // runInAction(() => {
    //   this.model.loading = true;
    // });

    await this.ui.setSEO(nextState.location.pathname);

    this.ui.setBreadcrumbs([
      {
        path: '/account',
        label: 'Account',
      },
      {
        path: '/account/coupons',
        label: 'Coupons',
      },
    ]);

    // try {
    //   const [coupon] = await Promise.all([this.api.user.getCoupons(this.user.user.identity), this.env.ready]);
    //   const polyfillCoupon = polyfillGoldenTicketCoupon.bind(null, this.user.user.is_golden_ticket);
    //   const coupons = coupon.data.map(polyfillCoupon) as CouponSchema[]; // The bound polyfill loses type information, it's a purely additive method
    //   runInAction(() => {
    //     this.model.coupons = coupons;
    //   });
    // } catch (e) {
    //   this.bugsnag.notifyException(e);
    // } finally {
    //   runInAction(() => {
    //     this.model.loading = false;
    //   });
    // }

    return;
  };

  @action
  hideCreditInfoBox = (): void => {
    this.storage.setItem('hideAccountCreditInfoBox', true);
  };
}
