import * as React from 'react';
import { classes, style } from 'typestyle';
import { percent, px } from 'csx';

const styles = {
  wrapper: style({
    width: percent(100),
    padding: px(16),
  }),
  block: style({
    borderRadius: px(8),
    overflow: 'hidden',
  }),
};

export interface BaseBlockProps {
  className?: string;
  blockClassName?: string;
}

export const BaseBlock: React.SFC<BaseBlockProps> = ({ children, className, blockClassName }) => (
  <div className={classes(styles.wrapper, className)}>
    <div className={classes(styles.block, blockClassName)}>{children}</div>
  </div>
);
