import React, { Component } from 'react';
import { classes, style } from 'typestyle';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { OnboardVerticalsLayout } from '../templates/OnboardVerticalsLayout';
import { LeftHandOnboardContentPanel } from '../atoms/LeftHandOnboardContentPanel';
import { RightHandOnboardContentPanel } from '../atoms/RightHandOnboardContenntPanel';
import { ChannelNameAvatar } from '../molecules/ChannelNameAvatar';
import { OnboardProgressBar } from '../atoms/OnboardProgressBar';
import desktopHeaderImagex2 from '../../../static/channel-onboarding/header-image-x2.png';
import desktopHeaderImageMobile from '../../../static/channel-onboarding/header-image-mobile-x2.png';
import { px } from 'csx';
import isEmpty from 'lodash/isEmpty';
import { ButtonProminence } from '../atoms/button/buttonStyles';
import { Button } from '../atoms/button/Button';
import { ONBOARD_FAVOURITE_GENRES } from '../../../constants';
import { ArrowRight } from '../atoms/icons/ArrowRight';
import { colorGreen, colorWhite, rebrand } from '../../../theme/color';
import { CircleButton } from '../atoms/button/CircleButton';
import { Link } from '../atoms/Link';
import { ChevronLeft } from '../atoms/icons/ChevronLeft';
import { Loading } from '../../loading/loading';
import { CheckCircle } from '../atoms/icons/CheckCircle';
import siteEvents, { SITE_EVENTS } from '../utilities/siteEvents';

const styles = {
  image: style({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    filter: 'blur(10px)',
    '-webkit-filter': 'blur(10px)',
  }),
  hideUpToDesktop: style(mediaUpToDesktop({ display: 'none' })),
  hideDesktop: style(mediaDesktop({ display: 'none' })),
  avatar: style({
    marginRight: px(8),
    width: '40px',
    height: '40px',
  }),
  welcomeHeadingText: style({
    position: 'absolute',
    left: '50px',
    top: '-17px',
    width: '300px',
  }),
  matchHeadingText: style(mediaUpToDesktop({ marginTop: '32px', top: '118px' }), {
    fontSize: '32px',
    position: 'absolute',
    top: '160px',
    marginTop: '68px',
  }),
  boldText: style({
    fontWeight: 700,
  }),
  text: style(mediaUpToDesktop({ top: '198px', width: '325px' }), {
    width: '351px',
    position: 'absolute',
    top: '280px',
  }),
  button: style(mediaUpToDesktop({ bottom: '60px', right: '0px', width: '224px' }), {
    display: 'grid',
    alignItems: 'center',
    width: '263px',
    position: 'absolute',
    bottom: '-80px',
    right: '-140px',
    height: '48px',
    boxShadow: 'none',
    $nest: {
      '&:hover': {
        boxShadow: 'none',
      },
    },
  }),
  arrow: style({
    position: 'absolute',
    right: '12px',
  }),
  textBtn: style(mediaUpToDesktop({ fontSize: '16px' }), {
    fontSize: '20px',
  }),
  textBtnColour: style({
    color: rebrand.contrast[90].toHexString(),
  }),
  container: style(
    mediaUpToDesktop({
      height: '98vh',
    })
  ),
  backButton: style(mediaUpToDesktop({ bottom: '60px', left: '0px' }), {
    position: 'absolute',
    bottom: '-80px',
    left: '-120px',
    width: '40px',
    height: '40px',
  }),
  rightPanelWrapper: style({ position: 'relative' }),
  loadingContainer: style({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
  }),
  LeftHandOnboardContentPanelWrapper: style({
    zIndex: 3,
  }),
};

const { PRIMARY } = ButtonProminence;

@inject(deps)
@observer
export class OnboardPersonalisationCompletePage extends Component<ComponentProps, { timerComplete: boolean }> {
  constructor(props) {
    super(props);
    this.state = {
      timerComplete: false,
    };
  }

  componentDidMount(): void {
    setTimeout(() => {
      this.setState({ timerComplete: true });
      siteEvents.emit(SITE_EVENTS.PERSONALISATION_SUCCESS);
    }, 9000);
  }
  render() {
    const {
      model: {
        user: { channel },
      },
      controller: {
        analytics: { sendMixpanel },
      },
    } = this.props;
    const { timerComplete } = this.state;
    return (
      <OnboardVerticalsLayout className={styles.container}>
        <LeftHandOnboardContentPanel className={styles.LeftHandOnboardContentPanelWrapper}>
          {!isEmpty(channel) && (
            <>
              <ChannelNameAvatar />

              <OnboardProgressBar step={3} />

              {!timerComplete && <h3 className={styles.matchHeadingText}>Matching you to music...</h3>}

              {timerComplete && <h3 className={styles.matchHeadingText}>Ready to see your matches?</h3>}

              <p className={styles.text}>
                We&apos;re <span className={styles.boldText}>personalizing your experience</span> with tracks, artists
                and playlists that we hope you&apos;ll love. You can always add favorites to save the best music
              </p>

              <Button
                className={styles.button}
                prominence={PRIMARY}
                href={'/'}
                data-test-next-button
                disabled={!timerComplete}
                onClick={() => sendMixpanel('user clicks show me the music')}
              >
                <span className={classes(timerComplete && styles.textBtnColour, styles.textBtn)}>
                  Show me the music!
                </span>
                <ArrowRight className={styles.arrow} color={rebrand.contrast[90].toHexString()} />
              </Button>
              <Link
                href={ONBOARD_FAVOURITE_GENRES}
                data-test-back-button
                onClick={() => sendMixpanel('user clicks back - onboard complete step')}
              >
                <CircleButton className={styles.backButton} prominence={ButtonProminence.SECONDARY}>
                  <ChevronLeft size={10} />
                </CircleButton>
              </Link>
            </>
          )}
        </LeftHandOnboardContentPanel>
        <RightHandOnboardContentPanel className={styles.rightPanelWrapper}>
          <div className={styles.loadingContainer}>
            {!timerComplete && <Loading />}
            {timerComplete && (
              <CheckCircle size={65} color={colorWhite.toHexString()} checkColor={colorGreen.toHexString()} />
            )}
          </div>
          <img src={desktopHeaderImagex2} className={classes(styles.image, styles.hideUpToDesktop)} />
          <img src={desktopHeaderImageMobile} className={classes(styles.image, styles.hideDesktop)} />
        </RightHandOnboardContentPanel>
      </OnboardVerticalsLayout>
    );
  }
}
