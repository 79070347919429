import * as React from 'react';
import hookdWhiteLogo from '../static/hookdwhitelogo.png';
import youTubeImage from '../static/youtube.png';
import abbeyRoadImage from '../static/abbey_road.png';
import midemImage from '../static/midemlab-finalist.png';
import { PlayerBar } from '../components/player/player-bar';
import { Component, ComponentProps, deps, inject, observer } from '../lib/component';
import { Head } from './head';
import { JustAdded } from './just-added';
import { Modals } from './modals';
import { FollowCompat } from '../components/compat/follow-compat';
import { AppShuntCTA } from '../components/app-shunt-cta';
import { AppCookieNotification } from '../components/app-cookie-notification';
import { constantHeaderHeight, constantDrawerWidth, constantExpandedHeaderHeight } from '../theme/constant';
import { classes, media, style } from 'typestyle';
import { mediaDesktop, mediaMobileOnly, mediaUpToTablet } from '../theme/media';
import { important, percent, px, viewHeight } from 'csx';
import { colorBlack, colorWhite } from '../theme/color';
import { zAppMenuDrawer } from '../theme/z';
import { betweenJustified, vertical } from 'csstips';
import { BaseContainer } from './base';
import { AppFooter } from '../components/project-happy/organisms/AppFooter';

@inject(deps)
@observer
export class LegacyPageContainer extends Component<ComponentProps> {
  render() {
    const {
      showPlayer,
      showChrome,
      firstPaint,
      cookieNotification,
      showMenuDrawer,
      showBreadcrumbs,
    } = this.props.model.ui;

    if (!showChrome)
      return (
        <span>
          <Head />
          {React.Children.only(this.props.children)}
          <Modals />
        </span>
      );

    const mainClasses = classes(LegacyPageContainer.styles.main);

    return (
      <BaseContainer className={LegacyPageContainer.styles.master} mainClassName={mainClasses}>
        {this.props.children}
      </BaseContainer>
      /*<div className={}>
        <div
          className={classes(MasterContainer.styles.blanket, showMenuDrawer && MasterContainer.styles.blanketVisible)}
          onClick={this.hideDrawer}
        />
        <Head />
        <Header />
        <AppMenuDrawer />
        <main className={mainClasses}>
          {this.props.children}
          <JustAdded />
          <AppFooter
            apiContent={content.apiContent}
            expanded={showPlayer}
            abbeyRoadImage={abbeyRoadImage}
            youTubeImage={youTubeImage}
            logoImage={hookdWhiteLogo}
            midemImage={midemImage}
            onClickLink={this.handleClickLink}
            Follow={FollowCompat}
            exampleLicense={exampleLicenseUri}
          />
          <FlashContainer />
        </main>
        <PlayerBar visible={showPlayer} />
        <Modals />
        {!firstPaint && (
          <AppCookieNotification
            model={cookieNotification}
            onClose={this.props.controller.ui.dismissCookieNotification}
            onClickLink={this.handleClickLink}
          />
        )}
        <FeatureFlagWidget />
      </div>*/
    );
  }

  handleClickLink = (event: React.MouseEvent<any>) => {
    const el = event.currentTarget as HTMLAnchorElement;
    const local = el.host === document.location.host;

    switch (true) {
      case el.pathname.includes('/blog') === true:
        return;

      case el.pathname === '#':
        return event.preventDefault();

      case local:
        event.preventDefault();
        event.stopPropagation();
        return this.props.model.router.push(el.pathname);
    }
  };

  hideDrawer = () => {
    this.props.controller.ui.setMenuDrawer(false);
  };

  static styles = {
    master: style({
      minHeight: viewHeight(100),
      $nest: {
        '@media all and (-ms-high-contrast:none)': {
          height: '100vh !important',
        },
      },
    }),
    main: style({
      ...vertical,
      ...betweenJustified,
      width: percent(100),
      background: colorWhite.toString(),
      position: 'relative',
      overflowX: 'hidden',
      minHeight: '100vh',
      padding: 0,
      $nest: {
        '@media all and (-ms-high-contrast:none)': {
          height: '100vh !important',
          flexShrink: important(0) as any,
        },
      },
    }),
    mainShunted: style(
      mediaDesktop({
        width: important(`calc(100% - ${constantDrawerWidth})`),
        marginRight: constantDrawerWidth,
      })
    ),
    blanket: style(
      mediaUpToTablet({
        position: 'fixed',
        display: 'none',
        top: px(-100),
        bottom: px(-100),
        left: px(-100),
        right: px(-100),
        background: colorBlack.fade(0.8).toString(),
        zIndex: zAppMenuDrawer - 1,
      }),
      mediaDesktop({
        display: 'none',
      })
    ),
    blanketVisible: style(
      mediaUpToTablet({
        display: 'block',
      })
    ),
  };
}
