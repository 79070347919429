import * as React from 'react';
import { createTextPanel } from '../utilities/prismic';
import { YouTubePanel } from '../molecules/panels/YouTubePanel';

export type PrismicYouTubePanelPayload = {
  primary: {
    youtube_video_id: string;
  };
};
export type PrismicYouTubePanelProps = {
  payload: PrismicYouTubePanelPayload;
};
export const PrismicYouTubePanel: React.SFC<PrismicYouTubePanelProps> = ({
  payload: {
    primary: { youtube_video_id },
  },
}) => <YouTubePanel videoId={youtube_video_id} />;
export const PrismicYouTubePanelWithText = createTextPanel(PrismicYouTubePanel);
