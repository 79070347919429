import * as React from 'react';
import { ModalProps } from '../../modal';
import { style } from 'typestyle';
import { flexRoot } from 'csstips';
import { px, rem } from 'csx';
import { rebrand } from '../../../theme/color';
import { ScrollableOverlay } from '../../scrollable-overlay/scrollable-overlay';
import overlayStyles, { ScrollableOverlayBodyStyle } from '../../../styles/scrollable-overlay';
import { mediaMobileOnly } from '../utilities/mediaQueries';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { Component, deps, inject, observer } from '../../../lib/component';
import { ACCOUNT_PLANS_ROUTE } from '../../../constants';

const styles = {
  wrapper: style({
    maxWidth: px(500),
  }),
  body: style(ScrollableOverlayBodyStyle, {
    padding: '1rem 0 0',
    borderTop: `1px solid ${rebrand.light1.toString()}`,
    textAlign: 'center',
    $nest: {
      '& > p': {
        margin: '0 0 1rem',
      },
    },
  }),
  textSM: style({ fontSize: px(12) }),
  actions: style(
    flexRoot,
    {
      justifyContent: 'center',
      flexDirection: 'row-reverse',
      gap: rem(1),
    },
    mediaMobileOnly({
      flexDirection: 'column',
      gap: rem(0.5),
      width: '100%',
      paddingBottom: rem(0.5),
    })
  ),
};

type SavingsReminderModalProps = ModalProps;

@inject(deps)
@observer
export class SavingsReminderModalLegacy extends Component<SavingsReminderModalProps> {
  constructor(props) {
    super(props);

    this.onReject = this.onReject.bind(this);
    this.onAccept = this.onAccept.bind(this);
  }

  onReject = (): void => {
    this.props.onRequestClose();

    const { unsubscribedCostFormatted, subscribedCostFormatted } = this.props.model.checkout;

    this.props.controller.analytics.sendMixpanel('Savings reminder modal offer rejected', {
      unsubscribedCost: unsubscribedCostFormatted,
      subscribedCost: subscribedCostFormatted,
    });
  };

  onAccept = (): void => {
    this.props.onRequestClose();
    this.props.model.router.push(ACCOUNT_PLANS_ROUTE);

    const { unsubscribedCostFormatted, subscribedCostFormatted } = this.props.model.checkout;

    this.props.controller.analytics.sendMixpanel('Savings reminder modal offer accepted', {
      unsubscribedCost: unsubscribedCostFormatted,
      subscribedCost: subscribedCostFormatted,
    });
  };

  render() {
    const { onRequestClose, model, ...props } = this.props;

    const {
      subscription: { trialLengthInDays },
    } = model;

    const couldTrial = trialLengthInDays > 0;

    return (
      <ScrollableOverlay className={styles.wrapper} onRequestClose={onRequestClose} {...props}>
        <div className={overlayStyles.header}>
          <h3 className={overlayStyles.title}>Save 75% {couldTrial ? 'with a free trial' : 'by subscribing'}</h3>
        </div>

        <div className={styles.body}>
          <p>
            With a Lickd subscription, you&apos;d benefit from <strong>75% off</strong> every Premium track <em>and</em>{' '}
            <strong>unlimited</strong> use of royalty-free tracks.
          </p>
          <p className={styles.textSM}>
            Subscription $15/month{couldTrial && ' after 14-day free trial'}. Cancel anytime.
          </p>
          <div className={styles.actions}>
            <Button
              data-test-wait-sign-up-button
              prominence={ButtonProminence.PRIMARY}
              size={ButtonSize.MEDIUM}
              onClick={this.onAccept}
            >
              {couldTrial ? 'Get a 14-day free trial' : 'Sign up'}
            </Button>
            <Button
              data-test-wait-continue-button
              prominence={ButtonProminence.SECONDARY}
              size={ButtonSize.MEDIUM}
              onClick={this.onReject}
            >
              Continue without discount
            </Button>
          </div>
        </div>
      </ScrollableOverlay>
    );
  }
}
