import * as React from 'react';
import { style } from 'typestyle';
import { ModalProps } from '../../modal';
import { Component } from '../../../lib/component';
import { center } from 'csstips';
import { colorLickdPink, colorWhite, rebrand } from '../../../theme/color';
import { SubscriptionLabel } from '../atoms/SubscriptionLabel';
import { CheckmarkCircle } from '../atoms/icons/CheckmarkCircle';
import { StarCircle } from '../atoms/icons/StarCircle';
import { OpenInNew } from '../atoms/icons/OpenInNew';
import { ScrollableOverlay } from '../../scrollable-overlay/scrollable-overlay';

const styles = {
  modal: style(center, {
    padding: '0 0 16px',
  }),
  title: style({
    textAlign: 'center',
    margin: '12px 0px',
  }),
  borderLine: style({
    borderBottom: `1px solid ${rebrand.light1.toString()}`,
    width: '100%',
  }),
  infoText: style({
    textAlign: 'center',
    marginLeft: '64px',
    marginRight: '64px',
    marginTop: '16px',
    fontSize: '12px',
  }),
  blankTrack: style({
    width: '64px',
    height: '64px',
    backgroundColor: '#E1E9F4',
    borderRadius: '4px',
    marginRight: '8px',
    position: 'relative',
  }),
  labelsContainer: style({ display: 'flex', marginTop: '32px' }),
  labelTextIcon: style({ fontSize: '12px', display: 'flex', alignItems: 'center', marginLeft: '8px' }),
  readMore: style({ display: 'flex', justifyContent: 'center', alignItems: 'center' }),
};
export type SubscriptionInfoModalProps = ModalProps & { payg: boolean };

export class SubscriptionInfoModalLegacy extends Component<SubscriptionInfoModalProps> {
  render() {
    const { show, onRequestClose, payg } = this.props;
    return (
      <ScrollableOverlay className={styles.modal} show={show} onRequestClose={onRequestClose}>
        {!payg && <h4 className={styles.title}>Included in the subscription</h4>}

        {payg && <h4 className={styles.title}>Premium - Pay per licence</h4>}

        <div className={styles.borderLine}></div>
        <div className={styles.labelsContainer}>
          <div className={styles.blankTrack}>
            <SubscriptionLabel included={!payg} />
          </div>
          {!payg ? (
            <div className={styles.labelTextIcon}>
              Included
              <CheckmarkCircle
                color={rebrand.dark1.toString()}
                checkColor={rebrand.highlight1.toString()}
                size={16}
                style={{ marginLeft: '4px' }}
              />
            </div>
          ) : (
            <div className={styles.labelTextIcon}>
              Premium
              <StarCircle color={colorLickdPink.toHexString()} style={{ marginLeft: '4px' }} />
            </div>
          )}
        </div>
        {!payg ? (
          <p className={styles.infoText}>
            Keep an eye out for the <em>Included</em> labels. These identify music that&apos;s{' '}
            <strong>included in the subscription</strong>. Usage of these tracks are unlimited and can be used across
            all social platforms.
          </p>
        ) : (
          <div className={styles.infoText}>
            <p>
              For a one-time fee, you can license <strong>mainstream music</strong>. Each licence purchased is for{' '}
              <strong>one use only</strong>. The fee you pay is based on your Rate Card which calculates your Average
              Video Views (AVV). Look out for the <em>Premium</em> label.
            </p>
            <div className={styles.readMore}>
              <a
                href="https://help.lickd.co/knowledge/whats-the-difference-between-an-included-and-premium-track"
                style={{ textDecoration: 'underline' }}
              >
                <strong>Read more</strong>
              </a>
              <OpenInNew size={12} style={{ marginLeft: '4px' }} />
            </div>
          </div>
        )}
      </ScrollableOverlay>
    );
  }
}
