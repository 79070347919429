import * as React from 'react';
import { observer } from 'mobx-react';
import { centerCenter, horizontal, horizontallySpaced, start, startJustified, vertical, wrap } from 'csstips';
import { px } from 'csx';
import { classes, style } from 'typestyle';
import { colorBrand, colorSubtle } from '../theme/color';
import { BillingAddress } from '../types';

export interface PageAccountBillingAddressesModel {
  addresses: Array<BillingAddress>;
}

export interface PageAccountBillingAddressesProps {
  model: PageAccountBillingAddressesModel;
  onAddAddress(): void;
  onEditAddress(index: number): void;
  i18n?: {
    title: string;
    add: string;
    edit: string;
  };
}

const DEFAULT_I18N = {
  title: 'Billing addresses',
  add: 'Add a new address',
  edit: 'Edit address',
};

export const PageAccountBillingAddresses = observer(
  ({ i18n = DEFAULT_I18N, model, onAddAddress }: PageAccountBillingAddressesProps) => (
    <div>
      <h2>{i18n.title}</h2>
      <div className={styles.tiles}>
        <div className={classes(styles.tile, styles.addTile)} onClick={() => onAddAddress()} children={i18n.add} />
        {model.addresses.map((address, index) => (
          <div key={index} className={classes(styles.tile, styles.editTile)}>
            {address.address1 && <div>{address.address1}</div>}
            {address.address2 && <div>{address.address2}</div>}
            {address.city && <div>{address.city}</div>}
            {address.postcode && <div>{address.postcode}</div>}
            {address.country && <div>{address.country}</div>}
          </div>
        ))}
      </div>
    </div>
  )
);

const styles = {
  container: style({
    marginBottom: px(40),
  }),
  tiles: style({
    ...horizontal,
    ...horizontallySpaced(10),
    ...wrap,
    $nest: {
      '&> div': {
        ...vertical,
        ...start,
        ...startJustified,
        margin: '0 0 10px 0',
        border: `1px solid ${colorSubtle.toString()}`,
        padding: px(20),
        width: px(240),
        height: px(240),
      },
      '&> div:first-child': {
        ...centerCenter,
        border: `2px dashed ${colorSubtle.toString()}`,
        cursor: 'pointer',
      },
    },
  }),
  tile: style({
    position: 'relative',
    margin: '0 0 10px 0',
    padding: px(20),
    width: px(240),
    height: px(240),
  }),
  editTile: style({
    ...vertical,
    ...start,
    ...startJustified,
    border: `1px solid ${colorSubtle.toString()}`,
    padding: '20px 20px 60px',
  }),
  addTile: style({
    ...centerCenter,
    border: `2px dashed ${colorSubtle.toString()}`,
    cursor: 'pointer',
    $nest: {
      '&:hover': {
        color: colorBrand.toString(),
      },
    },
  }),
};
