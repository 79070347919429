import { colorBackground, colorBrand, colorGunmetal, colorSubtle, colorWhite } from './color';
import { CSSProperties, NestedCSSProperties } from 'typestyle/lib/types';
import { color, percent } from 'csx';

export const resetAnchorStyles = (linkColor: string = colorWhite.toString(), noHover = false) => ({
  '&:link': { color: linkColor, textDecoration: 'none' },
  '&&:visited': { color: linkColor, textDecoration: 'none' },
  '&&&:hover': {
    color: noHover ? linkColor : color(linkColor).darken(0.15).toString(),
    textDecoration: noHover ? 'none' : 'underline',
  },
  '&&&&:active': { color: linkColor, textDecoration: 'none' },
});

export const resetButton: NestedCSSProperties = {
  appearance: 'none',
  '-moz-appearance': 'none',
  '-webkit-appearance': 'none',
  border: 0,
  padding: 0,
  background: 'none',
  font: 'inherit',
};

const trackMixin = (width: string, height: string, background: string): CSSProperties => ({
  boxSizing: 'border-box',
  border: 'none',
  width,
  height,
  background,
  pointerEvents: 'none',
});

const thumbMixin = (width: string, background: string): CSSProperties => ({
  boxSizing: 'border-box',
  border: 'none',
  width,
  height: width,
  borderRadius: percent(50),
  background: background,
  pointerEvents: 'all',
});

export const resetRangeStyles = (): NestedCSSProperties => {
  const trackHeight = '4px';
  const thumbHeight = '16px';
  const track = trackMixin('100%', trackHeight, colorSubtle.toString());
  const thumb = thumbMixin(thumbHeight, colorBrand.toString());

  return {
    $nest: {
      '&[type=range]': {
        '-webkit-appearance': 'none',
        margin: 0,
        padding: 0,
        minHeight: thumbHeight,
        background: `linear-gradient(${colorBackground.toString()}, ${colorBackground.toString()}) no-repeat center`,
        backgroundSize: `100% ${trackHeight}`,
        font: 'inherit',
        pointerEvents: 'none',
      },
      '&[type=range]:hover': {
        background: `linear-gradient(${colorBackground.darken(0.05).toString()}, ${colorBackground
          .darken(0.05)
          .toString()}) no-repeat center`,
      },
      '&[type=range]::focus': {
        background: `linear-gradient(${colorBackground.darken(0.15).toString()}, ${colorBackground
          .darken(0.15)
          .toString()}) no-repeat center`,
      },
      '&[type=range]::-webkit-slider-runnable-track': { ...track },
      '&[type=range]::-moz-range-track': track,
      '&[type=range]::-ms-track': track,
      '&[type=range]::-webkit-slider-thumb': {
        '-webkit-appearance': 'none',
        ...thumb,
      },
      '&[type=range]::-moz-range-thumb': track,
      '&[type=range]::-ms-thumb': {
        marginTop: 0,
        ...thumb,
      },
      '&[type=range]::-ms-tooltip': {
        display: 'none',
      },
    },
  };
};
