import React from 'react';
import { style } from 'typestyle';
import { flexRoot } from 'csstips';
import { PrismicImage } from '../../utilities/types';
import isEmpty from 'lodash/isEmpty';
import { mediaUpToLargeMobile } from '../../utilities/mediaQueries';

const styles = {
  wrapper: style(
    flexRoot,
    {
      justifyContent: 'space-between',
      maxWidth: '992px',
      gap: '0 32px',
      $nest: {
        '& img': {
          width: ' 100%',
          maxWidth: '340px',
        },
      },
    },
    mediaUpToLargeMobile({
      flexDirection: 'column',
      alignItems: 'center',
      gap: '32px 0',
    })
  ),
};

export type PricingImageProps = {
  images: {
    pricing_image: PrismicImage;
  }[];
};

const PricingImage: React.SFC<PricingImageProps> = ({ images }) => {
  return (
    <div>
      {!isEmpty(images) && (
        <div className={styles.wrapper}>
          {images.map((data, i) => (
            <div key={i}>
              <img src={data.pricing_image.url} alt={'pricing image'} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default PricingImage;
