import * as React from 'react';
import { ContentSliceProps } from './ContentSlice';
import { DecoratedPageCarouselProps } from '../DecoratedPageCarousel';
import { Component, deps, inject, observer } from '../../../../lib/component';
import { LoadingCarouselSlice } from './LoadingCarouselSlice';
import { TrackCarouselSlice } from './TrackCarouselSlice';
import { isArray, isEmpty } from '../../utilities/objects';
import { Button } from '../../atoms/button/Button';
import { style } from 'typestyle';
import { ONBOARD_ROUTE } from '../../../../constants';
import { ButtonProminence } from '../../atoms/button/buttonStyles';
import { rebrand } from '../../../../theme/color';
import { Headphones } from '../../atoms/icons/Headphones';
import { mediaUpToDesktop } from '../../utilities/mediaQueries';
import { autorun, reaction } from 'mobx';

const styles = {
  container: style({
    marginTop: '32px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  buttonOverlay: style({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 1,
    $nest: {
      '& svg': {
        marginLeft: '8px',
      },
    },
  }),
  button: style(
    mediaUpToDesktop({
      fontSize: '20px',
    }),
    {
      fontSize: '20px',
    }
  ),
  text: style({
    color: rebrand.contrast[40].toHexString(),
    textAlign: 'center',
    marginTop: '8px',
  }),
};
// Exclude gradient prop as this slice has a two tone background
type CarouselSliceProps = ContentSliceProps & DecoratedPageCarouselProps;

@inject(deps)
@observer
export class PersonalisedCarouselSlice extends Component<CarouselSliceProps> {
  state = {
    loading: true,
  };

  disposeAutorun: () => void;

  componentDidMount() {
    this.disposeAutorun = autorun(() => {
      const { user, channel } = this.props.model.user;
      if (user) {
        if (channel) {
          // Only proceed to loadPlaylist if channel is present
          this.loadPlaylist();
        } else if (user.default_channel) {
          // Keep the loading state if we are waiting for the channel data
          this.setState({ loading: true });
        } else {
          this.setState({ loading: false });
        }
      }
    });
  }

  componentWillUnmount() {
    // Clean up the autorun when the component unmounts
    if (this.disposeAutorun) {
      this.disposeAutorun();
    }
  }

  async loadPlaylist() {
    try {
      await this.props.controller.user.getRecommendedTracksPlaylist();
    } catch (error) {
      // The error is handled via buugsnag in getRecommendedTracksPlaylist()
      // The call failed, rather than reporting the error to the user, just reset the loading state and move on
    }
    // Set loading to false after attempting to load the playlist,
    // regardless of whether it was successful or not
    this.setState({ loading: false });
  }

  render() {
    const {
      model: {
        auth: { token },
        user: { channel },
      },
      controller: {
        analytics: { sendMixpanel },
        modal: { showAddChannelForModal },
      },
      heading,
      ...props
    } = this.props;
    const carouselHeading = heading || 'Tracks for you';

    if (!token) return null;

    const recommendedPlaylist = this.props.model.user.recommendedPlaylist;

    const recommendedPlaylistSlug = this.props.model.user.recommendedPlaylistSlug;

    if (this.state.loading === true) {
      return <LoadingCarouselSlice heading={carouselHeading} />;
    }

    // Display the prompt slice if the response is not and array or there isn't a playlist to display or category/vertical hasn't been set
    if (
      !isArray(recommendedPlaylist) ||
      recommendedPlaylist.length === 0 ||
      (channel && channel.channel_category === null)
    ) {
      return (
        <div className={styles.container}>
          <div className={styles.buttonOverlay}>
            <Button
              href={!isEmpty(channel) ? ONBOARD_ROUTE : undefined}
              prominence={ButtonProminence.PRIMARY}
              className={styles.button}
              data-test-personalize-button
              onClick={() => {
                if (!isEmpty(channel)) {
                  sendMixpanel('User clicks Personalize My Results');
                } else {
                  showAddChannelForModal();
                }
              }}
            >
              Personalize My Results <Headphones size={18} />
            </Button>

            <span className={styles.text}>It takes less than 2 minutes!</span>
          </div>
          <TrackCarouselSlice heading={carouselHeading} {...props} />
        </div>
      );
    }
    return (
      <TrackCarouselSlice
        heading={carouselHeading}
        tracks={recommendedPlaylist}
        slug={recommendedPlaylistSlug}
        {...props}
      />
    );
  }
}
