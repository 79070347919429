import * as React from 'react';
import DevilHandImg from '../../../../static/devil-hand.png';
import DevilHandImg2x from '../../../../static/devil-hand@2x.png';

export const DevilHand: React.FC = () => (
  <img
    src={DevilHandImg}
    alt="Grafitti-style image of a hand making the rock horns gesture"
    srcSet={`${DevilHandImg}, ${DevilHandImg2x} 2x`}
    width={59}
    height={100}
    loading="lazy"
  />
);
