import * as React from 'react';
import { observer } from 'mobx-react';
import {
  OnboardingTermsConditionsModal,
  OnboardingTermsConditionsModalProps
} from './onboarding-terms-conditions-modal';

@observer
export class OnboardingPrivacyPolicyModal extends React.Component<OnboardingTermsConditionsModalProps, {}> {
  render() {
    const {
      i18n = OnboardingPrivacyPolicyModal.i18n,
    } = this.props;

    return (
      <OnboardingTermsConditionsModal {...this.props} i18n={i18n}/>
    )
  }

  static i18n = {
    ...OnboardingTermsConditionsModal.i18n,
    title: 'Privacy Policy'
  }
}