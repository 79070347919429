import { observable } from 'mobx';
import { PlaylistSchema } from '../../types/schema';

export class PlaylistModel {
  @observable
  sync: { [playlistId: string]: Array<string> } = {};

  @observable
  tracks: { [playlistId: string]: Array<string> } = {};

  @observable
  favouritePlaylists: Array<string> = [];

  @observable
  removed: { [playlistId: string]: Array<string> } = {};
}
