import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const ChevronDown: React.SFC<SVGIconProps> = ({ size = 12, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={size * (8 / 12)} viewBox="0 0 12 8" {...props}>
    <path
      fill={color}
      d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
      transform="translate(-6 -8)"
      fillRule="evenodd"
    />
  </BaseSvg>
);
