import * as React from 'react';
import { style } from 'typestyle';
import { colorWhite } from '../theme/color';
import { px } from 'csx';
import { mediaMobileOnly, mediaTablet } from '../theme/media';
import { ButtonPill } from './button-pill';

export interface PageHomePricingHeaderProps {
  onClickLink?(event: React.MouseEvent<any>): void;
  i18n?: {
    title: string;
    subtitle: string;
    findOutMore: string;
  };
}

const DEFAULT_I18N = {
  title: 'So how much?',
  subtitle:
    "Our pricing structure is based on the average views your recent video uploads have received. When you sign-in to Lickd with your YouTube account we fetch your latest 50 videos and take an average of the video views. This allows us to estimate your audience size and put you into a price band below. Try our price calculator to find out your channel's price.",
  findOutMore: 'Find out more',
};

export const styles = {
  container: style(
    {
      $nest: {
        '& h1': {
          marginTop: '0',
          color: colorWhite.toString(),
          fontSize: px(54),
        },
        '& h2': {
          margin: '0 0 10px 0',
          color: colorWhite.toString(),
          fontSize: px(44),
        },
        '& p': {
          margin: 0,
          color: colorWhite.toString(),
        },
      },
    },
    mediaMobileOnly({
      padding: '40px 1rem 50px',
    }),
    mediaTablet({
      padding: '80px 80px 60px',
    })
  ),
  buttons: style({
    padding: '1rem 0 1rem',
  }),
};

export const PageHomePricingHeader: React.FC<PageHomePricingHeaderProps> = ({ onClickLink, i18n = DEFAULT_I18N }) => (
  <div className={styles.container}>
    <h1>Music License Pricing for YouTube</h1>
    <h2>{i18n.title}</h2>
    <p>{i18n.subtitle}</p>
    {onClickLink && (
      <div className={styles.buttons}>
        <ButtonPill href="/pricing" onClick={onClickLink}>
          {i18n.findOutMore}
        </ButtonPill>
      </div>
    )}
  </div>
);
