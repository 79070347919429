import { EnvModel } from '../../../modules/env/model';

// Based on https://github.com/audioxide/data/blob/main/components/youtube/canAutoplay.js
let canAutoplayCache;
export const canAutoplay = (): Promise<boolean> =>
  new Promise((resolve) => {
    if (EnvModel.isServer) return true; // Escape early on the server, videos will never be played; true as autoplay workarounds won't be necessary
    // let timeoutId;

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    const element = document.createElement('video');
    element.src =
      'data:video/webm;base64,GkXfo0AgQoaBAUL3gQFC8oEEQvOBCEKCQAR3ZWJtQoeBAkKFgQIYU4BnQI0VSalmQCgq17FAAw9CQE2AQAZ3aGFtbXlXQUAGd2hhbW15RIlACECPQAAAAAAAFlSua0AxrkAu14EBY8WBAZyBACK1nEADdW5khkAFVl9WUDglhohAA1ZQOIOBAeBABrCBCLqBCB9DtnVAIueBAKNAHIEAAIAwAQCdASoIAAgAAUAmJaQAA3AA/vz0AAA=';
    element.muted = true;
    element.setAttribute('muted', 'muted');
    const cacheAndResolve = (result) => {
      canAutoplayCache = result;
      clearTimeout(timeoutId); // Hoisted from parent closure
      resolve(result);
    };
    if (typeof canAutoplayCache === 'boolean') return cacheAndResolve(canAutoplayCache);
    if (isIOS) return cacheAndResolve(false);
    const playResult = element.play();
    const timeoutId = setTimeout(() => {
      cacheAndResolve(false);
    }, 250);

    if (playResult !== undefined) {
      playResult
        .then(() => cacheAndResolve(true))
        .catch((playError) =>
          playError.name !== 'NotSupportedError' ? cacheAndResolve(false) : cacheAndResolve(true)
        );
    } else {
      cacheAndResolve(true);
    }
  });
