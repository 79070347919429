import React from 'react';
import { classes, style } from 'typestyle';
import { InfoOutline } from '../atoms/icons/InfoOutline';
import { rebrand } from '../../../theme/color';
import { center, centerCenter, horizontal, vertical } from 'csstips';
import { important } from 'csx';
import { borderRadius, borderStyle } from '../../../theme/border';
interface InstructionCardProps {
  icon?: React.ReactNode;
  count?: number;
  title: string;
  isInfo?: boolean;
}
const InstructionCard: React.FC<React.PropsWithChildren<InstructionCardProps>> = ({
  children,
  icon,
  count,
  title,
  isInfo = false,
}) => {
  return (
    <div className={classes(styles.container, isInfo && styles.infoBackground)} data-test-instruction-card>
      {isInfo && (
        <span className={styles.positiontopLeft}>
          <InfoOutline size={30} />
        </span>
      )}
      {count && <span className={classes(styles.count, styles.positiontopLeft)}>{count}</span>}
      <div className={classes(icon ? styles.icon : styles.noIcon)}>{icon}</div>
      <h3 className={styles.title}>{title}</h3>
      <div>{children}</div>
    </div>
  );
};

const styles = {
  container: style(vertical, center, {
    width: '220px',
    height: '308px',
    padding: '32px 16px 24px 16px',
    border: borderStyle,
    borderRadius: borderRadius.LARGE,
    position: 'relative',
    backgroundColor: rebrand.contrast[10].toString(),
  }),
  infoBackground: style({ backgroundColor: rebrand.neutralOnDark[10].toString() }),
  count: style(centerCenter, {
    backgroundColor: rebrand.contrast[50].toString(),
    color: rebrand.contrast[10].toString(),
    height: '32px',
    width: '32px',
    borderRadius: '50px',
    display: 'flex',
    fontSize: '16px',
  }),
  title: style({
    margin: important('16px auto 8px auto'),
    fontSize: '24px',
    textAlign: 'center',
  }),
  icon: style(horizontal, centerCenter, {
    height: '100px',
    width: '100px',
    minHeight: '50px',
    flexShrink: 0,
    $nest: {
      '> img': {
        display: 'block',
        maxWidth: '100%',
        height: 'auto',
        maxHeight: '100%',
      },
    },
  }),
  noIcon: style({
    height: '50px',
  }),
  positiontopLeft: style({
    position: 'absolute',
    left: '16px',
    top: '16px',
  }),
};

export default InstructionCard;
