import { px, rem } from 'csx';
import { colorLightGrey } from './color';

export const borderRadius = {
  SMALL: rem(0.25), // 4px
  MEDIUM: rem(0.5), //8px
  LARGE: rem(1), // 16px
};

export const borderStyle = `${px(1)} solid ${colorLightGrey.toString()}`;
