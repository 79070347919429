import * as React from 'react';
import { observer } from 'mobx-react';
import { classes, style } from 'typestyle';
import { CSSProperties } from 'typestyle/lib/types';
import { px } from 'csx';
import { centerCenter } from 'csstips';
import { colorContrast, rebrand } from '../theme/color';

export interface BadgeProps {
  className?: string;
}

export const BadgeStyle: CSSProperties = {
  fontSize: px(10),
  width: px(16),
  height: px(16),
  borderRadius: px(16),
  background: rebrand.primary1.toString(),
};

export const Badge = observer(({ className, children }: React.PropsWithChildren<BadgeProps>) => (
  <span className={classes(style(BadgeStyle, centerCenter), 'badge', className)}>{children}</span>
));
