import { LicenceSchema, TrackSchema } from '../types/schema';
import { LicenceAPI } from '../types/api';

export const humanTrackName = (track: TrackSchema) => {
  return `${track.title} by ${track.artists[0].name} (${track.identity})`
};

export const stripQueryString = (path: string) => {
  if (!path) return null;

  return path.replace(/\?.*/, '');
};

export const videoAttribution = (licenceApi: LicenceSchema, referralUrl: string) => {
  const { identity, track } = licenceApi;

  return `License: ${identity}
${track.title} by ${track.artists.map(artist => artist.name).join(', ')} on Lickd.

Listen to the song here: ${referralUrl}`;
};