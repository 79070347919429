import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const TrendingUp: React.SFC<SVGIconProps> = ({ size = 20, color = '#2E2E3B', ...props }) => (
  <BaseSvg viewBox="0 0 20 12" width={size} height={size * (12 / 20)} fill={color} {...props}>
    <path
      d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"
      transform="translate(-2 -6)"
      fillRule="evenodd"
    />
  </BaseSvg>
);
