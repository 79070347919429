import * as React from 'react';
import { observer } from 'mobx-react';
import { SVGIcon } from './svg-icon';
import { ButtonCircle } from './button-circle';
import { colorGreyAccent, colorGunmetal } from '../theme/color';
import { classes, style } from 'typestyle';
import { important, px } from 'csx';
import { shadowSubtle } from '../theme/shadow';
import { transitionQuickEase } from '../theme/transition';
import { TrackSchema } from '../types/schema';

export interface ButtonPlayProps {
  className?: string;
  style?: any;
  size?: number;
  track: TrackSchema;
  currentTrack: TrackSchema;
  isPlaying: boolean;
  disabled?: boolean;
  invert?: boolean;

  onPause(): any;
  onPlay(): any;
  onLoadImmediate(track: TrackSchema): any;
}

@observer
export class ButtonPlay extends React.Component<ButtonPlayProps, any> {
  render() {
    const { props } = this;

    const isCurrent = props.currentTrack && props.track.identity === props.currentTrack.identity;

    const activePlaying = props.isPlaying && isCurrent;

    const action = !isCurrent
      ? props.onLoadImmediate.bind(null, props.track)
      : props.isPlaying
      ? props.onPause
      : props.onPlay;

    const Icon = activePlaying ? SVGIcon.Pause : SVGIcon.Play;

    const size = props.size || 28;
    const iconSize = Math.floor(size / 2) - 2;

    const className = classes(ButtonPlay.styles.container, props.className);

    return (
      <ButtonCircle style={props.style} onClick={action} className={className} size={size} data-test-track-play>
        <Icon
          className={!activePlaying && style({ marginLeft: px(2) })}
          color={colorGunmetal.toString()}
          size={iconSize}
        />
      </ButtonCircle>
    );
  }

  static styles = {
    container: style({
      transition: `background-color ${transitionQuickEase}`,
      boxShadow: shadowSubtle,
      backgroundColor: important(colorGreyAccent.toString()),

      cursor: 'pointer',
      $nest: {
        '& svg > path': {
          transition: `fill ${transitionQuickEase}`,
        },
        '& svg g': {
          transition: `fill ${transitionQuickEase}`,
        },
        '&:hover': {
          background: important(colorGreyAccent.darken(0.15).toString()),
        },
      },
    }),
  };
}
