import * as React from 'react';
import { observer } from 'mobx-react';
import { ButtonPill } from './button-pill';
import { classes, style } from 'typestyle';
import {
  center,
  centerCenter,
  centerJustified,
  horizontal,
  horizontallySpaced,
  startJustified,
  vertical,
  verticallySpaced,
} from 'csstips';
import { mediaTablet, mediaMobileOnly } from '../theme/media';
import { em, important, percent, px, url, viewWidth } from 'csx';
import { fontBody, fontHeading } from '../theme/font';
import { colorBrand, colorGunmetal, colorWhite } from '../theme/color';
import { gradientPricing } from '../theme/gradient';
import { ButtonDropdown } from './button-dropdown';
import { Alert } from './alert';
import { ChannelAvatar } from './channel-avatar';
import { ChannelSchema, PricingCalculatorSchema } from '../types/schema';
import { formatCurrency } from '../lib/currency';
import { AnalyticsController } from '../modules/analytics/controller';

export interface PricingCalculatorModel {
  channelId: string;
  loading: boolean;
  query: string;
  searching: boolean;
  channels: Array<ChannelSchema>;
  error: string;
  result: PricingCalculatorSchema;
  currency: {
    value: string;
    label: string;
  };
  currencies: Array<{
    value: string;
    label: string;
  }>;
  ratecardTypes: Array<{ value: string; label: string }>;
  selectedRatecardType: { value: string; label: string };
  ratecardType: string;
  ratecardLoading: boolean;
}

export interface PricingCalculatorProps {
  className?: string;
  model: PricingCalculatorModel;
  onChangeQuery(query: string): void;
  onChangeCurrency(value: string): void;
  onClickLink?(event: React.MouseEvent<any>): void;
  onSignUp?(event: React.MouseEvent<any>): void;
  onShowPricing?(event: React.MouseEvent<any>): void;
  onSelectRateType(type: string): void;
  onSearch(): void;
  onSubmit(channelId: string): void;
  inverted?: boolean;
  i18n?: {
    title: string;
    strap: string;
    placeholder: string;
    showPricing: string;
    search: string;
    searching: string;
    submit: string;
    loading: string;
    priceToday: string;
    priceBased(viewership: string): string;
    howCalculated: string;
    getPrice: string;
  };
  analytics: AnalyticsController;
}

@observer
export class PricingCalculator extends React.Component<PricingCalculatorProps> {
  private $input: HTMLInputElement;

  render() {
    const { className, inverted } = this.props;

    return (
      <div
        className={classes(
          PricingCalculator.styles.container,
          inverted && PricingCalculator.styles.containerInverted,
          className
        )}
      >
        <div className={PricingCalculator.styles.formContainer}>
          {this.renderStrap(inverted)}
          {this.renderSearchForm(inverted)}
          {this.renderError(inverted)}
          {this.renderSearchResults(inverted)}
          {this.renderPricingResult(inverted)}
        </div>
      </div>
    );
  }

  renderStrap = (inverted: boolean) => {
    const { model, i18n = PricingCalculator.i18n } = this.props;

    if (model.result) return null;

    return (
      <div className={classes(PricingCalculator.styles.strap, inverted && PricingCalculator.styles.strapInverted)}>
        {i18n.strap}
      </div>
    );
  };

  renderSearchForm = (inverted: boolean) => {
    const { model, i18n = PricingCalculator.i18n, onShowPricing } = this.props;

    return (
      <form onSubmit={this.handleSearch} className={PricingCalculator.styles.form}>
        <div
          className={classes(
            PricingCalculator.styles.inputGroup,
            inverted && PricingCalculator.styles.inputGroupInverted
          )}
          onClick={this.handleFocus}
        >
          <input
            ref={(el) => (this.$input = el)}
            className={classes(
              PricingCalculator.styles.inputField,
              PricingCalculator.styles.inputText,
              inverted && PricingCalculator.styles.inputFieldInverted,
              inverted && PricingCalculator.styles.inputTextInverted
            )}
            placeholder={i18n.placeholder}
            value={model.query}
            onChange={this.handleChangeQuery}
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
          />
        </div>
        <div className={PricingCalculator.styles.inputButtons}>
          <ButtonPill
            onClick={this.handleSearch}
            primary={true}
            className={PricingCalculator.styles.inputShowPricingButton}
            xlarge
          >
            {model.loading ? i18n.loading : model.searching ? i18n.searching : i18n.search}
          </ButtonPill>
          {onShowPricing && (
            <div className={PricingCalculator.styles.inputShowPricing}>
              <span>or</span>
              <ButtonPill onClick={onShowPricing} className={PricingCalculator.styles.inputShowPricingButton} xlarge>
                {i18n.showPricing}
              </ButtonPill>
            </div>
          )}
        </div>
        <div>
          <ButtonDropdown
            selected={model.selectedRatecardType}
            choices={model.ratecardTypes}
            onSelect={this.handleChangeType}
            left={true}
            muted={true}
          />
          <ButtonDropdown
            selected={model.currency}
            choices={model.currencies}
            onSelect={this.handleChangeCurrency}
            right={true}
            muted={true}
          />
        </div>
      </form>
    );
  };

  renderError = (inverted: boolean) => {
    const { model } = this.props;

    if (model.loading || model.searching || !model.error) return null;

    return (
      <div>
        <Alert type="warning" position="top-left" title="Oops!">
          {model.error}
        </Alert>
      </div>
    );
  };

  renderSearchResults = (inverted: boolean) => {
    const { model, i18n = PricingCalculator.i18n, onSubmit } = this.props;

    if (model.loading || model.searching || model.error || model.result) return null;

    return (
      <div className={PricingCalculator.styles.searchResults}>
        {model.channels.map((channel: ChannelSchema, index) => (
          <div key={index.toString()} className={PricingCalculator.styles.channel}>
            <div>
              <ChannelAvatar channel={channel} className={PricingCalculator.styles.avatar} />
            </div>
            <div>{channel.name}</div>
            <div>
              <ButtonPill onClick={() => onSubmit(channel.id)}>{i18n.getPrice}</ButtonPill>
            </div>
          </div>
        ))}
      </div>
    );
  };

  renderPricingResult = (inverted: boolean) => {
    const { model, onClickLink, i18n = PricingCalculator.i18n } = this.props;

    if (model.loading || model.searching || model.error || !model.result) return null;

    return (
      <div className={PricingCalculator.styles.results}>
        <div className={PricingCalculator.styles.resultsPanel}>
          <div
            className={PricingCalculator.styles.resultsAvatar}
            style={{ backgroundImage: url(model.result.avatar) }}
          />
          <div className={PricingCalculator.styles.resultsName}>{model.result.name}</div>
        </div>
        <div className={PricingCalculator.styles.resultsPanel}>
          <div>{i18n.priceToday}</div>
          <div className={PricingCalculator.styles.resultsPrice}>
            {formatCurrency(model.result.rate, model.result.currency)}
          </div>
          <div>{i18n.priceBased(Number(model.result.viewership).toLocaleString())}</div>
          {onClickLink && (
            <ButtonPill href={'/pricing'} onClick={onClickLink}>
              {i18n.howCalculated}
            </ButtonPill>
          )}
        </div>
      </div>
    );
  };

  handleChangeQuery = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;

    this.props.onChangeQuery(value);
  };

  handleChangeType = (type: string) => {
    this.props.onSelectRateType(type);
  };

  handleChangeCurrency = (value: string) => {
    this.props.onChangeCurrency(value);
  };

  handleSearch = (event: React.FormEvent<any>) => {
    event.preventDefault();
    this.props.analytics.sendMixpanel('User clicks find channel');
    this.props.onSearch();
  };

  handleFocus = () => {
    this.$input.focus();
  };

  static fn = {
    containerStyle: (image: string) => ({
      backgroundImage: `${gradientPricing}, ${url(image)}`,
    }),
  };

  static i18n = {
    title: 'So how much?',
    strap: 'Find your channel and we will work out your price',
    placeholder: 'Channel name',
    showPricing: 'Show pricing',
    search: 'Find channel',
    searching: 'Finding channel...',
    submit: 'Get price',
    loading: 'Calculating price...',
    priceToday: 'Your price today is',
    priceBased: (viewership: string) => `based on ${viewership} average video viewership`,
    howCalculated: 'How is this calculated?',
    getPrice: 'Get price',
  };

  static styles = {
    container: style(
      {
        ...vertical,
        ...center,
        ...startJustified,
        width: percent(100),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        color: colorGunmetal.toString(),
      },
      mediaMobileOnly({ padding: '40px 20px' }),
      mediaTablet({ padding: px(80) })
    ),
    containerInverted: style({
      color: colorWhite.toString(),
    }),
    title: style({
      fontSize: px(44),
      width: percent(100),
      margin: 0,
    }),
    strap: style(
      {
        fontFamily: fontHeading,
        fontSize: px(30),
        lineHeight: px(30),
        textAlign: 'center',
        color: colorBrand.toString(),
        margin: '0 0 10px 0',
      },
      mediaMobileOnly({
        fontSize: px(24),
        lineHeight: px(24),
      })
    ),
    strapInverted: style({
      color: colorWhite.toString(),
    }),
    formContainer: style(
      { ...vertical, ...centerCenter, width: percent(100), margin: '20px 0 40px' },
      mediaTablet({ minHeight: px(520) })
    ),
    form: style(
      { ...vertical, ...verticallySpaced(30), ...center, margin: '0 0 40px 0' },
      mediaMobileOnly({ width: percent(100) })
    ),
    inputGroup: style({
      padding: '10px',
      borderBottom: `2px solid ${colorGunmetal.toString()}`,
      width: px(420),
      maxWidth: percent(100),
    }),
    inputGroupInverted: style({
      borderBottom: important(`2px solid ${colorWhite.toString()}`),
    }),
    inputPretext: style(mediaMobileOnly({ display: 'none' })),
    inputText: style(
      {
        border: '0',
        padding: 0,
        color: colorGunmetal.toString(),
        fontFamily: fontBody,
        fontSize: px(22),
        lineHeight: px(32),
      },
      mediaMobileOnly({
        textAlign: 'center',
      })
    ),
    inputTextInverted: style({
      color: important(colorWhite.toString()),
    }),
    inputField: style({
      width: percent(100),
      background: 'transparent',
      $nest: {
        '&::placeholder': {
          color: colorGunmetal.fade(0.3).toString(),
        },
      },
    }),
    inputFieldInverted: style({
      $nest: {
        '&::placeholder': {
          color: important(colorWhite.fade(0.3).toString()),
        },
      },
    }),
    inputButtons: style(
      mediaMobileOnly({
        ...vertical,
        ...verticallySpaced(30),
      }),
      mediaTablet({
        ...horizontal,
        ...horizontallySpaced(20),
      })
    ),
    inputShowPricing: style({
      ...horizontal,
      ...horizontallySpaced(20),
      ...center,
      ...centerJustified,
    }),
    inputShowPricingButton: style(
      mediaTablet({
        fontSize: important('20px'),
      }),
      mediaMobileOnly({
        fontSize: important('16px'),
      })
    ),
    inputButton: style({
      fontSize: important('22px'),
      width: important('220px'),
    }),
    results: style(
      mediaMobileOnly({ ...vertical, ...verticallySpaced(20) }),
      mediaTablet({ ...horizontal, ...horizontallySpaced(40), ...center })
    ),
    resultsPanel: style({
      ...vertical,
      ...verticallySpaced(20),
      ...centerCenter,
      $nest: {
        '&> div': {
          textAlign: 'center',
        },
      },
    }),
    resultsAvatar: style(
      {
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: percent(50),
      },
      mediaMobileOnly({ width: viewWidth(30), height: viewWidth(30) }),
      mediaTablet({ width: viewWidth(16), height: viewWidth(16) })
    ),
    resultsName: style({
      fontSize: px(22),
      textAlign: 'center',
    }),
    resultsPrice: style({
      fontSize: px(44),
      lineHeight: px(44),
    }),
    error: style({
      color: colorGunmetal.toString(),
    }),
    subTitle: style({
      width: percent(100),
      $nest: {
        '& h3': {
          fontSize: important('30px'),
        },
      },
    }),
    benefits: style({
      width: percent(100),
    }),
    signUp: style({
      margin: '30px 0 0 0',
      width: percent(100),
    }),
    searchResults: style({
      maxHeight: px(220),
      overflowY: 'scroll',
      width: percent(100),
      maxWidth: px(720),
    }),
    channel: style({
      ...horizontal,
      ...horizontallySpaced(10),
      ...center,
      padding: '10px 0',
      $nest: {
        '&> div:nth-child(1)': {
          width: px(60),
        },
        '&> div:nth-child(2)': {
          flex: 1,
          lineHeight: em(1.4),
        },
        '&> div:nth-child(3)': {
          width: px(110),
          textAlign: 'right',
        },
      },
    }),
    avatar: style({
      width: px(60),
      height: px(60),
    }),
    avatarLarge: style({
      width: px(120),
      height: px(120),
    }),
  };
}
