/* eslint-disable react/display-name */
import React from 'react';
import { classes, style } from 'typestyle';
import { viewHeight } from 'csx';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { RegistrationSteps } from '../../../modules/page/registration/model';
import { EnvModel } from '../../../modules/env/model';
import { Loading } from '../../loading/loading';
import { AccountInformation } from '../organisms/registration/AccountInformation';
import { PaymentPlans } from '../organisms/registration/PaymentPlans';
import { Complete } from '../organisms/registration/Complete';
import { AnyComponent } from '../utilities/objects';
import { headerFullHeight } from '../config';
import { RegistrationProgress } from '../organisms/registration/RegistrationProgress';
import { PaymentPlansV2 } from '../organisms/registration/PaymentPlans-V2';
import { rebrand } from '../../../theme/color';
import { spacing } from '../../../theme/spacing';
import { mediaDesktop } from '../utilities/mediaQueries';
import { Container } from '../atoms/Container';

const progressBarHeight = 87;
const minBodyHeight = `calc(100vh - ${headerFullHeight + progressBarHeight}px)`;

const styles = {
  onboardingWrapper: style({
    $nest: {
      '&.darker': {
        backgroundColor: rebrand.neutralOnDark[10].toString(),
      },
    },
  }),
  onboardingContent: style(
    {
      minHeight: minBodyHeight,
      padding: `${spacing.LARGE} 0`,
      $nest: {
        '.darker &': {
          paddingTop: spacing.XLARGE,
        },
      },
    },
    mediaDesktop({
      padding: `40px 0 ${spacing.XXLARGE}`,
      $nest: {
        '.darker &': {
          paddingTop: spacing.XXXLARGE,
        },
      },
    })
  ),
  loading: style({
    marginTop: viewHeight(25),
  }),
};

export const Registration = inject(deps)(
  observer(
    ({
      model: {
        page: {
          registration: { step, stepIndex },
        },
        ui: { projectSubscriptionEnabled },
      },
    }: ComponentProps) => {
      const stepComponents: { [key in RegistrationSteps]: AnyComponent } = {
        [RegistrationSteps.ACCOUNT_INFORMATION]: AccountInformation,
        [RegistrationSteps.PLANS]: projectSubscriptionEnabled ? PaymentPlansV2 : PaymentPlans,
        [RegistrationSteps.COMPLETE]: Complete,
      };

      const StepComponent = step in stepComponents && stepComponents[step];
      const darkerBg = projectSubscriptionEnabled && step === RegistrationSteps.PLANS;

      return (
        <div className={classes(styles.onboardingWrapper, darkerBg ? 'darker' : '')}>
          <RegistrationProgress step={stepIndex} />
          <div className={styles.onboardingContent}>
            <Container wide>
              {EnvModel.isServer || !StepComponent ? (
                <Loading className={styles.loading} text="Loading account information..." />
              ) : (
                <StepComponent />
              )}
            </Container>
          </div>
        </div>
      );
    }
  )
);
