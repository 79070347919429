import { API, APIResponse, APIPaginatedResponse, APIError, FormErrors } from '../types';
import { action } from 'mobx';
import {
  ChannelSchema,
  CardSchema,
  OrderSchema,
  CouponSchema,
  UserPreferencesSchema,
  InviteSchema,
  LicenceSchema,
  WithInclude,
  ClaimIssueSchema,
  ImageSchema,
  SubscriptionInformationSchema,
  PlaylistSchema,
  ReferrerSchema,
  CreditSchema,
} from '../../../types/schema';
import { User, UserUpdateableFields } from '../../../types/user';
import { AccountsAPI } from '../../../types/api';
import { YouTubeVideo } from '../../../types/youtube';
import { BillingAddress } from '../../../types';
import encodeQueryString from '../../../lib/encodeQueryString';

type LicenceWithImages = WithInclude<LicenceSchema, 'images', ImageSchema>;
export type ClaimIssueWithLicenceWithImages = WithInclude<ClaimIssueSchema, 'licence', LicenceWithImages>;

export class UserAPIController extends API {
  @action
  getToken = async (code: string, identifiers: { [key: string]: any }) => {
    const url = `/user/youtube/create?code=${code}&${encodeQueryString(identifiers)}`;
    const resp = await this.request.get({ url });

    return (await resp.json()) as { token: string };
  };

  @action
  setDefaultChannel = async (default_channel: string) => {
    const url = `/user/session`;
    const body = JSON.stringify({ default_channel });
    const resp = await this.request.patch({ url, body });

    return (await resp.json()) as APIResponse<User>;
  };

  @action
  setVatNumber = async (vat_number: string) => {
    const url = `/user/session`;
    const body = JSON.stringify({ vat_number });
    const resp = await this.request.patch({ url, body });

    return (await resp.json()) as APIResponse<User>;
  };

  @action
  setContactDetails = async (values: UserUpdateableFields) => {
    const url = `/user/session`;
    const body = JSON.stringify(values);
    const resp = await this.request.patch({ url, body });
    await APIError.checkResponse<FormErrors>(resp);
    return (await resp.json()) as APIResponse<User>;
  };

  @action
  setMarketingPreferences = async (marketing_allowed: boolean) => {
    const url = `/user/session`;
    const body = JSON.stringify({ marketing_allowed });
    const resp = await this.request.patch({ url, body });

    return (await resp.json()) as APIResponse<User>;
  };

  @action
  getAccounts = async (userIdentity: string) => {
    const url = `/user/${userIdentity}/accounts`;
    const resp = await this.request.get({ url });
    return (await resp.json()) as APIResponse<AccountsAPI>;
  };
  @action
  getReferrer = async (userIdentity: string) => {
    const url = `/user/${userIdentity}/referrer`;
    const resp = await this.request.get({ url });
    return (await resp.json()) as ReferrerSchema;
  };
  @action
  getPreferences = async (userIdentity: string) => {
    const url = `/user/${userIdentity}/preferences`;
    const resp = await this.request.get({ url });
    return (await resp.json()) as APIResponse<UserPreferencesSchema>;
  };

  @action
  setPreferences = async (userIdentity: string, preferences: Partial<UserPreferencesSchema>) => {
    const url = `/user/${userIdentity}/preferences`;
    const body = JSON.stringify(preferences);
    return await this.request.patch({ url, body });
  };

  @action
  deleteAccount = async () => {
    const url = `/user/session`;
    const body = JSON.stringify({ delete_account: true });
    const resp = await this.request.patch({ url, body });

    return (await resp.json()) as APIResponse<User>;
  };

  @action
  downloadAccountData = async () => {
    const url = `/user/session`;
    const body = JSON.stringify({ download_request: true });
    const resp = await this.request.patch({ url, body });

    return (await resp.json()) as APIResponse<User>;
  };

  @action
  updateSurvey = async (userIdentity: string, survey: string, responses: { [question: string]: string }) => {
    const url = `/user/${userIdentity}/surveys`;
    const body = JSON.stringify({ survey, responses });

    return await this.request.post({ url, body });
  };

  @action
  clearPreferences = async (userIdentity: string) => {
    const url = `/user/${userIdentity}/preferences`;
    const body = JSON.stringify({
      daily_newmusic_email: false,
      daily_recommendation_email: false,
      monthly_newmusic_email: false,
      monthly_recommendation_email: false,
      product_announcement_email: false,
      thirdparty_promotions_email: false,
      twitter_order_announcement: false,
      weekly_newmusic_email: false,
      weekly_recommendation_email: false,
    });

    return await this.request.patch({ url, body });
  };

  @action
  getYouTubeChannels = async (userIdentity: string) => {
    const url = `/user/${userIdentity}/youtube/channels`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<Array<ChannelSchema>>;

    return json;
  };

  @action
  getYouTubeChannel = async (userIdentity: string, identity: string) => {
    const url = `/user/${userIdentity}/youtube/channels/${identity}`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<ChannelSchema>;

    return json;
  };

  @action
  getYouTubeChannelVideos = async (userIdentity: string, identity: string, page = 1) => {
    const url = `/user/${userIdentity}/youtube/channels/${identity}/videos?page=${page}`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIPaginatedResponse<Array<YouTubeVideo>>;

    return json;
  };

  @action
  getYouTubeVideos = async (userIdentity: string, visible: string, page = 1) => {
    const visibleType = visible === 'all' ? 'public,private,unlisted' : visible;
    const url = `/user/${userIdentity}/youtube/videos?filter=${visibleType}&page=${page}&direction=desc&include=channel,claims,licences`;
    const resp = await this.request.get({ url });
    await APIError.checkResponse(resp);
    const json = (await resp.json()) as APIPaginatedResponse<Array<YouTubeVideo>>;

    return json;
  };

  @action
  refreshYouTubeVideos = async (userIdentity: string, identity: string) => {
    const url = `/user/${userIdentity}/youtube/channels/${identity}/videos/refresh`;
    const resp = await this.request.get({ url });
    await APIError.checkResponse(resp);
    const json = (await resp.json()) as { success: boolean };

    return json;
  };

  @action
  setChannelVertical = async (userIdentity: string, identity: string, vertical: string) => {
    const url = `/user/${userIdentity}/youtube/channels/${identity}`;
    const body = JSON.stringify({ channel_category: vertical });
    const resp = await this.request.patch({ url, body });
    await APIError.checkResponse(resp);
    const json = (await resp.json()) as APIPaginatedResponse<Array<YouTubeVideo>>;

    return json;
  };

  @action
  setChannelGenreTags = async (userIdentity: string, identity: string, genreTag: Array<number>) => {
    const url = `/user/${userIdentity}/youtube/channels/${identity}/tags`;
    const body = JSON.stringify({ tags: genreTag });
    const resp = await this.request.put({ url, body });
    await APIError.checkResponse(resp);
    const json = (await resp.json()) as { success: boolean };

    return json;
  };

  @action
  createBillingAddress = async (userIdentity: string, address: BillingAddress) => {
    const url = `/user/${userIdentity}/addresses`;
    // const body = JSON.stringify(address);
    const body = JSON.stringify({
      ...address,
      country_id: address.country,
    });

    const resp = await this.request.post({ url, body });

    return await resp.json();
  };

  @action
  createCoupon = async (userIdentity: string, channelId: string) => {
    const url = `/user/${userIdentity}/coupon`;
    const body = JSON.stringify({ channelId });
    const resp = await this.request.post({ url, body });

    return (await resp.json()) as APIResponse<CouponSchema>;
  };

  @action
  getBillingAddresses = async (userIdentity: string) => {
    const url = `/user/${userIdentity}/addresses`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<Array<BillingAddress>>;

    return json;
  };

  @action
  getPaymentCards = async (userIdentity: string) => {
    const url = `/user/${userIdentity}/payments`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<Array<CardSchema>>;

    return json;
  };

  @action
  getLicencedTracks = async (userIdentity: string, page = 1, perPage = 20) => {
    const url = `/user/${userIdentity}/licences?page=${page}&perPage=${perPage}`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIPaginatedResponse<Array<LicenceSchema>>;

    return json;
  };

  @action
  getLicence = async (userIdentity: string, identity: string) => {
    const url = `/user/${userIdentity}/licences/${identity}?include=track.artists,track.images`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<LicenceSchema>;

    return json;
  };

  @action
  getClaimIssues = async (userIdentity: string, channelIdentity: string, videoIdentity: string) => {
    const url = `/user/${userIdentity}/youtube/channels/${channelIdentity}/videos/${videoIdentity}/issues?include=licence.track.images,licence.track.artists`;
    const response = await this.request.get({ url });
    await APIError.checkResponse(response);

    const json = (await response.json()) as APIResponse<Array<ClaimIssueWithLicenceWithImages>>;

    return json;
  };

  @action
  getSubscriptionInformation = async (userIdentity: string, channelIdentity = '') => {
    const channel = channelIdentity ? `&channel_id=${channelIdentity}` : '';
    const url = `/user/${userIdentity}/subscriptions?active=1${channel}`;

    const response = await this.request.get({ url });
    await APIError.checkResponse(response);

    const json = (await response.json()) as APIResponse<Array<SubscriptionInformationSchema>>;

    return json;
  };

  getSubscriptions = async (userIdentity: string, channelIdentity = '') => {
    const channel = channelIdentity ? `&channel_id=${channelIdentity}` : '';
    const url = `/user/${userIdentity}/subscriptions?${channel}`;

    const response = await this.request.get({ url });
    await APIError.checkResponse(response);

    const json = (await response.json()) as APIResponse<Array<SubscriptionInformationSchema>>;

    return json;
  };

  @action
  createClaimIssue = async (
    userIdentity: string,
    channelIdentity: string,
    videoIdentity: string,
    licenceIdentity: string,
    note?: string
  ): Promise<APIResponse<never>> => {
    const url = `/user/${userIdentity}/youtube/channels/${channelIdentity}/videos/${videoIdentity}/issues`;
    const body = JSON.stringify({
      licence_id: licenceIdentity,
      note,
    });
    const response = await this.request.post({ url, body });
    await APIError.checkResponse(response);

    return response.json();
  };

  @action
  getOrders = async (userIdentity: string, page = 1, dateOrder = 'DESC', channelId?: string) => {
    const url = `/user/${userIdentity}/orders?page=${page}&order=${dateOrder}${
      channelId ? `&youtube_channel_id=${channelId}` : ''
    }`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIPaginatedResponse<Array<OrderSchema>>;

    return json;
  };

  @action
  getOrder = async (userIdentity: string, identity: string) => {
    const url = `/user/${userIdentity}/orders/${identity}`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<OrderSchema>;

    return json;
  };

  @action
  downloadInvoice = async (userIdentity: string, identity: string) => {
    const url = `/user/${userIdentity}/orders/${identity}/invoice`;
    const resp = await this.request.get({
      url,
      headers: {
        Accept: 'application/pdf',
      },
    });
    const blob = await resp.blob();

    return blob;
  };

  @action
  unlinkSpotify = async (userIdentity: string) => this.unlink(userIdentity, 'spotify');

  @action
  unlinkTwitter = async (userIdentity: string) => this.unlink(userIdentity, 'twitter');

  @action
  unlinkDropbox = async (userIdentity: string) => this.unlink(userIdentity, 'dropbox');

  @action
  unlinkFacebook = async (userIdentity: string) => this.unlink(userIdentity, 'facebook');

  @action
  private unlink = async (userIdentity: string, service: string) => {
    const url = `/user/${userIdentity}/${service}`;
    const resp = await this.request.del({ url });

    return resp.json();
  };

  @action
  getInvites = async (userIdentity: string) => {
    const url = `/user/${userIdentity}/invite`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<Array<InviteSchema>>;

    return json;
  };

  @action
  createInvite = async (userIdentity: string, name: string, email: string) => {
    const url = `/user/${userIdentity}/invite`;
    const body = JSON.stringify({ name, email });
    const resp = await this.request.post({ url, body });

    return await resp.json();
  };

  @action
  addChannel = async (userIdentity: string, channel: ChannelSchema) => {
    const url = `/user/${userIdentity}/youtube/channels`;
    const body = JSON.stringify(channel);
    const resp = await this.request.post({ url, body });

    return await resp.json();
  };

  @action
  getCoupons = async (userIdentity: string): Promise<APIPaginatedResponse<CouponSchema[]>> => {
    const url = `/user/${userIdentity}/coupons`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIPaginatedResponse<CouponSchema[]>;

    return json;
  };

  @action
  getCredits = async (userIdentity: string): Promise<APIResponse<CreditSchema[]>> => {
    const url = `/user/${userIdentity}/credits`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<CreditSchema[]>;

    return json;
  };

  @action
  getRecommendedTrackPlaylist = async (
    userIdentity: string,
    channelIdentity: string
  ): Promise<APIResponse<PlaylistSchema>> => {
    const url = `/user/${userIdentity}/recommended-playlists/${channelIdentity}`;
    const response = await this.request.get({ url });
    await APIError.checkResponse(response);
    return response.json();
  };


  @action
  markRegistrationStepAsCompleted = async (userIdentity: string, step: string) => {
    const url = `/user/${userIdentity}/completed-registration-steps`;
    const body = JSON.stringify({ step });
    const resp = await this.request.patch({ url, body });

    return resp.json()
  };
}
