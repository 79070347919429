import { HttpError } from '../types';

class BaseError {
  constructor(message: string) {
    Error.call(this, message);
  }
}

BaseError.prototype = new Error();

export class HttpRequestError extends BaseError implements HttpError {
  public readonly name = 'HttpRequestError';
  public readonly message: string;

  constructor(public readonly response: Response, public readonly body: any) {
    super(response.statusText);
    this.message = response.statusText;
  }
}
