/* eslint-disable react/no-find-dom-node */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ScrollOverSensor from 'react-visibility-sensor';
import { observer } from 'mobx-react';
import { ImagePreloader } from '../types/fn';
import { url } from 'csx';
import { deps, inject, StatefulComponent } from '../lib/component';

export interface ResponsiveImageProps {
  identity: string;
  preload: ImagePreloader;
  className?: string;
  style?: any;
  Element?: any;
  href?: string;
  asBackground?: boolean;
  dangerouslySetInnerHTML?: any;
  onClick?(event: React.MouseEvent<any>): any;
  wrapBackground?(bg: string): string;
  sizeTarget?: any;
  defaultSize?: {
    width: number;
    height: number;
    type?: string;
    placeholder?: boolean;
  };
}

export interface ResponsiveImageState {
  src: string;
  loaded: boolean;
}

@inject(deps)
@observer
export class ResponsiveImage extends StatefulComponent<ResponsiveImageProps, ResponsiveImageState> {
  private _mounted: boolean;
  private $el: any;

  static defaultProps = {
    defaultSize: { width: 64, height: 64, placeholder: false },
  };

  constructor(props: ResponsiveImageProps) {
    super(props);
    this.state = ResponsiveImage.getDefaultState(props);
  }

  static getDefaultState = (props: ResponsiveImageProps) => ({
    src: props.preload(props.identity, props.defaultSize),
    loaded: false,
  });

  render() {
    const {
      model,
      controller,
      identity,
      preload,
      defaultSize,
      style = {},
      asBackground,
      wrapBackground,
      Element,
      ...otherProps
    } = this.props;
    const { src, loaded } = this.state;
    let content;

    const props = {
      ...otherProps,
      style,
      src,
    };

    if (asBackground) {
      const backgroundImage = wrapBackground ? wrapBackground(url(src)) : url(src);

      content = <div ref={(el) => (this.$el = el)} {...otherProps} style={{ ...style, backgroundImage }} />;
    } else if (!Element) {
      content = <img ref={(el) => (this.$el = el)} {...props} />;
    } else if (Element) {
      content = <Element ref={(el) => (this.$el = el)} {...props} />;
    }

    return (
      <ScrollOverSensor
        partialVisibility
        onChange={() => {
          if (!loaded) {
            this.loadImage();
          }
        }}
      >
        {content}
      </ScrollOverSensor>
    );
  }

  componentDidUpdate(previousProps: ResponsiveImageProps): void {
    if (this.props.identity != previousProps.identity) {
      this.setState(ResponsiveImage.getDefaultState(this.props));
    }
  }

  componentDidMount() {
    this._mounted = true;
    this.loadImage();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  loadImage = () => {
    if (!this._mounted || this.state.loaded) return;

    const node = ReactDOM.findDOMNode(this.props.sizeTarget || this.$el) as Element; // Based on the render function above, we know it's an Element

    if (node && ResponsiveImage.shouldLoad(node)) {
      const style = getComputedStyle(node);
      const width = Math.ceil(parseFloat(style.width));
      const height = Math.ceil(parseFloat(style.height));

      const widthLargest = width >= height;

      const src = this.props.preload(this.props.identity, {
        width: widthLargest ? width : 0,
        height: widthLargest ? 0 : height,
        type: this.props.defaultSize.type,
      });

      const img = new Image();
      img.onload = () => this._mounted && this.setState({ src, loaded: true });
      img.src = src;
    }
  };

  static shouldLoad = (el: Element, distance = 0) => {
    const { innerWidth, innerHeight } = window;
    const { left, right, top, bottom } = el.getBoundingClientRect();

    return (
      left - distance < innerWidth && right + distance > 0 && top - distance < innerHeight && bottom + distance > 0
    );
  };
}
