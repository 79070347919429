import * as React from 'react';
import { Component, deps, inject, observer } from '../lib/component';
import { AddRemoveCompat } from '../components/compat/add-remove-compat';
import { PlayButtonCompat } from '../components/compat/play-button-compat';
import { Loading } from '../components/loading/loading';
import { PaginationSelect } from '../components/pagination/select';
import { PageAccountLicences } from '../components/page-account-licences';
import { ArtistSchema, LicenceSchema, TrackSchema } from '../types/schema';
import { AccountLayout } from '../components/project-happy/templates/AccountLayout';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AccountLicencesPageProps {}

@inject(deps)
@observer
export class AccountLicencesPage extends Component<AccountLicencesPageProps> {
  render() {
    const { isMobile } = this.props.model.ui;
    const model = this.props.model.page.accountLicences;
    const { handleClickLink } = this.props.controller.ui;

    const props = {
      model,
      isMobile,
      AddRemove: AddRemoveCompat,
      PlayButton: PlayButtonCompat,
      preload: this.props.controller.image.preloadImage,
      preloadWaveform: this.props.controller.image.preloadWaveformSvg,
      onClickLink: handleClickLink,
      onDownloadAudio: this.handleDownloadAudio,
      onAttribution: this.handleViewAttribution,
      onViewOrder: this.handleViewOrder,
      i18n: this.props.model.content.accountLicensesI18n,
      analytics: this.props.controller.analytics,
      user: this.props.model.user,
      showChannelAddModal: this.props.controller.modal.showChannelAddModal,
      sendMixpanel: this.props.controller.analytics.sendMixpanel,
      location: this.props.model.router.location.pathname,
    };

    return (
      <AccountLayout>
        {model.loadingMore && (
          <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '120px', width: '100%' }}
          >
            <Loading />
          </div>
        )}
        {!model.loadingMore && <PageAccountLicences {...props} />}
        {!model.loadingMore && (
          <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: 20 }}
          >
            <PaginationSelect
              isMobile={isMobile}
              pagination={model.pagination}
              loadPage={this.props.controller.page.accountLicences.loadPage}
              pathRoot={`/account/licenses`}
            />
          </div>
        )}
      </AccountLayout>
    );
  }

  handleDownloadAudio = (licenceId: string) => {
    this.props.controller.modal.showDownloadModal(licenceId);
  };

  handleViewAttribution = (licenceId: string) => {
    this.props.controller.modal.showAttributionModal(licenceId);
  };

  handleViewOrder = (licence: LicenceSchema) => {
    this.props.model.router.push(`/account/order/${licence.order_id}`);
  };
}
