import { observable } from 'mobx';

export class AboutPageModel {
  @observable
  loading: boolean = false;

  @observable
  page_title: string = null;

  @observable
  page_subtitle: string = null;

  @observable
  definition_title: string = null;

  @observable
  definition_content: string = null;

  @observable
  cover_image: string = null;
  
  @observable
  section_1_title: string = null;
  
  @observable
  section_1_content: string = null;
  
  @observable
  section_1_image: string = null;

  @observable
  section_2_title: string = null;

  @observable
  section_2_content: string = null;

  @observable
  section_2_image: string = null;

  @observable
  section_3_title: string = null;

  @observable
  section_3_content: string = null;

  @observable
  section_3_image: string = null;

  @observable
  quote_content: string = null;

  @observable
  quote_by: string = null;

  @observable
  cta_title: string = null;

  @observable
  cta_content: string = null;

  @observable
  cta_button: string = null;

  @observable
  team_title: string = null;

  @observable
  team_strap: string = null;

  @observable
  team_members: Array<{
    avatar: string;
    name: string;
    role: string;
  }> = [];
}