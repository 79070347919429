import * as React from 'react';
import { BaseSvg, SVGIconProps } from '../BaseSvg';

export const LickdRebrandLogo: React.SFC<SVGIconProps> = ({ size = 66, color = '#FFF', ...props }) => (
  <BaseSvg version="1.2" width={size} height={size * (48 / 66)} viewBox="0 0 66 48" {...props}>
    <g fill={color} fillRule="evenodd">
      <path
        d="M52.774 23.28c1.213-.441 1.31-1.48 1.014-2.296l-2.96-8.131c-.34-.933-1.106-1.444-2.252-1.028l-1.33.484 4.174 11.463 1.354-.492Z"
        fillRule="nonzero"
      />
      <path d="M0 20.262 10.083 48 65.95 27.742 55.865 0 0 20.262Zm10.566 2.453-3.01 1.098 6.047 16.61 7.748-2.82-.942-2.585-4.737 1.722-5.106-14.025Zm9.488-3.449L17.02 20.37l6.045 16.614 3.037-1.105-6.048-16.612Zm3.294 3.234 3.18 8.737c1.019 2.796 3.474 3.512 6.16 2.53 2.52-.917 3.778-3.115 2.862-5.632l-.747-2.051-2.73.993.686 1.888c.34.932-.038 1.783-.995 2.13-1.026.373-1.83-.02-2.237-1.138l-3.088-8.48c-.357-.98-.113-1.834.984-2.233.982-.357 1.808.029 2.156.984l.695 1.91 2.73-.993-.797-2.19c-.897-2.465-3.282-3.207-5.732-2.315-2.615.95-4.093 3.204-3.127 5.86Zm12.33-8.92-3.034 1.105 6.048 16.613 3.034-1.103-2.002-5.499.357-2.505 4.843 6.84 3.22-1.172-6.778-9.038.66-7.549-2.963 1.078-.653 8.733-2.732-7.502Zm7.626-2.739 4.644-1.69c2.473-.9 4.868-.346 5.827 2.286l3.13 8.598c1.026 2.82-.436 4.829-2.933 5.737l-4.62 1.682-6.048-16.613Z" />
    </g>
  </BaseSvg>
);

// <svg width="66" height="48" xmlns="http://www.w3.org/2000/svg">
//   <g fill="#FFF" fill-rule="evenodd">
//     <path
//       d="M52.774 23.28c1.213-.441 1.31-1.48 1.014-2.296l-2.96-8.131c-.34-.933-1.106-1.444-2.252-1.028l-1.33.484 4.174 11.463 1.354-.492Z"
//       fill-rule="nonzero"
//     />
//     <path d="M0 20.262 10.083 48 65.95 27.742 55.865 0 0 20.262Zm10.566 2.453-3.01 1.098 6.047 16.61 7.748-2.82-.942-2.585-4.737 1.722-5.106-14.025Zm9.488-3.449L17.02 20.37l6.045 16.614 3.037-1.105-6.048-16.612Zm3.294 3.234 3.18 8.737c1.019 2.796 3.474 3.512 6.16 2.53 2.52-.917 3.778-3.115 2.862-5.632l-.747-2.051-2.73.993.686 1.888c.34.932-.038 1.783-.995 2.13-1.026.373-1.83-.02-2.237-1.138l-3.088-8.48c-.357-.98-.113-1.834.984-2.233.982-.357 1.808.029 2.156.984l.695 1.91 2.73-.993-.797-2.19c-.897-2.465-3.282-3.207-5.732-2.315-2.615.95-4.093 3.204-3.127 5.86Zm12.33-8.92-3.034 1.105 6.048 16.613 3.034-1.103-2.002-5.499.357-2.505 4.843 6.84 3.22-1.172-6.778-9.038.66-7.549-2.963 1.078-.653 8.733-2.732-7.502Zm7.626-2.739 4.644-1.69c2.473-.9 4.868-.346 5.827 2.286l3.13 8.598c1.026 2.82-.436 4.829-2.933 5.737l-4.62 1.682-6.048-16.613Z" />
//   </g>
// </svg>;
