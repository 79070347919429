import * as React from 'react';
import { Component, deps, inject, observer } from '../../../lib/component';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { classes, style } from 'typestyle';
import isEmpty from 'lodash/isEmpty';
import { ChannelSchema } from '../../../types/schema';
import { ChannelAvatar } from '../atoms/ChannelAvatar';
import { px } from 'csx';
import { betweenJustified, center, flexRoot } from 'csstips';
import { rebrand } from '../../../theme/color';
import { SemanticInformationType } from '../utilities/types';
import { CSSTransition } from 'react-transition-group';
import { transitionExpandFade } from '../../../theme/transition';
import { RatecardProgressBar } from './RatecardProgessBar';
import { spacing } from '../../../theme/spacing';
import { RemainingCredits } from '../atoms/RemainingCredits';
import { mixinTextEllipsis } from '../../../theme/mixin';

const styles = {
  card: style({
    background: 'white',
    borderRadius: px(8),
    color: rebrand.dark1.toString(),
    padding: `${spacing.DEFAULT} 0`,
    maxWidth: px(320),
    $nest: {
      '& + *': {
        marginTop: spacing.DEFAULT,
      },
    },
  }),
  header: style(flexRoot, {
    padding: `0 ${spacing.DEFAULT} ${spacing.DEFAULT}`,
    borderBottom: '1px solid',
    borderBottomColor: rebrand.neutralOnDark[20].toString(),
  }),
  channelAvatar: style({
    marginRight: px(8),
  }),
  channelName: style(mixinTextEllipsis, {
    margin: 0,
    fontSize: px(24),
    marginRight: 'auto',
  }),
  remainingCredits: style({
    flexShrink: 0,
    marginLeft: spacing.XSMALL,
    alignSelf: 'center',
  }),
  statusInformation: style(flexRoot, betweenJustified, center, {
    padding: spacing.DEFAULT,
    paddingBottom: 0,
    $nest: {
      '& + *': {
        marginTop: spacing.DEFAULT,
      },
    },
  }),
  subscriptionStatusText: style({
    fontSize: px(12),
    fontWeight: 700,
    margin: 0,
  }),
  subscribedText: style({
    color: rebrand.secondary['70'].toString(),
  }),
  unsubscribedText: style({
    color: rebrand.semantic[SemanticInformationType.WARNING]['60'].toString(),
  }),
};

interface ChannelCardProps {
  channel: ChannelSchema;
  showChannelSwitcher?: boolean;
  showRatecardProgressBar?: boolean;
  showCredits?: boolean;
  className?: string;
}

@inject(deps)
@observer
export class ChannelCard extends Component<ChannelCardProps> {
  render() {
    const {
      channel,
      showChannelSwitcher = false,
      showRatecardProgressBar = true,
      showCredits = true,
      className,
      model: {
        user: { channel: selectedChannel, isSubscribedToLegacyPlan },
      },
      controller: {
        modal: { changeChannel, showChannelSelectModal },
      },
    } = this.props;

    // Don't show anything if there's no channel provided
    if (isEmpty(channel)) return null;

    const isSubscribed = channel.is_subscribed;
    const isSelected = !isEmpty(selectedChannel) && selectedChannel.id === channel.id;
    const planType = isSubscribed && channel.subscription ? `${channel.subscription.plan_name} Plan` : 'Creator Plan';

    return (
      <section data-test-channel-card={channel.name} className={classes(styles.card, className ? className : '')}>
        <header data-test-channel-card-name className={styles.header}>
          <ChannelAvatar className={styles.channelAvatar} channel={channel} />
          <h3 className={styles.channelName}>{channel.name}</h3>

          {showCredits && (
            <RemainingCredits creditsAvailable={channel.credits_available} className={styles.remainingCredits} />
          )}
        </header>
        <div className={styles.statusInformation}>
          {isSubscribed && (
            <p className={classes(styles.subscriptionStatusText)}>
              {planType} {!isSubscribedToLegacyPlan && <span className={styles.subscribedText}>Active</span>}
            </p>
          )}
          {!isSubscribed && (
            <p className={classes(styles.subscriptionStatusText, styles.unsubscribedText)}>Not subscribed</p>
          )}
          {isSelected && !showChannelSwitcher && (
            <Button
              prominence={ButtonProminence.DISABLED}
              size={ButtonSize.SMALL}
              disabled={true}
              data-test-channel-card-disabled-channel-button
            >
              Current channel
            </Button>
          )}
          {!isSelected && (
            <Button
              prominence={ButtonProminence.SECONDARY}
              size={ButtonSize.SMALL}
              onClick={() => changeChannel(channel)}
              data-test-channel-card-select-channel-button
            >
              Switch to channel
            </Button>
          )}
          {isSelected && showChannelSwitcher && (
            <Button
              prominence={ButtonProminence.SECONDARY}
              size={ButtonSize.SMALL}
              onClick={(e) => {
                e.stopPropagation();
                showChannelSelectModal();
              }}
              data-test-change-channel-button
            >
              Change channel
            </Button>
          )}
        </div>
        {selectedChannel && showRatecardProgressBar && (
          <CSSTransition in={isSelected} {...transitionExpandFade}>
            <RatecardProgressBar ratecardStatistics={selectedChannel.ratecard_statistics} />
          </CSSTransition>
        )}
      </section>
    );
  }
}
