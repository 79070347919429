import * as React from 'react';
import { observer } from 'mobx-react';
import { ModalChildStyle } from './modal';
import { style } from 'typestyle';
import { colorBrand, colorContrast, rebrand } from '../theme/color';
import { percent, px, rem } from 'csx';
import { ScrollableOverlay } from './scrollable-overlay/scrollable-overlay';
import overlayStyles, { ScrollableOverlayBodyStyle } from '../styles/scrollable-overlay';
import {
  centerCenter,
  endJustified,
  flexRoot,
  horizontal,
  horizontallySpaced,
  vertical,
  verticallySpaced,
} from 'csstips';
import { CopyBox } from './copy-box';
import { Alert } from './alert';
import { AnalyticsController } from '../modules/analytics/controller';
import { attributionContent } from './project-happy/utilities/attributionText';
import { resetAnchorStyles } from '../theme/reset';
import { LabelledCheckbox } from './project-happy/atoms/controls/LabelledCheckbox';
import { spacing } from '../theme/spacing';
import { Button } from './project-happy/atoms/button/Button';
import { ButtonProminence, ButtonSize } from './project-happy/atoms/button/buttonStyles';
import { DownloadIcon } from './project-happy/atoms/icons/DownloadIcon';
import { Banner } from './project-happy/atoms/Banner';
import { SemanticInformationType } from './project-happy/utilities/types';
import { Link } from './project-happy/atoms/Link';

export interface DownloadModalModel {
  show: boolean;
  agree: boolean;
  loading: boolean;
  downloadError: boolean;
  downloadURL: string;
  attribution?: string;
  attributionError: boolean;
  artist: string;
  shortId: string;
  track: string;
  downloadLimitReached: boolean;
  licenseIdentity: string;
}

export interface DownloadModalProps {
  onRequestClose(): void;
  onAgreeToTerms(agree: boolean): void;
  onDownloadOriginal?(identity: string): Promise<any>;
  onDownloadInstrumental?(): void;
  onDownloadVocal?(): void;
  onSendAllToDropbox?(): void;
  model: DownloadModalModel;
  analytics: AnalyticsController;
  i18n?: {
    title: string;
    strap: string;
    agree: string;
    preparing: string;
    download: string;
    original: string;
    instrumental: string;
    vocal: string;
    dropbox: string;
    close: string;
    referralURL: string;
  };
}

export interface DownloadModalState {
  accept: boolean;
  isDownloading: boolean;
}

const styles = {
  wrapper: style({ maxWidth: px(440) }),
  body: style(ScrollableOverlayBodyStyle, {
    padding: '1.5rem 0 0',
    borderTop: `1px solid ${rebrand.light1.toString()}`,
  }),
  banner: style({
    marginBottom: spacing.LARGE,
  }),
  errorLink: style({
    color: 'inherit',
    fontWeight: 'normal',
    textDecoration: 'underline',
  }),
  smallText: style({
    fontSize: rem(0.75),
    marginTop: 0,
  }),
  copyBox: style({
    marginBottom: spacing.LARGE,
    overflowWrap: 'break-word',
  }),
  bold: style({
    textAlign: 'center',
    fontWeight: 700,
    margin: '1.5rem 0 0',
  }),
  buttons: style(flexRoot, centerCenter, {
    marginTop: spacing.LARGE,
  }),
  button: style(flexRoot, centerCenter, {
    gap: spacing.XSMALL,
  }),
  downloadLimitReached: style({
    textAlign: 'center',
    margin: '1.5rem 0 0',
    color: colorContrast.toString(),
  }),
};

@observer
export class DownloadModal extends React.Component<DownloadModalProps, DownloadModalState> {
  constructor(props) {
    super(props);
    this.state = {
      accept: false,
      isDownloading: false,
    };

    this.acknowledgeTrackDownload = this.acknowledgeTrackDownload.bind(this);
  }

  acknowledgeTrackDownload() {
    const { model } = this.props;

    if (!model.agree || model.downloadLimitReached) {
      return null;
    }

    const analytics = this.props.analytics;

    this.setState({ isDownloading: true });

    this.props
      .onDownloadOriginal(model.licenseIdentity)
      .then(() => {
        analytics.sendMixpanel('Downloading track', {
          license_identity: model.licenseIdentity,
        });

        window.location.href = model.downloadURL;
      })
      .finally(() => {
        this.setState({ isDownloading: false });
      });
  }

  render() {
    const { onRequestClose, onAgreeToTerms, model, i18n = DownloadModal.i18n } = this.props;
    const { isDownloading } = this.state;

    return (
      <ScrollableOverlay
        show={model.show}
        onRequestClose={onRequestClose}
        className={styles.wrapper}
        data-test-download-modal
      >
        <div className={overlayStyles.header}>
          <h3 className={overlayStyles.title}>Download audio</h3>
        </div>

        <div className={styles.body}>
          {model.attributionError && (
            <>
              <Banner type={SemanticInformationType.ERROR} className={styles.banner}>
                Your video attribution could not be loaded, please submit an issue via the{' '}
                <Link
                  href="https://help.lickd.co/knowledge"
                  className={styles.errorLink}
                  target="_blank"
                  data-test-download-modal-help-centre-link
                >
                  help centre
                </Link>
                .
              </Banner>

              <p className={styles.smallText}>
                You can still proceed to download the track and use it in your video, but we may not be able to clear
                claims for you without an attribution.
              </p>
            </>
          )}

          {model.attribution && !model.attributionError && (
            <>
              <Banner type={SemanticInformationType.SUCCESS} className={styles.banner}>
                Your video attribution has been generated.
              </Banner>

              <CopyBox
                content={attributionContent(
                  model.attribution,
                  model.track,
                  model.artist,
                  model.shortId,
                  i18n.referralURL
                )}
                className={styles.copyBox}
                singleLine={false}
              />
            </>
          )}

          {!model.downloadError && !model.attributionError && !model.downloadLimitReached && (
            <LabelledCheckbox
              name="agree"
              id="agree-to-download"
              checked={model.agree}
              onChange={(e) => onAgreeToTerms(e.target.checked)}
            >
              I understand I must add the Lickd licensing attribution to my video description.
            </LabelledCheckbox>
          )}

          {model.downloadError && (
            <Banner type={SemanticInformationType.ERROR}>
              There was a problem generating your audio file. If the problem persists, please submit an issue via the{' '}
              <Link
                href="https://help.lickd.co/knowledge"
                className={styles.errorLink}
                target="_blank"
                data-test-download-modal-help-centre-link
              >
                help centre
              </Link>
              .
            </Banner>
          )}

          {!model.downloadError && (
            <>
              <p className={styles.bold}>All downloads are 320 kbps MP3 files</p>
              <div className={styles.buttons}>
                {model.loading && (
                  <Button
                    prominence={ButtonProminence.SECONDARY}
                    size={ButtonSize.MEDIUM}
                    className={styles.button}
                    disabled={true}
                    data-test-download-modal-perparing-disabled-button
                  >
                    Preparing download...
                  </Button>
                )}

                {!model.loading && !model.downloadLimitReached && (
                  <Button
                    onClick={this.acknowledgeTrackDownload}
                    prominence={ButtonProminence.PRIMARY}
                    size={ButtonSize.MEDIUM}
                    className={styles.button}
                    disabled={!model.agree || isDownloading}
                    data-test-download-modal-download-button
                  >
                    {isDownloading ? 'Downloading...' : 'Download track'} <DownloadIcon />
                  </Button>
                )}

                {model.downloadLimitReached && (
                  <p className={styles.downloadLimitReached}>
                    Sorry! The download limit is set to 3 per track, please contact support if you need help.
                  </p>
                )}
              </div>
            </>
          )}
        </div>
      </ScrollableOverlay>
    );
  }

  static i18n = {
    title: 'Download audio',
    strap: 'We provide a 320kbs mp3 file for use in your video',
    agree: 'I understand I must add the Lickd licensing attribution to my video description.',
    // strap: 'Select which version of the track to download, or send all versions to your Dropbox',
    preparing: 'Preparing download',
    download: 'Download track',
    original: 'Download original',
    instrumental: 'Download instrumental',
    vocal: 'Download vocal',
    dropbox: 'Send all to Dropbox',
    close: 'Close',
    referralURL: '',
  };

  static styles = {
    container: style({
      ...ModalChildStyle,
      position: 'relative',
      width: px(640),
      $nest: {
        '&> h3': {
          color: colorBrand.toString(),
          fontSize: px(30),
          margin: 0,
        },
        '&> p': {
          margin: '24px 0 0 0',
        },
        '&> ul': {
          ...vertical,
          ...verticallySpaced(10),
          listStyle: 'none inside none',
          margin: '10px 0 0 0',
          padding: 0,
        },
        '&> ul li': {
          width: percent(100),
        },
        '&> ul [role="button"]': {
          width: percent(100),
        },
        '&> ul a': {
          width: percent(100),
        },
      },
    }),
    buttons: style({
      ...horizontal,
      ...horizontallySpaced(10),
      ...endJustified,
      margin: '30px 0 0 0',
    }),
    copyBox: style({
      marginBottom: '1.5rem',
      overflowWrap: 'break-word',
    }),
    downloadLimitReachedDiv: style({
      textAlign: 'center',
      $nest: {
        '&> p': {
          marginBottom: '0px',
          color: colorContrast.toString(),
        },
      },
    }),
    link: style({
      color: colorBrand.toString(),
      $nest: { ...resetAnchorStyles(colorBrand.toString()) },
    }),
  };
}
