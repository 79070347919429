import * as React from 'react';
import { classes, style } from 'typestyle';
import { Component, ComponentProps, deps, inject, observer } from '../../../../lib/component';
import { ButtonProminence } from '../../atoms/button/buttonStyles';
import { center, flexRoot, vertical } from 'csstips';
import { px } from 'csx';
import { ActionButton } from '../../atoms/button/ActionButton';
import { colorCrimson, rebrand } from '../../../../theme/color';
import { RegistrationSteps } from '../../../../modules/page/registration/model';
import { CountrySelector } from '../../atoms/controls/CountrySelector';
import { fontstackDefault, fontstackHeading } from '../../config';
import { Input } from '../../atoms/controls/Input';
import { preventAndCall } from '../../utilities/functions';
import { SessionStorageModel } from '../../../../modules/storage/model';
import { mediaLargeTablet, mediaUpToLargeTablet } from '../../utilities/mediaQueries';
import { RequiredIndicator } from '../../atoms/controls/RequiredIndicator';
import { SUPPORT_HUB } from '../../../../constants';
import { FREE_USER_EARLY, SKIP_PLANS_STAGE } from '../../../devtools/feature-flags';
import { LabelledCheckbox } from '../../atoms/controls/LabelledCheckbox';
import { spacing } from '../../../../theme/spacing';
import { headingStyles } from '../../utilities/font';
import { COMPLETED_REGISTRATION_STEPS } from '../../../../types/user';
import { ChannelInfo } from '../../molecules/ChannelInfo';

const { PRIMARY, DISABLED } = ButtonProminence;

const styles = {
  wrapper: style(flexRoot, vertical, center),
  text: style(headingStyles, {
    fontFamily: fontstackHeading,
    textAlign: 'center',
    margin: `0 0 ${spacing.XLARGE}`,
    fontSize: px(32),
    width: '100%',
  }),
  form: style({
    maxWidth: px(360),
    width: '100%',
    margin: '0 auto 32px',
    $nest: {
      '& h6': {
        fontFamily: fontstackDefault,
        margin: '0 0 8px',
        fontSize: px(12),
      },
    },
  }),
  label: style(flexRoot, vertical, {
    fontWeight: 400,
    marginBottom: px(16),
    $nest: {
      '& > span': {
        marginBottom: spacing.XXSMALL,
      },
    },
  }),
  prompt: style({ fontSize: px(12) }),
  fieldPrompt: style({ marginTop: px(-12) }),
  hintPrompt: style({ color: rebrand.neutralOnDark[70].toString(), fontStyle: 'italic' }),
  errorPrompt: style({ color: colorCrimson.toHexString() }),
  marketingPrompt: style({ marginBottom: px(-8), textAlign: 'center' }),
  collapsible: style(
    {
      maxWidth: px(488),
      width: 'calc(100% - 32px)',
      margin: px(16),
    },
    mediaUpToLargeTablet({
      marginTop: px(22),
      marginBottom: px(16),
    }),
    mediaLargeTablet({
      marginTop: px(32),
      marginBottom: px(24),
    })
  ),
  details: style({
    textAlign: 'center',
    maxWidth: px(360),
  }),
};

@inject(deps)
@observer
export class AccountInformation extends Component<ComponentProps> {
  state = {
    showErrors: false,
    showNickname: false,
    showTerms: false,
    submitting: false,
  };

  constructor(props) {
    super(props);
    this.showNickname = this.showNickname.bind(this);
    const {
      model: {
        page: { registration },
      },
      controller: { analytics },
    } = this.props;
    if (!registration.termsValid) {
      this.state.showTerms = true;
      analytics.sendMixpanel('User is shown backup T&Cs checkbox after sign-up');
    }
  }

  async submitAccountInformation() {
    const {
      model: {
        page: { registration },
        router,
      },
      controller: {
        page: { registration: registrationController },
      },
    } = this.props;
    if (!registration.accountInformationValid) {
      this.setState({ showErrors: true });
      return;
    }
    this.setState({ submitting: true });
    await registrationController.submitAccountInformation();
    if (SessionStorageModel.getItem(FREE_USER_EARLY)) {
      await this.props.controller.user.markRegistrationStepAsComplete(COMPLETED_REGISTRATION_STEPS.PLAN_SELECTED);
    }
    this.setState({ submitting: false });
    if (SessionStorageModel.getItem(SKIP_PLANS_STAGE)) {
      router.push(`/registration/${RegistrationSteps.COMPLETE}`);
    } else {
      router.push(`/registration/${RegistrationSteps.PLANS}`);
    }
  }

  handleNameEntry(event: React.ChangeEvent<HTMLInputElement>) {
    const {
      model: {
        page: { registration },
      },
    } = this.props;
    registration.fullName = event.target.value;
    if (!this.state.showNickname) {
      // We guess at a nickname as being the first word of a user's full name, but they may edit it
      registration.nickname = registration.fullName.split(' ')[0];
    }
  }

  showNickname() {
    this.setState({ showNickname: true });
    this.props.controller.analytics.sendMixpanel('User shows nickname field');
  }

  render() {
    const {
      model: {
        page: { registration },
        onboarding: { fullyOnboarded },
        user: { user, channel },
      },
      controller: {
        onboarding: { showTermsConditionsModal, showPrivacyPolicyModal },
      },
    } = this.props;

    const disableCountry = fullyOnboarded && registration.country.length === 2;

    return (
      <div className={styles.wrapper}>
        {user && user.default_channel && <ChannelInfo />}

        <p className={styles.text}>
          {user && user.default_channel ? 'Sorted!' : ''} Now let&apos;s get to know you better
        </p>

        <form className={styles.form} onSubmit={this.submitAccountInformation.bind(this)}>
          <label className={styles.label}>
            <span>
              What should we call you?
              <RequiredIndicator />
            </span>
            <Input
              type="text"
              placeholder="Enter full name"
              value={registration.fullName}
              onChange={this.handleNameEntry.bind(this)}
              isValid={!this.state.showErrors || registration.fullNameValid}
              required={true}
            />
          </label>
          {this.state.showErrors && !registration.fullNameValid && (
            <p className={classes(styles.prompt, styles.fieldPrompt, styles.errorPrompt)}>Please enter full name</p>
          )}
          {!this.state.showNickname && registration.fullName.length > 0 && (
            <p className={classes(styles.prompt, styles.fieldPrompt)}>
              We&apos;ll call you &quot;{registration.fullName.split(' ')[0]}&quot;{' '}
              <a href="#" onClick={this.showNickname}>
                Change
              </a>
            </p>
          )}
          {this.state.showNickname && (
            <label className={styles.label}>
              What&apos;s your preferred name?
              <Input
                type="text"
                value={registration.nickname}
                onChange={(evt) => (registration.nickname = evt.target.value)}
                placeholder="Enter preferred name"
              />
            </label>
          )}
          <label className={styles.label}>
            <span>
              Where do you live?
              <RequiredIndicator />
            </span>
            <CountrySelector
              value={registration.country}
              onChange={(evt) => (registration.country = evt.target.value)}
              isValid={!this.state.showErrors || registration.countryValid}
              required={true}
              disabled={disableCountry}
            />
          </label>
          {this.state.showErrors && !registration.countryValid && (
            <p className={classes(styles.prompt, styles.fieldPrompt, styles.errorPrompt)}>
              Please enter your country of residence
            </p>
          )}
          {disableCountry && (
            <p className={classes(styles.prompt, styles.fieldPrompt)}>
              Need to update your country? Please <a href={SUPPORT_HUB}>submit a support ticket</a>.
            </p>
          )}
          {!(this.state.showErrors && !registration.countryValid) && !disableCountry && (
            <p className={classes(styles.prompt, styles.fieldPrompt, styles.hintPrompt)}>
              So we can show you music available in your region
            </p>
          )}
          {this.state.showTerms && !fullyOnboarded && (
            <LabelledCheckbox
              onChange={(evt) => (registration.terms = evt.target.checked)}
              checked={registration.terms}
              isValid={!this.state.showErrors || registration.termsValid}
              required={true}
            >
              {' '}
              I accept the{' '}
              <a href="/terms-and-conditions" onClick={preventAndCall(showTermsConditionsModal)}>
                Terms and Conditions
              </a>{' '}
              and{' '}
              <a href="/privacy-policy" onClick={preventAndCall(showPrivacyPolicyModal)}>
                Privacy Policy
              </a>
            </LabelledCheckbox>
          )}
        </form>
        <ActionButton
          onClick={this.submitAccountInformation.bind(this)}
          prominence={!registration.accountInformationValid ? DISABLED : PRIMARY}
          loading={this.state.submitting}
        >
          Go to plans
        </ActionButton>
      </div>
    );
  }
}
