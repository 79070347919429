import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const PersonCircle: React.FC<SVGIconProps> = ({ size = 18, color = 'currentColor', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="1 1 18 17" {...props}>
    <g clipPath="url(#person-circle)">
      <path
        d="M10 1.7a8.3 8.3 0 1 0 0 16.6 8.3 8.3 0 0 0 0-16.6Zm0 2.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM10 16c-2 0-4-1-5-2.7 0-1.6 3.3-2.6 5-2.6 1.7 0 5 1 5 2.6a6 6 0 0 1-5 2.7Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="person-circle">
        <path fill={color} d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </BaseSvg>
);
