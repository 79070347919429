import * as React from 'react';
import { style } from 'typestyle';
import { ModalProps } from '../../modal';
import { Component } from '../../../lib/component';
import { center } from 'csstips';
import { colorLickdMint, colorLickdPink, rebrand } from '../../../theme/color';
import { SubscriptionLabel } from '../atoms/SubscriptionLabel';
import { CheckmarkCircle } from '../atoms/icons/CheckmarkCircle';
import { StarCircle } from '../atoms/icons/StarCircle';
import { ScrollableOverlay } from '../../scrollable-overlay/scrollable-overlay';
import { ChannelSchema } from '../../../types/schema';
import { CreditPlanTypes } from '../utilities/types';
import CatalogIncluded from '../../../static/catalogue-included.png';
import CatalogPremium from '../../../static/catalogue-premium.png';
import { ActionButton } from '../atoms/button/ActionButton';
import { ButtonSize } from '../atoms/button/buttonStyles';
import overlayStyles from '../../../styles/scrollable-overlay';

const styles = {
  modal: style(center, {
    maxWidth: '440px',
  }),
  title: style({
    textAlign: 'center',
    fontSize: '24px',
    margin: '0px 0px 24px 0px',
  }),
  borderLine: style({
    borderBottom: `1px solid ${rebrand.light1.toString()}`,
    width: '100%',
  }),
  infoText: style({
    marginTop: '24px',
    fontSize: '16px',
  }),
  blankTrack: style({
    width: '64px',
    height: '64px',
    backgroundColor: '#E1E9F4',
    borderRadius: '4px',
    marginRight: '8px',
    position: 'relative',
  }),
  catalogueTypeIcon: style({
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '32px',
    height: '32px',
  }),
  labelsContainer: style({ display: 'flex', marginTop: '24px' }),
  labelTextIcon: style({ fontSize: '12px', display: 'flex', alignItems: 'center', marginLeft: '8px' }),
};

const planCopy = {
  pro: (
    <>
      <p style={{ margin: '0px 0px 24px' }}>
        <strong>
          Good news! Your subscription gives you track credit(s) which you can use to license Premium tracks like this
          at no additional cost.
        </strong>
      </p>
      <p style={{ marginTop: '0px' }}>
        Creating like a pro and need more music? You can always purchase additional one-off licenses once you run out of
        credits each month.
      </p>
    </>
  ),
  starter: (
    <>
      <p style={{ margin: '0px 0px 24px' }}>
        <strong>
          Good news! Your subscription gives you track credit(s) which you can use to license Premium tracks like this
          at no additional cost.
        </strong>
      </p>
      <p style={{ marginTop: '0px' }}>
        Creating like a pro and need more music? You can always purchase additional one-off licenses or upgrade your
        subscription in&nbsp;
        <a href="/account/plans" style={{ color: '#5D6A84', textDecoration: 'underline' }}>
          your account
        </a>
        &nbsp;to get more credits.
      </p>
    </>
  ),
  legacy: (
    <>
      <p style={{ margin: '0px 0px 24px' }}>
        <strong>Premium tracks can be licensed using subscription credits as well as one off purchases.</strong>
      </p>
      <p style={{ marginTop: '0px' }}>
        Check out our new subscription plans, which include Premium track credits and can save you money on licenses!
      </p>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <ActionButton
          arrowSize={16}
          className={style({ width: '121px' })}
          size={ButtonSize.MEDIUM}
          href="/account/plans"
          data-test-navigate-to-plans-page
        >
          See plans
        </ActionButton>
      </div>
    </>
  ),
};

const getCopy = (channel?: ChannelSchema): React.ReactNode => {
  if (!channel || !channel.subscription) {
    return planCopy.legacy;
  }

  const { plan_name } = channel.subscription;

  switch (plan_name) {
    case CreditPlanTypes.PRO:
      return planCopy.pro;
    case CreditPlanTypes.STARTER:
    case CreditPlanTypes.PLUS:
      return planCopy.starter;
    default:
      return planCopy.legacy;
  }
};

export type SubscriptionInfoModalProps = ModalProps & { payg: boolean; channel: ChannelSchema };

export class SubscriptionInfoModal extends Component<SubscriptionInfoModalProps> {
  render() {
    const { show, onRequestClose, payg } = this.props;

    const copy = getCopy(this.props.channel);

    return (
      <ScrollableOverlay className={styles.modal} show={show} onRequestClose={onRequestClose}>
        <div className={overlayStyles.header}>
          {!payg && <h3 className={overlayStyles.title}>Included in the subscription</h3>}

          {payg && <h3 className={overlayStyles.title}>PREMIUM TRACKS</h3>}
        </div>

        <div className={styles.borderLine}></div>

        <div className={styles.labelsContainer}>
          <div className={styles.blankTrack}>
            <SubscriptionLabel included={!payg} />
            {!payg ? (
              <img src={CatalogIncluded} alt="cataglog included" className={styles.catalogueTypeIcon} />
            ) : (
              <img src={CatalogPremium} alt="cataglog premium" className={styles.catalogueTypeIcon} />
            )}
          </div>
          {!payg ? (
            <div className={styles.labelTextIcon} style={{ color: colorLickdMint.toHexString() }}>
              Included
              <CheckmarkCircle
                color={rebrand.dark1.toString()}
                checkColor={rebrand.highlight1.toString()}
                size={16}
                style={{ marginLeft: '4px' }}
              />
            </div>
          ) : (
            <div className={styles.labelTextIcon} style={{ color: colorLickdPink.toHexString() }}>
              Premium
              <StarCircle color={colorLickdPink.toHexString()} style={{ marginLeft: '4px' }} />
            </div>
          )}
        </div>
        {!payg ? (
          <p className={styles.infoText}>
            Keep an eye out for the <em>Included</em> labels. These identify music that&apos;s{' '}
            <strong>included in the subscription</strong>. Usage of these tracks are unlimited and can be used across
            all social platforms.
          </p>
        ) : (
          <div className={styles.infoText}>{copy}</div>
        )}
      </ScrollableOverlay>
    );
  }
}
