import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { px } from 'csx';
import { ContentSlice, ContentSliceProps } from './ContentSlice';
import { SonyMusicLogo } from '../../atoms/icons/labels/SonyMusicLogo';
import { EmpireLogo } from '../../atoms/icons/labels/EmpireLogo';
import { WarnerMusicLogo } from '../../atoms/icons/labels/WarnerMusicLogo';
import { UniversalMusicLogo } from '../../atoms/icons/labels/UniversalMusicLogo';
import { BmgLogo } from '../../atoms/icons/labels/BmgLogo';
import {
  mediaSmallTabletOnly,
  mediaUpToSmallTablet,
  mediaUpToDesktop,
  mediaDesktop,
  mediaMobileOnly,
} from '../../utilities/mediaQueries';
import { AudioNetworkLogo } from '../../atoms/icons/labels/AudioNetworkLogo';
import { SonyMusicMobileLogo } from '../../atoms/icons/labels/SonyMusicMobileLogo';
import { mediaTablet } from '../../../../theme/media';

const styles = {
  wrapper: style(
    mediaUpToDesktop({
      paddingBottom: px(32),
      $nest: {
        '.masthead + &, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient': {
          paddingTop: px(32),
        },
      },
    }),
    mediaDesktop({
      paddingBottom: px(56),
      $nest: {
        '.masthead + &, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient': {
          paddingTop: px(56),
        },
      },
    })
  ),
  content: style(
    {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    mediaUpToSmallTablet({
      flexDirection: 'column',
      $nest: {
        '& > svg': {
          marginTop: px(56),
          $nest: {
            '&:first-child': {
              marginTop: 0,
            },
          },
        },
      },
    }),
    mediaSmallTabletOnly({
      $nest: {
        '& > svg': {
          width: px(120),
        },
      },
    })
  ),
  hideMobile: style(mediaMobileOnly({ display: 'none' })),
  hideDesktopTablet: style(mediaDesktop({ display: 'none' }), mediaTablet({ display: 'none' })),
};

type LabelSliceProps = ContentSliceProps;

export const LabelSlice: React.SFC<LabelSliceProps> = ({ children, className, withGradient, ...props }) => (
  <ContentSlice
    className={classes('label-slice', className, styles.wrapper)}
    contentClassName={styles.content}
    withGradient={withGradient}
    {...props}
  >
    <SonyMusicLogo color={withGradient && 'white'} className={styles.hideMobile} />
    <SonyMusicMobileLogo color={withGradient && 'white'} className={styles.hideDesktopTablet} />
    <EmpireLogo color={withGradient && 'white'} />
    <WarnerMusicLogo color={withGradient && 'white'} />
    <UniversalMusicLogo color={withGradient && 'white'} />
    <BmgLogo color={withGradient && 'white'} />
    <AudioNetworkLogo color={withGradient && 'white'} style={{ flex: '1 0 100%', marginTop: '56px' }} />
  </ContentSlice>
);
