import * as React from 'react';
import { classes, style } from 'typestyle';
import {
  betweenJustified,
  center,
  centerJustified,
  end,
  flexRoot,
  horizontal,
  selfStretch,
  start,
  vertical,
} from 'csstips';
import { important, percent, px } from 'csx';
import { colorWhite, rebrand } from '../../../theme/color';
import { fontstackHeading } from '../config';
import { borderRadius } from '../../../theme/border';
import { spacing } from '../../../theme/spacing';
import { mediaUpToDesktop, mediaDesktop, mediaMediumDesktop } from '../utilities/mediaQueries';
import { StarCircle } from '../atoms/icons/StarCircle';
import { TagLabel } from '../atoms/icons/TagLabel';

interface Empty {
  type: 'emptyCredit';
  title: string;
  description: string;
  value: string;
  code?: never;
  usages?: never;
  expiry?: never;
}

interface NotEmpty {
  type: 'coupon' | 'credit';
  title?: string;
  description?: string;
  value: string;
  code?: string;
  usages: string | number;
  expiry: string;
}

type VoucherProps = NotEmpty | Empty;

const styles = {
  container: style(
    {
      width: '100%',
      borderRadius: borderRadius.LARGE,
      $nest: {
        '&.credit': {
          backgroundColor: rebrand.neutralOnDark[10].toString(),
          color: rebrand.contrast[50].toString(),
        },
        '&.emptyCredit': {
          backgroundColor: rebrand.neutralOnDark[10].toString(),
          color: rebrand.neutralOnDark[20].toString(),
        },
        '&.coupon': {
          backgroundColor: rebrand.contrast[50].toString(),
          color: colorWhite.toString(),
        },
      },
    },
    mediaDesktop(flexRoot, center, {
      minHeight: px(80),
    })
  ),
  main: style(
    {
      ...horizontal,
      ...center,
      ...betweenJustified,
      flex: '3 1 70%',
      borderColor: important(rebrand.contrast[30].toString()),
      $nest: {
        '.emptyCredit &': {
          borderColor: important(rebrand.neutralOnDark[20].toString()),
        },
      },
    },
    mediaUpToDesktop({
      borderBottom: '1px dashed',
      padding: `${spacing.DEFAULT} 0`,
      margin: `0 ${spacing.DEFAULT}`,
    }),
    mediaDesktop({
      ...selfStretch,
      padding: spacing.DEFAULT,
      borderRight: '1px dashed',
    })
  ),
  icon: style({
    marginRight: spacing.DEFAULT,
    flexShrink: 0,
  }),
  meta: style(
    {
      ...start,
      ...vertical,
      ...centerJustified,
      height: percent(100),
      flex: 1,
      $nest: {
        '& p': {
          margin: 0,
        },
      },
    },
    mediaMediumDesktop({
      flex: 3,
    })
  ),
  couponTitle: style({
    fontSize: px(24),
    margin: 0,
  }),
  description: style({
    $nest: {
      '.credit &': {
        color: rebrand.contrast[40].toString(),
      },
      '.emptyCredit &': {
        color: rebrand.neutralOnDark[20].toString(),
      },
      '.coupon &': {
        color: rebrand.contrast[30].toString(),
      },
    },
  }),
  code: style({
    wordBreak: 'break-all',
  }),
  value: style(
    {
      ...end,
      ...vertical,
      ...centerJustified,
      textAlign: 'right',
      paddingLeft: spacing.DEFAULT,
      height: percent(100),
      flex: 1,
      fontSize: px(24),
      fontFamily: fontstackHeading,
      lineHeight: 1.1,
    },
    mediaMediumDesktop({
      fontSize: px(28),
      flex: 2,
    })
  ),
  stub: style({
    ...horizontal,
    ...center,
    ...betweenJustified,
    flex: '0 1 264px',
    padding: spacing.DEFAULT,
    gap: spacing.XSMALL,
    color: rebrand.contrast[40].toString(),
    $nest: {
      '& > div': {
        flexShrink: 0,
      },
      '.credit &': {
        color: rebrand.contrast[40].toString(),
      },
      '.emptyCredit &': {
        color: rebrand.neutralOnDark[20].toString(),
      },
      '.coupon &': {
        color: rebrand.contrast[30].toString(),
      },
    },
  }),
  expires: style({
    textAlign: 'right',
  }),
  stubValue: style({
    display: 'block',
    fontWeight: 700,
    color: rebrand.contrast[50].toString(),
    $nest: {
      '.coupon &': {
        color: colorWhite.toString(),
      },
    },
  }),
};

/** Used to display credits and coupons */
export const Voucher: React.FC<VoucherProps> = ({ type, title, expiry, value, usages, code, description }) => {
  const starColor = type === 'emptyCredit' ? rebrand.neutralOnDark[20].toString() : rebrand.primary[100].toString();
  // Empty credits should not be announced by screenreaders
  const hideForAccessibility = type === 'emptyCredit' ? true : undefined;

  return (
    <div data-test-coupon-line className={classes(styles.container, type)} aria-hidden={hideForAccessibility}>
      <div className={styles.main}>
        <div className={styles.icon}>
          {type === 'credit' || type === 'emptyCredit' ? (
            <StarCircle size={20} color={starColor} />
          ) : (
            <TagLabel size={20} />
          )}
        </div>

        <div className={styles.meta}>
          {title && <h3 className={styles.couponTitle}>{title}</h3>}
          {description && <p className={styles.description}>{description}</p>}
          {code && <p className={classes(styles.description, styles.code)}>{code}</p>}
        </div>

        <div className={styles.value}>{value}</div>
      </div>

      <div className={styles.stub}>
        <div>
          usages
          {type !== 'emptyCredit' && (
            <span className={styles.stubValue}>
              <strong>{usages}</strong>
            </span>
          )}
        </div>
        <div className={styles.expires}>
          expires
          {type !== 'emptyCredit' && (
            <span className={styles.stubValue}>
              <strong>{expiry}</strong>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
