import { ChannelSchema, TrackSchema, ArtistSchema } from '../types/schema';
import { ImagePreloader } from '../types/fn';

export const getTrackImageURL = (track: TrackSchema, preload: ImagePreloader) => {
  if (!track || !track.images || !track.images.identity) return void 0;

  return preload(track.images.identity);
};

export const getChannelImageURL = (channel: ChannelSchema, size: string = 'high') => {
  if (!channel || !channel.images || channel.images.length === 0) return;

  let image = channel.images.find(image => image.size === size);
  if (!image) image = channel.images[0];
  if (!image) return;

  return image.url;
};

export const getTrackWaveformURL = (track: TrackSchema, preload: ImagePreloader, type: string = 'coloured', fallback: boolean = true) => {
  try {
    return preload(track.audio.identity, { type });
  } catch (e) {
    return;
  }
};

export const getArtistImageURL = (artist: ArtistSchema, preload: ImagePreloader) => {
  if (!artist) return void 0;

  return preload(artist.images.identity);
};