import { API, APIError, APIResponse, UserStatusResponse } from '../types';
import { action } from 'mobx';
import { RequestController } from '../../request/controller';
import { EnvModel } from '../../env/model';
import { CacheController } from '../../cache/controller';
import {
  EssentialsManagementUrlPayload,
  EssentialsPlan,
  EssentialsSubscriptionUrlPayload,
  V2Plan,
  V2SubscriptionChangePayload,
  V2SubscriptionUrlPayload,
} from '../../../components/project-happy/utilities/types';

export class SubscriptionAPIController extends API {
  constructor(public request: RequestController, public cache: CacheController, public env: EnvModel) {
    super(request, cache, env);
  }

  @action
  getSubscriptionPlans = async (): Promise<APIResponse<EssentialsPlan[]>> => {
    const response = await this.request.get({
      url: '/subscription/plans',
    });
    await APIError.checkResponse(response);
    return response.json();
  };

  @action
  getSubscriptionUrl = async (channelId?: string): Promise<APIResponse<EssentialsSubscriptionUrlPayload>> => {
    const response = await this.request.post({
      url: '/subscription',
      body: JSON.stringify({ youtube_channel_id: channelId }),
    });
    await APIError.checkResponse(response);
    return response.json();
  };

  @action
  getSubscriptionManagementUrl = async (): Promise<APIResponse<EssentialsManagementUrlPayload>> => {
    const response = await this.request.get({
      url: '/subscription/billing',
    });
    await APIError.checkResponse(response);
    return response.json();
  };

  /** Get V2 (Project Subscription) plan prices */
  @action
  getV2PlanPrices = async (channelId: string): Promise<APIResponse<V2Plan[]>> => {
    const response = await this.request.get({
      url: `/subscription/prices?youtube_channel_id=${channelId}`,
    });
    await APIError.checkResponse(response);
    return response.json();
  };

  /** Subscribe to V2 (Project Subscription) plans */
  @action
  getV2PlanSubscriptionUrl = async (
    channelId: string,
    stripePriceId: string
  ): Promise<APIResponse<V2SubscriptionUrlPayload>> => {
    const response = await this.request.post({
      url: '/subscription/prices/subscribe',
      body: JSON.stringify({
        youtube_channel_id: channelId,
        stripe_price_id: stripePriceId,
      }),
    });
    await APIError.checkResponse(response);
    return response.json();
  };

  /** Change V2 (Project Subscription) plans */
  @action
  getV2PlanSubscriptionChangeDetails = async (
    channelId: string,
    stripePriceId: string
  ): Promise<APIResponse<V2SubscriptionChangePayload>> => {
    const response = await this.request.post({
      url: '/subscription/prices/change',
      body: JSON.stringify({
        youtube_channel_id: channelId,
        stripe_price_id: stripePriceId,
      }),
    });
    await APIError.checkResponse(response);
    return response.json();
  };
}
