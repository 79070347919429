import { classes, style } from 'typestyle';
import * as React from 'react';
import { Modal, ModalChildStyle, ModalProps } from '../../modal';
import { percent, px } from 'csx';
import { colorGreyAccent, colorWhite, rebrand } from '../../../theme/color';
import { shadowElevation3 } from '../../../theme/shadow';
import { desktopContentWidth } from '../config';
import { CircleButton } from '../atoms/button/CircleButton';
import { CloseCross } from '../atoms/icons/CloseCross';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { mediaSmallTablet } from '../utilities/mediaQueries';
import { ACCOUNT_PLANS_ROUTE, CHART_SEARCH_ROUTE, SIGN_UP_ROUTE } from '../../../constants';
import { Button } from '../atoms/button/Button';
import { QuestionCircle } from '../atoms/icons/QuestionCircle';
import { Component, deps, inject, observer } from '../../../lib/component';
import ToolTipModal from '../molecules/ToolTipModal';
import { Currencies, CurrencySelector } from '../molecules/CurrencySelector';
import { ControlledCarousel } from '../atoms/carousel/ControlledCarousel';
import { isEmpty } from '../utilities/objects';
import { PricingTile } from '../molecules/PricingTile';
import { CarouselEvents } from '../utilities/carousel';
import EventBus from '../utilities/EventBus';
import { NextPageButton } from '../atoms/carousel/controllers/NextPageButton';
import { PreviousPageButton } from '../atoms/carousel/controllers/PreviousPageButton';
import { center } from 'csstips';
import { InfoCircle } from '../atoms/icons/InfoCircle';

const styles = {
  modal: style(
    ModalChildStyle,
    shadowElevation3,
    center,
    {
      maxWidth: px(desktopContentWidth),
      width: percent(100),
      overflowX: 'visible',
      overflowY: 'visible',
      backgroundColor: rebrand.dark1.toString(),
      borderRadius: px(35),
      padding: '32px 0',
      position: 'relative',
    },
    mediaSmallTablet({
      width: percent(75),
    })
  ),
  close: style({
    position: 'absolute',
    top: px(16),
    right: px(16),
  }),
  title: style({
    color: 'white',
    textAlign: 'center',
    marginTop: 0,
  }),
  dropdown: style({
    background: colorGreyAccent.toHexString(),
    padding: '8px 16px',
    borderRadius: px(24),
    border: 0,
  }),
  currencies: style({
    color: 'white',
    marginTop: px(16),
    marginBottom: px(8),
  }),
  carouselWrapper: style({
    width: percent(100),
    position: 'relative',
  }),
  carouselButton: style({
    position: 'absolute',
    top: 'calc(50% - 16px)',
    $nest: {
      // Increase the click area as these buttons are floating outside of the modal
      '&::after': {
        content: '""',
        position: 'absolute',
        top: px(-16),
        left: px(-16),
        right: px(-16),
        bottom: px(-16),
      },
    },
  }),
  previousButton: style({
    left: px(-48),
  }),
  nextButton: style({
    right: px(-48),
  }),
  carousel: style({
    $nest: {
      '& > div': center,
    },
  }),
  carouselTile: style({ minWidth: '136px' }),
  help: style({
    marginTop: px(8),
    marginBottom: px(24),
    $nest: {
      '&:hover': {
        opacity: 0.8,
      },
      '& p': { margin: 0, textDecoration: 'underline' },
    },
  }),
};

const HelpTooltip: React.SFC<ModalProps> = (props) => (
  <ToolTipModal {...props}>
    <h3>How are price bands calculated?</h3>
    <p>
      Our pricing structure is based on the average views your recent video uploads have received. When you sign-in to
      Lickd with your YouTube account we fetch your latest 50 videos and take an average of the video views. This allows
      us to estimate your audience size and put you into a price band.
    </p>
  </ToolTipModal>
);

export type PricingModalProps = ModalProps;

@inject(deps)
@observer
export class PricingModal extends Component<PricingModalProps> {
  carouselEvents: EventBus<CarouselEvents> = new EventBus<CarouselEvents>();

  state = {
    showHelpTooltip: false,
    currency: null,
  };

  constructor(props) {
    super(props);
    this.handleToggleTooltip = this.handleToggleTooltip.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
  }

  handleToggleTooltip() {
    this.setState({ showHelpTooltip: !this.state.showHelpTooltip });
  }

  handleCurrencyChange(currency: Currencies) {
    this.setState({ currency });
  }

  render() {
    const { show, onRequestClose, model } = this.props;
    const {
      user,
      page: {
        pricing: { ratecards, ratecardType },
      },
    } = model;
    const isLoggedIn = !isEmpty(user.user);
    const userCurrency = !isEmpty(user.channel) && !isEmpty(user.channel.ratecard) && user.channel.ratecard.currency;
    const currency = this.state.currency || userCurrency || Currencies.USD;
    const userRatecard = user.channel && user.channel.ratecard;
    let userRatecardId = -1;
    if (userRatecard && ratecards) {
      userRatecardId = ratecards.currency[currency].findIndex((ratecard) => userRatecard.name === ratecard.name);
    }
    return (
      <Modal className={styles.modal} show={show} onRequestClose={onRequestClose}>
        <CircleButton className={styles.close} prominence={ButtonProminence.SECONDARY} onClick={onRequestClose}>
          <CloseCross />
        </CircleButton>
        <h1 className={styles.title}>Chart Price Bands</h1>

        <CurrencySelector className={styles.currencies} value={currency} onChange={this.handleCurrencyChange} />
        <div className={styles.carouselWrapper}>
          <PreviousPageButton
            className={classes(styles.carouselButton, styles.previousButton)}
            events={this.carouselEvents}
          />
          <NextPageButton className={classes(styles.carouselButton, styles.nextButton)} events={this.carouselEvents} />
          <ControlledCarousel
            events={this.carouselEvents}
            className={styles.carousel}
            tileClassName={styles.carouselTile}
            perPage={6}
            centreTile={userRatecardId}
          >
            {!isEmpty(ratecards) &&
              ratecards.currency[currency].map((ratecard, key) => (
                <PricingTile key={key} ratecard={ratecard} highlight={key === userRatecardId} />
              ))}
          </ControlledCarousel>
        </div>

        <Button prominence={ButtonProminence.NONE} className={styles.help} onClick={this.handleToggleTooltip}>
          <InfoCircle color="white" size={24} />
          <p>How are price bands calculated?</p>
        </Button>
        <Button
          prominence={ButtonProminence.PRIMARY}
          size={ButtonSize.MEDIUM}
          href={isLoggedIn ? CHART_SEARCH_ROUTE : SIGN_UP_ROUTE}
        >
          Discover Chart Music
        </Button>
        <HelpTooltip show={this.state.showHelpTooltip} onRequestClose={this.handleToggleTooltip} />
      </Modal>
    );
  }
}
