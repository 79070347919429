import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const PlaylistHamburger: React.SFC<SVGIconProps> = ({ size = 24, color = '"#262E3F"', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 24 24" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M21 8H8V6H21V8Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M21 13H8V11H21V13Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M21 18H8V16H21V18Z" fill={color} />
    <path
      d="M6 7C6 7.82843 5.32843 8.5 4.5 8.5C3.67157 8.5 3 7.82843 3 7C3 6.17157 3.67157 5.5 4.5 5.5C5.32843 5.5 6 6.17157 6 7Z"
      fill={color}
    />
    <path
      d="M6 12C6 12.8284 5.32843 13.5 4.5 13.5C3.67157 13.5 3 12.8284 3 12C3 11.1716 3.67157 10.5 4.5 10.5C5.32843 10.5 6 11.1716 6 12Z"
      fill={color}
    />
    <path
      d="M6 17C6 17.8284 5.32843 18.5 4.5 18.5C3.67157 18.5 3 17.8284 3 17C3 16.1716 3.67157 15.5 4.5 15.5C5.32843 15.5 6 16.1716 6 17Z"
      fill={color}
    />
  </BaseSvg>
);
