import * as React from 'react';
import { FormEvent } from 'react';
import { ModalProps } from '../../modal';
import { ScrollableOverlay } from '../../scrollable-overlay/scrollable-overlay';
import { classes, style } from 'typestyle';
import { em, percent, px } from 'csx';
import { heading6 } from '../utilities/font';
import { Component, deps, inject, observer } from '../../../lib/component';
import { YouTubeVideo } from '../../../types/youtube';
import { Loading } from '../../loading/loading';
import { VideoCard } from '../molecules/VideoCard';
import { LicenceSelector } from '../atoms/controls/LicenceSelector';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { LicenceSchema } from '../../../types/schema';
import { flexRoot, vertical } from 'csstips';
import { rebrand } from '../../../theme/color';
import { APIError } from '../../../modules/api/types';
import { CSSTransition } from 'react-transition-group';
import { transitionModal } from '../../../theme/transition';
import { centerCenter, content } from 'csstips/lib/flex';
import { SemanticInformationType } from '../utilities/types';
import { isEmpty } from '../utilities/objects';
import { CheckboxInput } from '../atoms/controls/CheckboxInput';
import { mediaMobileOnly } from '../utilities/mediaQueries';
import { commonInputStyles } from '../utilities/inputs';
import { TextArea } from '../atoms/controls/TextArea';
import { fontBebasNeue } from '../../../theme/font';
import { Warning } from '../atoms/icons/Warning';
import { CheckmarkCircle } from '../atoms/icons/CheckmarkCircle';

const styles = {
  wrapper: style({ width: percent(100), display: 'block' }),
  heading: style({ textAlign: 'center', fontFamily: fontBebasNeue }),
  body: style({ position: 'relative', padding: '16px 24px' }),
  text: style({ fontSize: em(0.75), textAlign: 'center' }),
  form: style(flexRoot, vertical, {
    $nest: {
      '& label': {
        fontSize: em(0.75),
        fontWeight: 600,
        margin: '12px 0 2px',
      },
    },
  }),
  fieldLabel: style({
    fontSize: em(0.75),
    margin: '0 0 0.5em',
  }),
  marginTop: style({
    marginTop: '16px',
  }),
  warningIconWrapper: style({
    backgroundColor: rebrand.semantic[SemanticInformationType.ERROR]['10'].toString(),
    display: 'flex',
    justifyContent: 'flex-start',
    padding: px(8),
    textAlign: 'left',
    borderRadius: px(4),
    $nest: {
      '& > span': {
        marginRight: px(12),
      },
    },
  }),
  submittedText: style({
    backgroundColor: rebrand.semantic[SemanticInformationType.SUCCESS]['20'].toString(),
    margin: '24px 0px',
    padding: '16px',
    borderRadius: px(4),
  }),
  submittedHeight: style({
    height: '185px',
  }),
  fieldError: style({
    color: rebrand.semantic[SemanticInformationType.ERROR]['70'].toString(),
  }),
  fieldWarning: style({
    color: rebrand.semantic[SemanticInformationType.WARNING]['70'].toString(),
  }),
  userNote: style(commonInputStyles, mediaMobileOnly({ height: px(110) }), {
    fontSize: em(0.9),
    height: px(100),
  }),
  title: style(content, {
    textAlign: 'center',
    margin: '12px 0px',
    textTransform: 'uppercase',
    fontFamily: fontBebasNeue,
  }),
  acknowledgementWrapper: style({
    width: percent(100),
    padding: px(8),
    marginTop: px(16),
    textAlign: 'center',
  }),
  acknowledgementCheckbox: style({
    display: 'inline-flex',
    gap: px(8),
  }),
  error: style({
    color: rebrand.semantic[SemanticInformationType.ERROR][70].toString(),
  }),
  submitButton: style({
    alignSelf: 'center',
    marginTop: px(16),
    boxShadow: 'none',
    backgroundColor: rebrand.neutralOnDark[20].toString(),
    $nest: {
      '&:hover': {
        boxShadow: 'none',
      },
    },
  }),
  overlay: style(flexRoot, vertical, centerCenter, {
    background: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    width: percent(100),
    height: percent(100),
    textAlign: 'center',
    padding: '0 16px',
  }),
  close: style({
    boxShadow: 'none',
    backgroundColor: rebrand.neutralOnDark[20].toString(),
    padding: '4px 14px',
    $nest: {
      '&:hover': {
        boxShadow: 'none',
      },
    },
  }),
};

export type SubmitClaimIssueModalProps = ModalProps & { className?: string; video: YouTubeVideo };
type SubmitClaimIssueModalState = {
  licences: LicenceSchema[];
  selectedLicence: LicenceSchema;
  userNote: string;
  requireAcknowledgement: boolean;
  acknowledgement: boolean;
  clicked: boolean;
  loading: boolean;
  submitted: boolean;
  error: string | null;
};

@inject(deps)
@observer
export class SubmitClaimIssueModal extends Component<SubmitClaimIssueModalProps, SubmitClaimIssueModalState> {
  state: SubmitClaimIssueModalState = {
    licences: [],
    selectedLicence: null,
    userNote: '',
    requireAcknowledgement: false,
    acknowledgement: false,
    clicked: false,
    loading: false,
    submitted: false,
    error: null,
  };

  constructor(props) {
    super(props);
    this.submitClaimIssue = this.submitClaimIssue.bind(this);
  }

  async populateLicences() {
    const {
      model: {
        user: {
          user: { identity },
        },
      },
      controller: {
        api: {
          user: { getLicencedTracks },
        },
      },
    } = this.props;
    this.setState({
      loading: true,
    });
    const { data: licences } = await getLicencedTracks(identity, 1, 100);
    this.setState({
      loading: false,
      licences,
    });
  }

  get isValid(): boolean {
    const { video } = this.props;
    const { selectedLicence, userNote, requireAcknowledgement, acknowledgement } = this.state;
    const hasAcknowledged = !requireAcknowledgement || (requireAcknowledgement && acknowledgement);
    return !isEmpty(video) && !isEmpty(selectedLicence) && hasAcknowledged;
  }

  async submitClaimIssue(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const {
      video,
      model: {
        user: {
          user: { identity },
        },
      },
    } = this.props;
    const { selectedLicence, userNote } = this.state;
    if (!this.isValid) return;
    this.setState({ loading: true });
    try {
      const { success } = await this.props.controller.api.user.createClaimIssue(
        identity,
        video.channel.id,
        video.id,
        selectedLicence.identity,
        userNote
      );
      if (success !== false) {
        this.setState({
          submitted: true,
          selectedLicence: null,
          userNote: '',
        });
        this.props.controller.analytics.sendMixpanel('User submits a claim issue', {
          videoId: video.id,
          channelId: video.channel.id,
          licenceId: selectedLicence.identity,
        });
      } else {
        this.setState({
          error: 'An unknown error occurred.',
        });
      }
    } catch (error) {
      let isKnownUserError = false;
      if (error instanceof APIError && !isEmpty(error.payload) && error.payload.code === 'ISSUE_EXISTS') {
        isKnownUserError = true;
        this.setState({
          error: 'A claim issue for this video already exists using the selected licence.',
        });
        this.props.controller.analytics.sendMixpanel('User attempts to submit a duplicate claim issue', {
          videoId: video.id,
          channelId: video.channel.id,
          licenceId: selectedLicence.identity,
        });
      } else if (error instanceof APIError) {
        this.setState({
          error: error.reason,
        });
      } else {
        this.setState({
          error: 'An unknown error occurred.',
        });
      }
      if (!isKnownUserError) {
        this.props.controller.bugsnag.notifyException(error);
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  get licenceWarnings(): React.ReactNode[] {
    const { selectedLicence } = this.state;
    const { video } = this.props;
    const errors = [];
    if (selectedLicence === null) return errors;
    if (selectedLicence.youtube_video_claims.length > 0) {
      const [firstClaim] = selectedLicence.youtube_video_claims;
      errors.push(
        <>
          This licence has already been used to clear{' '}
          <a target="_blank" rel="noreferrer" href={`https://www.youtube.com/watch?v=${firstClaim.youtube_video_id}`}>
            another video
          </a>
          .
        </>
      );
    }
    if (video !== null && selectedLicence.order.youtube_channels.every(({ id }) => id !== video.channel.id)) {
      errors.push(
        <>
          This licence is only usable with videos uploaded to{' '}
          {selectedLicence.order.youtube_channels.map(({ name }, index, array) => (
            <>
              <b>{name}</b>
              {array.length - 1 !== index ? ', ' : ''}
            </>
          ))}
          .
        </>
      );
    }

    return errors;
  }

  get videoWarnings(): React.ReactNode[] {
    const { selectedLicence } = this.state;
    const { video } = this.props;
    const errors = [];
    if (video === null) return errors;
    if (video.privacy_status === 'private') {
      errors.push(
        `Your video appears to be "Private". We're unable to clear claims on "Private" videos. Please list your video as "Unlisted" so we can clear any claims for you, then set to "Public".`
      );
    }

    return errors;
  }

  componentDidUpdate(prevProps: Readonly<SubmitClaimIssueModalProps>) {
    if (prevProps.show === false && this.props.show === true && this.state.licences.length === 0) {
      this.populateLicences();
    } else if (prevProps.show === true && this.props.show === false) {
      this.setState({
        selectedLicence: null,
        userNote: '',
        requireAcknowledgement: false,
        acknowledgement: false,
        clicked: false,
        submitted: false,
        error: null,
      });
    }
    const warnings = this.licenceWarnings.concat(this.videoWarnings);
    if (warnings.length > 0 && this.state.requireAcknowledgement === false) {
      this.setState({ requireAcknowledgement: true });
    } else if (warnings.length === 0 && this.state.requireAcknowledgement === true) {
      this.setState({ requireAcknowledgement: false });
    }
  }

  render() {
    const {
      props: { video, model, controller, ...props },
    } = this;
    const {
      licences,
      selectedLicence,
      userNote,
      requireAcknowledgement,
      acknowledgement,
      clicked,
      loading,
      submitted,
      error,
    } = this.state;

    if (video === null) {
      return (
        <ScrollableOverlay className={styles.wrapper} {...props}>
          <Loading />
        </ScrollableOverlay>
      );
    }

    const { licenceWarnings, videoWarnings } = this;

    let acknowledgementText;
    if (licenceWarnings.length > 0 && videoWarnings.length > 0) {
      acknowledgementText =
        'The licence and video selected both appear to be in an unclearable state. You can still submit an issue, but we may not be able to clear the claim on this video.';
    } else if (licenceWarnings.length > 0) {
      acknowledgementText =
        "The licence you have selected doesn't look like it can be used to clear claims on this video. You can still submit an issue, but we may not be able to clear the claim using this licence.";
    } else if (videoWarnings.length > 0) {
      acknowledgementText =
        'The video you have selected looks like it is in an unclearable state. You can still submit an issue, but we may not be able to clear the claim on this video.';
    }
    return (
      <ScrollableOverlay className={styles.wrapper} {...props}>
        {submitted !== true && (
          <h2 data-test-video-claims-statuses-modal-two-title className={styles.heading}>
            Submit a Claim support request
          </h2>
        )}
        {submitted && (
          <h2 data-test-video-claims-statuses-modal-two-title className={styles.heading}>
            Request submitted
          </h2>
        )}
        <main className={classes(styles.body, submitted && styles.submittedHeight)}>
          {submitted !== true && (
            <form className={classes(styles.form, clicked && 'show-errors')} onSubmit={this.submitClaimIssue}>
              <label>Video</label>
              {videoWarnings.map((warning, index) => (
                <p
                  className={classes(styles.fieldLabel, styles.fieldError)}
                  key={index}
                  data-test-video-claims-statuses-modal-two-issue-video-warning
                >
                  {warning}
                </p>
              ))}
              <VideoCard video={video} />
              <label htmlFor="submit-claim-issue-licence-selector">Licence</label>
              {licenceWarnings.map((warning, index) => (
                <p
                  className={classes(styles.fieldLabel, styles.fieldError)}
                  key={index}
                  data-test-video-claims-statuses-modal-two-issue-licence-warning
                >
                  {warning}
                </p>
              ))}
              <LicenceSelector
                id="submit-claim-issue-licence-selector"
                dropup={true}
                maxResults={3}
                options={licences}
                selected={selectedLicence}
                required={true}
                onChange={(selectedLicence) => this.setState({ selectedLicence })}
                placeholder="Select a licence..."
              />
              <label htmlFor="user-note">More information</label>
              <TextArea
                id="user-note"
                className={styles.userNote}
                value={userNote}
                onChange={(event) => {
                  this.setState({ userNote: event.target.value });
                }}
                placeholder="Additional comments about this claim..."
                maxLength={255}
                data-test-video-claims-statuses-modal-two-user-note
              />
              <p className={classes(styles.fieldLabel, styles.marginTop)}>
                We&apos;ll review your request and add the claim status here if applicable. Keep an eye on your inbox or
                check back here in 48 hours.
              </p>
              {requireAcknowledgement && (
                <div className={styles.acknowledgementWrapper}>
                  <p
                    className={classes(styles.fieldLabel, styles.warningIconWrapper)}
                    data-test-video-claims-statuses-modal-two-acknowledgement-text
                  >
                    <span>
                      <Warning />
                    </span>
                    {acknowledgementText}
                  </p>
                  <label
                    data-test-video-claims-statuses-modal-two-acknowledgement-checkbox
                    className={styles.acknowledgementCheckbox}
                  >
                    <CheckboxInput
                      checked={acknowledgement}
                      required={true}
                      onChange={(event) => this.setState({ acknowledgement: event.target.checked })}
                    />{' '}
                    I understand
                  </label>
                </div>
              )}
              {error && <p className={classes(styles.text, styles.error)}>{error}</p>}
              <Button
                className={styles.submitButton}
                prominence={this.isValid ? ButtonProminence.SECONDARY : ButtonProminence.DISABLED}
                type="submit"
                onClick={() => this.setState({ clicked: true })}
                data-test-video-claims-statuses-modal-two-submit-button
              >
                Submit
              </Button>
            </form>
          )}
          <CSSTransition in={loading || submitted} {...transitionModal}>
            <div data-test-video-claims-statuses-modal-three-success-copy className={styles.overlay}>
              {loading && <Loading />}
              {submitted && (
                <>
                  <p className={classes(styles.fieldLabel, styles.warningIconWrapper, styles.submittedText)}>
                    <span>
                      <CheckmarkCircle
                        color={rebrand.semantic[SemanticInformationType.SUCCESS]['70'].toString()}
                        checkColor={rebrand.semantic[SemanticInformationType.SUCCESS]['20'].toString()}
                        size={16}
                      />
                    </span>
                    Success! Your support request has been submitted. You should receive an email, so be sure to check
                    your inbox. Our team are looking into the issue and should have this resolved within 48 hours.
                  </p>
                  <Button
                    onClick={props.onRequestClose}
                    prominence={ButtonProminence.SECONDARY}
                    size={ButtonSize.SMALL}
                    data-test-video-claims-statuses-modal-three-close-button
                    className={styles.close}
                  >
                    Close
                  </Button>
                </>
              )}
            </div>
          </CSSTransition>
        </main>
      </ScrollableOverlay>
    );
  }
}
