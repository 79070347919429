import * as React from 'react';
import { classes, style } from 'typestyle';
import { rebrand } from '../../../../theme/color';
import { px } from 'csx';
import { centerCenter } from 'csstips/lib/flex';

const styles = style(centerCenter, {
  display: 'inline-flex',
  backgroundColor: rebrand.primary1.toString(),
  borderRadius: px(4),
  width: px(24),
  height: px(24),
  $nest: {
    '& + h1, & + h2, & + h3, & + h4, & + h5, & + h6': {
      display: 'inline-block',
      paddingLeft: px(8),
    },
    '& svg': {
      maxWidth: px(14),
      maxHeight: px(14),
    },
    '& path': {
      fill: 'white',
    },
  },
});

export type IconSquareProps = {
  className?: string;
  color?: string;
};

export const IconSquare: React.SFC<IconSquareProps> = ({ children, className, color }) => {
  const colourStyles = color ? { backgroundColor: color } : null;
  return (
    <div className={classes(styles, className)} style={colourStyles}>
      {children}
    </div>
  );
};
