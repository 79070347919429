import * as React from 'react';
import { Strapline } from './atoms/Strapline';
import { ReactNodeArray } from 'react';
import { CtaButton } from './atoms/CtaButton';
import { BasePanel, BasePanelProps } from './BasePanel';
import { CoverFlow } from './atoms/CoverFlow';
import { isArray } from '../../utilities/objects';

export type ArtistsPanelProps = React.Component['props'] & {
  className?: string;
  src?: string;
  gradient?: BasePanelProps['gradient'];
  strapline?: ReactNodeArray;
  artists?: string[];
  cta?: { href: string; text: string };
};

export const ArtistsPanel: React.SFC<ArtistsPanelProps> = ({
  children,
  className,
  src,
  gradient,
  strapline,
  artists,
  cta,
}) => {
  const [firstChild, ...remainingChildren] = isArray(strapline) ? strapline : [];
  return (
    <BasePanel gradient={gradient} src={src}>
      {isArray(strapline) && (
        <Strapline>
          <span>{firstChild}</span>
          {remainingChildren}
        </Strapline>
      )}
      {isArray(artists) && (
        <CoverFlow>
          {artists.map((artistImgUrl, index) => (
            <img key={index} src={artistImgUrl} />
          ))}
        </CoverFlow>
      )}
      {cta && <CtaButton href={cta.href}>{cta.text}</CtaButton>}
    </BasePanel>
  );
};
