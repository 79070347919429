import {
  BLOGOSPHERE_UTM_CAMPAIGN,
  BLOGOSPHERE_UTM_MEDIUM,
  BLOGOSPHERE_UTM_SOURCE,
  SIGN_UP_ACTION_QUERY
} from '../constants';

export default async function setBlogosphereCampaign() {
  document.cookie = `utm_medium=${BLOGOSPHERE_UTM_MEDIUM}`;
  document.cookie = `utm_source=${BLOGOSPHERE_UTM_SOURCE}`;
  document.cookie = `utm_campaign=${BLOGOSPHERE_UTM_CAMPAIGN}`;

  window.location.href = `browse/collection/blogosphere-festival-2019?${SIGN_UP_ACTION_QUERY}&utm_source=blogosphere&utm_medium=blogosphere&utm_campaign=blogosphere`;
}