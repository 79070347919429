import * as React from 'react';
import { BaseSvg, SVGIconProps, SVGIconStyle } from './BaseSvg';

export type InfoCircleProps = SVGIconProps & { iconStyle?: SVGIconStyle };

export const InfoCircle: React.SFC<InfoCircleProps> = ({
  size = 12,
  color = '#2E2E3B',
  iconStyle = SVGIconStyle.OUTLINE,
  ...props
}) => (
  <BaseSvg width={size} height={size} viewBox="0 0 20 20" {...props}>
    <path
      fill={color}
      transform="translate(-2 -2)"
      fillRule="evenodd"
      d={
        iconStyle === SVGIconStyle.OUTLINE
          ? 'M11 17h2v-6h-2v6Zm1-15a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16ZM11 9h2V7h-2v2Z'
          : 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm1 15h-2v-6h2v6Zm0-8h-2V7h2v2Z'
      }
    />
  </BaseSvg>
);
