import * as React from 'react';
import { style } from 'typestyle';
import {
  horizontal, vertical, verticallySpaced, scroll, center, centerCenter, horizontallySpaced,
  centerJustified
} from 'csstips';
import { mediaTablet, mediaMobileOnly } from '../theme/media';
import { colorWhite } from '../theme/color';
import { Carousel } from './carousel';
import { em, important, percent, px } from 'csx';
import { fontHeading } from '../theme/font';
import { ButtonPill } from './button-pill';
import { observer } from 'mobx-react';
import {RatecardSchema, RatecardsSchema} from '../types/schema';
import {formatCurrency} from '../lib/currency';

export interface PageHomePricingGridModel {
  ratecards: RatecardsSchema;
  ratecardTypes: Array<{ value: string, label: string }>;
  ratecardType: string;
  ratecardLoading: boolean;
}

export interface PageHomePricingGridProps {
  onClickShowCalculator?(event: React.MouseEvent<any>): void;
  onSelectRateType(type: string): void;
  model: PageHomePricingGridModel;
  i18n?: {
    explanation: string;
    perSong: string;
    avv: string;
  }
}

export interface PageHomePricingGridState {
  selected: string;
}

const constants = {
  height: px(250)
};

@observer
export class PageHomePricingGrid extends React.Component<PageHomePricingGridProps, PageHomePricingGridState> {
  constructor(props) {
    super(props);
    this.state = { selected: 'US' };
  }

  render() {
    const { i18n = PageHomePricingGrid.i18n, model, onClickShowCalculator } = this.props;
    const { selected } = this.state;

    if (!model.ratecards) return null;

    const ratecard = this.getRateCard(selected);

    return (
      <div className={PageHomePricingGrid.styles.wrapper}>
        <Carousel className={PageHomePricingGrid.styles.container} innerClassName={PageHomePricingGrid.styles.containerInner}>
          {ratecard.map((band, index) => (
            <div key={index.toString()} className={PageHomePricingGrid.styles.row}>
              <div>
                <div className={PageHomePricingGrid.styles.price}>{formatCurrency(band.rate, band.currency).replace('.00', '')}</div>
                <div className={PageHomePricingGrid.styles.qualifier}>{i18n.perSong}</div>
              </div>
              <div>
                <div className={PageHomePricingGrid.styles.band}>{band.name}</div>
                <div className={PageHomePricingGrid.styles.qualifier}>{i18n.avv}</div>
              </div>
            </div>
          ))}
        </Carousel>
        <div className={PageHomePricingGrid.styles.controls}>
          <div className={PageHomePricingGrid.styles.buttons}>
            {!model.ratecardLoading && model.ratecardTypes.map((type, index) => (
              <ButtonPill
                key={index.toString()}
                children={type.label}
                primary={model.ratecardType === type.value}
                muted={model.ratecardType !== type.value}
                onClick={this.changeType.bind(this, type.value)}
              />
            ))}
            {model.ratecardLoading && (
              <ButtonPill
                children={"Loading..."}
                primary={false}
                muted={true}
              />
            )}
          </div>
        </div>
        <div className={PageHomePricingGrid.styles.controls}>
          <div className={PageHomePricingGrid.styles.buttons}>
            <ButtonPill primary={selected === 'US'} muted={selected !== 'US'} onClick={this.select.bind(this, 'US')}>$ USD</ButtonPill>
            <ButtonPill primary={selected === 'EU'} muted={selected !== 'EU'} onClick={this.select.bind(this, 'EU')}>€ EUR</ButtonPill>
            <ButtonPill primary={selected === 'UK'} muted={selected !== 'UK'} onClick={this.select.bind(this, 'UK')}>£ GBP</ButtonPill>
          </div>
          {onClickShowCalculator && (
            <div className={PageHomePricingGrid.styles.showPricing}>
              <span>or</span>
              <ButtonPill onClick={onClickShowCalculator}>Show calculator</ButtonPill>
            </div>
          )}
        </div>
      </div>
    )
  }

  select = (selected: string) => {
    this.setState({ selected });
  };

  changeType = (type: string) => {
    this.props.onSelectRateType(type);
  };

  getRateCard = (id: string): Array<RatecardSchema> => {
    const { ratecards } = this.props.model;

    switch (id) {
      case 'UK':
        return ratecards.currency.GBP;

      case 'EU':
        return ratecards.currency.EUR;

      default:
        return ratecards.currency.USD;
    }
  };

  static i18n = {
    explanation: 'Based on your average views per video.',
    perSong: 'per song',
    avv: 'average views per video'
  };

  static styles = {
    wrapper: style({
      ...vertical,
      ...verticallySpaced(40),
      ...centerCenter
    }),
    container: style(
      mediaTablet({ height: constants.height })
    ),
    containerInner: style(
      mediaMobileOnly({
        overflowX: important('hidden'),
        overflowY: important('hidden'),
        padding: '0 1rem',
        ...vertical,
        ...verticallySpaced(30)
      }),
      mediaTablet({
        ...horizontal,
        ...scroll,
        padding: '0 1rem',
        $nest: {
          '&> div': {
            borderRight: `2px solid ${colorWhite.fade(0.2).toString()}`
          },
          '&> div:last-child': {
            borderRight: '0'
          }
        }
      })
    ),
    row: style(
      {
        color: colorWhite.toString(),
        flex: 1,
        $nest: {
          '&> div:first-child': {
            flex: 4
          },
          '&> div:last-child': {
            textAlign: 'right',
            flex: 1
          }
        }
      },
      mediaMobileOnly({
        ...horizontal,
        ...center
      }),
      mediaTablet({
        ...vertical,
        ...center,
        height: constants.height,
        minWidth: px(180),
        padding: '60px 30px',
        $nest: {
          '&> div:first-child': {
            marginBottom: px(30),
            textAlign: 'center',
          },
          '&> div:last-child': {
            textAlign: 'center',
          }
        }
      })
    ),
    price: style({
      fontFamily: fontHeading,
      fontSize: px(52),
      lineHeight: px(52),
    }),
    band: style({
      fontFamily: fontHeading,
      fontSize: px(20),
      fontWeight: 500,
      lineHeight: em(1.2),
    }),
    qualifier: style(
      {
        fontSize: px(14),
        lineHeight: em(1.2),
        fontWeight: 500
      },
      mediaMobileOnly({
        fontSize: px(12)
      })
    ),
    controls: style(
      mediaMobileOnly({
        ...vertical,
        ...verticallySpaced(30),
        ...center
      }),
      mediaTablet({
        ...horizontal,
        ...horizontallySpaced(10)
      })
    ),
    buttons: style({
      ...horizontal,
      ...horizontallySpaced(10),
      ...centerCenter,
      flexWrap: 'wrap',
    }),
    showPricing: style({
      ...horizontal,
      ...horizontallySpaced(10),
      ...center,
      ...centerJustified,
      $nest: {
        '&> span': {
          fontWeight: 500,
          color: colorWhite.toString()
        }
      }
    })
  }
}