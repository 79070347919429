import * as React from 'react';

export class HideOnError extends React.Component<React.Component['props']> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError === true) return null; // If rendering fails then just hide the nested elements

    return <>{this.props.children}</>;
  }
}
