import * as React from 'react';
import { ModalProps } from '../../modal';
import { ScrollableOverlay } from '../../scrollable-overlay/scrollable-overlay';
import overlayStyles, { ScrollableOverlayBodyStyle } from '../../../styles/scrollable-overlay';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { Component, deps, inject, observer } from '../../../lib/component';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { rebrand } from '../../../theme/color';
import { StarCircle } from '../atoms/icons/StarCircle';
import { centerCenter, flexRoot } from 'csstips';

const styles = {
  wrapper: style({
    display: 'block',
    maxWidth: px(546),
    boxShadow: `0px 0px 24px 0px ${rebrand.primary[100].toString()}`,
  }),
  heading: style({
    fontSize: px(32),
  }),
  body: style(ScrollableOverlayBodyStyle, {
    padding: '1.5rem 0 0',
    borderTop: `1px solid ${rebrand.light1.toString()}`,
    textAlign: 'center',
    $nest: {
      '& > p': {
        margin: '0 0 1.5rem',
        fontSize: px(20),
      },
    },
  }),
  planCredits: style(flexRoot, centerCenter, {
    borderRadius: px(8),
    padding: '12px 16px',
    backgroundColor: rebrand.primary[10].toHexString(),
    color: rebrand.primary[100].toHexString(),
    margin: '0 auto 24px',
    width: 'fit-content',
    fontWeight: 700,
  }),
  starCircle: style({
    marginRight: '8px',
    flexShrink: 0,
  }),
  button: style({
    display: 'block',
    width: '100%',
  }),
};

export type PostUpgradeModalProps = ModalProps & { className?: string };

@inject(deps)
@observer
export class PostUpgradeModal extends Component<PostUpgradeModalProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { onRequestClose } = this.props;
    const { postUpgradeModalPlanName, postUpgradeModalPlanCredits } = this.props.model.modal;

    return (
      <ScrollableOverlay className={styles.wrapper} {...this.props}>
        <div className={overlayStyles.header}>
          <h3 className={classes(overlayStyles.title, styles.heading)}>
            {postUpgradeModalPlanName} SUBSCRIPTION ACTIVE
          </h3>
        </div>

        <div className={styles.body}>
          {postUpgradeModalPlanCredits && (
            <div className={styles.planCredits}>
              <StarCircle
                color={rebrand.primary[100].toHexString()}
                className={styles.starCircle}
                size={20}
              ></StarCircle>
              +{postUpgradeModalPlanCredits} Premium track credits
            </div>
          )}

          <p>
            <strong>Your new Premium track credits are ready to use!</strong>
          </p>

          <div>
            <Button
              prominence={ButtonProminence.PRIMARY}
              size={ButtonSize.LARGE}
              className={styles.button}
              onClick={onRequestClose}
              data-test-downgrade-modal-button
            >
              Let&apos;s go!
            </Button>
          </div>
        </div>
      </ScrollableOverlay>
    );
  }
}
