import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { TypeaheadComponentProps } from 'react-bootstrap-typeahead/types/components/Typeahead';
import { RenderMenuItemChildren } from 'react-bootstrap-typeahead/types/components/TypeaheadMenu';
import { InputProps } from 'react-bootstrap-typeahead/types/components/Input';
import { LicenceSchema } from '../../../../types/schema';
import { TrackArtwork } from '../TrackArtwork';
import { classes, cssRule, style } from 'typestyle';
import { em, percent, px } from 'csx';
import { isEmpty } from '../../utilities/objects';
import { center, flexRoot } from 'csstips';
import { colorWhite, rebrand } from '../../../../theme/color';
import { zOverContent } from '../../../../theme/z';
import { SemanticInformationType } from '../../utilities/types';
import { commonInputStyles } from '../../utilities/inputs';

const styles = {
  wrapper: style({
    $nest: {
      '& .rbt-input-main': {
        ...commonInputStyles,
        width: percent(100),
        fontSize: em(0.9),
        padding: '8px 4px',
      },
      '& .dropdown-menu': {
        background: colorWhite.toString(),
        border: '1px solid',
        borderColor: rebrand.dark1.toString(),
        borderRadius: px(8),
        zIndex: zOverContent,
        marginTop: px(2),
      },
      '& .dropdown-item.rbt-menu-pagination-option, & .dropdown-item .no-results': {
        display: 'flex',
        justifyContent: 'center',
        padding: px(8),
        fontSize: em(0.75),
        color: rebrand.dark1.toString(),
      },
      '& .dropdown-item.rbt-menu-pagination-option': {
        textDecoration: 'underline',
      },
      '& .dropdown-item.active, & .active > .menu-item': {
        background: rebrand.light1.toString(),
      },
    },
  }),
  menuItem: style(flexRoot, center, {
    padding: '8px 16px',
    $nest: {
      'a:not(last-child) > &': {
        borderBottom: `1px solid ${rebrand.light1.toString()}`,
      },
    },
  }),
  trackArtwork: style({
    width: px(50),
    borderRadius: em(0.3),
    marginRight: px(8),
  }),
  trackDetails: style({
    color: rebrand.dark1.toString(),
    $nest: {
      '& p': {
        margin: 0,
      },
      '& p:not(.track-title)': {
        fontSize: em(0.75),
      },
    },
  }),
};

cssRule(`.show-errors .${styles.wrapper}.invalid .rbt-input-main`, {
  borderColor: rebrand.semantic[SemanticInformationType.ERROR]['70'].toString(),
});

export type LicenceSelectorProps = Pick<
  TypeaheadComponentProps,
  'className' | 'id' | 'placeholder' | 'dropup' | 'maxResults' | 'defaultSelected'
> & {
  options: LicenceSchema[];
  selected: LicenceSchema;
  onChange?: (selected: LicenceSchema) => void;
  required?: boolean;
};

const LicenceMenuItem: RenderMenuItemChildren = (option: LicenceSchema) => (
  <div data-test-video-claims-statuses-modal-two-license-item className={`menu-item ${styles.menuItem}`}>
    <TrackArtwork className={styles.trackArtwork} width={50} track={option.track} />
    <div className={styles.trackDetails}>
      <p>{option.track.artists.map((artist) => artist.name).join(', ')}</p>
      <p className="track-title">{option.track.title}</p>
      <p>
        {!isEmpty(option.order) && !isEmpty(option.order.youtube_channels) && (
          <span>{option.order.youtube_channels.map((channel) => channel.name).join(', ')} &middot; </span>
        )}
        ({option.short_id})
      </p>
    </div>
  </div>
);

export class LicenceSelector extends React.Component<LicenceSelectorProps> {
  state = {
    inputValue: [],
  };

  render() {
    const { className, onChange, selected, required, ...props } = this.props;
    return (
      <Typeahead
        {...props}
        selected={this.state.inputValue}
        onBlur={() => this.setState({ inputValue: !isEmpty(selected) ? [selected] : [] })}
        onChange={(inputValue) => {
          this.setState({ inputValue });
          const [licence] = inputValue;
          !isEmpty(licence) && onChange(licence as LicenceSchema);
        }}
        inputProps={
          {
            'data-test-select-a-license': '',
          } as InputProps
        }
        className={classes(styles.wrapper, selected === null && required === true && 'invalid', className)}
        emptyLabel={<span className="no-results">No licences found.</span>}
        labelKey={(option: LicenceSchema) =>
          isEmpty(option)
            ? ''
            : `(${option.short_id}) ${option.track.title} by ${option.track.artists
                .map((artist) => artist.name)
                .join(', ')}`
        }
        renderMenuItemChildren={LicenceMenuItem}
      />
    );
  }
}
