import { API, APIError, APIResponse, UserStatusResponse } from '../types';
import { action } from 'mobx';
import { RequestController } from '../../request/controller';
import { EnvModel } from '../../env/model';
import { CacheController } from '../../cache/controller';
import { CardSchema, OrderSchema } from '../../../types/schema';
import { Basket } from '../../../types';
import { AnalyticsController } from '../../analytics/controller';
import { EssentialsSubscriptionUrlPayload } from '../../../components/project-happy/utilities/types';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe } from '@stripe/stripe-js';

export class CheckoutAPIController extends API {
  constructor(
    public request: RequestController,
    public cache: CacheController,
    public env: EnvModel,
    public analytics: AnalyticsController
  ) {
    super(request, cache, env);
  }

  @action
  getBasket = async (basketIdentity: string) => {
    const url = `/checkout/basket/${basketIdentity}?append=isAvailable`;
    const resp = await this.request.get({ url });
    await APIError.checkResponse(resp);
    const json = (await resp.json()) as APIResponse<Basket>;

    this.cache.addTracks(json.data.tracks);

    return json;
  };

  @action
  createBasket = async () => {
    const url = `/checkout/basket`;
    const resp = await this.request.post({ url });
    const json = (await resp.json()) as APIResponse<Basket>;

    return json;
  };

  @action
  addToBasket = async (basketIdentity: string, trackIdentity: string, quantity = 1) => {
    const url = `/checkout/basket/${basketIdentity}`;
    const body = JSON.stringify([{ track_id: trackIdentity, quantity }]);
    const resp = await this.request.patch({ url, body });
    await APIError.checkResponse(resp);

    const json = (await resp.json()) as APIResponse<Basket>;

    this.cache.addTracks(json.data.tracks);

    return json;
  };

  @action
  removeFromBasket = async (basketIdentity: string, trackIdentity: string) => {
    return this.addToBasket(basketIdentity, trackIdentity, 0);
  };

  @action
  createCustomer = async (userId: string, stripeToken: string, card: any) => {
    const { address_line1, address_line2, address_city, address_zip, address_country } = card;

    const url = `/user/${userId}/payments`;
    const body = JSON.stringify({
      address_line1,
      address_line2,
      address_city,
      address_zip,
      address_country,
    });

    const resp = await this.request.post({ url, body });
    const json = (await resp.json()) as APIResponse<Array<CardSchema>>;

    return json;
  };

  @action
  createOrder = async (
    userId: string,
    basketId: string,
    channelId: string,
    coupon: string = null,
    referrerChannelId: string = null,
    credits_used: number
  ): Promise<APIResponse<OrderSchema>> => {
    const url = `/user/${userId}/orders`;
    const body = JSON.stringify({ basketId, channelId, coupon, referrerChannelId, credits_used });
    const resp = await this.request.post({ url, body });
    await APIError.checkResponse(resp);
    return (await resp.json()) as APIResponse<OrderSchema>;
  };

  @action
  getOrder = async (userId: string, orderId: string) => {
    const url = `/user/${userId}/orders/${orderId}`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<OrderSchema>;

    return json;
  };

  @action
  payOrder = async (userId: string, orderId: string, payment: string) => {
    const url = `/user/${userId}/orders/${orderId}`;
    const body = JSON.stringify({ payment });
    const resp = await this.request.patch({ url, body });
    const json = (await resp.json()) as APIResponse<OrderSchema>;

    return json;
  };

  @action
  checkoutWithStripe = async (stripeSessionId: string) => {
    const stripe = await loadStripe(this.env.stripeKey);
    if (!stripe) {
      return;
    }

    this.analytics.sendMixpanel('Redirecting to Stripe');
    return stripe.redirectToCheckout({
      sessionId: stripeSessionId,
    });
  };

  @action
  getStripeSession = async (orderIdentity: string) => {
    const url = `/order/${orderIdentity}/checkout-session`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<OrderSchema>;

    return json;
  };

  @action
  markOrderAsComplete = async (userIdentity: string, orderIdentity: string) => {
    const url = `/user/${userIdentity}/orders/${orderIdentity}/complete`;
    await this.request.patch({ url });
  };

  @action
  getSubscriptionDetails = async (channelId: string): Promise<APIResponse<EssentialsSubscriptionUrlPayload>> => {
    const response = await this.request.post({
      url: '/subscription',
      body: JSON.stringify({ youtube_channel_id: channelId }),
    });
    await APIError.checkResponse(response);
    return response.json();
  };
}
