import isNil from 'lodash/isNil';
import { action, observable, runInAction } from 'mobx';
import { ArtistPageModel } from './model';
import { ArtistAPIController } from '../../api/artist/controller';
import { Preloadable } from '../types';
import { RouterState } from 'react-router';
import { RedirectFunction } from 'react-router';
import { ReleaseAPIController } from '../../api/release/controller';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import { ImageController } from '../../image/controller';
import { RouterModel } from '../../router/model';
import { BugsnagController } from '../../bugsnag/controller';
import { ContentModel } from '../../content/model';
import { stripQueryString } from '../../../lib/string';
import { TrackSchema } from '../../../types/schema';
import { HttpError } from '../../../types';
import { APIError } from '../../api/types';
import { UserModel } from '../../user/model';
import { AuthModel } from '../../auth/model';
import { browserRegion } from '../../../components/project-happy/utilities/browserRegion';

export class ArtistPageController implements Preloadable {
  constructor(
    private model: ArtistPageModel,
    private env: EnvModel,
    private ui: UIController,
    private artist: ArtistAPIController,
    private release: ReleaseAPIController,
    private image: ImageController,
    private router: RouterModel,
    private bugsnag: BugsnagController,
    private content: ContentModel,
    private auth: AuthModel
  ) {}

  @action
  getReleaseSlug = (path) => path.substring(path.lastIndexOf('/') + 1);

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    runInAction(() => {
      this.model.slug = stripQueryString(nextState.params['artist']);
      this.model.release = stripQueryString(nextState.params['release']) || null;
      this.model.releasePage = parseInt(nextState.location.query['page']) || 1;
      this.model.showBio = false;
      this.model.loading = true;
      this.model.isMissing = true;
    });
    const userRegion = this.auth.user ? this.auth.user.country : null;

    try {
      const [artist, releases, tracks, misc] = await Promise.all([
        this.artist.getArtist(this.model.slug, ['releases', 'images']),
        this.artist.getReleases(this.model.slug),
        this.artist.getTracks(this.model.slug, undefined, 1, userRegion),
        this.artist.getMiscTracks(this.model.slug, undefined, 1, userRegion),
        this.env.ready,
      ]);

      runInAction(() => {
        this.model.artist = artist.data;
        this.model.releases = releases.data;
        this.model.otherReleases = [];
        this.model.tracks = tracks.data;
        this.model.misc = misc.data;
        this.model.pageBody = this.content.strapArtist;
        this.model.activeRelease = null;

        this.model.releaseModels = this.model.releases.reduce(
          (acc, release) => ({
            ...acc,
            [release.identity]: observable({
              release,
              tracks: [],
              pagination: null,
              loading: true,
              loadingPage: null,
              artist: artist.data,
            }),
          }),
          {}
        );

        const letter = this.model.slug[0];

        this.ui.setBreadcrumbs([
          {
            path: '/browse',
            label: 'Browse',
          },
          {
            path: '/browse/artists',
            label: 'Artists',
          },
          {
            path: '/browse/artists/' + letter.toLowerCase(),
            label: letter.toUpperCase(),
          },
          {
            path: '/music/artists/' + this.model.slug,
            label: this.model.artist ? this.model.artist.name : this.model.slug,
          },
        ]);

        this.ui.preloadPlayer(this.model.tracks[0]);
      });

      await this.ui.setSEO(nextState.location.pathname.replace(/\/[\w-]+$/, ''), {
        artist: artist.data.name,
      });

      const preloadFirstRelease = () => {
        if (this.model.releases.length) {
          this.ui.preloadPlayer(this.model.releaseModels[this.model.releases[0].identity].tracks[0]);
        }
      };

      if (this.env.isServer) {
        let promises = [this.loadSoundsLike(this.model.artist.identity)];

        const release = this.model.releases.find(({ slug }) => slug === this.model.release);
        if (release) {
          promises.push(this.loadReleaseTracks(release.identity, this.model.releasePage));
        } else {
          promises = promises.concat(this.model.releases.map((release) => this.loadReleaseTracks(release.identity)));
        }

        await Promise.all(promises);
      } else {
        this.loadSoundsLike(this.model.artist.identity);
        Promise.all(this.model.releases.map((release) => this.loadReleaseTracks(release.identity))).then(() => {
          preloadFirstRelease();
        });
        this.ui.scrollToTop();
      }

      this.model.isMissing = false;

      return;
    } catch (error) {
      const isApiError = error instanceof APIError;
      if (!isApiError || (isApiError && error.code !== 404)) {
        this.bugsnag.notifyException(error);
      }

      this.env.ssrStatus = 404;
      this.model.isMissing = true;
    } finally {
      runInAction(() => {
        this.model.loading = false;
      });
    }
  };

  @action
  onEnterRelease = async (nextState: RouterState, replace: RedirectFunction) => {
    // Note: Various artist requires us to retrieve the release in a singular manner
    // because the combined data set is large
    const singleReleaseList = ['various-artists'];
    const releaseSlug = this.getReleaseSlug(nextState.location.pathname);

    runInAction(() => {
      this.model.slug = stripQueryString(nextState.params['artist']);
      this.model.release = releaseSlug;
      this.model.misc = [];
      this.model.loading = true;
    });

    try {
      const [artist, releases, singleRelease] = await Promise.all([
        this.artist.getArtist(this.model.slug),
        this.artist.getReleases(this.model.slug),
        this.artist.getSingleRelease(this.model.slug, releaseSlug),
        this.env.ready,
      ]);

      runInAction(() => {
        this.model.isSingle = singleReleaseList.includes(this.model.slug);
        this.model.artist = artist.data;
        this.model.releases = this.model.isSingle
          ? [singleRelease.data]
          : releases.data.filter((release) => release.slug === this.model.release);
        this.model.otherReleases = releases.data.filter((release) => release.slug !== this.model.release);
        this.model.tracks = [];
        this.model.pageBody = this.content.strapRelease;
        this.model.releaseModels = this.model.releases.reduce(
          (acc, release) => ({
            ...acc,
            [release.identity]: observable({
              release,
              tracks: [],
              pagination: null,
              loading: true,
              loadingPage: null,
              artist: artist.data,
            }),
          }),
          {}
        );
      });

      const { identity, title } = this.model.releases.find((r) => r.slug === this.model.release);

      await this.loadReleaseTracks(identity);

      const release = this.model.releaseModels[identity];
      this.model.activeRelease = release.release;

      runInAction(() => {
        this.ui.setBreadcrumbs([
          {
            path: '/browse/artists',
            label: 'Artists',
          },
          {
            path: '/browse/artists/a',
            label: this.model.slug[0].toUpperCase(),
          },
          {
            path: '/music/artists/' + this.model.slug,
            label: this.model.artist.name,
          },
          {
            path: '/music/artists/' + this.model.slug + '/release/' + this.model.release,
            label: title,
          },
        ]);
      });

      const pathMatch = nextState.location.pathname.replace(/artists\/[\w-]+/, 'artists').replace(/\/[\w-]+$/, '');

      await this.ui.setSEO(pathMatch, {
        artist: artist.data.name,
        release: release.release.title,
      });

      if (this.env.isServer) {
        const promises = [this.loadSoundsLike(this.model.artist.identity)];

        await Promise.all(promises);
      } else {
        this.loadSoundsLike(this.model.artist.identity);
        this.ui.scrollToTop();
      }

      this.ui.preloadPlayer(release.tracks[0]);

      return;
    } catch (e) {
      if (this.model.slug) {
        this.router.replace(`/music/artists/${this.model.slug}`);
      } else {
        this.router.replace(`/404`);
      }
    } finally {
      runInAction(() => {
        this.model.loading = false;
      });
    }
  };

  @action
  loadReleaseTracks = async (release: string, page = 1) => {
    if (!release) return;

    runInAction(() => {
      this.model.releaseModels[release].loadingPage = page;
      this.model.releaseModels[release].loading = true;
    });
    const userRegion = this.auth.user ? this.auth.user.country : null;
    const [releaseTracks, singleReleaseTracks] = await Promise.all([
      this.release.getReleaseTracks(
        release,
        ['images', 'artists', 'audio', 'rightsholders', 'releases', 'tags'],
        page,
        userRegion
      ),
      this.model.release
        ? this.artist.getSingleRelease(this.model.slug, this.model.release, ['tracks.releases'])
        : null,
    ]);

    runInAction(() => {
      this.model.releaseModels[release].loadingPage = null;
      this.model.releaseModels[release].tracks =
        this.model.isSingle && singleReleaseTracks && singleReleaseTracks.data
          ? singleReleaseTracks.data.tracks
          : releaseTracks.data;
      this.model.releaseModels[release].loading = false;
    });

    return;
  };

  @action
  loadSoundsLike = async (artistIdentity: string) => {
    // Stub, in case we re-instate
    return;
  };

  @action
  showBio = () => {
    this.model.showBio = true;
  };

  @action
  hideBio = () => {
    this.model.showBio = false;
  };
}
