import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const ClockLongTimeDuotone: React.SFC<SVGIconProps & { color?: never }> = ({
  size = 116,
  isSquare,
  ...props
}) => (
  <BaseSvg width={size} height={isSquare ? size : size * (115 / 116)} viewBox="0 0 116 115" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#262E3F"
        d="M58 0C26.2438281 0 .5 25.7438281.5 57.5S26.2438281 115 58 115s57.5-25.7438281 57.5-57.5S89.7561719 0 58 0"
      />
      <path
        fill="#FFF"
        d="M58 8.984375c26.751875 0 48.515625 21.76375 48.515625 48.515625S84.751875 106.015625 58 106.015625 9.484375 84.251875 9.484375 57.5 31.248125 8.984375 58 8.984375"
      />
      <path
        stroke="#FEF5FB"
        strokeLinecap="square"
        strokeLinejoin="bevel"
        strokeWidth="8"
        d="M58.006 21.968v35.384L40.308 26.697"
      />
      <path
        stroke="#FEF5FB"
        strokeLinecap="square"
        strokeLinejoin="bevel"
        strokeWidth="8"
        d="M58.006 22.263v35.385l-30.655-17.7"
      />
      <path
        stroke="#FCEAF8"
        strokeLinecap="square"
        strokeLinejoin="bevel"
        strokeWidth="8"
        d="M57.999 22.263v35.385H22.615"
      />
      <path stroke="#FCE1F5" strokeLinecap="square" strokeWidth="8" d="M57.999 21.979v35.384L27.345 75.062" />
      <path
        stroke="#FBD6F1"
        strokeLinecap="square"
        strokeLinejoin="bevel"
        strokeWidth="8"
        d="M58.003 22.262v35.68L40.311 88.6"
      />
      <path stroke="#FACCEE" strokeLinecap="square" strokeLinejoin="bevel" strokeWidth="8" d="M58.006 21.968v71.064" />
      <path
        stroke="#F8B8E7"
        strokeLinecap="square"
        strokeLinejoin="bevel"
        strokeWidth="8"
        d="M58.006 21.968v35.68l17.692 30.658"
      />
      <path
        stroke="#F7ADE3"
        strokeLinecap="square"
        strokeLinejoin="bevel"
        strokeWidth="8"
        d="M58.006 22.262v35.385H93.39"
      />
      <path
        stroke="#F5A3E0"
        strokeLinecap="square"
        strokeLinejoin="bevel"
        strokeWidth="8"
        d="M58.006 22.263v35.385l30.655-17.7"
      />
      <path
        stroke="#F499DC"
        strokeLinecap="square"
        strokeLinejoin="bevel"
        strokeWidth="8"
        d="M58.006 22.263v35.384l17.698-30.654"
      />
      <path stroke="#EA33BA" strokeLinecap="square" strokeWidth="8" d="M58.012 21.979v35.384l30.655 17.699" />
    </g>
  </BaseSvg>
);
