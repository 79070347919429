import * as React from 'react';
import { classes, cssRaw, extend, style } from 'typestyle';
import { em, percent, px } from 'csx';
import { abbreviateNumber } from '../utilities/numbers';
import { YouTubeLogo } from './icons/brands/YouTubeLogo';
import { SVGIcon } from '../../svg-icon';
import UserIcon from '../../../static/icon-user.svg';
import { colorDarkBlueGrey, colorDarkBlueGreyDarker } from '../../../theme/color';
import { mediaDesktop, mediaLargeTablet, mediaUpToDesktop, mediaUpToLargeTablet } from '../utilities/mediaQueries';
import { center, flexRoot } from 'csstips';
import { mediaTablet } from '../../../theme/media';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { screenReaderOnly } from '../utilities/font';
import { animationGradientPulse } from '../../../theme/animation';
import { mixinTextEllipsis } from '../../../theme/mixin';

const commonStyles = {
  wrapper: style({
    display: 'inline-flex',
    textAlign: 'left',
    $nest: {
      '& > img, & > div': {
        display: 'inline-block',
      },
    },
  }),
  img: style({
    borderRadius: em(1),
    backgroundColor: colorDarkBlueGrey.toHexString(),
    flexShrink: 0,
  }),
  channelName: style(mixinTextEllipsis, {
    display: 'block',
    fontWeight: 500,
    lineHeight: 'normal',
    fontFamily: 'Roboto, sans-serif',
    maxWidth: 'calc(100% - 1ch)',
  }),
  channelSubs: style(flexRoot, center, {
    lineHeight: 'normal',
    fontFamily: 'Roboto, sans-serif',
  }),
  youtubeIcon: style({
    marginRight: px(4),
  }),
};

const loadingStyles = style(animationGradientPulse, {
  width: px(100),
  display: 'inline-block',
  background: `linear-gradient(to right, ${colorDarkBlueGrey.toHexString()} 0%, ${colorDarkBlueGrey
    .fade(0.5)
    .toRGBA()} 50%, ${colorDarkBlueGrey.toHexString()} 100%)`,
  height: px(16),
  verticalAlign: 'text-top',
  backgroundSize: percent(200),
  marginRight: px(4),
  $nest: {
    '& p': screenReaderOnly,
  },
});

export enum BadgeSize {
  NORMAL,
  SMALL,
  VARIABLE,
}

const normalSizeStyles: { [key: string]: NestedCSSProperties } = {
  wrapper: {
    height: px(56),
  },
  img: {
    width: px(32),
    height: px(32),
    marginRight: px(8),
  },
  certifiedTick: {
    width: px(12),
    height: px(12),
  },
  channelName: {
    fontSize: px(16),
  },
  channelSubs: {
    fontSize: px(12),
  },
  youtubeIcon: {
    width: px(16),
    height: px(16),
  },
};
const smallSizeStyles: { [key: string]: NestedCSSProperties } = {
  wrapper: {
    height: px(42),
  },
  img: {
    width: px(26),
    height: px(26),
    marginRight: px(4),
  },
  certifiedTick: {
    width: px(10),
    height: px(10),
  },
  channelName: { fontSize: px(12) },
  channelSubs: {
    fontSize: px(8),
  },
  youtubeIcon: {
    width: px(12),
    height: px(12),
  },
};
const sizeStyles: { [key in BadgeSize]: { [key: string]: string } } = {
  [BadgeSize.NORMAL]: {
    wrapper: style(normalSizeStyles.wrapper),
    img: style(normalSizeStyles.img),
    certifiedTick: style(normalSizeStyles.certifiedTick),
    channelName: style(normalSizeStyles.channelName),
    channelSubs: style(normalSizeStyles.channelSubs),
    youtubeIcon: style(normalSizeStyles.youtubeIcon),
  },
  [BadgeSize.SMALL]: {
    wrapper: style(smallSizeStyles.wrapper),
    img: style(smallSizeStyles.img),
    certifiedTick: style(smallSizeStyles.certifiedTick),
    channelName: style(smallSizeStyles.channelName),
    channelSubs: style(smallSizeStyles.channelSubs),
    youtubeIcon: style(smallSizeStyles.youtubeIcon),
  },
  [BadgeSize.VARIABLE]: {
    wrapper: style(mediaUpToLargeTablet(smallSizeStyles.wrapper), mediaLargeTablet(normalSizeStyles.wrapper)),
    img: style(mediaUpToLargeTablet(smallSizeStyles.img), mediaLargeTablet(normalSizeStyles.img)),
    certifiedTick: style(
      mediaUpToLargeTablet(smallSizeStyles.certifiedTick),
      mediaLargeTablet(normalSizeStyles.certifiedTick)
    ),
    channelName: style(
      mediaUpToLargeTablet(smallSizeStyles.channelName),
      mediaLargeTablet(normalSizeStyles.channelName)
    ),
    channelSubs: style(
      mediaUpToLargeTablet(smallSizeStyles.channelSubs),
      mediaLargeTablet(normalSizeStyles.channelSubs)
    ),
    youtubeIcon: style(
      mediaUpToLargeTablet(smallSizeStyles.youtubeIcon),
      mediaLargeTablet(normalSizeStyles.youtubeIcon)
    ),
  },
};

export type YouTubeBadgeProps = React.Component['props'] & {
  className?: string;
  src: string;
  channelName: string;
  subscriberCount: number;
  size?: BadgeSize;
};

const BadgeWrapper: React.SFC<YouTubeBadgeProps> = ({ children, className, size = BadgeSize.VARIABLE }) => {
  const styles = sizeStyles[size];
  return <div className={classes(commonStyles.wrapper, styles.wrapper, className)}>{children}</div>;
};

enum BADGE_CONTENT {
  IMAGE,
  INFO,
}

const BadgeContentsLoading: (props: YouTubeBadgeProps) => JSX.Element[] = ({ size }) => {
  const styles = sizeStyles[size];
  return [
    <img key={BADGE_CONTENT.IMAGE} className={classes(commonStyles.img, styles.img)} src={UserIcon} />,
    <div key={BADGE_CONTENT.INFO}>
      <span className={classes(commonStyles.channelName, styles.channelName)}>
        <span className={loadingStyles}>
          <p>Loading channel name</p>
        </span>
        <SVGIcon.CheckmarkCircle className={styles.certifiedTick} color={colorDarkBlueGrey.toHexString()} />
      </span>
      <span className={classes(commonStyles.channelSubs, styles.channelSubs)}>
        <YouTubeLogo className={classes(commonStyles.youtubeIcon, styles.youtubeIcon)} />{' '}
        <span className={loadingStyles}>
          <p>Loading subscriber count</p>
        </span>
      </span>
    </div>,
  ];
};

export const YouTubeBadge: React.SFC<YouTubeBadgeProps> = ({
  className,
  src,
  channelName,
  subscriberCount,
  size = BadgeSize.VARIABLE,
}) => {
  const styles = sizeStyles[size];
  return (
    <div className={classes(commonStyles.wrapper, styles.wrapper, className)}>
      <img key={BADGE_CONTENT.IMAGE} className={classes(commonStyles.img, styles.img)} src={src} />
      <div key={BADGE_CONTENT.INFO}>
        <span className={classes(commonStyles.channelName, styles.channelName)}>
          {channelName}{' '}
          <SVGIcon.CheckmarkCircle className={styles.certifiedTick} color={colorDarkBlueGrey.toHexString()} />
        </span>
        <span className={classes(commonStyles.channelSubs, styles.channelSubs)}>
          <YouTubeLogo className={classes(commonStyles.youtubeIcon, styles.youtubeIcon)} />{' '}
          {abbreviateNumber(subscriberCount)} subscribers
        </span>
      </div>
    </div>
  );
};

export type LoadableYouTubeBadgeProps = YouTubeBadgeProps & { loading: boolean };

export const LoadableYouTubeBadge: React.SFC<LoadableYouTubeBadgeProps> = ({ loading, ...props }) => {
  if (loading) return <BadgeWrapper {...props}>{BadgeContentsLoading(props)}</BadgeWrapper>;

  return <YouTubeBadge {...props} />;
};
