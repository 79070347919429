import * as React from 'react';
import { classes, style } from 'typestyle';

const styles = style({
  listStyle: 'none',
  padding: 0,
  marginLeft: 0,
  $nest: {
    '& a': {
      color: 'inherit',
      $nest: {
        '&:hover, &:focus': {
          textDecoration: 'underline',
        },
      },
    },
  },
});

export type IconListProps = React.Component['props'] & {
  className?: string;
};

export const IconList: React.FC<IconListProps> = ({ children, className }) => (
  <ul className={classes(styles, className)}>{children}</ul>
);
