import React from 'react';
import { ComponentProps, inject, deps, observer } from '../../../lib/component';
import { StarCircle } from './icons/StarCircle';
import { colorWhite, rebrand } from '../../../theme/color';
import { classes, style } from 'typestyle';
import { center, endJustified, flexRoot } from 'csstips';
import { spacing } from '../../../theme/spacing';
import { px } from 'csx';

interface RemainingCreditsProps extends ComponentProps {
  creditsAvailable: number;
  className?: string;
  darkTheme?: boolean;
}

export const RemainingCredits = inject(deps)(
  observer(
    ({
      creditsAvailable,
      className,
      darkTheme = false,
      model: {
        user: { isSubscribed, isSubscribedToLegacyPlan },
        ui: { projectSubscriptionEnabled },
      },
    }: RemainingCreditsProps) => {
      if (!projectSubscriptionEnabled) return null;
      if (!isSubscribed && !creditsAvailable) return null;
      if (isSubscribedToLegacyPlan) return null;

      let textColor = darkTheme ? colorWhite.toString() : rebrand.contrast[50].toString();
      let starColor = darkTheme ? rebrand.primary[50].toString() : rebrand.primary[100].toString();

      if (!creditsAvailable) {
        textColor = darkTheme ? rebrand.contrast[30].toString() : rebrand.neutralOnLight[40].toString();
      }

      if (!creditsAvailable && !darkTheme) {
        starColor = rebrand.neutralOnLight[40].toString();
      }

      const styles = {
        wrapper: style(flexRoot, center, endJustified, {
          gap: spacing.XXSMALL,
          color: textColor,
          fontSize: px(12),
          fontWeight: 700,
          lineHeight: 1,
        }),
      };

      return (
        <div className={classes(styles.wrapper, className ? className : '')}>
          <StarCircle color={starColor} />
          <span>
            {creditsAvailable} credit{creditsAvailable !== 1 ? 's' : ''} left
          </span>
        </div>
      );
    }
  )
);
