import reject from 'lodash/reject';
import { BasketModel, BASKET_TRACKS, BASKET_IDENTITY } from './model';
import { action, autorun, IReactionDisposer, runInAction } from 'mobx';
import { StorageModel } from '../storage/model';
import { APIController } from '../api/controller';
import { EnvModel } from '../env/model';
import { UserModel } from '../user/model';
import { OnboardingModel } from '../onboarding/model';
import { AnalyticsController } from '../analytics/controller';
import { ImageController } from '../image/controller';
import { FlashMessageTypeInfo } from '../../types';
import { getTrackImageURL } from '../../lib/image';
import { TrackSchema } from '../../types/schema';
import { APIError } from '../api/types';
import { isArray, isEmpty } from '../../components/project-happy/utilities/objects';
import { notify } from '../../components/project-happy/organisms/Notifications';
import siteEvents, { SITE_EVENTS } from '../../components/project-happy/utilities/siteEvents';

export class BasketController {
  constructor(
    private model: BasketModel,
    private storage: StorageModel,
    private api: APIController,
    private env: EnvModel,
    private user: UserModel,
    private onboarding: OnboardingModel,
    private analytics: AnalyticsController,
    private image: ImageController
  ) {
    this._disposers = [];

    if (!env.isServer) {
      this._disposers.push(
        autorun(() => {
          if (typeof this.model.identity === 'string') {
            window.sessionStorage.setItem(BASKET_IDENTITY, this.model.identity);
          } else {
            window.sessionStorage.removeItem(BASKET_IDENTITY);
          }
        })
      );

      this._disposers.push(
        autorun(() => {
          if (isArray(this.model.tracks) && this.model.tracks.length > 0) {
            window.sessionStorage.setItem(BASKET_TRACKS, JSON.stringify(this.model.tracks));
          } else {
            window.sessionStorage.removeItem(BASKET_TRACKS);
          }
        })
      );

      this.maybeCreateBasket();
    }
  }

  private _disposers: IReactionDisposer[];

  dispose = () => {
    this._disposers.forEach((dispose) => dispose());
  };

  @action
  getBasket = async () => {
    if (!this.model.identity) return;

    runInAction(() => {
      this.model.sync = true;
    });

    try {
      const resp = await this.api.checkout.getBasket(this.model.identity);

      runInAction(() => {
        this.model.sync = false;
        this.model.identity = resp.data.identity;
        // TODO: Should we cater for locally stored tracks that aren't part of the response?
        this.model.tracks = resp.data.tracks;
      });
    } catch (err) {
      if (err instanceof APIError) {
        if (err.isServerError || err.isNetworkError) {
          // Server error, assume API issues and do nothing
          return;
        }
        // TODO: If we get a 404 and have a local basket, we should probably migrate our local basket over to a new basket?
      }
      // TODO: Throw up a modal to explain the issue to the user (rather than sudden empty basket)
      // Catastrophic failure, the basket is invalid in some way (maybe API 4xx or client error, nuclear option: dump state and create a valid basket
      this.reset();
      await this.maybeCreateBasket();
    }
  };

  @action
  private maybeCreateBasket = async () => {
    if (this.model.identity) return;

    runInAction(() => {
      this.model.sync = true;
    });

    const resp = await this.api.checkout.createBasket();

    runInAction(() => {
      this.model.sync = false;
      this.model.identity = resp.data.identity;

      if (resp.data.tracks.length > 0) {
        this.model.tracks = resp.data.tracks;
      } else {
        this.model.tracks = [];
      }
    });
  };

  @action
  addToBasket = async (track: TrackSchema) => {
    const { tracks } = this.model;
    if (tracks.find((t) => t.identity === track.identity)) return;

    runInAction(() => {
      this.model.sync = true;
      this.model.syncItems.push(track);
    });

    await this.maybeCreateBasket();

    const resp = await this.api.checkout.addToBasket(this.model.identity, track.identity, 1);

    runInAction(() => {
      this.model.sync = false;
      this.model.syncItems = reject(this.model.syncItems, (t) => t.identity === track.identity);

      if (!this.model.identity) {
        this.model.identity = resp.data.identity;
      }

      this.model.tracks = resp.data.tracks;
    });

    if (!this.user.user) {
      notify({
        title: `"${track.title}" has been added to your cart`,
        message: 'Subscribe to save money on Premium tracks',
        image: getTrackImageURL(track, this.image.preloadImage),
      });
    } else if (this.user.user) {
      notify({
        title: `"${track.title}" has been added to your cart`,
        image: getTrackImageURL(track, this.image.preloadImage),
      });
    }
    siteEvents.emit(SITE_EVENTS.ADD_TO_BASKET, { id: track.identity });

    this.analytics.sendAddToBasket(track);
    this.analytics.sendMixpanel('Added to basket', {
      identity: track.identity,
      track: track.title,
      slug: track.slug,
      artists: track.artists.map((artist) => {
        return artist.name;
      }),
    });
  };

  @action
  removeFromBasket = async (track: TrackSchema) => {
    runInAction(() => {
      this.model.sync = true;
      this.model.syncItems.push(track);
    });

    await this.maybeCreateBasket();

    const resp = await this.api.checkout.removeFromBasket(this.model.identity, track.identity);

    notify({
      title: `"${track.title}" has been removed from your cart`,
      image: getTrackImageURL(track, this.image.preloadImage),
    });
    siteEvents.emit(SITE_EVENTS.REMOVE_FROM_BASKET, { id: track.identity });

    runInAction(() => {
      this.model.sync = false;
      this.model.syncItems = reject(this.model.syncItems, (t) => t.identity === track.identity);

      if (!this.model.identity) {
        this.model.identity = resp.data.identity;
      }

      this.model.tracks = resp.data.tracks;
    });

    this.analytics.sendRemoveFromBasket(track);
  };

  @action
  reset = () => {
    this.model.identity = null;
    this.model.tracks = [];
  };
}
