import React from 'react';
import { CreditPlanTypes } from '../../utilities/types';
import { PaymentPlanBlock } from '../registration/PaymentPlanBlock';
import { Padlock } from '../../atoms/icons/Padlock';
import { rebrand } from '../../../../theme/color';
import { StarCircle } from '../../atoms/icons/StarCircle';
import { classes } from 'typestyle';
import { styles } from '../../organisms/plans-v2/styles';

interface PlanPlaceholderProps {
  planName: CreditPlanTypes;
  premiumCredits: number;
}

/** Placeholder plan shown to users who haven't connected a channel yet */
export const PlanPlaceholder: React.FC<PlanPlaceholderProps> = ({ planName, premiumCredits }) => {
  return (
    <PaymentPlanBlock
      className={classes(styles.planWrapper, styles.planWrapperNoChannel)}
      aria-hidden="true"
      data-test-placeholder-plan-block={planName}
    >
      <h2>{planName}</h2>

      <Padlock color={rebrand.contrast[30].toString()} className={styles.padlock} size={20} />

      {premiumCredits && (
        <div className={styles.planHeadingInfoText}>
          <StarCircle color={rebrand.neutralOnDark[20].toString()} size={20} />
          {premiumCredits} Premium track credit{premiumCredits !== 1 ? 's' : ''}
        </div>
      )}
    </PaymentPlanBlock>
  );
};
