import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const ArrowUp: React.SFC<SVGIconProps> = ({ size = 16, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 16 16" {...props}>
    <path
      fill={color}
      d="m4 12 1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8z"
      transform="translate(-4 -4)"
      fillRule="evenodd"
    />
  </BaseSvg>
);
