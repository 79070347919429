import { action, runInAction } from 'mobx';
import { RouterState, RedirectFunction } from 'react-router';
import { ArtistsPageModel } from './model';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import { APIController } from '../../api/controller';
import { ImageController } from '../../image/controller';
import { BugsnagController } from '../../bugsnag/controller';
import { PrismicController } from '../../prismic/controller';
import { RouterModel } from '../../router/model';
import {stripQueryString} from '../../../lib/string';
import AppBreadcrumbs from '../../../components/app-breadcrumbs';
import formatArtistLetter from '../../../lib/formatArtistLetter';

export class ArtistsPageController {
  constructor(
    private model: ArtistsPageModel,
    private env: EnvModel,
    private api: APIController,
    private ui: UIController,
    private image: ImageController,
    private bugsnag: BugsnagController,
    private prismic: PrismicController,
    private router: RouterModel
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    const letter = stripQueryString(nextState.params['letter']) || null;

    if (letter && !/^\w$/i.test(letter)) {
      return this.router.replace('/browse/artists');
    }

    runInAction(() => {
      this.model.loadingContent = true;
      this.model.letter = letter;
    });

    const breadcrumbs = [
      {
        path: '/browse',
        label: 'Browse'
      },
      {
        path: '/browse/artists',
        label: 'Artists'
      }
    ];

    if (letter) {
      breadcrumbs.push({
        path: '/browse/artists/' + letter,
        label: formatArtistLetter(letter)
      })
    }

    breadcrumbs[breadcrumbs.length - 1].label = AppBreadcrumbs.LOADING

    this.ui.setBreadcrumbs(breadcrumbs);

    const document = 'artists';

    try {
      const [{ data }] = await Promise.all([
        this.prismic.getSingle(document),
        this.env.ready
      ]);

      await this.ui.setSEO('/browse/artists' + (letter ? '/letter' : ''), { letter: letter ? formatArtistLetter(letter) : null });

      runInAction(() => {
        this.model.pageTitle = data.page_title;
        this.model.coverImage = data.cover_image.url;
        this.model.loadingContent = false;
        this.model.loading = false;
      });

      this.ui.setLastBreadcrumbLabel(letter ? formatArtistLetter(letter) : 'Artists');

      return;
    } catch (e) {
      this.bugsnag.notifyException(e);
      return this.router.replace('/browse/artists');
    } finally {
      runInAction(() => {
        this.model.loadingContent = false;
        this.model.loading = false;
      });
    }
  };
}