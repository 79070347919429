import * as React from 'react';
import { em, important, percent, px } from 'csx';
import { keyframes, style } from 'typestyle';
import { colorBrand, colorContrast, colorGunmetal, colorSubtle, colorWhite } from '../theme/color';
import { transitionQuickEase } from '../theme/transition';
import { FormFieldProps } from '../types/form';

export interface FormInputRadioProps extends FormFieldProps {}

const selected = colorContrast.toString();
const focus = colorBrand.toString();
const disabled = colorSubtle.toString();
const fg = colorGunmetal.toString();
const bg = colorWhite.toString();

const borderscale = keyframes({
  '50%': {
    boxShadow: `0 0 0 2px ${selected}`
  }
});

export class FormInputRadio extends React.Component<FormInputRadioProps, any> {
  private input: HTMLInputElement;

  render() {
    const { value, name, placeholder, disabled } = this.props;

    return (
      <div className={FormInputRadio.styles.container}>
        <input
          ref={(el) => (this.input = el)}
          name={name}
          type="radio"
          checked={value}
          onChange={this.notifyParent}
          disabled={disabled}
        />
        <label onClick={this.handleClick}>{placeholder}</label>
      </div>
    );
  }

  handleClick = (event) => {
    if (!this.props.disabled) {
      this.input.checked = !this.input.checked;
      this.notifyParent();
    }
  };

  notifyParent = () => {
    const { checked, name } = this.input;
    this.props.onChange(name, checked);
  }

  static styles = {
    container: style({
      margin: '10px 0',
      $nest: {
        '& input': {
          border: 0,
          clip: 'rect(0 0 0 0)',
          height: px(1),
          margin: px(-1),
          overflow: 'hidden',
          padding: 0,
          position: 'absolute',
          width: px(1),
        },
        '& label': {
          display: 'block',
          position: 'relative',
          paddingLeft: px(30),
          lineHeight: em(1.1),
          verticalAlign: 'middle',
          userSelect: 'none',
          fontSize: px(12)
        },
        '& input:focus + label:before': {
          borderColor: focus,
          backgroundColor: bg,
        },
        '& input:active + label:before': {
          transitionDuration: 0
        },
        '& input + label:before': {
          boxSizing: 'content-box',
          content: '""',
          color: fg,
          position: 'absolute',
          top: percent(50),
          left: 0,
          width: px(14),
          height: px(14),
          marginTop: px(-10),
          border: `2px solid ${fg}`,
          borderRadius: percent(50),
          textAlign: 'center',
          transition: `all ${transitionQuickEase}`
        },
        '& input + label:after': {
          boxSizing: 'content-box',
          content: '""',
          position: 'absolute',
          transformOrigin: percent(50),
          transition: `transform ${transitionQuickEase}`,
          backgroundColor: 'transparent',
          top: percent(50),
          left: px(4),
          width: px(8),
          height: px(3),
          marginTop: px(-5),
          borderStyle: 'solid',
          borderColor: bg,
          borderWidth: '0 0 3px 3px',
          transform: 'rotate(-45deg) scale(0)'
        },
        '& input:disabled + label:before': {
          borderColor: important(disabled)
        },
        '& input:disabled:focus + label:before': {
          backgroundColor: 'inherit'
        },
        '& input:disabled:checked + label:before': {
          backgroundColor: disabled
        },
        '& input:checked:focus + label:before': {
          borderColor: focus,
        },
        '& input:checked + label:after': {
          content: '""',
          transform: 'rotate(-45deg) scale(1)',
          transition: `transform ${transitionQuickEase}`
        },
        '& input:checked + label:before': {
          animation: `${borderscale} ${transitionQuickEase}`,
          background: selected,
          borderColor: selected
        }
      }
    })
  }
};