import * as React from 'react';
import { Voucher } from './Voucher';
import { style } from 'typestyle';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { centerCenter, flexRoot } from 'csstips';

interface TrackCreditInactiveProps {
  children: React.ReactNode;
}

const styles = {
  wrapper: style({
    position: 'relative',
  }),
  button: style(flexRoot, centerCenter, {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }),
};

/** Greyed out track credit with a subscribe CTA */
export const TrackCreditInactive: React.FC<TrackCreditInactiveProps> = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <Voucher type="emptyCredit" title="Premium track" description="Subscription" value="Track credit" />

      <Button
        className={styles.button}
        prominence={ButtonProminence.PRIMARY}
        size={ButtonSize.SMALL}
        href="/account/plans"
      >
        {children}
      </Button>
    </div>
  );
};
