import { px } from 'csx';
import React from 'react';
import { classes, style } from 'typestyle';
import { Component, deps, inject, observer } from '../../lib/component';
import { rebrand } from '../../theme/color';
import { borderStyle } from './styles';
import { mediaMobileOnly } from '../../theme/media';
import { Button } from '../project-happy/atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../project-happy/atoms/button/buttonStyles';
import { ChannelCard } from '../project-happy/molecules/ChannelCard';
import { spacing } from '../../theme/spacing';

const styles = {
  loginCta: style(
    {
      padding: `${spacing.LARGE} ${spacing.DEFAULT}`,
      borderBottom: borderStyle,
      textAlign: 'center',
      position: 'relative',
      $nest: {
        '& p:first-child': {
          margin: `${px(16)} ${px(0)} ${px(4)}`,
          lineHeight: px(16),
        },
      },
    },
    mediaMobileOnly({
      $nest: {
        '& p:first-child': {
          marginTop: 0,
        },
      },
    })
  ),
  wrapper: style({
    padding: `${spacing.LARGE} ${spacing.DEFAULT}`,
    position: 'relative',
    backgroundColor: rebrand.neutralOnDark[20].toString(),
    zIndex: 3,
  }),
  channelCard: style({
    margin: '0 auto',
  }),
  signUpBtn: style({ marginTop: '12px' }),
};

interface CheckoutUserPanelProps {
  className?: string;
  handleSignIn: (event: any, ref?: any) => void;
}

@inject(deps)
@observer
export default class CheckoutUserPanel extends Component<CheckoutUserPanelProps> {
  render() {
    const { model, className, handleSignIn } = this.props;
    const { user } = model.auth;
    const { channel } = model.user;

    if (!user) {
      return (
        <div className={classes(styles.loginCta, className)}>
          <p>Create a free account to checkout</p>
          <Button
            className={styles.signUpBtn}
            prominence={ButtonProminence.PRIMARY}
            size={ButtonSize.SMALL}
            onClick={handleSignIn}
          >
            Sign up
          </Button>
        </div>
      );
    }

    return (
      <div className={classes(styles.wrapper, className)}>
        {channel && (
          <ChannelCard
            channel={channel}
            showChannelSwitcher
            showRatecardProgressBar={false}
            showCredits={false}
            className={styles.channelCard}
          />
        )}
      </div>
    );
  }
}
