import * as React from 'react';
import { classes } from 'typestyle';
import { Component, ComponentProps, deps, inject, observer } from '../../../../lib/component';
import { PaymentPlanBlock } from '../../molecules/registration/PaymentPlanBlock';
import { LOAD_STATE } from '../../../../types/api';

import { enterprisePlanFeatures } from '../plans-v2/Plans';
import { styles } from '../plans-v2/styles';

import { rebrand } from '../../../../theme/color';
import { SUPPORT_HUB } from '../../../../constants';
import { IconList } from '../../atoms/IconList';
import { OpenInNew } from '../../atoms/icons/OpenInNew';
import { Link } from '../../atoms/Link';
import { InfoFilled } from '../../atoms/icons/InfoFilled';
import { Loading } from '../../../loading/loading';
import { isEmpty } from '../../utilities/objects';
import { ChannelInfo } from '../../molecules/ChannelInfo';
import { PaymentPlansPlaceholder } from '../../molecules/plans-v2/PaymentPlansPlaceholder';
import { ButtonSize } from '../../atoms/button/buttonStyles';
import { Button } from '../../atoms/button/Button';
import { PlanV2 } from '../plans-v2/PlanV2';

@inject(deps)
@observer
export class PaymentPlansV2 extends Component<ComponentProps> {
  state = {
    submittingOnboardingState: false,
  };

  constructor(props) {
    super(props);
    this.userSkips = this.userSkips.bind(this);
    this.handlePricingCalculatorClick = this.handlePricingCalculatorClick.bind(this);
  }

  // Leaving this here for now, in case we add a 'Skip plans' button
  userSkips(): void {
    this.props.controller.analytics.sendMixpanel('User clicks "Skip" button on payment plans', {
      path: this.props.model.router.location.pathname,
    });
  }

  handlePricingCalculatorClick(): void {
    this.props.controller.analytics.sendMixpanel('User clicks pricing calculator link (plans)');
  }

  render() {
    const {
      model: {
        subscription: {
          creditSubscriptionPlans,
          creditSubscriptionPlansState,
          v2PlansLoadError,
          v2PlansSubscribeError,
          v2PlanIsUpdating,
        },
        user: { user, loading, channel, loadingChannels },
      },
    } = this.props;

    const isLoading =
      isEmpty(user) || loading || loadingChannels || creditSubscriptionPlansState === LOAD_STATE.LOADING;

    const sortedPlans = [...creditSubscriptionPlans].sort((a, b) => a.price - b.price);

    // Channels with more than 2m AVV will return an empty array of plans
    const isEnterpriseCustomer = creditSubscriptionPlansState === LOAD_STATE.READY && sortedPlans.length === 0;

    const introText = isEnterpriseCustomer ? (
      <>
        <p>
          <strong>Wow, your YouTube channel is doing great!</strong>
        </p>
        <p>
          We&apos;ll work with you directly on licenses as our standard plans can&apos;t cover channels as big as yours.
          Please get in touch with our team via the Enterprise option below.
        </p>
      </>
    ) : !isEmpty(channel) ? (
      <p>Pick the plan that best suits your music usage - you can upgrade or downgrade later if you need to.</p>
    ) : (
      <p>Our pricing relies on your YouTube channel to give you a fair price.</p>
    );

    return (
      <>
        <div>
          <div className={styles.infoBlock}>
            <h1>Your plan options</h1>

            {isLoading ? (
              <Loading className={styles.loading} />
            ) : (
              <>
                {channel && <ChannelInfo />}
                <div className={styles.introText}>{introText}</div>
              </>
            )}
          </div>

          {!isLoading && (
            <>
              {/* No channel connected */}
              {isEmpty(channel) && <PaymentPlansPlaceholder className={classes(styles.plans, styles.plansNoChannel)} />}

              {/* Channel connected */}
              {!isEmpty(channel) && creditSubscriptionPlansState === LOAD_STATE.READY && (
                <div className={styles.plans}>
                  {!isEnterpriseCustomer ? (
                    sortedPlans.map((plan) => (
                      <PlanV2
                        key={plan.plan_name}
                        plan={plan}
                        allPlans={sortedPlans}
                        planLoadingState={v2PlanIsUpdating}
                      />
                    ))
                  ) : (
                    <PaymentPlanBlock
                      className={classes(styles.planWrapper, 'enterprise-plan')}
                      data-test-plan-block="enterprise"
                    >
                      <h2>Enterprise</h2>

                      <div className={styles.planHeadingInfoText}>
                        For brands or larger creators with over 2M subscribers
                      </div>

                      <div className={styles.pricingContentsSubscription}>
                        <span className={styles.pricingContentEnterprise}>TALK TO US</span>
                      </div>

                      <IconList className={styles.list}>{enterprisePlanFeatures}</IconList>

                      <Button
                        className={classes(styles.planButton, styles.planButtonLink)}
                        size={ButtonSize.LARGE}
                        href={
                          'https://lickd.typeform.com/to/TRIr5olf#hubspot_utk=xxxxx&hubspot_page_name=xxxxx&hubspot_page_url=xxxxx'
                        }
                        loading={this.state.submittingOnboardingState}
                        data-test-professional-plan-block-contact-us-button
                      >
                        Get in touch
                      </Button>
                    </PaymentPlanBlock>
                  )}
                </div>
              )}

              {isEmpty(channel) && (
                <div className={styles.channelInfoBox}>
                  <InfoFilled color={rebrand.contrast[50].toString()} size={17} />
                  <p>
                    <strong>Why do we need this?</strong> Subscriptions and licenses are based on your YouTube
                    channel&apos;s average video views. This is so that we can give you a fair price based on your
                    channel size - so you don&apos;t pay superstar prices!
                  </p>
                </div>
              )}

              {!isEmpty(channel) && !isEnterpriseCustomer && (
                <p className={styles.info}>
                  The subscription price and additional Premium track license cost above are based on your YouTube
                  channel&apos;s average video views. This is so that we can give you a fair price for your
                  channel&apos;s size. Check out our{' '}
                  <Link
                    href="https://lickd.co/pricing-calculator"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={this.handlePricingCalculatorClick}
                  >
                    pricing calculator <OpenInNew size={12} />
                  </Link>{' '}
                  for more info.
                </p>
              )}

              {creditSubscriptionPlansState === LOAD_STATE.ERROR && (
                <p className={styles.subscribeError}>
                  {v2PlansLoadError} <a href={SUPPORT_HUB}>Submit a support ticket</a> if this issue persists.
                </p>
              )}
              {v2PlansSubscribeError && (
                <p className={styles.subscribeError}>
                  {v2PlansSubscribeError} <a href={SUPPORT_HUB}>Submit a support ticket</a> if this issue persists.
                </p>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}
