import * as React from 'react';
import { observer } from 'mobx-react';
import { ReleaseHeader } from './release-header';
import { ReleaseTracks } from './release-tracks';
import { ArtistSchema, ReleaseSchema, TrackSchema } from '../types/schema';
import { ImagePreloader } from '../types/fn';
import { AnalyticsController } from '../modules/analytics/controller';
import { TrackLine } from './project-happy/molecules/TrackLine';
import { style } from 'typestyle';
import { colorLightGrey } from '../theme/color';

export interface ReleaseModel {
  artist: ArtistSchema;
  release: ReleaseSchema;
  tracks: Array<TrackSchema>;
  loading: boolean;
  loadingPage: number;
}

export interface ReleaseProps {
  AddRemove: any;
  PlayButton: any;
  Favourite: any;
  Loading: any;
  infinite: boolean;
  model: ReleaseModel;
  isMobile: boolean;
  preload: ImagePreloader;
  preloadWaveform: ImagePreloader;
  onClickLink(event: React.MouseEvent<any>): void;
  analytics: AnalyticsController;
}

const styles = {
  trackLineWrapper: style({
    borderBottom: `1px solid ${colorLightGrey.toString()}`,
    maxWidth: '1000px',
  }),
};

export const Release = observer(
  ({
    model,
    infinite,
    PlayButton,
    Favourite,
    AddRemove,
    Loading,
    onClickLink,
    preload,
    preloadWaveform,
    isMobile,
    analytics,
  }: ReleaseProps) => (
    <div>
      <ReleaseHeader model={model} onClickLink={onClickLink} preload={preload} />

      {model.tracks.map((track, i) => (
        <div key={i} className={styles.trackLineWrapper}>
          <TrackLine track={track} onClickLink={onClickLink} />
        </div>
      ))}
    </div>
  )
);
