import * as React from 'react';
import { classes, style } from 'typestyle';
import { percent } from 'csx';
import { flexRoot } from 'csstips';
import { pace } from '../../utilities/functions';
import { CarouselEvents, EventCarouselProps, perPageStyles } from '../../utilities/carousel';
import { clamp } from '../../utilities/numbers';

const styles = {
  wrapper: style({
    width: percent(100),
    height: percent(100),
    overflowX: 'scroll',
    overflowY: 'visible',
    position: 'relative',
    scrollbarWidth: 'none',
    $nest: {
      '&::-webkit-scrollbar': {
        $unique: true,
        display: 'none',
      },
    },
  }),
  innerWrapper: style(flexRoot, {
    height: percent(100),
  }),
  tileLike: style({
    flexShrink: 0,
    flexGrow: 0,
  }),
  tile: style({
    position: 'relative',
  }),
};

export const CarouselDefaultProps = {
  perPage: 6,
};

export type ControlledCarouselProps = EventCarouselProps & {
  className?: string;
  tileClassName?: string;
  perPage?: number;
  centreTile?: number;
};
export class ControlledCarousel extends React.Component<ControlledCarouselProps> {
  outer: HTMLDivElement;

  inner: HTMLDivElement;

  nextHandler: () => void;

  previousHandler: () => void;

  scrollHandler: () => void;

  state = {
    page: -1,
  };

  constructor(props) {
    super(props);
    this.nextHandler = () => this.updateScrollPosition(this.state.page + 1);
    this.props.events.addListener(CarouselEvents.SCROLL_NEXT, this.nextHandler);
    this.previousHandler = () => this.updateScrollPosition(this.state.page - 1);
    this.props.events.addListener(CarouselEvents.SCROLL_PREVIOUS, this.previousHandler);
  }

  componentDidMount() {
    this.centreItem();
    this.scrollHandler = pace(this.updatePage.bind(this), 250);
    this.outer.addEventListener('scroll', this.scrollHandler, false);
    this.updatePage();
  }

  componentWillUnmount() {
    this.props.events.removeListener(CarouselEvents.SCROLL_NEXT, this.nextHandler);
    this.props.events.removeListener(CarouselEvents.SCROLL_PREVIOUS, this.previousHandler);
    window.removeEventListener('scroll', this.scrollHandler);
  }

  get childArray(): React.ReactNodeArray {
    const { children } = this.props;
    if (!children) return [];
    return Array.isArray(children) ? children : [children];
  }

  getPageDivider() {
    return this.childArray.length > 0
      ? this.inner.children[0].clientWidth
      : this.outer.clientWidth / this.props.perPage;
  }

  updatePage() {
    const minPage = 1;
    // We have a perPage prop, but CSS might stop that being fully enforced, get a true count of tiles on a page
    const truePerPage = Math.floor(this.outer.clientWidth / this.getPageDivider());
    // The max page is the tile that allows the final child to be fully visible
    const maxPage = this.childArray.length - truePerPage + 1;
    // If the tile is slightly off the screen, count page as the first fully visible tile
    const calculatedPage = Math.ceil(this.outer.scrollLeft / this.getPageDivider()) + 1;
    // Ensure the page is bounded
    const page = clamp(minPage, calculatedPage, maxPage);
    if (page !== this.state.page) {
      this.setState({ page });
      this.props.events.emit(CarouselEvents.PAGE_CHANGED, page);
    }
    const isStart = page === minPage;
    const isEnd = this.outer.scrollWidth - this.outer.clientWidth - this.outer.scrollLeft === 0;
    if (isStart) {
      this.props.events.emit(CarouselEvents.POSITION_START);
    }
    if (isEnd) {
      this.props.events.emit(CarouselEvents.POSITION_END);
    }
  }

  centreItem() {
    if (this.props.centreTile && this.props.centreTile >= 0 && this.outer && this.inner && this.inner.children.length > this.props.centreTile) {
      const tileToCentre = this.inner.children[this.props.centreTile];
      const carouselWidth = this.outer.clientWidth;
      const tileWidth = tileToCentre.clientWidth;
      const start = tileWidth * this.props.centreTile;
      const halfAnchor = carouselWidth / 2 - tileWidth / 2;
      this.outer.scrollTo({ left: start - halfAnchor, behavior: 'instant' });
      this.updatePage();
    }
  }

  updateScrollPosition(page: number) {
    if (this.outer) {
      this.outer.scrollTo({ left: this.getPageDivider() * (page - 1), behavior: 'smooth' });
    }
  }

  render() {
    const { className, tileClassName, perPage = CarouselDefaultProps.perPage } = this.props;
    const perPageClass = perPageStyles[perPage - 1];

    return (
      <div className={classes(styles.wrapper, className)} ref={(ref) => (this.outer = ref)}>
        <div className={styles.innerWrapper} ref={(ref) => (this.inner = ref)}>
          {this.childArray.map((child, index) => (
            <span key={index} className={classes(styles.tileLike, styles.tile, perPageClass, tileClassName)}>
              {child}
            </span>
          ))}
        </div>
      </div>
    );
  }
}
