import React from 'react';
import { style } from 'typestyle';
import { flexRoot } from 'csstips';
import { PrismicImage, PrismicRichText } from '../../utilities/types';
import { renderPrismicRichTextArray } from '../../utilities/props';
import isEmpty from 'lodash/isEmpty';
import { colorWhite } from '../../../../theme/color';
import { important, px } from 'csx';
import { fontBebasNeue } from '../../../../theme/font';
import { mediaUpToLargeMobile } from '../../utilities/mediaQueries';

const styles = {
  wrapper: style(
    flexRoot,
    {
      justifyContent: 'space-between',
      maxWidth: '992px',
      $nest: {
        h2: {
          fontSize: px(28),
          color: colorWhite.toHexString(),
          fontFamily: important(fontBebasNeue),
          margin: '8px 0px',
        },
        p: {
          margin: '8px 0px',
        },
      },
    },
    mediaUpToLargeMobile({
      flexDirection: 'column',
      alignItems: 'center',
      $nest: {
        h2: {
          fontSize: px(24),
        },
      },
    })
  ),

  card: style(
    mediaUpToLargeMobile({
      padding: '0',
      $nest: {
        '&:not(:first-child)': {
          padding: '16px 0',
        },
      },
    }),
    {
      padding: '0 16px',
    }
  ),

  img: style({
    height: '100%',
  }),
  imgWrapper: style({
    height: '100px',
  }),
};

export type HowItWorksRefereeProps = {
  items: {
    how_it_works_heading: PrismicRichText;
    how_it_works_subheading: PrismicRichText;
    how_it_works_image: PrismicImage;
  }[];
  heading: PrismicRichText;
};

const HowItWorksReferee: React.SFC<HowItWorksRefereeProps> = ({ heading, items }) => {
  return (
    <div>
      {heading && renderPrismicRichTextArray(heading, 'heading1')}
      {!isEmpty(items) && (
        <div className={styles.wrapper}>
          {items.map((data, i) => (
            <div key={i} className={styles.card}>
              <div className={styles.imgWrapper}>
                <img className={styles.img} src={data.how_it_works_image.url} alt={data.how_it_works_image.alt} />
              </div>
              {renderPrismicRichTextArray(data.how_it_works_heading)}
              <p>{data.how_it_works_subheading}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default HowItWorksReferee;
