import * as React from 'react';
import { classes, style } from 'typestyle';
import { TrackSchema } from '../../../types/schema';
import { Component, deps, inject } from '../../../lib/component';
import { observer } from 'mobx-react';
import { percent, px, url } from 'csx';
import { shadowElevation2 } from '../../../theme/shadow';
import { transitionQuickEase } from '../../../theme/transition';
import { mediaDesktop, mediaLargeTabletOnly } from '../utilities/mediaQueries';
import { colorDarkBlueGrey } from '../../../theme/color';
import LickdLogoGrey from '../../../static/lickd_logo_grey.svg';
import { Heart } from '../atoms/icons/Heart';
import { ButtonProminence } from '../atoms/button/buttonStyles';
import { CircleButton } from '../atoms/button/CircleButton';

const backgroundColourStr = colorDarkBlueGrey.toHexString();

const styles = {
  wrapper: style({
    position: 'relative',
    margin: '0 8px',
    paddingBottom: px(8),
  }),
  tile: style({
    background: 'center no-repeat',
    backgroundColor: '#b8c3cd',
    backgroundImage: url(LickdLogoGrey),
    borderRadius: px(4),
    transition: `box-shadow ${transitionQuickEase}`,
    paddingBottom: percent(100),
    $nest: {
      '&:hover': shadowElevation2,
    },
  }),
  favouriteButton: style({
    position: 'absolute',
    top: px(8),
    right: px(8),
  }),
  logo: style(
    {
      position: 'absolute',
      textAlign: 'center',
      width: percent(100),
      top: percent(25),
      $nest: {
        '& > svg': {
          width: px(55),
          height: px(55),
        },
      },
    },
    mediaLargeTabletOnly({
      $nest: {
        '& > svg': {
          width: px(100),
          height: px(100),
        },
      },
    }),
    mediaDesktop({
      $nest: {
        '& > svg': {
          width: px(40),
          height: px(40),
        },
      },
    })
  ),
};

export type TrackTileProps = { className?: string; track?: TrackSchema };

@inject(deps)
@observer
export class EmptyTile extends Component<TrackTileProps> {
  render() {
    const { className } = this.props;
    return (
      <div className={classes(styles.wrapper, className)}>
        <div className={styles.tile}>
          <CircleButton className={styles.favouriteButton} prominence={ButtonProminence.SECONDARY} disabled>
            <Heart size={11} color={backgroundColourStr} />
          </CircleButton>
        </div>
      </div>
    );
  }
}
