import * as React from 'react';
import { colorBackground, colorBrand, colorSubtle, colorWhite, rebrand } from '../theme/color';
import { CSSProperties, NestedCSSProperties } from 'typestyle/lib/types';
import { em, important, px } from 'csx';
import { classes, style } from 'typestyle';
import { center, centerJustified, inlineRoot } from 'csstips';
import { transitionQuickEase } from '../theme/transition';

export interface PillProps {
  className?: string;
  style?: any;
  styles?: Array<NestedCSSProperties>;
  onClick?(event: React.MouseEvent<any>): void;
  contrast?: boolean;
  children?: any;
}

export const LegacyButtonStyle: CSSProperties = {
  ...inlineRoot,
  ...center,
  ...centerJustified,
  border: 0,
  boxShadow: `0 ${px(4)} ${px(6)} ${px(1)} rgba(0,0,0,0.2)`,
  flexShrink: 0,
  display: 'inline-flex',
  borderRadius: px(4),
  lineHeight: px(24),
  padding: `${px(4)} ${px(16)}`,
  cursor: 'pointer',
  textDecoration: 'none',
  userSelect: 'none',
  fontSize: px(16),
  fontWeight: 600,
  appearance: important('none'),
  height: 'fit-content',
  '-webkit-appearance': important('none'),
  '-moz-appearance': important('none'),
};

export const LegacyButtonStyleDefault: NestedCSSProperties = {
  background: rebrand.highlight1.toString(),
  color: colorWhite.toString(),
  transition: `all ${transitionQuickEase}`,
  whiteSpace: 'pre',
  $nest: {
    '&:hover': {
      background: rebrand.highlight2.toString(),
    },
    '&:focus': {
      background: rebrand.highlight3.toString(),
    },
  },
};

export const LegacyButtonStyleDisabled: NestedCSSProperties = {
  background: rebrand.light1.toString(),
  boxShadow: `0 ${px(2)} ${px(4)} ${px(1)} rgba(0,0,0,0.3)`,
  color: rebrand.light2.toString(),
  cursor: 'default',
  userSelect: 'none',
  $nest: {
    '&:hover': {
      background: colorBackground.fade(0.7).toString(),
    },
  },
};

const styles = {
  container: style(),
  containerDefault: style(),
  containerDisabled: style(),
};

export const Pill: React.SFC<PillProps> = (props: PillProps) => {
  const cz = classes(
    styles.container,
    !props.className && styles.containerDefault,
    props.styles && style(...props.styles),
    props.className
  );

  return (
    <div className={cz} onClick={props.onClick} style={props.style}>
      {props.children}
    </div>
  );
};
