import { computed, IObservableArray, observable, ObservableMap } from 'mobx';
import { UIHeroGenre, UIHeroTheme, UIHeroTempo, UIBrowseSection } from '../../types/ui';
import { OnboardingSignUpModalProps } from '../../components/onboarding-sign-up-modal';
import { NamedPrismicSlice, PrismicSlice, PrismicURL } from '../../components/project-happy/utilities/types';
import { PrismicFaq } from '../../components/project-happy/molecules/prismic/PrismicFaqBlock';
import { PrismicMenuLinkProps } from '../../components/project-happy/prismic-slices/PrismicMenuLink';
import { PrismicMenuLinkGroupProps } from '../../components/project-happy/prismic-slices/PrismicMenuLinkGroup';
import { PrismicAnnouncementPayload } from '../../components/project-happy/prismic-slices/PrismicAnnouncement';
import { EnvModel } from '../env/model';
import { StorageModel } from '../storage/model';
import { USER_LAST_SEEN_ANNOUNCEMENT } from '../../constants';

type AnnouncementSlice = PrismicSlice<PrismicAnnouncementPayload['primary']>;

export class ContentModel {
  @observable announcements: AnnouncementSlice[] = [];

  @computed
  get unseenAnnouncements(): AnnouncementSlice[] {
    const lastSeenDateTimestamp = Date.parse(StorageModel.getItem(USER_LAST_SEEN_ANNOUNCEMENT));
    if (EnvModel.isServer || Number.isNaN(lastSeenDateTimestamp)) return this.announcements;
    return this.announcements.filter(({ primary: { date } }) => Date.parse(date) > lastSeenDateTimestamp);
  }

  @observable fetched = false;

  @observable seo: {
    titleFallback: string;
    titleSuffix: string;
    descriptionFallback: string;
    paths: {
      [path: string]: {
        title: string;
        description: string;
        noSuffix: boolean;
      };
    };
  };

  @observable genericOops: Array<any>;

  // TODO refactor these away when as comes from ES now
  @observable genres: Array<UIHeroGenre>;
  @observable themes: Array<UIHeroTheme>;
  @observable tempos: Array<UIHeroTempo>;

  @observable chartingTracks: Array<string>;

  @observable question3Active: boolean;

  @observable cookieNotificationTitle: string;
  @observable cookieNotificationContent: any;

  @observable signUpModalTitle: string;
  @observable signUpModalJaackMaateTitle: string;
  @observable signUpModalVidsummitTitle: string;
  @observable signUpModalBlogosphereTitle: string;
  @observable signUpModalVidconTitle: string;
  @observable signUpModalGoogle: string;
  @observable signUpModalBenefit1: string;
  @observable signUpModalBenefit2: string;
  @observable signUpModalBenefit3: string;
  @observable signUpModalBenefit4: string;
  @observable signUpModalBlurb1: string;
  @observable signUpModalBlurb2: string;
  @observable signUpModalBlurb3: string;
  @observable signUpModalBlurb4: string;

  @computed
  get signUpModalI18n(): OnboardingSignUpModalProps['i18n'] {
    return {
      title: this.signUpModalTitle,
      jaackMaateTitle: this.signUpModalJaackMaateTitle,
      vidsummitTitle: this.signUpModalVidsummitTitle,
      blogosphereTitle: this.signUpModalBlogosphereTitle,
      vidconTitle: this.signUpModalVidconTitle,
      google: this.signUpModalGoogle,
      benefits: [
        {
          heading: this.signUpModalBenefit1,
          content: this.signUpModalBlurb1,
        },
        {
          heading: this.signUpModalBenefit2,
          content: this.signUpModalBlurb2,
        },
        {
          heading: this.signUpModalBenefit3,
          content: this.signUpModalBlurb3,
        },
        {
          heading: this.signUpModalBenefit4,
          content: this.signUpModalBlurb4,
        },
      ],
      termsConditions: 'Terms & conditons',
      privacyPolicy: 'Privacy policy',
    };
  }

  @observable licensesDownloadAudio: string;
  @observable licensesDownloadEula: string;
  @observable licensesPreparingEula: string;
  @observable licensesAttribution: string;
  @observable licensesViewOrder: string;
  @observable licensesEmpty: string;

  @computed
  get accountLicensesI18n() {
    return {
      downloadAudio: this.licensesDownloadAudio,
      downloadEULA: this.licensesDownloadEula,
      preparingEULA: this.licensesPreparingEula,
      attribution: this.licensesAttribution,
      viewOrder: this.licensesViewOrder,
      empty: this.licensesEmpty,
    };
  }

  @observable eulas: ObservableMap<string, IObservableArray<any> | Error>; // Using any instead of unknown given old TS version

  @observable shuntCTA: any;
  @observable buttonCTANewUser: string;

  @observable toasts: {
    [key: string]: {
      key: string;
      title: string;
      message: string;
    };
  };

  @observable coupons: {
    [type: string]: {
      type: string;
      title: string;
      message: string;
    };
  };

  @computed
  get firstUseCoupon(): {
    type: string;
    title: string;
    message: string;
  } {
    return this.coupons && this.coupons['first_use'] ? this.coupons['first_use'] : null;
  }

  @observable footerBlurb: string;

  @observable strapBrowse: string;
  @observable strapArtist: string;
  @observable strapRelease: string;
  @observable strapTrack: string;
  @observable brandedTrackMessage: string;

  @observable benefits1Title: string;
  @observable benefits1Content: any;
  @observable benefits2Title: string;
  @observable benefits2Content: any;
  @observable benefits3Title: string;
  @observable benefits3Content: any;

  @computed get benefits() {
    return [
      {
        title: this.benefits1Title,
        content: this.benefits1Content,
      },
      {
        title: this.benefits2Title,
        content: this.benefits2Content,
      },
      {
        title: this.benefits3Title,
        content: this.benefits3Content,
      },
    ];
  }

  @observable brandedContentWarning: string;

  @observable downloadModalTitle: string;
  @observable downloadModalStrap: string;
  @observable downloadModalAgree: string;

  @observable addChannelStrap: string;

  @observable licenceModalTitle: string;
  @observable licenceModalLoading: string;
  @observable licenceModalStrap: string;
  @observable licenceModalNotAvailable: string;
  @observable licenceModalClose: string;

  @observable referralTitle: string;
  @observable referralContent: any;
  @observable referralCTA: string;

  @observable ageWarning: string;

  @observable deleteModalContent: any;

  @observable attributionBlurb: string;
  @observable attributionWhy: string;
  @observable attributionContent: string;

  @observable homepageCarousel: ApiContentCarouselConfiguration[];

  @observable browseBottomGridTitle: string;
  @observable browseBottomGridRightsholder: string;

  @observable browseSections: UIBrowseSection[] = [];

  @observable pricingBackground: string;

  @observable footerFaqs: PrismicFaq[];
  @observable footerLinks: PrismicMenuLinkGroup[];
  @observable socialLinks: PrismicSocialLink[];

  @observable burgerMenuLinks: BurgerMenuLink[];
}

export enum PrismicMenuLinkSliceTypes {
  LINK_GROUP = 'menu_link_group',
  MENU_LINK = 'menu_link',
}

export type PrismicMenuLink = NamedPrismicSlice<PrismicMenuLinkSliceTypes.MENU_LINK, PrismicMenuLinkProps>;
export type PrismicMenuLinkGroup = NamedPrismicSlice<PrismicMenuLinkSliceTypes.LINK_GROUP, PrismicMenuLinkGroupProps>;

export enum PrismicSocialLinkTypes {
  YOUTUBE = 'Youtube',
  SPOTIFY = 'Spotify',
  FACEBOOK = 'Facebook',
  TWITTER = 'Twitter',
  INSTAGRAM = 'Instagram',
  LINKEDIN = 'LinkedIn',
}
export type PrismicSocialLink = {
  link_type: PrismicSocialLinkTypes;
  link_url: PrismicURL;
};

type BurgerMenuLink = PrismicMenuLink | PrismicMenuLinkGroup;

export type ApiContentCarouselConfiguration = {
  carousel_title: string;
  content_type: 'Tracks' | 'Playlists';
  content_slug: string;
};

export type ApiContentDataItem = {
  name: string;
  slug: string;
  images: {
    identity: string;
  };
};
