import * as React from 'react';
import { Component, inject, deps, observer } from '../../lib/component';
import { centerCenter } from 'csstips';
import { important, percent, px } from 'csx';
import { classes, style } from 'typestyle';
import {ButtonPill} from '../button-pill';
import {mediaTablet, mediaMobileOnly} from '../../theme/media';

export interface CTASignUpNewUserButtonProps {
  hideDesktop?: boolean;
  hideMobile?: boolean;
}

@inject(deps) @observer
export class CTASignUpNewUserButton extends Component<CTASignUpNewUserButtonProps> {
  render() {
    const { hideDesktop = false, hideMobile = false, children } = this.props;
    const model = this.props.model.onboarding;

    if (!children || !model.ctaSignUpNewUserButtonShow) return null;

    const className = classes(
      CTASignUpNewUserButton.styles.container,
      hideMobile && CTASignUpNewUserButton.styles.containerHideMobile,
      hideDesktop && CTASignUpNewUserButton.styles.containerHideDesktop
    );

    return (
      <div className={className}>
        <ButtonPill className={CTASignUpNewUserButton.styles.button} onClick={this.handleSignUp}>
          {children || model.ctaSignUpNewUserButtonContent}
        </ButtonPill>
      </div>
    )
  }

  handleSignUp = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    this.props.controller.onboarding.goToSignUpPage();
  };

  static i18n = {};

  static styles = {
    container: style(
      { ...centerCenter, overflow: 'hidden' },
      mediaMobileOnly({ padding: px(16) }),
      mediaTablet({ padding: px(48)})
    ),
    containerHideMobile: style(
      mediaMobileOnly({ display: important('none') })
    ),
    containerHideDesktop: style(
      mediaTablet({ display: important('none') })
    ),
    button: style(
      {
        fontSize: important('0.875rem'),
        whiteSpace: important('normal'),
        textAlign: important('center'),
        maxWidth: percent(100)
      },
      mediaMobileOnly({
        width: percent(100)
      })
    )
  };
}