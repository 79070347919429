import { CookiePolicyPageModel } from './model';
import { action } from 'mobx';
import { RedirectFunction, RouterState } from 'react-router';
import { PrismicController } from '../../prismic/controller';
import { BugsnagController } from '../../bugsnag/controller';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';

export class CookiePolicyPageController {

  constructor(
    private model: CookiePolicyPageModel,
    private prismic: PrismicController,
    private bugsnag: BugsnagController,
    private env: EnvModel,
    private ui: UIController
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    this.model.loading = true;
    const document = 'cookie_policy';

    this.ui.setBreadcrumbs([{
      path: '/cookie-policy',
      label: 'Cookie policy'
    }]);

    await this.ui.setSEO(nextState.location.pathname);

    try {
      const [{ data }] = await Promise.all([
        this.prismic.getSingle(document),
        this.env.ready
      ]);

      // RichText
      this.model.page_title = data.page_title;
      this.model.page_body = data.page_body;

      // Image
      this.model.cover_image = data.cover_image.url;

    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      this.model.loading = false;
    }

    return;
  };
}