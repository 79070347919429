import React from 'react';
import { classes, style } from 'typestyle';
import { rebrand } from '../../theme/color';
import { styles } from './styles';
import { TrackSchema } from '../../types/schema';
import { ImageModel } from '../../modules/image/model';
import { InlineArtistLinkList } from '../artist-link-list';
import { AnalyticsController } from '../../modules/analytics/controller';
import { formatCurrency } from '../../lib/currency';
import CheckoutPlayButton from './checkout-play-button';
import { px } from 'csx';
import { isEmpty } from '../project-happy/utilities/objects';
import { UserModel } from '../../modules/user/model';
import { Lightbulb } from '../project-happy/atoms/icons/Lightbulb';
import { ErrorIcon } from '../project-happy/atoms/icons/ErrorIcon';
import { Component, deps, inject, observer } from '../../lib/component';
import { StarCircle } from '../project-happy/atoms/icons/StarCircle';
import { CheckmarkCircle } from '../project-happy/atoms/icons/CheckmarkCircle';
import { CircleButton } from '../project-happy/atoms/button/CircleButton';
import { ButtonProminence } from '../project-happy/atoms/button/buttonStyles';
import NotifyButton from '../project-happy/atoms/button/NotifyButton';
import { InfoOutline } from '../project-happy/atoms/icons/InfoOutline';
import { Button } from '../project-happy/atoms/button/Button';
import { SrOnlyText } from '../project-happy/atoms/SrOnlyText';
import { CloseCross } from '../project-happy/atoms/icons/CloseCross';
import { ExplicitTag } from '../project-happy/atoms/ExplicitTag';

interface CheckoutTrackCardProps {
  trackModel: { track: TrackSchema; price: string | number; currency: string };
  coverUrlProvider: ImageModel['cover'];
  onClickLink(event: React.MouseEvent<any>): void;
  onRemoveTrack(identity: string): void;
  handleEulaRequest: (track: TrackSchema) => void;
  handleTYNTKRequest: (track: TrackSchema) => void;
  analytics: AnalyticsController;
  user: UserModel;
}

@inject(deps)
@observer
export default class CheckoutTrackCard extends Component<CheckoutTrackCardProps> {
  state = {
    agreementClicked: false,
    tynkClicked: false,
    trackUnavailable: false,
  };

  constructor(props) {
    super(props);
    this.handleEulaRequest = this.handleEulaRequest.bind(this);
    this.handleTYNTKRequest = this.handleTYNTKRequest.bind(this);
  }

  handleEulaRequest() {
    const { track } = this.props.trackModel;
    this.props.controller.analytics.sendMixpanel('User clicks License agreement', {
      identity: track.identity,
      title: track.title,
      slug: track.slug,
    });
    this.props.handleEulaRequest(track);
    this.setState({
      agreementClicked: true,
    });
  }

  handleTYNTKRequest() {
    const { track } = this.props.trackModel;
    this.props.controller.analytics.sendMixpanel('User clicks "Things you need to know" lightbulb', {
      identity: track.identity,
      title: track.title,
      slug: track.slug,
    });
    this.props.handleTYNTKRequest(track);
    this.setState({
      tynkClicked: true,
    });
  }

  render() {
    const {
      coverUrlProvider,
      trackModel,
      onClickLink,
      onRemoveTrack,
      handleTYNTKRequest,
      analytics,
      user,
      model,
    } = this.props;
    const { agreementClicked, trackUnavailable, tynkClicked } = this.state;

    const { track, price, currency } = trackModel;
    const { identity, images, artists } = track;
    const {
      checkout: { claimsFreeRead, claimsManagedRead },
    } = model;

    const isUmg = track.reporting_party.slug === 'umg';
    const isStock = track.is_stock_music;

    const isSubscribed = !isEmpty(user.user) && user.isSubscribed;

    const hasReadThingsYouShouldKnow = isUmg ? claimsManagedRead : claimsFreeRead;

    const iconProps = { width: 16, height: 16, className: styles.checkoutTrackLabel };

    return (
      <div className={styles.checkoutCard} data-test-track-licence-card={identity}>
        <div className={classes(styles.checkoutTrackline, isStock ? 'paid' : 'payg')}>
          {isStock ? (
            <CheckmarkCircle
              color={rebrand.dark1.toString()}
              checkColor={rebrand.highlight1.toString()}
              {...iconProps}
            />
          ) : (
            <StarCircle color="white" {...iconProps} />
          )}

          <div className={classes(styles.checkoutCardArtwork, trackUnavailable ? styles.trackUnavailableimage : '')}>
            <img src={coverUrlProvider(images.identity)} width={80} height={80} />
          </div>

          {!trackUnavailable && <CheckoutPlayButton track={track} className={styles.checkoutCardPlay} />}

          <div className={styles.checkoutCardArtist}>
            <span
              className={classes(styles.trackType, trackUnavailable && styles.unavailableTrackColor)}
              data-test-track-catalogue-type={isStock ? 'paid' : 'payg'}
            >
              {isStock ? 'Included' : 'Premium'}
            </span>
            <p className={styles.trackTitle}>
              {track.is_explicit ? <ExplicitTag className={styles.trackTitleExplicitTag} /> : null}
              <span className={styles.trackTitleText}>
                <a
                  href={`/music/artists/${track.artists[0].slug}/track/${track.slug}`}
                  onClick={onClickLink}
                  data-test-track-title
                  className={trackUnavailable ? styles.unavailableTrackColor : ''}
                >
                  {track.title}
                </a>
              </span>
            </p>

            <p data-test-artist-list>
              {InlineArtistLinkList({
                model: { artists },
                onClickLink,
                linkClassName: `artist ${trackUnavailable ? styles.unavailableTrackColor : ''}`,
              })}
            </p>
          </div>
        </div>

        <div className={styles.checkoutCardPrice}>
          <div className={styles.checkoutCardRemove}>
            <CircleButton
              prominence={ButtonProminence.SECONDARY}
              onClick={() => onRemoveTrack(track.identity)}
              data-test-track-licence-remove-button
            >
              <CloseCross />
              <SrOnlyText>Remove track</SrOnlyText>
            </CircleButton>
            <span>Remove</span>
          </div>

          {!trackUnavailable && (
            <div className={styles.checkoutCardArtistTotal}>
              <span className={styles.smallText}>{track.basket.quantity} x Standard License</span>
              {!isStock && <span className={styles.totalText}>{formatCurrency(price, currency)}</span>}
              {isStock && (
                <span className={classes(styles.totalText, style({ fontSize: px(12) }))}>
                  Included with subscription
                </span>
              )}
            </div>
          )}
        </div>

        <div className={styles.checkoutCardBottomRow}>
          {trackUnavailable ? (
            <>
              <div className={styles.trackUnavailableTextWrapper}>
                <InfoOutline />
                <span className={styles.trackUnavailableText}>This track is not available in your region</span>
              </div>
              {/* This is intensionally commented, comment will be removed when notify api is ready */}
              {/* <NotifyButton /> */}
            </>
          ) : (
            <>
              <Button
                className={classes(styles.checkoutCardButtonWithBadge, styles.checkoutCardLicenseBtn)}
                prominence={ButtonProminence.SECONDARY}
                onClick={this.handleEulaRequest}
                data-test-track-licence-agreement-button
              >
                License Agreement
                {!agreementClicked && (
                  <ErrorIcon data-test-track-license-agreement-badge className={'badge'} color="#9F002D" size={16} />
                )}
              </Button>

              <div className={styles.checkoutCardThingsToKnowWrapper}>
                <span className={styles.checkoutCardThingsToKnowText}>Things you need to know</span>
                <CircleButton
                  className={classes(styles.checkoutCardButtonWithBadge, styles.checkoutCardThingsToKnowBtn)}
                  prominence={ButtonProminence.SECONDARY}
                  onClick={this.handleTYNTKRequest}
                  data-test-track-licence-card-tyntk-button
                >
                  <Lightbulb />
                  <SrOnlyText>Things you need to know</SrOnlyText>
                  {!tynkClicked && (
                    <ErrorIcon data-test-track-licence-card-tyntk-badge className={'badge'} color="#9F002D" size={16} />
                  )}
                </CircleButton>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
