import { observable } from 'mobx';

export class FeaturesPageModel {
  @observable
  loading: boolean = false;

  @observable
  page_title: any = null;

  @observable
  cover_image: any = null;

  @observable
  video: any = null;

  @observable
  strap_title: string = null;

  @observable
  strap_content: any = null;

  @observable
  features_1: any = null;

  @observable
  features_2: any = null;

  @observable
  features_3: any = null;

  @observable
  features_4: any = null;

  @observable
  subtitle: string = null;

  @observable
  cta: string = null;

  @observable
  icon_rosette: string = null;

  @observable
  icon_disc: string = null;

  @observable
  icon_bulb: string = null;

  @observable
  icon_lightning: string = null;

  @observable
  icon_friends: string = null;
}