import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalChildStyle } from './modal';
import { em, px } from 'csx';
import { colorBrand, colorGunmetal, colorWhite } from '../theme/color';
import { TextNoOrphan } from './text-no-orphan';
import { style } from 'typestyle';
import { MailchimpForm } from './mailchimp-form';
import { FormField } from '../types/form';

export interface OnboardingBetaModalModel {
  show: boolean;
  action: string;
  hidden: string;
  email: FormField<any>;
}

export interface OnboardingBetaModalProps {
  model: OnboardingBetaModalModel;
  onRequestClose(): void;
  onChangeEmail(email: string): void;
  i18n?: {
    title: string;
    content: string;
    submit: string;
    close: string;
  };
}

const DEFAULT_I18N = {
  title: 'We are in closed beta!',
  content:
    "Please enter your email and we'll let you know when we launch. If you are a beta user, please use the sign in link in your email.",
  submit: 'Remind me',
  close: 'Close',
};

export const OnboardingBetaModal = observer(
  ({ model, onRequestClose, i18n = DEFAULT_I18N, onChangeEmail }: OnboardingBetaModalProps) => (
    <Modal show={model.show} onRequestClose={onRequestClose}>
      <div className={styles.container}>
        <h3>
          <TextNoOrphan value={i18n.title} />
        </h3>
        <p>{i18n.content}</p>
        <MailchimpForm model={model} onCancel={onRequestClose} onChangeEmail={onChangeEmail} i18n={i18n} />
      </div>
    </Modal>
  )
);

const styles = {
  container: style({
    ...ModalChildStyle,
    width: px(420),
    maxWidth: 'calc(100vw - 4rem)',
    background: colorWhite.toString(),
    $nest: {
      '&> h3': {
        color: colorBrand.toString(),
        fontSize: px(30),
        margin: '0 0 30px 0',
      },
      '& p': {
        color: colorGunmetal.toString(),
        margin: '5px 0 20px 0',
        lineHeight: em(1.4),
      },
    },
  }),
};
