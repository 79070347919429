import * as React from 'react';
import {classes, style} from 'typestyle';
import {important, percent, px} from 'csx';
import {betweenJustified, center, horizontal} from 'csstips';
import {colorBackground, colorBrand} from '../theme/color';
import {transitionQuickEase} from '../theme/transition';

export interface PinchSliderValues {
  min: number;
  max: number;
  lower: number;
  upper: number;
  step: number;
}

export interface PinchSliderState extends PinchSliderValues {
  trackingMouse: boolean;
}

export interface PinchSliderProps extends Partial<PinchSliderValues> {
  className?: string;
  onChange?(lower: number, upper: number): void;
  formatValue?(value: number): string;
}

export class PinchSlider extends React.Component<PinchSliderProps, PinchSliderState> {
  $track: HTMLDivElement;
  $left: HTMLDivElement;
  $right: HTMLDivElement;

  state = {
    min: 0,
    max: 100,
    lower: 0,
    upper: 100,
    step: 5,
    trackingMouse: false,
  };

  constructor(props) {
    super(props);
    this.notifyParent = PinchSlider.debounce(this.notifyParent, 50);
  }

  render() {
    const {
      formatValue = PinchSlider.formatValue,
    } = this.props;

    const {
      min,
      max,
      lower,
      upper
    } = this.state;

    const range = (max - min);
    const left = (lower - min) / range;
    const right = (upper - min) / range;

    const style = {
      left: `${left * 100}%`,
      right: `${(1 - right) * 100}%`
    };

    const isUnset = lower === min && upper === max;

    return (
      <div className={PinchSlider.styles.container}>
        <div className={classes(PinchSlider.styles.label, PinchSlider.styles.labelLeft)}>
          {formatValue(lower)}
        </div>
        <div className={PinchSlider.styles.track}
             ref={el => this.$track = el}
             onTouchStart={this.touch}
             onTouchMove={this.touch}
             onTouchEnd={this.touchEnd}
             onMouseDown={this.mouseDown}
        >
          <div className={classes(PinchSlider.styles.slider, isUnset && PinchSlider.styles.sliderUnset)} style={style}>
            <div className={classes(PinchSlider.styles.handle, PinchSlider.styles.handleLeft)}
                 ref={el => this.$left = el}
            />
            <div className={classes(PinchSlider.styles.handle, PinchSlider.styles.handleRight)}
                 ref={el => this.$right = el}
            />
          </div>
        </div>
        <div className={classes(PinchSlider.styles.label, PinchSlider.styles.labelRight)}>
          {formatValue(upper)}
        </div>
      </div>
    )
  }

  UNSAFE_componentWillMount() {
    this.syncState(this.props);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.mouseUp);
    window.removeEventListener('mousemove', this.mouseMove);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.syncState(nextProps);
  }

  
  touch = ({ targetTouches }: React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault();

    switch (targetTouches.length) {
      case 1: return this.updateSingle(targetTouches[0].clientX);
      case 2: return this.updateDouble(targetTouches[0].clientX, targetTouches[1].clientX);
      default: return;
    }
  };

  touchEnd = () => this.notifyParent();

  mouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    this.updateSingle(event.clientX);
    this.setState({ trackingMouse: true });

    window.addEventListener('mouseup', this.mouseUp);
    window.addEventListener('mousemove', this.mouseMove);

    event.preventDefault();
  };

  mouseMove = (event: MouseEvent) => {
    this.updateSingle(event.clientX);
  };

  mouseUp = () => {
    this.setState({ trackingMouse: false });

    window.removeEventListener('mouseup', this.mouseUp);
    window.removeEventListener('mousemove', this.mouseMove);

    this.notifyParent();
  };

  private updateSingle = (x: number) => {
    if (!this.$track) return;

    const { max, min, lower, upper, step } = this.state;
    const { width, left } = this.$track.getBoundingClientRect();

    const progress = (x - left) / width;
    const value = Math.round(progress * (max - min)) + min;

    const diffLower = Math.abs(value - lower);
    const diffUpper = Math.abs(value - upper);

    const nextLower = diffLower <= diffUpper ? Math.min(value, upper) : lower;
    const nextUpper = diffLower > diffUpper ? Math.max(value, lower) : upper;

    this.setState({
      lower: Math.max(Math.round(nextLower / step) * step, min),
      upper: Math.min(Math.round(nextUpper / step) * step, max),
    });
  };

  private updateDouble = (x1: number, x2: number) => {
    if (!this.$track) return;

    const { max, min, step } = this.state;
    const { width, left } = this.$track.getBoundingClientRect();

    const progress1 = (x1 - left) / width;
    const progress2 = (x2 - left) / width;

    const value1 = Math.round(progress1 * (max - min)) + min;
    const value2 = Math.round(progress2 * (max - min)) + min;

    const nextLower = Math.min(value1, value2);
    const nextUpper = Math.max(value1, value2);

    this.setState({
      lower: Math.max(Math.round(nextLower / step) * step, min),
      upper: Math.min(Math.round(nextUpper / step) * step, max),
    });
  };

  private syncState = (props: PinchSliderProps) => this.setState(
    ['min', 'max', 'lower', 'upper', 'step']
      .reduce((state, key) => ({
        ...state,
        [key]: typeof props[key] !== 'undefined'
          ? props[key]
          : state[key]
      }), {}));

  private readonly notifyParent = () => {
    if (this.props.onChange) {
      this.props.onChange(
        this.state.lower,
        this.state.upper,
      )
    }
  };

  static formatValue = (value: number) => value.toString();
  
  static debounce = (func, wait, immediate?) => {
    let timeout;
    return function() {
      let context = this, args = arguments;
      let later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  static styles = {
    container: style({
      ...horizontal,
      ...center,
      ...betweenJustified,
    }),
    track: style({
      cursor: 'pointer',
      flex: 1,
      margin: '0 16px',
      width: percent(100),
      borderRadius: px(4),
      background: colorBackground.darken(0.1).toString(),
      height: px(8),
      position: 'relative',
    }),
    slider: style({
      position: 'absolute',
      top: 0,
      bottom: 0,
      borderRadius: px(4),
      transition: `background ${transitionQuickEase}`,
      background: colorBrand.darken(0.1).toString(),
    }),
    sliderUnset: style({
      background: important(colorBackground.darken(0.1).toString()),
      $nest: {
        '& :after': {
          background: important(colorBackground.toString()),
        }
      }
    }),
    handle: style({
      borderRadius: px(24),
      position: 'absolute',
      top: px(-20),
      height: px(48),
      width: px(48),
      $nest: {
        '&:after': {
          transition: `background ${transitionQuickEase}`,
          display: 'block',
          content: '""',
          background: colorBrand.toString(),
          position: 'absolute',
          borderRadius: px(4),
          width: px(8),
          height: px(24),
          left: px(20),
          top: px(12)
        }
      }
    }),
    handleLeft: style({
      left: px(-24),
    }),
    handleRight: style({
      right: px(-24),
    }),
    label: style({
      fontSize: px(12),
      minWidth: px(50)
    }),
    labelLeft: style({
      textAlign: 'left',
    }),
    labelRight: style({
      textAlign: 'right',
    }),
  }
}