import { px } from 'csx';
import React from 'react';
import { style } from 'typestyle';
import { SrOnlyText } from './SrOnlyText';
import { ExplicitIcon } from './icons/ExplicitIcon';
import { Tooltip } from './Tooltip';
import { rebrand } from '../../../theme/color';
import { borderRadius } from '../../../theme/border';

interface ExplicitTagProps {
  className?: string;
}

const toolTipStyles = style({
  backgroundColor: rebrand.neutralOnDark[20].toHexString(),
  borderColor: rebrand.neutralOnDark[20].toHexString(),
  color: rebrand.contrast[50].toHexString(),
  borderRadius: borderRadius.SMALL,
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
});

const wrapperStyles = style({
  display: 'inline-block',
  flexShrink: 0,
  height: px(12),
  width: px(12),
  lineHeight: 1,
  marginRight: px(4),
});

export const ExplicitTag: React.FC<ExplicitTagProps> = ({ className }) => {
  return (
    <Tooltip
      positions={['top']}
      align={'center'}
      className={toolTipStyles}
      wrapperClassName={wrapperStyles}
      content={<div>Contains explicit lyrics</div>}
    >
      <span className={className ? className : undefined}>
        <ExplicitIcon />
        <SrOnlyText>Contains explicit lyrics</SrOnlyText>
      </span>
    </Tooltip>
  );
};
