/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createRef } from 'react';
import { Component, deps, inject, observer } from '../../../../lib/component';
import { classes, cssRaw, style } from 'typestyle';
import { GoButton } from '../../atoms/button/GoButton';
import { SVGIcon } from '../../../svg-icon';
import { colorBlack, colorGreyAccent, colorNeutralLight, colorWhite, rebrand } from '../../../../theme/color';
import { center, flexRoot } from 'csstips';
import { important, percent, px } from 'csx/lib/internal';
import {
  constantHeaderWithSearchHeight,
  fontstackDefault,
  constantHeaderWithSearchHeightScrollOrMobile,
} from '../../config';
import { shadowSearch } from '../../../../theme/shadow';
import { runInAction } from 'mobx';
import { CloseCross } from '../../atoms/icons/CloseCross';
import SearchSuggestion from './SearchSuggestion';
import { transitionQuickEase } from '../../../../theme/transition';
import { searchFilterAndSuggestionlInactive } from '../../../../theme/z';
import { EntityType } from '../../atoms/SearchSuggestionItem';
import { FilterBar } from '../../atoms/icons/FilterBar';
import {
  mediaDesktop,
  mediaUpToDesktop,
  isMobileUpToDesktop,
  isMediumDesktop,
  mediaUpToLargeMobile,
} from '../../utilities/mediaQueries';
import { Badge } from '../../../badge';
import isEmpty from 'lodash/isEmpty';
import { animationScaleUpDown } from '../../../../theme/animation';
import { Checkmark } from '../../atoms/icons/Checkmark';
import debounce from 'lodash/debounce';

const styles = {
  container: style({
    position: 'relative',
    zIndex: searchFilterAndSuggestionlInactive,
    $nest: {
      '&::before': {
        content: '""',
        opacity: 0,
        transition: `opacity ${transitionQuickEase}, transform 0.125s step-end`,
        background: rebrand.contrast[90].toString(),
        zIndex: -1,
        transform: 'translateX(100vw)',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      '&.active::before': {
        transition: `opacity ${transitionQuickEase}`,
        opacity: 0.3,
        transform: 'none',
      },
    },
  }),
  mainHeader: style(
    mediaUpToDesktop({
      padding: '16px 16px',
      height: constantHeaderWithSearchHeightScrollOrMobile,
      position: 'fixed',
    }),
    {
      left: 0,
      position: 'absolute',
      width: percent(100),
      background: 'rgb(38,46,63)',
      padding: '16px 0',
      height: constantHeaderWithSearchHeight,
    }
  ),
  mainHeaderOnScroll: style(
    mediaDesktop({
      height: constantHeaderWithSearchHeightScrollOrMobile,
      position: 'fixed',
    })
  ),
  headerAlignment: style(
    mediaUpToDesktop({
      justifyContent: 'flex-start',
      width: percent(100),
    }),
    {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      height: '100%',
      width: px(992),
      margin: '0 auto',
    }
  ),
  wrapper: style(
    {
      flex: 1,
      marginLeft: px(17),
      height: px(48),
    },
    mediaDesktop({
      height: px(56),
    })
  ),
  noSearchOnFilterActiveMobileOnly: style(
    mediaUpToDesktop({
      display: 'none',
    })
  ),
  searchForm: style(flexRoot, center, {
    backgroundColor: colorWhite.toHexString(),
    overflow: 'hidden',
    width: percent(100),
    borderRadius: px(4),
    padding: '8px 8px 8px 16px',
    height: percent(100),
  }),
  removeBorderBottom: style({
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  }),
  filterHeader: style({
    padding: '8px 11px',
    backgroundColor: colorGreyAccent.toString(),
    width: 'auto',
    height: px(48),
    alignItems: 'center',
    gap: px(8),
    flexShrink: 0,
    display: 'flex',
    borderRadius: px(4),
    cursor: 'pointer',
    position: 'relative',
    $nest: {
      '&:hover': {
        backgroundColor: rebrand.neutralOnDark[30].toString(),
      },
    },
    ...mediaDesktop({
      padding: '12px 15px',
      height: px(56),
      $nest: {
        '&:hover': {
          backgroundColor: rebrand.neutralOnDark[30].toString(),
        },
        '&.filterOpenBtn': {
          backgroundColor: rebrand.dark5.toString(),
          $nest: {
            '&>svg path': {
              fill: rebrand.contrast[40].toString(),
            },
            '&:hover': {
              $nest: {
                '&>svg path': {
                  fill: rebrand.contrast[30].toString(),
                  backgroundColor: rebrand.dark5.toString(),
                },
              },
            },
          },
        },
      },
    }),
  }),
  textOnlyOnMobile: style(
    mediaDesktop({
      display: 'none',
    })
  ),
  iconBounce: style(animationScaleUpDown),
  input: style({
    flexGrow: 1,
    border: 0,
    padding: px(8),
    paddingRight: 0,
    fontSize: px(16),
    fontFamily: fontstackDefault,
    $nest: {
      '&::placeholder': {
        color: colorNeutralLight.toString(),
      },
      '&:focus::placeholder': {
        color: 'transparent',
      },
    },
  }),
  inputIconWrapper: style({
    minWidth: px(24),
  }),
  close: style({
    $nest: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
  filterBadge: style({
    position: 'absolute',
    color: rebrand.contrast[90].toString(),
    height: important('24px'),
    width: important('24px'),
    fontSize: important('16px'),
    fontWeight: 700,
    top: px(-11),
    left: px(26),
  }),
  smallMobileOnly: style(
    mediaUpToLargeMobile({
      display: 'none',
    })
  ),
};

cssRaw(`
@supports selector(:focus-within) {
  .${styles.input} {
    outline: none;
  }

  .${styles.searchForm}:focus-within {
   box-shadow: ${shadowSearch};
  }

}`);
export type SearchFieldProps = {
  placeholder?: string;
  scrolling: boolean;
};
@inject(deps)
@observer
class SearchField extends Component<SearchFieldProps> {
  private searchRef = createRef<HTMLDivElement>();
  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.performAutoSuggestion = debounce(this.performAutoSuggestion.bind(this), 500);
  }
  componentDidMount(): void {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount(): void {
    document.removeEventListener('click', this.handleClickOutside);
  }

  performAutoSuggestion = (): void => {
    const {
      controller: {
        page: {
          searchMvp: { performAutoSuggestion },
        },
      },
    } = this.props;

    performAutoSuggestion();
  };

  handleClickOutside = (event: Event): void => {
    if (this.searchRef && !this.searchRef.current.contains(event.target as HTMLElement)) {
      this.props.model.page.searchMvp.showSuggestionDropdown = false;
    }
  };

  handleChange = (e: React.ChangeEvent): void => {
    const val = (e.target as HTMLInputElement).value;
    const {
      model: {
        page: {
          searchMvp: { searchState },
        },
      },
      controller: {
        page: {
          searchMvp: { resetQuery },
        },
      },
    } = this.props;

    runInAction(() => {
      searchState.query = val;

      this.performAutoSuggestion();

      if (!val) {
        resetQuery();
      }
    });
  };

  handleSearchTracks = (e: React.FormEvent<HTMLFormElement>): void => {
    const {
      controller: {
        page: {
          searchMvp: { performSearch, resetPagination, resetSuggestionEntity },
        },
      },
    } = this.props;
    e.preventDefault();

    if (this.props.model.page.searchMvp.cursor === null) {
      resetPagination();
      performSearch();
      resetSuggestionEntity();
    }
  };

  handleKeyDown(e: React.KeyboardEvent): void {
    const {
      model: {
        page: { searchMvp },
      },
      controller: {
        page: {
          searchMvp: { handleSuggestionSelection, updateSelectedIsHistory, performSearch, resetSuggestionEntity },
        },
      },
    } = this.props;
    const cursor = searchMvp.cursor;
    const allEntities = [...searchMvp.searchHistory, ...searchMvp.getEntities, ...searchMvp.suggestionTrackEntity];
    if (e.key === 'Enter' && cursor !== null) {
      const entity = allEntities[cursor];
      const isSearchHistory = cursor < searchMvp.searchHistory.length;
      updateSelectedIsHistory(isSearchHistory);

      const selectedHistory = isSearchHistory ? JSON.parse(entity.context) : null;
      const suggestion = isSearchHistory ? selectedHistory : entity;
      if (isSearchHistory && isEmpty(selectedHistory)) {
        this.props.model.page.searchMvp.searchState.query = entity.term;
        performSearch();
        resetSuggestionEntity();
        return;
      }
      if (suggestion.type) {
        handleSuggestionSelection(suggestion);
      }
      return;
    }

    if (e.keyCode === 38 && cursor === 0) {
      this.props.model.page.searchMvp.cursor = null;
    } else if (e.keyCode === 38 && cursor > 0) {
      this.props.model.page.searchMvp.cursor = cursor - 1;
    } else if (e.keyCode === 40 && cursor === null) {
      this.props.model.page.searchMvp.cursor = 0;
    } else if (e.keyCode === 40 && cursor < allEntities.length - 1) {
      this.props.model.page.searchMvp.cursor = cursor + 1;
    }
  }

  render() {
    const { isMobile } = this.props.model.ui;
    const {
      placeholder = isMobile ? 'Search for anything...' : 'Search for tracks, artists, playlists, genres or moods...',
      model: {
        page: { searchMvp },
      },
      controller: {
        page: {
          searchMvp: { resetQuery, performAutoSuggestion, toggleFilter, performSearch, scrollTop },
        },
        analytics: { sendMixpanel },
      },
      scrolling,
    } = this.props;

    const trackAsEntity = searchMvp.suggestionTrackEntity.map((track) => {
      return {
        ...track,
        type: 'track',
        artist: track.artists[0].name,
        artistSlug: track.artists[0].slug,
        name: track.title,
      };
    });
    const searchHistory = searchMvp.searchHistory.map((history) => {
      return {
        type: 'history',
        name: history.term,
        uuid: history.uuid,
        context: history.context,
      };
    });

    const filtersApplied = searchMvp.filterCount > 0;
    const showFilter = searchMvp.openFilter;
    const showSearchSuggestion = searchMvp.showSuggestionDropdown;
    const showCheckmark = isMobile && showFilter && filtersApplied;

    const fireUserClicksSearchBarMixpanelEvent = () => {
      sendMixpanel('User clicks search bar');
    };

    const fireUserClicksGoMixpanelEvent = () => {
      sendMixpanel('User non-validated search', { query: searchMvp.searchState.query });
    };
    const allEntities = [...searchHistory, ...searchMvp.getEntities, ...trackAsEntity];
    return (
      <div className={classes(showSearchSuggestion && 'active', styles.container)}>
        <div className={classes(styles.mainHeader, scrolling && styles.mainHeaderOnScroll)}>
          <div className={styles.headerAlignment}>
            <div
              data-test-filters-panel-button
              className={classes(
                styles.filterHeader,
                showFilter && 'filterOpenBtn',
                showSearchSuggestion && styles.smallMobileOnly
              )}
              onClick={() => {
                if (showFilter && isMobileUpToDesktop.matches) {
                  performSearch();
                  scrollTop();
                }
                if (!isMediumDesktop.matches) toggleFilter();
              }}
            >
              {!showCheckmark && <FilterBar />}

              {showCheckmark && <Checkmark size={28} />}

              {searchMvp.filterCount > 0 && (
                <Badge className={classes(styles.filterBadge, searchMvp.filterCountBounce ? styles.iconBounce : '')}>
                  <span data-test-filter-count> {searchMvp.filterCount}</span>
                </Badge>
              )}

              {showFilter && (
                <span data-test-apply-filters-button className={styles.textOnlyOnMobile}>
                  {searchMvp.filterCount > 0 ? 'Apply filters' : 'Close'}
                </span>
              )}
            </div>
            <div
              ref={this.searchRef}
              className={classes(styles.wrapper, showFilter && styles.noSearchOnFilterActiveMobileOnly)}
            >
              <form
                className={classes(styles.searchForm, showSearchSuggestion && styles.removeBorderBottom)}
                onSubmit={this.handleSearchTracks}
                data-test-search-form
              >
                <span className={styles.inputIconWrapper}>
                  {searchMvp.searchState.query.length ? (
                    <CloseCross
                      size={24}
                      color={colorNeutralLight.toString()}
                      className={styles.close}
                      onClick={() => resetQuery()}
                    />
                  ) : (
                    <SVGIcon.Search size={18} color={colorBlack.toString()} />
                  )}
                </span>

                <input
                  data-test-search-form-field
                  className={styles.input}
                  placeholder={placeholder}
                  value={searchMvp.searchState.query}
                  onClick={fireUserClicksSearchBarMixpanelEvent}
                  onChange={this.handleChange}
                  onFocus={() => {
                    if (searchMvp.searchState.query.length >= 3 && allEntities.length) {
                      this.props.model.page.searchMvp.showSuggestionDropdown = true;
                    }
                  }}
                  onKeyDown={this.handleKeyDown}
                />
                <GoButton data-test-search-form-submit-button type="submit" onClick={fireUserClicksGoMixpanelEvent} />
              </form>
              {showSearchSuggestion && <SearchSuggestion entities={allEntities as EntityType[]} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchField;
