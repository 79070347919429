import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const ExternalLink: React.FC<SVGIconProps> = ({ size = 24, isSquare, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 24 24" {...props}>
    {/* <path d="M0 0h48v48H0z" fill="none" /> */}
    <path
      fill={color}
      d="M5 3c-1.093 0-2 .907-2 2v14c0 1.093.907 2 2 2h14c1.093 0 2-.907 2-2v-7h-2v7H5V5h7V3H5zm9 0v2h3.586l-9.293 9.293 1.414 1.414L19 6.414V10h2V3h-7z"
    />
  </BaseSvg>
);
