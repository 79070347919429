import * as React from 'react';
import { Component, ComponentProps, deps, inject, observer } from '../../../lib/component';
import { classes, style } from 'typestyle';
import { colorLightBlueGrey, colorLightGrey, colorWhite, rebrand } from '../../../theme/color';
import FavouritePlaylistButtonCompat from '../../compat/favourite-playlist-button-compat';
import { ControlledTrackList } from '../organisms/ControlledTrackList';
import { fontComponentHeadings } from '../config';
import isEmpty from 'lodash/isEmpty';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { Loading } from '../../loading/loading';
import { APIError } from '../../../modules/api/types';
import { Page404 } from '../../page-404';
import { TrackList } from '../organisms/TrackList';

const styles = {
  wrapper: style({
    backgroundColor: colorLightBlueGrey.toHexString(),
  }),
  mastheadContainer: style(mediaUpToDesktop({ display: 'flex' }), {
    backgroundColor: rebrand.dark5.toHexString(),
    padding: '32px 0',
  }),
  playListMasthead: style(
    mediaUpToDesktop({
      maxWidth: '343px',
      $nest: {
        '& img': {
          objectFit: 'cover',
          height: '104px',
          width: '104px',
        },
        '& h1': {
          fontSize: '32px',
        },
      },
    }),
    {
      display: 'flex',
      maxWidth: '992px',
      margin: '0 auto',
      $nest: {
        '& img': {
          objectFit: 'cover',
          height: '152px',
          width: '152px',
          borderRadius: '4px',
        },
        '& h1': {
          fontFamily: fontComponentHeadings,
          fontSize: '60px',
        },
      },
    }
  ),
  mastheadText: style({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginLeft: '16px',
    color: colorWhite.toHexString(),
    $nest: { '& h1, & p': { margin: 0 }, '& p': { fontSize: '12px', color: rebrand.light2.toHexString() } },
  }),
  trackList: style({ margin: '0 auto 24px' }),
  favBtn: style({ position: 'absolute', top: '8px', right: '8px', zIndex: 2 }),
  tile: style({
    position: 'relative',
    flexShrink: 0,
  }),
  morePlaylists: style(
    mediaDesktop({
      columns: '2',
    }),
    mediaUpToDesktop({
      $nest: {
        '& li': {
          width: '288px',
        },
      },
    }),
    {
      padding: 0,
      $nest: {
        '& img': {
          objectFit: 'cover',
          height: '80px',
          width: '80px',
        },
        '& li': {
          listStyleType: 'none',
          display: 'flex',
          width: '488px',
          backgroundColor: colorWhite.toHexString(),
          borderBottom: `1px solid ${colorLightGrey.toString()}`,
        },
      },
    }
  ),
  morePlaylistsContainer: style(mediaUpToDesktop({ maxWidth: '288px' }), {
    maxWidth: '992px',
    margin: '0 auto',
    $nest: {
      '& h3': { fontSize: '16px', margin: '33px 0px 8px 0px ' },
    },
  }),
  morePlaylistsText: style({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    $nest: {
      '& p': {
        margin: '0px 16px',
      },
      '& p:first-child': {
        fontSize: '10px',
      },
      '& p:nth-child(2)': {
        fontSize: '12px',
        fontWeight: 700,
      },
    },
  }),
  loading: style({
    margin: '25vh 0',
  }),
  playlistLink: style({
    textDecoration: 'inherit',
    color: 'inherit',
  }),
};

@inject(deps)
@observer
export class PlaylistPage extends Component<ComponentProps> {
  constructor(props) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    return this.props.controller.page.browse.loadPlaylistBySlug(this.props.model.page.browse.slug);
  }

  render() {
    const {
      playlistMetadata,
      playlistTracks,
      playlistPageFeaturedPlaylists,
      slug,
      loading,
    } = this.props.model.page.browse;
    const {
      model: {
        router: {
          location: { query },
        },
        ui: { showPlayer },
      },
    } = this.props;
    const metadata = playlistMetadata.get(slug);

    const paginatedTracks = playlistTracks.get(slug);
    const { cover } = this.props.model.image;

    const notFound = isEmpty(paginatedTracks);
    if (notFound && loading) {
      return <Loading className={styles.loading} />;
    } else if (notFound) {
      return <Page404 />;
    }

    const {
      data: tracks,
      meta: { pagination },
    } = paginatedTracks;

    return (
      <div className={styles.wrapper}>
        <div className={styles.mastheadContainer}>
          {!isEmpty(metadata) && (
            <div className={styles.playListMasthead}>
              <div className={styles.tile}>
                <FavouritePlaylistButtonCompat identity={metadata.identity} className={styles.favBtn} />
                <img src={this.props.model.image.cover(metadata.images.identity)} data-test-playlist-image />
              </div>
              <div className={styles.mastheadText}>
                <p>Playlist</p>
                {metadata.is_generated ? (
                  <h1 data-test-playlist-title>Tracks For You</h1>
                ) : (
                  <h1 data-test-playlist-title>{metadata.name}</h1>
                )}
              </div>
            </div>
          )}
        </div>
        <ControlledTrackList
          className={styles.trackList}
          tracks={tracks}
          totalTracks={pagination.total}
          onLoadMore={this.loadMore}
          playerIsShowing={showPlayer}
        />
        {playlistPageFeaturedPlaylists && (
          <div className={styles.morePlaylistsContainer}>
            <h3>More Playlists</h3>

            <ul className={styles.morePlaylists}>
              {playlistPageFeaturedPlaylists.map((playlist, key) => (
                <li key={key}>
                  <div className={styles.tile}>
                    <FavouritePlaylistButtonCompat className={styles.favBtn} identity={playlist.identity} />
                    <img src={cover(playlist.images.identity)} alt="" />
                  </div>
                  <div className={styles.morePlaylistsText}>
                    <a
                      className={styles.playlistLink}
                      href={`/browse/collection/${playlist.slug}`}
                      data-test-playlist-link
                    >
                      <p>Playlist</p>
                      <p>{playlist.name}</p>
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}
