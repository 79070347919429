import * as React from 'react';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { ContentSlice } from './ContentSlice';
import { isEmpty } from '../../utilities/objects';
import { Button } from '../../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../../atoms/button/buttonStyles';

const { PRIMARY } = ButtonProminence;
const { LARGE } = ButtonSize;

const yPadding = px(40);

const styles = {
  wrapper: style({
    textAlign: 'center',
    paddingBottom: yPadding,
    $nest: {
      '.masthead + &, .with-gradient + &': {
        paddingTop: yPadding,
      },
      ':not(.with-gradient) + &': {
        marginTop: px(-16),
      },
    },
  }),
  content: style({
    $nest: {
      '& *': {
        margin: 0,
      },
    },
  }),
};

type CtaButtonSliceProps = React.Component['props'] & {
  className?: string;
  cta: { href: string; text: string };
};

export const CtaButtonSlice: React.SFC<CtaButtonSliceProps> = ({ children, className, cta }) => (
  <ContentSlice className={classes('cta-button-slice', className, styles.wrapper)} contentClassName={styles.content}>
    {!isEmpty(cta) && (
      <Button prominence={PRIMARY} href={cta.href} size={LARGE}>
        {cta.text}
      </Button>
    )}
  </ContentSlice>
);
