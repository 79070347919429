import { computed, observable } from 'mobx';
import {ArtistSchema} from '../../../types/schema';

export class ArtistsPageModel {
  @observable
  loading: boolean = false;

  @observable
  loadingContent: boolean = false;

  @observable
  letter: string;

  @observable
  artists: Array<ArtistSchema>;

  @observable
  pageTitle: string;

  @computed
  get title(): string {
    return this.pageTitle
      ? this.pageTitle + (this.letter ? ' ' + this.letter.toUpperCase() : '')
      : 'Loading...'
  };

  @observable
  coverImage: string;
}