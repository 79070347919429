import { PrismicSimpleBackgroundProps } from '../utilities/types';
import { isEmpty } from '../utilities/objects';
import * as React from 'react';
import { HomepageMasthead } from '../molecules/mastheads/HomepageMasthead';

export type PrismicHomepageMastheadPayload = PrismicSimpleBackgroundProps;

export type PrismicHomepageMastheadProps = {
  payload: PrismicHomepageMastheadPayload;
};
export const PrismicHomepageMasthead: React.SFC<PrismicHomepageMastheadProps> = ({
  payload: {
    primary: { background_image, background_colour },
  },
}) => <HomepageMasthead src={!isEmpty(background_image) && background_image.url} colour={background_colour} />;
