import * as React from 'react';
import { LayoutModal } from '../layout/modal';
import { SVGIcon } from '../svg-icon';
import {style} from 'typestyle';
import {centerCenter, inlineRoot, vertical} from 'csstips';
import {px} from 'csx';
import {colorBrand} from '../../theme/color';

export interface VideoThumbnailProps {
  videoId: string;
}

export interface VideoThumbnailState {
  showModal: boolean;
}

export class VideoThumbnail extends React.Component<VideoThumbnailProps, VideoThumbnailState> {
  render() {
    const { videoId } = this.props;
    const { showModal } = this.state;

    const style = {
      backgroundImage: `url(https://img.youtube.com/vi/${videoId}/1.jpg)`
    };

    return (
      <div className={VideoThumbnail.styles.container} style={style} onClick={this.handleClick}>
        <div className={VideoThumbnail.styles.play}>
          <SVGIcon.Play size={12}/>
        </div>
        <LayoutModal
          show={showModal}
          onRequestClose={this.handleClose}>
          <iframe id="ytplayer"  width="640" height="360"
                  src={`https://www.youtube.com/embed/${videoId}?autoplay=0&origin=${window.location.protocol + '// ' + window.location.host}`}
                  frameBorder="0"></iframe>
        </LayoutModal>
      </div>
    )
  }

  constructor(props: any) {
    super(props);
    this.state = { showModal: false };
  }

  handleClick = () => this.setState({ showModal: true });
  handleClose = () => this.setState({ showModal: false });

  static styles = {
    container: style({
      ...vertical,
      ...centerCenter,
      ...inlineRoot,
      cursor: 'pointer',
      width: '160px',
      height: '90px',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat'
    }),
    play: style({
      ...vertical,
      ...centerCenter,
      ...inlineRoot,
      width: px(32),
      height: px(32),
      borderRadius: px(32),
      backgroundColor: colorBrand.toString()
    })
  }
}