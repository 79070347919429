import * as React from 'react';
import { classes, style } from 'typestyle';
import { Component, ComponentProps, deps, inject, observer } from '../../../../lib/component';
import { em, important, percent, px, rem, white } from 'csx';
import { isDesktop, isMobileUpToDesktop, mediaUpToLargeMobile, mediaDesktop } from '../../utilities/mediaQueries';
import TitleText from '../../atoms/TitleText';
import { colorWhite, rebrand } from '../../../../theme/color';
import { Slider } from '../../atoms/Slider';
import { TagButton } from '../../atoms/button/TagButton';
import { PlatfortVideoIcons } from '../../atoms/icons/PlatfortVideoIcons';
import { constantHeaderWithSearchHeight } from '../../config';
import { zFilter } from '../../../../theme/z';
import { ArrowDown } from '../../atoms/icons/ArrowDown';
import { StarCircle } from '../../atoms/icons/StarCircle';
import { CheckmarkCircle } from '../../atoms/icons/CheckmarkCircle';
import { resetButton } from '../../../../theme/reset';
import { CSSTransition } from 'react-transition-group';
import { transitionQuickEase, transitionFade } from '../../../../theme/transition';
import { fontDefault } from '../../../../theme/font';

const styles = {
  container: style({
    flex: 1,
    border: 'none',
    width: 0,
    position: 'fixed',
    zIndex: zFilter,
    left: 0,
    top: constantHeaderWithSearchHeight,
    bottom: 0,
    overflowX: 'hidden',
    backgroundColor: colorWhite.toString(),
    overflow: 'scroll',
    $nest: {
      '&.filter-open': {
        width: px(350),
        border: `1px solid ${rebrand.light1.toString()}`,
        transition: '0.5s',
        ...mediaUpToLargeMobile({
          width: percent(100),
          display: 'block',
          position: 'fixed',
          left: 0,
          right: 0,
          top: constantHeaderWithSearchHeight,
        }),
      },
    },
  }),
  filterMarginOnScroll: style(
    mediaDesktop({
      position: 'absolute',
    })
  ),
  TrackPlayerShowing: style({
    paddingBottom: px(70),
  }),
  header: style({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '32px',
    paddingBottom: 0,
    minWidth: px(284),
  }),
  clearWrapper: style({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    $nest: {
      '&:hover': {
        $nest: {
          '& span': {
            color: rebrand.contrast[30].toString(),
          },
          '& svg path': {
            fill: rebrand.contrast[30].toString(),
          },
        },
      },
    },
  }),
  clear: style({
    fontWeight: 400,
    fontSize: px(16),
    color: rebrand.contrast[40].toString(),
  }),
  filterItemWrapper: style({
    padding: '16px 32px',
  }),
  platformWrapper: style({
    padding: '16px 32px 32px 32px',
  }),
  filterTitle: style({
    fontFamily: important(fontDefault),
    fontWeight: important(700) as any,
    fontSize: px(16),
    margin: '0 0 1rem',
    color: rebrand.dark1.toString(),
  }),
  lists: style({
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: ' 284px',
  }),
  item: style({
    marginRight: 8,
    marginBottom: 8,
  }),
  tagitem: style({
    marginRight: '1px',
    marginBottom: '1px',
  }),
  rangeTagWrapper: style({
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 4px',
    marginTop: '16px',
  }),
  centralise: style({
    justifyContent: 'center',
  }),
  rangebtn: style({
    padding: '6px 8px',
    borderRadius: 0,
  }),
  instrumentCatalogueBtn: style({
    padding: '2px 12px',
    borderRadius: 0,
  }),
  platformBtn: style({
    padding: '6px 10px',
  }),
  firstButton: style({
    borderRadius: '4px 0px 0px 4px',
  }),
  lastButton: style({
    borderRadius: '0px 4px 4px  0px',
  }),
  TagHeader: style(resetButton, {
    width: '100%',
    textAlign: 'inherit',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    $nest: {
      '& h4': {
        margin: 0,
      },
    },
  }),
  icon: style({
    marginBottom: px(2),
    transition: `transform ${transitionQuickEase}`,
  }),
  arrowUp: style({ transform: 'rotate(180deg)' }),
  count: style({
    fontSize: px(12),
    fontWeight: 400,
    color: rebrand.contrast[50].toString(),
    flex: 1,
    marginLeft: px(8),
    marginTop: px(4),
  }),
  dropdownContent: style({
    marginTop: rem(1),
    backgroundColor: colorWhite.toString(),
  }),
  catalogueIcon: style({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
  }),
};

type FilterLayoutProps = ComponentProps & {
  scrolling: boolean;
};

@inject(deps)
@observer
export class FilterLayout extends Component<FilterLayoutProps> {
  constructor(props) {
    super(props);
  }
  state = {
    bpmlabel: '',
    showGenres: false,
    showMoods: false,
  };

  searchOnDesktopUp = () => {
    !isMobileUpToDesktop.matches && this.props.controller.page.searchMvp.performSearch();
  };

  updateMoodAndGenre = (key: string, slug: string) => {
    const initialValue = this.props.model.page.searchMvp.searchVariables.filters[key];
    let newValue = initialValue || '';
    const initialArr = newValue.split(',');
    if (!initialValue) {
      newValue = slug;
    } else if (initialArr.includes(slug)) {
      newValue = initialArr.filter((s: string) => s !== slug).join(',');
    } else {
      newValue = initialValue + ',' + slug;
    }
    const genreOrMood = newValue === '' ? undefined : newValue;
    this.props.controller.page.searchMvp.refineFilter(key, genreOrMood);
    this.searchOnDesktopUp();
  };

  updateTempoFilter = (lower: number, upper: number) => {
    this.props.controller.page.searchMvp.refineFilter('tempoMin', lower);
    this.props.controller.page.searchMvp.refineFilter('tempoMax', upper);
    this.props.controller.analytics.sendMixpanel('User clicks filter slider', {
      tempoMin: lower,
      tempoMax: upper,
    });
    this.searchOnDesktopUp();
  };

  updateTempoFilterFromTag = (range: number[]) => {
    this.props.controller.page.searchMvp.refineFilter('tempoRange', range);
    this.props.controller.analytics.sendMixpanel('User clicks filter tempo tag', {
      tempoMin: range[0],
      tempoMax: range[1],
    });
    this.searchOnDesktopUp();
  };
  updateDurationFilter = (lower: number, upper: number) => {
    this.props.controller.page.searchMvp.refineFilter('durationMin', lower);
    this.props.controller.page.searchMvp.refineFilter('durationMax', upper);
    this.props.controller.analytics.sendMixpanel('User clicks filter slider', {
      durationMin: lower,
      durationMax: upper,
    });
    this.searchOnDesktopUp();
  };

  updateInstrumental = (value: boolean | undefined) => {
    this.props.controller.page.searchMvp.refineFilter('instrumental', value);
    this.searchOnDesktopUp();
  };
  updateTrackAvailability = (value: boolean | undefined) => {
    this.props.controller.page.searchMvp.refineFilter('hideNotAvailableIn', value);
    this.searchOnDesktopUp();
  };
  updateCatalogueType(value: string) {
    this.props.controller.page.searchMvp.refineFilter('catalogueType', value);
    this.searchOnDesktopUp();
  }
  updateVideoPlatforms = (platform: string, isActive: boolean | undefined) => {
    this.props.controller.page.searchMvp.refineFilter(platform, isActive ? undefined : true);
    this.searchOnDesktopUp();
  };
  formatDuration = (duration: number) => {
    const minutes = Math.floor(duration / 1000 / 60);
    const seconds = Math.floor(duration / 1000) % 60;
    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  };
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (isDesktop.matches) {
      this.props.model.page.searchMvp.openFilter = true;
    }
  };

  handleGenresChange = () => {
    this.setState({
      showGenres: !this.state.showGenres,
    });
  };

  handleMoodsChange = () => {
    this.setState({
      showMoods: !this.state.showMoods,
    });
  };

  render() {
    const {
      model: {
        page: {
          searchMvp: { openFilter, moods, genres, searchVariables, filterCount },
        },
      },
      controller: {
        page: {
          searchMvp: { resetFilter },
        },
        analytics: { sendMixpanel },
      },
      scrolling,
    } = this.props;
    const { showPlayer } = this.props.model.ui;
    const { showGenres, showMoods } = this.state;

    const renderGenres = () => {
      return (
        <div className={styles.filterItemWrapper} data-test-genres>
          <button
            className={styles.TagHeader}
            onClick={this.handleGenresChange}
            aria-controls="genres-dropdown"
            aria-expanded={showGenres}
            data-test-genre-title-clickable-area
          >
            <h4 className={styles.filterTitle}>Genres</h4>
            <span className={styles.count}>({genres.length})</span>
            <ArrowDown className={classes(styles.icon, showGenres && styles.arrowUp)} />
          </button>

          <CSSTransition in={showGenres} {...transitionFade}>
            <div
              id="genres-dropdown"
              className={classes(styles.lists, styles.dropdownContent)}
              data-test-genres-dropdown
            >
              {genres.map((genre, i) => {
                const activeList =
                  (searchVariables.filters['genre'] && searchVariables.filters['genre'].split(',')) || [];
                const active = activeList.includes(genre.slug);
                return (
                  <div key={`${genre.slug}-${i}`} className={styles.item}>
                    <TagButton
                      onClick={() => {
                        this.updateMoodAndGenre('genre', genre.slug),
                          sendMixpanel('User clicks filter', { filter: genre.name });
                      }}
                      text={genre.name}
                      active={active}
                      data-test-genre={genre.slug}
                      data-test-filter-genre-selected={active}
                    />
                  </div>
                );
              })}
            </div>
          </CSSTransition>
        </div>
      );
    };

    const renderMoods = () => {
      return (
        <div className={styles.filterItemWrapper} data-test-moods>
          <button
            className={styles.TagHeader}
            onClick={this.handleMoodsChange}
            aria-controls="moods-dropdown"
            aria-expanded={showGenres}
            data-test-mood-title-clickable-area
          >
            <h4 className={styles.filterTitle}>Moods</h4>
            <span className={styles.count}>({moods.length})</span>
            <ArrowDown className={classes(styles.icon, showMoods && styles.arrowUp)} />
          </button>

          <CSSTransition in={showMoods} {...transitionFade}>
            <div id="moods-dropdown" className={classes(styles.lists, styles.dropdownContent)} data-test-moods-dropdown>
              {moods.map((mood) => {
                const activeList =
                  (searchVariables.filters['mood'] && searchVariables.filters['mood'].split(',')) || [];
                const active = activeList.includes(mood.slug);

                return (
                  <div key={mood.slug} className={styles.item}>
                    <TagButton
                      text={mood.name}
                      onClick={() => {
                        this.updateMoodAndGenre('mood', mood.slug),
                          sendMixpanel('User clicks filter', { filter: mood.name });
                      }}
                      active={active}
                      data-test-mood={mood.slug}
                      data-test-filter-mood-selected={active}
                    />
                  </div>
                );
              })}
            </div>
          </CSSTransition>
        </div>
      );
    };

    const RenderTempo = () => {
      const { bpmRange, tempos } = this.props.model.page.searchMvp;
      const [MIN, MAX] = bpmRange;
      return (
        <>
          <div className={styles.filterItemWrapper} data-test-tempo>
            <h4 className={styles.filterTitle}>Tempo (BPM)</h4>
            <Slider
              lower={searchVariables.filters['tempoMin'] || MIN}
              upper={searchVariables.filters['tempoMax'] || MAX}
              min={MIN}
              max={MAX}
              step={1}
              formatValue={(v) => String(v)}
              onChange={this.updateTempoFilter}
            />
          </div>
          <div className={classes(styles.rangeTagWrapper, styles.centralise)}>
            {tempos.map((tempo, i) => {
              const checkMin = searchVariables.filters['tempoMin'] === tempo.range[0];
              const checkMax = searchVariables.filters['tempoMax'] === tempo.range[1];
              const first = i === 0;
              const last = i === tempos.length - 1;
              let active = false;
              if ((first && checkMax) || (last && checkMin) || (checkMin && checkMax)) {
                active = true;
              }

              return (
                <div key={tempo.label} className={classes(styles.tagitem)}>
                  <TagButton
                    data-test-tempo={tempo.label}
                    className={classes(styles.rangebtn, first && styles.firstButton, last && styles.lastButton)}
                    text={tempo.label}
                    onClick={() => {
                      if (tempo.label === this.state.bpmlabel) {
                        this.updateTempoFilterFromTag([null, null]);
                        this.setState({ bpmlabel: '' });
                      } else {
                        this.updateTempoFilterFromTag(tempo.range);
                        this.setState({ bpmlabel: tempo.label });
                      }
                      sendMixpanel('User clicks filter', { filter: tempo });
                    }}
                    active={active}
                  />
                </div>
              );
            })}
          </div>
        </>
      );
    };

    const RenderDuration = () => {
      const { durationRange } = this.props.model.page.searchMvp;
      const [MIN, MAX] = durationRange;
      return (
        <div className={styles.filterItemWrapper} data-test-duration>
          <h4 className={styles.filterTitle}>Duration</h4>
          <Slider
            lower={searchVariables.filters['durationMin'] || MIN}
            upper={searchVariables.filters['durationMax'] || MAX}
            min={MIN}
            max={MAX}
            step={1}
            formatValue={this.formatDuration}
            onChange={this.updateDurationFilter}
          />
        </div>
      );
    };
    const RenderInstrumental = () => {
      const { instrument } = this.props.model.page.searchMvp;

      return (
        <div className={styles.filterItemWrapper} data-test-instrumental>
          <h4 className={styles.filterTitle}>Instrumental</h4>
          <div className={styles.rangeTagWrapper}>
            {instrument.map((inst, i) => {
              const first = i === 0;
              const last = i === instrument.length - 1;
              return (
                <div key={inst.label} className={classes(styles.tagitem)}>
                  <TagButton
                    className={classes(
                      styles.instrumentCatalogueBtn,
                      first && styles.firstButton,
                      last && styles.lastButton
                    )}
                    text={inst.label}
                    onClick={() => this.updateInstrumental(inst.value)}
                    active={inst.value === searchVariables.filters['instrumental']}
                    data-test-instrumental={inst.label}
                    data-test-filter-instrumental-selected={inst.value === searchVariables.filters['instrumental']}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    };
    const RenderCatalogueType = () => {
      const { catalogueData } = this.props.model.page.searchMvp;

      return (
        <div className={styles.filterItemWrapper} data-test-cataloguetype>
          <h4 className={styles.filterTitle}>Catalogue Type</h4>
          <div className={styles.rangeTagWrapper} style={{ flexWrap: 'nowrap' }}>
            {catalogueData.map((catalogue, i) => {
              const first = i === 0;
              const last = i === catalogueData.length - 1;
              let iconRight = null;
              const active = catalogue.value === searchVariables.filters['catalogueType'];
              if (catalogue.label === 'Premium') {
                iconRight = (
                  <StarCircle size={16} color={rebrand.primary[100].toString()} className={`${styles.catalogueIcon}`} />
                );
              } else if (catalogue.label === 'Included') {
                iconRight = <CheckmarkCircle size={19.21} className={styles.catalogueIcon} />;
              }
              return (
                <div key={catalogue.label} className={classes(styles.tagitem)}>
                  <TagButton
                    className={classes(
                      styles.instrumentCatalogueBtn,
                      first && styles.firstButton,
                      last && styles.lastButton
                    )}
                    hideHoverState={catalogue.label === 'Premium' && !active ? false : true}
                    text={catalogue.label}
                    iconRight={iconRight}
                    onClick={() => {
                      this.updateCatalogueType(catalogue.value),
                        sendMixpanel('User clicks filter', { filter: catalogue });
                    }}
                    active={active}
                    data-test-cataloguetype={catalogue.label}
                    data-test-filter-cataloguetype-selected={
                      catalogue.value === searchVariables.filters['catalogueType']
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    };
    const RenderVideoPlatform = () => {
      const { platformsAndLabel } = this.props.model.page.searchMvp;
      const keys = Object.keys(platformsAndLabel);
      return (
        <div className={styles.platformWrapper} data-test-platform>
          <h4 className={styles.filterTitle}>Licensable Platforms</h4>
          <div className={styles.rangeTagWrapper}>
            {keys.map((platform) => {
              const isActive = !!searchVariables.filters[platform];
              return (
                <div key={platform} className={classes(styles.item)}>
                  <TagButton
                    className={classes(styles.platformBtn)}
                    text={platformsAndLabel[platform]}
                    onClick={() => {
                      this.updateVideoPlatforms(platform, isActive),
                        sendMixpanel('User clicks filter', { filter: platform });
                    }}
                    active={isActive}
                    isCircular
                    data-test-platform={platform}
                    data-test-filter-platform-selected={isActive}
                    icon={<PlatfortVideoIcons platform={platform} />}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    };
    const TrackAvailabileInMyCountry = () => {
      const { availableInCountry } = this.props.model.page.searchMvp;

      return (
        <div className={styles.filterItemWrapper} data-test-available-in-country>
          <h4 className={styles.filterTitle}>Available in my Country</h4>
          <div className={styles.rangeTagWrapper}>
            {availableInCountry.map((item, i) => {
              const first = i === 0;
              const last = i === availableInCountry.length - 1;
              return (
                <div key={item.label} className={classes(styles.tagitem)}>
                  <TagButton
                    className={classes(
                      styles.instrumentCatalogueBtn,
                      first && styles.firstButton,
                      last && styles.lastButton
                    )}
                    text={item.label}
                    onClick={() => this.updateTrackAvailability(item.value)}
                    active={item.value === searchVariables.filters['hideNotAvailableIn']}
                    data-test-available={item.label}
                    data-test-filter-available-selected={item.value === searchVariables.filters['hideNotAvailableIn']}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    };
    return (
      <div
        data-test-filters-panel
        className={classes(
          styles.container,
          openFilter && 'filter-open',
          !scrolling && styles.filterMarginOnScroll,
          showPlayer && styles.TrackPlayerShowing
        )}
      >
        <div className={styles.header}>
          <TitleText text="Filters" />
          {filterCount > 0 && (
            <div data-role="button" className={styles.clearWrapper} onClick={resetFilter} data-test-clear-filter>
              <span className={styles.clear}>Clear filters</span>
            </div>
          )}
        </div>

        <div>
          {renderGenres()}
          {renderMoods()}
          <RenderTempo />
          <RenderCatalogueType />
          <RenderInstrumental />
          <RenderDuration />
          <TrackAvailabileInMyCountry />
          <RenderVideoPlatform />
        </div>
      </div>
    );
  }
}
