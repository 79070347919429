import { px } from 'csx';
import * as React from 'react';
import { classes, style } from 'typestyle';
import { rebrand } from '../../theme/color';
import { spacing } from '../../theme/spacing';
import { StarCircle } from '../project-happy/atoms/icons/StarCircle';
import { centerCenter, flexRoot } from 'csstips';

interface CheckoutCreditsProps {
  creditsApplied: number;
  creditsAvailable: number;
  className?: string;
}

export const CheckoutCredits: React.FC<CheckoutCreditsProps> = ({
  creditsApplied,
  creditsAvailable,
  className,
  ...rest
}) => {
  const color = creditsApplied > 0 ? rebrand.primary[100].toString() : rebrand.neutralOnLight[40].toString();

  const styles = {
    wrapper: style(flexRoot, centerCenter, {
      gap: spacing.XXSMALL,
      fontSize: px(12),
      fontWeight: 700,
      color,
    }),
  };

  return (
    <div className={classes(styles.wrapper, className)} {...rest}>
      <StarCircle color={color} size={13} />
      {creditsAvailable > 0
        ? `${creditsApplied} credit${creditsApplied !== 1 ? 's' : ''} applied`
        : '0 credits available'}
    </div>
  );
};
