import {computed, observable} from 'mobx';
import TileGrid, {SimpleTileItem} from '../../../components/tile-grid';

export class CareersPageModel {
  @observable
  slug: string = null;

  @observable
  loading: boolean = false;

  @observable
  tiles: SimpleTileItem[] = [];

  @observable
  document_title: string = null;

  @observable
  document_meta_description: string = null;

  @observable
  page_title: string = null;

  @observable
  page_subtitle: string = null;

  @observable
  cover_image: string = null;

  @observable
  typeform_url: string = null;

  @observable
  cta_title: string = null;

  @observable
  cta_content: string = null;

  @observable
  cta_button: string = null;

  @observable
  sections: Array<{ heading: string; image: string; content: any; video: string; }> = [];

  @observable currentHeading: string = null;
  @observable currentDescription: string = null;
  @observable currentOpenings: Array<{ job_title: string; summary: string; posting: any; }> = [];

  @computed get currentShow(): boolean {
    return !this.slug && !!this.currentHeading && this.currentOpenings.length > 0;
  }

  @observable upcomingHeading: string = null;
  @observable upcomingDescription: string = null;
  @observable upcomingPositions: Array<{ job_title: string; summary: string; }> = [];

  @computed get upcomingShow(): boolean {
    return !this.slug && !!this.upcomingHeading && this.upcomingPositions.length > 0;
  }
}