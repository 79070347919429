import * as React from 'react';
import { classes, style } from 'typestyle';
import { colorGunmetal, rebrand } from '../../../theme/color';
import { CheckmarkCircle } from './icons/CheckmarkCircle';
import { StarCircle } from './icons/StarCircle';
import { em, px, url } from 'csx';
import PinkLabel from '../../../static/tile-icon-label-pink.png';
import MintLabel from '../../../static/tile-icon-label-green.png';
import { isFinePointer } from '../utilities/mediaQueries';
import { Tooltip } from './Tooltip';
import { zLabel } from '../../../theme/z';
import { flexRoot, vertical } from 'csstips';

const styles = {
  label: style({
    position: 'absolute',
    top: 0,
    left: 0,
    width: px(32),
    height: px(29),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderTopLeftRadius: 'inherit',
    zIndex: zLabel,
    $nest: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
  included: style({
    backgroundImage: url(MintLabel),
  }),
  premium: style({
    backgroundImage: url(PinkLabel),
  }),
  icon: style({ position: 'absolute', top: px(4), left: px(4) }),
  starIcon: style({ marginTop: px(1), marginLeft: px(1) }),
  tooltipContents: style(flexRoot, vertical, {
    $nest: {
      '& > span:not(:first-child):last-child': {
        color: rebrand.light2.toString(),
      },
    },
  }),
};

export const SubscriptionLabelContext = React.createContext(null);

export type SubscriptionLabelProps = {
  className?: string;
  included?: boolean;
};

export const SubscriptionLabel: React.SFC<SubscriptionLabelProps> = ({ className, included: propIncluded }) => (
  <SubscriptionLabelContext.Consumer>
    {(contextIncluded) => {
      const included = typeof contextIncluded === 'boolean' ? contextIncluded : propIncluded;
      if (typeof included !== 'boolean') return null;
      return (
        <Tooltip
          content={
            <span className={styles.tooltipContents}>
              {included ? (
                <>
                  <span>Included</span>
                  <span>Subscription</span>
                </>
              ) : (
                <>
                  <span>Premium</span>
                  <span>Pay per track</span>
                </>
              )}
            </span>
          }
        >
          {(tooltipTriggerProps) => (
            <span
              className={classes(styles.label, className, included ? styles.included : styles.premium)}
              {...tooltipTriggerProps}
            >
              {included ? (
                <CheckmarkCircle
                  size={16}
                  className={styles.icon}
                  color={rebrand.dark1.toString()}
                  checkColor={rebrand.highlight1.toString()}
                />
              ) : (
                <StarCircle
                  color="white"
                  className={classes(styles.icon, styles.starIcon)}
                  size={13.33}
                  style={{ marginTop: px(1), marginLeft: px(1) }}
                />
              )}
            </span>
          )}
        </Tooltip>
      );
    }}
  </SubscriptionLabelContext.Consumer>
);
