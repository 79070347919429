import * as React from 'react';
import { PlaylistSchema } from '../../../types/schema';
import { classes, style } from 'typestyle';
import { percent, px, rgb } from 'csx';
import { CarouselImage } from './CarouselImage';
import { rebrand } from '../../../theme/color';
import { defaultHeadingStyles, heading3 } from '../utilities/font';
import { centerCenter } from 'csstips/lib/flex';
import { flexRoot } from 'csstips';
import { CSSProperties } from 'typestyle/lib/types';
import { isEmpty } from '../utilities/objects';
import {
  getUnicodeCharacterArray,
  getUnicodeCharAt,
  getUnicodeCharCodeAt,
  isUnicodeHighSurrogate,
} from '../utilities/string';

const contentOverlay: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: percent(100),
  height: percent(100),
};

const styles = {
  wrapper: style({
    width: percent(100),
    height: 'inherit',
    position: 'relative',
    backgroundColor: rebrand.dark1.toString(),
    color: rebrand.contrast['10'].toString(),
    flexShrink: 0,
  }),
  fallbackInitials: style(centerCenter, defaultHeadingStyles, heading3, contentOverlay),
  images: style(flexRoot, contentOverlay, {
    flexWrap: 'wrap',
  }),
  image: style({
    width: percent(50),
    height: percent(50),
    objectFit: 'cover',
    $nest: {
      '&:only-child, & img': {
        width: percent(100),
        height: percent(100),
      },
    },
  }),
};

const getInitial = (word: string) => getUnicodeCharAt(word, 0).toUpperCase();

const getPlaylistInitials = (name: string) => {
  const nameSplit = name.trim().split(' ');
  const first = getInitial(nameSplit[0]);
  if (nameSplit.length === 1) return first;
  const last = getInitial(nameSplit[nameSplit.length - 1]);
  return first + last;
};

const getInitialBackgroundColour = (initials: string) => {
  const initialsArray = getUnicodeCharacterArray(initials);
  const firstChar = initialsArray[0] || '0';
  const secondChar = initialsArray[1] || firstChar;
  // While RGB is a value of up to 255, values above 220 make for VERY vivid colours
  const threshold = 220;
  const first = (getUnicodeCharCodeAt(firstChar, 0) || 0) % threshold;
  const second = (getUnicodeCharCodeAt(secondChar, 0) || 0) % threshold;
  const sum = (first + second) % threshold;
  const firstIsEven = first % 2 === 0;
  const secondIsEven = second % 2 === 0;
  const areSame = firstIsEven === secondIsEven;
  return rgb(
    firstIsEven ? 255 - first : first,
    secondIsEven ? 255 - second : second,
    areSame ? 255 - sum : sum
  ).toString();
};

export type UserPlaylistThumbnailProps = {
  className?: string;
  playlist: PlaylistSchema;
};

export const UserPlaylistThumbnail: React.FC<UserPlaylistThumbnailProps> = ({ className, playlist }) => {
  let images;
  const alt = `Artwork for "${playlist.name}" playlist`;
  const initials = getPlaylistInitials(playlist.name);
  if (playlist.use_playlist_image) {
    images = <CarouselImage className={styles.image} alt={alt} identity={playlist.images.identity} />;
  } else if (playlist.track_images) {
    images = playlist.track_images.map((image, index) => (
      <CarouselImage
        className={styles.image}
        key={index}
        alt={alt}
        identity={image.identity}
        width={230}
        height={230}
      />
    ));
    if (images.length < 4) {
      images.length = 1;
    }
  }
  const backgroundStyles = isEmpty(images) ? { backgroundColor: getInitialBackgroundColour(initials) } : null;
  return (
    <div className={classes(styles.wrapper, className)} style={backgroundStyles}>
      <div className={styles.fallbackInitials}>{initials}</div>
      <div className={styles.images}>{images}</div>
    </div>
  );
};
