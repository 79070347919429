import * as React from 'react';
import HashTagImg from '../../../../static/hashtag.png';
import HashTagImg2x from '../../../../static/hashtag@2x.png';

export const HashTag: React.FC = () => (
  <img
    src={HashTagImg}
    alt="Grafitti-style hashtag image"
    srcSet={`${HashTagImg}, ${HashTagImg2x} 2x`}
    width={100}
    height={100}
    loading="lazy"
  />
);
