import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalChildStyle } from './modal';
import { colorBrand } from '../theme/color';
import { rem } from 'csx';
import { style } from 'typestyle';
import { ButtonPill } from './button-pill';

export interface ModalGenericModel {
  show: boolean;
}

export interface ModalGenericProps {
  model: ModalGenericModel;
  onRequestClose(): void;
  noCloseButton?: boolean;
  i18n?: {
    close: string;
  };
}

const DEFAULT_I18N = {
  close: 'Close',
};

export const ModalGeneric = observer(
  ({ model, onRequestClose, children, noCloseButton = false, i18n = DEFAULT_I18N }: React.PropsWithChildren<ModalGenericProps>) => (
    <Modal show={model.show} onRequestClose={onRequestClose}>
      {model.show && (
        <div className={container}>
          {children}
          {!noCloseButton && (
            <div className={buttons}>
              <ButtonPill onClick={onRequestClose}>{i18n.close}</ButtonPill>
            </div>
          )}
        </div>
      )}
    </Modal>
  )
);

const container = style({
  ...ModalChildStyle,
  position: 'relative',
  $nest: {
    '&> h3': {
      color: colorBrand.toString(),
      fontSize: rem(1.75),
      margin: 0,
    },
    '&> p': {
      margin: '1.5rem 0 1rem 0',
    },
  },
});

const buttons = style({
  margin: '1rem 0 0 0',
  textAlign: 'right',
});
