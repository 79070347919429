import { LandingPageData, LandingPageModel } from './model';
import { action } from 'mobx';
import { RedirectFunction, RouterState } from 'react-router';
import { PrismicController } from '../../prismic/controller';
import { BugsnagController } from '../../bugsnag/controller';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import Prismic from 'prismic-javascript';
import { RouterModel } from '../../router/model';
import { stripQueryString } from '../../../lib/string';
import { ContentModel } from '../../content/model';

export class LandingPageController {
  constructor(
    private model: LandingPageModel,
    private prismic: PrismicController,
    private bugsnag: BugsnagController,
    private env: EnvModel,
    private ui: UIController,
    private router: RouterModel,
    private content: ContentModel,
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    this.model.loading = true;
    this.model.slug = stripQueryString(nextState.params['slug']);

    if (this.model.slug) return this.onEnterPage();

    this.router.replace('/');
  };

  private onEnterPage = async () => {
    try {
      const predicates = [
        Prismic.Predicates.at('document.type', 'v2'),
        Prismic.Predicates.at('my.v2.slug', this.model.slug),
      ];

      const [{ results }] = await Promise.all([this.prismic.queryLanding(predicates), this.env.ready]);

      if (results.length === 0) {
        return this.router.replace('/');
      }

      const data = results[0].data as LandingPageData;

      this.ui.setBreadcrumbs([]);
      this.ui.setTitle(`${data.meta_title} ${this.content.seo.titleSuffix}`);
      this.ui.setDescription(data.meta_description);

      this.ui.setOpenGraph({
        title: data.meta_title,
        description: data.meta_description,
      });

      this.model.background_image = data.background_image;
      this.model.cta_1_copy = data.cta_1_copy;
      this.model.cta_1_link = data.cta_1_link;
      this.model.cta_2_copy = data.cta_2_copy;
      this.model.cta_2_link = data.cta_2_link;
      this.model.cta_3_copy = data.cta_3_copy;
      this.model.cta_3_link = data.cta_3_link;
      this.model.divider_image = data.divider_image;
      this.model.header = data.header;
      this.model.header_image = data.header_image;
      this.model.hero_1_copy = data.hero_1_copy;
      this.model.hero_1_image = data.hero_1_image;
      this.model.hero_2_copy = data.hero_2_copy;
      this.model.hero_2_image = data.hero_2_image;
      this.model.hero_3_copy = data.hero_3_copy;
      this.model.hero_3_image = data.hero_3_image;
      this.model.hero_title = data.hero_title;
      this.model.meta_description = data.meta_description;
      this.model.meta_title = data.meta_title;
      this.model.slug = data.slug;
      this.model.strapline = data.strapline;
      this.model.video = data.video;
      this.model.video_title = data.video_title;
      this.model.video_copy = data.video_copy;
      this.model.loading = false;
    } catch (e) {
      this.bugsnag.notifyException(e);
      this.router.replace('/');
    }
  };
}
