import * as React from 'react';
import { classes, style } from 'typestyle';
import { AudioMetaSchema } from '../../types/schema';
import { ImagePreloader } from '../../types/fn';
import { em, percent, px, rem, viewWidth } from 'csx';
import { colorBackground, colorBlack, colorBrand, colorGunmetal, colorSubtle, colorWhite } from '../../theme/color';
import { mediaTablet, mediaMobileOnly } from '../../theme/media';
import { gradientPlayerProgress } from '../../theme/gradient';
import { User } from '../../types/user';
import Waveform from './waveform';
import { defaultStreamState } from '../../modules/api/audio/controller';
import { formatDuration } from '../duration';
import { centerCenter, vertical, verticallySpaced } from 'csstips';
import { constantPlayerHeight } from '../../theme/constant';
import Hls from 'hls.js';
import SILENCE from '../../static/1-second-of-silence.mp3';

export type PlayerLoadAudioMeta = (trackId: string) => Promise<AudioMetaSchema>;
export type PlayerPlayListener = (play: boolean) => Promise<any>;
export type PlayerDetachPlayListener = () => any;
export type PlayerAttachPlayListener = (listener: PlayerPlayListener) => PlayerDetachPlayListener;

type Props = {
  className?: string;
  trackId?: string;
  play?: boolean;
  volume?: number;
  preload: ImagePreloader;
  preloadWaveform: ImagePreloader;
  loadAudioMeta: PlayerLoadAudioMeta;
  attachPlayListener: PlayerAttachPlayListener;
  user?: User;
  startAt?: number;
  onFinish?(): any;
  onMeta?(meta: AudioMetaSchema): any;
  onProgressUpdate?(time: number): void;
};

type State = AudioMetaSchema & {
  init: boolean;
  loading: boolean;
  ready: boolean;
  currentTime: number;
  seeking: boolean;
  wait: number;
};

const defaultState: State = {
  init: true,
  loading: false,
  ready: false,
  currentTime: 0,
  wait: 0,
  seeking: false,
  ...defaultStreamState,
};

const THROTTLE_TIMEOUT = 5000;

class Player extends React.Component<Props, State> {
  private hls: Hls;
  private $container: HTMLDivElement;
  private $video: HTMLVideoElement;
  private initPromise: any;
  private playPromise: any;
  private _detachPlayListener: PlayerDetachPlayListener;
  private _mounted: boolean;
  private _retryAfterInterval: any;

  state = defaultState;

  render() {
    const progress = this.state.currentTime / (this.state.duration / 1000);
    const active = !this.state.is_blocked && !this.state.wait;

    const className = classes(active ? Player.styles.container : Player.styles.containerInactive, this.props.className);

    return (
      <div
        ref={(el) => (this.$container = el)}
        className={className}
        onMouseDown={this._mouseDown}
        onTouchStart={this._touchStart}
      >
        <audio
          autoPlay={false}
          ref={(el) => (this.$video = el as any)}
          className={Player.styles.video}
          onTimeUpdate={this._timeUpdate}
          onLoadedMetadata={this._loadedMetadata}
          onEnded={this._ended}
        />
        {active && this.state.waveform && (
          <Waveform className={Player.styles.waveform} preloadWaveform={this.props.preloadWaveform} {...this.state} />
        )}
        {this.state.is_blocked && !this.state.wait && (
          <div className={Player.styles.wait}>
            <div>This track's label has restricted playback</div>
            <div>
              We’re sorry, but Universal Music Group limit the number of times you can stream their tracks. Your limit
              will reset in 24 hours. Your ability to stream other tracks will not be affected.
            </div>
          </div>
        )}
        {this.state.is_blocked && !!this.state.wait && (
          <div className={Player.styles.wait}>
            <div>Please wait {formatDuration(this.state.wait * 1000)} to continue listening</div>
            {!this.props.user && (
              <div>Registered users can preview more tracks - sign up for a free account to skip the wait!</div>
            )}
            {this.props.user && (
              <div>
                We’re sorry, but our record label partners limit the number of times you can stream tracks. Your limit
                will reset shortly!
              </div>
            )}
          </div>
        )}

        {/* player runner */}
        {active && (
          <div className={Player.styles.progress} style={{ width: percent(progress * 100) }}>
            <div />
          </div>
        )}
        {active && <span className={Player.styles.nipple} style={{ left: percent(progress * 100) }} />}
      </div>
    );
  }

  componentDidMount() {
    this._mounted = true;
    this.loadSilence();

    if (this.props.trackId) {
      this.loadTrack(this.props.trackId);
    }

    // this._detachPlayListener = this.props.attachPlayListener(this._playListener);

    document.body.addEventListener('click', this._playListener);
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    if (props.trackId !== this.props.trackId && props.trackId) {
      // mute on new track to prevent clipping
      if (this.$video) {
        this.$video.volume = 0;
      }

      this.loadTrack(props.trackId);
    }
  }

  componentDidUpdate(props: Props, state: State) {
    const nextVolume = Math.min(this.props.volume || 0, 100) / 100;
    const isNewTrack = this.props.trackId !== props.trackId;

    if (this.state.audio && this.state.audio !== state.audio) {
      this._attachAudio(this.state.audio, this.state.type);
    }

    this.$video.volume = this.props.play && this.state.ready && !isNewTrack ? nextVolume : 0;

    if (this.state.ready) {
      const isNowReadyPlay = !state.ready && this.props.play;
      const isNowPlaying = this.props.play && !props.play;

      const isNowReadyPause = !state.ready && !this.props.play;
      const isNowPausing = !this.props.play && props.play;

      const startAt = isNowReadyPlay && this.props.startAt ? this.props.startAt : null;

      if (isNowReadyPlay || isNowPlaying) {
        this._playAudio(startAt);
      } else if (isNowReadyPause || isNowPausing) {
        this._pauseAudio();
      }
    } else if (!this.$video.paused) {
      this._pauseAudio();
    }

    if (this.state.seeking && !state.seeking) {
      this._attachDocumentListeners();
    }

    if (!this.state.seeking && state.seeking) {
      this._detachDocumentListeners();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    clearInterval(this._retryAfterInterval);
    delete this._retryAfterInterval;

    if (this.hls) {
      this.hls.destroy();
    }

    this._detachDocumentListeners();
    // this._detachPlayListener();
    document.body.removeEventListener('click', this._playListener);
  }

  loadSilence = () => {
    this.$video.src = SILENCE;
  };

  loadTrack = async (trackId: string) => {
    await this.initPromise;

    if (this.state.wait) return;

    this.setState({ ...defaultState, loading: true });

    const meta = await this.props.loadAudioMeta(trackId);

    this.setState({ ...meta, loading: false }, () => {
      if (this.props.onMeta) {
        this.props.onMeta(meta);
      }
    });

    if (meta.retry_after && !this._retryAfterInterval) {
      const end = Date.now() + meta.retry_after * 1000;

      this._retryAfterInterval = setInterval(() => {
        const now = Date.now();
        const wait = end <= now ? 0 : Math.ceil((end - now) / 1000);

        if (wait === 0) {
          clearInterval(this._retryAfterInterval);
          delete this._retryAfterInterval;
        }

        this.setState((state) => ({
          wait,
          retry_after: wait === 0 ? null : state.retry_after,
        }));
      }, 100);
    }
  };

  private _playListener = () => {
    if (this.initPromise) {
      return this.initPromise;
    }

    if (this.props.play || (this.$video.paused && this.$video.src === SILENCE)) {
      this.$video.play();
    } else {
      this.$video.pause();
    }

    this.initPromise = Promise.resolve(null);

    return this.initPromise;
  };

  private _playAudio = async (startAt: number | null) => {
    if (!this.$video || !this.$video.paused) return;
    await this.initPromise;

    if (!this.playPromise) {
      if (startAt !== null) {
        this.$video.currentTime = startAt < this.state.duration ? startAt / 1000 : 0;
      }

      this.playPromise = this.$video.play();
    }

    try {
      await this.playPromise;
    } finally {
      delete this.playPromise;
    }
  };

  private _pauseAudio = async () => {
    if (!this.$video || this.$video.paused) return;
    await this.playPromise;

    this.$video.pause();
  };

  private _attachAudio = async (audio: string, type: string) => {
    if (!audio || !this.$video) {
      return;
    }

    try {
      await this.playPromise;
    } finally {
      this.$video.pause();
    }

    if (type !== 'hls_stream' || !Hls.isSupported()) {
      try {
        await this.initPromise;
        this.$video.src = audio;
        this.$video.autoplay = false;
        this.$video.load();
      } finally {
      }

      return;
    }

    if (this.hls) {
      this.hls.destroy();
    }

    this.hls = new Hls();
    this.hls.on(Hls.Events.ERROR, this._hlsError);
    this.hls.on(Hls.Events.MEDIA_ATTACHED, this._hlsMediaAttached.bind(this, audio));
    this.hls.attachMedia(this.$video);
  };

  private _hlsMediaAttached = (audio: string) => {
    this.hls.loadSource(audio);
    this.hls.on(Hls.Events.MANIFEST_PARSED, this._hlsManifestParsed);
  };

  private _loadedMetadata = (event: React.SyntheticEvent<HTMLVideoElement>) => {
    this._ready();
  };

  private _hlsManifestParsed = () => {
    this._ready();
  };

  private _ready = () => {
    this.setState({ ready: true });
  };

  private _hlsError = (event, data) => {
    console.log('hls error', event, data);
  };

  private _timeUpdate = () => {
    this.setState({ currentTime: this.$video.currentTime });
    if (typeof this.props.onProgressUpdate === 'function') {
      this.props.onProgressUpdate(this.state.currentTime);
    }
  };

  private _ended = () => {
    if (this.props.onFinish) {
      this.props.onFinish();
    }

    this.$video.currentTime = 0;
  };

  private _mouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.button !== 0) return;
    this._seekStart(event.clientX);
    event.stopPropagation();
    event.preventDefault();
  };

  private _touchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    this._seekStart(event.touches[0].clientX);
    event.stopPropagation();
  };

  private _mouseMove = (event: MouseEvent) => {
    this._seekProgress(event.clientX);
    event.stopPropagation();
    event.preventDefault();
  };

  private _touchMove = (event: TouchEvent) => {
    this._seekProgress(event.touches[0].clientX);
    event.stopPropagation();
  };

  private _mouseUp = (event: MouseEvent) => {
    this._seekEnd();
    event.stopPropagation();
    event.preventDefault();
  };

  private _touchEnd = (event: TouchEvent) => {
    this._seekEnd();
    event.stopPropagation();
  };

  private _seekStart = (clientX: number) => {
    this.setState({ seeking: true });
    this._seekProgress(clientX);
  };

  private _seekProgress = (clientX: number) => {
    if (this.$video && this.state.ready && this.$video.seekable.length) {
      const progress = this._progressFromEvent(clientX);
      const maximumLoaded = this.$video.seekable.end(0);

      this.$video.currentTime = Math.min(progress * (this.state.duration / 1000), maximumLoaded);
    }
  };

  private _seekEnd = () => {
    this.setState({ seeking: false });
  };

  private _progressFromEvent = (clientX: number): number => {
    const { right, left } = this.$container.getBoundingClientRect();
    const progress = (clientX - left) / (right - left);
    return progress < 0 ? 0 : progress > 1 ? 1 : progress;
  };

  private _attachDocumentListeners = () => {
    document.addEventListener('mousemove', this._mouseMove);
    document.addEventListener('touchmove', this._touchMove);
    document.addEventListener('mouseup', this._mouseUp);
    document.addEventListener('touchend', this._touchEnd);
  };

  private _detachDocumentListeners = () => {
    document.removeEventListener('mousemove', this._mouseMove);
    document.removeEventListener('touchmove', this._touchMove);
    document.removeEventListener('mouseup', this._mouseUp);
    document.removeEventListener('touchend', this._touchEnd);
  };

  static styles = {
    container: style({
      position: 'relative',
    }),
    containerInactive: style(
      mediaMobileOnly({
        position: 'relative',
      })
    ),
    wait: style(
      {
        ...vertical,
        ...verticallySpaced(4),
        ...centerCenter,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: px(8),
        textAlign: 'center',
        background: colorBlack.fade(0.9).toString(),
        $nest: {
          '& > div': {
            color: colorWhite.toString(),
            fontSize: px(14),
            fontWeight: 500,
            lineHeight: '1.125em',
            margin: 0,
          },
          '& > div:last-child': {
            fontSize: px(12),
            fontWeight: 'normal',
            color: colorBackground.toString(),
          },
        },
      },
      mediaMobileOnly({
        top: px(6),
        height: constantPlayerHeight,
      })
    ),
    video: style({
      width: 0,
      height: 0,
      position: 'absolute',
      left: -9999,
    }),
    waveform: style({
      width: percent(100),
      height: percent(100),
      borderRadius: px(50),
    }),
    nipple: style({
      zIndex: 11,
      display: 'block',
      position: 'absolute',
      top: percent(50),
      left: 0,
      margin: '-10px 0 0 -10px',
      width: px(20),
      height: px(20),
      border: `2px solid ${colorWhite.toString()}`,
      background: colorGunmetal.toString(),
      borderRadius: px(20),
    }),
    progress: style(
      {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 10,
        overflow: 'hidden',
        $nest: {
          '& div': {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            background: gradientPlayerProgress,
          },
        },
      },
      mediaTablet({ display: 'none' })
    ),
    hideTablet: style(mediaTablet({ display: 'none' })),
    hideMobile: style(mediaMobileOnly({ display: 'none' })),
  };
}

export default Player;
