import { action, autorun, IReactionDisposer, runInAction } from 'mobx';
import { CheckoutModel } from './model';
import { AddressSchema, OrderSchema } from '../../types/schema';
import { APIController } from '../api/controller';
import { CheckoutPageModel } from '../page/checkout/model';
import { EnvModel } from '../env/model';
import { BasketController } from '../basket/controller';
import { UIController } from '../ui/controller';
import { AnalyticsController } from '../analytics/controller';
import { AuthController } from '../auth/controller';
import { BugsnagController } from '../bugsnag/controller';
import { RouterModel } from '../router/model';
import { ContentModel } from '../content/model';
import { CouponController } from '../coupon/controller';
import { getCookieValue } from '../../lib/helpers';
import { createCheckoutCardForm, createCheckoutCouponForm, createVatNumberForm, resetFormErrors } from '../../lib/form';
import { APIError as APIErrorType, FlashMessageTypeOrderSuccess, HttpError } from '../../types';
import { HttpRequestError } from '../../lib/Error';
import { APIError, APIResponse } from '../api/types';
import { LOAD_STATE } from '../../types/api';
import { notify } from '../../components/project-happy/organisms/Notifications';
import siteEvents, { SITE_EVENTS } from '../../components/project-happy/utilities/siteEvents';
import Cookies from 'js-cookie';

export enum CheckoutControllerErrors {
  PAID_INTEGRITY_MISMATCH = 'PAID_INTEGRITY_MISMATCH',
}

export class CheckoutController {
  constructor(
    private env: EnvModel,
    private model: CheckoutModel,
    private api: APIController,
    private checkoutPage: CheckoutPageModel,
    private basket: BasketController,
    private ui: UIController,
    private analytics: AnalyticsController,
    private auth: AuthController,
    private bugsnag: BugsnagController,
    private router: RouterModel,
    private content: ContentModel,
    private coupon: CouponController
  ) {
    this._disposers = [];

    if (!this.env.isServer) {
      this._disposers.push(
        autorun(() => {
          this.model.step = this.checkoutPage.step;
        })
      );
      let { channelId } = this.model;
      this._disposers.push(
        autorun(() => {
          if (this.model.channelId !== channelId && this.model.step !== 3) {
            channelId = this.model.channelId;
            this.cancelOrder();
          }
        })
      );

      let coupon: string;
      this._disposers.push(
        autorun(() => {
          if (coupon !== this.model.coupon) {
            this.model.couponForm = createCheckoutCouponForm(
              this.model.defaultCoupon,
              false // enable coupons to be switched
            );
            coupon = this.model.coupon;
          }
        })
      );
    }
  }

  private _disposers: IReactionDisposer[];

  private deferredOrderUID: ReturnType<typeof setTimeout> | null = null;

  dispose = () => {
    this._disposers.forEach((dispose) => dispose());
  };

  @action
  continueOrder = () => {
    const step = this.model.step + 1;

    if (step === 1) {
      this.analytics.sendMixpanel('Continuing to confirm order');
    }

    this.selectStep(step, true);
  };

  @action
  cancelOrder = () => {
    this.model.order = null;
    this.model.orderState = LOAD_STATE.EMPTY;
  };

  @action
  selectCard = (identity: string) => {
    if (this.model.loading) return;

    const card = this.model.cards.find((c) => c.identity === identity);
    if (!card) return;

    this.model.showAddCard = false;
    this.model.selectedCard = identity;
    this.model.address = card.address;
  };

  @action
  selectAddress = (address: AddressSchema) => {
    if (this.model.loading) return;

    this.model.address = address;
  };

  @action
  addCard = () => {
    this.model.showAddCard = true;
    this.model.selectedCard = null;
  };

  @action
  changeCardForm = (key: string, value: any) => {
    const field = this.model.addCardForm.fields[key];
    if (!field) return;

    field.value = value;
  };

  @action
  changeVatNumber = (vatNumber: string) => {
    this.model.vatNumberForm.fields.vatNumber.value = vatNumber;
  };

  @action
  changeCouponCode = (couponCode: string) => {
    this.model.couponForm.fields.couponCode.value = couponCode;
  };

  @action
  removeTrack = (identity: string) => {
    const track = this.model.tracks.find((t) => t.identity === identity);
    if (!track) return;

    const promise = this.basket.removeFromBasket(track);
    this.analytics.sendMixpanel('User clicks remove track');
    return promise;
  };

  @action
  generateStripeSession = async (orderIdentity: string) => {
    const {
      data: {
        stripe: { session_id: newSessionId },
        amount: { total: newTotal },
      },
    } = await this.api.checkout.getStripeSession(orderIdentity);
    const {
      amount: { total },
    } = this.model.order;
    if (total !== newTotal) {
      throw Error(CheckoutControllerErrors.PAID_INTEGRITY_MISMATCH);
    }

    return newSessionId;
  };

  @action
  selectStep = async (step: number, isSubmit = false) => {
    if (this.model.loading) return;
    if (!isSubmit && step > this.model.maxStep) return;

    this.model.globalError = null;

    let order;
    let success;
    let stripeSession;

    switch (true) {
      case step === 1:
      case step === 2:
        this.model.loading = true;
        order = await this.createOrder();

        if (!order) return;

        runInAction(() => {
          this.model.order = order;
          this.model.order.stripeSessionId = order.stripe.session_id;
        });

        success = this.goToConfirmOrder();
        this.model.loading = false;
        break;
      case step === 3:
        //this.user == false return xstep=0
        success = await this.goToDownload();

        break;
      default:
        success = await this.goToReviewOrder();
        break;
    }

    if (success) {
      this.ui.scrollToTop();
    }

    return true;
  };

  @action
  public loadOrder = async () => {
    let order;
    try {
      this.model.orderState = LOAD_STATE.LOADING;
      order = await this.createOrder();

      if (!order) return false;
    } catch (e) {
      this.model.orderState = LOAD_STATE.ERROR;
      this.bugsnag.notifyException(e);
      return false;
    }

    this.model.orderState = LOAD_STATE.READY;
    this.model.order = order;
    this.model.order.stripeSessionId = order.stripe.session_id;
    return true;
  };

  @action
  public deferredLoadOrder() {
    this.model.orderState = LOAD_STATE.LOADING;
    clearTimeout(this.deferredOrderUID);
    this.deferredOrderUID = setTimeout(() => this.loadOrder(), 2000);
  }

  @action
  checkifUserHasVisitedPageBefore() {
    if (this.router.location.pathname === '/checkout/download') {
      const hasVisitedPostOrderPage = Cookies.get('hasVisitedPostOrderPage');
      if (!this.env.isServer && !hasVisitedPostOrderPage) {
        Cookies.set('hasVisitedPostOrderPage', true, {
          domain: this.env.rootDomain,
        });
      }
    }
  }

  @action
  public goToDownload = async () => {
    const urlParams = new URLSearchParams(window.location.search);

    if (this.model.order && this.model.order.amount.total > 0 && !urlParams.has('session_id')) {
      // Since WEB-4661 we've used the stripe ID generated at the point of checkout confirmation to ensure users
      // have the correct price for their account (subscription state and ratecard). This may throw a PAID_INTEGRITY_MISMATCH error
      let stripeSessionId;
      this.model.orderState = LOAD_STATE.LOADING;
      try {
        stripeSessionId = await this.generateStripeSession(this.model.order.identity);
      } catch (error) {
        if (error instanceof Error && error.message === CheckoutControllerErrors.PAID_INTEGRITY_MISMATCH) {
          this.model.orderState = LOAD_STATE.ERROR;
          this.model.globalError = 'Your basket has changed. Refresh your browser to see changes.';
        }
        throw error;
      }
      this.model.orderState = LOAD_STATE.READY;
      return await this.api.checkout.checkoutWithStripe(stripeSessionId);
    }

    const { userId } = this.model;
    const orderId = urlParams.get('order_id') || (this.model.order && this.model.order.identity);
    const sessionId = urlParams.get('session_id');

    if (typeof orderId !== 'string') {
      this.router.push('/checkout');
    }

    let order: APIResponse<OrderSchema>;

    if (
      this.model.order &&
      Math.floor(Number(this.model.order.amount.total)) <= 0 &&
      this.model.order.status === 'pending'
    ) {
      order = await this.api.checkout.markOrderAsComplete(userId, orderId).then(() => {
        return this.api.checkout.getOrder(userId, orderId);
      });
    } else {
      order = await this.api.checkout.getOrder(userId, orderId);
    }

    if (
      !order.data ||
      (order.data.status != 'succeeded' && order.data.status != 'complete') ||
      (sessionId && sessionId !== order.data.stripe.session_id)
    ) {
      this.router.push('/checkout');
      return;
    }

    this.auth.getSession();
    this.model.order = order.data;
    this.model.step = 3;
    this.basket.reset();

    this.model.inProgress = false;

    this.checkifUserHasVisitedPageBefore();
    if (this.router.location.pathname !== '/checkout/download') {
      this.router.push('/checkout/download');
    }
    this.coupon.getCoupon(this.model.channelId, false).then(() => {
      this.model.couponForm = createCheckoutCouponForm(
        this.model.defaultCoupon,
        false // enable coupons to be switched
      );
    }); // get the next coupon and clear it

    siteEvents.emit(SITE_EVENTS.ORDER_SUCCESS, { orderId: this.model.order.identity });

    return true;
  };

  @action
  private goToReviewOrder = async () => {
    this.model.step = 0;
    this.model.order = null;
    this.model.inProgress = false;

    if (this.router.location.pathname.startsWith('/checkout')) {
      if (
        this.router.location.pathname.startsWith('/checkout/review') &&
        this.model.channelId &&
        this.model.tracks.length > 0
      ) {
        await this.coupon.getCoupon(this.model.channelId);
        this.analytics.sendCheckoutStep0(this.model.tracks);
      }

      if (this.router.location.pathname !== '/checkout/review') {
        this.router.push('/checkout/review');
      }
    }

    return true;
  };

  @action
  private goToPaymentDetails = async () => {
    if (this.model.tracks.length === 0 || (this.model.order && this.model.order.status === 'succeeded')) {
      return this.router.push('/checkout/review');
    }

    try {
      this.model.loading = true;

      const order = await this.createOrder();
      if (!order) return;

      runInAction(() => {
        this.model.order = order;
        this.model.selectedCard = null;
      });

      const promises = [this.getPaymentDetails()];

      await Promise.all(promises);

      runInAction(() => {
        if (this.model.cards.length) {
          const card = this.model.cards[0];
          this.model.selectedCard = card.identity;
          this.model.address = card.address;
        }

        this.model.showAddCard = this.model.cards.length === 0;

        this.model.vatNumber = this.model.defaultVatNumber;
        this.model.vatNumberForm = createVatNumberForm(this.model.defaultVatNumber);
        this.model.couponForm.disabled = true;
        this.model.addCardForm = createCheckoutCardForm();
        this.model.step = 1;
      });

      this.analytics.sendCheckoutStep1(this.model.tracks);

      runInAction(() => {
        this.model.inProgress = true;
      });

      this.router.push('/checkout/payment');

      return true;
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      this.model.loading = false;
    }
  };

  @action
  private goToConfirmOrder = async () => {
    if (this.model.tracks.length === 0 || (this.model.order && this.model.order.status === 'succeeded')) {
      return this.router.push('/checkout/review');
    }

    this.model.step = 2;

    this.analytics.sendCheckoutStep2(this.model.order);

    this.model.inProgress = true;
    this.router.push('/checkout/confirm');

    return true;
  };

  @action getPaymentDetails = async () => {
    const [addresses, cards] = await Promise.all([
      this.api.user.getBillingAddresses(this.model.userId),
      this.api.user.getPaymentCards(this.model.userId),
    ]);

    this.model.addresses = addresses.data;
    this.model.cards = cards.data;
  };

  @action
  private createOrder = async (): Promise<OrderSchema> => {
    try {
      const { basketId, userId, channelId, creditsToUse, applyCoupon } = this.model;

      const coupon = !applyCoupon ? null : this.model.coupon || null;
      const referrerChannelId = getCookieValue('referrerChannelId');

      const order = await this.api.checkout.createOrder(
        userId,
        basketId,
        channelId,
        coupon,
        referrerChannelId,
        creditsToUse
      );

      return order.data;
    } catch (e) {
      let reason = e.toString();
      if (e instanceof APIError) {
        reason = e.reason;
      } else if (e instanceof Error) {
        reason = e.message;
      }
      this.model.globalError = reason;
      this.bugsnag.notifyException(e);
      throw e;
    } finally {
      this.model.loading = false;
    }
  };

  @action
  private payOrder = async (): Promise<OrderSchema> => {
    try {
      this.model.loading = true;

      const { orderId, userId, selectedCard, isBeta } = this.model;
      const card = isBeta ? null : selectedCard;
      const order = await this.api.checkout.payOrder(userId, orderId, card);

      return order.data;
    } catch (e) {
      this.model.globalError =
        e instanceof HttpRequestError
          ? e.body && e.body.error
            ? e.body.error
            : e.message
          : ((e as HttpError).body as APIErrorType).error;

      this.bugsnag.notifyException(e);
    } finally {
      this.model.loading = false;
    }
  };

  @action
  private updateVatNumber = async () => {
    resetFormErrors(this.model.vatNumberForm);

    const { value } = this.model.vatNumberForm.fields.vatNumber;

    if ((value && value.length < 2) || value.length > 24) {
      this.model.vatNumberForm.fields.vatNumber.error = 'Must be between 2 and 24 characters';
      return false;
    }

    try {
      this.model.loading = true;

      await this.api.user.setVatNumber(value);
      await this.auth.getSession();
      this.model.vatNumber = value;
      return true;
    } catch (e) {
      this.model.vatNumberForm.fields.vatNumber.error = (e as HttpError).body.error;
      return false;
    } finally {
      this.model.loading = false;
    }
  };
}
