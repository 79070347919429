import * as React from 'react';
import { BasePanel, BasePanelProps } from './BasePanel';
import { classes, style } from 'typestyle';
import { px } from 'csx';

const styles = style({
  $nest: {
    '& iframe': {
      height: px(288),
    },
  },
});

export type YouTubePanelProps = BasePanelProps & {
  videoId: string;
  className?: string;
  basePanelClassName?: string;
};

export const YouTubePanel: React.SFC<YouTubePanelProps> = ({
  children,
  videoId,
  className,
  basePanelClassName,
  ...props
}) => (
  <BasePanel {...props} className={basePanelClassName}>
    <div className={classes(styles, className)}>
      <iframe
        width="100%"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        loading="lazy"
        frameBorder={0}
      ></iframe>
    </div>
  </BasePanel>
);
