import * as React from 'react';
import {observer} from 'mobx-react';
import {percent, rem} from 'csx';
import { colorGunmetal, colorWhite } from '../theme/color';
import {gradientHero} from '../theme/gradient';
import {classes, style} from 'typestyle';
import {centerJustified, flex, start, vertical} from 'csstips';
import {mediaMobileOnly, mediaTablet} from '../theme/media';
import {ResponsiveImage} from './responsive-image';
import {Component, deps, inject} from '../lib/component';
import {ImagePreloader} from '../types/fn';

export type Props = {
  loading?: boolean;
  hideMobile?: boolean;
  coverImage?: string;
  preload?: ImagePreloader;
  dangerouslySetInnerHTML?: { __html: any }
}

@inject(deps)
@observer
export class PageHeader extends Component<Props> {
  render() {
    const { hideMobile, coverImage, preload, children, dangerouslySetInnerHTML } = this.props;
    const { preloadCover } = this.props.controller.image;

    return (
      <ResponsiveImage
        identity={coverImage}
        wrapBackground={PageHeader.containerImage}
        asBackground={true}
        preload={preload || preloadCover}
        className={classes(PageHeader.styles.container, hideMobile && PageHeader.styles.hideMobile)}
        children={children}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      />
    );
  }

  static containerImage = (url: string) => url;

  static styles = {
    container: style(
      {
        ...flex,
        ...start,
        ...centerJustified,
        ...vertical,
        position: 'relative',
        width: percent(100),
        minHeight: rem(20),
        padding: rem(1),
        backgroundColor: colorGunmetal.toString(),
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        $nest: {
          '& h1': {
            color: colorWhite.toString(),
            width: percent(100),
            maxWidth: rem(60),
            margin: 0,
            fontSize: rem(2.125),
          },
          '& p': {
            color: colorWhite.toString(),
            width: percent(100),
            maxWidth: rem(40),
            margin: '1rem 0 0 0',
            fontSize: rem(1.125),
            fontWeight: 200,
          },
        },
      },
      mediaTablet({
        padding: `5rem`,
        $nest: {
          '& h1': {
            fontSize: rem(3.75),
          },
          '& p': {
            fontSize: rem(1.375),
          },
        },
      })
    ),
    hideMobile: style(
      mediaMobileOnly({
        display: 'none',
      })
    ),
  };
}