import * as React from 'react';
import range from 'lodash/range';
import { FormattedMessage } from 'react-intl';
import { RouterState } from 'react-router';
import { Location } from 'history';
import { ChildRouterSpec } from '../routes';
import createFragment from 'react-addons-create-fragment';
import {
  ArtistSchema,
  ChannelSchema,
  ChannelStatisticsSchema,
  OrderAmountSchema,
  RatecardSchema,
  RatecardStatisticsSchema,
  TrackSchema,
} from '../types/schema';
import { User } from '../types/user';
import { SearchFilter, SearchFilterDiff, SearchFilterDiffMap } from '../types';
import { StorageModel } from '../modules/storage/model';
import { ClientModel } from '../modules/client/model';

export const noop = (): void => void 0;

export const formatNameList = (items: Array<{ name: string }>) => {
  return [
    ...items.slice(0, items.length - 2).map((a) => a.name),
    items
      .slice(-2, items.length)
      .map((a) => a.name)
      .join(' and '),
  ].join(', ');
};

export const formatDuration = (duration: number) => {
  const minutes = Math.floor(duration / 1000 / 60);
  const seconds = Math.floor(duration / 1000) % 60;

  return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
};

export const getArtistPhotoByType = (artist: ArtistSchema, type: string) => {
  if (!artist || !artist.images) return;
  return artist.images.identity;
};

export const getFeaturedArtistPhoto = (artist: ArtistSchema) => {
  return getArtistPhotoByType(artist, 'featured');
};

export const getFeaturedArtistPhotoUrl = (artist: ArtistSchema) => {
  const url = getFeaturedArtistPhoto(artist);
  return url ? `url(${url})` : void 0;
};

export const getReleaseImage = (track: TrackSchema) => {
  try {
    return `url(${track.release.images.identity})`;
  } catch (e) {
    return void 0;
  }
};

export const getYoutubeAvatar = (user: User, size = 50) => {
  try {
    return user.avatar.replace(/sz=[\d]+$/, 'sz=' + size);
  } catch (e) {
    return;
  }
};

const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const getDisplayName = (user: User) => {
  if (user.nickname) return user.nickname;
  if (emailRe.test(user.name)) return user.name.split('@')[0];
  if (user.name) return user.name;
  return <FormattedMessage id="user.no_name" />;
};

export const paginationRange = (current: number, total: number, limit = 0) => {
  if (limit === 0) return [];
  const mid = Math.ceil(limit / 2);

  switch (true) {
    case total <= limit:
      return range(1, total + 1);

    case current <= mid:
      return range(1, limit + 1);

    case current >= total - mid:
      return range(total - limit + 1, total + 1);

    case current > mid || current < total - mid:
      return range(current - mid + 1, current + mid);
  }
};

export const getAmountValues = (amount: OrderAmountSchema) => {
  const card_fee = Number(amount.card_fee) || 0;
  const discount = Number(amount.discount) || 0;
  const vat = Number(amount.vat.amount) || 0;
  const total = Number(amount.total) || 0;
  const subtotal = total - vat - discount - card_fee;

  return { card_fee, discount, vat, total, subtotal };
};

// http://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
export const stringToHex = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const diffSearchFilter = (current: Array<SearchFilter>, next: Array<SearchFilter>): SearchFilterDiff => {
  const _current = flattenFilters(current);
  const _next = flattenFilters(next);

  const add = Object.keys(_next).reduce((res, attributeName) => {
    if (!_current[attributeName]) return { ...res, [attributeName]: _next[attributeName] };
    const cr = _current[attributeName];
    const nr = _next[attributeName];
    return {
      ...res,
      [attributeName]: (res[attributeName] || []).concat(nr.filter((r: string) => cr.indexOf(r) === -1)),
    };
  }, {} as SearchFilterDiffMap);

  const remove = Object.keys(_current).reduce((res, attributeName) => {
    if (!_next[attributeName]) return { ...res, [attributeName]: _current[attributeName] };
    const cr = _current[attributeName];
    const nr = _next[attributeName];
    return {
      ...res,
      [attributeName]: (res[attributeName] || []).concat(cr.filter((r: string) => nr.indexOf(r) === -1)),
    };
  }, {} as SearchFilterDiffMap);

  return { add, remove };
};

const flattenFilters = (filters: Array<SearchFilter>) => {
  return filters
    .filter((f) => f.attributeName !== 'artists.name')
    .reduce((res, f) => ({ ...res, [f.attributeName]: f.currentRefinement }), {} as SearchFilterDiffMap);
};

export const matchingAsyncRoute = (url: string) => (route: ChildRouterSpec) => {
  if (route.path == '/**') return false;
  const re = pathMatcher(route.path);
  return re.test(url) && !!route.onEnter;
};

export const pathMatcher = (path: string) => {
  const re = path
    .replace(/:[^/()]+/g, '([^/]+)')
    .replace('(/', '/?')
    .replace(/\)\)/g, ')?');

  return new RegExp(re);
};

export const getNamedParameterKeys = (path: string) => {
  const matcher = new RegExp('(?:[^:]+)(?::)([^/()]+)', 'g');

  let match;
  const keys = [];
  while ((match = matcher.exec(path))) keys.push(match[1]);

  return keys;
};

export const prepareRatecardStatistics = (statistics: RatecardStatisticsSchema) => {
  return {
    start_period: new Date(statistics.period.starts.timestamp * 1000),
    end_period: new Date(statistics.period.ends.timestamp * 1000),
  };
};

export const prepareStatistics = (statistics: ChannelStatisticsSchema) => {
  return {
    ...statistics,
    fetched_at: new Date(statistics.fetched_at.timestamp * 1000),
  };
};

export const getChannelImage = (channel: ChannelSchema) => {
  try {
    return `url(${channel.images.find((image) => image.size === 'high').url}`;
  } catch (e) {
    // Might not be the correct data structure, continue
  }
  try {
    return `url(${channel.images[0].url}`;
  } catch (e) {
    return void 0;
  }
};

export const replaceValues = (input: string, values: { [key: string]: string }) => {
  return Object.keys(values).reduce((out, key) => out.replace(new RegExp(`{{${key}}}`, 'g'), values[key]), input);
};

export const replaceComponents = (input: string, values: { [key: string]: any }) => {
  return createFragment(
    input.split(/{{([^}]+)}}/g).reduce((fragment, key, index) => {
      switch (true) {
        case index % 2 === 0:
          return {
            ...fragment,
            [`text_${index}`]: key,
          };

        default:
          /* eslint-disable no-case-declarations */
          const replacement = typeof values[key] === 'undefined' ? `{{${key}}}` : values[key];

          const j = Object.keys(fragment).filter((f) => f.endsWith(key)).length;
          /* eslint-enable no-case-declarations */

          return {
            ...fragment,
            [`${j}_${key}`]: replacement,
          };
      }
    }, {})
  );
};

export const getCookieValue = (key: string) => {
  const cookie = document.cookie
    .split(';')
    .map((part) => part.trim().split('='))
    .find((part) => part[0] === key);

  return cookie ? cookie[1] : null;
};

export const getTrackPrice = (track: TrackSchema, standardRatecard: RatecardSchema) => {
  if (track.is_stock_music) return 0;
  return standardRatecard.rate || parseFloat(standardRatecard.value);
};

export const reduceTracksPrice = (tracks: Array<TrackSchema>, standardRatecard: RatecardSchema) => {
  return tracks.reduce((total, track) => {
    return total + getTrackPrice(track, standardRatecard);
  }, 0);
};

export const getQueryStringValue = (queryString: string, key: string, defaultValue: any = void 0) =>
  queryString
    .split('&')
    .reduce((pairs, pair) => [...pairs, pair.split('=')], [])
    .reduce((value, [k, v]) => (k === key ? v : value), defaultValue);

export function formatBpm(value: number) {
  return `${value} BPM`;
}

export function getAliasedThemePlaylist(themeSlug: string) {
  if (themeSlug === 'vlogs') {
    return themeSlug; // vlogs is not really a theme, it's a playlist acting as psuedo theme
  }

  return `hookd-youtube-${themeSlug}`;
}

export function abbreviateAmount(amount) {
  const SI_SYMBOL = ['', 'k', 'M', 'B', 'T'];
  const tier = (Math.log10(amount) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return amount;

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = amount / scale;

  // format number to 1 decimal place max and add suffix
  return Math.round(scaled * 10) / 10 + suffix;
}

export function debounce(callback, time) {
  let interval;
  return (...args) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;
      callback(...args);
    }, time);
  };
}

export async function getCountryCode(model: ClientModel) {
  const existingUser = model.user.user;

  if (existingUser) {
    return existingUser.country;
  }

  if (window.sessionStorage.getItem('country')) {
    return window.sessionStorage.getItem('country');
  }

  const response = await fetch(model.env.apiUri + '/ip', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'User-Agent': model.env.userAgent,
    },
    mode: 'cors',
  });

  if (response.status == 200) {
    const data = await response.json();
    window.sessionStorage.setItem('country', data.iso_code);
    return data.iso_code;
  }

  return 'US';
}

type WrappedComponent<T> = { wrappedInstance?: T };
export function unwrapMobxComponent<T>(obj: T): T | null {
  if (obj === null) return obj;
  if (!('wrappedInstance' in obj)) return obj;
  const wrappedRef = obj as WrappedComponent<T>;
  if (typeof wrappedRef.wrappedInstance === 'object') return wrappedRef.wrappedInstance;
  return null;
}
