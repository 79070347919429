import * as React from 'react';
import gql from 'graphql-tag';
import {graphql, InjectedGraphQLProps} from 'react-apollo';
import {Component, deps, inject, observer} from '../../lib/component';
import {style} from 'typestyle';
import {rem} from 'csx';
import {colorSubtle} from '../../theme/color';

type CombinedProps = Props & InjectedGraphQLProps<Data>;

@inject(deps) @observer
class TrackPLine extends Component<CombinedProps> {
  render() {
    const {data} = this.props;

    if (!data.getTrack || !data.getTrack.p_line)
      return null;

    return (
      <div>{data.getTrack.p_line.indexOf(TrackPLine.symbol) !== -1 ? '' : TrackPLine.symbol + ' '}{data.getTrack.p_line}</div>
    )
  }

  static symbol = '\u2117';

  static styles = {
    container: style({
      zIndex: 1,
      background: colorSubtle.fade(0.075).toString(),
      padding: rem(1),
      borderRadius: rem(1),
      $nest: {
        '& h3': {
          margin: '1.5rem 0 1rem'
        },
        '& h3:first-child': {
          margin: '0 0 1rem 0'
        }
      }
    })
  };
}

type Props = {
  identity: string;
}

type Data = {
  getTrack: {
    p_line: string | null
  }
}

export default TrackPLine;

export const ConnectedTrackPLine = graphql(gql`
  query (
  $identity: ID!
  ) {
    getTrack(options:{identity:$identity}) {
      p_line
    }
  }
`, {
  options: (variables: Props) => ({
    variables,
    // fetchPolicy: 'network-only'
  }),
})(TrackPLine);