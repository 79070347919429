import { TermsAndConditionsPageModel } from './model';
import { action } from 'mobx';
import { RedirectFunction, RouterState } from 'react-router';
import { PrismicController } from '../../prismic/controller';
import { BugsnagController } from '../../bugsnag/controller';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import Prismic from 'prismic-javascript';

export class TermsAndConditionsPageController {
  constructor(
    private model: TermsAndConditionsPageModel,
    private prismic: PrismicController,
    private bugsnag: BugsnagController,
    private env: EnvModel,
    private ui: UIController
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    this.model.loading = true;

    this.ui.setBreadcrumbs([
      {
        path: '/terms-and-conditions',
        label: 'Terms & conditions',
      },
    ]);

    await this.ui.setSEO(nextState.location.pathname);

    try {
      const predicates = [
        Prismic.Predicates.at('document.type', 'terms__conditions'),
        Prismic.Predicates.at(`my.terms__conditions.uid`, 'terms-and-conditions'),
      ];

      const [{ results }] = await Promise.all([this.prismic.query(predicates), this.env.ready]);

      if (results.length === 0) {
        // The document doesn't exist
        return replace({ pathname: '/' });
      }

      const [{ data }] = results;

      // RichText
      this.model.page_title = data.page_title;
      this.model.page_body = data.page_body;

      // Image
      this.model.cover_image = data.cover_image.url;
    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      this.model.loading = false;
    }

    return;
  };
}
