import { observable, computed, ObservableMap } from 'mobx';
import { TrackSchema } from '../../../types/schema';
import { CatalogueDataArray, PrismicSlice, SlicePageContext } from '../../../components/project-happy/utilities/types';

export class HomePageModel {
  @observable slices: PrismicSlice[] = [];
  @observable carousels: ObservableMap<string, CatalogueDataArray>;
  @observable tracks: ObservableMap<string, TrackSchema>;
  @observable loadingCarouselData = false;

  @computed get sliceContext(): SlicePageContext {
    return {
      playlists: this.carousels,
      tracks: this.tracks,
      carousels: { forceIndicatorPosition: 'centre' },
      externalDataLoading: this.loadingCarouselData,
    };
  }
}
