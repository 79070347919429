import { isEmpty } from '../utilities/objects';
import * as React from 'react';
import { PrismicBackgroundColourProps, PrismicImage } from '../utilities/types';
import { PartnerBadgeSlice } from '../organisms/slices/PartnerBadgeSlice';

export type PrismicPartnerBadgePayload = PrismicBackgroundColourProps & {
  primary: {
    partner_logo: PrismicImage;
  };
};
export type PrismicPartnerBadgeProps = {
  payload: PrismicPartnerBadgePayload;
};
export const PrismicPartnerBadge: React.SFC<PrismicPartnerBadgeProps> = ({
  payload: {
    primary: { partner_logo, background_colour },
  },
}) => (
  <PartnerBadgeSlice partnerLogo={!isEmpty(partner_logo) && partner_logo.url} backgroundColour={background_colour} />
);
