import * as React from 'react';
import { classes, style } from 'typestyle';
import { BaseButton, BaseButtonProps } from './BaseButton';
import {
  currentButton,
  buttonProminence,
  ButtonProminence,
  buttonSize,
  ButtonSize,
  childHoverStates,
} from './buttonStyles';

const sizeStyles = {
  [ButtonSize.XSMALL]: style(buttonSize[ButtonSize.XSMALL]),
  [ButtonSize.SMALL]: style(buttonSize[ButtonSize.SMALL]),
  [ButtonSize.MEDIUM]: style(buttonSize[ButtonSize.MEDIUM]),
  [ButtonSize.LARGE]: style(buttonSize[ButtonSize.LARGE]),
  [ButtonSize.VARIABLE]: style(buttonSize[ButtonSize.VARIABLE]),
};

const prominenceStyles: { [key in ButtonProminence]: string } = {
  [ButtonProminence.NORMAL]: style(buttonProminence[ButtonProminence.NORMAL]),
  [ButtonProminence.PRIMARY]: style(buttonProminence[ButtonProminence.PRIMARY]),
  [ButtonProminence.SECONDARY]: style(buttonProminence[ButtonProminence.SECONDARY]),
  [ButtonProminence.OUTLINE_LIGHT]: style(buttonProminence[ButtonProminence.OUTLINE_LIGHT]),
  [ButtonProminence.HIGHLIGHT]: style(buttonProminence[ButtonProminence.HIGHLIGHT]),
  [ButtonProminence.OUTLINE]: style(buttonProminence[ButtonProminence.OUTLINE]),
  [ButtonProminence.INSET]: style(buttonProminence[ButtonProminence.INSET]),
  [ButtonProminence.NONE]: style(buttonProminence[ButtonProminence.NONE]),
  [ButtonProminence.DISABLED]: style(buttonProminence[ButtonProminence.DISABLED]),
};

const hoverStatesStyles: { [key in ButtonProminence]: string } = {
  [ButtonProminence.NORMAL]: style(childHoverStates[ButtonProminence.NORMAL]),
  [ButtonProminence.PRIMARY]: style(childHoverStates[ButtonProminence.PRIMARY]),
  [ButtonProminence.SECONDARY]: style(childHoverStates[ButtonProminence.SECONDARY]),
  [ButtonProminence.OUTLINE_LIGHT]: style(childHoverStates[ButtonProminence.OUTLINE_LIGHT]),
  [ButtonProminence.HIGHLIGHT]: style(childHoverStates[ButtonProminence.HIGHLIGHT]),
  [ButtonProminence.OUTLINE]: style(childHoverStates[ButtonProminence.OUTLINE]),
  [ButtonProminence.INSET]: style(childHoverStates[ButtonProminence.INSET]),
  [ButtonProminence.NONE]: style(childHoverStates[ButtonProminence.NONE]),
  [ButtonProminence.DISABLED]: style(childHoverStates[ButtonProminence.DISABLED]),
};

export interface ButtonProps extends BaseButtonProps {
  size?: ButtonSize;
  prominence?: ButtonProminence;
  loading?: boolean;
  hideHoverState?: boolean;
}

export const getButtonStyles = ({
  size = ButtonSize.VARIABLE,
  prominence = ButtonProminence.NORMAL,
  disabled,
  current,
  className,
  hideHoverState = true,
}: Pick<ButtonProps, 'size' | 'prominence' | 'disabled' | 'current' | 'className' | 'hideHoverState'>) => {
  const computedProminence =
    disabled !== true || prominence === ButtonProminence.NONE ? prominence : ButtonProminence.DISABLED;
  return classes(
    prominence !== ButtonProminence.NONE && sizeStyles[size],
    prominenceStyles[computedProminence],
    hideHoverState && hoverStatesStyles[computedProminence],
    current === true && currentButton,
    disabled === true && 'disabled',
    className
  );
};

export const Button: React.SFC<ButtonProps> = (props) => {
  // Pull the size, hideHoverState and prominence props out so they aren't passed to the BaseButton
  const { className, size, prominence, hideHoverState, ...remainingProps } = props;
  return <BaseButton className={getButtonStyles(props)} {...remainingProps} />;
};
