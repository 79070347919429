import * as React from 'react';
import { classes, style } from 'typestyle';
import { transitionQuickEase } from '../../../theme/transition';
import { ChevronDown } from './icons/ChevronDown';

const styles = style({
  transition: `transform ${transitionQuickEase}`,
  $nest: {
    '.open &': {
      transform: 'rotate3d(1, 0, 0, -180deg);',
    },
  },
});

export type CollapsibleIndicatorProps = {
  className?: string;
  colour?: string;
};

export const CollapsibleIndicator: React.SFC<CollapsibleIndicatorProps> = ({ className, colour }) => (
  <ChevronDown color={colour} className={classes(styles, className)} aria-hidden={true} />
);
