import { NestedCSSProperties } from 'typestyle/lib/types';
import { extend } from 'typestyle';

export const supportsSelector = (selector: string, fallback: string, ...styles: NestedCSSProperties[]) => ({
  $nest: {
    [`&${selector}`]: extend(...styles),
    [`@supports not selector(${selector})`]: extend({
      $nest: {
        [`&${fallback}`]: extend(...styles),
      },
    }),
  },
});

export const supports = (feature: string, ...styles: NestedCSSProperties[]) => ({
  $nest: {
    [`@supports ${feature}`]: extend(...styles),
  },
});

export const focusVisible = (...styles: NestedCSSProperties[]) =>
  supportsSelector(':focus-visible', ':focus', ...styles);
