import { observer } from 'mobx-react';
import * as React from 'react';
import { classes, style } from 'typestyle';
import { Component, deps, inject } from '../../../lib/component';
import { TrackSchema } from '../../../types/schema';
import PlayButton from './button/PlayButton';
import { colorDisabledImage } from '../../../theme/color';

const styles = {
  trackImage: style({
    height: '70px',
    width: '70px',
  }),
  trackImageSmall: style({
    height: '56px',
    width: '56px',
  }),
  playButton: style({
    position: 'absolute',
    top: '37px',
    left: '36px',
  }),
  playButtonSmall: style({
    position: 'absolute',
    top: '23px',
    left: '22px',
  }),
  playButtonMix: style({
    position: 'absolute',
    top: '12px',
    left: '12px',
  }),
  imageWithPlayButton: style({
    position: 'relative',
    display: 'flex',
  }),
  imgRadius: style({
    borderRadius: '4px',
  }),
  trackUnavailableimage: style({
    position: 'relative',
    display: 'flex',
    $nest: {
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: colorDisabledImage.toString(),
      },
    },
  }),
};
export interface ImageWithPlayBtnProps {
  track: TrackSchema;
  small?: boolean;
  playButtonClassName?: string;
  trackImageClassname?: string;
  hasImgRadius?: boolean;
}

@observer
@inject(deps)
export class ImageWithPlayBtn extends Component<ImageWithPlayBtnProps> {
  render() {
    const {
      track,
      small,
      trackImageClassname,
      playButtonClassName,
      hasImgRadius,
      model: {
        image: { cover },
      },
    } = this.props;
    const trackIsavailable = track.is_available_in_territory === null ? true : track.is_available_in_territory;

    return (
      <div
        className={classes(
          !trackIsavailable ? styles.trackUnavailableimage : styles.imageWithPlayButton,
          small ? styles.trackImageSmall : styles.trackImage,
          trackImageClassname
        )}
      >
        {track.images && <img src={cover(track.images.identity)} className={hasImgRadius ? styles.imgRadius : ''} />}
        {trackIsavailable && (
          <PlayButton
            className={classes(
              small ? styles.playButtonSmall : styles.playButton,
              !track.images ? styles.playButtonMix : '',
              playButtonClassName
            )}
            track={track}
          />
        )}
      </div>
    );
  }
}
