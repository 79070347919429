import { style } from 'typestyle';
import { centerCenter, content, scrollY, vertical } from 'csstips';
import { percent, rem, viewHeight, viewWidth } from 'csx';
import { colorBrand, colorGunmetal, colorWhite, rebrand } from '../theme/color';
import { transitionModal, transitionQuickEase } from '../theme/transition';
import { zModal } from '../theme/z';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { shadowNotification } from '../theme/shadow';

export const ModalContainerStyle: NestedCSSProperties = {
  ...centerCenter,
  zIndex: zModal,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: rebrand.contrast[50].fade(0.8).toString(),
  transition: `opacity ${transitionQuickEase}`,
};

export const ModalContentStyle: NestedCSSProperties = {
  maxWidth: `calc(100vw - 4rem)`,
  maxHeight: `calc(100vh - 4rem)`,
  ...scrollY,
};

export const ModalPadding: NestedCSSProperties = {
  padding: rem(2),
};

export const ModalChildStyle: NestedCSSProperties = {
  ...vertical,
  ...content,
  ...scrollY,
  ...ModalPadding,
  maxWidth: percent(100),
  maxHeight: percent(100),
  background: colorWhite.toString(),
  boxShadow: shadowNotification,
  borderTopLeftRadius: rem(1),
  borderTopRightRadius: rem(1),
  borderBottomLeftRadius: rem(1),
  borderBottomRightRadius: rem(1),
  margin: 0,
  padding: rem(2),
  $nest: {
    '&> h3': {
      color: colorBrand.toString(),
      fontSize: rem(1.75),
      margin: '0 0 1.75rem 0',
      width: percent(100),
    },
  },
};

// These styles have been split out from mvp/resources/assets/components/modal.tsx but many components may still import them from there
const styles = {
  container: style(ModalContainerStyle),
  content: style(ModalContentStyle),
  child: style(ModalChildStyle),
};

export default styles;
