import { action } from 'mobx';
import { API, APIResponse } from '../types';
import { ChannelSchema } from '../../../types/schema';
import { cacheExchange, Client, fetchExchange } from 'urql';
import { isEmpty } from '../../../components/project-happy/utilities/objects';

export class YouTubeAPIController extends API {
  private graphqlClient: Client = null;

  get client(): Client {
    if (this.graphqlClient === null) {
      this.graphqlClient = new Client({
        url: this.env.graphqlUri,
        exchanges: [cacheExchange, fetchExchange],
      });
    }
    return this.graphqlClient;
  }

  @action
  getChannel = async (channelIdentity: string): Promise<ChannelSchema> => {
    const { data, error } = await this.client
      .query(
        `
    query($channel: ID!) {
      channel: getYoutubeChannel(id: $channel) {
        id
        name
        images {
          size
          url
        }
      }
    }`,
        { channel: channelIdentity }
      )
      .toPromise();
    if (error) throw error;
    return data.channel;
  };

  @action
  searchChannels = async (query: string) => {
    const url = `/youtube/channels?q=${query}`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<Array<ChannelSchema>>;

    return json;
  };
}
