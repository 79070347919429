import { NestedCSSProperties } from 'typestyle/lib/types';
import { percent, px } from 'csx';
import { fontstackHeading, fontstackDefault, fontstackDisplay } from '../config';
import '../../../theme/fonts/flood/index';
import { extend } from 'typestyle';
import { loadingPulse } from '../../../theme/mixin';

export const headingStyles: NestedCSSProperties = {
  lineHeight: 'normal',
  letterSpacing: 0,
  fontWeight: 400,
};

export const defaultHeadingStyles: NestedCSSProperties = {
  ...headingStyles,
  fontFamily: fontstackHeading,
};

export const contentHeadingStyles: NestedCSSProperties = {
  ...headingStyles,
  fontFamily: fontstackDefault,
};

export const displayHeadingStyles: NestedCSSProperties = {
  ...headingStyles,
  fontFamily: fontstackDisplay,
};

export const heading1: NestedCSSProperties = {
  fontSize: px(160),
};

export const heading2: NestedCSSProperties = {
  fontSize: px(80),
};

export const heading3: NestedCSSProperties = {
  fontSize: px(56),
};

// This is currently only used on /sign-up and wasn't previously used when the heading order was setup
// TODO: Rework headings and Prismic documents to offer this heading
export const headingTitle: NestedCSSProperties = {
  fontSize: px(40),
};

export const heading4: NestedCSSProperties = {
  fontSize: px(32),
};

export const heading5: NestedCSSProperties = {
  // letterSpacing: px(-0.5),
  fontSize: px(24),
};

export const heading6: NestedCSSProperties = {
  fontSize: px(16),
};

export const applyHeadingStyles: NestedCSSProperties = {
  $nest: {
    '& :where(h1,.h1,h2,.h2,h3,.h3,h4,.h4)': defaultHeadingStyles,
    '& :where(h5,.h5,h6,.h6)': contentHeadingStyles,
  },
};

export const copyStyles: NestedCSSProperties = {
  $nest: {
    '& :where(h1,.h1,h2,.h2,h3,.h3,h4,.h4)': defaultHeadingStyles,
    '& :where(h5,.h5,h6,.h6)': contentHeadingStyles,
    '& h1, & .h1': heading1,
    '& h2, & .h2': heading2,
    '& h3, & .h3': heading3,
    '& h4, & .h4': heading4,
    '& h5, & .h5': heading5,
    '& :first-child': {
      marginTop: 0,
    },
    '& :last-child': {
      marginBottom: 0,
    },
    '& h1, & .h1, & h2, & .h2, & h3, & .h3, & h4, & .h4, & h5, & .h5, & h6, & .h6, & p': {
      marginTop: px(16),
      marginBottom: px(16),
    },
    '& li': {
      paddingLeft: px(23),
      textIndent: px(-23),
    },
  },
};

export const screenReaderOnly: NestedCSSProperties = {
  $nest: {
    '&:not(:focus):not(:active)': {
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
      height: px(1),
      overflow: 'hidden',
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: px(1),
    },
  },
};

export const loadingText: NestedCSSProperties = {
  position: 'relative',
  display: 'inline-block',
  $nest: {
    '&::after': extend(loadingPulse, {
      content: '""',
      width: percent(100),
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: px(4),
    }),
  },
};
