import React from 'react';
import { flexRoot } from 'csstips';
import { classes, style } from 'typestyle';
import { percent, px } from 'csx';
import { Component, deps, inject, observer } from '../../../lib/component';
import { fontstackDefault } from '../config';
import { rebrand } from '../../../theme/color';
import { transitionQuickEase } from '../../../theme/transition';
import { pace } from '../utilities/functions';
import { PlaylistSchema, TrackSchema } from '../../../types/schema';
import { AddCircle } from '../atoms/icons/AddCircle';
import { PlaylistAdded } from '../atoms/icons/PlaylistAdded';
import { PlaylistPlus } from '../atoms/icons/PlaylistPlus';
import { dropdownDialogAnimation } from '../../../theme/animation';
import { screenReaderOnly } from '../utilities/font';
import { Tooltip } from '../atoms/Tooltip';
import siteEvents, { SITE_EVENTS } from '../utilities/siteEvents';

const styles = {
  list: style({
    backgroundColor: rebrand.neutralOnDark['10'].toString(),
    color: rebrand.contrast['50'].toString(),
    padding: px(8),
    margin: 0,
    listStyle: 'none',
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
    $nest: {
      '&::-webkit-scrollbar': {
        $unique: true,
        width: px(4),
      },
      '&::-webkit-scrollbar-thumb': {
        $unique: true,
        background: rebrand.neutralOnLight['50'].toString(),
        cursor: 'pointer',
        borderRadius: px(8),
      },
    },
  }),
  animation: style({
    animation: `${dropdownDialogAnimation} .2s ease normal`,
  }),
  altText: style(screenReaderOnly),
  playlistTitle: style({
    width: percent(100),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'pre',
  }),
  tooltipClass: style({
    maxWidth: px(500),
  }),
  button: style(flexRoot, {
    fontFamily: fontstackDefault,
    color: rebrand.contrast['50'].toString(),
    background: 'none',
    border: 'none',
    borderRadius: px(4),
    padding: px(8),
    fontSize: px(16),
    lineHeight: 'normal',
    textAlign: 'left',
    maxWidth: px(225),
    width: percent(100),
    alignItems: 'center',
    marginBottom: px(2),

    transition: `background-color ${transitionQuickEase}`,
    $nest: {
      '&:hover, &:focus': {
        backgroundColor: rebrand.neutralOnDark['20'].toString(),
      },
    },
  }),
  createButton: style({
    backgroundColor: rebrand.neutralOnDark['30'].toString(),
    $nest: {
      '&:hover, &:focus': {
        backgroundColor: rebrand.neutralOnDark['40'].toString(),
      },
    },
  }),
  addCircle: style({
    marginRight: px(10),
  }),
  addedPlaylistButton: style({
    backgroundColor: rebrand.primary['100'].toString(),
    color: rebrand.contrast['10'].toString(),
    $nest: {
      '&:hover': {
        backgroundColor: `${rebrand.primary['50'].toString()}  !important`,
      },
    },
  }),
};
export type AddToPlaylistDialogProps = {
  track: Pick<TrackSchema, 'identity' | 'title'>;
  className?: string;
  onPlaylistSelection?(networkRequest: Promise<any>): void;
};

export type AddToPlaylistDialogState = {
  playlists: PlaylistSchema[];
  page: number;
  loading: boolean;
  error: string | null;
};

@inject(deps)
@observer
export class AddToPlaylistDialog extends Component<AddToPlaylistDialogProps, AddToPlaylistDialogState> {
  ref: HTMLUListElement = null;
  resizeHandler: () => void;

  static playlistCache: Map<string, PlaylistSchema[]> = new Map();

  constructor(props) {
    super(props);
    this.state = {
      playlists: AddToPlaylistDialog.playlistCache.get(this.props.track.identity) || [],
      page: 0,
      loading: false,
      error: null,
    };

    this.handleCreatePlaylist = this.handleCreatePlaylist.bind(this);
    this.resizeHandler = pace(this.infiniteLoadPlaylists.bind(this), 250);
  }

  componentDidMount() {
    this.ref.addEventListener('scroll', this.resizeHandler, false);
    if (this.state.playlists.length === 0) {
      this.loadPlaylists();
    }
  }

  componentWillUnmount() {
    this.ref.removeEventListener('scroll', this.resizeHandler);
  }

  async loadPlaylists() {
    this.setState({ loading: true });
    try {
      const { playlists } = this.state;
      const page = this.state.page + 1;
      const { data } = await this.props.controller.api.playlist.getUserPlaylists(
        this.props.model.user.user.identity,
        page,
        25,
        this.props.track.identity
      );
      playlists.push(...data);
      AddToPlaylistDialog.playlistCache.set(this.props.track.identity, playlists);
      this.setState({ playlists, page });
    } catch {
      this.setState({
        error: 'There was an error loading your playlists.',
      });
    }
    this.setState({ loading: false });
  }

  infiniteLoadPlaylists() {
    if (this.ref === null || this.props.model.user.loadingPlaylists === true) return;
    const scrollPosition = this.ref.scrollTop + this.ref.scrollHeight;
    if (this.ref.clientHeight - scrollPosition < 100) {
      this.loadPlaylists();
    }
  }

  handlePlaylistSelection(playlist: PlaylistSchema) {
    AddToPlaylistDialog.playlistCache.delete(this.props.track.identity);
    const networkRequest = this.props.controller.api.playlist.addTrackToUserPlaylist(
      this.props.model.user.user.identity,
      playlist.identity,
      this.props.track.identity
    );
    siteEvents.emit(SITE_EVENTS.USER_PLAYLIST_TRACK_ADD, {
      track: this.props.track.identity,
      playlist: playlist.identity,
    });
    const { onPlaylistSelection } = this.props;
    typeof onPlaylistSelection === 'function' && this.props.onPlaylistSelection(networkRequest);
    this.props.controller.analytics.sendMixpanel('User adds a track to playlist', {
      track_name: this.props.track.title,
      playlist_name: playlist.name,
    });
  }

  handleCreatePlaylist() {
    // Set the create playlist modal track ID and show the modal
    this.props.controller.modal.setCreatePlaylistModalTrackId(this.props.track.identity);
    this.props.controller.modal.showCreatePlaylistModal();

    // This is de-optimises the cache if the modal is closed without success, but it's safer than trying to check for success
    // We DON'T clear state as we rely on the dialogue being closed by onClickOutside in the parent
    AddToPlaylistDialog.playlistCache.clear();
  }

  render() {
    const { className } = this.props;
    const { playlists, loading, error } = this.state;

    if (playlists.length === 0 && loading) {
      return (
        <ul className={classes(styles.list, className)} ref={(ref) => (this.ref = ref)}>
          <li>Loading...</li>
        </ul>
      );
    } else if (error) {
      return (
        <ul className={classes(styles.list, className)} ref={(ref) => (this.ref = ref)}>
          <li>{error}</li>
        </ul>
      );
    }
    return (
      <ul
        className={classes(styles.list, className, styles.animation)}
        ref={(ref) => (this.ref = ref)}
        data-test-add-to-playlist-dialog
      >
        <li>
          <button
            data-test-add-to-playlist-new-playlist-row
            className={classes(styles.button, styles.createButton)}
            onClick={this.handleCreatePlaylist}
          >
            <AddCircle size={20} color="#262E3F" className={styles.addCircle} />
            Create new playlist
          </button>
        </li>
        {playlists.map((playlist) => {
          const trackExistsInPlaylist = playlist && playlist.track_exists;

          return (
            <li key={playlist.identity}>
              <Tooltip className={styles.tooltipClass} content={<span>{playlist.name} </span>}>
                <button
                  data-test-add-to-playlist-existing-playlist-row={trackExistsInPlaylist}
                  className={classes(styles.button, trackExistsInPlaylist && styles.addedPlaylistButton)}
                  onClick={() => this.handlePlaylistSelection(playlist)}
                >
                  {trackExistsInPlaylist ? (
                    <PlaylistAdded className={styles.addCircle} />
                  ) : (
                    <PlaylistPlus className={styles.addCircle} />
                  )}
                  <span className={styles.playlistTitle}>{playlist.name}</span>
                </button>
              </Tooltip>
            </li>
          );
        })}
      </ul>
    );
  }
}
