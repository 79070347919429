import * as React from 'react';
import { observer } from 'mobx-react';
import { ImageTile } from './image-tile';
import { ResponsiveImage, ResponsiveImageProps } from './responsive-image';


export interface ResponsiveImageTileProps extends ResponsiveImageProps {
  onClick?(event: React.MouseEvent<any>): void;
  ratio?: number;
}

@observer
export class ResponsiveImageTile extends React.Component<ResponsiveImageTileProps, any> {
  render() {
    return (
      <ResponsiveImage Element={ImageTile} {...this.props} />
    );
  }

  static i18n = {};
  static styles = {};
}