import * as React from 'react';
import { classes, style } from 'typestyle';
import { colorContrast } from '../theme/color';

export type HighlightGetParts = (input: string, match: string) => Array<any>;

export interface HighlightProps {
  className?: string;
  match?: string;
  innerHtml?: any;
  getParts?: HighlightGetParts;
}

export class Highlight extends React.Component<HighlightProps> {
  render() {
    const {
      match,
      innerHtml,
      children,
      className = Highlight.styles.defaultStyle,
      getParts = Highlight.getParts,
    } = this.props;

    const classNames = classes(Highlight.styles.container, className);

    if (innerHtml) {
      return <span className={classNames} dangerouslySetInnerHTML={{ __html: innerHtml }} />;
    }

    if (match && typeof children !== 'string') {
      console.warn('Highlight with match only works on string children. Got ' + typeof children);
      return <span>{children}</span>;
    }

    return <span className={classNames}>{getParts(children as string, match)}</span>;
  }

  static getParts: HighlightGetParts = (input: string, split?: string) => {
    if (!split) return [<span key={0}>{input}</span>];

    // new pre-escaping
    split = split.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    const re = new RegExp(split, 'ig');
    const matches = input.match(re);

    if (!matches) return [<span key={0}>{input}</span>];

    return input
      .split(re)
      .reduce(
        (parts, part, index) => [
          ...parts,
          <span key={2 * index}>{part}</span>,
          <em key={2 * index - 1}>{matches[index]}</em>,
        ],
        []
      )
      .slice(0, -1);
  };

  static styles = {
    container: style(),
    defaultStyle: style({
      $nest: {
        '& > em': {
          fontStyle: 'normal',
          fontWeight: 600,
          color: colorContrast.toString(),
        },
      },
    }),
  };
}
