import { ImageModel } from './model';
import { action } from 'mobx';
import { EnvModel } from '../env/model';
import {ImagePreloader, ImagePreloaderOptions} from '../../types/fn';
import loadingPlaceholder from '../../static/loading-image.svg';

export class ImageController {
  constructor(
    private model: ImageModel,
    private env: EnvModel
  ) {}

  @action
  private image = (src: string) => {
    if (this.env.isServer || !src) return src;

    const img = new Image();
    img.src = src;
    return src;
  };

  @action
  preloadRaw = (src: string, options?: ImagePreloaderOptions) => {
    if (options && options.placeholder)
      return loadingPlaceholder;

    return this.image(src)
  };

  @action
  preloadContent: ImagePreloader = (identity: string, options?: ImagePreloaderOptions) => {
    if (options && options.placeholder)
      return loadingPlaceholder;

    const src = options ? this.model.content(identity, options) : this.model.content(identity);

    return this.image(src);
  };

  @action
  preloadImage: ImagePreloader = (identity: string, options?: ImagePreloaderOptions) => {
    if (options && options.placeholder)
      return loadingPlaceholder;

    const src = options
      ? (options.type ? this.model.waveform(identity, options) : this.model.image(identity, options))
      : this.model.image(identity);

    return this.image(src);
  };

  @action
  preloadCover: ImagePreloader = (identity: string, options?: ImagePreloaderOptions) => {
    if (options && options.placeholder)
      return loadingPlaceholder;

    const src = options ? this.model.cover(identity, options) : this.model.cover(identity);
    return this.image(src);
  };

  @action
  preloadWaveformSvg: ImagePreloader = (identity: string, options?: ImagePreloaderOptions) => {
    if (options && options.placeholder)
      return loadingPlaceholder;

    return this.model.waveformSvg(identity);
  }
}