import React from 'react';
import { classes, style } from 'typestyle';
import { CtaButton } from './panels/atoms/CtaButton';
import { AddCircle } from '../atoms/icons/AddCircle';
import { Component } from '../../../lib/component';
import { OpenNewTab } from '../atoms/icons/OpenNewTab';
import { Button } from '../atoms/button/Button';
import { ButtonProminence } from '../atoms/button/buttonStyles';
import { important } from 'csx';

const styles = {
  button: style({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: important('4px 8px 4px 12px'),
    maxWidth: '183px',
  }),
  icon: style({
    marginLeft: '8px',
  }),
};
export type RedirectToYoubeProps = {
  className?: string;
  href: string;
};

export class RedirectToYoube extends Component<RedirectToYoubeProps> {
  render() {
    const { className, href } = this.props;

    return (
      <Button
        openInNewWindow
        onClick={() => true}
        data-test-redirect-to-youtube-button
        className={classes(styles.button, className)}
        prominence={ButtonProminence.PRIMARY}
        href={href}
      >
        View on Youtube <OpenNewTab className={styles.icon} />
      </Button>
    );
  }
}
