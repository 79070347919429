import React from 'react';
import { registrationProgressFullHeight } from '../organisms/registration/RegistrationProgress';
import { em, percent, px } from 'csx';
import { classes, style } from 'typestyle';
import { flexRoot } from 'csstips';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { SVGIcon } from '../../svg-icon';
import { colorBrand, colorGunmetal } from '../../../theme/color';
import { fontstackDisplay, headerFullHeight } from '../config';
import { headingTitle } from '../utilities/font';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { AnalyticsController } from '../../../modules/analytics/controller';

import { SIGN_UP_ROUTE } from '../../../constants';
import GoogleButton from '../../user/google-button';

const headerHeight = headerFullHeight + registrationProgressFullHeight;
const styles = {
  panels: style(
    {
      $nest: {
        '& > div': {
          width: percent(100),
          padding: px(16),
        },
      },
    },
    mediaUpToDesktop({
      $nest: {
        '& > div': {
          minHeight: `calc(100vh - ${headerHeight + 136}px)`,
        },
      },
    }),
    mediaDesktop(flexRoot, {
      $nest: {
        '& > div': {
          minHeight: `calc(100vh - ${headerHeight}px)`,
        },
      },
    })
  ),
  panelHeading: style(headingTitle, { marginBottom: px(16), textAlign: 'center' }),
  signUpHeading: style({ maxWidth: px(321), marginLeft: 'auto', marginRight: 'auto' }),
  signUpPanel: style({
    textAlign: 'center',
    marginTop: '50px',
    $nest: {
      '& a': {
        color: colorBrand.toString(),
        textDecoration: 'underline',
        $nest: {
          '&:hover, &:focus': {
            opacity: 0.8,
          },
        },
      },
      '& :where(h1, h2)': {
        fontFamily: fontstackDisplay,
      },
    },
  }),
  authority: style({
    marginTop: px(16),
    marginBottom: px(16),
  }),
  googleButton: style({
    fontSize: em(1.2),
    height: px(62),
    width: px(258),
    margin: 0,
    marginTop: px(32),
  }),
};

const sendClickLoginEvent = (analytics: AnalyticsController) =>
  analytics.sendMixpanel('User clicks sign up prompt', { path: '/log-in' });

export const LogIn = inject(deps)(
  observer(
    ({
      model: {
        auth: { youtubeLoginURL },
        subscription: {
          subscriptionPlansState,
          subscriptionPlan,
          trialLengthInDays,
          subscriptionUnitPrice,
          subscriptionDecimalPrice,
        },
        basket: { ratecard },
      },
      controller: { analytics },
    }: ComponentProps) => (
      <div>
        <div className={styles.panels}>
          <div className={styles.signUpPanel}>
            <h1 data-test-login-h1 className={classes(styles.panelHeading, styles.signUpHeading)}>
              Welcome back
            </h1>
            <h2 data-test-login-h2>Log in to your Lickd Account</h2>
            <GoogleButton className={styles.googleButton} href={youtubeLoginURL} text={'Log in with Google'} />
            <div className={styles.authority}>
              <SVGIcon.YouTubeCertified color={colorGunmetal.toHexString()} />
            </div>
            <p>
              Don&apos;t have an account{' '}
              <a
                style={{ color: colorGunmetal.toString() }}
                href={SIGN_UP_ROUTE}
                onClick={() => sendClickLoginEvent(analytics)}
              >
                sign up
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  )
);
