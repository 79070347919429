import { EnvModel } from '../../../modules/env/model';
import EventBus from './EventBus';

export enum SITE_EVENTS {
  SPAWN_MODAL = 'SPAWN_MODAL',
  SPAWN_NOTIFICATION = 'SPAWN_NOTIFICATION',
  REGISTRATION_TRANSITION = 'REGISTRATION_TRANSITION',
  REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS',
  SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS',
  PERSONALISATION_SUCCESS = 'PERSONALISATION_SUCCESS',
  ORDER_SUCCESS = 'ORDER_SUCCESS',
  FAVOURITE = 'FAVOURITE',
  UNFAVOURITE = 'UNFAVOURITE',
  USER_PLAYLIST_CREATE = 'USER_PLAYLIST_CREATE',
  USER_PLAYLIST_TRACK_ADD = 'USER_PLAYLIST_TRACK_ADD',
  USER_PLAYLIST_TRACK_REMOVE = 'USER_PLAYLIST_TRACK_REMOVE',
  ADD_TO_BASKET = 'ADD_TO_BASKET',
  REMOVE_FROM_BASKET = 'REMOVE_FROM_BASKET',
  TRACK_PLAY = 'TRACK_PLAY',
}

const allowedExternalEvents = [SITE_EVENTS.SPAWN_MODAL, SITE_EVENTS.SPAWN_NOTIFICATION];

let siteEvents = {
  emit: (event: SITE_EVENTS, payload) => void 0,
  addListener: (event, listener) => void 0,
  removeListener: (event, listener) => void 0,
} as InstanceType<typeof EventBus>;

if (!EnvModel.isServer) {
  const bus = new EventBus<SITE_EVENTS>();
  window['_lickd_events'] = {
    emit: (event, payload) => {
      if (!allowedExternalEvents.includes(event)) return;
      bus.emit(event, payload);
    },
    addListener: (event, listener) => bus.addListener(event, listener),
    removeListener: (event, listener) => bus.removeListener(event, listener),
  } as typeof bus;
  const listenerQueueKey = '_lickd_event_listener_queue';
  const emissionQueueKey = '_lickd_event_emission_queue';
  if (listenerQueueKey in window && Array.isArray(window[listenerQueueKey])) {
    const queuedListeners = window[listenerQueueKey];
    queuedListeners.forEach((listenerArgs) => {
      try {
        const [event, listener] = listenerArgs;
        bus.addListener(event, listener);
      } catch {
        console.error('An error occurred trying to add a listener to the site events');
      }
    });
  }
  if (emissionQueueKey in window && Array.isArray(window[emissionQueueKey])) {
    const queuedEmissions = window[emissionQueueKey];
    queuedEmissions.forEach((emissionArgs) => {
      try {
        const [event, payload] = emissionArgs;
        bus.emit(event, payload);
      } catch {
        console.error('An error occurred trying to emit a site event.');
      }
    });
  }

  siteEvents = bus;
}

export default siteEvents;
