import * as React from 'react';
import { MenuLink, MenuLinkProps } from '../atoms/MenuLink';
import { PrismicImage } from '../utilities/types';

export enum PrismicMenuLinkAudience {
  ALL = 'All users',
  LOGGED_IN = 'Logged in users',
  LOGGED_OUT = 'Logged out users',
}

export type PrismicMenuLinkFields = {
  link_icon?: PrismicImage;
  link_text: string;
  link_url: string;
  link_type: boolean;
  mobile_only?: boolean;
  audience?: PrismicMenuLinkAudience;
};

const getPrismicProps = ({ link_icon, link_text, link_url, link_type }: PrismicMenuLinkFields): MenuLinkProps => {
  const props: MenuLinkProps = { text: link_text, url: link_url, internal: link_type };
  if (link_icon) {
    props.icon = link_icon.url;
  }
  return props;
};

export type PrismicMenuLinkProps = {
  primary: PrismicMenuLinkFields;
};
export const PrismicMenuLink: React.FC<PrismicMenuLinkProps> = ({ primary }) => {
  return <MenuLink {...getPrismicProps(primary)} />;
};

export type PrismicMenuLinkComponentProps = Partial<MenuLinkProps> & {
  prismicFields: PrismicMenuLinkFields;
};
export const PrismicMenuLinkComponent: React.FC<PrismicMenuLinkComponentProps> = ({ prismicFields, ...props }) => {
  return <MenuLink {...getPrismicProps(prismicFields)} {...props} />;
};
