import { style } from 'typestyle';
import * as React from 'react';
import { ButtonPill, buttonPillHelpers } from './button-pill';
import { Modal } from './modal';
import { em, important, percent, px, viewWidth } from 'csx';
import { colorBrand, colorGunmetal, colorSubtle, colorWhite } from '../theme/color';
import { shadowSubtle } from '../theme/shadow';
import { zOverContent } from '../theme/z';
import { mediaTablet } from '../theme/media';
import ModalFlow from './modal-flow';
import ModalScreen from './modal-screen';
import { abbreviateAmount } from '../lib/helpers';
import { SUPPORT_HUB } from '../constants';

export default class LabelModal extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
    this.accept = this.accept.bind(this);
  }

  render() {
    const { ratecard } = this.props;
    const viralViews = ratecard ? (ratecard.viewership * 3 > 500000 ? ratecard.viewership * 3 : 500000) : 500000;

    const screens = [
      <ModalScreen key={0} handleClose={this.close}>
        <strong>What’s this about?</strong>
        <div className={style({ padding: '20px 0 20px 0' })}>
          There’s a rightsholder involved in releasing the claim for a track(s) in your basket (listed below); the
          claim(s) will be sorted within 4 hours on a working day and within 48 on a non-working day.
        </div>
        <strong>Tracks:</strong>
        <ul>
          {this.props.tracks
            .filter(function (track) {
              return track.reporting_party.slug == 'umg';
            })
            .map((track, key) => {
              const artists = track.artists
                .map(function (artist) {
                  return artist.name;
                })
                .join(', ');
              return (
                <li key={key}>
                  {track.title} - {artists}
                </li>
              );
            })}
        </ul>
      </ModalScreen>,
      <ModalScreen
        key={2}
        handleClose={this.close}
        buttons={
          <ButtonPill
            onClick={() => this.accept()}
            className={style(buttonPillHelpers.customColor(colorBrand, colorWhite, true))}
          >
            Accept and close
          </ButtonPill>
        }
      >
        <strong>What do I need to do?</strong>
        <ol className={style({ paddingLeft: px(20) })}>
          <li>Set each video’s privacy to ‘Unlisted’ until the claims are cleared.</li>
          <li>
            Send each video’s link and Lickd License ID via a <a href={SUPPORT_HUB}>support ticket</a>.
          </li>
          <li>
            Make sure you’re clued up on the license terms below – you’ll need to accept before you can continue the
            checkout process.
          </li>
        </ol>
        <ul className={style({ paddingLeft: px(20) })}>
          <li>
            You can only use this track in a video on a YouTube channel you’ve registered with Lickd, subject to the
            terms and restrictions set out in the license agreement.
          </li>
          <li>This track can’t be used in any ad, sponsored video, branded content or endorsement.</li>
          <li>This track can’t be used for a song or lyric video.</li>
          <li>You must include the correct attribution for this track in your video’s description.</li>
          <li>If you breach the license agreement your content could be taken down.</li>
          <li>
            If your video goes viral (video views total of over {abbreviateAmount(viralViews)}) in the first 30 days of
            your content going public, your video could be reclaimed by the rightsholder.
          </li>
        </ul>
      </ModalScreen>,
    ];
    return (
      <Modal show={this.props.show} onRequestClose={this.close}>
        <ModalFlow analytics={this.props.analytics}>{screens}</ModalFlow>
      </Modal>
    );
  }

  close = () => {
    this.props.analytics.sendMixpanel('User clicks close');
    this.props.onRequestClose();
  };

  accept = () => {
    this.props.analytics.sendMixpanel('User clicks accept + close');
    this.props.handleModalAccept();
  };

  static i18n = {
    close: 'OK',
  };

  static styles = {
    container: style({
      position: 'relative',
    }),
    text: style({
      textDecoration: 'underline dotted',
    }),
    tooltip: style({
      display: 'none',
      position: 'absolute',
      color: colorWhite.toString(),
      fontSize: px(14),
      lineHeight: em(1.4),
      background: colorGunmetal.darken(0.05).toString(),
      padding: '7px 10px',
      whiteSpace: 'pre',
      borderRadius: px(2.01),
      boxShadow: shadowSubtle,
      top: percent(100),
      maxWidth: viewWidth(100),
      zIndex: zOverContent,
    }),
    tooltipShow: style({
      display: important('block'),
    }),
    close: style(
      {
        color: colorSubtle.toString(),
        fontSize: px(12),
        whiteSpace: 'pre',
      },
      mediaTablet({ display: 'none' })
    ),
  };
}
