import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { px } from 'csx';
import { Strapline } from './atoms/Strapline';
import { StatItem, StatList } from './atoms/StatList';
import { FloatingYouTubeBadge } from './atoms/FloatingYouTubeBadge';
import { ReactNodeArray } from 'react';
import { CtaButton } from './atoms/CtaButton';
import { BasePanel, BasePanelProps } from './BasePanel';
import { isArray } from '../../utilities/objects';

const styles = {
  wrapper: style({
    $nest: {
      '& > *': {
        marginTop: px(8),
        marginBottom: px(8),
      },
    },
  }),
};

export type CreatorPanelStat = { figure: string; label: string };
export type CreatorPanelProps = React.Component['props'] & {
  className?: string;
  src?: string;
  gradient?: BasePanelProps['gradient'];
  strapline?: ReactNodeArray;
  statistics?: CreatorPanelStat[];
  channelBadge?: { channelImg: string; channelName: string; channelSubscribers: number; xPosition?: number };
  cta?: { href: string; text: string };
};

export const CreatorPanel: React.SFC<CreatorPanelProps> = ({
  children,
  className,
  src,
  gradient,
  strapline,
  statistics,
  channelBadge,
  cta,
}) => {
  const [firstChild, ...remainingChildren] = isArray(strapline) ? strapline : [];
  return (
    <BasePanel className={styles.wrapper} gradient={gradient} src={src}>
      {isArray(strapline) && (
        <Strapline>
          <span>{firstChild}</span>
          {remainingChildren}
        </Strapline>
      )}
      {isArray(statistics) && (
        <StatList>
          {statistics.map((statistic, index) => (
            <StatItem key={index} {...statistic} />
          ))}
        </StatList>
      )}
      {channelBadge && (
        <FloatingYouTubeBadge
          src={channelBadge.channelImg}
          xPosition={channelBadge.xPosition}
          channelName={channelBadge.channelName}
          subscriberCount={channelBadge.channelSubscribers}
        />
      )}
      {cta && <CtaButton href={cta.href}>{cta.text}</CtaButton>}
    </BasePanel>
  );
};
