import { CSSProperties, NestedCSSProperties } from 'typestyle/lib/types';
import { colorBrand, colorLinkHover, rebrand } from './color';
import { hsl, percent, px } from 'csx';
import { extend } from 'typestyle';
import { animationGradientPulse } from './animation';

export const mixinBackgroundCover: CSSProperties = {
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

export const mixinPositionAbsoluteFill: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

export const mixinTextEllipsis: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const defaultLink: NestedCSSProperties = {
  color: colorBrand.toHexString(),
  textDecoration: 'underline',
  $nest: {
    '&:hover, &:focus': {
      color: colorLinkHover.toHexString(),
    },
  },
};

export const overlayLink: NestedCSSProperties = {
  position: 'static',
  $nest: {
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:before': {
      bottom: 0,
      content: `''`,
      left: 0,
      overflow: 'hidden',
      position: 'absolute',
      right: 0,
      top: 0,
      whiteSpace: 'nowrap',
      zIndex: 1,
    },
  },
};

const baseColour = rebrand.light1;
const contrastColour = hsl(baseColour.hue(), baseColour.saturation(), baseColour.lightness() - 0.05);
export const loadingPulse: NestedCSSProperties = extend(animationGradientPulse, {
  backgroundImage: `linear-gradient(to right, ${baseColour.toString()} 0%, ${contrastColour.toString()} 50%, ${baseColour.toString()} 100%)`,
  backgroundSize: percent(200),
});
