import { AccountAttributionPageModel } from './model';
import { RouterState, RedirectFunction } from 'react-router';
import { runInAction } from 'mobx';
import { APIController } from '../../api/controller';
import {UserModel} from "../../user/model";
import { BugsnagController } from '../../bugsnag/controller';
import { UIController } from '../../ui/controller';
import { EnvModel } from '../../env/model';
import {stripQueryString} from '../../../lib/string';

export class AccountAttributionPageController {
  constructor(
    private model: AccountAttributionPageModel,
    private user: UserModel,
    private api: APIController,
    private bugsnag: BugsnagController,
    private ui: UIController,
    private env: EnvModel
  ) {}

  onEnter = async(nextState: RouterState, replace: RedirectFunction) => {
    const identity = stripQueryString(nextState.params['identity']);

    if (!identity) return replace({
      pathname: '/account/licenses'
    });

    try {
      runInAction(() => {
        this.model.loading = true;
        this.model.identity = identity;
      });

      await this.ui.setSEO('/account/attribution', { identity });
      this.ui.setBreadcrumbs([]);

      const licence = await this.api.user.getLicence(this.user.user.identity, identity);

      runInAction(() => {
        this.model.licence = licence.data;
        this.model.referralUrl = this.env.trackReferralUri + '/' + licence.data.identity;
      });

    } catch (e) {
      this.bugsnag.notifyException(e);
    } finally {
      this.model.loading = false;
    }

    return;
  }
}