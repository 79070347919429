import React from 'react';
import { px, url } from 'csx';
import { classes, style } from 'typestyle';
import { ChannelSchema } from '../../../types/schema';
import { isEmpty } from '../utilities/objects';
import { PersonCircleFilled } from './icons/PersonCircleFilled';

const getAvatarDimensions = (size: number) => ({
  width: px(size),
  height: px(size),
  borderRadius: px(size),
});

const styles = style(getAvatarDimensions(24), {
  backgroundSize: 'cover',
  flexShrink: 0,
});

export interface ChannelAvatarProps {
  channel: ChannelSchema;
  size?: number;
  className?: string;
}

export const ChannelAvatar: React.FC<ChannelAvatarProps> = ({ channel, size = 24, className }) => {
  const avatarUrl = channel && channel.images && channel.images.length ? channel.images[0].url : null;

  if (!isEmpty(channel)) {
    const inlineStyles = { backgroundImage: url(avatarUrl), ...getAvatarDimensions(size) };
    return <div className={classes(styles, className)} style={inlineStyles} />;
  } else {
    return (
      <div className={classes(styles, className)} style={getAvatarDimensions(size)}>
        <PersonCircleFilled />
      </div>
    );
  }
};
