import * as React from 'react';
import { InputHTMLAttributes } from 'react';
import { classes, style } from 'typestyle';
import { AdditionalInputProps, commonInputStyles } from '../../utilities/inputs';
import { colorCrimson } from '../../../../theme/color';

const styles = style({
  color: colorCrimson.toHexString(),
});

export const RequiredIndicator: React.SFC = () => <span className={styles}>*</span>;
