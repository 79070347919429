import { observable, ObservableMap } from 'mobx';
import { TrackSchema, TrackVideoPlatforms } from '../../../types/schema';
import { YoutubeContentOwner, YoutubeVideoCategory } from '../../../types/graphql';

export interface TrackSchemaWithUsage extends TrackSchema {
  video_platforms?: TrackVideoPlatforms;
  youtube_video_categories?: YoutubeVideoCategory[];
  youtube_content_owners?: YoutubeContentOwner[];
}

export class CheckoutPageModel {
  @observable
  step = 0;

  @observable
  tracksWithUsage: ObservableMap<string, TrackSchemaWithUsage | Error>;
}

export type CheckoutPageField = 'name' | 'number' | 'exp' | 'cvc' | 'address_zip';
