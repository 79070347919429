import * as React from 'react';
import { percent, px } from 'csx';
import { classes, style } from 'typestyle';
import { Alternative, colorDisabledImage, colorSubtle, rebrand } from '../../../theme/color';
import { mixinTextEllipsis } from '../../../theme/mixin';
import dayjs from 'dayjs';
import { toUpperFirst } from '../utilities/string';
import { YouTubeVideo } from '../../../types/youtube';
import { shadowElevation6 } from '../../../theme/shadow';
import { borderStyle } from '../../checkout-v2/styles';
import { SemanticInformationType } from '../utilities/types';
import { RedirectToYoube } from './RedirectToYoube';
import { mediaUpToLargeMobile } from '../utilities/mediaQueries';
import YoutubeDefaultImg from '../../../static/YT-default-img.jpg';

const videoDetailsHeight = px(177);
const YOUTUBE_VIDEO = 'https://www.youtube.com/watch?v=';

const styles = {
  card: style(shadowElevation6, {
    borderRadius: '8px',
    width: percent(100),
    overflow: 'hidden',
  }),
  videoDetails: style(
    mediaUpToLargeMobile({
      flexDirection: 'column',
      height: 'auto',
    }),
    {
      display: 'flex',
      height: videoDetailsHeight,
      borderBottom: `1px solid ${rebrand.light1.toString()}`,
      $nest: {
        '&:last-child': {
          borderBottom: 0,
        },
      },
    }
  ),
  videoImageWrapper: style(
    mediaUpToLargeMobile({
      width: '100%',
      height: '150.86px',
    }),
    {
      height: '100%',
      position: 'relative',
      width: '50%',
      $nest: {
        '&:hover a': {
          opacity: 1,
        },
        '&:hover::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: rebrand.contrast[40].toHexString(),
          opacity: 0.7,
        },
      },
    }
  ),
  privateBg: style({
    $nest: {
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: rebrand.contrast[40].toHexString(),
        opacity: 0.7,
      },
    },
  }),
  videoImage: style({ width: '100%', height: '100%', objectFit: 'cover' }),
  youtubeButton: style({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    whiteSpace: 'nowrap',
    opacity: 0,
    transition: 'opacity 0.3s',
  }),
  privacyStatus: style({
    borderRadius: '4px',
    display: 'inline-block',
    padding: '4px 8px',
    fontSize: '14px',
  }),
  title: style({
    fontSize: '14px',
    marginTop: '8px',
  }),
  videoTitle: style({ fontWeight: 600 }),
  published: style({
    fontSize: '12px',
    color: colorSubtle.toHexString(),
    marginTop: '8px',
  }),
  channelTitle: style({ lineHeight: 1 }),
  textWrapper: style(
    mediaUpToLargeMobile({
      width: '100%',
    }),
    {
      margin: '16px',
      width: '50%',
    }
  ),
  private: style({
    background: rebrand.semantic[SemanticInformationType.ERROR]['20'].toString(),
  }),
  unlisted: style({
    background: rebrand.semantic[SemanticInformationType.WARNING]['20'].toString(),
  }),
  public: style({
    background: Alternative.BabyBlue[10].toString(),
  }),
};

type VideoCardProps = { className?: string; video: YouTubeVideo };
export const VideoCard: React.FC<VideoCardProps> = ({ video, className, children, ...props }) => {
  return (
    <div className={classes(styles.card, className)} data-test-video-account-page-video-card {...props}>
      <div className={styles.videoDetails}>
        <div className={classes(styles.videoImageWrapper, video.privacy_status === 'private' && styles.privateBg)}>
          <img
            className={styles.videoImage}
            src={
              video.privacy_status === 'private'
                ? YoutubeDefaultImg
                : `https://i.ytimg.com/vi/${video.id}/hqdefault.jpg`
            }
          />
          {video.privacy_status !== 'private' && (
            <RedirectToYoube href={`${YOUTUBE_VIDEO}${video.id}`} className={styles.youtubeButton} />
          )}
        </div>
        <div className={styles.textWrapper}>
          <div className={classes(styles.privacyStatus, styles[video.privacy_status])}>
            {toUpperFirst(video.privacy_status)}
          </div>
          <div data-test-video-card-title className={classes(styles.videoTitle, styles.title)}>
            {video.name}
          </div>
          <div className={classes(styles.title, styles.channelTitle)}>{video.channel.name}</div>
          <div className={styles.published}>
            <time dateTime={video.published_at.date} data-test-video-card-timestamp={video.published_at.date}>
              {toUpperFirst(dayjs(video.published_at.date).fromNow())}
            </time>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};
