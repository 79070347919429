import { computed, observable } from 'mobx';
import {CouponSchema} from '../../types/schema';

export class CouponModel {
  @observable
  loading: boolean = false;

  @observable
  currentChannel: string;

  @observable
  promiseMap: {[channelId: string]: Promise<CouponSchema>} = {};

  @computed
  get currentPromise(): Promise<CouponSchema> {
    return this.promiseMap[this.currentChannel] || Promise.resolve(null);
  }

  @observable
  code: string;

  @observable
  type: string;

  @observable
  discount: number;

  @observable
  track_discount: number;

  @observable
  value: number;

  @observable
  title: string;

  @observable
  message: string;
}