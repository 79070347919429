import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const QuestionCircle: React.SFC<SVGIconProps> = ({ size = 12, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 20 20" {...props}>
    <path
      fill={color}
      d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm1 17h-2v-2h2v2Zm2.07-7.75-.9.92A3.4 3.4 0 0 0 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41a2 2 0 0 0-2-2 2 2 0 0 0-2 2H8a4 4 0 1 1 8 0c0 .88-.36 1.68-.93 2.25Z"
      transform="translate(-2 -2)"
      fillRule="evenodd"
    />
  </BaseSvg>
);
