import * as React from 'react';
import { Router, RouterContext } from 'react-router';
import { ClientController } from './controller';
import { ClientModel } from './model';
import { Provider, observer } from 'mobx-react';
import { IntlProvider } from 'react-intl';
import { ApolloProvider } from 'react-apollo';
import { PlayerBar } from '../../components/player/player-bar';
import { ExternalPrismicContentIslands } from '../../components/project-happy/prismic-slices/ExternalPrismicContentIslands';
import { EnvModel } from '../env/model';
import { Notifications } from '../../components/project-happy/organisms/Notifications';

export interface ClientViewProps {
  model: ClientModel;
  controller: ClientController;
  routerProps: any;
  server?: boolean;
}

@observer
export class ClientView extends React.Component<ClientViewProps> {
  render() {
    const Handler = this.props.server ? RouterContext : Router;
    const { showPlayer } = this.props.model.ui;

    const isStatic =
      this.props.model.env.isServer && // Beware: the routerProps.routes array differs between server and client
      Array.isArray(this.props.routerProps.routes) &&
      this.props.routerProps.routes.some((route) => route.static === true);

    return (
      <IntlProvider locale={this.props.model.i18n.locale} messages={this.props.model.i18n.messages}>
        <Provider model={this.props.model} controller={this.props.controller}>
          <ApolloProvider client={this.props.model.graphql.client}>
            <span>
              <Handler {...this.props.routerProps} onUpdate={this.handleUpdate} />
              <Notifications />
              {!isStatic && <PlayerBar visible={showPlayer} />}
              {!isStatic && !EnvModel.isServer && <ExternalPrismicContentIslands />}
            </span>
          </ApolloProvider>
        </Provider>
      </IntlProvider>
    );
  }

  handleUpdate = () => {
    this.props.controller.bugsnag.refresh();
  };
}
