import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const ShoppingBasket: React.SFC<SVGIconProps> = ({ size = 22, color = 'currentColor', ...props }) => (
  <BaseSvg id="shopping-basket" width={size} height={size} viewBox="0 0 22 22" {...props}>
    <path
      fill={color}
      transform="translate(-1 -2)"
      fillRule="evenodd"
      d="M17.21 9l-4.38-6.56a.993.993 0 00-.83-.42c-.32 0-.64.14-.83.43L6.79 9H2c-.55 0-1 .45-1 1 0 .09.01.18.04.27l2.54 9.27c.23.84 1 1.46 1.92 1.46h13c.92 0 1.69-.62 1.93-1.46l2.54-9.27L23 10c0-.55-.45-1-1-1h-4.79zM9 9l3-4.4L15 9H9zm3 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
    />
  </BaseSvg>
);
