import * as React from 'react';
import { classes, style } from 'typestyle';
import { ModalProps } from '../../modal';
import { Component, deps, inject, observer } from '../../../lib/component';
import { center, content, flexRoot, vertical } from 'csstips';
import { rebrand } from '../../../theme/color';
import { mediaMobileOnly, mediaUpToLargeMobile } from '../utilities/mediaQueries';
import { ScrollableOverlay } from '../../scrollable-overlay/scrollable-overlay';
import { YouTubeVideo } from '../../../types/youtube';

import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { Loading } from '../../loading/loading';
import { centerCenter } from 'csstips/lib/flex';
import { em, percent, px } from 'csx';
import { ClaimIssueWithLicenceWithImages } from '../../../modules/api/user/controller';
import { LicenceSchema } from '../../../types/schema';
import { APIError } from '../../../modules/api/types';
import { LicenceRow } from '../molecules/LicenceRow';
import VideoClaimsStatus from '../molecules/VideoClaimsStatus';
import { handleClaimsCheck } from '../utilities/videoClaimCheck';
import { fontBebasNeue } from '../../../theme/font';
import { InfoFilled } from '../atoms/icons/InfoFilled';
import { claimStatusMap } from '../../../constants';

const styles = {
  modal: style(
    center,
    {
      padding: px(24),
    },
    mediaMobileOnly({
      height: '110%',
      borderRadius: '16px 16px 0 0',
    })
  ),
  title: style(content, {
    textAlign: 'center',
    margin: '0 0 24px 0px',
    textTransform: 'uppercase',
    fontFamily: fontBebasNeue,
  }),
  subTitle: style(content, {
    fontWeight: 700,
    fontSize: px(16),
    color: rebrand.dark1.toString(),
    marginBottom: '8px',
  }),
  footerSubTitle: style(content, {
    fontWeight: 700,
    fontSize: px(16),
    color: rebrand.dark1.toString(),
    margin: 0,
  }),
  body: style(flexRoot, centerCenter, vertical, content, {
    position: 'relative',
    borderLeft: 0,
    borderRight: 0,
    width: percent(100),
  }),
  footer: style(flexRoot, center, vertical, content, {
    backgroundColor: rebrand.neutralOnDark[10].toString(),
    padding: px(24),
    borderRadius: px(4),
    marginTop: px(24),
  }),
  borderLine: style({
    borderBottom: `1px solid ${rebrand.light1.toString()}`,
    width: '100%',
  }),
  text: style({
    fontSize: em(0.75),
    textAlign: 'center',
  }),
  labelsContainer: style({
    display: 'flex',
    textAlign: 'center',
    width: percent(100),
    $nest: {
      '&:not(:first-child)': {
        borderTop: `1px solid ${rebrand.light1.toString()}`,
        paddingTop: px(16),
      },
    },
  }),
  statusIndicator: style(mediaUpToLargeMobile({ minWidth: ' 104px' }), {
    marginLeft: 'auto',
  }),
  list_text: style({
    backgroundColor: rebrand.neutralOnDark[10].toHexString(),
    borderRadius: '4px',
    display: 'flex',
    padding: '4px 8px',
    fontSize: '12px',
    marginBottom: '24px',
  }),
  icon: style({
    marginRight: px(16),
    marginTop: px(4),
  }),
  headerClaimStatus: style({ width: '100%', marginBottom: '8px' }),
  description: style({
    textAlign: 'center',
    fontSize: px(12),
    color: rebrand.contrast[40].toString(),
  }),
  descriptionWrapper: style({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '24px',
  }),
  submitText: style({
    color: rebrand.contrast[40].toString(),
  }),
  submitButton: style({
    boxShadow: 'none',
    backgroundColor: rebrand.neutralOnDark[20].toString(),
    $nest: {
      '&:hover': {
        boxShadow: 'none',
      },
    },
  }),
  list: style({
    margin: '24px 0',
    width: '100%',
  }),
  licenceRow: style({
    marginBottom: px(8),
  }),
  hr: style({
    border: 'none',
    width: percent(100),
    minHeight: '1px',
    backgroundColor: rebrand.neutralOnDark[20].toString(),
  }),
};

export type VideoClaimStatusesModalProps = ModalProps & {
  video: YouTubeVideo | null;
  onRequestNewClaimIssue(): void;
};

type VideoClaimStatusesModalState = {
  claims: LicenceSchema[];
  claimIssues: ClaimIssueWithLicenceWithImages[];
  loading: boolean;
  error: React.ReactNode;
};

const claimIssueStateTitle = {
  created: 'In progress',
  in_progress: 'In progress',
  resolved: 'Support Request Resolved',
  closed: 'Request closed',
};

@inject(deps)
@observer
export class VideoClaimStatusesModal extends Component<VideoClaimStatusesModalProps, VideoClaimStatusesModalState> {
  state: VideoClaimStatusesModalState = {
    claims: [],
    claimIssues: [],
    loading: false,
    error: false,
  };

  componentDidUpdate(previousProps: VideoClaimStatusesModalProps): void {
    if (previousProps.show === false && this.props.show === true) this.loadVideoData();
  }

  async loadVideoData() {
    if (this.props.video === null) return;
    this.setState({ loading: true });
    try {
      const claimsPromise = Promise.all(
        this.props.video.licences.map(async (licence) => {
          const { data } = await this.props.controller.api.user.getLicence(
            this.props.model.user.user.identity,
            licence.identity
          );
          return data;
        })
      );
      const issuesPromise = this.props.controller.api.user.getClaimIssues(
        this.props.model.user.user.identity,
        this.props.model.user.channel.id,
        this.props.video.id
      );
      const [claims, { data: claimIssues }] = await Promise.all([claimsPromise, issuesPromise]);
      this.setState({ claims, claimIssues });
    } catch (error) {
      if (error instanceof APIError) {
        this.setState({
          error: error.reason,
        });
      } else {
        this.setState({
          error: 'An unknown error occurred loading your videos claim statuses.',
        });
      }
    }
    this.setState({ loading: false });
  }
  renderTopClaimStatus = () => {
    if (this.state.claims.length === 0) return claimStatusMap.NO_CLAIMS;
    const statuses = this.state.claims && this.state.claims.map((item) => handleClaimsCheck(item.youtube_video_claims));
    if (statuses.includes(claimStatusMap.PENDING)) return claimStatusMap.PENDING;
    if (statuses.every((status) => status === claimStatusMap.CLEARED)) return claimStatusMap.CLEARED;
    return claimStatusMap.NO_CLAIMS;
  };

  render() {
    const { show, onRequestClose, video } = this.props;

    if (this.state.loading || video === null) {
      return (
        <ScrollableOverlay className={styles.modal} show={show} onRequestClose={onRequestClose}>
          <h2 className={styles.title} data-test-video-claims-statuses-modal-title>
            Your Videos Claim Statuses
          </h2>

          <Loading />
        </ScrollableOverlay>
      );
    }

    const topClaimStatus = this.renderTopClaimStatus();
    return (
      <ScrollableOverlay className={styles.modal} show={show} onRequestClose={onRequestClose}>
        <h2 className={classes(styles.title)} data-test-video-claims-statuses-modal-one-title>
          Videos Claims Statuses
        </h2>
        <main className={styles.body}>
          <VideoClaimsStatus claim={topClaimStatus} className={styles.headerClaimStatus} />
          <div className={styles.list_text}>
            <span className={styles.icon}>
              <InfoFilled color={rebrand.contrast[50].toString()} />
            </span>
            <span>
              {topClaimStatus === claimStatusMap.PENDING ? (
                <span>
                  There are outstanding claims on this video, for details see the list below. Do not switch your video
                  to &ldquo;Public&rdquo; until all claims are cleared.
                </span>
              ) : (
                <span>
                  Although our system has not found any open claims on this video, you should always check YouTube
                  Studio before switching your video to &ldquo;Public&rdquo;. If you see any claims that haven&apos;t
                  shown up here, you can submit a support request below and we&apos;ll sort it for you.
                </span>
              )}
            </span>
          </div>
          {this.state.claims.length > 0 && (
            <>
              <div className={styles.descriptionWrapper}>
                <span className={styles.subTitle} data-test-video-claims-statuses-title>
                  Videos Claims
                </span>
                {topClaimStatus === claimStatusMap.PENDING && (
                  <span className={styles.description}>
                    These are claims that have been found by our system. Some claims can take up to 48 hours to clear.
                    We will notify you when all claims are cleared.
                  </span>
                )}
                {topClaimStatus === claimStatusMap.CLEARED && (
                  <span className={styles.description}>
                    These are claims that are found by our system. Some claims can take up to 48 hours to clear.
                  </span>
                )}
              </div>
              <div className={styles.list}>
                {this.state.claims.map((licence) => {
                  const youtubeVideoClaim = handleClaimsCheck(licence.youtube_video_claims, true);

                  return (
                    <LicenceRow licence={licence} key={licence.identity} className={styles.licenceRow}>
                      <VideoClaimsStatus claim={youtubeVideoClaim} className={styles.statusIndicator} />
                    </LicenceRow>
                  );
                })}
              </div>
            </>
          )}
        </main>
        <hr className={styles.hr} />
        <footer className={styles.footer}>
          <p className={styles.footerSubTitle}>Support requests</p>
          <p
            data-test-video-claims-statuses-modal-one-claim-submit-text
            className={classes(styles.text, styles.submitText)}
          >
            See a claim on your video in YouTube but it&apos;s not shown above? Here is where you can submit a support
            request so our team can look into the claim and resolve it.
          </p>
          {this.state.claimIssues.length > 0 && (
            <div className={styles.list}>
              {this.state.claimIssues.map(({ id, ticket_status, licence }, index) => (
                <LicenceRow
                  licence={licence}
                  key={id}
                  data-test-video-claims-statuses-modal-track-line={index}
                  className={styles.licenceRow}
                >
                  <VideoClaimsStatus claim={claimIssueStateTitle[ticket_status]} className={styles.statusIndicator} />
                </LicenceRow>
              ))}
            </div>
          )}
          <Button
            prominence={ButtonProminence.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={this.props.onRequestNewClaimIssue}
            data-test-video-claims-statuses-modal-one-submit-button
            className={styles.submitButton}
          >
            Submit a support request
          </Button>
        </footer>
      </ScrollableOverlay>
    );
  }
}
