import * as React from 'react';
import PrismicDOM from 'prismic-dom';
import { Helmet } from 'react-helmet';
import { Component, deps, inject, observer } from '../lib/component';
import { classes } from 'typestyle';
import MHeaderImage from '../static/M1-Hero-Image.jpg';
import MHeaderImageGradient from '../static/M1-Hero-Gradient.png';
import DHeaderImage from '../static/D1-Hero-Image.jpg';
import DHeaderImageGradient from '../static/D1-Hero-Gradient.png';
import DownArrow from '../static/expand_more.png';
import LickdLogo from '../static/lickd_logo.svg';
import { ButtonPill } from '../components/button-pill';
import { Head } from './head';
import { LoadingContainer } from '../components/loading-container';
import { AffiliatePageModel } from '../modules/page/affiliate/model';

import styles from '../styles/marketing-pages';

export interface AffiliatePageProps {
  model: AffiliatePageModel;
}

@inject(deps)
@observer
export class AffiliatePage extends Component<AffiliatePageProps> {
  constructor(props) {
    super(props);
  }

  render(): JSX.Element | false | null {
    const model = this.props.model.page.affiliate;
    const affiliateUrl = `/?show-sign-up&?utm_medium=${model.medium}&utm_source=${model.source}&utm_campaign=${model.campaign}`;

    return (
      <LoadingContainer loading={model.loading}>
        <div className={classes(styles.background)}>
          <Head />
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <div className={classes(styles.container)}>
            <div className={classes(styles.hero, 'with-gradient')}>
              <div className={classes(styles.logos)}>
                <img src={LickdLogo} />
                {model.logo && <img src={model.logo} />}
              </div>
              <div className={classes(styles.heroInner)}>
                <div className={classes(styles.heroInnerText)}>{model.headerText}</div>
                <ButtonPill href={affiliateUrl} primary={false}>
                  {model.ctaText}
                </ButtonPill>
              </div>
              <div className={classes(styles.readMore)}>
                <div>Read more</div>
                <img src={DownArrow} width="12" height="8" />
              </div>
            </div>
            <div className={styles.content}>
              <div className={classes(styles.contentMain)}>
                {model.sections.map((section) => (
                  <div key={section.heading}>
                    <h4>{section.heading}</h4>
                    <span dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(section.content) }} />
                  </div>
                ))}
              </div>
              <div className={classes(styles.downloadCta)}>
                <ButtonPill href={affiliateUrl} primary={false}>
                  {model.ctaText}
                </ButtonPill>
              </div>
              <h4>{model.featuredTitle}</h4>

              <div className={classes(styles.hottestTracks)}>
                {model.featured.map((feature) => (
                  <div className={classes(styles.artist)} key={feature.image}>
                    <a href={affiliateUrl}>
                      <div>
                        <img src={LickdLogo} />
                        <div
                          className={classes(styles.artistImage)}
                          style={{ backgroundImage: `url('${feature.image}')` }}
                        >
                          <div className={classes(styles.artistName)}>
                            <div>{feature.name}</div>
                            {feature.subtext}
                          </div>
                        </div>
                      </div>
                    </a>
                    {feature.description}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </LoadingContainer>
    );
  }
}
