import { AccountProfilePageModel } from './model';
import { RouterState, RedirectFunction } from 'react-router';
import { action, runInAction } from 'mobx';
import { APIController } from '../../api/controller';
import { UserModel } from '../../user/model';
import { UIController } from '../../ui/controller';
import { ContentModel } from '../../content/model';
import { EnvModel } from '../../env/model';
import { AuthController } from '../../auth/controller';
import { createDetailsForm, createMarketingPreferencesForm, resetFormErrors } from '../../../lib/form';
import { HttpError } from '../../../types';
import { FormDetailsFields } from '../../../components/form-details';
import { FieldValues } from '../../../types/form';
import { APIError, FormErrors } from '../../api/types';
import { isEmpty } from '../../../components/project-happy/utilities/objects';
import { UserUpdateableFields } from '../../../types/user';

export class AccountProfilePageController {
  constructor(
    private user: UserModel,
    private model: AccountProfilePageModel,
    private api: APIController,
    private ui: UIController,
    private content: ContentModel,
    private env: EnvModel,
    private auth: AuthController
  ) {}

  @action
  onEnter = async (nextState: RouterState) => {
    runInAction(() => {
      this.model.loading = true;
    });

    await this.ui.setSEO(nextState.location.pathname);

    this.ui.setBreadcrumbs([
      {
        path: '/account',
        label: 'Account',
      },
      {
        path: '/account/profile',
        label: 'Profile',
      },
    ]);

    runInAction(() => {
      this.model.loading = false;
    });

    return;
  };

  @action
  editDetails = async () => {
    await this.env.ready;

    const { user } = this.user;

    const values: FieldValues<FormDetailsFields> = {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      country: user.country,
      language: user.language,
      preferred_genre: user.preferred_genre,
      telephone: user.telephone,
      is_business: user.is_business ? 1 : 0,
      found_us: user.found_us || '',
    };

    runInAction(() => {
      this.model.modalDetails.form = createDetailsForm(
        values,
        this.content.genres,
        this.user.user && this.user.user.country.length === 2
      );
      this.model.modalDetails.show = true;
    });
  };

  @action
  changeDetailsField = (field: string, value: string) => {
    this.model.modalDetails.form.fields[field].value = value;
  };

  @action
  closeDetailsModal = () => {
    this.model.modalDetails.show = false;
  };

  @action
  submitDetailsModal = async () => {
    resetFormErrors(this.model.modalDetails.form);
    const { fields } = this.model.modalDetails.form;

    if (!fields.email.value || fields.email.value.includes('@pages.plusgoogle.com')) {
      return (fields.email.error = 'Please enter your email address');
    }

    if (!fields.first_name.value) {
      return (fields.first_name.error = 'Please enter your first name');
    }

    if (!fields.last_name.value) {
      return (fields.last_name.error = 'Please enter your last name');
    }

    if (!fields.country.value) {
      return (fields.country.error = 'Please select a country');
    }

    const values: UserUpdateableFields = {};

    for (const key in fields) {
      // Don't include country if the user model already has one set
      if (key === 'country' && this.user.user.country.length === 2) continue;
      // Cast the is_business value from a numerical string to a boolean value
      if (key === 'is_business') {
        values[key] = fields[key].value === '1';
        continue;
      }
      values[key] = fields[key].value;
    }

    try {
      this.model.modalDetails.form.saving = true;
      await this.api.user.setContactDetails(values);
      await this.auth.getSession();
      this.closeDetailsModal();
    } catch (e) {
      this.model.modalDetails.form.error = 'Oops! Something went wrong, please try again.';
      if (e instanceof APIError && !isEmpty(e.payload)) {
        const apiError = e as APIError<FormErrors>;
        this.model.modalDetails.form.error = apiError.payload;
      } else {
        // We don't know how to deal with this error; log it for now
        // TODO: Should probably log this in bugsnag
        console.log(e);
      }
    } finally {
      this.model.modalDetails.form.saving = false;
    }
  };

  @action
  editMarketing = async () => {
    await this.env.ready;

    const { user } = this.user;

    runInAction(() => {
      this.model.modalMarketing.form = createMarketingPreferencesForm(user.marketing_allowed);
      this.model.modalMarketing.show = true;
    });
  };

  @action
  changeMarketingField = (field: string, value: string) => {
    this.model.modalMarketing.form.fields[field].value = value;
  };

  @action
  closeMarketingModal = () => {
    this.model.modalMarketing.show = false;
  };

  @action
  submitMarketingModal = async () => {
    resetFormErrors(this.model.modalMarketing.form);

    try {
      this.model.modalMarketing.form.saving = true;
      const marketing_allowed = this.model.modalMarketing.form.fields.marketing_allowed.value;
      const promises: Array<Promise<any>> = [this.api.user.setMarketingPreferences(marketing_allowed)];

      if (!marketing_allowed) {
        promises.push(this.api.user.clearPreferences(this.user.user.identity));
      }

      await Promise.all(promises);
      await this.auth.getSession();
      this.closeMarketingModal();
    } catch (e) {
      try {
        this.model.modalMarketing.form.error = (e as HttpError).body.error;
      } catch (e) {
        console.log(e);
        this.model.modalMarketing.form.error = 'Oops! Something went wrong, please try again.';
      }
    } finally {
      this.model.modalMarketing.form.saving = false;
    }
  };
}
