import * as React from 'react';
import { ModalProps } from '../../modal';
import { ScrollableOverlay } from '../../scrollable-overlay/scrollable-overlay';
import { style } from 'typestyle';
import { em, percent, px, quote } from 'csx';
import { heading6 } from '../utilities/font';
import { Component, deps, inject, observer } from '../../../lib/component';
import { ChannelAvatar } from '../../channel-avatar';
import { ChannelSchema } from '../../../types/schema';
import GoogleButton from '../../user/google-button';
import { colorLightGrey, rebrand } from '../../../theme/color';
import { isEmpty } from '../utilities/objects';
import { flexRoot } from 'csstips';
import { centerCenter } from 'csstips/lib/flex';

const styles = {
  wrapper: style({ padding: px(16), width: percent(100), display: 'block' }),
  heading: style(heading6, { marginTop: 0, textAlign: 'center' }),
  fakeChannelSelect: style({
    position: 'relative',
    height: px(200),
    display: 'flex',
    alignItems: 'center',
    marginBottom: em(1),
    $nest: {
      '&::before': {
        content: quote('Choose your account or brand channel'),
        position: 'absolute',
        width: percent(40),
        height: percent(100),
        border: `${px(1)} solid ${colorLightGrey.toString()}`,
        marginLeft: percent(30),
        borderRadius: em(0.3),
        filter: 'blur(2px)',
        textAlign: 'center',
        padding: em(1),
      },
    },
  }),
  highlightedChannel: style({
    display: 'flex',
    alignItems: 'center',
    width: percent(75),
    margin: '0 auto',
    padding: '12px 0',
    borderTop: `${px(1)} solid ${colorLightGrey.toString()}`,
    borderBottom: `${px(1)} solid ${colorLightGrey.toString()}`,
    backgroundColor: 'white',
    zIndex: 1,
  }),
  channelAvatar: style({
    width: px(28),
    height: px(28),
    marginRight: px(12),
  }),
  channelInformation: style({
    fontFamily: 'Roboto, sans-serif',
    $nest: {
      '& p': {
        margin: 0,
      },
    },
  }),
  youtubeIndicator: style({
    fontSize: em(0.75),
    color: rebrand.light3.toString(),
  }),
  googleButton: style(flexRoot, centerCenter),
};

export type ReconnectChannelModalProps = ModalProps & { className?: string; channel: ChannelSchema };

@inject(deps)
@observer
export class ReconnectChannelModal extends Component<ReconnectChannelModalProps> {
  render() {
    const {
      props: {
        channel: rawChannel,
        model: {
          auth: { youtubeChannelLoginURL },
        },
        controller,
        ...props
      },
    } = this;

    const channel = rawChannel || ({} as ChannelSchema);

    return (
      <ScrollableOverlay className={styles.wrapper} {...props}>
        <h3 className={styles.heading}>Reconnect your channel</h3>
        <p>
          In order to retrieve your video information and clear any copyright claims, we need you to reconnect our
          access to your account.
        </p>
        <div className={styles.fakeChannelSelect}>
          <div className={styles.highlightedChannel}>
            <ChannelAvatar channel={channel} className={styles.channelAvatar} />
            <div className={styles.channelInformation}>
              <p>{channel.name}</p>
              <p className={styles.youtubeIndicator}>YouTube</p>
            </div>
          </div>
        </div>
        <p>
          When you click &ldquo;Connect with Google&rdquo; below, you&apos;ll be asked to sign in and select a YouTube
          channel. Make sure to select <strong>{channel.name}</strong> when prompted.
        </p>
        <div className={styles.googleButton}>
          <GoogleButton href={youtubeChannelLoginURL} text="Connect with Google" />
        </div>
      </ScrollableOverlay>
    );
  }
}
