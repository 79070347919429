import * as React from 'react';
import { PlaylistGroupPlaylistSchema, PlaylistSchema } from '../../../types/schema';
import { classes, style } from 'typestyle';
import { em, percent } from 'csx';
import { transitionQuickEase } from '../../../theme/transition';
import { shadowElevation2 } from '../../../theme/shadow';
import { CarouselImage } from './CarouselImage';

const styles = {
  imageContainer: style({
    width: percent(100),
    borderRadius: em(0.3),
    overflow: 'hidden',
    transition: `box-shadow ${transitionQuickEase}`,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    $nest: {
      ':hover > &': shadowElevation2,
      '& picture': {
        width: percent(100),
        borderRadius: 0,
      },
      '& picture:only-child': {
        gridArea: '1 / 1 / 3 / 3',
        $nest: {
          '& img': {
            borderRadius: em(0.3),
          },
        },
      },
    },
  }),
};

export type PlaylistThumbnailProps = {
  className?: string;
  playlist: PlaylistGroupPlaylistSchema | PlaylistSchema;
  isTopSolutionImage?: boolean;
};

export const PlaylistThumbnail: React.FC<PlaylistThumbnailProps> = ({
  playlist,
  className,
  isTopSolutionImage = false,
}) => {
  let images;
  const alt = `Artwork for "${playlist.name}" playlist`;
  if (playlist.use_playlist_image) {
    images = <CarouselImage alt={alt} identity={playlist.images.identity} isTopSolutionImage={isTopSolutionImage} />;
  } else {
    images =
      playlist.track_images &&
      playlist.track_images.map((image, index) => (
        <CarouselImage key={index} alt={alt} identity={image.identity} width={74} height={74} />
      ));
  }
  return <div className={classes(styles.imageContainer, className)}>{images}</div>;
};
