import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const ContactMail: React.SFC<SVGIconProps> = ({ size = 20, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 20 16" {...props}>
    <path
      fill={color}
      d="M19.5 8.667v-.834L17 9.5l-2.5-1.667v.834l2.5 1.666 2.5-1.666Zm.833-4.167H3.667C2.75 4.5 2 5.25 2 6.167v11.666c0 .917.75 1.667 1.667 1.667h16.666c.917 0 1.659-.75 1.659-1.667L22 6.167c0-.917-.75-1.667-1.667-1.667ZM8.667 7c1.383 0 2.5 1.117 2.5 2.5S10.05 12 8.667 12a2.497 2.497 0 0 1-2.5-2.5c0-1.383 1.116-2.5 2.5-2.5Zm5 10h-10v-.833c0-1.667 3.333-2.584 5-2.584 1.666 0 5 .917 5 2.584V17Zm6.666-5h-6.666V7h6.666v5Z"
      fillRule="evenodd"
      transform="translate(-2 -4)"
    />
  </BaseSvg>
);
