import { px, rem, viewWidth } from 'csx';

export const constantTabletBreakpoint = 720;
export const constantMobileBreakpoint = 719;
export const constantDesktopBreakpoint = 1280;

// Trackline
export const constantLargeDesktopScreensTrackLineBreakpoint = 1087;
export const constantDesktopScreensTrackLineBreakpoint = 946;
export const constantTabletLandscapeTrackLineBreakpoint = 894;
export const constantTabletPortraitTrackLineBreakpoint = 804;
export const constantLargeMobileScreensTrackLineBreakpoint = 696;
export const constantMediumMobileScreensTrackLineBreakpoint = 572;
export const constantSmallMobileScreeensTrackLineBreakpoint = 464;
export const constantExtraSmallMobileScreensTrackLineBreakpoint = 288;

export const constantPlayerHeight = px(70);
export const constantHeaderHeight = px(60);
export const constantSearchHeight = px(60);

export const constantBreadcrumbsHeight = px(40);
export const constantExpandedHeaderHeight = `calc(${constantHeaderHeight} + ${constantBreadcrumbsHeight})`;
export const constantNavLinkHeight = px(32);
export const constantPageHeaderHeight = px(320);

export const constantDrawerWidth = px(320);
export const constantDrawerHeaderHeight = px(240);

export const constantCreditCardRatio = 53.98 / 85.6;

export const constantGoldenRatio = 1.61803398875;

export const constantCarouselDuration = 6000;

export const constantSearchFiltersWidth = px(360);
export const constantSearchSortWidth = px(480 / 4);

export const constantMaxLandingPageWidth = rem(64);
export const constantStandardPadding = `1.25rem 1rem`;
export const constantMaxReadingWidth = rem(36);

export const constantTileCarouselMobileSize = viewWidth(75);
export const constantTileCarouselDesktopSize = viewWidth(20);
export const constantTileCarouselAvailabilitySize = px(50);
