import * as React from 'react';
import { Component, deps, inject, observer } from '../../../lib/component';
import { renderComponentsFromPrismicSlices } from './index';
import { Loading } from '../../loading/loading';
import { PrismicContentData } from '../../../modules/prismic-content-islands/model';

type PrismicContentIslandProps = {
  content: PrismicContentData;
};

@inject(deps)
@observer
export class PrismicContentIsland extends Component<PrismicContentIslandProps> {
  ref: React.RefObject<never> = React.createRef();

  componentDidMount() {
    this.props.controller.prismicContentIslands.fetchPrismicContent(this.ref, this.props.content);
  }

  render() {
    const { contentIslandData } = this.props.model.prismicContentIslands;
    if (this.ref === null || !contentIslandData.has(this.ref)) return <Loading />;
    const { slices, context } = contentIslandData.get(this.ref);
    return <>{renderComponentsFromPrismicSlices(slices, context)}</>;
  }
}
