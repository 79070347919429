import * as React from 'react';

export const preventAndCall = (handler: () => void) => (e: React.MouseEvent<any>) => {
  e.preventDefault();
  handler();
};

export const debounce = (handler: () => void, timeout = 250) => {
  let timeoutUid: NodeJS.Timer;
  return () => {
    clearInterval(timeoutUid);
    timeoutUid = setTimeout(handler, timeout);
  };
};

export const pace = (func: (...args: any[]) => void, pace: number) => {
  let timeSinceLastExecution = 0;
  let lastCallId: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    const now = Date.now();
    const diff = now - timeSinceLastExecution;
    clearTimeout(lastCallId);
    if (diff > pace) {
      func(...args);
      timeSinceLastExecution = now;
      return;
    }
    lastCallId = setTimeout(func, pace, ...args);
  };
};

// A shim for Promise.allSettled, no monkey patching to avoid TypeScript complaining
type PromiseAllSettledPayload = { status: 'fulfilled'; value: any } | { status: 'rejected'; reason: any };
export const promiseAllSettled = (promises: Array<Promise<any>>) =>
  (Promise.all(
    promises.map((p) =>
      p
        .then((value) => ({
          status: 'fulfilled',
          value,
        }))
        .catch((reason) => ({
          status: 'rejected',
          reason,
        }))
    )
  ) as any) as Promise<PromiseAllSettledPayload[]>;

export const supportsLineClamp = () => {
  const style = document.documentElement.style;
  return 'webkitLineClamp' in style || 'lineClamp' in style;
};
