import * as React from 'react';
import { observer } from 'mobx-react';
import { extend, style } from 'typestyle';
import { ScrollableOverlay, ScrollableOverlayProps } from '../../scrollable-overlay/scrollable-overlay';
import {
  ScrollableOverlayBodyStyle,
  ScrollableOverlayHeaderStyle,
  ScrollableOverlayTitleStyle,
} from '../../../styles/scrollable-overlay';
import { Loading } from '../../loading/loading';
import PrismicDOM from 'prismic-dom';
import { isObservableArray } from 'mobx';
import { Component, deps, inject } from '../../../lib/component';
import { TrackSchema } from '../../../types/schema';
import TrackInfo from './track-info';
import { getEulaType } from '../../project-happy/utilities/types';
import { contentHeadingStyles } from '../../project-happy/utilities/font';
import { colorGunmetal } from '../../../theme/color';

const styles = {
  header: style(ScrollableOverlayHeaderStyle, {
    padding: '1rem',
    borderBottom: '1px solid #E0E4E9',
  }),
  heading: style(ScrollableOverlayTitleStyle),
  body: style(ScrollableOverlayBodyStyle, {
    padding: '0px',
    fontSize: '12px',
    $nest: {
      '& h1, & h2, & h3, & h4, & h5, & h6': extend(contentHeadingStyles, {
        fontWeight: 600,
      }),
      '& h3': {
        fontSize: '16px',
      },
      '& h1, & h2, & h3, & h4, & h5, & h6, & p': {
        padding: '0rem 1rem',
      },
      '& a': {
        color: colorGunmetal.toHexString(),
        fontWeight: 'bold',
      },
    },
  }),
};

export interface EulaModalProps {
  show: boolean;
  title: string;
  track: TrackSchema;
  onRequestClose(): void;
  coupon?: any;
  i18n?: {
    title: string;
    strap: any;
    loading: string;
    not_available: string;
    close: string;
    first_discount: string;
    affiliate_discount: string;
  };
}

@inject(deps)
@observer
export class EulaModal extends Component<EulaModalProps> {
  state = {
    visible: false,
  };

  constructor(props) {
    super(props);
    this.setVisibility = this.setVisibility.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<EulaModalProps>, prevState: Readonly<any>, snapshot?: any) {
    const prev = prevProps.show;
    const now = this.props.show;
    if (prev === false && now === true) {
      this.setState({ wasShowing: true });
    }
  }

  setVisibility(visible: boolean) {
    this.setState({ visible });
  }

  render() {
    const { show, title, onRequestClose, track, model } = this.props;
    const {
      page: { checkout },
      content,
    } = model;

    const trackKey = 'track-info';
    const eulaKey = 'eula-content';

    const visibilityHandlerProps: Pick<ScrollableOverlayProps, 'onVisibilityChange'> = {
      onVisibilityChange: this.setVisibility,
    };

    // Base loading state
    const loading = <Loading key={trackKey} text="Loading license information..." />;

    // Escape early (prior to data wrangling) if overlay isn't due to be shown
    if (!this.state.visible) {
      return (
        <ScrollableOverlay show={show} onRequestClose={onRequestClose} {...visibilityHandlerProps}>
          {loading}
        </ScrollableOverlay>
      );
    }

    const usageInformation = checkout.tracksWithUsage.get(track.identity);
    const eulaContent = content.eulas.get(getEulaType(track));

    const bodyContent: React.ReactNode[] = [loading];

    // Deal with EULA content first so we know whether to show separate loading states for EULA and track data
    if (eulaContent instanceof Error) {
      bodyContent.push(<div key={eulaKey}>{eulaContent.message}</div>);
    } else if (isObservableArray(eulaContent)) {
      bodyContent.push(
        <div
          key={eulaKey}
          dangerouslySetInnerHTML={{
            __html: PrismicDOM.RichText.asHtml(eulaContent),
          }}
        />
      );
    }
    const eulaResolved = bodyContent.length === 2;

    // If the EULA content is already resolved, track info needs separate error state
    if (usageInformation instanceof Error && eulaResolved) {
      bodyContent[0] = <div key={trackKey}>{usageInformation.message}</div>;
    } else if (typeof usageInformation === 'object' && usageInformation !== null && 'identity' in usageInformation) {
      bodyContent[0] = <TrackInfo key={trackKey} track={usageInformation} />;
    }
    const trackInfoResolved = bodyContent[0] === loading;

    // As track info is resolved and EULA content hasn't, add separate EULA loading state
    if (trackInfoResolved && !eulaResolved) {
      bodyContent.push(<Loading key={eulaKey} text="Loading EULA..." />);
    }

    return (
      <ScrollableOverlay show={show} onRequestClose={onRequestClose} {...visibilityHandlerProps}>
        <header className={styles.header}>
          <h1 className={styles.heading}>{title}</h1>
        </header>
        <main className={styles.body}>
          <span>{bodyContent}</span>
        </main>
      </ScrollableOverlay>
    );
  }
}
