import { AccountBillingPageModel } from './model';
import { APIController } from '../../api/controller';
import { RouterState, RedirectFunction } from 'react-router';
import { action, autorun, IReactionDisposer, runInAction } from 'mobx';
import { UserModel } from "../../user/model";
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import {createCreditCardForm, createVatNumberForm, resetForm, resetFormErrors} from '../../../lib/form';
import {BillingAddress, HttpError, LocationSuggestion} from '../../../types';
import {suggestionToAddress} from '../../../lib/address';

export class AccountBillingPageController {
  constructor(
    private user: UserModel,
    private model: AccountBillingPageModel,
    private api: APIController,
    private env: EnvModel,
    private ui: UIController,
  ) {
    this._disposers = [];

    this._disposers.push(autorun(() => {
      const { user } = this.user;
      this.model.vatNumber = user
        ? (user.vat.country || '') + (user.vat.number || '')
        : '';
    }));
  }

  private _disposers: IReactionDisposer[];

  dispose = () => {
    this._disposers.forEach(dispose => dispose());
  };

  @action
  onEnter = async(nextState: RouterState, replace: RedirectFunction) => {
    runInAction(() => {
      this.model.loading = true;
      this.ui.setBreadcrumbs([{
        path: '/account',
        label: 'Account'
      }, {
        path: '/account/billing',
        label: 'Billing'
      }]);
    });

    await this.ui.setSEO(nextState.location.pathname);

    const [addresses, cards] = await Promise.all([
      this.api.user.getBillingAddresses(this.user.user.identity),
      this.api.user.getPaymentCards(this.user.user.identity),
      this.env.ready
    ]);

    runInAction(() => {
      this.model.addresses = addresses.data;
      this.model.cards = cards.data;
      this.model.loading = false;
    });

    return;
  };

  @action
  private populateAddressForm = (address?: BillingAddress) => {
    const { fields } = this.model.modalAddress.form;

    Object.keys(fields).forEach(field => {
      fields[field].value = address ? address[field] : '';
    });

    resetForm(this.model.modalAddress.form);
  };

  @action
  editVatNumber = () => {
    this.model.modalVatNumber.form = createVatNumberForm(this.model.vatNumber);
    this.model.modalVatNumber.show = true;
  };

  @action
  changeVatNumberField = (field: string, value: string) => {
    this.model.modalVatNumber.form.fields.vatNumber.value = value;
  };

  @action
  closeVatNumberModal = () => {
    this.model.modalVatNumber.show = false;
  };

  @action
  submitVatNumberModal = async () => {
    resetFormErrors(this.model.modalVatNumber.form);

    const { value } = this.model.modalVatNumber.form.fields.vatNumber;

    if (value && value.length < 2 || value.length > 24) {
      return this.model.modalVatNumber.form.fields.vatNumber.error = 'Must be between 2 and 24 characters'
    }

    this.model.modalVatNumber.form.saving = true;

    try {
      await this.api.user.setVatNumber(value);
      this.model.vatNumber = value;
      this.model.modalVatNumber.form.saving = false;
      this.closeVatNumberModal();
    } catch (e) {
      this.model.modalVatNumber.form.saving = false;
      return this.model.modalVatNumber.form.error = (e as HttpError).body.error;
    }
  };

  @action
  addAddress = () => {
    this.populateAddressForm();
    this.model.modalAddress.edit = false;
    this.model.modalAddress.show = true;
  };

  @action
  editAddress = (index: number) => {
    const address = this.model.addresses[index];
    if (!address) return;

    this.populateAddressForm(address);
    this.model.modalAddress.edit = true;
    this.model.modalAddress.show = true;
  };

  @action
  submitAddress = async () => {
    const { modalAddress } = this.model;
    modalAddress.form.saving = true;

    resetFormErrors(modalAddress.form);

    const { fields } = this.model.modalAddress.form;

    try {
      if (!fields.address1.value) {
        return fields.address1.error = 'Please enter the street address';
      }

      if (!fields.postcode.value) {
        return fields.postcode.error = 'Please enter the postal code';
      }

      if (!fields.country.value) {
        return fields.country.error = 'Please enter the country';
      }
    } finally {
      modalAddress.form.saving = false;
    }

    const address = {
      address1: fields.address1.value,
      state: fields.state.value,
      city: fields.city.value,
      postcode: fields.postcode.value,
      country: fields.country.value
    };

    try {
      await this.api.user.createBillingAddress(
        this.user.user.identity,
        address
      );

      this.model.addresses.push(address);
      this.closeAddressModal();
      this.model.modalAddress.form.saving = false;
    } catch (e) {
      this.model.modalAddress.form.saving = false;
      this.model.modalAddress.form.error = (e as HttpError).body.error;
    }
  };

  @action
  closeAddressModal = () => {
    this.model.modalAddress.show = false;
  };

  @action
  changeAddressField = (field: string, value: any) => {
    const target = this.model.modalAddress.form.fields[field];
    if (!target) return;

    target.value = value;
  };

  @action
  onSuggestAddress = (suggestion: LocationSuggestion) => {
    const { fields } = this.model.modalAddress.form;
    const address = suggestionToAddress(suggestion);

    Object.keys(address).forEach(name => {
      const field = fields[name];
      if (!field) return;

      field.value = address[name];
    });
  };

  @action
  addCard = () => {
    const { addresses, modalCreditCard } = this.model;
    // TODO fire notification to let know must have an address before creating
    if (addresses.length === 0) return;
    modalCreditCard.form = createCreditCardForm(addresses);

    if (addresses.length === 1) {
      modalCreditCard.form.fields.address.value = addresses[0];
    }

    this.model.modalCreditCard.show = true;
  };

  @action
  closeCreditCardModal = () => {
    this.model.modalCreditCard.show = false;
  };

  @action
  changeCreditCardField = (field: string, value: any) => {
    const target = this.model.modalCreditCard.form.fields[field];
    if (!target) return;

    target.value = value;
  }
}