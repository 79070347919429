import * as React from 'react';
import { Collapsible, CollapsibleProps, SummaryProps } from '../../collapsible/collapsible';
import { CollapsibleIndicator } from '../atoms/CollapsibleIndicator';
import { extend, style } from 'typestyle';
import { betweenJustified, center, flexRoot } from 'csstips';
import { px } from 'csx';
import { menuLinkCssClassName } from './MenuLink';
import { focusVisible } from '../utilities/css';

const styles = {
  details: style({
    border: 'none',
    borderBottom: '1px solid #2E2E3B',
    borderRadius: 0,
    boxShadow: 'none',
  }),
  summary: style(flexRoot, center, betweenJustified, {
    color: 'white',
    fontWeight: 600,
  }),
  group: style({
    listStyle: 'none',
    margin: 0,
    paddingLeft: px(15),
    $nest: {
      '& li': {
        paddingBottom: px(15),
      },
      '& a': extend(
        {
          $nest: {
            '&:hover': {
              textDecoration: 'underline',
            },
          },
        },
        focusVisible({
          textDecoration: 'underline',
        })
      ),
      [`& .${menuLinkCssClassName}`]: {
        color: 'white',
      },
    },
  }),
};

export type MenuLinkGroupProps = CollapsibleProps & {
  name: string;
  onToggle?: SummaryProps['onToggle'];
};
export const MenuLinkGroup: React.SFC<MenuLinkGroupProps> = ({ children, name, onToggle, ...props }) => {
  const childArray = Array.isArray(children) ? children : [children];
  return (
    <Collapsible className={styles.details} {...props}>
      <Collapsible.Summary className={styles.summary} onToggle={onToggle}>
        {name} <CollapsibleIndicator colour="white" />
      </Collapsible.Summary>
      <ul className={styles.group}>
        {childArray.map((child, listKey) => (
          <li key={listKey}>{child}</li>
        ))}
      </ul>
    </Collapsible>
  );
};
