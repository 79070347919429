import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const Copy: React.SFC<SVGIconProps> = ({ size = 17, isSquare, color = 'currentColor', ...props }) => (
  <BaseSvg width={size} height={isSquare ? size : size * (17 / 16)} viewBox="0 0 16 17" {...props}>
    <g clipPath="url(#a)" fill={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.2 5v8.6c0 .6.4 1 1 1h6c.5 0 1-.4 1-1V7.2L8.2 4h-4a1 1 0 0 0-1 1Zm4.4.3H4.4v8.1h5.5V7.7L7.6 5.3Z"
      />
      <path d="M10.8 1.3h-4c-.5 0-1 .5-1 1.1v8.5c0 .6.5 1.1 1 1.1h6c.6 0 1-.5 1-1V4.4l-3-3.2Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.5 0h16v16H.5z" />
      </clipPath>
    </defs>
  </BaseSvg>
);
