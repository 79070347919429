import * as React from 'react';
import { classes, media, style } from 'typestyle';
import { important, px } from 'csx';
import { ContentSliceProps } from './slices/ContentSlice';
import { PlaylistTile } from '../molecules/PlaylistTile';
import { ArtistSchema, PlaylistGroupPlaylistSchema, PlaylistSchema } from '../../../types/schema';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { DecoratedPageCarousel, DecoratedPageCarouselProps } from './DecoratedPageCarousel';
import { colorGunmetal, rebrand } from '../../../theme/color';
import { BaseCarouselSlice } from './slices/BaseCarouselSlice';
import FavouritePlaylistButtonCompat from '../../compat/favourite-playlist-button-compat';
import { ArtistTile } from '../molecules/ArtistTile';
import { FavouriteArtistButtonCompat } from '../../compat/favourite-artist-button-compat';
import { SuggestionType } from '../atoms/SearchSuggestionItem';
import TitleText from '../atoms/TitleText';

const styles = {
  carouselControls: style({
    $nest: {
      '& > button': {
        transform: 'translateY(-34px)',
      },
    },
  }),
  carousel: style(
    mediaUpToDesktop({
      marginLeft: px(-16),
      marginRight: px(-16),
      width: 'calc(100% + 32px)',
    })
  ),
  tileClassName: style(
    media(
      {
        maxWidth: 250,
      },
      { width: '78%' }
    ),
    {
      minWidth: '166px',
    }
  ),
  tileClassNameSiderBarOpen: style(
    media(
      {
        maxWidth: 670,
      },
      { width: '87.5%' }
    )
  ),
  trackTile: style(
    {
      $nest: {
        ':not(.with-gradient) &': {
          color: colorGunmetal.toHexString(),
        },
      },
    },
    mediaUpToDesktop({
      margin: 0,
      marginLeft: px(16),
    })
  ),
  featuredIn: style(mediaDesktop({ marginTop: '6px' }), {
    color: rebrand.contrast[40].toString(),
    fontSize: important('24px'),
    fontWeight: 400,
    marginBottom: '25px',
    marginLeft: '12px',
  }),
  playlistTitle: style({
    marginBottom: '20px',
    marginLeft: '12px',
  }),
  artistTitle: style(
    mediaUpToDesktop({
      marginBottom: '39px',
    }),
    { marginBottom: '32px', marginLeft: '12px' }
  ),
};

export type TopSolutionEntity = (PlaylistGroupPlaylistSchema | PlaylistSchema | ArtistSchema) & {
  type: SuggestionType;
};

type CarouselSliceProps = ContentSliceProps &
  DecoratedPageCarouselProps & {
    topSolutions: Array<TopSolutionEntity>;
    actionHref?: string;
    filterOpen?: boolean;
    selectedEntity?: string;
    SelectTopSolutionMixPanel: (a: string, b: string) => void;
  };

export const TopSolutionCarousel: React.FC<CarouselSliceProps> = ({
  className,
  contentClassName,
  topSolutions,
  carouselClassName,
  filterOpen,
  selectedEntity,
  SelectTopSolutionMixPanel,
  ...props
}) => {
  return (
    <BaseCarouselSlice className={className} contentClassName={contentClassName}>
      <DecoratedPageCarousel
        actions={null}
        controlClassName={styles.carouselControls}
        carouselClassName={classes(styles.carousel, carouselClassName)}
        indicatorAlignment="centre"
        tileClassName={classes(styles.tileClassName, filterOpen && styles.tileClassNameSiderBarOpen)}
        mobileTileSize={40.5}
        withControls={false}
        indicators={false}
        {...props}
      >
        {topSolutions.map((topSolution: TopSolutionEntity, i: number) => {
          if (topSolution.type === 'playlist') {
            const category =
              i === 0
                ? 'top result'
                : i > 0
                ? selectedEntity === 'playlist'
                  ? 'similar playlists'
                  : 'featured in'
                : '';
            return (
              <div
                key={topSolution.identity}
                data-test-top-solution={i === 0 ? 'playlist' : 'feaured-playlist'}
                style={{ marginTop: i === 1 ? 1 : i > 1 ? 56 : 0 }}
              >
                {i === 0 && <TitleText text="Top Result" className={styles.playlistTitle} />}
                {i === 1 && (
                  <TitleText
                    text={selectedEntity === 'playlist' ? 'Similar Playlists' : 'FEATURED IN'}
                    className={styles.featuredIn}
                    data-test-header={selectedEntity === 'playlist' ? 'Similar Playlists' : 'FEATURED IN'}
                  />
                )}
                <PlaylistTile
                  onClick={() => SelectTopSolutionMixPanel(category, `browse/collection/${topSolution.slug}`)}
                  key={topSolution.identity}
                  playlist={topSolution}
                  className={styles.trackTile}
                  searchPage
                >
                  <FavouritePlaylistButtonCompat identity={topSolution.identity} />
                </PlaylistTile>
              </div>
            );
          }
          if (topSolution.type === 'artist') {
            return (
              <div key={topSolution.identity} data-test-top-solution="artist">
                <TitleText text="Top Result" className={styles.artistTitle} />
                <ArtistTile
                  isSearchPage
                  artist={(topSolution as any) as ArtistSchema}
                  onClick={() => SelectTopSolutionMixPanel('Top Solution', `music/artists/${topSolution.slug}`)}
                >
                  <FavouriteArtistButtonCompat artist={(topSolution as any) as ArtistSchema} />
                </ArtistTile>
              </div>
            );
          }
        })}
      </DecoratedPageCarousel>
    </BaseCarouselSlice>
  );
};
