import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const Padlock: React.SFC<SVGIconProps> = ({ size = 16, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={size * (22 / 16)} viewBox="0 0 16 22" {...props}>
    {/* <path d="M0 0h48v48H0z" fill="none" /> */}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.38232 6.0086C2.38232 2.72531 4.71652 0 8.24088 0C11.7542 0 14.0849 2.70832 14.0994 5.97787H14.1V9.94695C14.8385 10.6727 15.2964 11.6829 15.2964 12.8V18C15.2964 20.2091 13.5056 22 11.2964 22H4.65003C2.44089 22 0.650031 20.2091 0.650031 18V12.8C0.650031 11.4329 1.3359 10.2259 2.38232 9.50448V6.0086ZM11.17 8.8V5.97787H11.1701C11.1569 4.16822 9.97939 2.93333 8.24088 2.93333C6.49255 2.93333 5.3116 4.18222 5.3116 6.0086V8.8H11.17ZM9.43786 13.9333H6.50858V16.8667H9.43786V13.9333Z"
      fill={color}
    />
  </BaseSvg>
);
