import * as React from 'react';
import { classes, cssRaw, cssRule, style } from 'typestyle';
import { percent, px, url, viewWidth } from 'csx';
import { BrandPinkText } from '../../atoms/GradientText';
import { BaseMasthead, BaseMastheadProps } from './BaseMasthead';
import {
  mediaDesktop,
  mediaLargeMobile,
  mediaLargeTablet,
  mediaUpToDesktop,
  mediaUpToLargeTablet,
} from '../../utilities/mediaQueries';
import { contentHeadingStyles, heading1, heading2 } from '../../utilities/font';
import { Component, deps, inject, observer } from '../../../../lib/component';
import { Collapsible } from '../../../collapsible/collapsible';
import { shadowElevation2 } from '../../../../theme/shadow';
import { colorContrast100, colorGunmetal, colorNeutral, colorWhite, rebrand } from '../../../../theme/color';
import { SVGIcon } from '../../../svg-icon';
import { isEmpty } from '../../utilities/objects';
import ToolTipModal from '../ToolTipModal';
import { formatDecimalCurrency, formatNonDecimalCurrency } from '../../../../lib/currency';
import { Button } from '../../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../../atoms/button/buttonStyles';
import { ACCOUNT_PLANS_ROUTE } from '../../../../constants';
import { rebrandPageClass } from '../../utilities/page';
import { DashboardTeaserMasthead, DashboardTeaserProps } from './DashboardTeaserMasthead';
import { flexRoot } from 'csstips';
import { mixinTextEllipsis } from '../../../../theme/mixin';
import UserAvatar from '../../atoms/UserAvatar';
import { RatecardDisplay } from '../RatecardDisplay';

const { SECONDARY } = ButtonProminence;
const { XSMALL } = ButtonSize;

const styles = {
  masthead: style({
    height: percent(100),
    minHeight: px(512),
  }),
  mastheadWrapper: style({
    position: 'static',
  }),
  mastheadContents: style(
    {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      $nest: {
        '& > *': {
          width: px(560),
          margin: '1rem auto 0 auto',
        },
      },
    },
    mediaUpToLargeTablet({
      $nest: {
        '& > h1': {
          fontSize: px(32),
          maxWidth: '17rem',
        },
        '& > div': {
          width: percent(100),
        },
      },
    }),
    mediaLargeTablet({
      $nest: {
        '& > div': {
          width: px(488),
        },
        '& > h1': {
          fontSize: px(80),
          maxWidth: '42rem',
        },
      },
    })
  ),
  gradientHeading: style(
    mediaUpToDesktop({
      fontSize: viewWidth(15),
      letterSpacing: viewWidth(-1.2),
    }),
    mediaDesktop(heading1)
  ),
  headingWrapper: style({
    margin: 0,
  }),
  collapsible: style(
    {
      position: 'relative',
      margin: '1rem auto 0 auto',
      border: 0,
      background: rebrand.dark3.toString(),
      textAlign: 'center',
    },
    mediaLargeMobile({
      marginBottom: px(32),
    }),
    mediaUpToLargeTablet({
      maxWidth: px(400),
      width: 'calc(100% - 16px)',
      marginLeft: 'auto',
      marginRight: 'auto',
    }),
    mediaLargeTablet({
      marginTop: px(38),
    })
  ),
  collapsibleIndicator: style({
    background: 'none',
    position: 'absolute',
    top: px(-2),
    right: px(4),
    border: 'none',
    boxShadow: 'none',
  }),
  collapsibleSummary: style({
    position: 'relative',
    padding: px(0),
  }),
  collapsibleContent: style(
    {
      fontSize: px(12),
      lineHeight: px(16),
      paddingBottom: px(16),
      margin: '0 auto',
      maxWidth: percent(90),
      textAlign: 'center',
      $nest: {
        a: {
          display: 'block',
        },
      },
    },
    mediaLargeTablet({
      maxWidth: percent(75),
    })
  ),
  channel: style(shadowElevation2, flexRoot, {
    height: px(42),
    backgroundColor: 'white',
    borderRadius: px(8),
    $nest: {
      '& > *': {
        margin: `${px(8)} ${px(8)} 0 ${px(8)}`,
        height: px(24),
      },
    },
  }),
  channelName: style(mixinTextEllipsis, {
    display: 'block',
    color: '#2E2E3B',
    flexShrink: 1,
    textAlign: 'left',
    marginLeft: px(0),
    marginTop: px(13),
  }),
  channelButton: style({ flexShrink: 0 }),
  rates: style(
    {
      display: 'flex',
      flexDirection: 'row',
      width: percent(90),
      margin: `0 auto ${px(16)} auto`,
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    mediaLargeTablet({
      justifyContent: 'space-around',
    })
  ),
  ratesBox: style(
    {
      width: percent(30),
      borderRadius: px(4),
      backgroundColor: colorNeutral.toString(),
      color: colorWhite.toString(),
      minWidth: px(120),
      height: 'auto',
      position: 'relative',
      margin: `0 ${px(8)} ${px(16)} ${px(8)}`,
      $nest: {
        '& h5': {
          fontWeight: 300,
          margin: `${px(8)} auto 0 auto`,
        },
        '& p': {
          margin: `${px(4)} auto ${px(8)} auto`,
          fontWeight: 'bold',
        },
        '& svg': {
          position: 'absolute',
          top: px(0),
          right: px(6),
          width: px(13),
          cursor: 'pointer',
          $nest: {
            '& path': {
              fill: colorWhite.toString(),
            },
          },
        },
      },
    },
    mediaLargeTablet({
      margin: `0`,
      minWidth: percent(30),
    })
  ),
  toolTipWrapper: style({
    width: percent(40),
    margin: '0 auto',
    height: px(300),
    $nest: {
      '& > div': {
        position: 'absolute',
        top: 0,
        left: 0,
        height: px(300),
        backgroundColor: 'blue',
        zIndex: 10,
      },
    },
  }),
};

cssRule(
  `.${rebrandPageClass} .${styles.masthead} h2`,
  contentHeadingStyles,
  {
    display: 'block',
  },
  mediaUpToDesktop({
    fontSize: px(16),
  }),
  mediaDesktop({
    fontSize: px(24),
  })
);

export type CreatorDashboardMastheadProps = Omit<BaseMastheadProps, 'mastheadClassName'> & DashboardTeaserProps;

export const CreatorDashboardMasthead = inject(deps)(
  observer(
    ({
      children,
      model: {
        user: { user, channel },
      },
      className,
      ...childProps
    }: React.Component<CreatorDashboardMastheadProps>['props']) => {
      return <DashboardTeaserMasthead {...childProps} />;
    }
  )
);
