import * as React from 'react';
import CurvedArrowImg from '../../../../static/curved-arrow-v2.png';
import CurvedArrowImg2x from '../../../../static/curved-arrow-v2@2x.png';

interface CurvedArrowProps {
  className?: string;
}

export const CurvedArrowV2: React.FC<CurvedArrowProps> = ({ className }) => (
  <img
    src={CurvedArrowImg}
    className={className}
    alt="Grafitti-style image of a curved arrow"
    srcSet={`${CurvedArrowImg}, ${CurvedArrowImg2x} 2x`}
    loading="lazy"
  />
);
