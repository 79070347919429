import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { percent, px } from 'csx';
import { TrendingUp } from '../../../atoms/icons/TrendingUp';
import { fontHeading } from '../../../../../theme/font';
import {
  mediaUpToDesktop,
  mediaDesktop,
  mediaMobileOnly,
  mediaSmallTablet,
  mediaUpToLargeMobile,
  mediaLargeMobileOnly,
  mediaSmallTabletOnly,
  mediaLargeTablet,
  mediaLargeTabletOnly,
  mediaLargeMobile,
} from '../../../utilities/mediaQueries';
import { isArray } from '../../../utilities/objects';
import { heading4, heading5, heading6, headingStyles } from '../../../utilities/font';
import { betweenJustified, flexRoot, vertical } from 'csstips';

const styles = {
  list: style(flexRoot, vertical, betweenJustified, {
    height: 'auto',
    listStyle: 'none',
    margin: 0,
    padding: 0,
  }),
  item: style(
    headingStyles,
    {
      background: 'rgba(255, 255, 255, 0.6)',
      boxShadow: '0 1px 0 0 rgb(0 0 0 / 10%)',
      borderTopRightRadius: px(100),
      borderBottomRightRadius: px(100),
      display: 'flex',
      alignItems: 'center',
      $nest: {
        '&:not(:last-child)': {
          marginBottom: px(8),
        },
      },
    },
    mediaUpToLargeMobile({
      width: percent(62),
      fontSize: px(12),
      padding: '0 10px',
    }),
    mediaLargeMobile(heading6, {
      padding: '4px 16px',
    }),
    mediaLargeMobileOnly({
      width: percent(55),
    }),
    mediaSmallTabletOnly({
      width: percent(40),
    }),
    mediaLargeTabletOnly({
      width: percent(65),
    }),
    mediaDesktop({
      width: percent(54),
    })
  ),
  figure: style(
    {
      color: '#B91099',
    },
    mediaUpToLargeMobile(heading5, {
      margin: '0 4px',
      minWidth: px(66),
    }),
    mediaLargeMobile(heading4, {
      margin: '0 8px',
      minWidth: px(90),
    })
  ),
};

cssRaw(`
@supports (backdrop-filter: blur(5px) saturate(0.1)) {
  .${styles.item} {
    background: rgba(255, 255, 255, 0.4) !important;
    backdrop-filter: blur(5px) saturate(0.1);
  }
}
`);

export type StatItemProps = React.Component['props'] & { figure: string; label: string };

export const StatItem: React.SFC<StatItemProps> = ({ figure, label }) => (
  <li className={styles.item}>
    <TrendingUp />
    <span className={styles.figure}>{figure}</span>
    <span>{label}</span>
  </li>
);

export type StatListProps = React.Component['props'] & { className?: string };

export const StatList: React.SFC<StatListProps> = ({ children, className }) => {
  const childCount = isArray(children) ? children.length : 1;
  const marginOffset = Math.max(3 - childCount, 0) * 72;
  return <ul className={classes(styles.list, style({ paddingBottom: px(marginOffset) }), className)}>{children}</ul>;
};
