import * as React from 'react';
import { Component, deps, inject } from '../../../lib/component';
import { observer } from 'mobx-react';
import { colorGunmetal, rebrand } from '../../../theme/color';
import { classes, extend, style } from 'typestyle';
import { zAppHeaderContainer } from '../../../theme/z';
import { transitionQuickEase } from '../../../theme/transition';
import { important, percent, px, translateX, url } from 'csx';
import { desktopContentWidth, headerFullHeight } from '../config';
import { betweenJustified, center, flexRoot } from 'csstips';
import {
  mediaDesktop,
  mediaLargeMobile,
  mediaSmallTablet,
  mediaUpToDesktop,
  mediaUpToLargeMobile,
  mediaUpToSmallTablet,
} from '../utilities/mediaQueries';
import ArrowRightAsset from '../../../static/arrow-right-white.svg';
import { isArray } from '../utilities/objects';
import { PrismicMenuLinkSliceTypes } from '../../../modules/content/model';
import { PrismicMenuLinkAudience, PrismicMenuLinkComponent } from '../prismic-slices/PrismicMenuLink';
import { PrismicMenuLinkGroupComponent } from '../prismic-slices/PrismicMenuLinkGroup';
import { menuLinkCssClassName } from '../atoms/MenuLink';
import { linkCurrentPageClassName } from '../atoms/Link';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { focusVisible } from '../utilities/css';
import { AnalyticsContextProvider } from '../utilities/AnalyticsContext';

const styles = {
  container: style({
    position: 'relative',
    zIndex: zAppHeaderContainer - 1,
    $nest: {
      '&::before': {
        content: '""',
        opacity: 0,
        transition: `opacity ${transitionQuickEase}, transform 0.125s step-end`,
        background: rebrand.contrast[90].toString(),
        zIndex: -1,
        transform: 'translateX(100vw)',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      '&.active::before': {
        transition: `opacity ${transitionQuickEase}`,
        opacity: 0.3,
        transform: 'none',
      },
      '& .button': mediaDesktop({
        minHeight: px(32),
        paddingTop: px(5),
        paddingBottom: px(5),
      }),
    },
  }),
  wrapper: style(
    {
      overflow: 'auto',
      width: percent(100),
      maxWidth: px(250),
      position: 'fixed',
      top: px(headerFullHeight),
      bottom: 0,
      transition: `transform ${transitionQuickEase}`,
      background: 'transparent',
      transform: 'translateY(-200%)',
      $nest: {
        '.active &': {
          transform: 'none',
        },
      },
    },
    mediaUpToLargeMobile({ right: 0, maxWidth: percent(100) }),
    mediaLargeMobile(
      mediaUpToDesktop({
        right: px(16),
      })
    ),
    mediaDesktop({
      right: `calc((100% - ${desktopContentWidth}px) / 2)`,
    })
  ),
  header: style(flexRoot, center, betweenJustified, {
    padding: px(16),
    height: px(headerFullHeight),
    background: colorGunmetal.toHexString(),
    position: 'relative',
  }),
  mobileOnly: style(mediaSmallTablet({ display: important('none') })),
  links: style({
    background: rebrand.contrast[60].toString(),
    borderBottomLeftRadius: px(8),
    borderBottomRightRadius: px(8),
    padding: px(12),
    $nest: {
      [`& > .${linkCurrentPageClassName}:not(.details ~ .${linkCurrentPageClassName})`]: {
        background: rebrand.contrast[90].toString(),
      },
      [`& > .${menuLinkCssClassName}`]: {
        display: 'block',
        color: rebrand.contrast[10].toString(),
        padding: '4px 15px',
      },
      [`& > .${menuLinkCssClassName}:not(.details ~ .${menuLinkCssClassName})`]: {
        ...flexRoot,
        ...center,
        fontWeight: 700,
        borderRadius: px(4),
        padding: px(8),
        marginBottom: px(8),
        $nest: {
          '& svg': {
            boxSizing: 'content-box',
            paddingLeft: px(4),
            paddingRight: px(4),
          },
          '& > :first-child': extend(
            mediaUpToSmallTablet({
              marginRight: px(10),
            }),
            mediaSmallTablet({ marginRight: px(12) })
          ),
          '&::after': {
            content: '""',
            width: px(16),
            height: px(16),
            flexGrow: 1,
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 'right',
          },
        },
      },
      '& > a:not(.details ~ a)': extend(
        {
          $nest: {
            '&:hover': {
              background: rebrand.contrast[90].toString(),
            },
            '&::after': {
              backgroundImage: url(ArrowRightAsset),
              opacity: 0.4,
              transition: `opacity ${transitionQuickEase}`,
            },
            '&:hover::after': {
              opacity: 1,
            },
          },
        },
        focusVisible({
          outline: '2px solid rgb(253,87,201)',
          $nest: {
            '&::after': {
              opacity: 1,
            },
          },
        })
      ),
      [`& > .details + .${menuLinkCssClassName}`]: {
        marginTop: px(16),
      },
      [`& > .details ~ .${menuLinkCssClassName}`]: {
        fontSize: px(12),
        $nest: {
          '&:hover, &:focus': {
            textDecoration: 'underline',
          },
          '&:last-child': {
            marginBottom: px(16),
          },
        },
      },
    },
  }),
  logOut: style(
    {
      width: `calc(100% - 16px)`,
      margin: px(8),
    },
    mediaUpToLargeMobile({ fontSize: px(12), fontWeight: 500 })
  ),
};

export type AppMenuProps = { className?: string };

const analyticsContext = { uiLocation: 'Global navigation menu drawer' };

@inject(deps)
@observer
export class AppMenu extends Component<AppMenuProps> {
  menuWrapper: HTMLDivElement | null = null;

  state = {
    openGroup: -1,
  };

  constructor(props) {
    super(props);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    this.logoutHandler = this.logoutHandler.bind(this);
  }

  groupToggle(key: number) {
    if (this.state.openGroup === key) {
      return this.setState({ openGroup: -1 });
    }
    return this.setState({ openGroup: key });
  }

  closeDrawer() {
    this.props.controller.ui.setMenuDrawer(false);
  }

  clickHandler(event: Pick<Event, 'stopPropagation' | 'target'>) {
    // Don't allow event to propagate to menu wrapper
    event.stopPropagation();
    // If the click is on a link or the drawer wrapper, close the drawer
    if (event.target instanceof HTMLAnchorElement || (this.menuWrapper && this.menuWrapper === event.target)) {
      this.closeDrawer();
    }
  }

  logoutHandler() {
    this.props.controller.auth.logout();
    this.props.controller.analytics.sendMixpanel('User clicks log out prompt', {
      path: this.props.model.router.location.pathname,
    });
  }

  render() {
    const {
      model: {
        ui: { showMenuDrawer },
        auth: { token },
        content: { burgerMenuLinks },
      },
    } = this.props;

    return (
      <AnalyticsContextProvider context={analyticsContext}>
        <div
          className={classes(showMenuDrawer && 'active', styles.container)}
          onClick={this.closeDrawer}
          data-test-app-drawer
        >
          <div className={styles.wrapper} onClick={this.clickHandler} ref={(ref) => (this.menuWrapper = ref)}>
            <div className={styles.links}>
              {isArray(burgerMenuLinks) &&
                burgerMenuLinks.map((link, key) => {
                  if (link.slice_type === PrismicMenuLinkSliceTypes.MENU_LINK) {
                    const { audience, mobile_only } = link.primary;
                    if (
                      (audience === PrismicMenuLinkAudience.LOGGED_IN && !token) ||
                      (audience === PrismicMenuLinkAudience.LOGGED_OUT && token)
                    ) {
                      return null;
                    }
                    return (
                      <PrismicMenuLinkComponent
                        className={mobile_only && styles.mobileOnly}
                        key={key}
                        prismicFields={link.primary}
                        tabIndex={showMenuDrawer ? 0 : -1}
                      />
                    );
                  }
                  return (
                    <PrismicMenuLinkGroupComponent
                      key={key}
                      prismicFields={link.primary}
                      prismicItems={link.items}
                      show={this.state.openGroup === key}
                      onToggle={() => this.groupToggle(key)}
                    />
                  );
                })}
              {token && (
                <Button
                  data-test-app-drawer-log-out-button
                  className={styles.logOut}
                  onClick={this.logoutHandler}
                  prominence={ButtonProminence.OUTLINE}
                  size={ButtonSize.XSMALL}
                  disabled={!showMenuDrawer}
                >
                  Log out
                </Button>
              )}
            </div>
          </div>
        </div>
      </AnalyticsContextProvider>
    );
  }
}
