import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const ClaimsManaged: React.SFC<SVGIconProps> = ({ size = 16, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={size * (16 / 16)} viewBox="0 0 16 16" {...props}>
    <path
      fill={color}
      d="M.999 0h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-14a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1Zm9 8a2.5 2.5 0 0 0 0 5 2.5 2.5 0 0 0 0-5Zm.825 3.675L9.749 10.6V9h.5v1.395l.925.925-.35.355ZM10.499 3.5h-1.59c-.21-.58-.76-1-1.41-1-.65 0-1.2.42-1.41 1h-1.59c-.55 0-1 .45-1 1V12c0 .55.45 1 1 1h3.055a3.372 3.372 0 0 1-.71-1H4.499V4.5h1V6h4V4.5h1v2.54c.355.05.69.155 1 .3V4.5c0-.55-.45-1-1-1Zm-3 1a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5s.5.225.5.5-.225.5-.5.5Z"
    />
  </BaseSvg>
);
