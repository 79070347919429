import React from 'react';
import { center, flexRoot, vertical } from 'csstips';
import { classes, style } from 'typestyle';
import { percent, px } from 'csx';
import { Component, ComponentProps, deps, inject, observer } from '../../../lib/component';
import { VerticalSelector } from '../atoms/controls/VerticalSelector';
import { Button } from '../atoms/button/Button';
import { ButtonProminence } from '../atoms/button/buttonStyles';
import { isEmpty } from '../utilities/objects';

const styles = {
  form: style(flexRoot, vertical, center),
};

export enum ChannelVerticalFormErrorType {
  VALIDATION,
  DATA_MODEL,
  NETWORK,
}

type ChannelVerticalFormProps = {
  className?: string;
  onSuccess?: (vertical: string) => void;
  onError?: (reason: ChannelVerticalFormErrorType) => void;
};

@inject(deps)
@observer
export class ChannelVerticalForm extends Component<ChannelVerticalFormProps> {
  ref: HTMLFormElement = null;

  state = {
    vertical: '',
    clicked: false,
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    const {
      onError,
      onSuccess,
      model: {
        user: { user, channel },
      },
      controller: {
        user: { updateSelectedChannelVertical },
        analytics: { sendMixpanel },
        bugsnag,
      },
    } = this.props;
    const { vertical } = this.state;
    const dataReady = !isEmpty(user) && !isEmpty(channel);
    event.preventDefault();
    this.setState({
      clicked: true,
    });
    const isValid = this.ref && this.ref.checkValidity() && dataReady && vertical !== '';
    if (!isValid) {
      if (typeof onError === 'function') {
        onError(!dataReady ? ChannelVerticalFormErrorType.DATA_MODEL : ChannelVerticalFormErrorType.VALIDATION);
      }
      return;
    }
    sendMixpanel('User selects channel vertical', {
      user: user.identity,
      channel: channel.id,
      vertical,
    });
    if (typeof onSuccess === 'function') onSuccess(vertical);
    try {
      await updateSelectedChannelVertical(vertical);
    } catch (error) {
      if (typeof onError === 'function') onError(ChannelVerticalFormErrorType.NETWORK);
      bugsnag.notifyException(error);
    }
  }

  render() {
    const { className } = this.props;
    const { vertical } = this.state;

    return (
      <form
        className={classes(className, styles.form, this.state.clicked && 'show-errors')}
        ref={(ref) => (this.ref = ref)}
        onSubmit={this.handleSubmit}
        data-test-channel-vertical-form
      >
        <VerticalSelector
          value={vertical}
          isValid={!this.state.clicked || vertical !== ''}
          onChange={(event) => this.setState({ vertical: event.currentTarget.value })}
        />
        <Button
          prominence={ButtonProminence.SECONDARY}
          type="submit"
          disabled={vertical === ''}
          data-test-channel-vertical-submit
        >
          Save
        </Button>
      </form>
    );
  }
}
