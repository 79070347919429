import { PrismicContentData, PrismicContentIslandRef, PrismicContentIslandsModel } from './model';
import { EnvModel } from '../env/model';
import { action, observable, ObservableMap } from 'mobx';
import { PrismicController } from '../prismic/controller';
import { PrismicSlice } from '../../components/project-happy/utilities/types';
import Prismic from 'prismic-javascript';
import { fetchSliceData } from '../../components/project-happy/utilities/prismic';
import { APIController } from '../api/controller';
import { AuthModel } from '../auth/model';

export class PrismicContentIslandsController {
  constructor(
    private model: PrismicContentIslandsModel,
    private env: EnvModel,
    private prismic: PrismicController,
    private api: APIController,
    private auth: AuthModel
  ) {}

  @action
  fetchPrismicContent = async (contentIslandRef: PrismicContentIslandRef, contentData: PrismicContentData) => {
    const { contentIslandData, documentCache } = this.model;
    let slices: PrismicSlice[] = [];
    const context = {
      playlists: observable.map(),
      tracks: observable.map(),
      externalDataLoading: true,
    };
    contentIslandData.set(contentIslandRef, { slices, context });
    if (typeof contentData === 'string') {
      const documentUid = contentData;
      slices = documentCache.get(documentUid);
      if (!Array.isArray(slices)) {
        const predicates = Prismic.Predicates.at('my.content_island.uid', documentUid);
        const content = await this.prismic.query(predicates, { resolveImages: true });
        if (content.results.length === 0) {
          context.externalDataLoading = false;
          contentIslandData.set(contentIslandRef, { slices: [], context });
          return;
        }
        const loadedSliceData = content.results[0].data.slices;
        documentCache.set(documentUid, loadedSliceData);
        slices = loadedSliceData;
      }
    } else {
      slices.push(contentData);
    }
    contentIslandData.set(contentIslandRef, { slices, context });
    const { carousels, tracks } = await fetchSliceData(
      this.api,
      this.env,
      slices,
      this.auth.user && this.auth.user.country
    );
    context.playlists = observable.map(carousels);
    context.tracks = observable.map(tracks);
    context.externalDataLoading = false;
    contentIslandData.set(contentIslandRef, { slices, context });
  };
}
