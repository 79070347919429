import * as React from 'react';
import gql from 'graphql-tag';
import {graphql, InjectedGraphQLProps} from 'react-apollo';
import {Component, deps, inject, observer} from '../../lib/component';
import {style} from 'typestyle';
import {rem} from 'csx';
import {colorSubtle} from '../../theme/color';

type CombinedProps = Props & InjectedGraphQLProps<Data>;

@inject(deps) @observer
class ReleaseCLine extends Component<CombinedProps> {
  static symbol = '\u00A9';

  static styles = {
    container: style({
      zIndex: 1,
      background: colorSubtle.fade(0.075).toString(),
      padding: rem(1),
      borderRadius: rem(1),
      $nest: {
        '& h3': {
          margin: '1.5rem 0 1rem'
        },
        '& h3:first-child': {
          margin: '0 0 1rem 0'
        }
      }
    })
  };
}

type Props = {
  identity: string;
}

type Data = {
  getRelease: {
    c_line: string | null
  }
}

export default ReleaseCLine;

export const ConnectedReleaseCLine = graphql(gql`
  query (
  $identity: ID!
  ) {
    getRelease(options:{identity:$identity}) {
      c_line
    }
  }
`, {
  options: (variables: Props) => ({
    variables,
    // fetchPolicy: 'network-only'
  }),
})(ReleaseCLine);