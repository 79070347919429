import { CouponSchema } from '../types/schema';

export default function polyfillGoldenTicketCoupon(isGoldenTicket: boolean, coupon: CouponSchema) {
  if (!isGoldenTicket || coupon.type !== 'support_coupon') return coupon;

  return {
    ...coupon,
    type: 'golden_ticket',
  };
}
