import * as React from 'react';
import {observer} from 'mobx-react';
import {Form as IForm, FormField, FormFields} from '../types/form';
import {Form} from './form';

export interface FormMarketingPreferencesFields extends FormFields {
  marketing_allowed: FormField<boolean>;
}

export type FormMarketingPreferencesModel = IForm<FormMarketingPreferencesFields>;

export interface FormMarketingPreferencesProps {
  model: FormMarketingPreferencesModel;
  onChange(field: string, value: any): void;
  onSubmit(): void;
  onCancel(): void;
}

@observer
export class FormMarketingPreferences extends React.Component<FormMarketingPreferencesProps, {}> {
  render() {
    const { onChange, onSubmit, onCancel, model } = this.props;

    return (
      <Form
        model={model}
        onSubmit={onSubmit}
        onChange={onChange}
        onCancel={onCancel}
      />
    )
  }
}