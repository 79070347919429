import { computed, observable } from 'mobx';
import {LicenceSchema} from '../../../types/schema';

export class AccountAttributionPageModel {
  @observable
  identity: string;

  @observable
  loading: boolean = false;

  @observable
  licence: LicenceSchema;

  @observable
  referralUrl: string;
}