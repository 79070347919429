import { COUNTRIES, ENGLISH_COUNTRIES } from '../../../../constants';
import * as React from 'react';
import { Dropdown, DropdownProps } from './Dropdown';

export const CountrySelector: React.SFC<DropdownProps> = ({ className, ...props }) => (
  <Dropdown className={className} {...props}>
    <option value="" disabled>
      Select country
    </option>
    {ENGLISH_COUNTRIES.map((code, key) => (
      <option key={key} value={code}>
        {COUNTRIES[code]}
      </option>
    ))}
    <option disabled aria-label="A full list of countries follows this divider">
      &#9472;&#9472;&#9472;&#9472;&#9472;&#9472;
    </option>
    {Object.keys(COUNTRIES).map((code) => (
      <option key={code} value={code}>
        {COUNTRIES[code]}
      </option>
    ))}
  </Dropdown>
);
