import { PromoPanel } from '../molecules/panels/PromoPanel';
import { isEmpty } from '../utilities/objects';
import { splitPrismicRichText } from '../utilities/props';
import * as React from 'react';
import { PrismicBasePanelProps, PrismicRichText } from '../utilities/types';
import { createTextPanel } from '../utilities/prismic';

export type PrismicPromotionalPanelPayload = PrismicBasePanelProps & {
  primary: {
    title?: string;
    badge: PrismicRichText;
    strapline: PrismicRichText;
    static_banner_text?: string;
  };
};
export type PrismicPromotionalPanelProps = {
  payload: PrismicPromotionalPanelPayload;
};
export const PrismicPromotionalPanel: React.SFC<PrismicPromotionalPanelProps> = ({
  payload: {
    primary: {
      title,
      badge,
      strapline,
      static_banner_text,
      call_to_action_url,
      call_to_action_text,
      background_image,
      background_colour,
      background_gradient_colour_end,
      background_gradient_colour_direction,
    },
  },
}) => (
  <PromoPanel
    src={!isEmpty(background_image) && background_image.url}
    gradient={
      background_colour && {
        to: background_colour,
        from: background_gradient_colour_end,
        direction: background_gradient_colour_direction,
      }
    }
    heading={title}
    badge={splitPrismicRichText(badge[0])}
    promo={splitPrismicRichText(strapline[0])}
    banner={static_banner_text}
    cta={
      call_to_action_text && {
        href: !isEmpty(call_to_action_url) && call_to_action_url.url,
        text: call_to_action_text,
      }
    }
  />
);
PrismicPromotionalPanel.displayName = 'PrismicPromotionalPanel';
export const PrismicPromotionalPanelWithText = createTextPanel(PrismicPromotionalPanel);
