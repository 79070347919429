import { AccountPlaylistsPageModel } from './model';
import { RedirectFunction, RouterState } from 'react-router';
import { action, isObservableMap, observable } from 'mobx';
import { UIController } from '../../ui/controller';
import { BugsnagController } from '../../bugsnag/controller';
import { UserController } from '../../user/controller';

export class AccountPlaylistsPageController {
  constructor(private userController: UserController, private ui: UIController, private bugsnag: BugsnagController) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    try {
      this.ui.setBreadcrumbs([
        {
          path: '/account',
          label: 'Account',
        },
        {
          path: '/account/playlists',
          label: 'Playlists',
        },
      ]);

      await this.ui.setSEO(nextState.location.pathname);

      await this.userController.loadPlaylists();
    } catch (error) {
      this.bugsnag.notifyException(error);
    }
  };
}
