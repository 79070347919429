import * as React from 'react';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { QuestionCircle } from '../atoms/icons/QuestionCircle';
import { colorSubtle } from '../../../theme/color';
import ToolTipModal, { ToolTipModalProps } from './ToolTipModal';
import { Button } from '../atoms/button/Button';
import { ButtonProminence } from '../atoms/button/buttonStyles';

const styles = {
  questionPrompt: style({
    padding: 0,
    marginTop: '5px',
    $nest: {
      '&:hover, &:focus': {
        opacity: 0.8,
      },
    },
  }),
  textElements: style({
    $nest: {
      '& h3': {
        padding: '16px 0px 8px 0px',
      },
    },
  }),
};

const tooltipStyles = style({
  maxWidth: px(320),
});

const ClaimsManagedTip: React.SFC<ToolTipModalProps> = ({ className, ...props }) => (
  <ToolTipModal className={classes(tooltipStyles, className)} {...props}>
    <div className={styles.textElements}>
      <h3 style={{ paddingLeft: '0px' }}>Claim-Managed</h3>
      <p>
        A third party are involved in releasing the claim for this track; the claims will be sorted within 4 hours on a
        weekday and within 48 hours at the weekend.
      </p>
    </div>
  </ToolTipModal>
);

export class ClaimsManagedTooltip extends React.Component {
  state = {
    modalOpen: false,
  };

  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  }

  render() {
    return (
      <div>
        <Button
          prominence={ButtonProminence.NONE}
          className={styles.questionPrompt}
          label="More information on claims managed"
        >
          <QuestionCircle size={14} color={colorSubtle.toHexString()} onClick={this.toggleModal} />
        </Button>
        <ClaimsManagedTip show={this.state.modalOpen} onRequestClose={this.toggleModal} />
      </div>
    );
  }
}
