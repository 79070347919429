import * as React from 'react';
import {
  BasePageCarousel,
  BasePageCarouselDefaultProps,
  BasePageCarouselProps,
} from '../atoms/carousel/BasePageCarousel';
import { AnyObject } from '../utilities/objects';
import { debounce } from '../utilities/functions';
import { isDesktop } from '../utilities/mediaQueries';

export type PageCarouselProps = Omit<BasePageCarouselProps, 'page'> & Partial<Pick<BasePageCarouselProps, 'page'>>;

export class PageCarousel<T extends PageCarouselProps = PageCarouselProps> extends React.Component<T> {
  state = {
    items: 1,
    page: 1,
    pages: 1,
  };

  resizeHandler;

  constructor(props) {
    super(props);
    this.state.items = this.items;
    this.state.pages = this.pages;
    this.resizeHandler = debounce(this.updateCarouselState.bind(this));
  }

  get isLocalState() {
    return typeof this.props.page !== 'number';
  }

  get workingPage() {
    return this.isLocalState ? this.state.page : this.props.page;
  }

  get items() {
    const { children } = this.props;
    return Array.isArray(children) ? children.length : 1;
  }

  get pages() {
    const { perPage = BasePageCarouselDefaultProps.perPage } = this.props;
    if (isDesktop.matches) {
      return Number.isFinite(perPage) ? Math.ceil(this.state.items / perPage) : 1;
    }
    return this.state.items;
  }

  updatePage(page: number) {
    if (!Number.isFinite(page) || page < 1) return;
    if (this.isLocalState) {
      this.setState({ page });
    }
    const { onPageChange } = this.props as PageCarouselProps;
    if (typeof onPageChange === 'function') {
      onPageChange(page);
    }
  }

  updateCarouselState() {
    this.setState({ items: this.items, pages: this.pages });
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  componentDidUpdate(prevProps: Readonly<T>) {
    if (this.items === this.state.items && prevProps.perPage === this.props.perPage) return;
    this.updateCarouselState();
  }

  render() {
    const { children, onPageChange, ...props } = this.props as AnyObject;
    return (
      <BasePageCarousel page={this.workingPage} onPageChange={this.updatePage.bind(this)} {...props}>
        {children}
      </BasePageCarousel>
    );
  }
}
