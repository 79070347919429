/* eslint-disable @typescript-eslint/ban-types */
import { action } from 'mobx';
import { API, APIResponse } from '../types';
import { AudioMetaSchema } from '../../../types/schema';

export class AudioAPIController extends API {
  @action
  loadAudio = async (trackId: string): Promise<AudioMetaSchema> => {
    try {
      const url = `${this.env.audioUri}/stream`;
      const body = JSON.stringify({ trackId });
      const resp = await this.request.post({ url, body });
      let data: Partial<AudioMetaSchema> = { error: 'Unknown error' };
      try {
        data = await resp.json();
      } catch {
        // Parsing the body failed
      }
      if (!resp.ok) {
        data.is_blocked = resp.status === 423 || resp.status === 429;
      }

      return {
        ...defaultStreamState,
        ...data,
      };
    } catch (error) {
      return {
        ...defaultStreamState,
        error: error.toString(),
      };
    }
  };

  @action
  getLicenceAudio = async (identity: string) => {
    const url = `${this.env.audioUri}/download/url`;
    const body = JSON.stringify({ licence_id: identity });
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    const resp = await this.request.post({ url, body, headers });
    const json = (await resp.json()) as { url: string; downloadLimitReached: boolean };

    return json;
  };

  @action
  acknowledgeDownload = async (identity: string) => {
    const url = `${this.env.audioUri}/download/acknowledge`;
    const body = JSON.stringify({ licence_id: identity });
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    const resp = await this.request.post({ url, body, headers });
    const json = (await resp.json()) as APIResponse<{}>;

    return json;
  };
}

export const defaultStreamState: AudioMetaSchema = {
  error: null,
  playlist: null,
  audio: null,
  type: null,
  duration: 0,
  start: 0,
  end: 0,
  waveform: null,
  is_blocked: false,
  is_restricted: false,
  ratelimit_limit: 0,
  ratelimit_remaining: 0,
};
