import { AnyObject, isArray, isEmpty } from './objects';
import React, { CSSProperties, ImgHTMLAttributes, ReactNode, ReactNodeArray } from 'react';
import { getUid } from './string';
import { UtmLink } from '../atoms/UtmLink';
import {
  GradientDirection,
  PrismicImage,
  PrismicRichTextComponentName,
  PrismicRichTextContentElement,
  PrismicRichTextElement,
  PrismicRichTextImageElement,
} from './types';
import { NestedCSSProperties } from 'typestyle/lib/types';

export const resolveGradientDirection = (direction: GradientDirection) => {
  switch (direction) {
    case GradientDirection.HORIZONTAL:
      return 'to right';
    case GradientDirection.VERTICAL:
      return 'to top';
    case GradientDirection.DIAGONAL_UP:
      return 'to top right';
    case GradientDirection.DIAGONAL_DOWN:
      return 'to bottom right';
  }
};

export type BackgroundProps = {
  src?: string;
  gradient?: { from: string; to?: string; direction?: GradientDirection };
};
type ResolveBackgroundProps = (props: BackgroundProps) => Pick<CSSProperties, 'backgroundImage' | 'backgroundColor'>;
export const resolveBackgroundProps: ResolveBackgroundProps = ({ src, gradient }) => {
  const styles: CSSProperties = {};
  if (src) {
    // Will cover any background image, takes precedence
    styles.backgroundImage = `url('${src}')`;
  } else if (gradient && gradient.to && gradient.from) {
    styles.backgroundImage = `linear-gradient(${resolveGradientDirection(gradient.direction) || 'to right'}, ${
      gradient.from
    }, ${gradient.to})`;
  } else if (gradient && (gradient.to || gradient.from)) {
    styles.backgroundColor = gradient.to || gradient.from;
  }
  return styles;
};

const NumericListItem: React.FC = ({ children, ...props }) =>
  React.createElement('li', { style: { listStyle: 'decimal', ...props } }, children);

export const prismicElementMap: { [key in PrismicRichTextComponentName]: string | React.ComponentType } = {
  paragraph: 'p',
  heading1: 'h1',
  heading2: 'h2',
  heading3: 'h3',
  heading4: 'h4',
  heading5: 'h5',
  heading6: 'h6',
  strong: 'strong',
  em: 'em',
  span: 'span',
  div: 'div',
  'list-item': 'li',
  'o-list-item': NumericListItem, // This will render a numeric list item but actual numbering may still be an issue
  image: 'img',
  hyperlink: UtmLink,
  fragment: React.Fragment,
};

const getPrismicElementProps = (operation: ElementOperation): AnyObject => {
  if (operation.element === 'hyperlink' && !isEmpty(operation.data)) {
    return { href: operation.data.url };
  }

  return {};
};

export const splitPrismicRichText = (contentObject: PrismicRichTextElement) => {
  const fragments = [];
  if (isEmpty(contentObject) || contentObject.type === 'image') return fragments;
  const { text, spans: spansOriginal } = contentObject;
  const spans = Array.from(spansOriginal);
  let previous = 0;
  while (spans.length > 0) {
    const span = spans.shift();
    fragments.push(text.substring(previous, span.start));
    fragments.push(text.substring(span.start, span.end));
    previous = span.end;
  }
  fragments.push(text.substr(previous));
  return fragments;
};

enum OperationType {
  OPEN,
  CLOSE,
}
type ElementOperation = {
  type: OperationType;
  element: PrismicRichTextComponentName;
  position: number;
  data?: { [key: string]: string };
};

const renderPrismicRichTextChildren = (text: string, ops: ElementOperation[]) => {
  const openOp = ops.shift();
  const element = prismicElementMap[openOp.element];
  const children: ReactNode[] = [text.substring(openOp.position, ops[0].position)];
  // Don't worry about checking for the next op element type, sometimes they're not in order, but well formed data will nest correctly via recursion
  while (ops[0].type !== OperationType.CLOSE) {
    const { childEnd, childComponent } = renderPrismicRichTextChildren(text, ops);
    children.push(childComponent);
    children.push(text.substring(childEnd, ops[0].position));
  }
  const props = getPrismicElementProps(openOp);
  const component = React.createElement(element, { key: getUid(), ...props }, children);
  return { childEnd: ops.shift().position, childComponent: component };
};

const renderPrismicImage = (contentObject: PrismicImage) => {
  const { url, alt, dimensions } = contentObject;
  return React.createElement<ImgHTMLAttributes<HTMLImageElement>>('img', {
    key: getUid(),
    src: url,
    alt,
    ...dimensions,
  });
};

export const renderPrismicRichText = (
  contentObject: PrismicRichTextElement,
  overrideWrapperElement?: PrismicRichTextComponentName
) => {
  if (isEmpty(contentObject)) return null;
  if (contentObject.type === 'image') {
    return renderPrismicImage(contentObject);
  }
  const { text, spans: spansOriginal } = contentObject;
  const wrapperComponentName = overrideWrapperElement || contentObject.type;
  if (spansOriginal.length === 0) {
    // This is a plain text element, return a simple React element
    const wrapperElement = prismicElementMap[wrapperComponentName];
    return React.createElement(wrapperElement, { key: getUid() }, text);
  }
  // This element has nested elements for rich text formatting, arrange as operations
  const spans = Array.from(spansOriginal);
  const firstOp = { type: OperationType.OPEN, element: wrapperComponentName, position: 0 };
  const lastOp = { type: OperationType.CLOSE, element: wrapperComponentName, position: text.length };
  const ops: ElementOperation[] = [firstOp, lastOp];
  spans.forEach((span) => {
    const commonProps: Pick<ElementOperation, 'element' | 'data'> = { element: span.type };
    if ('data' in span) {
      commonProps.data = span.data;
    }
    ops.push({ type: OperationType.OPEN, position: span.start, ...commonProps });
    ops.push({ type: OperationType.CLOSE, position: span.end, ...commonProps });
  });
  ops.sort((a, b) => {
    if (a === firstOp || b === lastOp) return -1;
    if (b === firstOp || a === lastOp) return 1;
    if (a.position === b.position) return 0;
    return Number(a.position > b.position) * 2 - 1;
  });
  return renderPrismicRichTextChildren(text, ops).childComponent;
};

export const renderPrismicRichTextArray = (
  contentArray: PrismicRichTextElement[],
  overrideWrapperElement?: PrismicRichTextComponentName
): React.ReactNode[] =>
  isArray(contentArray) ? contentArray.map((item) => renderPrismicRichText(item, overrideWrapperElement)) : [];
