import * as React from 'react';
import { Component, inject, deps, observer } from '../lib/component';
import { classes, media, style } from 'typestyle';
import { percent, px, viewWidth } from 'csx';
import { centerCenter, horizontal, wrap } from 'csstips';
import { Loading } from '../components/loading/loading';
import { FavouriteArtistButtonCompat } from '../components/compat/favourite-artist-button-compat';
import { ButtonPill } from '../components/button-pill';
import { ArtistTile } from '../components/artist-tile';
import { colorGunmetal, colorLightGrey, colorSubtle, colorWhite } from '../theme/color';
import { mediaMobileOnly, mediaTablet, mediaTabletOnly } from '../theme/media';
import { AccountCommunicationPage } from './account-communication';
import { AnalyticsController } from '../modules/analytics/controller';
import PageAccountFavouritesListing from '../components/page-account-favourites-listing';
import { BROWSE_ROUTE, SEARCH_ROUTE } from '../constants';
import { PlaylistTile } from '../components/project-happy/molecules/PlaylistTile';
import FavouritePlaylistButtonCompat from '../components/compat/favourite-playlist-button-compat';
import { TrackLine } from '../components/project-happy/molecules/TrackLine';
import { AccountLayout } from '../components/project-happy/templates/AccountLayout';

export interface AccountFavouritesPageProps {
  i18n?: {
    loading: string;
    title: string;
    titleTracks: string;
    titleArtists: string;
    titlePlaylists: string;
  };
  analytics: AnalyticsController;
  onClickLink(event: React.MouseEvent<any>): any;
}

const TRACK_LISTING = 'track-listing';
const ARTIST_LISTING = 'artist-listing';
const PLAYLIST_LISTING = 'playlist-listing';

@inject(deps)
@observer
export class AccountFavouritesPage extends Component<AccountFavouritesPageProps> {
  render() {
    const { i18n = AccountFavouritesPage.i18n, analytics, onClickLink } = this.props;
    const model = this.props.model.page.favourites;
    const controller = this.props.controller.page.favourites;
    const { isMobile } = this.props.model.ui;
    const { preloadImage, preloadWaveformSvg } = this.props.controller.image;
    const { handleClickLink } = this.props.controller.ui;

    if (model.loading)
      return (
        <AccountLayout>
          <div className={AccountCommunicationPage.styles.containerLoading}>
            <Loading />
          </div>
        </AccountLayout>
      );

    return (
      <AccountLayout>
        <div
          className={classes(
            AccountFavouritesPage.styles.container,
            model.hasTracks && AccountFavouritesPage.styles.withTracks
          )}
        >
          <PageAccountFavouritesListing
            className={TRACK_LISTING}
            title={i18n.titleTracks}
            isEmpty={!model.hasTracks}
            emptyText="You don't have any favourite tracks yet"
            emptyHref={SEARCH_ROUTE}
            emptyCta="Search tracks"
            onClickLink={handleClickLink}
          >
            {model.infiniteTracks.tracks.map((track, i) => (
              <div key={i} className={AccountFavouritesPage.styles.trackLineWrapper}>
                <TrackLine track={track} onClickLink={onClickLink} />
              </div>
            ))}
            {model.hasMoreTracks && !model.loadingMoreTracks && (
              <div className={AccountFavouritesPage.styles.loadMore}>
                <ButtonPill onClick={controller.loadMoreTracks}>Load more</ButtonPill>
              </div>
            )}
            {model.loadingMoreTracks && (
              <div className={AccountFavouritesPage.styles.loadMore}>
                <Loading />
              </div>
            )}
          </PageAccountFavouritesListing>
          <PageAccountFavouritesListing
            className={ARTIST_LISTING}
            title={i18n.titleArtists}
            isEmpty={!model.hasArtists}
            emptyText="You don't have any favourite artists yet"
            emptyHref={SEARCH_ROUTE}
            emptyCta="Search artists"
            onClickLink={handleClickLink}
          >
            <div data-test-favourited-artists className={AccountFavouritesPage.styles.grid}>
              {model.filteredArtists.map((artist, index) => (
                <ArtistTile
                  className={AccountFavouritesPage.styles.artistTile}
                  key={index.toString()}
                  artist={artist}
                  preload={preloadImage}
                  Favourite={FavouriteArtistButtonCompat}
                  onClickLink={handleClickLink}
                />
              ))}
            </div>
            {model.hasMoreArtists && !model.loadingMoreArtists && (
              <div className={AccountFavouritesPage.styles.loadMore}>
                <ButtonPill onClick={controller.loadMoreArtists}>Load more</ButtonPill>
              </div>
            )}
            {model.loadingMoreArtists && (
              <div className={AccountFavouritesPage.styles.loadMore}>
                <Loading />
              </div>
            )}
          </PageAccountFavouritesListing>
          <PageAccountFavouritesListing
            className={PLAYLIST_LISTING}
            title={i18n.titlePlaylists}
            isEmpty={!model.hasPlaylists}
            emptyText="You don't have any favourite playlists yet"
            emptyHref={BROWSE_ROUTE}
            emptyCta="Browse playlists"
            onClickLink={handleClickLink}
          >
            <div data-test-favourited-playlists className={AccountFavouritesPage.styles.grid}>
              {model.filteredPlaylists.map((playlist, index) => (
                <PlaylistTile key={index} className={AccountFavouritesPage.styles.playlistTile} playlist={playlist}>
                  <FavouritePlaylistButtonCompat identity={playlist.uuid} />
                </PlaylistTile>
              ))}
            </div>
            {model.hasMoreArtists && !model.loadingMoreArtists && (
              <div className={AccountFavouritesPage.styles.loadMore}>
                <ButtonPill onClick={controller.loadMorePlaylists}>Load more</ButtonPill>
              </div>
            )}
            {model.loadingMoreArtists && (
              <div className={AccountFavouritesPage.styles.loadMore}>
                <Loading />
              </div>
            )}
          </PageAccountFavouritesListing>
        </div>
      </AccountLayout>
    );
  }

  static i18n = {
    loading: 'Loading...',
    title: 'Favourites',
    titleTracks: 'Favourite tracks',
    titleArtists: 'Favourite artists',
    titlePlaylists: 'Favourite playlists',
  };

  static styles = {
    container: style(
      mediaTablet({
        display: 'grid',
        gridTemplate: 'repeat(2, auto) / repeat(3, 1fr)',
        gridGap: px(20),
      })
    ),
    withTracks: style(
      mediaTablet({
        $nest: {
          [`& > .${TRACK_LISTING}`]: {
            gridArea: '1/1/3/3',
          },
          [`& > .${ARTIST_LISTING}`]: {
            gridArea: '1/3/2/4',
          },
          [`& > .${PLAYLIST_LISTING}`]: {
            gridArea: '2/3/3/4',
          },
        },
      })
    ),

    loading: style({
      height: px(100),
      ...centerCenter,
    }),
    body: style({
      padding: px(20),
      background: colorGunmetal.toString(),
      color: colorWhite.toString(),
    }),
    split: style(
      {
        ...horizontal,
        margin: 0,
      },
      mediaMobileOnly({
        flexDirection: 'column-reverse',
        padding: '0 20px',
      })
    ),
    wrapper: style(
      {
        position: 'relative',
        height: percent(100),
        margin: 0,
      },
      mediaTablet({
        marginLeft: px(20),
      })
    ),
    main: style(
      {
        overflow: 'hidden',
        flex: 2,
      },
      mediaMobileOnly({
        overflow: 'auto',
        width: percent(100),
        margin: 0,
        height: 'auto',
        flex: 'none',
      })
    ),
    aside: style(
      {
        flex: 1,
      },
      mediaMobileOnly({
        width: percent(100),
        margin: 0,
        height: 'auto',
        flex: 'none',
      })
    ),
    heading: style({
      margin: '30px 0',
    }),
    loadMore: style({
      ...centerCenter,
      padding: '20px',
    }),
    grid: style({
      ...horizontal,
      ...wrap,
      width: percent(100),
    }),
    artistTile: style({
      width: percent(50),
    }),
    playlistTile: style({
      width: 'calc(50% - 16px)',
    }),
    trackLineWrapper: style({
      borderBottom: `1px solid ${colorLightGrey.toString()}`,
      maxWidth: '1000px',
    }),
  };
}
