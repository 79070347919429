import { computed, observable } from 'mobx';
import {ChannelSchema, PricingCalculatorSchema, RatecardsSchema} from '../../types/schema';

export class PricingModel {
  @observable
  channelId: string = '';

  @observable
  loading: boolean = false;

  @observable
  searching: boolean = false;

  @observable
  query: string = '';

  @observable
  channels: Array<ChannelSchema> = [];

  @observable
  error: string = null;

  @observable
  result: PricingCalculatorSchema = null;

  @observable
  currency: { value: string; label: string } = {
    value: 'USD',
    label: '$ USD',
  };

  @observable
  currencies: Array<{ value: string; label: string }> = [
    {
      value: 'USD',
      label: '$ USD',
    },
    {
      value: 'EUR',
      label: '€ EUR',
    },
    {
      value: 'GBP',
      label: '£ GBP',
    }
  ];

  @observable
  ratecards: RatecardsSchema;

  @observable
  ratecardTypes: Array<{ value: string, label: string }> = [
    { value: 'standard', label: 'Non-Branded' },
  ];

  @observable
  ratecardType: string = 'standard';

  @computed
  get selectedRatecardType(): { value: string, label: string } {
    return this.ratecardTypes.find(t => t.value === this.ratecardType);
  }

  @observable
  ratecardLoading: boolean = false;

  @observable
  rates: Array<{
    id: string;
    label: string;
    value: string;
    available: boolean;
  }> = [
    {
      id: 'standard',
      value: `25% off your first track`,
      label: 'Standard',
      available: true
    },
  ];

  @observable
  ratesLoading: boolean;

  @observable
  unsubscribedRates: RatecardsSchema;

  @observable
  subscribedRates: RatecardsSchema;
}