import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const HeartOutline: React.SFC<SVGIconProps> = ({ size = 18, color = '#262E3F', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.55 20.03L12 21.35L13.45 20.04L13.4895 20.0041C18.6174 15.3442 22 12.2703 22 8.5C22 5.42 19.58 3 16.5 3C15.4028 3 14.3334 3.32208 13.4198 3.88099C12.8845 4.20843 12.4027 4.61714 12 5.09C11.5973 4.61714 11.1155 4.20843 10.5802 3.88099C9.66658 3.32208 8.59721 3 7.5 3C4.42 3 2 5.42 2 8.5C2 12.2769 5.39442 15.3549 10.5373 20.0185L10.55 20.03ZM12.0049 17.2993C14.352 15.1633 16.1097 13.5311 17.3335 12.0339C18.6203 10.4596 19 9.40459 19 8.5C19 7.07685 17.9231 6 16.5 6C15.6684 6 14.8233 6.40181 14.2841 7.03502L12 9.71721L9.71594 7.03502C9.17673 6.40181 8.33164 6 7.5 6C6.07685 6 5 7.07685 5 8.5C5 9.40455 5.37973 10.4594 6.66591 12.0319C7.89113 13.5298 9.65234 15.1628 12.0049 17.2993Z"
      fill={color}
    />
  </BaseSvg>
);
