import { centerCenter, centerJustified, flexRoot, vertical } from 'csstips';
import { px } from 'csx';
import * as React from 'react';
import { style } from 'typestyle';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import { rebrand } from '../../../theme/color';
import { Loading } from '../../loading/loading';
import { defaultHeadingStyles } from '../utilities/font';
import { mediaDesktop } from '../utilities/mediaQueries';
import { UserPlaylistThumbnail } from '../atoms/UserPlaylistThumbnail';
import isEmpty from 'lodash/isEmpty';
import { ControlledTrackList } from '../organisms/ControlledTrackList';
import { rebrandPageClass } from '../utilities/page';
import { ACCOUNT_PLAYLISTS_ROUTE } from '../../../constants';
import { Link } from '../atoms/Link';
import { PlaylistSchema } from '../../../types/schema';
import { Page404 } from '../../page-404';
import { Container } from '../atoms/Container';
import SimpleMasthead from '../molecules/mastheads/SimpleMasthead';
import { borderRadius } from '../../../theme/border';

const styles = {
  backLinkWrapper: style({
    marginBottom: px(16),
  }),
  backLink: style({
    color: rebrand.contrast['10'].toString(),
    fontSize: px(16),
  }),
  wrapper: style({
    backgroundColor: rebrand.neutralOnDark[10].toString(),
    paddingBottom: px(32),
  }),
  masthead: style(
    flexRoot,
    centerCenter,
    mediaDesktop({
      justifyContent: 'flex-start',
    })
  ),
  thumbnail: style(
    {
      borderRadius: borderRadius.MEDIUM,
      width: px(125),
      height: px(125),
      overflow: 'hidden',
    },
    mediaDesktop({
      width: px(152),
      height: px(152),
    })
  ),
  metadata: style(vertical, centerJustified, { marginLeft: px(16) }),
  subtitle: style({
    fontSize: px(12),
    color: rebrand.light2.toString(),
    margin: 0,
  }),
  title: style(
    {
      fontSize: px(32),
      margin: 0,
      $nest: { [`.${rebrandPageClass} &`]: defaultHeadingStyles },
    },
    mediaDesktop({
      fontSize: px(60),
    })
  ),
  empty: style(centerCenter, {
    height: px(120),
    fontWeight: 200,
    fontSize: px(18),
    color: rebrand.neutralOnLight['60'].toString(),
  }),
  loading: style({
    margin: '25vh 0',
  }),
  trackList: style({ margin: '0 auto' }),
};

export const UserPlaylistContext = React.createContext<PlaylistSchema>(null);

export const UserPlaylistPage = inject(deps)(
  observer(
    ({
      model: {
        auth: authModel,
        page: pageModel,
        ui: { showPlayer },
      },
      controller: { page: pageController },
    }: ComponentProps) => {
      const { user } = authModel;
      const { playlistMetadata, playlistTracks, playlistIdentity, loading } = pageModel.userPlaylists;
      const { userPlaylists } = pageController;

      const metadata = playlistMetadata.get(playlistIdentity);
      const paginatedTracks = playlistTracks.get(playlistIdentity);

      const notFound = isEmpty(paginatedTracks);

      if (notFound && loading) {
        return <Loading className={styles.loading} />;
      } else if (notFound) {
        return <Page404 />;
      }

      // Does the playlist belong to this user?
      const isLoggedInUser = !isEmpty(user) && user.identity === metadata.user_uuid;

      const {
        data: tracks,
        meta: { pagination },
      } = paginatedTracks;

      return (
        <div className={styles.wrapper}>
          <SimpleMasthead>
            <Container>
              {isLoggedInUser && (
                <div className={styles.backLinkWrapper}>
                  <Link className={styles.backLink} href={ACCOUNT_PLAYLISTS_ROUTE}>
                    &larr; Back to playlists
                  </Link>
                </div>
              )}
              <div className={styles.masthead}>
                <UserPlaylistThumbnail className={styles.thumbnail} playlist={metadata} />
                <div className={styles.metadata}>
                  <p className={styles.subtitle}>Playlist</p>
                  <h1 data-test-user-playlist-title className={styles.title}>
                    {metadata.name}
                  </h1>
                  <p data-test-user-playlist-description className={styles.subtitle}>
                    {metadata.description}
                  </p>
                </div>
              </div>
            </Container>
          </SimpleMasthead>

          {tracks.length === 0 && (
            <div className={styles.empty}>
              <p>{metadata.name} doesn&apos;t have any tracks in it yet.</p>
            </div>
          )}
          <UserPlaylistContext.Provider value={metadata}>
            <ControlledTrackList
              className={styles.trackList}
              tracks={tracks}
              totalTracks={pagination.total}
              onLoadMore={() => userPlaylists.loadPlaylistByIdentity(metadata.identity)}
              onRemoveTrackFromPlaylist={() => userPlaylists.loadPlaylistByIdentity(metadata.identity, true)}
              playerIsShowing={showPlayer}
            />
          </UserPlaylistContext.Provider>
        </div>
      );
    }
  )
);
