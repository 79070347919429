import * as React from 'react';
import { rebrand } from '../../../../theme/color';
import { IconListItem } from '../../atoms/IconListItem';
import { Tooltip } from '../../atoms/Tooltip';
import { InfoFilled } from '../../atoms/icons/InfoFilled';
import { CreditPlanTypes } from '../../utilities/types';
import { styles } from './styles';

// Colour used for iconlist icons
const ICON_COLOR = rebrand.secondary[60].toString();

// V2 PLAN FEATURES //

export const starterPlanFeatures = (additionalLicensePrice: string): React.ReactNode => (
  <>
    <IconListItem iconColor={ICON_COLOR}>
      1 Premium track credit included per month{' '}
      <Tooltip
        className={styles.tooltip}
        wrapperClassName={styles.tooltipIcon}
        positions={['top', 'bottom']}
        content={
          <span>
            Premium tracks are copyrighted “mainstream” songs which have been released by real artists for fans to enjoy
            listening to - you can get Premium track licenses on Lickd with credits
          </span>
        }
      >
        <InfoFilled size={14} color={rebrand.contrast[40].toString()} />
      </Tooltip>
    </IconListItem>
    <IconListItem iconColor={ICON_COLOR}>Premium track credits stay valid for 90 days after issue</IconListItem>
    <IconListItem iconColor={ICON_COLOR}>
      Additional Premium track licenses at {additionalLicensePrice}{' '}
      <Tooltip
        className={styles.tooltip}
        wrapperClassName={styles.tooltipIcon}
        positions={['top', 'bottom']}
        content={<span>Based on your YouTube channel&apos;s average video views</span>}
      >
        <InfoFilled size={14} color={rebrand.contrast[40].toString()} />
      </Tooltip>
    </IconListItem>
    <IconListItem iconColor={ICON_COLOR}>Unlimited royalty-free tracks</IconListItem>
    <IconListItem iconColor={ICON_COLOR}>All your copyright claims resolved</IconListItem>
  </>
);

export const plusPlanFeatures: React.ReactNode = (
  <>
    <IconListItem iconColor={ICON_COLOR}>Everything you get in Starter</IconListItem>
    <IconListItem iconColor={ICON_COLOR} icon="plus">
      1 additional Premium track credit per month
    </IconListItem>
    <IconListItem iconColor={ICON_COLOR} icon="plus">
      A monthly saving of 5% compared to Starter
    </IconListItem>
  </>
);

export const proPlanFeatures: React.ReactNode = (
  <>
    <IconListItem iconColor={ICON_COLOR}>Everything you get in Starter</IconListItem>
    <IconListItem iconColor={ICON_COLOR} icon="plus">
      3 additional Premium track credits per month
    </IconListItem>
    <IconListItem iconColor={ICON_COLOR} icon="plus">
      A monthly saving of 10% compared to Starter
    </IconListItem>
  </>
);

export const enterprisePlanFeatures: React.ReactNode = (
  <>
    <IconListItem iconColor={ICON_COLOR}>Exclusive licensing deals</IconListItem>
    <IconListItem iconColor={ICON_COLOR}>Branded content licensing</IconListItem>
    <IconListItem iconColor={ICON_COLOR}>Music selection support</IconListItem>
    <IconListItem iconColor={ICON_COLOR}>Partnership opportunities</IconListItem>
    <IconListItem iconColor={ICON_COLOR}>Personalised support</IconListItem>
  </>
);

export const getPlanFeatures = (planType: CreditPlanTypes, additionalLicensePrice: string): React.ReactNode => {
  switch (planType) {
    case CreditPlanTypes.STARTER:
      return starterPlanFeatures(additionalLicensePrice);
    case CreditPlanTypes.PLUS:
      return plusPlanFeatures;
    case CreditPlanTypes.PRO:
      return proPlanFeatures;
    default:
      return null;
  }
};

// V2 PLAN PLACEHOLDERS //
export interface PlanPlaceholder {
  name: CreditPlanTypes;
  premiumCredits: number;
}

export const placeholderPlans: PlanPlaceholder[] = [
  {
    name: CreditPlanTypes.STARTER,
    premiumCredits: 1,
  },
  {
    name: CreditPlanTypes.PLUS,
    premiumCredits: 2,
  },
  {
    name: CreditPlanTypes.PRO,
    premiumCredits: 4,
  },
];
