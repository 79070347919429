import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const Star: React.SFC<SVGIconProps> = ({ size = 14, color = '#262E3F', ...props }) => (
  <BaseSvg data-test-premium-star-circle width={size} height={size} viewBox="0 0 14 14" {...props}>
    <path
      d="M11.2302 13.5L7.00016 10.95L2.77016 13.5L3.89016 8.69L0.160156 5.46L5.08016 5.04L7.00016 0.5L8.92016 5.03L13.8402 5.45L10.1102 8.68L11.2302 13.5Z"
      fill={color}
    />
  </BaseSvg>
);
