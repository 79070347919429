import { SessionStorageModel } from '../../../modules/storage/model';
import { PROJECT_SUBSCRIPTION_PREVIEW } from '../../devtools/feature-flags';
import { PlansSlice } from '../organisms/slices/PlansSlice';
import * as React from 'react';

export const PrismicPlans: React.FC = (props) => {
  if (SessionStorageModel.getItem(PROJECT_SUBSCRIPTION_PREVIEW)) return null;

  return <PlansSlice {...props} />;
};
