import React from 'react';
import { observer } from '../../../../lib/component';
import { style } from 'typestyle';
import { centerCenter, vertical } from 'csstips';
import { ProgressBar } from '../../molecules/ProgressBar';
import { colorWhite } from '../../../../theme/color';
import { Container } from '../../atoms/Container';
import { spacing } from '../../../../theme/spacing';

export const registrationProgressFullHeight = 85;

const styles = {
  wrapper: style(centerCenter, vertical, {
    padding: `${spacing.LARGE} 0`,
    backgroundColor: colorWhite.toString(),
  }),
};

// Each registration step has a custom progress percentage
const progressPercentages = [12, 33, 65, 100];

type RegistrationProgressProps = { step: number };
export const RegistrationProgress = observer(({ step }: RegistrationProgressProps) => (
  <div className={styles.wrapper}>
    <Container wide>
      <ProgressBar steps={['Account', 'Plans', 'Complete']} progress={progressPercentages[step]} />
    </Container>
  </div>
));
