import * as React from 'react';
import { InputHTMLAttributes } from 'react';
import { classes, style } from 'typestyle';
import { AdditionalInputProps, commonInputStyles } from '../../utilities/inputs';

const styles = style(commonInputStyles);

export type InputProps = InputHTMLAttributes<HTMLInputElement> & AdditionalInputProps;
export const Input: React.FC<InputProps> = ({ className, isValid, ...props }) => (
  <input className={classes(styles, isValid === false && 'invalid', className)} {...props} />
);
