import isObject from './isObject';

export default function objectDifference<T extends object>(source: T, subtract: T): T {
  return Object.keys(source).reduce((out, key) => {
    const isEqual = source[key] === subtract[key];

    if (isEqual)
      return out;

    if (isObject(source[key]))
      return {
        ...(out as any),
        [key]: objectDifference(source[key], subtract[key])
      };

    return {
      ...(out as any),
      [key]: source[key]
    };
  }, {} as T)
}