import { style } from 'typestyle';
import * as React from 'react';
import { ModalChildStyle } from './modal';
import { em, px } from 'csx';
import { colorContrast, colorGunmetal, colorWhite } from '../theme/color';
import { AnalyticsController } from '../modules/analytics/controller';

interface ModalFlowProps {
  analytics: AnalyticsController;
  children: React.ReactElement[];
  className?: string;
}

export default class ModalFlow extends React.Component<ModalFlowProps, any> {
  constructor(props) {
    super(props);
    this.state = { screen: 0 };
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
  }

  render() {
    const children = React.Children.toArray(this.renderChildren());

    return <div className={this.props.className || ModalFlow.styles.modalContainer}>{children[this.state.screen]}</div>;
  }

  hasNext() {
    return Array.isArray(this.props.children) && this.state.screen < this.props.children.length - 1;
  }

  hasPrev() {
    return this.state.screen > 0;
  }

  handleNext() {
    this.setState((state) => ({
      screen: state.screen + 1,
    }));

    this.props.analytics.sendMixpanel('User clicks next');
  }

  handlePrev() {
    this.setState((state) => ({
      screen: state.screen - 1,
    }));

    this.props.analytics.sendMixpanel('User clicks back');
  }

  renderChildren() {
    return React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, {
        handleNext: this.handleNext,
        handlePrev: this.handlePrev,
        hasNext: this.hasNext(),
        hasPrev: this.hasPrev(),
      });
    });
  }
  static styles = {
    modalContainer: style(ModalChildStyle, {
      ...ModalChildStyle,
      width: px(600),
      maxWidth: 'calc(100vw - 4rem)',
      background: colorGunmetal.toString(),
      overflowX: 'hidden',
      $nest: {
        '&> h3': {
          color: colorContrast.toString(),
          fontSize: px(30),
          margin: '0 0 30px 0',
        },
        '& div': {
          color: colorWhite.toString(),
          lineHeight: em(1.4),
        },
        '& div:not(:last-child)': {
          margin: 0,
        },
      },
    }),
  };
}
