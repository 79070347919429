import React from 'react';
import { Button, ButtonProps } from './Button';
import { classes, cssRule, style } from 'typestyle';
import { ArrowRight } from '../icons/ArrowRight';
import { betweenJustified, center, flexRoot } from 'csstips';
import { px } from 'csx';
import { transitionQuickEase } from '../../../../theme/transition';
import { ButtonProminence, ButtonSize } from './buttonStyles';
import { LoadingDots } from '../icons/LoadingDots';
import { focusVisible } from '../../utilities/css';

const styles = {
  button: style(flexRoot, betweenJustified, center),
  arrow: style(flexRoot, {
    transition: `transform ${transitionQuickEase}`,
    justifySelf: 'flex-end',
    marginLeft: 'auto',
    paddingLeft: px(12),
  }),
  loading: style({
    height: px(16),
    justifySelf: 'flex-end',
    marginLeft: 'auto',
  }),
};

const fauxDisabled = 'disabled-action-button';

cssRule(
  `.${styles.button}.${fauxDisabled}`,
  {
    $nest: {
      [`&:hover > .${styles.arrow}`]: {
        transform: 'none !important',
      },
    },
  },
  focusVisible({
    $nest: {
      [`& > .${styles.arrow}`]: {
        transform: 'none !important',
      },
    },
  })
);

cssRule(
  `.${styles.button}:not(.disabled)`,
  {
    $nest: {
      [`&:hover > .${styles.arrow}`]: {
        transform: 'translateX(20%)',
      },
    },
  },
  focusVisible({
    $nest: {
      [`& > .${styles.arrow}`]: {
        transform: 'translateX(20%)',
      },
    },
  })
);

export type ActionButtonProps = ButtonProps & { loading?: boolean; arrowSize?: number };

export const ActionButton: React.FC<ActionButtonProps> = ({
  children,
  className,
  prominence,
  size,
  arrowSize = 21,
  loading = false,
  ...props
}) => (
  <Button
    className={classes(styles.button, className, (prominence === ButtonProminence.DISABLED || loading) && fauxDisabled)}
    prominence={Number.isFinite(prominence) ? prominence : ButtonProminence.PRIMARY}
    size={size || ButtonSize.LARGE}
    {...props}
  >
    {children}
    <span className={styles.arrow}>
      {loading !== true && <ArrowRight size={arrowSize} color="currentColor" />}
      {loading === true && <LoadingDots size={arrowSize} />}
    </span>
  </Button>
);
