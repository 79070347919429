import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isEmpty';
import { CacheModel } from './model';
import { action } from 'mobx';
import { ArtistSchema, TrackSchema } from '../../types/schema';

export class CacheController {
  constructor(private model: CacheModel) {}

  @action
  addTrack = (track: TrackSchema) => {
    if (!isEmpty(track) && !isNil(track.identity)) {
      this.model.track[track.identity] = track;
    }
  };

  @action
  addTracks = (tracks: Array<TrackSchema>) => {
    if (!isEmpty(tracks)) {
      tracks.forEach(this.addTrack);
    }
  };

  @action
  addArtist = (artist: ArtistSchema) => {
    if (!isEmpty(artist) && !isNil(artist.identity)) {
      this.model.artist[artist.identity] = artist;
    }
  };

  @action
  addArtists = (artists: Array<ArtistSchema>) => {
    if (!isEmpty(artists)) {
      artists.forEach(this.addArtist);
    }
  };
}
