import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { Component, deps, inject } from '../../../lib/component';
import { observer } from 'mobx-react';
import { em, important, percent, px } from 'csx';
import { shadowElevation2 } from '../../../theme/shadow';
import { mixinTextEllipsis } from '../../../theme/mixin';
import { transitionQuickEase } from '../../../theme/transition';
import { UtmLink } from '../atoms/UtmLink';
import { mediaUpToDesktop } from '../utilities/mediaQueries';
import { colorBlack, rebrand } from '../../../theme/color';
import { BackgroundProps, resolveBackgroundProps } from '../utilities/props';
import { defaultHeadingStyles, heading4 } from '../utilities/font';
import { mediaFinePointer } from '../../../theme/media';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { AnalyticsContextProvider } from '../utilities/AnalyticsContext';
import { getCarouselBackgroundImage } from '../atoms/CarouselImage';
import { defaultButtonFocusOutline } from '../atoms/button/buttonStyles';

const commonStyles = {
  wrapper: style({
    position: 'relative',
    borderRadius: '8px',
    margin: px(8),
  }),
  link: style({
    display: 'block',
    $nest: {
      '&::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        borderRadius: '8px',
      },
      '&:hover': { textDecoration: 'none' },
      '&:focus': { textDecoration: 'none' },
    },
  }),
  tile: style({
    width: percent(100),
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: em(0.3),
    transition: `box-shadow ${transitionQuickEase}`,
    $nest: {
      ':hover > &': shadowElevation2,
      ':focus-within > &': shadowElevation2,
    },
  }),
  name: style({
    fontWeight: 700,
    fontSize: px(16),
    color: rebrand.contrast[50].toString(),
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  type: style({
    fontSize: px(16),
    fontWeight: 400,
    color: rebrand.neutralOnLight[70].toString(),
    textTransform: 'capitalize',
  }),

  noBoxShadow: style({
    boxShadow: 'none',
  }),
};

const aspectRatio = 47.5;
const rectangle: NestedCSSProperties = { paddingBottom: percent(aspectRatio), position: 'relative' };
const square: NestedCSSProperties = { paddingBottom: percent(100) };
const largeStyles = {
  link: style(defaultHeadingStyles, heading4, {
    position: 'absolute',
    display: important('flex'),
    alignItems: 'center',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: '1rem 45% 1rem 1rem',
    color: rebrand.dark1.toHexString(),
  }),
  tile: style(rectangle, {
    position: 'relative',
  }),
  squareLink: style(square, {
    paddingTop: `calc(50% - 24px)`,
  }),
  squareTile: style(square),
};

const smallStyles = {
  link: style({
    fontSize: px(12),
    color: colorBlack.toHexString(),
    fontWeight: 600,
    lineHeight: px(16),
    marginTop: '12px',
    $nest: {
      '& > span': mixinTextEllipsis,
    },
  }),
  tile: style({
    paddingBottom: percent(100),
  }),
};

cssRaw(`
@supports (-webkit-line-clamp: 2) {
    .${smallStyles.link} > span {
      white-space: normal !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
}`);

export type LinkTileProps = BackgroundProps & {
  className?: string;
  href: string;
  heading: string;
  compact?: boolean;
  square?: boolean;
  isSearchPage?: boolean;
  type?: string;
};

@inject(deps)
@observer
export class LinkTile extends Component<LinkTileProps> {
  render() {
    const {
      className,
      href,
      heading,
      compact = false,
      square = false,
      isSearchPage,
      type,
      ...backgroundProps
    } = this.props;

    const dataAttributes = Object.entries(backgroundProps)
      .filter(([key]) => key.startsWith('data-'))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    const backgroundStyles =
      'src' in backgroundProps
        ? style(getCarouselBackgroundImage(backgroundProps.src, compact))
        : style(resolveBackgroundProps(backgroundProps));

    const sizeStyles = compact ? smallStyles : largeStyles;
    const shapeTileStyles = !compact && square && largeStyles.squareTile;
    const shapeLinkStyles = !compact && square && largeStyles.squareLink;

    return (
      <AnalyticsContextProvider context={{ linkTileHeading: heading }}>
        <div className={classes(commonStyles.wrapper, className)} {...dataAttributes}>
          <div
            className={classes(
              commonStyles.tile,
              sizeStyles.tile,
              shapeTileStyles,
              backgroundStyles,
              isSearchPage && commonStyles.noBoxShadow
            )}
          ></div>
          <UtmLink href={href} className={classes(commonStyles.link, shapeLinkStyles, sizeStyles.link)}>
            <span className={isSearchPage ? commonStyles.name : ''}>{heading}</span>
            {isSearchPage && <span className={commonStyles.type}>{type}</span>}
          </UtmLink>
        </div>
      </AnalyticsContextProvider>
    );
  }
}
