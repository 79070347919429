import React from 'react';
import { Modal } from '../modal';
import modalStyles from '../../styles/modal';
import { classes, style } from 'typestyle';
import { percent, px, em } from 'csx';
import { Button } from '../project-happy/atoms/button/Button';
import { ButtonProminence } from '../project-happy/atoms/button/buttonStyles';
import scrollableOverlay, {
  ScrollableOverlayHeaderStyle,
  ScrollableOverlayTitleStyle,
} from '../../styles/scrollable-overlay';
import { SUPPORT_HUB } from '../../constants';

interface PaidIntegrityMismatchErrorModalProps {
  show: boolean;
}

const styles = {
  modal: style({ maxWidth: px(640), width: percent(50), minWidth: px(400), margin: '0 auto' }),
  header: style(ScrollableOverlayHeaderStyle, {
    padding: '1rem',
    borderBottom: '1px solid #E0E4E9',
  }),
  heading: style(ScrollableOverlayTitleStyle),
  content: style({ padding: '0 2em' }),
  button: style({ display: 'block', margin: '0 auto' }),
  helpText: style({ fontSize: em(0.75), textAlign: 'center' }),
};

export default class PaidIntegrityMismatchErrorModal extends React.Component<PaidIntegrityMismatchErrorModalProps> {
  render() {
    const { show } = this.props;
    return (
      <Modal show={show} onRequestClose={void 0}>
        <div className={classes(scrollableOverlay.wrapper, styles.modal)}>
          <header className={styles.header}>
            <h1 className={styles.heading}>Checkout updated</h1>
          </header>
          <div className={styles.content}>
            <p>
              Changes have been made to your checkout since this page was loaded. Refresh your browser to see the
              changes.
            </p>
            <Button
              className={styles.button}
              prominence={ButtonProminence.PRIMARY}
              onClick={() => window.location.reload()}
            >
              Refresh
            </Button>
            <p className={styles.helpText}>
              Seeing this error repeatedly? Please <a href={SUPPORT_HUB}>submit a support ticket</a> or use the support
              chat for assistance.
            </p>
          </div>
        </div>
      </Modal>
    );
  }
}
