import { createSimpleSchema, list, object } from 'serializr';

export const SerializeImagesSchema = createSimpleSchema({
  identity: true
});

export const SerializeArtistSchema = createSimpleSchema({
  birthday: true,
  formed: true,
  hometown: true,
  is_solo_performer: true,
  identity: true,
  name: true,
  description: true,
  is_featured: true,
  slug: true,
  link: object(createSimpleSchema({
    details: true,
    similar: true,
    releases: true,
    tracks: true,
  })),
  images: object(SerializeImagesSchema)
});

export const SerializeTrackSchema = createSimpleSchema({
  identity: true,
  title: true,
  slug: true,
  duration: true,
  is_featured: true,
  is_charting: true,
  is_new_release: true,
  link: object(createSimpleSchema({
    details: true,
    similar: true
  })),
  links: object(createSimpleSchema({
    spotify_artist_url: true,
    spotify_track_url: true,
    spotify_release_url: true,
    hookd_artist_url: true,
  })),
  images: object(SerializeImagesSchema),
  artists: list(object(SerializeArtistSchema)),
  audio: object(createSimpleSchema({
    url: true,
    identity: true
  })),
  branded_content_allowed: true,
  share_url: true,
  tempo: true
});