import * as React from 'react';
import { classes, media, style } from 'typestyle';
import { Component, ComponentProps, deps, inject, observer } from '../../../../lib/component';
import { LOAD_STATE } from '../../../../types/api';

import { styles } from '../plans-v2/styles';

import { SUPPORT_HUB } from '../../../../constants';
import { isEmpty } from '../../utilities/objects';
import { PaymentPlansPlaceholder } from '../../molecules/plans-v2/PaymentPlansPlaceholder';
import { spacing } from '../../../../theme/spacing';
import { flexRoot, centerJustified, wrap } from 'csstips';
import { Loading } from '../../../loading/loading';
import { PlanV2 } from './PlanV2';
import { SubscriptionChangeTypes } from '../../utilities/types';
import { px } from 'csx';
import { borderRadius } from '../../../../theme/border';
import { rebrand } from '../../../../theme/color';

const localStyles = {
  infoBox: style({
    position: 'relative',
    border: `1px solid ${rebrand.neutralOnDark[20].toString()}`,
    borderRadius: borderRadius.MEDIUM,
    padding: spacing.DEFAULT,
    marginTop: spacing.DEFAULT,
    fontSize: px(12),
    $nest: {
      '& p': {
        margin: 0,
        textAlign: 'center',
      },
    },
  }),
  wrapper: style({
    marginTop: spacing.XXXLARGE,
  }),
  plans: style(
    flexRoot,
    centerJustified,
    wrap,
    {
      margin: `${spacing.XXXLARGE} auto 0`,
      gap: spacing.DEFAULT,
    },
    media(
      { minWidth: '1600px' },
      {
        flexWrap: 'nowrap',
        '-webkit-flex-wrap': 'nowrap',
      }
    )
  ),
};

@inject(deps)
@observer
export class AccountPlansV2 extends Component<ComponentProps> {
  constructor(props) {
    super(props);
  }

  componentDidMount(): void {
    const { router } = this.props.model;
    const { showPostUpgradeModal } = this.props.controller.modal;
    const { pollChannelForChanges } = this.props.controller.subscription;

    // Show post-upgrade confirmation modal if coming back from Stripe
    if (router.location.query && router.location.query['success'] === 'true') {
      const planName = router.location.query['plan'] || null;
      const planCredits = router.location.query['credits'] || null;

      if (planName) {
        showPostUpgradeModal(planName, planCredits);
        // Re-fetch channel data if the new plan doesn't show as selected
        pollChannelForChanges(SubscriptionChangeTypes.UPGRADE, planName);
      }
    }
  }

  render() {
    const {
      model: {
        subscription: {
          creditSubscriptionPlans,
          creditSubscriptionPlansState,
          v2PlansLoadError,
          v2PlanIsUpdating,
          v2PlansSubscribeError,
          pendingChannelUpdate,
        },
        user: { user, loading, channel, loadingChannels, isSubscribedToV2PlanAndCancelled },
      },
    } = this.props;

    const isLoading =
      isEmpty(user) ||
      loading ||
      loadingChannels ||
      creditSubscriptionPlansState === LOAD_STATE.LOADING ||
      pendingChannelUpdate;

    const sortedPlans = [...creditSubscriptionPlans].sort((a, b) => a.price - b.price);

    if (isLoading)
      return (
        <div className={localStyles.wrapper}>
          <Loading />
        </div>
      );

    return (
      <>
        {!isEmpty(channel) && isSubscribedToV2PlanAndCancelled && (
          <div className={localStyles.infoBox}>
            <p>
              Your subscription has been canceled. Your subscription will remain active for the rest of your billing
              cycle. During this time, you&apos;ll still have access to cheaper Premium tracks and royalty-free music.
              Once your billing cycle ends, you&apos;ll no longer receive these benefits, but you can then re-subscribe
              at any time.
            </p>
          </div>
        )}

        <div className={localStyles.wrapper}>
          {/* No channel connected */}
          {isEmpty(channel) && (
            <PaymentPlansPlaceholder className={classes(localStyles.plans, styles.plansNoChannel, 'fade-to-white')} />
          )}

          {/* Channel connected */}
          {!isEmpty(channel) && creditSubscriptionPlansState === LOAD_STATE.READY && (
            <div className={localStyles.plans}>
              {sortedPlans.map((plan) => (
                <PlanV2 key={plan.plan_name} plan={plan} allPlans={sortedPlans} planLoadingState={v2PlanIsUpdating} />
              ))}
            </div>
          )}

          {creditSubscriptionPlansState === LOAD_STATE.ERROR && (
            <p className={styles.subscribeError}>
              {v2PlansLoadError} <a href={SUPPORT_HUB}>Submit a support ticket</a> if this issue persists.
            </p>
          )}
          {v2PlansSubscribeError && (
            <p className={styles.subscribeError}>
              {v2PlansSubscribeError} <a href={SUPPORT_HUB}>Submit a support ticket</a> if this issue persists.
            </p>
          )}
        </div>
      </>
    );
  }
}
