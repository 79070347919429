import {
  colorBlack,
  colorBrand,
  colorContrast,
  colorGradientHeroBackgroundCenter,
  colorGradientHeroBackgroundMid,
  colorGradientHeroBackgroundOuter,
  colorGradientHeroCenter,
  colorGradientHeroMid,
  colorGradientHeroOuter,
  colorGradientPricingCenter,
  colorGradientPricingMid,
  colorGradientPricingOuter,
  colorGunmetal,
} from './color';

export const gradientHero = `radial-gradient(
  circle at 6% 10%,
  ${colorGradientHeroCenter.toString()},
  ${colorGradientHeroMid.toString()} 68%,
  ${colorGradientHeroOuter.toString()}
)`;

export const gradientPricing = `radial-gradient(
  circle at -8% 0,
  ${colorGradientPricingCenter.toString()},
  ${colorGradientPricingMid.toString()} 68%,
  ${colorGradientPricingOuter.toString()}
)`;

export const gradientTrackTile = `linear-gradient(
  0deg,
  ${colorGunmetal.toString()},
  rgba(0,0,0,0) 30%,
  rgba(0,0,0,0)
)`;

export const gradientCarouselLeft = `linear-gradient(
  90deg,
  ${colorGunmetal.fade(0.5).toString()},
  rgba(0,0,0,0)
)`;

export const gradientCarouselRight = `linear-gradient(
  270deg,
  ${colorGunmetal.fade(0.5).toString()},
  rgba(0,0,0,0)
)`;

export const gradientPlayerLeft = `linear-gradient(
  90deg,
  ${colorGunmetal.toString()},
  rgba(0,0,0,0)
)`;

export const gradientPlayerRight = `linear-gradient(
  270deg,
  ${colorGunmetal.toString()},
  rgba(0,0,0,0)
)`;

export const gradientPlayerProgress = `linear-gradient(
  90deg,
  ${colorBrand.toString()},
  ${colorContrast.toString()}
)`;

export const gradientDark = `radial-gradient(
  circle at -8% 0,
  ${colorGunmetal.darken(0.05).toString()},
  ${colorGunmetal.darken(0.075).toString()} 68%,
  ${colorGunmetal.darken(0.1).toString()}
)`;

export const gradientDrawer = `linear-gradient(90deg, ${colorContrast.toString()}, ${colorBrand.toString()})`;

export const gradientBackground = `linear-gradient(130deg,rgba(255,6,6,1) 7.63%,rgba(0,0,255,1) 100%)`;