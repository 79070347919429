import { observable } from 'mobx';
import {ChannelSchema} from '../../../types/schema';
import {YouTubeVideo} from '../../../types/youtube';
import {PaginationMeta} from '../../../types';

export class AccountChannelPageModel {
  @observable
  identity: string;

  @observable
  loading: boolean;

  @observable
  channel: ChannelSchema;

  @observable
  videos: Array<YouTubeVideo> = [];

  @observable
  videosPagination: PaginationMeta;

  @observable
  loadingMoreVideos: boolean = false;
}