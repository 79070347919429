import { action, autorun, IReactionDisposer } from 'mobx';
import { PlayerModel } from './model';
import { AnalyticsAPIController } from '../api/analytics/controller';
import { EnvModel } from '../env/model';
import { AnalyticsController } from '../analytics/controller';
import { AudioMetaSchema, TrackSchema } from '../../types/schema';
import Player, { PlayerPlayListener } from '../../components/player/player';
import { AnyObject } from '../../components/project-happy/utilities/objects';
import siteEvents, { SITE_EVENTS } from '../../components/project-happy/utilities/siteEvents';

export class PlayerController {
  constructor(
    private player: PlayerModel,
    private analyticsAPI: AnalyticsAPIController,
    private analytics: AnalyticsController,
    private env: EnvModel
  ) {
    this._disposers = [];
    if (!env.isServer) {
      let lastPlayed: TrackSchema;
      this._disposers.push(
        autorun(() => {
          const { currentTrack, play } = this.player;

          if (currentTrack !== lastPlayed && play) {
            lastPlayed = currentTrack;
            siteEvents.emit(SITE_EVENTS.TRACK_PLAY, { track: currentTrack });
            this.analytics.sendTrackPlay(currentTrack);
          }
        })
      );
    }
  }

  private _disposers: IReactionDisposer[];

  dispose = () => {
    this._disposers.forEach((dispose) => dispose());
  };

  @action
  load = async (track: TrackSchema, startAt: number | null = null) => {
    if (!this.player.playlist.find((t) => t.slug === track.slug)) {
      this.player.playlist.push(track);
    }

    this.player.loading = true;
    this.player.duration = 0;
    this.player.position = 0;
    this.player.startAt = startAt;
    this.player.currentTrack = track;
  };

  @action
  loadIfEmpty = (track: TrackSchema) => {
    if (!this.player.currentTrack) {
      this.load(track);
    }
  };

  @action
  loadImmediate = async (track: TrackSchema) => {
    await this.load(track);
    return this.playAndTrack();
  };

  @action
  loadImmediateAndSkip = async (startAt: number, track: TrackSchema) => {
    await this.load(track, startAt);
    return this.play();
  };

  @action
  loadImmediateAndSkipAndTrack = async (startAt: number, track: TrackSchema) => {
    await this.load(track, startAt);
    return this.playAndTrack();
  };

  @action
  loadImmediatePlaylist = (tracks: Array<TrackSchema>, track: TrackSchema) => {
    this.player.playlist.splice(0, this.player.playlist.length, ...tracks);
    return this.loadImmediate(track);
  };

  @action
  previous = () => {
    if (this.player.previousTrack) {
      this.player.duration = 0;
      this.player.position = 0;
      this.player.currentTrack = this.player.previousTrack;
    }
  };

  @action
  next = () => {
    if (this.player.nextTrack) {
      this.player.duration = 0;
      this.player.position = 0;
      this.player.currentTrack = this.player.nextTrack;
    }
  };

  @action
  attachPlayListener = (listener: PlayerPlayListener) => {
    const listenerIndex = this.player.listeners.indexOf(listener);

    if (listenerIndex === -1) {
      this.player.listeners.push(listener);
    }

    return this.detachPlayListener.bind(this, listener);
  };

  @action
  detachPlayListener = (listener: PlayerPlayListener) => {
    const listenerIndex = this.player.listeners.indexOf(listener);

    if (listenerIndex !== -1) {
      this.player.listeners.splice(listenerIndex, 1);
    }
  };

  @action
  play = () => {
    if (!this.player.currentTrack) return;
    this.player.listeners.forEach((listener) => listener(this.player.play));
    this.player.play = true;
    this.player.stop = false;
  };

  @action
  playAndTrack = () => {
    this.play();
    this.analytics.sendMixpanel('User clicks play', {
      identity: this.player.currentTrack.identity,
      title: this.player.currentTrack.title,
      slug: this.player.currentTrack.slug,
    });
  };

  @action
  pause = () => {
    this.player.play = false;
  };

  @action
  pauseAndTrack = () => {
    this.pause();
    this.analytics.sendMixpanel('User clicks pause', {
      identity: this.player.currentTrack.identity,
      title: this.player.currentTrack.title,
      slug: this.player.currentTrack.slug,
    });
  };

  @action
  stop = () => {
    this.player.play = false;
    this.player.stop = true;
    this.player.position = 0;
  };

  @action
  onPlaying = ({ duration, position }: OnPlayingOptions) => {
    this.player.position = position;
    this.player.duration = duration;
  };

  @action
  onFinishedPlaying = () => {
    setTimeout(this.next, 1000);
    this.stop();
  };

  @action
  seek = (position: number) => {
    if (this.player.currentTrack) {
      this.player.position = position;
    }
  };

  @action
  setVolume = (volume: number) => {
    this.player.volume = Math.min(Math.max(volume, 0), 100);
  };

  @action
  onMeta = (meta: AudioMetaSchema) => {
    this.player.duration = meta.duration;
  };
}

export interface OnPlayingOptions {
  duration: number;
  position: number;
}
