import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { centerCenter, flex1, horizontal, horizontallySpaced } from 'csstips';
import { classes, style } from 'typestyle';
import { percent, px } from 'csx';
import {
  colorContrast,
  colorDarkBlueGrey,
  colorGreyAccent,
  colorNeutral,
  colorGunmetal,
  colorWhite,
  rebrand,
} from '../theme/color';
import { mediaMobileOnly } from '../theme/media';
import { PlayerBarControls } from './player-bar-controls';
import { PlayerBarNowPlaying } from './player-bar-now-playing';
import { PlayerBarVolume } from './player-bar-volume';
import { gradientTrackTile } from '../theme/gradient';
import { formatDuration } from './duration';
import { AudioMetaSchema, TrackSchema } from '../types/schema';
import { ImagePreloader } from '../types/fn';
import Player, { PlayerAttachPlayListener } from './player/player';
import { User } from '../types/user';
import { resetAnchorStyles } from '../theme/reset';
import { AnalyticsController } from '../modules/analytics/controller';
import { constantPlayerHeight } from '../theme/constant';
import { MinimisePlayer } from './project-happy/atoms/icons/MinimisePlayer';
import { MaximisePlayer } from './project-happy/atoms/icons/MaximisePlayer';
import { mediaDesktop, mediaUpToDesktop } from './project-happy/utilities/mediaQueries';
import { ButtonCircle } from './button-circle';
import { MoreHoriz } from './project-happy/atoms/icons/MoreHoriz';
import { Component, deps } from '../lib/component';

export interface PlayerBarModel {
  track: TrackSchema;
  duration: number;
  isPlaying: boolean;
  startAt: number | null;
  volume: number;
  user: User;
  signInHref: string;
}

export interface PlayerBarProps {
  playerModel: PlayerBarModel;
  AddRemove: any;
  Favourite: any;
  es6?: boolean;
  className?: string;
  preload: ImagePreloader;
  preloadWaveform: ImagePreloader;
  isPlayerExpanded: boolean;
  handlePlayerExpanded: () => void;
  loadAudioMeta(trackId: string): Promise<AudioMetaSchema>;
  onClickSignIn(event: React.MouseEvent<any>): void;
  onClickPlay(event: React.MouseEvent<any>): void;
  onClickPause(event: React.MouseEvent<any>): void;
  onClickNext(event: React.MouseEvent<any>): void;
  onClickPrevious(event: React.MouseEvent<any>): void;
  onChangeVolume(volume: number): void;
  onFinish(): void;
  onMeta?(meta: AudioMetaSchema): any;
  onClickLink(event: React.MouseEvent<any>): void;
  attachPlayListener: PlayerAttachPlayListener;
  analytics: AnalyticsController;
}

export interface PlayerBarState {
  pulse: boolean;
  currentTime: string;
}

@inject(deps)
@observer
export class PlayerBar extends Component<PlayerBarProps, PlayerBarState> {
  _mounted = false;

  constructor(props) {
    super(props);
    this.state = { pulse: false, currentTime: '0' };
    this.updateCurrentTime = this.updateCurrentTime.bind(this);
  }

  updateCurrentTime(time: number): void {
    this.setState({ currentTime: formatDuration(time * 1000) });
  }

  render() {
    const { props, state } = this;
    const {
      playerModel,
      AddRemove,
      Favourite,
      preload,
      preloadWaveform,
      isPlayerExpanded,
      handlePlayerExpanded,
      onClickLink,
      analytics,
    } = props;

    const { currentTime } = state;
    const className = classes(props.className, PlayerBar.styles.container);

    let isMobile = false;

    if (window.screen.width < 1024) {
      isMobile = true;
    } else {
      isMobile = false;
    }
    return (
      <div className={className}>
        {!playerModel.user && (
          <div className={classes(PlayerBar.styles.signInToPlay, this.state.pulse && PlayerBar.styles.ctaPulse)}>
            <a href={playerModel.signInHref} onClick={props.onClickSignIn}>
              Sign up
            </a>
            <span> to listen to full track</span>
          </div>
        )}
        <div className={PlayerBar.styles.expandTab} onClick={handlePlayerExpanded}>
          {isPlayerExpanded ? (
            <MinimisePlayer className={PlayerBar.styles.playerTabIcon} />
          ) : (
            <MaximisePlayer className={PlayerBar.styles.playerTabIcon} />
          )}
        </div>
        <div data-test-track-player className={classes(PlayerBar.styles.innerContainer, PlayerBar.styles.innerContainerMain)}>
          <PlayerBarControls
            model={playerModel}
            onClickPlay={props.onClickPlay}
            onClickPause={props.onClickPause}
            onClickNext={props.onClickNext}
            onClickPrevious={props.onClickPrevious}
            preload={preload}
          />
          <PlayerBarNowPlaying analytics={analytics} model={playerModel} onClickLink={onClickLink} />
          <div className={classes(PlayerBar.styles.elapsedTime, PlayerBar.styles.hideUpToDesktop)}>
            <span>{currentTime}</span>
          </div>
          {/* Conditional is here to stop both players playing at once, known edge case issue is audio will stop when switching from desktop to mobile views.
          The user will need to click pause and play to resume playing or refresh the page. This is so we can continue to use the legacy code until the rebuild of the FE */}
          {!isMobile && (
            <Player
              user={playerModel.user}
              className={PlayerBar.styles.waveformDesktop}
              play={playerModel.isPlaying}
              startAt={playerModel.startAt}
              trackId={playerModel.track ? playerModel.track.identity : void 0}
              loadAudioMeta={props.loadAudioMeta}
              preload={preload}
              preloadWaveform={preloadWaveform}
              onFinish={this.handleFinish}
              onMeta={props.onMeta}
              volume={playerModel.volume}
              attachPlayListener={props.attachPlayListener}
              onProgressUpdate={this.updateCurrentTime}
            />
          )}
          {playerModel.duration && (
            <div className={classes(PlayerBar.styles.duration, PlayerBar.styles.hideUpToDesktop)}>
              <span>{formatDuration(playerModel.duration)}</span>
            </div>
          )}
          <PlayerBarVolume volume={playerModel.volume} onChangeVolume={props.onChangeVolume} />
          <div className={PlayerBar.styles.addRemove}>
            {/* Desktop view */}
            <span className={PlayerBar.styles.hideUpToDesktop}>
              <AddRemove size={36} track={playerModel.track} withLicense={true} reverse={true} />
            </span>
            {/* Mobile view */}
            <span className={PlayerBar.styles.hideDesktop}>
              <AddRemove size={36} track={playerModel.track} />
            </span>
            <Favourite
              track={playerModel.track}
              size={36}
              className={classes(PlayerBar.styles.favBtn, PlayerBar.styles.hideUpToDesktop)}
            />
            <ButtonCircle className={PlayerBar.styles.trackInfoBtn} size={36} onClick={this.handleClickTrackInfo}>
              <MoreHoriz color={colorGunmetal.toString()} />
            </ButtonCircle>
          </div>
        </div>
        <div className={classes(PlayerBar.styles.innerContainer, PlayerBar.styles.innerContainerFallback)} />
        <div className={PlayerBar.styles.stackedWaveform}>
          <div className={PlayerBar.styles.elapsedTime}>
            <span>{currentTime}</span>
          </div>
          {/* Conditional is here to stop both players playing at once, known edge case issue is audio will stop when switching from desktop to mobile views.
           The user will need to click pause and play to resume playing or refresh the page.
           This is so we can continue to use the legacy code until the rebuild of the FE */}
          {isMobile && (
            <Player
              user={playerModel.user}
              className={PlayerBar.styles.waveformMobile}
              play={playerModel.isPlaying}
              startAt={playerModel.startAt}
              trackId={playerModel.track ? playerModel.track.identity : void 0}
              loadAudioMeta={props.loadAudioMeta}
              preload={preload}
              preloadWaveform={preloadWaveform}
              onFinish={this.handleFinish}
              onMeta={props.onMeta}
              volume={playerModel.volume}
              attachPlayListener={props.attachPlayListener}
              onProgressUpdate={this.updateCurrentTime}
            />
          )}
          {playerModel.duration && (
            <div className={PlayerBar.styles.duration}>
              <span>{formatDuration(playerModel.duration)}</span>
            </div>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleFinish = () => {
    if (this.props.onFinish) {
      this.props.onFinish();
    }

    this.setState({ pulse: true }, () => {
      setTimeout(() => {
        if (this._mounted) this.setState({ pulse: false });
      }, 420);
    });
  };

  handleClickTrackInfo = (event: React.MouseEvent<any>): void => {
    this.props.controller.modal.showSongInfoModal(this.props.playerModel.track);
  };

  static styles = {
    container: style({
      height: constantPlayerHeight,
      background: colorGunmetal.darken(0.05).toString(),
    }),
    innerContainer: style({
      ...horizontal,
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      height: constantPlayerHeight,
      background: colorGunmetal.toString(),
    }),
    innerContainerMain: style({
      zIndex: 2,
    }),
    innerContainerFallback: style(
      {
        zIndex: 1,
      },
      mediaMobileOnly({
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: gradientTrackTile,
      })
    ),
    waveformDesktop: style(
      {
        ...flex1,
        height: px(50),
        margin: '10px 0',
        zIndex: 3,
      },
      mediaUpToDesktop({
        display: 'none',
      })
    ),
    waveformMobile: style({
      ...flex1,
      height: px(30),
      zIndex: 3,
      top: '20%',
    }),
    addRemove: style({
      ...centerCenter,
      ...horizontal,
      ...horizontallySpaced(10),
      height: percent(100),
      padding: '0 10px 0 0',
    }),
    duration: style(
      {
        ...centerCenter,
        height: percent(100),
        lineHeight: px(14),
        color: colorWhite.toString(),
        borderLeft: '3px solid',
        borderLeftColor: colorNeutral.toString(),
        padding: '0 10px 0 7px',
        fontSize: px(13),
      },
      mediaDesktop({
        marginRight: '70px',
      })
    ),
    elapsedTime: style(
      {
        ...centerCenter,
        height: percent(100),
        lineHeight: px(14),
        color: colorWhite.toString(),
        borderRight: '3px solid',
        borderRightColor: colorNeutral.toString(),
        padding: '0 10px 0 7px',
        fontSize: px(13),
        width: px(49),
      },
      mediaDesktop({
        marginLeft: '70px',
      })
    ),
    hideMobile: style(mediaMobileOnly({ display: 'none' })),
    hideUpToDesktop: style(mediaUpToDesktop({ display: 'none' })),
    hideDesktop: style(mediaDesktop({ display: 'none' })),
    ctaPulse: style({
      $nest: {
        '> *': {
          opacity: 0,
          transition: 'opacity 0.25s ease-in',
        },
      },
    }),
    signIn: style({
      color: colorContrast.toString(),
      $nest: {
        ...resetAnchorStyles(colorContrast.toString()),
      },
    }),
    favBtn: style({
      backgroundColor: colorGreyAccent.toString(),
    }),
    trackInfoBtn: style({
      backgroundColor: colorGreyAccent.toString(),
      $nest: {
        '&:hover': {
          backgroundColor: colorDarkBlueGrey.toString(),
        },
      },
    }),
    playerTabIcon: style({
      display: 'block',
      margin: 'auto',
    }),
    expandTab: style(
      {
        background: colorGunmetal.toString(),
        position: 'absolute',
        top: '-25px',
        right: 0,
        width: '25px',
        height: '25px',
        paddingTop: '3px',
        borderTopLeftRadius: '8px',
        borderBottom: '1px solid',
        borderBottomColor: colorGunmetal.toString(),
      },
      //Not visible on desktop
      mediaDesktop({ display: 'none' })
    ),
    stackedWaveform: style(
      {
        display: 'flex',
        background: colorGunmetal.toString(),
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: '50px',
        zIndex: -1,
      },
      mediaDesktop({
        display: 'none',
      })
    ),
    signInToPlay: style(
      {
        $nest: {
          '& a': { color: colorWhite.toString(), textDecoration: 'underline' },
          '& span': { color: colorWhite.toString() },
        },
      },
      {
        background: rebrand.primary1.toString(),
        position: 'absolute',
        top: '-25px',
        zIndex: -1,
        height: '25px',
        width: '100vw',
        borderRadius: '8px 8px 0 0',
        textAlign: 'center',
        fontSize: '12px',
        lineHeight: '25px',
      },
      mediaDesktop({
        width: '900px',
      })
    ),
  };
}
