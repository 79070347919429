import * as React from 'react';
import { classes, cssRule, extend, style } from 'typestyle';
import { px } from 'csx';
import { ContentSlice, ContentSliceProps, GradientBackgroundStyles } from './ContentSlice';
import { mediaDesktop, mediaUpToDesktop } from '../../utilities/mediaQueries';
import { colorLightBlueGrey } from '../../../../theme/color';
import { PrismicSliceCSSClasses } from '../../utilities/types';

const tilesSliceClass = PrismicSliceCSSClasses.TILE_SLICE;
cssRule(`.${tilesSliceClass} + .${tilesSliceClass}, .${PrismicSliceCSSClasses.CAROUSEL_SLICE} + .${tilesSliceClass}`, {
  borderTopWidth: px(1),
  borderTopStyle: 'solid',
  borderTopColor: colorLightBlueGrey.toHexString(),
  paddingTop: px(49),
});

const styles = style(
  {
    color: 'inherit',
    position: 'relative',
  },
  mediaUpToDesktop({
    paddingBottom: px(32),
    $nest: {
      '.masthead + &, .slice-group + &, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient, .with-background + &:not(.with-background), :not(.with-background) + &.with-background': {
        paddingTop: px(32),
      },
    },
  }),
  mediaDesktop({
    paddingBottom: px(56),
    $nest: {
      '.masthead + &, .slice-group + &, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient, .with-background + &:not(.with-background), :not(.with-background) + &.with-background': {
        paddingTop: px(56),
      },
    },
  })
);

type BaseTilesSliceProps = ContentSliceProps;

export const BaseTilesSlice: React.SFC<BaseTilesSliceProps> = ({ className, ...props }) => (
  <ContentSlice className={classes(tilesSliceClass, styles, className)} {...props} />
);
