import * as React from 'react';
import { classes, extend, style } from 'typestyle';
import { centerCenter } from 'csstips';
import { px } from 'csx';
import { colorDarkBlueGrey, colorGreyAccent } from '../../theme/color';
import { SVGIcon } from '../svg-icon';
import { mediaDesktop, mediaMobileOnly, mediaTablet, mediaTabletOnly } from '../../theme/media';
import { constantTileCarouselDesktopSize, constantTileCarouselMobileSize } from '../../theme/constant';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { LickdRebrandLogo } from '../project-happy/atoms/icons/brands/LickdRebrandLogo';

// This can be derived from a scale of 1.2 for a button size of 34, as used on the homepage
// We can inject these as props if we need to in the future. For now it's homepage only
const buttonSize = '40.8px';
const backgroundColourStr = colorDarkBlueGrey.toHexString();
const foregroundColour = colorGreyAccent;
const foregroundColourStr = foregroundColour.toHexString();

export const EmptyTileDimensionStyles: NestedCSSProperties = extend(
  mediaMobileOnly({ width: constantTileCarouselMobileSize, height: constantTileCarouselMobileSize }),
  mediaTabletOnly({ width: constantTileCarouselDesktopSize, height: constantTileCarouselDesktopSize }),
  mediaDesktop({ width: constantTileCarouselDesktopSize, height: constantTileCarouselDesktopSize })
);

const styles = {
  wrapper: style(centerCenter, {
    position: 'relative',
    flexShrink: 0,
    background: backgroundColourStr,
  }),
  wrapperDimensions: style(EmptyTileDimensionStyles),
  favouriteButton: style(centerCenter, {
    position: 'absolute',
    top: px(10),
    right: px(10),
    width: buttonSize,
    height: buttonSize,
    background: foregroundColourStr,
    borderRadius: buttonSize,
  }),
  favouriteIcon: style({
    $nest: {
      '& path': {
        stroke: backgroundColourStr,
        fill: backgroundColourStr,
      },
    },
  }),
};

export interface EmptyTileProps {
  key?: React.Key;
  className?: string;
}

const EmptyTile: React.SFC<EmptyTileProps> = ({ className }) => (
  <div className={classes(styles.wrapper, className || styles.wrapperDimensions)}>
    <div className={styles.favouriteButton}>
      <SVGIcon.Favourite size={18} color={backgroundColourStr} className={styles.favouriteIcon} />
    </div>
    <LickdRebrandLogo color={foregroundColour.toString()} />
  </div>
);

export default EmptyTile;
