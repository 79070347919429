import React from 'react';
import { Component, deps, inject, observer } from '../../../lib/component';
import { ChannelAvatar } from './ChannelAvatar';
import { ChannelSchema } from '../../../types/schema';

export interface UserAvatarProps {
  className?: string;
  channelOverride?: ChannelSchema;
}

@inject(deps)
@observer
export default class UserAvatar extends Component<UserAvatarProps> {
  render() {
    const {
      className,
      channelOverride,
      model: {
        user: { channel: currentChannel },
      },
    } = this.props;
    const channel = channelOverride ? channelOverride : currentChannel;

    return <ChannelAvatar className={className} channel={channel} />;
  }
}
