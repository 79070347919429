import * as React from 'react';
import { classes, cssRule, extend, media, style } from 'typestyle';
import { important, percent, px } from 'csx';
import { Button } from '../atoms/button/Button';
import { rebrand } from '../../../theme/color';
import { desktopContentWidth, headerFullHeight } from '../config';
import {
  mediaDesktop,
  mediaLargeMobile,
  mediaSmallTablet,
  mediaUpToDesktop,
  mediaUpToLargeMobile,
  mediaUpToSmallTablet,
} from '../utilities/mediaQueries';
import { AppMenu } from './AppMenu';
import { Component, deps, inject, observer } from '../../../lib/component';
import { betweenJustified, center, flexRoot } from 'csstips';
import { zAppHeaderContainer } from '../../../theme/z';
import { ShoppingBasket } from '../atoms/icons/ShoppingBasket';
import { Badge } from '../../badge';
import { MagnifyingGlass } from '../atoms/icons/MagnifyingGlass';
import { Heart } from '../atoms/icons/Heart';
import { IconButton } from '../atoms/button/IconButton';
import UserAvatar from '../atoms/UserAvatar';
import { screenReaderOnly } from '../utilities/font';
import { Menu } from '../atoms/icons/Menu';
import { Headphones } from '../atoms/icons/Headphones';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { BROWSE_ROUTE, LOG_IN_ROUTE, SEARCH_ROUTE, SIGN_UP_ROUTE } from '../../../constants';
import { LickdRebrandLogo } from '../atoms/icons/brands/LickdRebrandLogo';
import { PersonCircle } from '../atoms/icons/PersonCircle';
import { ArrowDown } from '../atoms/icons/ArrowDown';
import { FourSquares } from '../atoms/icons/FourSquares';
import { focusVisible } from '../utilities/css';
import { CloseCross } from '../atoms/icons/CloseCross';
import { centerCenter } from 'csstips/lib/flex';
import { AnalyticsContextProvider } from '../utilities/AnalyticsContext';
import { isEmpty } from '../utilities/objects';

const { PRIMARY, OUTLINE, INSET } = ButtonProminence;
const { MEDIUM } = ButtonSize;

cssRule('html body', {
  marginTop: px(headerFullHeight),
});

const styles = {
  header: style({
    position: 'fixed',
    backgroundColor: rebrand.contrast[50].toString(),
    top: 0,
    left: 0,
    right: 0,
    width: percent(100),
    height: px(headerFullHeight),
    zIndex: zAppHeaderContainer,
  }),
  wrapper: style(
    {
      height: percent(100),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    mediaUpToDesktop({
      marginLeft: px(16),
      marginRight: px(16),
    }),
    mediaDesktop({
      margin: '0 auto',
      width: px(desktopContentWidth),
    })
  ),
  actions: style(flexRoot, center, betweenJustified, {
    $nest: {
      '& > .button': extend(centerCenter, {
        gap: px(4),
        boxShadow: 'none',
        $nest: {
          '&:not(.icon-button)': {
            padding: '4px 12px',
            height: px(32),
            whiteSpace: 'nowrap',
          },
        },
      }),
      '& > *:not(:last-child)': {
        marginRight: px(8),
      },
    },
  }),
  signUpCta: style({ padding: '4px 12px 4px 10px' }),
  basket: style({
    $nest: {
      '&.with-items': extend(
        {
          borderColor: 'transparent',
          backgroundColor: rebrand.secondary[50].toString(),
          color: rebrand.contrast[90].toString(),
          $nest: {
            '&:hover': {
              backgroundColor: rebrand.secondary[60].toString(),
              color: rebrand.contrast[90].toString(),
              $nest: {
                '& svg:not(.badge) path': {
                  fill: rebrand.contrast[90].toString(),
                },
              },
            },
          },
        },
        focusVisible({
          backgroundColor: rebrand.secondary[60].toString(),
          color: rebrand.contrast[90].toString(),
          $nest: {
            '& svg:not(.badge) path': {
              fill: rebrand.contrast[90].toString(),
            },
          },
        })
      ),
    },
  }),
  basketBadge: style({
    position: 'absolute',
    top: px(-8),
    right: px(2),
  }),
  openCloseBtn: style({ maxWidth: px(32) }),
  arrowUp: style({ transform: 'rotate(180deg)' }),
  hideOnTinyScreens: style(media({ maxWidth: px(319) }, { display: important('none') })),
  hideOnSmallScreens: style(media({ maxWidth: px(400) }, { display: important('none') })),
  mobileOnly: style(mediaSmallTablet({ display: important('none') })),
  desktopOnly: style(mediaUpToSmallTablet({ display: important('none') })),
};

export interface HeaderBannerProps {
  className?: string;
  href?: string;
  onClick?: (event: React.MouseEvent<any>) => void;
}

const analyticsContext = { uiLocation: 'Global Navigation' };

@inject(deps)
@observer
export class HeaderBanner extends Component<HeaderBannerProps> {
  state = {
    redirect: '',
  };

  constructor(props) {
    super(props);
    this.handleActionClick = this.handleActionClick.bind(this);
  }

  handleActionClick(event: React.MouseEvent<any>) {
    if (!(event.target instanceof HTMLElement || event.target instanceof SVGElement)) return;
    let closestLink = null;
    try {
      closestLink = event.target.closest('a');
    } catch {
      // Very old browsers don't support .closest, but don't cause an error
    }
    if (closestLink === null) return;
    this.props.controller.ui.setMenuDrawer(false);
  }

  render() {
    const {
      model: {
        basket: { tracks },
        auth: { token },
        ui: { showMenuDrawer },
        router: { location },
        user: { user, channel },
      },
      controller: {
        ui: { setMenuDrawer },
        analytics,
        modal: { showAddChannelForModal },
      },
    } = this.props;

    const handleBasketClick = (event: React.MouseEvent<any>) => {
      analytics.sendMixpanel('User clicks basket');
      if (!isEmpty(user) && isEmpty(channel)) {
        event.preventDefault();
        showAddChannelForModal('checkout');
      }
    };

    const handleSearchClick = (event: React.MouseEvent<any>) => {
      analytics.sendMixpanel('User clicks search');
    };

    return (
      <AnalyticsContextProvider context={analyticsContext}>
        <header className={styles.header} data-test-global-navigation>
          <div className={styles.wrapper} onClick={this.handleActionClick}>
            <Button href="/" aria-label="Lickd" prominence={ButtonProminence.NONE} data-test-global-navigation-logo>
              <LickdRebrandLogo />
            </Button>
            <div className={classes(styles.actions)}>
              <Button
                data-test-global-navigation-button="search"
                prominence={OUTLINE}
                size={MEDIUM}
                className={classes(!token && styles.desktopOnly)}
                href={SEARCH_ROUTE}
                onClick={handleSearchClick}
              >
                <MagnifyingGlass />
                <span className={styles.desktopOnly}>Search</span>
              </Button>
              <Button
                data-test-global-navigation-button="browse"
                prominence={OUTLINE}
                size={MEDIUM}
                className={styles.desktopOnly}
                href={BROWSE_ROUTE}
              >
                <FourSquares />
                Browse
              </Button>
              {token && (
                <IconButton
                  data-test-global-navigation-button="favourites"
                  prominence={OUTLINE}
                  href="/account/favourites"
                  label="View favourites"
                  className={styles.desktopOnly}
                >
                  <Heart size={17.5} color="white" />
                </IconButton>
              )}
              <Button
                data-test-global-navigation-button="checkout"
                prominence={OUTLINE}
                size={MEDIUM}
                className={classes(styles.basket, tracks.length > 0 && 'with-items', !token && styles.desktopOnly)}
                href="/checkout"
                onClick={handleBasketClick}
              >
                <ShoppingBasket style={{ marginTop: px(1) }} size={17} />
                <span className={styles.desktopOnly}>Cart</span>
                {tracks.length > 0 && (
                  <Badge className={styles.basketBadge}>
                    {tracks.length}
                    <span className={style(screenReaderOnly)}> tracks in basket</span>
                  </Badge>
                )}
              </Button>
              {token && (
                <Button
                  data-test-global-navigation-toggle-app-drawer
                  prominence={INSET}
                  size={MEDIUM}
                  className={styles.desktopOnly}
                  current={showMenuDrawer || location.pathname.startsWith('/account')}
                  onClick={() => setMenuDrawer(!showMenuDrawer)}
                >
                  <PersonCircle />
                  Account
                  <ArrowDown className={classes(showMenuDrawer && styles.arrowUp)} />
                </Button>
              )}
              {!token && (
                <>
                  <Button
                    data-test-global-navigation-button="sign up"
                    size={MEDIUM}
                    prominence={PRIMARY}
                    className={styles.signUpCta}
                    href={SIGN_UP_ROUTE}
                  >
                    Sign up
                  </Button>
                  <Button
                    data-test-global-navigation-button="log in"
                    size={MEDIUM}
                    prominence={INSET}
                    href={LOG_IN_ROUTE}
                  >
                    <PersonCircle className={styles.desktopOnly} />
                    Log in
                  </Button>
                </>
              )}
              <IconButton
                data-test-global-navigation-toggle-app-drawer
                prominence={INSET}
                className={styles.mobileOnly}
                current={showMenuDrawer}
                label="Open menu"
                onClick={() => setMenuDrawer(!showMenuDrawer)}
              >
                {showMenuDrawer ? (
                  <CloseCross viewBox="4 4 16 16" width={22} height={20} color="white" />
                ) : (
                  <Menu color="white" size={22} />
                )}
              </IconButton>
            </div>
          </div>
        </header>
        <AppMenu />
      </AnalyticsContextProvider>
    );
  }
}
