import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { em, percent, px } from 'csx';
import { colorBrand, colorContrast } from '../../../../theme/color';
import { ContentSlice, ContentSliceProps } from './ContentSlice';
import { YouTubeBadge } from '../../atoms/YouTubeBadge';
import { Button } from '../../atoms/button/Button';
import { isEmpty } from '../../utilities/objects';
import { ReactNodeArray } from 'react';
import { shadowElevation1 } from '../../../../theme/shadow';
import { mediaDesktop, mediaUpToDesktop } from '../../utilities/mediaQueries';
import { centerCenter, flexRoot, vertical } from 'csstips';

const styles = {
  wrapper: style({
    position: 'relative',
    textAlign: 'center',
    $nest: {
      '.with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient': {
        paddingTop: px(56),
      },
      '.masthead + &': {
        marginTop: px(-80),
      },
    },
  }),
  content: style({
    paddingBottom: px(40),
  }),
  logoWrapperPanel: style(
    shadowElevation1,
    flexRoot,
    centerCenter,
    {
      margin: '0 auto',
      borderRadius: em(0.5),
      overflow: 'hidden',
      marginBottom: px(24),
      padding: px(8),
    },
    mediaUpToDesktop({
      width: px(155),
      height: px(155),
    }),
    mediaDesktop({
      width: px(195),
      height: px(195),
    })
  ),
  partnerLogo: style({
    width: percent(100),
    height: 'auto',
  }),
};

type PartnerBadgeSliceProps = ContentSliceProps & {
  partnerLogo: string;
  backgroundColour?: string;
};

export const PartnerBadgeSlice: React.SFC<PartnerBadgeSliceProps> = ({
  children,
  className,
  partnerLogo,
  backgroundColour,
  ...props
}) => (
  <ContentSlice
    className={classes('partner-badge-slice', className, styles.wrapper)}
    contentClassName={styles.content}
    {...props}
  >
    {children}
    <div className={classes(styles.logoWrapperPanel, backgroundColour && style({ backgroundColor: backgroundColour }))}>
      <img className={styles.partnerLogo} src={partnerLogo} />
    </div>
  </ContentSlice>
);
