import { keyframes } from 'typestyle';
import { CSSProperties } from 'typestyle/lib/types';
import { percent } from 'csx';

export const keyframesPulse = keyframes({
  '0%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0,
  },
});

export const animationPulse: CSSProperties = {
  animationName: keyframesPulse,
  animationDirection: 'alternate',
  animationDuration: '1s',
  animationIterationCount: 'infinite',
};

export const animationFadeIn: CSSProperties = {
  animationName: keyframesPulse,
  animationDirection: 'reverse',
  animationDuration: '0.25s',
  animationIterationCount: 1,
};

export const keyframesBounce = keyframes({
  '0%': {
    transform: 'translate3d(0, 0, 0)',
  },
  '40%': {
    transform: 'translate3d(0, 0, 0)',
  },
  '60%': {
    transform: 'translate3d(0, -0.25em, 0)',
  },
  '80%': {
    transform: 'translate3d(0, 0, 0)',
  },
});

export const animationBounce: CSSProperties = {
  animationName: keyframesBounce,
  animationDuration: '2s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
  animationDirection: 'alternate',
};

export const keyframesSlowPulseOne = keyframes({
  '0%': {
    opacity: 0,
  },
  '40%': {
    opacity: 0,
  },
  '50%': {
    opacity: 1,
  },
  '90%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0,
  },
});

export const keyframesSlowPulseTwo = keyframes({
  '0%': {
    opacity: 1,
  },
  '40%': {
    opacity: 1,
  },
  '50%': {
    opacity: 0,
  },
  '90%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
});

export const animationSlowPulseOne: CSSProperties = {
  animationName: keyframesSlowPulseOne,
  animationDuration: '6s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
};

export const animationSlowPulseTwo: CSSProperties = {
  animationName: keyframesSlowPulseTwo,
  animationDuration: '6s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
};

export const keyframesGradientPulse = keyframes({
  '0%': {
    backgroundPositionX: percent(0),
  },
  '100%': {
    backgroundPositionX: percent(200),
  },
});

export const animationGradientPulse: CSSProperties = {
  animationName: keyframesGradientPulse,
  animationDuration: '0.75s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
};

export const keyframesSpin = keyframes({
  '0%': {
    transform: 'rotate(0)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

export const animationSpin: CSSProperties = {
  animationName: keyframesSpin,
  animationDuration: '1.5s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
};

export const dropdownDialogAnimation = keyframes({
  from: {
    transform: 'translateY(-1%)',
  },
  to: {
    transform: 'translateY(0%)',
  },
});

export const keyframesScaleUpDown = keyframes({
  '0%': {
    transform: 'scale(1.3)',
  },
  '100%': {
    transform: 'rotate(1)',
  },
});

export const animationScaleUpDown: CSSProperties = {
  animationName: keyframesScaleUpDown,
  animationDuration: '100ms',
  animationTimingFunction: 'ease',
};

export const keyframesSlideUp = keyframes({
  '0%': {
    transform: 'translateY(10px)',
  },
  '100%': {
    transform: '0',
  },
});

export const animationSlideUp: CSSProperties = {
  animationName: keyframesSlideUp,
  animationDuration: '0.25s',
  animationIterationCount: 1,
};
