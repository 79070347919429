import React from 'react';
import { style } from 'typestyle';
import { rebrand } from '../../../../theme/color';
import { px } from 'csx';
import { mediaDesktop } from '../../utilities/mediaQueries';

interface SimpleMastheadProps {
  children: React.ReactNode;
}

const styles = {
  masthead: style({
    backgroundColor: rebrand.dark5.toHexString(),
    padding: '32px 0',
    color: rebrand.contrast['10'].toString(),
  }),
};

const SimpleMasthead: React.FC<SimpleMastheadProps> = ({ children }: SimpleMastheadProps) => {
  return <div className={styles.masthead}>{children}</div>;
};

export default SimpleMasthead;
