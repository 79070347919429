import * as React from 'react';
import { ModalProps } from '../../../../modal';
import { ScrollableOverlay } from '../../../../scrollable-overlay/scrollable-overlay';
import overlayStyles, { ScrollableOverlayBodyStyle } from '../../../../../styles/scrollable-overlay';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { Component, deps, inject, observer } from '../../../../../lib/component';
import { rebrand } from '../../../../../theme/color';
import { CreditPlanTypes, V2Plan } from '../../../utilities/types';
import { StarterPlanContent } from './StarterPlanContent';
import { LegacyPlanContent } from './LegacyPlanContent';
import { ProAndPlusPlansContent } from './ProAndPlusPlansContent';

const styles = {
  wrapper: style({
    display: 'block',
    maxWidth: px(760),
    height: 'fit-content',
    backgroundColor: rebrand.neutralOnDark[10].toHexString(),
  }),
  heading: style({
    fontSize: px(32),
  }),
  body: style(ScrollableOverlayBodyStyle, {
    padding: '1.5rem 0 0',
    borderTop: `1px solid ${rebrand.light1.toString()}`,
    textAlign: 'center',
    overflowY: 'visible',
    $nest: {
      '& > p': {
        margin: '0 0 1.5rem',
        fontSize: px(20),
      },
    },
  }),
  planCredits: style({
    display: 'flex',
    borderRadius: px(8),
    padding: '12px 16px',
    backgroundColor: rebrand.primary[10].toHexString(),
    color: rebrand.primary[100].toHexString(),
    margin: '0 auto 24px',
    width: 'fit-content',
  }),
  starCircle: style({
    marginRight: '8px',
  }),
  button: style({
    display: 'block',
    width: '100%',
  }),
};

export type PreCancellationModalProps = ModalProps & {
  handleCancellation(): void;
  handleDowngrade(nextPlan: V2Plan): void;
};

@inject(deps)
@observer
export class PreCancellationModal extends Component<PreCancellationModalProps> {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      onRequestClose,
      handleCancellation,
      handleDowngrade,
      model: {
        user: {
          isSubscribedToV2Plan,
          isSubscribedToV2PlanAndCancelled,
          user,
          channel,
          unsubscribedRatecard,
          subscribedRatecard,
        },
        subscription: { creditSubscriptionPlans },
      },
    } = this.props;

    const currentV2Plan = isSubscribedToV2Plan
      ? creditSubscriptionPlans.find((plan) => plan.plan_name === channel.subscription.plan_name) || null
      : null;

    const initialPlan: V2Plan = {
      currency_code: '',
      currency_symbol: '',
      plan_credits: 0,
      plan_name: CreditPlanTypes.STARTER,
      premium_tracks_price: '',
      price: -Infinity,
      stripe_id: '',
      trial_length_in_days: 0,
    };

    const availableDowngradePlan =
      currentV2Plan && !isSubscribedToV2PlanAndCancelled && creditSubscriptionPlans.length > 0
        ? creditSubscriptionPlans.reduce((maxPlan, plan) => {
            if (plan.price < currentV2Plan.price && plan.price > maxPlan.price) {
              return plan;
            }
            return maxPlan;
          }, initialPlan)
        : null;

    const downgradePlan =
      availableDowngradePlan && availableDowngradePlan.price !== -Infinity ? availableDowngradePlan : null;

    const pendingSubscription =
      isSubscribedToV2Plan && channel.pending_subscription ? channel.pending_subscription : null;

    return (
      <ScrollableOverlay className={styles.wrapper} {...this.props}>
        <div className={overlayStyles.header}>
          <h3 className={classes(overlayStyles.title, styles.heading)}>Cancel subscription</h3>
        </div>

        <div className={styles.body}>
          {!isSubscribedToV2Plan && (
            <LegacyPlanContent
              user={user}
              onRequestClose={onRequestClose}
              handleCancellation={handleCancellation}
              subscribedRateCard={subscribedRatecard}
              unsubscribedRateCard={unsubscribedRatecard}
            />
          )}
          {isSubscribedToV2Plan &&
            channel.subscription &&
            channel.subscription.plan_name === CreditPlanTypes.STARTER && (
              <StarterPlanContent
                user={user}
                onRequestClose={onRequestClose}
                handleCancellation={handleCancellation}
                subscribedRateCard={subscribedRatecard}
                unsubscribedRateCard={unsubscribedRatecard}
              />
            )}
          {isSubscribedToV2Plan &&
            channel.subscription &&
            channel.subscription.plan_name !== CreditPlanTypes.STARTER && (
              <ProAndPlusPlansContent
                user={user}
                handleDowngrade={handleDowngrade}
                handleCancellation={handleCancellation}
                downgradePlan={downgradePlan}
                currentPlan={currentV2Plan}
                pendingSubscription={pendingSubscription}
              />
            )}
        </div>
      </ScrollableOverlay>
    );
  }
}
