import * as React from 'react';
import { observer } from 'mobx-react';
import { classes, style } from 'typestyle';
import { ScrollableOverlay } from '../../scrollable-overlay/scrollable-overlay';
import { Component, deps, inject } from '../../../lib/component';
import { SVGIcon } from '../../svg-icon';
import { colorContrast100, colorLightBlueGrey, colorGunmetal, colorGreen } from '../../../theme/color';
import { TrackSchemaWithUsage } from '../../../modules/page/checkout/model';
import { Loading } from '../../loading/loading';
import { isArray, isEmpty } from '../utilities/objects';
import { PlayerBarNowPlaying } from '../../player-bar-now-playing';
import { AddRemoveCompat } from '../../compat/add-remove-compat';
import { FavouriteButtonCompat } from '../../compat/favourite-button-compat';
import { centerCenter, horizontal } from 'csstips/lib/flex';
import { horizontallySpaced } from 'csstips/lib/box';
import { ArrowRight } from '../atoms/icons/ArrowRight';
import { Person } from '../atoms/icons/person';
import { Album } from '../atoms/icons/Album';
import { Description } from '../atoms/icons/Discription';
import { percent, px } from 'csx';
import { Link } from 'react-router';
import { EulaModal } from '../../checkout-v2/eula-modal/eula-modal';
import { TrackSchema } from '../../../types/schema';
import { ClaimsManaged } from '../atoms/icons/ClaimsManaged';
import { ClaimsManagedTooltip } from '../molecules/ClaimsManagedTooltip';
import maybe from '../../../lib/maybe';
import { ButtonPill } from '../../button-pill';
import { fontBody } from '../../../theme/font';

import { ImageWithPlayBtn } from '../atoms/ImageWithPlayBtn';
import { AddToPlaylistButton } from '../atoms/button/AddToPlaylistButton';
import { zModal } from '../../../theme/z';
import { ArtistLinkList } from '../../artist-link-list';
import { resetAnchorStyles } from '../../../theme/reset';

export interface SongInfoModalProps {
  show: boolean;
  track: TrackSchemaWithUsage;
  onRequestClose(): void;
}
@inject(deps)
@observer
export class SongInfoModal extends Component<SongInfoModalProps> {
  constructor(props) {
    super(props);

    this.state = {
      showEulaModal: false,
      eulaTrack: null,
    };
  }

  showEula(track: TrackSchema): any {
    this.props.controller.page.checkout.loadEulaInfo(track);
    this.setState({
      showEulaModal: true,
      eulaTrack: track,
    });
  }

  hideEula(): void {
    this.setState({
      showEulaModal: false,
    });
  }
  render() {
    const { show, track, onRequestClose, model, controller } = this.props;

    const PLATFORM_NAMES = {
      youtube: 'YouTube',
      dailymotion: 'DailyMotion',
      linkedin: 'LinkedIn',
    };

    const toUpperFirst = (str: string) => str[0].toUpperCase() + str.substr(1);

    const formatPlatform = (platformId: string) => PLATFORM_NAMES[platformId] || toUpperFirst(platformId);

    const allMoods = [];

    const isNotLoggedIn = isEmpty(this.props.model.user.user);
    const isLoggedIn = !isNotLoggedIn;

    let genres = null;
    let themes = null;
    let moods = null;
    let tempo = null;
    let bpm = null;

    let isBrandSponsoredTrack = null;
    let isClaimsManaged = null;
    let artistSlug = null;
    let releaseSlug = null;

    if (track) {
      if (track.artists.length > 0) {
        artistSlug = track.artists[0].slug;
      }
      if (track.releases.length > 0) {
        releaseSlug = track.releases[0].slug;
      }
      isBrandSponsoredTrack = track.brand_sponsored;

      genres = maybe(() => track.tags.hookd_genre, []);
      themes = maybe(() => track.tags.hookd_theme, []);
      moods = maybe(() => allMoods, []);
      tempo = maybe(() => track.tempo, null);
      bpm = maybe(() => track.bpm, null);

      if (track.reporting_party.slug == 'umg') {
        isClaimsManaged = true;
      } else {
        isClaimsManaged = false;
      }

      if (isArray(track.tags.hookd_mood)) {
        allMoods.push(...track.tags.hookd_mood);
      }
      if (isArray(track.tags.musiio_mood)) {
        allMoods.push(...track.tags.musiio_mood);
      }
    }

    if (isEmpty(track))
      return (
        <ScrollableOverlay show={show} onRequestClose={onRequestClose}>
          <div className={styles.container}>
            <h2>Song Information</h2>
            <Loading className={styles.loading} />
          </div>
        </ScrollableOverlay>
      );
    const trackIsavailable = track.is_available_in_territory === null ? true : track.is_available_in_territory;

    return (
      <ScrollableOverlay show={show} onRequestClose={onRequestClose}>
        <div className={styles.container}>
          <h2>Song Information</h2>
          <div className={styles.player}>
            <ImageWithPlayBtn track={track} />
            <PlayerBarNowPlaying
              analytics={controller.analytics}
              model={{ track }}
              onClickLink={controller.ui.handleClickLink}
            />
            {/* This is intensionally commented, comment will be removed when notify api is ready */}
            {!trackIsavailable ? (
              <span className={styles.notifyBtn}>{/* <NotifyButton /> */}</span>
            ) : (
              <div className={styles.addRemoveTrackBtns}>
                <span>
                  <AddRemoveCompat track={track} size={36} />
                </span>
                <div onClick={isNotLoggedIn ? onRequestClose : null}>
                  <FavouriteButtonCompat track={track} size={36} />
                </div>
                {isLoggedIn && (
                  <AddToPlaylistButton
                    track={track}
                    className={styles.addToPlaylistButton}
                    tooltipClassName={styles.addToPlaylistTooltip}
                  />
                )}
              </div>
            )}
          </div>
          <main className={styles.contentScroll}>
            {track.artists && (
              <div className={styles.sectionContainer} style={{ width: '100%' }}>
                <h3>Artists</h3>
                <div className={styles.artistList}>
                  <Person className={styles.personArtistList} size={16} />
                  <ArtistLinkList model={{ artists: track.artists }} linkClassName={styles.artistLinkList} />
                </div>
              </div>
            )}
            <div className={classes(styles.trackCharacteristicsContainer, styles.sectionContainer)}>
              <div className={styles.trackCharacteristic}>
                {genres && genres.length > 0 && (
                  <div>
                    <h3>Genres</h3>
                    <div className={styles.pillContainer}>
                      {genres.map((genre, index) => (
                        <ButtonPill
                          tertiary={true}
                          href={`/search?genre=${genre.slug}`}
                          // eslint-disable-next-line react/no-children-prop
                          children={genre.name}
                          className={styles.pill}
                          key={index.toString()}
                        />
                      ))}
                    </div>
                  </div>
                )}

                {moods && moods.length > 0 && (
                  <div>
                    <h3>Moods</h3>
                    <div className={styles.pillContainer}>
                      {moods.map((mood, index) => (
                        <ButtonPill
                          tertiary={true}
                          href={`/search?mood=${mood.slug}`}
                          // eslint-disable-next-line react/no-children-prop
                          children={mood.name}
                          className={styles.pill}
                          onClick={onRequestClose}
                          key={index.toString()}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.trackCharacteristic}>
                {themes && (
                  <div>
                    <h3>Themes</h3>
                    <div className={styles.pillContainer}>
                      {themes.map((theme, index) => (
                        <ButtonPill
                          tertiary={true}
                          href={`/search?theme=${theme.slug}`}
                          // eslint-disable-next-line react/no-children-prop
                          children={theme.name}
                          className={styles.pill}
                          key={index.toString()}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {((tempo && tempo !== 'Unknown') || !!bpm) && (
                  <div>
                    <h3>Tempo</h3>
                    <div className={styles.pillContainer}>
                      {!!bpm && (
                        <ButtonPill
                          className={styles.pill}
                          tertiary={true}
                          href={this.getBpmHref(bpm)}
                          onClick={onRequestClose}
                          // eslint-disable-next-line react/no-children-prop
                          children={`${bpm} BPM`}
                        />
                      )}
                      {!!tempo && (
                        <ButtonPill
                          className={styles.pill}
                          tertiary={true}
                          href={this.getTempoHref(tempo)}
                          onClick={onRequestClose}
                          // eslint-disable-next-line react/no-children-prop
                          children={tempo}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.sectionContainer} style={{ width: '100%' }}>
              <h3>Target Platforms</h3>
              <ul className={styles.platforms}>
                {track.video_platforms &&
                  Object.entries(track.video_platforms).map(([name, allowed], index) => (
                    <li key={index}>
                      {allowed ? (
                        <SVGIcon.Checkmark className={styles.targetIcons} color={colorGreen.toHexString()} size={16} />
                      ) : (
                        <SVGIcon.Cross className={styles.targetIcons} color={colorGunmetal.toHexString()} size={16} />
                      )}
                      <span>{formatPlatform(name)}</span>
                    </li>
                  ))}
              </ul>
            </div>
            <div
              className={classes(styles.additionalInfoContainer, styles.sectionContainer)}
              style={{ paddingBottom: '15px' }}
            >
              <h3>Additional Information</h3>

              {isClaimsManaged ? (
                <div className={classes(styles.additionalInfoIconText, styles.additionalInfo)}>
                  <ClaimsManaged size={14} className={styles.additionalInfoIconText} />
                  <span className={styles.additionalInfoIconText}>Claim-managed</span>
                  <ClaimsManagedTooltip />
                </div>
              ) : (
                <div className={classes(styles.additionalInfoIconText, styles.additionalInfo)}>
                  <ClaimsManaged size={14} className={styles.additionalInfoIconText} />
                  <span className={styles.additionalInfoIconText}>Automated claims clearance</span>
                </div>
              )}
            </div>
            <EulaModal
              show={this.state.showEulaModal}
              title={'End User License Agreement'}
              track={track}
              onRequestClose={this.hideEula.bind(this)}
            />
            <div
              className={classes(styles.headingsArrowsContainer, styles.sectionContainer)}
              onClick={() => this.showEula(track)}
            >
              <Description size={22} />
              <h3>View license agreement</h3>
              <ArrowRight className={styles.arrowBtn} />
            </div>
            {artistSlug && (
              <div className={styles.sectionContainer}>
                <Link
                  className={styles.headingsArrowsContainer}
                  to={`/music/artists/${artistSlug}`}
                  onClick={onRequestClose}
                >
                  <Person size={20} />
                  <h3>Go to artist</h3>
                  <ArrowRight className={styles.arrowBtn} />
                </Link>
              </div>
            )}
            {releaseSlug && (
              <Link
                className={styles.headingsArrowsContainer}
                to={`/music/artists/${artistSlug}/release/${releaseSlug}`}
                onClick={onRequestClose}
              >
                <Album size={20} />
                <h3>Go to release</h3>
                <ArrowRight className={styles.arrowBtn} />
              </Link>
            )}
          </main>
        </div>
      </ScrollableOverlay>
    );
  }
  getTempoHref = (tempo: string) => {
    const nextTempoRange = SongInfoModal.tempos.find((t) => t.label.toLowerCase() === tempo.toLowerCase());

    if (nextTempoRange && nextTempoRange.range) {
      const [min, max] = nextTempoRange.range;

      return `/search?bpmMin=${min}&bpmMax=${max}`;
    }
  };

  getBpmHref = (bpm: number) => {
    return `/search?bpmMin=${bpm}&bpmMax=${bpm}`;
  };

  static tempos = [
    {
      range: [0, 80],
      label: 'Very Slow',
    },
    {
      range: [80, 110],
      label: 'Slow',
    },
    {
      range: [110, 130],
      label: 'Medium',
    },
    {
      range: [130, 150],
      label: 'Fast',
    },
    {
      range: [150, 300],
      label: 'Very Fast',
    },
  ];
}

const styles = {
  container: style({
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    $nest: {
      '& li': {
        display: 'inline',
        padding: '0px 6px 8px 6px',
        whiteSpace: 'nowrap',
      },
      '& h3': {
        paddingLeft: '16px',
        fontFamily: fontBody,
        fontWeight: 600,
        fontSize: '16px',
        marginBottom: '8px',
        letterSpacing: 0,
      },
      '& h2': {
        textAlign: 'center',
        fontFamily: fontBody,
        fontWeight: 600,
        fontSize: '16px',
        margin: '16px',
        letterSpacing: 0,
      },
    },
  }),
  contentScroll: style({
    overflowY: 'scroll',
  }),
  targetIcons: style({
    paddingRight: '4px',
    top: '2px',
  }),
  player: style({
    display: 'flex',
    flexShrink: 0,
    background: colorContrast100.toHexString(),
  }),
  addRemoveTrackBtns: style(centerCenter, horizontal, horizontallySpaced(10), {
    padding: '0 10px 0 8px',
    marginLeft: 'auto',
    flexShrink: 0,
  }),
  addToPlaylistButton: style({ width: px(36), height: px(36) }),
  addToPlaylistTooltip: style({ zIndex: zModal }),
  headingsArrowsContainer: style({
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 15px 0 15px',

    $nest: {
      '& h3': {
        marginRight: 'auto',
        color: colorGunmetal.toHexString(),
        marginBottom: '16px',
      },

      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
  additionalInfoContainer: style({
    paddingTop: '1px',
    backgroundColor: colorLightBlueGrey.toHexString(),
  }),
  additionalInfo: style({ marginLeft: '15px' }),
  additionalInfoIconText: style({
    display: 'flex',
    alignItems: 'center',
    $nest: {
      '& span': {
        fontSize: '14px',
        margin: '0 4px 0 8px',
      },
    },
  }),
  arrowBtn: style({
    margin: '20px 9px 20px 0px',
  }),
  pillContainer: style({ marginLeft: '8px' }),
  pill: style({
    maxWidth: percent(90),
    margin: '0 0 8px 8px',
    borderRadius: '4px',
    fontSize: '12px',
    padding: '2px 4px',
    boxShadow: '0px 2px 4px 1px rgba(0,0,0,0.3) !important',
    $nest: {
      '&:last-child': {
        marginBottom: '16px',
      },
      '&:first-child': {
        marginTop: '8px',
      },
    },
  }),
  trackCharacteristicsContainer: style({
    display: 'flex',
  }),
  trackCharacteristic: style({
    width: '50%',
    $nest: {
      '& h3': {
        marginBottom: '0px',
      },
    },
  }),
  platforms: style({
    maxWidth: '90%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '10px',
    margin: '0px',
  }),
  sectionContainer: style({
    borderBottom: '1px solid #E0E4E9',
  }),
  loading: style({
    top: '40%',
    width: percent(50),
    marginLeft: 'auto',
    marginRight: 'auto',
  }),
  artistList: style({
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '20px',
    marginBottom: '16px',
  }),
  personArtistList: style({
    marginTop: '4px',
    marginRight: '8px',
  }),
  artistLinkList: style({ color: colorGunmetal.toString(), $nest: { ...resetAnchorStyles(colorGunmetal.toString()) } }),
  notifyBtn: style({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 'auto',
    padding: ' 0 10px 0 8px',
  }),
};
