import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const Album: React.SFC<SVGIconProps> = ({ size = 20, isSquare, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={isSquare ? size : size * (20 / 20)} viewBox="0 0 20 20" {...props}>
    <path
      data-test-album-icon
      fill={color}
      transform="translate(-2 -2)"
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm0 14.5c-2.49 0-4.5-2.01-4.5-4.5S9.51 7.5 12 7.5s4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5Zm0-5.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1Z"
    />
  </BaseSvg>
);
