import { observable, ObservableMap } from 'mobx';
import { PaginationMeta } from '../../../types';
import { PlaylistSchema, TrackSchema } from '../../../types/schema';
import { APIPaginatedResponse } from '../../api/types';

export class UserPlaylistsPageModel {
  @observable
  loading = false;

  @observable
  pagination: PaginationMeta;

  @observable
  page = 1;

  @observable
  playlistIdentity: string;

  @observable playlistTracks: ObservableMap<string, APIPaginatedResponse<TrackSchema[]>> = observable.map();
  @observable playlistMetadata: ObservableMap<string, PlaylistSchema> = observable.map();
}
