import { observable } from 'mobx';

export class ContactPageModel {
  @observable
  loading: boolean = false;

  @observable
  page_title: any = null;

  @observable
  page_body: any = null;

  @observable
  cover_image: any = null;

  @observable
  email_block: any = null;

  @observable
  address_block: any = null;
}