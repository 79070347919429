import * as React from 'react';
import { ClientModel } from '../modules/client/model';
import { ClientController } from '../modules/client/controller';
import { RouterState } from 'react-router';

export const deps = (provides: any): ComponentProps => ({
  model: provides.model,
  controller: provides.controller,
});

export interface ComponentProps {
  model?: ClientModel;
  controller?: ClientController;
}

export class StatefulComponent<T, S> extends React.Component<T & ComponentProps, S> {
  static async getInitialProps(state: RouterState, model: ClientModel, controller: ClientController): Promise<any> {
    return {};
  }
}

export class Component<T, P = any> extends StatefulComponent<T, P> {}

export { inject, observer } from 'mobx-react';
