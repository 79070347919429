import * as React from 'react';
import {observer} from 'mobx-react';
import {colorBrand, colorSubtle} from '../theme/color';
import {px} from 'csx';
import {style} from 'typestyle';

export interface PageAccountBillingVatNumberModel {
  vatNumber: string;
}

export interface PageAccountBillingVatNumberProps {
  model: PageAccountBillingVatNumberModel;
  onEdit(): void;
  i18n?: {
    title: string;
    empty: string;
    edit: string;
    add: string;
  };
}

const DEFAULT_I18N = {
  title: 'VAT number',
  empty: 'No VAT number',
  edit: 'edit',
  add: 'add'
};

@observer
export class PageAccountBillingVatNumber extends React.Component<PageAccountBillingVatNumberProps, {}> {
  render() {
    const { model, i18n = DEFAULT_I18N } = this.props;

    return (
      <div className={PageAccountBillingVatNumber.styles.container}>
        <h2>{i18n.title}</h2>
        <div>
          {model.vatNumber && (
            <span>{model.vatNumber}</span>
          )}
          {!model.vatNumber && (
            <span className={PageAccountBillingVatNumber.styles.empty}>{i18n.empty}</span>
          )}
          <a className={PageAccountBillingVatNumber.styles.action} onClick={this.handleEdit}>
            {model.vatNumber ? i18n.edit : i18n.add}
          </a>
        </div>
      </div>
    )
  }

  handleEdit = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    this.props.onEdit();
  };

  static styles = {
    container: style({
      marginBottom: px(40)
    }),
    empty: style({
      color: colorSubtle.fade(0.7).toString()
    }),
    action: style({
      color: colorBrand.toString(),
      cursor: 'pointer',
      marginLeft: px(20),
      fontSize: px(14)
    })
  }
}