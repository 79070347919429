import React from 'react';
import { Component, deps, inject, observer } from '../../lib/component';
import { formatCurrency } from '../../lib/currency';
import { Button } from '../project-happy/atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../project-happy/atoms/button/buttonStyles';
import { styles } from './styles';
import { LOAD_STATE } from '../../types/api';
import { classes } from 'typestyle';
import { LoadingDots } from '../project-happy/atoms/icons/LoadingDots';
import { SrOnlyText } from '../project-happy/atoms/SrOnlyText';

interface CheckoutActionsProps {
  className?: string;
  orderReady?: boolean;
  termsAccepted: boolean;
  isSubmitting: boolean;
  handleCheckout: (event: any, ref?: any) => void;
}

@inject(deps)
@observer
export class CheckoutActions extends Component<CheckoutActionsProps> {
  render() {
    const { handleCheckout, termsAccepted, className, isSubmitting } = this.props;
    const { checkout, subscription, user, basket } = this.props.model;
    const isOrderLoading = checkout.orderState === LOAD_STATE.LOADING;
    const showLoadingState = isOrderLoading || isSubmitting;

    let buttonText = 'Checkout';
    let total: number | string = checkout.total;
    let disabled = true;

    if (!user.user) {
      buttonText = 'Sign in to checkout';
      total = basket.total;
      // The user isn't signed in, button will pop up a sign in modal, don't disable
      disabled = false;
    } else if (checkout.orderState === LOAD_STATE.ERROR) {
      buttonText = 'An error occurred';
    } else if (checkout.needsSubscription) {
      // If the user needs a subscription to checkout, a modal will be shown on click,
      // so don't disable unless the terms haven't been accepted
      disabled = !termsAccepted;
    } else if (checkout.ready) {
      // Order is ready, don't disable unless the terms haven't been accepted or the form is submitting
      disabled = !termsAccepted || isSubmitting;
    }

    return (
      <div className={className}>
        {!isOrderLoading && (
          <div className={styles.totalWrapper}>
            <span>Total to pay</span>
            <span data-test-total-checkout-price className={classes(styles.totalText, styles.largeText)}>
              {formatCurrency(total, checkout.currency)}
            </span>
          </div>
        )}

        <Button
          disabled={disabled}
          prominence={ButtonProminence.HIGHLIGHT}
          size={ButtonSize.MEDIUM}
          onClick={handleCheckout}
          className="checkoutButton"
          type="submit"
          data-test-checkout-submit-button
        >
          {showLoadingState ? (
            <>
              <LoadingDots />
              <SrOnlyText>Loading</SrOnlyText>
            </>
          ) : (
            buttonText
          )}
        </Button>
      </div>
    );
  }
}
