import { quote } from 'csx';

export const fontBody = 'Inter';
export const fontHeading = 'Flood';
export const fontIcons = 'hookd-icons';

export const fontDefault = `${quote(fontBody)}, sans-serif`;
export const fontTitles = `${quote(fontHeading)}, ${quote(fontBody)}, 'Segoe UI', sans-serif`;
export const fontSerif = 'serif';
export const fontBebasNeue = 'Bebas Neue';
