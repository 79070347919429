/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { LicenceSchema } from '../../../types/schema';
import { style } from 'typestyle';
import { OrderLicence } from '../molecules/OrderLicence';

interface OrderLicenseListProps {
  licences: LicenceSchema[];
  currency: string;
}
export const OrderLicenseList = ({ licences, currency }: OrderLicenseListProps) => {
  return (
    <div className={styles.container}>
      {licences.map((license) => (
        <OrderLicence licence={license} currency={currency} key={license.identity} isDownload />
      ))}
    </div>
  );
};

const styles = {
  container: style({
    width: '100%',
  }),
};
