import * as React from 'react';
import { classes, extend, style } from 'typestyle';
import { em, percent, px } from 'csx';
import { colorWhite, rebrand } from '../../../theme/color';
import { aroundJustified, center, flexRoot, vertical } from 'csstips';
import { formatNonDecimalCurrency } from '../../../lib/currency';
import { RatecardSchema } from '../../../types/schema';
import { heading6, headingStyles } from '../utilities/font';
import { HighlightText } from '../atoms/HighlightText';

const styles = {
  wrapper: style(flexRoot, vertical, center, aroundJustified, {
    background: rebrand.light1.toString(),
    flexDirection: 'column',
    color: rebrand.dark1.toString(),
    textAlign: 'center',
    fontSize: px(11),
    position: 'relative',
    margin: '0 8px 8px',
    padding: px(0),
    height: px(159),
    borderRadius: em(0.3),
    $nest: {
      '&.highlight': {
        height: px(192),
        backgroundColor: rebrand.primary1.toString(),
        color: colorWhite.toString(),
      },
    },
  }),
  highlightTitle: style(headingStyles, heading6, {
    margin: '4px 0 0',
  }),
  priceBox: style({
    display: 'block',
    flexShrink: 0,
    backgroundColor: rebrand.dark1.toString(),
    color: colorWhite.toString(),
    borderRadius: px(4),
    width: percent(90),
    marginTop: px(8),
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    $nest: {
      '.highlight &': {
        backgroundColor: rebrand.highlight1.toString(),
        color: rebrand.dark1.toString(),
        $nest: {
          '&::after': {
            borderTopColor: rebrand.highlight1.toString(),
          },
        },
      },
      '&::after': {
        content: '""',
        width: 0,
        height: 0,
        border: '16px solid transparent',
        borderBottomWidth: 0,
        borderTopColor: rebrand.dark1.toString(),
        position: 'absolute',
        bottom: px(-16),
        left: 'calc(50% - 16px)',
      },
    },
  }),
  viewership: style({
    width: percent(50),
    $nest: {
      '& abbr': extend(headingStyles, heading6, {
        display: 'block',
      }),
    },
  }),
};

export type PricingTileProps = { className?: string; ratecard: RatecardSchema; highlight?: boolean };

export const PricingTile: React.SFC<PricingTileProps> = ({ className, ratecard, highlight = false }) => (
  <div className={classes(styles.wrapper, highlight && 'highlight', className)}>
    {highlight && <h6 className={styles.highlightTitle}>Your Plan</h6>}
    <HighlightText prefixed={false} className={styles.priceBox}>
      {formatNonDecimalCurrency(ratecard.rate, ratecard.currency)} per song license
    </HighlightText>
    <p className={styles.viewership}>
      <abbr title={ratecard.viewership.toLocaleString()}>{ratecard.name}</abbr> average views per video
    </p>
  </div>
);
