import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const PlaylistAdded: React.SFC<SVGIconProps> = ({ size = 20, color = 'white', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 20 20" {...props}>
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L4 11L5.41 9.59L8 12.17L11.795 8.375L14.59 5.58L16 7L8 15Z"
      fill={color}
    />
  </BaseSvg>
);
