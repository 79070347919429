import React from 'react';
import { Skeleton } from '../../atoms/Skeleton';
import { style } from 'typestyle';
import { spacing } from '../../../../theme/spacing';

export const RatecardSkeleton: React.FC = () => {
  const styles = {
    wrapper: style({
      width: '100%',
      padding: spacing.DEFAULT,
    }),
    channel: style({
      marginBottom: spacing.DEFAULT,
    }),
  };

  return (
    <div className={styles.wrapper} data-test-ratecard-loader>
      <div className={styles.channel}>
        <Skeleton height={57} />
      </div>
      <Skeleton height={67} />
    </div>
  );
};
