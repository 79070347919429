import * as React from 'react';
import { ModalProps } from '../../modal';
import { ScrollableOverlay } from '../../scrollable-overlay/scrollable-overlay';
import overlayStyles, { ScrollableOverlayBodyStyle } from '../../../styles/scrollable-overlay';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { Component, deps, inject, observer } from '../../../lib/component';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { rebrand } from '../../../theme/color';

const styles = {
  wrapper: style({ display: 'block', maxWidth: px(530) }),
  heading: style({
    fontSize: px(32),
  }),
  body: style(ScrollableOverlayBodyStyle, {
    padding: '1.5rem 0 0',
    borderTop: `1px solid ${rebrand.light1.toString()}`,
    textAlign: 'center',
    $nest: {
      '& > p': {
        margin: '0 0 1.5rem',
        fontSize: px(20),
      },
    },
  }),
  button: style({
    display: 'block',
    width: '100%',
  }),
};

export type PostDowngradeModalProps = ModalProps & { className?: string };

@inject(deps)
@observer
export class PostDowngradeModal extends Component<PostDowngradeModalProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { onRequestClose } = this.props;
    const { postDowngradeModalSelectedPlan } = this.props.model.modal;

    return (
      <ScrollableOverlay className={styles.wrapper} {...this.props}>
        <div className={overlayStyles.header}>
          <h3 className={classes(overlayStyles.title, styles.heading)}>
            Downgraded to {postDowngradeModalSelectedPlan}
          </h3>
        </div>

        <div className={styles.body}>
          <p>
            <strong>Changes will take effect when your subscription renews.</strong>
          </p>

          <div>
            <Button
              prominence={ButtonProminence.SECONDARY}
              size={ButtonSize.LARGE}
              className={styles.button}
              onClick={onRequestClose}
              data-test-downgrade-modal-button
            >
              Got it
            </Button>
          </div>
        </div>
      </ScrollableOverlay>
    );
  }
}
