import * as React from 'react';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { ContentSliceProps } from './ContentSlice';
import { ArtistSchema } from '../../../../types/schema';
import { mediaDesktop, mediaUpToDesktop } from '../../utilities/mediaQueries';
import { DecoratedPageCarousel, DecoratedPageCarouselProps } from '../DecoratedPageCarousel';
import { colorGunmetal } from '../../../../theme/color';
import { shadowElevation2 } from '../../../../theme/shadow';
import { BaseCarouselSlice } from './BaseCarouselSlice';
import { ArtistTile } from '../../molecules/ArtistTile';
import { FavouriteArtistButtonCompat } from '../../../compat/favourite-artist-button-compat';
import { LargeArtistTile } from '../../molecules/LargeArtistTile';
import { SeeAllButton } from '../../molecules/panels/atoms/SeeAllButton';

const styles = {
  carouselControls: style({
    $nest: {
      '& > button': {
        transform: 'translateY(-34px)',
      },
    },
  }),
  carousel: style(
    mediaUpToDesktop({
      marginLeft: px(-16),
      marginRight: px(-16),
      width: 'calc(100% + 32px)',
    })
  ),
  trackTile: style(
    {
      $nest: {
        ':not(.with-gradient) &': {
          color: colorGunmetal.toHexString(),
        },
      },
    },
    mediaUpToDesktop({
      margin: 0,
      paddingLeft: px(16),
    })
  ),
  trackPlay: style(
    {
      position: 'absolute',
      bottom: px(81),
      zIndex: 1,
    },
    mediaUpToDesktop({
      right: px(8),
    }),
    mediaDesktop({
      right: px(16),
    })
  ),
  channelButton: style({
    backgroundColor: '#E0E4E9',
    color: '#2E2E3B',
    height: px(32),
    fontSize: px(16),
    $nest: {
      '&:hover, &:active': {
        backgroundColor: '#E0E4E9',
        ...shadowElevation2,
      },
    },
  }),
};

// Exclude gradient prop as this slice has a two tone background
type CarouselSliceProps = ContentSliceProps &
  DecoratedPageCarouselProps & { artists: ArtistSchema[]; actionHref?: string };

export const ArtistCarouselSlice: React.SFC<CarouselSliceProps> = ({
  children,
  className,
  contentClassName,
  artists,
  actionHref,
  carouselClassName,
  actions,
  ...props
}) => {
  const shouldUseLargeTile = props.perPage <= 3;
  if (shouldUseLargeTile) {
    props.mobileTileSize = 85.3;
  }
  const defaultAction = (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <SeeAllButton href={actionHref || '/browse/artists'} data-test-see-all-button-artist-carousel-slice>
        See all
      </SeeAllButton>
    </div>
  );
  return (
    <BaseCarouselSlice className={className} contentClassName={contentClassName}>
      <DecoratedPageCarousel
        actions={React.isValidElement(actions) || actions === null ? actions : defaultAction}
        controlClassName={!shouldUseLargeTile && styles.carouselControls}
        carouselClassName={classes(styles.carousel, carouselClassName)}
        {...props}
      >
        {artists.map((artist, key) =>
          shouldUseLargeTile ? (
            <LargeArtistTile key={key} artist={artist} />
          ) : (
            <ArtistTile key={key} artist={artist} className={styles.trackTile}>
              <FavouriteArtistButtonCompat artist={artist} />
            </ArtistTile>
          )
        )}
      </DecoratedPageCarousel>
    </BaseCarouselSlice>
  );
};
