import * as React from 'react';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { heading4, heading5, headingStyles } from '../../../utilities/font';
import { mediaDesktop, mediaUpToDesktop } from '../../../utilities/mediaQueries';

const styles = style(
  headingStyles,
  {
    color: 'white',
  },
  mediaUpToDesktop(heading5, {
    marginLeft: px(16),
    marginTop: px(16),
  }),
  mediaDesktop(heading4, {
    marginLeft: px(32),
    marginTop: px(32),
  })
);

export type HeadingProps = React.Component['props'] & { className?: string };

export const Heading: React.SFC<HeadingProps> = ({ children, className }) => (
  <h3 className={classes(styles, className)}>{children}</h3>
);
