import * as React from 'react';
import { ButtonCircle } from './button-circle';
import { SVGIcon } from './svg-icon';
import { classes, style } from 'typestyle';
import { colorGreyAccent, colorGunmetal, colorLickdPink, colorWhite, rebrand } from '../theme/color';
import { transitionQuickEase } from '../theme/transition';
import { important, px } from 'csx';
import { animationPulse } from '../theme/animation';
import { observer } from 'mobx-react';
import { ShoppingBasket } from './project-happy/atoms/icons/ShoppingBasket';
import { MarkCircle } from './project-happy/atoms/icons/MarkCircle';
import { DashCircle } from './project-happy/atoms/icons/DashCircle';
import { animationScaleUpDown } from '../theme/animation';

export interface HookItProps {
  className?: string;
  size?: number;
  onClick?(event: React.MouseEvent<any>): void;
  isSync?: boolean;
  isHookd?: boolean;
  label?: string;
  showLabel?: boolean;
  removeOnly?: boolean;
  reverse?: boolean;
  invert?: boolean;
}

@observer
export class HookIt extends React.Component<HookItProps, any> {
  state = { isHovered: false };

  render() {
    const {
      size = 28,
      className,
      isHookd,
      reverse,
      isSync,
      label,
      showLabel = false,
      removeOnly,
      invert,
      onClick,
    } = this.props;

    const iconSize = Math.floor(size / 2);
    const fontSize = px(size / 2 - 1);

    const styles = HookIt.styles;

    const btnClasses = classes(
      className,
      styles.common,
      removeOnly ? styles.actionRemoveOnly : styles.actionDefault,
      isHookd ? styles.hookd : styles.unhookd,
      isHookd && !invert && styles.hookdNormal,
      !isHookd && !invert && styles.unhookdNormal,
      isHookd && invert && styles.hookdInverted,
      !isHookd && invert && styles.unhookdInverted,
      isSync && styles.sync,
      showLabel && 'with-label',
      showLabel && (reverse ? styles.withLabelReverse : styles.withLabel)
    );

    return (
      <div>
        <ButtonCircle
          className={btnClasses}
          onClick={onClick}
          size={size}
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}
          data-test-track-license-button={isHookd}
          aria-label={showLabel ? undefined : label}
        >
          {!removeOnly && (
            <>
              {isHookd ? (
                <ShoppingBasket className={styles.icon} size={iconSize} color={colorLickdPink.toString()} />
              ) : (
                <ShoppingBasket className={styles.icon} size={iconSize} color={colorWhite.toString()} />
              )}
              {isHookd && (
                <>
                  {!this.state.isHovered ? (
                    <MarkCircle size={10} className={styles.checkIcon} />
                  ) : (
                    <DashCircle size={10} className={styles.checkIcon} />
                  )}
                </>
              )}
            </>
          )}
          {showLabel && reverse && <span style={{ fontSize }}>{label}</span>}
          {removeOnly && <SVGIcon.Cross size={iconSize} />}
          {showLabel && !reverse && <span style={{ fontSize }}>{label}</span>}
        </ButtonCircle>
      </div>
    );
  }

  static styles = {
    common: style({
      flexShrink: 0,
      $nest: {
        '& path': {
          transition: `all ${transitionQuickEase}`,
        },
        '& svg': {
          transition: `all ${transitionQuickEase}`,
        },
      },
    }),
    hookdNormal: style({
      // Basket button colour when selected
      backgroundColor: important(colorGreyAccent.toString()),
    }),
    hookdInverted: style({
      backgroundColor: important(colorGunmetal.toString()),
    }),
    actionRemoveOnly: style({}),
    actionDefault: style({
      $nest: {
        '& svg': {
          margin: '-1px 0 0 0',
        },
      },
    }),
    icon: style({
      flexShrink: 0,
    }),
    iconBounce: style(animationScaleUpDown),
    hookd: style({
      transition: `background-color ${transitionQuickEase}`,
      position: 'relative',
      $nest: {
        '&:hover': {
          backgroundColor: important(rebrand.neutralOnDark['40'].toString()),
        },
        '& span': {
          color: colorLickdPink.toString(),
          fontWeight: 600,
        },
      },
    }),
    unhookdNormal: style({
      // Basket Button default colour
      backgroundColor: important(rebrand.primary[100].toString()),
    }),
    unhookdInverted: style({
      backgroundColor: important(colorGunmetal.toString()),
    }),
    unhookd: style({
      transition: `background-color ${transitionQuickEase}`,
      $nest: {
        '&:hover': {
          backgroundColor: important(rebrand.primary[50].toString()),
        },
        '& span': {
          transition: `color ${transitionQuickEase}`,
          color: colorWhite.toString(),
          fontWeight: 600,
        },
        '&:hover span': {
          color: colorWhite.toString(),
        },
      },
    }),
    sync: style({
      $nest: {
        '& svg#shopping-basket': {
          ...animationPulse,
        },
      },
    }),
    withLabel: style({
      width: important('auto'),
      padding: '0 1em 0 0.6em',
      $nest: {
        '& span': {
          margin: '0 0 0 0.5em',
          lineHeight: '1em',
        },
      },
    }),
    withLabelReverse: style({
      width: important('auto'),
      padding: '0 1em',
      $nest: {
        '& span': {
          margin: '0 0 0 0.25em',
          lineHeight: '1em',
        },
      },
    }),
    checkIcon: style({
      position: 'absolute',
      top: px(2),
      right: 0,
    }),
  };
}
