import { centerCenter, flexRoot, vertical } from 'csstips';
import { px } from 'csx';
import * as React from 'react';
import { style } from 'typestyle';
import { Component, ComponentProps, deps, inject, observer } from '../../../lib/component';
import { colorBrand, colorSubtle, rebrand } from '../../../theme/color';
import { Loading } from '../../loading/loading';
import { defaultHeadingStyles } from '../utilities/font';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';
import { Button } from '../atoms/button/Button';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { animationSpin } from '../../../theme/animation';
import { mediaLargeTablet } from '../utilities/mediaQueries';
import { AccountLayout } from '../templates/AccountLayout';
import { BackToTopButton } from '../atoms/button/BackToTopButton';
import { LoadMoreButton } from '../atoms/button/LoadMoreButton';
import { UserPlaylistThumbnail } from '../atoms/UserPlaylistThumbnail';
import { UserPlaylistCard } from '../molecules/UserPlaylistCard';
import { CreateUserPlaylistModal } from '../organisms/CreateUserPlaylistModal';

dayjs.extend(relativeTime);

const styles = {
  heading: style(defaultHeadingStyles, { flexGrow: 1 }),
  containerLoading: style({
    ...centerCenter,
  }),
  empty: style(centerCenter, vertical, {
    height: px(120),
    fontWeight: 200,
    fontSize: px(18),
    color: colorSubtle.toString(),
  }),
  buttonWrapper: style({ display: 'flex', justifyContent: 'flex-end' }),
  header: style(flexRoot, { alignItems: 'baseline', flexWrap: 'wrap' }),
  createPlaylistButton: style({ marginRight: px(24), marginLeft: px(24) }),
  playlistsWrapper: style({
    padding: px(8),
  }),
  cardWrapper: style({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
  }),
  playlistCard: style(
    {
      margin: '8px 0',
    },
    mediaLargeTablet({
      width: 'calc(50% - 8px)',
    })
  ),
  cardActionButton: style({
    margin: '8px',
  }),
};

@inject(deps)
@observer
export class AccountUserPlaylistsPage extends Component<ComponentProps> {
  state = {
    showCreatePlaylistModal: false,
  };

  constructor(props) {
    super(props);
    this.handleClickCreatePlaylist = this.handleClickCreatePlaylist.bind(this);
  }

  handleClickCreatePlaylist() {
    this.props.controller.modal.showCreatePlaylistModal();
  }

  render() {
    const {
      model: {
        user: { playlists, playlistPagination: pagination, loadingPlaylists: loading },
      },
      controller: {
        user: { loadMorePlaylists },
      },
    } = this.props;

    const Header = (
      <div className={styles.header}>
        <h1 className={styles.heading}>Playlists</h1>
        <Button
          prominence={ButtonProminence.SECONDARY}
          size={ButtonSize.SMALL}
          onClick={this.handleClickCreatePlaylist}
          data-test-user-playlists-create-button
        >
          New playlist
        </Button>
      </div>
    );

    if (loading && playlists.length === 0)
      return (
        <AccountLayout>
          {Header}
          <div className={styles.containerLoading}>
            <Loading />
          </div>
        </AccountLayout>
      );

    return (
      <AccountLayout>
        <div className={styles.playlistsWrapper}>
          {Header}

          {playlists.length === 0 && (
            <div className={styles.empty}>
              <p>You don&apos;t have any playlists yet.</p>
              <Button
                prominence={ButtonProminence.SECONDARY}
                size={ButtonSize.SMALL}
                onClick={this.handleClickCreatePlaylist}
                data-test-user-playlists-create-button
              >
                Create a playlist
              </Button>
            </div>
          )}
          <div className={styles.cardWrapper}>
            {playlists.map((playlist) => (
              <UserPlaylistCard className={styles.playlistCard} playlist={playlist} key={playlist.identity} />
            ))}
          </div>
          <div className={styles.buttonWrapper}>
            <BackToTopButton />
            {pagination && pagination.total_pages > 1 && (
              <LoadMoreButton
                onClick={loadMorePlaylists}
                disabled={pagination.current_page === pagination.total_pages}
                loading={loading}
                data-test-user-playlists-page-load-more
              />
            )}
          </div>
        </div>
      </AccountLayout>
    );
  }
}
