import { SubscriptionModel } from './model';
import { action, autorun } from 'mobx';
import { APIError } from '../api/types';
import { OnboardingModel } from '../onboarding/model';
import { LOAD_STATE } from '../../types/api';
import { APIController } from '../api/controller';
import { UserModel } from '../user/model';
import { BugsnagController } from '../bugsnag/controller';
import { USER } from '../auth/model';
import {
  CreditPlanTypes,
  isCancelledSubscription,
  SubscriptionChangeTypes,
  SubscriptionStates,
  V2SubscriptionChangePayload,
} from '../../components/project-happy/utilities/types';
import { UserController } from '../user/controller';
import { isEmpty } from '../../components/project-happy/utilities/objects';
import { ModalController } from '../modal/controller';

export class SubscriptionController {
  disposers: Array<() => void> = [];

  constructor(
    private model: SubscriptionModel,
    private onboarding: OnboardingModel,
    private userModel: UserModel,
    private api: APIController,
    private bugsnag: BugsnagController,
    private userController: UserController,
    private modal: ModalController
  ) {
    this.loadSubscriptionPlans();

    // Load plan prices when the user has a channel
    // Also runs if the channel changes
    this.disposers.push(
      autorun(() => {
        if (this.userModel.channel) {
          this.loadPlanPricesV2(this.userModel.channel.id);
        }
      })
    );
  }

  dispose() {
    this.disposers.forEach((disposer) => disposer());
  }

  @action
  async loadSubscriptionPlans() {
    this.model.subscriptionPlansState = LOAD_STATE.LOADING;
    try {
      const { data } = await this.api.subscription.getSubscriptionPlans();
      this.model.subscriptionPlans = data;
      this.model.subscriptionPlansState = LOAD_STATE.READY;
    } catch (e) {
      this.model.subscriptionPlansState = LOAD_STATE.ERROR;
      if (e instanceof APIError) {
        this.model.subscriptionPlansError = e.reason;
      }
      this.bugsnag.notifyException(e);
    }
  }

  @action
  async navigateToStripePortal() {
    try {
      const {
        data: { portal_url },
      } = await this.api.subscription.getSubscriptionManagementUrl();
      window.location.href = portal_url;
    } catch (e) {
      let error = 'An unknown error occurred redirecting you to the subscription management portal';
      if (e instanceof APIError) {
        error = e.reason;
      }
      throw Error(error);
    }
  }

  async navigateToStripeSubscription(): Promise<void> {
    const id = this.userModel.channel ? this.userModel.channel.id : null;

    try {
      const {
        data: { checkout_url },
      } = await this.api.subscription.getSubscriptionUrl(id);
      // Clear the cached user model: when the user comes back, we'll need to wait for a new model
      window.sessionStorage.removeItem(USER);
      window.location.href = checkout_url;
    } catch (e) {
      let error = 'An unknown error occurred during subscription process.';
      if (e instanceof APIError) {
        error = e.reason;
      }
      throw Error(error);
    }
  }

  /** Load V2 plan prices for a channel (Project Subscription) */
  @action
  loadPlanPricesV2 = async (channelId: string): Promise<void> => {
    this.model.creditSubscriptionPlansState = LOAD_STATE.LOADING;
    try {
      const { data } = await this.api.subscription.getV2PlanPrices(channelId);
      this.model.creditSubscriptionPlans = data;
      this.model.creditSubscriptionPlansState = LOAD_STATE.READY;
    } catch (e) {
      this.model.creditSubscriptionPlansState = LOAD_STATE.ERROR;
      if (e instanceof APIError) {
        this.model.v2PlansLoadError = e.reason;
      }
      this.bugsnag.notifyException(e);
    }
  };

  @action
  navigateToStripeSubscriptionV2 = async (stripePriceId: string): Promise<void> => {
    const channelId = this.userModel.channel ? this.userModel.channel.id : null;

    this.setV2PlanIsUpdating(true);

    try {
      const {
        data: { checkout_url },
      } = await this.api.subscription.getV2PlanSubscriptionUrl(channelId, stripePriceId);

      this.setV2PlanIsUpdating(false);
      // Clear the cached user model: when the user comes back, we'll need to wait for a new model
      window.sessionStorage.removeItem(USER);
      window.location.href = checkout_url;
    } catch (e) {
      this.setV2PlanIsUpdating(false);

      let error = 'An unknown error occurred during subscription process.';
      if (e instanceof APIError) {
        error = e.reason;
      }

      this.model.v2PlansSubscribeError = error;
      this.bugsnag.notifyException(error);
    }
  };

  @action
  navigateToStripeSubscriptionChange = async (stripePriceId: string, planName: CreditPlanTypes): Promise<void> => {
    const channelId = this.userModel.channel ? this.userModel.channel.id : null;

    this.setV2PlanIsUpdating(true);

    try {
      const {
        data: { checkout_url, change_type },
      } = await this.api.subscription.getV2PlanSubscriptionChangeDetails(channelId, stripePriceId);

      this.setV2PlanIsUpdating(false);

      if (change_type === SubscriptionChangeTypes.UPGRADE && checkout_url) {
        // Clear the cached user model to trigger a fresh fetch of user details
        window.sessionStorage.removeItem(USER);
        // For upgrades, go to the Stripe checkout URL
        window.location.href = checkout_url;
      } else if (change_type === SubscriptionChangeTypes.DOWNGRADE) {
        // Refresh the user
        await this.userController.refreshUser();
        // Trigger the post-downgrade modal and poll for changes
        this.modal.showPostDowngradeModal(planName);
        this.pollChannelForChanges(SubscriptionChangeTypes.DOWNGRADE, planName);
      }
    } catch (e) {
      this.setV2PlanIsUpdating(false);
      let error = 'An unknown error occurred while trying to change your subscription.';
      if (e instanceof APIError) {
        error = e.reason;
      }

      this.model.v2PlansSubscribeError = error;
      this.bugsnag.notifyException(error);
    }
  };

  @action
  setV2PlanIsUpdating = (val: boolean): void => {
    this.model.v2PlanIsUpdating = val;
  };

  /** Refetch plans if changes haven't been reflected yet */
  @action
  pollChannelForChanges = (
    changeType: SubscriptionChangeTypes | SubscriptionStates.CANCELLED,
    newPlanName?: CreditPlanTypes
  ): void => {
    if (this.model.pollChannelTimer) {
      clearTimeout(this.model.pollChannelTimer);
    }

    const handler = async () => {
      const { channel, loadingChannels, user } = this.userModel;
      const { getYoutubeChannel, getYoutubeChannels, fetchCredits } = this.userController;

      let hasUpdated = !isEmpty(channel) && !loadingChannels;

      if (!isEmpty(channel)) {
        if (changeType === SubscriptionChangeTypes.DOWNGRADE && newPlanName) {
          hasUpdated = !!channel.pending_subscription && channel.pending_subscription.plan_name === newPlanName;
        } else if (changeType === SubscriptionChangeTypes.UPGRADE && newPlanName) {
          hasUpdated = !!channel.subscription && channel.subscription.plan_name === newPlanName;
        } else if (changeType === SubscriptionStates.CANCELLED) {
          hasUpdated = isCancelledSubscription(channel.subscription_status);
        }
      }

      if (!hasUpdated) {
        await Promise.all([getYoutubeChannel(user.default_channel), getYoutubeChannels(), fetchCredits]);

        this.model.pendingChannelUpdate = true;
        this.model.pollChannelTimer = setTimeout(handler, 2000);

        return;
      }

      this.model.pendingChannelUpdate = false;
    };

    handler();
  };
}
