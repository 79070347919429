import Prismic from 'prismic-javascript';
import { action } from 'mobx';
import { RouterState } from 'react-router';
import { MCNPageModel } from './model';
import { PrismicController } from '../../prismic/controller';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import { ImageController } from '../../image/controller';
import { RouterModel } from '../../router/model';
import { stripQueryString } from '../../../lib/string';
import { ContentModel } from '../../content/model';

export class MCNPageController {
  constructor(
    private model: MCNPageModel,
    private prismic: PrismicController,
    private env: EnvModel,
    private ui: UIController,
    private router: RouterModel,
    private content: ContentModel,
    private image: ImageController,
  ) {}

  @action
  onEnter = async (nextState: RouterState) => {
    this.model.loading = true;
    this.model.slug = stripQueryString(nextState.params['slug']);

    if (this.model.slug) return this.onEnterPage();

    try {
      const predicates = [Prismic.Predicates.at('document.type', 'mcn_page')];

      const [{ results }] = await Promise.all([this.prismic.query(predicates), this.env.ready]);

      if (results.length === 0) {
        return this.router.replace('/');
      }
    } catch (e) {
      throw new Error(e);
    } finally {
      this.model.loading = false;
    }
  };

  private onEnterPage = async () => {
    try {
      const predicates = [
        Prismic.Predicates.at('document.type', 'mcn_page'),
        Prismic.Predicates.at('my.mcn_page.slug', this.model.slug),
      ];

      const [{ results }] = await Promise.all([this.prismic.query(predicates), this.env.ready]);

      if (results.length === 0) {
        return this.router.replace('/');
      }

      const { data } = results[0];

      this.ui.setTitle(`${data.page_title} ${this.content.seo.titleSuffix}`);
      this.ui.setDescription(data.document_meta_description);

      const sectionsSlice = data.body.find((slice: any) => slice.slice_type === 'sections');
      const tracksSlice = data.body.find((slice: any) => slice.slice_type === 'featured_tracks');

      this.model.userIdParam = data.user_id_param;
      this.model.logo = this.image.preloadContent(data.mcn_partner_logo.url);
      this.model.customHeaderImage = this.image.preloadContent(data.custom_header.url, { width: 1280 });
      this.model.ctaText = data.cta_text;
      this.model.headerText = data.header_text;
      this.model.medium = data.utm_medium;
      this.model.campaign = data.utm_campaign;
      this.model.source = data.utm_source;

      this.model.sections = !sectionsSlice
        ? []
        : sectionsSlice.items.map((item: any) => ({
            heading: item.body_title[0].text,
            content: item.body_content,
          }));

      this.model.featuredTitle = tracksSlice.primary.tracks_title[0].text;

      this.model.featured = !tracksSlice
        ? []
        : tracksSlice.items.map((item: any) => ({
            image: this.image.preloadContent(item.artist_image.url),
            name: item.artist_name,
            subtext: item.artist_subtext,
            description: item.artist_track_description,
          }));
    } catch (e) {
      throw new Error(e);
    } finally {
      this.model.loading = false;
    }
  };
}
