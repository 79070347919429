import { PrismicRichText } from '../utilities/types';
import { HeadingSlice } from '../organisms/slices/HeadingSlice';
import { isEmpty } from '../utilities/objects';
import * as React from 'react';
import { classes, cssRule, style } from 'typestyle';
import { mediaSmallTablet, mediaDesktop, mediaUpToSmallTablet, mediaUpToDesktop } from '../utilities/mediaQueries';
import { heading3, heading4, heading5, heading6 } from '../utilities/font';
import { renderPrismicRichTextArray } from '../utilities/props';

export type PrismicHeadlineStatementPayload = {
  primary: {
    heading?: PrismicRichText;
    subheading?: PrismicRichText;
    subheading2?: PrismicRichText;
    with_gradient: boolean;
  };
};
export type PrismicHeadlineStatementProps = {
  payload: PrismicHeadlineStatementPayload;
};
export const PrismicHeadlineStatement: React.SFC<PrismicHeadlineStatementProps> = ({
  payload: {
    primary: { heading, subheading, subheading2, with_gradient },
  },
}) => (
  <HeadingSlice withGradient={with_gradient}>
    <h2 className="heading">{renderPrismicRichTextArray(heading)}</h2>
    <p className="subheading">{renderPrismicRichTextArray(subheading)}</p>
    {subheading2 && <p className="subheading">{renderPrismicRichTextArray(subheading2)}</p>}
  </HeadingSlice>
);
