import * as React from 'react';
import { Component, deps, inject, observer } from '../lib/component';
import { PagePricing } from '../components/page-pricing';
import Helmet from 'react-helmet';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PricingContainerProps {}

@inject(deps)
@observer
export class PricingContainer extends Component<PricingContainerProps> {
  render() {
    const { model, controller } = this.props;
    const user = this.props.model.auth.user;
    const { type, title, message } = this.props.model.coupon;

    const coupon =
      !model.user.user && !!model.content.firstUseCoupon
        ? model.content.firstUseCoupon
        : type === 'first_use' && title && message
        ? model.coupon
        : null;

    return (
      <div>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <PagePricing
          analytics={this.props.controller.analytics}
          model={model.page.pricing}
          pricingModel={model.pricing}
          onChangeCurrency={controller.pricing.changeCurrency}
          onChangeQuery={controller.pricing.changeQuery}
          onSelectGridRateType={controller.page.pricing.selectRateType}
          onSelectCalculatorRateType={controller.pricing.selectRateType}
          onSearch={controller.pricing.search}
          onSubmit={controller.pricing.submit}
          onSignUp={user ? void 0 : this.handleSignUp}
          preload={controller.image.preloadContent}
          coupon={coupon}
        />
      </div>
    );
  }

  handleSignUp = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    this.props.controller.onboarding.goToSignUpPage();
  };

  UNSAFE_componentWillMount() {
    this.props.controller.pricing.reset();
  }
}
