import { classes, style } from 'typestyle';
import * as React from 'react';
import { SelectHTMLAttributes } from 'react';
import { AdditionalInputProps, dropdownInputStyles } from '../../utilities/inputs';
import { ChevronDown } from '../icons/ChevronDown';
import { percent, px } from 'csx';
import { colorLightBlueGrey } from '../../../../theme/color';
import { ArrowDown } from '../icons/ArrowDown';

const styles = {
  wrapper: style({
    position: 'relative',
    $nest: {
      '&::after': {
        content: '""',
        position: 'absolute',
        right: px(2),
        top: 'calc(50% - 8px)',
        height: px(16),
        width: percent(11),
        cursor: 'pointer',
        backgroundImage: `linear-gradient(to right, ${colorLightBlueGrey
          .fade(0)
          .toRGBA()}, ${colorLightBlueGrey.toHexString()} 25%)`,
      },
    },
  }),
  select: style(dropdownInputStyles, {
    width: percent(100),
    cursor: 'pointer',
  }),
  arrow: style({
    position: 'absolute',
    right: px(8),
    top: px(10),
    zIndex: 1,
  }),
};

export type DropdownProps = SelectHTMLAttributes<HTMLSelectElement> & AdditionalInputProps;

export const Dropdown: React.SFC<DropdownProps> = ({ children, className, isValid, ...props }) => (
  <span className={styles.wrapper}>
    <select className={classes(styles.select, isValid === false && 'invalid', className)} {...props}>
      {children}
    </select>
    <ArrowDown color="#2E2E3B" isSquare={true} className={styles.arrow} />
  </span>
);
