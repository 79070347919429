import { computed, observable } from 'mobx';
import { AggregatedTagSchema } from '../../../types/schema';
import SearchFilters, { SearchFiltersI18n } from '../../../components/search/filters';
import { formatBpm, formatDuration } from '../../../lib/helpers';
import { UIHeroGenre, UILocalTempo } from '../../../types/ui';
import { PaginationInput } from '../../../types';
import { CatalogueType, FindFiltersInput, FindSortMode, FindSortOrder } from '../../../types/graphql';
import findFilters from '../../../lib/findFilters';
import objectDifference from '../../../lib/objectDifference';

export type SearchSort =
  | 'relevance'
  | 'popularSpotify'
  | 'popularYouTube'
  | 'popularLickd'
  | 'recentlyAdded'
  | 'artistName';

export type SearchState = {
  query: string;
  pagination: PaginationInput;
  filters: FindFiltersInput;
  sortMode: FindSortMode;
  sortOrder: FindSortOrder;
};

export type SearchVideoPlatforms = Pick<
  FindFiltersInput,
  | 'dailymotion'
  | 'facebook'
  | 'instagram'
  | 'linkedin'
  | 'snapchat'
  | 'twitch'
  | 'twitter'
  | 'vimeo'
  | 'youtube'
  | 'TikTok'
  | 'podcasting'
>;

export const DEFAULT_STATE: SearchState = {
  query: '',
  pagination: {
    size: 25,
    from: 0,
  },
  sortMode: 'POPULAR_SPOTIFY',
  sortOrder: 'DESC',
  filters: findFilters({
    matchArtistFallback: true,
  }),
};

export const DEFAULT_VIDEO_PLATFORMS: SearchVideoPlatforms = {
  dailymotion: null,
  facebook: null,
  instagram: null,
  linkedin: null,
  snapchat: null,
  twitch: null,
  twitter: null,
  vimeo: null,
  youtube: null,
  TikTok: null,
  podcasting: null,
};

export const DEFAULT_VIDEO_PLATFORM_LABELS: { [key in keyof SearchVideoPlatforms]: string } = {
  dailymotion: 'Dailymotion',
  facebook: 'Facebook',
  instagram: 'Instagram',
  linkedin: 'LinkedIn',
  snapchat: 'Snapchat',
  twitch: 'Twitch',
  twitter: 'Twitter',
  vimeo: 'Vimeo',
  youtube: 'YouTube',
  TikTok: 'TikTok',
  podcasting: 'Podcast',
};

export const DEFAULT_VIDEO_PLATFORM_KEYS: Array<keyof SearchVideoPlatforms> = Object.keys(
  DEFAULT_VIDEO_PLATFORMS
) as Array<keyof SearchVideoPlatforms>;

export class SearchPageModel {
  @observable loading = true;

  @observable defaultTitle = '';

  @computed get durationRange() {
    return [0, 1040000];
  }
  @computed get bpmRange() {
    return [60, 300];
  }

  @computed get defaultState(): SearchState {
    return DEFAULT_STATE;
  }

  @computed get defaultVideoPlatforms(): SearchVideoPlatforms {
    return DEFAULT_VIDEO_PLATFORMS;
  }

  @computed get defaultVideoPlatformKeys(): Array<keyof SearchVideoPlatforms> {
    return Object.keys(DEFAULT_VIDEO_PLATFORMS) as Array<keyof SearchVideoPlatforms>;
  }

  @computed get stateDifference(): SearchState {
    const diff = objectDifference(this.state, this.defaultState);
    return diff;
  }

  @observable userHasInteractedWithSearch = false;

  @observable userState: SearchState = { ...DEFAULT_STATE };

  @observable showFilters = false;

  @observable showSort = false;

  @computed get state(): SearchState {
    return {
      ...this.defaultState,
      ...this.userState,
    };
  }

  @observable apiState: SearchState = { ...DEFAULT_STATE };

  @observable genres: Array<UIHeroGenre> = [];
  @observable themes: Array<AggregatedTagSchema> = [];
  @observable moods: Array<AggregatedTagSchema> = [];
  @observable tempos: Array<UILocalTempo> = [
    {
      range: [0, 80],
      label: 'V. Slow',
    },
    {
      range: [80, 110],
      label: 'Slow',
    },
    {
      range: [110, 130],
      label: 'Medium',
    },
    {
      range: [130, 150],
      label: 'Fast',
    },
    {
      range: [150, 300],
      label: 'V. Fast',
    },
  ];

  @computed get filterI18n(): SearchFiltersI18n {
    return {
      sections: [
        { hide: true, category: 'Artist', label: this.state.filters.artist || null },
        { hide: true, category: 'Label', label: this.state.filters.rightsholder || null },

        {
          category: 'Catalogue type',
          label: getCatalogueTypeText(this.state, this.defaultState),
        },
        { category: 'Song properties', label: getSongPropertiesText(this.state) },
        { category: 'Order by', label: getOrderByText(this.state, this.defaultState) },
        { category: 'Mood', label: getMoodText(this.state, this.moods) },
        { category: 'Genre', label: getGenreText(this.state, this.genres) },
        { category: 'Length', label: getDurationText(this.state, this.defaultState) },
        { category: 'Tempo', label: getTempoText(this.state, this.defaultState, this.tempos) },
        { category: 'Target platforms', label: getTargetPlatformsText(this.state) },
      ],
      durationMin: 'Minimum',
      durationMax: 'Maximum',
      emptyFilter: 'any',
    };
  }

  @computed get filterCount(): number {
    return this.filterI18n.sections.map((s) => s.label).filter(Boolean).length;
  }
}

const getCatalogueTypeText = (state: SearchState, defaults: SearchState) => {
  if (state.filters.catalogueType === defaults.filters.catalogueType) return null;
  if (state.filters.catalogueType === 'PRODUCTION') return 'Included';
  return 'Premium';
};

const getOrderByText = (state: SearchState, defaults: SearchState) => {
  if (state.sortOrder === defaults.sortOrder) return null;

  return SearchFilters.SortOptions[state.sortOrder] || null;
};

const getGenreText = ({ filters }: SearchState, genres: UIHeroGenre[]) => {
  if (filters.genre) {
    const genre = genres.find((g) => g.slug === filters.genre);

    if (genre) {
      return genre.label;
    }
  }

  return null;
};

const getThemeText = ({ filters }: SearchState, themes: AggregatedTagSchema[]) => {
  if (filters.theme) {
    const theme = themes.find((t) => t.slug === filters.theme);

    if (theme) {
      return theme.tag;
    }
  }

  return null;
};

const getMoodText = ({ filters }: SearchState, themes: AggregatedTagSchema[]) => {
  if (filters.mood) {
    const theme = themes.find((t) => t.slug === filters.mood);

    if (theme) {
      return theme.tag;
    }
  }

  return null;
};

const getDurationText = ({ filters }: SearchState, defaults: SearchState) => {
  const hasDurationMin = filters.durationMin !== defaults.filters.durationMin;
  const hasDurationMax = filters.durationMax !== defaults.filters.durationMax;

  switch (true) {
    case hasDurationMin && hasDurationMax:
      return `${formatDuration(filters.durationMin)} to ${formatDuration(filters.durationMax)}`;

    case hasDurationMax:
      return `up to ${formatDuration(filters.durationMax)}`;

    case hasDurationMin:
      return `over ${formatDuration(filters.durationMin)}`;

    default:
      return null;
  }
};

const getTempoText = ({ filters }: SearchState, defaults: SearchState, tempos: UILocalTempo[]) => {
  const hasBpmMin = filters.bpmMin !== defaults.filters.bpmMin;
  const hasBpmMax = filters.bpmMax !== defaults.filters.bpmMax;
  const format = formatBpm;

  if (hasBpmMin && hasBpmMax && filters.bpmMin && filters.bpmMax) {
    return `${format(filters.bpmMin)} to ${format(filters.bpmMax)}`;
  }

  if (hasBpmMax && filters.bpmMax) {
    return `up to ${format(filters.bpmMax)}`;
  }

  if (hasBpmMin && filters.bpmMin) {
    return `over ${format(filters.bpmMin)}`;
  }

  return null;
};

const getUsageText = ({ filters }: SearchState) => {
  const parts = [];

  if (filters.brandSponsored === true) parts.push('Brand Sponsored');
  if (filters.brandSponsored === false) parts.push('Not Brand Sponsored');

  return parts.length ? parts.join(', ') : null;
};

const getSongPropertiesText = ({ filters }: SearchState) => {
  const parts = [];

  if (filters.instrumental === true) parts.push('Instrumental');
  if (filters.instrumental === false) parts.push('Not Instrumental');

  if (filters.explicit === true) parts.push('Explicit');
  if (filters.explicit === false) parts.push('Not Explicit');

  return parts.length ? parts.join(', ') : null;
};

const getTargetPlatformsText = ({ filters }: SearchState) => {
  return Object.keys(DEFAULT_VIDEO_PLATFORM_LABELS)
    .reduce((parts, key) => (filters[key] ? [...parts, DEFAULT_VIDEO_PLATFORM_LABELS[key]] : parts), [])
    .join(', ');
};
