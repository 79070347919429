import * as React from 'react';
import { Component, deps, inject, observer } from '../lib/component';
import { FormattedMessage } from 'react-intl';
import { AccountChannelVideos } from '../components/account/channel-videos';
import {AccountLayout} from "../components/project-happy/templates/AccountLayout";

export interface AccountChannelPageProps {

}

@inject(deps) @observer
export class AccountChannelPage extends Component<AccountChannelPageProps> {
  render() {
    const { isMobile } = this.props.model.ui;
    const { accountChannel } = this.props.model.page;

    return (
      <AccountLayout>
        {accountChannel.loading && (
          <h2>Loading...</h2>
        )}
        {!accountChannel.loading && (
          <FormattedMessage tagName="h2" id="account.channel.title" values={{ name: accountChannel.channel.name }}/>
        )}
        <AccountChannelVideos
          isMobile={isMobile}
          videos={accountChannel.videos}
          loadingMore={accountChannel.loading}
          pagination={accountChannel.videosPagination}
          loadPage={this.props.controller.page.accountChannel.loadPage}
        />
      </AccountLayout>
    )
  }
}
