import {ArtistSchema, TrackSchema} from '../types/schema';

export default function extractUniqueArtists(tracks: TrackSchema[]): ArtistSchema[] {
  const artistMap: {[identity: string]: ArtistSchema} = tracks.reduce((artists, track) => {
    const artist = track.artists[0];
    if (!artist) return artists;

    return { ...artists, [artist.identity]: artist };
  }, {});

  return Object.keys(artistMap).map(key => artistMap[key])
}
