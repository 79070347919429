import Prismic from 'prismic-javascript';
import { action, observable } from 'mobx';
import { RedirectFunction, RouterState } from 'react-router';
import { PrismicSlices } from '../project-happy/utilities/types';
import { PrismicTrackPlaylistPayload } from '../project-happy/prismic-slices/PrismicTrackPlaylist';
import { APIController } from '../../modules/api/controller';
import { BugsnagController } from '../../modules/bugsnag/controller';
import { PrismicController } from '../../modules/prismic/controller';
import { EnvModel } from '../../modules/env/model';
import { UIController } from '../../modules/ui/controller';
import { LanderModel } from './model';
import { SIGN_UP_ROUTE } from '../../constants';

export class LanderController {
  constructor(
    private model: LanderModel,
    private prismic: PrismicController,
    private env: EnvModel,
    private ui: UIController,
    private api: APIController,
    private bugsnag: BugsnagController
  ) {
    this.model.playlists = observable.map();
  }

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    let documentType = 'acquisition';
    if (nextState.location.pathname.startsWith('/affiliate')) {
      documentType = 'affiliate';
    } else if (nextState.location.pathname.startsWith('/partner')) {
      documentType = 'partner';
    } else if (nextState.location.pathname.startsWith('/outreach')) {
      documentType = 'outreach';
    } else if (nextState.location.pathname.startsWith('/lickd-reviews')) {
      documentType = 'testimonials';
    }
    const prismicDocumentType = `${documentType}_lander`;
    const documentSlug = nextState.params['slug'] || nextState.location.pathname.replace(/\//g, '');
    const predicates = [
      Prismic.Predicates.at('document.type', prismicDocumentType),
      Prismic.Predicates.at(`my.${prismicDocumentType}.uid`, documentSlug),
    ];

    const [{ results }] = await Promise.all([this.prismic.query(predicates, { resolveImages: true }), this.env.ready]);

    if (results.length === 0) {
      return replace({ pathname: '/' });
    }

    const { data } = results[0];

    const { body, ...metadata } = data;

    // As of WEB-3801, affiliate landing pages are all redirected to the external marketing landing page
    if (documentType === 'affiliate') {
      const { utm_source, utm_medium, utm_campaign } = metadata;
      const hasLandingPage = [
        'umg-germany', // WEB-3562
        'involved-publishing', // WEB-3770
      ];
      if (!hasLandingPage.includes(documentSlug)) {
        return replace({
          pathname: `https://l.lickd.co/get-all-the-music/?utm_campaign=${utm_campaign}&utm_medium=${utm_medium}&utm_source=${utm_source}`,
        });
      }
    }

    this.ui.setSEO(nextState.routes[0].path, metadata);

    await Promise.all(
      body
        .filter((slice) => slice.slice_type === PrismicSlices.TRACK_PLAYLIST)
        .map(async (slice: PrismicTrackPlaylistPayload) => {
          const { playlist_slug } = slice.primary;
          try {
            const response = await this.api.browse.getPlaylistTracks(playlist_slug, 1, 6);
            this.model.playlists.set(playlist_slug, response.data);
          } catch {
            this.bugsnag.notifyException(
              new Error(
                `Error loading "${playlist_slug}" playlist on ${documentType} lander with ` +
                  `document slug "${documentSlug}" at ${nextState.location.pathname}`
              )
            );
          }
        })
    );

    this.model.slices = body;
    this.model.metadata = metadata;
  };
}
