import * as React from 'react';
import {classes, style} from 'typestyle';

export default class MainWrapper extends React.Component<{className?: string, style?: any}, any> {
  render() {
    return (
      <div className={classes(MainWrapper.styles.container, this.props.className)} style={this.props.style}>
        {this.props.children}
      </div>
    )
  }

  static styles = {
    container: style({
      flex: 1,
      $nest: {
        '@media all and (-ms-high-contrast:none)': {
          flex: '1 0 auto !important'
        }
      }
    })
  }
}