import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const LoadingDots: React.SFC<SVGIconProps> = ({ size = 21, color = 'currentColor', ...props }) => (
  <BaseSvg width={size} height={size * (12 / 84)} viewBox="0 0 84 12" {...props}>
    <rect x="0" width="12" height="12" fill={color}>
      <animate attributeName="opacity" from="0" to="1" dur="0.75s" begin="0" repeatCount="indefinite" />
    </rect>
    <rect x="21" width="12" height="12" fill={color}>
      <animate attributeName="opacity" from="0" to="1" dur="0.75s" begin="0.1875s" repeatCount="indefinite" />
    </rect>
    <rect x="42" width="12" height="12" fill={color}>
      <animate attributeName="opacity" from="0" to="1" dur="0.75s" begin="0.375s" repeatCount="indefinite" />
    </rect>
    <rect x="63" width="12" height="12" fill={color}>
      <animate attributeName="opacity" from="0" to="1" dur="0.75s" begin="0.5625s" repeatCount="indefinite" />
    </rect>
  </BaseSvg>
);
