import * as React from 'react';
import { center, horizontal, vertical } from 'csstips';
import { classes, style } from 'typestyle';
import { em, important, px } from 'csx';
import { mediaMobileOnly } from '../theme/media';
import { transitionQuickEase } from '../theme/transition';
import { colorBackground } from '../theme/color';

export interface PreferenceSelectorProps {
  title: string;
  subtitle: string;
  saving: boolean;
  disabled?: boolean;
}

export class PreferenceSelector extends React.Component<PreferenceSelectorProps, any> {
  render() {
    return (
      <div className={classes(PreferenceSelector.styles.container, this.props.disabled && PreferenceSelector.styles.disabled)}>
        <div>
          <div>{this.props.title}</div>
          <div>{this.props.subtitle}</div>
        </div>
        <form onSubmit={this.handleSubmit}>
          {this.props.children}
        </form>
      </div>
    )
  }

  handleSubmit = (event: React.FormEvent<any>) => event.preventDefault();

  static styles = {
    container: style(
      {
        ...horizontal,
        ...center,
        transition: `color ${transitionQuickEase}`,
        $nest: {
          '&> div:first-child': {
            flex: 1,
            lineHeight: em(1.4)
          },
          '&> div:first-child > div:first-child': {
            fontWeight: 'bold'
          },
          '&> form': {
            ...horizontal,
          },
          '&> form label': {
            paddingLeft: px(18),
            paddingBottom: px(10)
          }
        }
      },
      mediaMobileOnly({
        ...vertical,
        ...center
      })
    ),
    disabled: style({
      color: important(colorBackground.toString())
    })
  }
}