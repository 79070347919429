import { Dict } from 'mixpanel-browser';

const unsentEvents = [];
const unsentIdentifyCalls = [];

export class Mixpanel {
  identify = (userId: string, retryCount = 0) => {
    try {
      if ((window as any).mixpanel && typeof (window as any).mixpanel.identify === 'function') {
        (window as any).mixpanel.identify(userId);
        // Clear the unsent identify calls if any
        unsentIdentifyCalls.length = 0;
      } else if (retryCount < 3) {
        // Retry after some time if Mixpanel is not loaded
        setTimeout(() => this.identify(userId, retryCount + 1), 500);
      } else {
        // If we can't send the identify after retries, store it
        unsentIdentifyCalls.push(userId);
      }
    } catch (error) {
      console.error('Mixpanel identify error:', error);
    }
  };

  track = (event: string, props?: Dict, callback?: () => void) => {
    try {
      if (unsentEvents.length > 0) {
        unsentEvents.forEach((eventArguments) => (window as any).mixpanel.track(...eventArguments));
        unsentEvents.length = 0;
      }
      (window as any).mixpanel.track(event, props, callback);
    } catch {
      // If we can't send the event, store it in the hopes that we can send it later
      unsentEvents.push([event, { ...props, originalTime: Date.now() / 1000 }, callback]);
    }
  };
}

export default new Mixpanel();
