import * as React from 'react';
import { style } from 'typestyle';
import { Component, ComponentProps, deps, inject, observer } from '../../../../lib/component';
import { ButtonProminence } from '../../atoms/button/buttonStyles';
import { center, centerCenter, flexRoot, vertical } from 'csstips';
import { percent, px, viewHeight } from 'csx';
import { ActionButton } from '../../atoms/button/ActionButton';
import { colorBlack, colorBlueGrey, colorGunmetal, colorLickdPink } from '../../../../theme/color';
import { fontstackDefault } from '../../config';
import { ONBOARD_ROUTE, SUPPORT_HUB } from '../../../../constants';
import { EnvModel } from '../../../../modules/env/model';
import { Button } from '../../atoms/button/Button';
import { RegistrationSteps } from '../../../../modules/page/registration/model';
import { isEmpty } from '../../utilities/objects';
import { Loading } from '../../../loading/loading';
import { SemanticInformationType } from '../../utilities/types';
import siteEvents, { SITE_EVENTS } from '../../utilities/siteEvents';

const { SUCCESS } = SemanticInformationType;

const styles = {
  wrapper: style(flexRoot, vertical, center, {
    maxWidth: px(328),
    margin: '0 auto',
    paddingLeft: px(8),
    paddingRight: px(8),
  }),
  heading: style({
    fontSize: px(32),
    marginBottom: 0,
  }),
  text: style({
    fontWeight: 300,
    maxWidth: px(298),
    textAlign: 'center',
    margin: '16px 0px',
    $nest: {
      '& b': {
        display: 'block',
        fontWeight: 'bold',
      },
    },
  }),
  planWrapper: style({
    width: percent(100),
    maxWidth: px(340),
    paddingRight: px(8),
    paddingLeft: px(8),
    $nest: {
      '& .payment-plan': {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: px(8),
      },
    },
  }),
  banner: style({
    textTransform: 'uppercase',
    backgroundSize: 'contain',
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    $nest: {
      '& h2': {
        fontSize: '32px',
      },
    },
  }),
  chartBanner: style({
    backgroundColor: colorBlack.toHexString(),
    backgroundSize: '320px, cover',
  }),
  stockChartBanner: style({
    backgroundColor: colorLickdPink.toString(),
  }),
  planButton: style({
    display: 'flex',
    margin: '16px 26px',
  }),
  collapsible: style({
    width: 'calc(100% - 32px)',
    margin: '22px 16px 16px',
  }),
  info: style({
    textAlign: 'center',
    fontSize: px(8),
    $nest: {
      '& h4': {
        fontFamily: fontstackDefault,
        fontSize: px(12),
        fontWeight: 'normal',
        marginTop: px(4),
        marginBottom: px(8),
        textDecoration: 'underline',
      },
      '& p': {
        marginTop: 0,
        marginBottom: px(8),
        $nest: {
          '&:last-child': {
            marginBottom: 0,
          },
        },
      },
    },
  }),
  actions: style(flexRoot, vertical, centerCenter, { width: percent(100) }),
  subscribeCta: style({
    margin: '16px 0',
  }),
  skipCta: style({
    textDecoration: 'underline',
    fontSize: px(12),
  }),
  pending: style({ marginTop: viewHeight(25) }),
  container: style({
    position: 'relative',
    width: '320px',
    $nest: {
      '& p': {
        textAlign: 'center',
      },
      '& p:first-child': {
        fontSize: '12px',
        fontWeight: 600,
        color: colorGunmetal.toString(),
      },
    },
  }),
  infoText: style({
    fontSize: '12px',
    color: colorGunmetal.toString(),
  }),
  blockWrapper: style({
    display: 'inline-flex',
    justifyContent: 'space-between',
    width: '320px',
  }),
  ctaText: style({ marginTop: '0px', marginBottom: '32px', color: colorBlueGrey.toHexString() }),
};

const pendingMessages = [
  'Your subscription is pending',
  "We're still processing your subscription",
  'Your subscription is taking longer than usual to process',
  <p key={4}>
    Your subscription shouldn&apos;t take this long to process. Please <a href={SUPPORT_HUB}>submit a support ticket</a>{' '}
    for further assistance.
  </p>,
];

type CompleteState = {
  pendingMessage: string;
  isPending: boolean;
  subscriptionSuccess: boolean;
  subscriptionChannel: string;
};

@inject(deps)
@observer
export class Complete extends Component<ComponentProps, CompleteState> {
  pendingMessages = pendingMessages.values();
  messageUID: NodeJS.Timer = null;
  pollUID: NodeJS.Timer = null;

  state = {
    pendingMessage: null,
    isPending: false,
    subscriptionSuccess: null,
    subscriptionChannel: null,
  };

  constructor(props) {
    super(props);
    this.iteratePendingMessages = this.iteratePendingMessages.bind(this);
  }

  parseQueryParams() {
    const { search } = this.props.model.router.location;
    const searchParams = new URLSearchParams(search);
    const newState: Partial<CompleteState> = {
      subscriptionSuccess: null,
      subscriptionChannel: null,
    };
    if (searchParams.has('success')) {
      newState.subscriptionSuccess = searchParams.get('success') === 'true';
    }
    if (searchParams.has('channel')) {
      newState.subscriptionChannel = searchParams.get('channel');
    }
    if (
      newState.subscriptionSuccess !== this.state.subscriptionSuccess ||
      newState.subscriptionChannel !== this.state.subscriptionChannel
    ) {
      this.setState(newState as CompleteState);
    }
    return newState;
  }

  componentDidMount() {
    if (EnvModel.isServer) return;
    this.iteratePendingMessages();
    const { subscriptionSuccess, subscriptionChannel } = this.parseQueryParams();
    if (subscriptionSuccess) {
      if (typeof subscriptionChannel === 'string') {
        this.pollSubscribingChannel(subscriptionChannel);
        this.setState({ isPending: true });
      }
    }
    siteEvents.emit(SITE_EVENTS.REGISTRATION_SUCCESS);
  }

  componentDidUpdate() {
    this.parseQueryParams();
  }

  componentWillUnmount(): void {
    clearInterval(this.messageUID);
    clearInterval(this.pollUID);
  }

  pollSubscribingChannel(channelId: string): void {
    clearInterval(this.pollUID);
    const handler = async () => {
      const { user: userModel } = this.props.model;
      const { user } = this.props.controller;
      await Promise.all([
        user.getYoutubeChannel(userModel.user.default_channel),
        user.getYoutubeChannels(),
        user.fetchCredits(),
      ]);
      const channel = this.props.model.user.channels.find((channel) => channel.id === channelId);
      if (isEmpty(channel) || channel.is_subscribed === false) {
        this.setState({
          isPending: true,
        });
        this.pollUID = setTimeout(handler, 2500);
        return;
      }
      siteEvents.emit(SITE_EVENTS.SUBSCRIPTION_SUCCESS, { id: channelId });
      this.setState({
        isPending: false,
      });
    };
    handler();
  }

  iteratePendingMessages(): void {
    const message = this.pendingMessages.next();
    if (message.done) return;
    this.setState({
      pendingMessage: message.value,
    } as CompleteState);
    this.messageUID = setTimeout(this.iteratePendingMessages, 10000);
  }

  render() {
    const {
      model: {
        user,
        basket: { tracks },
        user: { loadingChannels },
      },
    } = this.props;
    const { pendingMessage, isPending } = this.state;
    const hasTracks = tracks.length > 0;

    // Read the router location so the component will re-render on URL change
    this.props.model.router.location;

    if (this.state.subscriptionSuccess === false) {
      return (
        <div className={styles.wrapper}>
          <p className={styles.text}>Sorry, there&apos;s been an issue subscribing to Lickd Creator Subscription.</p>
          <p>
            If the problem persists, please <a href={SUPPORT_HUB}>submit a support ticket</a> for assistance.
          </p>
          <Button className={styles.subscribeCta} prominence={ButtonProminence.PRIMARY} href={RegistrationSteps.PLANS}>
            Return to plans
          </Button>
        </div>
      );
    }

    if (isPending) {
      return (
        <div className={styles.wrapper}>
          <Loading className={styles.pending} text={pendingMessage} />
        </div>
      );
    }

    if (isEmpty(user) || loadingChannels || user.loadingAccounts) {
      return (
        <div className={styles.wrapper}>
          <Loading className={styles.pending} text="Loading channel information..." />
        </div>
      );
    }

    return (
      <div className={styles.wrapper}>
        <h3 className={styles.heading}>You&apos;re all set!</h3>
        <p className={styles.text}>Check out the music and find your perfect soundtrack.</p>

        {hasTracks && (
          <div className={styles.actions}>
            <ActionButton className={styles.subscribeCta} href="/checkout">
              Checkout
            </ActionButton>
          </div>
        )}
        {!hasTracks &&
          (user.channel ? (
            <div className={styles.actions}>
              <ActionButton className={styles.subscribeCta} href={ONBOARD_ROUTE}>
                Personalize Your Music
              </ActionButton>
              <p className={styles.ctaText}>Results in less than 2 minutes!</p>
              <Button prominence={ButtonProminence.SECONDARY} href={'/'}>
                Skip for now
              </Button>
            </div>
          ) : (
            <ActionButton className={styles.subscribeCta} href={'/'} data-test-start-discovering-music-button>
              Start discovering music
            </ActionButton>
          ))}
      </div>
    );
  }
}
