import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const AddCircle: React.SFC<SVGIconProps> = ({ size = 20, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 20 20" {...props}>
    <path
      fill={color}
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2Z"
      id="a"
      transform="translate(-2 -2)"
      fillRule="evenodd"
    />
  </BaseSvg>
);
