import { observable } from 'mobx';
import {LicenceSchema} from '../../../types/schema';
import {PaginationMeta} from '../../../types';

export class AccountLicencesPageModel {
  @observable
  loadingMore: boolean = false;

  @observable
  licences: Array<LicenceSchema> = [];

  @observable
  pagination: PaginationMeta;

  @observable
  page: number = 1;

  @observable
  i18n: any = {};
}
