import * as React from 'react';
import { classes, style } from 'typestyle';
import { percent, px } from 'csx';
import { defaultHeadingStyles, heading3, headingTitle } from '../../utilities/font';
import { constantPageHeaderHeight } from '../../../../theme/constant';
import { fontstackDefault } from '../../config';
import { BackgroundProps, resolveBackgroundProps } from '../../utilities/props';
import { PrismicSliceCSSClasses } from '../../utilities/types';
import { centerCenter, flexRoot, vertical } from 'csstips';
import { mediaDesktop, mediaUpToDesktop } from '../../utilities/mediaQueries';

const styles = {
  masthead: style(
    flexRoot,
    vertical,
    centerCenter,
    {
      width: percent(100),
      paddingLeft: px(16),
      paddingRight: px(16),
      textAlign: 'center',
      position: 'relative',
      color: 'white',
      backgroundPosition: 'center top',
      $nest: {
        '#root & h1': defaultHeadingStyles,
        '& h1:not(:last-child)': {
          marginBottom: 0,
        },
        '& h2': {
          fontFamily: fontstackDefault,
          fontWeight: 400,
          fontSize: px(16),
          letterSpacing: 'normal',
        },
      },
    },
    mediaUpToDesktop({
      minHeight: px(104),
      $nest: {
        '& h1': headingTitle,
      },
    }),
    mediaDesktop({
      minHeight: px(176),
      $nest: {
        '& h1': heading3,
      },
    })
  ),
};

export type BrowseMastheadProps = BackgroundProps & {
  heading: string;
};

export const BrowseMasthead: React.SFC<BrowseMastheadProps> = ({ children, heading, ...backgroundProps }) => {
  const backgroundStyles = resolveBackgroundProps(backgroundProps);
  return (
    <div className={classes(PrismicSliceCSSClasses.MASTHEAD, styles.masthead)} style={backgroundStyles}>
      <h1>{heading}</h1>
      {children}
    </div>
  );
};
