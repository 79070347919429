import { PrismicSimpleBackgroundProps } from '../utilities/types';
import { LandingMasthead } from '../molecules/mastheads/LandingMasthead';
import { isEmpty } from '../utilities/objects';
import { classes, style } from 'typestyle';
import { heading4, headingStyles } from '../utilities/font';
import { mediaLargeMobile, mediaUpToLargeMobile } from '../utilities/mediaQueries';
import { viewWidth } from 'csx';
import * as React from 'react';

export type PrismicMastheadPayload = PrismicSimpleBackgroundProps & {
  primary: {
    highlight_text?: string;
    heading?: string;
    subtitle?: string;
  };
};
export type PrismicMastheadProps = {
  payload: PrismicMastheadPayload;
};
export const PrismicMasthead: React.SFC<PrismicMastheadProps> = ({
  payload: {
    primary: { highlight_text, heading, subtitle, background_image, background_colour },
  },
}) => (
  <LandingMasthead
    src={!isEmpty(background_image) && background_image.url}
    colour={background_colour}
    headingPrefix={highlight_text}
    headingSuffix={heading}
  >
    {subtitle && (
      <p
        className={classes(
          style(headingStyles, mediaUpToLargeMobile({ fontSize: viewWidth(5) }), mediaLargeMobile(heading4))
        )}
      >
        {subtitle}
      </p>
    )}
  </LandingMasthead>
);
