import { TrackSchemaWithUsage } from '../../../modules/page/checkout/model';
import React from 'react';
import { classes, style } from 'typestyle';
import { mediaDesktop, mediaTablet, mediaTabletOnly } from '../../../theme/media';
import { important, percent, px } from 'csx';
import { colorCrimson, colorDarkGreen, colorStatusWarning, rebrand } from '../../../theme/color';
import { isObservableArray } from 'mobx';
import { ErrorOutlineCircle } from '../../project-happy/atoms/icons/ErrorCircleOutline';
import { inject, observer } from 'mobx-react';
import { Component, deps } from '../../../lib/component';
import { center, flexRoot, startJustified } from 'csstips';
import { Checkmark } from '../../project-happy/atoms/icons/Checkmark';
import { CloseCross } from '../../project-happy/atoms/icons/CloseCross';
import { CheckmarkCircle } from '../../project-happy/atoms/icons/CheckmarkCircle';
import { toUpperFirst } from '../../project-happy/utilities/string';

export interface TrackInfoProps {
  track: TrackSchemaWithUsage & { p_line?: string };
}

const styles = {
  list: style(
    { paddingLeft: '16px', listStyleType: 'none' },
    mediaTablet({
      display: 'flex',
      flexWrap: 'wrap',
    })
  ),
  listItem: style(
    {
      listStyle: 'none',
      marginBottom: '7px',
      alignItems: 'center',
    },
    mediaTabletOnly({
      display: 'inline-block',
      minWidth: percent(20),
    }),
    mediaDesktop({
      minWidth: percent(30),
    })
  ),
  listItemLayout: style({
    display: 'flex',
    $nest: {
      '& > svg': {
        flexShrink: 0,
        top: '-1px',
      },
    },
  }),
  listText: style({ marginLeft: px(4), marginRight: px(10) }),
  singleItemWrapper: style({ marginLeft: px(40) }),
  sectionContainer: style({
    borderBottom: '1px solid #E0E4E9',
  }),
  icon: style({
    verticalAlign: 'middle',
  }),
  track: style(flexRoot, startJustified, center, {
    backgroundColor: rebrand.dark1.toString(),
    color: 'white',
    $nest: {
      ':first-child ~ &': {
        borderTop: '1px solid black',
      },
      '& > :first-child ~ *': {
        marginLeft: px(8),
      },
      '& p': {
        margin: 0,
        fontSize: px(12),
        padding: important('0px'),
      },
      '& .track': {
        fontWeight: 600,
      },
      '& .p-line': {
        fontSize: px(10),
        color: rebrand.light2.toString(),
      },
    },
  }),
  trackCover: style({
    height: px(65),
    flexShrink: 0,
  }),
};

const PLATFORM_NAMES = {
  youtube: 'YouTube',
  dailymotion: 'DailyMotion',
  linkedin: 'LinkedIn',
};

const formatPlatform = (platformId: string) => PLATFORM_NAMES[platformId] || toUpperFirst(platformId);

@observer
@inject(deps)
export default class TrackInfo extends Component<TrackInfoProps> {
  render() {
    const {
      track,
      model: {
        image: { cover },
      },
    } = this.props;

    const { video_platforms, rightsholders, restrictions, vouch_status, images } = track;

    const isStock = track.is_stock_music === true;

    const listedRightsholders = Array.isArray(rightsholders)
      ? rightsholders.filter((rightsholder) => rightsholder.is_listed)
      : [];

    const artists = track.artists
      .map(function (artist) {
        return artist.name;
      })
      .join(', ');

    return (
      <div>
        <div className={styles.track}>
          <img className={styles.trackCover} src={cover(images.identity)} width={65} height={65} />
          <div>
            <p className="track">{track.title}</p>
            <p>{artists}</p>
            <p className="p-line">{track.p_line}</p>
          </div>
        </div>
        <div className={styles.sectionContainer}>
          <h3>Allowed platforms</h3>
          <ul className={styles.list}>
            {video_platforms &&
              Object.entries(video_platforms).map(([name, allowed], index) => (
                <li key={index} className={classes(styles.listItem, styles.listItemLayout)}>
                  {allowed ? (
                    <Checkmark className={styles.icon} color={colorDarkGreen.toHexString()} />
                  ) : (
                    <CloseCross className={styles.icon} color={colorCrimson.toHexString()} size={16} />
                  )}
                  <span className={styles.listText}>{formatPlatform(name)}</span>
                </li>
              ))}
          </ul>
        </div>
        {isObservableArray(restrictions) && (
          <span>
            <div className={styles.sectionContainer}>
              <h3>Restrictions</h3>
              <ul className={styles.list}>
                {restrictions &&
                  restrictions.map((restriction, index) => (
                    <li key={index} className={classes(styles.listItem, styles.listItemLayout)}>
                      <CloseCross className={styles.icon} color={colorCrimson.toHexString()} size={16} />
                      <span className={styles.listText}>{toUpperFirst(restriction)}</span>
                    </li>
                  ))}
              </ul>
            </div>
          </span>
        )}
        <div className={styles.sectionContainer}>
          <h3>Usage</h3>
          <ul className={styles.list}>
            <li className={classes(styles.listItem, styles.listItemLayout)}>
              <CloseCross className={styles.icon} color={colorCrimson.toHexString()} size={16} />
              <span className={styles.listText}>No remixing</span>
            </li>
            <li className={classes(styles.listItem, styles.listItemLayout)}>
              {isStock ? (
                <>
                  <CheckmarkCircle className={styles.icon} size={13} />
                  <span className={styles.listText}>
                    This license is valid for use in an unlimited number of videos
                  </span>
                </>
              ) : (
                <>
                  <ErrorOutlineCircle className={styles.icon} color={colorStatusWarning.toHexString()} size={13} />
                  <span className={styles.listText}>This license is valid for use in 1 video</span>
                </>
              )}
            </li>
          </ul>
        </div>
        {listedRightsholders.length > 0 && (
          <span>
            <h3>Rightsholders</h3>
            <ul className={styles.list}>
              {listedRightsholders.map((rightsholder, index) => (
                <li key={index} className={classes(styles.listItem, styles.listItemLayout)}>
                  <Checkmark className={styles.icon} color={colorDarkGreen.toHexString()} />
                  <span className={styles.listText}>{rightsholder.name}</span>
                </li>
              ))}
            </ul>
          </span>
        )}
      </div>
    );
  }
}
