import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { px } from 'csx';
import { colorContrast, colorLightBlueGrey } from '../../../theme/color';
import { mediaLargeTablet, mediaUpToLargeTablet } from '../utilities/mediaQueries';
import { AnalyticsContextProvider } from '../utilities/AnalyticsContext';

const styles = {
  wrapper: style(
    {
      backgroundColor: colorLightBlueGrey.toHexString(),
      $nest: {
        '.slice-group + &': {
          paddingTop: 0,
        },
      },
    },
    mediaUpToLargeTablet({
      padding: '16px 0',
    }),
    mediaLargeTablet({
      padding: px(32),
    })
  ),
  inner: style(
    {
      backgroundColor: 'white',
      overflow: 'hidden',
    },
    mediaLargeTablet({
      borderRadius: px(32),
    })
  ),
};

type SliceGroupProps = {
  analyticsIdentifier?: string;
};

export const SliceGroup: React.SFC<SliceGroupProps> = ({ children, analyticsIdentifier }) => (
  <AnalyticsContextProvider context={{ prismicSection: analyticsIdentifier || 'Unknown' }}>
    <div className={classes('slice-group', styles.wrapper)}>
      <div className={styles.inner}>{children}</div>
    </div>
  </AnalyticsContextProvider>
);
