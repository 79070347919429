import * as React from 'react';
import { Component, deps, inject, observer } from '../lib/component';
import { Loading } from '../components/loading/loading';
import { style } from 'typestyle';
import { em, px } from 'csx';
import { ButtonPill } from '../components/button-pill';
import { FormDetails } from '../components/form-details';
import { FormMarketingPreferencesModal } from '../components/form-marketing-preferences-modal';
import { colorSubtle } from '../theme/color';
import { ACCOUNT_PLANS_ROUTE, COUNTRIES, LANGUAGES, SUPPORT_HUB } from '../constants';
import { AccountCommunicationPage } from './account-communication';
import { Link } from '../components/project-happy/atoms/Link';
import { SubscriptionStates } from '../components/project-happy/utilities/types';
import { isEmpty } from '../components/project-happy/utilities/objects';
import { AccountLayout } from '../components/project-happy/templates/AccountLayout';

export interface AccountProfileProps {
  i18n?: {
    detailsTitle: string;
    detailsEdit: string;
    marketingTitle: string;
    marketingEdit: string;
    dataTitle: string;
    dataEdit: string;
    dangerTitle: string;
    dangerEdit: string;
    emptyValue: string;
  };
}

@inject(deps)
@observer
export class AccountProfilePage extends Component<AccountProfileProps> {
  render() {
    const { i18n = AccountProfilePage.i18n } = this.props;
    const model = this.props.model.page.accountProfile;
    const controller = this.props.controller.page.accountProfile;
    const modalController = this.props.controller.modal;
    const { user, isSubscribed, hasOngoingSubscriptions, subscriptionStatus } = this.props.model.user;

    if (model.loading || isEmpty(user))
      return (
        <AccountLayout>
          <div className={AccountCommunicationPage.styles.containerLoading}>
            <Loading />
          </div>
        </AccountLayout>
      );

    const genre =
      user &&
      this.props.model.content.genres &&
      this.props.model.content.genres.find((g) => g.slug === user.preferred_genre);
    const countryName = COUNTRIES[user.country] || i18n.emptyValue;

    const isUnknownSubscriptionState = subscriptionStatus === SubscriptionStates.UNKNOWN;
    const isOverdue = subscriptionStatus === SubscriptionStates.OVERDUE;
    const disableDeleteButton = hasOngoingSubscriptions || (isUnknownSubscriptionState && isSubscribed);

    return (
      <AccountLayout>
        <div data-test-profile-block className={AccountProfilePage.styles.block}>
          <h3>{i18n.detailsTitle}</h3>
          {!model.modalDetails.show && (
            <dl className={AccountProfilePage.styles.definitions}>
              <dt>Email</dt>
              <dd data-test-profile-email>{user.email}</dd>
              <dt>First name</dt>
              <dd data-test-profile-first-name>{user.first_name}</dd>
              <dt>Last name</dt>
              <dd data-test-profile-last-name>{user.last_name}</dd>
              <dt>Country</dt>
              <dd data-test-profile-country>{countryName}</dd>
              <dt>Language</dt>
              <dd>{LANGUAGES[user.language] || i18n.emptyValue}</dd>
              <dt>Telephone</dt>
              <dd>{user.telephone || i18n.emptyValue}</dd>
              <dt>Is business</dt>
              <dd>{user.is_business ? 'Yes' : 'No'}</dd>
              <dt>Preferred genre</dt>
              <dd>{genre ? genre.label : i18n.emptyValue}</dd>
              <dt>How did you find us?</dt>
              <dd>{user.found_us ? user.found_us : i18n.emptyValue}</dd>
            </dl>
          )}
          {!model.modalDetails.show && (
            <ButtonPill onClick={() => controller.editDetails()}>{i18n.detailsEdit}</ButtonPill>
          )}
          {model.modalDetails.show && (
            <FormDetails
              model={model.modalDetails.form}
              onChange={controller.changeDetailsField}
              onSubmit={controller.submitDetailsModal}
              onCancel={controller.closeDetailsModal}
            />
          )}
        </div>
        <div className={AccountProfilePage.styles.block}>
          <h3>{i18n.marketingTitle}</h3>
          <dl className={AccountProfilePage.styles.definitions}>
            <dt>Allow marketing</dt>
            <dd>{user.marketing_allowed ? `Yes` : `No`}</dd>
          </dl>
          <ButtonPill onClick={() => controller.editMarketing()}>{i18n.marketingEdit}</ButtonPill>
          <FormMarketingPreferencesModal
            model={model.modalMarketing}
            onChange={controller.changeMarketingField}
            onSubmit={controller.submitMarketingModal}
            onRequestClose={controller.closeMarketingModal}
            onCancel={controller.closeMarketingModal}
          />
        </div>
        <div className={AccountProfilePage.styles.block}>
          <h3>{i18n.dataTitle}</h3>
          <ButtonPill onClick={() => modalController.showDownloadAccountDataModal()}>{i18n.dataEdit}</ButtonPill>
        </div>
        <div className={AccountProfilePage.styles.block}>
          <h3>{i18n.dangerTitle}</h3>
          <ButtonPill
            className={ButtonPill.styles.danger}
            onClick={() => !disableDeleteButton && modalController.showDeleteAccountModal()}
            disabled={disableDeleteButton}
          >
            {i18n.dangerEdit}
          </ButtonPill>
          {disableDeleteButton && (
            <p className={AccountProfilePage.styles.unsubText}>
              {hasOngoingSubscriptions && !isOverdue && (
                <>
                  You must unsubscribe from Lickd Creator subscription before you can delete your account.{' '}
                  <Link href={ACCOUNT_PLANS_ROUTE}>Manage plans</Link>
                </>
              )}
              {hasOngoingSubscriptions && isOverdue && (
                <>
                  You have an active subscription with an overdue charge,{' '}
                  <a href={SUPPORT_HUB}>submit a support ticket</a> so we can assist you in resolving this issue.
                </>
              )}
              {isUnknownSubscriptionState && (
                <>
                  You may have an active Lickd Creator subscription, <a href={SUPPORT_HUB}>submit a support ticket</a>{' '}
                  so we can assist with deleting your account.
                </>
              )}
            </p>
          )}
        </div>
      </AccountLayout>
    );
  }

  static i18n = {
    detailsTitle: 'My details',
    detailsEdit: 'Edit details',
    marketingTitle: 'My marketing preferences',
    marketingEdit: 'Edit marketing preferences',
    dataTitle: 'My data',
    dataEdit: 'Download my data',
    dangerTitle: 'Danger zone',
    dangerEdit: 'Delete my account',
    emptyValue: '-',
  };

  static styles = {
    block: style({
      marginBottom: px(40),
      maxWidth: px(600),
    }),
    definitions: style({
      margin: '1em 0',
      $nest: {
        '& dt': {
          margin: 0,
          padding: 0,
          fontSize: px(12),
          lineHeight: em(1.1),
          color: colorSubtle.toString(),
        },
        '& dd': {
          margin: '0 0 1em 0',
        },
        '& dd:last-child': {
          margin: 0,
        },
      },
    }),
    unsubText: style({
      fontSize: px(12),
    }),
  };
}
