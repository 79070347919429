import * as React from 'react';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { Popover, PopoverPosition, PopoverProps } from 'react-tiny-popover';
import { rebrand } from '../../../theme/color';
import { zTooltip } from '../../../theme/z';
import { isFinePointer } from '../utilities/mediaQueries';
import { ArtistSchema } from '../../../types/schema';
import { InlineArtistLinkList } from '../../artist-link-list';
import ClientOnly from './ClientOnly';

export const styles = {
  container: style({ zIndex: zTooltip }),
  tooltip: style({
    backgroundColor: rebrand.dark1.toString(),
    borderColor: rebrand.dark1.toString(),
    borderRadius: px(4),
    fontSize: px(12),
    color: 'white',
    padding: '4px 8px',
    lineHeight: 'normal',
    zIndex: zTooltip,
    position: 'relative',
    $nest: {
      '&::after': {
        content: '""',
        width: 0,
        height: 0,
        border: '4px solid transparent',
        position: 'absolute',
      },
      '&.top': {
        transform: 'translateY(-4px)',
        $nest: {
          '&::after': {
            borderBottomWidth: 0,
            bottom: px(-4),
            left: 'calc(50% - 4px)',
            borderTopColor: 'inherit',
          },
        },
      },
      '&.left': {
        transform: 'translateX(-4px)',
        $nest: {
          '&::after': {
            borderRightWidth: 0,
            right: px(-4),
            top: 'calc(50% - 4px)',
            borderLeftColor: 'inherit',
          },
        },
      },
      '&.bottom': {
        transform: 'translateY(4px)',
        $nest: {
          '&::after': {
            borderTopWidth: 0,
            top: px(-4),
            left: 'calc(50% - 4px)',
            borderBottomColor: 'inherit',
          },
        },
      },
    },
  }),
  childWrapper: style({
    display: 'inherit',
  }),
  artistLinkHover: style({
    $nest: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
};

export type TooltipProps = {
  content: JSX.Element;
  className?: string;
  portalClassName?: string;
  wrapperClassName?: string;
  show?: boolean;
  positions?: PopoverPosition[];
  disableOnMobile?: boolean;
  onClickOutside?: () => void;
  align?: 'start' | 'end' | 'center';
  artists?: Array<ArtistSchema>;
};

export class Tooltip extends React.Component<TooltipProps> {
  state = {
    shown: false,
    hoverTimeout: null,
    overTrigger: false,
    overTooltip: false,
  };

  showTooltip = (): void => {
    clearTimeout(this.state.hoverTimeout);
    this.setState({ shown: true });
  };

  hideTooltip = (): void => {
    if (!this.state.overTrigger && !this.state.overTooltip) {
      this.setState({ shown: false });
    }
  };

  handleMouseEnterTrigger = (): void => {
    const timeoutId = setTimeout(this.showTooltip, 500); // Delay for tooltip to show
    this.setState({
      overTrigger: true,
      hoverTimeout: timeoutId,
    });
  };

  handleMouseLeaveTrigger = (): void => {
    this.setState({ overTrigger: false });
    clearTimeout(this.state.hoverTimeout); // Clear any pending tooltip display timeout
    this.setState({ hoverTimeout: setTimeout(this.hideTooltip, 300) }); // Delay to allow quick re-entry
  };

  handleMouseEnterTooltip = (): void => {
    this.setState({ overTooltip: true });
    clearTimeout(this.state.hoverTimeout); // Only clear the hide timeout if necessary
  };

  handleMouseLeaveTooltip = (): void => {
    this.setState({ overTooltip: false }, this.hideTooltip); // Hide the tooltip when mouse leaves
  };

  componentWillUnmount(): void {
    clearTimeout(this.state.hoverTimeout);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    const {
      content,
      className,
      portalClassName,
      wrapperClassName,
      children,
      show,
      positions = ['left', 'top', 'bottom'] as PopoverPosition[],
      align = 'center',
      disableOnMobile = true,
      onClickOutside,
      artists,
    } = this.props;

    const isControlled = typeof show === 'boolean';
    const activeShowState = isControlled ? show : this.state.shown;

    const childWrapperClasses = classes(styles.childWrapper, wrapperClassName);
    const handledChildren = (
      <span
        className={childWrapperClasses}
        onMouseEnter={this.handleMouseEnterTrigger}
        onMouseLeave={this.handleMouseLeaveTrigger}
      >
        {children}
      </span>
    );

    // Determine mouse event handlers based on the presence of artist data
    const mouseEnterTooltip = artists && artists.length > 0 ? this.handleMouseEnterTooltip : undefined;
    const mouseLeaveTooltip = artists && artists.length > 0 ? this.handleMouseLeaveTooltip : undefined;

    const tooltipContent = (
      <div
        onMouseEnter={mouseEnterTooltip}
        onMouseLeave={mouseLeaveTooltip}
        className={classes(styles.tooltip, className, positions[0])}
      >
        {artists && artists.length > 0 ? (
          <>
            {InlineArtistLinkList({
              model: { artists },
              linkClassName: styles.artistLinkHover,
            })}
          </>
        ) : (
          content
        )}
      </div>
    );

    return (
      <ClientOnly>
        <Popover
          isOpen={activeShowState && (isFinePointer.matches || !disableOnMobile)}
          containerClassName={classes(styles.container, portalClassName)}
          content={tooltipContent}
          positions={positions}
          onClickOutside={onClickOutside}
          align={align}
        >
          {handledChildren}
        </Popover>
      </ClientOnly>
    );
  }
}
