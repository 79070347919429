import {
  PrismicComplexBackgroundProps,
  PrismicHeadedCarouselProps,
  PrismicHeadedTileGridProps,
  PrismicManualLinkListProps,
  PrismicSizeConfigurableCarouselProps,
  PrismicSlice,
  PrismicTileShape,
  SlicePageContext,
} from '../utilities/types';
import * as React from 'react';
import { LinkCarouselSlice } from '../organisms/slices/LinkCarouselSlice';
import { AnyObject } from '../utilities/objects';
import { LinkTilesSlice } from '../organisms/slices/LinkTilesSlice';

export type PrismicLinkTilesPayload = PrismicHeadedTileGridProps & PrismicManualLinkListProps;

export type PrismicLinkTilesProps = {
  payload: PrismicLinkTilesPayload;
  context: SlicePageContext;
};
export const PrismicLinkTiles: React.SFC<PrismicLinkTilesProps> = ({
  payload: {
    primary: { tiles_heading },
    items,
  },
}) => (
  <LinkTilesSlice
    heading={tiles_heading}
    links={items.map(
      ({
        tile_heading,
        link_pathname,
        background_image: { url },
        background_colour,
        background_gradient_colour_end,
        background_gradient_colour_direction,
      }) => ({
        heading: tile_heading,
        href: link_pathname,
        src: url,
        gradient: {
          from: background_colour,
          to: background_gradient_colour_end,
          direction: background_gradient_colour_direction,
        },
      })
    )}
  />
);
