import { observable } from 'mobx';

export class TermsAndConditionsPageModel {
  @observable
  loading: boolean = true;

  @observable
  page_title: any = null;

  @observable
  page_body: any = null;

  @observable
  cover_image: any = null;
}