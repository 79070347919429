import * as React from 'react';
import { Component, deps, inject, observer } from '../../lib/component';
import { ButtonPlay } from '../button-play';
import { TrackSchema } from '../../types/schema';

export interface PlayButtonCompatProps {
  track: TrackSchema;
  size?: number;
  className?: string;
  disabled?: boolean;
  invert?: boolean;
  style?: any;
  startAt?: number;
}

@inject(deps)
@observer
export class PlayButtonCompat extends Component<PlayButtonCompatProps> {
  render() {
    const { disabled, size, invert, style, startAt } = this.props;
    const { currentTrack, play } = this.props.model.player;
    const { player } = this.props.controller;

    return (
      <ButtonPlay
        style={style}
        className={this.props.className}
        track={this.props.track}
        currentTrack={currentTrack}
        isPlaying={play}
        onLoadImmediate={player.loadImmediateAndSkipAndTrack.bind(null, startAt || null)}
        onPlay={player.playAndTrack}
        onPause={player.pauseAndTrack}
        disabled={disabled}
        size={size}
        invert={invert}
      />
    );
  }
}
