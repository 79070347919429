import { action } from 'mobx';
import { API, APIError, APIPaginatedResponse, APIResponse, getEmptyPaginatedResponse } from '../types';
import { PlaylistGroupPlaylistSchema, PlaylistSchema, TrackSchema } from '../../../types/schema';
import { AnyObject, objectToUrlSearchParams } from '../../../components/project-happy/utilities/objects';

export class PlaylistAPIController extends API {
  @action
  getUserPlaylists = async (user: string, page = 1, perPage = 25, trackIdentity = '') => {
    const url = `/user/${user}/playlists?page=${page}&perPage=${perPage}${
      trackIdentity ? `&track_id=${trackIdentity}` : ''
    }`;
    const response = await this.request.get({ url });
    await APIError.checkResponse(response);
    const playlistResponseData = (await response.json()) as APIPaginatedResponse<PlaylistSchema[]>;
    // User favourites are currently included in a user playlists response, keep this logic here to ensure it doesn't leak into MVC code
    playlistResponseData.data = playlistResponseData.data.filter(({ slug }) => slug !== 'favourites');
    return playlistResponseData;
  };

  @action
  createUserPlaylist = async (user: string, playlistName: string, playlistDescription: string) => {
    const url = `/user/${user}/playlists`;
    const body = JSON.stringify({ name: playlistName, description: playlistDescription, is_public: true });
    const resp = await this.request.post({ url, body });

    return await resp.json();
  };

  @action
  addTrackToUserPlaylist = async (user: string, playlist: string, track: string) => {
    const url = `/user/${user}/playlists/${playlist}/tracks`;
    const body = JSON.stringify({ track_id: track });
    const resp = await this.request.post({ url, body });

    return await resp.json();
  };

  @action
  removeTrackFromUserPlaylist = async (user: string, playlist: string, track: string) => {
    const url = `/user/${user}/playlists/${playlist}/tracks/${track}`;
    const resp = await this.request.del({ url });

    return await resp.json();
  };

  @action
  getUserPlaylistMetadata = async (userIdentity: string, playlistIdentity: string) => {
    const url = `/user/${userIdentity}/playlists/${playlistIdentity}`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<PlaylistSchema>;

    return json;
  };

  // Public route to retrieve a user playlist using only the playlist ID
  @action
  getUserPlaylistMetadataPublic = async (playlistIdentity: string): Promise<APIResponse<PlaylistSchema>> => {
    const url = `/user/playlists/${playlistIdentity}`;
    const resp = await this.request.get({ url });
    const json = (await resp.json()) as APIResponse<PlaylistSchema>;

    return json;
  };

  @action
  getUserPlaylistTracks = async (
    user: string,
    playlist: string,
    page = 1,
    perPage = 25,
    availableIn
  ): Promise<APIPaginatedResponse<Array<TrackSchema>>> => {
    const url = `/user/${user}/playlists/${playlist}/tracks?page=${page}&perPage=${perPage}&include=releases,images,tags${
      availableIn ? `&available_in=${availableIn}` : ''
    }`;
    try {
      const response = await this.request.get({ url });
      await APIError.checkResponse(response);
      const json = await response.json();
      this.cache.addTracks(json.data);

      return json;
    } catch {
      return getEmptyPaginatedResponse();
    }
  };

  // Public route to retrieve user playlist tracks using only the playlist ID
  @action
  getUserPlaylistTracksPublic = async (
    playlist: string,
    page = 1,
    perPage = 25,
    availableIn?: string
  ): Promise<APIPaginatedResponse<Array<TrackSchema>>> => {
    const url = `/user/playlists/${playlist}/tracks?page=${page}&perPage=${perPage}&include=releases,images,tags${
      availableIn ? `&available_in=${availableIn}` : ''
    }`;
    try {
      const response = await this.request.get({ url });
      await APIError.checkResponse(response);
      const json = await response.json();
      this.cache.addTracks(json.data);

      return json;
    } catch {
      return getEmptyPaginatedResponse();
    }
  };

  @action
  async getFavourites(
    user: string,
    page = 1,
    perPage = 25
  ): Promise<APIPaginatedResponse<Array<PlaylistGroupPlaylistSchema>>> {
    const url = `/user/${user}/favourites/playlists?page=${page}&perPage=${perPage}`;
    try {
      const response = await this.request.get({ url });
      await APIError.checkResponse(response);
      return await response.json();
    } catch {
      return getEmptyPaginatedResponse();
    }
  }

  @action
  async favouritePlaylist(user: string, playlist: string) {
    const url = `/user/${user}/favourites/playlists`;
    const body = JSON.stringify({ playlist_uuid: playlist });
    const response = await this.request.post({ url, body });
    await APIError.checkResponse(response);
    return await response.json();
  }

  @action
  async unfavouritePlaylist(user: string, playlist: string) {
    const url = `/user/${user}/favourites/playlists`;
    const body = JSON.stringify({ playlist_uuid: playlist });
    const response = await this.request.del({ url, body });
    await APIError.checkResponse(response);
    return await response.json();
  }

  @action
  getCuratedPlaylists = async (filters?: AnyObject) => {
    const searchParams = '?' + objectToUrlSearchParams(filters).toString();
    const url = `/catalog/playlist${searchParams}`;
    const response = await this.request.get({ url });
    await APIError.checkResponse(response);
    const json = (await response.json()) as APIResponse<Array<PlaylistSchema>>;

    return json;
  };

  @action
  getCuratedPlaylistTracks = async (
    slug: string,
    page = 1,
    perPage?: number,
    availableIn = ''
  ): Promise<APIPaginatedResponse<Array<TrackSchema>>> => {
    let queryParameters = `page=${page}`;
    if (typeof perPage === 'number') {
      queryParameters += `&perPage=${perPage}`;
    }
    const url = `/catalog/playlist/${slug}/tracks?${queryParameters}&include=releases,images${
      availableIn ? `&available_in=${availableIn}` : ''
    }`;
    const response = await this.request.get({ url });
    await APIError.checkResponse(response);
    return response.json();
  };
}
