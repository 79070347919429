import * as React from 'react';
import { observer } from 'mobx-react';
import { ReleaseTrack } from './release-track';
import { style } from 'typestyle';
import { ContainerQuery } from 'react-container-query';
import { TrackSchema } from '../types/schema';
import { ImagePreloader } from '../types/fn';
import { AnalyticsController } from '../modules/analytics/controller';
import { TrackLine } from './project-happy/molecules/TrackLine';
import { colorLightGrey } from '../theme/color';

export interface ReleaseTracksModel {
  tracks: Array<TrackSchema>;
  highlight?: string;
  loading: boolean;
}

export interface ReleaseTracksProps {
  model: ReleaseTracksModel;
  pageSize?: number;
  PlayButton: any;
  Favourite: any;
  isMobile: boolean;
  AddRemove: any;
  preloadWaveform: ImagePreloader;
  onClickLink(event: React.MouseEvent<any>): any;
  analytics: AnalyticsController;
}

@observer
export class ReleaseTracks extends React.Component<ReleaseTracksProps, any> {
  render() {
    const {
      model,
      PlayButton,
      Favourite,
      AddRemove,
      pageSize = 12,
      onClickLink,
      preloadWaveform,
      isMobile,
      analytics,
    } = this.props;

    // destructure to force re-render of container query if model changes
    const { tracks, loading } = model;

    return (
      <ContainerQuery
        query={ReleaseTracks.query}
        /* eslint-disable react/no-children-prop */
        children={(params: any) => (
          <div className={ReleaseTracks.styles.container}>
            {model.tracks.map((track, index) => (
              <div key={index}>
                <div className={ReleaseTracks.styles.trackLineWrapper}>
                  <TrackLine track={track} onClickLink={onClickLink} />
                </div>
                {track.mixes != undefined &&
                  track.mixes.length > 0 &&
                  track.mixes.map((mix, index) => (
                    <div key={index} className={ReleaseTracks.styles.trackLineWrapper}>
                      <TrackLine track={mix} onClickLink={onClickLink} />
                    </div>
                  ))}
              </div>
            ))}
          </div>
        )}
      />
    );
  }

  static styles = {
    container: style({
      padding: '0.5rem 0',
    }),
    trackLineWrapper: style({
      borderBottom: `1px solid ${colorLightGrey.toString()}`,
      maxWidth: '1000px',
    }),
  };

  static query = {
    isMobile: {
      maxWidth: 600,
    },
  };
}
