import { RedirectFunction } from 'react-router';
import { action } from 'mobx';
import { RouterState } from 'react-router';
import { FakeDoorPageModel } from './model';
import { PrismicController } from '../../prismic/controller';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import { ImageController } from '../../image/controller';
import { RouterModel } from '../../router/model';
import { ContentModel } from '../../content/model';

export class FakeDoorPageController {
  constructor(
    private model: FakeDoorPageModel,
    private prismic: PrismicController,
    private env: EnvModel,
    private ui: UIController,
    private router: RouterModel,
    private content: ContentModel,
    private image: ImageController,
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    this.model.loading = true;

    await this.ui.setSEO(nextState.location.pathname);

    try {
      const [{ data }] = await Promise.all([this.prismic.getSingle('fake_door'), this.env.ready]);

      this.model.form = data.fakedoor_form.url;
      this.model.mobileHeader = data.mobile_header_img.url;
      this.model.desktopHeader = data.desktop_header_img.url;
    } catch (e) {
      this.router.replace('/404');
    } finally {
      this.model.loading = false;
    }

    return;
  };

  @action
  goBack = () => this.router.goBack();
}
