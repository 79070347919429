import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';
import { colorGunmetal } from '../../../../theme/color';

export const VariationBox: React.FC<SVGIconProps> = ({
  size = 18,
  isSquare,
  color = colorGunmetal.toHexString(),
  ...props
}) => (
  <BaseSvg width={size} height={size} viewBox="0 0 18 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.27 2.27V13.47H13.47V2.27H2.27ZM1.575.668c-.501 0-.907.406-.907.907v12.593c0 .5.406.906.907.906h12.593a.906.906 0 0 0 .906-.906V1.574c0-.501-.405-.907-.906-.907H1.574Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.54 3.226c.5 0 .793.341.793.842v11.565c0 1.002-.698 1.7-1.7 1.7H4.068c-.5 0-.842-.292-.842-.793 0-.501.341-.79.842-.79H15.75V4.068c0-.5.289-.842.79-.842Z"
      fill={color}
    />
  </BaseSvg>
);
