import { APIController } from '../../api/controller';
import { AccountOrdersPageModel } from './model';
import { action, autorun, runInAction, when } from 'mobx';
import { RouterState, RedirectFunction } from 'react-router';
import { UserModel } from '../../user/model';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import { stripQueryString } from '../../../lib/string';
import { BugsnagController } from '../../bugsnag/controller';
import { isEmpty } from '../../../components/project-happy/utilities/objects';

export class AccountOrdersPageController {
  disposables: (() => void)[] = [];

  constructor(
    private user: UserModel,
    private model: AccountOrdersPageModel,
    private api: APIController,
    private env: EnvModel,
    private ui: UIController,
    private bugsnag: BugsnagController
  ) {}

  dispose() {
    this.disposables.forEach((disposer) => typeof disposer === 'function' && disposer());
  }

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    try {
      this.model.loading = true;

      this.ui.setBreadcrumbs([
        {
          path: '/account',
          label: 'Account',
        },
        {
          path: '/account/orders',
          label: 'Orders',
        },
      ]);

      await this.ui.setSEO(nextState.location.pathname);

      await this.env.ready;

      this.disposables.push(
        autorun(() => {
          if (!isEmpty(this.user.channel)) {
            this.model.channelId = this.user.channel.id;
            this.loadOrders();
          }
        })
      );

      return;
    } catch (e) {
      replace({
        pathname: `/account/licenses`,
      });
    }

    return;
  };

  @action
  loadOrders = async (page = 1): Promise<void> => {
    try {
      this.model.loading = true;

      const orders = await this.api.user.getOrders(
        this.user.user.identity,
        page,
        this.model.dateOrder,
        this.model.channelId
      );

      this.model.orders = orders.data;
      this.model.pagination = orders.meta.pagination;
    } catch (error) {
      this.bugsnag.notifyException(error);
    } finally {
      this.model.loading = false;
    }
  };

  @action
  changeDateOrder = async (): Promise<void> => {
    this.model.dateOrder = this.model.dateOrder === 'DESC' ? 'ASC' : 'DESC';
    this.loadOrders();
  };

  @action
  loadMoreOrders = async (): Promise<void> => {
    if (this.model.pagination.current_page === this.model.pagination.total_pages) return;

    this.model.loadingMore = true;

    const page = this.model.pagination.current_page + 1;

    try {
      const orders = await this.api.user.getOrders(
        this.user.user.identity,
        page,
        this.model.dateOrder,
        this.model.channelId
      );

      this.model.orders.push(...orders.data);
      this.model.pagination = orders.meta.pagination;
    } catch (error) {
      this.bugsnag.notifyException(error);
    } finally {
      this.model.loadingMore = false;
    }
  };

  @action
  downloadInvoice = async (invoiceId: string): Promise<void> => {
    const { identity } = this.user.user;

    try {
      this.model.isDownloadingInvoice = invoiceId;

      // Request the invoice PDF from the API
      const pdfDocument = await this.api.user.downloadInvoice(identity, invoiceId);
      // Create an object URL from the response
      const objUrl = window.URL.createObjectURL(pdfDocument);

      // Create and click an anchor element to trigger the download
      const link = document.createElement('a');
      link.href = objUrl;
      link.download = objUrl.split('/').pop();
      link.click();

      // Release the object URL
      window.URL.revokeObjectURL(objUrl);
    } catch (error) {
      this.bugsnag.notifyException(new Error(`Failed to generate invoice for order: ${invoiceId}`));
    } finally {
      this.model.isDownloadingInvoice = null;
    }
  };
}
