import * as React from 'react';
import { classes, style } from 'typestyle';
import { Component, deps, inject } from '../../../lib/component';
import { observer } from 'mobx-react';
import { important, px } from 'csx';
import { getButtonStyles } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';

const styles = {
  select: style({
    appearance: 'none',
    '-webkit-appearance': important('none'),
    '-moz-appearance': important('none'),
    backgroundColor: 'transparent',
    border: 'none',
    padding: '0 1em 0 0',
    margin: 0,
    fontFamily: 'inherit',
    cursor: 'inherit',
    lineHeight: 'inherit',
    outline: 'none',
    fontSize: px(16),
    fontWeight: 600,
    $nest: {
      '&::ms-expand': {
        display: 'none',
      },
    },
  }),
  baseButton: getButtonStyles({ size: ButtonSize.SMALL, prominence: ButtonProminence.SECONDARY }),
  selectButton: style({
    display: 'inline-flex',
    $nest: {
      '&:after': {
        position: 'relative',
        content: "''",
        top: px(7),
        width: '0.8em',
        height: '0.5em',
        backgroundColor: '#000',
        clipPath: 'polygon(100% 0%, 0 0%, 50% 100%)',
      },
    },
  }),
};

export type SelectProps = {
  children: any;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value: number;
  selectClassName?: string;
};

@inject(deps)
@observer
export class Select extends Component<SelectProps> {
  render() {
    const { value, onChange, children, selectClassName } = this.props;
    return (
      <div className={classes(styles.baseButton, styles.selectButton)}>
        <select value={value} onChange={onChange} className={classes(styles.select, selectClassName)}>
          {children}
        </select>
      </div>
    );
  }
}
