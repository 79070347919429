import * as React from 'react';
import gql from 'graphql-tag';
import { graphql, InjectedGraphQLProps } from 'react-apollo';
import { Component, deps, inject, observer } from '../../lib/component';
import { classes, style } from 'typestyle';
import { important, px, rem } from 'csx';
import { centerCenter } from 'csstips';
import maybe from '../../lib/maybe';
import { SimilarTrack, Track } from '../../types/graphql';
import { colorSubtle } from '../../theme/color';
import { ReleaseTracks, ReleaseTracksModel } from '../release-tracks';
import { PlayButtonCompat } from '../compat/play-button-compat';
import { FavouriteButtonCompat } from '../compat/favourite-button-compat';
import { AddRemoveCompat } from '../compat/add-remove-compat';
import { Loading } from '../loading/loading';

type SimilarTracksProps = {
  identity: string;
  containerStyles: string;
  headingText: string;
  headingStyles: string;
};

type Data = {
  getTrack: Track;
};

type CombinedProps = SimilarTracksProps & InjectedGraphQLProps<Data>;

@inject(deps)
@observer
class TrackSimilarTracks extends Component<CombinedProps> {
  render() {
    const { data, headingText, headingStyles, containerStyles } = this.props;
    const { handleClickLink } = this.props.controller.ui;
    const { preloadWaveformSvg } = this.props.controller.image;
    const { isMobile } = this.props.model.ui;

    if (data.loading) {
      return (
        <div className={classes(TrackSimilarTracks.styles.container, TrackSimilarTracks.styles.loading)}>
          <Loading size={32} />
        </div>
      );
    }

    // Return null if there are no similar tracks
    const similar: SimilarTrack[] = data.getTrack && data.getTrack.similar ? data.getTrack.similar : [];
    if (!similar.length) return null;

    return (
      <div className={containerStyles}>
        <h3 className={headingStyles}>{headingText}</h3>

        <ReleaseTracks
          analytics={this.props.controller.analytics}
          model={TrackSimilarTracks.releaseTracksModel(similar)}
          AddRemove={AddRemoveCompat}
          PlayButton={PlayButtonCompat}
          Favourite={FavouriteButtonCompat}
          isMobile={isMobile}
          onClickLink={handleClickLink}
          preloadWaveform={preloadWaveformSvg}
        />
      </div>
    );
  }

  static releaseTracksModel = (tracks: SimilarTrack[] | null): ReleaseTracksModel => ({
    loading: false,
    tracks: tracks ? tracks.filter((t) => !!t.track).map((t) => t.track as any) : [],
  });

  static styles = {
    container: style({
      zIndex: 1,
      background: colorSubtle.fade(0.075).toString(),
      padding: rem(1),
      borderRadius: rem(1),
      $nest: {
        '& h3': {
          margin: '1.5rem 0 1rem',
        },
        '& h3:first-child': {
          margin: '0 0 1rem 0',
        },
      },
    }),
    loading: style({
      ...centerCenter,
      width: important('100%'),
      minHeight: px(240),
    }),
  };
}

export default TrackSimilarTracks;

export const ConnectedTrackSimilarTracks = graphql(
  gql`
    query($identity: ID!) {
      getTrack(options: { identity: $identity }) {
        similar {
          track {
            audition_point
            identity
            title
            slug
            duration
            releases {
              title
              slug
              artist {
                slug
              }
            }
            created_at
            is_charting
            is_featured
            is_new_release
            brand_sponsored
            branded_content
            audio {
              identity
            }
            images {
              identity
            }
            artists {
              images {
                identity
              }
              name
              slug
              identity
            }
          }
        }
      }
    }
  `,
  {
    options: ({ identity }: SimilarTracksProps) => ({
      variables: {
        identity,
      },
    }),
  }
)(TrackSimilarTracks);
