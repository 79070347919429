import * as React from 'react';
import { VerticalQuestionnaireSlice } from '../organisms/slices/VerticalQuestionnaireSlice';
import { PrismicRichText } from '../utilities/types';
import { renderPrismicRichTextArray } from '../utilities/props';

export type PrismicVerticalQuestionnairePayload = {
  primary: {
    heading?: string;
    prompt?: string;
    confirmation_message?: PrismicRichText;
  };
};
export type PrismicVerticalQuestionnaireProps = {
  payload: PrismicVerticalQuestionnairePayload;
};
export const PrismicVerticalQuestionnaire: React.SFC<PrismicVerticalQuestionnaireProps> = ({
  payload: {
    primary: { heading, prompt, confirmation_message },
  },
}) => (
  <VerticalQuestionnaireSlice confirmation={<>{renderPrismicRichTextArray(confirmation_message)}</>}>
    {heading && <h2 className="heading">{heading}</h2>}
    {prompt && <p className="subheading">{prompt}</p>}
  </VerticalQuestionnaireSlice>
);
