import React from 'react';
import { CheckboxInput, CheckboxInputProps } from './CheckboxInput';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { colorBrand } from '../../../../theme/color';
import { flexRoot } from 'csstips';

const styles = {
  label: style(flexRoot, {
    fontSize: px(12),
    lineHeight: 1.5,
    cursor: 'pointer',
    $nest: {
      '& a': {
        color: 'inherit',
        textDecoration: 'underline',
        $nest: {
          '&:hover, &:focus': {
            opacity: 0.8,
          },
        },
      },
    },
  }),
  input: style({
    marginRight: px(7),
  }),
};

export type LabelledCheckboxProps = { inputClassName?: string } & CheckboxInputProps;
export const LabelledCheckbox: React.SFC<LabelledCheckboxProps> = ({
  children,
  className,
  inputClassName,
  ...props
}) => (
  <label data-test-checkbox-row className={classes(styles.label, className)}>
    <CheckboxInput className={classes(styles.input, inputClassName)} {...props} />
    <span>{children}</span>
  </label>
);
