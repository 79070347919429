import { AccountLicencesPageModel } from './model';
import { RedirectFunction, RouterState } from 'react-router';
import { action, runInAction } from 'mobx';
import { APIController } from '../../api/controller';
import { UserModel } from '../../user/model';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import { stripQueryString } from '../../../lib/string';

export class AccountLicencesPageController {
  constructor(
    private user: UserModel,
    private model: AccountLicencesPageModel,
    private api: APIController,
    private env: EnvModel,
    private ui: UIController
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    const page = parseInt(stripQueryString(nextState.params['page'])) || 1;

    try {
      runInAction(() => {
        this.model.loadingMore = true;
        this.model.page = page;
        this.ui.setBreadcrumbs([
          {
            path: '/account',
            label: 'Account',
          },
          {
            path: '/account/licenses',
            label: 'Licenses',
          },
        ]);
      });

      await this.ui.setSEO(nextState.location.pathname);

      const [tracks] = await Promise.all([
        this.api.user.getLicencedTracks(this.user.user.identity, page, 20),
        this.env.ready,
      ]);

      runInAction(() => {
        this.model.licences = tracks.data;
        this.model.pagination = tracks.meta.pagination;
        this.model.loadingMore = false;
      });

      return;
    } catch (e) {
      replace({
        pathname: `/account/licenses`,
      });
    }

    return;
  };

  @action
  loadPage = async (page: number) => {
    const lastPage = this.model.page;

    try {
      this.model.loadingMore = true;
      this.model.page = page;
      const tracks = await this.api.user.getLicencedTracks(this.user.user.identity, page, 20);

      runInAction(() => {
        this.model.licences = tracks.data;
        this.model.pagination = tracks.meta.pagination;
      });
    } catch (e) {
      this.model.page = lastPage;
    } finally {
      this.model.loadingMore = false;
    }
  };
}
