import * as React from 'react';
import { PrismicImage, PrismicSimpleBackgroundProps, PrismicSlice } from '../utilities/types';
import { PromotionalBannerSlice } from '../organisms/slices/PromotionalBannerSlice';

export type PrismicPromotionalBannerPayload = PrismicSimpleBackgroundProps &
  PrismicSlice<{
    background_image: { mobile: PrismicImage };
    link_url: string;
    link_type: boolean;
  }>;

export type PrismicPromotionalBannerProps = {
  payload: PrismicPromotionalBannerPayload;
};
export const PrismicPromotionalBanner: React.SFC<PrismicPromotionalBannerProps> = ({
  payload: {
    primary: { background_image, background_colour, link_url, link_type },
  },
}) => (
  <PromotionalBannerSlice
    src={[background_image.mobile.url, background_image.url]}
    width={[background_image.mobile.dimensions.width, background_image.dimensions.width]}
    height={[background_image.mobile.dimensions.height, background_image.dimensions.height]}
    colour={background_colour}
    href={link_url}
    internal={link_type}
    alt={background_image.alt}
  />
);
