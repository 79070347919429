import * as React from 'react';
import { ModalProps } from '../../modal';
import { ScrollableOverlay } from '../../scrollable-overlay/scrollable-overlay';
import { Widget } from '@typeform/embed-react';
import { style } from 'typestyle';
import { percent, px } from 'csx';
import { Loading } from '../../loading/loading';
import { rebrand } from '../../../theme/color';
import { heading6 } from '../utilities/font';
import { animationFadeIn } from '../../../theme/animation';
import { Component, deps, inject, observer } from '../../../lib/component';

const styles = {
  wrapper: style({ padding: px(16), width: percent(100) }),
  heading: style(heading6, { marginTop: 0, textAlign: 'center' }),
  formEmbed: style({ backgroundColor: rebrand.dark1.toString(), borderRadius: px(4), minHeight: '75vh' }),
  loader: style(animationFadeIn, {
    position: 'absolute',
    top: px(54),
    width: percent(100),
    height: 'calc(100% - 54px)',
    left: 0,
    background: 'white',
  }),
};

export type OffboardingModalProps = ModalProps & { className?: string; onSubmit: () => void };

@inject(deps)
@observer
export class OffboardingModal extends Component<OffboardingModalProps> {
  state = {
    hasSubmitted: false,
  };

  constructor(props) {
    super(props);
    this.handleSubmitted = this.handleSubmitted.bind(this);
  }

  handleSubmitted() {
    this.setState({ hasSubmitted: true });
    this.props.onSubmit();
  }

  render() {
    const {
      state: { hasSubmitted },
      props: {
        model: {
          env: {
            typeform: { offboarding },
          },
        },
      },
    } = this;
    return (
      <ScrollableOverlay className={styles.wrapper} {...this.props}>
        <h3 className={styles.heading}>Let us know why you&apos;re cancelling</h3>
        <Widget
          className={styles.formEmbed}
          id={offboarding}
          autoResize={true}
          opacity={0}
          hideHeaders={true}
          hideFooter={true}
          onSubmit={this.handleSubmitted}
        />
        {hasSubmitted && <Loading className={styles.loader} />}
      </ScrollableOverlay>
    );
  }
}
