import { NestedCSSProperties } from 'typestyle/lib/types';
import { extend, media } from 'typestyle';
import { constantTabletBreakpoint, constantMobileBreakpoint, constantDesktopBreakpoint } from './constant';

export const mediaMobileOnly: (styles: NestedCSSProperties) => NestedCSSProperties = (styles: NestedCSSProperties) =>
  media({ maxWidth: constantMobileBreakpoint }, styles);

export const mediaTabletLandscape: (styles: NestedCSSProperties) => NestedCSSProperties = (
  styles: NestedCSSProperties,
) =>
  media(
    {
      minWidth: constantMobileBreakpoint,
      maxHeight: 720,
      maxWidth: constantDesktopBreakpoint,
      orientation: 'landscape',
    },
    styles,
  );

export const mediaTablet = (styles: NestedCSSProperties) => media({ minWidth: constantTabletBreakpoint }, styles);

export const mediaUpToTablet = (styles: NestedCSSProperties) =>
  media({ maxWidth: constantDesktopBreakpoint - 1 }, styles);

export const mediaTabletOnly = (styles: NestedCSSProperties) =>
  media({ minWidth: constantTabletBreakpoint, maxWidth: constantDesktopBreakpoint - 1 }, styles);

export const mediaDesktop: (styles: NestedCSSProperties) => NestedCSSProperties = (styles: NestedCSSProperties) =>
  media({ minWidth: constantDesktopBreakpoint }, styles);

export const mediaPointer = (type: 'fine' | 'coarse', styles: NestedCSSProperties) => ({
  $nest: {
    [`@media (pointer: ${type})`]: extend(styles),
  },
});

export const mediaFinePointer = (styles: NestedCSSProperties) => mediaPointer('fine', styles);

export const mediaCoarsePointer = (styles: NestedCSSProperties) => mediaPointer('coarse', styles);
