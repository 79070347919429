import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Component, deps, inject, observer } from '../../lib/component';
import { classes, style } from 'typestyle';
import {
  center,
  centerCenter,
  centerJustified,
  horizontal,
  horizontallySpaced,
  vertical,
  verticallySpaced,
} from 'csstips';
import { important, percent, px, viewWidth } from 'csx';
import { graphql, InjectedGraphQLProps } from 'react-apollo';
import gql from 'graphql-tag';
import { Loading } from '../loading/loading';
import { BrowseState } from '../../modules/page/browse/model';
import { colorWhite, rebrand } from '../../theme/color';
import { PaginationMeta } from '../../types';
import { zUnderLayer } from '../../theme/z';
import { TrackSchema } from '../../types/schema';
import { PageHeaderCarousel, PageHeaderCarouselModel } from '../page-header-carousel';
import extractUniqueArtists from '../../lib/extractUniqueArtists';
import { PlaylistTile } from '../playlist-tile';
import { TagTile } from '../tag-tile';
import { mediaDesktop, mediaMobileOnly, mediaTablet } from '../../theme/media';
import { TagTileGrid, Tile } from '../tile-grid';
import MainWrapper from '../main-wrapper';
import maybe from '../../lib/maybe';
import FavouritePlaylistButtonCompat from '../compat/favourite-playlist-button-compat';
import { BrowseMasthead } from '../project-happy/molecules/mastheads/BrowseMasthead';
import { TrackList } from '../project-happy/organisms/TrackList';

export interface BrowseResultsProps extends BrowseState {
  hidePagination?: boolean;
  pathGenerate?: (page: number) => string;
  pathRoot?: string;
}

export type BrowseResultsDataType = {
  item: {
    identity: string;
    name: string;
    slug: string;
    description: string;
    images: {
      identity: string;
    };
    tracks: {
      pagination: {
        total: number;
        totalPages: number;
        from: number;
        size: number;
        page: number;
      };
      results: TrackSchema[];
    };
  };
  related: {
    results: Array<{
      name: string;
      slug: string;
      images: {
        identity: string;
      };
    }>;
  };
};

type DataProps = {
  loading: boolean;
  error?: any;
};

type MaybeData = (BrowseResultsDataType & DataProps) | null;
type CombinedProps = BrowseResultsProps & InjectedGraphQLProps<BrowseResultsDataType>;

@inject(deps)
@observer
export class BrowseResults extends Component<CombinedProps> {
  render() {
    const { data, hidePagination, pathGenerate, pathRoot } = this.props;
    const { isMobile } = this.props.model.ui;
    const { type, makeRelatedPath, playlistGroups, slug } = this.props.model.page.browse;
    const { cover } = this.props.model.image;
    const { goToPage } = this.props.controller.page.browse;
    const { preloadImage, preloadWaveformSvg, preloadContent } = this.props.controller.image;
    const { handleClickLink } = this.props.controller.ui;
    const featuredPlaylists = playlistGroups.get('featured-playlists');
    const dataIsObject = typeof data === 'object' && data !== null;
    const itemExists = dataIsObject && typeof data.item === 'object' && data.item !== null;
    const hasHits = itemExists && data.item.tracks && data.item.tracks.results;
    const hasRelated = dataIsObject && data.related && data.related.results;
    const description = itemExists && data.item.description ? data.item.description : null;
    const identity = itemExists ? data.item.identity : null;

    const FavouriteButton =
      type === 'collection' && identity ? () => <FavouritePlaylistButtonCompat identity={identity} /> : () => null;

    const trackListIsPagniated = itemExists
      ? data.item.tracks.pagination.total > data.item.tracks.pagination.size
      : null;
    const baseURL = this.props.model.env.baseUrl;
    const pathName = this.props.model.router.location.pathname;
    const pathNameArray = pathName.split('/');
    let pathNameWithoutPageNumber;

    // If the end of the url is a number, remove it from the URL and create a new path
    if (Number(pathNameArray[pathNameArray.length - 1])) {
      pathNameArray.pop();
      pathNameWithoutPageNumber = pathNameArray.join('/');
    }

    if (data.loading) {
      return <Loading className={BrowseResults.styles.loading} />;
    }

    return (
      <MainWrapper className={BrowseResults.styles.container}>
        <div>
          {itemExists && (
            <Helmet>
              <meta property="og:image" content={this.props.model.image.cover(data.item.images.identity)} />
            </Helmet>
          )}
          {trackListIsPagniated && pathNameWithoutPageNumber && (
            <Helmet>
              <link rel="canonical" href={`${baseURL}${pathNameWithoutPageNumber}`}></link>
            </Helmet>
          )}
        </div>
        <BrowseMasthead heading={itemExists && data.item.name} gradient={{ from: rebrand.dark1.toString() }}>
          {FavouriteButton && <FavouriteButton />}
        </BrowseMasthead>
        {data.error && (
          <div className={BrowseResults.styles.loadingOverlay}>
            <h2>Hmm. Something ain&apos;t right here...</h2>
            <p>We&apos;ve been notified and are on the case, please try again later!</p>
          </div>
        )}
        <div className={BrowseResults.styles.split}>
          {hasHits && <div className={BrowseResults.styles.tracks}>{/*  No longer used */}</div>}
        </div>
      </MainWrapper>
    );
  }

  renderTile = (tile: Tile<any>, commonProps) => {
    const { isCollection } = this.props.model.page.browse;

    switch (true) {
      case isCollection:
        return <PlaylistTile playlist={tile} {...commonProps} />;

      default:
        return <TagTile tag={tile} {...commonProps} />;
    }
  };

  UNSAFE_componentWillMount() {
    this.updateSEO();
  }

  componentDidUpdate(lastProps: CombinedProps) {
    this.updateSEO();
    if (lastProps.options.slug !== this.props.options.slug) {
      this.props.model.page.browse.page = 1;
    }
    const { data } = this.props;
    const dataIsObject = typeof data === 'object' && data !== null;
    const itemExists = dataIsObject && typeof data.item === 'object' && data.item !== null;
    const hasHits = itemExists && data.item.tracks && data.item.tracks.results;
    if (this.props.options.slug.startsWith('stock-') && data.loading === false && !hasHits) {
      this.props.model.router.replace(this.props.model.router.location.pathname.replace('stock-', 'included-'));
    }
  }

  updateSEO = () => {
    const type = maybe(() => this.props.model.page.browse.type, null);
    const name = maybe(() => this.props.data.item.name, null);

    if (!type || !name) return;

    const mappedType = type.toLowerCase();

    this.props.controller.ui.setLastBreadcrumbLabel(name);
    this.props.controller.ui.setSEO(`/browse/${mappedType}/slug`, { [mappedType]: name });
  };

  releaseTracksModel = (hits: any[]) => ({
    loading: false,
    tracks: hits,
  });

  getPath = (type: string, tile: Tile<any>) => {
    if (type === 'labels') {
      return `${tile.model.artist.slug}/release/${tile.slug}`;
    }

    return tile.slug;
  };

  getHeroModel = (data: MaybeData): PageHeaderCarouselModel => ({
    loading: !data || data.loading,
    artists:
      data && data.item && data.item.tracks && data.item.tracks.results
        ? extractUniqueArtists(data.item.tracks.results)
            .slice(0, 5)
            .map((a) => ({
              name: a.name,
              slug: a.slug,
              image: a.images ? a.images.identity : null,
            }))
        : [],
    title: data && data.item && data.item.name ? data.item.name : data.error ? 'Oops 🤦' : 'Loading...',
  });

  paginationMeta = (meta: any): PaginationMeta => ({
    count: meta.total,
    current_page: meta.page, // 0-index graphql, 1-index PaginationSelect
    per_page: meta.size,
    total: meta.total,
    total_pages: meta.totalPages,
  });

  static mapTiles = (results: BrowseResultsDataType['related']['results']): Tile<any>[] => {
    return results.map((r) => ({
      ...r,
      model: r,
    }));
  };

  static styles = {
    loading: style({
      margin: '25vh 0',
    }),
    container: style({
      position: 'relative',
    }),
    split: style(
      mediaMobileOnly({
        ...vertical,
        ...verticallySpaced(16),
        ...center,
        $nest: {
          '&>div': {
            width: percent(100),
          },
          '&>div:first-child': {
            padding: px(16),
          },
        },
      }),
      mediaTablet({
        ...horizontal,
        ...horizontallySpaced(16),
        ...centerJustified,
        padding: px(16),
        $nest: {
          '&>div:first-child': {
            flex: 2,
          },
          '&>div:last-child': {
            flex: 1,
          },
        },
      })
    ),
    description: style(
      {
        whiteSpace: 'pre-wrap',
        margin: '1rem 0 2rem',
      },
      mediaMobileOnly({
        padding: '0 1rem',
      })
    ),
    pageCarousel: style(),
    loadingOverlay: style({
      ...centerCenter,
      ...vertical,
      zIndex: zUnderLayer,
      padding: '16px 80px',
      maxWidth: px(420),
      minHeight: px(320),
      background: colorWhite.fade(0.9).toString(),
    }),
    tracks: style({}),
    pagination: style({
      ...centerCenter,
    }),
    sideHeading: style(mediaMobileOnly({ padding: '0 16px' })),
    carousel: style(
      {
        minHeight: px(190),
        display: 'none',
      },
      mediaMobileOnly({ height: viewWidth(52), display: 'block' })
    ),
    tile: style(
      { flexShrink: 0, height: 'auto !important', minWidth: px(190), $nest: { '& *': { fontSize: px(20) } } },
      mediaMobileOnly({ width: viewWidth(52) })
    ),
    tileGrid: style(
      {
        width: percent(100),
        display: important('none'),
      },
      mediaTablet({
        display: important('flex'),
      })
    ),
    relatedPlaylists: style(
      mediaDesktop({
        width: 'calc(33.33333333% - 16px)',
        paddingBottom: 'calc(33.33333333% - 16px)',
      }),
      {
        $nest: {
          '& .favourites-button': {
            position: 'absolute',
            top: px(8),
            right: px(8),
          },
        },
      }
    ),
    tileGridItem: style(
      mediaTablet({
        $nest: {
          '& div > *': {
            fontSize: important(viewWidth((100 * 8) / 420).toString()),
          },
        },
      }),
      mediaDesktop({
        $nest: {
          '& div > div': {
            fontSize: important(viewWidth((100 * 4) / 420).toString()),
          },
        },
      })
    ),
  };
}

export const CollectionBrowseResults = graphql(
  gql`
    query($options: GetCollectionInput!, $related: BrowseQueryInput!, $pagination: PaginationInput!) {
      related: browseCollection(options: $related) {
        results {
          identity
          name
          slug
          images {
            identity
          }
        }
      }
      item: getCollection(options: $options) {
        identity
        name
        slug
        images {
          identity
        }
        tracks(pagination: $pagination) {
          pagination {
            total
            totalPages
            from
            size
            page
          }
          results {
            identity
            title
            slug
            duration
            created_at
            is_charting
            is_featured
            is_new_release
            is_stock_music
            brand_sponsored
            branded_content
            audio {
              identity
            }
            images {
              identity
            }
            artists {
              images {
                identity
              }
              name
              slug
              identity
            }
          }
        }
      }
    }
  `,
  {
    options: (variables: BrowseResultsProps) => ({
      variables,
      // fetchPolicy: 'network-only'
    }),
  }
)(BrowseResults);

export const TagBrowseResults = graphql(
  gql`
    query($options: GetTagInput!, $related: BrowseQueryInput!, $pagination: PaginationInput!) {
      related: browseTag(options: $related) {
        results {
          name
          slug
          images {
            identity
          }
        }
      }
      item: getTag(options: $options) {
        name
        slug
        images {
          identity
        }
        tracks(pagination: $pagination) {
          pagination {
            total
            totalPages
            from
            size
            page
          }
          results {
            identity
            title
            slug
            duration
            created_at
            is_charting
            is_featured
            is_new_release
            brand_sponsored
            branded_content
            audio {
              identity
            }
            images {
              identity
            }
            artists {
              images {
                identity
              }
              name
              slug
              identity
            }
          }
        }
      }
    }
  `,
  {
    options: (variables: BrowseResultsProps) => ({
      variables,
      // fetchPolicy: 'network-only'
    }),
  }
)(BrowseResults);

export const RightsholderBrowseResults = graphql(
  gql`
    query($options: GetRightsholderInput!, $related: BrowseQueryInput!, $pagination: PaginationInput!) {
      related: browseRelease(options: $related) {
        results {
          name: title
          slug
          images {
            identity
          }
          artist {
            slug
          }
        }
      }
      item: getRightsholder(options: $options) {
        name
        slug
        description
        images {
          identity
        }
        tracks(pagination: $pagination) {
          pagination {
            total
            totalPages
            from
            size
            page
          }
          results {
            identity
            title
            slug
            duration
            created_at
            is_charting
            is_featured
            is_new_release
            brand_sponsored
            branded_content
            audio {
              identity
            }
            images {
              identity
            }
            artists {
              images {
                identity
              }
              name
              slug
              identity
            }
          }
        }
      }
    }
  `,
  {
    options: (variables: BrowseResultsProps) => ({
      variables,
      // fetchPolicy: 'network-only'
    }),
  }
)(BrowseResults);

export const FilterBrowseResults = graphql(
  gql`
    query($options: GetFilterInput!, $related: BrowseQueryInput!, $pagination: PaginationInput!) {
      related: browseFilter(options: $related) {
        results {
          name: title
          slug
          images {
            identity
          }
        }
      }
      item: getFilter(options: $options) {
        name: title
        slug
        images {
          identity
        }
        tracks(pagination: $pagination) {
          pagination {
            total
            totalPages
            from
            size
            page
          }
          results {
            identity
            title
            slug
            duration
            created_at
            is_charting
            is_featured
            is_new_release
            brand_sponsored
            branded_content
            audio {
              identity
            }
            images {
              identity
            }
            artists {
              images {
                identity
              }
              name
              slug
              identity
            }
          }
        }
      }
    }
  `,
  {
    options: (variables: BrowseResultsProps) => ({
      variables,
      // fetchPolicy: 'network-only'
    }),
  }
)(BrowseResults);
