import { ImgHTMLAttributes, SourceHTMLAttributes } from 'react';
import * as React from 'react';
import { isArray } from '../utilities/objects';
import { classes, style } from 'typestyle';
import { percent, px } from 'csx';
import { desktopContentWidth } from '../config';
import { parseImageUrl } from '../../../modules/image/model';

const styles = style({ display: 'block', maxWidth: px(desktopContentWidth), width: percent(100), height: 'auto' });

type ResponsiveImageProps = Omit<SourceHTMLAttributes<HTMLSourceElement>, 'src' | 'width' | 'height'> & {
  alt: ImgHTMLAttributes<HTMLImageElement>['alt'];
  src: string | string[];
  width?: number | number[];
  height?: number | number[];
};

export const ResponsiveImage: React.SFC<ResponsiveImageProps> = ({ src, width, height, alt, className, ...props }) => {
  const srcArray = isArray(src) ? src : [src];
  const widthArray = isArray(width) ? width : [width];
  const heightArray = isArray(height) ? height : [height];
  return (
    <picture className={classes(className, styles)} {...props}>
      {srcArray.map((srcItem, index, array) => {
        const props: SourceHTMLAttributes<HTMLSourceElement> = {
          width: widthArray[index],
          height: heightArray[index],
        };
        let srcSet = srcItem;
        try {
          const { baseUrl, ext } = parseImageUrl(srcItem);
          srcSet = `${baseUrl}-${widthArray[index]}x${heightArray[index]}.${ext}`;
        } catch {
          // Continue using the URL passed via Prismic
        }
        props.srcSet = srcSet;
        if (index < array.length - 1) {
          props.media = `(max-width: ${widthArray[index]}px)`;
        }
        return <source key={index} {...props} />;
      })}
      <img className={styles} src={src[0]} alt={alt} width={width[0]} height={height[0]} loading="lazy" />
    </picture>
  );
};
