import * as React from 'react';
import { classes, style } from 'typestyle';
import { TrackSchema } from '../../../types/schema';
import { em, percent, px } from 'csx';
import { mixinTextEllipsis, overlayLink } from '../../../theme/mixin';
import { UtmLink } from '../atoms/UtmLink';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { colorDisabledImage, colorNeutral, rebrand } from '../../../theme/color';
import { zLabel } from '../../../theme/z';
import { SubscriptionLabel } from '../atoms/SubscriptionLabel';
import { AnalyticsContextProvider } from '../utilities/AnalyticsContext';
import { CarouselImage } from '../atoms/CarouselImage';
import { transitionQuickEase } from '../../../theme/transition';
import { shadowElevation2 } from '../../../theme/shadow';
import { Tooltip } from '../atoms/Tooltip';
import { InfoOutline } from '../atoms/icons/InfoOutline';

const styles = {
  wrapper: style({
    position: 'relative',
    paddingBottom: px(66),
    margin: '0 8px',
    borderRadius: em(0.3),
    $nest: {
      '& .favourites-button': {
        position: 'absolute',
        top: px(8),
        right: px(8),
      },
      '& .play-button': {
        position: 'absolute',
        bottom: px(80),
        right: px(8),
      },
    },
  }),
  img: style({
    borderRadius: em(0.3),
    transition: `box-shadow ${transitionQuickEase}`,
    $nest: {
      ':hover > &': shadowElevation2,
    },
  }),
  trackInfo: style(
    {
      position: 'absolute',
      bottom: px(10),

      fontSize: px(12),
    },
    mediaUpToDesktop({
      width: 'calc(100% - 16px)',
    }),
    mediaDesktop({
      width: percent(100),
    })
  ),
  track: style({
    fontWeight: 600,
  }),
  trackLink: style(overlayLink, {
    color: 'inherit',
  }),
  trackLine: style(mixinTextEllipsis, { display: 'block', lineHeight: 'normal', color: colorNeutral.toString() }),
  overlayContent: style({ $nest: { '& > *': { zIndex: zLabel } } }),
  name: style(mixinTextEllipsis, {
    fontWeight: 700,
    fontSize: px(16),
    color: rebrand.contrast[50].toString(),
    display: 'block',
  }),
  type: style(mixinTextEllipsis, {
    fontSize: px(16),
    fontWeight: 400,
    color: rebrand.neutralOnLight[70].toString(),
  }),
  imageSearchPage: style(
    mediaUpToDesktop({
      height: px(150),
    }),
    {
      boxShadow: 'none',
      height: px(224),
    }
  ),
  trackUnavailableimage: style({
    position: 'relative',
    display: 'flex',
    $nest: {
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: colorDisabledImage.toString(),
      },
    },
  }),
  unavailableToolTipStyles: style({
    backgroundColor: rebrand.neutralOnDark[20].toHexString(),
    borderColor: rebrand.neutralOnDark[20].toHexString(),
    color: rebrand.contrast[50].toHexString(),
    borderRadius: px(4),
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
  }),
  info: style({
    position: 'absolute',
    top: '8px',
    zIndex: zLabel,
    right: '5px',
  }),
};

export type TrackTileProps = { className?: string; track?: TrackSchema; isSearchPage?: boolean };

export const TrackTile: React.FC<TrackTileProps> = ({ children, className, track, isSearchPage = false }) => {
  const artists = track ? track.artists.map((artist) => artist.name).join(', ') : null;
  if (track) {
    const trackIsavailable = track.is_available_in_territory === null ? true : track.is_available_in_territory;

    return (
      <AnalyticsContextProvider
        context={{ trackIdentity: track.identity, trackTitle: track.title, trackArtist: artists }}
      >
        <div className={classes(styles.wrapper, className)} data-test-track-carousel={track.identity}>
          <SubscriptionLabel included={track.is_stock_music} />
          <UtmLink
            {...(isSearchPage ? { 'data-test-top-solution-text-link': 'track' } : {})}
            href={`/music/artists/${track.artists[0].slug}/track/${track.slug}`}
            className={classes(styles.trackLink, !trackIsavailable && styles.trackUnavailableimage)}
          >
            <CarouselImage
              className={classes(styles.img, isSearchPage && styles.imageSearchPage)}
              alt={`Artwork for "${track.title}" by ${artists}`}
              identity={track.images.identity}
            />
          </UtmLink>
          <div className={styles.trackInfo}>
            <span className={classes(styles.track, styles.trackLine, isSearchPage && styles.name)}>{track.title}</span>
            <span className={isSearchPage ? styles.type : styles.trackLine}>{artists}</span>
          </div>
          <span className={styles.overlayContent}>
            {trackIsavailable ? (
              children
            ) : (
              <div className={styles.info}>
                <Tooltip
                  className={styles.unavailableToolTipStyles}
                  positions={['top']}
                  content={<span> Not available in your region</span>}
                >
                  <InfoOutline />
                </Tooltip>
              </div>
            )}
          </span>
        </div>
      </AnalyticsContextProvider>
    );
  } else {
    return <CarouselImage className={classes(styles.img, isSearchPage && styles.imageSearchPage)} />;
  }
};
