import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const Description: React.SFC<SVGIconProps> = ({ size = 20, isSquare, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={isSquare ? size : size * (16 / 20)} viewBox="0 0 16 20" {...props}>
    <path
      fill={color}
      d="M10 0H2C.9 0 .01.9.01 2L0 18c0 1.1.89 2 1.99 2H14c1.1 0 2-.9 2-2V6l-6-6Zm-.857 9v1.143H7.429v5.143c0 .948-.766 1.714-1.715 1.714A1.712 1.712 0 0 1 4 15.286c0-.949.766-1.715 1.714-1.715.2 0 .395.04.572.103V9h2.857ZM9 1.5 14.5 7H9V1.5Z"
    />
  </BaseSvg>
);
