import React from 'react';
import { Component, ComponentProps, deps, inject, observer } from '../../lib/component';
import { TrackSchema } from '../../types/schema';
import { PlayerController } from './controller';
import { PlayerModel } from './model';
import { AnalyticsContext } from '../../components/project-happy/utilities/AnalyticsContext';
import { AnyObject } from '../../components/project-happy/utilities/objects';

export interface WithPlayerCompatibleComponentProps {
  track: TrackSchema;
}

export type WithPlayerComponentProps = WithPlayerCompatibleComponentProps & {
  playerController: PlayerController;
  playerModel: PlayerModel;
  isCurrent: boolean;
  isCurrentPlaying: boolean;
  onPause: PlayerController['pauseAndTrack'];
  onPlay: PlayerController['playAndTrack'];
  onLoadImmediate: PlayerController['loadImmediateAndSkipAndTrack'];
};

export default function withPlayer<T extends WithPlayerComponentProps = WithPlayerComponentProps>(
  WrappedComponent: React.ComponentType<T>
) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  type PublicProps = Omit<T, keyof WithPlayerComponentProps> & WithPlayerCompatibleComponentProps;
  const ComponentWithPlayer: React.FunctionComponent<PublicProps> = inject(deps)(
    observer((props: PublicProps & ComponentProps) => {
      const playerController = props.controller.player;
      const playerModel = props.model.player;
      const { currentTrack, play } = playerModel;
      const { track } = props;

      const isCurrent = currentTrack && track.identity === currentTrack.identity;
      const isCurrentPlaying = play && isCurrent;

      return (
        <AnalyticsContext.Consumer>
          {(analyticsData) => {
            const fireAndTrack = (method: (...args) => void, event: 'play' | 'pause') => (...args) => {
              method(...args);
              props.controller.analytics.sendMixpanel(`User clicks ${event}`, {
                identity: playerModel.currentTrack.identity,
                title: playerModel.currentTrack.title,
                slug: playerModel.currentTrack.slug,
                ...analyticsData,
              });
            };
            return (
              <WrappedComponent
                playerController={playerController}
                playerModel={playerModel}
                isCurrent={isCurrent}
                isCurrentPlaying={isCurrentPlaying}
                onLoadImmediate={fireAndTrack(playerController.loadImmediateAndSkip, 'play')}
                onPlay={fireAndTrack(playerController.play, 'play')}
                onPause={fireAndTrack(playerController.pause, 'pause')}
                {...(props as T)}
              />
            );
          }}
        </AnalyticsContext.Consumer>
      );
    })
  );

  ComponentWithPlayer.displayName = `withPlayer(${displayName})`;

  return ComponentWithPlayer;
}
