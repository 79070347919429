import * as React from 'react';
import { Component, deps, inject, observer } from '../../lib/component';
import { PlayerModel } from '../../modules/player/model';
import { PlayerController } from '../../modules/player/controller';
import { PlayerBar as Player } from '../player-bar';
import { AddRemoveCompat } from '../compat/add-remove-compat';
import { FavouriteButtonCompat } from '../compat/favourite-button-compat';
import { ArtistSchema, TrackSchema } from '../../types/schema';
import { classes, style } from 'typestyle';
import { zPlayer } from '../../theme/z';
import { colorGunmetal } from '../../theme/color';
import { constantDrawerWidth, constantPlayerHeight } from '../../theme/constant';
import { centerJustified, horizontal } from 'csstips';
import { transitionQuickEase } from '../../theme/transition';
import { mediaDesktop, mediaTablet } from '../../theme/media';
import { important } from 'csx';
import { mediaUpToDesktop } from '../project-happy/utilities/mediaQueries';

export interface PlayerBarProps {
  className?: string;
  visible: boolean;
}

const playerBodyOffset = style({ marginBottom: constantPlayerHeight });

@inject(deps)
@observer
export class PlayerBar extends Component<PlayerBarProps> {
  state = { isPlayerExpanded: false };

  togglePlayerExpanded(): void {
    this.setState({
      isPlayerExpanded: !this.state.isPlayerExpanded,
    });
  }

  componentDidUpdate(prevProps: Readonly<PlayerBarProps>) {
    if (prevProps.visible !== this.props.visible && document) {
      const action = this.props.visible ? 'add' : 'remove';
      document.body.classList[action](playerBodyOffset);
    }
  }

  render() {
    const controller: PlayerController = this.props.controller.player;
    const model: PlayerModel = this.props.model.player;
    const { preloadImage, preloadWaveformSvg } = this.props.controller.image;
    const { es6 } = this.props.model.env;
    const { handleClickLink, handleClickSignIn } = this.props.controller.ui;

    const props = { AddRemove: AddRemoveCompat, Favourite: FavouriteButtonCompat };

    const className = classes(
      PlayerBar.styles.container,
      this.props.model.ui.showMenuDrawer && PlayerBar.styles.containerShunted,
      !this.props.visible && PlayerBar.styles.hidden,
      this.state.isPlayerExpanded ? PlayerBar.styles.expandedContainer : PlayerBar.styles.collapsedContainer
    );

    return (
      <Player
        analytics={this.props.controller.analytics}
        className={className}
        es6={es6}
        playerModel={model.player}
        onChangeVolume={controller.setVolume}
        onClickNext={controller.next}
        onClickPrevious={controller.previous}
        onClickPlay={controller.playAndTrack}
        onClickPause={controller.pauseAndTrack}
        onFinish={controller.stop}
        onMeta={controller.onMeta}
        preload={preloadImage}
        preloadWaveform={preloadWaveformSvg}
        onClickLink={handleClickLink}
        onClickSignIn={handleClickSignIn}
        loadAudioMeta={this.props.controller.api.audio.loadAudio}
        attachPlayListener={this.props.controller.player.attachPlayListener}
        isPlayerExpanded={this.state.isPlayerExpanded}
        handlePlayerExpanded={() => this.togglePlayerExpanded()}
        {...props}
      />
    );
  }

  static styles = {
    container: style({
      ...horizontal,
      ...centerJustified,
      zIndex: zPlayer,
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      background: colorGunmetal.toString(),
      opacity: 1,
      transition: `transform ${transitionQuickEase}, opacity ${transitionQuickEase}`,
      transform: `translate3d(0,0,0)`,
    }),
    collapsedContainer: style({
      height: constantPlayerHeight,
      transition: `height ${transitionQuickEase}`,
    }),
    expandedContainer: style(
      mediaUpToDesktop({ height: '120px', transition: `height ${transitionQuickEase}` }),
      mediaDesktop({
        height: '70px',
      })
    ),
    containerShunted: style(
      mediaDesktop({
        right: constantDrawerWidth,
      })
    ),
    hidden: style({
      transform: important(`translate3d(0, ${constantPlayerHeight}, 0)`),
      opacity: important(0) as any,
    }),
  };
}
