import * as React from 'react';
import { classes, cssRaw, style } from 'typestyle';
import { px } from 'csx';
import { HighlightText } from '../../../atoms/HighlightText';

const styles = style({
  textAlign: 'center',
  color: 'white',
  background: 'rgba(216, 216, 216, 0.3)',
  paddingTop: px(16),
  paddingBottom: px(16),
});

cssRaw(`
@supports (backdrop-filter: blur(10px) saturate(0.9)) {
  .${styles} {
    backdrop-filter: blur(10px) saturate(0.9);
  }
}
`);

export type FrostedBannerProps = React.Component['props'] & { className?: string };

export const FrostedBanner: React.SFC<FrostedBannerProps> = ({ children, className }) => (
  <div className={styles}>
    <span className="h4">{children}</span>
  </div>
);
