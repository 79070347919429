import * as React from 'react';
import { classes, cssRaw, cssRule, style } from 'typestyle';
import { px } from 'csx';
import { BackgroundProps, resolveBackgroundProps } from '../../utilities/props';
import {
  mediaUpToDesktop,
  mediaDesktop,
  mediaUpToLargeMobile,
  mediaLargeMobileOnly,
  mediaSmallTabletOnly,
  mediaUpToLargeTablet,
  mediaLargeTablet,
} from '../../utilities/mediaQueries';
import { GradientDirection } from '../../utilities/types';
import { shadowElevation2 } from '../../../../theme/shadow';
import { isArray } from '../../utilities/objects';

const styles = {
  wrapper: style(shadowElevation2, {
    borderRadius: px(8),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    height: px(288),
  }),
  wrapperTall: style({
    justifyContent: 'space-between',
  }),
};

cssRule(
  `.${styles.wrapper}.${styles.wrapperTall}`,
  mediaUpToLargeTablet({
    minHeight: px(288),
    height: 'calc(100vw - 32px)', // Aim for a 1:1 ratio with panel width
    maxHeight: px(488),
  }),
  mediaLargeTablet({
    height: px(488),
  })
);

export type BasePanelProps = React.Component['props'] &
  BackgroundProps & {
    className?: string;
  };

export const BasePanel: React.SFC<BasePanelProps> = ({ children, className, ...backgroundProps }) => {
  const backgroundStyles = resolveBackgroundProps(backgroundProps);
  const isTall =
    isArray(children) &&
    children.filter((child) => typeof child !== 'undefined' && child !== false && child !== null).length > 1;
  return (
    <div className={classes(styles.wrapper, isTall && styles.wrapperTall, style(backgroundStyles), className)}>
      {children}
    </div>
  );
};
