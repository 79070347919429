import isNil from 'lodash/isNil';
import { TrackPageModel } from './model';
import { action, runInAction } from 'mobx';
import { RedirectFunction, RouterState } from 'react-router';
import { BugsnagController } from '../../bugsnag/controller';
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import { APIController } from '../../api/controller';
import { ContentModel } from '../../content/model';
import { ImageController } from '../../image/controller';
import { stripQueryString } from '../../../lib/string';
import { RouterModel } from '../../router/model';
import { AnalyticsController } from '../../analytics/controller';
import { isEmpty } from '../../../components/project-happy/utilities/objects';
import { AuthModel } from '../../auth/model';
import { browserRegion } from '../../../components/project-happy/utilities/browserRegion';

export class TrackPageController {
  constructor(
    private model: TrackPageModel,
    private bugsnag: BugsnagController,
    private env: EnvModel,
    private api: APIController,
    private ui: UIController,
    private content: ContentModel,
    private image: ImageController,
    private router: RouterModel,
    private analytics: AnalyticsController,
    private auth: AuthModel
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    runInAction(() => {
      this.model.artistSlug = stripQueryString(nextState.params['artist']);
      this.model.trackSlug = stripQueryString(nextState.params['track']);
      this.model.loading = true;
    });
    try {
      const [resp] = await Promise.all([
        this.api.artist.getTrack(
          this.model.artistSlug,
          this.model.trackSlug,
          [
            'images',
            'releases',
            'releases.images',
            'artists',
            'artists.images',
            'audio',
            'tags',
            'rightsholders',
            'mixes',
          ],
          this.auth.user ? this.auth.user.country : null
        ),
        this.env.ready,
      ]);

      const track = resp.data;
      const artists = !isEmpty(track) && !isNil(track.artists) ? track.artists : [];
      const [artist] = artists.length ? artists : null;
      const artistName = artist ? artist.name : this.model.artistSlug;

      this.analytics.sendTrackPageVisit(track);

      runInAction(() => {
        const breadcrumbs = [
          {
            path: '/browse/artists',
            label: 'Artists',
          },
          {
            path: '/browse/artists/a',
            label: this.model.artistSlug[0].toUpperCase(),
          },
          {
            path: `/music/artists/${this.model.artistSlug}`,
            label: artistName,
          },
          {
            path: `/music/artists/${this.model.artistSlug}/track/${this.model.trackSlug}`,
            label: track.title,
          },
        ];

        this.ui.setBreadcrumbs(breadcrumbs);
      });

      await this.ui.setSEO('/music/artists/track', {
        track: track.title,
        artist: artistName,
      });

      const { genres } = this.content;

      runInAction(() => {
        this.model.track = track;
        this.model.artists = artists;
        this.model.artist = artist;
        this.model.pageBody = this.content.strapTrack;
        this.model.genres =
          track.tags && track.tags.hookd_genre && track.tags.hookd_genre.length !== 0
            ? track.tags.hookd_genre
                .filter((genre) => genres.find(({ slug }) => genre.slug === slug))
                .map((genre) => ({ slug: genre.slug, label: genre.name }))
            : [];

        this.ui.preloadPlayer(this.model.track);
      });
    } catch (e) {
      if (this.model.artistSlug) {
        this.router.replace(`/music/artists/${this.model.artistSlug}`);
      } else {
        this.router.replace(`/404`);
      }

      this.bugsnag.notifyException(e);
    } finally {
      runInAction(() => {
        this.model.loading = false;
      });
    }

    return;
  };
}
