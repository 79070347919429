import {
  PrismicComplexBackgroundProps,
  PrismicHeadedCarouselProps,
  PrismicManualLinkListProps,
  PrismicSizeConfigurableCarouselProps,
  PrismicSlice,
  PrismicTileShape,
  SlicePageContext,
} from '../utilities/types';
import * as React from 'react';
import { LinkCarouselSlice } from '../organisms/slices/LinkCarouselSlice';
import { AnyObject } from '../utilities/objects';

export type PrismicLinkCarouselPayload = PrismicHeadedCarouselProps &
  PrismicSizeConfigurableCarouselProps &
  PrismicManualLinkListProps &
  PrismicSlice<{ tile_size: boolean }>; // This is a legacy option previously available in the Prismic component config

export type PrismicLinkCarouselProps = {
  payload: PrismicLinkCarouselPayload;
  context: SlicePageContext;
};
export const PrismicLinkCarousel: React.SFC<PrismicLinkCarouselProps> = ({
  payload: {
    primary: { carousel_heading, tile_shape, tile_size, action_href },
    items,
  },
  context: { carousels = {} },
}) => {
  // We can use tile_size for backwards compatibility
  let tileShape = tile_shape;
  if (typeof tileShape !== 'string') {
    tileShape = tile_size ? PrismicTileShape.LARGE_RECTANGLE : PrismicTileShape.SMALL;
  }
  const perPage = tileShape !== PrismicTileShape.SMALL ? 3 : 6;
  const square = tileShape !== PrismicTileShape.LARGE_RECTANGLE;
  return (
    <LinkCarouselSlice
      heading={carousel_heading}
      square={square}
      actionHref={action_href}
      links={items.map(
        ({
          tile_heading,
          link_pathname,
          background_image: { url },
          background_colour,
          background_gradient_colour_end,
          background_gradient_colour_direction,
        }) => ({
          heading: tile_heading,
          href: link_pathname,
          src: url,
          gradient: {
            from: background_colour,
            to: background_gradient_colour_end,
            direction: background_gradient_colour_direction,
          },
        })
      )}
      indicatorAlignment={carousels.forceIndicatorPosition}
      perPage={perPage}
    />
  );
};
