import { px } from 'csx';
import React from 'react';
import { classes, style } from 'typestyle';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import UserAvatar from '../atoms/UserAvatar';
import { rebrand } from '../../../theme/color';
import { spacing } from '../../../theme/spacing';
import { borderRadius } from '../../../theme/border';
import { ChannelSchema } from '../../../types/schema';
import { shadowElevation6 } from '../../../theme/shadow';
import { mixinTextEllipsis } from '../../../theme/mixin';
import { mediaDesktop } from '../utilities/mediaQueries';

interface ChannelInfoProps extends ComponentProps {
  className?: string;
  channelOverride?: ChannelSchema;
}

const styles = {
  channelInfo: style({
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    maxWidth: px(550),
    textAlign: 'left',
    margin: `0 auto ${spacing.XLARGE}`,
    backgroundColor: rebrand.contrast[10].toString(),
    padding: spacing.SMALL,
    borderRadius: borderRadius.MEDIUM,
    ...shadowElevation6,
  }),
  avatarName: style({
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
  }),
  avatar: style({
    marginRight: px(8),
    width: '40px',
    height: '40px',
  }),
  channelName: style(mixinTextEllipsis, { fontSize: '24px', margin: 0 }),
  avv: style(
    {
      color: rebrand.contrast[40].toString(),
      margin: `0 0 0 ${spacing.DEFAULT}`,
      fontSize: px(12),
      textAlign: 'right',
    },
    mediaDesktop({
      marginLeft: spacing.XLARGE,
    })
  ),
};

export const ChannelInfo = inject(deps)(
  observer(
    ({
      model: {
        user: { channel: currentChannel },
        pricing: { subscribedRates },
      },
      className,
      channelOverride = null,
    }: ChannelInfoProps) => {
      const channel = channelOverride ? channelOverride : currentChannel;

      if (!channel) return null;

      let averageVideoViews = '';

      // Get the additional licence price and AVV from the subscribed ratecard
      if (channel && channel.ratecard && subscribedRates) {
        const { currency, viewership: channelViewership } = channel.ratecard;

        const subscribedRatecard = subscribedRates.currency[currency].find(
          ({ viewership }) => viewership === channelViewership
        );

        averageVideoViews = subscribedRatecard.name.split(' ')[0];
      }

      return (
        <div className={classes(styles.channelInfo, className && className)}>
          <div className={styles.avatarName}>
            <UserAvatar className={styles.avatar} channelOverride={channelOverride} />
            <h3 className={styles.channelName}>{channel.name}</h3>
          </div>
          {averageVideoViews && <p className={styles.avv}>{averageVideoViews} average video views</p>}
        </div>
      );
    }
  )
);
