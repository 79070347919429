import * as React from 'react';
import { classes, cssRaw, cssRule, style } from 'typestyle';
import { px } from 'csx';
import { colorContrast, colorPrimary30 } from '../../../theme/color';
import { headingTitle } from '../utilities/font';
import { mediaLargeTablet, mediaUpToLargeTablet } from '../utilities/mediaQueries';

const styles = style(
  {
    color: 'white',
    textAlign: 'center',
    fontWeight: 600,
    position: 'relative',
    $nest: {
      '&::after': {
        content: '""',
        width: 0,
        height: 0,
        border: '16px solid transparent',
        borderBottomWidth: 0,
        position: 'absolute',
        bottom: px(-16),
        left: 'calc(50% - 16px)',
      },
    },
  },
  mediaUpToLargeTablet({
    padding: '22px 0',
  }),
  mediaLargeTablet({
    padding: '44px 0',
  })
);

cssRule(`.${styles} h2`, headingTitle, {
  margin: 0,
  padding: 0,
});

export type GroupHeadingProps = { className: string };

export const GroupHeading: React.SFC<GroupHeadingProps> = ({ children, className }) => (
  <div className={classes('with-gradient', styles, className)}>{children}</div>
);
