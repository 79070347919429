import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const MagnifyingGlass: React.FC<SVGIconProps> = ({ size = 16, color = 'currentColor', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 18 18" {...props}>
    <path
      d="M12.5 11h-.79l-.28-.27a6.5 6.5 0 1 0-.7.7l.27.28v.79l5 4.99L17.49 16l-4.99-5Zm-6 0a4.5 4.5 0 1 1-.01-8.99A4.5 4.5 0 0 1 6.5 11Z"
      fill={color}
      fillRule="evenodd"
    />
  </BaseSvg>
);
