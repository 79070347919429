import * as React from 'react';
import { classes, style } from 'typestyle';
import { centerCenter, vertical } from 'csstips';
import { percent, px } from 'csx';
import { colorGunmetal, colorWhite } from '../../theme/color';
import { SVGIcon } from '../svg-icon';
import { mediaCoarsePointer, mediaMobileOnly, mediaTablet } from '../../theme/media';
import { FavouritePrompt } from '../project-happy/atoms/icons/FavouritePrompt';

const styles = {
  wrapper: style(centerCenter, vertical, {
    position: 'absolute',
    top: 0,
    left: 0,
    width: percent(100),
    height: percent(100),
    cursor: 'pointer',
    padding: percent(10),
  }),
  wrapperFill: style(
    mediaMobileOnly({
      padding: '32% 15%',
    }),
    mediaTablet({
      padding: percent(10),
    })
  ),
  overlay: style(vertical, centerCenter, {
    backgroundColor: colorGunmetal.fade(0.5).toString(),
    borderRadius: px(16),
    color: colorWhite.toString(),
    width: percent(100),
    height: percent(100),
  }),
  text: style({ fontWeight: 600, textAlign: 'center' }, mediaMobileOnly({ width: percent(75) })),
};

export interface FavouriteCtaProps {
  onClickLink?: (event: React.MouseEvent<any>) => void;
  ctaText?: string;
  className?: string;
}

export const FavouriteCTA: React.SFC<FavouriteCtaProps> = ({ onClickLink, ctaText, className, children }) => (
  <div
    className={classes(styles.wrapper, (typeof children === 'undefined' || children === null) && styles.wrapperFill)}
    onClick={onClickLink}
  >
    <div className={classes(styles.overlay, className)}>
      <FavouritePrompt color="white" />
      <span className={styles.text}>{ctaText || 'Start building your collection'}</span>
    </div>
    {children}
  </div>
);
