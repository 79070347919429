import * as React from 'react';
import { formatCurrency } from '../../lib/currency';
import { CouponSchema, OrderSchema } from '../../types/schema';
import { toDateString } from '../project-happy/utilities/date';
import { Voucher } from '../project-happy/molecules/Voucher';

type CouponType =
  | 'internal'
  | 'support_coupon'
  | 'first_use'
  | 'second_use'
  | 'referral_use'
  | 'golden_ticket'
  | 'new_user';

interface CouponTypeDetails {
  label: string;
  description: string;
}

type Props = {
  coupon: CouponSchema;
};

const couponDetails: { [key in CouponType]: CouponTypeDetails } = {
  first_use: {
    label: 'First Track Free',
    description: 'Your first license for your channel is absolutely on us',
  },
  second_use: {
    label: 'First Use Discount',
    description: 'Thanks for using Lickd!',
  },
  golden_ticket: {
    label: 'Golden Ticket',
    description: 'Eight free tracks and insider access',
  },
  internal: {
    label: 'Inside Access',
    description: 'Unlimited usage - Lickd staff',
  },
  referral_use: {
    label: 'Referral',
    description: 'A track discount earned by referring a friend!',
  },
  support_coupon: {
    label: 'Support Coupon',
    description: 'Hopefully this patches things up',
  },
  new_user: {
    label: '',
    description: '',
  },
};

export default class UserCoupon extends React.Component<Props, any> {
  render() {
    const { coupon } = this.props;

    let discount = '';
    if (coupon.value) {
      discount = formatCurrency(coupon.value, coupon.currency);
    } else if (coupon.discount) {
      discount = `${coupon.discount || 0}% off order`;
    } else if (coupon.track_discount) {
      discount = `${coupon.track_discount || 0}% off track`;
    }

    const timestamp = coupon.expires ? coupon.expires.timestamp * 1000 : 0;
    const hasExpired = coupon.expires && timestamp < Date.now();
    const remaining = Math.max(coupon.limit - (coupon.order ? coupon.order.length : 0), 0);
    const usages = hasExpired ? 'EXPIRED' : remaining;
    const expiry = coupon.expires ? toDateString(new Date(timestamp)) : 'Does not expire';

    const details = couponDetails[coupon.type] || null;

    return (
      <Voucher
        type="coupon"
        title={details ? details.label : null}
        expiry={expiry}
        value={discount}
        code={coupon.coupon}
        usages={usages}
      />
    );
  }
}
