import * as React from 'react';
import {classes, style} from 'typestyle';
import {color, percent, viewHeight, viewWidth} from 'csx';
import {
  colorBlack,
  colorBrand,
  colorContrast,
  colorGradientHeroCenter,
  colorGradientHeroMid, colorGradientHeroOuter,
  colorGradientTop, colorGunmetal
} from '../theme/color';

type Props = {
  className?: string;
  reverse?: boolean;
  gradientClassName?: string;
}

type State = {}

export default class GradientCover extends React.Component<Props, State> {
  private $gradient?: HTMLDivElement;
  private _mounted: boolean = false;

  render() {
    const {
      className,
      reverse,
      gradientClassName,
    } = this.props;

    return (
      <div className={classes(className, GradientCover.styles.container)}>
        <div ref={el => this.$gradient = el} className={classes(
          GradientCover.styles.gradient,
          gradientClassName
            ? gradientClassName
            : reverse
              ? GradientCover.styles.gradientReverse
              : GradientCover.styles.gradientBrand,
        )}/>
      </div>
    )
  }

  componentDidMount() {
    this._mounted = true;
    window.addEventListener('mousemove', this.moveGradient);
  }

  componentWillUnmount() {
    this._mounted = false;
    window.removeEventListener('mousemove', this.moveGradient);
  }

  moveGradient = (event: MouseEvent) => {
    if (!this._mounted)
      return;

    const x = 0.5 * (50 - (100 * event.clientX / window.innerWidth));
    const y = 0.5 * (50 - (100 * event.clientY / window.innerHeight));

    this.$gradient!.style.transform = `translate3d(${x}vw, ${y}vh, 0)`;
  };

  static styles = {
    container: style({
      overflow: 'hidden',
      pointerEvents: 'none'
    }),
    gradient: style({
      position: 'absolute',
      top: viewHeight(-33),
      right: viewWidth(-33),
      bottom: viewHeight(-33),
      left: viewWidth(-33),
      pointerEvents: 'none',
      transformOrigin: 'center',
      transition: 'transform 50ms ease-in',
    }),
    gradientBrand: style({
      background: colorGunmetal.toString(),
    }),
    gradientReverse: style({
      background: colorGunmetal.toString(),
    })
  }
}