import { cssRaw } from 'typestyle';
// Regular
import otfRegular from './Flood-Regular.otf';
import woffRegular from './Flood-Regular.woff';
import woff2Regular from './Flood-Regular.woff2';

cssRaw(`@font-face {
    font-family: 'Flood';
    src: url('${woffRegular}');
    src: local('Flood'),
    url('${woff2Regular}') format('woff2'),
    url('${otfRegular}') format('opentype'),
    url('${woffRegular}') format('woff'),
    font-weight: normal;
    font-style: normal;
}`);
