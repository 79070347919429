import { EnvModel } from '../env/model';
import { action } from 'mobx';
import { AuthModel } from '../auth/model';
import { StorageModel } from '../storage/model';
import { RequestModel } from './model';
import { APIError } from '../api/types';
import { getUid } from '../../components/project-happy/utilities/string';
import { Logger } from 'winston';
import Cookies from 'js-cookie';

export class RequestController {
  constructor(
    private env: EnvModel,
    private auth: AuthModel,
    private storage: StorageModel,
    private model: RequestModel,
    private logger: Logger
  ) {}

  url = (path: string) => {
    const baseUrl = path[0] === '/' ? this.env.apiUri + path : path;
    const gaData = this.getGAClientSessionIDs();

    if (!gaData) {
      return baseUrl; // GA data is not available, return the base URL
    }

    const hasQueryParams = baseUrl.includes('?');
    const gaParams = `googleClientId=${encodeURIComponent(gaData.gaClientId)}&googleSessionId=${encodeURIComponent(
      gaData.sessionId
    )}`;

    return baseUrl + (hasQueryParams ? '&' : '?') + gaParams;
  };

  @action
  get = async (options: RequestOptions) => this.fetch('GET', options);

  @action
  patch = async (options: RequestOptions) => this.fetch('PATCH', options);

  @action
  post = async (options: RequestOptions) => this.fetch('POST', options);

  @action
  put = async (options: RequestOptions) => this.fetch('PUT', options);

  @action
  del = async (options: RequestOptions) => this.fetch('DELETE', options);

  private fetch = async (method: string, options: RequestOptions): Promise<Response> => {
    const fetchRequest = {
      id: getUid(),
      method,
      url: this.url(options.url),
      options: this.options({ ...options, mode: options.mode }, method)
    };

    let permitted = true;
    let res;

    const request = (): Promise<Response> =>
      fetch(fetchRequest.url, fetchRequest.options).catch((err) => {
        // Fetch API throws a TypeError for network problems, wrap it in an APIError so it's easy to differentiate
        let message = 'An unknown error occurred';
        if (err instanceof Error) {
          message = err.message;
        }

        throw new APIError(-1, `An error occurred while fetching: ${message}`);
      });



    this.logger.info('Fetch request - start', { fetchRequest });

    try {
      res = await request();

      this.logger.info('Fetch request - success', {
        fetchRequest,
        fetchResponse: {
          status: res.status,
          statusText: res.statusText,
        },
      });
    } catch (error) {
      this.logger.error('Fetch request - error', { fetchRequest, error });

      throw error; // Rethrow the error to avoid returning undefined
    } finally {
      this.logger.info('Fetch request - finished', { fetchRequest });
    }

    if (res.status === 401) {
      this.model.refresh = this.refresh();
      permitted = await this.model.refresh;

      if (permitted) {
        res = await request();
      } else {
        this.auth.mustReAuth = true;
      }
    }

    return res;
  };

  private refresh = async () => {
    if (!this.auth.token) return false;
    const url = this.env.baseUrl + '/auth/refresh';
    const req = fetch(url, this.options({}, 'get'));

    const res = await req;

    const auth = res.headers.get('Authorization');
    if (!auth) return false;

    const token = auth.match(RequestController.BearerRegExp)[1];
    if (!token) return false;

    this.storage.setItem('token', token);

    return res.status >= 200 && res.status < 400;
  };

  private options = (options: RequestOptions, method: string): RequestOptions & RequestInit => ({
    ...options,
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'User-Agent': this.env.userAgent,
      ...(this.auth.token ? { Authorization: `Bearer ${this.auth.token}` } : {}),
      ...(options.headers ? options.headers : {}),
    },
    mode: 'cors' as RequestMode,
  });

  getGAClientSessionIDs = () => {
    const isProduction = this.env.appEnv === 'prod';

    const gaClientIdentity = Cookies.get('_ga');

    // Conditionally set the cookie key based on the environment
    const gaSessionCookieKey = isProduction ? '_ga_NZ3QM0Z4PY' : '_ga_FQTQJWB049';
    const gaSessionId = Cookies.get(gaSessionCookieKey);

    let sessionId = null;

    if (gaSessionId) {
      const parts = gaSessionId.split('.');
      sessionId = parts.length > 2 ? parts[2] : null; // Check that the array has enough parts to have an index 2
    }

    // Handle scenarios based on cookie availability
    if (!gaClientIdentity && !gaSessionId) {
      // Both cookies are missing
      return { gaClientId: null, sessionId: null };
    } else if (!gaClientIdentity && gaSessionId) {
      // Missing GA client ID but session ID is available
      return { gaClientId: null, sessionId };
    } else {
      // GA client ID is available, session ID may or may not be
      return { gaClientId: gaClientIdentity, sessionId };
    }
  };

  static BearerRegExp = /Bearer (.+)/;

  static RefreshWithin = 5 * 60;
}

export interface RequestOptions {
  url?: string;
  body?: any;
  headers?: { [key: string]: string };
  mode?: RequestMode;
  signal?: AbortSignal;
}
