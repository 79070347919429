import { style } from 'typestyle';
import { mediaDesktop } from './mediaQueries';
import { percent } from 'csx';
import EventBus from './EventBus';

export enum CarouselEvents {
  PAGE_CHANGED = 'pageChanged',
  POSITION_START = 'positionStart',
  POSITION_END = 'positionEnd',
  SCROLL_NEXT = 'scrollNext',
  SCROLL_PREVIOUS = 'scrollPrevious',
}

export type EventCarouselProps = { events: EventBus<CarouselEvents> };

export const perPageWidths = [
  { width: percent(100) },
  { width: percent(50) },
  { width: percent(33.333333333333336) },
  { width: percent(25) },
  { width: percent(20) },
  { width: percent(16.666666666666668) },
];

export const perPageStyles = [
  style(perPageWidths[0]),
  style(perPageWidths[1]),
  style(perPageWidths[2]),
  style(perPageWidths[3]),
  style(perPageWidths[4]),
  style(perPageWidths[5]),
];

export const desktopOnlyPerPageStyles = [
  style(mediaDesktop({ width: percent(100) })),
  style(mediaDesktop({ width: percent(50) })),
  style(mediaDesktop({ width: percent(33.333333333333336) })),
  style(mediaDesktop({ width: percent(25) })),
  style(mediaDesktop({ width: percent(20) })),
  style(mediaDesktop({ width: percent(16.666666666666668) })),
];
