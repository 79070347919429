import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalChildStyle } from '../modal';
import { classes, style } from 'typestyle';
import { em, percent, px, rgba } from 'csx';
import overlayStyles, { ScrollableOverlayCloseButtonStyle } from '../../styles/scrollable-overlay';
import { ButtonPill } from '../button-pill';
import { center, centerCenter, endJustified, horizontal, horizontallySpaced } from 'csstips';
import { ChannelAvatar } from '../channel-avatar';
import { ChannelSchema } from '../../types/schema';
import GenericError from '../generic-error';
import { SVGIcon } from '../svg-icon';
import {
  colorBlack,
  colorBlueGrey,
  colorBrand,
  colorDarkGreen,
  colorLightBlueGrey,
  colorLightGrey,
  colorGunmetal,
  colorWhite,
} from '../../theme/color';
import { Loading } from '../loading/loading';
import GoogleButton from '../user/google-button';
import { fontTitles } from '../../theme/font';
import ChannelListing from './channel-listing';

export interface ChannelSelectModalV2Model {
  channels: Array<ChannelSchema>;
  channel: ChannelSchema;
  activating: ChannelSchema;
  show: boolean;
  loading: boolean;
  selecting: boolean;
  addUrl: string;
}

export interface ChannelSelectModalV2Props {
  model: ChannelSelectModalV2Model;
  onRequestClose(): void;
  onSelectChannel(channel: ChannelSchema): void;
  onAdd(): void;
  i18n?: {
    title: string;
    active: string;
    loading: string;
    activating: string;
    choose: string;
    close: string;
    add: string;
  };
}

@observer
export class ChannelSelectModalV2 extends React.Component<ChannelSelectModalV2Props, any> {
  render() {
    const { onRequestClose, onAdd, model, i18n = ChannelSelectModalV2.i18n, onSelectChannel } = this.props;
    const { loading, selecting, activating, addUrl, show } = model;

    const header = (
      <header className={ChannelSelectModalV2.styles.header}>
        <h3>Change channel</h3>
        <button onClick={onRequestClose} data-test-close-modal className={ChannelSelectModalV2.styles.button}>
          <SVGIcon.Cross size={16} color="black" />
        </button>
      </header>
    );

    if (loading)
      return (
        <Modal show={show} onRequestClose={onRequestClose}>
          <section className={ChannelSelectModalV2.styles.container}>
            {header}
            <Loading text="Loading channels..." />
          </section>
        </Modal>
      );

    return (
      <Modal show={show} onRequestClose={selecting ? void 0 : onRequestClose}>
        <section data-test-channel-select-modal className={ChannelSelectModalV2.styles.container}>
          {header}
          <main className={ChannelSelectModalV2.styles.main}>
            <ChannelListing channels={model.channels} selectedChannel={model.channel} />
            {!loading && !model.channels.length && <GenericError />}
          </main>
          <footer className={ChannelSelectModalV2.styles.footer}>
            <h4 className={ChannelSelectModalV2.styles.footerTitle}>Need to add a channel?</h4>
            <GoogleButton
              href={model.addUrl}
              text="Connect with Google"
              className={ChannelSelectModalV2.styles.addButton}
            />
            <div className={ChannelSelectModalV2.styles.authority}>
              <SVGIcon.YouTubeCertified color={colorBlack.toHexString()} />
            </div>
            <a className={ChannelSelectModalV2.styles.legal} href="/terms-and-conditions">
              Terms and conditions
            </a>
            <a className={ChannelSelectModalV2.styles.legal} href="/privacy-policy">
              Privacy policy
            </a>
          </footer>
        </section>
      </Modal>
    );
  }

  handleAdd = (event: React.MouseEvent<any>) => {
    this.props.onRequestClose();
    this.props.onAdd();
  };

  static i18n = {
    title: 'Switch channel',
    active: 'Current',
    choose: 'Choose',
    loading: 'Loading channels...',
    activating: 'Activating...',
    close: 'Cancel',
    add: 'Add a channel',
  };

  static styles = {
    container: style({
      ...ModalChildStyle,
      width: px(288),
      padding: 0,
      backgroundColor: colorWhite.toHexString(),
    }),
    header: style({
      position: 'relative',
      textAlign: 'center',
      $nest: {
        '& h3': {
          fontSize: px(24),
        },
      },
    }),
    button: style(ScrollableOverlayCloseButtonStyle),
    main: style({
      // padding: ModalChildStyle.padding,
    }),
    buttons: style({
      ...horizontal,
      ...horizontallySpaced(10),
      ...endJustified,
      margin: '30px 0 0 0',
    }),
    footer: style({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: px(16),
      $nest: {
        '& a': {
          color: colorGunmetal.toHexString(),
        },
      },
    }),
    footerTitle: style({
      fontSize: px(20),
      margin: '16px 0 8px',
    }),
    addButton: style({
      fontSize: px(13),
    }),
    authority: style({
      ...centerCenter,
      ...horizontal,
      ...horizontallySpaced(30),
      margin: '16px 0',
      $nest: {
        '& path': {
          fill: colorGunmetal.toString(),
        },
        '& path:nth-child(4)': {
          fill: colorWhite.toString(),
        },
      },
    }),
    legal: style({
      fontSize: px(12),
      textDecoration: 'underline',
    }),
  };
}
