import React from 'react';
import { CarouselEvents } from '../../../utilities/carousel';
import { CircleButton } from '../../button/CircleButton';
import { ButtonProminence } from '../../button/buttonStyles';
import { AbstractButtonController } from './AbstractButtonController';
import { ChevronRight } from '../../icons/ChevronRight';

export class NextPageButton extends AbstractButtonController {
  constructor(props) {
    super(props);
    this.disabledEvent = CarouselEvents.POSITION_END;
    this.actionEvent = CarouselEvents.SCROLL_NEXT;
    this.addListeners();
  }

  render() {
    return (
      <CircleButton
        prominence={ButtonProminence.SECONDARY}
        disabled={this.state.disabled}
        onClick={this.handle}
        {...this.props}
      >
        <ChevronRight width={5} color="white" />
      </CircleButton>
    );
  }
}
