import { PrismicRichText, PrismicSimpleBackgroundProps, PrismicSlice, PrismicURL } from '../utilities/types';
import { isEmpty } from '../utilities/objects';
import * as React from 'react';
import { renderPrismicRichTextArray } from '../utilities/props';
import { DashboardTeaserMasthead } from '../molecules/mastheads/DashboardTeaserMasthead';

export type PrismicDashboardTeaserMastheadPayload = PrismicSimpleBackgroundProps &
  PrismicSlice<{
    heading: PrismicRichText;
    text: PrismicRichText;
    cta: string;
    url: PrismicURL;
  }>;

export type PrismicDashboardTeaserMastheadProps = {
  payload: PrismicDashboardTeaserMastheadPayload;
};
export const PrismicDashboardTeaserMasthead: React.SFC<PrismicDashboardTeaserMastheadProps> = ({
  payload: {
    primary: { background_image, background_colour, heading, text, cta, url },
  },
}) => {
  const src = !isEmpty(background_image) && background_image.url;
  const props = {
    src,
    colour: background_colour,
    heading: renderPrismicRichTextArray(heading, 'fragment'),
    text: renderPrismicRichTextArray(text, 'span'),
    cta,
    href: !isEmpty(url) && url.url,
  };
  return <DashboardTeaserMasthead {...props} />;
};
