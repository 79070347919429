import { percent, px } from 'csx';
import React from 'react';
import { classes, style } from 'typestyle';
import { ComponentProps, deps, inject, observer } from '../../../lib/component';
import UserAvatar from '../atoms/UserAvatar';
import { rebrand } from '../../../theme/color';
import { spacing } from '../../../theme/spacing';
import { borderRadius } from '../../../theme/border';
import { ChannelSchema } from '../../../types/schema';
import { center, centerCenter, flexRoot } from 'csstips';
import { isEmpty } from '../utilities/objects';
import { mixinTextEllipsis } from '../../../theme/mixin';
import { loadingText } from '../utilities/font';
import { mediaDesktop, mediaSmallTablet } from '../utilities/mediaQueries';
import { Button } from '../atoms/button/Button';
import { ButtonProminence, ButtonSize } from '../atoms/button/buttonStyles';

interface ChannelBannerProps extends ComponentProps {
  className?: string;
  channelOverride?: ChannelSchema;
  children?: React.ReactNode;
  hideChangeBtn?: boolean;
}

const styles = {
  banner: style(flexRoot, centerCenter, {
    gap: spacing.DEFAULT,
    width: '100%',
    backgroundColor: rebrand.neutralOnDark[10].toString(),
    padding: `${spacing.SMALL} ${spacing.DEFAULT}`,
    borderRadius: borderRadius.MEDIUM,
  }),
  channelInfo: style(flexRoot, center, mediaSmallTablet({ maxWidth: percent(50) })),
  avatar: style({
    marginRight: px(8),
    width: '24px',
    height: '24px',
  }),
  channelName: style(mixinTextEllipsis, {
    fontSize: '24px',
    lineHeight: 1,
    margin: 0,
    $nest: {
      '&.loading': loadingText,
      '&.channel-missing': { color: rebrand.contrast[30].toString() },
    },
  }),
  changeChannelBtn: style(
    {
      marginLeft: 'auto',
    },
    mediaDesktop({ display: 'none' })
  ),
};

export const ChannelBanner = inject(deps)(
  observer(
    ({
      model: {
        user: { loading, loadingAccounts, channel: currentChannel, channels },
      },
      controller: {
        modal: { showChannelSelectModal },
      },
      className,
      channelOverride = null,
      children,
      hideChangeBtn = false,
    }: ChannelBannerProps) => {
      const channel = channelOverride ? channelOverride : currentChannel;

      return (
        <div className={classes(styles.banner, className)}>
          <div className={styles.channelInfo}>
            <UserAvatar className={styles.avatar} channelOverride={channelOverride} />
            <h3 className={styles.channelName}>
              {loadingAccounts || loading ? 'Loading...' : isEmpty(channel) ? 'No channel added' : channel.name}
            </h3>
          </div>

          {children}

          {!hideChangeBtn && !isEmpty(channel) && (
            <Button
              className={styles.changeChannelBtn}
              prominence={ButtonProminence.SECONDARY}
              size={ButtonSize.SMALL}
              onClick={(e) => {
                e.stopPropagation();
                showChannelSelectModal();
              }}
              data-test-change-channel-button
            >
              Change
            </Button>
          )}
        </div>
      );
    }
  )
);
