import * as React from 'react';
import { InputHTMLAttributes } from 'react';
import { style } from 'typestyle';
import { RadioInput } from './RadioInput';
import { colorGunmetal } from '../../../../theme/color';

const styles = {
  wrapper: style({
    background: 'white',
    borderColor: colorGunmetal.toHexString(),
  }),
  indicator: style({
    background: 'transparent',
    $nest: {
      ':checked + &': {
        background: colorGunmetal.toHexString(),
      },
    },
  }),
};

export type RadioInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;
export const DarkRadioInput: React.SFC<RadioInputProps> = (props) => (
  <RadioInput wrapperClassName={styles.wrapper} indicatorClassName={styles.indicator} {...props} />
);
