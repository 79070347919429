import * as React from 'react';
import { Component, deps, inject, observer, ComponentProps } from '../../../lib/component';
import { Loading } from '../../loading/loading';
import { classes, media, style } from 'typestyle';
import { important, percent, px } from 'csx';
import { constantHeaderHeight } from '../../../theme/constant';
import {
  constantHeaderWithSearchHeight,
  constantHeaderWithSearchHeightScrollOrMobile,
  desktopBreakpoint,
  desktopContentWidth,
  headerFullHeight,
} from '../config';
import SearchField from '../molecules/Search/SearchField';
import { FilterLayout } from '../molecules/Search/FilterLayout';
import MainWrapper from '../../main-wrapper';
import { transitionQuickEase } from '../../../theme/transition';
import { mediaDesktop, mediaLargeDesktop, mediaUpToDesktop, mediaUpToLargeMobile } from '../utilities/mediaQueries';
import { TrackLine } from '../molecules/TrackLine';
import { ArtistSchema, TrackSchema } from '../../../types/schema';
import { colorLightGrey, colorNeutralLight, colorWhite, rebrand } from '../../../theme/color';
import TitleText from '../atoms/TitleText';
import { Button } from '../atoms/button/Button';
import { ButtonSize, ButtonProminence } from '../atoms/button/buttonStyles';
import { ArrowDownward } from '../atoms/icons/ArrowDownward';
import { numberWithCommas } from '../utilities/numberWithComma';
import { ArrowUp } from '../atoms/icons/ArrowUp';
import { ErrorIcon } from '../atoms/icons/ErrorIcon';
import { Return } from '../atoms/icons/Return';
import { FavouriteButtonCompat } from '../../compat/favourite-button-compat';
import PlayButton from '../atoms/button/PlayButton';
import { TrackTile } from '../molecules/TrackTile';
import { ImagePreloader } from '../../../types/fn';
import { LinkTile } from '../molecules/LinkTile';
import { SortDropdownButton } from '../atoms/button/SortDropdownButton';
import { TopSolutionCarousel, TopSolutionEntity } from '../organisms/TopSolutionCarousel';
import { LoadMoreArrowButton } from '../atoms/button/LoadMoreArrow';
import ScrollToTopButton from '../atoms/button/ScrollToTopArrowButton';
import { scrollToTop } from '../utilities/page';
import ClientOnly from '../atoms/ClientOnly';

interface ArtistTileProps {
  artist: {
    name: string;
    slug: string;
    images: {
      identity: string;
    };
  };
  className?: string;
  onClickLink(event: React.MouseEvent<any>): void;
  preload: ImagePreloader;
  ratio?: number;
  Favourite?: any;
  withLabel?: boolean;
}
@inject(deps)
@observer
export class Search extends Component<ComponentProps> {
  state = { scrolling: false, playlist: { uuid: null } };
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY >= headerFullHeight) {
      this.setState({ scrolling: true });
    } else {
      this.setState({ scrolling: false });
    }
  };

  handleTrackLineClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (this.props.model.page.searchMvp.searchState.query.length > 0) {
      this.props.controller.analytics.sendMixpanel('User clicks link from search result');
    }
    this.props.controller.ui.handleClickLink(event);
  };

  isScrollAtBottom = () => {
    const scrollableContent = document.documentElement.scrollHeight - window.innerHeight;
    return Math.ceil(window.scrollY + window.innerHeight) < scrollableContent;
  };

  SelectTopSolutionMixPanel = (category: string, selectedTileUrl: string) => {
    this.props.controller.analytics.sendMixpanel('User selects a top result', {
      category,
      selectedTileUrl: `${this.props.model.env.baseUrl}/${selectedTileUrl}`,
    });
  };

  render() {
    const {
      model: {
        page: { searchMvp },
        user: { channel },
        env: { imagesUri },
        image: { cover },
        ui: { showPlayer },
      },
      controller: {
        page: {
          searchMvp: { loadMore, scrollTop, refreshPage },
        },
        analytics: { sendMixpanel },
      },
    } = this.props;

    const resultsTotal = searchMvp.results.length;

    const ShowLoadMore = resultsTotal < searchMvp.pagination.total;

    const searchQuery = this.props.model.router.location.search;

    const searchQueryOrFiltersToCheck = [
      'query=',
      'genre=',
      'mood=',
      'catalogueType=',
      'instrumental=',
      'hideNotAvailableIn=',
      'durationMin=',
      'durationMax=',
      'facebook=',
      'snapchat=',
      'twitch=',
      'twitter=',
      'vimeo=',
      'youtube=',
      'linkedin=',
      'instagram=',
      'TikTok=',
      'podcasting=',
    ];

    const searchContainsQueryOrFilter = searchQueryOrFiltersToCheck.some((query) => searchQuery.includes(query));
    const isValidatedSearch = !!searchMvp.selectedEntity.type;
    return (
      <MainWrapper className={styles.container}>
        <SearchField scrolling={this.state.scrolling} />
        {searchMvp.loading && (
          <div className={styles.loadingOverlay}>
            <Loading />
          </div>
        )}
        <div className={styles.tracklayout}>
          <FilterLayout scrolling={this.state.scrolling} />
          <div className={classes(styles.trackWrapper, searchMvp.openFilter && 'left-filter-open')}>
            <div>
              {!isValidatedSearch && searchMvp.noSearchResult && (
                <div className={styles.contentAlignment}>
                  <div className={styles.noResultWrapper} data-test-failed-search>
                    <ErrorIcon size={20} color={colorNeutralLight.toString()} />
                    <span className={styles.noResult}>
                      Sorry, it doesn&apos;t look like we have that at the moment. Please try another search or browse
                      all tracks below
                    </span>
                    <Button
                      data-test-failed-search-button
                      className={styles.noResultbutton}
                      size={ButtonSize.SMALL}
                      prominence={ButtonProminence.PRIMARY}
                      onClick={refreshPage}
                    >
                      <span>Start over</span>
                      <Return />
                    </Button>
                  </div>
                </div>
              )}
              {searchMvp.selectedEntity.type && searchMvp.getSelectedEntity && (
                <div className={styles.topSolutionWrapper}>
                  <div className={classes(styles.contentAlignment, styles.topSolutionMobileAlignment)}>
                    {searchMvp.selectedEntity.type === 'artist' || searchMvp.selectedEntity.type === 'playlist' ? (
                      <TopSolutionCarousel
                        perPage={4}
                        filterOpen={searchMvp.openFilter}
                        selectedEntity={searchMvp.selectedEntity.type}
                        contentClassName={classes(
                          styles.topSolutionMargin,
                          searchMvp.openFilter && styles.topSolutions
                        )}
                        SelectTopSolutionMixPanel={this.SelectTopSolutionMixPanel}
                        className={searchMvp.selectedEntity.type === 'playlist' && styles.topsolutionSpacing}
                        topSolutions={
                          [searchMvp.getSelectedEntity, ...searchMvp.topSolutionplaylist] as TopSolutionEntity[]
                        }
                      />
                    ) : (
                      <div className={styles.topSolutionContainer}>
                        {searchMvp.selectedEntity.type === 'track' && (
                          <div className={styles.titleWrapper}>
                            <TitleText
                              text="Top Result"
                              className={classes(
                                styles.topSolutionTitle,
                                searchMvp.openFilter && styles.topSolutionTitleFilterOpen
                              )}
                            />
                          </div>
                        )}
                        <div className={styles.tilesWrapper}>
                          {searchMvp.selectedEntity.type === 'track' && (
                            <div className={styles.tracktile} data-test-top-solution={'track'}>
                              <TrackTile
                                key={1}
                                track={(searchMvp.getSelectedEntity as any) as TrackSchema}
                                isSearchPage
                              >
                                <FavouriteButtonCompat track={(searchMvp.getSelectedEntity as any) as TrackSchema} />
                                <PlayButton track={(searchMvp.getSelectedEntity as any) as TrackSchema} />
                              </TrackTile>
                            </div>
                          )}
                          {searchMvp.selectedEntity.type === 'genre' && searchMvp.getSelectedEntity.images.identity && (
                            <div className={styles.tracktile} data-test-top-solution={'genre'}>
                              <LinkTile
                                heading={searchMvp.getSelectedEntity.name}
                                square={true}
                                compact
                                href={`/browse/genres/${searchMvp.getSelectedEntity.slug}`}
                                src={cover(searchMvp.getSelectedEntity.images.identity)}
                                isSearchPage
                                type={'Genre'}
                              />
                            </div>
                          )}
                          {searchMvp.selectedEntity.type === 'mood' && searchMvp.getSelectedEntity.images.identity && (
                            <div className={styles.tracktile} data-test-top-solution={'mood'}>
                              <LinkTile
                                compact
                                heading={searchMvp.getSelectedEntity.name}
                                square={true}
                                href={`/browse/moods/${searchMvp.getSelectedEntity.slug}`}
                                src={cover(searchMvp.getSelectedEntity.images.identity)}
                                isSearchPage
                                type={'Mood'}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {searchMvp.results.length > 0 && (
                <div className={classes(styles.contentAlignment, !searchMvp.openFilter && styles.centerTracks)}>
                  <div className={styles.titleAndPlanWrapper}>
                    <TitleText text="Tracks" className={styles.title} />
                    {/* This part was commented intensionally because we want to pause on it for now */}
                    {/* {isDesktop.matches && (
                    <div className={styles.planWrapper}>
                      {channel && channel.ratecard && (
                        <div className={styles.planLabel}>
                          Premium{' '}
                          <span className={styles.price}>
                            {formatNonDecimalCurrency(channel.ratecard.value, channel.ratecard.currency)}
                          </span>
                          <StarCircle color={rebrand.primary[100].toString()} size={16} className={styles.iconMargin} />
                        </div>
                      )}
                      <div className={styles.planLabel}>
                        Included <span className={styles.price}>£0</span>
                        <CheckmarkCircle
                          color={rebrand.secondary[70].toString()}
                          checkColor={colorWhite.toString()}
                          size={20}
                          className={styles.iconMargin}
                        />
                      </div>
                    </div>
                  )} */}
                    <SortDropdownButton />
                  </div>
                </div>
              )}
              <div
                data-test-track-results
                className={classes(styles.tracks, !searchMvp.openFilter && styles.centerTracks)}
              >
                <>
                  {searchMvp.results.map((track, i) => (
                    <div key={i} className={styles.trackLineWrapper}>
                      {/* Note: Type has been set due to legacy reasons */}
                      <TrackLine
                        track={(track as any) as TrackSchema}
                        key={track.identity}
                        onClickLink={this.handleTrackLineClick}
                        showFilter={searchMvp.openFilter}
                      />
                    </div>
                  ))}
                </>
                <ClientOnly>
                  <ScrollToTopButton scrollTop={scrollToTop} playerIsShowing={showPlayer} />
                </ClientOnly>
              </div>
              <div className={styles.contentAlignment}>
                <div className={classes(styles.loadMoreWrapper, !ShowLoadMore && styles.flexEnd)}>
                  {ShowLoadMore && (
                    <LoadMoreArrowButton
                      hidden={searchMvp.loading}
                      loading={searchMvp.loadingMoreResults}
                      handleLoadMore={() => {
                        loadMore();
                        sendMixpanel('User clicks load more on search track view');
                      }}
                    />
                  )}
                  {searchMvp.pagination.total > 0 && (
                    <span data-test-showing-more>
                      Showing {searchMvp.results.length} of
                      {!searchContainsQueryOrFilter && <span> 1m+</span>}
                      {searchContainsQueryOrFilter &&
                        (resultsTotal <= 999 ? (
                          <span> {numberWithCommas(searchMvp.pagination.total)}</span>
                        ) : (
                          <span> 1000+</span>
                        ))}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    );
  }
}
const styles = {
  container: style({
    margin: 0,
    position: 'relative',
    transition: `padding ${transitionQuickEase}`,
    minHeight: `calc(100vh -  ${constantHeaderHeight})`,
  }),
  tracklayout: style({
    display: 'flex',
  }),
  trackWrapper: style(
    mediaUpToDesktop({
      marginTop: constantHeaderWithSearchHeightScrollOrMobile,
    }),
    {
      marginLeft: 0,
      marginTop: constantHeaderWithSearchHeight,
      transition: ` margin-left 0.5s`,
      flex: 1,
      $nest: {
        '&.left-filter-open': {
          marginLeft: px(350),
        },
      },
    }
  ),
  tracks: style(
    mediaUpToLargeMobile({ padding: '0 16px' }),
    mediaLargeDesktop({ alignItems: 'center', width: `calc(100% - 318px)` }),
    {
      padding: '0 32px',
      display: 'flex',
      flexDirection: 'column',
    }
  ),
  topSolutions: style(
    mediaDesktop({
      width: important('100%'),
      maxWidth: '992px',
      marginRight: 0,
      marginLeft: 0,
    })
  ),
  topSolutionMargin: style(mediaUpToDesktop({ margin: 0 }), {
    paddingTop: '16px',
  }),
  titleWrapper: style({
    display: 'flex',
    alignItems: 'center',
    height: '25px',
  }),
  topSolutionTitle: style(
    mediaDesktop({
      width: '25%',
    }),
    mediaUpToDesktop({
      width: '42.5%',
      paddingLeft: '16px',
    })
  ),
  topSolutionTitleFilterOpen: style(
    media(
      { maxWidth: 670 },
      {
        width: important('82%'),
      }
    )
  ),
  featuredIn: style({
    marginLeft: '12px',
    color: rebrand.contrast[40].toString(),
    fontSize: px(24),
    fontWeight: 400,
  }),
  centerTracks: style({
    maxWidth: '992px',
    marginLeft: 'auto',
    marginRight: 'auto',
  }),
  contentAlignment: style(
    mediaUpToLargeMobile({ padding: '0 16px' }),
    mediaLargeDesktop({ alignItems: 'center', width: `calc(100% - 350px)` }),
    {
      padding: '0 32px',
      display: 'flex',
      flexDirection: 'column',
    }
  ),
  topSolutionMobileAlignment: style(mediaUpToDesktop({ padding: '0px' })),
  trackLineWrapper: style({
    borderBottom: `1px solid ${colorLightGrey.toString()}`,
    maxWidth: px(desktopBreakpoint),
    width: percent(100),
  }),
  titleAndPlanWrapper: style({
    display: 'flex',
    alignItems: 'center',
    width: desktopContentWidth,
    padding: '32px 0px',
    maxWidth: percent(100),
    justifyContent: 'space-between',
  }),
  title: style({ paddingRight: px(16) }),
  planWrapper: style({ display: 'flex', alignItems: 'center' }),
  planLabel: style({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    paddingRight: px(16),
    fontWeight: 700,
    fontSize: px(12),
    color: rebrand.neutralOnLight[60].toString(),
  }),
  iconMargin: style({ marginLeft: 4 }),
  price: style({ marginLeft: 4 }),
  loadMoreWrapper: style({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '32px 0px',
    width: desktopContentWidth,
    maxWidth: percent(100),
  }),
  loadMore: style({
    display: 'flex',
    alignItems: 'center',
    color: rebrand.contrast[50].toString(),
    fontWeight: 400,
    fontSize: px(16),
  }),
  loadingOverlay: style({
    margin: '35vh 0',
    position: 'absolute',
    left: 0,
    right: 0,
  }),
  flexEnd: style({ justifyContent: 'flex-end' }),
  noResult: style({
    color: colorNeutralLight.toString(),
    fontWeight: 400,
    fontSize: px(16),
    marginLeft: px(8),
  }),
  noResultWrapper: style({
    display: 'flex',
    alignItems: 'center',
    width: desktopContentWidth,
    padding: '32px 0px',
    maxWidth: percent(100),
    flexWrap: 'wrap',
  }),
  noResultbutton: style({
    whiteSpace: 'nowrap',
    boxShadow: 'none',
    padding: '4px 8px',
    display: 'flex',
    justifyContent: 'space-around',
    marginLeft: px(8),
    alignItems: 'center',
    $nest: {
      '&:hover': { boxShadow: 'none' },
    },
  }),
  tracktile: style(
    mediaUpToDesktop({
      width: px(150),
      height: px(213),
      $nest: {
        '&> div > a > img': {
          width: percent(100),
          height: px(150),
        },
      },
    }),
    {
      width: px(224),
      height: px(282),
      $nest: {
        '&> div > a > img': {
          width: percent(100),
          height: px(224),
        },
      },
    }
  ),
  topSolutionWrapper: style({
    width: percent(100),
    height: 'auto',
    backgroundColor: rebrand.neutralOnDark[10].toString(),
  }),
  topSolutionContainer: style({
    display: 'flex',
    width: desktopContentWidth,
    padding: '32px 0px',
    maxWidth: percent(100),
    flexDirection: 'column',
  }),
  tilesWrapper: style({
    paddingTop: px(32),
  }),
  topsolutionSpacing: style({
    paddingBottom: important('0px'),
  }),
};
