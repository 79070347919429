import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const Heart: React.SFC<SVGIconProps> = ({ size = 16, isSquare, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={isSquare ? size : size * (15 / 16)} viewBox="0 0 16 15" {...props}>
    <path
      d="m8 14.68-1.16-1.06C2.72 9.9 0 7.42 0 4.4 0 1.94 1.94 0 4.4 0 5.8 0 7.13.65 8 1.67A4.8 4.8 0 0 1 11.6 0C14.06 0 16 1.94 16 4.4c0 3.02-2.72 5.49-6.84 9.23L8 14.68Z"
      fill={color}
      fillRule="evenodd"
    />
  </BaseSvg>
);
