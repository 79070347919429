import * as React from 'react';
import { Component, deps, inject, observer } from '../lib/component';
import { Loading } from '../components/loading/loading';
import { centerCenter } from 'csstips';
import { px } from 'csx';
import { style } from 'typestyle';
import {PageAccountInvites} from '../components/page-account-invites';
import {AccountLayout} from "../components/project-happy/templates/AccountLayout";

export interface AccountInvitesPageProps {

}

@inject(deps) @observer
export class AccountInvitesPage extends Component<AccountInvitesPageProps> {
  render() {
    if (this.props.model.ui.isServer) return <div />;

    const model = this.props.model.page.accountInvites;

    if (model.loading) return (
      <AccountLayout>
        <div className={style({ padding: px(80), ...centerCenter })}>
          <Loading />
        </div>
      </AccountLayout>
    );

    return (
      <AccountLayout>
        <PageAccountInvites
          model={model}
          referralURL={this.props.model.user.referralURL}
        />
      </AccountLayout>
    )
  }
}
