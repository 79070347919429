import * as React from 'react';
import {Component, deps, inject, observer} from '../lib/component';
import {media, style} from 'typestyle';
import {percent, px, viewWidth} from 'csx';
import {centerCenter, horizontal, wrap} from 'csstips';
import {
  CollectionBrowseResults,
  FilterBrowseResults,
  RightsholderBrowseResults,
  TagBrowseResults
} from '../components/browse/results';
import {PageHeaderCarousel} from '../components/page-header-carousel';
import { colorBackground, colorGunmetal } from '../theme/color';
import {constantHeaderHeight} from '../theme/constant';
import {mediaMobileOnly, mediaTablet} from '../theme/media';
import {ConnectedBrowseTagGrid} from '../components/browse/tag-grid';
import {ConnectedBrowsePlaylistGrid} from '../components/browse/playlist-grid';
import {SimpleTileGrid, SimpleTileItem} from '../components/tile-grid';
import {UIBrowseSection} from '../types/ui';
import {gradientDark} from '../theme/gradient';
import {TagTile} from '../components/tag-tile';
import {ConnectedBrowseRightsholderReleaseGrid} from '../components/browse/release-grid';
import {ConnectedRandomTrackGrid} from '../components/browse/random-track-grid';
import {ConnectedBrowseRightsholderGrid} from '../components/browse/rightsholder-grid';
import {PaginationInput} from '../types';
import MainWrapper from '../components/main-wrapper';
import {ConnectedBrowseFilterGrid} from '../components/browse/filter-grid';

export interface BrowsePageProps {}

@inject(deps)
@observer
export class BrowsePage extends Component<BrowsePageProps> {
  render() {
    const model = this.props.model.page.browse;
    const controller = this.props.controller.page.browse;
    const { ui, image } = this.props.controller;
    const { isMobile } = this.props.model.ui;
    const { content } = this.props.model;
    const { preloadContent, preloadImage } = this.props.controller.image;

    if (model.isCollection) return <CollectionBrowseResults {...model.browseState} pathGenerate={model.activePath} />;

    if (model.isTag) return <TagBrowseResults {...model.browseState} pathGenerate={model.activePath} />;

    if (model.isRightsholder)
      return <RightsholderBrowseResults {...model.browseState} pathGenerate={model.activePath} />;

    if (model.isFilter) return <FilterBrowseResults {...model.browseState} pathGenerate={model.activePath} />;

    const tiles =
      !model.slug && !model.type && content.browseSections && content.browseSections.length
        ? content.browseSections.map(BrowsePage.mapToTile)
        : [];

    return (
      <MainWrapper className={BrowsePage.styles.container}>
        <PageHeaderCarousel
          className={BrowsePage.styles.pageCarousel}
          model={model.heroCarousel}
          onClickLink={ui.handleClickLink}
          preloadContent={preloadContent}
          hide={isMobile && !!model.type}
        />
        {!model.slug && (
          <div className={BrowsePage.styles.typeContainer}>
            {!!model.type && this.renderType(model.type, model.pagination)}
            {!model.type && (
              <SimpleTileGrid
                className={BrowsePage.styles.tileGrid}
                tiles={tiles}
                renderTile={(itemClass, tile, index) => (
                  <TagTile
                    className={itemClass}
                    href={BrowsePage.makePath(tile.slug)}
                    key={tile.slug + index}
                    tag={tile}
                    preload={preloadImage}
                    onClickLink={ui.handleClickLink}
                  />
                )}
              />
            )}
          </div>
        )}
      </MainWrapper>
    );
  }

  renderType = (type: string, pagination: PaginationInput) => {
    switch (type) {
      case 'filter':
        return <ConnectedBrowseFilterGrid pagination={pagination} />;
      case 'genre':
        return <ConnectedBrowseTagGrid type="hookd_genre" pagination={pagination} />;
      case 'mood':
        return <ConnectedBrowseTagGrid type="hookd_mood" pagination={pagination} />;
      case 'theme':
        return <ConnectedBrowseTagGrid type="hookd_theme" pagination={pagination} />;
      case 'collection':
        return <ConnectedBrowsePlaylistGrid pagination={pagination} />;
      case 'labels':
        return <ConnectedBrowseRightsholderGrid pagination={pagination} />;
      case 'music-from-movies':
        return <ConnectedBrowseRightsholderReleaseGrid rightsholder="cutting-edge" pagination={pagination} />;
      case 'random':
        return <ConnectedRandomTrackGrid seed={new Date().toISOString()} pagination={pagination} />;
    }
  };

  static mapToTile = ({ label, slug, image }: UIBrowseSection): SimpleTileItem => ({
    name: label,
    slug,
    images: {
      identity: image,
    },
  });

  static makePath = (slug: string) => `/browse/${slug}`;

  static styles = {
    container: style({
      background: gradientDark,
    }),
    typeContainer: style(
      {
        margin: '0 auto',
        maxWidth: px(1280),
      },
      mediaMobileOnly({
        padding: px(16),
      }),
      mediaTablet({
        padding: constantHeaderHeight,
      })
    ),
    tileGrid: style({
      background: colorGunmetal.darken(0.05).toString(),
    }),
    pageCarousel: style(),
    loading: style({
      ...centerCenter,
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    }),
    carouselContainer: style({
      position: 'relative',
      width: percent(100),
    }),
    carouselHeader: style({
      background: colorBackground.toString(),
      color: colorGunmetal.toString(),
      padding: '0 16px',
      margin: 0,
      lineHeight: constantHeaderHeight,
      height: constantHeaderHeight,
    }),
    themeCarousel: style(
      { minHeight: px(260) },
      mediaMobileOnly({ height: viewWidth(85) }),
      mediaTablet({ height: viewWidth(25) })
    ),
    themeTile: style(
      { flexShrink: 0, height: 'auto !important', minWidth: px(260) },
      mediaMobileOnly({ width: viewWidth(85) }),
      mediaTablet({ width: viewWidth(25) })
    ),
    heroCarousel: style(
      { minHeight: px(220) },
      mediaMobileOnly({ height: viewWidth(60) }),
      mediaTablet({ height: viewWidth(13) })
    ),
    heroTile: style(
      { flexShrink: 0, height: 'auto !important', minWidth: px(220) },
      mediaMobileOnly({ width: viewWidth(60) }),
      mediaTablet({ width: viewWidth(13) })
    ),
    carousel: style(
      { minHeight: px(190) },
      mediaMobileOnly({ height: viewWidth(52) }),
      mediaTablet({ height: viewWidth(11.25) })
    ),
    tile: style(
      { flexShrink: 0, height: 'auto !important', minWidth: px(190), $nest: { '& *': { fontSize: px(20) } } },
      mediaMobileOnly({ width: viewWidth(52) }),
      mediaTablet({ width: viewWidth(11.25) })
    ),
    grid: style({
      ...horizontal,
      ...wrap,
    }),
    gridItem: style(
      media(
        { maxWidth: 460 },
        {
          width: percent(100 / 2),
        }
      ),
      media(
        { minWidth: 460, maxWidth: 600 },
        {
          width: percent(100 / 3),
        }
      ),
      media(
        { minWidth: 600, maxWidth: 900 },
        {
          width: percent(100 / 4),
        }
      ),
      media(
        { minWidth: 900 },
        {
          width: percent(100 / 6),
        }
      )
    ),
    showMore: style({
      ...centerCenter,
      padding: px(16),
      $nest: {
        '&> *': {
          maxWidth: viewWidth(75),
          whiteSpace: 'normal',
          textAlign: 'center',
        },
        '& svg': {
          marginLeft: px(8),
        },
      },
    }),
  };
}
