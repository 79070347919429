import * as React from 'react';
import { FaqBlock } from '../../atoms/FaqBlock';
import { Faq } from '../../atoms/Faq';
import { PrismicRichText } from '../../utilities/types';
import { renderPrismicRichTextArray } from '../../utilities/props';
import { style } from 'typestyle';
import { em } from 'csx';

const styles = {
  faq: style({
    $nest: {
      '& p': {
        marginTop: 0,
        marginBottom: 0,
        paddingTop: em(1),
        paddingBottom: em(1.25),
      },
    },
  }),
};

export type PrismicFaq = {
  question: string;
  answer: PrismicRichText;
};

export type PrismicFaqBlockProps = React.Component['props'] & {
  className?: string;
  faqs: PrismicFaq[];
};

export const PrismicFaqBlock: React.SFC<PrismicFaqBlockProps> = ({ className, faqs }) => (
  <FaqBlock className={className}>
    {faqs.map(({ question, answer }, key) => (
      <Faq question={question} key={key} className={styles.faq}>
        <div>{renderPrismicRichTextArray(answer)}</div>
      </Faq>
    ))}
  </FaqBlock>
);
