import * as React from 'react';
import { getUid } from '../../utilities/string';
import { SVGIconProps as LegacySVGIconProps } from '../../../svg-icon';

export type BaseSvgProps = React.SVGAttributes<SVGElement> & {
  label?: string;
  isHiddenFromScreenReader?: boolean;
  isSquare?: boolean; // This is only here to ensure it isn't passed on from parent components
  checkColor?: string;
  title?: string;
};

export type SVGIconProps = LegacySVGIconProps & BaseSvgProps;

export enum SVGIconStyle {
  OUTLINE,
  FILLED,
}

export const BaseSvg: React.SFC<BaseSvgProps> = ({
  children,
  isSquare,
  checkColor,
  label,
  isHiddenFromScreenReader = false,
  ...props
}) => {
  if (isHiddenFromScreenReader === true) {
    props['aria-hidden'] = 'true';
  }
  let titleId;
  if (label) {
    titleId = getUid();
    props['aria-labelledby'] = titleId;
    props.role = 'img';
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      {label && <title id={titleId}>{label}</title>}
      {children}
    </svg>
  );
};
