import React from 'react';
import { style, classes } from 'typestyle';
import { rebrand } from '../../../theme/color';
import { px } from 'csx';
import { borderRadius } from '../../../theme/border';

const styles = {
  panel: style({
    backgroundColor: rebrand.neutralOnDark[10].toString(),
    padding: px(16),
    borderRadius: borderRadius.MEDIUM,
  }),
};

interface InfoBoxProps {
  className?: string;
}

export const InfoBox = ({ className = '', children }: React.PropsWithChildren<InfoBoxProps>) => {
  return <div className={classes(styles.panel, className)}>{children}</div>;
};
