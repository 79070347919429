import { px } from 'csx';
import React from 'react';
import { style } from 'typestyle';

const classes = style({
  position: 'absolute',
  width: px(1),
  height: px(1),
  padding: 0,
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  borderWidth: 0,
});

export const SrOnlyText: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <span className={classes}>{children}</span>;
};
