import { colorBlack } from './color';
import { CSSProperties } from 'typestyle/lib/types';

export const shadowNotification = `0 1px 2px 0 rgba(0, 0, 0, 0.17)`;
export const shadowSubtle = `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`;
export const shadowFirm = `0 0 6px ${colorBlack.toRGBA()}`;
export const shadowSearch = `inset 0 2px 6px 0px rgba(0,0,0,0.2)`;
export const shadowSort = '0 4px 10px 0 rgba(0,0,0,0.1)';

export const shadowElevation1: CSSProperties = {
  boxShadow: '0px 2px 6px 1px rgba(0,0,0,0.2)',
};

export const shadowElevation2: CSSProperties = {
  boxShadow: '0px 4px 6px 1px rgba(0,0,0,0.2)',
};

export const shadowElevation3: CSSProperties = {
  boxShadow: '0px 6px 6px 1px rgba(0,0,0,0.05)',
};

export const shadowElevation4: CSSProperties = {
  boxShadow: '-6px 0px 6px 1px rgba(0,0,0,0.05)',
};

export const shadowElevation5: CSSProperties = {
  boxShadow: '0px 8px 32px 0px rgba(0,0,0,0.08)',
};

export const shadowElevation6: CSSProperties = {
  boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.16)',
};
