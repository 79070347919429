import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';
import { rebrand } from '../../../../theme/color';
import { SemanticInformationType } from '../../utilities/types';

export const InfoFilled: React.SFC<SVGIconProps> = ({
  size = 14,
  color = rebrand.semantic[SemanticInformationType.WARNING]['60'].toString(),
  ...props
}) => (
  <BaseSvg width={size} height={size} viewBox="0 0 14 14" {...props} style={{ cursor: 'pointer' }}>
    <path
      d="M7.00016 0.333252C3.32016 0.333252 0.333496 3.31992 0.333496 6.99992C0.333496 10.6799 3.32016 13.6666 7.00016 13.6666C10.6802 13.6666 13.6668 10.6799 13.6668 6.99992C13.6668 3.31992 10.6802 0.333252 7.00016 0.333252ZM7.66683 10.3333H6.3335V6.33325H7.66683V10.3333ZM7.66683 4.99992H6.3335V3.66659H7.66683V4.99992Z"
      fill={color}
    />
  </BaseSvg>
);
