import * as React from 'react';
import { classes, style } from 'typestyle';
import { center, centerCenter, flexRoot } from 'csstips';
import { rebrand } from '../theme/color';
import { Button } from './project-happy/atoms/button/Button';
import { ButtonProminence, ButtonSize } from './project-happy/atoms/button/buttonStyles';
import { Copy } from './project-happy/atoms/icons/Copy';
import { wait } from './project-happy/utilities/asynchronous';
import { ErrorOutlineCircle } from './project-happy/atoms/icons/ErrorCircleOutline';
import { px } from 'csx/lib/internal';
import { Checkmark } from './project-happy/atoms/icons/Checkmark';
import { spacing } from '../theme/spacing';
import { borderRadius } from '../theme/border';

const styles = {
  container: style({
    border: 0,
    color: rebrand.neutralOnDark[100].toString(),
    background: rebrand.neutralOnDark[10].toString(),
    borderRadius: borderRadius.SMALL,
    boxShadow: `1px 1px ${rebrand.light1.toString()} inset`,
    position: 'relative',
  }),
  input: style({
    border: 0,
    padding: px(8),
    flexGrow: 1,
    background: 'transparent',
    color: 'inherit',
    fontSize: px(12),
  }),
  button: style(flexRoot, centerCenter, {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    width: '100%',
  }),
  singleLineButton: style(flexRoot, centerCenter, {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
  }),
  content: style({
    padding: spacing.SMALL,
    flexGrow: 1,
    background: 'transparent',
    color: 'inherit',
    fontSize: px(12),
    maxHeight: px(200),
    overflowWrap: 'break-word',
    overflowY: 'auto',
    $nest: {
      '& p': {
        marginBottom: 0,
      },
      '& p:first-child': {
        marginTop: 0,
      },
    },
  }),
  singleLineContent: style({
    $nest: {
      '& p:first-child': {
        marginRight: px(64),
      },
    },
  }),
};

export interface CopyBoxProps {
  className?: string;
  content: string;
  singleLine?: boolean;
}

enum CopyState {
  READY,
  COPIED,
  ERROR,
}

export interface CopyBoxState {
  copied: CopyState;
}

export class CopyBox extends React.Component<CopyBoxProps, CopyBoxState> {
  private content: any;

  state = {
    copied: CopyState.READY,
  };

  constructor(props) {
    super(props);
    this.copyValue = this.copyValue.bind(this);
  }

  render() {
    const { content, className, singleLine = true } = this.props;
    const { copied } = this.state;

    const paragraphs = this.props.content.split('\n').map((paragraph, index) => <p key={index}>{paragraph}</p>);

    return (
      <div className={classes(styles.container, className)}>
        <textarea
          data-test-referral-url
          className={styles.input}
          value={content}
          readOnly={true}
          ref={(el) => (this.content = el)}
          style={{ position: 'absolute', left: '-9999px' }}
        />
        <div className={classes(styles.content, singleLine ? styles.singleLineContent : '')}>{paragraphs}</div>
        <Button
          className={singleLine ? styles.singleLineButton : styles.button}
          prominence={ButtonProminence.SECONDARY}
          size={ButtonSize.SMALL}
          onClick={this.copyValue}
          data-test-copy-btn
        >
          {copied === CopyState.READY && (
            <>
              <Copy /> Copy
            </>
          )}
          {copied === CopyState.COPIED && (
            <>
              <Checkmark /> Copied
            </>
          )}
          {copied === CopyState.ERROR && (
            <>
              <ErrorOutlineCircle /> Error
            </>
          )}
        </Button>
      </div>
    );
  }

  async setCopyState(state: CopyState) {
    this.setState({ copied: state });
    await wait(3000);
    this.setState({ copied: CopyState.READY });
  }

  async copyValue() {
    const input = this.content;
    // Attempt to copy with execCommand (good browser support but deprecated)
    try {
      input.select();
      if (document.execCommand('copy')) {
        this.setCopyState(CopyState.COPIED);
        return;
      }
    } catch (error) {
      console.error('execCommand copy failed', error);
    }

    // Attempt to copy with Clipboard API (requires a secure context, user permissions and a modern browser)
    const navigatorInstance = navigator as
      | (Navigator & { clipboard?: { writeText: (text: string) => Promise<void> } })
      | undefined;
    if (navigatorInstance && navigatorInstance.clipboard) {
      try {
        await navigatorInstance.clipboard.writeText(input.value);
        this.setCopyState(CopyState.COPIED);
        return;
      } catch (error) {
        console.error('Clipboard API copy failed', error);
      }
    }

    // If both methods fail, display a prompt with the value to copy
    this.setCopyState(CopyState.ERROR);
    prompt('Unable to copy text automatically. Please copy the following text manually:', input.value);
  }
}
