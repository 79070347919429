import * as React from 'react';
import { classes, cssRaw, cssRule, style } from 'typestyle';
import { px } from 'csx';
import { ContentSlice, ContentSliceProps } from './ContentSlice';
import { mediaDesktop, mediaLargeTablet, mediaSmallTablet, mediaUpToSmallTablet } from '../../utilities/mediaQueries';
import { heading3, heading4, heading5, heading6, headingStyles } from '../../utilities/font';
import { colorBrand, colorLightGrey, colorGunmetal, colorWhite } from '../../../../theme/color';
import { ButtonPill } from '../../../button-pill';
import { SVGIcon } from '../../../svg-icon';
import { VideoOverlayModal } from '../VideoOverlayModal';
import { mediaMobileOnly } from '../../../../theme/media';

const styles = {
  wrapper: style(
    mediaUpToSmallTablet({
      paddingBottom: px(32),
      $nest: {
        '.masthead + &, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient': {
          paddingTop: px(32),
        },
        '& h2': {
          fontSize: '32px',
        },
      },
    }),
    mediaSmallTablet({
      paddingBottom: px(56),
      $nest: {
        '.masthead + &, .with-gradient + &:not(.with-gradient), :not(.with-gradient) + &.with-gradient': {
          paddingTop: px(56),
        },
      },
    }),
    {
      borderTop: `1px solid ${colorLightGrey.toString()}`,
      borderBottom: `1px solid ${colorLightGrey.toString()}`,
      textAlign: 'center',
      $nest: {
        '& h2': {
          fontSize: '56px',
        },
      },
    }
  ),
  infoPanel: style({
    width: '152px',
    height: '152px',
    backgroundColor: colorGunmetal.toString(),
    color: colorWhite.toString(),
    margin: 'auto',
    borderRadius: '4px',
    marginBottom: '30px',
    $nest: {
      '& p': {
        position: 'relative',
        top: '25px',
        fontWeight: 200,
        lineHeight: '1.5em',
        padding: '0px 15px',
      },
      '& h3': {
        fontWeight: 400,
        position: 'relative',
        top: '36px',
        fontSize: '1em',
      },
    },
  }),
  bulletPointIcon: style({
    position: 'relative',
    top: '43px',
    backgroundColor: colorBrand.toString(),
    width: '48px',
    height: '48px',
    borderRadius: '100%',
    color: colorWhite.toString(),
    margin: 'auto',
    $nest: {
      '& h2': {
        position: 'relative',
        top: '4px',
        margin: '0',
        left: '-1px',
        fontSize: '32px !important',
      },
    },
  }),
  button: style({
    fontSize: '20px',
    $nest: {
      '& svg': {
        marginLeft: '10px',
      },
    },
  }),
  bulletPointContainer: style({
    display: 'inline-block',
    height: '205px',
    width: '100%',
  }),
  buttonWrapper: style(mediaMobileOnly({ marginTop: '40px' }), {
    position: 'relative',
    marginTop: '56px',
  }),
  bulletPointWrapper: style(
    {
      display: 'flex',
      margin: '0 auto',
      justifyContent: 'space-between',
      width: '488px',
    },
    mediaUpToSmallTablet({
      flexDirection: 'column',
      width: 'auto',
    }),
    mediaDesktop({
      $nest: {
        '&> div:nth-child(2)': {
          position: 'relative',
          top: '-20px',
        },
      },
    }),
    mediaSmallTablet({
      $nest: {
        '&> div:nth-child(2)': {
          position: 'relative',
          top: '-20px',
        },
      },
    }),
    mediaLargeTablet({
      $nest: {
        '&> div:nth-child(2)': {
          position: 'relative',
          top: '-20px',
        },
      },
    })
  ),
};

cssRule(
  '.heading-slice .subheading',
  { fontWeight: 300, display: 'block' },
  mediaUpToSmallTablet(heading6),
  mediaSmallTablet(heading5)
);

cssRule(
  '.heading-slice h2.heading, .heading-slice .heading',
  mediaUpToSmallTablet(heading4),
  mediaSmallTablet(heading3)
);

type HeadingSliceProps = ContentSliceProps & {
  videoId: string;
};

export class HowItWorksSlice extends React.Component<HeadingSliceProps, any> {
  constructor(props) {
    super(props);
    this.state = { showVideoModal: false };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  render() {
    const { children, videoId, className, ...props } = this.props;

    return (
      <ContentSlice className={classes('how-it-works-slice', className, styles.wrapper)} {...props}>
        <h2 style={{ marginBottom: 0 }}>How it works</h2>

        <div className={styles.bulletPointWrapper}>
          <div className={styles.bulletPointContainer}>
            <div className={styles.bulletPointIcon}>
              <h2>1</h2>
            </div>
            <div className={styles.infoPanel}>
              <h3>Sign-up</h3>
              <p>Jump in and create your account</p>
            </div>
          </div>
          <div className={styles.bulletPointContainer}>
            <div className={styles.bulletPointIcon}>
              <h2>2</h2>
            </div>
            <div className={styles.infoPanel}>
              <h3>Link YouTube</h3>
              <p>
                Add your
                <span style={{ display: 'block' }}>YouTube</span>
                channel
              </p>
            </div>
          </div>
          <div className={styles.bulletPointContainer}>
            <div className={styles.bulletPointIcon}>
              <h2>3</h2>
            </div>
            <div className={styles.infoPanel}>
              <h3>License</h3>
              <p>License and use music, claims-free</p>
            </div>
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <ButtonPill primary={true} xlarge={true} onClick={this.handleShow}>
            <div className={styles.button}>
              Watch how <SVGIcon.Play size={14} />
            </div>
          </ButtonPill>
          <VideoOverlayModal show={this.state.showVideoModal} onRequestClose={this.handleClose} videoId={videoId} />
        </div>
      </ContentSlice>
    );
  }

  handleShow(e: React.MouseEvent<any>): void {
    e.preventDefault();
    this.setState({ showVideoModal: true });
  }

  handleClose(): void {
    return this.setState({ showVideoModal: false });
  }
}
