import * as React from 'react';
import { classes, style } from 'typestyle';
import { Checkmark } from './icons/Checkmark';
import { flexRoot, start } from 'csstips';
import { spacing } from '../../../theme/spacing';
import { AddCircle } from './icons/AddCircle';

const styles = {
  listItem: style(flexRoot, start, {
    listStyle: 'none',
    position: 'relative',
    padding: 0,
    margin: 0,
    gap: spacing.XSMALL,
    $nest: {
      '& + &': {
        marginTop: spacing.DEFAULT,
      },
    },
  }),
  icon: style({
    flexShrink: 0,
  }),
};

export type IconListProps = {
  className?: string;
  iconColor?: string;
  icon?: 'checkmark' | 'plus';
};

export const IconListItem: React.FC<IconListProps> = ({
  children,
  className,
  iconColor = 'currentColor',
  icon = 'checkmark',
}) => (
  <li className={classes(styles.listItem, className)}>
    {icon === 'checkmark' && <Checkmark className={styles.icon} color={iconColor} size={20} />}
    {icon === 'plus' && <AddCircle className={styles.icon} color={iconColor} size={20} />}
    <div>{children}</div>
  </li>
);
