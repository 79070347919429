import React from 'react';
import { TermsCheckbox } from '../atoms/controls/TermsCheckbox';
import GoogleButton from '../../user/google-button';
import { center, flexRoot, vertical } from 'csstips';
import { classes, style } from 'typestyle';
import { em, percent, px } from 'csx';
import { Component, ComponentProps, deps, inject, observer } from '../../../lib/component';
import { setOnboardingSessionValues } from '../utilities/inputs';
import { centerCenter } from 'csstips/lib/flex';

const styles = {
  form: style(flexRoot, vertical, center),
  checkbox: style(vertical, centerCenter, {
    marginTop: px(8),
    marginBottom: px(8),
    textAlign: 'left',
  }),
  button: style({
    fontSize: em(1.2),
    height: px(62),
    width: px(258),
    margin: 0,
    marginTop: px(32),
  }),
};

@inject(deps)
@observer
export class SignUpForm extends Component<ComponentProps> {
  ref: HTMLFormElement = null;

  state = {
    clicked: false,
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      clicked: true,
    });
    const isValid = this.ref && this.ref.checkValidity();
    this.props.controller.analytics.sendMixpanel('User clicks "Sign up with Google" button', {
      termsChecked: isValid,
    });
    if (!isValid) return;
    window.location.href = this.props.model.auth.youtubeLoginURL;
  }

  render() {
    const { youtubeLoginURL } = this.props.model.auth;

    return (
      <form
        action={youtubeLoginURL}
        method="get"
        className={classes(styles.form, this.state.clicked && 'show-errors')}
        ref={(ref) => (this.ref = ref)}
        onSubmit={this.handleSubmit}
      >
        <TermsCheckbox className={styles.checkbox} />
        <GoogleButton className={styles.button} onClick={this.handleSubmit} />
      </form>
    );
  }
}
