import { AccountCommunicationPageModel } from './model';
import { action, runInAction } from 'mobx';
import { RouterState, RedirectFunction } from 'react-router';
import { APIController } from '../../api/controller';
import { AuthModel } from '../../auth/model';
import { EnvModel } from '../../env/model';
import { PrismicController } from '../../prismic/controller';
import { UIController } from '../../ui/controller';
import { AuthController } from '../../auth/controller';
import {createMarketingPreferencesForm, resetFormErrors} from '../../../lib/form';
import {HttpError} from '../../../types';
import {UserPreferencesSchema} from '../../../types/schema';

export class AccountCommunicationPageController {
  constructor(
    private model: AccountCommunicationPageModel,
    private auth: AuthModel,
    private api: APIController,
    private env: EnvModel,
    private prismic: PrismicController,
    private ui: UIController,
    private authController: AuthController,
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    this.model.loading = true;
    const documentId = 'communication_preferences';

    this.ui.setBreadcrumbs([{
      path: '/account',
      label: 'Account'
    }, {
      path: '/account/communication',
      label: 'Communication preferences'
    }]);

    await this.ui.setSEO(nextState.location.pathname);

    const [preferences, content] = await Promise.all([
      this.api.user.getPreferences(this.auth.user.identity),
      this.prismic.getSingle(documentId),
      this.env.ready
    ]);

    const document = content.data;

    this.model.i18n = {
      newMusic: {
        title: document.new_content_title,
        subtitle: document.new_content_content
      },
      recommendedMusic: {
        title: document.recommended_content_title,
        subtitle: document.recommended_content_content
      },
      productAnnouncements: {
        title: document.product_announcements_title,
        subtitle: document.product_announcements_content
      },
      twitterAnnouncement: {
        title: document.twitter_order_announcement_title,
        subtitle: document.twitter_order_announcement_content
      },
      thirdParty: {
        title: document.third_party_promotions_title,
        subtitle: document.third_party_promotions_content
      }
    };

    this.model.preferences = preferences.data;
    this.model.saving = Object.keys(preferences.data)
      .reduce((acc, key) => ({ ...acc, [key]: false }), {}) as UserPreferencesSchema;

    this.model.disabled = !this.auth.user.marketing_allowed;

    this.model.loading = false;

    return;
  };

  @action
  setPreferences = async (preferences: Partial<UserPreferencesSchema>) => {
    if (this.model.disabled) return;

    const keys = Object.keys(preferences);

    try {
      keys.forEach(key => this.model.saving[key] = true);
      await this.api.user.setPreferences(this.auth.user.identity, preferences);
      keys.forEach(key => this.model.preferences[key] = preferences[key]);
    } finally {
      keys.forEach(key => this.model.saving[key] = false);
    }
  };

  @action
  editMarketing = async () => {
    await this.env.ready;

    const { user } = this.auth;

    runInAction(() => {
      this.model.modalMarketing.form = createMarketingPreferencesForm(user.marketing_allowed);
      this.model.modalMarketing.show = true;
    });
  };

  @action
  changeMarketingField = (field: string, value: string) => {
    this.model.modalMarketing.form.fields[field].value = value;
  };

  @action
  closeMarketingModal = () => {
    this.model.modalMarketing.show = false;
  };

  @action
  submitMarketingModal = async () => {
    resetFormErrors(this.model.modalMarketing.form);

    try {
      this.model.modalMarketing.form.saving = true;
      const marketing_allowed = this.model.modalMarketing.form.fields.marketing_allowed.value;
      const promises: Array<Promise<any>> = [this.api.user.setMarketingPreferences(marketing_allowed)];

      if (!marketing_allowed) {
        promises.push(this.api.user.clearPreferences(this.auth.user.identity))
      }

      await Promise.all(promises);
      const [preferences] = await Promise.all([
        this.api.user.getPreferences(this.auth.user.identity),
        this.authController.getSession()
      ]);

      runInAction(() => {
        this.model.preferences = preferences.data;
        this.model.disabled = !marketing_allowed;
      });

      this.closeMarketingModal();
    } catch (e) {
      try {
        this.model.modalMarketing.form.error = (e as HttpError).body.error;
      } catch (e) {
        console.log(e);
        this.model.modalMarketing.form.error = 'Oops! Something went wrong, please try again.'
      }
    } finally {
      this.model.modalMarketing.form.saving = false;
    }
  };
}
