import { TwoColumnSlice } from '../organisms/slices/TwoColumnSlice';
import * as React from 'react';
import { PrismicMasthead } from './PrismicMasthead';
import { PrismicHeadlineStatement } from './PrismicHeadlineStatement';
import { PrismicCreatorStatPanel, PrismicCreatorStatPanelWithText } from './PrismicCreatorStatPanel';
import { PrismicTrackPlaylist } from './PrismicTrackPlaylist';
import { PrismicPromotionalPanel, PrismicPromotionalPanelWithText } from './PrismicPromotionalPanel';
import { PrismicHeadlineWithCallToAction } from './PrismicHeadlineWithCallToAction';
import { PrismicCreatorTestimonial } from './PrismicCreatorTestimonial';
import { PrismicStatementPanel, PrismicStatementPanelWithText } from './PrismicStatementPanel';
import { PrismicArtistsFeaturePanel, PrismicArtistsFeaturePanelWithText } from './PrismicArtistsFeaturePanel';
import { PrismicCtaButton } from './PrismicCtaButton';
import { PrismicComponentCommonProps, PrismicSlice, PrismicSlices, SlicePageContext } from '../utilities/types';
import { AnyComponent, isEmpty } from '../utilities/objects';
import { TruantMasthead } from '../molecules/mastheads/TruantMasthead';
import { LabelSlice } from '../organisms/slices/LabelSlice';
import { PrismicTrackCarousel } from './PrismicTrackCarousel';
import { PrismicLinkCarousel } from './PrismicLinkCarousel';
import { PrismicPlaylistCarousel } from './PrismicPlaylistCarousel';
import { PrismicTopPicksCarousel } from './PrismicTopPicksCarousel';
import { PrismicFavouritesCarousel } from './PrismicFavouritesCarousel';
import { PrismicPartnerBadge } from './PrismicPartnerBadge';
import { PrismicYouTubePanel, PrismicYouTubePanelWithText } from './PrismicYouTubePanel';
import { PrismicYouTubeVideoTrackCarousel } from './PrismicYouTubeVideoTrackCarousel';
import { PrismicHomepageMasthead } from './PrismicHomepageMasthead';
import { PrismicCreatorDashboardMasthead } from './PrismicCreatorDashboardMasthead';
import { PrismicGroupHeading, PrismicGroupHeadingPanelPayload } from './PrismicGroupHeading';
import { PrismicCatalogueCarousel } from './PrismicCatalogueCarousel';
import { SliceGroup } from '../atoms/SliceGroup';
import { PrismicHowItWorks } from './PrismicHowItWorks';
import { PrismicTextPanel } from './PrismicTextPanel';
import { PrismicPlans } from './PrismicPlans';
import { AnalyticsContextProvider } from '../utilities/AnalyticsContext';
import { PrismicCatalogueTiles } from './PrismicCatalogueTiles';
import { PrismicLinkTiles } from './PrismicLinkTiles';
import { PrismicBrowseMasthead } from './PrismicBrowseMasthead';
import { PrismicPromotionalBanner } from './PrismicPromotionalBanner';
import { PrismicDashboardTeaserMasthead } from './PrismicDashboardTeaserMasthead';
import { PrismicVerticalQuestionnaire } from './PrismicVerticalQuestionnaire';
import { PrismicAnnouncement } from './PrismicAnnouncement';
import { observable } from 'mobx';
import { PrismicPersonalisedCarousel } from './PrismicPersonalisedCarousel';
import { PrismicLists } from './PrismicLists';
import { PrismicHowItWorksReferee } from './PrismicHowItWorksReferee';
import { PrismicPricingImage } from './PrismicPricingImages';
import { PrismicDashboardHeader } from './PrismicDashboardHeader';
const prismicSliceMap: { [key in PrismicSlices]: AnyComponent<PrismicComponentCommonProps> } = {
  [PrismicSlices.MASTHEAD]: PrismicMasthead,
  [PrismicSlices.TRUANT_MASTHEAD]: TruantMasthead as React.SFC<PrismicComponentCommonProps>,
  [PrismicSlices.HOMEPAGE_MASTHEAD]: PrismicHomepageMasthead,
  [PrismicSlices.CREATOR_DASHBOARD_MASTHEAD]: PrismicCreatorDashboardMasthead,
  [PrismicSlices.DASHBOARD_TEASER_MASTHEAD]: PrismicDashboardTeaserMasthead,
  [PrismicSlices.BROWSE_MASTHEAD]: PrismicBrowseMasthead,
  [PrismicSlices.HEADLINE]: PrismicHeadlineStatement,
  [PrismicSlices.CREATOR_PANEL]: PrismicCreatorStatPanel,
  [PrismicSlices.CREATOR_PANEL_RICHTEXT]: PrismicCreatorStatPanelWithText,
  [PrismicSlices.ARTIST_PANEL]: PrismicArtistsFeaturePanel,
  [PrismicSlices.ARTIST_PANEL_RICHTEXT]: PrismicArtistsFeaturePanelWithText,
  [PrismicSlices.TRACK_PLAYLIST]: PrismicTrackPlaylist,
  [PrismicSlices.PROMO_PANEL]: PrismicPromotionalPanel,
  [PrismicSlices.PROMO_PANEL_RICHTEXT]: PrismicPromotionalPanelWithText,
  [PrismicSlices.PARTNERS]: LabelSlice as React.SFC<PrismicComponentCommonProps>,
  [PrismicSlices.YOUTUBE_PANEL]: PrismicYouTubePanel,
  [PrismicSlices.YOUTUBE_PANEL_RICHTEXT]: PrismicYouTubePanelWithText,
  [PrismicSlices.HEADLINE_CTA]: PrismicHeadlineWithCallToAction,
  [PrismicSlices.CREATOR_TESTIMONIAL]: PrismicCreatorTestimonial,
  [PrismicSlices.PARTNER_BADGE]: PrismicPartnerBadge,
  [PrismicSlices.STATEMENT_PANEL]: PrismicStatementPanel,
  [PrismicSlices.STATEMENT_PANEL_RICHTEXT]: PrismicStatementPanelWithText,
  [PrismicSlices.CTA_BUTTON]: PrismicCtaButton,
  [PrismicSlices.TRACK_CAROUSEL]: PrismicTrackCarousel,
  [PrismicSlices.YOUTUBE_TRACK_CAROUSEL]: PrismicYouTubeVideoTrackCarousel,
  [PrismicSlices.PLAYLIST_CAROUSEL]: PrismicPlaylistCarousel,
  [PrismicSlices.LINK_CAROUSEL]: PrismicLinkCarousel,
  [PrismicSlices.TOP_PICKS_CAROUSEL]: PrismicTopPicksCarousel,
  [PrismicSlices.FAVOURITES_CAROUSEL]: PrismicFavouritesCarousel,
  [PrismicSlices.SLICE_GROUP_HEADING]: PrismicGroupHeading,
  [PrismicSlices.CATALOGUE_CAROUSEL]: PrismicCatalogueCarousel,
  [PrismicSlices.HOW_IT_WORKS]: PrismicHowItWorks,
  [PrismicSlices.TEXT_PANEL]: PrismicTextPanel,
  [PrismicSlices.PLANS]: PrismicPlans,
  [PrismicSlices.CATALOGUE_TILES]: PrismicCatalogueTiles,
  [PrismicSlices.LINK_TILES]: PrismicLinkTiles,
  [PrismicSlices.PROMO_BANNER]: PrismicPromotionalBanner,
  [PrismicSlices.VERTICAL_QUESTIONNAIRE]: PrismicVerticalQuestionnaire,
  [PrismicSlices.ANNOUNCEMENT]: PrismicAnnouncement,
  [PrismicSlices.PERSONALISED_CAROUSEL]: PrismicPersonalisedCarousel,
  [PrismicSlices.LIST_ITEM_TEXTS]: PrismicLists,
  [PrismicSlices.HOW_IT_WORKS_REFEREE]: PrismicHowItWorksReferee,
  [PrismicSlices.PRICING_IMAGE]: PrismicPricingImage,
  [PrismicSlices.DASHBOARD_HEADER]: PrismicDashboardHeader,
};

const halfWidthSlices = [
  PrismicSlices.CREATOR_PANEL,
  PrismicSlices.CREATOR_PANEL_RICHTEXT,
  PrismicSlices.ARTIST_PANEL,
  PrismicSlices.ARTIST_PANEL_RICHTEXT,
  PrismicSlices.PROMO_PANEL,
  PrismicSlices.PROMO_PANEL_RICHTEXT,
  PrismicSlices.STATEMENT_PANEL,
  PrismicSlices.STATEMENT_PANEL_RICHTEXT,
  PrismicSlices.YOUTUBE_PANEL,
  PrismicSlices.YOUTUBE_PANEL_RICHTEXT,
  PrismicSlices.TEXT_PANEL,
];

export const getEmptySlicePageContext = (): SlicePageContext => ({
  playlists: observable.map(),
  tracks: observable.map(),
});

const renderComponentFromPrismicSlice = (
  slice: PrismicSlice,
  key: number,
  sliceContext: SlicePageContext,
  componentArray: JSX.Element[]
) => {
  if (!(slice.slice_type in prismicSliceMap)) return;
  const Slice = prismicSliceMap[slice.slice_type];
  componentArray.push(
    <AnalyticsContextProvider context={{ prismicSlicePosition: key + 1, prismicSliceType: slice.slice_type }} key={key}>
      <Slice payload={slice} context={sliceContext} />
    </AnalyticsContextProvider>
  );
};

export const renderComponentsFromPrismicSlices = (
  slices: PrismicSlice[],
  sliceContext: SlicePageContext
): JSX.Element[] => {
  const components = [];
  for (let pointer = 0; pointer < slices.length; pointer++) {
    const slice = slices[pointer];
    if (slice.slice_type === PrismicSlices.SLICE_GROUP_HEADING) {
      // Nest components after a group heading into a group component
      const groupComponents = [];
      const groupName = (slice as PrismicGroupHeadingPanelPayload).primary.heading;
      renderComponentFromPrismicSlice(slices[pointer], pointer, sliceContext, groupComponents);
      while (pointer + 1 < slices.length && slices[pointer + 1].slice_type !== PrismicSlices.SLICE_GROUP_HEADING) {
        pointer++;
        renderComponentFromPrismicSlice(slices[pointer], pointer, sliceContext, groupComponents);
      }
      components.push(
        <SliceGroup key={`group-${pointer}`} analyticsIdentifier={groupName}>
          {groupComponents}
        </SliceGroup>
      );
    } else if (halfWidthSlices.includes(slice.slice_type)) {
      // Nest half width components into columns
      const columnComponents = [];
      renderComponentFromPrismicSlice(slices[pointer], pointer, sliceContext, columnComponents);
      const nextSlice = slices[pointer + 1];
      if (!isEmpty(nextSlice) && halfWidthSlices.includes(nextSlice.slice_type)) {
        pointer++;
        renderComponentFromPrismicSlice(slices[pointer], pointer, sliceContext, columnComponents);
      }
      components.push(<TwoColumnSlice key={`two-column-${pointer}`}>{columnComponents}</TwoColumnSlice>);
    } else {
      // Standard rendering case, just render the component into the top level
      renderComponentFromPrismicSlice(slices[pointer], pointer, sliceContext, components);
    }
  }
  return components;
};
