import { AccountChannelPageModel } from './model';
import { action, runInAction } from 'mobx';
import { RouterState, RedirectFunction } from 'react-router';
import { APIController } from '../../api/controller';
import {UserModel} from "../../user/model";
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';
import {stripQueryString} from '../../../lib/string';

export class AccountChannelPageController {
  constructor(
    private user: UserModel,
    private model: AccountChannelPageModel,
    private api: APIController,
    private env: EnvModel,
    private ui: UIController
  ) {}

  @action
  onEnter = async (nextState: RouterState, replace: RedirectFunction) => {
    const identity = stripQueryString(nextState.params['identity']);

    if (!identity) return replace({
      pathname: '/account/channels'
    });

    runInAction(() => {
      this.model.identity = identity;
      this.model.loading = true;
      this.ui.setBreadcrumbs([{
        path: '/account',
        label: 'Account'
      }, {
        path: '/account/channels',
        label: 'Channels'
      }]);
    });

    await this.ui.setSEO(nextState.location.pathname);

    await Promise.all([
      this.loadPage(1),
      this.env.ready
    ]);

    runInAction(() => {
      this.model.loading = false;
    });

    return;
  };

  @action
  loadPage = async (page: number = 1) => {
    runInAction(() => {
      this.model.loadingMoreVideos = true;
    });

    const [channel, videos] = await Promise.all([
      this.api.user.getYouTubeChannel(this.user.user.identity, this.model.identity),
      this.api.user.getYouTubeChannelVideos(this.user.user.identity, this.model.identity, page)
    ])

    runInAction(() => {
      this.model.videos = videos.data;
      this.model.channel = channel.data;
      this.model.videosPagination = videos.meta.pagination;
      this.model.loadingMoreVideos = false;
    });
  };
}