import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const FourSquares: React.FC<SVGIconProps> = ({ size = 16, color = 'currentColor', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="3 3 14 14" {...props}>
    <path
      d="M3.3 4.4c0-.6.5-1 1.1-1h3.4c.6 0 1 .4 1 1v3.4c0 .6-.4 1-1 1H4.4c-.6 0-1-.4-1-1V4.4Zm7.8 0c0-.6.5-1 1.1-1h3.4c.6 0 1 .4 1 1v3.4c0 .6-.4 1-1 1h-3.4c-.6 0-1-.4-1-1V4.4Zm-7.8 7.8c0-.6.5-1 1.1-1h3.4c.6 0 1 .4 1 1v3.4c0 .6-.4 1-1 1H4.4c-.6 0-1-.4-1-1v-3.4Zm7.8 0c0-.6.5-1 1.1-1h3.4c.6 0 1 .4 1 1v3.4c0 .6-.4 1-1 1h-3.4c-.6 0-1-.4-1-1v-3.4Z"
      fill={color}
    />
  </BaseSvg>
);
