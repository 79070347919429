import * as React from 'react';
import { BaseSvg, SVGIconProps } from '../BaseSvg';

export const TwitterLogo: React.SFC<SVGIconProps> = ({ size = 22, color = '#1DA1F2', ...props }) => (
  <BaseSvg width={size} height={size * (22 / 18)} viewBox="0 0 22 18" {...props}>
    <path
      d="M21.996 2.123c-.81.36-1.68.602-2.592.71A4.526 4.526 0 0 0 21.388.337a9.037 9.037 0 0 1-2.866 1.095 4.513 4.513 0 0 0-7.69 4.116 12.81 12.81 0 0 1-9.3-4.715 4.51 4.51 0 0 0 1.396 6.025 4.495 4.495 0 0 1-2.044-.564v.057c0 2.148 1.514 3.999 3.62 4.425a4.52 4.52 0 0 1-2.04.077 4.517 4.517 0 0 0 4.217 3.134 9.055 9.055 0 0 1-5.604 1.93A9.18 9.18 0 0 1 0 15.857a12.773 12.773 0 0 0 6.918 2.027c8.3 0 12.84-6.876 12.84-12.84 0-.195-.005-.39-.014-.583a9.172 9.172 0 0 0 2.252-2.336"
      fill={color}
      fillRule="evenodd"
    />
  </BaseSvg>
);
