import { AccountChannelsPageModel } from './model';
import { action, runInAction } from 'mobx';
import { RouterState, RedirectFunction } from 'react-router';
import { APIController } from '../../api/controller';
import {UserModel} from "../../user/model";
import { EnvModel } from '../../env/model';
import { UIController } from '../../ui/controller';

export class AccountChannelsPageController {
  constructor(
    private user: UserModel,
    private model: AccountChannelsPageModel,
    private api: APIController,
    private env: EnvModel,
    private ui: UIController
  ) {

  }

  @action
  onEnter = async(nextState: RouterState, replace: RedirectFunction) => {
    runInAction(() => {
      this.model.loading = true;

      this.ui.setBreadcrumbs([{
        path: '/account',
        label: 'Account'
      }, {
        path: '/account/channels',
        label: 'Channels'
      }]);
    });

    await this.ui.setSEO(nextState.location.pathname);

    const [channels] = await Promise.all([
      this.api.user.getYouTubeChannels(this.user.user.identity),
      this.env.ready
    ]);

    runInAction(() => {
      this.model.channels = channels.data;
      this.model.loading = false;
    });

    return;
  };
}