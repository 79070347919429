import * as React from 'react';
import { classes, style } from 'typestyle';
import { black, important, percent, px } from 'csx';
import { center, vertical } from 'csstips';
import { colorBackground, colorBlack, colorWhite, rebrand } from '../../../theme/color';
import { transitionQuickEase } from '../../../theme/transition';
import { debounce } from '../utilities/functions';
import { Bars } from './Bars';
export interface SliderValues {
  min: number;
  max: number;
  lower: number;
  upper: number;
  step: number;
}

export interface SliderState extends SliderValues {
  trackingMouse: boolean;
}

export interface SliderProps extends Partial<SliderValues> {
  className?: string;
  onChange?(lower: number, upper: number): void;
  formatValue?(value: number): string;
}

export class Slider extends React.Component<SliderProps, SliderState> {
  $track: HTMLDivElement;
  $left: HTMLDivElement;
  $right: HTMLDivElement;

  state = {
    min: 0,
    max: 100,
    lower: 0,
    upper: 100,
    step: 5,
    trackingMouse: false,
  };

  constructor(props) {
    super(props);
    this.notifyParent = debounce(this.notifyParent, 50);
  }

  render() {
    const { formatValue = Slider.formatValue } = this.props;

    const { min, max, lower, upper } = this.state;

    const range = max - min;
    const left = (lower - min) / range;
    const right = (upper - min) / range;

    const style = {
      left: `${left * 100}%`,
      right: `${(1 - right) * 100}%`,
    };

    const isUnset = lower === min && upper === max;
    return (
      <div className={styles.container}>
        <Bars lower={lower} upper={upper} min={min} max={max} />

        <div
          className={styles.track}
          ref={(el) => (this.$track = el)}
          onTouchStart={this.touch}
          onTouchMove={this.touch}
          onTouchEnd={this.touchEnd}
          onMouseDown={this.mouseDown}
        >
          <div className={classes(styles.slider, isUnset && styles.sliderUnset)} style={style}>
            <div className={classes(styles.handle, styles.handleLeft)} ref={(el) => (this.$left = el)} />
            <div className={classes(styles.handle, styles.handleRight)} ref={(el) => (this.$right = el)} />
          </div>
        </div>
        <div className={styles.labelWrapper}>
          <div className={classes(styles.label, styles.labelLeft)}>{formatValue(lower)}</div>
          <div className={classes(styles.label, styles.labelRight)}>{formatValue(upper)}</div>
        </div>
      </div>
    );
  }

  UNSAFE_componentWillMount() {
    this.syncState(this.props);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.mouseUp);
    window.removeEventListener('mousemove', this.mouseMove);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.syncState(nextProps);
  }

  touch = ({ targetTouches }: React.TouchEvent<HTMLDivElement>) => {
    switch (targetTouches.length) {
      case 1:
        return this.updateSingle(targetTouches[0].clientX);
      case 2:
        return this.updateDouble(targetTouches[0].clientX, targetTouches[1].clientX);
      default:
        return;
    }
  };

  touchEnd = () => this.notifyParent();

  mouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    this.updateSingle(event.clientX);
    this.setState({ trackingMouse: true });

    window.addEventListener('mouseup', this.mouseUp);
    window.addEventListener('mousemove', this.mouseMove);

    event.preventDefault();
  };

  mouseMove = (event: MouseEvent) => {
    this.updateSingle(event.clientX);
  };

  mouseUp = () => {
    this.setState({ trackingMouse: false });

    window.removeEventListener('mouseup', this.mouseUp);
    window.removeEventListener('mousemove', this.mouseMove);

    this.notifyParent();
  };

  private updateSingle = (x: number) => {
    if (!this.$track) return;

    const { max, min, lower, upper, step } = this.state;
    const { width, left } = this.$track.getBoundingClientRect();

    const progress = (x - left) / width;
    const value = Math.round(progress * (max - min)) + min;

    const diffLower = Math.abs(value - lower);
    const diffUpper = Math.abs(value - upper);

    const nextLower = diffLower <= diffUpper ? Math.min(value, upper) : lower;
    const nextUpper = diffLower > diffUpper ? Math.max(value, lower) : upper;

    this.setState({
      lower: Math.max(Math.round(nextLower / step) * step, min),
      upper: Math.min(Math.round(nextUpper / step) * step, max),
    });
  };

  private updateDouble = (x1: number, x2: number) => {
    if (!this.$track) return;

    const { max, min, step } = this.state;
    const { width, left } = this.$track.getBoundingClientRect();

    const progress1 = (x1 - left) / width;
    const progress2 = (x2 - left) / width;

    const value1 = Math.round(progress1 * (max - min)) + min;
    const value2 = Math.round(progress2 * (max - min)) + min;

    const nextLower = Math.min(value1, value2);
    const nextUpper = Math.max(value1, value2);

    this.setState({
      lower: Math.max(Math.round(nextLower / step) * step, min),
      upper: Math.min(Math.round(nextUpper / step) * step, max),
    });
  };

  private syncState = (props: SliderProps) =>
    this.setState(
      ['min', 'max', 'lower', 'upper', 'step'].reduce(
        (state, key) => ({
          ...state,
          [key]: typeof props[key] !== 'undefined' ? props[key] : state[key],
        }),
        {}
      )
    );

  private readonly notifyParent = () => {
    if (this.props.onChange) {
      this.props.onChange(this.state.lower, this.state.upper);
    }
  };

  static formatValue = (value: number) => value.toString();
}

const styles = {
  container: style({
    ...vertical,
    ...center,
  }),
  track: style({
    cursor: 'pointer',
    margin: '0 16px',
    width: percent(100),
    borderRadius: px(4),
    background: colorBackground.darken(0.1).toString(),
    height: px(5),
    position: 'relative',
  }),
  slider: style({
    position: 'absolute',
    top: 0,
    bottom: 0,
    borderRadius: px(4),
    transition: `background ${transitionQuickEase}`,
    background: '#FD57C9',
  }),
  sliderUnset: style({
    background: important(colorBackground.darken(0.1).toString()),
    $nest: {
      '& :after': {
        background: colorWhite.toString(),
      },
    },
  }),
  handle: style({
    borderRadius: px(24),
    position: 'absolute',
    top: px(-20),
    width: px(24),
    height: px(24),
    cursor: 'pointer',
    backgroundColor: 'transparent',
    $nest: {
      '&:after': {
        transition: `background ${transitionQuickEase}`,
        display: 'block',
        content: '""',
        backgroundColor: colorWhite.toString(),
        position: 'absolute',
        borderRadius: percent(100),
        width: px(24),
        height: px(24),
        top: px(10),
        border: `5px solid ${colorBlack}`,
      },
    },
  }),
  handleLeft: style({
    left: px(-15),
  }),
  handleRight: style({
    right: px(-15),
  }),
  labelWrapper: style({
    display: 'flex',
    width: percent(100),
    justifyContent: 'space-between',
    marginTop: px(12),
  }),
  label: style({
    fontSize: px(12),
    minWidth: px(50),
  }),
  labelLeft: style({
    color: rebrand.dark1.toString(),
    fontWeight: 700,
    fontSize: px(16),
    textAlign: 'left',
  }),
  labelRight: style({
    color: rebrand.dark1.toString(),
    fontWeight: 700,
    fontSize: px(16),
    textAlign: 'right',
  }),
};
