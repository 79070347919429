import * as React from 'react';
import { observer } from 'mobx-react';
import { ButtonCircle } from './button-circle';
import { SVGIcon } from './svg-icon';
import { classes, style } from 'typestyle';
import { shadowSubtle } from '../theme/shadow';
import { transitionQuickEase } from '../theme/transition';
import {
  colorBrand,
  colorDarkBlueGrey,
  colorGreyAccent,
  colorGunmetal,
  colorLickdPink,
  colorWhite,
} from '../theme/color';
import { em, important, px } from 'csx';
import { animationPulse } from '../theme/animation';
import { animationScaleUpDown } from '../theme/animation';
import { HeartOutline } from './project-happy/atoms/icons/HeartOutline';

export interface ButtonFavouriteProps {
  className?: string;
  isFavourite?: boolean;
  iconBounce?: boolean;
  onClick?(event: React.MouseEvent<any>): void;
  isSync?: boolean;
  size?: number;
  label?: string;
  withLabel?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  i18n?: {};
}

@observer
export class ButtonFavourite extends React.Component<ButtonFavouriteProps, any> {
  render() {
    const {
      i18n = ButtonFavourite.i18n,
      size,
      isFavourite,
      isSync,
      className,
      onClick,
      label,
      withLabel = false,
      iconBounce,
      children,
      ...props
    } = this.props;

    const iconSize = Math.floor(size / 2) - 2;
    const fontSize = px(size / 2 - 1);

    const cz = classes(
      'favourite-button',
      ButtonFavourite.styles.container,
      isFavourite ? ButtonFavourite.styles.added : ButtonFavourite.styles.removed,
      isSync && ButtonFavourite.styles.sync,
      withLabel && ButtonFavourite.styles.withLabel,
      iconBounce && ButtonFavourite.styles.iconBounce,
      className
    );

    return (
      <ButtonCircle
        className={cz}
        size={size}
        onClick={onClick}
        aria-label={isFavourite ? 'Remove from favourites' : 'Add to favourites'}
        {...props}
      >
        {isFavourite ? <SVGIcon.Favourite size={iconSize} /> : <HeartOutline />}
        {withLabel && <span style={{ fontSize }}>{label}</span>}
      </ButtonCircle>
    );
  }

  static defaultProps = {
    size: 28,
    isFavourite: false,
    isSync: false,
  };

  static i18n = {};

  static styles = {
    container: style({
      $nest: {
        '& path': {
          transition: `all ${transitionQuickEase}`,
        },
        '& svg': {
          marginTop: em(1 / 16),
          transition: `all ${transitionQuickEase}`,
        },
        '& span': {
          color: colorGunmetal.toString(),
          transition: `all ${transitionQuickEase}`,
        },
      },
    }),
    withLabel: style({
      width: important('auto'),
      padding: '0 1em 0 0.6em',
      $nest: {
        '& span': {
          margin: '0 0 0 0.5em',
          lineHeight: '1em',
        },
        '& svg': {
          margin: '-1px 0 0 0',
        },
      },
    }),
    added: style({
      backgroundColor: important(colorGreyAccent.toString()),
      $nest: {
        '& path': {
          stroke: colorLickdPink.toString(),
          fill: colorLickdPink.toString(),
        },
        '&:hover': {
          backgroundColor: important(colorDarkBlueGrey.toString()),
        },
        '& span': {
          color: colorLickdPink.toString(),
        },
      },
    }),
    iconBounce: style({
      $nest: {
        '& svg': {
          ...animationScaleUpDown,
        },
      },
    }),
    removed: style({
      backgroundColor: colorGreyAccent.toString(),
      $nest: {
        '&:hover': {
          backgroundColor: colorDarkBlueGrey.toString(),
        },
        '& span': {
          color: colorBrand.toString(),
        },
        '&:hover span': {
          color: colorWhite.toString(),
        },
      },
    }),
    sync: style({
      $nest: {
        '& path': {
          ...animationPulse,
        },
      },
    }),
  };
}
