import React from 'react';
import { classes, style } from 'typestyle';
import { CtaButton } from './panels/atoms/CtaButton';
import { AddCircle } from '../atoms/icons/AddCircle';
import { Component } from '../../../lib/component';

const styles = {
  containerConnectChannel: style({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  }),
  connectChannelButton: style({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '37px',
  }),
};
export type ConnectChannelPromptProps = {
  showChannelAddModal: () => void;
  sendMixpanel?: any;
  className?: string;
  wrapperClassName?: string;
  location?: string;
  loading?: boolean;
  hideIcon?: boolean;
  text?: string;
};

export class ConnectChannelPrompt extends Component<ConnectChannelPromptProps> {
  render() {
    const {
      showChannelAddModal,
      className,
      sendMixpanel,
      location,
      loading,
      wrapperClassName,
      hideIcon = false,
      text,
    } = this.props;
    return (
      <div className={classes(styles.containerConnectChannel, wrapperClassName)}>
        <CtaButton
          onClick={() => {
            showChannelAddModal(), sendMixpanel('User clicks connect channel prompt', { route: { location } });
          }}
          data-test-connect-channel-button
          className={classes(styles.connectChannelButton, className)}
          loading={loading}
          disabled={loading}
        >
          {text || 'Connect Channel'}
          {!hideIcon && <AddCircle size={24} style={{ marginLeft: '8px' }} />}
        </CtaButton>
      </div>
    );
  }
}
