import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const ErrorIcon: React.SFC<SVGIconProps> = ({ size = 24, isSquare, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 20 20" {...props}>
    <path
      fill={color}
      transform="translate(-2 -2)"
      fillRule="evenodd"
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm1 15h-2v-2h2v2Zm0-4h-2V7h2v6Z"
    />
  </BaseSvg>
);
