import React from 'react';
import { AbstractStatefulCheckbox } from './AbstractStatefulCheckbox';
import { setOnboardingSessionValues } from '../../utilities/inputs';
import { preventAndCall } from '../../utilities/functions';
import { ComponentProps, deps, inject, observer } from '../../../../lib/component';
import { LabelledCheckbox } from './LabelledCheckbox';
import { classes, style } from 'typestyle';
import { px } from 'csx';
import { colorCrimson, colorGunmetal } from '../../../../theme/color';

const styles = {
  wrapper: style({
    $nest: {
      '& a': {
        fontWeight: 'bold',
      },
    },
  }),
  error: style({
    fontSize: px(12),
    marginTop: px(8),
    marginBottom: 0,
    textAlign: 'left',
    color: colorCrimson.toHexString(),
    display: 'none',
    $nest: {
      '.show-errors &': { display: 'block' },
    },
  }),
};

export type TermsCheckboxProps = ComponentProps & { className?: string };

@inject(deps)
@observer
export class TermsCheckbox extends AbstractStatefulCheckbox<TermsCheckboxProps> {
  clickHandler(event: React.ChangeEvent<HTMLInputElement>) {
    super.clickHandler(event);
    setOnboardingSessionValues({ termsCheckbox: this.state.checked });
  }

  render() {
    const {
      className,
      controller: {
        onboarding: { showTermsConditionsModal, showPrivacyPolicyModal },
      },
    } = this.props;
    const isValid = this.state.checked;
    return (
      <span className={classes(className, styles.wrapper)}>
        <LabelledCheckbox onChange={this.clickHandler} checked={this.state.checked} required>
          I accept the{' '}
          <a href="/terms-and-conditions" onClick={preventAndCall(showTermsConditionsModal)}>
            Terms and Conditions
          </a>{' '}
          and{' '}
          <a href="/privacy-policy" onClick={preventAndCall(showPrivacyPolicyModal)}>
            Privacy Policy
          </a>
        </LabelledCheckbox>
        {!isValid && <p className={styles.error}>Please agree to the terms and policy</p>}
      </span>
    );
  }
}
