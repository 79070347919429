import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const Copyright: React.SFC<SVGIconProps> = ({ size = 24, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={size} viewBox="0 0 24 24" {...props}>
    <path
      d="M11.88 9.14c1.28.06 1.61 1.15 1.63 1.66h1.79c-.08-1.98-1.49-3.19-3.45-3.19C9.64 7.61 8 9 8 12.14c0 1.94.93 4.24 3.84 4.24 2.22 0 3.41-1.65 3.44-2.95h-1.79c-.03.59-.45 1.38-1.63 1.44-1.31-.04-1.86-1.06-1.86-2.73 0-2.89 1.28-2.98 1.88-3ZM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Z"
      fill={color}
      fillRule="evenodd"
    />
  </BaseSvg>
);
