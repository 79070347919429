import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const AudioFile: React.SFC<SVGIconProps> = ({ size = 18, isSquare, color = '#2E2E3B', ...props }) => (
  <BaseSvg width={size} height={isSquare ? size : size * (18 / 14)} viewBox="0 0 14 18" {...props}>
    <path
      d="M10 0H2C.9 0 .01.9.01 2L0 18c0 1.1.89 2 1.99 2H14c1.1 0 2-.9 2-2V6l-6-6Zm-.8571429 9v1.1428571H7.4285714v5.1428572C7.4285714 16.2342857 6.6628571 17 5.7142857 17 4.7657143 17 4 16.2342857 4 15.2857143c0-.9485714.7657143-1.7142857 1.7142857-1.7142857.2 0 .3942857.04.5714286.1028571V9h2.8571428ZM9 1.5 14.5 7H9V1.5Z"
      fill={color}
      transform="translate(-2 -2)"
      fillRule="evenodd"
    />
  </BaseSvg>
);
