import { deps, inject, observer, StatefulComponent } from '../../lib/component';
import { percent, px, rem } from 'csx';
import { classes, style } from 'typestyle';
import * as React from 'react';
import imgNormal from '../../theme/icon/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web@2x.png';
import imgFocus from '../../theme/icon/google_signin_buttons/web/2x/btn_google_signin_dark_focus_web@2x.png';
import imgPressed from '../../theme/icon/google_signin_buttons/web/2x/btn_google_signin_dark_pressed_web@2x.png';
import { colorBrand, colorSignInInfo } from '../../theme/color';
import infoIcon from '../../static/ico-tip.svg';
import GoogleButton from './google-button';

export enum SignInType {
  REGISTER,
  SIGNIN,
}

type Props = {
  className?: string;
  onClick?(event: React.MouseEvent<HTMLAnchorElement>, type: SignInType): any;
};

type State = {
  imgSrc: string;
};

@inject(deps)
@observer
export default class GoogleSignIn extends StatefulComponent<Props, State> {
  state = {
    imgSrc: imgNormal,
  };

  render() {
    const { youtubeLoginURL, user } = this.props.model.auth;
    const { className } = this.props;

    if (user) return null;

    const { imgSrc } = this.state;
    const focusImg = this.setImage.bind(this, imgFocus);
    const normalImg = this.setImage.bind(this, imgNormal);
    const pressImg = this.setImage.bind(this, imgPressed);

    return (
      <div className={GoogleSignIn.styles.container}>
        <GoogleButton
          className={classes(GoogleSignIn.styles.link, className)}
          href={youtubeLoginURL}
          onClick={(evt) => this.handleClick(evt, SignInType.REGISTER)}
        />
        {/* <a
          className={classes(GoogleSignIn.styles.link, className)}
          href={youtubeLoginURL}
          rel="nofollow"
          onClick={(evt) => this.handleClick(evt, SignInType.REGISTER)}
          onFocus={focusImg}
          onBlur={normalImg}
          onMouseDown={pressImg}
          onMouseUp={normalImg}
          onKeyDown={pressImg}
          onKeyUp={normalImg}
        >
          <img src={imgSrc} alt="Sign in with Google" />
        </a> */}
        <p className={GoogleSignIn.styles.loginLink}>
          <a href={youtubeLoginURL} rel="nofollow" onClick={(evt) => this.handleClick(evt, SignInType.SIGNIN)}>
            Already have an account? Log in
          </a>
        </p>
      </div>
    );
  }

  handleClick = (event: React.MouseEvent<HTMLAnchorElement>, type: SignInType) => {
    this.props.controller.analytics.sendMixpanel('User clicks single sign on');

    if (this.props.onClick) {
      this.props.onClick(event, type);
    }
  };

  setImage = (imgSrc: string) => this.setState({ imgSrc });

  static styles = {
    container: style({
      zIndex: 42,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }),
    link: style({
      display: 'inline-block',
      $nest: {
        '&, & img': {
          fontSize: px(14),
        },
        img: {
          display: 'block',
        },
      },
    }),
    loginLink: style({
      textAlign: 'center',
      fontSize: px(12),
      marginTop: px(8),
      $nest: {
        '&, &:visited': {
          color: colorBrand.toString(),
          textDecoration: 'underline',
        },
      },
    }),
    info: style({
      margin: '10px 0 0 0',
      textAlign: 'left',
      fontSize: rem(0.818),
      fontWeight: 500,
      color: colorSignInInfo.toString(),
      position: 'relative',
    }),
    infoIcon: style({
      width: '13px',
      height: '13px',
      position: 'absolute',
      left: px(-25),
      top: px(5),
    }),
  };
}
