import React from 'react';
import { Button, ButtonProps } from './Button';
import { classes, style } from 'typestyle';
import { center, flexRoot } from 'csstips';
import { px } from 'csx';
import { ButtonProminence, ButtonSize } from './buttonStyles';
import { AddCircle } from '../icons/AddCircle';

const styles = {
  button: style(flexRoot, center, {
    margin: '12px 0px 0px 8px',
  }),
  plus: style({
    marginRight: px(5),
  }),
};

export type LoadMoreProps = ButtonProps & { loading?: boolean };

export const LoadMoreButton: React.FC<LoadMoreProps> = ({
  children,
  className,
  prominence,
  size,
  disabled,
  loading,
  ...props
}) => (
  <Button
    {...props}
    className={classes(styles.button, className)}
    size={size || ButtonSize.XSMALL}
    prominence={prominence || ButtonProminence.HIGHLIGHT}
    disabled={disabled || loading}
    data-test-load-more
  >
    <AddCircle className={styles.plus} size={13} /> Load More
  </Button>
);
