import * as React from 'react';
import {classes, style} from 'typestyle';
import {percent} from 'csx';
import {deps, inject, observer, StatefulComponent} from '../lib/component';

type Props = {
  className?: string;
  children?: string;
  refresh?: number; // pass in width/height of super-parent to force re-calculation
}

type State = {
  fontSize: number;
  loaded: boolean;
}

@inject(deps) @observer
export default class FullWidthText extends StatefulComponent<Props, State> {
  $outer?: HTMLSpanElement;
  $inner?: HTMLSpanElement;

  state: State = {
    fontSize: 0,
    loaded: false
  };

  render() {
    const {
      className,
      children,
    } = this.props;

    const {
      loaded,
      fontSize
    } = this.state;

    const style = loaded
      ? {fontSize}
      : {};

    return (
      <span
        ref={el => this.$outer = el}
        className={classes(FullWidthText.styles.container, className)}
        children={(
          <span
            ref={el => this.$inner = el}
            style={style}
            children={children}
          />
        )}
      />
    )
  }

  componentDidMount() {
    this.rescaleText();
  }

  componentDidUpdate(props: Props) {
    if (this.props.children !== props.children || this.props.refresh !== props.refresh) {
      this.rescaleText();
    }
  }

  protected rescaleText = () => {
    if (!this.$outer || !this.$inner || !this.props.children)
      return;

    const outerStyle = getComputedStyle(this.$outer);
    const inheritedFontSize = parseFloat(outerStyle.fontSize);
    const outerPadding
      = (outerStyle.paddingLeft ? parseFloat(outerStyle.paddingLeft) : 0)
      + (outerStyle.paddingRight ? parseFloat(outerStyle.paddingRight) : 0);

    const outerWidth = this.$outer.clientWidth - outerPadding;
    const innerWidth = this.$inner.getBoundingClientRect().width;

    if (isNaN(inheritedFontSize))
      return;

    const fontSize = innerWidth <= outerWidth
      ? inheritedFontSize
      : (outerWidth / innerWidth) * inheritedFontSize;

    if (!Number.isFinite(fontSize)) return;

    this.setState({loaded: true, fontSize});
  };

  static styles = {
    container: style({
      width: percent(100),
      $nest: {
        '&>span': {
          whiteSpace: 'nowrap',
        }
      }
    })
  }
}