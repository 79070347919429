import * as React from 'react';
import { url } from 'csx';
import { RatioTile } from './ratio-tile';

export interface ImageTileProps {
  src: string;
  className?: string;
  onClick?(event: React.MouseEvent<any>): void;
  ratio?: number;
  href?: string;
}

export class ImageTile extends React.Component<ImageTileProps, any> {
  public static defaultProps = {
    ratio: 1,
  };

  render() {
    const { children, src, className, onClick, ratio, href } = this.props;

    return (
      <RatioTile className={className} onClick={onClick} ratio={ratio} href={href}>
        <div style={{ backgroundImage: url(src) }} />
        {children}
      </RatioTile>
    )
  }
}