import { AnyObject } from './objects';

type Payload = AnyObject | boolean | string | number;
type Handler = (payload?: Payload) => void;
type RegisterableEvent<T> = T | '*';
export default class EventBus<T extends string = string> {
  private listeners: { [key: string]: Set<Handler> } = {
    '*': new Set(),
  };

  private static checkParameters<T extends string>(event: T, listener: Handler) {
    if (typeof event !== 'string') throw Error('The event passed was not a string');
    if (typeof listener !== 'function') throw Error('The listener passed was not a function');
  }

  addListener(event: RegisterableEvent<T>, listener: Handler) {
    EventBus.checkParameters(event, listener);
    if (!(event in this.listeners)) {
      this.listeners[event] = new Set();
    }
    this.listeners[event].add(listener);
  }

  removeListener(event: RegisterableEvent<T>, listener: Handler) {
    EventBus.checkParameters(event, listener);
    if (!(event in this.listeners)) return;
    this.listeners[event].delete(listener);
  }

  emit(event: T, payload?: Payload) {
    if (event !== '*') {
      this.emit('*' as T, { event, payload });
    }
    if (!(event in this.listeners)) return;
    this.listeners[event].forEach((listener) => listener(payload));
  }
}
