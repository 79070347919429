import * as React from 'react';
import { BaseSvg, SVGIconProps } from './BaseSvg';

export const AccessTime: React.SFC<SVGIconProps> = ({ size = 14, color = '#262E3F', ...props }) => (
  <BaseSvg data-test-premium-star-circle width={size} height={size} viewBox="0 0 14 14" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.333252 6.99992C0.333252 10.6833 3.31659 13.6666 6.99992 13.6666C10.6833 13.6666 13.6666 10.6833 13.6666 6.99992C13.6666 3.31659 10.6833 0.333252 6.99992 0.333252C3.31659 0.333252 0.333252 3.31659 0.333252 6.99992ZM6.16658 2.83325H7.41658V7.20825L11.1666 9.43325L10.5416 10.4582L6.16658 7.83325V2.83325Z"
      fill={color}
    />
  </BaseSvg>
);
