import * as React from 'react';
import { classes, style } from 'typestyle';
import { px, percent } from 'csx';
import { PageCarouselWithControls, PageCarouselWithControlsProps } from '../molecules/PageCarouselWithControls';
import { BasePageCarousel } from '../atoms/carousel/BasePageCarousel';
import { PageCarousel } from '../molecules/PageCarousel';
import { heading4 } from '../utilities/font';
import { betweenJustified, center, centerJustified, flexRoot } from 'csstips';
import { mediaDesktop, mediaUpToDesktop } from '../utilities/mediaQueries';
import { AnalyticsContextProvider } from '../utilities/AnalyticsContext';

const third = percent(100 / 3);
const styles = {
  information: style(flexRoot, betweenJustified, center, {
    marginBottom: px(16),
    $nest: {
      '& > h3:nth-child(1), & > div:nth-child(3)': {
        minWidth: third,
      },
    },
  }),
  heading: style(heading4, { margin: 0 }),
  headingFixedWidth: style({ width: third }),
  indicators: style(
    {
      alignItems: 'flex-end',
      height: px(24),
    },
    mediaUpToDesktop({
      display: 'none',
    }),
    mediaDesktop(flexRoot)
  ),
  centredIndicators: style(centerJustified, {
    width: third,
    $nest: {
      '&:last-child': {
        marginRight: third,
      },
    },
  }),
  indicator: style({
    width: px(40),
    height: px(4),
    background: '#E0E4E9',
    margin: '0 8px',
    borderRadius: px(2),
    cursor: 'pointer',
    $nest: {
      '&:only-child': {
        $unique: true,
        display: 'none', // Don't show carousel indicators if there's only one page
      },
    },
  }),
  activeIndicator: style({
    background: '#B7C2CC',
    cursor: 'default',
  }),
};

export type DecoratedPageCarouselProps = PageCarouselWithControlsProps & {
  controlClassName?: string;
  heading?: string;
  indicators?: boolean;
  indicatorAlignment?: 'centre';
  withControls?: boolean;
  actions?: JSX.Element;
  basecarouselClassName?: string;
};

export class DecoratedPageCarousel extends PageCarousel<DecoratedPageCarouselProps> {
  render() {
    const {
      heading,
      indicators = true,
      indicatorAlignment,
      withControls = true,
      onPageChange,
      actions,
      className,
      controlClassName,
      basecarouselClassName,
      ...props
    } = this.props;
    const indicatorChildren = Array(this.state.pages).fill(null);
    const centreIndicator = !actions && indicatorAlignment === 'centre';
    return (
      <AnalyticsContextProvider context={{ carouselHeading: heading || '[Empty]' }}>
        <div className={className}>
          <div className={styles.information}>
            {heading ? (
              <h3 className={classes(styles.heading, centreIndicator && styles.headingFixedWidth)}>{heading}</h3>
            ) : (
              <span />
            )}
            {indicators && (
              <div className={classes(styles.indicators, centreIndicator && styles.centredIndicators)}>
                {indicatorChildren.map((_, position) => (
                  <span
                    key={position}
                    className={classes(styles.indicator, position + 1 === this.workingPage && styles.activeIndicator)}
                    onClick={() => this.updatePage(position + 1)}
                  />
                ))}
              </div>
            )}
            {actions && actions}
          </div>
          {withControls && (
            <PageCarouselWithControls
              className={controlClassName}
              page={this.workingPage}
              onPageChange={this.updatePage.bind(this)}
              {...props}
            />
          )}
          {!withControls && (
            <PageCarousel
              page={this.workingPage}
              onPageChange={this.updatePage.bind(this)}
              className={basecarouselClassName}
              {...props}
            />
          )}
        </div>
      </AnalyticsContextProvider>
    );
  }
}
