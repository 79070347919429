import * as React from 'react';
import { BaseSvg, SVGIconProps } from '../BaseSvg';

export const FacebookLogo: React.SFC<SVGIconProps> = ({ size = 24, color = '#3A5998', ...props }) => (
  <BaseSvg width={size} height={size * (22 / 14)} viewBox="0 0 14 22" {...props}>
    <path
      d="M13.65.16C13.244.106 11.844 0 10.22 0c-3.4 0-5.733 1.825-5.733 5.17v2.882H.65v3.913h3.837V22h4.604V11.965h3.823l.587-3.913h-4.41v-2.5c0-1.123.347-1.903 2.198-1.903h2.361V.16Z"
      fill={color}
      fillRule="evenodd"
    />
  </BaseSvg>
);
