import * as React from 'react';
import { observer } from 'mobx-react';
import { classes, style } from 'typestyle';
import { colorBrand, colorError, colorSubtle } from '../theme/color';
import { em, percent, px } from 'csx';
import { transitionQuickEase } from '../theme/transition';
import { FormInputExpiry } from './form-input-expiry';
import { FormInputAddress } from './form-input-address';
import { FormInputCheckbox } from './form-input-checkbox';
import { FormInputDate } from './form-input-date';
import { FormInputSelectWithOther } from './form-input-select-with-other';
import { FormField } from '../types/form';
import { COUNTRIES, ENGLISH_COUNTRIES, LANGUAGES, SUPPORT_HUB } from '../constants';

export interface FormInputProps {
  model: FormField<any>;
  disabled?: boolean;
  onChange(field: string, value: any): void;
}

let idCounter = 0;

@observer
export class FormInput extends React.Component<FormInputProps, any> {
  render() {
    const { model, disabled } = this.props;

    const label = FormInput.styles.label;
    const labelActive = classes(FormInput.styles.label, FormInput.styles.labelVisible);

    const id = (this.props.model.type || 'field') + '-' + ++idCounter;

    const inputProps = {
      id,
      className: classes(FormInput.styles.input, !model.value && FormInput.styles.inputEmpty),
      placeholder: model.label + (model.required ? ' (required)' : ''),
      name: model.name,
      value: model.value,
      disabled: disabled || model.disabled,
      required: model.required,
      onChange: this.handleChange,
      autoComplete: model.autoComplete,
    };

    const C = model.type === 'expiry' || model.type === 'checkbox' ? FormInput.WrapDiv : FormInput.WrapLabel;

    return (
      <C className={FormInput.styles.container} htmlFor={id}>
        {model.type !== 'checkbox' && (
          <div className={classes(model.value ? labelActive : label, model.required && FormInput.styles.required)}>
            {model.label}
          </div>
        )}
        {this.renderField(inputProps, model)}
        {model.helpText && <div className={FormInput.styles.helpText}>{model.helpText}</div>}
        {model.error && <div className={FormInput.styles.error}>{model.error}</div>}
      </C>
    );
  }

  static WrapLabel: any = (props: any) => <label {...props} />;
  static WrapDiv: any = ({ htmlFor, ...props }: any) => <div {...props} />;

  renderField = (inputProps: any, model: FormField<any>) => {
    const { type = 'text' } = this.props.model;

    switch (type) {
      case 'select':
        return (
          <select {...inputProps} className={classes(inputProps.className, FormInput.styles.select)}>
            <option value="">{inputProps.placeholder}</option>
            {model.options.map((option, index) => (
              <option key={index.toString()} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );

      case 'country':
        return [
          <select
            key="country-select"
            {...inputProps}
            className={classes(inputProps.className, FormInput.styles.select)}
          >
            <option value="">Country</option>
            {ENGLISH_COUNTRIES.map((code, index) => (
              <option key={index.toString()} value={code}>
                {COUNTRIES[code]}
              </option>
            ))}
            <option value=" ">------</option>
            {Object.keys(COUNTRIES)
              .sort((a, b) => (COUNTRIES[a] > COUNTRIES[b] ? 1 : -1))
              .map((code) => (
                <option key={code} value={code}>
                  {COUNTRIES[code]}
                </option>
              ))}
          </select>,
          inputProps.disabled && (
            <p key="disabled-country-prompt">
              Need to update your country? <a href={SUPPORT_HUB}>Submit a support ticket</a>
            </p>
          ),
        ];

      case 'language':
        return (
          <select {...inputProps} className={classes(inputProps.className, FormInput.styles.select)}>
            <option value="">Language</option>
            {Object.keys(LANGUAGES).map((code) => (
              <option key={code} value={code}>
                {LANGUAGES[code]}
              </option>
            ))}
          </select>
        );

      case 'expiry':
        return <FormInputExpiry {...inputProps} onChange={this.props.onChange} />;

      case 'address':
        return <FormInputAddress {...inputProps} onChange={this.props.onChange} options={model.options} />;

      case 'checkbox':
        return <FormInputCheckbox {...inputProps} onChange={this.props.onChange} />;

      case 'date':
        return <FormInputDate {...inputProps} onChange={this.props.onChange} />;

      case 'select-with-other':
        return <FormInputSelectWithOther {...inputProps} options={model.options} onChange={this.props.onChange} />;

      case 'cc':
        return <input {...inputProps} type="number" pattern="[0-9]*" />;

      case 'textarea':
        return <textarea {...inputProps} />;

      default:
        return <input type={type} {...inputProps} />;
    }
  };

  handleChange = (event: React.FormEvent<any>) => {
    const { name, value } = event.target as HTMLInputElement;
    this.props.onChange(name, value);
  };

  static styles = {
    container: style({
      position: 'relative',
      display: 'block',
      margin: 0,
      padding: 0,
    }),
    label: style({
      position: 'absolute',
      top: 0,
      left: 0,
      fontSize: px(12),
      lineHeight: 1.1,
      opacity: 0,
      transform: 'translate3d(0,4px,0)',
      transition: `all ${transitionQuickEase}`,
    }),
    labelVisible: style({
      opacity: 1,
      transform: 'translate3d(0,0,0)',
    }),
    input: style({
      width: percent(100),
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: 0,
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      borderBottom: `1px solid ${colorSubtle.toString()}`,
      padding: '8px 0',
      margin: '10px 0',
      $nest: {
        '&:placeholder': {
          color: colorSubtle.toString(),
        },
        '&:hover': {
          borderBottom: `2px solid ${colorSubtle.toString()}`,
          padding: '8px 0 7px',
        },
        '&&:focus': {
          borderBottom: `2px solid ${colorBrand.toString()}`,
          outline: 'none',
          padding: '8px 0 7px',
        },
        '&&&[disabled]': {
          borderBottom: `1px dashed ${colorSubtle.toString()}`,
        },
        '&&&&[disabled]:hover': {
          borderBottom: `1px dashed ${colorSubtle.toString()}`,
          padding: '8px 0',
        },
      },
    }),
    select: style({
      backgroundRepeat: 'no-repeat',
      backgroundImage: `linear-gradient(45deg, transparent 50%, ${colorSubtle.toString()} 50%), linear-gradient(135deg, ${colorSubtle.toString()} 50%, transparent 50%)`,
      backgroundPosition: `calc(100% - 10px) calc(1em), calc(100% - 5px) calc(1em)`,
      backgroundSize: `5px 5px, 5px 5px`,
      padding: '8px 24px 8px 0',
      $nest: {
        '&:hover': {
          padding: '8px 24px 7px 0',
        },
        '&&:focus': {
          padding: '8px 24px 7px 0',
        },
        '&&&&&[disabled]:hover': {
          padding: '8px 24px 8px 0',
        },
      },
    }),
    inputEmpty: style({
      color: `${colorSubtle.toString()} !important`,
    }),
    error: style({
      color: colorError.toString(),
      lineHeight: em(1.1),
      fontSize: px(12),
      margin: '0 0 10px 0',
    }),
    helpText: style({
      color: colorSubtle.toString(),
      lineHeight: em(1.1),
      fontSize: px(12),
      margin: '0 0 10px 0',
    }),
    required: style({
      $nest: {
        '&::after': {
          position: 'absolute',
          top: 0,
          right: px(-5),
          content: '"*"',
          display: 'block',
          color: colorError.toString(),
          fontSize: px(8),
        },
      },
    }),
  };
}
