import * as React from 'react';
import { classes, style } from 'typestyle';
import {horizontal} from 'csstips';
import {FormFieldProps} from '../types/form';

export interface FormInputSelectWithOtherProps extends FormFieldProps {
  i18n?: {
    other: string;
    otherPlaceholder: string;
  }
}

export interface FormInputSelectWithOtherState {
  value: string;
  other: string;
}

export class FormInputSelectWithOther extends React.Component<FormInputSelectWithOtherProps, FormInputSelectWithOtherState> {
  static OTHER = 'Other';

  private $other: HTMLInputElement;

  state = {
    other: '',
    value: ''
  };

  render() {
    const { className, options, placeholder, i18n = FormInputSelectWithOther.i18n } = this.props;
    const { other, value } = this.state;

    const commonProps = {
      disabled: this.props.disabled,
      required: this.props.required,
      className
    };

    const showOther = value === FormInputSelectWithOther.OTHER;

    return (
      <div className={FormInputSelectWithOther.styles.container}>
        <select {...commonProps} className={classes(className, showOther && FormInputSelectWithOther.styles.selectOther)} id={this.props.id + '-1'} value={showOther ? FormInputSelectWithOther.OTHER : value} onChange={this.handleChangeSelect}>
          <option value="">{placeholder}</option>
          {options && options.length > 0 && options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
          <option value={FormInputSelectWithOther.OTHER}>{i18n.other}</option>
        </select>
        {showOther && (
          <input
            {...commonProps}
            className={classes(className, FormInputSelectWithOther.styles.other)}
            placeholder={i18n.otherPlaceholder}
            value={other}
            onChange={this.handleChangeOther}
            ref={el => this.$other = el}
          />
        )}
      </div>
    )
  }

  UNSAFE_componentWillMount() {
    this.handleValue(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleValue(nextProps);
  }

  handleValue = (props: FormInputSelectWithOtherProps) => {
    if (!props.options || props.options.length === 0) {
      return this.setState({
        value: FormInputSelectWithOther.OTHER,
        other: '',
      });
    }

    const invalidValue = props.options.find(option =>
      option.value === FormInputSelectWithOther.OTHER
    );

    if (invalidValue) {
      throw new Error('Cannot use reserved values');
    }

    if (!props.value) {
      return this.setState({
        other: '',
        value: ''
      });
    }

    const matchingOption = props.options.find(option => option.value === props.value);

    this.setState(state => ({
      value: matchingOption ? matchingOption.value : FormInputSelectWithOther.OTHER,
      other: matchingOption
        ? state.other
        : (props.value === FormInputSelectWithOther.OTHER ? '' : props.value)
    }));
  };

  handleChangeSelect = (event: React.FormEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    if (value === FormInputSelectWithOther.OTHER) {
      return this.setState(state => ({
        value: FormInputSelectWithOther.OTHER,
        other: state.other || ''
      }), () => {
        this.emitChange();
        this.$other.focus();
      });
    }

    return this.setState({ value }, this.emitChange);
  };

  handleChangeOther = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({ other: event.currentTarget.value }, this.emitChange);
  };

  emitChange = () => {
    const { i18n = FormInputSelectWithOther.i18n } = this.props;
    const { other, value } = this.state;
    const emit = this.props.onChange.bind(null, this.props.name);

    switch (true) {
      case (value !== '' && value !== FormInputSelectWithOther.OTHER):
        return emit(value);

      case (value === FormInputSelectWithOther.OTHER):
        return emit(other || i18n.other);

      default:
        return emit('');
    }
  };

  static i18n = {
    other: 'Other',
    otherPlaceholder: 'e.g. My MCN'
  };

  static styles = {
    container: style({
      ...horizontal
    }),
    selectOther: style({
      width: '80px !important',
    }),
    other: style({
      flex: 1
    })
  }
}