import React from 'react';
import { CarouselEvents, EventCarouselProps } from '../../../utilities/carousel';
import { ButtonProps } from '../../button/Button';

export type ButtonControllerProps = EventCarouselProps & ButtonProps;

export class AbstractButtonController extends React.Component<ButtonControllerProps> {
  disabledEvent: CarouselEvents;

  actionEvent: CarouselEvents;

  disabledHandler: () => void;

  enabledHandler: () => void;

  state = {
    disabled: false,
  };

  setDisabled(isDisabled: boolean) {
    if (this.state.disabled === isDisabled) return;
    this.setState({ disabled: isDisabled });
  }

  handle() {
    this.props.events.emit(this.actionEvent);
  }

  constructor(props) {
    super(props);
    this.handle = this.handle.bind(this);
  }

  protected addListeners(): void {
    this.disabledHandler = this.setDisabled.bind(this, true);
    this.props.events.addListener(this.disabledEvent, this.disabledHandler);
    this.enabledHandler = this.setDisabled.bind(this, false);
    this.props.events.addListener(CarouselEvents.PAGE_CHANGED, this.enabledHandler);
  }

  componentWillUnmount() {
    this.props.events.removeListener(CarouselEvents.POSITION_START, this.disabledHandler);
    this.props.events.removeListener(CarouselEvents.PAGE_CHANGED, this.enabledHandler);
  }
}
