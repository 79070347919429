import { ContentModel } from './model';
import { EnvModel } from '../env/model';
import { action, isObservableArray, observable, ObservableMap } from 'mobx';
import { PrismicController } from '../prismic/controller';
import { GraphQLController } from '../graphql/controller';
import { gql } from 'react-apollo';
import { BugsnagController } from '../bugsnag/controller';
import { getUid } from '../../components/project-happy/utilities/string';
import { Logger } from 'winston';
import { isEmpty } from '../../components/project-happy/utilities/objects';
import { PrismicAnnouncementPayload } from '../../components/project-happy/prismic-slices/PrismicAnnouncement';
import { StorageModel } from '../storage/model';
import { USER_LAST_SEEN_ANNOUNCEMENT } from '../../constants';

export class ContentController {
  constructor(
    private model: ContentModel,
    private env: EnvModel,
    private prismic: PrismicController,
    private graphql: GraphQLController,
    private bugsnag: BugsnagController,
    private logger: Logger
  ) {
    if (!env.isServer) this.hydrate();
    this.model.eulas = observable.map();
  }

  @action init = () => {
    this.env.startupPromises.push(this.populate());
  };

  @action hydrate = () => {
    const { initialContentModel } = window as any;
    if (initialContentModel && initialContentModel.fetched) {
      Object.keys(initialContentModel).forEach((key) => {
        const model = this.model as any;
        model[key] = initialContentModel[key];
      });
    } else {
      this.init();
    }
  };

  @action populate = async () => {
    const logger = this.logger;

    const globalConfigDocumentId = 'globals';
    const platformAnnouncementsDocumentId = 'platform_announcements';

    const contentRequest = { id: getUid() };

    logger.info('Content request - start', { contentRequest });

    let prismicResponse, platformAnnouncementsResponse;

    try {
      [prismicResponse, platformAnnouncementsResponse] = await Promise.all([
        this.prismic.getSingle(globalConfigDocumentId).then((value) => {
          logger.info('Content request (prismic) - complete', { contentRequest });
          return value;
        }),
        this.prismic.getSingle(platformAnnouncementsDocumentId, { resolveImages: true }).then((value) => {
          logger.info('Content request (prismic platform announcements) - complete', { contentRequest });
          return value;
        }),
      ]);

      logger.info('Content request - success', { contentRequest });
    } catch (error) {
      logger.error('Content request - error', { contentRequest, error });
    } finally {
      logger.info('Content request - finished', { contentRequest });
    }

    const document = Object.keys(prismicResponse.data).length && prismicResponse.data;

    if (!isEmpty(platformAnnouncementsResponse.data) && !isEmpty(platformAnnouncementsResponse.data.slices)) {
      this.model.announcements = platformAnnouncementsResponse.data.slices.filter(
        (announcement: PrismicAnnouncementPayload) => Date.parse(announcement.primary.date) <= Date.now()
      );
    }

    const seoSlice = document.body.find((slice: any) => slice.slice_type === 'title___description');
    this.model.seo = {
      titleFallback: seoSlice.primary.title_fallback,
      titleSuffix: seoSlice.primary.title_suffix,
      descriptionFallback: seoSlice.primary.description_fallback,
      paths: seoSlice.items.reduce(
        (paths: any, item: any) => ({
          ...paths,
          [item.path]: {
            title: item.title,
            description: item.description,
            noSuffix: !!item.no_suffix,
          },
        }),
        {}
      ),
    };

    const browseSections = document.body.find((slice: any) => slice.slice_type === 'browse_sections');
    this.model.browseSections = (browseSections ? browseSections.items : []).map((section) => ({
      ...section,
      image: section.image ? section.image.url : null,
    }));

    this.model.genericOops = document.generic_oops;

    const genresSlice = document.body.find((slice: any) => slice.slice_type === 'genres');
    this.model.genres = genresSlice ? genresSlice.items : [];

    const themesSlice = document.body.find((slice: any) => slice.slice_type === 'themes');
    this.model.themes = themesSlice ? themesSlice.items : [];

    const temposSlice = document.body.find((slice: any) => slice.slice_type === 'tempos');
    this.model.tempos = temposSlice ? temposSlice.items : [];

    const chartingTracksSlice = document.body.find((slice: any) => slice.slice_type === 'charting_tracks');
    this.model.chartingTracks = (chartingTracksSlice ? chartingTracksSlice.items : []).map((t: any) => t.identity);

    this.model.question3Active = !!document.question_3_active;

    this.model.cookieNotificationTitle = document.cookie_notification_title;
    this.model.cookieNotificationContent = document.cookie_notification_content;

    this.model.signUpModalTitle = document.sign_up_modal_title;
    this.model.signUpModalJaackMaateTitle = document.sign_up_modal_jaackmaate_title;
    this.model.signUpModalVidsummitTitle = document.sign_up_modal_vidsummit_title;
    this.model.signUpModalGoogle = document.sign_up_modal_google;
    this.model.signUpModalBenefit1 = document.sign_up_modal_benefit_1;
    this.model.signUpModalBenefit2 = document.sign_up_modal_benefit_2;
    this.model.signUpModalBenefit3 = document.sign_up_modal_benefit_3;
    this.model.signUpModalBenefit4 = document.sign_up_modal_benefit_4;
    this.model.signUpModalBlurb1 = document.sign_up_modal_blurb_1;
    this.model.signUpModalBlurb2 = document.sign_up_modal_blurb_2;
    this.model.signUpModalBlurb3 = document.sign_up_modal_blurb_3;
    this.model.signUpModalBlurb4 = document.sign_up_modal_blurb_4;

    this.model.licensesDownloadAudio = document.licenses_download_audio;
    this.model.licensesDownloadEula = document.licenses_download_eula;
    this.model.licensesPreparingEula = document.licenses_prepare_eula;
    this.model.licensesAttribution = document.licenses_attribution;
    this.model.licensesViewOrder = document.licenses_view_order;
    this.model.licensesEmpty = document.licenses_empty;

    this.model.shuntCTA = document.shunt_cta;
    this.model.buttonCTANewUser = document.button_cta_new_user;

    const toastsSlice = document.body.find((slice: any) => slice.slice_type === 'toast');
    this.model.toasts = toastsSlice.items.reduce(
      (toasts: any, toast: any) => ({
        ...toasts,
        [toast.key]: toast,
      }),
      {}
    );

    const couponsSlice = document.body.find((slice: any) => slice.slice_type === 'coupons');
    this.model.coupons = couponsSlice.items.reduce(
      (coupons: any, coupon: any) => ({
        ...coupons,
        [coupon.type]: coupon,
      }),
      {}
    );

    this.model.footerBlurb = document.footer_blurb;
    this.model.strapBrowse = document.strap_browse;
    this.model.strapArtist = document.strap_artist;
    this.model.strapRelease = document.strap_release;
    this.model.strapTrack = document.strap_track;
    this.model.brandedTrackMessage = document.branded_track_message;

    this.model.benefits1Title = document.benefits_1_title;
    this.model.benefits1Content = document.benefits_1_content;
    this.model.benefits2Title = document.benefits_2_title;
    this.model.benefits2Content = document.benefits_2_content;
    this.model.benefits3Title = document.benefits_3_title;
    this.model.benefits3Content = document.benefits_3_content;

    this.model.brandedContentWarning = document.branded_content_warning;

    this.model.downloadModalTitle = document.download_modal_title;
    this.model.downloadModalStrap = document.download_modal_strap;
    this.model.downloadModalAgree = document.download_modal_agree;

    this.model.addChannelStrap = document.add_channel_strap;

    this.model.licenceModalTitle = document.licence_modal_title;
    this.model.licenceModalLoading = document.licence_modal_loading;
    this.model.licenceModalStrap = document.licence_modal_strap;
    this.model.licenceModalNotAvailable = document.licence_modal_not_available;
    this.model.licenceModalClose = document.licence_modal_close;

    this.model.referralTitle = document.referral_title;
    this.model.referralContent = document.referral_content;
    this.model.referralCTA = document.referral_cta;

    this.model.ageWarning = document.age_warning;

    this.model.deleteModalContent = document.delete_modal_content;

    this.model.attributionBlurb = document.attribution_blurb;
    this.model.attributionWhy = document.attribution_why;
    this.model.attributionContent = document.attribution_content;

    this.model.homepageCarousel = document.homepage_carousels;

    this.model.browseBottomGridTitle = document.browse_bottom_grid_title;
    this.model.browseBottomGridRightsholder = document.browse_bottom_grid_rightsholder;

    this.model.pricingBackground = document.pricing_background.url;

    this.model.footerFaqs = document.frequently_asked_questions;
    this.model.footerLinks = document.footer_links;
    this.model.socialLinks = document.social_links;

    this.model.burgerMenuLinks = document.burger_menu;

    this.model.fetched = true;
  };

  @action fetchEulas = async (type: string) => {
    if (!this.model.eulas.has(type) || this.model.eulas.get(type) instanceof Error) {
      this.model.eulas.set(type, null);
      try {
        const eulas = await this.prismic.getByTag('eula_versions');
        if (Array.isArray(eulas.results)) {
          eulas.results.forEach((eula) => this.model.eulas.set(eula.uid, eula.data.content));
        }
        if (!isObservableArray(this.model.eulas.get(type))) {
          this.model.eulas.set(type, Error(`A EULA for ${type} could not be found. Please contact hello@lickd.co`));
        }
      } catch (e) {
        const errorMsg = 'An error occurred retrieving the EULA for this track. Please contact hello@lickd.co';
        this.bugsnag.notifyException(e);
        this.model.eulas.set(type, Error(errorMsg));
      }
    }
  };

  @action setLastSeenAnnouncement = () => {
    StorageModel.setItem(USER_LAST_SEEN_ANNOUNCEMENT, new Date().toISOString());
  };
}
