import { computed, observable } from 'mobx';
import { LOAD_STATE } from '../../types/api';
import { EssentialsPlan, V2Plan } from '../../components/project-happy/utilities/types';
import { UserModel } from '../user/model';
import { isArray, isEmpty } from '../../components/project-happy/utilities/objects';
import { toDateString } from '../../components/project-happy/utilities/date';
import { addDays } from 'date-fns';

export class SubscriptionModel {
  constructor(private user: UserModel) {}

  @observable
  subscriptionPlansState: LOAD_STATE = LOAD_STATE.EMPTY;

  @observable
  subscriptionPlans: EssentialsPlan[];

  @observable
  subscriptionPlansError = 'An unknown error occurred.';

  @observable
  creditSubscriptionPlansState: LOAD_STATE = LOAD_STATE.EMPTY;

  @observable
  creditSubscriptionPlans: V2Plan[] = [];

  @observable
  v2PlansLoadError = 'An unknown error occurred.';

  @observable
  v2PlansSubscribeError: string | null = null;

  @observable
  v2PlanIsUpdating = false;

  @observable
  pollChannelTimer: NodeJS.Timer | null = null;

  @observable
  pendingChannelUpdate = false;

  @computed
  get subscriptionPlan(): Partial<EssentialsPlan> {
    const userCurrency = !isEmpty(this.user.channel) ? this.user.channel.ratecard.currency : 'USD';
    return isArray(this.subscriptionPlans)
      ? this.subscriptionPlans.find((plan) => plan.currency_code === userCurrency)
      : {};
  }

  // If the currency were GBP, this value would be pennies e.g. 1 == 1 penny
  @computed
  get subscriptionDecimalPrice(): number {
    if (isEmpty(this.subscriptionPlan)) return 0;
    const price = Number(this.subscriptionPlan.price);
    if (!Number.isInteger(price)) return 0;
    return price;
  }

  // If the currency were GBP, this value would be pounds e.g. 1 == £1 GBP
  @computed
  get subscriptionUnitPrice(): number {
    return this.subscriptionDecimalPrice / 100;
  }

  @computed
  get trialLengthInDays(): number {
    if (isEmpty(this.subscriptionPlan)) return 0;
    const days = Number(this.subscriptionPlan.trial_length_in_days);
    if (!Number.isInteger(days)) return 0;
    return days;
  }

  @computed
  get canSubscribe(): boolean {
    return !isEmpty(this.user.channel);
  }

  // If a trial were to start today, this would be the end date of that trial
  @computed
  get potentialTrialEndDate(): string {
    return toDateString(addDays(new Date(), this.trialLengthInDays));
  }
}
