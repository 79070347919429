import * as React from 'react';
import { observer } from 'mobx-react';
import { classes, style } from 'typestyle';
import { colorGunmetal, colorWhite } from '../theme/color';
import { mediaTablet, mediaMobileOnly } from '../theme/media';
import { em, percent, px, url, viewHeight, viewWidth } from 'csx';
import { centerCenter, horizontal, horizontallySpaced, vertical, verticallySpaced } from 'csstips';
import {ArtistMappingSchema} from '../types/schema';
import {ImagePreloader} from '../types/fn';

export interface AppHeroSearchResultsFallbackProps {
  className?: string;
  artist: {
    name: string;
    image: {
      url: string;
    }
  }
}

@observer
export class AppHeroSearchResultsFallback extends React.Component<AppHeroSearchResultsFallbackProps, any> {
  render() {
    const { className, artist } = this.props;

    return (
      <div className={classes(className, AppHeroSearchResultsFallback.styles.container)}>
        <div>
          <h3>Ah shucks!</h3>
          {artist.image && (
            <div style={{ backgroundImage: url(artist.image.url) }} />
          )}
        </div>
        <h4>We don’t have {artist.name} for you yet <span>(but trust us, we’ll get it Lickd soon!)</span></h4>
        <p>Here are some similar artists in the meantime for you to try:</p>
      </div>
    )
  }

  static styles = {
    container: style(
      {
        ...vertical,
        ...verticallySpaced(20),
        borderRight: `2px solid ${colorWhite.fade(0.2).toString()}`,
        flexShrink: 0,
        padding: px(10),
        position: 'relative',
        $nest: {
          '&> div': {
            ...centerCenter,
            ...horizontal,
            ...horizontallySpaced(10)
          },
          '&> div > div': {
            flexShrink: 0,
            borderRadius: percent(50),
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          },
          '&> div > h3': {
            margin: 0,
            fontSize: px(32),
            color: colorWhite.toString(),
            flex: 1
          },
          '&> h4': {
            margin: 0,
            fontSize: px(24),
            color: colorWhite.toString(),
          },
          '&> h4 span': {
            fontSize: px(16),
          },
          '&> p': {
            margin: 0,
            color: colorWhite.toString(),
            flex: 1,
            fontSize: px(16),
            lineHeight: em(1.4)
          }
        }
      },
      mediaMobileOnly({
        width: viewWidth(75),
        maxWidth: viewHeight(100),
        height: viewWidth(75),
        maxHeight: viewHeight(60),
        $nest: {
          '&> div > div': {
            width: viewWidth(20),
            maxWidth: viewHeight(20),
            height: viewWidth(20),
            maxHeight: viewHeight(20)
          },
        }
      }),
      mediaTablet({
        width: viewWidth(20),
        minWidth: px(360),
        height: viewWidth(20),
        minHeight: px(240),
        $nest: {
          '&> div > div': {
            width: viewWidth(5),
            minWidth: px(60),
            height: viewWidth(5),
            minHeight: px(60),
          },
        }
      })
    ),
  }
}